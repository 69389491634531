import React, { Component } from 'react';
import * as Responses from './Saga/ResponseList';
import { connect } from 'react-redux';
import styled from 'styled-components';
// import './Css/Images.css';
import Carousel, { Modal, ModalGateway } from 'react-images';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
const authentication = require('../../react-azure-adb2c2').default;

const Balloon = styled.div`
    position:relative;

    padding:0px;
    margin: 0 0 0 0;
    margin-bottom: 0;

`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;

`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 0;
    max-width: 250px;
    max-height:270px;
    border-radius: 12px;
    margin-bottom: 2px;
    text-align: left;
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const DeleteButton = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    top: 0;

`;

// const MsgArea = styled.p`
//     margin:0px;
//     word-wrap: break-word;
// `;

const BodyImg = styled.img`
    border-radius: 12px;
height:250px;
    margin:0;
    padding:0;
`;

class LineTextComponent extends Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],
            bloburl: "",
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            images: [],
            currentIndex: 0,
            modalIsOpen: false,
            refresh:false,

        };
        this.imagebox = this.imagebox.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentWillMount() {

        const token = authentication.getAccessToken();
        const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/template/docs/download/` + this.props.msg.file_id
        // const url = `http://localhost:5000/v1/rent/line/template/docs/download/` + this.props.msg.file_id

        var bloburl
        fetch(url, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => response.blob()).then(images => {

            // Then create a local URL for that image and print it 
            bloburl = URL.createObjectURL(images)
            // alert(bloburl)
            console.log(bloburl)
            this.setState({
                bloburl: bloburl
            })

        });

    }

    imagebox(index) {

        let images = [];

        images.push({ src: this.state.bloburl })



        this.setState({ "images": images })
        if (index >= 0) {
            this.setState({ currentIndex: 0 })
            this.setState({ modalIsOpen: true });
        }
    }
    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    };

    onDeleteMessage(no){
        // this.props.change_rent_line_template_detail([])
        // let rent_line_template_detail = this.props.rent_line_template_detail
    
        // rent_line_template_detail = rent_line_template_detail.filter(a=>a.no != no)

        // this.props.change_rent_line_template_detail(JSON.parse(JSON.stringify(rent_line_template_detail)))

        this.props.delete_rent_line_template_detail(no)


        // let rent_line_template = this.props.rent_line_template

        // alert(rent_line_template.id)
        // this.props.get_rent_line_template_detail(rent_line_template.id)



        this.setState({
            refresh: !this.state.refresh
          });
    }
    render() {
        return (
            <div>
                <Balloon className="col-xs-12">

                    <Chatting>
                        {this.props.line_template_edit_open ? <DeleteButton>
                            <IconButton onClick={()=>this.onDeleteMessage(this.props.msg.no)} size="large"><CloseIcon /></IconButton>
                        </DeleteButton>:""}
                        {/* <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time> */}
                        <Says>

                            <BodyImg src={this.state.bloburl} onClick={() => this.imagebox(0)} key={this.props.key} />
                            {/* <BodyImg src={this.state.bloburl} onClick={() => this.imagebox(0)} /> */}

                        </Says>
                    </Chatting>
                </Balloon>
                <ModalGateway >
                    {this.state.modalIsOpen ? (
                        <Modal onClose={this.toggleModal} >
                            <Carousel views={this.state.images}
                                currentIndex={this.state.currentIndex}

                            />
                        </Modal>
                    ) : null}
                </ModalGateway>
                {/* <Balloon className="col-xs-12">
                    <Chatting><SayP></SayP>
                        <Time>{this.state.date_time}</Time>
                        <Says>
                            <p><a href="https://www.homestation.jp/manage/common/line/rest/line/download?file={this.props.msg.body_all}" target="_blank" rel="noreferrer noopener">
                                <BodyImg src="https://www.homestation.jp/manage/common/line/rest/line/download?file={this.props.msg.body_all}" /></a></p>
                        </Says>
                    </Chatting>
                </Balloon> */}
            </div>
        );
    }
}



//コンテナ
const mapStateToProps = state => {
    return {
        rent_line_templates_list: state.responselist.rent_line_templates_list,
        rent_line_template_detail: state.responselist.rent_line_template_detail,
        rent_line_template: state.responselist.rent_line_template,
        line_template_edit_open: state.responselist.line_template_edit_open,

    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_rent_line_template_detail(state) {
            dispatch(Responses.change_rent_line_template_detail(state))
        },
        delete_rent_line_template_detail(state) {
            dispatch(Responses.delete_rent_line_template_detail(state))
        },
        change_rent_line_template(state) {
            dispatch(Responses.change_rent_line_template(state))
        },
        get_rent_line_template_detail(state) {
            dispatch(Responses.get_rent_line_template_detail(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineTextComponent);
