import React from 'react';

import styled from 'styled-components';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const MenuLi = styled.li`
    display: inline-block;

    
`;

export default class NavMenu extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }
    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }


    render() {

        const result = this.props.shops.filter(x => x.tenpoNo === this.props.user_details.amSectionId);


        //    alert(shops.tenpoName)
        return (
            <header>

                <div style={{ textAlign: "right", height: "100%" }}>

                    <a href="https://www.homestation.jp/manage/top/index">
                        <i className="icon-homestation navbar-brand" style={{ lineHeight: "inherit" }}></i>
                    </a>
                    {/* <nav> */}




                    <ul className="">


                        <MenuLi >
                            ログイン中： {!result[0] ? "" : result[0].tenpoName}　{this.props.user_details.userNameAll}
                        </MenuLi>
                      
                        <MenuLi className="dropdown" >
                            <Dropdown direction="down" isOpen={this.state.dropdownOpenCog} toggle={() => { this.setState({ dropdownOpenCog: !this.state.dropdownOpenCog }); }}>
                                <DropdownToggle
                                    tag="span"
                                    className="fas fa-cog navbar-menu"
                                    data-toggle="dropdown"
                                    aria-expanded={this.state.dropdownOpenCog}
                                >
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?settings=1">ガジェットストア</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/top/index?settings=2">ガジェット並び替え</DropdownItem>
                                    <DropdownItem href="https://www.homestation.jp/manage/master/holidays">祝日更新</DropdownItem>
                                    <DropdownItem  />
                                    <DropdownItem href="https://www.homestation.jp/manage/login/logout">ログアウト</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </MenuLi>
                    </ul>
                    {/* </nav> */}

                </div >





            </header >
        );
    }
}
