import React, { Component } from 'react';

import { connect } from 'react-redux';


import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const moment = require('moment-timezone');
class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  render() {



    let penalty
    let is_student_new = ""
    let is_student_old = ""
    if (this.props.rent_contract_details_new.penalty_name + this.props.rent_contract_details_new.penalty_remarks !== this.props.rent_contract_details_old.penalty_name + this.props.rent_contract_details_old.penalty_remarks || this.props.rent_contract_details_new.is_student !== this.props.rent_contract_details_old.is_student) {
      is_student_new = this.props.rent_contract_details_new.is_student ? " 2月1日から3月25日特約 " : ""
      is_student_old = this.props.rent_contract_details_new.is_student ? " 2月1日から3月25日特約 " : ""
      penalty = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.penalty_name + (this.props.rent_contract_details_old.penalty_remarks ? "(" + this.props.rent_contract_details_old.penalty_remarks + is_student_old + ")" : "")}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.penalty_name + (this.props.rent_contract_details_new.penalty_remarks ? "(" + this.props.rent_contract_details_new.penalty_remarks + is_student_new + ")" : "")}</div></span>
    } else {
      penalty = (this.props.rent_contract_details_new.penalty_name ? this.props.rent_contract_details_new.penalty_name : "") + ((this.props.rent_contract_details_new.penalty_remarks || is_student_new) ? "(" + this.props.rent_contract_details_new.penalty_remarks + is_student_new + ")" : "")
    }



    let update_array = []
    let update_new = ""
    let update_old = ""
    if (this.props.rent_contract_details_new.rent_contract_money_logs) {
      this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 3).map(function (value) {
        let update_name = value.name

        if (value.amount_month) {
          update_name = update_name + "　" + value.amount_month + "ヶ月";
        }
        if (value.amount) {
          update_name = update_name + "　" + (value.amount + value.tax_amount).toLocaleString() + "円";
        }
        if (value.rent_m_etc_id) {
          update_name = update_name + "　" + (value.rent_m_etc_id === 1 ? "実費" : value.rent_m_etc_id === 2 ? "込み" : value.rent_m_etc_id === 3 ? "無し" : "");
        }

        if (value.each_year) {
          update_name = update_name + "　" + value.each_year + "年ごと";
        }
        if (value.each_month) {
          update_name = update_name + "　" + value.each_month + "月ごと";
        }

        update_name = update_name + "　" + (value.remarks ? value.remarks : "")

        update_array.push(update_name)
      })
    }
    update_new = update_array.join('<br/>');
    update_array = []
    if (this.props.rent_contract_details_old.rent_contract_money_logs) {
      this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 3).map(function (value) {
        let update_name = value.name
        if (value.amount_month) {
          update_name = update_name + "　" + value.amount_month + "ヶ月";
        }
        if (value.amount) {
          update_name = update_name + "　" + (value.amount + value.tax_amount).toLocaleString() + "円";
        }
        if (value.rent_m_etc_id) {
          update_name = update_name + "　" + (value.rent_m_etc_id === 1 ? "実費" : value.rent_m_etc_id === 2 ? "込み" : value.rent_m_etc_id === 3 ? "無し" : "");
        }

        if (value.each_year) {
          update_name = update_name + "　" + value.each_year + "年ごと";
        }
        if (value.each_month) {
          update_name = update_name + "　" + value.each_month + "月ごと";
        }

        update_name = update_name + "　" + (value.remarks ? value.remarks : "");

        update_array.push(update_name)
      })
    }
    update_old = update_array.join('<br/>');
    let update = ""
    if (update_new !== update_old) {
      update = <span><div style={{ color: "red", fontWeight: "bold" }}><div
        dangerouslySetInnerHTML={{
          __html: update_old
        }}
      /></div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: update_new
            }}
          />
        </div></span>
    } else {
      update = <div
        dangerouslySetInnerHTML={{
          __html: update_new
        }}
      />
    }



    let leave_array = []
    let leave_new = ""
    let leave_old = ""
    if (this.props.rent_contract_details_new.rent_contract_money_logs) {
      this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.account_group === 4).map(function (value) {
        let leave_name = value.name
        if (value.amount_month) {
          leave_name = leave_name + "　" + value.amount_month + "ヶ月";
        }
        if (value.amount) {
          leave_name = leave_name + "　" + (value.amount + value.tax_amount).toLocaleString() + "円";
        }
        if (value.rent_m_etc_id) {
          leave_name = leave_name + "　" + (value.rent_m_etc_id === 1 ? "実費" : value.rent_m_etc_id === 2 ? "込み" : value.rent_m_etc_id === 3 ? "無し" : "");
        }

        if (value.each_year) {
          leave_name = leave_name + "　" + value.each_year + "年ごと";
        }
        if (value.each_month) {
          leave_name = leave_name + "　" + value.each_month + "月ごと";
        }

        leave_name = leave_name + "　" + (value.remarks ? value.remarks : "");

        leave_array.push(leave_name)
      })
    }
    leave_new = leave_array.join('<br/>');

    leave_array = []
    if (this.props.rent_contract_details_old.rent_contract_money_logs) {
      this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.account_group === 4).map(function (value) {
        let leave_name = value.name
        if (value.amount_month) {
          leave_name = leave_name + "　" + value.amount_month + "ヶ月";
        }
        if (value.amount) {
          leave_name = leave_name + "　" + (value.amount + value.tax_amount).toLocaleString() + "円";
        }
        if (value.rent_m_etc_id) {
          leave_name = leave_name + "　" + (value.rent_m_etc_id === 1 ? "実費" : value.rent_m_etc_id === 2 ? "込み" : value.rent_m_etc_id === 3 ? "無し" : "");
        }

        if (value.each_year) {
          leave_name = leave_name + "　" + value.each_year + "年ごと";
        }
        if (value.each_month) {
          leave_name = leave_name + "　" + value.each_month + "月ごと";
        }

        leave_name = leave_name + "　" + (value.remarks ? value.remarks : "")

        leave_array.push(leave_name)
      })
    }
    leave_old = leave_array.join('<br/>');
    let leave = ""
    if (leave_new !== leave_old) {
      leave = <span><div style={{ color: "red", fontWeight: "bold" }}><div
        dangerouslySetInnerHTML={{
          __html: leave_old
        }}
      /> </div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}><div
        dangerouslySetInnerHTML={{
          __html: leave_new
        }}
      /></div></span>
    } else {
      leave = <div
        dangerouslySetInnerHTML={{
          __html: leave_new
        }}
      />
    }
    let safe_new = ""

    if (this.props.rent_contract_details_new.rent_contract_money_logs && this.props.rent_contract_details_new.rent_contract_money_logs.filter(a => a.rent_m_account_id === 22)[0]) {
      safe_new = "個別"
    }
    if (this.props.rent_contract_details_new.kaketsuke) {
      safe_new = "一括"
    }
    let safe_old = ""
    if (this.props.rent_contract_details_old.rent_contract_money_logs && this.props.rent_contract_details_old.rent_contract_money_logs.filter(a => a.rent_m_account_id === 22)[0]) {
      safe_old = "個別"
    }
    if (this.props.rent_contract_details_new.kaketsuke) {
      safe_old = "一括"
    }

    let safe = ""
    if (safe_new !== safe_old) {
      safe = <span><div style={{ color: "red", fontWeight: "bold" }}>{safe_old} </div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{safe_new}</div></span>
    } else {
      safe = safe_new
    }

    let billing_closing_date = ""
    if (this.props.rent_contract_details_old.billing_closing_date !== this.props.rent_contract_details_new.billing_closing_date) {
      billing_closing_date = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.billing_closing_date ? moment(this.props.rent_contract_details_new.billing_closing_date).format("YYYY年M月D日") : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.billing_closing_date ? moment(this.props.rent_contract_details_new.billing_closing_date).format("YYYY年M月D日") : ""}</div></span>

    } else {
      billing_closing_date = this.props.rent_contract_details_new.billing_closing_date ? moment(this.props.rent_contract_details_new.billing_closing_date).format("YYYY年M月D日") : "-"
    }

    let billing_closing_date_nyuukyosha = ""
    if (this.props.rent_contract_details_old.billing_closing_date_nyuukyosha !== this.props.rent_contract_details_new.billing_closing_date_nyuukyosha) {
      billing_closing_date_nyuukyosha = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.billing_closing_date ? moment(this.props.rent_contract_details_new.billing_closing_date_nyuukyosha).format("YYYY年M月D日") : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.billing_closing_date_nyuukyosha ? moment(this.props.rent_contract_details_new.billing_closing_date_nyuukyosha).format("YYYY年M月D日") : ""}</div></span>

    } else {
      billing_closing_date_nyuukyosha = this.props.rent_contract_details_new.billing_closing_date_nyuukyosha ? moment(this.props.rent_contract_details_new.billing_closing_date_nyuukyosha).format("YYYY年M月D日") : "-"
    }

    let contract_document_type = ""
    if (this.props.rent_contract_details_old.contract_document_type !== this.props.rent_contract_details_new.contract_document_type) {
      contract_document_type = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.contract_document_type === 0 ? "旧" : this.props.rent_contract_details_old.contract_document_type === 1 ? "2020年民法対応" : this.props.rent_contract_details_old.contract_document_type === 2 ? "社宅統一契約書" : ""}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.contract_document_type === 0 ? "旧" : this.props.rent_contract_details_new.contract_document_type === 1 ? "2020年民法対応" : this.props.rent_contract_details_new.contract_document_type === 2 ? "社宅統一契約書" : ""}</div></span>

    } else {
      contract_document_type = this.props.rent_contract_details_new.contract_document_type === 0 ? "旧" : this.props.rent_contract_details_new.contract_document_type === 1 ? "2020年民法対応" : this.props.rent_contract_details_new.contract_document_type === 2 ? "社宅統一契約書" : ""
    }

    let receipt = ""
    if (this.props.rent_contract_details_old.receipt !== this.props.rent_contract_details_new.receipt) {
      receipt = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.receipt === 1 ? "領収書必要" : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.receipt === 1 ? "領収書必要" : "-"}</div></span>

    } else {
      receipt = this.props.rent_contract_details_new.receipt === 1 ? "領収書必要" : "-"

    }

    let owner_payment_way = ""
    if (this.props.rent_contract_details_old.owner_payment_way !== this.props.rent_contract_details_new.owner_payment_way) {
      owner_payment_way = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.owner_payment_way === 1 ? "振込み" : this.props.rent_contract_details_old.owner_payment_way === 2 ? "現金" : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.owner_payment_way === 1 ? "振込み" : this.props.rent_contract_details_new.owner_payment_way === 2 ? "現金" : "-"}</div></span>

    } else {
      owner_payment_way = this.props.rent_contract_details_new.owner_payment_way === 1 ? "振込み" : this.props.rent_contract_details_new.owner_payment_way === 2 ? "現金" : "-"

    }
    return (

      <div>

        <table className='basicList'>
          <tr>
            <th >契約書タイプ</th>
            <td colSpan="3">{contract_document_type}</td>

          </tr>

          <tr>
            <th style={{ width: "25%" }}>請求締日</th>
            <td style={{ width: "25%" }}>{billing_closing_date}</td>
            <th style={{ width: "25%" }}>{this.props.rent_contract_details_new.type ? "請求締日(入居者)" : ""}</th>
            <td style={{ width: "25%" }}>{this.props.rent_contract_details_new.type ? billing_closing_date_nyuukyosha : ""}</td>

          </tr>
          <tr>

            <th>領収書必要</th>
            <td>{receipt}</td>

            <th></th>
            <td></td>
            {/* <th>領収書必要(入居者)</th>
            <td>{receipt}</td> */}

          </tr>

          {(this.props.rent_contract_details_new.division === 3 || this.props.rent_contract_details_new.division === 6 || this.props.rent_contract_details_new.division === 9 || this.props.rent_contract_details_new.division === 10) ? <tr>
            <th>オーナー支払方法</th>
            <td colSpan="3">{owner_payment_way}</td>

          </tr> : ""}

          <tr>
            <th>更新時</th>
            <td colSpan="3">{update}</td>
          </tr>

          <tr>
            <th>退去精算</th>
            <td>{leave}</td>
            <th>違約金</th>
            <td>{penalty}</td>
          </tr>
          <tr>
            <th>緊急サポート</th>
            <td colSpan="3">{safe}</td>
          </tr>
          <tr>
            <th>おまかせみのり</th>
            <td colSpan="3">{this.props.rent_contract_details_new.omakase_name}{this.props.rent_contract_details_new.omakase_discription ? "(" + this.props.rent_contract_details_new.omakase_discription + ")" : ""}</td>
          </tr>
          <tr>
            <th>請求書備考</th>
            <td colSpan="3">{this.props.rent_contract_details_new.estimate_remarks}</td>
          </tr>

        </table>
      </div >

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    // change_contract_detail_edit_disp(state) {
    //   dispatch(Contracts.change_contract_detail_edit_disp(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
