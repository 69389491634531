import React, { Component } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
//import CustomerInfoEdit from './CustomerInfoEditComponent';
//import CustomerInfoDisp from './CustomerInfoDispComponent';
import Fab from '@mui/material/Fab';
import { Modal, ModalFooter, Button, ModalBody } from 'reactstrap';
import MuiButton from '@mui/material/Button';
import './Css/Line.css';

import throttle from 'lodash.throttle';
import * as Line from './Saga/Line';
import LineDispUser from './LineDispUser';


import * as Firebase from '../../Store/Firebase';
import { initializeApp } from 'firebase/app';
import firebaseConfig from '../../firebase-config';
import { getFirestore, collection, query, where, onSnapshot, orderBy } from "firebase/firestore";
const firebaseApp = initializeApp(firebaseConfig);

// const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);


const authentication = require('react-azure-adb2c2').default;
// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }


let unsub = [];
class Lines extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            // top: this.props.lines[0].id,
            modal_send: 0,
            reflash: false,
            base64: null,
            mimeType: null,
            lines: [],
        };
        console.log(this.props.lines)
        // this.props.lines.map(){
        //     this.Print()
        // }
        //
        this.send = this.send.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.lineopen = this.lineopen.bind(this);
        this.toggle_send = this.toggle_send.bind(this);
        this.togglesheet = this.togglesheet.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);

        this.onDrop = this.onDrop.bind(this);


        // this.props.get_fire_line_detail_all({ line_user_id: this.props.line_user.line_user_id, line_account_section_id: this.props.line_user.line_account_section_id })

        // this.props.get_lines_all(this.props.line_user)
        // this.line_looked = this.line_looked.bind(this);
        let token = authentication.getAccessToken();
        // alert(process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/list/` + this.props.line_user.id + '/' + this.props.line_user.line_account_section_id + '/' + 20)
        // alert(token)
        // fetch(`http://localhost:5000/v1/lines/list/` + this.props.line_user.id + '/' + this.props.line_user.line_account_section_id + '/' + 20, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })

        // this.props.get_line_all({ line_user_id: this.props.line_user.line_user_id, line_account_section_id: this.props.line_user.line_account_section_id, take: this.state.take })




        fetch(process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/list/` + this.props.line_user.line_user_id + '/' + this.props.line_user.line_account_section_id + '/' + 20, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        // fetch(`http://localhost:5000/v1/lines/list/` + this.props.line_user.line_user_id + '/' + this.props.line_user.line_account_section_id + '/' + 20, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
            .then(response => response.json())
            .then(data => {

                console.log("かえってきたよ")
                console.log(data)
                this.setState({ lines: data })



                let timestamp = ""
                data.map(function (value) {
                    timestamp = value.timestamp > timestamp ? value.timestamp : timestamp
                })

                const citiesRef = collection(db, "line");

                // if (unsub[this.props.line_user.line_user_id]) {
                //     alert("あんす")
                // }
                if (unsub[this.props.line_user.line_user_id]) unsub[this.props.line_user.line_user_id]()
                // query11 = query(citiesRef, where("Timestamp", ">", Math.floor(new Date().getTime() / 1000)));
                let querySnapshot = query(citiesRef, where("line_user_id", "==", this.props.line_user.line_user_id), where("line_account_section_id", "==", this.props.line_user.line_account_section_id), where("timestamp", ">", parseInt(timestamp)), orderBy("timestamp", "desc"));
                unsub[this.props.line_user.line_user_id] = onSnapshot(querySnapshot, { includeMetadataChanges: true }, (snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        // alert(change.type)
                        if (change.type === 'added') {
                            // alert(change.doc.data())
                            // alert("りっすん")
                            // this.setState({ lines: [] })
                            var datas = []
                            datas = this.state.lines
                            datas.push(change.doc.data())
                            this.setState({ lines: JSON.parse(JSON.stringify(datas)) })
                            this.props.change_line_bottom(true)
                            // this.setState({ lines: [] })

                            // console.log(change.doc.data())
                            // alert(change.doc.data().MailAccountId)

                            // }

                        }

                    })
                })

            })



        // var db_response = getFirestore(firebaseApp);
        // timestamp = Math.floor(new Date().getTime() / 1000)
        // alert(this.props.line_user.line_user_id)
        // unsub = db.collection("line").where("line_user_id", "==", this.props.line_user.line_user_id).where("line_account_section_id", "==", this.props.line_user.line_account_section_id).where("timestamp", ">", parseInt(timestamp)).orderBy("timestamp", "desc")
        //     .onSnapshot(snapshot => {
        //         snapshot.docChanges().forEach(function (change) {
        //             alert(timestamp)

        //             this.setState({ reflesh: !this.state.reflesh })
        //         }, this)
        //     });
        // let ref = firebaseDb.collection('line').where("line_user_id", "==", this.props.line_user.line_user_id).where("line_account_section_id", "==", this.props.line_user.line_account_section_id).where("timestamp", ">", parseInt(timestamp)).orderBy("timestamp", "desc")

        // ref.off()
        // ref.on('child_added',
        //     (snapshot) => {

        //         this.Print(snapshot);

        //         this.node.scrollTop = 30;

        //     })







        // querySnapshot.forEach((doc) => {
        //     alert(doc.data())
        //     // console.log(`${doc.id} => ${doc.data()}`);
        // });




        // unsub = db.collection("line").where("line_user_id", "==", this.props.line_user.line_user_id).where("line_account_section_id", "==", this.props.line_user.line_account_section_id).where("timestamp", ">", parseInt(timestamp)).orderBy("timestamp", "desc")
        //     .onSnapshot(snapshot => {
        //         snapshot.docChanges().forEach(function (change) {
        //             alert(change.doc.data())
        //             // change.doc.data();

        //         }, this)

        //     })
        //         // console.log(data); // `data.json()` の呼び出しで解釈された JSON データ
        //     }).catch(error => { console.log(error) });



        // var result = props.lines.map(function( value ) {

        //         // alert()
        //     return this.Print(value)

        //     //配列の各要素を2倍にする
        //     // return value * 2;

        // });
    }






    // }


    async componentDidMount() {
        console.log(this.props)

        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_line_bottom(false);
        }


    }
    componentDidUpdate() {
        // alert(this.props.line_bottom)
        // alert(this.props.line_bottom)
        if (this.node != null && this.props.line_bottom === true) {
            // alert(this.node.scrollHeight)
            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_line_bottom(false);
        }
    }
    componentWillUnmount() {
        if (unsub[this.props.line_user.line_user_id]) unsub[this.props.line_user.line_user_id]()
        // unsubscribe();
        // if (unsub) unsub()
        // if (unsubscribe) {
        //     alert("アンマウント")
        // }
    }

    send(value) {

        // alert(this.props.line_user_details.id)
        this.props.send_message({ replytoken: "globalcenter", message_type: "text", message_text: value.message, line_user_id: this.props.line_user.line_user_id, line_account_section_id: this.props.line_user.line_account_section_id, rent_response_id: this.props.responsedetail.id })


    }


    lineopen() {
        // alert(this.props.line_user_details.line_account_section_id)
        // this.props.get_line_user_details({ line_user_id: this.props.line_user.line_user_id, line_account_section_id: this.props.line_user.line_account_section_id })
        this.props.get_line_open({ line_user_id: this.props.line_user.line_user_id, line_account_section_id: this.props.line_user.line_account_section_id, take: 20 })
        // alert(this.props.line_user.line_account_section_id)
        // this.props.get_line_close()
        // this.props.get_line_open({ line_user_id: this.props.line_user.line_user_id, line_account_section_id: this.props.line_user.line_account_section_id, take: 20 })
    }


    toggle_send(nom) {

        this.setState({
            // modal_send: nom,
            base64: null,
            name: null,
            mimeType: null,
        })
        this.props.change_line_file_data(null)
        this.props.change_line_send_modal(nom)
    }



    scrollToBottom = () => {

        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;


            this.props.change_line_bottom(false);
        }
    }

    togglesheet = () => {

        this.setState(prevState => ({
            modalsheet: !prevState.modalsheet,

        }));
    }

    onDrop = (files) => {
        console.log(files)
        const file = files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        console.log(file.type)
        reader.onload = () => {
            this.setState({
                base64: reader.result,
                name: file.name,
                mimeType: file.type
            })
            this.props.change_line_file_data({
                file: files,
                name: file.name,
                mimeType: file.type
            })
        }

        // let sendfile = [];
        // sendfile.file = files;



    };
    // line_looked() {

    //     this.props.update_line_looked()
    //     // this.togglemailtaiou()
    // }

    handleScroll = throttle((event) => {
        console.log(this.node.scrollTop)

        // console.log(this.refs[this.state.top])

        if (this.node.scrollTop === 0) {

            // alert(this.state.take)
            let token = authentication.getAccessToken();
            fetch(process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/list/` + this.props.line_user.line_user_id + '/' + this.props.line_user.line_account_section_id + '/' + (this.state.take + 5), { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
            //fetch(`http://localhost:5000/v1/lines/list/` + this.props.line_user.line_user_id + '/' + this.props.line_user.line_account_section_id + '/' + (this.state.take + 5), { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
                .then(response => response.json())
                .then(data => {

                    console.log("かえってきたよ")
                    console.log(data)
                    this.setState({ lines: data })


                    // console.log(data); // `data.json()` の呼び出しで解釈された JSON データ
                });
            // this.props.get_lines_all({ line_user_id: this.props.line_user.id, line_account_section_id: this.props.line_user.line_account_section_id, take: this.state.take + 5 })
            // this.props.get_line_all({ line_user_id: this.props.line_user.line_user_id, line_account_section_id: this.props.line_user.line_account_section_id, take: this.state.take + 5 })

            this.setState({ take: this.state.take + 5 })
            this.node.scrollTop = 50;
            //     let ref = firebaseDb.child('line/all').orderByChild('id').startAt(this.state.old_id + 1).limitToFirst(1);

            //     ref.off()
            //     ref.on('child_added',
            //         (snapshot) => {

            //             this.Print(snapshot);

            //             this.node.scrollTop = 30;

            //         })
        }
        // this.setState({
        //     scrollTop: this.node.scrollTop
        //  })

    }, 100)

    render() {


        return (
            <div className={this.props.line_user.unfollowed_at ? "item-line2" : "item-line"} id="item-line" onScroll={this.handleScroll} ref={(node) => this.node = node} style={{ height: this.props.line_open_flg ? "calc(100% - 60px)" : "500px" }}>

                <div>
                    {this.props.line_looked_modal ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "calc(100% - 17px)", height: "calc(100% - 10px)", marginLeft: -15, marginTop: -30 }}>

                        <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "300px", padding: "20px", background: "#FFF" }}>
                            LINEを既読にしますか？

                            <div>
                                <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.props.update_line_looked} >既読にする</Button>
                                <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.props.change_line_looked_modal(false)}>キャンセル</Button>
                            </div>
                        </div>

                    </div> : ""
                    }

                    {this.state.lines.length > 0 ? <LineDispUser contents={this.state.lines} /> : ""}
                    {/* {this.props.line_user.id}
                    aae
                    {this.props.lines_all[0] ? this.props.lines_all[0].id : ""} */}
                    <div style={{ float: "left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>

                    <div><div style={{ position: "absolute", bottom: "10px", right: "10px" }}>{this.props.responsedetail.receive_line_at ? <Fab variant="extended" size="small" style={{ marginRight: "10px" }} onClick={() => this.props.change_line_looked_modal(true)}>
                        <VisibilityIcon color="primary" style={{ fontSize: "20px" }} />
                    </Fab> : ""} <Fab variant="extended" size="small" style={{ marginRight: "10px" }} onClick={this.lineopen}>
                            <i class="fab fa-line" style={{ fontSize: "20px", color: "green" }}></i>

                        </Fab></div>
                        <div style={{ position: "absolute", bottom: "10px", left: "10px" }}>
                            <Tooltip title="LINE解除"><Fab variant="extended" size="small" style={{ marginRight: "10px" }} onClick={this.togglesheet}>
                                <CancelIcon color="primary" style={{ fontSize: "20px" }} /></Fab></Tooltip>
                            
                                {(() => {

                                    if(this.props.responsedetail.section_id != this.props.line_user.line_account_section_id){
                                        const shop = this.props.line_user.line_account_section_id === 1 ? <span style={{fontSize:11}} className="badge badge-success">番</span>
                                        : this.props.line_user.line_account_section_id === 2 ? <span style={{fontSize:11}} className="badge badge-warning">レ</span>
                                          : this.props.line_user.line_account_section_id === 3 ? <span style={{fontSize:11}} className="badge badge-danger">駅</span>
                                            : this.props.line_user.line_account_section_id === 4 ? <span style={{fontSize:11}} className="badge badge-dark">東</span>
                                              : this.props.line_user.line_account_section_id === 6 ? <span style={{fontSize:11}} className="badge badge-light">公</span>
                                                : this.props.line_user.line_account_section_id === 17 ? <span style={{fontSize:11}} className="badge badge-info">サ</span>
                                                  : this.props.line_user.line_account_section_id ? <span style={{fontSize:11}} className="badge badge-secondary">通</span>
                                                    : ""

                                        return <Fab variant="extended" size="small" style={{ marginRight: "10px" }}>{shop}</Fab>
                                    }

                                })()}


                               

                        </div>
                    </div>

                    <Modal isOpen={this.state.modalsheet} toggle={this.togglesheet} fade={false}>

                        <ModalBody>
                            <div style={{ fontWeight: "bold" }}>LINEの結び付けを解除しますが、よろしいですか？</div>
                            <div style={{ marginTop: 10 }}>※解除すると、反響登録されていないLINEアカウントとして追客リストに表示されます。</div>
                        </ModalBody>
                        <ModalFooter>
                            <MuiButton variant="contained" color="secondary" onClick={() => {this.props.delete_line_link({ line_user_id: this.props.line_user.line_user_id, line_account_section_id: this.props.line_user.line_account_section_id });this.togglesheet()}}>はい</MuiButton>
                            <MuiButton variant="contained" style={{ marginLeft: 3 }} onClick={this.togglesheet}>いいえ</MuiButton>
                        </ModalFooter>

                    </Modal>

                </div>
            </div >
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {

        responsedetail: state.responsesReducer.responsedetail,
        lines: state.line.lines,
        line_bottom: state.line.line_bottom,
        line_user_details: state.line.line_user_details,
        line_open_flg: state.line.line_open_flg,
        line_send_modal: state.line.line_send_modal,
        line_send_file_loading: state.line.line_send_file_loading,
        line_send_list_loading: state.line.line_send_list_loading,
        line_looked_modal: state.line.line_looked_modal,
        line_all: state.line.line_all,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        send_message(state) {
            dispatch(Line.send_message(state))
        },
        // get_line_all(state) {
        //     dispatch(Line.get_line_all(state))
        // },
        get_lines_success(state) {
            dispatch(Line.get_lines_success(state))
        },
        change_line_bottom(state) {
            dispatch(Line.change_line_bottom(state))
        },
        get_line_close(state) {
            dispatch(Line.get_line_close(state))
        },
        get_line_open(state) {
            dispatch(Line.get_line_open(state))
        },
        get_line_user_details(state) {
            dispatch(Line.get_line_user_details(state))
        },
        send_line_stock_rooms(state) {
            dispatch(Line.send_line_stock_rooms(state))
        },
        change_line_file_data(state) {
            dispatch(Line.change_line_file_data(state))
        },
        upload_line_file(state) {
            dispatch(Line.upload_line_file(state))
        },
        change_line_send_modal(state) {
            dispatch(Line.change_line_send_modal(state))
        },
        change_line_looked_modal(state) {
            dispatch(Line.change_line_looked_modal(state))
        },
        update_line_looked(state) {
            dispatch(Line.update_line_looked(state))
        },
        get_fire_line_detail(state) {
            dispatch(Firebase.get_fire_line_detail(state))
        },
        // get_fire_line_detail_all(state) {
        //     dispatch(Firebase.get_fire_line_detail_all(state))
        // },

        delete_line_link(state) {
            dispatch(Line.delete_line_link(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Lines);
