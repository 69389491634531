import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Mail from './Saga/Mail';
import Loading from '../Layout/LoadingComponent';
class MailDetailFilesComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // date: "",
            // subject: "",
            // from: "",
            // contents: "",
        };
        
       
    }

    componentWillMount() {
        this.props.get_mail_detail_files(this.props.mailid)
     
    }

    onClick = (id,name) => { 
console.log(name)
        // let mailfile: { [key: string]: any; } = {};
       var  mailfile={};
        mailfile.id=id;
        mailfile.name=name;
        this.props.get_mail_file(mailfile)
    
    }

    render() {

        return (
            <div>
            {this.props.get_mail_detail_files_loading===1? <Loading /> :<div style={{ borderBottom: (this.props.mail_detail_files.length > 0) ? '1px solid #dcdcdc' : 'none', padding: (this.props.mail_detail_files.length > 0) ? 15 : 0 }}>
                {
                    this.props.mail_detail_files.length > 0?this.props.mail_detail_files.map(function (item, i) {
                        return (
                            <div onClick={ () =>this.onClick(item.id,item.name)} > {item.name}</div>     
                        );

                    },this): ""
                }

           
            </div>}
            </div>
        );
    }
};


//export  default CustomerList;
const mapStateToProps = state => {
    return {

        mail_detail_files:state.mailReducer.mail_detail_files,
        mail_file:state.mailReducer.mail_file,
        get_mail_detail_files_loading:state.mailReducer.get_mail_detail_files_loading,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
 
        get_mail_detail_files(state) {
           dispatch(Mail.get_mail_detail_files(state))
        },
        get_mail_file(state) {
            dispatch(Mail.get_mail_file(state))
         },
        
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailDetailFilesComponent);




