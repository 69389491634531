import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Contracts from './Saga/Contracts';
import CarEreaEdit from './CarEreaEdit';
import SelectM from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';
import "react-datepicker/dist/react-datepicker.css";
import './Css/Contracts.css';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
var moment = require('moment-timezone');
// const selectStyles = {
//   menu: base => ({
//     ...base,
//     zIndex: 0
//   })
// };


// const RenderSelectInput = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="店舗を選択"
//   />
// )

// const RenderSelectUsers = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="追客担当を選択"
//   />
// )

let timer

class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,
      reflash: false,
      ownshop_operation: true,
      ownshop_contract: true,
      focus_pet: false,

      parking_area_select_disp: false,
    };


    // this.props.change_contract_follow_list_form(formData);
    this.toggle = this.toggle.bind(this);
    this.togglecalender = this.togglecalender.bind(this);

    // this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.change_operation_user_id = this.change_operation_user_id.bind(this);
    this.change_contract_user_id = this.change_contract_user_id.bind(this);


    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.sort = this.sort.bind(this);
    this.toggle_select_comp = this.toggle_select_comp.bind(this);
    //  this.handlesectionIdChange = this.handlesectionIdChange.bind(this);
    // this.props.get_intend_list();
    // this.props.initialize({sectionId:this.props.masterReducer.user_details.amSectionId>0?this.props.masterReducer.user_details.amSectionId:0});//フォームに初期値を反映させる
    this.save = this.save.bind(this);
    this.saved = this.saved.bind(this);


    this.change_ownshop_contract = this.change_ownshop_contract.bind(this);

    this.change_contract_date = this.change_contract_date.bind(this);
    this.change_application_date = this.change_application_date.bind(this);
    this.handle_search_tatemono = this.handle_search_tatemono.bind(this);
    // this.change_tatemono = this.change_tatemono.bind(this);
    this.focus_pet = this.focus_pet.bind(this);
    this.blur_pet = this.blur_pet.bind(this);
    this.setpet = this.setpet.bind(this);
    this.change_parking_area = this.change_parking_area.bind(this);
    this.parking_area_select_close = this.parking_area_select_close.bind(this);
    this.handleManageCompanyChange = this.handleManageCompanyChange.bind(this);
    this.handleTatemonoOpen = this.handleTatemonoOpen.bind(this);

  }


  // change_tatemono(e, value) {
  //   // console.log(value)
  //   let rent_contract_editor_forms = this.props.rent_contract_editor_forms

  //   if (value) {

  //     rent_contract_editor_forms.tatemono_id = value.value
  //     rent_contract_editor_forms.tatemono_name = value.label
  //     rent_contract_editor_forms.tatemono_kana = this.props.tm_tatemono_list.filter(a => a.tatemono_id === value.value)[0] ? this.props.tm_tatemono_list.filter(a => a.tatemono_id === value.value)[0].tatemono_kana : ""

  //   } else {
  //     rent_contract_editor_forms.tatemono_id = 0
  //     rent_contract_editor_forms.tatemono_name = ''
  //     rent_contract_editor_forms.tatemono_kana = ''
  //   }
  //   this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
  // }
  change_ownshop_contract() {

    let contract_follow_list_form = this.props.contract_follow_list_form
    if (!this.state.ownshop_contract && this.props.users.filter(a => a.amSectionId === this.props.contract_follow_list_form.section_id && a.user_id === contract_follow_list_form.contract_user_id)[0]) {

    } else if (!this.state.ownshop_contract) {

      contract_follow_list_form.contract_user_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }


    this.setState({ ownshop_contract: !this.state.ownshop_contract })
  }
  // handleChangeSectionId(e, newValue) {


  //   let contract_follow_list_form = this.props.contract_follow_list_form
  //   contract_follow_list_form.section_id = newValue ? newValue.value : 0
  //   this.props.change_contract_follow_list_form(contract_follow_list_form);
  //   this.props.get_contract_follow_list()
  // }


  handleChange = (event) => {
    this.props.change_refresh()
    // alert(event.target.name)
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    if (event.target.name === "registration_tatemono") {
      rent_contract_editor_forms.registration_tatemono = !rent_contract_editor_forms.registration_tatemono;
      rent_contract_editor_forms.tatemono_id = 0
      rent_contract_editor_forms.tatemono_name = ''
      rent_contract_editor_forms.room_id = 0
      rent_contract_editor_forms.room_name = ''
      this.props.change_tm_room_list([])
    } else if(event.target.name === "is_important_contract") {//重要契約
      rent_contract_editor_forms[event.target.name] = event.target.checked
    } else {
      rent_contract_editor_forms[event.target.name] = event.target.value;
    }

    if (event.target.name === "room_id") {
      // alert(event.target.value)
      rent_contract_editor_forms.room_id = event.target.value
      rent_contract_editor_forms.management_company = this.props.tm_room_list.filter(a => a.room_id == event.target.value)[0] ? this.props.tm_room_list.filter(a => a.room_id == event.target.value)[0].kanri_corp_no : ""
      let kanri_kubun_no = this.props.tm_room_list.filter(a => a.room_id == event.target.value)[0] ? this.props.tm_room_list.filter(a => a.room_id == event.target.value)[0].kanri_kubun_no : ""
      let division = kanri_kubun_no == 1 ? 2 : kanri_kubun_no == 2 ? 3 : kanri_kubun_no == 3 ? 6 : kanri_kubun_no == 4 ? 1 : kanri_kubun_no == 6 ? 4 : ""
      rent_contract_editor_forms.division = division
      rent_contract_editor_forms.room_name = this.props.tm_room_list.filter(a => a.room_id == event.target.value)[0] ? this.props.tm_room_list.filter(a => a.room_id == event.target.value)[0].room_name : ""
    }

    // this.props.change_rent_contract_editor_forms({});
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }


  handleSelectChange = (event, value) => {
    this.props.change_refresh()
    // console.log(event.target)
    // alert(value.name)
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    if (value && value.name) {
      rent_contract_editor_forms[value.name] = value.value;
      if (value.name === "user_id") {
        rent_contract_editor_forms.section_id = value.section_id;
      }
      if (value.name === "section_id") {
        rent_contract_editor_forms.user_id = 0;
      }

      if (value.name === "operation_user_id") {
        rent_contract_editor_forms.operation_section_id = value.section_id;
      }

      if (value.name === "operation_section_id") {
        rent_contract_editor_forms.operation_user_id = 0;
      }

      if (value.name === "tatemono_id") {

        rent_contract_editor_forms.tatemono_id = value.value
        rent_contract_editor_forms.tatemono_name = value.label
        if (rent_contract_editor_forms.counting == -1 || rent_contract_editor_forms.counting == 2) {
          rent_contract_editor_forms.room_id = null;
          rent_contract_editor_forms.room_id2 = null;
          rent_contract_editor_forms.room_id3 = null;
          rent_contract_editor_forms.room_name = null;
          rent_contract_editor_forms.room_name2 = null;
          rent_contract_editor_forms.room_name3 = null;

          rent_contract_editor_forms.tatemono_id = value.value
        } else {
          rent_contract_editor_forms.room_id = -1;
          rent_contract_editor_forms.room_name = '';
        }

        this.props.get_tm_room_list(value.value)

      }


      // this.props.change_rent_contract_editor_forms({});
      this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);

      this.setState({ reflash: !this.state.reflash });
    }
  }

  handleManageCompanyChange = (event, value) => {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.management_company = value.value;
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);


  }
  setpet = (value) => {

    // alert(event.target.name)
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    rent_contract_editor_forms.pet_type = value

    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);

    this.setState({ reflash: !this.state.reflash });
  }



  handle_search_tatemono(e) {

    if (e.target.value) {
      let name = e.target.value
      clearTimeout(timer);
      timer = setTimeout(() => {

        this.props.get_tm_tatemono_list(name)
      }, 400);
    }
    // }
    // this.setState({ reflash: !this.state.reflash });

  }


  focus_pet(e) {

    this.setState({ focus_pet: true })
  }
  blur_pet(e) {

    setTimeout(() => {
      this.setState({ focus_pet: false })
    }, 200)

  }

  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")

    // this.props.get_contract_follow_list({ section_id: this.props.user_details.amSectionId })
  }
  toggle() {
    //this.setState(prevState => ({
    //    modal: !prevState.modal
    //}));
    this.props.customerNewDispSet(0)
    // let matching = {}


    // this.props.change_matching(matching)
    this.props.history.push('/CustomerNew');

  }


  togglecalender() {
    this.setState(prevState => ({
      modalcalender: !prevState.modalcalender
    }));
  }
  saveSelectValue = (e, id) => {
    let data = {}
    data.id = id;
    data.value = e.target.value

    this.props.saveSelectValue(data)
  }

  // handleSectionIdChange(sectionId) {
  //   alert()
  //   let formData =   this.state.formData
  //   formData.sectionId=sectionId
  //     this.setState(formData);

  //   }
  change_operation_user_id(e, newValue) {


    if (newValue) {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.operation_user_id = newValue ? newValue.value : 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    } else {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.operation_user_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }
    this.setState({ reflesh: !this.props.reflesh })


  };
  change_contract_user_id(e, newValue) {
    if (newValue) {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.contract_user_id = newValue ? newValue.value : 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    } else {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.contract_user_id = 0;
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }
  };
  change_contract_date = date => {
    this.props.change_refresh()
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.contract_date = date
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    this.setState({ reflesh: !this.props.reflesh })
  };

  change_application_date = date => {
    this.props.change_refresh()
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.application_date = date
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    this.setState({ reflesh: !this.props.reflesh })
  };

  sort(sort) {
    let formData = this.props.responselist.formData
    formData.sort = sort.target.value
    this.props.change_formData(formData);
    this.props.search_response_list()

  }

  toggle_select_comp() {
    this.setState({ modal_select_comp: !this.state.modal_select_comp })
  }


  save(e) {
    this.props.update_rent_m_contract_follow_settings()
    this.setState({ anchorEl: e.currentTarget });


  }
  saved(e) {

    this.setState({ anchorEl: null });
    this.props.change_rent_m_contract_follow_settings_save(false)
  }

  change_parking_area() {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    rent_contract_editor_forms["room_id"] = null
    rent_contract_editor_forms["room_name"] = null
    rent_contract_editor_forms["room_id2"] = null
    rent_contract_editor_forms["room_name2"] = null
    rent_contract_editor_forms["room_id3"] = null
    rent_contract_editor_forms["room_name3"] = null

    this.props.tm_parkingarea_list.filter(a => a.selectcontract == true).map(function (value, key) {
      if (key == 0) {
        rent_contract_editor_forms["room_id"] = value.pa_id
        rent_contract_editor_forms["room_name"] = value.pa_name
      } else {
        rent_contract_editor_forms["room_id" + (key + 1)] = value.pa_id
        rent_contract_editor_forms["room_name" + (key + 1)] = value.pa_name
      }
    })


    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    let select = {}
    let tm_parkingarea_list = this.props.tm_parkingarea_list.filter(a => a.selectcontract == true)
    console.log(tm_parkingarea_list.length)
    if (tm_parkingarea_list.length >= 1) {
      console.log(tm_parkingarea_list)
      console.log(this.props.tm_parkingarea_list, "????")
      console.log(this.props.rent_contract_details, "でーた")
      select["rent_contract_id"] = this.props.rent_contract_details.id
      select["P_Id"] = this.props.rent_contract_details.tatemono_id
      select["PA_Id"] = rent_contract_editor_forms["room_id"]
      select["name"] = this.props.rent_contract_details.name1
      select["car_color"] = tm_parkingarea_list[0].color
      select["car_name"] = tm_parkingarea_list[0].syasyu
      select["car_no"] = tm_parkingarea_list[0].bango
      select["remark"] = tm_parkingarea_list[0].remark
      select["users"] = this.props.user_details.id
      select["maker"] = tm_parkingarea_list[0].maker
      console.log(select, "確認")
      this.props.change_tm_parkingarea_select_section(select);
    } else {
      select["rent_contract_id"] = this.props.rent_contract_details.id
      select["P_Id"] = this.props.rent_contract_details.tatemono_id
      select["PA_Id"] = rent_contract_editor_forms["room_id"]
      select["name"] = this.props.rent_contract_details.name1
      this.props.change_tm_parkingarea_select_section(select);
    }
    this.setState({ reflesh: !this.props.reflesh })

    this.parking_area_select_close()

  }

  parking_area_select_close() {
    this.props.change_tm_parkingarea_list([])
    this.setState({ parking_area_select_disp: false })
  }

  handleTatemonoOpen(tatemono_id, room_id) {
    if (room_id == -1) {
      window.open(`https://www.homestation.jp/manage/building/detail/index?t_id=` + tatemono_id, '_blank', 'noopener')
    } else {
      window.open(`https://www.homestation.jp/manage/room/detail/index?t_id=` + tatemono_id + `&r_id=` + room_id, '_blank', 'noopener')
    }
  }

  render() {
    return (
      <div id="contractfollow">
        <Grid container>
          <Grid
            // justify="space-between" // Add it here :)
            // item
            xs={4}
          >

            <TextField
              fullWidth
              id="contract_date"
              label="契約日"
              type="date"
              name="contract_date"
              size="small"
              // style={{marginLeft:20}}
              value={moment(this.props.rent_contract_editor_forms.contract_date).format("YYYY-MM-DD")}
              InputLabelProps={{
                  shrink: true,
              }}
              variant="standard"
              onChange={this.handleChange}
            />

            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-inline1"
                label="契約日"
                size="small"
                //   showTodayButton={true}
                // style={{ marginTop: "0" }}
                autoOk={true}
                InputLabelProps={{ shrink: this.props.rent_contract_editor_forms.contract_date }}
                // error={true}
                // helperText="契約日を選択してください"
                //   errorMessages={['契約日を選択してください']}
                name="contract_date"
                value={this.props.rent_contract_editor_forms.contract_date}
                // value={new Date()}
                // value={this.props.estimates.contractDate}
                onChange={this.change_contract_date}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                //   minDateMessage="契約日を選択してください"
                // minDate={new Date("2000-01-01")}
                invalidDateMessage={"日付の形式が違います"}
              />
            </MuiPickersUtilsProvider> */}
          </Grid>
          <Grid
            // justify="space-between" // Add it here :)
            // item
            xs={4}
          >

            <TextField
              fullWidth
              id="application_date"
              label="申込日"
              type="date"
              name="application_date"
              size="small"
              style={{marginLeft:20}}
              value={moment(this.props.rent_contract_editor_forms.application_date).format("YYYY-MM-DD")}
              InputLabelProps={{
                  shrink: true,
              }}
              variant="standard"
              onChange={this.handleChange}
              disabled={moment(this.props.rent_contract_editor_forms.application_date).isBefore(moment().format('YYYY-MM-1'))}
            />
            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-inline1"
                label="申込日"
                size="small"
                //   showTodayButton={true}
                style={{ marginTop: "0" }}
                autoOk={true}
                InputLabelProps={{ shrink: this.props.rent_contract_editor_forms.application_date }}
                // error={true}
                // helperText="契約日を選択してください"
                //   errorMessages={['契約日を選択してください']}
                name="application_date"
                value={this.props.rent_contract_editor_forms.application_date}


                disabled={moment(this.props.rent_contract_editor_forms.application_date).isBefore(moment().format('YYYY-MM-1'))}
                // value={new Date()}
                // value={this.props.estimates.contractDate}
                onChange={this.change_application_date}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                //   minDateMessage="契約日を選択してください"
                // minDate={new Date("2000-01-01")}
                invalidDateMessage={"日付の形式が違います"}
              />
            </MuiPickersUtilsProvider> */}
          </Grid>
          <Grid xs={4}>
            <FormControlLabel
              control={
                <Checkbox
                  name="is_important_contract"
                  checked={this.props.rent_contract_editor_forms.is_important_contract}
                  onChange={this.handleChange}
                  value={this.props.rent_contract_editor_forms.is_important_contract}
                  color="primary"
                />
              }
              label="重要契約"
            />
          </Grid>




          {
            !this.props.rent_contract_editor_forms.registration_tatemono ? <Grid xs={12} style={{ padding: 5 }}>

              <Autocomplete
                id="tatemono_id"
                // options={this.props.users_select}
                options={this.props.tm_tatemono_list.length === 0 && this.props.rent_contract_editor_forms.tatemono_id && this.props.rent_contract_editor_forms.tatemono_name ? [{ value: this.props.rent_contract_editor_forms.tatemono_id, label: this.props.rent_contract_editor_forms.tatemono_name, name: "tatemono_id" }]
                  : this.props.tm_tatemono_list.length === 0 && (!this.props.rent_contract_editor_forms.tatemono_id || !this.props.rent_contract_editor_forms.tatemono_name) ? [{ value: 0, label: '', name: "tatemono_id" }]
                    : this.props.tm_tatemono_list.map((value) => ({
                      value: value.tatemono_id,
                      label: value.tatemono_name ? value.tatemono_name : "",
                      name: "tatemono_id"
                    }))}
                getOptionLabel={(option) => option.label ? option.label : ''}
                // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
                // value={this.props.rent_customer_editor_froms.name1}
                style={{ width: "100%", marginTop: 20 }}
                name="tatemono_id"
                loading={this.props.tm_tatemono_list_loading}
                defaultValue={this.props.rent_contract_editor_forms.tatemono_id ? { value: this.props.rent_contract_editor_forms.tatemono_id, label: this.props.rent_contract_editor_forms.tatemono_name } : {}}
                // open={false}
                // value={this.props.contract_follow_list_form.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.contract_user_id)[0] : null}
                onChange={this.handleSelectChange}
                renderInput={(params) => <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>

                        {this.props.tm_tatemono_list_loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  onChange={this.handle_search_tatemono}
                  label={"物件名:" + (this.props.rent_contract_editor_forms.tatemono_kana ? this.props.rent_contract_editor_forms.tatemono_kana : "")} />}

              />



            </Grid> : ""}


          {
            !this.props.rent_contract_editor_forms.registration_tatemono && this.props.rent_contract_editor_forms.counting != -1 && this.props.rent_contract_editor_forms.counting != 2 ? <Grid xs={4} style={{ padding: 5 }} >

              <FormControl variant="outlined" style={{}} >
                <InputLabel id="demo-simple-select-outlined-label" shrink>部屋名</InputLabel>
                <SelectM
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 120 }}
                  name="room_id"
                  // defaultValue={this.props.rent_contract_editor_forms.room_id ? { value: this.props.rent_contract_editor_forms.room_id, label: this.props.rent_contract_editor_forms.room_name } : {}}
                  value={this.props.rent_contract_editor_forms.room_id}
                  onChange={this.handleChange}
                  label="部屋名"
                  size="small"
                  margin='dense'
                  notched
                >
                  <MenuItem value="-1">
                    -
                  </MenuItem>
                  {this.props.tm_room_list.length === 0 && this.props.rent_contract_editor_forms.room_id ?
                    <MenuItem value={this.props.rent_contract_editor_forms.room_id} > {this.props.rent_contract_editor_forms.room_name}</MenuItem>
                    : this.props.tm_room_list.map(function (value) {
                      return <MenuItem value={value.room_id} > {value.room_name}</MenuItem>
                    })}

                </SelectM>
              </FormControl>
              {this.props.rent_contract_editor_forms.tatemono_id && this.props.rent_contract_editor_forms.room_id ? <IconButton
                style={{ padding: 5 }}
                onClick={() => this.handleTatemonoOpen(this.props.rent_contract_editor_forms.tatemono_id, this.props.rent_contract_editor_forms.room_id)}
                size="large"><OpenInNewIcon /></IconButton> : ""}


            </Grid> : ""}


          {
            this.props.rent_contract_editor_forms.registration_tatemono ? <Grid xs={12} style={{ padding: 5 }}>
              <FormControl style={{ marginTop: 25, width: "100%" }} >
                <TextField

                  //   className={classes.textField}
                  label="建物名"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  name="tatemono_name"
                  value={this.props.rent_contract_editor_forms.tatemono_name}
                  style={{ float: "left", width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                /> </FormControl>
            </Grid> : ""}
          {
            this.props.rent_contract_editor_forms.registration_tatemono && this.props.rent_contract_editor_forms.counting != -1 && this.props.rent_contract_editor_forms.counting != 2 ? <Grid xs={6} style={{ padding: 5 }}>
              <FormControl style={{ marginTop: 25 }} >
                <TextField

                  //   className={classes.textField}
                  label="部屋名"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  name="room_name"
                  value={this.props.rent_contract_editor_forms.room_name}
                  style={{ float: "left", width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                /></FormControl>


            </Grid> : ""}
          <Grid xs={8} style={{ padding: 5 }}>
            物件の登録がない場合はWEB課へ登録依頼してください。
            {/* {this.props.rent_contract_editor_forms.division === 4 || this.props.rent_contract_editor_forms.division === 5 ? <FormControlLabel
                style={{ marginLeft: 5, marginTop: 20 }}

                control={
                  <Checkbox

                    name="registration_tatemono"
                    checked={this.props.rent_contract_editor_forms.registration_tatemono ? true : false}
                    onChange={this.handleChange}
                    value="1"
                    color="primary"
                  />
                }
                label="未登録"
              /> : ""} */}
          </Grid>


          {
            !this.props.rent_contract_editor_forms.registration_tatemono && this.props.rent_contract_editor_forms.counting == -1 || this.props.rent_contract_editor_forms.counting == 2 ? <Grid xs={12} style={{ padding: 5 }} >

              {this.props.rent_contract_editor_forms.room_id ? <TextField id="outlined-basic" label="区画1" variant="outlined" disabled value={this.props.rent_contract_editor_forms.room_name} style={{ width: 90, marginRight: 5 }} /> : ""}
              {this.props.rent_contract_editor_forms.room_id2 ? <TextField id="outlined-basic" label="区画2" variant="outlined" disabled value={this.props.rent_contract_editor_forms.room_name2} style={{ width: 90, marginRight: 5 }} /> : ""}
              {this.props.rent_contract_editor_forms.room_id3 ? <TextField id="outlined-basic" label="区画3" variant="outlined" disabled value={this.props.rent_contract_editor_forms.room_name3} style={{ width: 90, marginRight: 5 }} /> : ""}
              {/* {this.props.tm_parkingarea_list ? this.props.tm_parkingarea_list.filter(a => a.selectcontract == true).map(function(value, key){
                  return <TextField id="outlined-basic" label="区画名" variant="outlined" disabled value={value.pa_name} style={{width:90, marginRight:5}} />
                }):""} */}

              <Button style={{ marginTop: 20, marginLeft: 5 }} variant="outlined" color="primary" onClick={() => this.setState({ "parking_area_select_disp": true })}>区画選択</Button>



            </Grid> : ""}


          <Modal isOpen={this.state.parking_area_select_disp} size="xxl" fade={false} >

            <ModalHeader toggle={() => this.setState({ "parking_area_select_disp": !this.state.parking_area_select_disp })}> 駐車場登録</ModalHeader>

            <ModalBody>

              <Card style={{ margin: 5 }}>
                <CardContent>

                  <CarEreaEdit />

                </CardContent>
              </Card >

            </ModalBody>

            <ModalFooter>
              <Button variant="contained" onClick={this.change_parking_area} color="primary" style={{ float: "right", margin: 5 }}>
                選択
              </Button>
              <Button variant="contained" onClick={this.parking_area_select_close} color="secondary" style={{ float: "right", margin: 5 }}>
                閉じる
              </Button>

            </ModalFooter>


          </Modal >




          <Grid xs={4} style={{ marginTop: 10, padding: 5 }}>
            <FormControl variant="outlined" >
              <InputLabel id="demo-simple-select-outlined-label" shrink>管理区分</InputLabel>
              <SelectM
                notched
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ width: 120 }}
                name="division"
                value={this.props.rent_contract_editor_forms.division}
                onChange={this.handleChange}
                label="管理区分"
                size="small"
                margin='dense'
              >
                <MenuItem value={0}>
                  -
                </MenuItem>
                <MenuItem value={2}>管理</MenuItem>
                <MenuItem value={1}>サブ</MenuItem>
                <MenuItem value={3}>一般</MenuItem>
                <MenuItem value={6}>専任</MenuItem>
                <MenuItem value={4}>業物</MenuItem>
                <MenuItem value={5}>業物折半</MenuItem>
              </SelectM>
            </FormControl>
          </Grid>



          <Grid xs={8} style={{ marginTop: 5 }}>
            <FormControl style={{ width: "100%" }}>
              {<Autocomplete
                id="combo-box-demo"
                // options={this.props.users_select}
                options={this.props.tm_kanri_corp_list.map((value) => ({
                  value: value.kanri_corp_no,
                  label: value.kanri_corp_name
                }))}
                onChange={this.handleManageCompanyChange}
                getOptionLabel={(option) => option.label ? option.label : ''}
                // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                style={{ width: "100%" }}
                name="management_company"
                value={this.props.rent_contract_editor_forms.management_company ? this.props.tm_kanri_corp_list.filter(a => a.kanri_corp_no === this.props.rent_contract_editor_forms.management_company).map((value) => ({
                  value: value.kanri_corp_no,
                  label: value.kanri_corp_name
                }))[0] : {}}
                // value={this.props.contract_follow_list_form.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.contract_user_id)[0] : null}

                renderInput={(params) => <TextField
                  variant="filled"
                  size="small"
                  margin='dense'
                  {...params} label="管理会社" />}

              />}
            </FormControl>
          </Grid>

          <Grid xs={12} style={{ marginTop: 15, padding: 5 }}>
            <TextField
              id="outlined-basic"
              //   className={classes.textField}
              label={'契約者:' + this.props.rent_contract_editor_forms.kana1}
              margin="normal"
              variant="filled"
              disabled
              onChange={this.handleChange}
              name="name1"
              value={this.props.rent_contract_details.name1}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>

          {this.props.rent_contract_editor_forms.contract_form === 1 ? <Grid xs={12} style={{ marginTop: 0, padding: 5 }}>
            <TextField
              id="outlined-basic"
              //   className={classes.textField}
              label={'入居者:' + this.props.rent_contract_editor_forms.kana2}
              margin="normal"
              variant="filled"
              disabled
              onChange={this.handleChange}
              name="name2"
              value={this.props.rent_contract_editor_forms.name2}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid> : ""}


          <Grid xs={6} style={{ padding: "0px 5px" }}>
            <FormControl >

              {this.props.section_select.length > 0 && this.props.user_details ? <Autocomplete
                id="combo-box-demo"
                options={this.props.section_select.map((value) => ({
                  value: value.value,
                  label: value.label,
                  name: 'section_id',
                }))}
                getOptionLabel={(option) => option.label ? option.label : ''}
                value={this.props.rent_contract_editor_forms.section_id ? this.props.section_select.filter(a => a.value === this.props.rent_contract_editor_forms.section_id)[0] : {}}
                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                style={{ width: 180 }}
                onChange={this.handleSelectChange}
                name="section_id"
                size="small"
                renderInput={(params) => <TextField
                  variant="filled"
                  
                  margin='dense'
                  {...params} label="店舗を選択" />}

              /> : ""}
            </FormControl>

          </Grid>


          <Grid xs={6} style={{ padding: "0px 5px" }}>



            <FormControl style={{ marginLeft: "10px", width: "160px" }}>
              {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
                id="combo-box-demo"
                options={this.props.rent_contract_editor_forms.section_id ? this.props.users.filter(a => a.amSectionId === this.props.rent_contract_editor_forms.section_id).map((value) => ({
                  value: value.id,
                  label: value.userNameAll,
                  name: 'user_id',
                  section_id: value.amSectionId,
                })) : this.props.users.map((value) => ({
                  value: value.id,
                  label: value.userNameAll,
                  name: 'user_id',
                  section_id: value.amSectionId,
                }))}
                getOptionLabel={(option) => option.label ? option.label : ''}
                value={this.props.rent_contract_editor_forms.user_id ? this.props.users.map((value) => ({
                  value: value.id,
                  label: value.userNameAll,
                  name: 'user_id',
                  section_id: value.amSectionId,
                })).filter(a => a.value === this.props.rent_contract_editor_forms.user_id)[0] : {}}


                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                style={{ width: 150 }}
                name="user_id"
                // value={this.props.rent_contract_editor_froms.user_id}
                // value={this.props.contract_follow_list_form.operation_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.operation_user_id)[0] : null}
                onChange={this.handleSelectChange}
                size="small"
                renderInput={(params) => <TextField
                  variant="outlined"
                  
                  margin='dense'
                  name="user_id"
                  {...params} label="担当を選択" />}

              /> : ""}
            </FormControl>
          </Grid>

          <Grid xs={6} style={{ padding: "0px 5px", }}>
            <FormControl >

              {this.props.section_select.length > 0 && this.props.user_details ? <Autocomplete
                id="combo-box-demo"
                options={this.props.section_select.map((value) => ({
                  value: value.value,
                  label: value.label,
                  name: 'operation_section_id',
                }))}
                getOptionLabel={(option) => option.label ? option.label : ''}
                name="operation_section_id"
                value={this.props.rent_contract_editor_forms.operation_section_id ? this.props.section_select.filter(a => a.value === this.props.rent_contract_editor_forms.operation_section_id)[0] : {}}
                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                style={{ width: 180 }}
                onChange={this.handleSelectChange}
                size="small"
                renderInput={(params) => <TextField
                  variant="filled"
                  margin='dense'
                  
                  {...params} label="店舗を選択" />}

              /> : ""}
            </FormControl>

          </Grid>


          <Grid xs={6} style={{ padding: "0px 5px" }}>
            <FormControl style={{ marginLeft: "10px", width: "160px" }}>
              {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
                id="combo-box-demo"
                options={this.props.rent_contract_editor_forms.operation_section_id ? this.props.users.filter(a => a.amSectionId === this.props.rent_contract_editor_forms.operation_section_id).map((value) => ({
                  value: value.id,
                  label: value.userNameAll,
                  name: 'operation_user_id',
                  section_id: value.amSectionId,
                })) : this.props.users.map((value) => ({
                  value: value.id,
                  label: value.userNameAll,
                  name: 'operation_user_id',
                  section_id: value.amSectionId,
                }))}
                getOptionLabel={(option) => option.label ? option.label : ''}
                // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                style={{ width: 150 }}
                name="operation_user_id"
                value={this.props.rent_contract_editor_forms.operation_user_id ? this.props.users.map((value) => ({
                  value: value.id,
                  label: value.userNameAll,
                  name: 'operation_user_id',
                  section_id: value.amSectionId,
                })).filter(a => a.value === this.props.rent_contract_editor_forms.operation_user_id)[0] : {}}
                // value={this.props.rent_contract_editor_froms.operation_user_id}
                // value={this.props.contract_follow_list_form.operation_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.operation_user_id)[0] : null}
                onChange={this.handleSelectChange}
                size="small"
                renderInput={(params) => <TextField
                  variant="outlined"
                  
                  margin='dense'
                  name="operation_user_id"
                  {...params} label="手続担当を選択" />}

              /> : ""}
            </FormControl>

          </Grid>





          <Grid xs={4} style={{ marginTop: 10, padding: 5 }}>
            <FormControl variant="outlined" >
              <InputLabel id="demo-simple-select-outlined-label">契約形態</InputLabel>
              <SelectM
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ width: 200 }}
                name="rent_m_contract_type_id"
                value={this.props.rent_contract_editor_forms.rent_m_contract_type_id}
                onChange={this.handleChange}
                label="契約形態"
                size="small"
                margin='dense'
              >

                {this.props.rent_m_contract_types.map(function (value) {
                  return <MenuItem value={value.id}>{value.name}</MenuItem>
                })}

              </SelectM>
            </FormControl>
          </Grid>
          <Grid xs={8} style={{ marginTop: 10, padding: 5 }}>
            <FormControl variant="outlined" >
              <InputLabel id="demo-simple-select-outlined-label">契約書タイプ</InputLabel>
              <SelectM
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ width: 200 }}
                name="contract_document_type"
                value={this.props.rent_contract_editor_forms.contract_document_type}
                onChange={this.handleChange}
                label="契約形態"
                size="small"
                margin='dense'
              >
                <MenuItem value={1}>2020年民法対応</MenuItem>
                <MenuItem value={2}>社宅統一契約書</MenuItem>
                <MenuItem value={0}>旧</MenuItem>



              </SelectM>
            </FormControl>

          </Grid>




          {/* <FormControl variant="outlined" >


              <Autocomplete
                id="combo-box-demo"
                options={this.props.rent_m_traders.map((value) => ({
                  value: value.id,
                  label: value.name

                }))}
                getOptionLabel={(option) => option.label}
                value={this.props.rent_contract_editor_forms.rent_m_contract_type_id}
                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                style={{ width: 180 }}
                onChange={this.handleSelectChange}
                renderInput={(params) => <TextField
                  variant="filled"
                  {...params} label="客付業者" />}

              />
            </FormControl> */}


          < Grid
            xs={4}
          >

            {/* <RadioGroup aria-label="gender" name="gender1" > */}
            < FormControlLabel value={0} name="traders" onChange={this.handleChange} checked={!parseInt(this.props.rent_contract_editor_forms.traders) ? true : false} control={< Radio />} label="自社付" />
            <FormControlLabel value={1} name="traders" onChange={this.handleChange} checked={parseInt(this.props.rent_contract_editor_forms.traders) ? true : false} control={<Radio />} label="他社付" />
            {/* </RadioGroup> */}
          </Grid >
          <Grid
            xs={4}
          >

            {parseInt(this.props.rent_contract_editor_forms.traders) ? <FormControl variant="outlined" >

              <Autocomplete
                id="combo-box-demo"
                options={this.props.rent_m_traders.map((value) => ({
                  value: value.id,
                  label: value.name,
                  name: "traders",
                }))}
                getOptionLabel={(option) => option.label ? option.label : ''}


                defaultValue={this.props.rent_m_traders.filter(a => a.id === this.props.rent_contract_editor_forms.traders).map((value) => ({
                  value: value.id,
                  label: value.name,
                  name: "traders",
                }))[0]}
                // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                style={{ width: 180 }}
                name="traders"
                onChange={this.handleSelectChange}
                renderInput={(params) => <TextField
                  variant="filled"
                  name="traders"
                  size="small"
                  margin='dense'
                  {...params} label="客付業者" />}

              />
            </FormControl> : ""}
          </Grid>
          <Grid
            xs={4}
          >
            {this.props.rent_contract_editor_forms.traders === 7 ? <TextField
              id="outlined-basic"
              variant="filled"
              //   className={classes.textField}
              label="客付業者その他"
              margin="normal"
              name="traders_etc"
              onChange={this.handleChange}

              value={this.props.rent_contract_editor_forms.traders_etc}
              style={{ float: "left", width: "100%", paddingRight: "5px" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            /> : ""}
          </Grid>



          <Grid
            xs={6}
          >

            {/* <RadioGroup aria-label="gender" name="gender1" > */}
            <FormControlLabel value={1} name="pets" onChange={this.handleChange} checked={parseInt(this.props.rent_contract_editor_forms.pets) ? true : false} control={<Radio />} label="ペットあり" />
            <FormControlLabel value={0} name="pets" onChange={this.handleChange} checked={!parseInt(this.props.rent_contract_editor_forms.pets) ? true : false} control={<Radio />} label="ペットなし" />
            {/* </RadioGroup> */}
          </Grid>
          <Grid
            xs={6}
          >

            {parseInt(this.props.rent_contract_editor_forms.pets) ? <span>ペット種類<input type="text" style={{ width: 140 }}
              name="pet_type"
              value={this.props.rent_contract_editor_forms.pet_type}
              onChange={this.handleChange} list="data" /></span> : ""}

            <datalist id="data">

              <option key={1} value="小型犬 1匹" />
              <option key={2} value="小型犬 2匹" />
              <option key={3} value="中型犬 1匹" />
              <option key={4} value="中型犬 2匹" />
              <option key={5} value="大型犬 1匹" />
              <option key={6} value="大型犬 2匹" />
              <option key={7} value="猫 1匹" />
              <option key={8} value="猫 2匹" />
            </datalist>
            {/* {parseInt(this.props.rent_contract_editor_forms.pets) ? <TextField
              label="ペット種類"
              style={{ width: 140 }}
              name="pet_type"
              onChange={this.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              // id="formatted-text-mask-input"
              onFocus={this.focus_pet}
              onBlur={this.blur_pet}
              // InputProps={{ inputComponent: TextMaskCustom }}
              value={this.props.rent_contract_editor_forms.pet_type}
            /> : ""}
            {this.state.focus_pet ? <Paper style={{
              width: 140, position: "absolute", marginLeft: 0, marginTop: 0, zIndex: 2,
              height: 90,
              overflow: "auto"
            }}>
              <MenuList >
                <MenuItem onClick={() => this.setpet('小型犬 1匹')}>
                  <Typography variant="inherit">小型犬 1匹</Typography>
                </MenuItem>
                <MenuItem onClick={() => this.setpet('小型犬 2匹')}>
                  <Typography variant="inherit">小型犬 2匹</Typography>
                </MenuItem>
                <MenuItem onClick={() => this.setpet('')}>
                  <Typography variant="inherit">中型犬 1匹</Typography>
                </MenuItem>
                <MenuItem onClick={() => this.setpet('')}>
                  <Typography variant="inherit">中型犬 2匹</Typography>
                </MenuItem>
                <MenuItem onClick={() => this.setpet('')}>
                  <Typography variant="inherit">大型犬 1匹</Typography>
                </MenuItem>
                <MenuItem onClick={() => this.setpet('')} >
                  <Typography variant="inherit">大型犬 2匹</Typography>
                </MenuItem>
                <MenuItem onClick={() => this.setpet('')} >
                  <Typography variant="inherit">猫 1匹</Typography>
                </MenuItem>
                <MenuItem onClick={() => this.setpet('')}>
                  <Typography variant="inherit">猫 2匹</Typography>
                </MenuItem>
              </MenuList>
            </Paper > : ""} */}

          </Grid>



          <Grid xs={4} style={{ marginTop: 20 }}>
            <FormControl variant="outlined" >
              <InputLabel id="demo-simple-select-outlined-label">連帯保証人</InputLabel>
              <SelectM
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ width: 120 }}
                name="cosigner"
                value={this.props.rent_contract_editor_forms.cosigner}

                onChange={this.handleChange}
                label="連帯保証人"
                size="small"
                margin='dense'
              >
                <MenuItem value={0}>無</MenuItem>
                <MenuItem value={1}>1人</MenuItem>
                <MenuItem value={2}>2人</MenuItem>
                <MenuItem value={3}>緊急連絡先</MenuItem>
              </SelectM>
            </FormControl>
          </Grid>
          <Grid xs={4} style={{ marginTop: 20, display: this.props.rent_contract_editor_forms.counting === 2 ? "none" : "" }} >
            <FormControl variant="outlined" >
              <InputLabel id="demo-simple-select-outlined-label">入居人数</InputLabel>
              <SelectM
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ width: 120 }}
                name="number_of_renters"
                value={this.props.rent_contract_editor_forms.number_of_renters}
                onChange={this.handleChange}
                label="入居人数"
                size="small"
                margin='dense'
              >
                <MenuItem value="-1">
                  -
                </MenuItem>
                <MenuItem value={1}>1人</MenuItem>
                <MenuItem value={2}>2人</MenuItem>
                <MenuItem value={3}>3人</MenuItem>
                <MenuItem value={4}>4人</MenuItem>
                <MenuItem value={5}>5人</MenuItem>
                <MenuItem value={6}>6人</MenuItem>
              </SelectM>
            </FormControl>
          </Grid>
          <Grid xs={4} style={{ marginTop: 20 }}>
            <FormControl variant="outlined" >
              <InputLabel id="demo-simple-select-outlined-label">駐車台数</InputLabel>
              <SelectM
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ width: 120 }}
                name="number_of_cars"
                value={this.props.rent_contract_editor_forms.number_of_cars}
                onChange={this.handleChange}
                label="駐車台数"
                size="small"
                margin='dense'
              >
                <MenuItem value="-1">
                  -
                </MenuItem>
                <MenuItem value={0}>無</MenuItem>
                <MenuItem value={1}>1台</MenuItem>
                <MenuItem value={2}>2台</MenuItem>
                <MenuItem value={3}>3台</MenuItem>
              </SelectM>
            </FormControl>
          </Grid>


        </Grid >



      </div >
    );
  }
}





const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    user_details: state.masterReducer.user_details,
    // csv_loading: state.Adjustment.csv_loading,
    // search_list: state.Adjustment.search_list,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
    // seisan_follow_search: state.Adjustment.seisan_follow_search,
    // tantou: state.Adjustment.tantou,
    // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
    // rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    rent_contract_details: state.contracts.rent_contract_details,
    tm_tatemono_list: state.contracts.tm_tatemono_list,
    tm_tatemono_list_loading: state.contracts.tm_tatemono_list_loading,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    tm_kanri_corp_list: state.contracts.tm_kanri_corp_list,
    tm_room_list: state.contracts.tm_room_list,
    rent_m_contract_types: state.contracts.rent_m_contract_types,
    rent_m_traders: state.contracts.rent_m_traders,
    tm_parkingarea_list: state.contracts.tm_parkingarea_list,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    get_tm_tatemono_list(state) {
      dispatch(Contracts.get_tm_tatemono_list(state))
    },
    change_rent_contract_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_editor_forms(state))
    },
    change_tm_parkingarea_select_section(state) {
      dispatch(Contracts.change_tm_parkingarea_select_section(state))
    },
    get_tm_room_list(state) {
      dispatch(Contracts.get_tm_room_list(state))
    },
    change_tm_room_list(state) {
      dispatch(Contracts.change_tm_room_list(state))
    },
    change_tm_parkingarea_list(state) {
      dispatch(Contracts.change_tm_parkingarea_list(state))
    },
    // change_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.change_rent_m_contract_follow_settings(state))
    // },
    // change_contract_follow_list_form(state) {
    //   dispatch(ContractFollow.change_contract_follow_list_form(state))
    // },
    // get_contract_follow_list(state) {
    //   dispatch(ContractFollow.get_contract_follow_list(state))
    // },
    // search_contract_follow_list(state) {
    //   dispatch(ContractFollow.search_contract_follow_list(state))
    // },
    // update_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.update_rent_m_contract_follow_settings(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);
