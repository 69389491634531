
const authentication = require('../../../react-azure-adb2c2').default;


// export const update = (values:any) => {

//     const token = authentication.getAccessToken();
//     const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     return fetch(url, {
//         method: 'put',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res)
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }






export const addpdffile = (values: any) => {


    let url = `https://homestationreportapi.azurewebsites.net/api/EstimateToMail/` + values.id + '/' + values.mailid;


    // let url = `https://localhost:44363/api/EstimateToMail/`+values.id+'/'+values.mailid;

    const token = authentication.getAccessToken();


    // alert(url)

    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));






};











// export const detail = (Id: number) => {

//             const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + Id;

//             const token = authentication.getAccessToken();

//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }



