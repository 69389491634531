import React, { Component } from 'react';
import { connect } from 'react-redux';
import Settings from './Settings';
// import RoomList from './RoomList';
// import Building from './Building';
import Map from './Map';
// import styled from 'styled-components'
import List from './List';
import Button from '@mui/material/Button';



import {
  change_showRight, change_showLeft,
  change_showFlag, change_map_disp, update_rent_introduction_point_templates,
} from './Saga/MapMypageTemplate';
import { add_mail_room } from '../Mail/Saga/Mail';
import AddCircleIcon from '@mui/icons-material/AddCircle';





// ]
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
      point: 0,
      disp: false,

      modal:false,
    }

    // props.get_response_rooms({rentResponseId:props.rent_response_id});
    this.handleSubmit = this.handleSubmit.bind(this);
    // this.selectTatemono = this.selectTatemono.bind(this);
    this.mapchange = this.mapchange.bind(this);
    this.handleClose = this.handleClose.bind(this);

    // this.props.get_response_map_rooms_success([]);
  }

  mapchange() {
    // this.setState({disp:!this.state.disp})
    this.props.change_map_disp(!this.props.map_disp)
    // this.setState({disp:0})
  }

  


  _onChildClick = (key, childProps) => {
    alert(key)
    // this.props.onCenterChange([childProps.lat, childProps.lng]);
  }

  handleSubmit(state) {
    console.log(state)

    if (this.state.bounds) {
      state.max_lat = this.state.bounds[0];
      state.min_lat = this.state.bounds[2];
      state.max_lng = this.state.bounds[3];
      state.min_lng = this.state.bounds[1];
      // state.rentResponseId = this.props.rent_response_id;
      // this.props.get_response_map_rooms(state);

    }
    // this.props.actions.get_response_rooms(state);

  }

  handleClose(){
    this.setState({ modal: false })
  }
  render() {
    // const {handleChange} = this.props;
    //moment.locale('ja')

    return (

      <div style={{ paddingTop: 5 }}>
        <div style={{float:"left", marginRight:10}}>
          <List />
        </div>
        <div className="search_menu" style={{ float:"left" }}>
          <Settings mapchange={this.mapchange} onSubmit={this.handleSubmit} onChange={this.handleSubmit} />
        </div>

        {/* <div style={{ height: window.innerHeight - 50, position: "absolute", zIndex: 5, Top: 5, Bottom: 5, left: 0, right: 0 }}> */}
        <div style={{ float:"left", width:window.innerWidth-610, height:window.innerHeight-200, marginLeft:5 }}>
          {this.props.mapmypagetemplate.map_disp && this.props.mapmypagetemplate.rent_introduction_point_templates.id ? <Map key={1} bounds={(bounds) => this.setState({ bounds: bounds })} /> : <Map key={2} tatemono_list={this.props.mapsearch.response_rooms} bounds={(bounds) => this.setState({ bounds: bounds })} />}
          {this.props.mapmypagetemplate.rent_introduction_point_templates.id ? <Button size="small" color="primary" variant="contained" type="button" style={{ margin: "5px", marginTop:10 }} onClick={() => this.props.update_rent_introduction_point_templates(2)} startIcon={<AddCircleIcon/>}>
          自店にテンプレートを複製
      </Button> : ""}
          
        </div>

{/* 
        <div style={{ height: window.innerHeight - 50, position: "absolute", zIndex: 5, Top: 5, Bottom: 5, left: 0, right: 0 }}>
          {this.props.mapmypage.map_disp ? <Map key={1} rent_response_id={this.props.rent_response_id} bounds={(bounds) => this.setState({ bounds: bounds })} /> : <Map key={2} rent_response_id={this.props.rent_response_id} tatemono_list={this.props.mapsearch.response_rooms} bounds={(bounds) => this.setState({ bounds: bounds })} />}
        </div> */}

      </div>

    )
  }
}

// const Outer = styled.div`
// height:620; 
// position:absolute; 
// zIndex:5; 
// Top:10; 
// left:10; 
// right:10;`

//containers

// ({mapsearchReducer}) => ({mapsearchReducer}),

export default connect(
  ({ mapsearch, mapmypagetemplate }) => ({ mapsearch, mapmypagetemplate }),
  // state => ({
  //   mapsearch: state.mapsearchReducer,

  // }),
  {

    // get_response_map_rooms_success,
    change_showRight,
    change_showLeft,
    change_showFlag,

    // insert_map_introductions,
    add_mail_room,
    change_map_disp,

    update_rent_introduction_point_templates,
  }

)(App)
// const mapStateToProps = (state) => {
//   return {
//     response_rooms: state.mapsearchReducer.response_rooms,

//   }
// }
// function mapDispatchToProps(dispatch) {
//   return {

//       get_response_rooms(state) {
//           dispatch(Rooms.get_response_rooms(state))
//       },
//   };
// }
// export default connect(mapStateToProps, mapDispatchToProps)(App);


