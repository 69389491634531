import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from "./Grid";
import FollowSheetSearch from "./FollowSheetSearch";

interface Props {
    section_id: number
}

interface State {
}

class ResponseFollowSheet extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
    }
    render() {
        return (
            <div className="item-outer col-lg-12">

                <div>
                    <Card >
                        <CardContent >
                            <FollowSheetSearch section_id={this.props.section_id} />
                        </CardContent >
                    </Card >
                </div>

                <div style={{ marginTop: 10 }}>
                    <Card >
                        <CardContent >
                            <Grid />
                        </CardContent >
                    </Card >
                </div>

            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResponseFollowSheet);




