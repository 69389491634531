import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';

import { startSubmit, stopSubmit, reset, submit } from 'redux-form';


import * as rent_introduction_point_template_headers_api from '../Api/rent_introduction_point_template_headers_api';
import * as rent_introduction_point_templates_api from '../Api/rent_introduction_point_templates_api';

import * as MapMypage from '../../MapMypage/Saga/MapMypage';
import StateManager from 'react-select';

export interface MapSearchState {

  showRight: boolean;
  showLeft: boolean;
  showFlag: boolean;
  draggable: boolean;
  map_settings_flg: number,
  map_polygon_settings: any;
  map_point_settings: any;
  map_settings_edit: number;
  map_disp: boolean,

  rent_introduction_point_template_headers: any,

  rent_introduction_point_templates: any,
  rent_introduction_point_template_list: any,
  select_section_id: number,

  
}
const initialState: MapSearchState = {

  showRight: false,
  showLeft: true,
  showFlag: true,
  draggable: true,
  map_polygon_settings: [],
  map_point_settings: [],
  map_settings_flg: 1,
  map_settings_edit: 1,
  map_disp: false,


  rent_introduction_point_template_headers: [],

  rent_introduction_point_templates:[],
  rent_introduction_point_template_list:[],
  select_section_id: 0,
}



///action 
const actionCreator = actionCreatorFactory();


export const change_showRight = actionCreator<any>('change_showRight');
export const change_showLeft = actionCreator<any>('change_showLeft');
export const change_showFlag = actionCreator<any>('change_showFlag');
export const change_draggable = actionCreator<any>('change_draggable');


export const change_map_polygon_settings = actionCreator<any>('CHANGE_MAP_POLYGON_SETTINGS');
export const change_map_point_settings = actionCreator<any>('CHANGE_MAP_POINT_SETTINGS');

export const change_map_settings_flg = actionCreator<any>('CHANGE_MAP_SETTINGS_FLG');
export const change_map_settings_edit = actionCreator<any>('CHANGE_MAP_SETTINGS_EDIT');

export const change_map_disp = actionCreator<any>('CHANGE_MAP_DISP');
export const change_map_points = actionCreator<any>('CHANGE_MAP_POINTS');

export const add_rent_introduction_point_templates = actionCreator<any>('ADD_RENT_INTRODUCTION_POINT_TEMPLATES');

export const get_rent_introduction_point_templates = actionCreator<any>('GET_RENT_INTRODUCTION_POINT_TEMPLATES');
export const change_rent_introduction_point_templates = actionCreator<any>('CHANGE_RENT_INTRODUCTION_POINT_TEMPLATES');
export const update_rent_introduction_point_templates = actionCreator<any>('UPDATE_RENT_INTRODUCTION_POINT_TEMPLATES');
export const delete_rent_introduction_point_templates = actionCreator<any>('DELETE_RENT_INTRODUCTION_POINT_TEMPLATES');

export const insert_rent_introduction_point_template_headers = actionCreator<any>('INSERT_RENT_INTRODUCTION_POINT_TEMPLATE_HEADERS');
export const delete_rent_introduction_point_template_headers = actionCreator<any>('DELETE_RENT_INTRODUCTION_POINT_TEMPLATE_HEADERS');
export const change_rent_introduction_point_template_headers = actionCreator<any>('CHANGE_RENT_INTRODUCTION_POINT_TEMPLATE_HEADERS');//ok

export const get_rent_introduction_point_template_list = actionCreator<any>('GET_RENT_INTRODUCTION_POINT_TEMPLATE_LIST');
export const change_rent_introduction_point_template_list = actionCreator<any>('CHANGE_RENT_INTRODUCTION_POINT_TEMPLATE_LIST');

export const change_select_section_id = actionCreator<any>('CHANGE_SELECT_SECTION_ID');

export const update_rent_introduction_point_detail_templates = actionCreator<any>('UPDATE_RENT_INTRODUCTION_POINT_DETAIL_TEMPLATES');



export const mapmypagetemplateReducer = reducerWithInitialState(initialState)

  .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
  .case(change_showLeft, (state, payload) => ({ ...state, showLeft: payload }))
  .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
  .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

  .case(change_map_polygon_settings, (state, payload) => ({ ...state, map_polygon_settings: payload }))
  .case(change_map_point_settings, (state, payload) => ({ ...state, map_point_settings: payload }))

  .case(change_map_settings_flg, (state, payload) => ({ ...state, map_settings_flg: payload }))
  .case(change_map_settings_edit, (state, payload) => ({ ...state, map_settings_edit: payload }))

  .case(change_map_disp, (state, payload) => ({ ...state, map_disp: payload }))

  .case(change_rent_introduction_point_templates, (state, payload) => ({ ...state, rent_introduction_point_templates: payload }))

  .case(change_rent_introduction_point_template_headers, (state, payload) => ({ ...state, rent_introduction_point_template_headers: payload }))

  .case(change_rent_introduction_point_template_list, (state, payload) => ({ ...state, rent_introduction_point_template_list: payload }))

  .case(change_select_section_id, (state, payload) => ({ ...state, select_section_id: payload }))
  

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const mapmypagetemplateSaga = [

  takeEvery('UPDATE_RENT_INTRODUCTION_POINT_TEMPLATES', handle_update_rent_introduction_point_templates),
  takeEvery('UPDATE_RENT_INTRODUCTION_POINT_DETAIL_TEMPLATES', handle_update_rent_introduction_point_detail_templates),

  takeEvery('INSERT_RENT_INTRODUCTION_POINT_TEMPLATE_HEADERS', handle_insert_rent_introduction_point_template_headers),

  takeEvery('DELETE_RENT_INTRODUCTION_POINT_TEMPLATES', handle_delete_rent_introduction_point_templates),
  takeEvery('DELETE_RENT_INTRODUCTION_POINT_TEMPLATE_HEADERS', handle_delete_rent_introduction_point_template_headers),

  takeEvery('ADD_RENT_INTRODUCTION_POINT_TEMPLATES', handle_add_rent_introduction_point_templates),

  takeEvery('GET_RENT_INTRODUCTION_POINT_TEMPLATES', handle_get_rent_introduction_point_templates),
  takeEvery('GET_RENT_INTRODUCTION_POINT_TEMPLATE_LIST', handle_get_rent_introduction_point_template_list),
];
// export default masterSaga;


// テンプレートリスト取得
function* handle_get_rent_introduction_point_template_list(action: any): any {

  const state = yield select();
  let section_id = state.mapmypagetemplate.select_section_id
  const { payload, error } = yield call(rent_introduction_point_templates_api.list, section_id);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_rent_introduction_point_template_list(payload));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}


// ●ヘッダー取得
function* handle_get_rent_introduction_point_templates(action: any): any {
  const state = yield select();

  // yield put(change_rent_introduction_point_templates([]));
  // yield put(change_rent_introduction_point_templates({}));
  yield put(change_rent_introduction_point_template_headers([]))

  const { payload, error } = yield call(rent_introduction_point_templates_api.detail, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_rent_introduction_point_templates(payload));
    yield put(change_map_disp(!state.mapmypagetemplate.map_disp));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

//●　ヘッダー追加
function* handle_insert_rent_introduction_point_template_headers(action: any): any {

  const state = yield select();

  let rent_introduction_point_templates = state.mapmypagetemplate.rent_introduction_point_templates

  let contents = ""
  if (action.payload === 1) {
    contents = "ポイント" + (rent_introduction_point_templates.rent_introduction_point_template_headers.filter((a: any) => a.type === 1).length + 1)
  } else if (action.payload === 2) {
    contents = "範囲" + (rent_introduction_point_templates.rent_introduction_point_template_headers.filter((a: any) => a.type === 2).length + 1)
  } else if (action.payload === 3) {
    contents = "コメント" + (rent_introduction_point_templates.rent_introduction_point_template_headers.filter((a: any) => a.type === 3).length + 1)
  } else if (action.payload === 4) {
    contents = "線" + (rent_introduction_point_templates.rent_introduction_point_template_headers.filter((a: any) => a.type === 4).length + 1)
  } else if (action.payload === 5) {
    contents = "円" + (rent_introduction_point_templates.rent_introduction_point_template_headers.filter((a: any) => a.type === 5).length + 1)
  }


  const { payload, error } = yield call(rent_introduction_point_template_headers_api.insert, { type: action.payload, rent_introduction_point_template_id: rent_introduction_point_templates.id, contents: contents });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    payload.rent_introduction_point_template_details = payload.rent_introduction_point_template_details ? payload.rent_introduction_point_template_details.map(function (value: any) {

      value = value ? value : []
      //配列の各要素を2倍にする
      return value;

    }) : [];
    rent_introduction_point_templates.rent_introduction_point_template_headers = rent_introduction_point_templates.rent_introduction_point_template_headers.concat(payload)






    yield put(change_rent_introduction_point_templates(rent_introduction_point_templates));
    yield put(change_rent_introduction_point_template_headers(payload));
    yield put(change_map_disp(!state.mapmypagetemplate.map_disp));



  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}

// ●ヘッダー削除
function* handle_delete_rent_introduction_point_template_headers(action: any): any {

  const state = yield select();

  let rent_introduction_point_templates = state.mapmypagetemplate.rent_introduction_point_templates
  yield put(change_rent_introduction_point_templates([]));

  const { payload, error } = yield call(rent_introduction_point_template_headers_api.deletepoint, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    rent_introduction_point_templates.rent_introduction_point_template_headers = rent_introduction_point_templates.rent_introduction_point_template_headers.filter((a: any) => a.id !== action.payload)
    yield put(change_rent_introduction_point_templates(rent_introduction_point_templates));
    yield put(change_rent_introduction_point_template_headers([]));
    yield put(change_map_disp(!state.mapmypagetemplate.map_disp));
  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}

// ●保存 または テンプレート新規作成
function* handle_update_rent_introduction_point_templates(action: any): any {
  const state = yield select();

  let rent_introduction_point_template_list = state.mapmypagetemplate.rent_introduction_point_template_list
  let rent_introduction_point_templates = state.mapmypagetemplate.rent_introduction_point_templates
  console.log(rent_introduction_point_templates)


  // const { payload, error } = yield call(rent_introduction_point_templates_api.update, action.payload == 0 ? {} : rent_introduction_point_templates);
  // 0:新規 1:更新 2:テンプレートコピー
  const { payload, error } = yield call(rent_introduction_point_templates_api.update, {type:action.payload, rent_introduction_point_templates: rent_introduction_point_templates});

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)


    // yield put(Mail.get_mail_rent_introduction_header_list(responsedetail.id));

    yield put(get_rent_introduction_point_templates(action.payload == 0 || action.payload == 2 ? payload.id : rent_introduction_point_templates.id));

    // 新規追加
    if(action.payload == 0){
      yield put(change_rent_introduction_point_template_list(rent_introduction_point_template_list.concat(payload)));

    }else if(action.payload == 2){//2:テンプレートコピー

      // 選択店舗セット
      yield put(change_select_section_id(state.masterReducer.user_details.amSectionId))
      // テンプレートリスト更新
      yield put(get_rent_introduction_point_template_list(state.masterReducer.user_details.amSectionId))
      // alert(payload.id)
      yield put(get_rent_introduction_point_templates(payload.id));
      

    }else{
      // 一覧の情報更新
      rent_introduction_point_template_list = rent_introduction_point_template_list.map(function (value: any) {
        if (value.id === rent_introduction_point_templates.id) {
            // alert(payload.title)
            value.name = rent_introduction_point_templates.name
        }
        return value
      })
      yield put(change_rent_introduction_point_template_list(rent_introduction_point_template_list));
    }

    yield put(change_rent_introduction_point_template_headers([]));

    yield put(change_map_disp(!state.mapmypagetemplate.map_disp));


  } else {
    alert("error")
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_update_rent_introduction_point_detail_templates(action: any): any {
  const state = yield select();



  let rent_introduction_point_template_headers = state.mapmypagetemplate.rent_introduction_point_template_headers

  // yield put(change_rent_introduction_headers(rent_introduction_headers));
  // console.log(rent_introduction_headers)
  // alert(JSON.stringify(rent_introduction_headers))
  const { payload, error } = yield call(rent_introduction_point_templates_api.update_point, rent_introduction_point_template_headers);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    //  yield put(get_response_rooms_success(payload));
    //  alert(responsedetail.id)
    // yield put(get_rent_introduction_headers_list(responsedetail.id));

    // yield put(change_select_list_open(false));

    // yield put(Mail.get_mail_rent_introduction_header_list(responsedetail.id));


    // yield put(change_map_disp(!state.mapmypage.map_disp));

    // yield put(change_rent_introduction_point_headers([]));


  } else {
    alert("error")
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// ●テンプレート削除
function* handle_delete_rent_introduction_point_templates(action: any): any {

  const state = yield select();

  let rent_introduction_point_template_list = state.mapmypagetemplate.rent_introduction_point_template_list

  const { payload, error } = yield call(rent_introduction_point_templates_api.deleteheaders, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    yield put(change_rent_introduction_point_template_list(rent_introduction_point_template_list.filter((a: any) => a.id !== action.payload)));
    yield put(change_rent_introduction_point_templates([]));
    yield put(change_rent_introduction_point_template_headers([]));
    yield put(change_map_disp(!state.mapmypagetemplate.map_disp));
  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}

// ●マイページにテンプレートを追加
function* handle_add_rent_introduction_point_templates(action: any): any {
  // yield put(change_rent_introduction_point_templates([]));
  // yield put(change_rent_introduction_point_templates({}));

  const state = yield select();

  let rent_introduction_header_id = state.mapmypage.rent_introduction_headers.id
  let rent_introduction_headers_list = state.mapmypage.rent_introduction_headers_list

  const { payload, error } = yield call(rent_introduction_point_templates_api.add, {rent_introduction_header_id:rent_introduction_header_id, rent_introduction_point_template_id : action.payload});

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    // テンプレートダイアログをとじる
    yield put(MapMypage.change_rent_introduction_point_template_disp(false));

    // 一覧の情報更新
    rent_introduction_headers_list = rent_introduction_headers_list.map(function (value: any) {
      if (value.id === rent_introduction_header_id) {
          // alert(payload.title)
          value.rent_introduction_point_headers = payload
      }
      return value
    })
    yield put(MapMypage.change_rent_introduction_headers_list(rent_introduction_headers_list));
    
    yield put(MapMypage.change_rent_introduction_headers(rent_introduction_headers_list.filter((a:any) => a.id === rent_introduction_header_id)[0]))

    yield put(MapMypage.change_map_disp(!state.mapmypage.map_disp));

    // yield put(change_rent_introduction_point_templates(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}