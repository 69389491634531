import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import Loading from '../Layout/LoadingComponent';

import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';
import CardContent from '@mui/material/CardContent';

import 'fixed-data-table-2/dist/fixed-data-table.css';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';

import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Chip from '@mui/material/Chip';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import './Css/Grid.css'

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';

var moment = require('moment-timezone');


var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
  };

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))
class CustomerList extends Component {
    constructor(props) {
 
        super(props);
        this.state = {
            // modal: false,
            modal_del: false,
            modalselect: false,

            zoom: 14,
            draggable: true,
            lat: 34.36179852,
            lng: 134.048788977,
            geo_lat : "",
            geo_lng : "",
            center: null,

            columnWidths: {
                id: 80,
                rent_contract_id: 80,
                section_id: 100,
                user_id: 90,
                date: 100,
                contract_date: 100,
                tatemono_name: 160,
                room_name: 100,
                tinryo: 70,
                tyuute: 70,
                name1: 160,
                name2: 110,
                office_name: 150,
                rent_m_way_name: 100,
                rent_m_medium_name: 100,

            },

            colSortDirs: {},
        };


        this.onChange = this.onChange.bind(this);
        this.onSearchChangeDate = this.onSearchChangeDate.bind(this);
        this.handleChangeCheck = this.handleChangeCheck.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.handleRentMCorporateIdChange = this.handleRentMCorporateIdChange.bind(this);
        this.RowClickResponse = this.RowClickResponse.bind(this);
        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this._onSortChange = this._onSortChange.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this.props.get_rent_m_corporate_all_list()
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            },
        }));
    }

    _onSortChange(columnKey, sortDir) {

        let rent_responses_corporate_count_list = this.props.rent_responses_corporate_count_list;
        rent_responses_corporate_count_list.sort((indexA, indexB) => {
          // alert(indexA)
    
          var valueA = indexA[columnKey];
          var valueB = indexB[columnKey];
          var sortVal = 0;
          if (valueA > valueB) {
            sortVal = 1;
          }
          if (valueA < valueB) {
            sortVal = -1;
          }
    
          if (!valueA && !valueB) {
            sortVal = 0
          }
          if (!valueA) {
            sortVal = -1
          }
          if (!valueB) {
            sortVal = 1
          }
    
    
          if (sortVal !== 0 && sortDir === SortTypes.DESC) {
            sortVal = sortVal * -1;
          }
    
          return sortVal;
        });
        this.props.change_rent_responses_corporate_count_list(rent_responses_corporate_count_list)
        this.setState({
          // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
          colSortDirs: {
            [columnKey]: sortDir,
          },
        });
      }


    onChange(state){
        // console.log(this.props.claso_blog_places)
        let rent_responses_corporate_count_list_search_form =  this.props.rent_responses_corporate_count_list_search_form

        rent_responses_corporate_count_list_search_form[state.target.name] = state.target.value
        
        this.props.change_rent_responses_corporate_count_list_search_form(rent_responses_corporate_count_list_search_form)
        this.setState({
            change: !this.state.change
        });
        
    }
    
    handleChangeCheck(state){
        // console.log(this.props.claso_blog_places)
        let rent_responses_corporate_count_list_search_form =  this.props.rent_responses_corporate_count_list_search_form

        rent_responses_corporate_count_list_search_form[state.target.name] = state.target.checked
        
        this.props.change_rent_responses_corporate_count_list_search_form(rent_responses_corporate_count_list_search_form)
        this.setState({
            change: !this.state.change
        });
        
    }

    onSearchChangeDate(value, name) {

        let rent_responses_corporate_count_list_search_form = this.props.rent_responses_corporate_count_list_search_form
        rent_responses_corporate_count_list_search_form[name] = value
        this.props.change_rent_responses_corporate_count_list_search_form(rent_responses_corporate_count_list_search_form)

        this.setState({
            change: !this.state.change
        });
    }

    handleRentMCorporateIdChange(e, value) {
        let rent_responses_corporate_count_list_search_form = this.props.rent_responses_corporate_count_list_search_form
        if (value && value.id) {
            rent_responses_corporate_count_list_search_form.rent_m_corporate_id = value.id
        } else {
            rent_responses_corporate_count_list_search_form.rent_m_corporate_id = 0
        }

        // if(rent_responses_corporate_count_list_search_form.owner_id > 0){
        //     this.props.get_eigyou_owner_room(rent_responses_corporate_count_list_search_form.owner_id)
        // }

        this.props.change_rent_responses_corporate_count_list_search_form(rent_responses_corporate_count_list_search_form);
        this.setState({ change: !this.state.change });

    }

    onClickSearch(){

        this.props.get_rent_responses_corporate_count_list()
        this.setState({ change: !this.state.change });
    }

    RowClickResponse(event,index){
        window.open(`/customer/` + this.props.rent_responses_corporate_count_list[index].id, '_blank', 'noopener')
        // this.props.change_modalResponseDetail(true)
        // this.props.change_responseid(this.props.rent_responses_response_list[index].id)

    }

    _rowClassNameGetter(index){
        
        if(this.props.rent_responses_corporate_count_list && this.props.rent_responses_corporate_count_list[index]) {
          return this.props.rent_responses_corporate_count_list[index].is_cancel == 1 ? "cancel" : "normal"
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            // return "normal"
        }else{
          return ""
        }
    }




    render() {
        return <>
        {this.props.rent_m_corporate_all_list && this.props.rent_m_corporate_all_list.length == 0 ? <Loading /> :
        <>
            <Card>
                <CardContent>

                    <Grid container spacing={1}>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
                            <Grid justifyContent="space-between" item xs={1}>
                                <TextField 
                                    fullWidth
                                    size="small" 
                                    onChange={this.onChange}
                                    value={this.props.rent_responses_corporate_count_list_search_form.rent_m_corporate_id} 
                                    margin="dense"
                                    label="法人ID"
                                    name="rent_m_corporate_id"
                                    variant="outlined"
                                    InputLabelProps={{ shrink: true }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            // エンターキー押下時の処理
                                            // this.handleChangeNotSearchTextEnter(e)
                                        }
                                    }}
                                />

                            </Grid>


                            <Grid justifyContent="space-between" item xs={4}>

                                {this.props.rent_m_corporate_all_list.length > 0 ? <Autocomplete
                                    id="combo-box-demo"
                                    options={this.props.rent_m_corporate_all_list.length === 0 && this.props.rent_responses_corporate_count_list_search_form.rent_m_corporate_id ? [this.props.rent_m_corporate_all_list.filter(a => parseInt(a.id) === parseInt(this.props.rent_responses_corporate_count_list_search_form.rent_m_corporate_id))[0]]
                                        : this.props.rent_m_corporate_all_list}
            
                                    getOptionLabel={(option) => option.name ? option.name : ''}

                                    fullWidth
                                    name="ownerId"
                                    loading={this.props.users_loading}

                                    value={this.props.rent_responses_corporate_count_list_search_form.rent_m_corporate_id ? this.props.rent_m_corporate_all_list.filter(a => parseInt(a.id) === parseInt(this.props.rent_responses_corporate_count_list_search_form.rent_m_corporate_id))[0] : {}}

                                    onChange={this.handleRentMCorporateIdChange}
                                    renderInput={(params) => <TextField
                                        {...params}
                                        variant="outlined"
                                        name="userId"
                                        size="small"
                                        style={{marginTop:8}}
                                        // error={!this.props.rent_responses_editor.user_id}
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>

                                                    {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                        // onChange={this.handleChange}
                                        label="法人" />}

                                />:""}
                            </Grid>
                            <Grid justifyContent="space-between" item xs={7}>
                                <FormGroup row>
                                    <FormControlLabel control={<Checkbox name="corporate_response" checked={this.props.rent_responses_corporate_count_list_search_form.corporate_response} onChange={this.handleChangeCheck} color="primary" style={{marginLeft: 10}} />} label="法人反響" />
                                    <FormControlLabel control={<Checkbox name="corporate_contract" checked={this.props.rent_responses_corporate_count_list_search_form.corporate_contract} onChange={this.handleChangeCheck} color="primary" style={{marginLeft: 10}} />} label="法人契約" />
                                    <FormControlLabel control={<Checkbox name="personal_response" checked={this.props.rent_responses_corporate_count_list_search_form.personal_response} onChange={this.handleChangeCheck} color="primary" style={{marginLeft: 10}} />} label="個人反響" />
                                    <FormControlLabel control={<Checkbox name="personal_contract" checked={this.props.rent_responses_corporate_count_list_search_form.personal_contract} onChange={this.handleChangeCheck} color="primary" style={{marginLeft: 10}} />} label="個人契約" />
                                </FormGroup>
                            </Grid>
                            <Grid justifyContent="space-between" item xs={4}>
                                <TextField
                                    id="start_date"
                                    label="反響日　開始"
                                    type="date"
                                    name="start_date"
                                    size="small"
                                    onChange={this.onChange}
                                    value={moment(this.props.rent_responses_corporate_count_list_search_form.start_date).format("YYYY-MM-DD")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    style={{marginRight:10}}
                                />
                                {/* <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="dense"
                                    id="date-picker-inline1"
                                    label="反響日　開始"
                                    // showTodayButton={true}
                                    style={{ marginTop: "0", marginRight:5 }}
                                    autoOk={true}
                                    InputLabelProps={{ shrink: true }}
                                    name="start_date"
                                    value={this.props.rent_responses_corporate_count_list_search_form.start_date}
                                    onChange={(e) => this.onSearchChangeDate(e, "start_date")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={"日付の形式が違います"}
                                /> */}
                                <TextField
                                    id="end_date"
                                    label="反響日　終了"
                                    type="date"
                                    name="end_date"
                                    size="small"
                                    onChange={this.onChange}
                                    value={moment(this.props.rent_responses_corporate_count_list_search_form.end_date).format("YYYY-MM-DD")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    style={{marginRight:10}}
                                />
                                {/* <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="dense"
                                    id="date-picker-inline1"
                                    label="反響日終了"
                                    // showTodayButton={true}
                                    style={{ marginTop: "0" }}
                                    autoOk={true}
                                    InputLabelProps={{ shrink: true }}
                                    name="end_date"
                                    value={this.props.rent_responses_corporate_count_list_search_form.end_date}
                                    onChange={(e) => this.onSearchChangeDate(e, "end_date")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={"日付の形式が違います"}
                                /> */}
                            </Grid>
                            <Grid justifyContent="space-between" item xs={4}>
                                <TextField
                                    id="contract_start_date"
                                    label="契約日　開始"
                                    type="date"
                                    name="contract_start_date"
                                    size="small"
                                    onChange={this.onChange}
                                    value={moment(this.props.rent_responses_corporate_count_list_search_form.contract_start_date).format("YYYY-MM-DD")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    style={{marginRight:10}}
                                />
                                {/* <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="dense"
                                    id="date-picker-inline1"
                                    label="契約日　開始"
                                    // showTodayButton={true}
                                    style={{ marginTop: "0", marginRight:5 }}
                                    autoOk={true}
                                    InputLabelProps={{ shrink: true }}
                                    name="contract_start_date"
                                    value={this.props.rent_responses_corporate_count_list_search_form.contract_start_date}
                                    onChange={(e) => this.onSearchChangeDate(e, "contract_start_date")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={"日付の形式が違います"}
                                /> */}
                                
                                <TextField
                                    id="contract_end_date"
                                    label="契約日　終了"
                                    type="date"
                                    name="contract_end_date"
                                    size="small"
                                    onChange={this.onChange}
                                    value={moment(this.props.rent_responses_corporate_count_list_search_form.contract_end_date).format("YYYY-MM-DD")}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    variant="standard"
                                    style={{marginRight:10}}
                                />
                                {/* <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="dense"
                                    id="date-picker-inline1"
                                    label="契約日　終了"
                                    // showTodayButton={true}
                                    style={{ marginTop: "0" }}
                                    autoOk={true}
                                    InputLabelProps={{ shrink: true }}
                                    name="contract_end_date"
                                    value={this.props.rent_responses_corporate_count_list_search_form.contract_end_date}
                                    onChange={(e) => this.onSearchChangeDate(e, "contract_end_date")}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={"日付の形式が違います"}
                                /> */}
                            </Grid>

                            <Grid justifyContent="space-between" item xs={2}>
                                <Button size="small" onClick={() => this.onClickSearch()} variant="contained" color="primary" style={{ marginLeft: 5 }}>検索</Button>
                            </Grid>
                        {/* </MuiPickersUtilsProvider> */}
                    </Grid>


                    

                </CardContent>

            </Card>

            <Card>
                <CardContent>
                    <div style={{ float: "right" }}><Chip label={this.props.rent_responses_corporate_count_list.length + "件"} style={{ fontSize: 14 }} variant="outlined" /></div>
                    {this.props.rent_responses_corporate_count_list_loading ? <Loading /> : <Table 
                        className="gridCorporateNormalList"
                        rowHeight={34} 
                        rowsCount={this.props.rent_responses_corporate_count_list.length} 
                        height={window.innerHeight-315} 
                        headerHeight={30} 
                        width={window.innerWidth - 130}
                        onRowClick={this.RowClickResponse}
                        rowClassNameGetter={this._rowClassNameGetter}
                        onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    >
                        {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} aria-label="edit" size="small" onClick={() => this.responsedetail(this.props.rent_responses_corporate_count_list[rowIndex].id)}><EditIcon /></IconButton></Cell>)} width={40} /> */}

                        <Column width={this.state.columnWidths.id} isResizable={true} columnKey="id" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>ID</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].id}</Cell>)} />
                        <Column width={this.state.columnWidths.rent_contract_id} isResizable={true} columnKey="rent_contract_id" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>状況</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].rent_contract_id > 0 ? (this.props.rent_responses_corporate_count_list[rowIndex].is_cancel ? "キャンセル" : "成約済み") : this.props.rent_responses_corporate_count_list[rowIndex].coming_response_count > 0 ? "来店済み" : this.props.rent_responses_corporate_count_list[rowIndex].end_response_date ? "追客終了" : ""}</Cell>)} />
                    
                        <Column width={this.state.columnWidths.section_id} isResizable={true} columnKey="section_id" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>店舗</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.section_select && this.props.rent_responses_corporate_count_list[rowIndex].section_id ? this.props.section_select.filter((a) => a.value === this.props.rent_responses_corporate_count_list[rowIndex].section_id).length > 0 ? this.props.section_select.filter((a) => a.value === this.props.rent_responses_corporate_count_list[rowIndex].section_id)[0].label : "" : ""}</Cell>)} />
                        <Column width={this.state.columnWidths.user_id} isResizable={true} columnKey="user_id" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>担当</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_responses_corporate_count_list[rowIndex].user_id ? this.props.users_select.filter((a) => a.value === this.props.rent_responses_corporate_count_list[rowIndex].user_id).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_responses_corporate_count_list[rowIndex].user_id)[0].label : "" : ""}</Cell>)} />
                        <Column width={this.state.columnWidths.date} isResizable={true} columnKey="date" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>反響日</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_responses_corporate_count_list[rowIndex].date).format("YYYY-MM-DD")}</Cell>)} />
                        <Column width={this.state.columnWidths.contract_date} isResizable={true} columnKey="contract_date" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>契約日</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].contract_date ? moment(this.props.rent_responses_corporate_count_list[rowIndex].contract_date).format("YYYY-MM-DD") : ""}</Cell>)} />
                        <Column width={this.state.columnWidths.tatemono_name} isResizable={true} columnKey="tatemono_name" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>物件名</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].Tatemono_Id ? this.props.rent_responses_corporate_count_list[rowIndex].Tatemono_Name : this.props.rent_responses_corporate_count_list[rowIndex].rent_contracts_building_name}</Cell>)} />
                        <Column width={this.state.columnWidths.room_name} isResizable={true} columnKey="room_name" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>部屋名</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].Tatemono_Id ? this.props.rent_responses_corporate_count_list[rowIndex].Room_Name : this.props.rent_responses_corporate_count_list[rowIndex].rent_contracts_room_name}</Cell>)} />
                        <Column width={this.state.columnWidths.tinryo} isResizable={true} columnKey="tinryo" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>賃料</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].tinryo}</Cell>)} />
                        <Column width={this.state.columnWidths.tyuute} isResizable={true} columnKey="tyuute" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>仲介手数料</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].tyuute}</Cell>)} />
                        
                        <Column width={this.state.columnWidths.name1} isResizable={true} columnKey="name1" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>契約者</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].name1}</Cell>)} />
                        <Column width={this.state.columnWidths.name2} isResizable={true} columnKey="name2" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>入居者</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].name2}</Cell>)} />
                        
                        <Column width={this.state.columnWidths.office_name} isResizable={true} columnKey="office_name" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>勤務先</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].office_name}</Cell>)} />
                        {/* <Column header={<Cell>入居者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].name2}</Cell>)} /> */}
                        
                        <Column width={this.state.columnWidths.rent_m_way_name} isResizable={true} columnKey="rent_m_way_name" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>反響経路</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].rent_m_way_name}</Cell>)} />
                        <Column width={this.state.columnWidths.rent_m_medium_name} isResizable={true} columnKey="rent_m_medium_name" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.id} style={{ whiteSpace: "nowrap" }}>反響媒体</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_responses_corporate_count_list[rowIndex].rent_m_medium_name}</Cell>)} />
                        </Table>}
                </CardContent>
            </Card>
        </>
        }
        </>;
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        section_select: state.masterReducer.section_select,
        users_select: state.masterReducer.users_select,
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_corporates_top_open:state.corporation.rent_m_corporates_top_open,
        rent_m_corporates_detail_open:state.corporation.rent_m_corporates_detail_open,
        rent_responses_corporate_count_list_search_form:state.corporation.rent_responses_corporate_count_list_search_form,
        rent_responses_corporate_count_list:state.corporation.rent_responses_corporate_count_list,
        rent_m_corporate_all_list: state.corporation.rent_m_corporate_all_list,
        rent_responses_corporate_count_list_loading: state.corporation.rent_responses_corporate_count_list_loading,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_m_corporates_top_open(state) {
            dispatch(Corporation.change_rent_m_corporates_top_open(state))
        },
        change_rent_m_corporates_detail_open(state) {
            dispatch(Corporation.change_rent_m_corporates_detail_open(state))
        },
        change_rent_responses_corporate_count_list_search_form(state) {
            dispatch(Corporation.change_rent_responses_corporate_count_list_search_form(state))
        },
        get_rent_m_corporate_all_list(state) {
            dispatch(Corporation.get_rent_m_corporate_all_list(state))
        },
        get_rent_responses_corporate_count_list(state) {
            dispatch(Corporation.get_rent_responses_corporate_count_list(state))
        },
        change_rent_responses_corporate_count_list(state) {
            dispatch(Corporation.change_rent_responses_corporate_count_list(state))
        },
        // get_m_corporations_list(state: any) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




