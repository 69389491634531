import React, { Component } from 'react';

import { connect } from 'react-redux';

import { reduxForm } from 'redux-form';
import '../../Css/Table.css';

import TatemonoDisp from './TatemonoDispComponent';
import RoomDisp from './RoomDispComponent';
import LifeLineDisp from './LifeLineDispComponent';
import SetsubiDisp from './SetsubiDispComponent';
import * as Rooms from './Saga/Rooms';
import Loading from '../Layout/LoadingComponent';
import MapDisp from './MapDispComponent';

import MonthGrid from './MonthGridComponent';

import InformationDisp from './InformationDispComponent';
import RoomImagesComponent from './RoomImagesComponent';


class RoomDocComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        //this.onChange = this.onChange.bind(this);

        const room = {
            tatemono_id: props.tatemonoId,
            room_id: props.roomId,
        }
        props.get_room_details(room)
        props.get_room_setsubi(room)
        props.get_rooms_tatemono_image_list(room)
        props.get_rooms_room_image_list(room)
        props.get_rooms_chinryou_list(room)
        props.get_room_campaign(room)
        
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }




    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }



    render() {
        const gaikan_url = "https://img.global-center.co.jp/v2/tatemono/" + this.props.room_details.tatemono_id + "/1/1/520/780/0";
        const madori_url = "https://img.global-center.co.jp/v2/rooms/" + this.props.room_details.tatemono_id + "/" + this.props.room_details.room_id + "/8/1/520/780/0";


        return (
            <div>


                <div className="">
                    <div className="item-outer col-lg-12">
                        <div className="item row">


                            <div className="col-lg-3">

                                <div style={{ border: "1px solid #CCCCCC", padding: 5 }}>
                                    {this.props.room_details.tatemono_id ? <img style={{ height: "auto", maxHeight: 200 }} src={gaikan_url} alt="" /> : ""}
                                </div>
                                <div style={{ border: "1px solid #CCCCCC", padding: 5, marginTop: 5 }}>
                                    {this.props.room_details.tatemono_id ? <img style={{ height: "auto", maxHeight: 200 }} src={madori_url} alt="" /> : ""}
                                </div>
                                {<div style={{ marginTop: 5 }}>{this.props.room_details.tatemono_id ? <MapDisp lat={this.props.room_details.ido} lng={this.props.room_details.keido} /> : <Loading />}</div>}
                                {/* <div className="float-left" style={{border:"1px solid #CCCCCC", padding:5}}>ヴィラ・アンソレイエ高松南Ⅰ - Ⅰ-3号室</div> */}


                            </div>

                            <div className="col-lg-4">
                                <div style={{ marginTop: 0 }}>{this.props.room_details.tatemono_id ? <RoomDisp roomdetail={this.props.room_details} /> : <Loading />}</div>
                                {this.props.room_details.tatemono_id ? <MonthGrid /> : <Loading />}
                                {this.props.room_details.tatemono_id ? <InformationDisp room_campaign={this.props.room_campaign}  room_details={this.props.room_details}/> : <Loading />}



                            </div>
                            <div className="col-lg-5">

                                <div style={{ marginTop: 0 }}>{this.props.room_details.tatemono_id ? <TatemonoDisp roomdetail={this.props.room_details} /> : <Loading />}</div>
                                <div style={{ marginTop: 5 }}>{this.props.room_details.tatemono_id ? <SetsubiDisp roomdetail={this.props.room_details} /> : <Loading />}</div>

                                <div style={{ marginTop: 5 }}>{this.props.room_details.tatemono_id ? <LifeLineDisp roomdetail={this.props.room_details} /> : <Loading />}</div>

                                {/* <div style={{marginTop:5}}>{this.props.room_details.tatemonoId ? <ChinryoDisp roomdetail={this.props.room_details} />: <Loading />}</div> */}



                            </div>
                            <div className="col-lg-12">
                                <RoomImagesComponent />
                            </div>
                        </div>

                    </div>


                </div>

            </div>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        room_details: state.roomsReducer.room_details,
        room_campaign: state.roomsReducer.room_campaign,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_room_details(state) {
            dispatch(Rooms.get_room_details(state))
        },
        get_room_setsubi(state) {
            dispatch(Rooms.get_room_setsubi(state))
        },
        get_rooms_tatemono_image_list(state) {
            dispatch(Rooms.get_rooms_tatemono_image_list(state))
        },
        get_rooms_room_image_list(state) {
            dispatch(Rooms.get_rooms_room_image_list(state))
        },
        get_rooms_chinryou_list(state) {
            dispatch(Rooms.get_rooms_chinryou_list(state))
        },
        get_room_campaign(state) {
            dispatch(Rooms.get_room_campaign(state))
        },
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

RoomDocComponent = reduxForm({
    form: 'roomform', // a unique identifier for this form
    //validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(RoomDocComponent)

export default connect(mapStateToProps, mapDispatchToProps)(RoomDocComponent);