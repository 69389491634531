import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import Grid from "./ResponseListTableComponent";
import ResponseAddComponent from '../ResponseDetail/ResponseAddComponent';
import ResponseSearch from "./ResponseSearchComponent";
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getRentMReasons } from '../../Store/Master';
import { responseList, change_formData } from './Saga/ResponseList';
import { change_response_add_disp, change_response_add_detail, change_customer_add_detail, change_modalResponseDetail, get_rent_m_medea } from '../ResponseDetail/Saga/Responses';
import MailTemplateComponent from '../MailTemplate/Index';
import EventListener from 'react-event-listener';
import '../../Css/Form.css';
import ResponseComponent from "../ResponseDetail/ResponseComponent";
import AutoSend from "../AutoSend/Index";
import { Card, CardContent } from '@mui/material';
import { styled } from '@mui/system';
const BackdropStyled = styled(Backdrop)(({ theme }) => ({
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: 20000,
    color: '#fff',
}));
class ResponseList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
            toggleaddresponse: false,
            togglemailtemplate: false,
            showRight: 0,
            userDetail: {},
            condition: "1",
        };
        this.handletoggleaddresponse = this.handletoggleaddresponse.bind(this);
        this.handletogglemailtemplate = this.handletogglemailtemplate.bind(this);
        this.responsedetailsearch = this.responsedetailsearch.bind(this);
        props.get_rent_m_medea()
    }

    componentWillMount() {}

    componentDidMount() {
        window.addEventListener('beforeunload', this.handleBeforeUnload);
    }
    
    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }

    handleBeforeUnload = (event) => {
        if(this.props.responsesReducer.rent_responses_free_edit_disp && this.props.responsesReducer.modalResponseDetail){
            const message = '修正中の内容があります。本当にページを閉じますか？';
            event.returnValue = message;
            return message;
        }
    };

    handleResize = () => {
        this.setState({ height: window.innerHeight });
    };

    handletoggleaddresponse(state) {
        this.setState({ userDetail: state });
        this.setState(prevState => ({
            toggleaddresponse: !prevState.toggleaddresponse
        }));
    }

    handletogglemailtemplate() {
        this.setState(prevState => ({
            togglemailtemplate: !prevState.togglemailtemplate
        }));
    }

    responsedetailsearch(value) {
        this.setState({ condition: value.condition });
        this.props.responseList(value);
    }

    toggle_modalResponseDetail(value) {
        if(!this.props.responsesReducer.rent_responses_free_edit_disp){
            this.props.change_modalResponseDetail(false);
        } else {
            alert("準備シートの内容が編集中です。\n修正が終わったら、修正フォームを閉じてください。")
        }
    }

    render() {
        return (
            <div>
                <EventListener target="window" onResize={this.handleResize} />
                {this.state.togglemailtemplate ? <MailTemplateComponent /> :
                    <div className="box responseMenu" style={{ position: "relative" }}>
                        <div style={{ position: "absolute", zIndex: 5, left: 0, right: 0 }}>
                            {this.props.masterReducer.user_details.amSectionId &&
                                <div className="item item-outer col-lg-12" style={{ paddingLeft: 3, paddingRight: 3 }}>
                                    <Card style={{ float: "left", marginLeft: 5, boxShadow: "none" }}>
                                        <ResponseSearch />
                                    </Card>
                                    <Card style={{ boxShadow: "none" }}>
                                        <CardContent style={{ marginLeft: 5, padding: 0 }}>
                                            <Grid />
                                        </CardContent>
                                    </Card>
                                </div>
                            }
                        </div>
                    </div>
                }

                <Modal isOpen={this.props.responsesReducer.response_add_disp} toggle={() => this.props.change_response_add_disp(0)} size="xxl" fade={false}>
                    <ModalHeader toggle={() => this.props.change_response_add_disp(0)}>新規登録</ModalHeader>
                    <ModalBody style={{ background: "#9fb6cc" }}>
                        <ResponseAddComponent responseedit={null} responseeditchange={null} />
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.props.responsesReducer.modalResponseDetail} toggle={() => this.toggle_modalResponseDetail()} size="xxl" fade={false}>
                    <ResponseComponent change_modalResponseDetail={(a) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responsesReducer.responseid} response_modal={true} />
                </Modal>

                <Modal isOpen={this.state.modalAutoSend} toggle={this.toggleAutoSend} size="xl" fade={false}>
                    <ModalHeader toggle={this.toggleAutoSend}>自動送信予定</ModalHeader>
                    <ModalBody style={{ height: "550px" }}>
                        <AutoSend rentResponseId={this.state.rentResponseId} />
                    </ModalBody>
                </Modal>

                <Modal isOpen={this.state.modalmailtaiou} toggle={this.togglemailtaiou} size="sm" fade={false}>
                    <ModalHeader toggle={this.togglemailtaiou}>確認済みにする</ModalHeader>
                    <ModalBody>確認済みにするとメール・LINE受信のアラートが削除されます。</ModalBody>
                    <ModalFooter>
                        <button className="btn btn-danger" style={{ margin: "5px" }} onClick={this.mailtaioudelete}>確認済みにする</button>
                        <button className="btn btn-secondary" style={{ margin: "5px" }} onClick={this.togglemailtaiou}>キャンセル</button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.line_delete} toggle={this.toggle_line_delete} fade={false}>
                    <ModalHeader toggle={this.toggle_line_delete}>LINE削除</ModalHeader>
                    <ModalBody>本当にLINEアカウントを削除してもよろしいですか？</ModalBody>
                    <ModalFooter>
                        <button className="btn btn-danger" style={{ margin: "5px" }} onClick={() => this.line_delete()}>削除</button>
                        <button className="btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggle_line_delete}>キャンセル</button>
                    </ModalFooter>
                </Modal>

                <BackdropStyled open={this.props.responselist.respnose_list_backdrop}>
                    <CircularProgress color="inherit" />
                </BackdropStyled>
            </div>
        );
    }
}

export default compose(connect(
    ({ masterReducer, responsesReducer, responselist }) => ({ masterReducer, responsesReducer, responselist }),
    {
        responseList,
        getRentMReasons,
        change_response_add_disp,
        change_response_add_detail,
        change_customer_add_detail,
        change_formData,
        change_modalResponseDetail,
        get_rent_m_medea
    }
))(ResponseList);
