import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter } from 'connected-react-router'
import logger from 'redux-logger';
// import rootSaga from './sagaes';
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'


// import * as Customers from './Customers';
import * as Customers from '../Components/Customers/Saga/Customers';
import * as Master from './Master';

import * as Firebase from './Firebase';
// import * as Contracts from './Contracts';

import * as Rooms from '../Components/Rooms/Saga/Rooms';
// import * as Tenants from './Tenants';
// import * as Monies from './Monies';
// import * as Mail from './Mail';
import * as Common from './Common';
// import lineReducer from './Line.js_';
import { reducer as reduxFormReducer } from 'redux-form';

import * as MapSearch from '../Components/MapSearch/Saga/MapSearch';
import * as Mail from '../Components/Mail/Saga/Mail';
import * as MailTemplate from '../Components/MailTemplate/Saga/MailTemplate';
import * as ResponseList from '../Components/ResponseList/Saga/ResponseList';
import * as CustomerContacts from '../Components/CustomerContacts/Saga/CustomerContacts';
import * as Responses from '../Components/ResponseDetail/Saga/Responses';
import * as Sales from '../Components/Sales/Saga/Sales';
import * as Task from '../Components/Task/Saga/Task';
import * as Estimates from '../Components/Estimates/Saga/Estimates';
import * as AutoSend from '../Components/AutoSend/Saga/AutoSend';
// import * as Tenants from '../Components/Tenant/Saga/Tenants';
// import * as TenantList from '../Components/TenantList/Saga/TenantList';

// import * as Monies from '../Components/Accounting/Saga/Monies';
import * as MypageLog from '../Components/MypageLog/Saga/MypageLog';
import * as Entrusted from '../Components/Entrusted/Saga/Entrusted';

import * as Search from '../Components/Search/Saga/Search';
import * as RoomList from '../Components/RoomList/Saga/RoomList';
// import * as RoomList2 from '../Components/RoomList/Saga/RoomList';
import * as Line from '../Components/Line/Saga/Line';
import * as IntroductionList from '../Components/IntroductionList/Saga/IntroductionList';
// import * as IntroductionListEdit from '../Components/IntroductionListEdit/Saga/IntroductionListEdit';

import * as EstimateList from '../Components/EstimateList/Saga/EstimateList';
// import * as TcBlog from '../Components/TcBlog/Saga/TcBlog';
import * as MapMypage from '../Components/MapMypage/Saga/MapMypage';
import * as MapMypageTemplate from '../Components/MapMypageTemplate/Saga/MapMypageTemplate';
import * as ContractFollow from '../Components/ContractFollow/Saga/ContractFollow';
import * as ResponseFiles from '../Components/ResponseFiles/Saga/ResponseFiles';
import * as StockRooms from '../Components/StockRooms/Saga/StockRooms';
import * as Calendar from '../Components/Calendar/Saga/Calendar';

import * as Contracts from '../Components/Contracts/Saga/Contracts';

import * as ResponseFollowSheet from '../Components/ResponseFollowSheet/Saga/ResponseFollowSheet';
import * as Corporation from '../Components/Corporation/Saga/Corporation';

import * as Help from '../Components/Help/Saga/Help';

import * as Files from '../Components/Files/Saga/Files';
import { all } from 'redux-saga/effects';

import * as DashBoard from '../Components/DashBoard/Saga/DashBoard';
import * as YoyakuParking from '../Components/YoyakuParking/Saga/YoyakuParking';



export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

const reducers = {
    form: reduxFormReducer, // mounted under "form"
    responsesReducer: Responses.responsesReducer,
    customersReducer: Customers.customersReducer,
    // firebaseReducer: firebaseReducer,
    mailReducer: Mail.mailReducer,
    mailtemplate: MailTemplate.mailtemplateReducer,
    // lineReducer: lineReducer,
    masterReducer: Master.masterReducer,

    firebasestoreReducer: Firebase.firebasestoreReducer,

    autosends: AutoSend.autosendsReducer,

    // rentContractMoniesReducer: Monies.rentContractMoniesReducer,

    sales: Sales.salesReducer,
    task: Task.taskReducer,
    customerContactsReducer: CustomerContacts.customerContactsReducer,
    roomsReducer: Rooms.roomsReducer,
    // tenantsReducer:Tenants.tenantsReducer,

    commonReducer: Common.commonReducer,
    mapsearch: MapSearch.mapsearchReducer,
    responselist: ResponseList.responselistReducer,
    estimates: Estimates.estimatesReducer,
    mypagelog: MypageLog.mypageLogReducer,
    entrusted: Entrusted.entrustedReducer,
    search: Search.searchReducer,
    contractfollow: ContractFollow.contractfollowReducer,
    help: Help.helpReducer,

    // tenantsReducer: Tenants.tenantsReducer,
    // tenantlistReducer: TenantList.tenantlistReducer,

    roomlist: RoomList.roomlistReducer,
    // roomlist2: RoomList2.roomlistReducer,
    line: Line.lineReducer,
    introductionlist: IntroductionList.introductionlistReducer,

    estimateList: EstimateList.estimateListReducer,

    // tcblog: TcBlog.tcblogReducer,
    mapmypage: MapMypage.mapmypageReducer,
    mapmypagetemplate: MapMypageTemplate.mapmypagetemplateReducer,
    responsefiles: ResponseFiles.responsefilesReducer,
    StockRooms: StockRooms.stockroomsReducer,
    calendar: Calendar.calendarReducer,

    contracts: Contracts.contractsReducer,
    files: Files.filesReducer,

    corporation: Corporation.corporationReducer,
    ResponseFollowSheet: ResponseFollowSheet.ResponseFollowReducer,

    dashboard: DashBoard.dashboardReducer,

    yoyakuparking: YoyakuParking.yoyakuparkingReducer,
    // introductionlistedit: IntroductionListEdit.introductionlisteditReducer,

};
const rootReducer = combineReducers({
    router: connectRouter(history),
    ...reducers // rest of your reducers
});





export function* rootSaga() {
    yield all([
        ...Master.masterSaga,
        ...Firebase.firebasestoreSaga,
        ...Responses.responsesSaga,
        ...Customers.customersSaga,
        ...ContractFollow.contractfollowSaga,
        ...Sales.salesSaga,
        ...Task.taskSaga,
        ...Mail.mailSaga,
        ...MailTemplate.mailtemplateSaga,
        ...CustomerContacts.customerContactsSaga,
        ...Rooms.roomsSaga,

        ...MapSearch.mapsearchSaga,
        ...ResponseList.responselistSaga,
        ...Estimates.estimatesSaga,
        ...MypageLog.mypageLogSaga,
        ...Entrusted.entrustedSaga,
        ...AutoSend.autosendsSaga,
        ...Search.searchSaga,
        ...RoomList.roomlistSaga,
        // ...RoomList2.roomlistSaga,
        ...Line.lineSaga,
        ...IntroductionList.introductionlistSaga,

        ...EstimateList.estimateListSaga,

        ...MapMypage.mapmypageSaga,
        ...MapMypageTemplate.mapmypagetemplateSaga,
        ...ResponseFiles.responsefilesSaga,
        ...StockRooms.stockroomsSaga,
        ...Calendar.calendarSaga,
        ...Contracts.contractsSaga,
        ...Files.filesSaga,
        ...Corporation.corporationSaga,
        ...ResponseFollowSheet.ResponseFollowSaga,
        ...Help.helpSaga,
        ...DashBoard.dashboardSaga,
        ...YoyakuParking.yoyakuparkingSaga,
        

        // ...IntroductionListEdit.introductionlisteditSaga,

    ]);
}




export default function configureStore(preloadedState) {
    const store = createStore(
        rootReducer, // root reducer with router state
        preloadedState,
        compose(
            applyMiddleware(
                routerMiddleware(history), // for dispatching history actions
                thunk,
                logger,
                sagaMiddleware,
                // ... other middlewares ...
            ),
        ),
    )
    sagaMiddleware.run(rootSaga)

    return store
}

//export default function configureStore(history, initialState) {

//  const reducers = {
//    counter: Counter.reducer,
//      //weatherForecasts: WeatherForecasts.reducer,
//      form: reduxFormReducer, // mounted under "form"
//      responsesReducer: responsesReducer,
//      customersReducer: customersReducer,
//      firebaseReducer: firebaseReducer,
//      mailReducer: mailReducer,
//      lineReducer:  lineReducer,
//  };


//  const middleware = [
//      thunk,
//      logger,
//    routerMiddleware(history)
//  ];

//  // In development, use the browser's Redux dev tools extension if installed
//  const enhancers = [];
//  const isDevelopment = process.env.NODE_ENV === 'development';
//  if (isDevelopment && typeof window !== 'undefined' && window.devToolsExtension) {
//    enhancers.push(window.devToolsExtension());
//  }

//  const rootReducer = combineReducers({
//    ...reducers,
//    routing: routerReducer
//  });

//  return createStore(
//    rootReducer,
//    initialState,
//    compose(applyMiddleware(...middleware), ...enhancers)
//  );
//}
