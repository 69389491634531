import React, { Component } from 'react';

import { connect } from 'react-redux';

import ja from "date-fns/locale/ja";
import Select from '@mui/material/Select';

import '../../Css/Table.css';

import * as Calendarsaga from './Saga/Calendar';

import Grid from '@mui/material/Grid';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import DateFnsUtils from '@date-io/date-fns';


import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment';
import StoreIcon from '@mui/icons-material/Store';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Event from '@mui/icons-material/Event';
import Today from '@mui/icons-material/Today';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import NavigateNext from '@mui/icons-material/NavigateNext';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import './Css/Calendar.css';



class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,

    };

    this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeDateButton = this.onChangeDateButton.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeDateTotalStart = this.onChangeDateTotalStart.bind(this);
    this.onChangeDateTotalEnd = this.onChangeDateTotalEnd.bind(this);
    this.clickEventTotalExcel = this.clickEventTotalExcel.bind(this);
  }

  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")


  }

  handleChange = (event) => {
    let calendar_staff_list_form = this.props.calendar_staff_list_form

    if(event.target.name == "section_id_os"){
      calendar_staff_list_form[event.target.name] = event.target.checked
        if(event.target.checked){
          calendar_staff_list_form["section_id"] = [45, 34, 501, 502, 503, 7]
        }else{
          calendar_staff_list_form["section_id"] = []
        }
        
    }else{
      calendar_staff_list_form[event.target.name] = event.target.value
    }

    this.props.change_calendar_staff_list_form(calendar_staff_list_form);

    this.props.get_calendar_list()

    this.setState({
      refresh: !this.state.refresh    //再描画
    });


  }

  handleChangeSectionId(e, newValue) {
    // alert(newValue)
    if (newValue) {
      let calendar_staff_list_form = this.props.calendar_staff_list_form
      calendar_staff_list_form.section_id = newValue.tenpoNo
      this.props.change_calendar_staff_list_form(calendar_staff_list_form);
      // alert(calendar_staff_list_form.section_id)
    } else {
      let calendar_staff_list_form = this.props.calendar_staff_list_form
      calendar_staff_list_form.section_id = 0

      this.props.change_calendar_staff_list_form(calendar_staff_list_form);
    }

    this.setState({
      refresh: !this.state.refresh    //再描画
    });

    this.props.get_calendar_list();

  }

  onChangeDateTotalStart(value) {

    if(!value){
      return
    }

    let calendar_staff_list_form = this.props.calendar_staff_list_form
    calendar_staff_list_form.start_total = moment(value).startOf('day').format('YYYY/MM/DD HH:mm:ss')
    this.props.change_calendar_staff_list_form(calendar_staff_list_form);

    this.setState({
      refresh: !this.state.refresh    //再描画
    });
  }

  onChangeDateTotalEnd(value) {

    if(!value){
      return
    }

    let calendar_staff_list_form = this.props.calendar_staff_list_form
    calendar_staff_list_form.end_total = moment(value).endOf('day').format('YYYY/MM/DD HH:mm:ss')
    this.props.change_calendar_staff_list_form(calendar_staff_list_form);

    this.setState({
      refresh: !this.state.refresh    //再描画
    });
  }

  onChangeDate(value) {

    if(!value){
      return
    }

    let calendar_staff_list_form = this.props.calendar_staff_list_form
    calendar_staff_list_form.start = moment(value).startOf('day').format('YYYY/MM/DD HH:mm:ss')
    calendar_staff_list_form.end = moment(value).endOf('day').format('YYYY/MM/DD HH:mm:ss')
    this.props.change_calendar_staff_list_form(calendar_staff_list_form);

    if(calendar_staff_list_form.start != 'Invalid date' && calendar_staff_list_form.end != 'Invalid date'){
      this.props.get_calendar_list();
    }

    this.setState({
      refresh: !this.state.refresh    //再描画
    });
  }

  onChangeDateButton(value) {

    let calendar_staff_list_form = this.props.calendar_staff_list_form

    if (value == 0) {
      calendar_staff_list_form.start = moment().startOf('day').format('YYYY/MM/DD HH:mm:ss')
      calendar_staff_list_form.end = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss')
    } else {
      calendar_staff_list_form.start = moment(calendar_staff_list_form.start).add(value, "days").startOf('day').format('YYYY/MM/DD HH:mm:ss')
      calendar_staff_list_form.end = moment(calendar_staff_list_form.end).add(value, "days").endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }


    this.props.change_calendar_staff_list_form(calendar_staff_list_form);

    this.props.get_calendar_list();

    this.setState({
      refresh: !this.state.refresh    //再描画
    });
  }

  onChangeType(type) {
    this.props.change_calendar_type(type)

    // this.props.change_calendar_staff_list_form({start: '', end: '', section_id: 0, user_id: 0,})

    this.props.get_calendar_list()

    this.setState({
      change: !this.state.change
    });

  }

  clickEventTotalExcel() {

    this.props.get_calendar_type_total_csv()
  }

  render() {



    return (
      <Grid container style={{ width: 170 }}>

        <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 0 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<StoreIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(0)} >今週</Button></Grid>
        <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 1 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<EventNoteIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(1)} >全店</Button></Grid>
        <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 2 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<PermContactCalendarIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(2)} >担当別</Button></Grid>
        <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 3 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<Event />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(3)} >日別</Button></Grid>
        
        <Grid xs={12} style={{ padding: "0px", textAlign:"center", marginBottom:15, marginTop:15 }}>
          {/* <div style={{border:"1px solid #dddddd", borderRadius:"10px"}}> */}
          <Typography variant="h6">
            {this.props.calendar_staff_list_form.start != 'Invalid date' && moment(this.props.calendar_staff_list_form.start).format("YYYY年M月D日(ddd)")}
          </Typography>

          {/* </div> */}
        </Grid>

        <Grid xs={12} style={{ padding: "10px 10px 0px 10px" }}>

          <TextField
            fullWidth
            id="start"
            label="日付"
            type="date"
            name="start"
            size="small"
            // style={{marginLeft:20}}
            value={moment(this.props.calendar_staff_list_form.start).format("YYYY-MM-DD")}
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            // onChange={this.onChangeDate}
            onChange={e => {
                this.onChangeDate(e.target.value)
              }
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                  // エンターキー押下時の処理
                  this.onChangeDate(e.target.value)
              }
            }}
          />


{/* 

          <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
            <KeyboardDatePicker
              fullWidth 
              // className='search_calendar'
              disableToolbar
              // containerStyle={{width: '100%'}}
              variant="inline"
              // variant="static"
              format="yyyy/MM/dd(EE)"
              margin="dense"
              id="date-picker-inline1"
              label="日付"
              // showTodayButton={true}
              style={{ float: "left", marginTop: "0" }}
              autoOk={true}
              InputLabelProps={{ shrink: true }}
              name="date"
              value={this.props.calendar_staff_list_form.start}
              onChange={this.onChangeDate}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    this.onChangeDate(e.target.value)
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage={"日付の形式が違います"}
            />

          </MuiPickersUtilsProvider> */}


        </Grid>

        <Grid xs={12} style={{ padding: 5 }}>


          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(-7)}
            size="large"><DoubleArrow fontSize="small" style={{ transform: "rotate(180deg)" }} /></IconButton>

          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(-1)}
            size="large"><NavigateNext fontSize="medium" style={{ transform: "rotate(180deg)" }} /></IconButton>
          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(0)}
            size="large"><Today fontSize="small" /></IconButton>
          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(1)}
            size="large"><NavigateNext fontSize="medium" /></IconButton>

          <IconButton
            style={{ padding: 5 }}
            onClick={() => this.onChangeDateButton(7)}
            size="large"><DoubleArrow fontSize="small" /></IconButton>

        </Grid>

        <Grid xs={12} style={{ padding: "15px 5px 5px 5px" }}>
          {this.props.calendar_search_store_list && this.props.calendar_search_store_list.length > 0 ? <FormControl size="small" style={{ width: "100%" }}>
            <InputLabel>部署を選択</InputLabel>
            <Select
              id="section_id"
              name="section_id"
              onChange={this.handleChange}
              value={this.props.calendar_staff_list_form.section_id}
              label="部署を選択"
              multiple
              variant='standard'
              renderValue={() => this.props.calendar_staff_list_form.section_id.length > 0 ? this.props.calendar_staff_list_form.section_id.map(function (item) {
                return parseInt(item) > 0 && this.props.calendar_search_store_list.filter(a => a.tenpo_no === parseInt(item)).length > 0 ? this.props.calendar_search_store_list.filter(a => a.tenpo_no === parseInt(item))[0].tenpo_name : ""
              }, this).join(',') : ""}
            >
              {this.props.calendar_search_store_list && this.props.calendar_search_store_list.length > 0 ? this.props.calendar_search_store_list.map(function (item, i) {
                return (
                  <MenuItem value={item.tenpo_no}><Checkbox checked={this.props.calendar_staff_list_form.section_id.indexOf(item.tenpo_no) > -1} /><ListItemText primary={item.tenpo_name} /></MenuItem>
                );

              }, this) : ""}
            </Select>
          </FormControl> : ""}

          {this.props.calendar_search_store_list && this.props.calendar_search_store_list.length > 0 ? <FormControlLabel
              control={
              <Checkbox
                  name="section_id_os"
                  checked={this.props.calendar_staff_list_form.section_id_os}
                  onChange={this.handleChange}
                  color="primary"
                  style={{marginLeft: 10}}
              />
              }
              label="管理部のみ"
            />:""}

          {/* {this.props.shops.length > 0 && this.props.user_details ? <Autocomplete
              id="combo-box-demo"
              options={this.props.shops.sort((a, b) => {return a.dispOrder < b.dispOrder ? -1 : 1;}).filter(a => a.tenpoNo > 0 && a.tenpoNo <=6 || a.tenpoNo == 7 || a.tenpoNo == 10 || a.tenpoNo == 16 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 47).filter(a=> a.tenpoNo != -1)}
              getOptionLabel={(option) => option.tenpoName}
              defaultValue={this.props.shops.filter(a => a.tenpoNo > 0 && a.tenpoNo <=6 || a.tenpoNo == 7 || a.tenpoNo == 10 || a.tenpoNo == 16 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 47).filter(a => a.tenpoNo === this.props.user_details.amSectionId)[0]}
              value={this.props.shops.filter(a => a.tenpoNo > 0 && a.tenpoNo <=6 || a.tenpoNo == 7 || a.tenpoNo == 10 || a.tenpoNo == 16 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 47).filter(a => a.tenpoNo === this.props.calendar_staff_list_form.section_id)[0]}
              style={{ width: "100%" }}
              onChange={this.handleChangeSectionId}
              renderInput={(params) => <TextField
                {...params} label="部署を選択" />}

            /> : ""} */}
        </Grid>
        



        <Grid xs={12} style={{ padding: 5, marginTop:20 }}>

          <TextField
            fullWidth
            id="start_total"
            label="開始"
            type="date"
            name="start_total"
            size="small"
            // style={{marginLeft:20}}
            value={moment(this.props.calendar_staff_list_form.start_total).format("YYYY-MM-DD")}
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            onChange={e => {
                this.onChangeDateTotalStart(e.target.value)
              }
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                  // エンターキー押下時の処理
                  this.onChangeDateTotalStart(e.target.value)
              }
            }}
          />
        </Grid>
        <Grid xs={12} style={{ padding: 5 }}>

          <TextField
            fullWidth
            id="end_total"
            label="終了"
            type="date"
            name="end_total"
            size="small"
            // style={{marginLeft:20}}
            value={moment(this.props.calendar_staff_list_form.end_total).format("YYYY-MM-DD")}
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            onChange={e => {
                this.onChangeDateTotalEnd(e.target.value)
              }
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                  // エンターキー押下時の処理
                  this.onChangeDateTotalEnd(e.target.value)
              }
            }}
          />


          {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>

            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy/MM/dd(EE)"
              margin="dense"
              id="date-picker-inline1"
              label="開始"
              // showTodayButton={true}
              style={{ float: "left", marginTop: "0" }}
              autoOk={true}
              InputLabelProps={{ shrink: true }}
              name="date"
              value={this.props.calendar_staff_list_form.start_total}
              onChange={this.onChangeDateTotalStart}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    this.onChangeDateTotalStart(e.target.value)
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage={"日付の形式が違います"}
            />
            <KeyboardDatePicker
              disableToolbar
              variant="inline"
              format="yyyy/MM/dd(EE)"
              margin="dense"
              id="date-picker-inline1"
              label="終了"
              // showTodayButton={true}
              style={{ float: "left", marginTop: "0" }}
              autoOk={true}
              InputLabelProps={{ shrink: true }}
              name="date"
              value={this.props.calendar_staff_list_form.end_total}
              onChange={this.onChangeDateTotalEnd}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                    // エンターキー押下時の処理
                    this.onChangeDateTotalEnd(e.target.value)
                }
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              invalidDateMessage={"日付の形式が違います"}
            />

          </MuiPickersUtilsProvider> */}

        </Grid>
        <Grid xs={12} style={{ padding: 5 }}>
          <Button
            disabled={this.props.calendar_staff_list_form.start_total == 'Invalid date' || this.props.calendar_staff_list_form.end_total == 'Invalid date'}
            size="small"
            startIcon={<GetAppIcon />}
            onClick={() => this.clickEventTotalExcel()}
            variant="contained"
            style={{ marginRight: 5 }}>Excel</Button>
        </Grid>

      </Grid >
    );
  }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users: state.masterReducer.users,
    users_select: state.masterReducer.users_select,
    section_select: state.masterReducer.section_select,
    shops: state.masterReducer.shops,

    calendar_list: state.calendar.calendar_list,
    calendar_staff_list_form: state.calendar.calendar_staff_list_form,
    calendar_type: state.calendar.calendar_type,
    calendar_search_store_list: state.calendar.calendar_search_store_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    change_calendar_staff_list_form(state) {
      dispatch(Calendarsaga.change_calendar_staff_list_form(state))
    },
    change_calendar_type(state) {
      dispatch(Calendarsaga.change_calendar_type(state))
    },
    get_calendar_type_total_csv(state) {
      dispatch(Calendarsaga.get_calendar_type_total_csv(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);

// ResponseListSearch = reduxForm({
//   form: 'formset', // a unique identifier for this form
//   onSubmit: submit
//   //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseListSearch)
