import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import ja from "date-fns/locale/ja";
import CloseIcon from '@mui/icons-material/Close';
import CheckBox from '@mui/material/Checkbox';
import * as YoyakuParking from './Saga/YoyakuParking';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { Modal, Row, Col, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
// import Modal from '@mui/material/Modal';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';

// import { CheckBox } from '@mui/icons-material';
var moment = require('moment-timezone');


const phoneNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];


const mobileNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex][column.key]}
  </Cell>;

const ContractDate = ({ column, props, rowIndex }) => <Cell style={{ whiteSpace: "nowrap" }} >
  {props.tm_yoyakuparkingarea_list[rowIndex].contraction_date ? moment(props.tm_yoyakuparkingarea_list[rowIndex].contraction_date).format('YYYY/MM/DD') : ""
  }

</Cell>;


const Basicl = ({ dispstate, props, changedata }) =>
<tr>
<th>申込日</th>
    <td>
    {moment(props.yoyaku_parking.created_at).format('YYYY/MM/DD')}
    </td>
    <th>連絡</th>
    <td>
    { dispstate ?
        <FormGroup>
            <FormControlLabel label={'連絡済'} style={{margin:0}}  control={<CheckBox defaultChecked={props.yoyaku_parking.Is_Renraku_Zumi} onChange={(e) => changedata({ name: 'Is_Renraku_Zumi', value: e.target.checked })}/>}/>
        </FormGroup>
        :
        props.yoyaku_parking.Is_Renraku_Zumi ? '連絡済':'未連絡'
        }
    
    </td>
</tr>



const Basicll = ({ dispstate, props, changedata }) =>
<tr >
    <th>備考</th>
    <td colSpan="3">
        { dispstate ?
            <TextField
                hiddenLabel
                id="outlined-name"
                size="small"
                label="備考"
                style={{width: "80%"}}
                defaultValue={props.yoyaku_parking.Park_Bikou}
                variant="outlined"
                onChange={(e) => changedata({ name: 'Park_Bikou', value: e.target.value })}
            />
            :
            props.yoyaku_parking.Park_Bikou
        }
    </td>

</tr>;

const Reservel = ({ dispstate, props, changedata }) =>
<tr>
    <th>お客様名</th>
    
    <td colSpan="3">
    { dispstate ? 
        <Grid item>    
            <TextField
                hiddenLabel
                id="outlined-name"
                size="small"
                label="お客様名"
                style={{width: "80%"}}
                defaultValue={props.yoyaku_parking.Kokyaku_Name}
                variant="outlined"
                onChange={(e) => changedata({ name: 'Kokyaku_Name', value: e.target.value })}
            />
        </Grid>
      :
      props.yoyaku_parking.Kokyaku_Name
      }
    </td>

</tr>;

const Reservell = ({ dispstate, props, changedata }) =>
<tr>
    <th>メール</th>
    <td>
        
    { dispstate ?
        <TextField
            hiddenLabel
            id="outlined-name"
            size="small"
            label="メール"
            defaultValue={props.yoyaku_parking.Mail}
            variant="outlined"
            onChange={(e) => changedata({ name: 'Mail', value: e.target.value })}
        />
    :
    props.yoyaku_parking.Mail
    }

    </td>
    
    <th>電話番号</th>
    <td>
    { dispstate ? 
        <TextField
            hiddenLabel
            id="outlined-name"
            size="small"
            label="電話番号"
            defaultValue={props.yoyaku_parking.Tel}
            variant="outlined"
            onChange={(e) => changedata({ name: 'Tel', value: e.target.value })}
            // onChange={()=> alert()}
        />
    :
    props.yoyaku_parking.Tel
    }
    </td>
</tr>;

const Reservelll = ({ dispstate, props, changedata }) =>
<tr >
    <th>駐車場備考</th>
    <td colSpan="3">
    { dispstate ? 
        <TextField
            hiddenLabel
            id="outlined-name"
            size="small"
            label="備考"
            style={{width: "80%"}}
            defaultValue={props.yoyaku_parking.Renraku_Bikou}
            variant="outlined"
            onChange={(e) => changedata({ name: 'Renraku_Bikou', value: e.target.value })}
        />
    :
        props.yoyaku_parking.Renraku_Bikou
    } 

    </td>

</tr>;

const Traderl = ({ dispstate, props, changedata }) =>
<tr>
    <th>業者名</th>
    <td colSpan="3">
    { dispstate ? 
    <Grid item>    
        <TextField
            hiddenLabel
            id="outlined-name"
            size="small"
            label="業者名"
            style={{width: "80%"}}
            defaultValue={props.yoyaku_parking.Gyousha_Name}
            variant="outlined"
            onChange={(e) => changedata({ name: 'Gyousha_Name', value: e.target.value })}
        />
    </Grid>:
        props.yoyaku_parking.Gyousha_Name
    }                                            
    </td>
</tr>;

const Traderll = ({ dispstate, props, changedata }) =>
<tr >
<th>業者担当者</th>
<td>
    { dispstate ? 
    <TextField
        hiddenLabel
        id="outlined-name"
        size="small"
        label="業者担当者"
        defaultValue={props.yoyaku_parking.Gyousha_Tantou}
        variant="outlined"
        onChange={(e) => changedata({ name: 'Gyousha_Tantou', value: e.target.value })}
    />
    :
    props.yoyaku_parking.Gyousha_Tantou
    }
</td>
<th>電話番号</th>
<td>
{ dispstate ? 
    <TextField
        hiddenLabel
        id="outlined-name"
        size="small"
        label="電話番号"
        defaultValue={props.yoyaku_parking.Gyousha_Tel}
        variant="outlined"
        onChange={(e) => changedata({ name: 'Gyousha_Tel', value: e.target.value })}
    />
    :
    props.yoyaku_parking.Gyousha_Tel
    }
</td>
</tr>;

const Traderlll = ({ dispstate, props, changedata }) =>
<tr>
    <th>備考</th>
    <td colSpan="3">
    { dispstate ? 
        <TextField
            hiddenLabel
            id="outlined-name"
            label="備考"
            size="small"
            style={{width: "80%"}}
            defaultValue={props.yoyaku_parking.Gyousha_Bikou}
            variant="outlined"
            onChange={(e) => changedata({ name: 'Gyousha_Bikou', value: e.target.value })}
        />
        :
        props.yoyaku_parking.Gyousha_Bikou}
    </td>
</tr>;


function age(birthDate) {
  birthDate = new Date(birthDate);
  // 文字列に分解
  const y2 = birthDate.getFullYear().toString().padStart(4, '0');
  const m2 = (birthDate.getMonth() + 1).toString().padStart(2, '0');
  const d2 = birthDate.getDate().toString().padStart(2, '0');

  // 今日の日付
  const today = new Date();
  const y1 = today.getFullYear().toString().padStart(4, '0');
  const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
  const d1 = today.getDate().toString().padStart(2, '0');

  // 引き算
  const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
  return age;

}



const Text = ({ column, props, rowIndex, change_coloumn }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex].selectcontract ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="color"
      value={props.tm_yoyakuparkingarea_list[rowIndex][column.key]}
      onChange={(e) => change_coloumn({ row: rowIndex, name: column.key, value: e.target.value })}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      // value={monies[rowIndex][column.key]}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.tm_yoyakuparkingarea_list[rowIndex][column.key]}

  </Cell>;

const SelectContract = ({ column, props, rowIndex, change_coloumn }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
        {props.tm_yoyakuparkingarea_list[rowIndex].selectcontract ? <Tooltip title="選択" >
        <IconButton size="small" aria-label="delete" onClick={() => change_coloumn({ row: rowIndex, name: "selectcontract", value: !props.tm_yoyakuparkingarea_list[rowIndex].selectcontract })}>
            <CheckCircleIcon color="primary" />
        </IconButton>
        </Tooltip>

        : <Tooltip title="申込" >
            <IconButton size="small" aria-label="delete" onClick={() => change_coloumn({ row: rowIndex, name: "selectcontract", value: !props.tm_yoyakuparkingarea_list[rowIndex].selectcontract })}>
            <IndeterminateCheckBoxIcon color="secondary" />
            </IconButton>
        </Tooltip>}
        </Cell>;

const Nyuukyo = ({ column, props, rowIndex, monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex].nyuukyo_kanou_year ? props.tm_yoyakuparkingarea_list[rowIndex].nyuukyo_kanou_year + '/' + props.tm_yoyakuparkingarea_list[rowIndex].nyuukyo_kanou_month + '/' + props.tm_yoyakuparkingarea_list[rowIndex].nyuukyo_kanou_day : ""}
  </Cell>;

const KuushitsuJoukyou = ({ column, props, rowIndex, monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == -2 ? "キャンセル" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 1 ? <span style={{fontWeight:"bold"}}>募集中</span> : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 2 ? "押さえ" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 3 ? "申込み" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 4 ? "入居中" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 5 ? "解約予定" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 6 ? "募集停止" : "f"}
  </Cell>;

const KanouKbn = ({ column, props, rowIndex, monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex].Kanou_Kbn_Name}
  </Cell>;
const Chinryou = ({ column, props, rowIndex, monies }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.tm_yoyakuparkingarea_list[rowIndex].Chinryou == 0 ? '-' : props.tm_yoyakuparkingarea_list[rowIndex].Is_Kazei ? String(Math.round(Number(props.tm_yoyakuparkingarea_list[rowIndex].Chinryou)*1.1).toLocaleString())+'円(内税'+String(Math.round(Number(props.tm_yoyakuparkingarea_list[rowIndex].Chinryou)*0.1).toLocaleString())+'円)' : props.tm_yoyakuparkingarea_list[rowIndex].Chinryou + '円'}
</Cell>;
const Shikikin = ({ column, props, rowIndex, monies }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.tm_yoyakuparkingarea_list[rowIndex].Shiki_Seikyuu_Gaku_Tsuki_Suu ? props.tm_yoyakuparkingarea_list[rowIndex].Shiki_Seikyuu_Gaku_Tsuki_Suu + 'ヶ月' : props.tm_yoyakuparkingarea_list[rowIndex].Shikikin ? props.tm_yoyakuparkingarea_list[rowIndex].Shikikin + '円' : '-'}
</Cell>;
const Reikin = ({ column, props, rowIndex, monies }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.tm_yoyakuparkingarea_list[rowIndex].Rei_Seikyuu_Gaku_Tsuki_Suu ? props.tm_yoyakuparkingarea_list[rowIndex].Rei_Seikyuu_Gaku_Tsuki_Suu + 'ヶ月' : props.tm_yoyakuparkingarea_list[rowIndex].Reikin ? props.tm_yoyakuparkingarea_list[rowIndex].Reikin + '円': '-'}
</Cell>;

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

const columns = [
  { key: 'selectcontract', name: '申込', width: 50 },
  { key: 'p_name', name: '駐車場名', width: 200 },
  { key: 'pa_name', name: '区画番号', width: 80},
  { key: 'kuushitsu_joukyou_no', name: '募集状況', width: 100 },
  { key: 'nyuukyo_kanou', name: '入居可能日', width: 90 },
  { key: 'kanou_kbn', name: '可能区分', width: 90 },
  { key: 'chinryou', name: '賃料', width: 180 },
  { key: 'shikikin', name: '敷金', width: 100 },
  { key: 'reikin', name: '礼金', width: 100 },
]







class CustomerList extends Component {
  constructor(props) {
    super(props);
    console.log(columns)
    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      editmodal:false,
      editdata:true,
      contract_date:moment().format('YYYY/MM/DD'),
      modal_area_more:false,
      DisabledState:true,
      SendDisabledState:false,
      kubun:0,
      mail:'',
      name:'',
      changecard:false,
      changedata:{},
    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.handleStartDate = this.handleStartDate.bind(this);
    this.handleKubun = this.handleKubun.bind(this);
    this.handleMail = this.handleMail.bind(this);
    this.handleName = this.handleName.bind(this);
    this.props.get_tm_yoyakuparkingarea_detail_list();
    // this.props.send_tm_yoyakuparkingarea_select_list();
    this.add_new = this.add_new.bind(this);
    this.change_coloumn = this.change_coloumn.bind(this);
    this.ChangeData = this.ChangeData.bind(this);
    this.handleCreate = this.handleCreate.bind(this);
    this.CreateResponse = this.CreateResponse.bind(this);
  }





  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })
    this.setState({ columns: columnsset })
  }



  add_new() {
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.family.push({
      name: "",
      relations: "",
      sex: 0,
      birthday: null,
      workplace: '',
      tel: '',
      nationality: '',
      remarks: '',
    })
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms)
    this.setState({ reflash: !this.state.reflash })
  }
  handleStartDate(e){
      // var date = moment(state).format('YYYY/MM/DD')
      // this.setState({contract_date: date})
      this.setState({contract_date: e.target.value})
  }
  handleKubun(state){
    this.setState({kubun: state.target.value})
  }
  handleMail(state){
    this.setState({mail: state.target.value})
  }
  handleName(state){
    this.setState({name: state.target.value})
  }

  ChangeData(set){
    console.log(set)
    let yoyakuparking_edit = this.props.yoyakuparking_edit
    yoyakuparking_edit[set.name] = set.value
    this.props.change_yoyakuparking_edit(yoyakuparking_edit)
}
  change_coloumn(set) {
    let tm_yoyakuparkingarea_list = this.props.tm_yoyakuparkingarea_list
    // this.props.tm_parkingarea_checked = !this.props.tm_parkingarea_checked
    if(set.value === true){
        this.setState({ DisabledState: false })
    }
    if(set.value === false && tm_yoyakuparkingarea_list.filter(a=> a.selectcontract == true).length == 1){
        this.setState({ DisabledState: true })
    }
    console.log(tm_yoyakuparkingarea_list.filter(a=> a.selectcontract == true).length)
    if(set.name == "selectcontract" && set.value == true){
        // if(1 <= tm_yoyakuparkingarea_list.filter(a=> a.selectcontract == true).length){
        this.setState({ modal_area_more: true })
        // return
    //   }
    }
    tm_yoyakuparkingarea_list[set.row][set.name] = set.value
    this.setState({ reflash: !this.state.reflash })
  }
  Send_application(value) {
    var selectedlist = this.props.tm_yoyakuparkingarea_list.filter((x) => x.selectcontract)
    selectedlist[0].contract_date = this.state.contract_date
    selectedlist[0].contractor_kubun = this.state.kubun
    selectedlist[0].contractor_name = this.state.name
    selectedlist[0].contractor_mail = this.state.mail
    this.props.send_tm_yoyakuparkingarea_select_list(selectedlist);
    this.setState({ editmodal: false })
  }
  OpenSendModal(){
     
    this.setState({mail: this.props.yoyaku_parking.Mail?this.props.yoyaku_parking.Mail:this.state.mail })
    this.setState({name: this.props.yoyaku_parking.Kokyaku_Name?this.props.yoyaku_parking.Kokyaku_Name:this.state.name })
    this.setState({ editmodal: true })
  }
  CreateResponse(){
    var selectedlist = this.props.yoyaku_parking;
    this.props.create_yoyaku_response(selectedlist);
  }
  CloseSendModal(){
    this.setState({ editmodal: false })
  }
  ChangeCard(state){
      var a = !this.state.changecard
      let yoyakuparking_edit = this.props.yoyakuparking_edit
      switch(state){
          case 'update':
            yoyakuparking_edit.is_deleted = false
            this.props.change_yoyaku_parking(yoyakuparking_edit)
            this.props.update_yoyakuparking(yoyakuparking_edit)
            alert("保存しました。");
            break;
          case 'delete':
            yoyakuparking_edit.is_deleted = true
            this.props.update_yoyakuparking(yoyakuparking_edit);
            this.props.change_yoyaku_parking_top_open(false);
            alert("削除しました。");
            break;
          default:
            break;
      }
      this.setState({changecard:a})
  }
  handleCreate(){
    let yoyaku_parking = this.props.yoyaku_parking
    this.props.create_yoyaku_parking(yoyaku_parking);
  }

  render() {
    let hiwari = []
    const { handleSubmit } = this.props;
    let paringimg_p_id = ""
    let paringimg = []
    this.props.tm_yoyakuparkingarea_list.map(function (value) {

      if (value.p_id === paringimg_p_id) {

      } else {
        paringimg_p_id = value.p_id

        paringimg.push(value)
      }

    })
    let info_status = false
    return (
        <div id="contract_car" >

          <Grid container spacing={2}>

            {paringimg.map(function (value) {
              return <Grid item={true} xs={6}>
                <Card style={{ margin: 5 }}>
                  <CardContent>
                    {/* <Col lg="12" className="item-outer" > */}
                    <div style={{ fontSize: 18, fontWeight: "bold" }}> {value.p_name}</div>
                    <img src={"https://img.global-center.co.jp/v1/parking/" + value.p_id + "/3/1/400/400/0"} alt="図面" />

                  </CardContent>
                </Card >
                
              </Grid>
            })}
              <Grid item={true} xs={6}>
                <Card style={{ margin: 5 }}>
                  <CardContent>
                    {/* <Col lg="12" className="item-outer" > */}
                    <form onSubmit={handleSubmit} >
                    <div >
                      {this.props.yoyaku_parking_new_create?<div>
                          <Button style={{ float: "left", margin: 5 }} size="small" variant="contained" color="primary" id="SendApplication" onClick={() => this.handleCreate()}>
                              空き待ち予約作成
                          </Button>
                          <Button style={{ float: "left", margin: 5 }} size="small" variant="contained" color="primary" id="SendApplication" disabled={this.state.DisabledState} onClick={() => this.OpenSendModal()}>
                              申込フォーム送信
                          </Button>
                      </div>:''}
                    <div style={{ fontSize: 18, fontWeight: "bold" }}>{!this.props.yoyaku_parking_new_create ?'基本情報': ''}
                      {!this.props.yoyaku_parking_new_create ? !this.state.changecard ?
                      <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.ChangeCard('edit')}>
                          編集
                      </Button> :
                      <Button style={{ float: "right", margin: 5 }} size="small" color="inherit" variant="contained"  onClick={() => this.ChangeCard('close')}>
                          閉じる
                      </Button>
                      : ''}
                      </div>
                      <table>
                      <colgroup width='20%' />
                      <colgroup width='30%' />
                      <colgroup width='20%' />
                      <colgroup width='30%' />

                          {!this.props.yoyaku_parking_new_create ?
                              <tbody>
                                  <Basicl dispstate={this.state.changecard} props={this.props} changedata={this.ChangeData}/>
                                  <Basicll dispstate={this.state.changecard} props={this.props} changedata={this.ChangeData}/>
                              </tbody>
                              :''
                          }
                      </table>
                      <div style={{ fontSize: 18, fontWeight: "bold" }}>{!this.props.yoyaku_parking_new_create ?　'予約者情報' : '' }</div>
                      <table>
                      <colgroup width='20%' />
                      <colgroup width='30%' />
                      <colgroup width='20%' />
                      <colgroup width='30%' />

                          {!this.props.yoyaku_parking_new_create ?
                          <tbody>
                              <Reservel dispstate={this.state.changecard} props={this.props} changedata={this.ChangeData}/>
                              <Reservell dispstate={this.state.changecard} props={this.props} changedata={this.ChangeData}/>
                              <Reservelll dispstate={this.state.changecard} props={this.props} changedata={this.ChangeData}/>
                          </tbody> :
                              ''
                          }
                      </table>




                      <div style={{ fontSize: 18, fontWeight: "bold" }}>{!this.props.yoyaku_parking_new_create ? '仲介業者情報' : ''}</div>
                      <table>
                      <colgroup width='20%' />
                      <colgroup width='30%' />
                      <colgroup width='20%' />
                      <colgroup width='30%' />

                          {!this.props.yoyaku_parking_new_create ?
                          <tbody>
                              <Traderl dispstate={this.state.changecard} props={this.props} changedata={this.ChangeData}/>
                              <Traderll dispstate={this.state.changecard} props={this.props} changedata={this.ChangeData}/>
                              <Traderlll dispstate={this.state.changecard} props={this.props} changedata={this.ChangeData}/>
                              {this.state.changecard ?
                              <tr>
                                  <th></th>
                                  <td colSpan="3">
                                      <Button style={{ float: "right", margin: 5, backgroundColor:"#4caf50", color:"#FFFFFF"}}  size="small" variant="contained"  onClick={() => this.ChangeCard('update')}>
                                              保存
                                      </Button>
                                      <Button style={{ float: "right", margin: 5, }} color="inherit" size="small" variant="contained"  onClick={() => this.ChangeCard('delete')}>
                                              削除
                                      </Button>
                                  </td>
                              </tr>:''}
                          </tbody> :
                             ''
                          }
                      </table>
                    </div>
                  </form>
                  </CardContent>
                </Card >
                
                
                  {this.props.yoyaku_parking.Mail ?
                  <Card style={{ margin: 5 }}>
                      <CardContent>
                          <Button style={{ float: "left", margin: 5 }} size="small" variant="contained" color="success" onClick={() => this.CreateResponse()}>
                              反響
                          </Button>
                          <Button style={{ float: "left", margin: 5 }} size="small" variant="contained" color="primary" disabled={this.state.DisabledState} id="SendApplication" onClick={() => this.OpenSendModal()}>
                              申込フォーム送信
                          </Button>
                      </CardContent>
                  </Card >
                :''
                  }
                      
              </Grid>
              {this.state.editmodal ?
                  <Modal isOpen={this.state.editmodal} toggle={() => { this.CloseSendModal() }}>

                      <div><IconButton
                          onClick={() => { this.CloseSendModal() }}
                          style={{ float: "right", marginTop: 0, marginRight: 0 }}
                          size="large"><CloseIcon /></IconButton></div>
                      <div>
                          <Card style={{ margin: 5 }}>
                          <table>
                              <tbody>
                                  <tr>
                                      <th colSpan="3">区分</th>
                                      <td colSpan="3">
                                      <RadioGroup
                                          row
                                          aria-labelledby="demo-row-radio-buttons-group-label"
                                          name="row-radio-buttons-group"
                                          defaultValue={this.state.kubun}
                                          onChange={this.handleKubun}
                                      >
                                          <FormControlLabel value="0" control={<Radio />} label="個人" />
                                          <FormControlLabel value="1" control={<Radio />} label="法人" />
                                      </RadioGroup>
                                      </td>
                                  </tr>
                                  <tr>
                                      <th colSpan="3">お客様名</th>
                                      <td colSpan="3">
                                      <TextField style={{ float: "left"  , margin: "10px 10px" ,width:'100%'}} defaultValue={this.props.yoyaku_parking.Kokyaku_Name ? this.props.yoyaku_parking.Kokyaku_Name : this.state.name} onChange={this.handleName} id="search standard-basic" label="お客様名" variant="standard" InputLabelProps={{ shrink: true }} />
                                      </td>
                                  </tr>
                                  <tr>
                                      <th colSpan="3">メールアドレス</th>
                                      <td colSpan="3">
                                      <TextField style={{ float: "left"  , margin: "10px 10px" ,width:'100%'}} defaultValue={this.props.yoyaku_parking.Mail ? this.props.yoyaku_parking.Mail : this.state.mail} onChange={this.handleMail} id="search standard-basic" label="メールアドレス" variant="standard" InputLabelProps={{ shrink: true }} />
                                      </td>
                                  </tr>
                                  <tr>
                                      <th colSpan="3">契約予定日</th>
                                          <td colSpan="3">
                                              {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
                                                  <div style={{width:"750"}}>
                                                  <TextField
                                                      id="contract_date"
                                                      label="契約日"
                                                      type="date"
                                                      name="contract_date"
                                                      size="small"
                                                      variant='outlined'
                                                      onChange={this.handleStartDate}
                                                      value={moment(this.state.contract_date).format("YYYY-MM-DD")}
                                                      InputLabelProps={{
                                                          shrink: true,
                                                      }}
                                                  />
                                                      {/* <KeyboardDatePicker
                                                          disableToolbar
                                                          variant="inline"
                                                          format="yyyy/MM/dd"
                                                          margin="normal"
                                                          id="date-picker-inline1"
                                                          label="契約日"
                                                          //   showTodayButton={true}
                                                          style={{ float: "left", marginTop: "0" }}
                                                          autoOk={true}
                                                          InputLabelProps={{ shrink: true }}
                                                          // error={true}
                                                          // helperText="契約日を選択してください"
                                                          //   errorMessages={['契約日を選択してください']}
                                                          name="contraction_date_start"
                                                          //   value={this.props.contract_follow_list_form.contraction_date_start}
                                                          value={this.state.contract_date}
                                                          // value={this.props.estimates.contractDate}
                                                          onChange={this.handleStartDate}
                                                          KeyboardButtonProps={{
                                                              'aria-label': 'change date',
                                                          }}
                                                          //   minDateMessage="契約日を選択してください"
                                                          // minDate={new Date("2000-01-01")}
                                                          invalidDateMessage={"日付の形式が違います"}
                                                          /> */}
                                                  </div>
                                              {/* </MuiPickersUtilsProvider> */}
                                          </td>
                                  </tr>
                              </tbody>
                          </table>
                          </Card >
                
                          <Card style={{ margin: 5 }}>
                              <CardContent>
                                  <Button style={{ float: "left", margin: 5 }} size="small" variant="contained" color="primary" disabled={this.state.mail === '' || this.state.name === '' ? true : false} onClick={() => this.Send_application()}>
                                      送信
                                  </Button>
                              </CardContent>
                          </Card >
                      </div>
                </Modal>
                :''
                  }
                  
            < Grid xs={12}>

              {<div style={{ marginTop: 10, marginBottom: 10 }}><Table
                rowHeight={40}
                rowsCount={this.props.tm_yoyakuparkingarea_list.length}
                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                isColumnResizing={false}
                touchScrollEnabled={true}
                width={window.innerWidth - 200}
                height={this.props.tm_yoyakuparkingarea_list.length * 40 + 60}
                // width={window.innerWidth - 110}
                // height={window.innerHeight - 250}
                headerHeight={40}>
                {this.state.columns.map(function (value, i) {
                  return <Column
                    columnKey={value.key}
                    header={<SortHeaderCell
                      onSortChange={this._onSortChange}
                      sortDir={this.state.colSortDirs[value.key]}
                      style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                    // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                    cell={({ rowIndex, ...props }) => (

                      value.key === "selectcontract" ? <SelectContract column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></SelectContract>
                         : value.key === "bango" ? <Text column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Text>

                            : value.key === "maker" ? <Text column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Text>

                              : value.key === "syasyu" ? <Text column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Text>

                                : value.key === "remarks" ? <Text column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></Text>
                                : value.key === "kuushitsu_joukyou_no" ? <KuushitsuJoukyou column={value} props={this.props} rowIndex={rowIndex} ></KuushitsuJoukyou>
                                  : value.key === "nyuukyo_kanou" ? <Nyuukyo column={value} props={this.props} rowIndex={rowIndex} ></Nyuukyo>

                                    : value.key === "kanou_kbn" ? <KanouKbn column={value} props={this.props} rowIndex={rowIndex} change_coloumn={this.change_coloumn}></KanouKbn>
                                      // : value.key === "tatemono_name" ? <TatemonoName column={value} props={this.props} rowIndex={rowIndex}></TatemonoName>
                                      //   : value.key === "room_name" ? <RoomName column={value} props={this.props} rowIndex={rowIndex}></RoomName>
                                          : value.key === "contraction_date" ? <ContractDate column={value} props={this.props} rowIndex={rowIndex}></ContractDate>
                                              : value.key === "chinryou" ? <Chinryou column={value} props={this.props} rowIndex={rowIndex}></Chinryou>
                                                  : value.key === "shikikin" ? <Shikikin column={value} props={this.props} rowIndex={rowIndex}></Shikikin>
                                                      // : value.key === "shiki_tsuki" ? <ShikiTsuki column={value} props={this.props} rowIndex={rowIndex}></ShikiTsuki>
                                                          : value.key === "reikin" ? <Reikin column={value} props={this.props} rowIndex={rowIndex}></Reikin>
                                                              // : value.key === "rei_tsuki" ? <ReiTsuki column={value} props={this.props} rowIndex={rowIndex}></ReiTsuki>





                                            //     : value.key === "relations" ? <Relations column={value} props={this.props} rowIndex={rowIndex} ></Relations>

                                            //       : value.key === "name" ? <Name column={value} props={this.props} rowIndex={rowIndex} ></Name>
                                            //         : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Delete>

                                            //           : value.key === "workplace" ? <Workplace column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Workplace>
                                            //             : value.key === "tel" ? <Tel column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Tel>
                                            //               : value.key === "nationality" ? <Nationality column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Nationality>
                                            //                 : value.key === "remarks" ? <Remarks column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Remarks>


                                            :
                                            <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                    )}

                    width={value.width}
                    isResizable={true}


                  />
                }, this)}

              </Table > </div>
              }


            </Grid>
          </Grid>
        </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    yoyaku_data: state.yoyakuparking,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    rent_responses_id: state.yoyakuparking.yoyaku_parking_responses_id,
    // rent_contract_details: state.yoyakuparking.rent_contract_details,
    // rent_contract_family_editor_disp: state.contracts.rent_contract_family_editor_disp,
    // rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    // t_parking_keiyaku_list: state.contracts.t_parking_keiyaku_list,
    yoyaku_parking: state.yoyakuparking.yoyaku_parking,
    tm_yoyakuparkingarea_list: state.yoyakuparking.tm_yoyakuparkingarea_list,
    tm_parkingarea_checked:false,
    yoyakuparking_edit:state.yoyakuparking.yoyakuparking_edit,
    yoyaku_parking_new_create:state.yoyakuparking.yoyaku_parking_new_create
  }
}
function mapDispatchToProps(dispatch) {
  return {


    // change_responseid(state) {
    //   dispatch(Responses.change_responseid(state))
    // },
    // change_modalResponseDetail(state) {
    //   dispatch(Responses.change_modalResponseDetail(state))
    // },
    // change_rent_contract_editor_forms(state) {
    //   dispatch(Contracts.change_rent_contract_editor_forms(state))
    // },
    // check_rent_contract_editor_disp(state) {
    //   dispatch(Contracts.check_rent_contract_editor_disp(state))
    // },
    // get_t_parking_keiyaku_list(state) {
    //   dispatch(Contracts.get_t_parking_keiyaku_list(state))
    // },
    // change_t_parking_keiyaku_list(state) {
    //   dispatch(Contracts.change_t_parking_keiyaku_list(state))
    // },
    get_tm_yoyakuparkingarea_detail_list(state) {
        dispatch(YoyakuParking.get_tm_yoyakuparkingarea_detail_list(state))
    },
    send_tm_yoyakuparkingarea_select_list(state) {
        dispatch(YoyakuParking.send_tm_yoyakuparkingarea_select_list(state))
    },
    change_yoyakuparking_edit(state) {
        dispatch(YoyakuParking.change_yoyakuparking_edit(state))
    },
    update_yoyakuparking(state) {
        dispatch(YoyakuParking.update_yoyakuparking(state))
    },
    change_yoyaku_parking_top_open(state) {
        dispatch(YoyakuParking.change_yoyaku_parking_top_open(state))
    },
    change_yoyaku_parking(state){
        dispatch(YoyakuParking.change_yoyaku_parking(state))
    },
    create_yoyaku_parking(state){
        dispatch(YoyakuParking.create_yoyaku_parking(state))
    },
    create_yoyaku_response(state){
        dispatch(YoyakuParking.create_yoyaku_response(state))
    },
    // change_tm_yoyakuparkingarea_list(state) {
    //   dispatch(Contracts.change_tm_yoyakuparkingarea_list(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);