import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import DriveEtaIcon from '@mui/icons-material/DriveEta';

import MaskedInput from 'react-text-mask';
import './Css/Grid.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import IconButton from '@mui/material/IconButton';

import Checkbox from '@mui/material/Checkbox';
import * as ContractFollow from './Saga/ContractFollow';
import FormControlLabel from '@mui/material/FormControlLabel';

import Tooltip from '@mui/material/Tooltip';

import { Modal, Row, Col, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ResponseComponent from "../ResponseDetail/ResponseComponent";

import * as Responses from '../ResponseDetail/Saga/Responses';
import * as Contracts from '../Contracts/Saga/Contracts';

import Typography from '@mui/material/Typography';

import MenuBookIcon from '@mui/icons-material/MenuBook';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import BackupIcon from '@mui/icons-material/Backup';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';

import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import Editor from './Editor';
import WebIcon from '@mui/icons-material/Web';
import Dialog from '@mui/material/Dialog';
import makeStyles from '@mui/styles/makeStyles';

import MobileFriendly from '@mui/icons-material/MobileFriendly';
let timer
var moment = require('moment-timezone');
let columns = []
// Table data as a list of array.
function timeMask(value) {
  const chars = value.split('');
  const hours = [
    /[0-2]/,
    chars[0] == '2' ? /[0-3]/ : /[0-9]/
  ];

  const minutes = [/[0-5]/, /[0-9]/];

  return hours.concat(':').concat(minutes);
}

var top = 0
var left = 0

var useStyles = makeStyles({
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
  },
  dialog: {
    position: 'absolute',
    left: left,
    top: top
  }
});

function SimpleDialog(props) {
  useStyles = makeStyles({
    avatar: {
      // backgroundColor: blue[100],
      // color: blue[600],
    },
    dialog: {
      position: 'absolute',
      left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
      top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),

      width: 520,
      height: 580
    }
  });

  const classes = useStyles();
  const { onClose, selectedValue, open, contract_follow_editer } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      // classes={{
      //   paper: classes.dialog
      // }}

      style={{
        position: 'absolute',
        left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
        top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),
  
        width: 520,
        height: 580
      }}
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {/* <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle> */}

      <Editor onClose={handleClose} />

    </Dialog>
  );
}















// Custom cell implementation with special prop
const MyCustomCell = ({ mySpecialProp }) =>
  <Cell>
    {mySpecialProp === "column2" ? "I'm column 2" : "I'm not column 2"}
  </Cell>;


const TatemonoName = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: 

      props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
      : (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? ""
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }} >{props.contract_follow_search_list[rowIndex].parking_application_id !== 0 && props.contract_follow_search_list[rowIndex].parking_application_id ? <DriveEtaIcon/>: ''}
    {/* <HomeWorkIcon color="primary" onClick={() => props.togglesheet(props.contract_follow_search_list[rowIndex].tatemono_id, props.contract_follow_search_list[rowIndex].room_id)} /> */}
    {props.contract_follow_search_list[rowIndex][column.key_name]}</Cell>;
const RoomName = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: 
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
    :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }} >{props.contract_follow_search_list[rowIndex].room_name}{props.contract_follow_search_list[rowIndex].room_name2 ? "," + props.contract_follow_search_list[rowIndex].room_name2 : ""}{props.contract_follow_search_list[rowIndex].room_name3 ? "," + props.contract_follow_search_list[rowIndex].room_name3 : ""}</Cell>;


const KanriKubun = ({ column, props, rowIndex, open_customer_window }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: 
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
    :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }} >
    <span>{props.contract_follow_search_list[rowIndex].division === 1 ? "サブ"
      : props.contract_follow_search_list[rowIndex].division === 2 ? "管理"
        : props.contract_follow_search_list[rowIndex].division === 3 ? "一般"
          : props.contract_follow_search_list[rowIndex].division === 4 ? "業物"
            : props.contract_follow_search_list[rowIndex].division === 5 ? "業物折半"
              : props.contract_follow_search_list[rowIndex].division === 6 ? "専任"
                : ""}</span>
    <span>{props.contract_follow_search_list[rowIndex].division !== 1 && props.contract_follow_search_list[rowIndex].division !== 2 ? props.contract_follow_search_list[rowIndex].kanri_corp_name : ""}</span>

    <IconButton
      onClick={() => open_customer_window(props.contract_follow_search_list[rowIndex])}
      size="large"><WebIcon color="primary" /></IconButton>
  </Cell>;


const Date = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: 
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
    :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }} >

    {props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}


  </Cell>;

const ContractDate = ({ column, props, rowIndex, contractdetail }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: 
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
    :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }} >
    <IconButton
      onClick={() => contractdetail(props.contract_follow_search_list[rowIndex].rent_response_id, props.contract_follow_search_list[rowIndex].rent_contract_id)}
      size="large">{props.contract_follow_search_list[rowIndex].cloudsign_completed_at ? <CloudDoneIcon color="primary"/> : props.contract_follow_search_list[rowIndex].cloudsign_created_at ? <BackupIcon color="error"/> :  <MenuBookIcon color="primary" />}</IconButton>
    {props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}


  </Cell>;
const DateCheck = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap", background: props.contract_follow_search_list[rowIndex][column.key_name] ? "#f5f5f5" : "pink", width: "100%", height: "100%" }} >{props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}</Cell>;


const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.contract_follow_search_list[rowIndex][column.key]}
  </Cell>;



const EndCheck = ({ column, props, rowIndex, set_contract_follow_end_bulk }) =>
  <Cell style={{ whiteSpace: "nowrap", margin: 10 }} >
    <FormControlLabel
      checked={(props.contract_follow_end_bulk.indexOf(props.contract_follow_search_list[rowIndex].rent_contract_id) !== -1)}
      // checked={props.contract_follow_search_list[rowIndex].end_check}
      onChange={() => set_contract_follow_end_bulk(props.contract_follow_search_list[rowIndex].rent_contract_id)}
      control={<Checkbox name="checkedC" />} />
  </Cell>;


function Follow({ column, props, rowIndex, contract_follow_editer }) {


  return <div>{

    ((props.contract_follow_search_list[rowIndex].counting === 2 || props.contract_follow_search_list[rowIndex].counting === -1) && !column.parking) ? <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "50px", background: "#696969" }} > </Cell > :

      column.key_name == 75 ? <Cell style={{ whiteSpace: "nowrap", width: "100%" }} >
        {!props.contract_follow_search_list[rowIndex].get_water_user_id ? <div style={{ whiteSpace: "nowrap", width: "100%", height: "50px", background: "#696969" }} > </div > :
          <div onClick={(e) => contract_follow_editer(column.key_name, rowIndex, props.contract_follow_search_list[rowIndex], e, false)} style={{
            borderBottom: "1px dashed  #9c9c9c", borderTop: "1px solid #9c9c9c",
            width: "100%",
            lineHeight: props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name] ? 0.9 : 1.5,
            height: "25px",
            backgroundColor: !props.contract_follow_editer.nyuukyosha && column.key_name && props.contract_follow_editer.key_name === column.key_name && rowIndex === props.contract_follow_editer.row_index ? "#ff7f50"
              : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "#ffae7f"
                : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') === moment().add(1, 'd').format('YYYY-MM-DD') ? "#87ceeb"
                  : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? "pink"
                    : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 ? "#7fffd4"
                      : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 2 ? "#CCC"
                        : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 4 ? "#696969"
                          : ""
          }}> <div>
              {props.contract_follow_search_list[rowIndex]["date_" + column.key_name] ? moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).tz("Asia/Tokyo").format('M月D日') : ""}
              {(props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 2) ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_" + column.key_name]).format("H:mm")}>{props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 ? <DirectionsWalkIcon color="primary" style={{ fontSize: "16px" }} /> : <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} />}</Tooltip> : ""}
              浄水器
            </div>



            {props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}><div style={{ fontSize: "10px", background: props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 0 ? "#ffd700" : "" }}>{props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}</div></Tooltip> : ""}

          </div>}
        {!props.contract_follow_search_list[rowIndex].get_shower_user_id ? <div style={{ whiteSpace: "nowrap", width: "100%", height: "50px", background: "#696969" }} > </div > : <div onClick={(e) => contract_follow_editer(column.key_name, rowIndex, props.contract_follow_search_list[rowIndex], e, true)} style={{
          width: "100%",
          height: "25px",
          lineHeight: props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? 0.9 : 1.5,
          backgroundColor: props.contract_follow_editer.nyuukyosha && column.key_name && props.contract_follow_editer.key_name === column.key_name && rowIndex === props.contract_follow_editer.row_index ? "#ff7f50"
            : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "#ffae7f"
              : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).format('YYYY-MM-DD') === moment().add(1, 'd').format('YYYY-MM-DD') ? "#87ceeb"
                : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? "pink"
                  : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 ? "#7fffd4"
                    : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 2 ? "#CCC"
                      : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 4 ? "#696969"
                        : ""
        }}>
          <div>
            {props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name] ? moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).tz("Asia/Tokyo").format('M月D日') : ""}
            {/* {props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}><ChatIcon color="primary" /></Tooltip> : ""}</div> */}
            {(props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 2) ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_n_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_n_" + column.key_name]).format("H:mm")}>{props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 1 ? <DirectionsWalkIcon color="primary" style={{ fontSize: "16px" }} /> : <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} />}</Tooltip> : ""}
            シャワー
          </div>
          {props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}><div style={{ fontSize: "10px", background: props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 0 ? "#ffd700" : "" }}>{props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}</div></Tooltip> : ""}
        </div>
        }
      </Cell >
        :
        props.contract_follow_search_list[rowIndex].contract_form === 1 && column.key_name != 3 ? 
        column.key_name == 78 && !props.contract_follow_search_list[rowIndex].need_parking_certificate ? <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "50px", background: "#696969" }} > </Cell > :<Cell style={{ whiteSpace: "nowrap", width: "100%" }} >
          <div onClick={(e) => contract_follow_editer(column.key_name, rowIndex, props.contract_follow_search_list[rowIndex], e, false)} style={{
            borderBottom: "1px dashed  #9c9c9c", borderTop: "1px solid #9c9c9c",
            width: "100%",
            lineHeight: props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name] ? 0.9 : 1.5,
            height: "25px",
            backgroundColor: !props.contract_follow_editer.nyuukyosha && column.key_name && props.contract_follow_editer.key_name === column.key_name && rowIndex === props.contract_follow_editer.row_index ? "#ff7f50"
              : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "#ffae7f"
                : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') === moment().add(1, 'd').format('YYYY-MM-DD') ? "#87ceeb"
                  : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? "pink"
                    : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 ? "#7fffd4"
                      : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 2 ? "#CCC"
                        : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 4 ? "#696969"
                          : ""
          }}> <div>
              {props.contract_follow_search_list[rowIndex]["date_" + column.key_name] ? moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).tz("Asia/Tokyo").format('M月D日') : ""}
              {(props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 2) ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_" + column.key_name]).format("H:mm")}>{props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 ? <DirectionsWalkIcon color="primary" style={{ fontSize: "16px" }} /> : <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} />}</Tooltip> : ""}

              {column.key_name == 6 && props.contract_follow_search_list[rowIndex].contract_money ? "【" + props.contract_follow_search_list[rowIndex].contract_money.toLocaleString() + "円】" : ""}
            </div>



            {props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}><div style={{ fontSize: "10px", background: props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 0 ? "#ffd700" : "" }}>{props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}</div></Tooltip> : ""}

          </div>
          <div onClick={(e) => contract_follow_editer(column.key_name, rowIndex, props.contract_follow_search_list[rowIndex], e, true)} style={{
            width: "100%",
            height: "25px",
            lineHeight: props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? 0.9 : 1.5,
            backgroundColor: props.contract_follow_editer.nyuukyosha && column.key_name && props.contract_follow_editer.key_name === column.key_name && rowIndex === props.contract_follow_editer.row_index ? "#ff7f50"
              : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "#ffae7f"
                : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).format('YYYY-MM-DD') === moment().add(1, 'd').format('YYYY-MM-DD') ? "#87ceeb"
                  : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? "pink"
                    : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 ? "#7fffd4"
                      : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 2 ? "#CCC"
                        : props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 4 ? "#696969"
                          : ""
          }}>
            <div>
              {props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name] ? moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).tz("Asia/Tokyo").format('M月D日') : ""}
              {/* {props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}><ChatIcon color="primary" /></Tooltip> : ""}</div> */}
              {(props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 2) ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_n_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_n_" + column.key_name]).format("H:mm")}>{props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 1 ? <DirectionsWalkIcon color="primary" style={{ fontSize: "16px" }} /> : <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} />}</Tooltip> : ""}
              {column.key_name == 6 && props.contract_follow_search_list[rowIndex].contract_money_tenant ? "【" + props.contract_follow_search_list[rowIndex].contract_money_tenant.toLocaleString() + "円】" : ""}

            </div>
            {props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}><div style={{ fontSize: "10px", background: props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 0 ? "#ffd700" : "" }}>{props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}</div></Tooltip> : ""}
          </div>
        </Cell >
          : column.key_name == 78 && !props.contract_follow_search_list[rowIndex].need_parking_certificate ? <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "50px", background: "#696969" }} > </Cell > :
          <Cell onClick={(e) => contract_follow_editer(column.key_name, rowIndex, props.contract_follow_search_list[rowIndex], e, false)} style={{
            whiteSpace: "nowrap", width: "100%",
            background: column.key_name && props.contract_follow_editer.key_name === column.key_name && rowIndex === props.contract_follow_editer.row_index ? "#ff7f50"
              : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "#ffae7f"
                : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') === moment().add(1, 'd').format('YYYY-MM-DD') ? "#87ceeb"
                  : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? "pink"
                    : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 ? "#7fffd4"
                      : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 2 ? "#CCC"
                        : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 4 ? "#696969"
                          : ""
          }} >
            <div style={{ width: "100%", height: "50px", borderTop: "1px solid  #9c9c9c" }}>
              <div> {props.contract_follow_search_list[rowIndex]["date_" + column.key_name] ? moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).tz("Asia/Tokyo").format('M月D日') : ""}
                {(props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 2) ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_" + column.key_name]).format("H:mm")}>{props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 ? <DirectionsWalkIcon color="primary" style={{ fontSize: "16px" }} /> : <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} />}</Tooltip> : ""}
                {/* : props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 2 ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_" + column.key_name]).format("H:mm")}> <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} /></Tooltip> : ""} */}
                {column.key_name == 6 && props.contract_follow_search_list[rowIndex].contract_money ? "【" + props.contract_follow_search_list[rowIndex].contract_money.toLocaleString() + "円】" : ""}
              </div>
              {props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}><div style={{ background: props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 0 ? "#ffd700" : "" }}>{props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}</div></Tooltip> : ""}</div>

          </Cell >
          
  }

  </div >
};

const Staff = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: 
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
    :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }}>
    {props.contract_follow_search_list[rowIndex].operation_user_id > 0 && props.contract_follow_search_list[rowIndex].user_id !== props.contract_follow_search_list[rowIndex].operation_user_id ? <div><div>{props.contract_follow_search_list[rowIndex].operation_user_nickname}</div><div>{props.contract_follow_search_list[rowIndex].user_nickname}</div></div> : <div>{props.contract_follow_search_list[rowIndex].user_nickname}</div>}
  </Cell>;

const NameF = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", borderTop: "1px solid  #9c9c9c", background: 
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
    :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }}  >

    {(props.contract_follow_search_list[rowIndex].agent_name != null && props.contract_follow_search_list[rowIndex].agent_name != "") || (props.contract_follow_search_list[rowIndex].agent_etc != null && props.contract_follow_search_list[rowIndex].agent_etc != "") ? <div style={{ width: "100%", height: "10px", textAlign: "left", padding: 0, fontSize: 9 }} >{props.contract_follow_search_list[rowIndex].agent_name}{props.contract_follow_search_list[rowIndex].agent_etc}</div> : ""}
    <div style={{ borderBottom: "1px dashed #9c9c9c", width: "100%", height: (props.contract_follow_search_list[rowIndex].agent_name != null && props.contract_follow_search_list[rowIndex].agent_name != "") || (props.contract_follow_search_list[rowIndex].agent_etc != null && props.contract_follow_search_list[rowIndex].agent_etc != "") ? 15 : 25, textAlign: "left", padding: 0, fontSize: (props.contract_follow_search_list[rowIndex].agent_name != null && props.contract_follow_search_list[rowIndex].agent_name != "") || (props.contract_follow_search_list[rowIndex].agent_etc != null && props.contract_follow_search_list[rowIndex].agent_etc != "") ? 12 : 14 }} >
    
      
      
      {props.contract_follow_search_list[rowIndex].name1}
      
    </div>
    <div style={{
      textAlign: "left", width: "100%", height: "25px", background: 
      props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
      :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                            // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                              : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                                : ""
    }}>
      {parseInt(props.contract_follow_search_list[rowIndex].room_support_m_user_id) > 0 ? <MobileFriendly style={{color:"#4abdba", fontSize:15, marginTop:-1}}  /> : ""}
      {props.contract_follow_search_list[rowIndex].line_img_url ? props.contract_follow_search_list[rowIndex].line_img_url.split(',').map(function (item, key) {
        return (
          props.contract_follow_search_list[rowIndex].line_img_url.split(',')[key] != "" ? 
          <span style={{position: "relative"}}><img className="faceiconcontract_houjin" src={'https://img.global-center.co.jp/v1/line/img/'+ props.contract_follow_search_list[rowIndex].line_user_id.split(',')[key] +'/200/200'} alt="lineimg" />{props.contract_follow_search_list[rowIndex].unfollowed_at && props.contract_follow_search_list[rowIndex].unfollowed_at.split(',')[key] ? <i style={{ color: "red", position: "absolute", right: 0, top: 0 }} class="fas fa-ban fa-xs"></i> : ""}</span>
          :
          props.contract_follow_search_list[rowIndex].unfollowed_at && props.contract_follow_search_list[rowIndex].unfollowed_at.split(',')[key] ? <i style={{ color: "red" }} class="fas fa-ban fa-lg"></i> : ""
        )
      }, this) : ""
      }
      {props.contract_follow_search_list[rowIndex].name2}
    </div></Cell >;

const Name = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: 
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
    :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }}  >
{parseInt(props.contract_follow_search_list[rowIndex].room_support_m_user_id) > 0 ? <MobileFriendly style={{color:"#4abdba", marginTop:-2}} /> : ""}
    {props.contract_follow_search_list[rowIndex].line_img_url ? props.contract_follow_search_list[rowIndex].line_img_url.split(',').map(function (item, key) {
      return (
        props.contract_follow_search_list[rowIndex].line_img_url.split(',')[key] != "" ? 
        <span style={{position: "relative"}}><img className="faceiconcontract" src={'https://img.global-center.co.jp/v1/line/img/'+ props.contract_follow_search_list[rowIndex].line_user_id.split(',')[key] +'/200/200'} alt="lineimg" />{props.contract_follow_search_list[rowIndex].unfollowed_at && props.contract_follow_search_list[rowIndex].unfollowed_at.split(',')[key] ? <i style={{ color: "red", position: "absolute", right: 0, top: 0 }} class="fas fa-ban fa-lg"></i> : ""}</span>
        :
        props.contract_follow_search_list[rowIndex].unfollowed_at && props.contract_follow_search_list[rowIndex].unfollowed_at.split(',')[key] ? <i style={{ color: "red" }} class="fas fa-ban fa-lg"></i> : ""
      )
    }, this) : ""}
    {props.contract_follow_search_list[rowIndex].name1}
  </Cell >;

const Section = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: 
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
      : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"
    :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"
                              : ""
  }} >{props.contract_follow_search_list[rowIndex].section_name}</Cell >;

const Status = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap",
    height: "100%",
    width: "100%",
    borderTop: "1px solid  #9c9c9c",
    background:
    
    props.contract_follow_search_list[rowIndex].receive_mail_at ? "#ffe6b3"
    : props.contract_follow_search_list[rowIndex].receive_line_at ? "#d7ffb3"

      :(props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                            // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                              : props.contract_follow_search_list[rowIndex].counting === 2 ? "#cccccc"

                                : ""
  }} >
    <div>{
    
    props.contract_follow_search_list[rowIndex].receive_mail_at ? <span className="badge badge-danger">メール受信</span>
    : props.contract_follow_search_list[rowIndex].receive_line_at ? <span className="badge badge-danger">LINE受信</span>
    
    // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? <span className="badge badge-danger">メール受信</span>
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? <span className="badge badge-success">メール送信</span>
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? <span className="badge badge-info">自動送信</span>
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? <span className="badge badge-secondary">既読</span>
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? <span className="badge badge-secondary">反響受信</span>
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? <span className="badge badge-secondary">対応</span>
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? <span className="badge badge-danger">マッチング物件なし</span>
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? <span className="badge badge-danger"></span>
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? <span className="badge badge-success">LINE送信</span>
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? <span className="badge badge-secondary">最終対応</span>
                        // : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? <span className="badge badge-danger">LINE受信</span>

                          : ""}
      {(props.contract_follow_search_list[rowIndex].counting === 0 || props.contract_follow_search_list[rowIndex].counting === -1) ? <span className="badge  badge-info">申込審査中</span> : ""}
      {(props.contract_follow_search_list[rowIndex].contract_follow_comp_date) ? <span className="badge badge-secondary">完了済み</span> : ""}
      

    </div>
    <div style={{ textAlign: "left" }}>{props.contract_follow_search_list[rowIndex].last_response_date ? moment(props.contract_follow_search_list[rowIndex].last_response_date).utc().format("YYYY/MM/DD HH:mm") : ""}</div >



  </Cell >;


var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}




function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={timeMask}
      // placeholderChar={'\u2000'}
      showMask
    />
  );
}
class CustomerList extends Component {
  constructor(props) {
    super(props);



    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      focus_start: false,
      focus_end: false,
      // open: false,
      selectedValue: false,
      anchorEl: null,
      open: false,
      jyusetu : false,
      top: 0,
      left: 0
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.contract_follow_editer = this.contract_follow_editer.bind(this);
    this.close_contract_follow_editer = this.close_contract_follow_editer.bind(this);
    this.togglereserve = this.togglereserve.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.contractdetail = this.contractdetail.bind(this);
    // this.handle_change_user_id = this.handle_change_user_id.bind(this);
    this.change_schedule = this.change_schedule.bind(this);

    this.open_customer_window = this.open_customer_window.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.set_contract_follow_end_bulk = this.set_contract_follow_end_bulk.bind(this);


  }
  componentDidMount() {

  }
  open_customer_window(row) {

    window.open(`/contracts/` + row.rent_contract_id, '_blank', 'noopener')
  }


  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.props.rent_m_contract_follows_disp.map(function (value) {
      if (value.key_name === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }



  contract_follow_editer(key_name, rowIndex, row, e, nyuukyosha) {

    if (this.props.contract_follow_easy_mode) {
      if(key_name == 3){
        this.setState({
          open: true,
          jyusetu: true
        })
        this.setState({
          top:
            e.target.getBoundingClientRect().top

        })
        this.setState({
          left:
            e.target.getBoundingClientRect().left

        })

        clearTimeout(timer);
        timer = setTimeout(function () {
          this.setState({
            open: false,
            jyusetu: false
          })
        }.bind(this), 800);
      }else if (nyuukyosha && this.props.contract_follow_search_list[rowIndex]["date_n_" + key_name]) {
        // alert(e.target.getBoundingClientRect().top)
        // alert(JSON.stringfy(e.currentTarget))
        this.setState({
          open: true,
        })
        this.setState({
          top:
            e.target.getBoundingClientRect().top

        })
        this.setState({
          left:
            e.target.getBoundingClientRect().left

        })

        clearTimeout(timer);
        timer = setTimeout(function () {
          this.setState({
            open: false
          })
        }.bind(this), 800);
      } else if (!nyuukyosha && this.props.contract_follow_search_list[rowIndex]["date_" + key_name]) {
        console.log(e.target)
        this.setState({
          open: true
        })
        this.setState({
          top:
            e.target.getBoundingClientRect().top

        })
        this.setState({
          left:
            e.target.getBoundingClientRect().left

        })


        clearTimeout(timer);
        timer = setTimeout(function () {
          this.setState({
            open: false
          })
        }.bind(this), 800);

        // alert("すでに登録があります。")
      } else {
        this.props.update_contract_follow_easy_mode({ nyuukyosha: nyuukyosha, rent_contract_id: row.rent_contract_id, rent_m_contract_follow_id: key_name })
      }
      // this.props.contract_follow_search_list[rowIndex]["date_" + key_name] ?
      //   contract_follow_editer.rent_contract_id = row.rent_contract_id

    } else {
      console.log("エディタ最初")

      top = e.target.getBoundingClientRect().top
      left = e.target.getBoundingClientRect().left
      this.handleClickOpen()
      // alert(e.target.getBoundingClientRect().x)
      if (e.target.getBoundingClientRect().x - 580 < 0 && e.target.getBoundingClientRect().x + 500 > window.innerWidth) {
        this.setState({ cardx: 0 })
      } else if (e.target.getBoundingClientRect().x - 580 >= 0) {
        this.setState({ cardx: e.target.getBoundingClientRect().x - 580 })

      }
      else if (e.target.getBoundingClientRect().x + 580 >= 0) {
        this.setState({ cardx: e.target.getBoundingClientRect().x })

      }
      let contract_follow_editer = this.props.contract_follow_editer


      contract_follow_editer.rent_contract_id = row.rent_contract_id
      contract_follow_editer.rent_response_id = row.rent_response_id
      contract_follow_editer.rent_m_contract_follow_id = key_name

      contract_follow_editer.open = true
      contract_follow_editer.contract_form = row.contract_form
      contract_follow_editer.title = this.props.rent_m_contract_follows_disp.filter(a => a.key_name === key_name)[0].name
      // contract_follow_editer.contract_form = row.contract_form
      contract_follow_editer.tatemono_name = row.tatemono_name
      contract_follow_editer.room_name = row.room_name
      // contract_follow_editer.section_id = this.props.contract_follow_search_list[rowIndex]["section_id"] ? this.props.contract_follow_search_list[rowIndex]["section_id"] : this.props.user_details.am_section_id
      contract_follow_editer.key_name = key_name
      contract_follow_editer.row_index = rowIndex
      contract_follow_editer.is_time = this.props.rent_m_contract_follows_disp.filter(a => a.key_name === key_name)[0].is_time
      contract_follow_editer.agent_name = row.agent_name
      contract_follow_editer.agent_etc = row.agent_etc

      contract_follow_editer.web_video_call_reservation_id = row.web_video_call_reservation_id
      contract_follow_editer.web_video_call_reservation_uuid = row.web_video_call_reservation_uuid
      if (nyuukyosha) {

        contract_follow_editer.name = row.name2
        contract_follow_editer.nyuukyosha = true
        contract_follow_editer.user_id = this.props.contract_follow_search_list[rowIndex]["user_id_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["user_id_n_" + key_name] : this.props.user_details.id
        contract_follow_editer.section_id = this.props.contract_follow_search_list[rowIndex]["section_id_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["section_id_n_" + key_name] : contract_follow_editer.user_id > 0 ? this.props.users.filter(a => a.id == contract_follow_editer.user_id)[0].amSectionId : null
        contract_follow_editer.date = this.props.contract_follow_search_list[rowIndex]["date_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["date_n_" + key_name] : null
        contract_follow_editer.datetime_start = moment(this.props.contract_follow_search_list[rowIndex]["datetime_start_n_" + key_name]).isValid() ? this.props.contract_follow_search_list[rowIndex]["datetime_start_n_" + key_name] : null
        contract_follow_editer.datetime_end = moment(this.props.contract_follow_search_list[rowIndex]["datetime_end_n_" + key_name]).isValid() ? this.props.contract_follow_search_list[rowIndex]["datetime_end_n_" + key_name] : null
        contract_follow_editer.remarks = this.props.contract_follow_search_list[rowIndex]["remarks_n_" + key_name]
        contract_follow_editer.way = this.props.contract_follow_search_list[rowIndex]["way_n_" + key_name]
        contract_follow_editer.progress = this.props.contract_follow_search_list[rowIndex]["progress_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["progress_n_" + key_name] : 0



      } else {
        contract_follow_editer.nyuukyosha = false
        contract_follow_editer.name = row.name1
        contract_follow_editer.user_id = this.props.contract_follow_search_list[rowIndex]["user_id_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["user_id_" + key_name] : this.props.user_details.id
        // contract_follow_editer.user_id = this.props.contract_follow_search_list[rowIndex]["user_id_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["user_id_" + key_name] : key_name == 3 ? "" : this.props.user_details.id
        contract_follow_editer.section_id = this.props.contract_follow_search_list[rowIndex]["section_id_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["section_id_" + key_name] : contract_follow_editer.user_id > 0 ? this.props.users.filter(a => a.id == contract_follow_editer.user_id)[0].amSectionId : null
        contract_follow_editer.date = this.props.contract_follow_search_list[rowIndex]["date_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["date_" + key_name] : null
        contract_follow_editer.datetime_start = moment(this.props.contract_follow_search_list[rowIndex]["datetime_start_" + key_name]).isValid() ? this.props.contract_follow_search_list[rowIndex]["datetime_start_" + key_name] : null
        contract_follow_editer.datetime_end = moment(this.props.contract_follow_search_list[rowIndex]["datetime_end_" + key_name]).isValid() ? this.props.contract_follow_search_list[rowIndex]["datetime_end_" + key_name] : null
        contract_follow_editer.remarks = this.props.contract_follow_search_list[rowIndex]["remarks_" + key_name]
        contract_follow_editer.way = this.props.contract_follow_search_list[rowIndex]["way_" + key_name]
        // contract_follow_editer.progress = this.props.contract_follow_search_list[rowIndex]["progress_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["progress_" + key_name] : 0
        contract_follow_editer.progress = this.props.contract_follow_search_list[rowIndex]["progress_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["progress_" + key_name] : key_name == 3 ? 1 : 0
      }
      console.log("エディタ描画")

      this.props.change_contract_follow_editer(contract_follow_editer)
    }
    this.setState({ reflesh: !this.props.reflesh })
  }

  close_contract_follow_editer(row) {
    console.log("ddd")
    let contract_follow_editer = this.props.contract_follow_editer

    contract_follow_editer.open = false
    contract_follow_editer.key_name = 0
    contract_follow_editer.row_index = 0
    contract_follow_editer.contract_form = 0
    contract_follow_editer.name = ''
    contract_follow_editer.is_time = false

    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
  }

  change_schedule(date) {
    let contract_follow_editer = this.props.contract_follow_editer

    // alert(date.start)
    contract_follow_editer.date = date.start
    contract_follow_editer.datetime_start = date.start
    contract_follow_editer.datetime_end = date.end
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
  }
  togglereserve() {
    this.setState({ modalreserve: !this.state.modalreserve })
  }
  handleOnChange(e) {

    let contract_follow_editer = this.props.contract_follow_editer
    contract_follow_editer[e.target.name] = e.target.value
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
  }



  _onSortChange(columnKey, sortDir) {
    // const ratz = /[a-z]/
    // alert(ratz.test(columnKey))

    // alert(_defaultSortIndexes.indexOf(columnKey))
    let contract_follow_search_list = this.props.contract_follow_search_list;
    // columnKey = ratz.test(columnKey) ? columnKey : "date_" + columnKey

    // columnKey = columnKey === "kanri_kubun" ? "kanri_kubun_search" : columnKey


    // alert(columnKey)

    contract_follow_search_list.sort((indexA, indexB) => {
      // alert(indexA)

      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }

      

      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.props.change_contract_follow_search_list(contract_follow_search_list)
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }




  contractdetail(response_id, rent_contract_id) {
    this.props.change_modalResponseDetail(true)
    this.props.change_responseid(response_id)
    this.props.change_rent_contract_id(rent_contract_id)
    if(rent_contract_id == 18 || rent_contract_id == 19 || !rent_contract_id){
        this.props.change_rent_contract_editor_disp(true);
    }

  }

  handleClickOpen = () => {
    this.props.change_contract_follow_editer_open(true)
    // this.setState({ open: true })
  };

  handleClose = (value) => {
    // this.setState({ open: false })
    this.props.change_contract_follow_editer_open(false)
  };

  set_contract_follow_end_bulk = (value) => {
    // this.setState({ open: false })
    let contract_follow_end_bulk = this.props.contract_follow_end_bulk
    if (contract_follow_end_bulk.indexOf(value) !== -1) {
      contract_follow_end_bulk.splice(contract_follow_end_bulk.indexOf(value), 1)
    } else {
      contract_follow_end_bulk.push(value)

    }
    this.props.change_contract_follow_end_bulk([])
    this.props.change_contract_follow_end_bulk(contract_follow_end_bulk)
    this.setState({ reflash: !this.state.reflash })
  };


  render() {
    // if (this.myRef.current) {
    //   console.log(this.myRef.current.getBoundingClientRect())
    //   alert()

    // }
    return (
      <div id="contract_follow_list" >
        {/* {this.props.contract_follow_editer.open ? <div onClick={this.close_contract_follow_editer} style={{
          position: "absolute", zIndex: 2000, width: window.innerWidth - 110, height: window.innerHeight - 300
        }}></div> : ""}



        {this.props.contract_follow_editer.open ? <Editor close_contract_follow_editer={this.close_contract_follow_editer} /> : ""} */}




        <Table
          rowHeight={50}
          rowsCount={this.props.contract_follow_search_list.length}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          isColumnResizing={false}
          touchScrollEnabled={true}
          width={window.innerWidth - 110}
          height={window.innerHeight - 250}

          headerHeight={40}>
          {this.props.rent_m_contract_follows_disp.map(function (value, i) {
            return <Column
              columnKey={value.key_name}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key_name]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
              cell={({ rowIndex, ...props }) => (

                value.key_name === "tatemono_name" ? <TatemonoName column={value} props={this.props} rowIndex={rowIndex}></TatemonoName>
                  : value.key_name === "room_name" ? <RoomName column={value} props={this.props} rowIndex={rowIndex}></RoomName>
                    : value.key_name === "kanri_kubun" ? <KanriKubun column={value} props={this.props} rowIndex={rowIndex} open_customer_window={this.open_customer_window}></KanriKubun>

                      : value.key_name === "application_date" ? <Date column={value} props={this.props} rowIndex={rowIndex}></Date>
                        : value.key_name === "contract_date" ? <ContractDate column={value} props={this.props} rowIndex={rowIndex} contractdetail={this.contractdetail}></ContractDate>
                          : value.key_name === "name1" ? this.props.contract_follow_search_list[rowIndex].contract_form === 1 ? <NameF column={value} props={this.props} rowIndex={rowIndex}></NameF> : <Name column={value} props={this.props} rowIndex={rowIndex}></Name>

                            : value.key_name === "section_name" ? <Section column={value} props={this.props} rowIndex={rowIndex}></Section>

                              : value.key_name === "user_nickname" ? <Staff column={value} props={this.props} rowIndex={rowIndex}></Staff>
                                : value.key_name === "operation_user_nickname" ? <Base column={value} props={this.props} rowIndex={rowIndex}></Base>

                                  : value.key_name === 2 ? <Follow column={value} props={this.props} rowIndex={rowIndex} contract_follow_editer={this.contract_follow_editer}></Follow>
                                    : value.key_name === "status" ? <Status column={value} props={this.props} rowIndex={rowIndex}></Status>

                                      : value.key_name === "end_check" ? <EndCheck column={value} props={this.props} rowIndex={rowIndex} set_contract_follow_end_bulk={this.set_contract_follow_end_bulk}></EndCheck>


                                        : <Follow column={value} props={this.props} rowIndex={rowIndex} contract_follow_editer={this.contract_follow_editer}></Follow>
              )}

              width={this.props.rent_m_contract_follows_disp.filter(a => a.key_name === value.key_name)[0].width}
              isResizable={true}
              fixed={value.frozen}

            />
          }, this)}





        </Table >



        <Modal isOpen={this.props.modalResponseDetail} toggle={() => this.props.change_modalResponseDetail(false)}
          size="xxl" fade={false}

        >


          {/* <ModalHeader toggle={() => this.props.change_modalResponseDetail(false)}>反響詳細</ModalHeader> */}


          <ResponseComponent
            responseTabs="r4"
            change_modalResponseDetail={(a) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responseid} response_modal={true} />

        </Modal>



        <SimpleDialog selectedValue={1} open={this.props.contract_follow_editer_open} onClose={this.handleClose} change_modalResponseDetail={this.props.change_modalResponseDetail} responseid={this.props.responseid} />



        {/* <Popper open={this.state.open} style={{ position: "absolute", top: "500px", left: "500px" }} anchorEl={this.state.anchorEl} transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <Typography sx={{ p: 2 }}>すでに登録があります。</Typography>
              </Paper>
            </Fade>
          )}
        </Popper> */}
        {this.state.open ? <Card style={{ position: "absolute", top: this.state.top - 150, left: this.state.left }} >
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              {this.state.jyusetu ? "重説は簡単登録できません。" : "すでに登録があります。"}
            </Typography>


          </CardContent>

        </Card> : ""}


        {/* <Popover
          id={"22"}
          open={this.state.open}
          anchorEl={this.state.anchorEl}
          // onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <Typography sx={{ p: 2 }}>The content of the Popover.</Typography>
        </Popover> */}
        {/* <Modal isOpen={this.state.modalreserve} toggle={this.togglereserve} size="xxl" fade={false}>

          <ModalHeader toggle={this.togglereserve}>予定</ModalHeader>
          <ModalBody>
            <div style={{ height: window.innerHeight - 200, background: 'white' }}>
              <FollowCalendar change_schedule={this.change_schedule} section_id={this.props.contract_follow_editer.section_id} /></div>
          </ModalBody>
          <ModalFooter>
            <Button variant="contained" onClick={this.togglereserve} color="primary" style={{ float: "right", margin: 5 }}>
              閉じる
      </Button>

          </ModalFooter>
        </Modal> */}



      </div >
    );
  }
};


// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

//export  default CustomerList;
const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users: state.masterReducer.users,
    users_select: state.masterReducer.users_select,
    contract_follow_search_list: state.contractfollow.contract_follow_search_list,
    rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    contract_follow_editer: state.contractfollow.contract_follow_editer,
    modalResponseDetail: state.responsesReducer.modalResponseDetail,
    responseid: state.responsesReducer.responseid,
    contract_follow_editer_loading: state.contractfollow.contract_follow_editer_loading,
    contract_follow_editer_open: state.contractfollow.contract_follow_editer_open,
    contract_follow_end_bulk: state.contractfollow.contract_follow_end_bulk,
    contract_follow_easy_mode: state.contractfollow.contract_follow_easy_mode,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_contract_follow_editer(state) {
      dispatch(ContractFollow.change_contract_follow_editer(state))
    },
    change_contract_follow_list(state) {
      dispatch(ContractFollow.change_contract_follow_list(state))
    },
    change_contract_follow_search_list(state) {
      dispatch(ContractFollow.change_contract_follow_search_list(state))
    },
    get_contract_follow_list(state) {
      dispatch(ContractFollow.get_contract_follow_list(state))
    },
    change_responseid(state) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

    change_rent_contract_id(state) {
      dispatch(Contracts.change_rent_contract_id(state))
    },
    change_rent_contract_editor_disp(state) {
      dispatch(Contracts.change_rent_contract_editor_disp(state))
    },
    update_rent_contract_follow_details(state) {
      dispatch(ContractFollow.update_rent_contract_follow_details(state))
    },
    change_contract_follow_editer_open(state) {
      dispatch(ContractFollow.change_contract_follow_editer_open(state))
    },

    change_contract_follow_end_bulk(state) {
      dispatch(ContractFollow.change_contract_follow_end_bulk(state))
    },
    update_contract_follow_easy_mode(state) {
      dispatch(ContractFollow.update_contract_follow_easy_mode(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);