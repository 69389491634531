import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, Views } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import moment from 'moment'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import events from '../Calendar/sampleevents'
import * as Calendarsaga from '../Calendar/Saga/Calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../Calendar/Css/Calendar.css';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import Popper from '@mui/material/Popper';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import Editor from '../ContractFollow/Editor';
import * as ContractFollow from '../ContractFollow/Saga/ContractFollow';
import * as CustomerContacts from '../CustomerContacts/Saga/CustomerContacts';
import ContactEditComponent from '../CustomerContacts/ContactEditComponent';

import AnouncementIcon from '@mui/icons-material/Announcement';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import DescriptionIcon from '@mui/icons-material/Description';
import ContactsIcon from '@mui/icons-material/Contacts';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import EditIcon from '@mui/icons-material/Edit';
import TaskEditor from '../Calendar/TaskEditor';
import Task from '../Calendar/TaskEditor';
import { formValueSelector } from 'redux-form';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import HomeIcon from '@mui/icons-material/Home';
import StoreIcon from '@mui/icons-material/Store';

const DragAndDropCalendar = withDragAndDrop(Calendar)
const globallocalizer = localizer(globalize);


var top = null
var left = null

var useStyles = makeStyles({
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
  },
  dialog: {
    position: 'absolute',
    left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
    top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),
  }
});



function eventStyleGetter(event, start, end, isSelected, props) {
  // console.log(event);
  //type:1, // 0:出勤 1:? 2:来店とか 3:タスク
  // var backgroundColor = event.type === 0 ? '#fffacd' : event.type === 4 ? '#e0e0e0' : event.type == 3 ? event.color : event.type == 2 && event.progress != 2 ? "#F7A46B" : event.type == 2 && event.progress == 2 ? "#a9a9a9" : event.intend ? '#98fb98' : '#87cefa';
  // var backgroundColor = event.id === 0 ? '#fffacd' : event.intend ? '#98fb98' : '#87cefa';
  var backgroundColor = ""
  if (event.type === 0) {// 出勤
    backgroundColor = '#fffacd' // クリーム
  } else if (event.type === 4) {// 予定
    backgroundColor = '#e0e0e0' // グレイ
  } else if (event.type == 3) {// タスク
    // if(event.completed_at){
    //   backgroundColor = '#a9a9a9'
    // }else{
      backgroundColor = event.color
    // }
  } else if (event.type == 2) {// 来店
    // if (event.progress != 2) {
      // backgroundColor = "#F7A46B"// オレンジ
      backgroundColor = "#F9A7A7"// 赤
    // } else {
    //   backgroundColor = "#a9a9a9"// グレイ
    // }
  } else if (event.type == 1) {// 来店
    // if (event.intend) {// 予定
      // event.returning == 0 && event.rent_hearing_sheet_id > 0 ? backgroundColor = '#95C195' : backgroundColor = '#98fb98' // 黄緑
      backgroundColor = "#98fb98"// 黄緑
    // } else {
    //   // backgroundColor = '#87cefa' // 水色
    //   backgroundColor = '#a9a9a9' // グレイ
    // }
  } else if(event.type == 6){// オーナー
    // if(event.no_count == 1){
      // backgroundColor = '#cec9f7' // 薄紫
      backgroundColor = '#F9A7A7' // 薄い赤
    // }else{
    //   backgroundColor = "#a9a9a9"// グレイ
    // }
    
  }

  var style = {
    backgroundColor: backgroundColor,
    borderRadius: '2px',
    borderColor: '#FFF',
    // opacity: 0.8,
    color: '#4d4d4d',
    border: '0px',
    display: 'block'
  };
  return {
    style: style
  };
}



class RBCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: events,
      baseDate: this.props.rent_customer_contacts_calendar_forms.date,
      cardx: window.innerWidth - 600,
      open_event_editor: false,
      event_type: 3,
      precalendar: [],
      reflesh: false,
    }


    // let calendar_staff_list_form = this.props.calendar_staff_list_form

    // this.props.change_calendar_staff_list_form(calendar_staff_list_form)

    this.moveEvent = this.moveEvent.bind(this)
    this.newEvent = this.newEvent.bind(this)
    this.editEvent = this.editEvent.bind(this)

    this.viewEvent = this.viewEvent.bind(this)


    this.resizeEvent = this.resizeEvent.bind(this)
    this.Event = this.Event.bind(this)
    this.onNavigate = this.onNavigate.bind(this)
    this.onReflesh = this.onReflesh.bind(this)

  }
  componentDidMount() {
    this.onNavigate(new Date(this.props.rent_customer_contacts_calendar_forms.date ? this.props.rent_customer_contacts_calendar_forms.start : new Date()), "day");
  }


  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {

    this.props.change_calendar_list_edit_pre(this.props.calendar_list_edit)

    start = new Date(moment(moment(start).startOf('day')) > moment(start) ? moment(start).startOf('day') : start)
    end = new Date(moment(moment(start).endOf('day')) < moment(end) ? moment(start).endOf('day') : end)

    if (event.type == 3) {

      const events = this.props.calendar_list_edit
      const idx = events.indexOf(event)
      let allDay = event.allDay

      console.log({ start, end, allDay })

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      const updatedEvent = { ...event, start, end, allDay }
      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)


      this.props.change_calendar_list_edit(nextEvents)

      let rent_task_detail = this.props.rent_task_detail

      rent_task_detail.id = event.id
      rent_task_detail.section_id = event.is_together ? (event.back_section_id == 5 && event.back_section_group == 1 ? 501 : event.back_section_id == 5 && event.back_section_group == 2 ? 502 : event.back_section_id == 5 && event.back_section_group == 3 ? 503 : event.back_section_id) : (event.section_id == 5 && event.section_group == 1 ? 501 : event.section_id == 5 && event.section_group == 2 ? 502 : event.section_id == 5 && event.section_group == 3 ? 503 : event.section_id)
      rent_task_detail.user_id = event.is_together ? event.back_user_id : event.user_id
      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
          },this)
      }
      rent_task_detail.together_user_id = together_user_ids
      rent_task_detail.start_at = moment(start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')
      rent_task_detail.title = event.title
      rent_task_detail.remarks = event.remarks
      rent_task_detail.color = event.color
      rent_task_detail.event_type = event.event_type
      rent_task_detail.completed_at = event.completed_at

      rent_task_detail.start_at_pre = moment(event.start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at_pre = moment(event.end).format('YYYY/MM/DD HH:mm')


      this.props.change_rent_task_detail(rent_task_detail)


      this.props.change_calendar_event_type(event.type)
      this.setState({ event_type: event.type })
      this.props.set(event.type)


    } else if (event.type === 1) {
      // alert(start)

      const events = this.props.calendar_list_edit
      const idx = events.indexOf(event)
      let allDay = event.allDay

      console.log({ start, end, allDay })

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      const updatedEvent = { ...event, start, end, allDay }
      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)

      this.props.change_calendar_list_edit(nextEvents)

      let start_edit = start
      // 時間外から引っ張り出してきたらスタート時間が30分ずれるのを回避
      if (moment(event.start).tz("Asia/Tokyo").hour() < 9 || moment(event.start).tz("Asia/Tokyo").hour() > 18) {
        // alert(moment(start).tz("Asia/Tokyo").hour())
        start_edit = moment(start).add(-30, 'minutes')

      }

      let rent_customer_contacts_calendar_forms_back = this.props.rent_customer_contacts_calendar_forms

      if(rent_customer_contacts_calendar_forms_back.id == event.id) {
        // alert("一致")
        this.props.change_rent_customer_contacts_calendar_forms([])

        let rent_customer_contacts_calendar_forms = rent_customer_contacts_calendar_forms_back
        rent_customer_contacts_calendar_forms.start = moment(start_edit).format('YYYY/MM/DD HH:mm')
        rent_customer_contacts_calendar_forms.end = moment(end).format('YYYY/MM/DD HH:mm')

        this.props.change_rent_customer_contacts_calendar_forms(rent_customer_contacts_calendar_forms)

        this.props.set_time(rent_customer_contacts_calendar_forms.start, rent_customer_contacts_calendar_forms.end)
        
      }else{

        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms

        rent_customer_contacts_forms.rent_response_id = event.rent_response_id;
        rent_customer_contacts_forms.section_id = event.section_id;
        rent_customer_contacts_forms.user_id = event.user_id;
        rent_customer_contacts_forms.date = moment(start).format("YYYY/MM/DD");
        rent_customer_contacts_forms.remarks = event.remarks ? event.remarks : '';
        rent_customer_contacts_forms.way = event.way ? event.way : '';
        rent_customer_contacts_forms.going = event.going ? event.going : 0;
        rent_customer_contacts_forms.going_time = event.going_time ? event.going_time : 0;
        rent_customer_contacts_forms.going_user_id = event.going_user_id ? event.going_user_id : 0;
        rent_customer_contacts_forms.is_application = event.is_application ? event.is_application : 0;
        rent_customer_contacts_forms.rent_m_going_reason_id = event.rent_m_going_reason_id ? event.rent_m_going_reason_id : 0;
        rent_customer_contacts_forms.going_section = event.going_section ? event.going_section : 0;
        rent_customer_contacts_forms.online = event.online ? event.online : 0;
        rent_customer_contacts_forms.no_coming = event.no_coming ? event.no_coming : 0;
        rent_customer_contacts_forms.is_prepare = event.is_prepare ? event.is_prepare : 0;
        rent_customer_contacts_forms.rent_m_response_status_id = event.rent_m_response_status_id ? event.rent_m_response_status_id : 0;
        rent_customer_contacts_forms.intend = event.intend ? event.intend : 0;
        rent_customer_contacts_forms.agent_name = event.agent_name;
        rent_customer_contacts_forms.agent_etc = event.agent_etc;

        rent_customer_contacts_forms.start = moment(start_edit).format('YYYY/MM/DD HH:mm')
        rent_customer_contacts_forms.end = moment(end).format('YYYY/MM/DD HH:mm')

        rent_customer_contacts_forms.id = event.id ? event.id : 0;
        rent_customer_contacts_forms.title = event.title ? event.title : "";

        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)

        this.props.change_calendar_event_type(event.type)
        this.setState({ event_type: event.type })
        this.props.set(event.type)
      }

      

    } else if (event.type === 2) {

      const events = this.props.calendar_list_edit
      const idx = events.indexOf(event)
      let allDay = event.allDay

      console.log({ start, end, allDay })

      if (!event.allDay && droppedOnAllDaySlot) {
        allDay = true
      } else if (event.allDay && !droppedOnAllDaySlot) {
        allDay = false
      }

      const updatedEvent = { ...event, start, end, allDay }
      const nextEvents = [...events]
      nextEvents.splice(idx, 1, updatedEvent)


      this.props.change_calendar_list_edit(nextEvents)
      // alert(start)
      let contract_follow_editer = this.props.contract_follow_editer

      contract_follow_editer.rent_contract_id = event.rent_contract_id;
      contract_follow_editer.rent_response_id = event.rent_response_id;
      contract_follow_editer.section_id = event.follow_section_id;
      contract_follow_editer.user_id = event.user_id;

      // contract_follow_editer.date = moment(start).utc().format("YYYY/MM/DD");
      contract_follow_editer.date = moment(start).format("YYYY/MM/DD");
      contract_follow_editer.remarks = event.remarks;
      contract_follow_editer.way = event.way;
      // contract_follow_editer.going = event.going;
      contract_follow_editer.rent_m_contract_follow_id = event.rent_m_contract_follow_id;
      contract_follow_editer.title = event.rent_m_contract_follow_name
      contract_follow_editer.is_time = event.is_time
      contract_follow_editer.key_name = "3"
      contract_follow_editer.progress = event.progress;

      contract_follow_editer.tatemono_name = event.tatemono_name;
      contract_follow_editer.room_name = event.room_name;
      contract_follow_editer.name = event.nyuukyosha ? event.name2 : event.name1;
      // contract_follow_editer.name = "dddd";
      contract_follow_editer.name1 = event.name1;
      contract_follow_editer.name2 = event.name2;
      contract_follow_editer.nyuukyosha = event.nyuukyosha;
      // contract_follow_editer.section_id = event.section_id;
      contract_follow_editer.agent_name = event.agent_name;
      contract_follow_editer.agent_etc = event.agent_etc;
      // contract_follow_editer.name2 = event.name2;
      // alert(moment(start).format('YYYY/MM/DD HH:mm'))
      contract_follow_editer.datetime_start = new Date(moment(start).format('YYYY/MM/DD HH:mm'))
      contract_follow_editer.datetime_end = new Date(moment(end).format('YYYY/MM/DD HH:mm'))

      contract_follow_editer.date_pre = moment(event.date).format('YYYY/MM/DD')
      contract_follow_editer.start_at_pre = moment(event.datetime_start).format('YYYY/MM/DD HH:mm')
      contract_follow_editer.end_at_pre = moment(event.datetime_end).format('YYYY/MM/DD HH:mm')
      // user_id: 'crm.rent_contract_follow_details.

      this.props.change_contract_follow_editer(contract_follow_editer)

      this.props.change_calendar_event_type(event.type)
      this.setState({ event_type: event.type })
      this.props.set(event.type)
    }




  }

  onReflesh(){
    alert("reflesh")
    // this.setState({ reflesh: !this.state.reflesh })
  }

  resizeEvent = ({ event, start, end }) => {

    this.props.change_calendar_list_edit_pre(this.props.calendar_list_edit)

    start = new Date(moment(moment(start).startOf('day')) > moment(start) ? moment(start).startOf('day') : start)
    end = new Date(moment(moment(start).endOf('day')) < moment(end) ? moment(start).endOf('day') : end)

    if (event.type == 3) {

      this.setState({ event_type: 3 })
      const nextEvents = this.props.calendar_list_edit.map(existingEvent => {
        var ret = existingEvent;
        if (existingEvent.id == event.id) {
          //日付けを変える変更はさせない
          if (existingEvent.start.getFullYear() != start.getFullYear()
            || existingEvent.start.getMonth() != start.getMonth()
            || existingEvent.start.getDate() != start.getDate()
          ) {
            start = existingEvent.start;
          }
          if (existingEvent.end.getFullYear() != end.getFullYear()
            || existingEvent.end.getMonth() != end.getMonth()
            || existingEvent.end.getDate() != end.getDate()
          ) {
            end = existingEvent.end;
          }
          ret = { ...existingEvent, start, end };
        } else {
          ret = existingEvent;
        }
        return ret;
      })
      this.props.change_calendar_list_edit(nextEvents)

      let rent_task_detail = this.props.rent_task_detail

      rent_task_detail.id = event.id
      rent_task_detail.section_id = event.is_together ? (event.back_section_id == 5 && event.back_section_group == 1 ? 501 : event.back_section_id == 5 && event.back_section_group == 2 ? 502 : event.back_section_id == 5 && event.back_section_group == 3 ? 503 : event.back_section_id) : (event.section_id == 5 && event.section_group == 1 ? 501 : event.section_id == 5 && event.section_group == 2 ? 502 : event.section_id == 5 && event.section_group == 3 ? 503 : event.section_id)
      rent_task_detail.user_id = event.is_together ? event.back_user_id : event.user_id
      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
          },this)
      }
      rent_task_detail.together_user_id = together_user_ids
      rent_task_detail.start_at = moment(start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')

      rent_task_detail.title = event.title
      rent_task_detail.remarks = event.remarks
      rent_task_detail.color = event.color
      rent_task_detail.event_type = event.event_type
      rent_task_detail.completed_at = event.completed_at

      rent_task_detail.start_at_pre = moment(event.start).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at_pre = moment(event.end).format('YYYY/MM/DD HH:mm')


      this.props.change_rent_task_detail(rent_task_detail)


      this.props.change_calendar_event_type(event.type)
      this.setState({ event_type: event.type })
      this.props.set(event.type)


    } else if (event.type === 1) {

      // this.setState({ event_type: 1 })



      const nextEvents = this.props.calendar_list_edit.map(existingEvent => {
        var ret = existingEvent;
        if (existingEvent.id == event.id && existingEvent.type == 1) {
          //日付けを変える変更はさせない
          if (existingEvent.start.getFullYear() != start.getFullYear()
            || existingEvent.start.getMonth() != start.getMonth()
            || existingEvent.start.getDate() != start.getDate()
          ) {
            start = existingEvent.start;
          }
          if (existingEvent.end.getFullYear() != end.getFullYear()
            || existingEvent.end.getMonth() != end.getMonth()
            || existingEvent.end.getDate() != end.getDate()
          ) {
            end = existingEvent.end;
          }
          ret = { ...existingEvent, start, end };
        } else {
          ret = existingEvent;
        }
        return ret;
      })

      this.props.change_calendar_list_edit(nextEvents)

      let rent_customer_contacts_calendar_forms_back = this.props.rent_customer_contacts_calendar_forms
      

      if(rent_customer_contacts_calendar_forms_back.id == event.id) {
        this.props.change_rent_customer_contacts_calendar_forms([])

        let rent_customer_contacts_calendar_forms = rent_customer_contacts_calendar_forms_back

        rent_customer_contacts_calendar_forms.start = moment(start).format('YYYY/MM/DD HH:mm')
        rent_customer_contacts_calendar_forms.end = moment(end).format('YYYY/MM/DD HH:mm')

        this.props.change_rent_customer_contacts_calendar_forms(rent_customer_contacts_calendar_forms)

        this.props.set_time(rent_customer_contacts_calendar_forms.start, rent_customer_contacts_calendar_forms.end)
        
      }else{


        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms

        rent_customer_contacts_forms.rent_response_id = event.rent_response_id;
        rent_customer_contacts_forms.section_id = event.section_id;
        rent_customer_contacts_forms.user_id = event.user_id;

        rent_customer_contacts_forms.date = moment(event.date).format("YYYY/MM/DD");
        rent_customer_contacts_forms.remarks = event.remarks ? event.remarks : '';
        rent_customer_contacts_forms.way = event.way ? event.way : '';
        rent_customer_contacts_forms.going = event.going ? event.going : 0;
        rent_customer_contacts_forms.going_time = event.going_time ? event.going_time : 0;
        rent_customer_contacts_forms.going_user_id = event.going_user_id ? event.going_user_id : 0;
        rent_customer_contacts_forms.is_application = event.is_application ? event.is_application : 0;
        rent_customer_contacts_forms.rent_m_going_reason_id = event.rent_m_going_reason_id ? event.rent_m_going_reason_id : 0;
        rent_customer_contacts_forms.going_section = event.going_section ? event.going_section : 0;
        rent_customer_contacts_forms.online = event.online ? event.online : 0;
        rent_customer_contacts_forms.no_coming = event.no_coming ? event.no_coming : 0;
        rent_customer_contacts_forms.is_prepare = event.is_prepare ? event.is_prepare : 0;
        rent_customer_contacts_forms.rent_m_response_status_id = event.rent_m_response_status_id ? event.rent_m_response_status_id : 0;
        rent_customer_contacts_forms.intend = event.intend ? event.intend : 0;
        rent_customer_contacts_forms.agent_name = event.agent_name;
        rent_customer_contacts_forms.agent_etc = event.agent_etc;

        rent_customer_contacts_forms.start = moment(start).format('YYYY/MM/DD HH:mm')
        rent_customer_contacts_forms.end = moment(end).format('YYYY/MM/DD HH:mm')

        rent_customer_contacts_forms.id = event.id ? event.id : 0;

        rent_customer_contacts_forms.title = event.title ? event.title : "";
        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)



        this.props.change_calendar_event_type(event.type)
        this.setState({ event_type: event.type })
        this.props.set(event.type)


      }





    } else if (event.type === 2) {
      this.setState({ event_type: 2 })
      const nextEvents = this.props.calendar_list_edit.map(existingEvent => {
        var ret = existingEvent;

        if (existingEvent.rent_contract_id == event.rent_contract_id && existingEvent.rent_m_contract_follow_id == event.rent_m_contract_follow_id) {
        // if (existingEvent.id == event.id) {
          // alert(event.id)
          if (existingEvent.start.getFullYear() != start.getFullYear()
            || existingEvent.start.getMonth() != start.getMonth()
            || existingEvent.start.getDate() != start.getDate()
          ) {
            // alert(existingEvent.start)
            start = existingEvent.start;
          }
          if (existingEvent.end.getFullYear() != end.getFullYear()
            || existingEvent.end.getMonth() != end.getMonth()
            || existingEvent.end.getDate() != end.getDate()
          ) {
            end = existingEvent.end;
          }
          ret = { ...existingEvent, start, end };


        } else {
          ret = existingEvent;
          // alert()
        }
        return ret;
      })

      this.props.change_calendar_list_edit(nextEvents)
      // alert(start)
      let contract_follow_editer = this.props.contract_follow_editer

      contract_follow_editer.rent_contract_id = event.rent_contract_id;
      contract_follow_editer.rent_response_id = event.rent_response_id;
      contract_follow_editer.section_id = event.follow_section_id;
      contract_follow_editer.user_id = event.user_id;

      contract_follow_editer.date = moment(event.date).utc().format("YYYY/MM/DD");
      contract_follow_editer.remarks = event.remarks;
      contract_follow_editer.way = event.way;
      // contract_follow_editer.going = event.going;
      contract_follow_editer.rent_m_contract_follow_id = event.rent_m_contract_follow_id;
      contract_follow_editer.title = event.rent_m_contract_follow_name
      contract_follow_editer.is_time = event.is_time
      contract_follow_editer.key_name = "3"
      contract_follow_editer.progress = event.progress;

      contract_follow_editer.tatemono_name = event.tatemono_name;
      contract_follow_editer.room_name = event.room_name;
      contract_follow_editer.name = event.nyuukyosha ? event.name2 : event.name1;
      // contract_follow_editer.name = "dddd";
      contract_follow_editer.name1 = event.name1;
      contract_follow_editer.name2 = event.name2;
      contract_follow_editer.nyuukyosha = event.nyuukyosha;
      // contract_follow_editer.section_id = event.section_id;
      contract_follow_editer.agent_name = event.agent_name;
      contract_follow_editer.agent_etc = event.agent_etc;

      contract_follow_editer.datetime_start = new Date(moment(start).format('YYYY/MM/DD HH:mm'))
      contract_follow_editer.datetime_end = new Date(moment(end).format('YYYY/MM/DD HH:mm'))


      this.props.change_calendar_event_type(event.type)
      this.setState({ event_type: event.type })
      this.props.set(event.type)

    }
 

    this.setState({ "reflesh": !this.state.reflesh })


  }

  newEvent = ({ start, end }) => {

    let rent_customer_contacts_calendar_forms_back = this.props.rent_customer_contacts_calendar_forms
    this.props.change_rent_customer_contacts_calendar_forms([])

    if(rent_customer_contacts_calendar_forms_back.id > 0){
      let calendar_list_edit = this.props.calendar_list_edit.map(value => {
        if (value.id == rent_customer_contacts_calendar_forms_back.id) {
          value.start = start
          value.end = end
        }
        return value;
      })
      this.props.change_calendar_list_edit(calendar_list_edit)
    }else{
      let calendar_list_edit = this.props.calendar_list_edit.filter(a=> !(a.id == 0 && a.type == 1))

      let rent_customer_contacts_calendar_forms_data = {
              id: 0,
              type: 1,
              start: start,
              end: end,
              user_id: rent_customer_contacts_calendar_forms_back.user_id, 
            }


      this.props.change_calendar_list_edit(calendar_list_edit.concat(rent_customer_contacts_calendar_forms_data))
    }
    

      
    let rent_customer_contacts_calendar_forms = rent_customer_contacts_calendar_forms_back
    rent_customer_contacts_calendar_forms.start = moment(start).format('YYYY/MM/DD HH:mm')
    rent_customer_contacts_calendar_forms.end = moment(end).format('YYYY/MM/DD HH:mm')

    this.props.change_rent_customer_contacts_calendar_forms(rent_customer_contacts_calendar_forms)

    this.props.set_time(rent_customer_contacts_calendar_forms.start, rent_customer_contacts_calendar_forms.end)

    this.setState({ reflesh: !this.state.reflesh })
  }

  editEvent = (event) => {

    this.props.change_calendar_list_edit_pre(this.props.calendar_list_edit)

    if (event.type === 3) {
      let rent_task_detail = this.props.rent_task_detail
      rent_task_detail.id = event.id
      rent_task_detail.title = event.title
      rent_task_detail.section_id = event.is_together ? (event.back_section_id == 5 && event.back_section_group == 1 ? 501 : event.back_section_id == 5 && event.back_section_group == 2 ? 502 : event.back_section_id == 5 && event.back_section_group == 3 ? 503 : event.back_section_id) : (event.section_id == 5 && event.section_group == 1 ? 501 : event.section_id == 5 && event.section_group == 2 ? 502 : event.section_id == 5 && event.section_group == 3 ? 503 : event.section_id)
      rent_task_detail.user_id = event.is_together ? event.back_user_id : event.user_id
      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
          },this)
      }
      rent_task_detail.together_user_id = together_user_ids
      rent_task_detail.start_at = moment(event.start_edit).format('YYYY/MM/DD HH:mm')
      // rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(event.end_edit).format('YYYY/MM/DD HH:mm')
      rent_task_detail.remarks = event.remarks
      rent_task_detail.color = event.color
      rent_task_detail.event_type = event.event_type
      rent_task_detail.completed_at = event.completed_at
      // alert( this.props.user_details.id)
      this.props.change_rent_task_detail(rent_task_detail)


      this.props.change_calendar_event_type(event.type)
      this.setState({ event_type: event.type })
      this.props.set(event.type)


    } else if (event.type === 2) {


      let contract_follow_editer = this.props.contract_follow_editer

      contract_follow_editer.rent_contract_id = event.rent_contract_id;
      contract_follow_editer.rent_response_id = event.rent_response_id;
      contract_follow_editer.section_id = event.follow_section_id;
      contract_follow_editer.user_id = event.user_id;

      contract_follow_editer.date = moment(event.date).utc().format("YYYY/MM/DD");
      contract_follow_editer.remarks = event.remarks;
      contract_follow_editer.way = event.way;
      // contract_follow_editer.going = event.going;
      contract_follow_editer.rent_m_contract_follow_id = event.rent_m_contract_follow_id;
      contract_follow_editer.title = event.rent_m_contract_follow_name
      contract_follow_editer.is_time = event.is_time
      contract_follow_editer.key_name = "3"
      contract_follow_editer.progress = event.progress;

      contract_follow_editer.tatemono_name = event.tatemono_name;
      contract_follow_editer.room_name = event.room_name;
      contract_follow_editer.name = event.nyuukyosha ? event.name2 : event.name1;

      contract_follow_editer.name1 = event.name1;
      contract_follow_editer.name2 = event.name2;
      contract_follow_editer.nyuukyosha = event.nyuukyosha;
      // contract_follow_editer.section_id = event.section_id;
      contract_follow_editer.agent_name = event.agent_name;
      contract_follow_editer.agent_etc = event.agent_etc;
      // contract_follow_editer.name2 = event.name2;

      contract_follow_editer.datetime_start = event.start_edit;
      contract_follow_editer.datetime_end = event.end_edit;

      // user_id: 'crm.rent_contract_follow_details.
      this.props.change_contract_follow_editer(contract_follow_editer)


      this.props.change_calendar_event_type(event.type)
      this.setState({ event_type: event.type })
      this.props.set(event.type)

    } else if (event.type === 1) {

      if(this.props.rent_customer_contacts_calendar_forms.id != 0 && event.id != this.props.rent_customer_contacts_calendar_forms.id){

        let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms

        rent_customer_contacts_forms.rent_response_id = event.rent_response_id;
        rent_customer_contacts_forms.section_id = event.section_id;
        rent_customer_contacts_forms.user_id = event.user_id;

        rent_customer_contacts_forms.date = moment(event.date).format("YYYY/MM/DD");
        rent_customer_contacts_forms.remarks = event.remarks ? event.remarks : '';
        rent_customer_contacts_forms.way = event.way ? event.way : '';
        rent_customer_contacts_forms.going = event.going ? event.going : 0;
        rent_customer_contacts_forms.going_time = event.going_time ? event.going_time : 0;
        rent_customer_contacts_forms.going_user_id = event.going_user_id ? event.going_user_id : 0;
        rent_customer_contacts_forms.is_application = event.is_application ? event.is_application : 0;
        rent_customer_contacts_forms.rent_m_going_reason_id = event.rent_m_going_reason_id ? event.rent_m_going_reason_id : 0;
        rent_customer_contacts_forms.going_section = event.going_section ? event.going_section : 0;
        rent_customer_contacts_forms.online = event.online ? event.online : 0;
        rent_customer_contacts_forms.no_coming = event.no_coming ? event.no_coming : 0;
        rent_customer_contacts_forms.is_prepare = event.is_prepare ? event.is_prepare : 0;
        rent_customer_contacts_forms.rent_m_response_status_id = event.rent_m_response_status_id ? event.rent_m_response_status_id : 0;
        rent_customer_contacts_forms.intend = event.intend ? event.intend : 0;
        rent_customer_contacts_forms.agent_name = event.agent_name;
        rent_customer_contacts_forms.agent_etc = event.agent_etc;
        rent_customer_contacts_forms.intend_time = event.intend_time ? event.intend_time : 0;
        rent_customer_contacts_forms.intend_time2 = event.intend_time2 ? event.intend_time2 : 0;
        rent_customer_contacts_forms.start = event.start_edit ? moment(event.start_edit).format("YYYY/MM/DD HH:mm") : null;
        rent_customer_contacts_forms.end = event.end_edit ? moment(event.end_edit).format("YYYY/MM/DD HH:mm") : null;
      
        rent_customer_contacts_forms.id = event.id ? event.id : 0;
        rent_customer_contacts_forms.title = event.title ? event.title : "";
        this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)


        this.props.change_calendar_event_type(event.type)
        this.setState({ event_type: event.type })
        this.props.set(event.type)

      }

    }



  }

  dblclick = (event) => {

    console.log(event)

  }

  onMouseDown(view) {
    console.log('onMouseDown')
    console.log(view)
    // alert(view)
  }

  onTouchStart(view) {
    console.log('onTouchStart')
    console.log(view)
    // alert(view)
  }

  viewEvent(view) {

    this.onNavigate(this.state.baseDate, view)
    console.log(view)
    // alert(view)
  }
  onNavigate = (date, view) => {
    let start, end;

    this.setState({ baseDate: date })

    if (view === 'month') {
      start = moment(date).startOf('month').startOf('week').startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).endOf('month').endOf('week').endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    if (view === 'agenda') {
      start = moment(date).startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).add(1, 'months').endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    if (view === 'week') {
      start = moment(date).add(-6, 'days').startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).add(6, 'days').endOf('day').format('YYYY/MM/DD HH:mm:ss')

    }
    if (view === 'day') {
      start = moment(date).startOf('day').format('YYYY/MM/DD HH:mm:ss')

      end = moment(date).endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }


    console.log(start, end);

    return console.log({ start, end });
  }
  click(e) {

    top = e.target.getBoundingClientRect().top
    left = e.target.getBoundingClientRect().left
    // this.handleClickOpen()

    useStyles = makeStyles({
      avatar: {
        // backgroundColor: blue[100],
        // color: blue[600],
      },
      dialog: {
        position: 'absolute',
        left: 600 + left <= window.innerWidth ? left : (left - 600 > 0 ? left - 520 : 0),
        top: 600 + top <= window.innerHeight ? top : (top - 600 > 0 ? top - 580 : 0),
        width: 600,
        height: 580
      }
    });


  }

  handleClickOpen = () => {
    // alert(this.state.event_type)

  };

  handleClose = (value) => {
 
    if (value === 3) {
    
      this.props.change_task_edit_modal(false)

      this.props.change_calendar_list(this.state.precalendar)
      

    } else if (value === 2) {

      this.props.change_contract_follow_edit_modal(false)

      this.props.change_calendar_list(this.state.precalendar)



    } else if (value === 1) {

      this.props.change_customer_contacts_modal(false)

      this.props.change_calendar_list(this.state.precalendar)



    }






    this.setState({ reflesh: !this.state.reflesh })


  };
  // holidayの数値によってその日のシフトが決まります↓
  // 0出勤 1公休 2有給 3AM半休 4特休 5遅刻 6早退 7出張 8取り消し(=出勤) 9欠勤 11その他 12PM半休 13外出 14旅行
  // NULLの場合も出勤

  // console.log(f)
  Event = ({ event, }) => {

    if (event.type == 0) {

      let holiday = event.users.filter(a => parseInt(a.id) == parseInt(this.props.rent_customer_contacts_calendar_forms.user_id)).length > 0 ? event.users.filter(a => parseInt(a.id) == parseInt(this.props.rent_customer_contacts_calendar_forms.user_id))[0].holiday : ""
      // alert()
      return (

        <div style={{ width: "100%" }}>
          {holiday === 0 ? "出勤" : holiday === 1 ? "公休" : holiday === 2 ? "有給" : holiday === 3 ? "AM半休" : holiday === 4 ? "特休" : holiday === 5 ? "遅刻" : holiday === 6 ? "早退" : holiday === 7 ? "出張" : holiday === 8 ? "出勤" : holiday === 9 ? "欠勤" : holiday === 12 ? "PM半休" : holiday === 13 ? "外出" : holiday === 14 ? "旅行" : ""}
        </ div >);
    } else if (event.type == 4) {
      return (
        <div style={{ width: "100%" }}>
          <div style={{float:"left"}}><DirectionsWalkIcon />
          <span style={{marginRight:3}}>前{this.props.calendar_list_edit.filter(a => (a.type == 1) && parseInt(a.user_id) == parseInt(this.props.rent_customer_contacts_calendar_forms.user_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T00:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00")).length}</span>
          <span style={{marginRight:3}}>後{this.props.calendar_list_edit.filter(a => (a.type == 1) && parseInt(a.user_id) == parseInt(this.props.rent_customer_contacts_calendar_forms.user_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00")).length}</span>
          <span style={{marginRight:3}}>夕{this.props.calendar_list_edit.filter(a => (a.type == 1) && parseInt(a.user_id) == parseInt(this.props.rent_customer_contacts_calendar_forms.user_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T23:59:59")).length}</span>
            
          </div>
          <div style={{float:"left"}}>
          <DescriptionIcon />
          <span style={{marginRight:3}}>前{this.props.calendar_list_edit.filter(a => (a.type == 2) && parseInt(a.user_id) == parseInt(this.props.rent_customer_contacts_calendar_forms.user_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T00:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00")).length}</span>
          <span style={{marginRight:3}}>後{this.props.calendar_list_edit.filter(a => (a.type == 2) && parseInt(a.user_id) == parseInt(this.props.rent_customer_contacts_calendar_forms.user_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T13:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00")).length}</span>
          <span>夕{this.props.calendar_list_edit.filter(a => (a.type == 2) && parseInt(a.user_id) == parseInt(this.props.rent_customer_contacts_calendar_forms.user_id) &&
            moment(a.start) >= moment(moment(event.start).format("YYYY-MM-DD") + "T16:00:00") &&
            moment(a.start) < moment(moment(event.start).format("YYYY-MM-DD") + "T23:59:59")).length}</span>
          </div>
        </ div >);
    } else if (event.type == 3) {

      return (
        <div
          style={{ width: "100%", height: '100%' }}
          onClick={this.click.bind(this)}
        >
          {/* <Tooltip title={event.remarks}  placement="bottom-start"> */}
          {event.title + "(" + event.user_nickname + ")"}
          {/* </Tooltip> */}
        </ div >);
    } else if (event.type == 1) {
      return (

        <div
          style={{ width: "100%", height: '100%' }} onClick={this.click.bind(this)} >
          {event.id == this.props.rent_customer_contacts_calendar_forms.id ? <EditIcon style={{color:"red"}} /> : ""}
          {event.returning == 0 ? 
            event.rent_hearing_sheet_id > 0 ? <span><FiberNewIcon style={{color:"red"}} /><span className="badge badge-primary" style={{fontWeight:"normal", marginRight:1}}>web</span></span> 
            : <FiberNewIcon style={{color:"red"}} /> 
          : <DirectionsWalkIcon />}
          {event.going == 2 ? <span className="badge badge-primary" style={{fontWeight:"normal", backgroundColor:"gray", marginRight:1}}>現</span> : ""}
          {event.title}
        </ div>);
    } else if (event.type == 2) {
      return (

        <div
          style={{ width: "100%", height: '100%' }} onClick={this.click.bind(this)} >

          {event.way == 2 ? <ContactsIcon /> : (event.rent_m_contract_follow_id == 7 || event.rent_m_contract_follow_id == 8) ? <VpnKeyIcon /> : <DescriptionIcon /> }{event.title}
        </ div>);
    } else if (event.type == 6) {
      return (
        <div
          style={{ width: "100%", height: '100%' }} onClick={(e)=>this.click(e, event.type)} >

          {event.type6 == 1 ? event.raiten_kbn_no == 1 || event.raiten_kbn_no == 3 ? <HomeIcon style={{color:event.no_count == 0?"#666666":"red"}} /> : //訪問
          event.raiten_kbn_no == 4 || event.raiten_kbn_no == 6 ? <LocalPhoneIcon style={{color:event.no_count == 0?"#666666":"red"}} /> : // TEL　FAX
          event.raiten_kbn_no == 2 || event.raiten_kbn_no == 7 ? <StoreIcon style={{color:event.no_count == 0?"#666666":"red"}} /> : // 来店
          event.raiten_kbn_no == 5 ? <EmailIcon style={{color:event.no_count == 0?"#666666":"red"}} /> :"" // メール
          :<CorporateFareIcon style={{color:event.no_count == 0?"#666666":"red"}} />}

          {event.title}
        
        </ div>);
    }
  };
  render() {
    return (
      <span>
        {/* {this.state.open_event_editor ? <Card style={{ position: "absolute", zIndex: 3000, width: 500, height: 500, marginLeft: this.state.cardx, marginTop: "-70px" }}>
          <CardContent >
          </CardContent >
        </Card > : ""} */}
        {/* {this.props.user_id} */}
        <DragAndDropCalendar
          selectable
          localizer={globallocalizer}
          min={this.props.rent_customer_contacts_calendar_forms.date ? new Date(moment(this.props.rent_customer_contacts_calendar_forms.date).format('YYYY/MM/DD 09:00:00')) : new Date(moment().format('YYYY/MM/DD 09:00:00'))}
          max={this.props.rent_customer_contacts_calendar_forms.date ? new Date(moment(this.props.rent_customer_contacts_calendar_forms.date).format('YYYY/MM/DD 18:59:59')) : new Date(moment().format('YYYY/MM/DD 18:59:59'))}
          culture={'ja-JP'}
          events={this.props.calendar_list_edit}
          onEventDrop={this.moveEvent}
          resizable
          onView={this.viewEvent}
          onEventResize={this.resizeEvent}
          onSelectEvent={(event) => this.editEvent(event)}
          // onSelectEvent={event => alert(event.title)}
          onMouseDown={this.onMouseDown}
          onTouchStart={this.onTouchStart}
          // scrollToTime={new Date('2021/10/21 16:00:00')} 
          onSelectSlot={this.newEvent}
          // onDragStart={console.log('onDragStart')}
          // onDragOver={console.log('onDragOver')}
          date={this.props.rent_customer_contacts_calendar_forms.date}
          // date={this.state.baseDate}
          // onNavigate={date => {
          //   this.setState({ baseDate: date });
          // }}

          // onNavigate={this.onNavigate}
          // onDoubleClickEvent={this.dblclick}
          onNavigate={this.onNavigate}
          eventPropGetter={eventStyleGetter}
          components={{
            event: this.Event,

          }}
          defaultView={'day'}
          views={{ agenda: true, week: true, month: true, day: true }}
          toolbar={false}
          // showMultiDayTimes={true}
          formats={{
            dayFormat: (date) => {
              return (moment(date).format('M月DD日(dddd)'));
            },
            agendaDateFormat: (date) => {
              return (moment(date).format('M月DD日(dddd)'));
            },
            dayHeaderFormat: (date) => {
              return (moment(date).format('YYYY年M月DD日(dddd)'));
            },
            agendaHeaderFormat: ({ start, end }) => {
              return (moment(start).format('YYYY年M月DD日') + ' ～ ' + moment(end).format('YYYY年M月DD日'));
            },
            dayRangeHeaderFormat: ({ start, end }) => {
              return (moment(start).format('YYYY年M月DD日') + ' ～ ' + moment(end).format('YYYY年M月DD日'));
            },
          }}
          messages={{
            date: '日付',
            time: '時間',
            event: '内容',
            allDay: '終日',
            week: '週',
            work_week: 'Work Week',
            day: '日',
            month: '月',
            previous: '←前へ',
            next: '次へ→',
            yesterday: '昨日',
            tomorrow: '明日',
            today: '今日',
            agenda: '一覧',
            noEventsInRange: '期間内に登録されているイベントがありません',
          }}

          // style={{ height: window.innerHeight  - 131}}
          style={{ height: 550,}}
        />

      </span>
    );
  }
};


const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,

    calendar_list: state.calendar.calendar_list,
    calendar_staff_list_form: state.calendar.calendar_staff_list_form,

    rent_customer_contacts_forms: state.customerContactsReducer.rent_customer_contacts_forms,
    rent_customer_contacts_calendar_forms: state.customerContactsReducer.rent_customer_contacts_calendar_forms,
    contract_follow_editer: state.contractfollow.contract_follow_editer,
    rent_task_detail: state.calendar.rent_task_detail,
    task_edit_modal: state.calendar.task_edit_modal,
    contract_follow_edit_modal: state.contractfollow.contract_follow_edit_modal,
    customer_contacts_modal: state.customerContactsReducer.customer_contacts_modal,

    calendar_list_edit: state.calendar.calendar_list_edit,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    change_calendar_staff_list_form(state) {
      dispatch(Calendarsaga.change_calendar_staff_list_form(state))
    },
    change_calendar_list(state) {
      dispatch(Calendarsaga.change_calendar_list(state))
    },

    change_rent_task_detail(state) {
      dispatch(Calendarsaga.change_rent_task_detail(state))
    },

    change_contract_follow_editer(state) {
      dispatch(ContractFollow.change_contract_follow_editer(state))
    },
    change_rent_customer_contacts_forms(state) {
      dispatch(CustomerContacts.change_rent_customer_contacts_forms(state))
    },
    change_rent_customer_contacts_calendar_forms(state) {
      dispatch(CustomerContacts.change_rent_customer_contacts_calendar_forms(state))
    },
    change_task_edit_modal(state) {
      dispatch(Calendarsaga.change_task_edit_modal(state))
    },
    change_contract_follow_edit_modal(state) {
      dispatch(ContractFollow.change_contract_follow_edit_modal(state))
    },
    change_customer_contacts_modal(state) {
      dispatch(CustomerContacts.change_customer_contacts_modal(state))
    },
    change_calendar_event_type(state) {
      dispatch(Calendarsaga.change_calendar_event_type(state))
    },
    change_calendar_precalendar(state) {
      dispatch(Calendarsaga.change_calendar_precalendar(state))
    },
    change_calendar_list_edit(state) {
      dispatch(Calendarsaga.change_calendar_list_edit(state))
    },
    change_calendar_list_edit_pre(state) {
      dispatch(Calendarsaga.change_calendar_list_edit_pre(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RBCalendar);