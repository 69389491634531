import React, { Component } from 'react';
import { connect } from 'react-redux';
import Search from './Search';
import RoomList from './RoomList';
import Building from './Building';
import Map from './Map';
import './Css/Search.css';


import {
  get_room_details, get_room_campaign} from '../Rooms/Saga/Rooms';
import {
  get_response_map_rooms, change_showRight, change_showLeft,
  change_showFlag, get_response_map_rooms_success, delete_map_introduction, insert_map_to_introductions
} from './Saga/MapSearch';
import { add_mail_room } from '../Mail/Saga/Mail';
class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
    }

    // props.get_response_rooms({rentResponseId:props.rent_response_id});
    this.handleSubmit = this.handleSubmit.bind(this);
    this.selectTatemono = this.selectTatemono.bind(this);


    this.props.get_response_map_rooms_success([]);
  }



  selectTatemono(tatemonoId, roomId) {
    // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}
    if (this.props.mail) {
      this.props.add_mail_room({ window: window, id: 131, tatemonoId: tatemonoId, roomId: roomId, rentResponseId: this.props.rent_response_id })
    } else {
      let insertdata = {
        rentResponseId: this.props.rent_response_id,
        tatemonoId: tatemonoId,
        status: 2,

        roomId: roomId,

      };


      this.props.insert_map_to_introductions(insertdata)
    }
  }


  _onChildClick = (key, childProps) => {
    alert(key)
    // this.props.onCenterChange([childProps.lat, childProps.lng]);
  }

  handleSubmit(state) {
    console.log(state)

    if (this.state.bounds) {
      state.max_lat = this.state.bounds[0];
      state.min_lat = this.state.bounds[2];
      state.max_lng = this.state.bounds[3];
      state.min_lng = this.state.bounds[1];
      state.rentResponseId = this.props.rent_response_id;
      this.props.get_response_map_rooms(state);

    }
    // this.props.actions.get_response_rooms(state);

  }
  render() {
    // const {handleChange} = this.props;
    //moment.locale('ja')

    return (

      <div style={{ position: "relative", height: window.innerHeight - 70, overflow: "hidden", paddingTop: 0 }}>
        {/* <div style={{ position:"absolute", zIndex:100, Top:0, left:-209}}>
  <div style={{backgroundColor:"#66CCFF", width:40, height:500, textAlign:"left", paddingLeft:7}}>
    <div className="fa fa-search" style={{fontSize:26, color:"White"}} onClick={()=>this.props.change_showFlag(!this.props.mapsearch.showFlag)}></div>
  </div>
</div> */}


        {/* <div className="search_menu" style={{ position:"absolute", zIndex:100, Top:0, left:-269, height:850, overflowY:"scroll", display: this.props.mapsearch.showFlag ? '' : 'none' }}> */}
        <div className="search_menu" style={{ position: "absolute", zIndex: 100, Top: 0, left: 0, height: window.innerHeight - 50, overflowY: "scroll", display: this.props.mapsearch.showLeft ? "" : "none" }}>

          <Search onSubmit={this.handleSubmit} onChange={this.handleSubmit} />

        </div>
        <div style={{ position: "absolute", zIndex: 100, backgroundColor: "#666", left: this.props.mapsearch.showLeft ? 250 : 0, width: 35, height: 35, textAlign: "left", paddingLeft: 8, paddingTop: 8 }} onClick={() => this.props.change_showLeft(this.props.mapsearch.showLeft ? false : true)}>
          <div className={this.props.mapsearch.showLeft ? "fa fa-arrow-left" : "fa fa-arrow-right"} style={{ fontSize: 18, color: "White" }}></div>
        </div>
        <div style={{ height: window.innerHeight - 50, position: "absolute", zIndex: 5, Top: 5, Bottom: 5, left: 0, right: 0 }}>
          <Map rent_response_id={this.props.rent_response_id} tatemono_list={this.props.mapsearch.response_rooms} bounds={(bounds) => this.setState({ bounds: bounds })} />
        </div>

        <div style={{ position: "absolute", zIndex: 100, backgroundColor: "#666", right: this.props.mapsearch.showRight ? 350 : 0, width: 35, height: 35, textAlign: "left", paddingLeft: 8, paddingTop: 8 }} onClick={() => this.props.change_showRight(this.props.mapsearch.showRight ? false : true)}>
          <div className={this.props.mapsearch.showRight ? "fa fa-arrow-right" : "fa fa-arrow-left"} style={{ fontSize: 18, color: "White" }}></div>
        </div>

        <div style={{ position: "absolute", zIndex: 100, Top: 0, right: -50, width: 400, height: window.innerHeight, background: "#FFF", display: this.props.mapsearch.showRight ? "" : "none" }}>
          <div className="search_menu" style={{ position: "absolute", zIndex: 100, Top: 0, right: 0, width: 400, height: window.innerHeight - 50, overflowY: "scroll" }}>
            <div style={{ float: "left", width: 400, display: this.props.mapsearch.showRight === -400 ? 'none' : '' }}>
              <Building />
              {this.props.mapsearch.map_room_details[0] ? <RoomList rent_response_id={this.props.rent_response_id} responsedetail={this.props.responsedetail} selectsheet={this.selectsheet} selectTatemono={this.selectTatemono} delete_map_introduction={this.props.delete_map_introduction} mail={this.props.mail} get_room_details={this.props.get_room_details} get_room_campaign={this.props.get_room_campaign} /> : ""}
            </div>


          </div>
        </div>


      </div>

    )
  }
}

// const Outer = styled.div`
// height:620; 
// position:absolute; 
// zIndex:5; 
// Top:10; 
// left:10; 
// right:10;`

//containers

// ({mapsearchReducer}) => ({mapsearchReducer}),

export default connect(
  ({ mapsearch }) => ({ mapsearch }),
  // state => ({
  //   mapsearch: state.mapsearchReducer,

  // }),
  {
    get_response_map_rooms,
    get_response_map_rooms_success,
    change_showRight,
    change_showLeft,
    change_showFlag,
    delete_map_introduction,
    insert_map_to_introductions,
    add_mail_room,

    get_room_details,
    get_room_campaign
  }

)(App)
// const mapStateToProps = (state) => {
//   return {
//     response_rooms: state.mapsearchReducer.response_rooms,

//   }
// }
// function mapDispatchToProps(dispatch) {
//   return {

//       get_response_rooms(state) {
//           dispatch(Rooms.get_response_rooms(state))
//       },
//   };
// }
// export default connect(mapStateToProps, mapDispatchToProps)(App);


