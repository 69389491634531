import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { Cell } from 'fixed-data-table-2';

import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CorporatesContactsList from "./CorporatesContactsList";
import CorporatesReplace from "./CorporatesReplace";

import * as Responses from '../ResponseDetail/Saga/Responses';
import CorporatesDetailDialog from "./CorporatesDetailDialog";
import CorporatesCountDialog from "./CorporatesCountDialog";
import './Css/Grid.css'
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Chip from '@mui/material/Chip';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import GetAppIcon from '@mui/icons-material/GetApp';
import LinkIcon from '@mui/icons-material/Link';
import SearchIcon from '@mui/icons-material/Search';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import BusinessIcon from '@mui/icons-material/Business';


var SortTypes = {
    ASC: 'ASC',
    DESC: 'DESC',
  };

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

const useStyles = makeStyles(theme => ({
    dialogPaper: {

        height: '900px'
    },
}));

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))


const headers = [
    { label: "ID", key: "id" },
    { label: "法人名", key: "name" },
    { label: "カナ", key: "kana" },

    { label: "最終営業対応履歴日", key: "last_contact_at" },
    { label: "営業対応履歴数", key: "contact_count" },
    { label: "主要取引先", key: "main_corp" },
    { label: "その他取引先", key: "other_main_corp" },
    
    { label: "反響数", key: "response_no" },
    { label: "契約数", key: "contraction_no" },
    { label: "法人反響数", key: "corporate_response_no" },
    { label: "法人契約数", key: "corporate_contraction_no" },
    
    { label: "代表者", key: "president" },
    { label: "代表者役職", key: "position" },
    { label: "資本金", key: "capital" },
    { label: "設立年月日", key: "establish" },
    { label: "創業年月日", key: "start_of_business" },
    { label: "事業所数", key: "office" },
    { label: "従業員数", key: "employee" },
    { label: "HP", key: "url" },
    { label: "事業内容", key: "vision" },

    { label: "最終更新者", key: "updated_user_id" },
    { label: "最終更新日", key: "updated_at" },
    

];

class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modal_contacts: false,
            modal_replace: false,
            // modalselect: false,
            change: false,
            search: '',
            count_modal: false,

        };

        this.handleChangeNotSearchText = this.handleChangeNotSearchText.bind(this);
        this.handleChangeNotSearchTextEnter = this.handleChangeNotSearchTextEnter.bind(this);
        this.handleContactsOpen = this.handleContactsOpen.bind(this);
        this.handleReplaceOpen = this.handleReplaceOpen.bind(this);
        this.handleDetailOpen = this.handleDetailOpen.bind(this);
        this.handleClear = this.handleClear.bind(this);
        this.clickCSV = this.clickCSV.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
    }


    handleContactsOpen() {

        this.setState({ "modal_contacts": true })
    }

    handleReplaceOpen() {

        this.setState({ "modal_replace": true })
    }

    handleSearch(state) { // DB取得するやつ
        // alert(state.target.value)
        let rent_m_corporates_list_search_form = this.props.rent_m_corporates_list_search_form

        if(state.target.name == "partnership"){
            rent_m_corporates_list_search_form[state.target.name] = state.target.checked
        }else{
            rent_m_corporates_list_search_form[state.target.name] = state.target.value
        }

        this.props.change_rent_m_corporates_list_search_form(rent_m_corporates_list_search_form);

        this.handleChangeNotSearchTextEnter()
    }

    handleChangeNotSearchText(state) {

        let rent_m_corporates_list_search_form = this.props.rent_m_corporates_list_search_form

        rent_m_corporates_list_search_form[state.target.name] = state.target.value

        this.props.change_rent_m_corporates_list_search_form(rent_m_corporates_list_search_form);

        this.setState({ refresh: !this.state.refresh })
    }

    handleChangeNotSearchTextEnter(state) {

        this.props.get_rent_m_corporates_list()
        this.setState({ refresh: !this.state.refresh })
    }



    handleDetailOpen() {
        // 新規
        let rent_m_corporates = { id: '', kubun: 1, name: "", kana: "", post: "", add1: "", add2: "", tel1: "", url: "", capital: "", establish: "", start_of_business: "", president: "", position: "", office: "", employee: "", vision: "", lat: "", lng: "", is_display: 0, }
        this.props.change_rent_m_corporates(rent_m_corporates)

        this.props.change_rent_m_corporates_detail_open(true)
    }

    handleClear() {
        let search_data_replace = { m_corporate_name: "", select_m_corporate_id: "", customer_name: "", }
        this.props.change_search_data_replace(search_data_replace);
        this.props.change_rent_m_corporates_list_replace([])
        this.props.change_rent_m_corporates_list_replace_search([])
        this.props.change_rent_customer_list_replace([])
        this.props.change_rent_customer_list_replace_search([])

    }

    clickCSV() {
        this.props.get_rent_m_corporates_list_csv()
    }



    render() {

        return (
            <div style={{ backgroundColor: "White", width: 230, padding: 5, textAlign: "left", border: "1px solid rgb(221, 221, 221)", height: (window.innerHeight - 60) }}>
                
                <Grid container spacing={2}>

                    <Grid justifyContent="space-between" style={{textAlign:"left", borderBottom: "1px solid #dddddd"}} item xs={12}>
                        <Button style={{ backgroundColor: "#007bff", marginRight: 5, marginBottom:5, }} size="small" variant="contained" color="primary" startIcon={<PermContactCalendarIcon />} onClick={this.handleContactsOpen}>営業対応履歴</Button>
                        {(this.props.user_details.amSectionId == 10 || this.props.user_details.amSectionId == 16 || this.props.user_details.id == 1015 || this.props.user_details.id == 1191) ? <Button style={{marginRight: 5, marginBottom:5 }} size="small" variant="contained" color="secondary" startIcon={<LinkIcon />} onClick={this.handleReplaceOpen}>結びつけ</Button> : ""}
                        <Modal isOpen={this.props.rent_m_corporates_detail_open} toggle={() => { this.props.change_rent_m_corporates_detail_open(false) }} fade={false}  >
                            <CorporatesDetailDialog parentComponent={0}></CorporatesDetailDialog>
                        </Modal>
                        <Button style={{ backgroundColor: "#ff9100", marginRight: 5, marginBottom:5 }} size="small" variant="contained" color="secondary" startIcon={<BusinessIcon />} onClick={()=>this.setState({ count_modal: true })}>反響・契約検索</Button>
                        <Modal isOpen={this.state.count_modal} toggle={() => this.setState({ count_modal: false })} fade={false}  size="xxl" >
                            <ModalHeader toggle={() => this.setState({ count_modal: false })}>
                                <span>反響・契約検索</span>
                                {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
                            </ModalHeader>
                            <ModalBody>
                                <CorporatesCountDialog />
                            </ModalBody>
                        </Modal>
                    </Grid>
                    <Grid justifyContent="space-between" style={{textAlign:"left"}} item xs={12}>
                        <Button style={{ marginRight: 5 }} size="small" variant="contained" color="primary" startIcon={<ControlPointIcon />} onClick={() => { this.handleDetailOpen() }}>法人新規登録</Button>
                        <Button
                            size="small"
                            startIcon={<GetAppIcon />}
                            onClick={() => this.clickCSV()}
                            variant="contained"
                            style={{ marginLeft: 5 }}>csv</Button>

                        {/* <Button style={{ marginRight: 5 }} size="small" variant="contained" color="default" startIcon={<GetAppIcon />}>
                            <CSVLink style={{ textDecoration: "none", color: "black" }} headers={headers} filename={"法人リスト.csv"} data={this.props.rent_m_corporates_list_search.map(row => ({...row, 
                    last_contact_at: row.last_contact_at ? moment(row.last_contact_at).format("YYYY-MM-DD") : "",
                    updated_at: row.updated_at ? moment(row.updated_at).format("YYYY-MM-DD HH:mm:ss") : "",
                    updated_user_id: row.updated_user_id ? this.props.users.filter(a=>a.id == row.updated_user_id)[0].userNameAll : "",
                    main_corp: row.main_corp == 1 ? "穴吹ハウジング" : row.main_corp == 2 ? "その他" : row.main_corp == 3 ? "穴吹ハウジング or その他" : "",

                    }),this)}>CSV</CSVLink>
                        </Button> */}

                        
                        
                    </Grid>
                    <Grid justifyContent="space-between" item xs={12}>
                        <FormControl variant="outlined" size="small" style={{ width: 110 }}>
                            <InputLabel id="demo-simple-select-outlined-label">表示件数</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                name="count"
                                // onChange={this.onChange}
                                value={this.props.rent_m_corporates_list_search_form.count}
                                onChange={(e) => this.handleSearch(e)}
                                label="表示件数"
                                
                            >
                                <MenuItem value={100}>100件</MenuItem>
                                <MenuItem value={500}>500件</MenuItem>
                                <MenuItem value={1000}>1000件</MenuItem>
                                <MenuItem value={5000}>5000件</MenuItem>
                                <MenuItem value={10000}>10000件</MenuItem>
                                <MenuItem value={100000}>すべて</MenuItem>
                            </Select>
                        </FormControl>

                        <Chip label={this.props.rent_m_corporates_list_count + "件"} style={{ margin: 5, marginLeft: 10, fontSize: 14, }} variant="outlined" />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={12}>
                        <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#5cb85c" }} onClick={this.handleChangeNotSearchTextEnter} startIcon={<SearchIcon />}>検索</Button>
                    </Grid>
                    <Grid justifyContent="space-between" item xs={12}>
                        <TextField 
                            fullWidth
                            size="small" 
                            onChange={this.handleChangeNotSearchText}
                            value={this.props.rent_m_corporates_list_search_form.name} 
                            margin="dense"
                            label="法人名"
                            name="name"
                            variant="outlined"
                            InputLabelProps={{ shrink: true }}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    // エンターキー押下時の処理
                                    this.handleChangeNotSearchTextEnter(e)
                                }
                            }}
                        />
                    </Grid>
                    <Grid justifyContent="space-between" item xs={12}>
                        <FormControlLabel style={{ width: "100%", marginBottom: 0, marginLeft: 0 }} control={<Checkbox style={{}} checked={this.props.rent_m_corporates_list_search_form.partnership} onChange={this.handleSearch} name="partnership" />} label="提携企業" />
                    </Grid>
                </Grid>

             
                <Modal fade={false} isOpen={this.state.modal_contacts} toggle={(e) => { this.setState({ "modal_contacts": false }) }} size="xxl" >
                    <div><div style={{ fontSize: 17, marginTop: 10, marginLeft: 15, float: "left" }}>営業対応履歴</div><IconButton
                        onClick={() => { this.setState({ "modal_contacts": false }) }}
                        style={{ float: "right", marginTop: 0, marginRight: 0 }}
                        size="large"><CloseIcon /></IconButton></div>
                    {/* <div><IconButton onClick={() => { this.props.change_rent_m_corporates_detail_open(false) }} style={{ float: "right", marginTop: 0, marginRight: 0 }}><CloseIcon /></IconButton></div> */}

                    {/* <IconButton aria-label="edit" size="small" style={{width:40, float:"right", marginRight:0}} onClick={this.handleClose}><CloseIcon /></IconButton> */}
                    <CorporatesContactsList></CorporatesContactsList>

                    {/* <Button onClick={this.handleClose}>閉じる</Button> */}

                </Modal>
                <Modal fade={false} isOpen={this.state.modal_replace} toggle={(e) => { this.setState({ "modal_replace": false }) }} size="xxl" >
                    <div><div style={{ fontSize: 17, marginTop: 10, marginLeft: 15, float: "left" }}>法人変更</div><IconButton
                        onClick={() => { this.setState({ "modal_replace": false }); this.handleClear() }}
                        style={{ float: "right", marginTop: 0, marginRight: 0 }}
                        size="large"><CloseIcon /></IconButton></div>
                    {/* <div><IconButton onClick={() => { this.props.change_rent_m_corporates_detail_open(false) }} style={{ float: "right", marginTop: 0, marginRight: 0 }}><CloseIcon /></IconButton></div> */}

                    {/* <IconButton aria-label="edit" size="small" style={{width:40, float:"right", marginRight:0}} onClick={this.handleClose}><CloseIcon /></IconButton> */}
                    <CorporatesReplace></CorporatesReplace>

                    {/* <Button onClick={this.handleClose}>閉じる</Button> */}

                </Modal>


            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates_list: state.corporation.rent_m_corporates_list,
        rent_m_corporates_list_search: state.corporation.rent_m_corporates_list_search,
        rent_m_corporates_list_count: state.corporation.rent_m_corporates_list_count,
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_corporates_top_open: state.corporation.rent_m_corporates_top_open,
        search_data: state.corporation.search_data,
        modalResponseDetail: state.responsesReducer.modalResponseDetail,
        responseid: state.responsesReducer.responseid,
        rent_m_corporates_detail_open: state.corporation.rent_m_corporates_detail_open,
        rent_m_corporates_list_search_form: state.corporation.rent_m_corporates_list_search_form,

        user_details: state.masterReducer.user_details,
        users: state.masterReducer.users,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_m_corporates_list_search_form(state) {
            dispatch(Corporation.change_rent_m_corporates_list_search_form(state))
        },
        get_rent_m_corporates_list(state) {
            dispatch(Corporation.get_rent_m_corporates_list(state))
        },
        get_rent_m_corporates_list_csv(state) {
            dispatch(Corporation.get_rent_m_corporates_list_csv(state))
        },
        
        change_search_data(state) {
            dispatch(Corporation.change_search_data(state))
        },
        change_rent_m_corporates_top_open(state) {
            dispatch(Corporation.change_rent_m_corporates_top_open(state))
        },
        insert_rent_m_corporates(state) {
            dispatch(Corporation.insert_rent_m_corporates(state))
        },
        update_rent_m_corporates(state) {
            dispatch(Corporation.update_rent_m_corporates(state))
        },
        change_rent_m_corporates(state) {
            dispatch(Corporation.change_rent_m_corporates(state))
        },
        set_rent_m_corporates(state) {
            dispatch(Corporation.set_rent_m_corporates(state))
        },
        get_rent_m_corporates_list_search(state) {
            dispatch(Corporation.get_rent_m_corporates_list_search(state))
        },
        change_responseid(state) {
            dispatch(Responses.change_responseid(state))
        },
        change_modalResponseDetail(state) {
            dispatch(Responses.change_modalResponseDetail(state))
        },


        change_search_data_replace(state) {
            dispatch(Corporation.change_search_data_replace(state))
        },
        change_rent_m_corporates_list_replace(state) {
            dispatch(Corporation.change_rent_m_corporates_list_replace(state))
        },
        change_rent_m_corporates_list_replace_search(state) {
            dispatch(Corporation.change_rent_m_corporates_list_replace_search(state))
        },
        change_rent_customer_list_replace(state) {
            dispatch(Corporation.change_rent_customer_list_replace(state))
        },
        change_rent_customer_list_replace_search(state) {
            dispatch(Corporation.change_rent_customer_list_replace_search(state))
        },

        change_rent_m_corporates_detail_open(state) {
            dispatch(Corporation.change_rent_m_corporates_detail_open(state))
        },

        change_rent_m_corporates_list_search(state) {
            dispatch(Corporation.change_rent_m_corporates_list_search(state))
        },



    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




