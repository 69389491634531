import React, { Component } from 'react';
import { connect } from 'react-redux';
// import * as Responses from '../ResponseDetail/Saga/Responses';
import '../../Css/Grid.css';
import LoadingComponent from '../Layout/LoadingComponent';

const ReactDataGrid = require('react-data-grid');

interface Props {
    customer_logs: any,
    // responselist: any,
    // customerList: any,
    // customerno: number,
    // change_customerloding: any,
    // customerdetailData: any,
    // customerlist: [],
    // change_customerloding1:any,
    // responsedetail: any,
    // responseUpdate: any,
    // change_customerloding2:any,
    // toggle: any,
    // customer_list_loading: number,
    customer_logs_loading: number,
}


interface State {

    // state types
}

// const style = {
//     border: "solid #000",
//     height:"200"
// }
//<span type="button" className="btn btn-primary" target="_blank" href={`/tradedetails/` + props.row.id}>詳細</span>
// const DetailLinkFormatter = ({ value, ...props }) => {

//     if (props.row.flg === 3) {
//         return <a type="button" rel="noreferrer" className="btn btn-primary" target="_blank" href={`/customer/` + props.row.id}>詳細</a>;
//     } else {
//         return value;
//     }
//     //return <ProgressBar now={value} label={`${value}%`} />;
// };



const columns = [

    {
        key: 'date', name: '反響日', width: 100, resizable: true
    },
    { key: 'sectionName', name: '店舗', resizable: true },
    { key: 'staffName', name: '担当', resizable: true },
    { key: 'contractDate', name: '契約日', resizable: true },
    { key: 'buildingName', name: '契約物件', width: 200, resizable: true },
    { key: 'id', name: 'ID', resizable: true },
    // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
];




// function getCellActions(column, row) {
//     //const cellActions = {
//     //    firstName: firstNameActions
//     //};
//     console.log(column)
//     //return row.id % 2 === 0 ? cellActions[column.key] : null;
// }

class CustomerGridComponent extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);


    }
    componentWillMount() {

        // this.props.customerList()

    }
    getSelectedCell(event: any) {
        // alert()
    }

    onRowClick = (rowIdx: any, row: any) => {


        // if (this.props.customerno === -1) {
        //     let customerdetail: { [key: string]: number; } = {};
        //     customerdetail.customerid=row.id;
        //     customerdetail.no=-1;
        //     this.props.customerdetailData(customerdetail)
        // }else if (this.props.customerno === 0) {
        //     this.props.change_customerloding0(0)
        //     let customerdetail: { [key: string]: number; } = {};
        //     customerdetail.customerid=row.id;
        //     customerdetail.no=0;
        //     this.props.customerdetailData(customerdetail)
        //     //this.props.responseUpdate(this.props.responsedetail);
        // }else if (this.props.customerno === 1) {
        //     this.props.change_customerloding1(0)
        //     this.props.responsedetail.rentCustomerId = row.id;
        //     this.props.responseUpdate(this.props.responsedetail);

        // } else if (this.props.customerno === 2) {
        //     this.props.change_customerloding2(0)
        //     this.props.responsedetail.rentCustomerId2 = row.id;
        //     this.props.responseUpdate(this.props.responsedetail);

        // }
        // this.props.toggle();




    }

    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.customer_logs_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", backgroundColor: "#ddd", height: 200, padding: "50px" }} > <h3>{message}</h3></div>

        );
    };

    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div>

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    enableCellSelect={false}
                    columns={columns}
                    rowGetter={(i: number) => this.props.customer_logs[i]}

                    rowsCount={this.props.customer_logs.length}
                    headerRenderer={true}
                    dragable={true}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    emptyRowsView={this.EmptyRowsView}
                    onRowClick={this.onRowClick}
                    //onCellSelected={this.getSelectedCell}
                    minHeight={200} />
            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        customer_logs: state.responsesReducer.customer_logs,
        customer_logs_loading: state.responsesReducer.customer_logs_loading,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        // responseList(state: any) {
        //     dispatch(ResponseList.responseList(state))
        // },


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerGridComponent);