import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment'
import * as Customers from './Saga/Customers';
import '../../Css/Table.css';

import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Apartment from '@mui/icons-material/Apartment';
import IconButton from '@mui/material/IconButton';
interface Props {
    customer_details_new: any,
    customer_details_old: any,
    customer_details: any,
    rent_customer_editor_disp: boolean,
    change_rent_customer_editor_disp: any,
    check_rent_contract_editor_disp: any,
    change_customeredit: any,
    rent_m_agents: any,
    rent_hearing_sheets: any,
    user_details: any,
    responsedetail: any,
    // customerno: number,
    // change_customerloding0: any,
    // customerdetailData: any,
    // // customerlist: [],
    // change_customerloding1: any,
    // responsedetail: any,
    // responseUpdate: any,
    // change_customerloding2: any,
    // // toggle: any,
    // // customerNewDispSet: any,
    // // customerReset: any,
    // customereditchange: any,
    // customerdetail: any,
    // edit: number,
    // select: number,
    // handleSelectCustomer: any,
    // handleCustomerreset: any,
}


interface State {
    // state types
}








class CustomerInfoDisp extends Component<Props, State> {



    constructor(props: Readonly<Props>) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);

    }


    age(birthDate: any) {
        birthDate = new Date(birthDate);
        // 文字列に分解
        const y2 = birthDate.getFullYear().toString().padStart(4, '0');
        const m2 = (birthDate.getMonth() + 1).toString().padStart(2, '0');
        const d2 = birthDate.getDate().toString().padStart(2, '0');

        // 今日の日付
        const today = new Date();
        const y1 = today.getFullYear().toString().padStart(4, '0');
        const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
        const d1 = today.getDate().toString().padStart(2, '0');

        // 引き算
        const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
        return age;

    }

    // toggle() {
    //     this.setState(prevState => ({
    //         modal: !prevState.modal
    //     }));
    //     }


    // toggleselect() {
    //     if (this.props.customerno === 0) {
    //         this.props.customerNewDispSet(0)
    //     } else { 
    //     this.setState(prevState => ({
    //         modalselect: !prevState.modalselect
    //     }));
    // }
    // }

    // customerreset() {

    //         this.props.customerReset(this.props.customerno)



    //     this.props.customereditchange(1)

    // }


    // handleonClick() {

    //     if (this.props.customerno === -1) {
    //         this.props.change_customerloding0(0)
    //         let customerdetail: { [key: string]: number; } = {};
    //         customerdetail.customerid = this.props.customerdetail.id;
    //         customerdetail.no = 0;
    //         this.props.customerdetailData(customerdetail)
    //         //this.props.responseUpdate(this.props.responsedetail);
    //     } else if (this.props.customerno === 1) {
    //         this.props.change_customerloding1(0)
    //         this.props.responsedetail.rentCustomerId = this.props.customerdetail.id;
    //         this.props.responseUpdate(this.props.responsedetail);

    //     } else if (this.props.customerno === 2) {
    //         this.props.change_customerloding2(0)
    //         this.props.responsedetail.rentCustomerId2 = this.props.customerdetail.id;
    //         this.props.responseUpdate(this.props.responsedetail);

    //     }
    // }

    open_corporate(id : any) {
          window.open(`/Corporate/` + id, '_blank', 'noopener')
        
      }



    render() {
        let type
        if (this.props.customer_details_new.type !== this.props.customer_details_old.type) {
            type = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.type ? "法人" : this.props.customer_details_old.type == 0 ? "個人" : ""}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.type ? "法人" : "個人"}</div></span>
        } else {
            type = this.props.customer_details_new.type ? "法人" : "個人"
        }

        let type_change = false
        if (this.props.customer_details_new.type !== this.props.customer_details_old.type) {
            type_change = true
        }

        // let memo
        // if ((this.props.customer_details_new.memo ? this.props.customer_details_new.memo : "") !== (this.props.customer_details_old.memo ? this.props.customer_details_old.memo : "")) {
        //     memo = <span>(<div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.memo}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.memo}</div>)</span>
        // } else {
        //     memo = this.props.customer_details_new.memo ? "(" + this.props.customer_details_new.memo + ")" : ""
        // }
        
        // let name1
        // if (this.props.customer_details_new.name1 !== this.props.customer_details_old.name1) {
        //     name1 = <span>
        //         <div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.name1} {this.props.customer_details_old.type ? <IconButton style={{marginLeft:10, padding:5, color:'#17a2b8'}} onClick={()=>this.open_corporate(this.props.customer_details_old.rent_m_corporate_id)}><Apartment fontSize='small'/></IconButton> : ""}</div>
        //           <ArrowDropDownIcon /> 
        //         <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.name1} {this.props.customer_details_new.type ? <IconButton style={{marginLeft:10, padding:5, color:'#17a2b8'}} onClick={()=>this.open_corporate(this.props.customer_details_new.rent_m_corporate_id)}><Apartment fontSize='small'/></IconButton> : ""}</div></span>
        // } else {
        //     name1 = <span>{this.props.customer_details_new.name1} {this.props.customer_details_new.type ? <IconButton style={{marginLeft:10, padding:5, color:'#17a2b8'}} onClick={()=>this.open_corporate(this.props.customer_details_new.rent_m_corporate_id)}><Apartment fontSize='small'/></IconButton> : ""}</span> 
        // }


        let name1
        let new_tmp = (this.props.customer_details_new.name1 ? this.props.customer_details_new.name1 : "") + (this.props.customer_details_new.memo ? "(" + this.props.customer_details_new.memo + ")" : "")
        let old_tmp = (this.props.customer_details_old.name1 ? this.props.customer_details_old.name1 : "") + (this.props.customer_details_old.memo ? "(" + this.props.customer_details_old.memo + ")" : "")
        
        if (new_tmp !== old_tmp) {
            name1 = <span>
                <div style={{ color: "red", fontWeight: "bold" }}>
                    {
                        (this.props.customer_details_old.name1 ? this.props.customer_details_old.name1 : "")
                    }
                    {
                        (this.props.customer_details_old.memo ? <span className="badge badge-info" style={{marginLeft:3}}>{this.props.customer_details_old.memo}</span> : "")
                    }
                        
                </div>
                  <ArrowDropDownIcon /> 
                <div style={{ color: "blue", fontWeight: "bold" }}>
                    {
                        (this.props.customer_details_new.name1 ? this.props.customer_details_new.name1 : "")
                    }
                    {
                        (this.props.customer_details_new.memo ? <span className="badge badge-info" style={{marginLeft:3}}>{this.props.customer_details_new.memo}</span> : "")
                    }
                </div>
                </span>
        } else {
            name1 = <span>
                    {
                        (this.props.customer_details_new.name1 ? this.props.customer_details_new.name1 : "")
                    }
                    {
                        (this.props.customer_details_new.memo ? <span className="badge badge-info" style={{marginLeft:3}}>{this.props.customer_details_new.memo}</span> : "")
                    }
                
                </span> 
        }

        let kana1
        if (this.props.customer_details_new.kana1 !== this.props.customer_details_old.kana1) {
            kana1 = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.kana1}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.kana1}</div></span>
        } else {
            kana1 = this.props.customer_details_new.kana1
        }

        let rent_m_corporate_name
        if ((this.props.customer_details_new.rent_m_corporate_name && this.props.customer_details_new.rent_m_corporate_name != "0" ? this.props.customer_details_new.rent_m_corporate_name : "") !== (this.props.customer_details_old.rent_m_corporate_name && this.props.customer_details_old.rent_m_corporate_name != "0" ? this.props.customer_details_old.rent_m_corporate_name : "")) {
            rent_m_corporate_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.rent_m_corporate_name && this.props.customer_details_old.rent_m_corporate_name != "0" ? <span>{this.props.customer_details_old.rent_m_corporate_name}<IconButton
                style={{marginLeft:10, padding:5, color:'#17a2b8'}}
                onClick={()=>this.open_corporate(this.props.customer_details_old.rent_m_corporate_id)}
                size="large"><Apartment fontSize='small'/></IconButton></span> : "" }</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.rent_m_corporate_name && this.props.customer_details_new.rent_m_corporate_name != "0" ? <span>{this.props.customer_details_new.rent_m_corporate_name}<IconButton
                style={{marginLeft:10, padding:5, color:'#17a2b8'}}
                onClick={()=>this.open_corporate(this.props.customer_details_new.rent_m_corporate_id)}
                size="large"><Apartment fontSize='small'/></IconButton></span> : ""}</div></span>
        } else {
            rent_m_corporate_name = this.props.customer_details_new.rent_m_corporate_name && this.props.customer_details_new.rent_m_corporate_name != "0" ? <span>{this.props.customer_details_new.rent_m_corporate_name}<IconButton
                style={{marginLeft:10, padding:5, color:'#17a2b8'}}
                onClick={()=>this.open_corporate(this.props.customer_details_new.rent_m_corporate_id)}
                size="large"><Apartment fontSize='small'/></IconButton></span> : ""
        }

        let rent_corporate_office_name
        if ((this.props.customer_details_new.rent_corporate_office_name && this.props.customer_details_new.rent_corporate_office_name != "0" ? this.props.customer_details_new.rent_corporate_office_name : "") !== (this.props.customer_details_old.rent_corporate_office_name && this.props.customer_details_old.rent_corporate_office_name != "0" ? this.props.customer_details_old.rent_corporate_office_name : "")) {
            rent_corporate_office_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.rent_corporate_office_name && this.props.customer_details_old.rent_corporate_office_name != "0" ? this.props.customer_details_old.rent_corporate_office_name : "" }</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.rent_corporate_office_name && this.props.customer_details_new.rent_corporate_office_name != "0" ? this.props.customer_details_new.rent_corporate_office_name : ""}</div></span>
        } else {
            rent_corporate_office_name = this.props.customer_details_new.rent_corporate_office_name && this.props.customer_details_new.rent_corporate_office_name != "0" ? this.props.customer_details_new.rent_corporate_office_name : ""
        }

        let name2
        if (this.props.customer_details_new.name2 !== this.props.customer_details_old.name2) {
            name2 = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.name2}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.name2}</div></span>
        } else {
            name2 = this.props.customer_details_new.name2
        }

        let kana2
        if (this.props.customer_details_new.kana2 !== this.props.customer_details_old.kana2) {
            kana2 = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.kana2}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.kana2}</div></span>
        } else {
            kana2 = this.props.customer_details_new.kana2
        }

        let executive
        if (this.props.customer_details_new.executive !== this.props.customer_details_old.executive) {
            executive = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.executive}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.executive}</div></span>
        } else {
            executive = this.props.customer_details_new.executive
        }

        let agent_name
        let name_old = this.props.customer_details_old.rent_m_agent_id ? this.props.rent_m_agents.filter((e:any) => e.id == this.props.customer_details_old.rent_m_agent_id)[0].name : "";
        let name_new = this.props.customer_details_new.rent_m_agent_id ? this.props.rent_m_agents.filter((e:any) => e.id == this.props.customer_details_new.rent_m_agent_id)[0].name : "";
        if (this.props.customer_details_new.rent_m_agent_id !== this.props.customer_details_old.rent_m_agent_id) {
            agent_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{name_old}{this.props.customer_details_old.rent_m_agent_id == 5 && this.props.customer_details_old.agent_etc ? "("+this.props.customer_details_old.agent_etc+")" :""}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{name_new}{this.props.customer_details_new.rent_m_agent_id == 5 && this.props.customer_details_new.agent_etc ? "("+this.props.customer_details_new.agent_etc+")" :""}</div></span>
        } else {
            agent_name = this.props.customer_details_new.rent_m_agent_id == 5 && this.props.customer_details_new.agent_etc ? name_new + "(" + this.props.customer_details_new.agent_etc + ")" :name_new
        }


        let office_name
        if ((this.props.customer_details_new.office_name ? this.props.customer_details_new.office_name:"") + (this.props.customer_details_new.office_rent_corporate_office_name && this.props.customer_details_new.office_rent_corporate_office_name != "0" ? this.props.customer_details_new.office_rent_corporate_office_name :"") !== (this.props.customer_details_old.office_name ? this.props.customer_details_old.office_name : "") + (this.props.customer_details_old.office_rent_corporate_office_name && this.props.customer_details_old.office_rent_corporate_office_name != "0" ? this.props.customer_details_old.office_rent_corporate_office_name : "")) {
            office_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{(this.props.customer_details_old.office_name && this.props.customer_details_old.office_name != "0" ? this.props.customer_details_old.office_name : "") + " " + (this.props.customer_details_old.office_rent_corporate_office_name && this.props.customer_details_old.office_rent_corporate_office_name != "0" ? this.props.customer_details_old.office_rent_corporate_office_name :"")}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{(this.props.customer_details_new.office_name && this.props.customer_details_new.office_name != "0" ? this.props.customer_details_new.office_name:"") + " " + (this.props.customer_details_new.office_rent_corporate_office_name && this.props.customer_details_new.office_rent_corporate_office_name != "0" ? this.props.customer_details_new.office_rent_corporate_office_name : "")}</div></span>
            // office_name=this.props.customer_details_old.office_name
        } else {
            office_name = (this.props.customer_details_new.office_name ? this.props.customer_details_new.office_name : "") + " " + (this.props.customer_details_new.office_rent_corporate_office_name && this.props.customer_details_new.office_rent_corporate_office_name  != "0" ? this.props.customer_details_new.office_rent_corporate_office_name : "")
        }

        let m_country = [{ id: 0, value: "日本" }, { id: 1, value: "中国" }, { id: 2, value: "ベトナム" }, { id: 3, value: "ネパール" }, { id: 4, value: "韓国" }, { id: 5, value: "台湾" }, { id: 6, value: "その他" }]
        let country
        if (this.props.customer_details_new.rent_m_country_id !== this.props.customer_details_old.rent_m_country_id) {
            country = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.rent_m_country_id >= 0 ? m_country.filter(e => e.id === this.props.customer_details_old.rent_m_country_id)[0].value : ""}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.rent_m_country_id >= 0 ? m_country.filter(e => e.id === this.props.customer_details_new.rent_m_country_id)[0].value : ""}</div></span>
        } else {
            country = this.props.customer_details_new.rent_m_country_id >= 0 ? m_country.filter(e => e.id === this.props.customer_details_new.rent_m_country_id)[0].value : ""
        }

        let sex
        if (this.props.customer_details_new.sex !== this.props.customer_details_old.sex) {
            sex = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.sex === 1 ? "男" : this.props.customer_details_old.sex === 2 ? "女" : "" }</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.sex === 1 ? "男" : this.props.customer_details_new.sex === 2 ? "女" : ""}</div></span>
        } else {
            sex = this.props.customer_details_new.sex === 1 ? "男" : this.props.customer_details_new.sex === 2 ? "女" : "" 
        }
        let birthday
        if (this.props.customer_details_new.birthday !== this.props.customer_details_old.birthday) {
            birthday = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.birthday != null ? moment(this.props.customer_details_old.birthday).format("YYYY/MM/DD") + "(" + this.age(this.props.customer_details_old.birthday) + "歳)" : ""}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.birthday != null ? moment(this.props.customer_details_new.birthday).format("YYYY/MM/DD") + "(" + this.age(this.props.customer_details_new.birthday) + "歳)" : ""}</div></span>
        } else {
            birthday = this.props.customer_details_new.birthday != null ? moment(this.props.customer_details_new.birthday).format("YYYY/MM/DD") + "(" + this.age(this.props.customer_details_new.birthday) + "歳)" : ""

        }


        let post
        if (this.props.customer_details_new.post !== this.props.customer_details_old.post) {
            post = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.post}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.post}</div></span>
        } else {
            post = this.props.customer_details_new.post ? this.props.customer_details_new.post : null
        }

        let add1
        if (this.props.customer_details_new.add1 !== this.props.customer_details_old.add1) {
            add1 = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.add1}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.add1}</div></span>
        } else {
            add1 = this.props.customer_details_new.add1 ? this.props.customer_details_new.add1 : null
        }
        let add2
        if (this.props.customer_details_new.add2 !== this.props.customer_details_old.add2) {
            add2 = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.add2}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.add2}</div></span>
        } else {
            add2 = this.props.customer_details_new.add2 ? this.props.customer_details_new.add2 : null
        }
        let tel1
        // alert(this.props.customer_details_new.tel1 + this.props.customer_details_new.tel1_remarks + " sss " + this.props.customer_details_old.tel1 + this.props.customer_details_old.tel1_remarks)
        if ((this.props.customer_details_new.tel1 ? this.props.customer_details_new.tel1 : "") + (this.props.customer_details_new.tel1_remarks ? this.props.customer_details_new.tel1_remarks : "") !== (this.props.customer_details_old.tel1 ? this.props.customer_details_old.tel1 : "") + (this.props.customer_details_old.tel1_remarks ? this.props.customer_details_old.tel1_remarks : "")) {
            let tel1_new = this.props.customer_details_new.tel1 ? this.props.customer_details_new.tel1 : ""
            tel1_new = tel1_new + (this.props.customer_details_new.tel1_remarks ? "(" + this.props.customer_details_new.tel1_remarks + ")" : "")
            let tel1_old = this.props.customer_details_old.tel1 ? this.props.customer_details_old.tel1 : ""
            tel1_old = tel1_old + (this.props.customer_details_old.tel1_remarks ? "(" + this.props.customer_details_old.tel1_remarks + ")" : "")
            tel1 = <span><div style={{ color: "red", fontWeight: "bold" }}>{tel1_old}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{tel1_new}</div></span>
        } else {
            tel1 = this.props.customer_details_new.tel1 ? this.props.customer_details_new.tel1 : ""
            tel1 = tel1 + (this.props.customer_details_new.tel1_remarks ? "(" + this.props.customer_details_new.tel1_remarks + ")" : "")
        }

        let tel2
        if ((this.props.customer_details_new.tel2 ? this.props.customer_details_new.tel2 : "") + (this.props.customer_details_new.tel2_remarks ? this.props.customer_details_new.tel2_remarks : "") !== (this.props.customer_details_old.tel2 ? this.props.customer_details_old.tel2 : "") + (this.props.customer_details_old.tel2_remarks ? this.props.customer_details_old.tel2_remarks : "")) {
            let tel2_new = this.props.customer_details_new.tel2 ? this.props.customer_details_new.tel2 : ""
            tel2_new = tel2_new + (this.props.customer_details_new.tel2_remarks ? "(" + this.props.customer_details_new.tel2_remarks + ")" : "")
            let tel2_old = this.props.customer_details_old.tel2 ? this.props.customer_details_old.tel2 : ""
            tel2_old = tel2_old + (this.props.customer_details_old.tel2_remarks ? "(" + this.props.customer_details_old.tel2_remarks + ")" : "")
            tel2 = <span><div style={{ color: "red", fontWeight: "bold" }}>{tel2_old}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{tel2_new}</div></span>

        } else {
            tel2 = this.props.customer_details_new.tel2 ? this.props.customer_details_new.tel2 : ""
            tel2 = tel2 + (this.props.customer_details_new.tel2_remarks ? "(" + this.props.customer_details_new.tel2_remarks + ")" : "")
        }

        let fax
        if ((this.props.customer_details_new.fax ? this.props.customer_details_new.fax:"") + (this.props.customer_details_new.fax_remarks ? this.props.customer_details_new.fax_remarks :"") !== (this.props.customer_details_old.fax ? this.props.customer_details_old.fax : "") + (this.props.customer_details_old.fax_remarks ? this.props.customer_details_old.fax_remarks : "")) {
            let fax_new = this.props.customer_details_new.fax ? this.props.customer_details_new.fax : ""
            fax_new = fax_new + (this.props.customer_details_new.fax_remarks ? "(" + this.props.customer_details_new.fax_remarks + ")" : "")
            let fax_old = this.props.customer_details_old.fax ? this.props.customer_details_old.fax : ""
            fax_old = fax_old + (this.props.customer_details_old.fax_remarks ? "(" + this.props.customer_details_old.fax_remarks + ")" : "")
            fax = <span><div style={{ color: "red", fontWeight: "bold" }}>{fax_old}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{fax_new}</div></span>
        } else {
            fax = this.props.customer_details_new.fax ? this.props.customer_details_new.fax : ""
            fax = fax + (this.props.customer_details_new.fax_remarks ? "(" + this.props.customer_details_new.fax_remarks + ")" : "")

        }

        let mail1
        if ((this.props.customer_details_new.mail1 ? this.props.customer_details_new.mail1 : "") + (this.props.customer_details_new.mail1_remarks ? this.props.customer_details_new.mail1_remarks : "") !== (this.props.customer_details_old.mail1 ? this.props.customer_details_old.mail1 : "") + (this.props.customer_details_old.mail1_remarks ? this.props.customer_details_old.mail1_remarks : "")) {
            let mail1_new = this.props.customer_details_new.mail1 ? this.props.customer_details_new.mail1 : ""
            mail1_new = mail1_new + (this.props.customer_details_new.mail1_remarks ? "(" + this.props.customer_details_new.mail1_remarks + ")" : "")
            let mail1_old = this.props.customer_details_old.mail1 ? this.props.customer_details_old.mail1 : ""
            mail1_old = mail1_old + (this.props.customer_details_old.mail1_remarks ? "(" + this.props.customer_details_old.mail1_remarks + ")" : "")
            mail1 = <span><div style={{ color: "red", fontWeight: "bold" }}>{mail1_old}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{mail1_new}</div></span>
        } else {
            mail1 = this.props.customer_details_new.mail1 ? this.props.customer_details_new.mail1 : ""
            mail1 = mail1 + (this.props.customer_details_new.mail1_remarks ? "(" + this.props.customer_details_new.mail1_remarks + ")" : "")

            // mail1 = this.props.rent_contract_details_new.mail1 + this.props.rent_contract_details_new.mail1_remarks ? this.props.rent_contract_details_new.mail1 + (this.props.rent_contract_details_new.mail1_remarks ? "(" + this.props.rent_contract_details_new.mail1_remarks + ")" : "") : null
        }
        let mail2
        if ((this.props.customer_details_new.mail2 ? this.props.customer_details_new.mail2 : "") + (this.props.customer_details_new.mail2_remarks ? this.props.customer_details_new.mail2_remarks : "") !== (this.props.customer_details_old.mail2 ? this.props.customer_details_old.mail2 : "") + (this.props.customer_details_old.mail2_remarks ? this.props.customer_details_old.mail2_remarks : "")) {
            let mail2_new = this.props.customer_details_new.mail2 ? this.props.customer_details_new.mail2 : ""
            mail2_new = mail2_new + (this.props.customer_details_new.mail2_remarks ? "(" + this.props.customer_details_new.mail2_remarks + ")" : "")
            let mail2_old = this.props.customer_details_old.mail2 ? this.props.customer_details_old.mail2 : ""
            mail2_old = mail2_old + (this.props.customer_details_old.mail2_remarks ? "(" + this.props.customer_details_old.mail2_remarks + ")" : "")
            mail2 = <span><div style={{ color: "red", fontWeight: "bold" }}>{mail2_old}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{mail2_new}</div></span>
        } else {
            mail2 = this.props.customer_details_new.mail2 ? this.props.customer_details_new.mail2 : ""
            mail2 = mail2 + (this.props.customer_details_new.mail2_remarks ? "(" + this.props.customer_details_new.mail2_remarks + ")" : "")

        }

        let remarks
        if (this.props.customer_details_new.remarks !== this.props.customer_details_old.remarks) {
            remarks = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.customer_details_old.remarks}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.customer_details_new.remarks}</div></span>
        } else {
            remarks = this.props.customer_details_new.remarks
        }




        // if (this.props.customerdetail.id > 0) {

        return (
            <div>

                <table className='basicList'>
                    <colgroup style={{ width: "20%" }} />
                    <colgroup style={{ width: "30%" }} />
                    <colgroup style={{ width: "20%" }} />
                    <colgroup style={{ width: "30%" }} />
                    <tbody>
                        <tr >
                            <th>契約形態</th>
                            <td colSpan={3}>
                                {type}
                                
                                {(this.props.user_details.amSectionId==10 || this.props.customer_details.workflow_id==0 || this.props.customer_details.parking_application_id>0)?  <Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.props.change_customeredit(1)}>
                                    編集
                                </Button>:<i style={{ float: "right" }}>作成依頼済みのためHS4から編集してください。</i>} 
                                {this.props.responsedetail.rent_contract_id ? <span className="badge badge-warning" style={{ float: "right", marginRight:10 }} >申込済</span>:""}
                                {/* {this.props.edit !== 0 ? <Button color="primary" onClick={this.props.handleCustomerreset} className="float-right" size="sm">新規</Button> : ""}
                                {this.props.edit !== 0 ? <Button color="success" onClick={() => this.props.customereditchange(1)} className="float-right" style={{ marginRight: '5px' }} size="sm">編集</Button> : ""}
                                {this.props.select === 1 ? <Button color="success" onClick={() => this.props.handleSelectCustomer(this.props.customerdetail)} className="float-right" size="sm">この顧客情報を選択</Button> : ""} */}
                            </td>
                        </tr>
                        <tr style={{ display: this.props.customer_details_new.type || type_change ? '' : 'none' }}>
                            <th>法人名</th>
                            <td colSpan={3}>
                                <div style={{fontWeight: "bold"}}>{rent_m_corporate_name}</div>
                                <div style={{fontWeight: "bold"}}>{rent_corporate_office_name}</div>
                            </td>

                        </tr>
                        <tr>
                            <th>{this.props.customer_details_new.type ? "契約書用契約者名" : "お客様名"}</th>
                            <td colSpan={3}>
                                <div style={{ fontSize: 9 }}>{kana1}</div>
                                <div style={{ fontWeight: this.props.customer_details_new.type ? "normal":"bold" }}>{name1}</div>
                            </td>

                        </tr>
                        <tr style={{ display: this.props.customer_details_new.type || type_change ? '' : 'none' }}>
                            <th>入居者名</th>
                            <td colSpan={3}>
                                <div style={{ fontSize: 9 }}>{kana2}</div>
                                {name2}

                            </td>

                        </tr>

                        <tr style={{ display: executive ? '' : 'none' }}>
                            <th>代表者名</th>
                            <td colSpan={3}>
                                {executive}
                            </td>

                        </tr>

                        {/* <tr style={{ display: agent_name ? '' : 'none' }}>
                            <th>代行会社</th>
                            <td colSpan={3}>
                                {agent_name}
                            </td>

                        </tr> */}
                        
                        <tr style={{ display: office_name ? '' : 'none' }}>
                            <th>勤務先</th>
                            <td colSpan={3}>
                                {office_name}
                            </td>

                        </tr>
                        <tr style={{ display: (sex || birthday) ? '' : 'none' }}>
                            <th>性別</th>
                            <td>{sex}</td>
                            <th>生年月日</th>
                            <td>{birthday}



                            </td>
                        </tr>

                        <tr style={{ display: country ? '' : 'none' }}>
                            <th>国籍</th>
                            <td colSpan={3}>
                                <div className="form-inline">
                                    {country}
                                </div>
                            </td>

                        </tr>
                        <tr style={{ display: post ? '' : 'none' }}>
                            <th>郵便番号</th>
                            <td colSpan={3}>
                                <div className="form-inline">
                                    {post}
                                </div>
                            </td>

                        </tr>
                        <tr style={{ display: add1 ? '' : 'none' }}>
                            <th>住所1</th>
                            <td colSpan={3}>{add1}
                            </td>
                        </tr>
                        <tr style={{ display: add2 ? '' : 'none' }}>
                            <th>住所2</th>
                            <td colSpan={3}>{add2}
                            </td>
                        </tr>
                        <tr style={{ display: tel1 ? '' : 'none' }}>
                            <th>TEL1</th>
                            <td colSpan={3}>{tel1}　</td>
                        </tr>
                        <tr style={{ display: tel2 ? '' : 'none' }}>
                            <th>TEL2</th>
                            <td colSpan={3}>{tel2}　</td>
                        </tr>
                        <tr style={{ display: fax ? '' : 'none' }}>
                            <th>Fax</th>
                            <td colSpan={3}>{fax}</td>
                        </tr>
                        <tr style={{ display: mail1 ? '' : 'none' }}>
                            <th>メール1</th>
                            <td colSpan={3}>{mail1}</td>
                        </tr>
                        <tr style={{ display: mail2 ? '' : 'none' }}>
                            <th>メール2</th>
                            <td colSpan={3}>{mail2}</td>
                        </tr>
                        <tr style={{ display: remarks ? '' : 'none' }}>
                            <th>お客様備考</th>
                            <td colSpan={3}>{remarks}
                            </td>

                        </tr>
                    </tbody>
                </table>
                
                {this.props.rent_hearing_sheets && this.props.rent_hearing_sheets.mypage_partnership > 0 ? 
                <table className='basicList' style={{marginTop:5}}>
                    <colgroup style={{ width: "20%" }} />
                    <colgroup style={{ width: "30%" }} />
                    <colgroup style={{ width: "20%" }} />
                    <colgroup style={{ width: "30%" }} />
                    <tbody>
                        <tr>
                            <th>業務提携申告</th>
                            <td>あり</td>
                            <th>勤務先</th>
                            <td>{this.props.rent_hearing_sheets.mypage_partnership_remarks}</td>
                        </tr>
                    </tbody>
                </table>
                :""}

                

                
                {this.props.customer_details.type && (this.props.customer_details.referral_fee || 
                    this.props.customer_details.manage_referral_fee || 
                    this.props.customer_details.agent_charge || 
                    this.props.customer_details.manage_agent_charge || 
                    this.props.customer_details.be_discounted || 
                    this.props.customer_details.partnership_remarks) ? <>
                        <div style={{fontWeight:"bold", color:"red"}}>＜法人契約　提携内容＞</div>
                        <table className='basicList'>
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <tbody>
                                <tr >
                                    <th>紹介料（税別）</th>
                                    <td>
                                        <div>
                                            {this.props.customer_details.manage_referral_fee ? "管理：" + this.props.customer_details.manage_referral_fee :""}
                                            {this.props.customer_details.manage_referral_fee ? this.props.customer_details.manage_referral_fee_unit > 0 ? "円":"％" : ""}
                                            {this.props.customer_details.manage_referral_fee ? this.props.customer_details.manage_referral_fee_subject ? "（":"" : ""}
                                            {this.props.customer_details.manage_referral_fee ? this.props.customer_details.manage_referral_fee_subject ? this.props.customer_details.manage_referral_fee_subject.split(',').map(function (item:any) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.customer_details.manage_referral_fee ? this.props.customer_details.manage_referral_fee_subject ? "）":"" : ""}
                                        </div>
                                        <div>
                                            {this.props.customer_details.referral_fee ? "他：" + this.props.customer_details.referral_fee :""}
                                            {this.props.customer_details.referral_fee ? this.props.customer_details.referral_fee_unit > 0 ? "円":"％" : ""}
                                            {this.props.customer_details.referral_fee ? this.props.customer_details.referral_fee_subject ? "（":"" : ""}
                                            {this.props.customer_details.referral_fee ? this.props.customer_details.referral_fee_subject ? this.props.customer_details.referral_fee_subject.split(',').map(function (item:any) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.customer_details.referral_fee ? this.props.customer_details.referral_fee_subject ? "）":"" : ""}
                                        </div>
                                    </td>
                                    <th>仲介手数料の割引</th>
                                    <td>
                                        <div>
                                            {this.props.customer_details.manage_agent_charge ? "管理：" + this.props.customer_details.manage_agent_charge : ""}
                                            {this.props.customer_details.manage_agent_charge ? this.props.customer_details.manage_agent_charge_unit > 0 ? "円":"％" : ""}
                                            {this.props.customer_details.manage_agent_charge ? this.props.customer_details.manage_agent_charge_subject ? "（":"" : ""}
                                            {this.props.customer_details.manage_agent_charge ? this.props.customer_details.manage_agent_charge_subject ? this.props.customer_details.manage_agent_charge_subject.split(',').map(function (item:any) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.customer_details.manage_agent_charge ? this.props.customer_details.manage_agent_charge_subject ? "）":"":""}
                                        </div>
                                        <div>
                                            {this.props.customer_details.agent_charge ? "他：" + this.props.customer_details.agent_charge : ""}
                                            {this.props.customer_details.agent_charge ? this.props.customer_details.agent_charge_unit > 0 ? "円":"％" : ""}
                                            {this.props.customer_details.agent_charge ? this.props.customer_details.agent_charge_subject ? "（":"" : ""}
                                            {this.props.customer_details.agent_charge ? this.props.customer_details.agent_charge_subject ? this.props.customer_details.agent_charge_subject.split(',').map(function (item:any) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.customer_details.agent_charge ? this.props.customer_details.agent_charge_subject ? "）":"" : ""}
                                        </div>
                                    </td>
                                </tr>
                                {this.props.customer_details.be_discounted ? <tr >
                                    <th>割引対象</th>
                                    <td colSpan={3}>{this.props.customer_details.be_discounted}</td>
                                </tr>:""}
                                {this.props.customer_details.partnership_remarks ? <tr >
                                    <th>注意事項・支払い方法・その他</th>
                                    <td colSpan={3}>
                                        {/* {this.props.customer_details.partnership_remarks} */}
                                        <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.customer_details.partnership_remarks.length ? this.props.customer_details.partnership_remarks.replace(/\r?\n/g, '<br/>') : '' }}></span>
                                    </td>
                                </tr>:""}
                            </tbody>
                        </table></>:""}

                {
                    (this.props.customer_details.type == 0) &&
                    (this.props.customer_details.office_referral_fee || 
                    this.props.customer_details.office_manage_referral_fee || 
                    this.props.customer_details.office_agent_charge || 
                    this.props.customer_details.office_manage_agent_charge || 
                    this.props.customer_details.office_be_discounted || 
                    this.props.customer_details.office_partnership_remarks) ? <>
                        <div style={{fontWeight:"bold", color:"red"}}>＜勤務先　提携内容＞</div>
                        <table className='basicList'>
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <tbody>
                                <tr >
                                    <th>紹介料（税別）</th>
                                    <td>
                                        <div>
                                            {this.props.customer_details.office_manage_referral_fee ? "管理：" + this.props.customer_details.office_manage_referral_fee :""}
                                            {this.props.customer_details.office_manage_referral_fee ? this.props.customer_details.office_manage_referral_fee_unit > 0 ? "円":"％" : ""}
                                            {this.props.customer_details.office_manage_referral_fee ? this.props.customer_details.office_manage_referral_fee_subject ? "（":"" : ""}
                                            {this.props.customer_details.office_manage_referral_fee ? this.props.customer_details.office_manage_referral_fee_subject ? this.props.customer_details.office_manage_referral_fee_subject.split(',').map(function (item:any) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.customer_details.office_manage_referral_fee ? this.props.customer_details.office_manage_referral_fee_subject ? "）":"" : ""}
                                        </div>
                                        <div>
                                            {this.props.customer_details.office_referral_fee ? "他：" + this.props.customer_details.office_referral_fee :""}
                                            {this.props.customer_details.office_referral_fee ? this.props.customer_details.office_referral_fee_unit > 0 ? "円":"％" : ""}
                                            {this.props.customer_details.office_referral_fee ? this.props.customer_details.office_referral_fee_subject ? "（":"" : ""}
                                            {this.props.customer_details.office_referral_fee ? this.props.customer_details.office_referral_fee_subject ? this.props.customer_details.office_referral_fee_subject.split(',').map(function (item:any) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.customer_details.office_referral_fee ? this.props.customer_details.office_referral_fee_subject ? "）":"" : ""}
                                        </div>
                                    </td>
                                    <th>仲介手数料の割引</th>
                                    <td>
                                        <div>
                                            {this.props.customer_details.office_manage_agent_charge ? "管理：" + this.props.customer_details.office_manage_agent_charge : ""}
                                            {this.props.customer_details.office_manage_agent_charge ? this.props.customer_details.office_manage_agent_charge_unit > 0 ? "円":"％" : ""}
                                            {this.props.customer_details.office_manage_agent_charge ? this.props.customer_details.office_manage_agent_charge_subject ? "（":"" : ""}
                                            {this.props.customer_details.office_manage_agent_charge ? this.props.customer_details.office_manage_agent_charge_subject ? this.props.customer_details.office_manage_agent_charge_subject.split(',').map(function (item:any) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.customer_details.office_manage_agent_charge ? this.props.customer_details.office_manage_agent_charge_subject ? "）":"":""}
                                        </div>
                                        <div>
                                            {this.props.customer_details.office_agent_charge ? "他：" + this.props.customer_details.office_agent_charge : ""}
                                            {this.props.customer_details.office_agent_charge ? this.props.customer_details.office_agent_charge_unit > 0 ? "円":"％" : ""}
                                            {this.props.customer_details.office_agent_charge ? this.props.customer_details.office_agent_charge_subject ? "（":"" : ""}
                                            {this.props.customer_details.office_agent_charge ? this.props.customer_details.office_agent_charge_subject ? this.props.customer_details.office_agent_charge_subject.split(',').map(function (item:any) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.customer_details.office_agent_charge ? this.props.customer_details.office_agent_charge_subject ? "）":"" : ""}
                                        </div>
                                    </td>
                                </tr>
                                {this.props.customer_details.office_be_discounted ? <tr >
                                    <th>割引対象</th>
                                    <td colSpan={3}>{this.props.customer_details.office_be_discounted}</td>
                                </tr>:""}
                                {this.props.customer_details.office_partnership_remarks ? <tr >
                                    <th>注意事項・支払い方法・その他</th>
                                    <td colSpan={3}>
                                        {/* {this.props.customer_details.office_partnership_remarks} */}
                                        <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.customer_details.office_partnership_remarks.length ? this.props.customer_details.office_partnership_remarks.replace(/\r?\n/g, '<br/>') : '' }}></span>
                                    </td>
                                </tr>:""}
                            </tbody>
                        </table></>:""}
            </div>
        );
        //     } else {
        //     return (
        //         <div>
        //          {/* 入居者登録  <Button color="success" onClick={this.toggleselect} className="float-right" size="sm">選択</Button>
        //             <Modal isOpen={this.state.modalselect} toggle={this.toggleselect} size="lg" fade={false}>
        //                 <CustomerList
        //                     toggle={this.toggleselect} customerno={this.props.customerno} id={this.props.id} onSubmit={this.props.customerUpdate}
        //                 />
        //             </Modal> */}
        //         </div>
        //     );

        // }

    }
}

//containers
const mapStateToProps = (state: any) => {
    return {
        customer_details_old: state.customersReducer.customer_details_old,
        customer_details_new: state.customersReducer.customer_details_new,
        customer_details: state.customersReducer.customer_details,
        rent_customer_editor_disp: state.contracts.rent_customer_editor_disp,

        rent_m_agents: state.customersReducer.rent_m_agents,
        rent_hearing_sheets: state.responsesReducer.rent_hearing_sheets,
        user_details: state.masterReducer.user_details,
        responsedetail: state.responsesReducer.responsedetail,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_customeredit(state: any) {
            dispatch(Customers.change_customeredit(state))
        },

        // customerNewDispSet(state: any) {
        //     dispatch(Customers.customerNewDispSet(state))
        // },
        // customerReset(state:any) {
        //     dispatch(Customers.customerReset(state))
        // },
        // change_customerloding(state: number) {
        //     dispatch(Customers.change_customerloding(state))
        // },
        // change_rent_customer_editor_disp(state: any) {
        //     dispatch(Contracts.change_rent_customer_editor_disp(state))
        // },


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerInfoDisp);
