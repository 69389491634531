
const authentication = require('../../react-azure-adb2c2').default;

export const get_user_details = () => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/users/details`;

    const token = authentication.getAccessToken();
 



    return fetch(url, {
        method: 'get',
   
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}