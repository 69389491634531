// import { MasterState } from './Types';
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';



import { call, put, takeEvery } from 'redux-saga/effects';
import * as RentMMedeasApi from './Api/RentMMedeasApi';
import * as UsersApi from './Api/UsersApi';
import * as RentAgentsApi from './Api/RentAgentsApi';
import * as RentMReasonsApi from './Api/RentMReasonsApi';
import * as TmTenpoesApi from './Api/TmTenpoesApi';
import * as TmSetsubiApi from './Api/TmSetsubiApi';
import * as TenantMAddressesApi from './Api/TenantMAddressesApi';
import * as TmChuugakkousApi from './Api/TmChuugakkousApi';
import * as TmShougakkousApi from './Api/TmShougakkousApi';
import * as ShikuchousonMasterApi from './Api/ShikuchousonMasterApi';
import * as TodoufukenMasterApi from './Api/TodoufukenMasterApi';
import * as TmKanriCorpsApi from './Api/TmKanriCorpsApi';
import * as TmOwnersApi from './Api/TmOwnersApi';
import * as RentMContractTypesApi from './Api/RentMContractTypesApi';
import * as TmChinryouKoumokusApi from './Api/TmChinryouKoumokusApi';
import * as MPenaltiesApi from './Api/MPenaltiesApi';
import * as m_stations_api from './Api/m_stations_api';



import * as tm_chinryou_koumoku_api from './Api/tm_chinryou_koumoku_api';









//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface MasterState {

    rentMMedeas: any;
    users: any;
    rentAgents: any;
    rentMReasons: any;
    user_details: any;
    shops: any;
    users_select: any;
    window_height: number;
    setsubi_master: any,
    section_select: any,
    shougakkous: any,
    chuugakkous: any,
    chomeis: any,
    todoufukens: any,
    shikuchousons: any,
    chouikis: any,
    kanricorps: any,
    tm_owners: any,
    tm_owners_select: any,
    draggable: number,
    rent_m_contract_types: any,
    rent_m_contract_types_detail: any,
    tm_chinryou_koumokus: any,
    m_penalties: any,
    m_stations: any,
    tm_chinryou_koumoku: any,
    tm_chinryou_koumoku_select: any,
}
const initialState: MasterState = {


    rentMMedeas: [],
    users: [],
    rentAgents: [],
    rentMReasons: [],
    user_details: [],
    shops: [],
    users_select: [],
    window_height: 0,
    setsubi_master: [],
    section_select: [],
    shougakkous: [],
    chuugakkous: [],
    chomeis: [],
    todoufukens: [],
    shikuchousons: [],
    chouikis: [],
    kanricorps: [],
    tm_owners: [],
    tm_owners_select: [],
    draggable: 1,
    rent_m_contract_types: [],
    rent_m_contract_types_detail: [],
    tm_chinryou_koumokus: [],
    m_penalties: [],
    m_stations: [],
    tm_chinryou_koumoku: [],
    tm_chinryou_koumoku_select: [],
}



///action 
const actionCreator = actionCreatorFactory();

export const getRentMMedeas = actionCreator('RENT_M_MEDEAS_REQUEST');
export const getRentMMedeasSuccess = actionCreator("RENT_M_MEDEAS_SUCCESS");
export const getRentMMedeasFailure = actionCreator("RENT_M_MEDEAS_FAILURE");

export const getUsers = actionCreator('USERS_REQUEST');
export const getUsersSuccess = actionCreator("USERS_SUCCESS");
export const getUsersFailure = actionCreator("USERS_FAILURE");

export const get_users_select = actionCreator('GET_USERS_SELECT');


export const get_user_details = actionCreator('GET_USER_DETAILS');
export const get_user_details_success = actionCreator("GET_USER_DETAILS_SUCCESS");
export const get_user_details_failure = actionCreator("GET_USER_DETAILS_FAILURE");

export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");

export const get_shops = actionCreator('GET_SHOPS');
export const get_shops_success = actionCreator("GET_SHOPS_SUCCESS");
export const get_shops_failure = actionCreator("GET_SHOPS_FAILURE");
export const get_section_select = actionCreator('GET_SECTION_SELECT');


export const get_chomeis = actionCreator('GET_CHOUMEIS');
export const get_chomeis_success = actionCreator("GET_CHOUMEIS_SUCCESS");
export const get_shougakkous = actionCreator('GET_SHOUGAKKOUS');
export const get_shougakkous_success = actionCreator("GET_SHOUGAKKOUS_SUCCESS");
export const get_chuugakkous = actionCreator('GET_CHUUGAKKOUS');
export const get_chuugakkous_success = actionCreator("GET_CHUUGAKKOUS_SUCCESS");



export const change_window_height = actionCreator<number>('CHANGE_WINDOW_HEIGHT');

export const get_setsubi_master = actionCreator('GET_SETSUBI_MASTER');
export const get_setsubi_master_success = actionCreator("GET_SETSUBI_MASTER_SUCCESS");

export const get_todoufukens = actionCreator('GET_TODOUFUKENS');
export const get_todoufukens_success = actionCreator('GET_TODOUFUKENS_SUCCESS');
export const get_shikuchousons = actionCreator("GET_SHIKUCHOUSONS");
export const get_shikuchousons_success = actionCreator("GET_SHIKUCHOUSONS_SUCCESS");
export const get_chouikis_success = actionCreator("GET_CHOUIKIS_SUCCESS");

export const get_kanricorps = actionCreator("GET_KANRICORPS");
export const get_kanricorps_success = actionCreator("GET_KANRICORPS_SUCCESS");


export const get_tm_owners = actionCreator("GET_TM_OWNERS");
export const get_tm_owners_success = actionCreator("GET_TM_OWNERS_SUCCESS");
export const get_tm_owners_select_success = actionCreator("GET_TM_OWNERS_SELECT_SUCCESS");

export const change_draggable = actionCreator<number>('CHANGE_DRAGGABLE');

export const get_rent_m_contract_types = actionCreator('GET_RENT_M_CONTRACT_TYPES');

export const get_rent_m_contract_types_success = actionCreator<any>('GET_RENT_M_CONTRACT_TYPES_SUCCESS');

export const get_rent_m_contract_types_detail_success = actionCreator<any>('GET_RENT_M_CONTRACT_TYPES_DETAIL_SUCCESS');

export const get_tm_chinryou_koumokus = actionCreator('GET_TM_CHINRYOU_KOUMOKUS');
export const get_tm_chinryou_koumokus_success = actionCreator<any>('GET_TM_CHINRYOU_KOUMOKUS_SUCCESS');


export const get_m_penalties = actionCreator('GET_M_PENALTIES');
export const get_m_penalties_success = actionCreator<any>('GET_M_PENALTIES_SUCCESS');

export const get_m_stations = actionCreator('GET_M_STATIONS');
export const get_m_stations_success = actionCreator<any>('GET_M_STATIONS_SUCCESS');

export const get_tm_chinryou_koumoku = actionCreator('GET_TM_CHINRYOU_KOUMOKU');
export const change_tm_chinryou_koumoku = actionCreator<any>('CHANGE_TM_CHINRYOU_KOUMOKU');
export const change_tm_chinryou_koumoku_select = actionCreator<any>('CHANGE_TM_CHINRYOU_KOUMOKU_SELECT');



export const masterReducer = reducerWithInitialState(initialState)
    .case(getRentMMedeasSuccess, (state, payload) => ({ ...state, rentMMedeas: payload }))
    //.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
    .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
    .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
    .case(get_user_details_success, (state, payload) => ({ ...state, user_details: payload }))
    .case(get_users_select, (state, payload) => ({ ...state, users_select: payload }))
    // .case(get_user_details_failure, (state, payload) => ({ ...state, user_details: payload }))
    .case(get_shops_success, (state, payload) => ({ ...state, shops: payload }))
    .case(get_shops_failure, (state, payload) => ({ ...state, shops: payload }))
    .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
    .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
    .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))

    .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))


    .case(change_window_height, (state, payload) => ({ ...state, window_height: payload }))

    .case(get_section_select, (state, payload) => ({ ...state, section_select: payload }))

    .case(get_chomeis_success, (state, payload) => ({ ...state, chomeis: payload }))
    .case(get_shougakkous_success, (state, payload) => ({ ...state, shougakkous: payload }))
    .case(get_chuugakkous_success, (state, payload) => ({ ...state, chuugakkous: payload }))

    .case(get_todoufukens_success, (state, payload) => ({ ...state, todoufukens: payload }))
    .case(get_shikuchousons_success, (state, payload) => ({ ...state, shikuchousons: payload }))
    .case(get_chouikis_success, (state, payload) => ({ ...state, chouikis: payload }))
    .case(get_kanricorps_success, (state, payload) => ({ ...state, kanricorps: payload }))

    .case(get_tm_owners_success, (state, payload) => ({ ...state, tm_owners: payload }))
    .case(get_tm_owners_select_success, (state, payload) => ({ ...state, tm_owners_select: payload }))

    .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))


    .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }))

    .case(get_rent_m_contract_types_success, (state, payload) => ({ ...state, rent_m_contract_types: payload }))


    .case(get_rent_m_contract_types_detail_success, (state, payload) => ({ ...state, rent_m_contract_types_detail: payload }))
    .case(get_tm_chinryou_koumokus_success, (state, payload) => ({ ...state, tm_chinryou_koumokus: payload }))
    .case(get_m_penalties_success, (state, payload) => ({ ...state, m_penalties: payload }))
    .case(get_m_stations_success, (state, payload) => ({ ...state, m_stations: payload }))
    .case(change_tm_chinryou_koumoku, (state, payload) => ({ ...state, tm_chinryou_koumoku: payload }))
    .case(change_tm_chinryou_koumoku_select, (state, payload) => ({ ...state, tm_chinryou_koumoku_select: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const masterSaga = [takeEvery('RENT_M_MEDEAS_REQUEST', handleRequest),
takeEvery('GET_SETSUBI_MASTER', handleRequestSetsubiMaster),
takeEvery('USERS_REQUEST', handleUsersRequest),
takeEvery('GET_USER_DETAILS', handleUserdetailsRequest),
takeEvery('GET_SHOPS', handleshopsRequest),
takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
takeEvery('GET_CHOUMEIS', handle_get_chomeis),
takeEvery('GET_SHOUGAKKOUS', handle_get_shougakkous),
takeEvery('GET_CHUUGAKKOUS', handle_get_chuugakkous),
takeEvery('GET_TODOUFUKENS', handle_get_todoufukens),
takeEvery('GET_SHIKUCHOUSONS', handle_get_shikuchousons),
takeEvery('GET_KANRICORPS', handle_get_kanricorps),
takeEvery('GET_TM_OWNERS', handle_get_tm_owners),
takeEvery('GET_RENT_M_CONTRACT_TYPES', handle_rent_m_contract_types),
takeEvery('GET_TM_CHINRYOU_KOUMOKUS', handle_get_tm_chinryou_koumokus),
takeEvery('GET_M_PENALTIES', handle_get_m_penalties),
takeEvery('GET_M_STATIONS', handle_get_m_stations),
takeEvery('GET_TM_CHINRYOU_KOUMOKU', handle_get_tm_chinryou_koumoku),





];
// export default masterSaga;


function* handleRequest(action: any): any {

    let { payload } = action;
    payload = yield call(RentMMedeasApi.rentMMedeasList, payload);

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payload)
    yield put(getRentMMedeasSuccess(payload));

    //} else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    //}
}



function* handleUsersRequest(action: any): any {

    let { payload } = action;
    payload = yield call(UsersApi.UsersLIst, payload);

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payload)

    yield put(getUsersSuccess(payload));

    // let userselect:any =[];

    const userselection = payload.map((item: any, i: number) => ({
        value: item.id,
        label: item.userNameAll
    }))

    yield put(get_users_select(userselection));


    // let userselectss  = payload.map(function (item:any, i:number) {
    //         let userselects:any =[];
    //         userselects.value=item.id;
    //         userselects.label=item.userNameAll
    //         userselect.concat(userselects)
    // })
    // console.log(persons)
    // yield put(get_users_select(userselectss));


    //} else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    //}
}




function* handleUserdetailsRequest(action: any) {
    // let { payload } = action;
    const { payload, error } = yield call(UsersApi.UsersDetails, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    if (payload && !error) {
        console.log(payload)

        yield put(get_user_details_success(payload));


    } else {

    }




    //} else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    //}
}






function* handleshopsRequest(action: any): any {

    let { payload } = action;
    payload = yield call(TmTenpoesApi.get_shops, payload);




    const shopsselection = payload.map((item: any, i: number) => ({
        value: item.tenpoNo,
        label: item.tenpoName
    }))

    yield put(get_section_select(shopsselection));
    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payload)

    yield put(get_shops_success(payload));
    //} else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    //}
}


function* handleRentAgentsRequest(action: any): any {

    let { payload } = action;
    payload = yield call(RentAgentsApi.RentAgentsList, payload);

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payload)

    yield put(getRentAgentsSuccess(payload));
    //} else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    //}
}

function* handleRentMReasonsRequest(action: any): any {

    let { payload } = action;
    payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payload)

    yield put(getRentMReasonsSuccess(payload));
    //} else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    //}
}

function* handleRequestSetsubiMaster(action: any) {

    const { payload, error } = yield call(TmSetsubiApi.get_setsubi, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_setsubi_master_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_get_chomeis(action: any) {

    const { payload, error } = yield call(TenantMAddressesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_chomeis_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_todoufukens(action: any) {

    const { payload, error } = yield call(TodoufukenMasterApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        const todoufukens = payload.map((item: any, i: number) => ({
            value: item.kenmei,
            label: item.kenmei
        }))
        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_todoufukens_success(todoufukens));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}
function* handle_get_shikuchousons(action: any) {

    const { payload, error } = yield call(ShikuchousonMasterApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        let shikuchousons = payload.map((item: any, i: number) => ({
            value: item.shikuchousonName,
            label: item.shikuchousonName
        }))
        let chouiki = payload.map((item: any, i: number) => ({
            value: item.chouikiName,
            label: item.chouikiName
        }))
        //   shikuchousons= Array.from(new Set(shikuchousons));
        //   console.log(shikuchousons)

        shikuchousons = new Map(shikuchousons.map((o: any) => [o.value, o]));
        shikuchousons = Array.from(shikuchousons.values())

        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_shikuchousons_success(shikuchousons));
        yield put(get_chouikis_success(chouiki));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}
function* handle_get_shougakkous(action: any) {

    const { payload, error } = yield call(TmShougakkousApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_shougakkous_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}
function* handle_get_chuugakkous(action: any) {

    const { payload, error } = yield call(TmChuugakkousApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_chuugakkous_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_kanricorps(action: any) {

    const { payload, error } = yield call(TmKanriCorpsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        const kanricorps = payload.map((item: any, i: number) => ({
            value: item.kanriCorpNo,
            label: item.kanriCorpName
        }))
        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_kanricorps_success(kanricorps));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_tm_owners(action: any) {

    const { payload, error } = yield call(TmOwnersApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        const owners = payload.map((item: any, i: number) => ({
            value: item.ownerId,
            label: item.ownerName + '(' + item.ownerNameKana + ')' + item.ownerId + ' ' + item.choiki
        }))
        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_tm_owners_success(payload));
        yield put(get_tm_owners_select_success(owners));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_rent_m_contract_types(action: any) {

    const { payload, error } = yield call(RentMContractTypesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        const RentMContractTypes = payload.map((item: any, i: number) => ({
            value: item.id,
            label: item.name
        }))
        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_rent_m_contract_types_success(RentMContractTypes));

        yield put(get_rent_m_contract_types_detail_success(payload));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_tm_chinryou_koumokus(action: any) {

    const { payload, error } = yield call(TmChinryouKoumokusApi.get_chinryoukoumoku, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        //    const TmChinryoukoumoKusTypes = payload.map((item:any, i:number) => ({
        //     value: item.id,
        //     label: item.name
        //   }))
        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_tm_chinryou_koumokus_success(payload));

        //    yield put(get_rent_m_contract_types_detail_success(payload));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_get_m_penalties(action: any) {

    const { payload, error } = yield call(MPenaltiesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        //    const TmChinryoukoumoKusTypes = payload.map((item:any, i:number) => ({
        //     value: item.id,
        //     label: item.name
        //   }))
        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_m_penalties_success(payload));

        //    yield put(get_rent_m_contract_types_detail_success(payload));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_get_m_stations(action: any) {

    const { payload, error } = yield call(m_stations_api.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        //    const TmChinryoukoumoKusTypes = payload.map((item:any, i:number) => ({
        //     value: item.id,
        //     label: item.name
        //   }))
        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_m_stations_success(payload));

        //    yield put(get_rent_m_contract_types_detail_success(payload));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_tm_chinryou_koumoku(action: any) {

    const { payload, error } = yield call(tm_chinryou_koumoku_api.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)


        //    const TmChinryoukoumoKusTypes = payload.map((item:any, i:number) => ({
        //     value: item.id,
        //     label: item.name
        //   }))
        //yield put(get_setsubi_master_success(setsubis));
        yield put(change_tm_chinryou_koumoku(payload));



        const koumoku = payload.map((item: any, i: number) => ({
            value: item.Chinryou_Koumoku_No,
            label: item.Chinryou_Koumoku_Name
        }))

        yield put(change_tm_chinryou_koumoku_select(koumoku));
        //    yield put(get_rent_m_contract_types_detail_success(payload));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

