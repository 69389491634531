import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ContractSheetCheck from './ContractSheetCheck';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import Button from '@mui/material/Button';
import "react-input-range/lib/css/index.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import ContractSendMailEdit from './ContractSendMailEdit';
// import './Css/Contracts.css';
var moment = require('moment-timezone');
const zoomlist=[
    {value:75, label:"75%"},
    {value:100, label:"100%"},
    {value:125, label:"125%"},
    {value:150, label:"150%"},
    {value:200, label:"200%"},
    {value:300, label:"300%"},
    {value:400, label:"400%"},
  ]
class ContractSendMail extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      scale: 125,
      confirm: false,
      sheet:0,
      check:true,
      base64:null,
      typeset:null,
      buttonState:true,
      sendModal:false,
      switch:this.props.rent_contract_details ? this.props.rent_contract_details.settlement_lock : false,
      tab:'m0'
    };
    this.change_new = this.change_new.bind(this);
    this.change_old = this.change_old.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.numPages = this.numPages.bind(this);
    this.ChangeButtonClick = this.ChangeButtonClick.bind(this);
    this.SendButtonClick = this.SendButtonClick.bind(this);
    this.CloseButtonClick = this.CloseButtonClick.bind(this);
    this.filesGet = this.filesGet.bind(this);
    this.checkState = this.checkState.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleTabChange = this.handleTabChange.bind(this);
    this.handleSave = this.handleSave.bind(this)
  }
  handleSave(e){
    this.props.change_check_contract_send_mail_confirm(this.props.check_contract_send_mail)
    this.props.change_check_contract_send_modal(false)
  }
  change_new(e) {
    this.props.change_rent_contract_logs_new(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  change_old(e) {
    this.props.change_rent_contract_logs_old(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  SendButtonClick(){
    this.setState({sendModal: false})
    this.props.resend_contract_sheet();
  }
  CloseButtonClick(){
  }
  handleButtonClick(page) {
    this.setState({ page })
  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }
  checkState(state){
    this.setState({check : state})
  }
  handleChange(state){
    this.props.change_settlement_lock(state.target.checked);
    this.setState({switch : state.target.checked})
  }
  handleResend(payment){
  }
  numPages(state) {
    this.setState({ numPages:state })
  }
  ChangeButtonClick(value){
    this.setState({ sheet: value })
    this.setState({ scale: 125 })
    this.setState({ rotate: 0 })
    this.setState({ page: 1 })
    this.filesGet(value)
  }
  filesGet(state){
    if(this.props.check_contract_sheet_file.length > 0){
        const reader = new FileReader()
        reader.readAsDataURL(this.props.check_contract_sheet_file[state].file)
        reader.onload = () => {
            this.setState({
            base64: reader.result,
            })
        }
    }
  }
  handleZoom(type){
    var index = zoomlist.findIndex(a=>a.value == this.state.scale)
    if(type == 1){
      this.setState({ scale: zoomlist[index - 1].value })
    }else if(type == 2){
      this.setState({ scale: zoomlist[index + 1].value })
    }
  }
  handleFileChange(key){
    this.setState({sheet: key})
    this.setState({rotate: 0})
    this.setState({scale: 125})
    this.setState({ page: 1 })
  }
  handleTabChange(e,newvalue){
    this.setState({tab:newvalue})
  }


  render() {
    let ver = []
    for (var i = 1; i <= this.props.rent_contract_details.ver; i++) {
      ver.push(i)
    }
    return (
      <div>
       <Modal fade={false} isOpen={this.props.check_contract_send_modal} toggle={() => this.CloseButtonClick()} size="xxl" style={{minHeight:'85%',zIndex:'2000'}} >
       <ModalHeader toggle={() => { this.props.change_check_contract_send_modal(false);}}>
       <Tabs
            id="fileupload"
            name="fileupload"
            value={this.state.tab}
            indicatorColor="primary"
            textColor="primary"
            onChange={this.handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
        >
            <Tab label="メール内容" value="m0" />
            <Tab label="添付ファイル" value="m1" />
        </Tabs>

        
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={2}>
            {
                this.props.check_contract_send_files && this.state.tab == 'm1' ? 
            <Grid xs={3} md={3} item className="item-outer-outer" >
                <Card>    
                    <CardContent>
                    {this.props.check_contract_send_files.map((value, key) => <div className="selectFiles" onClick={()=> this.handleFileChange(key)} key={'mailfile'+key}>{value.file_name.replace('mail_','')}</div>)}
                    </CardContent>
                </Card>
            </Grid>
            
            :""
            }
            <Grid xs={this.props.check_contract_send_files && this.state.tab == 'm1' ? 9 : 12} md={this.props.check_contract_send_files && this.state.tab == 'm1' ? 9 : 12} item className="item-outer-outer" style={{ paddingRight: 0 }}>
            <Card>
                <CardContent>
                {
                    this.props.check_contract_send_files && this.state.tab == 'm1' ? 
                    <div style={{ overflow: 'auto', height: window.innerHeight-280, textAlign:"center" }}>
                    <ContractSheetCheck numPages={this.numPages} scale={this.state.scale} page={this.state.page} rotate={this.state.rotate} sheet={this.state.sheet} base64={this.state.base64} check_contract_sheet_file_data={this.props.check_contract_send_files[this.state.sheet]}></ContractSheetCheck>
                    </div>:''
                }
                {
                    this.props.check_contract_send_files && this.state.tab == 'm1'? 
                <div style={{textAlign:"center", padding:10, backgroundColor:"white"}}>
                  <IconButton
                    aria-label="left"
                    disabled={this.state.page <= 1}
                    onClick={() => this.handleButtonClick(this.state.page - 1)}
                    size="large">
                    <ChevronLeftIcon />
                  </IconButton>
                  {this.state.page || 1} / {this.state.numPages || '-'}
                  <IconButton
                    aria-label="left"
                    disabled={this.state.page >= this.state.numPages || !this.state.numPages}
                    onClick={() => this.handleButtonClick(this.state.page + 1)}
                    size="large">
                    <ChevronRightIcon />
                  </IconButton>

                  <IconButton
                    aria-label="left"
                    onClick={() => this.handleRotateClick(this.state.rotate - 90)}
                    size="large">
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton
                    aria-label="left"
                    onClick={() => this.handleRotateClick(this.state.rotate + 90)}
                    size="large">
                    <RotateRightIcon />
                  </IconButton>
                  <IconButton
                    disabled={!(this.state.scale > 75)}
                    onClick={(e)=>this.handleZoom(1)}
                    style={{marginLeft:15}}
                    size="large">
                  <RemoveCircleIcon />
                  </IconButton>
                  <FormControl variant="outlined" style={{ marginTop: 5 }}>
                    <InputLabel id="demo-simple-select-outlined-label">ズーム</InputLabel>
                    <Select
                      style={{ width: 90, }}
                      value={this.state.scale}
                      onChange={(e) => this.setState({ scale: e.target.value })}
                      label="ズーム"
                      margin='dense'
                    >
                      {zoomlist.map((value, key) => <MenuItem key={key} value={value.value}>{value.label}</MenuItem>)}

                    </Select>
                  </FormControl>
                  <IconButton
                    disabled={!(this.state.scale < 400)}
                    onClick={(e)=>this.handleZoom(2)}
                    size="large">
                  <AddCircleIcon />
                  </IconButton>

                </div> : ""}
                {this.state.tab == 'm0' ? 
                <ContractSendMailEdit tab={this.state.tab}/>
                :""}
                </CardContent>
            </Card>
          </Grid>
        </Grid>
        </ModalBody>
        <ModalFooter>
          <div style={{width:'100%'}}>
            <Button variant="contained" onClick={()=>{this.setState({ sheet: 0 });this.props.change_check_contract_send_modal(false)}} color="inherit"  style={{ float: "left", margin: 5 }}>
                閉じる
            </Button>
            {this.state.tab == 'm0' ?
            <Button variant="contained" onClick={()=>this.handleSave()} color="primary" style={{ float: "right", margin: 5 }}>
                保存
            </Button>
            :""}
          </div>
        </ModalFooter>
        </Modal>
      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    customerdetail: state.customersReducer.customerdetail,
    responsedetail: state.responsesReducer.responsedetail,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_logs_new: state.contracts.rent_contract_logs_new,
    rent_contract_logs_old: state.contracts.rent_contract_logs_old,
    contracts_cancel_confirm: state.contracts.contracts_cancel_confirm,
    check_contract_sheet: state.contracts.check_contract_sheet,
    check_contract_button:state.contracts.check_contract_button,
    check_contract_sheet_text:state.contracts.check_contract_sheet_text,
    check_contract_sheet_file:state.contracts.check_contract_sheet_file,
    cloudsign_document_id:state.contracts.cloudsign_document_id,
    check_contract_send_modal:state.contracts.check_contract_send_modal,
    check_contract_send_files:state.contracts.check_contract_send_files,
    check_contract_send_mail:state.contracts.check_contract_send_mail,
    check_contract_send_mail_confirm:state.contracts.check_contract_send_mail_confirm,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    // change_rent_contract_logs_new(state) {
    //   dispatch(Contracts.change_rent_contract_logs_new(state))
    // },
    // change_rent_contract_logs_old(state) {
    //   dispatch(Contracts.change_rent_contract_logs_old(state))
    // },
    // change_rent_contract_logs_ver(state) {
    //   dispatch(Contracts.change_rent_contract_logs_ver(state))
    // },
    // contracts_send_parking(state) {
    //   dispatch(Contracts.contracts_send_parking(state))
    // },
    // download_docs(state) {
    //   dispatch(Contracts.download_docs(state))
    // },
    // download_bill(state) {
    //   dispatch(Contracts.download_bill(state))
    // },
    // download_contract(state) {
    //   dispatch(Contracts.download_contract(state))
    // },
    // change_contracts_cancel_confirm(state) {
    //   dispatch(Contracts.change_contracts_cancel_confirm(state))
    // },
    // contracts_cancel(state) {
    //   dispatch(Contracts.contracts_cancel(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
    change_check_contract_send_modal(state){
        dispatch(Contracts.change_check_contract_send_modal(state))
    },
    change_settlement_lock(state){
        dispatch(Contracts.change_settlement_lock(state))
    },
    change_check_contract_sheet_text(state){
        dispatch(Contracts.change_check_contract_sheet_text(state))
    },
    change_check_contract_sheet(state) {
        dispatch(Contracts.change_check_contract_sheet(state))
      },
    contracts_resend_parking(state){
        dispatch(Contracts.contracts_resend_parking(state))
    },
    resend_contract_sheet(state){
        dispatch(Contracts.resend_contract_sheet(state))
    },
    contracts_send_parking(state) {
        dispatch(Contracts.contracts_send_parking(state))
    },
    contracts_sheet_check(state) {
        dispatch(Contracts.contracts_sheet_check(state))
    },
    change_check_contract_send_mail_confirm(state) {
        dispatch(Contracts.change_check_contract_send_mail_confirm(state))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSendMail);
