
import Composed from './Composed';
import React, { createContext, useState, useEffect } from "react"


const authentication = require('react-azure-adb2c2').default;

export const ThemeContext = createContext();

// const token = authentication.getAccessToken();




function App() {
    const [data, setData] = useState([]);
    const [alldata, setAllData] = useState([]);
    const token = authentication.getAccessToken();

    const fetchData = async () => {
        const response = await fetch(process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/analytics`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
        const data = await response.json()

        let setdata = [
            { rent_m_way_id: 1, sum: 0 },
            { rent_m_way_id: 2, sum: 0 },
            { rent_m_way_id: 3, sum: 0 },
            { rent_m_way_id: 4, sum: 0 },
            { rent_m_way_id: 5, sum: 0 },
            { rent_m_way_id: 6, sum: 0 },
            { rent_m_way_id: 7, sum: 0 },
        ];

        data.map(function (value) {
            setdata = setdata.map(function (values) {
                if (values.rent_m_way_id == value.rent_m_way_id) {
                    values.sum = values.sum + 1
                }
                return values
            })



        })




        setData(setdata)
        setAllData(data)

    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <ThemeContext.Provider value={{ data }}>
            <div>
                <p style={{ paddingLeft: 10, paddingTop: 10, fontWeight: "bold", fontSize: "16px", marginBottom: 0 }}>  反響経路</p>
                <hr />
                {data.length ? <Composed /> : ""}

            </div>
        </ThemeContext.Provider>
    );
}

export default App;