import React, { Component } from 'react';

import { connect } from 'react-redux';

import SetsubiEdit from './SetsubiEditComponent';
import SetsubiDisp from './SetsubiDispComponent';
import Loading from '../Layout/LoadingComponent';






class SetsubiComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            setsubiedit : 1,
            mode: '1',
            //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
        };
   

    }


    render() {
       
    return (
        
        <div>
            {this.state.setsubiedit === 0 ? <SetsubiDisp roomdetail={this.props.roomdetail} setsubieditchange={this.props.setsubieditchange} /> : this.props.roomdetail.tatemonoId ? <SetsubiEdit hiddenFlg={0} closed={1} roomdetail={this.props.roomdetail} setsubi_master={this.props.setsubi_master} setsubieditchange={this.props.setsubieditchange} />:<Loading />  }
      　</div>
 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        //tatemonoedit: state.tatemonoReducer.tatemonoedit,


    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        tatemonochange() {
            //dispatch(Responses.responseeditchange())
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SetsubiComponent);
