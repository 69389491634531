import React, { Component } from 'react';
import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';

import * as Responses from './../ResponseDetail/Saga/Responses';

import { Field, reduxForm } from 'redux-form';
import '../../Css/Table.css';


//小学校リスト
//あ行
const shougakkouListA = [
    { id: 43, label: "浅野小学校" },
    { id: 84, label: "庵治小学校" },
    { id: 33, label: "一宮小学校" },
    { id: 41, label: "植田小学校" },
    { id: 31, label: "円座小学校" },
    { id: 15, label: "太田小学校" },
    { id: 16, label: "太田南小学校" },
    { id: 44, label: "大野小学校" },
    { id: 86, label: "男木小学校" },
]

//か行
const shougakkouListKa = [
    { id: 3, label: "亀阜小学校" },
    { id: 30, label: "川岡小学校" },
    { id: 37, label: "川島小学校" },
    { id: 28, label: "川添小学校" },
    { id: 50, label: "川東小学校" },
    { id: 11, label: "木太小学校" },
    { id: 10, label: "木太北部小学校" },
    { id: 12, label: "木太南小学校" },
    { id: 24, label: "鬼無小学校" },
    { id: 25, label: "香西小学校" },
    { id: 54, label: "香南小学校" },
    { id: 53, label: "国分寺南部小学校" },
    { id: 52, label: "国分寺北部小学校" },
]

//さ行
const shougakkouListSa = [
    { id: 35, label: "三渓小学校" },
    { id: 85, label: "塩江小学校" },
    { id: 22, label: "下笠居小学校" },
    { id: 60, label: "新番丁小学校" },
    { id: 38, label: "十河小学校" },
]

//た行
const shougakkouListTa = [
    { id: 61, label: "高松第一小学校" },
    { id: 49, label: "田中小学校" },
    { id: 34, label: "多肥小学校" },
    { id: 32, label: "檀紙小学校" },
    { id: 13, label: "中央小学校" },
    { id: 26, label: "鶴尾小学校" },
    { id: 23, label: "弦打小学校" },
]

//な行
const shougakkouListNa = []

//は行
const shougakkouListHa = [
    { id: 47, label: "白山小学校" },
    { id: 9, label: "花園小学校" },
    { id: 29, label: "林小学校" },
    { id: 40, label: "東植田小学校" },
    { id: 48, label: "氷上小学校" },
    { id: 45, label: "平井小学校" },
    { id: 36, label: "仏生山小学校" },
    { id: 20, label: "古高松小学校" },
    { id: 21, label: "古高松南小学校" },
]

//ま行
const shougakkouListMa = [
    { id: 27, label: "前田小学校" },
    { id: 55, label: "牟礼小学校" },
    { id: 59, label: "牟礼北小学校" },
    { id: 51, label: "牟礼南小学校" },
]

//や行
const shougakkouListYa = [
    { id: 17, label: "屋島小学校" },
    { id: 19, label: "屋島西小学校" },
    { id: 18, label: "屋島東小学校" },
]

//ら行
const shougakkouListRa = [
    { id: 14, label: "栗林小学校" },
]

//わ行
const shougakkouListWa = []


export class CheckboxGroup extends React.Component {

    checkboxGroup() {
        let { label, required, options, input, meta, request_shougakkou } = this.props;

        return options.map((option, index) => {
            //console.log(input.value)

            return (
                <div className="checkbox eq" key={option.id}>
                    <label for={"shougakkkou" + option.id}>
                        <input type="checkbox"
                            id={"shougakkkou" + option.id}

                            name={`${input.name}[${option.id}]`}
                            value={option.id}
                            checked={request_shougakkou ? (request_shougakkou.split(',').filter(a => a === String(option.id))[0] ? true : false) : false}
                            onChange={(event) => {

                                // let newValue = [...input.value];
                                // if (event.target.checked) {
                                //     newValue.push(option.id);
                                // } else {
                                //     //newValue.splice(newValue.indexOf(index+1), 1);
                                //     newValue = newValue.filter(function (item) {
                                //         return item !== option.id;
                                //     });
                                // }

                                return input.onChange(option.id);
                            }}
                        />
                        <span>{option.label}</span>
                    </label>
                </div>)
        });
    }


    render() {
        return (
            <div>
                {this.checkboxGroup()}
            </div>
        )
    }
}


class ShougakkouEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reflesh: false
            // gasu: this.props.responsedetail.gasu,
            // denki: this.props.responsedetail.denki,
            // ih: this.props.responsedetail.ih,
            // gasuka: this.props.responsedetail.gasuka,
            // denkika: this.props.responsedetail.denkika,
            // ihka: this.props.responsedetail.ihka,
            // eakon: this.props.responsedetail.eakon,
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);

        this.set_shougakkou = this.set_shougakkou.bind(this);

        // this.onChange = this.onChange.bind(this);


    }

    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }


    componentDidMount() {
        // This method is called when the component is first added to the document
        // this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });     
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        // this.ensureDataFetched();
    }

    set_shougakkou(value) {

        let rent_responses_editor = this.props.rent_responses_editor

        let request_shougakkou = rent_responses_editor.request_shougakkou ? rent_responses_editor.request_shougakkou.split(',') : []

        if (request_shougakkou.filter(a => a === String(value))[0]) {

            rent_responses_editor.request_shougakkou = request_shougakkou.filter(a => a !== String(value)).join(',')

        } else {
            request_shougakkou.push(String(value))
            console.log(request_shougakkou)

            rent_responses_editor.request_shougakkou = request_shougakkou.join(',')
            //     rent_responses_editor.request_shougakkou = request_shougakkou ? request_shougakkou.push(value).join(',') : value

        }
        this.props.change_rent_responses_editor(rent_responses_editor)

        this.setState({ reflesh: !this.state.reflesh })
    }



    render() {

        // const { handleSetsubiSubmit } = this.props;
        return (

            <div>
                {/* <form onSubmit={handleSetsubiSubmit}  >    */}

                <table>
                    <colgroup width='20%' />
                    <colgroup width='80%' />
                    <tbody>

                        <tr>
                            <th>あ行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListA} />
                                {/* <Field name="checkGroup" component={CheckboxGroup} options={shougakkouList.slice(0,5)} /> */}
                            </td>
                        </tr>
                        <tr>
                            <th>か行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListKa} />
                            </td>
                        </tr>
                        <tr>
                            <th>さ行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListSa} />
                            </td>
                        </tr>
                        <tr>
                            <th>た行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListTa} />
                            </td>
                        </tr>
                        <tr>
                            <th>な行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListNa} />
                            </td>
                        </tr>
                        <tr>
                            <th>は行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListHa} />
                            </td>
                        </tr>
                        <tr>
                            <th>ま行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListMa} />
                            </td>
                        </tr>
                        <tr>
                            <th>や行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListYa} />
                            </td>
                        </tr>
                        <tr>
                            <th>ら行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListRa} />
                            </td>
                        </tr>
                        <tr>
                            <th>わ行</th>
                            <td>
                                <Field name="checkShougakkou" request_shougakkou={this.props.rent_responses_editor.request_shougakkou} onChange={(e) => this.set_shougakkou(e)} component={CheckboxGroup} options={shougakkouListWa} />
                            </td>
                        </tr>

                    </tbody>
                </table>


                {/* </form> */}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {

        rent_responses_editor: state.responsesReducer.rent_responses_editor,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_rent_responses_editor(state) {
            dispatch(Responses.change_rent_responses_editor(state))
        },
    };
}

// ShougakkouEdit = reduxForm({
//     form: 'roomform', // a unique identifier for this form
//     // validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ShougakkouEdit)

export default connect(mapStateToProps, mapDispatchToProps)(ShougakkouEdit);
// export default ShougakkouEdit;