import React, { Component } from 'react';

import { connect } from 'react-redux';

import RoomEdit from './RoomEditComponent';
import RoomDisp from './RoomDispComponent';
import Loading from '../Layout/LoadingComponent';





class TatemonoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            roomedit : 0,
            mode: '1',
            //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
        };
   

    }


    render() {
       
    return (
        <div>
           
            {this.state.roomedit === 0 ? <RoomDisp roomdetail={this.props.roomdetail} roomeditchange={this.props.roomeditchange} /> : this.props.roomdetail.tatemonoId ? <RoomEdit roomdetail={this.props.roomdetail} closed={1} roomeditchange={this.props.roomeditchange} />:<Loading /> }

      　</div>
 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        //tatemonoedit: state.tatemonoReducer.tatemonoedit,


    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        tatemonochange() {
            //dispatch(Responses.responseeditchange())
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TatemonoComponent);
