import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as Mail from './Saga/Mail';
import * as Master from '../../Store/Master';
import "./Css/MailEditor.css";


import {
	ClassicEditor,
	AccessibilityHelp,
	AutoLink,
	Autosave,
	Bold,
	Code,
	CodeBlock,
	Essentials,
	FontBackgroundColor,
	FontColor,
	// FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HtmlComment,
	HtmlEmbed,
	Italic,
	Link,
	Paragraph,
	RemoveFormat,
	SelectAll,
	ShowBlocks,
	SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	Underline,
	Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/ja.js';

import 'ckeditor5/ckeditor5.css';
// import EnterAsBR from './EnterAsBR';  // カスタムプラグインをインポート
// import SoftBreak from '@ckeditor/ckeditor5-enter/src/softbreak';
// import Enter from '@ckeditor/ckeditor5-enter/src/enter';
// import ShiftEnter from '@ckeditor/ckeditor5-enter/src/shiftenter';


const editorConfig = {

  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'sourceEditing',
      'showBlocks',
      'selectAll',
      '|',
      'heading',
      '|',
      'fontSize',
      // 'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'subscript',
      'superscript',
      'code',
      'removeFormat',
      '|',
      'specialCharacters',
      'link',
      'insertTable',
      'highlight',
      'codeBlock',
      'htmlEmbed',
      '|',
      'accessibilityHelp'
    ],
    shouldNotGroupWhenFull: false
  },
  plugins: [
    // EnterAsBR, // カスタムプラグインを追加
    // Enter,
    // ShiftEnter,
    AccessibilityHelp,
    AutoLink,
    Autosave,
    Bold,
    Code,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    // FontFamily,
    FontSize,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HtmlComment,
    HtmlEmbed,
    Italic,
    Link,
    Paragraph,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Underline,
    Undo
  ],
  fontFamily: {
    supportAllValues: true
  },
  fontSize: {
    options: [10, 12, 14, 'default', 18, 20, 22],
    supportAllValues: true
  },
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5'
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6'
      }
    ]
  },
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true
      }
    ]
  },
  language: 'ja',
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
    decorators: {
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'file'
        }
      }
    }
  },
  // placeholder: 'Type or paste your content here!',
  // Enterを押したときに <br> を挿入
  // enterMode: 'CKEDITOR.ENTER_BR',
  // shiftEnterMode: 'CKEDITOR.ENTER_P', // Shift + Enterで<p>タグを挿入
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
  },
  translations: [translations]
};
//function CkEditorComponent(props) {

const CkEditorComponent = ({ onEditorReady, ...props }) => {

  const [modal, setModal] = useState(false);
  const editorRef = useRef(null);

  const handleEditorReady = (editor) => {
    editorRef.current = editor;
    window.editorInstance2 = editor; // グローバルに保存
  };
  // const handleEditorReady = (editor) => {
  //   onEditorReady(editor); // 親コンポーネントにエディタを渡す
  // };

  // useImperativeHandle(ref, () => ({
  //   getEditor() {
  //       return editorRef.current;
  //   }
  // }));

   
    return (
      <div>


        {/* <div className="editor-container" style={{height:props.editor_textarea_height}}> */}
        <div style={{height:props.editor_textarea_height, overflow:"auto"}}>
          <CKEditor 
            editor={ClassicEditor} 
            config={editorConfig} 

            data={props.send_mail_details.htmlUtf8}
            // onChange={handleChange}
            onReady={ editor => handleEditorReady(editor) }

            onFocus={(e)=>{props.change_draggable(2)}}

            onBlur={(event, editor) => {

              const data = editor.getData();

              let send_mail_details = props.send_mail_details
              send_mail_details.htmlUtf8 = data
              props.change_send_mail_details(send_mail_details)
              props.update_send_mail_editor_state(send_mail_details)

              props.change_draggable(1)

            }}

            // onChange={(event, editor) => {

            //   const data = editor.getData();

            //   let send_mail_details = props.send_mail_details
            //   send_mail_details.htmlUtf8 = data
            //   props.change_send_mail_details(send_mail_details)

            // }}

          />
        </div>






      {/* <CkEditor
                  //新規のメール
               
                    data={this.props.send_mail_details.htmlUtf8}
         
                    
                    onChange={ ( event, editor ) => {
               
                        const data = event.editor.getData();
                        let send_mail_details = this.props.send_mail_details;
                        send_mail_details.htmlUtf8=data
                     
                        this.props.change_send_mail_details(send_mail_details)
                       

                    } }
                    onBlur={ ( event ) => {

                        const data = event.editor.getData();
                        let send_mail_details = this.props.send_mail_details;
                        send_mail_details.htmlUtf8=data
                   
                          this.props.change_send_mail_details(send_mail_details)
            
                        this.props.update_send_mail_editor_state(send_mail_details)
                        this.setState({
                          data,
                        });
                    } }

             
                    onFocus={ 

                      this.props.toggleselectclose
  
                    }
                    
             
                    config={{
                      uploadUrl: 'localhost',
                      height:this.props.editor_textarea_height,
                      enterMode:2,//エンターを押したときにpタグじゃなbrを挿入
                      language :'ja',
                      extraPlugins:'image2,uploadimage,colorbutton,colordialog',
                      allowedContent : true,
  
                      toolbar: [[ 'Bold'],[ 'Italic'],[ 'image'],[ 'Link'],[ 'Format','Font','FontSize'],[ 'FontSize'],[ 'TextColor','BGColor' ],[ 'Table'],[ 'Undo'],[ 'Redo']]
                      
                  }}
                /> */}

      </div>
    );
  }





const mapStateToProps = (state) => {
  return {

  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_send_mail_details(state) {
      dispatch(Mail.change_send_mail_details(state))
    },
    update_send_mail_editor_state(state) {
          dispatch(Mail.update_send_mail_editor_state(state))
      } ,
    change_draggable(state) {
        dispatch(Master.change_draggable(state))
    },

  };
}


export default connect(mapStateToProps, mapDispatchToProps)(CkEditorComponent);