
const authentication = require('../../../react-azure-adb2c2').default;






export const getdetail = (values) => {


    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/users/` + values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const get_detail_by_user_id = (values) => {


    // let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/users/id/` + values;
    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/users/id/` + values.line_user_id + "/" + values.line_account_section_id;
    // let url = `http://localhost:5000/v1/line/users/id/` + values.line_user_id + "/" + values.line_account_section_id;
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const del_user = (values) => {


    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/users/del/` + values.line_user_id + "/" + values.line_account_section_id;

    // let url = `http://localhost:5000/v1/line/users/del/` + values.line_user_id + "/" + values.line_account_section_id;
    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
