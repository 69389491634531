import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as RoomList from './Saga/RoomList';
import * as CustomerContacts from '../CustomerContacts/Saga/CustomerContacts';
import * as Mail from '../Mail/Saga/Mail';
import VisitEdit from '../CustomerContacts/VisitEdit';
import Button from '@mui/material/Button';
import './Css/GridRooms.css';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';

import HomeWorkIcon from '@mui/icons-material/HomeWork';
import MailIcon from '@mui/icons-material/Mail';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LoadingComponent from '../Layout/LoadingComponent';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import moment from 'moment';
import * as IntroductionList from '../IntroductionList/Saga/IntroductionList';
import * as StockRooms from '../StockRooms/Saga/StockRooms';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

const ReactDataGrid = require('react-data-grid');


const DateFormatter = (payload) => {
    if (payload.value) {
        return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
    } else {
        return "";
    }
};

const gaikanFormatter = (payload) => {
    if (payload.row.row_type === 1) {

        const url = "https://img.global-center.co.jp/v2/tatemono/" + payload.row.tatemono_id + "/1/1/80/60/0";
        return (<img src={url} />)
    } else if (payload.row.row_type === 2) {
        const url = "https://img.global-center.co.jp/v2/rooms/" + payload.row.tatemono_id + "/" + payload.row.room_id + "/8/1/50/50/0";
        return <div><div><img src={url} /></div></div>
    }
};


const NameFormatter = (payload) => {
    if (payload.row.row_type === 1) {
        return <div>
            <div style={{ fontSize: "7px" }}>{payload.row.tatemono_kana}</div>
            <div><b>{payload.row.tatemono_name}</b></div>
            <div>{payload.row.shikuchoson}{payload.row.choiki}{payload.row.other_address}</div>
        </div>
    } else if (payload.row.row_type === 2) {
        return <div><div><b>{payload.row.room_name}　({payload.row.madori_name})</b></div>
            <div>{payload.row.kuushitsu_joukyou_name} 　{payload.row.kanri_kubun_no === 2 || payload.row.kanri_kubun_no === 3 ? payload.row.kanri_kubun_name : ""}</div>
        </div>
    }
};
const boshuFormatter = (payload) => {
    return <div> <div>{payload.row.kuushitsu_joukyou_name}</div><div></div></div>
};
const ChinRyouFormatter = (payload) => {
    if (payload.row.row_type === 1) {
        return <div> <div>竣工:{payload.row.shunkou_year}年{payload.row.shunkou_month}月</div>
            <div>管理会社:{payload.row.kanri_corp_name}</div></div>
    } else if (payload.row.row_type === 2) {
        return (
            <div>
                <div>賃料 {payload.row.chinryou ? payload.row.chinryou.toLocaleString() + "円" : "-"}</div>
                <div>共益 {payload.row.kyouekihi ? payload.row.kyouekihi.toLocaleString() + "円" : "-"}</div>
                {/* <div>水道 {payload.row.suidouryou}</div>
                <div>車① {payload.row.pryou}</div> */}
            </div>
        )
    }
};


const ChinRyou2Formatter = (payload) => {
    if (payload.row.row_type === 1) {
        return <div> </div>
    } else if (payload.row.row_type === 2) {
        return (
            <div>

                <div>水道 {payload.row.suidouryou}</div>
                <div>車① {payload.row.p_ryou}</div>
            </div>
        )
    }
};


const KeiyakuFormatter = (payload, key) => {
    // console.log(payload.row.shikikin_mon)
    if (payload.row.row_type === 1) {
        return <div>   </div>
    } else if (payload.row.row_type === 2) {
        return <div >
            <div>敷金 {payload.row.shikikin_mon ? payload.row.shikikin_mon + "ヶ月" : "-"}</div>
            <div>礼金 {payload.row.reikin_mon ? payload.row.reikin_mon + "ヶ月" : "-"}</div>
        </div>
    }

};


const RowRenderer = ({ renderBaseRow, ...props }) => {
    //console.log(props)
    //const color = props.idx % 2 ? "green" : "blue";
    // const border = props.row_type === 1 ? "border-top" : "";
    let backgroundColor = "";

    if (props.row.row_type === 1) {
        backgroundColor = "row-highlight";
    } else if (props.row.row_type === 2) {
        backgroundColor = " ";
    }
    //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
    return <span className={backgroundColor}>{renderBaseRow(props)}</span>;



};


class RoomSearchListGridComponent extends Component {
    constructor(props) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);
        this.EmptyRowsView = this.EmptyRowsView.bind(this);


        this.getCellActions = this.getCellActions.bind(this);
        this.getRowCount = this.getRowCount.bind(this);
        this.visitUpdate = this.visitUpdate.bind(this);

        this.state = {
            refresh: true,
            modalvisit: false,
            columns: [

                { key: 'col1', name: '外観/間取', width: 100, resizable: true, formatter: gaikanFormatter },
                { key: 'col2', name: '物件名/部屋', width: 250, resizable: true, formatter: NameFormatter },
                // { key: 'kuushitsu_joukyou_name', name: '募集状況', width: 80, resizable: true },


                { key: 'col3', name: '', width: 200, resizable: true, formatter: ChinRyouFormatter },
                { key: 'col4', name: '', width: 130, resizable: true, formatter: ChinRyou2Formatter },
                { key: 'col5', name: '', width: 130, resizable: true, formatter: KeiyakuFormatter },


                // { key: 'chinRyou', name: '賃料', width: 120,resizable: true, formatter: ChinRyouFormatter},
                // { key: 'chinRyouK', name: '契約時', width: 100,resizable: true, formatter: KeiyakuFormatter},
                // { key: 'selected', name: '', width: 60,resizable: true},
                // { key: 'id', name: 'ID', resizable: true },
                // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
            ],

        }


    }



    componentDidUpdate() {

        if (this.props.roomlist_update) {


            this.setState({
                refresh: true
            });
            this.getRowCount()
            this.props.change_roomlist_update(false)
        }

        // this.props.customerList()

    }
    getSelectedCell(event) {
        alert()
    }

    onRowClick = (rowIdx, row) => {

        // if (row)
        // this.props.toggle(row);




    }

    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.room_search_list_loading === true ? <LoadingComponent /> : <div style={{ textAlign: "center", padding: "100px" }} > <h3>{message}</h3></div>

        );
    };





    getCellActions(column, row) {


        if (column.key === "col2" && row.row_type !== 1) {
            if (this.props.mail) {
                return [{
                    icon: row.stocked ? <span><RemoveShoppingCartIcon style={{ color: "red" }} /><p style={{ fontSize: 10, textAlign: "center" }}>ｽﾄｯｸ</p></span> : <span><AddShoppingCartIcon /><p style={{ fontSize: 10, textAlign: "center" }}>ｽﾄｯｸ</p></span>,

                    callback: () => {
                        let intro = []
                        if (row.stocked) {
                            this.props.delete_stock_rooms(this.props.stock_rooms.filter((a) => a.tatemono_id === row.tatemono_id && a.room_id === row.room_id)[0].id)
                            let search_rooms = this.props.search_rooms
                            intro = search_rooms.map(function (value) {
                                if (value.tatemono_id === row.tatemono_id && value.room_id === row.room_id) {
                                    value.stocked = false
                                }
                                return value
                            })

                        } else {
                            let insertdata = {
                                tatemono_id: row.tatemono_id,

                                // search_rooms: this.props.search_rooms,
                                room_id: row.room_id,

                            };


                            this.props.insert_stock_rooms(insertdata)


                            let search_rooms = this.props.search_rooms

                            intro = search_rooms.map(function (value) {
                                if (value.tatemono_id === row.tatemono_id && value.room_id === row.room_id) {
                                    value.stocked = true
                                }
                                return value
                            })
                        }

                        this.props.change_search_rooms(intro);
                        this.props.search()
                        this.setState({ refresh: !this.state.refresh })

                    }
                }, {
                    icon: <span><MailIcon /><p style={{ fontSize: 10, textAlign: "center" }}>メール</p></span>,
                    callback: () => {
                        // this.props.selectTatemono(row.tatemono_id, row.room_id)
                        // this.props.selectTatemono(row.tatemonoId, row.roomId)
                        // if (window.editorInstance2) {
                        //     window.editorInstance2.model.change(writer => {
                        //         // カーソルの位置にテキストノードを作成
                        //         const textNode = writer.createText("あああああああああああああああああああああああああああああああ");
                        //         // 現在の選択箇所に挿入
                        //         window.editorInstance2.model.insertContent(textNode, window.editorInstance2.model.document.selection);
                        //     });
                        // }
                        if (window.editorInstance2) {
     
                              this.props.add_mail_room({ window: window, id: 131, tatemonoId: row.tatemono_id, roomId: row.room_id, rentResponseId: this.props.rent_response_id })

                          }

                        


                    }
                }, {
                    icon: <span><HomeWorkIcon /><p style={{ fontSize: 10, textAlign: "center" }}>詳細</p></span>,
                    callback: () => {
                        this.props.sheet(row.tatemono_id, row.room_id, row.tatemono_name, row.room_name)
                        // this.props.selectTatemono(row.tatemonoId, row.roomId)


                    }
                }



                ]
            } else if (this.props.visit) {
                return [{
                    icon: row.stocked ? <span><RemoveShoppingCartIcon style={{ color: "red" }} /><p style={{ fontSize: 10, textAlign: "center" }}>ｽﾄｯｸ</p></span> : <span><AddShoppingCartIcon /><p style={{ fontSize: 10, textAlign: "center" }}>ｽﾄｯｸ</p></span>,

                    callback: () => {
                        let intro = []
                        if (row.stocked) {
                            this.props.delete_stock_rooms(this.props.stock_rooms.filter((a) => a.tatemono_id === row.tatemono_id && a.room_id === row.room_id)[0].id)
                            let search_rooms = this.props.search_rooms
                            intro = search_rooms.map(function (value) {
                                if (value.tatemono_id === row.tatemono_id && value.room_id === row.room_id) {
                                    value.stocked = false
                                }
                                return value
                            })

                        } else {
                            let insertdata = {
                                tatemono_id: row.tatemono_id,

                                // search_rooms: this.props.search_rooms,
                                room_id: row.room_id,

                            };


                            this.props.insert_stock_rooms(insertdata)


                            let search_rooms = this.props.search_rooms

                            intro = search_rooms.map(function (value) {
                                if (value.tatemono_id === row.tatemono_id && value.room_id === row.room_id) {
                                    value.stocked = true
                                }
                                return value
                            })
                        }

                        this.props.change_search_rooms(intro);
                        this.props.search()
                        this.setState({ refresh: !this.state.refresh })

                    }
                }, {
                    icon: <span><AddCircleIcon /><p style={{ fontSize: 10, textAlign: "center" }}>案内</p></span>,
                    callback: () => {
                        // this.props.selectTatemono(row.tatemono_id, row.room_id)
                        // this.props.selectTatemono(row.tatemonoId, row.roomId)
                        this.selectTatemono(row.tatemono_id, row.room_id, row.tatemono_name, row.room_name)
                        // this.props.add_mail_room({ window: window, id: 131, tatemonoId: row.tatemono_id, roomId: row.room_id, rentResponseId: this.props.rent_response_id })


                    }
                }, {
                    icon: <span><HomeWorkIcon /><p style={{ fontSize: 10, textAlign: "center" }}>詳細</p></span>,
                    callback: () => {
                        this.props.sheet(row.tatemono_id, row.room_id, row.tatemono_name, row.room_name)
                        // this.props.selectTatemono(row.tatemonoId, row.roomId)


                    }
                }



                ]
            } else {

                return [{
                    icon: row.stocked ? <span><RemoveShoppingCartIcon style={{ color: "red" }} /><p style={{ fontSize: 10, textAlign: "center" }}>ｽﾄｯｸ</p></span> : <span><AddShoppingCartIcon /><p style={{ fontSize: 10, textAlign: "center" }}>ｽﾄｯｸ</p></span>,

                    callback: () => {
                        let intro = []
                        if (row.stocked) {
                            this.props.delete_stock_rooms(this.props.stock_rooms.filter((a) => a.tatemono_id === row.tatemono_id && a.room_id === row.room_id)[0].id)
                            let search_rooms = this.props.search_rooms
                            intro = search_rooms.map(function (value) {
                                if (value.tatemono_id === row.tatemono_id && value.room_id === row.room_id) {
                                    value.stocked = false
                                }
                                return value
                            })

                        } else {
                            let insertdata = {
                                tatemono_id: row.tatemono_id,

                                // search_rooms: this.props.search_rooms,
                                room_id: row.room_id,

                            };


                            this.props.insert_stock_rooms(insertdata)


                            let search_rooms = this.props.search_rooms

                            intro = search_rooms.map(function (value) {
                                if (value.tatemono_id === row.tatemono_id && value.room_id === row.room_id) {
                                    value.stocked = true
                                }
                                return value
                            })
                        }

                        this.props.change_search_rooms(intro);
                        this.props.search()
                        this.setState({ refresh: !this.state.refresh })

                    }
                }, {
                    icon: row.selected ? <span><RemoveCircleIcon style={{ color: "red" }} /><p style={{ fontSize: 10, textAlign: "center" }}>紹介</p></span> : <span><AddCircleOutlineIcon /><p style={{ fontSize: 10, textAlign: "center" }}>紹介</p></span>,
                    callback: () => {
                        // this.props.selectTatemono(row.tatemono_id, row.room_id)
                        // this.props.selectTatemono(row.tatemonoId, row.roomId)
                        let intro = []

                        if (row.selected) {

                            this.props.delete_introduction({ rent_response_id: this.props.rent_response_id, rentIntroductionId: this.props.introductions.filter((a) => a.tatemono_id === row.tatemono_id && a.room_id === row.room_id)[0].id })
                            let search_rooms = this.props.search_rooms

                            intro = search_rooms.map(function (value) {
                                if (value.tatemono_id === row.tatemono_id && value.room_id === row.room_id) {
                                    value.selected = false
                                }
                                return value
                            })

                        } else {
                            let insertdata = {
                                rentResponseId: this.props.rent_response_id,
                                tatemonoId: row.tatemono_id,
                                status: 2,
                                // search_rooms: this.props.search_rooms,
                                roomId: row.room_id,

                            };


                            this.props.insert_introductions(insertdata)


                            let search_rooms = this.props.search_rooms

                            intro = search_rooms.map(function (value) {
                                if (value.tatemono_id === row.tatemono_id && value.room_id === row.room_id) {
                                    value.selected = true
                                }

                                return value
                            })
                        }

                        this.props.change_search_rooms(intro);
                        this.props.search()
                        this.setState({ refresh: !this.state.refresh })
                    }
                }
                    , {
                    icon: <span><HomeWorkIcon /><p style={{ fontSize: 10, textAlign: "center" }}>詳細</p></span>,
                    callback: () => {
                        this.props.sheet(row.tatemono_id, row.room_id, row.tatemono_name, row.room_name)
                        // this.props.selectTatemono(row.tatemonoId, row.roomId)


                    }
                }

                ]
            }

        } else if (column.key === "col3" && row.row_type !== 1) {
            return [{
                icon: <span><ListAltIcon /><p style={{ fontSize: 10, textAlign: "center" }}>見積</p></span>,
                callback: () => {
                    this.props.estimate(row.tatemono_id, row.room_id)
                }

                //     props.delete_introduction(value.selected, value.tatemonoId, value.roomId)
                // }> 取消</button >
                //     { value.roomId != "　" ? (props.mail === true ? <button className="btn btn-sm btn-success" style={{ width: "70px", fontSize: 9, padding: 0 }} onClick={() => props.selectTatemono(payload.row.tatemonoId, value.roomId)}>メールに追加</button> : (value.selected === null ? <button className="btn btn-sm btn-success" style={{ width: "50px" }} onClick={() => props.selectTatemono(payload.row.tatemonoId, value.roomId)}>追加</button> : <button className="btn btn-sm btn-danger" style={{ width: "50px" }} onClick={() => props.delete_introduction(value.selected, value.tatemonoId, value.roomId)}>取消</button>)) : "　" }

            }
            ]

        }

    }
    getRowCount() {

        let count = this.props.search_rooms_list.length;
        if (this.state.refresh && count > 0) {

            count--; // hack for update data-grid
            this.setState({
                refresh: false
            });
        }

        return count;
    }

    selectTatemono(tatemonoId, roomId, tatemonoName, roomName) {

          // 案内物件に追加

          let rent_customer_contacts_forms = this.props.fromMapOpen === 3 ? this.props.rent_customer_contacts_calendar_forms : this.props.rent_customer_contacts_forms
    
          let visit_editor_forms = this.props.visit_editor_forms
    
          this.props.get_customer_contacts_tm_room_list(parseInt(tatemonoId))
    
          visit_editor_forms.date = rent_customer_contacts_forms.date
          visit_editor_forms.id = 0
          visit_editor_forms.remarks = ""
          visit_editor_forms.rent_customer_contact_id = rent_customer_contacts_forms.id
          visit_editor_forms.rent_response_id = rent_customer_contacts_forms.rent_response_id
          visit_editor_forms.rent_visit_id = 0
          visit_editor_forms.room_id = parseInt(roomId)
          visit_editor_forms.room_name = roomName
          visit_editor_forms.tatemono_id = parseInt(tatemonoId)
          visit_editor_forms.tatemono_name = tatemonoName
          visit_editor_forms.user_id = rent_customer_contacts_forms.user_id
          visit_editor_forms.no = 0
    
          this.props.change_visit_editor_forms(visit_editor_forms)
    
          this.setState({ modalvisit: true })

      }

    visitUpdate(){
        // alert(this.props.fromMapOpen)
        if(this.props.fromMapOpen == 3){
            this.props.update_rent_visits_edit()
        }else{
            this.props.update_rent_visits()
        }

        let visit_editor_forms = this.props.visit_editor_forms

        let insertdata = {
            rentResponseId: this.props.fromMapOpen == 3 ? this.props.rent_customer_contacts_calendar_forms.rent_response_id : this.props.rent_customer_contacts_forms.rent_response_id,
            tatemonoId: visit_editor_forms.tatemono_id,
            status: 2,
            roomId: visit_editor_forms.room_id,

        };


        this.props.insert_introductions(insertdata)

        this.setState({ "modalvisit": false })

    }

    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];[]
        console.log("dddddddddddddddddd")
        return (
            <div >

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    // enableCellSelect={false}
                    columns={this.state.columns}
                    rowGetter={(i) => this.props.search_rooms_list[i]}
                    rowHeight={60}
                    headerRowHeight={35}
                    rowsCount={this.getRowCount()}
                    getCellActions={this.getCellActions}
                    // headerRenderer={true}
                    rowRenderer={RowRenderer}
                    // dragable={true}
                    minHeight={this.props.height}
                    minWidth={this.props.width}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    emptyRowsView={this.EmptyRowsView}
                // onRowClick={this.props.selectsheet}
                //onCellSelected={this.getSelectedCell}
                />


        <Dialog open={this.state.modalvisit} onClose={() => this.setState({ "modalvisit": false })} >
            <DialogTitle>案内物件</DialogTitle>
            <DialogContent>
            <VisitEdit />
            </DialogContent>
            <DialogActions>
            <Button variant="contained" style={{ margin: 5 }} color="primary" onClick={()=>this.visitUpdate()}>保存</Button>
            {/* {visit_editor_forms.rent_visit_id ? <Button style={{ margin: 5 }} variant="contained" color="secondary" onClick={delete_rent_visits}>削除</Button> : ""} */}
            <Button variant="outlined" style={{ margin: 5 }} onClick={() => this.setState({ "modalvisit": false })}>閉じる</Button>
            </DialogActions>
        </Dialog>

            </div>
        );
    }


}



const mapStateToProps = (state) => {
    return {
        // rooms: state.roomsReducer.rooms,
        // introductions:state.responsesReducer.introductions,
        // responseid: state.customersReducer.responseid,
        search_rooms_list: state.roomlist.search_rooms_list,
        room_search_list_loading: state.roomlist.room_search_list_loading,
        search_rooms: state.roomlist.search_rooms,
        rent_response_id: state.responsesReducer.responsedetail.id,


        introductions: state.introductionlist.introductions,
        stock_rooms: state.StockRooms.stock_rooms,
        roomlist_update: state.roomlist.roomlist_update,

        fromMapOpen: state.mapsearch.fromMapOpen,
        rent_customer_contacts_calendar_forms: state.customerContactsReducer.rent_customer_contacts_calendar_forms,
        rent_customer_contacts_forms: state.customerContactsReducer.rent_customer_contacts_forms,
        visit_editor_forms: state.customerContactsReducer.visit_editor_forms
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_search_rooms(state) {
            dispatch(RoomList.get_search_rooms(state))
        },
        // add_mail_room(state) {
        //     dispatch(Mail.add_mail_room(state))
        // },
        change_search_rooms(state) {
            dispatch(RoomList.change_search_rooms(state))
        },
        delete_introduction(state) {
            dispatch(IntroductionList.delete_introduction(state))
        },
        insert_introductions(state) {
            dispatch(IntroductionList.insert_introductions(state))
        }, add_mail_room(state) {
            dispatch(Mail.add_mail_room(state))
        },
        search(state) {
            dispatch(RoomList.search(state))
        },
        insert_stock_rooms(state) {
            dispatch(StockRooms.insert_stock_rooms(state))
        },
        delete_stock_rooms(state) {
            dispatch(StockRooms.delete_stock_rooms(state))
        },
        change_roomlist_update(state) {
            dispatch(RoomList.change_roomlist_update(state))
        },
        get_customer_contacts_tm_room_list(state) {
            dispatch(CustomerContacts.get_customer_contacts_tm_room_list(state))
        },
        change_visit_editor_forms(state) {
            dispatch(CustomerContacts.change_visit_editor_forms(state))
        },
        update_rent_visits_edit(state) {
            dispatch(CustomerContacts.update_rent_visits_edit(state))
        },
        update_rent_visits(state) {
            dispatch(CustomerContacts.update_rent_visits(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RoomSearchListGridComponent);