import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import * as Mail from '../../Mail/Saga/Mail';
import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';

import * as RentResponseEstimateListApi from '../Api/RentResponseEstimateListApi';
import * as RentResponseEstimateTextApi from '../Api/RentResponseEstimateTextApi';
import * as EstimateDownloadApi from '../Api/EstimateDownloadApi';
import * as EstimateToMailApi from '../Api/EstimateToMailApi';

// import * as BanksApi from '../Api/BanksApi';
// import * as UsersApi from './Api/UsersApi';
// import * as RentAgentsApi from './Api/RentAgentsApi';

///types


//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface AutoSendState {
    estimate_list: any;
    estimate_list_loading: boolean;
    // auto_send_reserve_list:any;
    estimate_list_modal: boolean;
    estimate_put_loading: boolean;
    estimate_search: any;
    // auto_sends_templates:any;
}
const initialState: AutoSendState = {

    estimate_list: [],
    estimate_list_loading: false,
    // auto_send_reserve_list:[],
    estimate_list_modal: false,
    estimate_put_loading: false,
    estimate_search: { responseOnly: true },
    // auto_sends_templates:[],
    // users: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();

export const get_estimate_list = actionCreator<any>('GET_ESTIMATE_LIST');
export const get_estimate_list_success = actionCreator<any>("GET_ESTIMATE_LIST_SUCCESS");
// export const get_auto_sends_failure = actionCreator("GET_AUTO_SENDS_FAILURE");

export const change_estimate_list_loading = actionCreator<any>("CHANGE_ESTIMATE_LIST_LOADING");


export const insert_estimate_to_mail_text = actionCreator<any>("INSERT_ESTIMATE_TO_MAIL_TEXT");


export const download_estimate_pdf = actionCreator<any>("DOWNLOAD_ESTIMATE_PDF");

export const add_estimate_to_mail = actionCreator<any>("ADD_ESTIMATE_TO_MAIL");

export const estimate_put_loading = actionCreator<any>("ESTIMATE_PUT_LOADING");

export const change_estimate_search = actionCreator<any>("CHANGE_ESTIMATE_SEARCH");



export const estimateListReducer = reducerWithInitialState(initialState)
    .case(get_estimate_list_success, (state, payload) => ({ ...state, estimate_list: payload }))
    .case(change_estimate_list_loading, (state, payload) => ({ ...state, estimate_list_loading: payload }))

    .case(estimate_put_loading, (state, payload) => ({ ...state, estimate_put_loading: payload }))

    .case(change_estimate_search, (state, payload) => ({ ...state, estimate_search: payload }))



//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const estimateListSaga = [takeEvery('GET_ESTIMATE_LIST', handle_get_estimate_list),
// takeEvery('DELETE_AUTO_SEND', handle_delete_auto_send),
takeEvery('INSERT_ESTIMATE_TO_MAIL_TEXT', handle_insert_estimate_to_mail_text),

takeEvery('DOWNLOAD_ESTIMATE_PDF', handle_download_estimate_pdf),

takeEvery('ADD_ESTIMATE_TO_MAIL', handle_add_estimate_to_mail),


    // takeEvery('GET_RENT_CONTRACT_ACCOUNTING', handle_get_rent_contract_accounting),
    // takeEvery('GET_BANKS', handle_get_banks),
    // takeEvery('USERS_REQUEST', handleUsersRequest),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_estimate_list(action: any) {
    yield put(get_estimate_list_success({}));
    yield put(change_estimate_list_loading(true));


    // var year = action.payload.contractDateStart.getFullYear();
    // var month = action.payload.contractDateStart.getMonth() + 1;
    // var day = action.payload.contractDateStart.getDate();

    // action.payload.contractDateStart= year + '/' + month + '/' + day 
    // alert(action.payload.contractDateStart)
    const { payload, error } = yield call(RentResponseEstimateListApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(get_estimate_list_success(payload));
        yield put(change_estimate_list_loading(false));
    } else {

        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_insert_estimate_to_mail_text(action: any): any {

    const state = yield select();
    let send_mail_details = {...state.mailReducer.send_mail_details}

    yield put(estimate_put_loading(true));



    // yield put(get_estimate_list_success({}));
    // yield put(change_estimate_list_loading(true));
    const { payload, error } = yield call(RentResponseEstimateTextApi.detail, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // // let editor =action.payload.window.CKEDITOR.instances.editor1;
        // let editor = action.payload.window.CKEDITOR.instances[Object.keys(action.payload.window.CKEDITOR.instances)[0]];
        
        // //  state.mailReducer.send_mail_details.subject=payload.subject;
        // // alert(state.mailReducer.send_mail_details.subject)
        // //   yield put(Mail.change_send_mail_details(state.mailReducer.send_mail_details))
        
        // editor.insertHtml(payload.text);
        // // state.form.sendmailForm.subject=pa

        const editorInstance = window.editorInstance2;

        // editorInstance と model、document の null チェック
        if (editorInstance && editorInstance.model && editorInstance.model.document) {
            editorInstance.model.change(writer => {

                // HTMLを含む要素を挿入する
                const viewFragment = editorInstance.data.processor.toView('<div>' + payload.text + '</div>');
                const modelFragment = editorInstance.data.toModel(viewFragment);
                editorInstance.model.insertContent(modelFragment, editorInstance.model.document.selection);

            });

            // エディタの全内容を取得
            const editorContent = editorInstance.getData();
            console.log('エディタの全内容:', editorContent);

            send_mail_details.htmlUtf8 = editorContent


        } else {
            console.error('Editor instance or model or document is not available');
        }

        
        yield put(Mail.change_send_mail_details(send_mail_details))


        yield put(estimate_put_loading(false));
    } else {
        alert("error")
        yield put(estimate_put_loading(false));
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

// function* handle_delete_auto_send(action:any) {
//     yield put(get_auto_sends_success({}));
//     yield put(change_auto_sends_loading(true));
//  const { payload, error }= yield call(RentIntroductionsApi.insert, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         // 成功時アクション呼び出し
//         yield put(get_auto_sends(action.payload.rentResponseId));
//     } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     }
// }














function* handle_download_estimate_pdf(action: any) {
    yield put(estimate_put_loading(true));
    const { payload, error } = yield call(EstimateDownloadApi.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.buildingName + action.payload.roomName + "見積.pdf";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();
        yield put(estimate_put_loading(false));
        //    yield put(get_rent_contract_monies_success(payload));

    } else {
        alert("error")
        yield put(estimate_put_loading(false));
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



function* handle_add_estimate_to_mail(action: any) {
    //   console.log(action.payload)
    yield put(estimate_put_loading(true));
    const { payload, error } = yield call(EstimateToMailApi.addpdffile, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        //    alert(action.payload.mailid)
        yield put(Mail.get_mail_detail_files(action.payload.mailid))
        yield put(estimate_put_loading(false));
        // yield put(get_banks_success(payload));
        //    yield put(get_rent_contract_monies_success(payload));

    } else {
        alert("error")
        yield put(estimate_put_loading(false));
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}





// function* handleUsersRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(UsersApi.UsersLIst, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getUsersSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentAgentsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentAgentsApi.RentAgentsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentAgentsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentMReasonsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentMReasonsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }