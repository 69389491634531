import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
// import * as Responses from '../../Store/Responses';
import '../../Css/Grid.css';
import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment';
const ReactDataGrid = require('react-data-grid');

// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {

    rent_mypage_logs: any,

    rent_mypage_logs_loading: number
}


interface State {

    // state types
}

const DateFormatter = (payload: any) => {
    if (payload.value) {
        return moment(payload.value).format("YYYY/MM/DD HH:mm:ss");
        //   return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
    } else {
        return "";
    }
};
// "TEL".




const columns = [

    {
        key: 'displayAt', name: '閲覧日時', width: 200, resizable: true, formatter: DateFormatter
    },
    { key: 'page', name: 'アクセスページ', width: 200, resizable: true },


    // { key: 'id', name: 'ID', resizable: true },
    // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
];



const RowRenderer = ({ renderBaseRow, ...props }: any) => {
    console.log(props.row.intend)

    const backgroundColor = (props.row.intend === 1 || props.row.intend === true) ? "row-highlight-green" : "";
    return <span className={backgroundColor}>{renderBaseRow(props)}</span>;
};



// function getCellActions(column, row) {
//     //const cellActions = {
//     //    firstName: firstNameActions
//     //};
//     console.log(column)
//     //return row.id % 2 === 0 ? cellActions[column.key] : null;
// }

class MypageLogGridComponent extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        // this.onRowClick = this.onRowClick.bind(this);


    }
    componentWillMount() {

        // this.props.get_rent_customer_contacts(this.props.rent_response_id)
        // this.props.customerList()

    }
    getSelectedCell(event: any) {
        alert()
    }

    // onRowClick = (rowIdx:any, row:any) => {

    //     // if (this.props.customerno === 0) {
    //     //     this.props.change_customerloding0(0)
    //     //     let customerdetail: { [key: string]: number; } = {};
    //     //     customerdetail.customerid=row.id;
    //     //     customerdetail.no=0;
    //     //     this.props.customerdetailData(customerdetail)
    //     //     //this.props.responseUpdate(this.props.responsedetail);
    //     // }else if (this.props.customerno === 1) {
    //     //     this.props.change_customerloding1(0)
    //     //     this.props.responsedetail.rentCustomerId = row.id;
    //     //     this.props.responseUpdate(this.props.responsedetail);

    //     // } else if (this.props.customerno === 2) {
    //     //     this.props.change_customerloding2(0)
    //     //     this.props.responsedetail.rentCustomerId2 = row.id;
    //     //     this.props.responseUpdate(this.props.responsedetail);

    //     // }
    //     if(row)
    //     this.props.toggle(row);




    // }

    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.rent_mypage_logs_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", height: 250, padding: "100px" }} > <h3>{message}</h3></div>

        );
    };

    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div>

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    enableCellSelect={false}
                    columns={columns}
                    rowGetter={(i: number) => this.props.rent_mypage_logs[i]}
                    rowRenderer={RowRenderer}
                    rowsCount={this.props.rent_mypage_logs.length}
                    headerRenderer={true}
                    dragable={true}
                    // getCellActions={getCellActions}

                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    emptyRowsView={this.EmptyRowsView}
                    // onRowClick={this.onRowClick}
                    //onCellSelected={this.getSelectedCell}
                    minHeight={300} />
            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {

        // contacts_loading:state.customerContactsReducer.contacts_loading
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        // get_rent_customer_contacts(state:any) {
        //     dispatch(Contacts.get_rent_customer_contacts(state))
        // },


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MypageLogGridComponent);