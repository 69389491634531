
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';



import { call, put, takeEvery } from 'redux-saga/effects';
import * as RentMypageLogsApi from '../Api/RentMypageLogsApi';
import moment from 'moment'


export interface ContactsState {
    rent_mypage_logs: any;

    rent_mypage_logs_loading: number;
    rent_mypage_logs_graph: any;
}

const initialState: ContactsState = {

    rent_mypage_logs: [],
    rent_mypage_logs_loading: 0,
    rent_mypage_logs_graph: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();

export const get_rent_mypage_logs = actionCreator('GET_RENT_RENT_MYPAGE_LOGS');
export const get_rent_mypage_logs_success = actionCreator("GET_RENT_RENT_MYPAGE_LOGS_SUCCESS");
export const get_rent_mypage_logs_failure = actionCreator("GET_RENT_RENT_MYPAGE_LOGS_FAILURE");
export const change_rent_mypage_logs_loading = actionCreator<number>("CHANGE_RENT_RENT_MYPAGE_LOGS_LOADING");

export const get_rent_mypage_logs_graph = actionCreator<number>("GET_RENT_MYPAGE_LOGS_GRAPH");
export const change_rent_mypage_logs_graph = actionCreator<any>("CHANGE_RENT_MYPAGE_LOGS_GRAPH");

export const mypageLogReducer = reducerWithInitialState(initialState)


    .case(get_rent_mypage_logs_success, (state, payload) => ({ ...state, rent_mypage_logs: payload }))
    .case(change_rent_mypage_logs_loading, (state, payload) => ({ ...state, rent_mypage_logs_loading: payload }))
    .case(change_rent_mypage_logs_graph, (state, payload) => ({ ...state, rent_mypage_logs_graph: payload }))







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const mypageLogSaga = [takeEvery('GET_RENT_RENT_MYPAGE_LOGS', handle_get_rent_mypage_logs),
    takeEvery('GET_RENT_MYPAGE_LOGS_GRAPH', handle_get_rent_mypage_logs_graph),
    // takeEvery('UPDATE_RENT_CUSTOMER_CONTACTS', handle_update_rent_customer_contacts_Request),
    // takeEvery('DELETE_RENT_CUSTOMER_CONTACTS', handle_delete_rent_customer_contacts_Request),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_rent_mypage_logs(action: any) {

    yield put(change_rent_mypage_logs_loading(1));
    const { payload, error } = yield call(RentMypageLogsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(get_rent_mypage_logs_success(payload));

        yield put(get_rent_mypage_logs_graph(payload));
        yield put(change_rent_mypage_logs_loading(0));
        //   yield put(get_rent_customer_contacts_calendar_success(calendar));


    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_rent_mypage_logs_graph(action: any) {

    let data = action.payload

    let list = [
        {time: 0, sum: 0},
        {time: 1, sum: 0},
        {time: 2, sum: 0},
        {time: 3, sum: 0},
        {time: 4, sum: 0},
        {time: 5, sum: 0},
        {time: 6, sum: 0},
        {time: 7, sum: 0},
        {time: 8, sum: 0},
        {time: 9, sum: 0},
        {time: 10, sum: 0},
        {time: 11, sum: 0},
        {time: 12, sum: 0},
        {time: 13, sum: 0},
        {time: 14, sum: 0},
        {time: 15, sum: 0},
        {time: 16, sum: 0},
        {time: 17, sum: 0},
        {time: 18, sum: 0},
        {time: 19, sum: 0},
        {time: 20, sum: 0},
        {time: 21, sum: 0},
        {time: 22, sum: 0},
        {time: 23, sum: 0},
    ]

    for(let i = 0; i < data.length; i++){
        list[parseInt(moment(data[i].displayAt).format("H"))].sum++
    }

    yield put(change_rent_mypage_logs_graph(list));

}

// function* handle_add_rent_customer_contacts_Request(action: any) {


// const { payload, error } = yield call(RentCustomerContactsApi.insert, action.payload);


//     if (payload && !error) {

//         yield put(get_rent_customer_contacts_success(payload));


//         const calendar = payload.map((item:any, i:number) => ({
//             id: item.id,
//             intend: item.intend,
//             title: (item.way==1?"電話":item.way==2?"メール":"来店")+(item.intend===1?"予定":""),
//             allDay: true,
//             start: item.date,
//             end: item.date,
//             contents:item
//           }))
//           yield put(get_rent_customer_contacts_calendar_success(calendar));
//         // 成功時アクション呼び出し
//     // console.log(payload)
//     // yield put(add_rent_customer_contacts_success(payload));
//     // get_rent_customer_contacts(1);
//     } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     }
// }


// function* handle_update_rent_customer_contacts_Request(action: any) {

//     const { payload, error } = yield call(RentCustomerContactsApi.update, action.payload);


//         if (payload && !error) {
//             console.log(payload)
//             yield put(get_rent_customer_contacts_success(payload));

//             const calendar = payload.map((item:any, i:number) => ({
//                 id: item.id,
//                 intend: item.intend,
//                 title: (item.way==1?"電話":item.way==2?"メール":"来店")+(item.intend===1?"予定":""),
//                 allDay: true,
//                 start: item.date,
//                 end: item.date,
//                 contents:item
//               }))
//               yield put(get_rent_customer_contacts_calendar_success(calendar));
//             // 成功時アクション呼び出し

//         // get_rent_customer_contacts(1);
//         } else {
//         //    // 失敗時アクション呼び出し

//         }
//     }


//     function* handle_delete_rent_customer_contacts_Request(action: any) {

//         const { payload, error } = yield call(RentCustomerContactsApi.contact_delete, action.payload);


//             if (payload && !error) {
//                 console.log(payload)
//                 yield put(get_rent_customer_contacts_success(payload));
//                 const calendar = payload.map((item:any, i:number) => ({
//                     id: item.id,
//                     intend: item.intend,
//                     title: (item.way==1?"電話":item.way==2?"メール":"来店")+(item.intend===1?"予定":""),
//                     allDay: true,
//                     start: item.date,
//                     end: item.date,
//                     contents:item
//                   }))
//                   yield put(get_rent_customer_contacts_calendar_success(calendar));

//             } else {
//             //    // 失敗時アクション呼び出し

//             }
//         }

// function* handleUsersRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(UsersApi.UsersLIst, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getUsersSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentAgentsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentAgentsApi.RentAgentsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentAgentsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentMReasonsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentMReasonsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }