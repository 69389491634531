import React, { Component } from 'react';

import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';

import "react-datepicker/dist/react-datepicker.css";


import * as Estimates from './Saga/Estimates';

import TextField from '@mui/material/TextField';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import InputAdornment from '@mui/material/InputAdornment';

import { ValidatorForm } from 'react-material-ui-form-validator';

import withStyles from '@mui/styles/withStyles';
import 'bootstrap/dist/css/bootstrap.css';
import './Css/Form.css';
import moment from 'moment';



import ja from "date-fns/locale/ja";



const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',

    },
    '& label': {
      color: 'green',

    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
      color: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'green',
        color: 'green',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
        color: 'green',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'green',
        color: 'green',
      },
    },
  },
})(TextField);



const CssTextField2 = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'pink',

    },
    '& label': {
      color: 'pink',

    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'pink',
      color: 'pink',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'pink',
        color: 'pink',
      },
      '&:hover fieldset': {
        borderColor: 'yellow',
        color: 'pink',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'pink',
        color: 'pink',
      },
    },
  },
})(TextField);



class CustomerInfoEdit extends Component {

  constructor(props) {
    super(props);


    // if (props.customerdetail.name1) {
    //  props.initialize(props.customerdetail);//フォームに初期値を反映させる
    // } else {


    //     var customerdetail = {
    //         id:0,
    //         name1: "",
    //         kana1: "",
    //         name2: "",
    //         kana2: "",
    //         type: "0",
    //         sex: "1",
    //     }
    //     props.initialize(customerdetail);//フォームに初期値を反映させる
    // }
    this.state = {
      formData: {
        email: '',
        password: '',
        tatemonoName: '',
        applicationDate: null,
        contractionDate: null,
        applicationFeePayer: false,
      },
      submitted: false,
    }


    // this.onChangeShowHide = this.onChangeShowHide.bind(this);
    // this.onChangeSex= this.onChangeSex.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    // this.handleApplicationDateChange = this.handleApplicationDateChange.bind(this);
    // this.handleContractionDateChange = this.handleContractionDateChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCheckBoxChange = this.handleCheckBoxChange.bind(this);


    //    this.elements = null;
    //this.props.initialize(this.props.forecasts);

  }


  handleCheckBoxChange(event) {
    // this.setState({[event.target.name]: event.target.checked });

    // alert(event.target.checked )
    let estimateData = this.props.estimateData

    estimateData.applicationFeePayor = event.target.checked ? 1 : 0
    // const room= this.props.estimate_rooms.filter(a=>a.roomId===event.target.value)

    this.props.change_estimateData(estimateData)
    this.setState({ change: true })
  };
  // handleCheckBoxChange = name => event => {
  //   // this.setState({ ...this.state.formData, [name]: event.target.checked });
  // };
  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
    });
  }

  componentWillMount() {

    console.log(this.props)
    if (this.props.customerdetail) {
      this.props.customerdetail.customerno = this.props.customerno;


    }

  }

  componentDidMount() {


  }



  componentDidUpdate() {

    if (this.props.estimate_sum_flg === true) {

      this.props.change_estimate_sum_flg(false)
      //  this.refresh() 
      this.setState({ change: true })
    }
    // This method is called when the route parameters change
    //this.ensureDataFetched();

  }

  handleChange = (event) => {
    // alert(event.target.name)
    console.log(event)
    // alert(event.target.name)
    let estimateData = this.props.estimateData
    // if(event.target.name==="trader"){ alert(event.target.value)
    //   estimateData[event.target.name] = Number(event.target.value)
    // }else{
    estimateData[event.target.name] = event.target.value
    // }
    this.props.change_estimateData(estimateData)
    this.setState({ change: true })
    this.props.check_estimates()

  }


  // handleApplicationDateChange = date => {
  //     // this.setState({formData:{applicationDate:date,
  //     //     contractionDate:this.state.formData.contractionDate}});
  //         let formData =   this.state.formData
  //         formData.applicationDate=date
  //           this.setState(formData);
  //   };

  // handleContractionDateChange = date => {
  // let formData =   this.state.formData
  // formData.contractionDate=date
  //   this.setState(formData);

  // };


  handleCustomerChange(event) {
    event.persist();
    console.log(event.target.value)
    // const change = this.state.customerdetail;
    // change[event.target.name] = event.target.value
    // this.setState({customerdetail: change})
    let change = this.props.customerdetail;
    change[event.target.name] = event.target.value;

    console.log(this.state.customerdetail)

  }
  handleSelectCustomer(state) {
    this.setState({ customerdetail: state })


    this.props.change_customerloding(1)
    this.props.change_customeredit(0)
  }

  handleCustomerreset() {

    this.setState({
      customerdetail: {
        id: 0,
        type: "0",
        sex: "1"
      }
    })
    this.props.change_customeredit(1)
  }












  render() {

    // const { customersubmit,handleSubmit } = this.props;

    // const year = [""];
    // const day = [""];

    // var hiduke = new Date(); 
    // for (var i = 1926; i <= hiduke.getFullYear(); i++) {
    //     year.push(i);
    // }
    // for (var i2= 1; i2 <= 31; i2++) {
    //     day.push(i2);
    // }

    const { formData, submitted } = this.state;
    console.log(formData)
    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
        onError={errors => console.log(errors)}
      >
        <Grid
          justifyContent="space-between" // Add it here :)
          container

        >
          <Grid item xs={12}>
            <TextField
              style={{ float: "left" }}
              id="applicationFee"
              label="申込金"
              type="number"
              // className={classes.textField}
              InputProps={{
                endAdornment: <InputAdornment position="end">円</InputAdornment>,
              }}
              name="applicationFee"
              value={this.props.estimateData.applicationFee}
              InputLabelProps={{
                shrink: true,
              }}
              margin="normal"
              onChange={this.handleChange}
              variant='standard'
            />

            {this.props.estimateData.contractFlg === 1 ? <TextField
              id="applicationDate"
              label="申込金受取日"
              type="date"
              name="applicationDate"
              size="small"
              onChange={this.handleChange}
              value={moment(this.props.estimateData.applicationDate).format("YYYY-MM-DD")}
              InputLabelProps={{
                  shrink: true,
              }}
              style={{ float: "left", marginLeft:20, marginTop:19 }}
              variant="standard"
            />:""}

            {/* {this.props.estimateData.contractFlg === 1 ? <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


              <KeyboardDatePicker

                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-inline2"
                label="申込金受取日"

                error={true}
                helperText="申込金受取日を選択してください"
                //   showTodayButton={true}
                name="applicationDate"
                value={formData.applicationDate}
                onChange={this.handleApplicationDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                invalidDateMessage={"日付の形式が違います"}
              />


            </MuiPickersUtilsProvider> : ""} */}


            {String(this.props.estimateData.type) === "1" ? <FormControlLabel


              control={
                <Checkbox
                  style={{ float: "left" }}
                  name="applicationFeePayor"
                  checked={this.props.estimateData.applicationFeePayor === 1 ? true : false}
                  onChange={this.handleCheckBoxChange}
                  value="1"
                  color="primary"
                />
              }
              label="入居者負担"
            /> : ""}
          </Grid>
          <Grid>
            <CssTextField
              id="contractSum"
              label="契約者合計"
              value={String(this.props.estimateMonies1.reduce((p, x) => p + ((x.billing === 0 && x.rentMAccountId !== -1 && x.isFreeRent === 0) ? (x.amount + x.taxAmount) : 0), 0) + this.props.estimateMonies2.reduce((p, x) => p + ((x.billing === 0 && x.rentMAccountId !== -1) ? (x.amount + x.taxAmount) : 0), 0)
                + this.props.estimateMonies5.reduce((p, x) => p + ((x.billing === 0 && x.rentMAccountId !== -1 && x.isFreeRent === 0) ? (x.amount + x.taxAmount) : 0), 0)
                + this.props.estimateMonies1.reduce((p, x) => p + ((x.billing === 0 && x.rentMAccountId !== -1) ? (x.amount + x.taxAmount) : 0), 0) * this.props.estimateData.payNextmonth
                - (this.props.estimateData.applicationFeePayor === 0 ? this.props.estimateData.applicationFee : 0)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + "円"}
              style={{ marginTop: "16px" }}
              // defaultValue={

              //   String(this.props.estimateMonies1.reduce((p, x) => p + x.amount+x.taxAmount, 0)+this.props.estimateMonies2.reduce((p, x) => p + x.amount+x.taxAmount, 0)+this.props.estimateMonies5.reduce((p, x) => p + x.amount+x.taxAmount, 0)-this.props.estimateData.applicationFee).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')+"円"}
              // // className={classes.textField}
              // margin="normal"
              // InputProps={{
              //   readOnly: true,
              // }}
              variant="outlined"
            // style={{float:"left"}}
            />


            {String(this.props.estimateData.type) === "1" ?
              <CssTextField2
                id="contractSum"
                label="入居者合計"
                value={

                  String(this.props.estimateMonies1.reduce((p, x) => p + ((x.billing === 1 && x.rentMAccountId !== -1 && x.isFreeRent === 0) ? (x.amount + x.taxAmount) : 0), 0) + this.props.estimateMonies2.reduce((p, x) => p + ((x.billing === 1 && x.rentMAccountId !== -1) ? (x.amount + x.taxAmount) : 0), 0) + this.props.estimateMonies5.reduce((p, x) => p + ((x.billing === 1 && x.rentMAccountId !== -1 && x.isFreeRent === 0) ? (x.amount + x.taxAmount) : 0), 0)
                    + this.props.estimateMonies1.reduce((p, x) => p + ((x.billing === 1 && x.rentMAccountId !== -1) ? (x.amount + x.taxAmount) : 0), 0) * this.props.estimateData.payNextmonth
                    - (this.props.estimateData.applicationFeePayor === 1 ? this.props.estimateData.applicationFee : 0)).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,') + "円"}
                // className={classes.textField}
                margin="normal"
                InputProps={{
                  readOnly: true,
                }}
                variant="outlined"
                style={{ marginLeft: "10px" }}
              />
              : ""}

          </Grid></Grid>
      </ValidatorForm>
    );
  }
}









const mapStateToProps = state => {
  return {
    customerNewDispSet: state.customersReducer.customerNewDispSet,
    customerlist: state.customersReducer.customerlist,
    formvalue: state.form,
    estimateMonies1: state.estimates.estimateMonies1,
    estimateMonies5: state.estimates.estimateMonies5,
    estimateMonies2: state.estimates.estimateMonies2,
    estimateData: state.estimates.estimateData,
    estimate_sum_flg: state.estimates.estimate_sum_flg,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_estimateData(state) {
      dispatch(Estimates.change_estimateData(state))
    },
    change_estimate_sum_flg(state) {
      dispatch(Estimates.change_estimate_sum_flg(state))
    },
    check_estimates() {
      dispatch(Estimates.check_estimates())
    },
    // customerList(state) {
    //     dispatch(Customers.customerList(state))
    // },

  };
}




// CustomerInfoEdit = reduxForm({
//     form: 'customerform', // a unique identifier for this form
//     // validate//バリデーション

//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(CustomerInfoEdit)

export default connect(
  mapStateToProps, mapDispatchToProps
)(CustomerInfoEdit)


