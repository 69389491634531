import React from 'react';
import { Calendar, Views } from 'react-big-calendar'
import myEventsList from './events'
import moment from 'moment'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'



import "react-big-calendar/lib/css/react-big-calendar.css";

require('globalize/lib/cultures/globalize.culture.ja-JP')

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.

const globallocalizer = localizer(globalize);

//日本語曜日対応
moment.lang('ja', {weekdays: ["日","月","火","水","木","金","土"]});

const resourceMap = [
  { resourceId: 1, resourceTitle: '大会議室' },
  { resourceId: 2, resourceTitle: '小会議室' },
  { resourceId: 3, resourceTitle: '応接 1' },
  { resourceId: 4, resourceTitle: '応接 2' },
]

const TestScheduler = props => (
  <div style={{height:"500px"}}>

    <Calendar
      localizer={globallocalizer}
      resources={resourceMap}
      resourceIdAccessor="resourceId"
      resourceTitleAccessor="resourceTitle"
      step={15}
      onView={props.onView}
      timeslots={8}
      defaultView={Views.month}
      events={myEventsList}
      culture={'ja-JP'}
      startAccessor="start"
      endAccessor="end"
      views={{agenda:true, week: true, month: true,day:true }}
      formats = {{
          dayFormat: (date) => {
            return (moment.utc(date).format('M月DD日(dddd)'));
          },
          agendaDateFormat: (date) => {
            return (moment.utc(date).format('M月DD日(dddd)'));
          },
          dayHeaderFormat: (date) => {
            return (moment.utc(date).format('YYYY年M月DD日(dddd)'));
          },
          agendaHeaderFormat: ({start, end}) => {
              return (moment.utc(start).format('YYYY年M月DD日') + ' ～ ' + moment.utc(end).format('YYYY年M月DD日') );
          },
          dayRangeHeaderFormat: ({start, end}) => {
              return (moment.utc(start).format('YYYY年M月DD日') + ' ～ ' + moment.utc(end).format('YYYY年M月DD日') );
          },
      }}
      messages={{
        date: '日付',
        time: '時間',
        event: '内容',
        allDay: '終日',
        week: '週',
        work_week: 'Work Week',
        day: '日',
        month: '月',
        previous: '←前へ',
        next: '次へ→',
        yesterday: '昨日',
        tomorrow: '明日',
        today: '今日',
        agenda: '一覧',
        noEventsInRange: '期間内に登録されているイベントがありません',
      }}
    />
  </div>
)

export default TestScheduler;