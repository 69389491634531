const authentication = require('../../../react-azure-adb2c2').default;

// ●
export const insert = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/template/headers/insert`
    // const url = `http://localhost:5000/v1/rent/introduction/point/template/headers/insert`

    const token = authentication.getAccessToken();

    // alert(JSON.stringify(values))
    //const search = values ? values : {};
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


// ●
export const deletepoint = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/template/headers/delete/` + values;
    // const url = `http://localhost:5000/v1/rent/introduction/point/template/headers/delete/` + values;
    // alert(url)
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}