import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as YoyakuParking from './Saga/YoyakuParking';
import NewCreateEdit from './NewCreateEdit';

import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';



const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))


class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };
        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()

        // const id = this.props.rent_m_corporate_id ? this.props.rent_m_corporate_id : ''

        // this.props.set_rent_m_corporates(id)
        // this.props.get_owner_detail(id)
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <Card style={{ backgroundColor: "#e9f2f8" }}>
                <CardContent>
                    <Grid container spacing={1} >
                        <Grid item xs={12}>
                            <Card style={{ marginBottom: 10 }}>
                                <CardContent style={{ marginBottom: 30 }}>
                                    <NewCreateEdit/>
                                </CardContent>
                            </Card >
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        m_corporations_list: state.corporation.m_corporations_list,
        rent_responses_response_list: state.corporation.rent_responses_response_list,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        // set_rent_m_corporates(state) {
        //     dispatch(Corporation.set_rent_m_corporates(state))
        // },
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




