import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';


import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';
import CardContent from '@mui/material/CardContent';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';
import DescriptionIcon from '@mui/icons-material/Description';
import Button from '@mui/material/Button';
import Dropzone from 'react-dropzone';
import './Css/DropZone.css';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import GetAppIcon from '@mui/icons-material/GetApp';
import moment from 'moment';

import withStyles from '@mui/styles/withStyles';



const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))

const list =[
    {id: 1001, filename: "提携契約書", bikou: "備考", tantou: "担当", nitiji: "2021-10-10"},
    {id: 1002, filename: "提携契約書", bikou: "備考", tantou: "担当", nitiji: "2021-10-10"},
    {id: 1003, filename: "提携契約書", bikou: "備考", tantou: "担当", nitiji: "2021-10-10"},
]

class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modal_del:false,
            modalselect: false,
            columnWidths: {
                name:200,
                remarks:300,
                created_user:90,
                created_at:120,
              },
        };

        // this.toggle = this.toggle.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onDrop = this.onDrop.bind(this);
        
        this.handleDelOpen = this.handleDelOpen.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        
        this.handleDownload = this.handleDownload.bind(this);
        
        this.handleUpdate = this.handleUpdate.bind(this);
        this.handleOpen = this.handleOpen.bind(this);

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
          columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
          },
        }));
    }


    handleOpen(id){
            // 新規
        let rent_corporate_docs = {id:'', rent_m_corporate_id:"", file:"", remarks:""}
        this.props.change_rent_corporate_docs(rent_corporate_docs)
        
        this.setState({ "modal": true })
    }

    onDrop(files){
        const file = files[0]
            
        let rent_corporate_docs =  this.props.rent_corporate_docs

        rent_corporate_docs["rent_m_corporate_id"] = this.props.rent_m_corporates.id
        rent_corporate_docs["file"] = file

        rent_corporate_docs["remarks"] = this.props.rent_corporate_docs.remarks ? this.props.rent_corporate_docs.remarks : ""
            
        this.props.change_rent_corporate_docs(rent_corporate_docs)
    
        this.setState({
            change: !this.state.change
        });
    
            // this.props.add_introduction_file(sendfile)
    };

    onChange(state){
        // console.log(this.props.claso_blog_places)
        let rent_corporate_docs =  this.props.rent_corporate_docs

        rent_corporate_docs[state.target.name] = state.target.value
        
        this.props.change_rent_corporate_docs(rent_corporate_docs)
        this.setState({
            change: !this.state.change
        });
        
    }

    handleDelOpen(id){
        if(id){

            this.props.set_rent_corporate_docs(id)

            this.setState({ "modal_del": true })
        }
        
    }

    handleDelete(id, delflg){

        let rent_corporate_docs =  this.props.rent_corporate_docs
        rent_corporate_docs["is_deleted"] = delflg
        this.props.change_rent_corporate_docs(rent_corporate_docs)
   
        if(id){
            this.props.update_rent_corporate_docs()
        }

        this.setState({
            modal_del: false,
            change: !this.state.change
        });
        
    }

    handleDownload(id, name){
        if(id){

            this.props.download_rent_corporate_docs({id:id, name:name})

            this.setState({
                change: !this.state.change
            });
        }
        
    }

    

    handleUpdate(id){

        this.props.insert_rent_corporate_docs()

        this.setState({
            modal: false,
            change: !this.state.change
        });

        
    }
    


    render() {

        return (
            <div>
                <div style={{ float: "left" }} ><DescriptionIcon style={{ color: "#5cb85c" }}></DescriptionIcon> ファイル</div>
                <Button style={{ float: "right",  }} size="small" variant="contained" color="primary" startIcon={<ControlPointIcon />} onClick={(e) => { this.handleOpen()}}>新規</Button>
                <div style={{clear:"both"}}></div>
                <Table 
                    rowHeight={34} 
                    rowsCount={this.props.rent_corporate_docs_list.length} 
                    height={200} 
                    headerHeight={30} 
                    width={(window.innerWidth / 2) - 75}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                >
                    <Column header={<Cell></Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}><IconButton style={{marginTop:-5}} onClick={(e) => { this.handleDownload(this.props.rent_corporate_docs_list[rowIndex].id, this.props.rent_corporate_docs_list[rowIndex].name); }} aria-label="edit" size="small"><GetAppIcon /></IconButton></Cell>)} width={40}/>
                    <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>ファイル名</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_docs_list[rowIndex].name}</Cell>)} />
                    <Column width={this.state.columnWidths.remarks} isResizable={true} columnKey="remarks" header={<Cell>備考</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_corporate_docs_list[rowIndex].remarks}</Cell>)} />
                    <Column width={this.state.columnWidths.created_user} isResizable={true} columnKey="created_user" header={<Cell>担当</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.users_select && this.props.rent_corporate_docs_list[rowIndex].created_user ? this.props.users_select.filter((a) => a.value === this.props.rent_corporate_docs_list[rowIndex].created_user).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_corporate_docs_list[rowIndex].created_user)[0].label : "": ""}</Cell>)} />
                    <Column width={this.state.columnWidths.created_at} isResizable={true} columnKey="created_at" header={<Cell>日時</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{moment(this.props.rent_corporate_docs_list[rowIndex].created_at).format("YYYY-MM-DD")}</Cell>)}/>
                    <Column header={<Cell></Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}><IconButton style={{marginTop:-5}} onClick={(e) => { this.handleDelOpen(this.props.rent_corporate_docs_list[rowIndex].id); }} aria-label="edit" size="small"><DeleteForeverIcon /></IconButton></Cell>)} width={40}/>
                </Table>




                <Modal isOpen={this.state.modal} fade={false} size="lg">
                    <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                        <span>ファイル登録</span>
                        {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
                    </ModalHeader>
                        

                    <ModalBody>
                        <Card>
                            <CardContent>
                                <div fullWidth style={{height: 120, margin: 5 }}>

                                <Dropzone

                                    onDrop={(e)=>this.onDrop(e)}>
                                    {({ acceptedFiles, getRootProps, getInputProps }) => (

                                        <div className="dropzone" {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5", height: 40, }}>

                                            <input {...getInputProps()} />

                                                <p>ファイル添付　クリックまたはドラッグ</p>


                                        </div>

                                    )}
                                </Dropzone>

                            </div>
                            </CardContent>
                            <CardContent>
                                {this.props.rent_corporate_docs.file? this.props.rent_corporate_docs.file.name : ""}
                            </CardContent>
                            <CardContent>
                                <TextField
                                    fullWidth
                                    name="remarks"
                                    label="備考"
                                    multiline
                                    rows={4}
                                    value={this.props.rent_corporate_docs.remarks}
                                    variant="outlined"
                                    onChange={this.onChange}
                                />
                            </CardContent>

                        </Card>



                    </ModalBody>
                        

                        {/* <Button onClick={this.handleClose}>閉じる</Button> */}
                    <ModalFooter>

                        <Button variant="contained" color="primary" onClick={()=>this.handleUpdate(this.props.rent_m_corporates.id)} style={{backgroundColor:"#5cb85c", marginRight:5}}>保存</Button>　

                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modal_del} fade={false} className="modal-dialog-centered">
                    <ModalBody>
                        <div>本当に削除してよろしいですか？</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="secondary" onClick={()=>this.handleDelete(this.props.rent_corporate_docs.id, 1)}>削除</Button>
                        <Button variant="contained" style={{marginLeft:3}} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
                    </ModalFooter>
                        
                </Modal>

            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_corporate_docs: state.corporation.rent_corporate_docs,
        rent_corporate_docs_list: state.corporation.rent_corporate_docs_list,
        users_select: state.masterReducer.users_select,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_rent_corporate_docs_list(state) {
            dispatch(Corporation.get_rent_corporate_docs_list(state))
        },
        change_rent_corporate_docs(state) {
            dispatch(Corporation.change_rent_corporate_docs(state))
        },
        set_rent_corporate_docs(state) {
            dispatch(Corporation.set_rent_corporate_docs(state))
        },
        insert_rent_corporate_docs(state) {
            dispatch(Corporation.insert_rent_corporate_docs(state))
        },
        update_rent_corporate_docs(state) {
            dispatch(Corporation.update_rent_corporate_docs(state))
        },
        download_rent_corporate_docs(state) {
            dispatch(Corporation.download_rent_corporate_docs(state))
        },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




