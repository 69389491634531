import React, { Component } from 'react';
import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';

import { Field, reduxForm } from 'redux-form';
import '../../Css/Table.css';
import * as Responses from './../ResponseDetail/Saga/Responses';

//小学校リスト
//あ行
const chuugakkouListA = [
    { id: 35, label: "庵治中学校" },
    { id: 14, label: "一宮中学校" },
    { id: 7, label: "太田中学校" },
    { id: 37, label: "男木中学校" },
]

//か行
const chuugakkouListKa = [
    { id: 21, label: "香川第一中学校" },
    { id: 17, label: "勝賀中学校" },
    { id: 5, label: "木太中学校" },
    { id: 12, label: "協和中学校" },
    { id: 13, label: "香東中学校" },
    { id: 22, label: "香南中学校" },
    { id: 18, label: "国分寺中学校" },
]

//さ行
const chuugakkouListSa = [
    { id: 6, label: "桜町中学校" },
    { id: 1, label: "紫雲中学校" },
    { id: 36, label: "塩江中学校" },
    { id: 10, label: "下笠居中学校" },
]

//た行
const chuugakkouListTa = [
    { id: 31, label: "高松第一中学校" },
    { id: 4, label: "玉藻中学校" },
    { id: 11, label: "鶴尾中学校" },
]

//な行
const chuugakkouListNa = []

//は行
const chuugakkouListHa = [
    { id: 9, label: "古高松中学校" },
]

//ま行
const chuugakkouListMa = [
    { id: 23, label: "三木中学校" },
    { id: 24, label: "牟礼中学校" },
]

//や行
const chuugakkouListYa = [
    { id: 8, label: "屋島中学校" },
    { id: 16, label: "山田中学校" },
]

//ら行
const chuugakkouListRa = [
    { id: 15, label: "龍雲中学校" },
]

//わ行
const chuugakkouListWa = []


export class CheckboxGroup extends React.Component {

    checkboxGroup() {
        let { label, required, options, input, meta, request_chuugakkou } = this.props;

        return options.map((option, index) => {
            //console.log(input.value)

            return (
                <div className="checkbox eq" key={option.id}>
                    <label for={"chuugakkou" + option.id}>
                        <input type="checkbox"
                            id={"chuugakkou" + option.id}
                            name={`${input.name}[${option.id}]`}
                            value={option.id}
                            checked={request_chuugakkou ? (request_chuugakkou.split(',').filter(a => a === String(option.id))[0] ? true : false) : false}
                            onChange={(event) => {
                                // let newValue = [...input.value];
                                // if (event.target.checked) {
                                //     newValue.push(option.id);
                                // } else {
                                //     //newValue.splice(newValue.indexOf(index+1), 1);
                                //     newValue = newValue.filter(function (item) {
                                //         return item !== option.id;
                                //     });
                                // }

                                return input.onChange(option.id);
                            }}
                        />
                        <span>{option.label}</span>
                    </label>
                </div>)
        });
    }


    render() {
        return (
            <div>
                {this.checkboxGroup()}
            </div>
        )
    }
}


class ChuugakkouEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            reflesh: false
            // gasu: this.props.responsedetail.gasu,
            // denki: this.props.responsedetail.denki,
            // ih: this.props.responsedetail.ih,
            // gasuka: this.props.responsedetail.gasuka,
            // denkika: this.props.responsedetail.denkika,
            // ihka: this.props.responsedetail.ihka,
            // eakon: this.props.responsedetail.eakon,
        };

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.set_chuugakkou = this.set_chuugakkou.bind(this);
        // this.onChange = this.onChange.bind(this);


    }

    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }


    componentDidMount() {
        // This method is called when the component is first added to the document
        // this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });     
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        // this.ensureDataFetched();
    }

    set_chuugakkou(value) {



        let rent_responses_editor = this.props.rent_responses_editor

        let request_chuugakkou = rent_responses_editor.request_chuugakkou ? rent_responses_editor.request_chuugakkou.split(',') : []

        if (request_chuugakkou.filter(a => a === String(value))[0]) {

            rent_responses_editor.request_chuugakkou = request_chuugakkou.filter(a => a !== String(value)).join(',')

        } else {
            request_chuugakkou.push(String(value))
            console.log(request_chuugakkou)

            rent_responses_editor.request_chuugakkou = request_chuugakkou.join(',')
            //     rent_responses_editor.request_shougakkou = request_shougakkou ? request_shougakkou.push(value).join(',') : value

        }
        this.props.change_rent_responses_editor(rent_responses_editor)

        this.setState({ reflesh: !this.state.reflesh })
    }



    render() {

        // const { handleSetsubiSubmit } = this.props;
        return (

            <div>
                {/* <form onSubmit={handleSetsubiSubmit}  >    */}

                <table>
                    <colgroup width='20%' />
                    <colgroup width='80%' />
                    <tbody>

                        <tr>
                            <th>あ行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListA} />
                            </td>
                        </tr>
                        <tr>
                            <th>か行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListKa} />
                            </td>
                        </tr>
                        <tr>
                            <th>さ行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListSa} />
                            </td>
                        </tr>
                        <tr>
                            <th>た行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListTa} />
                            </td>
                        </tr>
                        <tr>
                            <th>な行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListNa} />
                            </td>
                        </tr>
                        <tr>
                            <th>は行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListHa} />
                            </td>
                        </tr>
                        <tr>
                            <th>ま行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListMa} />
                            </td>
                        </tr>
                        <tr>
                            <th>や行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListYa} />
                            </td>
                        </tr>
                        <tr>
                            <th>ら行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListRa} />
                            </td>
                        </tr>
                        <tr>
                            <th>わ行</th>
                            <td>
                                <Field name="checkChuugakkou" request_chuugakkou={this.props.rent_responses_editor.request_chuugakkou} onChange={(e) => this.set_chuugakkou(e)} component={CheckboxGroup} options={chuugakkouListWa} />
                            </td>
                        </tr>

                    </tbody>
                </table>


                {/* </form> */}
            </div>
        );
    }
}


const mapStateToProps = state => {
    return {

        rent_responses_editor: state.responsesReducer.rent_responses_editor,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_responses_editor(state) {
            dispatch(Responses.change_rent_responses_editor(state))
        },

        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

// ChuugakkouEdit = reduxForm({
//     form: 'roomform', // a unique identifier for this form
//     // validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ChuugakkouEdit)
export default connect(mapStateToProps, mapDispatchToProps)(ChuugakkouEdit);
// export default ChuugakkouEdit;