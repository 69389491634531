import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Contracts from '../Contracts/Saga/Contracts';
import * as Customers from './Saga/Customers';
import * as Corporation from '../Corporation/Saga/Corporation';
import CorporatesPlaceDialog from "../Corporation/CorporatesPlaceDialog";
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import "react-datepicker/dist/react-datepicker.css";
import '../Contracts/Css/Contracts.css';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import TextField from '@mui/material/TextField';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SearchIcon from '@mui/icons-material/Search';
import Dialog from '@mui/material/Dialog';
import CancelIcon from '@mui/icons-material/Cancel';
import { useKana } from 'react-use-kana';
import './Css/Grid.css'





const CorpAdd = ({ coprporate_add_forms, change_coprporate_add_forms }) => {
  const { kana, setKanaSource } = useKana();
  function change_name(e) {
    setKanaSource(e.target.value)







    if (e.target.name === "name") {
      coprporate_add_forms.name = e.target.value;
      coprporate_add_forms.kana = kana;




    } else {
      coprporate_add_forms.kana = e.target.value;
    }
    change_coprporate_add_forms(coprporate_add_forms);

  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12} style={{ padding: "0px 5px" }}>
        <TextField
          id="outlined-basic"
          //   className={classes.textField}
          label="企業名"
          margin="dense"
          variant="outlined"
          onChange={change_name}
          name="name"

          value={coprporate_add_forms.name}
          style={{ float: "left", width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid xs={12} style={{ padding: "0px 5px" }}>
        <TextField
          id="outlined-basic"
          //   className={classes.textField}
          label="企業名カナ"
          margin="dense"
          variant="filled"
          onChange={change_name}
          name="kana"
          value={coprporate_add_forms.kana}
          style={{ float: "left", width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>


  );
};

class ResponseListSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      modal_siten: false,
      columnWidths:{
        id:70, 
        name:300,
        rent_corporate_office_name:390,
        office_name:300,
      }
    };

    this.handleChangeNotSearchText = this.handleChangeNotSearchText.bind(this);
    this.handleChangeNotSearchTextEnter = this.handleChangeNotSearchTextEnter.bind(this);
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.RowClick = this.RowClick.bind(this);
    this.onRowDoubleClick = this.onRowDoubleClick.bind(this);
    this.RowClickOffice = this.RowClickOffice.bind(this);
    this.onRowDoubleClickOffice = this.onRowDoubleClickOffice.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
    this._rowClassNameGetterOffice = this._rowClassNameGetterOffice.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpenSiten = this.handleOpenSiten.bind(this);
  }

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    this.setState(({ columnWidths }) => ({
      columnWidths: {
        ...columnWidths,
        [columnKey]: newColumnWidth,
      },
    }));
}

  
  handleChangeNotSearchText(state) {
// alert(state.target.value)
    let rent_m_corporate_search = this.props.rent_m_corporate_search

    rent_m_corporate_search[state.target.name] = state.target.value

    this.props.change_rent_m_corporate_search(rent_m_corporate_search);

    this.setState({ refresh: !this.state.refresh })
  }

  handleChangeNotSearchTextEnter(state) {

      let rent_m_corporate_search =this.props.rent_m_corporate_search
      rent_m_corporate_search.rent_m_corporate_id = 0
      rent_m_corporate_search.rent_m_corporate_kana = ""
      rent_m_corporate_search.rent_m_corporate_name = ""
      rent_m_corporate_search.rent_corporate_office_id = 0
      rent_m_corporate_search.rent_corporate_office_kana = ""
      rent_m_corporate_search.rent_corporate_office_name = ""
      this.props.change_rent_m_corporate_search(rent_m_corporate_search)

      this.props.get_rent_m_corporate_search_list()
      this.setState({ refresh: !this.state.refresh })
  }

RowClick(event,index){
    // alert(index)
    let rent_m_corporate_search = this.props.rent_m_corporate_search
    rent_m_corporate_search.rent_m_corporate_id = this.props.rent_m_corporate_search_list[index].id
    rent_m_corporate_search.rent_m_corporate_name = this.props.rent_m_corporate_search_list[index].name
    rent_m_corporate_search.rent_m_corporate_kana = this.props.rent_m_corporate_search_list[index].kana

    rent_m_corporate_search.rent_corporate_office_id = this.props.rent_m_corporate_search_list[index].rent_corporate_office_id
    rent_m_corporate_search.rent_corporate_office_name = this.props.rent_m_corporate_search_list[index].rent_corporate_office_name
    rent_m_corporate_search.rent_corporate_office_kana = this.props.rent_m_corporate_search_list[index].rent_corporate_office_kana

    this.props.change_rent_m_corporate_search(rent_m_corporate_search)

    // if(rent_m_corporate_search.rent_m_corporate_id > 0){
    //     this.props.get_rent_m_corporate_office_search_list(rent_m_corporate_search.rent_m_corporate_id)
    // }

    // this.props.handleOwnerSearchClose()

    this.setState({ refresh: !this.state.refresh })
}

_rowClassNameGetter(index){
        
  if(this.props.rent_m_corporate_search_list && this.props.rent_m_corporate_search_list[index]) {
    if(this.props.rent_m_corporate_search_list[index].id == this.props.rent_m_corporate_search.rent_m_corporate_id &&
      this.props.rent_m_corporate_search_list[index].rent_corporate_office_id == this.props.rent_m_corporate_search.rent_corporate_office_id){
      return "select"
    }else{
      return "normal"
    }
      
  }else{
    return ""
  }
}

onRowDoubleClick(event,index){
  // alert(index)

  let rent_m_corporate_search = this.props.rent_m_corporate_search
  rent_m_corporate_search.rent_m_corporate_id = this.props.rent_m_corporate_search_list[index].id
  rent_m_corporate_search.rent_m_corporate_name = this.props.rent_m_corporate_search_list[index].name
  rent_m_corporate_search.rent_m_corporate_kana = this.props.rent_m_corporate_search_list[index].kana

  rent_m_corporate_search.rent_corporate_office_id = this.props.rent_m_corporate_search_list[index].rent_corporate_office_id
  rent_m_corporate_search.rent_corporate_office_name = this.props.rent_m_corporate_search_list[index].rent_corporate_office_name
  rent_m_corporate_search.rent_corporate_office_kana = this.props.rent_m_corporate_search_list[index].rent_corporate_office_kana
  this.props.change_rent_m_corporate_search(rent_m_corporate_search)

  let rent_customer_editor_forms = this.props.rent_customer_editor_forms

  if(this.props.modal_corporate == 1){//契約者
    if(rent_customer_editor_forms.rent_m_corporate_id != rent_m_corporate_search.rent_m_corporate_id){
      rent_customer_editor_forms.name1 = rent_m_corporate_search.rent_m_corporate_name + (rent_m_corporate_search.rent_corporate_office_name ? " " + rent_m_corporate_search.rent_corporate_office_name : "")
      rent_customer_editor_forms.kana1 = rent_m_corporate_search.rent_m_corporate_kana + (rent_m_corporate_search.rent_corporate_office_kana ? " " + rent_m_corporate_search.rent_corporate_office_kana : "")

    }
  
    rent_customer_editor_forms.rent_m_corporate_id = rent_m_corporate_search.rent_m_corporate_id
    rent_customer_editor_forms.rent_m_corporate_name = rent_m_corporate_search.rent_m_corporate_name


    rent_customer_editor_forms.rent_corporate_office_id = rent_m_corporate_search.rent_corporate_office_id
    rent_customer_editor_forms.rent_corporate_office_name = rent_m_corporate_search.rent_corporate_office_name
  }

  //勤務先
  rent_customer_editor_forms.office_rent_m_corporate_id = rent_m_corporate_search.rent_m_corporate_id
  rent_customer_editor_forms.office_name = rent_m_corporate_search.rent_m_corporate_name

  rent_customer_editor_forms.office_rent_corporate_office_id = rent_m_corporate_search.rent_corporate_office_id
  rent_customer_editor_forms.office_rent_corporate_office_name = rent_m_corporate_search.rent_corporate_office_name


  this.props.change_rent_customer_editor_forms(rent_customer_editor_forms);

  this.props.change_rent_m_corporate_search_modal(false)

  this.setState({ reflash: !this.state.reflash });
}

RowClickOffice(event,index){
  // alert(index)
  let rent_m_corporate_search = this.props.rent_m_corporate_search
  if(rent_m_corporate_search.rent_corporate_office_id == this.props.rent_m_corporate_office_search_list[index].id){
    rent_m_corporate_search.rent_corporate_office_id = 0
    rent_m_corporate_search.rent_corporate_office_name = ""
    rent_m_corporate_search.rent_corporate_office_kana = ""
  }else{
    rent_m_corporate_search.rent_corporate_office_id = this.props.rent_m_corporate_office_search_list[index].id
    rent_m_corporate_search.rent_corporate_office_name = this.props.rent_m_corporate_office_search_list[index].name
    rent_m_corporate_search.rent_corporate_office_kana = this.props.rent_m_corporate_office_search_list[index].kana
  }
  
  this.props.change_rent_m_corporate_search(rent_m_corporate_search)

  // this.props.handleOwnerSearchClose()

  this.setState({ refresh: !this.state.refresh })
}

onRowDoubleClickOffice(event,index){
  // alert(index)

  let rent_m_corporate_search = this.props.rent_m_corporate_search
  rent_m_corporate_search.rent_corporate_office_id = this.props.rent_m_corporate_office_search_list[index].id
  rent_m_corporate_search.rent_corporate_office_name = this.props.rent_m_corporate_office_search_list[index].name
  rent_m_corporate_search.rent_corporate_office_kana = this.props.rent_m_corporate_office_search_list[index].kana
  this.props.change_rent_m_corporate_search(rent_m_corporate_search)

  let rent_customer_editor_forms = this.props.rent_customer_editor_forms

  if(this.props.modal_corporate == 1){//契約者
    rent_customer_editor_forms.rent_m_corporate_id = rent_m_corporate_search.rent_m_corporate_id
    rent_customer_editor_forms.rent_m_corporate_name = rent_m_corporate_search.rent_m_corporate_name
    // rent_customer_editor_forms.name1 = rent_m_corporate_search.rent_m_corporate_name
    // rent_customer_editor_forms.kana1 = rent_m_corporate_search.rent_m_corporate_kana

    rent_customer_editor_forms.rent_corporate_office_id = rent_m_corporate_search.rent_corporate_office_id
    rent_customer_editor_forms.rent_corporate_office_name = rent_m_corporate_search.rent_corporate_office_name
  }

  //勤務先
  rent_customer_editor_forms.office_rent_m_corporate_id = rent_m_corporate_search.rent_m_corporate_id
  rent_customer_editor_forms.office_name = rent_m_corporate_search.rent_m_corporate_name

  rent_customer_editor_forms.office_rent_corporate_office_id = rent_m_corporate_search.rent_corporate_office_id
  rent_customer_editor_forms.office_rent_corporate_office_name = rent_m_corporate_search.rent_corporate_office_name


  this.props.change_rent_customer_editor_forms(rent_customer_editor_forms);

  this.props.change_rent_m_corporate_search_modal(false)

  this.setState({ reflash: !this.state.reflash });
}

_rowClassNameGetterOffice(index){
        
  if(this.props.rent_m_corporate_office_search_list && this.props.rent_m_corporate_office_search_list[index]) {
    if(this.props.rent_m_corporate_office_search_list[index].id == this.props.rent_m_corporate_search.rent_corporate_office_id){
      return "select"
    }else{
      return "normal"
    }
      
  }else{
    return ""
  }
}

handleClose(){
  this.setState({ "modal_siten": false })
}

handleOpenSiten(id){

  // let rent_corporate_offices = { id: '', rent_m_corporate_id: this.props.rent_m_corporate_search.rent_m_corporate_id, rent_m_corporate_office_id: 0, post_card: 0, in_office: 0, remarks: '', name1:"", name2:'', department:'', position:'', tel:'', fax:'', mail:'', remarks:'', is_deleted: 0, created_at: '', created_user_id: '' }
  let rent_corporate_offices = { id: '', rent_m_corporate_id: id, rent_m_corporate_office_id: 0, post_card: 0, in_office: 0, remarks: '', name1:"", name2:'', department:'', position:'', tel:'', fax:'', mail:'', remarks:'', is_deleted: 0, created_at: '', created_user_id: '' }

  this.props.change_rent_corporate_offices(rent_corporate_offices)

  this.setState({ "modal_siten": true })
}

  render() {


    return (
      <div>


        <Dialog open={this.props.rent_m_corporate_editor > 0 } fade={false}  maxWidth="sm" onClose={(e) => { this.props.change_rent_m_corporate_editor(0) }}>

            <Card style={{}}>

              <CardHeader

                action={
                  <IconButton
                    aria-label="settings"
                    onClick={() => this.props.change_rent_m_corporate_editor(0)}
                    size="large">
                    <CancelIcon />
                  </IconButton>
                }
                title="企業登録"
                subheader="必ず既存の企業名を検索して二重登録にならないようにしてください。"
              />
              <CardContent>

                <CorpAdd coprporate_add_forms={this.props.coprporate_add_forms} change_coprporate_add_forms={this.props.change_coprporate_add_forms} />

              </CardContent>
              <CardActions >
                <div style={{ padding: 20, width: "100%", textAlign: "center" }}>
                  <Button variant="contained" color="primary" style={{ margin: 5 }} onClick={this.props.add_rent_m_corporates} >登録する</Button>
                  <Button variant="contained" color="secondary" style={{ margin: 5 }} onClick={() => this.props.change_rent_m_corporate_editor(0)}>キャンセル</Button>
                </div>
              </CardActions>


            </Card>


          </Dialog>




          <Grid container spacing={3} style={{width:850}}>
            <Grid xs={12}>
              <TextField
                style={{width:200}}
                onChange={this.handleChangeNotSearchText}
                value={this.props.rent_m_corporate_search.name}
                size="small"
                label="法人名"
                name="name"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        // エンターキー押下時の処理
                        this.handleChangeNotSearchTextEnter(e)
                    }
                }}
              />
  {/* 
              <FormControlLabel value="0" name="contract_form" onChange={this.handleChange} checked={this.props.rent_customer_editor_forms.contract_form === 0 ? true : false} control={<Radio />} label="個人" />
              <FormControlLabel value="1" name="contract_form" onChange={this.handleChange} checked={this.props.rent_customer_editor_forms.contract_form === 1 ? true : false} control={<Radio />} label="法人" /> */}

              <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#5cb85c", marginLeft: 10, marginTop:10 }} onClick={()=>this.handleChangeNotSearchTextEnter()} startIcon={<SearchIcon />}>検索</Button>
              
            </Grid>
            <Grid xs={6} style={{position:"relative"}}>
              <Table
                  className="gridCorporateSearchList"
                  rowHeight={34}
                  rowsCount={this.props.rent_m_corporate_search_list.length} 
                  width={850}
                  // height={150}
                  height={480}
                  headerHeight={34}
                  onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                  isColumnResizing={false}
                  rowClassNameGetter={this._rowClassNameGetter}
                  onRowClick={this.RowClick}
                  onRowDoubleClick={this.onRowDoubleClick}
              >
                  <Column width={this.state.columnWidths.id} isResizable={true} columnKey="id" header={<Cell>ID</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                      {this.props.rent_m_corporate_search_list[rowIndex].id}
                  </Cell>)} />
                  <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>法人名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                      {this.props.rent_m_corporate_search_list[rowIndex].name}
                  </Cell>)} />
                  <Column width={75} isResizable={true} columnKey="add" header={<Cell>支店追加</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                      {!this.props.rent_m_corporate_search_list[rowIndex].rent_corporate_office_id ? <IconButton
                        onClick={()=>{this.handleOpenSiten(this.props.rent_m_corporate_search_list[rowIndex].id)}}
                        style={{padding:0}}
                        size="large"><AddCircleIcon style={{color:"#5cb85cd", fontSize: "20px" }} /></IconButton>:""}
                  </Cell>)} />
                  <Column width={this.state.columnWidths.rent_corporate_office_name} isResizable={true} columnKey="rent_corporate_office_name" header={<Cell>支店名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                      {this.props.rent_m_corporate_search_list[rowIndex].rent_corporate_office_name}
                  </Cell>)} />

              </Table>
              {/* <div style={{ position: "absolute", bottom: "10px", right: "40px" }}> */}
              <div style={{ marginTop: "10px" }}>
                  <Tooltip title="法人追加"><Fab variant="extended" color="primary" size="small" style={{ marginRight: "10px" }} onClick={() => this.props.change_rent_m_corporate_editor(1)}>
                      <AddIcon style={{ fontSize: "20px" }} /></Fab></Tooltip>
              </div>
              <Dialog open={this.state.modal_siten} fade={false}  maxWidth="xl" onClose={(e) => { this.setState({ "modal_siten": false }) }}>
                    <CorporatesPlaceDialog handleClose={this.handleClose}/>

                </Dialog>
            </Grid>
            {/* <Grid xs={6} style={{position:"relative"}}>
              <Table
                  className="gridCorporateSearchList"
                  rowHeight={34}
                  rowsCount={this.props.rent_m_corporate_office_search_list.length} 
                  width={400}
                  // height={150}
                  height={280}
                  headerHeight={34}
                  onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                  isColumnResizing={false}
                  rowClassNameGetter={this._rowClassNameGetterOffice}
                  onRowClick={this.RowClickOffice}
                  onRowDoubleClick={this.onRowDoubleClickOffice}
              >
                  <Column width={this.state.columnWidths.office_name} isResizable={true} columnKey="name" header={<Cell>支店名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                      {this.props.rent_m_corporate_office_search_list[rowIndex].name}
                  </Cell>)} />

              </Table>
              {this.props.rent_m_corporate_search.rent_m_corporate_id > 0 ? <div style={{ position: "absolute", bottom: "10px", right: "40px" }}>
                  <Tooltip title="支店追加"><Fab variant="extended" color="primary" size="small" style={{ marginRight: "10px" }} onClick={()=>{this.handleOpenSiten()}}>
                      <AddIcon style={{ fontSize: "20px" }} /></Fab></Tooltip>
              </div>:""}
              <Dialog open={this.state.modal_siten} fade={false}  maxWidth="xl" onClose={(e) => { this.setState({ "modal_siten": false }) }}>
                    <CorporatesPlaceDialog handleClose={this.handleClose}/>

                </Dialog>
            </Grid> */}
          </Grid>
          
      </div>
    );
  }
}





const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    user_details: state.masterReducer.user_details,
    rent_m_agents: state.customersReducer.rent_m_agents,
    // csv_loading: state.Adjustment.csv_loading,
    // search_list: state.Adjustment.search_list,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
    // seisan_follow_search: state.Adjustment.seisan_follow_search,
    // tantou: state.Adjustment.tantou,
    // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
    // rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_customer_editor_disp: state.contracts.rent_customer_editor_disp,
    rent_customer_editor_forms: state.customersReducer.rent_customer_editor_forms,
    rent_m_corporates: state.customersReducer.rent_m_corporates,
    rent_m_corporates_loading: state.customersReducer.rent_m_corporates_loading,
    rent_m_corporate_editor: state.customersReducer.rent_m_corporate_editor,
    coprporate_add_forms: state.customersReducer.coprporate_add_forms,
    customerlist: state.customersReducer.customerlist,
    rent_m_corporate_search_modal: state.customersReducer.rent_m_corporate_search_modal,
    rent_m_corporate_search: state.customersReducer.rent_m_corporate_search,
    rent_m_corporate_search_list: state.customersReducer.rent_m_corporate_search_list,
    rent_m_corporate_office_search_list: state.customersReducer.rent_m_corporate_office_search_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_rent_customer_editor_disp(state) {
      dispatch(Contracts.change_rent_customer_editor_disp(state))
    },
    // change_rent_customer_editor_forms(state) {
    //   dispatch(Contracts.change_rent_customer_editor_forms(state))
    // },
    get_rent_customer_zip_address(state) {
      dispatch(Customers.get_rent_customer_zip_address(state))
    },
    get_rent_m_corporates(state) {
      dispatch(Customers.get_rent_m_corporates(state))
    },
    change_rent_m_corporate_editor(state) {
      dispatch(Customers.change_rent_m_corporate_editor(state))
    },
    // update_rent_contracts(state) {
    //   dispatch(Contracts.update_rent_contracts(state))
    // },
    change_coprporate_add_forms(state) {
      dispatch(Customers.change_coprporate_add_forms(state))
    },
    change_rent_customer_editor_forms(state) {
      dispatch(Customers.change_rent_customer_editor_forms(state))
    },

    change_customeredit(state) {
      dispatch(Customers.change_customeredit(state))
    },
    check_rent_customer_editor_disp(state) {
      dispatch(Customers.check_rent_customer_editor_disp(state))
    },
    update_customerdetail(state) {
      dispatch(Customers.update_customerdetail(state))
    },
    add_rent_m_corporates(state) {
      dispatch(Customers.add_rent_m_corporates(state))
    },
    add_rent_m_corporates_other(state) {
      dispatch(Customers.add_rent_m_corporates_other(state))
    },
    customerList(state) {
      dispatch(Customers.customerList(state))
    },

    new_customerdetail(state) {
      dispatch(Customers.new_customerdetail(state))
    },
    change_rent_m_corporate_search_modal(state) {
      dispatch(Customers.change_rent_m_corporate_search_modal(state))
    },
    get_rent_m_corporate_search_list(state) {
      dispatch(Customers.get_rent_m_corporate_search_list(state))
    },
    change_rent_m_corporate_search(state) {
      dispatch(Customers.change_rent_m_corporate_search(state))
    },
    get_rent_m_corporate_office_search_list(state) {
      dispatch(Customers.get_rent_m_corporate_office_search_list(state))
    },
    change_rent_corporate_offices(state) {
      dispatch(Corporation.change_rent_corporate_offices(state))
  },
    // search_contract_follow_list(state) {
    //   dispatch(ContractFollow.search_contract_follow_list(state))
    // },
    // update_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.update_rent_m_contract_follow_settings(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);
