
const authentication = require('../../../react-azure-adb2c2').default;



export const list = (values: any) => {


    // const url = process.env.REACT_APP_API_BASE_URL + `/api/SearchRooms/`;
    // const url = `http://localhost:5000/v1/t/room/stocks/list`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/t/room/stocks/list`;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insert = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/v1/t/room/stocks/insert/`;
    // const url = `http://localhost:5000/v1/t/room/stocks/insert/`;



    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const stockdelete = (values: any) => {


    // const url = process.env.REACT_APP_API_BASE_URL + `/api/SearchRooms/`;
    // const url = `http://localhost:5000/v1/t/room/stocks/delete/` + values;

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/t/room/stocks/delete/` + values;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'DELETE',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const stockdeleteall = (values: any) => {


    // const url = process.env.REACT_APP_API_BASE_URL + `/api/SearchRooms/`;
    // const url = `http://localhost:5000/v1/t/room/stocks/delete/` + values;

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/t/room/stocks/deleteall/`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'DELETE',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
// export const response_list = (values: any) => {


//     const url = process.env.REACT_APP_API_BASE_URL + `/response/ResponseSearchRoomsApi`;

//     // const url = `https://localhost:44341/response/ResponseSearchRoomsApi`;

//     // alert(JSON.stringify(values))
//     const token = authentication.getAccessToken();

//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     }).then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// };








// export const detail = (values: any) => {

//     const url = process.env.REACT_APP_API_BASE_URL + `/api/SearchRooms/detail?tatemono_id=` + values.tatemonoId + '&room_id=' + values.roomId;

//     const token = authentication.getAccessToken();

//     return fetch(url, {
//         headers: { 'Authorization': 'Bearer ' + token }
//     }).then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }


// export const update = (values: any) => {

//     const token = authentication.getAccessToken();
//     const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContacts/` + values.id;
//     // const url = `https://localhost:44341/api/RentCustomerContacts/` + values.id;
//     return fetch(url, {
//         method: 'put',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }

// export function insert(values: any) {

//     const token = authentication.getAccessToken();
//     const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContacts/`;
//     // const url = `https://localhost:44341/api/RentCustomerContacts/`;


//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));
// }