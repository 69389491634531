import React, { Component } from 'react';
import styled from 'styled-components'
import iekanri from './Img/Map/icon_ie03.png'
import iekanriselected from './Img/Map/icon_ie06.png'
import iezero from './Img/Map/icon_zeronine.png'
import iezeroselected from './Img/Map/icon_zeronine2.png'

import ieippan from './Img/Map/icon_ie01.png'
import ieippanselected from './Img/Map/icon_ie04.png'
import iegyou from './Img/Map/icon_ie02.png'
import iegyouselected from './Img/Map/icon_ie05.png'
import GoogleMapReact from 'google-map-react'
import { connect } from 'react-redux';
import { submit } from 'redux-form'

import { get_map_room_details, get_response_map_rooms } from './Saga/MapSearch';
import { change_room_list_search_form } from '../RoomList/Saga/RoomList';
//   change_showRight,
//   change_showFlag,
//   change_draggable }from './Sagaes/MapSearch';

const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: false,
    fullscreenControl: false,


  }
}




// const App = (props) =>  (
class App extends Component {
  constructor(props) {
    super(props);
    this.onChildClick = this.onChildClick.bind(this);
  }


  onChildClick = (key, childProps) => {

    // alert(this.props.rent_response_id)
    this.props.get_map_room_details({ tatemono_id: this.props.mapsearch.response_rooms[key].tatemono_id, rent_response_id: this.props.rent_response_id });
    // this.props.onCenterChange([childProps.lat, childProps.lng]);
  }

  onBoundsChange = (center, zoom, bounds, marginBounds, map) => {
    console.log(bounds)

    let room_list_search_form = this.props.roomlist.room_list_search_form

    room_list_search_form.max_lat = bounds[0];
    room_list_search_form.min_lat = bounds[2];
    room_list_search_form.max_lng = bounds[3];
    room_list_search_form.min_lng = bounds[1];

    this.props.change_room_list_search_form(room_list_search_form)

    this.props.get_response_map_rooms();

    // this.props.bounds(bounds);
    // this.props.submit('mapsearchform')

    // this.props.onCenterChange(center);
    // this.props.onZoomChange(zoom);
  }
  render() {

    return (
      <GoogleMapReact

        bootstrapURLKeys={{
          key: 'AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo'
        }}
        defaultCenter={{
          lat: 34.334862,
          lng: 134.038062
        }}
        defaultZoom={16}
        getMapBounds={this.getMapBounds}
        options={createMapOptions}
        draggable={this.props.mapsearch.draggable}
        onBoundsChange={this.onBoundsChange.bind(this)}
        onChildClick={this.onChildClick}


      // onChildMouseDown={this.onChildMouseDown.bind(this)}
      // onChildMouseUp={this.onChildMouseUp.bind(this)}
      // onChildMouseMove={this.onCircleInteraction.bind(this)}
      >


        {
          this.props.mapsearch.response_rooms.length > 0 ? this.props.mapsearch.response_rooms.map((pin, key) => (

            <Pin

              key={key}

              style={{ position: 'absolute', width: 30, height: 30, left: -20, top: -20, cursor: 'pointer', borderRadius: 30 }}

              lat={pin.ido}
              lng={pin.keido}
            >
              <div style={{ position: 'absolute', left: -30, top: 35, whiteSpace: "nowrap", opacity: "0.8", padding: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10, backgroundColor: pin.content ? "#ff6600":"#000", color: "#FFF", fontWeight: "bold", fontSize: 9 }}>{pin.tatemono_name}</div>

            </Pin>
          )) : ""
        }


        {
          this.props.mapsearch.response_rooms.length > 0 ? this.props.mapsearch.response_rooms.map((pin, key) => (

            <Pin

              key={key}

              style={{ position: 'absolute', width: 30, height: 30, left: pin.kanri_corp_no === 1 && pin.is_zero_nine == 1 ? -25 :-20, top: pin.kanri_corp_no === 1 && pin.is_zero_nine == 1 ? -25 :-20, cursor: 'pointer', borderRadius: 30 }}

              lat={pin.ido}
              lng={pin.keido}
            >
              {/* <img src={pin.sub === true ? 'https://img.global-center.co.jp/v1/view/recommendstar.png' : (pin.kanri_corp_no === 1 ? (pin.selected > 0 ? iekanriselected : iekanri) : (pin.kanri_corp_no === 8 || pin.kanri_corp_no === 82 ? (pin.selected > 0 ? ieippanselected : ieippan) : (pin.selected > 0 ? iegyouselected : iegyou)))} style={{ "width": pin.sub === true ? 40 : 30 }} alt="" /> */}
              <img src={pin.kanri_corp_no === 1 && pin.is_zero_nine == 1 ? (pin.selected > 0 ? iezeroselected : iezero) : (pin.kanri_corp_no === 1 ? (pin.selected > 0 ? iekanriselected : iekanri) : (pin.kanri_corp_no === 8 || pin.kanri_corp_no === 82 ? (pin.selected > 0 ? ieippanselected : ieippan) : (pin.selected > 0 ? iegyouselected : iegyou)))} style={{ "width": pin.kanri_corp_no === 1 && pin.is_zero_nine == 1 ? 40 : 30 }} alt="" />

            </Pin>
          )) : ""
        }


      </GoogleMapReact >)

  }
}


const Pin = styled.div``




export default connect(
  ({ mapsearch, roomlist }) => ({ mapsearch, roomlist }),
  {
    get_map_room_details,
    submit,
    change_room_list_search_form,
    get_response_map_rooms,
    // MapSearch
  }
)(App)
