import React, { Component } from 'react';

import { connect } from 'react-redux';

import ContractCreate from './ContractCreate';
import ContractMoneyDisp from './ContractMoneyDisp';
import ContractInfoDisp from './ContractInfoDisp';
import ContractAttention from './ContractAttention';
import ContractCosignerDisp from './ContractCosignerDisp';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import PdfDisplay from './PdfDisplay';
// import CustomerInfoDisp from './_CustomerInfoDisp.tsx_';
import Customer from '../Customers/Index';
import Car from './Car';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Family from './Family';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
// import Button from '@mui/material/Button';
import Button from '@mui/material/Button';
import CancelIcon from '@mui/icons-material/Cancel';
import CardActions from '@mui/material/CardActions';
// import CustomerInfoDispResponse from '../Customers/CustomerInfoDispComponent';
import * as Mail from '../Mail/Saga/Mail';
import * as Customers from '../Customers/Saga/Customers';
import ContractSpecialDisp from './ContractSpecialDisp';
import ContractVer from './ContractVer';
// import CustomerEdit from './CustomerEdit';
import ContractReqEdit from './ContractReqEdit';
import ContractFlow from './ContractFlow';
import ContractEdit from './ContractEdit';
import EmergencyContactEdit from './EmergencyContactEdit';
import ContractSpecialEdit from './ContractSpecialEdit';
import LineComponent from '../Line/LineComponent';
import LineSign from '../Line/LineSign';
// import CustomerInfoEdit from '../Customers/CustomerInfoEditComponent';
import CustomerEdit from './_CustomerEdit.js_';
import Loading from '../Layout/LoadingComponent';
import TextField from '@mui/material/TextField';
import EstimateMonthGridComponent from '../Estimates/EstimateMonthGridComponent';
import EstimateAfterGridComponent from '../Estimates/EstimateAfterGridComponent';
import EstimateOnetimeGridComponent from '../Estimates/EstimateOnetimeGridComponent';
import EstimatesInfoEditComponent from '../Estimates/EstimatesInfoEditComponent';
import EstimatesEtcEdit from '../Estimates/EstimatesEtcEdit';
import Grid from '@mui/material/Grid';
import ContractEtcMoneyDisp from './ContractEtcMoneyDisp';
import CarEdit from './CarEdit';
import ContractEtc from './ContractEtc';
import ContractEtcEdit from './ContractEtcEdit';
import ContractIppan from './ContractIppan';
import ContractIppanEdit from './ContractIppanEdit';
import ContractDocDl from './ContractDocDl';
import ContractReSend from './ContractReSend';

import * as Estimates from '../Estimates/Saga/Estimates';
import * as Contracts from './Saga/Contracts';
import * as Responses from '../ResponseDetail/Saga/Responses';
import { Row, Col } from 'reactstrap';
import ContactsComponent from '../CustomerContacts/ContactsComponent';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import * as Files from '../Files/Saga/Files';
import MailResponseComponent from '../Mail/MailResponseComponent';
import ContractMoneyMonth from './ContractMoneyMonth';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import IconButton from '@mui/material/IconButton';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import withStyles from '@mui/styles/withStyles';
// import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import LineDispByUser from '../Line/LineDispByUser';
import CardHeader from '@mui/material/CardHeader';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { compose } from 'redux'

const styles = (theme) => ({

  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: 20000,
    color: '#fff',
  },

})

const zoomlist=[
  {value:75, label:"75%"},
  {value:100, label:"100%"},
  {value:125, label:"125%"},
  {value:150, label:"150%"},
  {value:200, label:"200%"},
  {value:300, label:"300%"},
  {value:400, label:"400%"},
]

class ContractsComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 125,
      type: props.match ? props.match.params.type : "",
      id: props.match ? props.match.params.id : "",

    };

    {/* 1:申込ファイル 2:社内ファイル 3:公開ファイル 4:契約書添付ファイル 5:契約書添付 */}
    if(this.state.id){
      if(this.state.type == 1){
        this.props.download_disp_application_files({id:this.state.id})//●
      } else if(this.state.type == 2){
        this.props.download_disp_rent_response_docs({id:this.state.id})//●
      } else if(this.state.type == 3){
        this.props.download_disp_limited_public_docs({id:this.state.id})
      } else if(this.state.type == 4){
        this.props.download_disp_contract_files({id:this.state.id, type:0})//●
      } else if(this.state.type == 5){
        this.props.download_disp_rent_response_contract_docs({id:this.state.id})//●
        
      }
    }
    // props.get_estimates({ rentEstimateId: 0, tatemonoId: this.props.tatemonoId, roomId: this.props.roomId });

    // const rent_contract_id = props.responsedetail.rent_contract_id ? props.responsedetail.rent_contract_id : (props.match ? props.match.params.rent_contract_id : props.responsedetail.type === 1 ? 19 : 18);

    this.numPages = this.numPages.bind(this);

    this.handleZoom = this.handleZoom.bind(this);
  }

  componentWillUnmount() {
    this.props.change_contracts_rent_application_doc_uri(null);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }


  handleZoom(type){

    var index = zoomlist.findIndex(a=>a.value == this.state.value)

    if(type == 1){
      this.setState({ value: zoomlist[index - 1].value })
    }else if(type == 2){
      this.setState({ value: zoomlist[index + 1].value })
    }

  }


  render() {
    return (
      <div>
          {/* <PdfReadComponent size={this.state.value}/>  */}
          <div style={{ overflow: 'auto', height: this.props.contracts_doc_extension.indexOf("pdf") != -1 ? window.innerHeight-85 : window.innerHeight-20, textAlign:"center", }}>
            {this.props.contracts_rent_application_doc_uri ? <PdfDisplay scale={this.state.value} rotate={this.state.rotate} page={this.state.page} numPages={this.numPages} /> : ""}
          </div>


          {this.props.contracts_doc_extension.indexOf("pdf") != -1 ? <div style={{textAlign:"center", padding:10, backgroundColor:"white"}}>
            
            {/* <InputRange
            maxValue={20}
            minValue={0}
            draggableTrack={false}
            value={this.state.value}
            allowSameValues={false}
            onChange={value => this.setState({ value })}

          /> */}
            <IconButton
              aria-label="left"
              disabled={this.state.page <= 1}
              onClick={() => this.handleButtonClick(this.state.page - 1)}
              size="large">
              <ChevronLeftIcon />
            </IconButton>
            {this.state.page || 1} / {this.state.numPages || '-'}
            <IconButton
              aria-label="left"
              disabled={this.state.page >= this.state.numPages || !this.state.numPages}
              onClick={() => this.handleButtonClick(this.state.page + 1)}
              size="large">
              <ChevronRightIcon />
            </IconButton>

            <IconButton
              aria-label="left"
              onClick={() => this.handleRotateClick(this.state.rotate - 90)}
              size="large">
              <RotateLeftIcon />
            </IconButton>
            <IconButton
              aria-label="left"
              onClick={() => this.handleRotateClick(this.state.rotate + 90)}
              size="large">
              <RotateRightIcon />
            </IconButton>


            <IconButton
              disabled={!(this.state.value > 75)}
              onClick={(e)=>this.handleZoom(1)}
              style={{marginLeft:15}}
              size="large">
            <RemoveCircleIcon />
            </IconButton>
            <FormControl variant="outlined" style={{ marginTop: 5 }}>
              <InputLabel id="demo-simple-select-outlined-label">ズーム</InputLabel>
              <Select
                style={{ width: 90, }}
                value={this.state.value}
                onChange={(e) => this.setState({ value: e.target.value })}
                label="ズーム"
                margin='dense'
              >
                {zoomlist.map((value, key) => <MenuItem key={key} value={value.value}>{value.label}</MenuItem>)}

              </Select>
            </FormControl>
            <IconButton
              disabled={!(this.state.value < 400)}
              onClick={(e)=>this.handleZoom(2)}
              size="large">
            <AddCircleIcon />
            </IconButton>
          </div> : ""}
      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {
    customer_contacts_calendar: state.customerContactsReducer.customer_contacts_calendar,
    estimate_loading: state.estimates.estimate_loading,
    customerdetail: state.customersReducer.customerdetail,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    contract_detail_edit_disp: state.estimates.contract_detail_edit_disp,
    estimate_edit_disp: state.estimates.estimate_edit_disp,
    contracts_rent_application_doc_uri: state.contracts.contracts_rent_application_doc_uri,
    responseid: state.responsesReducer.responseid,
    responsedetail: state.responsesReducer.responsedetail,
    line_user_details: state.line.line_user_details,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_editor_disp: state.contracts.rent_contract_editor_disp,
    rent_emergency_editor_disp: state.contracts.rent_emergency_editor_disp,
    rent_m_corporate_editor: state.contracts.rent_m_corporate_editor,
    rent_contract_workflows: state.contracts.rent_contract_workflows,

    rent_m_reasons: state.contracts.rent_m_reasons,
    rent_m_schools: state.contracts.rent_m_schools,
    check_rent_customer_info: state.contracts.check_rent_customer_info,
    tm_kanri_corp_list: state.contracts.tm_kanri_corp_list,

    m_penalties: state.contracts.m_penalties,
    rent_m_contract_types: state.contracts.rent_m_contract_types,
    rent_contract_etc_editor_disp: state.contracts.rent_contract_etc_editor_disp,
    rent_contract_special_editor_disp: state.contracts.rent_contract_special_editor_disp,
    rent_contract_ippan_editor_disp: state.contracts.rent_contract_ippan_editor_disp,
    rent_contract_car_editor_disp: state.contracts.rent_contract_car_editor_disp,
    response_mail_loading: state.mailReducer.response_mail_loading,
    customer_contacts_loading: state.customerContactsReducer.response_mail_loading,
    line_loading: state.line.line_loading,
    flie_loading: state.files.flie_loading,
    customerloding: state.customersReducer.customerloding,
    customeredit: state.customersReducer.customeredit,
    contracts_backdrop: state.contracts.contracts_backdrop,
    line_open_flg: state.line.line_open_flg,
    lines: state.line.lines,
    contracts_doc_extension: state.contracts.contracts_doc_extension,
    line_users: state.line.line_users,
    response_Tabs:state.responsesReducer.response_tabs,
    file_viewer_dialog_disp: state.contracts.file_viewer_dialog_disp,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_contracts_rent_application_doc_uri(state) {
      dispatch(Contracts.change_contracts_rent_application_doc_uri(state))
    },
    download_disp_application_files(state) {
        dispatch(Files.download_disp_application_files(state))
    },
    download_disp_rent_response_docs(state) {
        dispatch(Files.download_disp_rent_response_docs(state))
    },
    download_disp_limited_public_docs(state) {
        dispatch(Files.download_disp_limited_public_docs(state))
    },
    download_disp_contract_files(state) {
        dispatch(Files.download_disp_contract_files(state))
    },
    download_disp_contract_files(state) {
        dispatch(Files.download_disp_contract_files(state))
    },
    download_disp_rent_response_contract_docs(state) {
        dispatch(Files.download_disp_rent_response_contract_docs(state))
    },
  };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ContractsComponent);
