
const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {
    // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentContractChecks/`;

    // const url = `http://localhost:5000/v1/rent/contract/checks`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/contract/checks`
    // const url = `https://localhost:44341/api/RentContractChecks/`+values;

    const token = authentication.getAccessToken();

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payloadchecks => ({ payloadchecks }))
        .catch(errorchecks => ({ errorchecks }));

}

