
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, take, select } from 'redux-saga/effects';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';

import * as RentMCorporationsApi from '../Api/RentMCorporationsApi';
import * as RentCorporateContactsApi from '../Api/RentCorporateContactsApi';
import * as RentResponsesApi from '../Api/RentResponsesApi';
import * as TMZipAddressApi from '../Api/TMZipAddressApi';
import * as RentCorporateDocsApi from '../Api/RentCorporateDocsApi';
import * as RentCorporateStaffsApi from '../Api/RentCorporateStaffsApi';
import * as RentCorporateOfficesApi from '../Api/RentCorporateOfficesApi';
import * as RentMAgentsApi from '../Api/RentMAgentsApi';

import * as Customers from '../../Customers/Saga/Customers';

import moment from 'moment';
import StateManager from 'react-select';

export interface corporationState {
    // rent_contracts_list: any;
    // rent_sales_list: any;

    rent_m_corporates_list: any;
    rent_m_corporates_list_count: any;
    rent_m_corporates_list_search_form: any;
    rent_m_corporates_list_search: any;
    rent_m_corporates_list_backdrop: boolean;
    rent_m_corporates: any;
    rent_m_corporates_top_open: boolean;
    rent_m_corporates_detail_open: boolean;


    search_data: any;
    search_data_contacts: any;
    search_data_replace: any;

    rent_responses_response_list: any;
    rent_responses_coming_list: any;
    rent_responses_contract_list: any;
    rent_corporate_contract_by_year: any;
    rent_corporate_contract_by_month: any;
    rent_responses_contract_count: any;

    rent_corporate_contacts_list: any;
    rent_corporate_contacts: any;

    rent_corporate_contacts_all_list: any;
    rent_corporate_contacts_all_list_search: any;
    rent_corporate_contacts_all: any;

    rent_corporate_docs_list: any;
    rent_corporate_docs: any;

    rent_corporate_staffs_list: any;
    rent_corporate_staffs: any;

    rent_corporate_offices_list: any;
    rent_corporate_offices: any;

    rent_responses_list: any;


    rent_m_corporates_list_replace: any;
    rent_m_corporates_list_replace_search: any
    rent_customer_list_replace: any;
    rent_customer_list_replace_search: any;

    rent_m_agents_list: any;

    rent_corporate_contacts_tab_list: any;

    corporate_loading: boolean;


    rent_responses_corporate_count_list: any;
    rent_responses_corporate_count_list_search_form: any;
    rent_m_corporate_all_list: any;
    rent_responses_corporate_count_list_loading: boolean;

}

const initialState: corporationState = {

    // rent_contracts_list: [],
    // rent_sales_list: [],
    rent_m_corporates_list: [],
    rent_m_corporates_list_count: 0,
    rent_m_corporates_list_search_form: {name: "", partnership: false, count:100},
    rent_m_corporates_list_search: [],
    rent_m_corporates_list_backdrop: false,
    rent_m_corporates: [],
    rent_m_corporates_top_open: false,
    rent_m_corporates_detail_open: false,
    search_data: { name: "", },
    search_data_contacts: { name: "", user_id: "", section_id:"", start_date: moment().startOf('months').format("YYYY-MM-DD"), end_date: moment().endOf('months').format("YYYY-MM-DD") },
    search_data_replace: { m_corporate_name: "", select_m_corporate_id:"", customer_name: "", },

    rent_responses_response_list: [],
    rent_responses_coming_list: [],
    rent_responses_contract_list: [],
    rent_corporate_contract_by_year: [],
    rent_corporate_contract_by_month: [],
    rent_responses_contract_count: [],

    rent_corporate_contacts_list: [],
    rent_corporate_contacts: [],

    rent_corporate_contacts_all_list: [],
    rent_corporate_contacts_all_list_search: [],
    rent_corporate_contacts_all: [],

    rent_corporate_docs_list: [],
    rent_corporate_docs: [],

    rent_corporate_staffs_list: [],
    rent_corporate_staffs: [],

    rent_corporate_offices_list: [],
    rent_corporate_offices: [],

    rent_responses_list: [],


    rent_m_corporates_list_replace: [],
    rent_m_corporates_list_replace_search: [],
    rent_customer_list_replace: [],
    rent_customer_list_replace_search: [],

    rent_m_agents_list: [],

    rent_corporate_contacts_tab_list: [],

    corporate_loading: false,

    rent_responses_corporate_count_list: [],
    rent_responses_corporate_count_list_search_form: { 
        rent_m_corporate_id: "", 
        start_date: null,
        end_date: null,
        contract_start_date: moment("2010/01/01").startOf('months').format("YYYY-MM-DD"), 
        contract_end_date: moment().endOf('months').format("YYYY-MM-DD"),
    },
    rent_m_corporate_all_list:[],
    rent_responses_corporate_count_list_loading: false,
    


    // m_corporations: any;,
    // users: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();

export const change_search_data = actionCreator<any>('CHANGE_SEARCH_DATA');
export const change_search_data_contacts = actionCreator<any>('CHANGE_SEARCH_DATA_CONTACTS');
export const change_search_data_replace = actionCreator<any>('CHANGE_SEARCH_DATA_REPLACE');
export const get_rent_m_corporates_list = actionCreator('GET_RENT_M_CORPORATES_LIST');
export const change_rent_m_corporates_list = actionCreator('CHANGE_RENT_M_CORPORATES_LIST');
export const change_rent_m_corporates_list_count = actionCreator('CHANGE_RENT_M_CORPORATES_LIST_COUNT');
export const change_rent_m_corporates_list_search_form = actionCreator('CHANGE_RENT_M_CORPORATES_LIST_SEARCH_FORM');
export const get_rent_m_corporates_list_search = actionCreator('GET_RENT_M_CORPORATES_LIST_SEARCH');
export const change_rent_m_corporates_list_search = actionCreator('CHANGE_RENT_M_CORPORATES_LIST_SEARCH');
export const change_rent_m_corporates_list_backdrop = actionCreator<boolean>('CHANGE_RENT_M_CORPORATES_LIST_BACKDROP');
export const get_rent_m_corporates_list_csv = actionCreator('GET_RENT_M_CORPORATES_LIST_CSV');


export const change_rent_m_corporates_top_open = actionCreator<boolean>('CHANGE_RENT_M_CORPORATES_TOP_OPEN');
export const change_rent_m_corporates_detail_open = actionCreator<boolean>('CHANGE_RENT_M_CORPORATES_DETAIL_OPEN');

export const change_rent_m_corporates = actionCreator<any>('CHANGE_RENT_M_CORPORATES');
export const insert_rent_m_corporates = actionCreator('INSERT_RENT_M_CORPORATES');
export const update_rent_m_corporates = actionCreator('UPDATE_RENT_M_CORPORATES');
export const update_rent_m_corporates_partnership = actionCreator('UPDATE_RENT_M_CORPORATES_PARTNERSHIP');
export const set_rent_m_corporates = actionCreator('SET_RENT_M_CORPORATES');
export const get_rent_m_corporates_zip_address = actionCreator<any>('GET_RENT_M_CORPORATES_ZIP_ADDRESS');

export const get_rent_corporate_contacts_list = actionCreator('GET_RENT_CORPORATE_CONTACTS_LIST');
export const change_rent_corporate_contacts_list = actionCreator('CHANGE_RENT_CORPORATE_CONTACTS_LIST');

export const set_rent_corporate_contacts = actionCreator('SET_RENT_CORPORATE_CONTACTS');
export const change_rent_corporate_contacts = actionCreator<any>('CHANGE_RENT_CORPORATE_CONTACTS');
export const insert_rent_corporate_contacts = actionCreator('INSERT_RENT_CORPORATE_CONTACTS');
export const update_rent_corporate_contacts = actionCreator('UPDATE_RENT_CORPORATE_CONTACTS');

export const get_rent_responses_response_list = actionCreator('GET_RENT_RESPONSES_RESPONSE_LIST');
export const get_rent_responses_coming_list = actionCreator('GET_RENT_RESPONSES_COMING_LIST');
export const get_rent_responses_contract_list = actionCreator('GET_RENT_RESPONSES_CONTRACT_LIST');
export const change_rent_responses_response_list = actionCreator<any>('CHANGE_RENT_RESPONSES_RESPONSE_LIST');
export const change_rent_responses_coming_list = actionCreator('CHANGE_RENT_RESPONSES_COMING_LIST');
export const change_rent_responses_contract_list = actionCreator('CHANGE_RENT_RESPONSES_CONTRACT_LIST');

export const get_rent_responses_contract_count = actionCreator('GET_RENT_RESPONSES_CONTRACT_COUNT');
export const change_rent_responses_contract_count = actionCreator<any>('CHANGE_RENT_RESPONSES_CONTRACT_COUNT');

export const change_rent_corporate_contract_by_year = actionCreator<any>('CHANGE_RENT_CORPORATE_CONTRACT_BY_YEAR');
export const change_rent_corporate_contract_by_month = actionCreator<any>('CHANGE_RENT_CORPORATE_CONTRACT_BY_MONTH');

export const get_rent_corporate_docs_list = actionCreator('GET_RENT_CORPORATE_DOCS_LIST');
export const change_rent_corporate_docs_list = actionCreator('CHANGE_RENT_CORPORATE_DOCS_LIST');

export const set_rent_corporate_docs = actionCreator('SET_RENT_CORPORATE_DOCS');
export const change_rent_corporate_docs = actionCreator<any>('CHANGE_RENT_CORPORATE_DOCS');
export const insert_rent_corporate_docs = actionCreator('INSERT_RENT_CORPORATE_DOCS');
export const update_rent_corporate_docs = actionCreator('UPDATE_RENT_CORPORATE_DOCS');
export const download_rent_corporate_docs = actionCreator('DOWNLOAD_RENT_CORPORATE_DOCS');

export const get_rent_corporate_staffs_list = actionCreator('GET_RENT_CORPORATE_STAFFS_LIST');
export const change_rent_corporate_staffs_list = actionCreator('CHANGE_RENT_CORPORATE_STAFFS_LIST');

export const set_rent_corporate_staffs = actionCreator('SET_RENT_CORPORATE_STAFFS');
export const change_rent_corporate_staffs = actionCreator<any>('CHANGE_RENT_CORPORATE_STAFFS');
export const insert_rent_corporate_staffs = actionCreator('INSERT_RENT_CORPORATE_STAFFS');
export const update_rent_corporate_staffs = actionCreator('UPDATE_RENT_CORPORATE_STAFFS');

export const get_rent_corporate_offices_list = actionCreator('GET_RENT_CORPORATE_OFFICES_LIST');
export const change_rent_corporate_offices_list = actionCreator('CHANGE_RENT_CORPORATE_OFFICES_LIST');

export const set_rent_corporate_offices = actionCreator('SET_RENT_CORPORATE_OFFICES');
export const change_rent_corporate_offices = actionCreator<any>('CHANGE_RENT_CORPORATE_OFFICES');
export const insert_rent_corporate_offices = actionCreator('INSERT_RENT_CORPORATE_OFFICES');
export const update_rent_corporate_offices = actionCreator('UPDATE_RENT_CORPORATE_OFFICES');

export const get_rent_corporate_contacts_all_list = actionCreator('GET_RENT_CORPORATE_CONTACTS_ALL_LIST');
export const change_rent_corporate_contacts_all_list = actionCreator('CHANGE_RENT_CORPORATE_CONTACTS_ALL_LIST');
export const get_rent_corporate_contacts_all_list_search = actionCreator('GET_RENT_CORPORATE_CONTACTS_ALL_LIST_SEARCH');
export const change_rent_corporate_contacts_all_list_search = actionCreator('CHANGE_RENT_CORPORATE_CONTACTS_ALL_LIST_SEARCH');

export const get_rent_responses_list = actionCreator<any>('GET_RENT_RESPONSES_LIST');
export const change_rent_responses_list = actionCreator('CHANGE_RENT_RESPONSES_LIST');

export const set_rent_corporate_contacts_all = actionCreator('SET_RENT_CORPORATE_CONTACTS_ALL');
// export const change_rent_corporate_contacts_all = actionCreator<any>('CHANGE_RENT_CORPORATE_CONTACTS_ALL');
export const insert_rent_corporate_contacts_all = actionCreator('INSERT_RENT_CORPORATE_CONTACTS_ALL');
export const update_rent_corporate_contacts_all = actionCreator('UPDATE_RENT_CORPORATE_CONTACTS_ALL');

export const get_rent_m_corporates_list_replace = actionCreator('GET_RENT_M_CORPORATES_LIST_REPLACE');
export const change_rent_m_corporates_list_replace = actionCreator('CHANGE_RENT_M_CORPORATES_LIST_REPLACE');
export const get_rent_m_corporates_list_replace_search = actionCreator('GET_RENT_M_CORPORATES_LIST_REPLACE_SEARCH');
export const change_rent_m_corporates_list_replace_search = actionCreator('CHANGE_RENT_M_CORPORATES_LIST_REPLACE_SEARCH');
export const get_rent_customer_list_replace = actionCreator('GET_RENT_CUSTOMER_LIST_REPLACE');
export const change_rent_customer_list_replace = actionCreator<any>('CHANGE_RENT_CUSTOMER_LIST_REPLACE');
export const update_rent_customer_list_replace = actionCreator<any>('UPDATE_RENT_CUSTOMER_LIST_REPLACE');
export const get_rent_customer_list_replace_search = actionCreator('GET_RENT_CUSTOMER_LIST_REPLACE_SEARCH');
export const change_rent_customer_list_replace_search = actionCreator<any>('CHANGE_RENT_CUSTOMER_LIST_REPLACE_SEARCH');

export const get_rent_m_agents_list = actionCreator('GET_RENT_M_AGENTS_LIST');
export const change_rent_m_agents_list = actionCreator('CHANGE_RENT_M_AGENTS_LIST');

export const change_rent_corporate_contacts_tab_list = actionCreator('CHANGE_RENT_CORPORATE_CONTACTS_TAB_LIST');

export const change_corporate_loading = actionCreator<boolean>('CHANGE_CORPORATE_LOADING');

export const get_rent_responses_corporate_count_list = actionCreator('GET_RENT_RESPONSES_CORPORATE_COUNT_LIST');
export const change_rent_responses_corporate_count_list = actionCreator<any>('CHANGE_RENT_RESPONSES_CORPORATE_COUNT_LIST');
export const change_rent_responses_corporate_count_list_search_form = actionCreator('CHANGE_RENT_RESPONSES_CORPORATE_COUNT_LIST_SEARCH_FORM');
export const get_rent_m_corporate_all_list = actionCreator('GET_RENT_M_CORPORATE_ALL_LIST');
export const change_rent_m_corporate_all_list = actionCreator('CHANGE_RENT_M_CORPORATE_ALL_LIST');
export const change_rent_responses_corporate_count_list_loading = actionCreator<any>('CHANGE_RENT_RESPONSES_CORPORATE_COUNT_LIST_LOADING');



export const corporationReducer = reducerWithInitialState(initialState)
    .case(change_search_data, (state, payload) => ({ ...state, search_data: payload }))
    .case(change_search_data_contacts, (state, payload) => ({ ...state, search_data_contacts: payload }))
    .case(change_search_data_replace, (state, payload) => ({ ...state, search_data_replace: payload }))
    .case(change_rent_m_corporates_list, (state, payload) => ({ ...state, rent_m_corporates_list: payload }))
    .case(change_rent_m_corporates_list_count, (state, payload) => ({ ...state, rent_m_corporates_list_count: payload }))
    .case(change_rent_m_corporates_list_search_form, (state, payload) => ({ ...state, rent_m_corporates_list_search_form: payload }))
    .case(change_rent_m_corporates_list_search, (state, payload) => ({ ...state, rent_m_corporates_list_search: payload }))
    .case(change_rent_m_corporates_list_backdrop, (state, payload) => ({ ...state, rent_m_corporates_list_backdrop: payload }))
    .case(change_rent_m_corporates, (state, payload) => ({ ...state, rent_m_corporates: payload }))
    .case(change_rent_m_corporates_top_open, (state, payload) => ({ ...state, rent_m_corporates_top_open: payload }))
    .case(change_rent_m_corporates_detail_open, (state, payload) => ({ ...state, rent_m_corporates_detail_open: payload }))
    .case(change_rent_corporate_contacts_list, (state, payload) => ({ ...state, rent_corporate_contacts_list: payload }))
    .case(change_rent_corporate_contacts, (state, payload) => ({ ...state, rent_corporate_contacts: payload }))
    .case(change_rent_corporate_staffs_list, (state, payload) => ({ ...state, rent_corporate_staffs_list: payload }))
    .case(change_rent_corporate_staffs, (state, payload) => ({ ...state, rent_corporate_staffs: payload }))
    .case(change_rent_corporate_offices_list, (state, payload) => ({ ...state, rent_corporate_offices_list: payload }))
    .case(change_rent_corporate_offices, (state, payload) => ({ ...state, rent_corporate_offices: payload }))
    .case(change_rent_responses_list, (state, payload) => ({ ...state, rent_responses_list: payload }))
    .case(change_rent_corporate_contacts_all_list, (state, payload) => ({ ...state, rent_corporate_contacts_all_list: payload }))
    .case(change_rent_corporate_contacts_all_list_search, (state, payload) => ({ ...state, rent_corporate_contacts_all_list_search: payload }))
    // .case(change_rent_corporate_contacts_all, (state, payload) => ({ ...state, rent_corporate_contacts_all: payload }))
    .case(change_rent_responses_response_list, (state, payload) => ({ ...state, rent_responses_response_list: payload }))
    .case(change_rent_responses_coming_list, (state, payload) => ({ ...state, rent_responses_coming_list: payload }))
    .case(change_rent_responses_contract_list, (state, payload) => ({ ...state, rent_responses_contract_list: payload }))
    .case(change_rent_responses_contract_count, (state, payload) => ({ ...state, rent_responses_contract_count: payload }))
    .case(change_rent_corporate_contract_by_year, (state, payload) => ({ ...state, rent_corporate_contract_by_year: payload }))
    .case(change_rent_corporate_contract_by_month, (state, payload) => ({ ...state, rent_corporate_contract_by_month: payload }))

    .case(change_rent_corporate_docs_list, (state, payload) => ({ ...state, rent_corporate_docs_list: payload }))
    .case(change_rent_corporate_docs, (state, payload) => ({ ...state, rent_corporate_docs: payload }))

    .case(change_rent_m_corporates_list_replace, (state, payload) => ({ ...state, rent_m_corporates_list_replace: payload }))
    .case(change_rent_m_corporates_list_replace_search, (state, payload) => ({ ...state, rent_m_corporates_list_replace_search: payload }))
    .case(change_rent_customer_list_replace, (state, payload) => ({ ...state, rent_customer_list_replace: payload }))
    .case(change_rent_customer_list_replace_search, (state, payload) => ({ ...state, rent_customer_list_replace_search: payload }))

    .case(change_rent_m_agents_list, (state, payload) => ({ ...state, rent_m_agents_list: payload }))

    .case(change_rent_corporate_contacts_tab_list, (state, payload) => ({ ...state, rent_corporate_contacts_tab_list: payload }))

    .case(change_corporate_loading, (state, payload) => ({ ...state, corporate_loading: payload }))

    .case(change_rent_responses_corporate_count_list, (state, payload) => ({ ...state, rent_responses_corporate_count_list: payload }))
    .case(change_rent_m_corporate_all_list, (state, payload) => ({ ...state, rent_m_corporate_all_list: payload }))
    .case(change_rent_responses_corporate_count_list_loading, (state, payload) => ({ ...state, rent_responses_corporate_count_list_loading: payload }))

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const corporationSaga = [
    takeEvery('GET_RENT_M_CORPORATES_LIST', handle_get_rent_m_corporates_list),
    takeEvery('GET_RENT_M_CORPORATES_LIST_SEARCH', handle_get_rent_m_corporates_list_search),
    takeEvery('UPDATE_RENT_M_CORPORATES', handle_update_rent_m_corporates),
    takeEvery('UPDATE_RENT_M_CORPORATES_PARTNERSHIP', handle_update_rent_m_corporates_partnership),
    takeEvery('INSERT_RENT_M_CORPORATES', handle_insert_rent_m_corporates),
    takeEvery('GET_RENT_M_CORPORATES_LIST_CSV', handle_get_rent_m_corporates_list_csv),
    takeEvery('SET_RENT_M_CORPORATES', handle_set_rent_m_corporates),
    takeEvery('GET_RENT_M_CORPORATES_ZIP_ADDRESS', handle_get_rent_m_corporates_zip_address),

    takeEvery('GET_RENT_CORPORATE_CONTACTS_LIST', handle_get_rent_corporate_contacts_list),
    takeEvery('SET_RENT_CORPORATE_CONTACTS', handle_set_rent_corporate_contacts),
    takeEvery('INSERT_RENT_CORPORATE_CONTACTS', handle_insert_rent_corporate_contacts),
    takeEvery('UPDATE_RENT_CORPORATE_CONTACTS', handle_update_rent_corporate_contacts),

    takeEvery('GET_RENT_CORPORATE_STAFFS_LIST', handle_get_rent_corporate_staffs_list),
    takeEvery('SET_RENT_CORPORATE_STAFFS', handle_set_rent_corporate_staffs),
    takeEvery('INSERT_RENT_CORPORATE_STAFFS', handle_insert_rent_corporate_staffs),
    takeEvery('UPDATE_RENT_CORPORATE_STAFFS', handle_update_rent_corporate_staffs),

    takeEvery('GET_RENT_CORPORATE_OFFICES_LIST', handle_get_rent_corporate_offices_list),
    takeEvery('SET_RENT_CORPORATE_OFFICES', handle_set_rent_corporate_offices),
    takeEvery('INSERT_RENT_CORPORATE_OFFICES', handle_insert_rent_corporate_offices),
    takeEvery('UPDATE_RENT_CORPORATE_OFFICES', handle_update_rent_corporate_offices),

    takeEvery('GET_RENT_RESPONSES_LIST', handle_get_rent_responses_list),


    takeEvery('GET_RENT_CORPORATE_CONTACTS_ALL_LIST', handle_get_rent_corporate_contacts_all_list),
    takeEvery('GET_RENT_CORPORATE_CONTACTS_ALL_LIST_SEARCH', handle_get_rent_corporate_contacts_all_list_search),
    takeEvery('SET_RENT_CORPORATE_CONTACTS_ALL', handle_set_rent_corporate_contacts_all),
    takeEvery('INSERT_RENT_CORPORATE_CONTACTS_ALL', handle_insert_rent_corporate_contacts_all),
    takeEvery('UPDATE_RENT_CORPORATE_CONTACTS_ALL', handle_update_rent_corporate_contacts_all),

    takeEvery('GET_RENT_RESPONSES_RESPONSE_LIST', handle_get_rent_responses_response_list),
    takeEvery('GET_RENT_RESPONSES_COMING_LIST', handle_get_rent_responses_coming_list),
    takeEvery('GET_RENT_RESPONSES_CONTRACT_LIST', handle_get_rent_responses_contract_list),
    takeEvery('GET_RENT_RESPONSES_CONTRACT_COUNT', handle_get_rent_responses_contract_count),

    takeEvery('GET_RENT_CORPORATE_DOCS_LIST', handle_get_rent_corporate_docs_list),
    takeEvery('SET_RENT_CORPORATE_DOCS', handle_set_rent_corporate_docs),
    takeEvery('INSERT_RENT_CORPORATE_DOCS', handle_insert_rent_corporate_docs),
    takeEvery('UPDATE_RENT_CORPORATE_DOCS', handle_update_rent_corporate_docs),
    takeEvery('DOWNLOAD_RENT_CORPORATE_DOCS', handle_download_rent_corporate_docs),

    takeEvery('GET_RENT_M_CORPORATES_LIST_REPLACE', handle_get_rent_m_corporates_list_replace),
    takeEvery('GET_RENT_M_CORPORATES_LIST_REPLACE_SEARCH', handle_get_rent_m_corporates_list_replace_search),
    takeEvery('GET_RENT_CUSTOMER_LIST_REPLACE', handle_get_rent_customer_list_replace),
    takeEvery('UPDATE_RENT_CUSTOMER_LIST_REPLACE', handle_update_rent_customer_list_replace),
    takeEvery('GET_RENT_CUSTOMER_LIST_REPLACE_SEARCH', handle_get_rent_customer_list_replace_search),

    takeEvery('GET_RENT_M_AGENTS_LIST', handle_get_rent_m_agents_list),

    takeEvery('GET_RENT_RESPONSES_CORPORATE_COUNT_LIST', handle_get_rent_responses_corporate_count_list),
    takeEvery('GET_RENT_M_CORPORATE_ALL_LIST', handle_get_rent_m_corporate_all_list),

];
// export default masterSaga;


// 法人リスト
function* handle_get_rent_m_corporates_list(action: any): any {

    yield put(change_rent_m_corporates_list_backdrop(true))

    const state = yield select();

    let rent_m_corporates_list_search_form = state.corporation.rent_m_corporates_list_search_form

    const { payload, error } = yield call(RentMCorporationsApi.list, rent_m_corporates_list_search_form);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert("2")
        // 成功時アクション呼び出し
        yield put(change_rent_m_corporates_list(payload.list))
        yield put(change_rent_m_corporates_list_count(payload.count))
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }
    yield put(change_rent_m_corporates_list_backdrop(false))

}
// 検索
function* handle_get_rent_m_corporates_list_search(action: any): any {

    const state = yield select();

    // 文字列が一致するか
    // let rent_m_corporates_list_base=  state.corporation.rent_m_corporates_list.filter((a:any)=>{
    //     if(a.name.indexOf(action.payload) != -1) return true
    // })
    let rent_m_corporates_list_base = state.corporation.rent_m_corporates_list

    let search_data = state.corporation.search_data

    if (search_data.name) {
        // alert(search_data.name)
        // rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => a.name.indexOf(search_data.name))//名前
        rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => (a.name && a.name.indexOf(search_data.name) != -1) || (a.kana && a.kana.indexOf(search_data.name) != -1) || (a.id && a.id == search_data.name))//物件名〇
    }

    yield put(change_rent_m_corporates_list_search(rent_m_corporates_list_base))

}
// オーナーリストCSV用
function* handle_get_rent_m_corporates_list_csv(action: any): any {

    yield put(change_rent_m_corporates_list_backdrop(true))

    const state = yield select();

    let rent_m_corporates_list_search_form = state.corporation.rent_m_corporates_list_search_form

    const { payload, error } = yield call(RentMCorporationsApi.list_csv, rent_m_corporates_list_search_form);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // yield put(change_tm_owner_list_csv(payload.list))
        // yield put(change_tm_owner_list_csv_disp(true))

        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = "法人リスト.csv";
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();

        // yield put(change_tm_owner_list_backdrop(false))

    } else {
        // yield put(change_tm_owner_list_backdrop(false))
    }

    yield put(change_rent_m_corporates_list_backdrop(false))

}
// 法人選択時
function* handle_set_rent_m_corporates(action: any): any {

    yield put(change_corporate_loading(false))

    const { payload, error } = yield call(RentMCorporationsApi.detail, action.payload);

    yield put(change_rent_responses_response_list(null))

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        
        // 成功時アクション呼び出し
        yield put(change_rent_m_corporates(payload))
        yield put(get_rent_corporate_contacts_list(action.payload))
        yield put(get_rent_responses_response_list(action.payload))
        yield put(get_rent_responses_coming_list(action.payload))
        yield put(get_rent_responses_contract_list(action.payload))
        yield put(get_rent_responses_contract_count(action.payload))
        yield put(get_rent_corporate_docs_list(action.payload))
        yield put(get_rent_corporate_staffs_list(action.payload))
        yield put(get_rent_corporate_offices_list(action.payload))

        yield put(get_rent_responses_list(action.payload))

        
        
    } else {

    }


}
// 基本情報更新
function* handle_update_rent_m_corporates(action: any): any {

    // alert(action.payload)

    const state = yield select();

    let rent_m_corporates = state.corporation.rent_m_corporates

    const { payload, error } = yield call(RentMCorporationsApi.update, rent_m_corporates);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_m_corporates_list = state.corporation.rent_m_corporates_list

        // 一覧の情報更新
        rent_m_corporates_list = rent_m_corporates_list.map(function (value: any) {
            if (value.id === payload.id) {
                // alert(payload.title)
                value.kubun = payload.kubun //1本社　2支店　3その他
                value.name = payload.name
                value.kana = payload.kana
                value.post = payload.post
                value.add1 = payload.add1
                value.add2 = payload.add2
                value.tel1 = payload.tel1
                value.url = payload.url
                value.capital = payload.capital
                value.establish = payload.establish
                value.start_of_business = payload.start_of_business
                value.president = payload.president
                value.position = payload.position
                value.office = payload.office
                value.employee = payload.employee
                value.vision = payload.vision
                value.lat = payload.lat
                value.lng = payload.lng
                value.is_display = payload.is_display
                value.remarks_person = payload.remarks_person
                value.remarks_attention = payload.remarks_attention
                value.remarks_request = payload.remarks_request
                value.remarks_information = payload.remarks_information

                value.updated_user_id = payload.updated_user_id
                value.updated_at = payload.updated_at
            }
            return value
        })

        //一覧から削除
        let result = rent_m_corporates_list.filter((value: any) => {
            return (!(value.id == payload.id && value.is_display == true));
        });
        rent_m_corporates_list = result

        // 成功時アクション呼び出し
        // ブログ一覧更新
        yield put(change_rent_m_corporates_list(rent_m_corporates_list))
        yield put(get_rent_m_corporates_list_search())
        // 情報更新
        yield put(change_rent_m_corporates(payload))



    } else {

    }
}
// 基本情報更新(提携)
function* handle_update_rent_m_corporates_partnership(action: any): any {

    // alert(action.payload)

    const state = yield select();

    let rent_m_corporates = state.corporation.rent_m_corporates

    const { payload, error } = yield call(RentMCorporationsApi.update_partnership, rent_m_corporates);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_m_corporates_list = state.corporation.rent_m_corporates_list

        // 一覧の情報更新
        rent_m_corporates_list = rent_m_corporates_list.map(function (value: any) {
            if (value.id === payload.id) {
                // alert(payload.id)
                value.referral_fee = payload.referral_fee
                value.referral_fee_unit = payload.referral_fee_unit
                value.referral_fee_subject = payload.referral_fee_subject
                value.manage_referral_fee = payload.manage_referral_fee
                value.manage_referral_fee_unit = payload.manage_referral_fee_unit
                value.manage_referral_fee_subject = payload.manage_referral_fee_subject
                value.agent_charge = payload.agent_charge
                value.agent_charge_unit = payload.agent_charge_unit
                value.agent_charge_subject = payload.agent_charge_subject
                value.manage_agent_charge = payload.manage_agent_charge
                value.manage_agent_charge_unit = payload.manage_agent_charge_unit
                value.manage_agent_charge_subject = payload.manage_agent_charge_subject
                value.be_discounted = payload.be_discounted
                value.partnership_remarks = payload.partnership_remarks
                value.partnership_at = payload.partnership_at
                value.updated_user_id = payload.updated_user_id
                value.updated_at = payload.updated_at
            }
            return value
        })
        // 成功時アクション呼び出し
        // ブログ一覧更新
        yield put(change_rent_m_corporates_list(rent_m_corporates_list))
        yield put(get_rent_m_corporates_list_search())
        // 情報更新
        yield put(change_rent_m_corporates(payload))



    } else {

    }
}
// 基本情報新規
function* handle_insert_rent_m_corporates(action: any): any {

    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let rent_m_corporates = state.corporation.rent_m_corporates
    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(RentMCorporationsApi.insert, rent_m_corporates);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)
        let rent_m_corporates_list = state.corporation.rent_m_corporates_list

        let list = rent_m_corporates_list.concat(payload)
        // list.sort(function(a:any,b:any){
        //     if(moment(a.date) > moment(b.date)) return -1;
        //     if(moment(a.date) < moment(b.date)) return 1;
        //     return 0;
        // });
        rent_m_corporates.id = payload.id


        // // 成功時アクション呼び出し
        yield put(change_rent_m_corporates_list(list))
        yield put(get_rent_m_corporates_list_search())
        // 情報更新
        yield put(change_rent_m_corporates(rent_m_corporates))




    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}

// 営業対応リスト
function* handle_get_rent_corporate_contacts_list(action: any) {

    const { payload, error } = yield call(RentCorporateContactsApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_corporate_contacts_list(payload))
        yield put(change_rent_corporate_contacts_tab_list(payload.filter((a:any)=>a.rival != null && a.rival != "")))
        
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }
}
// 営業対応リスト選択時
function* handle_set_rent_corporate_contacts(action: any): any {

    const state = yield select();

    let rent_corporate_contacts_base = state.corporation.rent_corporate_contacts_list.filter((a: any) => a.id === action.payload)[0]

    let rent_corporate_contacts: any = {}
    rent_corporate_contacts.id = rent_corporate_contacts_base.id
    rent_corporate_contacts.rent_m_corporate_id = rent_corporate_contacts_base.rent_m_corporate_id
    rent_corporate_contacts.section_id = rent_corporate_contacts_base.section_id
    rent_corporate_contacts.user_id = rent_corporate_contacts_base.user_id
    rent_corporate_contacts.date = rent_corporate_contacts_base.date
    rent_corporate_contacts.start_at = rent_corporate_contacts_base.start_at ? moment(rent_corporate_contacts_base.start_at).utc().format('HH:mm')  : ""
    rent_corporate_contacts.start_at = rent_corporate_contacts.start_at == "00:00" ? "" : rent_corporate_contacts.start_at 
    rent_corporate_contacts.end_at = rent_corporate_contacts_base.end_at ? moment(rent_corporate_contacts_base.end_at).utc().format('HH:mm')  : ""
    rent_corporate_contacts.end_at = rent_corporate_contacts.end_at == "00:00" ? "" : rent_corporate_contacts.end_at 
    rent_corporate_contacts.intend = rent_corporate_contacts_base.intend
    rent_corporate_contacts.main_corp = rent_corporate_contacts_base.main_corp
    rent_corporate_contacts.other_main_corp = rent_corporate_contacts_base.other_main_corp
    rent_corporate_contacts.staff = rent_corporate_contacts_base.staff
    rent_corporate_contacts.rent_m_corporate_way_id = rent_corporate_contacts_base.rent_m_corporate_way_id
    rent_corporate_contacts.remarks = rent_corporate_contacts_base.remarks
    rent_corporate_contacts.is_deleted = rent_corporate_contacts_base.is_deleted
    rent_corporate_contacts.rival = rent_corporate_contacts_base.rival
    rent_corporate_contacts.need = rent_corporate_contacts_base.need
    rent_corporate_contacts.trouble = rent_corporate_contacts_base.trouble
    rent_corporate_contacts.request = rent_corporate_contacts_base.request

    yield put(change_rent_corporate_contacts(rent_corporate_contacts))


}
// 営業対応履歴更新
function* handle_update_rent_corporate_contacts(action: any): any {

    // alert(action.payload)

    const state = yield select();

    let rent_corporate_contacts = state.corporation.rent_corporate_contacts
    rent_corporate_contacts.start_at = rent_corporate_contacts.start_at.replace('__:__', "")
    rent_corporate_contacts.end_at = rent_corporate_contacts.end_at.replace('__:__', "")
    const { payload, error } = yield call(RentCorporateContactsApi.update, rent_corporate_contacts);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_corporate_contacts_list = state.corporation.rent_corporate_contacts_list

        // 一覧の情報更新
        rent_corporate_contacts_list = rent_corporate_contacts_list.map(function (value: any) {
            if (value.id === payload.rent_corporate_contacts.id) {
                // alert(payload.title)
                value.rent_m_corporate_id = payload.rent_corporate_contacts.rent_m_corporate_id
                value.section_id = payload.rent_corporate_contacts.section_id
                value.user_id = payload.rent_corporate_contacts.user_id
                value.date = payload.rent_corporate_contacts.date
                value.start_at = payload.rent_corporate_contacts.start_at
                value.end_at = payload.rent_corporate_contacts.end_at
                value.intend = payload.rent_corporate_contacts.intend
                value.rent_m_corporate_way_id = payload.rent_corporate_contacts.rent_m_corporate_way_id
                value.remarks = payload.rent_corporate_contacts.remarks
                value.is_deleted = payload.rent_corporate_contacts.is_deleted



                value.main_corp = payload.rent_corporate_contacts.main_corp
                value.other_main_corp = payload.rent_corporate_contacts.other_main_corp
                value.staff = payload.rent_corporate_contacts.staff
                value.rival = payload.rent_corporate_contacts.rival
                value.need = payload.rent_corporate_contacts.need
                value.trouble = payload.rent_corporate_contacts.trouble
                value.request = payload.rent_corporate_contacts.request


            }
            return value
        })

        //一覧から削除
        let result = rent_corporate_contacts_list.filter((value: any) => {
            return (!(value.id == payload.rent_corporate_contacts.id && value.is_deleted == 1));
        });
        rent_corporate_contacts_list = result

        // 成功時アクション呼び出し
        // ブログ一覧更新
        yield put(change_rent_corporate_contacts_list(rent_corporate_contacts_list))
        // 情報更新
        // yield put(change_rent_corporate_contacts(payload))

        // 一覧の情報更新
        let rent_m_corporates_list = state.corporation.rent_m_corporates_list
        rent_m_corporates_list = rent_m_corporates_list.map(function (value: any) {
            if (value.id === payload.rent_m_corporates.id) {
                // alert(payload.title)
                value.last_contact_at = payload.rent_m_corporates.last_contact_at
                value.contact_count = payload.rent_m_corporates.contact_count
                value.main_corp = payload.rent_m_corporates.main_corp
                // value.main_corp_name = payload.rent_m_corporates.main_corp == 1 ? "穴吹ハウジング" : payload.rent_m_corporates.main_corp == 2 ? "その他" : payload.rent_m_corporates.main_corp == 3 ? "穴吹ハウジング or その他" : ""
                value.other_main_corp = payload.rent_m_corporates.other_main_corp
            }
            return value
        })
        yield put(change_rent_m_corporates_list(rent_m_corporates_list))

        
        let rent_m_corporates = state.corporation.rent_m_corporates

        rent_m_corporates.last_contact_at = payload.rent_m_corporates.last_contact_at
        rent_m_corporates.contact_count = payload.rent_m_corporates.contact_count
        rent_m_corporates.main_corp = payload.rent_m_corporates.main_corp
        // rent_m_corporates.main_corp_name = payload.rent_m_corporates.main_corp == 1 ? "穴吹ハウジング" : payload.rent_m_corporates.main_corp == 2 ? "その他" : payload.rent_m_corporates.main_corp == 3 ? "穴吹ハウジング or その他" : ""
        rent_m_corporates.other_main_corp = payload.rent_m_corporates.other_main_corp

        yield put(change_rent_m_corporates(JSON.parse(JSON.stringify(rent_m_corporates))))
        

    } else {

    }
}
// 営業対応履歴新規
function* handle_insert_rent_corporate_contacts(action: any): any {
    const state = yield select();

    let rent_corporate_contacts = state.corporation.rent_corporate_contacts

    const { payload, error } = yield call(RentCorporateContactsApi.insert, rent_corporate_contacts);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_corporate_contacts_list = state.corporation.rent_corporate_contacts_list
        let rent_m_corporates_list = state.corporation.rent_m_corporates_list

        let list = rent_corporate_contacts_list.concat(payload.rent_corporate_contacts)
        list.sort(function (a: any, b: any) {
            if (moment(a.date) > moment(b.date)) return -1;
            if (moment(a.date) < moment(b.date)) return 1;
            return 0;
        });
        // // 成功時アクション呼び出し
        yield put(change_rent_corporate_contacts_list(list))

        // 一覧の情報更新
        rent_m_corporates_list = rent_m_corporates_list.map(function (value: any) {
            if (value.id === payload.rent_m_corporates.id) {
                // alert(payload.title)
                value.last_contact_at = payload.rent_m_corporates.last_contact_at
                value.contact_count = payload.rent_m_corporates.contact_count
                value.main_corp = payload.rent_m_corporates.main_corp
                // value.main_corp_name = payload.rent_m_corporates.main_corp == 1 ? "穴吹ハウジング" : payload.rent_m_corporates.main_corp == 2 ? "その他" : payload.rent_m_corporates.main_corp == 3 ? "穴吹ハウジング or その他" : ""
                value.other_main_corp = payload.rent_m_corporates.other_main_corp
            }
            return value
        })
        yield put(change_rent_m_corporates_list(rent_m_corporates_list))



        let rent_m_corporates = state.corporation.rent_m_corporates

        rent_m_corporates.last_contact_at = payload.rent_m_corporates.last_contact_at
        rent_m_corporates.contact_count = payload.rent_m_corporates.contact_count
        rent_m_corporates.main_corp = payload.rent_m_corporates.main_corp
        // rent_m_corporates.main_corp_name = payload.rent_m_corporates.main_corp == 1 ? "穴吹ハウジング" : payload.rent_m_corporates.main_corp == 2 ? "その他" : payload.rent_m_corporates.main_corp == 3 ? "穴吹ハウジング or その他" : ""
        rent_m_corporates.other_main_corp = payload.rent_m_corporates.other_main_corp

        yield put(change_rent_m_corporates(JSON.parse(JSON.stringify(rent_m_corporates))))

    } else {

    }
}


// 担当者リスト
function* handle_get_rent_corporate_staffs_list(action: any) {

    const { payload, error } = yield call(RentCorporateStaffsApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_corporate_staffs_list(payload))
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }
}
// 担当者リスト選択時
function* handle_set_rent_corporate_staffs(action: any): any {

    const state = yield select();

    let rent_corporate_staffs_base = state.corporation.rent_corporate_staffs_list.filter((a: any) => a.id === action.payload)[0]

    let rent_corporate_staffs: any = {}
    rent_corporate_staffs.rent_m_corporate_id = rent_corporate_staffs_base.rent_m_corporate_id
    rent_corporate_staffs.id = rent_corporate_staffs_base.id
    rent_corporate_staffs.rent_m_corporate_office_id = rent_corporate_staffs_base.rent_m_corporate_office_id
    rent_corporate_staffs.post_card = rent_corporate_staffs_base.post_card
    rent_corporate_staffs.in_office = rent_corporate_staffs_base.in_office
    rent_corporate_staffs.name1 = rent_corporate_staffs_base.name1
    rent_corporate_staffs.name2 = rent_corporate_staffs_base.name2
    rent_corporate_staffs.department = rent_corporate_staffs_base.department
    rent_corporate_staffs.position = rent_corporate_staffs_base.position
    rent_corporate_staffs.tel = rent_corporate_staffs_base.tel
    rent_corporate_staffs.fax = rent_corporate_staffs_base.fax
    rent_corporate_staffs.mail = rent_corporate_staffs_base.mail
    rent_corporate_staffs.remarks = rent_corporate_staffs_base.remarks
    rent_corporate_staffs.is_deleted = rent_corporate_staffs_base.is_deleted

    yield put(change_rent_corporate_staffs(rent_corporate_staffs))

}
// 担当者リスト更新
function* handle_update_rent_corporate_staffs(action: any): any {


    const state = yield select();

    let rent_corporate_staffs = state.corporation.rent_corporate_staffs
    let rent_corporate_offices_list = state.corporation.rent_corporate_offices_list

    const { payload, error } = yield call(RentCorporateStaffsApi.update, rent_corporate_staffs);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_corporate_staffs_list = state.corporation.rent_corporate_staffs_list

        // 一覧の情報更新
        rent_corporate_staffs_list = rent_corporate_staffs_list.map(function (value: any) {
            if (value.id === payload.id) {
                // alert(payload.title)
                value.rent_m_corporate_id = payload.rent_m_corporate_id
                value.rent_m_corporate_office_id = payload.rent_m_corporate_office_id
                value.rent_m_corporate_office_name = payload.rent_m_corporate_office_id > 0 ? rent_corporate_offices_list.filter((a:any)=>parseInt(a.id) == parseInt(payload.rent_m_corporate_office_id))[0].name : ""
                value.post_card = payload.post_card
                value.in_office = payload.in_office
                value.name1 = payload.name1
                value.name2 = payload.name2
                value.department = payload.department
                value.position = payload.position
                value.tel = payload.tel
                value.fax = payload.fax
                value.mail = payload.mail
                value.remarks = payload.remarks
                value.is_deleted = payload.is_deleted
            }
            return value
        })

        //一覧から削除
        let result = rent_corporate_staffs_list.filter((value: any) => {
            return (!(value.id == payload.id && value.is_deleted == 1));
        });
        rent_corporate_staffs_list = result

        // 成功時アクション呼び出し
        // ブログ一覧更新
        yield put(change_rent_corporate_staffs_list(rent_corporate_staffs_list))

    } else {

    }
}
// 担当者リスト新規
function* handle_insert_rent_corporate_staffs(action: any): any {
    const state = yield select();

    let rent_corporate_staffs = state.corporation.rent_corporate_staffs

    const { payload, error } = yield call(RentCorporateStaffsApi.insert, rent_corporate_staffs);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_corporate_staffs_list = state.corporation.rent_corporate_staffs_list

        let list = rent_corporate_staffs_list.concat(payload)
        // list.sort(function (a: any, b: any) {
        //     if (moment(a.date) > moment(b.date)) return -1;
        //     if (moment(a.date) < moment(b.date)) return 1;
        //     return 0;
        // });



        // // 成功時アクション呼び出し
        yield put(change_rent_corporate_staffs_list(list))

    } else {

    }
}

// 削除ボタンだしていいかの判断するための、反響リストを取得
function* handle_get_rent_responses_list(action: any) {

    const { payload, error } = yield call(RentResponsesApi.response2_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_responses_list(payload))
        yield put(change_corporate_loading(true))
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }
}

// 拠点リスト
function* handle_get_rent_corporate_offices_list(action: any) {
    const { payload, error } = yield call(RentCorporateOfficesApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_corporate_offices_list(payload))
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }
}
// 拠点リスト選択時
function* handle_set_rent_corporate_offices(action: any): any {

    const state = yield select();

    let rent_corporate_offices_base = state.corporation.rent_corporate_offices_list.filter((a: any) => a.id === action.payload)[0]

    let rent_corporate_offices: any = {}
    rent_corporate_offices.id = rent_corporate_offices_base.id
    rent_corporate_offices.rent_m_corporate_id = rent_corporate_offices_base.rent_m_corporate_id
    rent_corporate_offices.is_main = rent_corporate_offices_base.is_main
    rent_corporate_offices.affiliate = rent_corporate_offices_base.affiliate
    rent_corporate_offices.name = rent_corporate_offices_base.name
    rent_corporate_offices.kana = rent_corporate_offices_base.kana
    rent_corporate_offices.post = rent_corporate_offices_base.post
    rent_corporate_offices.add1 = rent_corporate_offices_base.add1
    rent_corporate_offices.add2 = rent_corporate_offices_base.add2
    rent_corporate_offices.rent_m_agent_id = rent_corporate_offices_base.rent_m_agent_id
    rent_corporate_offices.agent_remarks = rent_corporate_offices_base.agent_remarks
    rent_corporate_offices.affiliate_remarks = rent_corporate_offices_base.affiliate_remarks
    rent_corporate_offices.contact1 = rent_corporate_offices_base.contact1
    rent_corporate_offices.contact2 = rent_corporate_offices_base.contact2
    rent_corporate_offices.contact3 = rent_corporate_offices_base.contact3
    rent_corporate_offices.lat = rent_corporate_offices_base.lat
    rent_corporate_offices.lng = rent_corporate_offices_base.lng
    rent_corporate_offices.is_deleted = rent_corporate_offices_base.is_deleted

    yield put(change_rent_corporate_offices(rent_corporate_offices))

}
// 拠点リスト更新
function* handle_update_rent_corporate_offices(action: any): any {


    const state = yield select();

    let rent_corporate_offices = state.corporation.rent_corporate_offices

    const { payload, error } = yield call(RentCorporateOfficesApi.update, rent_corporate_offices);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_corporate_offices_list = state.corporation.rent_corporate_offices_list
        let rent_m_agents_list = state.corporation.rent_m_agents_list

        // 一覧の情報更新
        rent_corporate_offices_list = rent_corporate_offices_list.map(function (value: any) {
            if (value.id === payload.id) {
                // alert(payload.title)
                value.rent_m_corporate_id = payload.rent_m_corporate_id
                value.is_main = payload.is_main
                value.affiliate = payload.affiliate
                value.name = payload.name
                value.kana = payload.kana
                value.post = payload.post
                value.add1 = payload.add1
                value.add2 = payload.add2
                value.rent_m_agent_id = payload.rent_m_agent_id
                value.rent_m_agent_name = payload.rent_m_agent_id > 0 ? rent_m_agents_list.filter((a:any) => a.id == payload.rent_m_agent_id)[0].name : ""
                value.agent_remarks = payload.agent_remarks
                value.affiliate_remarks = payload.affiliate_remarks
                value.contact1 = payload.contact1
                value.contact2 = payload.contact2
                value.contact3 = payload.contact3
                value.lat = payload.lat
                value.lng = payload.lng
                value.is_deleted = payload.is_deleted
            }
            return value
        })

        //一覧から削除
        let result = rent_corporate_offices_list.filter((value: any) => {
            return (!(value.id == payload.id && value.is_deleted == 1));
        });
        rent_corporate_offices_list = result

        // 成功時アクション呼び出し
        // ブログ一覧更新
        yield put(change_rent_corporate_offices_list(rent_corporate_offices_list))

    } else {

    }
}
// 拠点者リスト新規
function* handle_insert_rent_corporate_offices(action: any): any {
    const state = yield select();

    let rent_corporate_offices = state.corporation.rent_corporate_offices

    const { payload, error } = yield call(RentCorporateOfficesApi.insert, rent_corporate_offices);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_corporate_offices_list = state.corporation.rent_corporate_offices_list
        let list = rent_corporate_offices_list.concat(payload)
        // // 成功時アクション呼び出し
        yield put(change_rent_corporate_offices_list(list))

        // let rent_m_corporate_office_search_list = state.customersReducer.rent_m_corporate_office_search_list
        // list = rent_m_corporate_office_search_list.concat(payload)
        // yield put(Customers.change_rent_m_corporate_office_search_list(list));
        let rent_m_corporate_search = state.customersReducer.rent_m_corporate_search
        rent_m_corporate_search.rent_corporate_office_id = payload.id
        rent_m_corporate_search.rent_corporate_office_name = payload.name
        rent_m_corporate_search.rent_corporate_office_kana = payload.kana
    
        yield put(Customers.change_rent_m_corporate_search(rent_m_corporate_search))

        yield put(Customers.get_rent_m_corporate_search_list([]));

    } else {

    }
}
// 代行会社リスト
function* handle_get_rent_m_agents_list(action: any) {

    const { payload, error } = yield call(RentMAgentsApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        yield put(change_rent_m_agents_list(payload))
    } else {

    }

}



// 反響リスト
function* handle_get_rent_responses_response_list(action: any) {

    const { payload, error } = yield call(RentResponsesApi.response_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_responses_response_list(payload))
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }

}

// 来店リスト
function* handle_get_rent_responses_coming_list(action: any) {

    const { payload, error } = yield call(RentResponsesApi.coming_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        yield put(change_rent_responses_coming_list(payload))
        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }

}

// 契約リスト
function* handle_get_rent_responses_contract_list(action: any) {
    //1サブリース 2管理 3一般 4業物 5業物折半 6専任 //counting1
    const { payload, error } = yield call(RentResponsesApi.contract_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_responses_contract_list(payload))

        //月計の計算----------------------------------------------------------------------------------

        let count_month = [{ month: 1, houjin: 0, kojin: 0 },
        { month: 2, houjin: 0, kojin: 0 },
        { month: 3, houjin: 0, kojin: 0 },
        { month: 4, houjin: 0, kojin: 0 },
        { month: 5, houjin: 0, kojin: 0 },
        { month: 6, houjin: 0, kojin: 0 },
        { month: 7, houjin: 0, kojin: 0 },
        { month: 8, houjin: 0, kojin: 0 },
        { month: 9, houjin: 0, kojin: 0 },
        { month: 10, houjin: 0, kojin: 0 },
        { month: 11, houjin: 0, kojin: 0 },
        { month: 12, houjin: 0, kojin: 0 }]

        payload.forEach(function (value: any) {
            let index = count_month.findIndex(e => e.month == parseInt(moment(value.date).format('Mo')) && parseInt(moment(value.date).format('YYYY')) >= parseInt(moment().add(-5, 'years').format('YYYY')))

            if (index > -1) {// ある場合
                if (value.type === 1) {
                    count_month[index].houjin++
                } else {
                    count_month[index].kojin++
                }

            } else {// ない場合
            }
        })
        yield put(change_rent_corporate_contract_by_month(count_month))

        //年計の計算----------------------------------------------------------------------------------
        let count = new Array()

        for (let i = 10; i >= 0; i--) {
            count.push({ year: moment().add(-i, 'years').format('YYYY'), houjin: 0, kojin: 0 })
        }

        payload.forEach(function (value: any) {
            let index = count.findIndex(e => e.year == moment(value.date).format('YYYY'))

            if (index > -1) {// ある場合
                if (value.type === 1) {
                    count[index].houjin++
                } else {
                    count[index].kojin++
                }

            } else {// ない場合
                // let tmp = {year:moment(value.date).format('YYYY'), houjin:0, kojin:0}
                // if(value.type === 1){
                //     tmp.houjin++
                // }else{
                //     tmp.kojin++
                // }
                // count.push(tmp)
            }
        })

        //   // 並び替え
        //   count.sort(function(a,b){
        //     if(a.year<b.year) return -1;
        //     if(a.year > b.year) return 1;
        //     return 0;
        // });
        yield put(change_rent_corporate_contract_by_year(count))

        // yield put(change_rent_m_corporates_list_search(payload))
    } else {

    }

}

// 契約カウント
function* handle_get_rent_responses_contract_count(action: any): any {
    //1サブリース 2管理 3一般 4業物 5業物折半 6専任 //counting1
    const state = yield select();

    let rent_responses_contract_count = state.corporation.rent_responses_contract_count

    let rent_responses_response_list = state.corporation.rent_responses_response_list
    let rent_responses_coming_list = state.corporation.rent_responses_coming_list

    let count = new Array()

    for (let i = 5; i >= 0; i--) {
        let year = moment().add(-i, 'years').format('YYYY')
        let search = { id: action.payload, start: year + "-01-01", end: year + "-12-31" }

        const { payload, error } = yield call(RentResponsesApi.contract_count, search);

        // サーバーからのレスポンスデータによる分岐処理
        if (payload && !error) {
            // 成功時アクション呼び出し
            // payload.response_count_0 = rent_responses_response_list ? rent_responses_response_list.filter((a:any) => a.type == 0 && a.date >= year + "-01-01" &&  a.date <= year + "-12-31").length : 0
            // payload.response_count_1 = rent_responses_response_list ? rent_responses_response_list.filter((a:any) => a.type == 1 && a.date >= year + "-01-01" &&  a.date <= year + "-12-31").length : 0
            // payload.coming_response_count_0 = rent_responses_coming_list ? rent_responses_coming_list.filter((a:any) => a.type == 0 && a.date >= year + "-01-01" &&  a.date <= year + "-12-31").length : 0
            // payload.coming_response_count_1 = rent_responses_coming_list ? rent_responses_coming_list.filter((a:any) => a.type == 1 && a.date >= year + "-01-01" &&  a.date <= year + "-12-31").length : 0

            count = count.concat(payload)

            

        } else {

        }

    }

    yield put(change_rent_responses_contract_count(count))


}

// ファイルリスト
function* handle_get_rent_corporate_docs_list(action: any) {

    const { payload, error } = yield call(RentCorporateDocsApi.list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_corporate_docs_list(payload))
    } else {

    }
}
// fileアップロード
function* handle_insert_rent_corporate_docs(action: any): any {

    // console.log(action.payload.file)
    // alert(action.payload.file.type)
    // console.log(action.payload.key)

    const state = yield select();

    let rent_corporate_docs = state.corporation.rent_corporate_docs


    const { payload, error } = yield call(RentCorporateDocsApi.upload, rent_corporate_docs);

    //     // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // // 成功時アクション呼び出し
        yield put(change_rent_corporate_docs_list(payload))


    } else {
        // alert(2);
    }
}
// ファイル選択時
function* handle_set_rent_corporate_docs(action: any): any {

    const state = yield select();

    let rent_corporate_docs_list = state.corporation.rent_corporate_docs_list.filter((a: any) => a.id === action.payload)[0]

    let rent_corporate_docs: any = {}
    rent_corporate_docs.id = rent_corporate_docs_list.id
    rent_corporate_docs.name = rent_corporate_docs_list.name
    rent_corporate_docs.mimetype = rent_corporate_docs_list.mimetype
    rent_corporate_docs.extension = rent_corporate_docs_list.extension
    rent_corporate_docs.remarks = rent_corporate_docs_list.remarks
    rent_corporate_docs.is_deleted = rent_corporate_docs_list.is_deleted

    yield put(change_rent_corporate_docs(rent_corporate_docs))


}
// ファイル更新　削除
function* handle_update_rent_corporate_docs(action: any): any {

    const state = yield select();

    let rent_corporate_docs = state.corporation.rent_corporate_docs

    const { payload, error } = yield call(RentCorporateDocsApi.update, rent_corporate_docs);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_corporate_docs_list = state.corporation.rent_corporate_docs_list

        // 一覧の情報更新
        rent_corporate_docs_list = rent_corporate_docs_list.map(function (value: any) {
            if (value.id === payload.id) {
                // alert(payload.title)
                value.is_deleted = payload.is_deleted
            }
            return value
        })

        //一覧から削除
        let result = rent_corporate_docs_list.filter((value: any) => {
            return (!(value.id == payload.id && value.is_deleted == 1));
        });
        rent_corporate_docs_list = result

        // 成功時アクション呼び出し
        // ブログ一覧更新
        yield put(change_rent_corporate_docs_list(rent_corporate_docs_list))
        // 情報更新
        // yield put(change_rent_corporate_contacts(payload))



    } else {

    }
}
// ファイルダウンロード
function* handle_download_rent_corporate_docs(action: any): any {
    const state = yield select();
    // alert(action.payload)
    const { payload, error } = yield call(RentCorporateDocsApi.download, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.name;
        // a.download = "10.pdf";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();
        //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


// 営業対応リスト all
function* handle_get_rent_corporate_contacts_all_list(action: any) {
    const { payload, error } = yield call(RentCorporateContactsApi.all_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(change_rent_corporate_contacts_all_list(payload))
        yield put(get_rent_corporate_contacts_all_list_search(payload))
        // yield put(change_rent_corporate_contacts_all_list_search(payload))
    } else {

    }
}
// 営業対応リスト 検索 all
function* handle_get_rent_corporate_contacts_all_list_search(action: any): any {

    const state = yield select();

    // 文字列が一致するか
    // let rent_m_corporates_list_base=  state.corporation.rent_m_corporates_list.filter((a:any)=>{
    //     if(a.name.indexOf(action.payload) != -1) return true
    // })


    let rent_corporate_contacts_all_list_base = state.corporation.rent_corporate_contacts_all_list
    let search_data_contacts = state.corporation.search_data_contacts

    if (search_data_contacts.name) {
        rent_corporate_contacts_all_list_base = rent_corporate_contacts_all_list_base.filter((a: any) => (a.name && a.name.indexOf(search_data_contacts.name) != -1) || (a.kana && a.kana.indexOf(search_data_contacts.name) != -1))//法人名カナ
    }

    if (search_data_contacts.user_id) {
        rent_corporate_contacts_all_list_base = rent_corporate_contacts_all_list_base.filter((a: any) => (a.user_id && a.user_id == search_data_contacts.user_id))//担当
    }

    if (search_data_contacts.section_id) {
        rent_corporate_contacts_all_list_base = rent_corporate_contacts_all_list_base.filter((a: any) => (a.section_id && a.section_id == search_data_contacts.section_id))//部署
    }
    
    if (search_data_contacts.start_date) {
        rent_corporate_contacts_all_list_base = rent_corporate_contacts_all_list_base.filter((a: any) => (a.date && moment(a.date).format("YYYY-MM-DD") >= moment(search_data_contacts.start_date).format("YYYY-MM-DD")))//期間
    }

    if (search_data_contacts.end_date) {
        rent_corporate_contacts_all_list_base = rent_corporate_contacts_all_list_base.filter((a: any) => (a.date && moment(a.date).format("YYYY-MM-DD") <= moment(search_data_contacts.end_date).format("YYYY-MM-DD")))//期間
    }


    rent_corporate_contacts_all_list_base.sort(function (a: any, b: any) {
        if (moment(a.date) > moment(b.date)) return -1;
        if (moment(a.date) < moment(b.date)) return 1;
        return 0;
    });

    yield put(change_rent_corporate_contacts_all_list_search(rent_corporate_contacts_all_list_base))

}
// 営業対応リスト選択時 all
function* handle_set_rent_corporate_contacts_all(action: any): any {

    const state = yield select();

    let rent_corporate_contacts_all_base = state.corporation.rent_corporate_contacts_all_list.filter((a: any) => a.id === action.payload)[0]

    let rent_corporate_contacts: any = {}
    rent_corporate_contacts.id = rent_corporate_contacts_all_base.id
    rent_corporate_contacts.rent_m_corporate_id = rent_corporate_contacts_all_base.rent_m_corporate_id
    rent_corporate_contacts.user_id = rent_corporate_contacts_all_base.user_id
    rent_corporate_contacts.section_id = rent_corporate_contacts_all_base.section_id
    rent_corporate_contacts.date = rent_corporate_contacts_all_base.date
    rent_corporate_contacts.start_at = rent_corporate_contacts_all_base.start_at ? moment(rent_corporate_contacts_all_base.start_at).utc().format('HH:mm')  : ""
    rent_corporate_contacts.start_at = rent_corporate_contacts.start_at == "00:00" ? "" : rent_corporate_contacts.start_at 
    rent_corporate_contacts.end_at = rent_corporate_contacts_all_base.end_at ? moment(rent_corporate_contacts_all_base.end_at).utc().format('HH:mm')  : ""
    rent_corporate_contacts.end_at = rent_corporate_contacts.end_at == "00:00" ? "" : rent_corporate_contacts.end_at 
    rent_corporate_contacts.intend = rent_corporate_contacts_all_base.intend
    rent_corporate_contacts.main_corp = rent_corporate_contacts_all_base.main_corp
    rent_corporate_contacts.other_main_corp = rent_corporate_contacts_all_base.other_main_corp
    rent_corporate_contacts.staff = rent_corporate_contacts_all_base.staff
    rent_corporate_contacts.rent_m_corporate_way_id = rent_corporate_contacts_all_base.rent_m_corporate_way_id
    rent_corporate_contacts.remarks = rent_corporate_contacts_all_base.remarks
    rent_corporate_contacts.rival = rent_corporate_contacts_all_base.rival
    rent_corporate_contacts.need = rent_corporate_contacts_all_base.need
    rent_corporate_contacts.trouble = rent_corporate_contacts_all_base.trouble
    rent_corporate_contacts.request = rent_corporate_contacts_all_base.request

    rent_corporate_contacts.is_deleted = rent_corporate_contacts_all_base.is_deleted

    yield put(change_rent_corporate_contacts(rent_corporate_contacts))
}
// 営業対応履歴更新 all
function* handle_update_rent_corporate_contacts_all(action: any): any {

    // alert(action.payload)

    const state = yield select();

    let rent_corporate_contacts = state.corporation.rent_corporate_contacts

    rent_corporate_contacts.start_at = rent_corporate_contacts.start_at.replace('__:__', "")
    rent_corporate_contacts.end_at = rent_corporate_contacts.end_at.replace('__:__', "")
    const { payload, error } = yield call(RentCorporateContactsApi.update, rent_corporate_contacts);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        let rent_corporate_contacts_all_list = state.corporation.rent_corporate_contacts_all_list
        let rent_m_corporates_list = state.corporation.rent_m_corporates_list
        let users_select = state.masterReducer.users_select
        let section_select = state.masterReducer.section_select
        let kanricorps = state.masterReducer.kanricorps

        // 一覧の情報更新
        rent_corporate_contacts_all_list = rent_corporate_contacts_all_list.map(function (value: any) {
            if (value.id === payload.rent_corporate_contacts.id) {
                value.rent_m_corporate_id = payload.rent_corporate_contacts.rent_m_corporate_id//
                value.date = payload.rent_corporate_contacts.date//
                value.start_at = payload.rent_corporate_contacts.start_at//
                value.end_at = payload.rent_corporate_contacts.end_at//
                value.intend = payload.rent_corporate_contacts.intend//
                value.user_id = payload.rent_corporate_contacts.user_id//
                value.section_id = payload.rent_corporate_contacts.section_id//
                value.rent_m_corporate_way_id = payload.rent_corporate_contacts.rent_m_corporate_way_id//
                value.remarks = payload.rent_corporate_contacts.remarks
                value.is_deleted = payload.rent_corporate_contacts.is_deleted

                value.name = payload.rent_corporate_contacts.rent_m_corporate_id ? rent_m_corporates_list.filter((value: any) => { return value.id == payload.rent_corporate_contacts.rent_m_corporate_id; })[0].name : "";
                value.section_name = payload.rent_corporate_contacts.section_id ? section_select.filter((value: any) => { return value.value == payload.rent_corporate_contacts.section_id; })[0].label : "";
                value.user_name = payload.rent_corporate_contacts.user_id ? users_select.filter((value: any) => { return value.value == payload.rent_corporate_contacts.user_id; })[0].label : "";
                value.taiou_date = moment(payload.rent_corporate_contacts.date).format("YYYY/MM/DD")
                value.rent_m_corporate_way_name = payload.rent_corporate_contacts.rent_m_corporate_way_id == 1 ? "企業訪問" : payload.rent_corporate_contacts.rent_m_corporate_way_id == 2 ? "TEL" : payload.rent_corporate_contacts.rent_m_corporate_way_id == 3 ? "メール" : ""
                
                value.main_corp = payload.rent_corporate_contacts.main_corp
                value.main_corp_name = payload.rent_corporate_contacts.main_corp == 1 ? "穴吹ハウジング" : payload.rent_corporate_contacts.main_corp == 2 ? "その他" : payload.rent_corporate_contacts.main_corp == 3 ? "穴吹ハウジング or その他" : ""
                // value.kanri_corp_name = payload.kanri_corp_no ? kanricorps.filter((value: any) => { return value.value == payload.kanri_corp_no; })[0].label : "";
                value.other_main_corp = payload.rent_corporate_contacts.other_main_corp
                value.staff = payload.rent_corporate_contacts.staff

                value.rival = payload.rent_corporate_contacts.rival
                value.need = payload.rent_corporate_contacts.need
                value.trouble = payload.rent_corporate_contacts.trouble
                value.request = payload.rent_corporate_contacts.request
            }
            return value
        })

        //一覧から削除
        let result = rent_corporate_contacts_all_list.filter((value: any) => {
            return (!(value.id == payload.rent_corporate_contacts.id && value.is_deleted == 1));
        });
        rent_corporate_contacts_all_list = result

        // 成功時アクション呼び出し
        // ブログ一覧更新
        yield put(change_rent_corporate_contacts_all_list(rent_corporate_contacts_all_list))
        //yield put(get_rent_corporate_contacts_all_list_search(action.payload))
        yield put(get_rent_corporate_contacts_all_list_search(action.payload))
        // 情報更新
        // yield put(change_rent_corporate_contacts(payload))

        // 一覧の情報更新
        rent_m_corporates_list = rent_m_corporates_list.map(function (value: any) {
            if (value.id === payload.rent_m_corporates.id) {
                // alert(payload.title)
                value.last_contact_at = payload.rent_m_corporates.last_contact_at
                value.contact_count = payload.rent_m_corporates.contact_count
                value.main_corp = payload.rent_m_corporates.main_corp
                value.main_corp_name = payload.rent_m_corporates.main_corp == 1 ? "穴吹ハウジング" : payload.rent_m_corporates.main_corp == 2 ? "その他" : payload.rent_m_corporates.main_corp == 3 ? "穴吹ハウジング or その他" : ""
                value.other_main_corp = payload.rent_m_corporates.other_main_corp
            }
            return value
        })
        yield put(change_rent_m_corporates_list(rent_m_corporates_list))



    } else {

    }
}
// 営業対応履歴新規 all
function* handle_insert_rent_corporate_contacts_all(action: any): any {
    const state = yield select();

    let rent_corporate_contacts = state.corporation.rent_corporate_contacts

    const { payload, error } = yield call(RentCorporateContactsApi.insert, rent_corporate_contacts);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        let rent_corporate_contacts_all_list = state.corporation.rent_corporate_contacts_all_list

        let rent_m_corporates_list = state.corporation.rent_m_corporates_list
        let users_select = state.masterReducer.users_select
        let section_select = state.masterReducer.section_select
        let kanricorps = state.masterReducer.kanricorps

        payload.rent_corporate_contacts.name = payload.rent_corporate_contacts.rent_m_corporate_id ? rent_m_corporates_list.filter((value: any) => { return value.id == payload.rent_corporate_contacts.rent_m_corporate_id; })[0].name : "";
        payload.rent_corporate_contacts.section_name = payload.rent_corporate_contacts.section_id ? section_select.filter((value: any) => { return value.value == payload.rent_corporate_contacts.section_id; })[0].label : "";
        payload.rent_corporate_contacts.user_name = payload.rent_corporate_contacts.user_id ? users_select.filter((value: any) => { return value.value == payload.rent_corporate_contacts.user_id; })[0].label : "";
        payload.rent_corporate_contacts.taiou_date = moment(payload.rent_corporate_contacts.date).format("YYYY/MM/DD")
        payload.rent_corporate_contacts.rent_m_corporate_way_name = payload.rent_corporate_contacts.rent_m_corporate_way_id == 1 ? "企業訪問" : payload.rent_corporate_contacts.rent_m_corporate_way_id == 2 ? "TEL" : payload.rent_corporate_contacts.rent_m_corporate_way_id == 3 ? "メール" : ""

        payload.rent_corporate_contacts.main_corp_name = payload.rent_corporate_contacts.main_corp == 1 ? "穴吹ハウジング" : payload.rent_corporate_contacts.main_corp == 2 ? "その他" : payload.rent_corporate_contacts.main_corp == 3 ? "穴吹ハウジング or その他" : ""
        // payload.kanri_corp_name = payload.kanri_corp_no ? kanricorps.filter((value: any) => { return value.value == payload.kanri_corp_no; })[0].label : "";

        console.log("aaaa")
        console.log(payload)
        // // 成功時アクション呼び出し
        yield put(change_rent_corporate_contacts_all_list(rent_corporate_contacts_all_list.concat(payload.rent_corporate_contacts)))
        yield put(get_rent_corporate_contacts_all_list_search())

        // 一覧の情報更新
        rent_m_corporates_list = rent_m_corporates_list.map(function (value: any) {
            if (value.id === payload.rent_m_corporates.id) {
                // alert(payload.title)
                value.last_contact_at = payload.rent_m_corporates.last_contact_at
                value.contact_count = payload.rent_m_corporates.contact_count
                value.main_corp = payload.rent_m_corporates.main_corp
                value.main_corp_name = payload.rent_m_corporates.main_corp == 1 ? "穴吹ハウジング" : payload.rent_m_corporates.main_corp == 2 ? "その他" : payload.rent_m_corporates.main_corp == 3 ? "穴吹ハウジング or その他" : ""
                value.other_main_corp = payload.rent_m_corporates.other_main_corp
            }
            return value
        })
        yield put(change_rent_m_corporates_list(rent_m_corporates_list))
    } else {

    }
}


// 集計リスト　反響・契約 ダイアログ
function* handle_get_rent_responses_corporate_count_list(action: any): any {

    const state = yield select();

    yield put(change_rent_responses_corporate_count_list_loading(true))

    const rent_responses_corporate_count_list_search_form =  state.corporation.rent_responses_corporate_count_list_search_form
    const { payload, error } = yield call(RentCorporateContactsApi.count_list, rent_responses_corporate_count_list_search_form);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し

        let list = payload
        let list_p = new Array()

        if(rent_responses_corporate_count_list_search_form.corporate_response){//法人反響
            list_p = list_p.concat(list.filter((a:any) => a.type == 1))
            list = list.filter((a:any) => !(a.type == 1))
        }
        if(rent_responses_corporate_count_list_search_form.corporate_contract){//法人契約
            list_p = list_p.concat(list.filter((a:any) => a.type == 1 && a.rent_contract_id > 0 && !a.is_cancel))
            list = list.filter((a:any) => !(a.type == 1 && a.rent_contract_id > 0))
        }
        if(rent_responses_corporate_count_list_search_form.personal_response){//個人反響
            list_p = list_p.concat(list.filter((a:any) => a.type == 0))
            list = list.filter((a:any) => !(a.type == 0))
        }
        if(rent_responses_corporate_count_list_search_form.personal_contract){//個人契約
            list_p = list_p.concat(list.filter((a:any) => a.type == 0 && a.rent_contract_id > 0 && !a.is_cancel))
            list = list.filter((a:any) => !(a.type == 0 && a.rent_contract_id > 0))
        }
        if(!rent_responses_corporate_count_list_search_form.corporate_response &&
            !rent_responses_corporate_count_list_search_form.corporate_contract &&
            !rent_responses_corporate_count_list_search_form.personal_response &&
            !rent_responses_corporate_count_list_search_form.personal_contract 
            ){//ドレモチェックついてない
            list_p = list
        }

        yield put(change_rent_responses_corporate_count_list(list_p))
        yield put(change_rent_responses_corporate_count_list_loading(false))
        // yield put(change_rent_corporate_contacts_all_list_search(payload))
    } else {

    }
    yield put(change_rent_responses_corporate_count_list_loading(false))
}
// 法人リスト 全件
function* handle_get_rent_m_corporate_all_list(action: any): any {

    const state = yield select();

    let rent_m_corporates_list_search_form = {name: "", partnership: false, count:1000000}

    const { payload, error } = yield call(RentMCorporationsApi.list, rent_m_corporates_list_search_form);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert("2")
        // 成功時アクション呼び出し
        yield put(change_rent_m_corporate_all_list(payload.list))

    } else {

    }

}


// 法人リスト 置換用
function* handle_get_rent_m_corporates_list_replace(action: any): any {

    const state = yield select();

    let search_data_replace = state.corporation.search_data_replace

    const { payload, error } = yield call(RentMCorporationsApi.search_list, {name: search_data_replace.m_corporate_name});
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        yield put(change_rent_m_corporates_list_replace(payload))
        yield put(change_rent_m_corporates_list_replace_search(payload))
    } else {

    }

}
// 法人リスト 検索　置換用
function* handle_get_rent_m_corporates_list_replace_search(action: any): any {

    const state = yield select();
    
    let rent_m_corporates_list_replace_base = state.corporation.rent_m_corporates_list_replace

    let search_data_replace = state.corporation.search_data_replace
    
    if (search_data_replace.m_corporate_name) {
        // alert(search_data.name)
        // rent_m_corporates_list_base = rent_m_corporates_list_base.filter((a: any) => a.name.indexOf(search_data.name))//名前
        rent_m_corporates_list_replace_base = rent_m_corporates_list_replace_base.filter((a: any) => (a.name && a.name.indexOf(search_data_replace.m_corporate_name) != -1) || (a.kana && a.kana.indexOf(search_data_replace.m_corporate_name) != -1) || (a.id && a.id == search_data_replace.m_corporate_name))//物件名〇
    }

    yield put(change_rent_m_corporates_list_replace_search(rent_m_corporates_list_replace_base))

}

// 顧客リスト 置換用
function* handle_get_rent_customer_list_replace(action: any) {

    const { payload, error } = yield call(RentResponsesApi.customer_list, action.payload);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        yield put(change_rent_customer_list_replace(JSON.parse(JSON.stringify(payload))))
        yield put(change_rent_customer_list_replace_search(payload))

    } else {

    }

}
// 顧客リスト 検索　置換用
function* handle_get_rent_customer_list_replace_search(action: any): any{
    const state = yield select();

    let search_data_replace = state.corporation.search_data_replace

    const { payload, error } = yield call(RentResponsesApi.customer_list, search_data_replace);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        // yield put(change_rent_customer_list_replace(JSON.parse(JSON.stringify(payload))))
        yield put(change_rent_customer_list_replace_search(payload))

    } else {

    }

}
// // 顧客リスト 検索　置換用
// function* handle_get_rent_customer_list_replace_search(action: any): any {

//     const state = yield select();
    

//     let rent_customer_list_replace_base = state.corporation.rent_customer_list_replace

//     let search_data_replace = state.corporation.search_data_replace

//     if (search_data_replace.customer_name) {
        
//         // rent_customer_list_replace_base = rent_customer_list_replace_base.filter((a: any) => (a.name1 && a.name1.indexOf(search_data_replace.customer_name) != -1))//物件名〇
    
//         rent_customer_list_replace_base = rent_customer_list_replace_base.filter((a: any) => (a.name1 && a.name1.indexOf(search_data_replace.customer_name) != -1) || (a.name2 && a.name2.indexOf(search_data_replace.customer_name) != -1) || (a.office_name && a.office_name.indexOf(search_data_replace.customer_name) != -1))//物件名〇
//         // rent_customer_list_replace_base = rent_customer_list_replace_base.filter((a: any) => (a.name1 && a.name1 == "株式会社グローバルセンター"))//物件名〇
//     }


//     yield put(change_rent_customer_list_replace_search(JSON.parse(JSON.stringify(rent_customer_list_replace_base))))

// }
// 置換用　更新
function* handle_update_rent_customer_list_replace(action: any): any {

    // alert(action.payload)

    const state = yield select();

    let search_data_replace = state.corporation.search_data_replace
    let rent_m_corporates_list_replace = state.corporation.rent_m_corporates_list_replace
    let rent_customer_list_replace = state.corporation.rent_customer_list_replace
    let rent_customer_list_replace_search = state.corporation.rent_customer_list_replace_search

    var rent_m_corporate = rent_m_corporates_list_replace.filter((a:any) => a.id == search_data_replace.select_m_corporate_id && a.rent_corporate_office_id == search_data_replace.select_corporate_office_id)[0]
    var rent_customer = rent_customer_list_replace_search.filter((a:any) => a.is_select_name == true || a.is_select_office == true )
    
    const { payload, error } = yield call(RentResponsesApi.update, {rent_m_corporate:rent_m_corporate, rent_customer:rent_customer});


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        for(var i = 0; i < rent_customer.length; i++){

            // 一覧の情報更新
            rent_customer_list_replace_search = rent_customer_list_replace_search.map(function (value: any) {
                if (value.rent_customer_id === rent_customer[i].rent_customer_id) {

                    if(rent_customer[i].is_select_name){
                        // value.rent_m_corporate_id = rent_m_corporate.id
                        // value.name1 = rent_m_corporate.name

                        value.rent_m_corporate_id = rent_m_corporate.id
                        value.name1 = rent_m_corporate.name + (rent_m_corporate.rent_corporate_office_name ? " "+rent_m_corporate.rent_corporate_office_name : "")
                        value.rent_corporate_office_id = rent_m_corporate.rent_corporate_office_id ? rent_m_corporate.rent_corporate_office_id : 0
                        value.rent_corporate_office_name = rent_m_corporate.rent_corporate_office_name ? rent_m_corporate.rent_corporate_office_name : ""
                    }
                    if(rent_customer[i].is_select_office){
                        value.office_rent_m_corporate_id = rent_m_corporate.id
                        value.office = rent_m_corporate.id
                        value.office_name = rent_m_corporate.name

                        value.office_rent_corporate_office_id = rent_m_corporate.rent_corporate_office_id ? rent_m_corporate.rent_corporate_office_id : 0
                        value.office_rent_corporate_office_name = rent_m_corporate.rent_corporate_office_name ? rent_m_corporate.rent_corporate_office_name : ""
                    }
                    value.is_select_name = false
                    value.is_select_office = false
                }
                return value
            })
        }

        yield put(change_rent_customer_list_replace_search(rent_customer_list_replace_search))
        // yield put(get_rent_customer_list_replace_search())


    } else {

    }
}





// 〒検索
function* handle_get_rent_m_corporates_zip_address(action: any): any {

    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let rent_corporate_offices = state.corporation.rent_corporate_offices
    const { payload, error } = yield call(TMZipAddressApi.address, rent_corporate_offices.post);

    yield put(change_rent_corporate_offices({}));
    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {

        if (payload.todoufuken_name) {
            rent_corporate_offices.add1 = payload.todoufuken_name + payload.shikuchouson_name + payload.chouiki_name;
        }

        yield put(change_rent_corporate_offices(rent_corporate_offices));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }

}