import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
// import * as firebase from "firebase";
import { eventChannel } from 'redux-saga'
import { call, put, takeEvery, take, select } from 'redux-saga/effects';


import * as MailTemplatesApi from '../Api/MailTemplatesApi';
import * as MailTemplateCategoriesApi from '../Api/MailTemplateCategoriesApi';


import * as mail_template_parameters_api from '../Api/mail_template_parameters_api';

import * as Mail from '../../Mail/Saga/Mail';


// import * as Common from './Common';

export interface MailTemplatesState {
    // mails:any;
    // datebinary:number;
    // min_mail_id:number;
    // mail_loading:number;
    // mail_details:any;
    // mail_listening:number;
    // mail_detail_files:any;
    // mail_file:any;
    // get_mail_details_loading:number;
    // get_mail_detail_files_loading:number;
    // mail_new:number;
    // mail_addresses:any;
    // send_mail_details:any;
    // send_mail_editor_state:any;
    // send_mail_cc_disp:number;
    // send_mail_bcc_disp:number;
    // send_files:any;
    // send_edit_contents:number;
    // send_to_addresses:any;
    mail_templates: any;
    mail_template_categories: any;
    mail_template_details: any;
    mail_template_search: any;
    mail_templates_search_list: any;
    mail_template_parameters: any;

    
    mail_template_form: any;
    mail_template_list: any;
    mail_template_list_search: any;
    mail_template_category_default: any;// 反響から開いたら反響が初期値　契約だと契約が初期値に設定する用

    auto_send_mail_template_category_list: any;
}

const initialState: MailTemplatesState = {

    // mails: [],
    // mail_details: [],
    // min_mail_id:0,
    // datebinary:0,
    // mail_loading:0,
    // mail_listening:0,
    // mail_detail_files:[],
    mail_templates: [],
    mail_template_categories: [],
    mail_template_details: [],
    mail_template_search: {
        section_id: 0,
        user_id: 0,
        mail_template_category_id: 70
    },
    mail_templates_search_list: [],
    mail_template_parameters: [],
    // mail_file:"",
    // get_mail_details_loading:0,
    // get_mail_detail_files_loading:0,
    // mail_new:0,
    // mail_addresses:[],
    // send_mail_details:[],
    // send_mail_editor_state:[],
    // send_mail_cc_disp:0,
    // send_mail_bcc_disp:0,
    // send_files:[],
    // send_edit_contents:1,
    // send_to_addresses:[],
    // response_mails:[]

    
    mail_template_form:{section_id:-1, user_id:"", category:"", },
    mail_template_list:[],
    mail_template_list_search:[],
    mail_template_category_default:0,
    auto_send_mail_template_category_list:[]
}



///action
const actionCreator = actionCreatorFactory();




export const get_mail_templates = actionCreator('GET_MAIL_TEMPLATES');
export const get_mail_templates_success = actionCreator<any>("GET_MAIL_TEMPLATES_SUCCESS");

export const get_mail_templates_search_list = actionCreator('GET_MAIL_TEMPLATES_SEARCH_LIST');
export const change_mail_templates_search_list = actionCreator<any>("CHANGE_MAIL_TEMPLATES_SEARCH_LIST");
export const change_mail_template_search = actionCreator<any>('CHANGE_MAIL_TEMPLATE_SEARCH');

export const get_mail_template_parameters = actionCreator('GET_MAIL_TEMPLATE_PARAMETERS');
export const change_mail_template_parameters = actionCreator<any>("CHANGE_MAIL_TEMPLATE_PARAMETERS");



export const get_mail_template_details = actionCreator('GET_MAIL_TEMPLATE_DETAILS');
export const change_mail_template_details = actionCreator<any>("CHANGE_MAIL_TEMPLATE_DETAILS");

export const get_mail_template_categories = actionCreator('GET_MAIL_TEMPLATE_CATEGORIES');
export const change_mail_template_categories = actionCreator<any>("CHANGE_MAIL_TEMPLATE_CATEGORIES");


export const update_mail_templates = actionCreator('UPDATE_MAIL_TEMPLATES');
export const update_mail_templates_success = actionCreator<any>("UPDATE_MAIL_TEMPLATES_SUCCESS");
export const update_mail_templates_failure = actionCreator<any>("UPDATE_MAIL_TEMPLATES_FAILURE");


export const add_mail_templates = actionCreator('ADD_MAIL_TEMPLATES');
export const add_mail_templates_success = actionCreator<any>("ADD_MAIL_TEMPLATES_SUCCESS");
export const add_mail_templates_failure = actionCreator<any>("ADD_MAIL_TEMPLATES_FAILURE");

export const delete_mail_templates = actionCreator('DELETE_MAIL_TEMPLATES');


export const add_mail_templates_category = actionCreator<any>('ADD_MAIL_TEMPLATES_CATEGORY');
// export const add_mail_templates_category_success = actionCreator<any>("ADD_MAIL_TEMPLATES_CATEGORY_SUCCESS");
// export const add_mail_templates_category_failure = actionCreator<any>("ADD_MAIL_TEMPLATES_CATEGORY_FAILURE");

export const update_mail_templates_category = actionCreator<any>('UPDATE_MAIL_TEMPLATES_CATEGORY');
// export const update_mail_templates_category_success = actionCreator<any>("UPDATE_MAIL_TEMPLATES_CATEGORY_SUCCESS");
// export const update_mail_templates_category_failure = actionCreator<any>("UPDATE_MAIL_TEMPLATES_CATEGORY_FAILURE");

export const delete_mail_templates_category = actionCreator<any>('DELETE_MAIL_TEMPLATES_CATEGORY');


// export const add_mail_templates_detail= actionCreator<any>('ADD_MAIL_TEMPLATES_DETAIL');

export const change_mail_template_form = actionCreator<any>('CHANGE_MAIL_TEMPLATE_FORM');

export const get_mail_template_list= actionCreator('GET_MAIL_TEMPLATE_LIST');
export const change_mail_template_list = actionCreator('CHANGE_MAIL_TEMPLATE_LIST');
export const get_mail_template_list_search = actionCreator('GET_MAIL_TEMPLATE_LIST_SEARCH');
export const change_mail_template_list_search = actionCreator('CHANGE_MAIL_TEMPLATE_LIST_SEARCH');
export const get_mail_template_details_new = actionCreator('GET_MAIL_TEMPLATE_DETAILS_NEW');
export const insert_mail_template_details = actionCreator<any>('INSERT_MAIL_TEMPLATE_DETAILS');
export const update_mail_template_details = actionCreator('UPDATE_MAIL_TEMPLATE_DETAILS');
export const delete_mail_template_details = actionCreator('DELETE_MAIL_TEMPLATE_DETAILS');
export const insert_mail_template_replace_and_set = actionCreator('INSERT_MAIL_TEMPLATE_REPLACE_AND_SET');
export const update_mail_template_list_sort = actionCreator('UPDATE_MAIL_TEMPLATE_LIST_SORT');

export const change_mail_template_category_default = actionCreator('CHANGE_MAIL_TEMPLATE_CATEGORY_DEFAULT');

export const get_auto_send_mail_template_category_list = actionCreator('GET_AUTO_SEND_MAIL_TEMPLATE_CATEGORY_LIST');
export const change_auto_send_mail_template_category_list = actionCreator('CHANGE_AUTO_SEND_MAIL_TEMPLATE_CATEGORY_LIST');
export const insert_auto_send_mail_template_category = actionCreator('INSERT_AUTO_SEND_MAIL_TEMPLATE_CATEGORY');
export const update_auto_send_mail_template_category = actionCreator('UPDATE_AUTO_SEND_MAIL_TEMPLATE_CATEGORY');
export const delete_auto_send_mail_template_category = actionCreator('DELETE_AUTO_SEND_MAIL_TEMPLATE_CATEGORY');
export const update_auto_send_mail_template_category_sort = actionCreator('UPDATE_AUTO_SEND_MAIL_TEMPLATE_CATEGORY_SORT');


export const mailtemplateReducer = reducerWithInitialState(initialState)



    .case(get_mail_templates_success, (state, payload) => ({ ...state, mail_templates: payload }))
    .case(add_mail_templates_success, (state, payload) => ({ ...state, mail_templates: payload }))

    .case(change_mail_template_categories, (state, payload) => ({ ...state, mail_template_categories: payload }))

    .case(change_mail_template_details, (state, payload) => ({ ...state, mail_template_details: payload }))

    .case(change_mail_templates_search_list, (state, payload) => ({ ...state, mail_templates_search_list: payload }))

    .case(change_mail_template_search, (state, payload) => ({ ...state, mail_template_search: payload }))

    .case(change_mail_template_parameters, (state, payload) => ({ ...state, mail_template_parameters: payload }))

    .case(change_mail_template_form, (state, payload) => ({ ...state, mail_template_form: payload }))
    .case(change_mail_template_list, (state, payload) => ({ ...state, mail_template_list: payload }))
    .case(change_mail_template_list_search, (state, payload) => ({ ...state, mail_template_list_search: payload }))
    .case(change_mail_template_category_default, (state, payload) => ({ ...state, mail_template_category_default: payload }))

    .case(change_auto_send_mail_template_category_list, (state, payload) => ({ ...state, auto_send_mail_template_category_list: payload }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const mailtemplateSaga = [


    takeEvery('GET_MAIL_TEMPLATES', handle_get_mail_templates),

    takeEvery('GET_MAIL_TEMPLATE_CATEGORIES', handle_get_mail_template_categories),

    takeEvery('GET_MAIL_TEMPLATES_SEARCH_LIST', handle_get_mail_templates_search_list),



    takeEvery('UPDATE_MAIL_TEMPLATES', handle_update_mail_templates),
    takeEvery('DELETE_MAIL_TEMPLATES', handle_delete_mail_templates),

    takeEvery('ADD_MAIL_TEMPLATES_CATEGORY', handle_add_mail_templates_category),
    takeEvery('DELETE_MAIL_TEMPLATES_CATEGORY', handle_delete_mail_templates_category),

    takeEvery('ADD_MAIL_TEMPLATES', handle_add_mail_templates),

    takeEvery('UPDATE_MAIL_TEMPLATES_CATEGORY', handle_update_mail_templates_category),


    takeEvery('GET_MAIL_TEMPLATE_DETAILS', handle_get_mail_template_details),


    takeEvery('GET_MAIL_TEMPLATE_PARAMETERS', handle_get_mail_template_parameters),

    takeEvery('GET_MAIL_TEMPLATE_LIST', handle_get_mail_template_list),
    takeEvery('GET_MAIL_TEMPLATE_LIST_SEARCH', handle_get_mail_template_list_search),
    takeEvery('GET_MAIL_TEMPLATE_DETAILS_NEW', handle_get_mail_template_details_new),
    takeEvery('INSERT_MAIL_TEMPLATE_DETAILS', handle_insert_mail_template_details),
    takeEvery('UPDATE_MAIL_TEMPLATE_DETAILS', handle_update_mail_template_details),
    takeEvery('DELETE_MAIL_TEMPLATE_DETAILS', handle_delete_mail_template_details),
    takeEvery('INSERT_MAIL_TEMPLATE_REPLACE_AND_SET', handle_insert_mail_template_replace_and_set),
    takeEvery('UPDATE_MAIL_TEMPLATE_LIST_SORT', handle_update_mail_template_list_sort),

    takeEvery('GET_AUTO_SEND_MAIL_TEMPLATE_CATEGORY_LIST', handle_get_auto_send_mail_template_category_list),
    takeEvery('INSERT_AUTO_SEND_MAIL_TEMPLATE_CATEGORY', handle_insert_auto_send_mail_template_category),
    takeEvery('UPDATE_AUTO_SEND_MAIL_TEMPLATE_CATEGORY', handle_update_auto_send_mail_template_category),
    takeEvery('DELETE_AUTO_SEND_MAIL_TEMPLATE_CATEGORY', handle_delete_auto_send_mail_template_category),
    takeEvery('UPDATE_AUTO_SEND_MAIL_TEMPLATE_CATEGORY_SORT', handle_update_auto_send_mail_template_category_sort)
];


function* handle_get_mail_template_categories(action: any) {


    const { payload, error } = yield call(MailTemplateCategoriesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        const mail_template_categories = payload.map((item: any, i: number) => ({
            label: item.category_name,
            value: item.id,
            display: item.display,
            section_id: item.section_id
        }))
        // 成功時アクション呼び出し
        yield put(change_mail_template_categories(mail_template_categories))

        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }
};


function* handle_get_auto_send_mail_template_category_list(action: any):any {

    const state = yield select();

    let mail_template_form = state.mailtemplate.mail_template_form

    const { payload, error } = yield call(MailTemplateCategoriesApi.auto_send_list, {section_id: mail_template_form.section_id});

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // const mail_template_categories = payload.map((item: any, i: number) => ({
        //     label: item.category_name,
        //     value: item.id,
        //     display: item.display,
        // }))
        // // 成功時アクション呼び出し
        // yield put(change_mail_template_categories(mail_template_categories))

        // 成功時アクション呼び出し
        yield put(change_auto_send_mail_template_category_list(payload))

    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }
};

function* handle_insert_auto_send_mail_template_category(action: any) :any {

    const state = yield select();

    let auto_send_mail_template_category_list = state.mailtemplate.auto_send_mail_template_category_list
    let mail_template_categories = state.mailtemplate.mail_template_categories

    const { payload, error } = yield call(MailTemplateCategoriesApi.auto_send_insert, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し

        yield put(change_auto_send_mail_template_category_list(auto_send_mail_template_category_list.concat(payload)))
        // yield put(get_mail_templates())

        // 成功時アクション呼び出し
        yield put(change_mail_template_categories(mail_template_categories.concat({label: payload.category_name, value:payload.id, display: payload.display, section_id: payload.section_id})))

    } else {

    }

}

function* handle_update_auto_send_mail_template_category(action: any) :any {

    const state = yield select();

    let auto_send_mail_template_category_list = state.mailtemplate.auto_send_mail_template_category_list
    let mail_template_categories = state.mailtemplate.mail_template_categories

    const { payload, error } = yield call(MailTemplateCategoriesApi.auto_send_update, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し

        auto_send_mail_template_category_list = auto_send_mail_template_category_list.map(function (value: any) {
            if (value.id === payload.id) {
                value.category_name = payload.category_name
            }
            return value
        })
        yield put(change_auto_send_mail_template_category_list(auto_send_mail_template_category_list))


        mail_template_categories = mail_template_categories.map(function (value: any) {
            if (value.value === payload.id) {
                value.label = payload.category_name
            }
            return value
        })
        // 成功時アクション呼び出し
        yield put(change_mail_template_categories(mail_template_categories))


    } else {

    }

}


function* handle_delete_auto_send_mail_template_category(action: any) :any {

    const state = yield select();

    let auto_send_mail_template_category_list = state.mailtemplate.auto_send_mail_template_category_list
    let mail_template_categories = state.mailtemplate.mail_template_categories

    const { payload, error } = yield call(MailTemplateCategoriesApi.auto_send_delete, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し

        yield put(change_auto_send_mail_template_category_list(auto_send_mail_template_category_list.filter((a:any)=>a.id != payload.id)))
        yield put(change_mail_template_categories(mail_template_categories.filter((a:any)=>a.value != payload.id)))

    } else {

    }

}


function* handle_update_auto_send_mail_template_category_sort(action: any):any {

    const state = yield select();
    let auto_send_mail_template_category_list = state.mailtemplate.auto_send_mail_template_category_list
    let mail_template_categories = state.mailtemplate.mail_template_categories
    let mail_template_form = state.mailtemplate.mail_template_form

    
    const { payload, error } = yield call(MailTemplateCategoriesApi.auto_send_update_sort, auto_send_mail_template_category_list);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        
        // yield put(get_mail_template_list())
        yield put(change_auto_send_mail_template_category_list(payload.filter((a:any) => a.section_id == mail_template_form.section_id)))

        const mail_template_categories2 = payload.map((item: any, i: number) => ({
            label: item.category_name,
            value: item.id,
            display: item.display,
            section_id: item.section_id,
        }))

        yield put(change_mail_template_categories(mail_template_categories.filter((a:any)=>a.display != 200).concat(mail_template_categories2)))


    } else {

    }

    
}



function* handle_get_mail_templates(action: any) {



    const { payload, error } = yield call(MailTemplatesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        yield put(get_mail_templates_success(payload))

        yield put(get_mail_templates_search_list())

        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}




function* handle_get_mail_template_parameters(action: any) {


    const { payload, error } = yield call(mail_template_parameters_api.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        if(action.payload){
            if(action.payload.is_text) {
                yield put(change_mail_template_parameters(payload.filter((a:any)=>a.display== action.payload.display && a.is_text == action.payload.is_text)))
            }else{
                yield put(change_mail_template_parameters(payload.filter((a:any)=>a.display== action.payload.display)))
            }
            
        }else{
            // 成功時アクション呼び出し
            yield put(change_mail_template_parameters(payload))
        }
        


        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}


function* handle_get_mail_templates_search_list(action: any): any {

    const state = yield select();

    let mail_templates_search_list = state.mailtemplate.mail_templates.filter((a: any) => a.mail_template_category_id === state.mailtemplate.mail_template_search.mail_template_category_id)
    if (state.mailtemplate.mail_template_search.section_id !== 10 && state.mailtemplate.mail_template_search.section_id !== 26 && state.mailtemplate.mail_template_search.section_id !== 37 && state.mailtemplate.mail_template_search.section_id !== 47 && state.mailtemplate.mail_template_search.section_id !== 55 && state.mailtemplate.mail_template_search.section_id !== 25) {
        mail_templates_search_list = mail_templates_search_list.filter((a: any) => a.section_id === state.mailtemplate.mail_template_search.section_id)
    }
    // 成功時アクション呼び出し
    yield put(change_mail_templates_search_list(mail_templates_search_list))


}

function* handle_get_mail_template_details(action: any): any {
    const state = yield select();
    let detail = state.mailtemplate.mail_templates.filter((a: any) => a.id === action.payload)
    console.log(detail[0])
    if (detail[0]) {
        yield put(change_mail_template_details(detail[0]))
    } else {
        yield put(change_mail_template_details([]))
    }
    // サーバーからのレスポンスデータによる分岐処理
    // if (payload && !error) {
    //     // 成功時アクション呼び出し



    // } else {

    // }

}

function* handle_update_mail_templates(action: any): any {

    const state = yield select();

    const { payload, error } = yield call(MailTemplatesApi.update, state.mailtemplate.mail_template_details);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // yield put(get_mail_templates_success(payload))

        yield put(get_mail_templates())
        alert("保存されました")
        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}

function* handle_delete_mail_templates(action: any) {



    const { payload, error } = yield call(MailTemplatesApi.deletetemplate, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // yield put(get_mail_templates_success(payload))

        yield put(get_mail_templates())

        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {
        alert(error)
        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}



function* handle_add_mail_templates(action: any) {



    const { payload, error } = yield call(MailTemplatesApi.insert, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // yield put(add_mail_templates_success(payload))
        yield put(get_mail_templates())
        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}

function* handle_add_mail_templates_category(action: any) {

    console.log(action.payload)

    const { payload, error } = yield call(MailTemplatesApi.insertcategory, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // yield put(get_mail_templates_success(payload))
        yield put(get_mail_templates())

        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}

function* handle_update_mail_templates_category(action: any) {

    console.log(action.payload)

    const { payload, error } = yield call(MailTemplatesApi.updatecategory, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // yield put(get_mail_templates_success(payload))
        yield put(get_mail_templates())

        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}

function* handle_delete_mail_templates_category(action: any) {

    console.log(action.payload)

    const { payload, error } = yield call(MailTemplatesApi.deletecategory, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // yield put(get_mail_templates_success(payload))
        yield put(get_mail_templates())

        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))
        // yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}

function* handle_get_mail_template_list(action: any): any {

    const state = yield select();
    const { payload, error } = yield call(MailTemplatesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        // 成功時アクション呼び出し
        yield put(change_mail_template_list(payload))

        if(action.payload == 1){//自動送信
            let mail_template_form = state.mailtemplate.mail_template_form
            mail_template_form.category = 76
            // alert(state.masterReducer.user_details.amSectionId)
            mail_template_form.section_id = state.masterReducer.user_details.amSectionId
            // mail_template_form.auto_send_mail_template_category_id = 
            yield put(change_mail_template_form(mail_template_form))
        }

        // let mail_template_form = state.mailtemplate.mail_template_form
        // mail_template_form.section_id = state.masterReducer.user_details.amSectionId

        yield put(get_mail_template_list_search(action.payload))

    } else {

    }

}

function* handle_update_mail_template_list_sort(action: any):any {

    const state = yield select();
    let mail_template_list_search = state.mailtemplate.mail_template_list_search
    
    const { payload, error } = yield call(MailTemplatesApi.update_sort, mail_template_list_search);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        
        yield put(get_mail_template_list())

    } else {

    }

    
}

function* handle_get_mail_template_list_search(action: any): any {


    const state = yield select();
    let mail_template_form = state.mailtemplate.mail_template_form
    let mail_template_list = state.mailtemplate.mail_template_list

    mail_template_list = mail_template_list.filter((a:any) => a.display == 100)

    if (mail_template_form.section_id == -1){
        mail_template_list = mail_template_list.filter((a:any) => !a.section_id)
    }else if (mail_template_form.section_id >= 1){
        // mail_template_list = mail_template_list.filter((a:any) => a.section_id == mail_template_form.section_id || !a.section_id)
        mail_template_list = mail_template_list.filter((a:any) => a.section_id == mail_template_form.section_id)
    }
    if (mail_template_form.user_id){
        mail_template_list = mail_template_list.filter((a:any) => a.user_id == mail_template_form.user_id)
    }
    if (mail_template_form.category){
        mail_template_list = mail_template_list.filter((a:any) => a.mail_template_category_id == mail_template_form.category)

        if (mail_template_form.category == 76 && mail_template_form.auto_send_mail_template_category_id > 0){
            mail_template_list = mail_template_list.filter((a:any) => a.auto_send_mail_template_category_id == mail_template_form.auto_send_mail_template_category_id)
        }
    }

    yield put(change_mail_template_list_search(mail_template_list))

}

function* handle_get_mail_template_details_new(action: any): any {
    const state = yield select();
    
    yield put(change_mail_template_details([]))

    let detail = state.mailtemplate.mail_template_list.filter((a: any) => a.id === action.payload)
    console.log(detail[0])
    let subject = detail[0].subject

    detail[0].subject = subject ? subject : ""

    detail[0].tab = detail[0].send_type == 0 || detail[0].send_type == 2 ? 0 : 1
    if (detail[0]) {
        yield put(change_mail_template_details(JSON.parse(JSON.stringify(detail[0]))))
    } else {
        yield put(change_mail_template_details([]))
    }
    // サーバーからのレスポンスデータによる分岐処理
    // if (payload && !error) {
    //     // 成功時アクション呼び出し



    // } else {

    // }

}

function* handle_insert_mail_template_details(action: any):any {

    const state = yield select();

    const { payload, error } = yield call(MailTemplatesApi.insert_base, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        let mail_template_list = state.mailtemplate.mail_template_list
        // 成功時アクション呼び出し
        yield put(change_mail_template_list(mail_template_list.concat(payload)));
        yield put(get_mail_template_list_search())


        yield put(change_mail_template_details(JSON.parse(JSON.stringify(payload[0]))))
        

    } else {

    }

}

function* handle_update_mail_template_details(action: any):any {

    const state = yield select();
    let mail_template_details = state.mailtemplate.mail_template_details
    
    const { payload, error } = yield call(MailTemplatesApi.update_base, mail_template_details);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        let mail_template_list = state.mailtemplate.mail_template_list
        // 成功時アクション呼び出し

        // 一覧の情報更新
        mail_template_list = mail_template_list.map(function (value: any) {
        if (value.id === mail_template_details.id) {
            value.template_name = payload.template_name
            value.subject = payload.subject
            value.body_all = payload.body_all
            value.body_all_text = payload.body_all_text
            value.section_id = payload.section_id
            value.user_id = payload.user_id
            value.is_deleted = payload.is_deleted
            value.display = payload.display
            value.mail_template_category_id = payload.mail_template_category_id
            value.send_type = payload.send_type
            value.auto_send_mail_template_category_id = payload.auto_send_mail_template_category_id
            value.day_term = payload.day_term
            value.time = payload.time

        }
        return value
      })
  
      //一覧から削除
      let result = mail_template_list.filter((value: any) => {
        return !value.is_deleted || value.is_deleted == 0;
      });
      mail_template_list = result




        yield put(change_mail_template_list(mail_template_list));
        yield put(get_mail_template_list_search())

    } else {

    }

    
}


function* handle_delete_mail_template_details(action: any):any {

    const state = yield select();
    let mail_template_details = state.mailtemplate.mail_template_details
    
    const { payload, error } = yield call(MailTemplatesApi.deletetemplate, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        let mail_template_list = state.mailtemplate.mail_template_list
        // 成功時アクション呼び出し

      //一覧から削除
      let result = mail_template_list.filter((value: any) => {
        return !(value.id == action.payload);
      });
      mail_template_list = result




        yield put(change_mail_template_list(mail_template_list));
        yield put(get_mail_template_list_search())

    } else {

    }
}

function* handle_insert_mail_template_replace_and_set(action: any):any {

    const state = yield select();
    let mail_template_details = state.mailtemplate.mail_template_details
    let responsedetail = state.responsesReducer.responsedetail
    let send_mail_details = {...state.mailReducer.send_mail_details}
    
    const { payload, error } = yield call(MailTemplatesApi.set, {response_id :responsedetail.id, template_id:action.payload.template_id});

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


    if(action.payload.uwagaki){
        send_mail_details.html = payload.body_all
        send_mail_details.htmlUtf8 = payload.body_all
        send_mail_details.bodyUtf8 = payload.body_all_text;
        send_mail_details.body = payload.body_all_text;
        send_mail_details.subject = payload.subject;
    }else {
        

        if(mail_template_details.tab == 0){//HTML
        
            // let editor = action.payload.window.CKEDITOR.instances[Object.keys(action.payload.window.CKEDITOR.instances)[0]];
            // editor.insertHtml(payload.html_utf8);
            if(action.payload.uwagaki){
                // editor.setData(payload.body_all);
                send_mail_details.subject = payload.subject;
            }else{


                const editorInstance = window.editorInstance2;

                // editorInstance と model、document の null チェック
                if (editorInstance && editorInstance.model && editorInstance.model.document) {
                    editorInstance.model.change(writer => {
                        // HTMLを含む要素を挿入する '<p><strong>HTMLで挿入されたテキスト</strong></p>'

                        // console.log('挿入データ:',payload.body_all)
                        // HTMLを含む要素を挿入する
                        const viewFragment = editorInstance.data.processor.toView('<div>' + payload.body_all + '</div>');
                        const modelFragment = editorInstance.data.toModel(viewFragment);
                        editorInstance.model.insertContent(modelFragment, editorInstance.model.document.selection);

                    });

                    // エディタの全内容を取得
                    const editorContent = editorInstance.getData();

                    send_mail_details.htmlUtf8 = editorContent

                } else {
                    console.error('Editor instance or model or document is not available');
                }

            }

        }else{

            if(action.payload.uwagaki){
                // editor.setData(payload.body_all);
                // send_mail_details.subject = payload.subject;
                send_mail_details.bodyUtf8 = payload.body_all_text;
                send_mail_details.body = payload.body_all_text;

                // send_mail_details.html = payload.body_all
                // send_mail_details.htmlUtf8 = payload.body_all
                // let editor = action.payload.window.CKEDITOR.instances[Object.keys(action.payload.window.CKEDITOR.instances)[0]];
                // editor.setData(payload.body_all);
                // send_mail_details.subject = payload.subject;
            }else{

                // 現在のテキストを取得
                const currentText = send_mail_details.bodyUtf8;

                // カーソル位置に文字を挿入
                const newText = currentText.substring(0, action.payload.selectionStart) + payload.body_all_text + currentText.substring(action.payload.selectionStart);

                // テキストフィールドを更新
                send_mail_details.bodyUtf8 = newText;
                send_mail_details.body = newText;


                // editor.insertHtml(payload.body_all);
            }

        }
            
    }
    yield put(Mail.change_send_mail_details(send_mail_details))
    // this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)
    // this.setState({ "modalNewTemplateSelect": false })

    } else {

    }
}
