// const authentication = require('react-azure-adb2c').default;
const authentication = require('../../../react-azure-adb2c2').default;


export const detail = (value: any) => {

    // let url = `http://localhost:5000/v1/rent/m/corporates/detail/` + value;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/corporates/detail/` + value;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const list = (value: any) => {

    // let url = `http://localhost:5000/v1/rent/m/corporates/list`;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/corporates/list`;

    // let url = process.env.REACT_APP_API_OPENROOM_EX_URL + `/v1/web/blogs/m/themelist`;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const list_csv = (values: any) => {

    // let url = `http://localhost:5000/v1/rent/m/corporates/listcsv`;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/corporates/listcsv`;
    // alert(url)
  
    const token = authentication.getAccessToken();
  
    return fetch(url, {
      method: 'post',
      body: JSON.stringify(values),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(response => response.blob())
    .then(payload => ({ payload }))
    // .then(res => res.json())
    // .then(payload => ({ payload }))
    .catch(error => ({ error }));
  
  };

export const update = (value: any) => {
    // alert( JSON.stringify(value))

    // let url = `http://localhost:5000/v1/rent/m/corporates/update/`+ value.id;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/corporates/update/`+ value.id;
    

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const update_partnership = (value: any) => {
    // alert( JSON.stringify(value))

    // let url = `http://localhost:5000/v1/rent/m/corporates/updatepartnership/`+ value.id;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/corporates/updatepartnership/`+ value.id;
    

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insert = (values: any) => {
    // alert(JSON.stringify(values))
// console.log(values)
    // const url = `http://localhost:5000/v1/rent/m/corporates/add/`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/corporates/add/`;


    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


export const search_list = (values: any) => {
    // const url = `http://localhost:5000/v1/rent/m/corporates/search`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/m/corporates/search`;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

// export const list = (values: any) => {


//     let url = `https://homestationapi.azurewebsites.net/api/RentContracts?moneyall=1`;

 




//     const token = authentication.getAccessToken();

//         return  fetch(url, {
//         method: 'get',
//         // body: JSON.stringify(search),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     }).then(res => res.json())
//     .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// };




// export const update = (values:any) => {

//     const token = authentication.getAccessToken();
//     const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     return fetch(url, {
//         method: 'put',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res)
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }



















// export const detail = (Id: number) => {

//             const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + Id;

//             const token = authentication.getAccessToken();

//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }



