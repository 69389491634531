import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
//const authentication = require('react-azure-adb2c').default;
//import { Dispatch } from 'redux';


import { call, put, takeEvery, select } from 'redux-saga/effects';

import * as sales_sheet_api from '../Api/sales_sheet_api';
import moment from 'moment';
// import * as UsersApi from './Api/UsersApi';
// import * as RentAgentsApi from './Api/RentAgentsApi';

///types


//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface DashBoardState {
    sales_sheet_form: any;
    // banks: any;
}
const initialState: DashBoardState = {

    sales_sheet_form: {
        section_id: 0,
        start: moment().format("YYYY/MM/1"),
        end: moment().format("YYYY/MM/DD")
    },

    // users: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();

export const download_sales_sheet = actionCreator<any>('DOWNLOAD_SALES_SHEET');

export const change_sales_sheet_form = actionCreator<any>('CHANGE_SALES_SHEET_FORM');

// export const get_rent_contract_monies_success = actionCreator("GET_RENT_CONTRACT_MONIES_SUCCESS");
// export const get_rent_contract_monies_failure = actionCreator("GET_RENT_CONTRACT_MONIES_FAILURE");

// export const get_rent_contract_accounting = actionCreator("GET_RENT_CONTRACT_ACCOUNTING");


// export const get_banks = actionCreator('GET_BANKS');
// export const get_banks_success = actionCreator("GET_BANKS_SUCCESS");
// export const get_banks_failure = actionCreator("GET_BANKS_FAILURE");

// export const getUsers = actionCreator('USERS_REQUEST');
// export const getUsersSuccess = actionCreator("USERS_SUCCESS");
// export const getUsersFailure = actionCreator("USERS_FAILURE");

// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const dashboardReducer = reducerWithInitialState(initialState)
    .case(change_sales_sheet_form, (state, payload) => ({ ...state, sales_sheet_form: payload }))
// .case(get_rent_contract_monies_success, (state, payload) => ({ ...state, rent_contract_monies: payload }));



//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const dashboardSaga = [takeEvery('DOWNLOAD_SALES_SHEET', handle_download_sales_sheet),
    // takeEvery('GET_RENT_CONTRACT_ACCOUNTING', handle_get_rent_contract_accounting),
    // takeEvery('GET_BANKS', handle_get_banks),
    // takeEvery('USERS_REQUEST', handleUsersRequest),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_download_sales_sheet(action: any): any {
    const state = yield select();
    let sales_sheet_form = state.dashboard.sales_sheet_form
    sales_sheet_form.start = moment(sales_sheet_form.start).format("YYYY/MM/DD")
    sales_sheet_form.end = moment(sales_sheet_form.end).format("YYYY/MM/DD")
    // alert(moment(sales_sheet_form.end).format("YYYY/MM/DD"))
    const { payload, error } = yield call(sales_sheet_api.sales_sheet_api, sales_sheet_form);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = "賃貸日報.xlsx";
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();
        //    yield put(get_rent_contract_monies_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}



// function* handle_get_rent_contract_accounting(action: any) {

//     const { payload, error } = yield call(RentContractAccountingApi.list, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         // 成功時アクション呼び出し
//         console.log(payload)
//         const a = document.createElement('a');
//         // ダウンロードされるファイル名
//         a.download = "会計.csv";
//         a.href = URL.createObjectURL(payload);
//         // ダウンロード開始
//         a.click();
//         //    yield put(get_rent_contract_monies_success(payload));

//     } else {
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }



// function* handle_get_banks(action: any) {
//     console.log(action.payload)

//     const { payload, error } = yield call(BanksApi.list, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//         console.log(payload)

//         yield put(get_banks_success(payload));
//         //    yield put(get_rent_contract_monies_success(payload));

//     } else {

//         alert(error)
//         //    // 失敗時アクション呼び出し
//         //    yield put(loginFailure(payload));
//     }
// }





// function* handleUsersRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(UsersApi.UsersLIst, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getUsersSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentAgentsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentAgentsApi.RentAgentsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentAgentsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentMReasonsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentMReasonsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }