import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';

import EditIcon from '@mui/icons-material/Edit';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import * as YoyakuParking from './Saga/YoyakuParking';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Grid from '@mui/material/Grid';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
var moment = require('moment-timezone');


const phoneNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];


const mobileNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex][column.key]}
  </Cell>;

const ContractDate = ({ column, props, rowIndex }) => <Cell style={{ whiteSpace: "nowrap" }} >
  {props.tm_yoyakuparkingarea_list[rowIndex].contraction_date ? moment(props.tm_yoyakuparkingarea_list[rowIndex].contraction_date).format('YYYY/MM/DD') : ""
  }

</Cell>;



function age(birthDate) {
  birthDate = new Date(birthDate);
  // 文字列に分解
  const y2 = birthDate.getFullYear().toString().padStart(4, '0');
  const m2 = (birthDate.getMonth() + 1).toString().padStart(2, '0');
  const d2 = birthDate.getDate().toString().padStart(2, '0');

  // 今日の日付
  const today = new Date();
  const y1 = today.getFullYear().toString().padStart(4, '0');
  const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
  const d1 = today.getDate().toString().padStart(2, '0');

  // 引き算
  const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
  return age;

}



const Text = ({ column, props, rowIndex, change_coloumn }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex].selectcontract ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="color"
      value={props.tm_yoyakuparkingarea_list[rowIndex][column.key]}
      onChange={(e) => change_coloumn({ row: rowIndex, name: column.key, value: e.target.value })}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      // value={monies[rowIndex][column.key]}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.tm_yoyakuparkingarea_list[rowIndex][column.key]}

  </Cell>;

const SelectContract = ({ column, props, rowIndex, change_coloumn }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
        {props.tm_yoyakuparkingarea_list[rowIndex].selectcontract ? <Tooltip title="選択" >
        <IconButton size="small" aria-label="delete" onClick={() => change_coloumn({ row: rowIndex, name: "selectcontract", value: !props.tm_yoyakuparkingarea_list[rowIndex].selectcontract })}>
            <CheckCircleIcon color="primary" />
        </IconButton>
        </Tooltip>

        : <Tooltip title="申込" >
            <IconButton size="small" aria-label="delete" onClick={() => change_coloumn({ row: rowIndex, name: "selectcontract", value: !props.tm_yoyakuparkingarea_list[rowIndex].selectcontract })}>
            <IndeterminateCheckBoxIcon color="secondary" />
            </IconButton>
        </Tooltip>}
        </Cell>;

const Nyuukyo = ({ column, props, rowIndex, monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex].nyuukyo_kanou_year ? props.tm_yoyakuparkingarea_list[rowIndex].nyuukyo_kanou_year + '/' + props.tm_yoyakuparkingarea_list[rowIndex].nyuukyo_kanou_month + '/' + props.tm_yoyakuparkingarea_list[rowIndex].nyuukyo_kanou_day : ""}
  </Cell>;

const KuushitsuJoukyou = ({ column, props, rowIndex, monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == -2 ? "キャンセル" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 1 ? <span style={{fontWeight:"bold"}}>募集中</span> : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 2 ? "押さえ" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 3 ? "申込み" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 4 ? "入居中" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 5 ? "解約予定" : props.tm_yoyakuparkingarea_list[rowIndex].kuushitsu_joukyou_no == 6 ? "募集停止" : "f"}
  </Cell>;

const KanouKbn = ({ column, props, rowIndex, monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.tm_yoyakuparkingarea_list[rowIndex].Kanou_Kbn_Name}
  </Cell>;
const Chinryou = ({ column, props, rowIndex, monies }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.tm_yoyakuparkingarea_list[rowIndex].Chinryou == 0 ? '-' : props.tm_yoyakuparkingarea_list[rowIndex].Is_Kazei ? String(Math.round(Number(props.tm_yoyakuparkingarea_list[rowIndex].Chinryou)*1.1).toLocaleString())+'円(内税'+String(Math.round(Number(props.tm_yoyakuparkingarea_list[rowIndex].Chinryou)*0.1).toLocaleString())+'円)' : props.tm_yoyakuparkingarea_list[rowIndex].Chinryou + '円'}
</Cell>;
const Shikikin = ({ column, props, rowIndex, monies }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.tm_yoyakuparkingarea_list[rowIndex].Shiki_Seikyuu_Gaku_Tsuki_Suu ? props.tm_yoyakuparkingarea_list[rowIndex].Shiki_Seikyuu_Gaku_Tsuki_Suu + 'ヶ月' : props.tm_yoyakuparkingarea_list[rowIndex].Shikikin ? props.tm_yoyakuparkingarea_list[rowIndex].Shikikin + '円' : '-'}
</Cell>;
const Reikin = ({ column, props, rowIndex, monies }) =>
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.tm_yoyakuparkingarea_list[rowIndex].Rei_Seikyuu_Gaku_Tsuki_Suu ? props.tm_yoyakuparkingarea_list[rowIndex].Rei_Seikyuu_Gaku_Tsuki_Suu + 'ヶ月' : props.tm_yoyakuparkingarea_list[rowIndex].Reikin ? props.tm_yoyakuparkingarea_list[rowIndex].Reikin + '円': '-'}
</Cell>;

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

const columns = [
  { key: 'selectcontract', name: '申込', width: 50 },
  { key: 'p_name', name: '駐車場名', width: 200 },
  { key: 'pa_name', name: '区画番号', width: 80},
  { key: 'kuushitsu_joukyou_no', name: '募集状況', width: 100 },
  { key: 'nyuukyo_kanou', name: '入居可能日', width: 90 },
  { key: 'kanou_kbn', name: '可能区分', width: 90 },
  { key: 'chinryou', name: '賃料', width: 180 },
  { key: 'shikikin', name: '敷金', width: 100 },
  { key: 'reikin', name: '礼金', width: 100 },
]







class CustomerList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      search:'',
      modal_area_more:false,
      DisabledState:true,
      changedata:{},
      columnWidths: {
        PId: 100,
        PName: 200,
        PKana: 200,
        Zip:130,
        address:200,
        kukaku:100,
    },
    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    // this.props.get_tm_yoyakuparkingarea_detail_list();
    // this.props.send_tm_yoyakuparkingarea_select_list();
    this.add_new = this.add_new.bind(this);
    this.change_coloumn = this.change_coloumn.bind(this);
    this.handlePSearch = this.handlePSearch.bind(this);
    this.SearchWord = this.SearchWord.bind(this);
    this.handleSelect = this.handleSelect.bind(this)
  }





  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    this.setState(({ columnWidths }) => ({
        columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
        },
    }));
}
  handlePSearch(state){
    console.log(this.state.search)
    this.props.get_tm_yoyakuparkingarea_new_create(this.state.search)
  }
  SearchWord(state){
      console.log(state.target.value)
    this.setState({
        search: state.target.value
    });
  }
  handleSelect(state){
      console.log("selected parking",state)
      let rent_m_corporates = {}
        rent_m_corporates.P_Data = state
        // rent_m_corporates = value
        var parea = this.props.tm_yoyakuparkingarea_new_create_list.PA_List.filter(x => x.p_id == state.P_Id)
        // this.props.change_rent_m_corporates(rent_m_corporates);
        this.setState({
            change: !this.state.change
        });
        this.props.change_yoyaku_parking(rent_m_corporates)
        this.props.change_tm_yoyakuparkingarea_list(parea)
        this.props.change_yoyaku_parking_top_open(true)
    //   this.props.tm_yoyakuparkingarea_new_create_list
  }
  add_new() {
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.family.push({
      name: "",
      relations: "",
      sex: 0,
      birthday: null,
      workplace: '',
      tel: '',
      nationality: '',
      remarks: '',
    })
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms)
    this.setState({ reflash: !this.state.reflash })
  }




  change_coloumn(set) {
    let tm_yoyakuparkingarea_list = this.props.tm_yoyakuparkingarea_list
    // this.props.tm_parkingarea_checked = !this.props.tm_parkingarea_checked
    if(set.value === true){
        this.setState({ DisabledState: false })
    }
    if(set.value === false && tm_yoyakuparkingarea_list.filter(a=> a.selectcontract == true).length == 1){
        this.setState({ DisabledState: true })
    }
    console.log(tm_yoyakuparkingarea_list.filter(a=> a.selectcontract == true).length)
    if(set.name == "selectcontract" && set.value == true){
        // if(1 <= tm_yoyakuparkingarea_list.filter(a=> a.selectcontract == true).length){
        this.setState({ modal_area_more: true })
        // return
    //   }
    }
    tm_yoyakuparkingarea_list[set.row][set.name] = set.value
    this.setState({ reflash: !this.state.reflash })
  }

  render() {
    // let hiwari = []
    // const { handleSubmit } = this.props;
    // let paringimg_p_id = ""
    // let paringimg = []
    console.log(this.props.tm_yoyakuparkingarea_new_create_list,"わ")
    let parkinginfo = this.props.tm_yoyakuparkingarea_new_create_list.P_List?this.props.tm_yoyakuparkingarea_new_create_list.P_List:[]
    console.log(parkinginfo)
    // this.props.tm_yoyakuparkingarea_list.map(function (value) {

    //   if (value.p_id === paringimg_p_id) {

    //   } else {
    //     paringimg_p_id = value.p_id

    //     paringimg.push(value)
    //   }

    // })
    // let info_status = false
    return (
        <div id="contract_car" style={{maxHeight:"90%"}}>
          <Grid >
              <TextField style={{ float: "left"  , margin: "10px 10px" ,width:700}} onChange={this.SearchWord} id="search standard-basic" label="駐車場名" name="search" variant="standard" InputLabelProps={{ shrink: true }} />
              <Button style={{ float: "left", margin: 5 }} size="small" variant="contained" color="primary" onClick={this.handlePSearch}>
                    検索
                </Button>
          </Grid>
          {parkinginfo.length > 0  ?
            <Table 
                rowHeight={34}
                rowsCount={parkinginfo.length}
                width={window.innerWidth - 100}
                height={window.innerHeight - 280}
                headerHeight={40}
                onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                isColumnResizing={false}
            >

                <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} aria-label="edit" size="small" onClick={() => { this.handleSelect(parkinginfo[rowIndex] ) }}><EditIcon /></IconButton></Cell>)} width={40} />
                <Column width={this.state.columnWidths.PId} isResizable={true} columnKey="PId" header={<Cell>駐車場ID</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props} >{parkinginfo[rowIndex].P_Id}</Cell>)} />
                <Column width={this.state.columnWidths.PName} isResizable={true} columnKey="PName" header={<Cell>駐車場名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{parkinginfo[rowIndex].P_Name}</Cell>)} />
                <Column width={this.state.columnWidths.PKana} isResizable={true} columnKey="PKana" header={<Cell>駐車場名カナ</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{parkinginfo[rowIndex].P_Kana}</Cell>)} />
                <Column width={this.state.columnWidths.Zip} isResizable={true} columnKey="Zip" header={<Cell>郵便番号</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{parkinginfo[rowIndex].Zip}</Cell>)} />
                <Column width={this.state.columnWidths.address} isResizable={true} columnKey="address" header={<Cell>住所</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{parkinginfo[rowIndex].address}</Cell>)} />
                <Column width={this.state.columnWidths.kukaku} isResizable={true} columnKey="kukaku" header={<Cell>総区画数</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{parkinginfo[rowIndex].Soukukakusuu}</Cell>)} />
            </Table> : ''}



        
      </div>
      
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    // rent_contract_details: state.yoyakuparking.rent_contract_details,
    // rent_contract_family_editor_disp: state.contracts.rent_contract_family_editor_disp,
    // rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    // t_parking_keiyaku_list: state.contracts.t_parking_keiyaku_list,
    yoyaku_parking: state.yoyakuparking.yoyaku_parking,
    tm_yoyakuparkingarea_list: state.yoyakuparking.tm_yoyakuparkingarea_list,
    tm_yoyakuparkingarea_new_create_list:state.yoyakuparking.tm_yoyakuparkingarea_new_create_list,
    tm_parkingarea_checked:false,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_yoyaku_parking_top_open(state) {
        dispatch(YoyakuParking.change_yoyaku_parking_top_open(state))
    },
    change_yoyaku_parking(state){
        dispatch(YoyakuParking.change_yoyaku_parking(state))
    },
    change_tm_yoyakuparkingarea_list(state){
        dispatch(YoyakuParking.change_tm_yoyakuparkingarea_list(state))
    },
    get_tm_yoyakuparkingarea_detail_list(state) {
        dispatch(YoyakuParking.get_tm_yoyakuparkingarea_detail_list(state))
    },
    get_tm_yoyakuparkingarea_new_create(state) {
        dispatch(YoyakuParking.get_tm_yoyakuparkingarea_new_create(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);