import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import './Css/Grid.css';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';

var moment = require('moment-timezone');
moment.locale("ja", {
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});
var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);
    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}

const columns =
  [
    { key: 'date', name: '反響日', width: 100, resizable: true },
    { key: 'created_at', name: '登録日時', width: 150, resizable: true },
    { key: 'way', name: '経路', width: 100, resizable: true },
    { key: 'name1', name: '名前', width: 150, resizable: true },
    { key: 'rank', name: 'ランク', width: 50, resizable: true },
    { key: 'Tatemono_Name', name: '反響物件', width: 200, resizable: true },
    { key: 'nickname', name: '担当', width: 75, resizable: true },
    { key: 'telmail', name: '追客', width: 85, resizable: true },
    { key: 'one', name: '1回目', width: 75, resizable: true },
    { key: 'two', name: '2回目', width: 75, resizable: true },
    { key: 'three', name: '3回目', width: 75, resizable: true },
    { key: 'four', name: '4回目', width: 75, resizable: true },
    { key: 'five', name: '5回目', width: 75, resizable: true },
    { key: 'six', name: '6回目', width: 75, resizable: true },
    { key: 'seven', name: '7回目', width: 75, resizable: true },
    { key: 'eight', name: '8回目', width: 75, resizable: true },
    { key: 'gap', name: '初回対応時間', width: 220, resizable: true },
  ]

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props[rowIndex][column.key]}
  </Cell>

const Tanto = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <div>
      {props[rowIndex].Tenpo_Name_Short}
    </div>
    <div>
      {props[rowIndex].nickname}
    </div>
  </Cell>

const Gap = ({ column, props, rowIndex }) =>
  (() => {
    if (props[rowIndex].resmail_ps[0]) {

      var telflag = 0;
      var lineflag = 0;

      var teldate1 = 0;
      var linedate1 = 0;
      var date1 = 0;
      var date2 = new Date(moment(props[rowIndex].resmail_ps[0].date).utc().format('YYYY-MM-DD HH:mm:ss'));
      var time1 = 0;

      if (props[rowIndex].mail_ps[0]) {
        date1 = new Date(moment(props[rowIndex].mail_ps[0].date).utc().format('YYYY-MM-DD HH:mm:ss'));
        time1 = date2.getTime() - date1.getTime();
      }

      // telの方がメールより先なら、telの差aaaにする
      if (props[rowIndex].tel_ps[0]) {
        teldate1 = new Date(moment(props[rowIndex].tel_ps[0].date).utc().format('YYYY-MM-DD HH:mm:ss'));
        if (teldate1 < date1 || date1 == 0) {
          time1 = date2.getTime() - teldate1.getTime();
          telflag = 1;
        }
      }
      // lineが先ならlineああaa
      if (props[rowIndex].line[0]) {
        linedate1 = new Date(moment(props[rowIndex].line[0].date).utc().format('YYYY-MM-DD HH:mm:ss'));
        if (linedate1 < date1 && linedate1 < teldate1) {
          time1 = date2.getTime() - linedate1.getTime();
          lineflag = 1;
        } else if (telflag == 0 && linedate1 < date1) {
          time1 = date2.getTime() - linedate1.getTime();
          lineflag = 1;
        } else if (telflag == 0 && date1 == 0) {
          time1 = date2.getTime() - linedate1.getTime();
          lineflag = 1;
        }
      }

      var notflag = 0;
      if (!props[rowIndex].mail_ps[0] && !props[rowIndex].tel_ps[0] && !props[rowIndex].line[0]) {
        notflag = 1;
      }

      var hour = 0;
      var min = 0;

      if (time1 !== 0) {
        hour = Math.floor((Math.abs(time1) / (60 * 1000)) / 60);
        min = Math.floor((Math.abs(time1) / (60 * 1000))) - (60 * hour);
      }

      if (notflag == 0) {
        return <Cell style={{ whiteSpace: "nowrap" }} >
          <div>
            【受信】{moment(props[rowIndex].resmail_ps[0].date).utc().format('YYYY-MM-DD(ddd) HH:mm:ss')}
          </div>
          {telflag == 1 || lineflag == 1 ?

            lineflag == 1 ?
              <div>
                【LINE】{props[rowIndex].line[0] ? moment(props[rowIndex].line[0].date).utc().format('YYYY-MM-DD(ddd) HH:mm:ss') : ""}
              </div> :
              <div>
                【TEL】{props[rowIndex].tel_ps[0] ? moment(props[rowIndex].tel_ps[0].date).utc().format('YYYY-MM-DD(ddd) HH:mm:ss') : ""}
              </div>

            :
            <div>
              【メール】{props[rowIndex].mail_ps[0] ? moment(props[rowIndex].mail_ps[0].date).utc().format('YYYY-MM-DD(ddd) HH:mm:ss') : ""}
            </div>}

          {teldate1 !== 0 && teldate1 < date1 || teldate1 !== 0 && date1 == 0 ?
            <div>
              【差異】{props[rowIndex].tel_ps[0] && props[rowIndex].tel_ps[0].date < props[rowIndex].resmail_ps[0].date ? "-" : ""}{hour}時間{min}分
            </div>
            :
            <div>
              【差異】{props[rowIndex].mail_ps[0] && props[rowIndex].mail_ps[0].date < props[rowIndex].resmail_ps[0].date ? "-" : ""}{hour}時間{min}分
            </div>}
        </Cell>
      } else { return "" }
    } else {
      return ""
    }
  })()


const Rank = ({ column, props, rowIndex }) =>
  (() => {
    var rank = "";
    if (props[rowIndex][column.key] == 1) {
      rank = "S";
    } else if (props[rowIndex][column.key] == 2) {
      rank = "A";
    } else if (props[rowIndex][column.key] == 3) {
      rank = "B";
    } else if (props[rowIndex][column.key] == 4) {
      rank = "C";
    }
    return <Cell style={{ whiteSpace: "nowrap" }} >
      {rank}
    </Cell>
  })()


const Way = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <div>
      {props[rowIndex][column.key]}
    </div>
    <div>
      {props[rowIndex].medea}
    </div>
  </Cell>

const Name = ({ column, props, rowIndex, url_open }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props[rowIndex]['end_response_date'] ? <div style={{ backgroundColor: "#a9a9a9" }}>【追客終了】</div> : ""}
    <div>{props[rowIndex][column.key]}</div>
    <div>
      <a style={{ cursor: "pointer" }} onClick={() => url_open(props[rowIndex].id)}>
        <i className="fas fa-external-link-alt"></i>
      </a>
    </div>
  </Cell>

const Dates = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).utc().format('YYYY-MM-DD') : ""}
  </Cell>

const Created_at = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props[rowIndex][column.key] ? moment(props[rowIndex][column.key]).utc().format('YYYY-MM-DD HH:mm:ss') : ""}
  </Cell>

const Tatemono = ({ column, props, rowIndex }) =>
  <Tooltip placement="bottom-start" title={props[rowIndex].tatemono[0] ?
    props[rowIndex].tatemono[0].Tatemono_Name + "-" + props[rowIndex].tatemono[0].Room_Name
    : ""}>
    <Cell style={{ whiteSpace: "nowrap" }} >
      {props[rowIndex].tatemono[0] ?
        props[rowIndex].tatemono[0].Tatemono_Name + "-" + props[rowIndex].tatemono[0].Room_Name
        : ""}
    </Cell>
  </Tooltip>


const TelMail = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 85 }}>追客TEL</td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 85 }} > 追客メール</td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 85 }} > 来店</td></tr>
    </table>
  </Cell >

const One = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].tel[0] ? (props[rowIndex].tel[0].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].tel[0] ? moment(props[rowIndex].tel[0].date).utc().format('MM/DD') : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].mail[0] ? (props[rowIndex].mail[0].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].mail[0] ? moment(props[rowIndex].mail[0].date).utc().format('MM/DD') : ""}
        {props[rowIndex].mail[0] && props[rowIndex].mail[0].mail_template_id !== 0 ? <i className="fas fa-robot"></i> : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].raiten[0] ? (props[rowIndex].raiten[0].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].raiten[0] ? moment(props[rowIndex].raiten[0].date).utc().format('MM/DD') : ""}
      </td></tr>
    </table>
  </Cell >

const Two = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].tel[1] ? (props[rowIndex].tel[1].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].tel[1] ? moment(props[rowIndex].tel[1].date).utc().format('MM/DD') : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].mail[1] ? (props[rowIndex].mail[1].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].mail[1] ? moment(props[rowIndex].mail[1].date).utc().format('MM/DD') : ""}
        {props[rowIndex].mail[1] && props[rowIndex].mail[1].mail_template_id !== 0 ? <i className="fas fa-robot"></i> : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].raiten[1] ? (props[rowIndex].raiten[1].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].raiten[1] ? moment(props[rowIndex].raiten[1].date).utc().format('MM/DD') : ""}
      </td></tr>
    </table>
  </Cell >


const Three = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].tel[2] ? (props[rowIndex].tel[2].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].tel[2] ? moment(props[rowIndex].tel[2].date).utc().format('MM/DD') : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].mail[2] ? (props[rowIndex].mail[2].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].mail[2] ? moment(props[rowIndex].mail[2].date).utc().format('MM/DD') : ""}
        {props[rowIndex].mail[2] && props[rowIndex].mail[2].mail_template_id !== 0 ? <i className="fas fa-robot"></i> : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].raiten[2] ? (props[rowIndex].raiten[2].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].raiten[2] ? moment(props[rowIndex].raiten[2].date).utc().format('MM/DD') : ""}
      </td></tr>
    </table>
  </Cell >


const Four = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].tel[3] ? (props[rowIndex].tel[3].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].tel[3] ? moment(props[rowIndex].tel[3].date).utc().format('MM/DD') : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].mail[3] ? (props[rowIndex].mail[3].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].mail[3] ? moment(props[rowIndex].mail[3].date).utc().format('MM/DD') : ""}
        {props[rowIndex].mail[3] && props[rowIndex].mail[3].mail_template_id !== 0 ? <i className="fas fa-robot"></i> : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].raiten[3] ? (props[rowIndex].raiten[3].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].raiten[3] ? moment(props[rowIndex].raiten[3].date).utc().format('MM/DD') : ""}
      </td></tr>
    </table>
  </Cell >


const Five = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].tel[4] ? (props[rowIndex].tel[4].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].tel[4] ? moment(props[rowIndex].tel[4].date).utc().format('MM/DD') : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].mail[4] ? (props[rowIndex].mail[4].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].mail[4] ? moment(props[rowIndex].mail[4].date).utc().format('MM/DD') : ""}
        {props[rowIndex].mail[4] && props[rowIndex].mail[4].mail_template_id !== 0 ? <i className="fas fa-robot"></i> : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].raiten[4] ? (props[rowIndex].raiten[4].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].raiten[4] ? moment(props[rowIndex].raiten[4].date).utc().format('MM/DD') : ""}
      </td></tr>
    </table>
  </Cell >

const Six = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].tel[5] ? (props[rowIndex].tel[5].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].tel[5] ? moment(props[rowIndex].tel[5].date).utc().format('MM/DD') : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].mail[5] ? (props[rowIndex].mail[5].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].mail[5] ? moment(props[rowIndex].mail[5].date).utc().format('MM/DD') : ""}
        {props[rowIndex].mail[5] && props[rowIndex].mail[5].mail_template_id !== 0 ? <i className="fas fa-robot"></i> : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].raiten[5] ? (props[rowIndex].raiten[5].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].raiten[5] ? moment(props[rowIndex].raiten[5].date).utc().format('MM/DD') : ""}
      </td></tr>
    </table>
  </Cell >


const Seven = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].tel[6] ? (props[rowIndex].tel[6].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].tel[6] ? moment(props[rowIndex].tel[6].date).utc().format('MM/DD') : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].mail[6] ? (props[rowIndex].mail[6].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].mail[6] ? moment(props[rowIndex].mail[6].date).utc().format('MM/DD') : ""}
        {props[rowIndex].mail[6] && props[rowIndex].mail[6].mail_template_id !== 0 ? <i className="fas fa-robot"></i> : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].raiten[6] ? (props[rowIndex].raiten[6].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].raiten[6] ? moment(props[rowIndex].raiten[6].date).utc().format('MM/DD') : ""}
      </td></tr>
    </table>
  </Cell >

const Eight = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <table style={{ padding: 0 }}>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].tel[7] ? (props[rowIndex].tel[7].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].tel[7] ? moment(props[rowIndex].tel[7].date).utc().format('MM/DD') : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].mail[7] ? (props[rowIndex].mail[7].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].mail[7] ? moment(props[rowIndex].mail[7].date).utc().format('MM/DD') : ""}
        {props[rowIndex].mail[7] && props[rowIndex].mail[7].mail_template_id !== 0 ? <i className="fas fa-robot"></i> : ""}
      </td></tr>
      <tr><td style={{ padding: 1, height: 30, width: 75, backgroundColor: props[rowIndex].raiten[7] ? (props[rowIndex].raiten[7].intend == 1 ? "#c8fac8" : "#d3d3d3") : "white" }}>
        {props[rowIndex].raiten[7] ? moment(props[rowIndex].raiten[7].date).utc().format('MM/DD') : ""}
      </td></tr>
    </table>
  </Cell >

class CustomerList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columns: columns,
      reflash: false,
      colSortDirs: {},
    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.url_open = this.url_open.bind(this);

  }
  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })
    this.setState({ columns: columnsset })
  }





  _onSortChange(columnKey, sortDir) {
    let seisan_follow_search_list = this.props.response_follow_sheet_list;
    seisan_follow_search_list.sort((indexA, indexB) => {
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.setState({
      // sortedDataList: new s DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  url_open(id) {
    var url = 'https://crm.homestation.jp/customer/' + id;
    window.open(url, '_blank', 'noopener')
  }

  render() {

    return (
      <div id="seisanlist">
        <Table
          rowHeight={95}
          rowsCount={this.props.response_follow_sheet_list.length}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          width={window.innerWidth - 200}
          height={window.innerHeight - 320}
          headerHeight={30}>
          {columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              cell={({ rowIndex, ...props }) => (
                value.key === "created_at" ? <Created_at column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Created_at>
                  : value.key === "rank" ? <Rank column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Rank>
                    : value.key === "nickname" ? <Tanto column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Tanto>
                      : value.key === "gap" ? <Gap column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Gap>
                        : value.key === "way" ? <Way column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Way>
                          : value.key === "name1" ? <Name column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex} url_open={this.url_open}></Name>
                            : value.key === "Tatemono_Name" ? <Tatemono column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Tatemono>
                              : value.key === "eight" ? <Eight column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Eight>
                                : value.key === "seven" ? <Seven column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Seven>
                                  : value.key === "six" ? <Six column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Six>
                                    : value.key === "five" ? <Five column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Five>
                                      : value.key === "four" ? <Four column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Four>
                                        : value.key === "three" ? <Three column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Three>
                                          : value.key === "two" ? <Two column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Two>
                                            : value.key === "one" ? <One column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></One>
                                              : value.key === "telmail" ? <TelMail column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></TelMail>
                                                : value.key === "date" ? <Dates column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Dates>
                                                  : <Base column={value} props={this.props.response_follow_sheet_list} rowIndex={rowIndex}></Base>
              )}
              width={this.state.columns.filter(a => a.key === value.key)[0].width}
              isResizable={true}
              fixed={value.frozen}
            />
          }, this)}
        </Table >
      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    response_follow_sheet_list: state.ResponseFollowSheet.response_follow_sheet_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);