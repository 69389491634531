import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
var moment = require('moment-timezone');



const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 10px;
    max-width: 250px;
    border-radius: 12px;
    background: #7fff97;
    margin-bottom: 2px;
    text-align: left;
    &:after {
        content: "";
        position: absolute;
        top: 3px; 
        right: -19px;
        border: 8px solid transparent;
        border-left: 18px solid #7fff97;
        -ms-transform: rotate(-35deg);
        -webkit-transform: rotate(-35deg);
        transform: rotate(-35deg);
    }
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 5 0 5px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

const MsgArea = styled.p`
    margin:0px;
    word-wrap: break-word;
    whiteSpace:pre-line;
`;

// export default class LineTextComponent extends React.Component {
class LineTextComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: '1',
            messages: [],

            date_time: moment(this.props.msg.date).tz("Asia/Tokyo").format('MM/DD HH:mm'),

        };
    }

    render() {
        return (
            <Balloon className="col-xs-12">
                <Chatting>
                    <Time>
                        <div>{this.props.users && this.props.msg.user_id > 0 && this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id)).length > 0 ? this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id))[0].nickname : ""}</div>
                        <div>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</div>
                    </Time>
                    <Says>
                        <MsgArea >  <span dangerouslySetInnerHTML={{ __html: this.props.msg.message_text.replace(/\r?\n/g, '<br/>') }} /></MsgArea>
                    </Says>
                </Chatting>
            </Balloon>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {
        users: state.masterReducer.users,
    }
}
function mapDispatchToProps(dispatch) {
    return {

        // get_firestore_listen(state) {
        //     dispatch(Line.get_firestore_listen(state))
        // },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineTextComponent);