import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as StockRooms from './Saga/StockRooms';

import '../../Css/GridRooms.css';
import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import Grid from './Grid';
import Fab from '@mui/material/Fab';

import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';

import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

interface Props {
    stock_rooms: any,
    get_stock_rooms: any,
    change_stock_rooms_open: any,
    deleteall_stock_rooms: any,
  
}


interface State {
    modalDelete: boolean,
 
}







class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {

            modalDelete: false,

        }
        this.handleSubmit = this.handleSubmit.bind(this);
      
        this.toggleDelete = this.toggleDelete.bind(this);
  
        this.props.get_stock_rooms()
    }


    //     submit(state:any){
    //         console.log(state)
    // this.props.get_response_rooms(state)
    //     }
    handleSubmit(state: any) {
        console.log(state)


        // state.rentResponseId = this.props.rent_response_id;


        // this.props.actions.get_response_rooms(state);

    }

    // selectTatemono(tatemonoId: string, roomId: string) {
    //     // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}

    //     let insertdata = {
    //         // rentResponseId: this.props.rent_response_id,
    //         tatemonoId: tatemonoId,
    //         status: 2,

    //         roomId: roomId,

    //     };


    //     // this.props.insert_introductions(insertdata)
    // }

    // selectsheet(row: any) {
    //     console.log(row)
    //     if (row)
    //         this.setState(prevState => ({
    //             // modalsheet: !prevState.modalsheet,
    //             tatemonoId: row.tatemonoId,
    //             roomId: row.roomId
    //         }));
    // }
    // onMypage(value: any) {
    //     // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}

    //     let updatedata = {
    //         id: value.id,
    //         // rentResponseId: this.props.rent_response_id,
    //         tatemonoId: value.tatemonoId,
    //         roomId: value.roomId,
    //         sectionId: value.sectionId,

    //         status: value.status,
    //         onMypage: value.onMypage === 1 ? 0 : 1,
    //         introFlag: value.introFlag,
    //         numbering: value.numbering,
    //         createdAt: value.createdAt,
    //         createdUser: value.createdUser,
    //         sendFlag: value.sendFlag,
    //         remarks: value.remarks,
    //     };



    //     // this.props.update_open_introductions(updatedata)
    // }

    // togglesheet = () => {

    //     this.setState(prevState => ({
    //         // modalsheet: !prevState.modalsheet,

    //     }));
    // }

    toggleDelete = () => {

        this.setState((prevState: any) => ({
            modalDelete: !prevState.modalDelete,

        }));
    }

    // estimate(tatemonoId: any, roomId: any) {
    //     this.setState({ tatemonoId: tatemonoId, roomId: roomId })


    //     this.toggleestimates()
    // }


    toggleestimates = () => {

        this.setState(prevState => ({
            // modalestimates: !prevState.modalestimates,

        }));

    }
    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div>
                {this.state.modalDelete ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%" }}>
                    <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "300px", height: "200px", padding: "20px", background: "#FFF" }}>
                        ストックを空にしますか？
    <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.props.deleteall_stock_rooms} >空にする</Button>
                        <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={this.toggleDelete}>キャンセル</Button>
                    </div>

                </div> : ""
                }
                <div style={{ padding: 3 }}>

                    <ShoppingCartIcon style={{ fontSize: "30px" }} />    ストック
                <Fab
                        variant="extended"
                        size="small"
                        color="secondary"
                        aria-label="add"
                        style={{ margin: 5 }}
                        onClick={this.toggleDelete}
                    >
                        <RemoveShoppingCartIcon />
          空にする
        </Fab><i className="fas fa-times" onClick={() => this.props.change_stock_rooms_open(false)} style={{ float: "right", margin: 12 }} ></i>
                    <Grid
                    // rent_response_id={this.props.rooms}
                    // onMypage={this.onMypage}
                    // selectsheet={this.selectsheet}
                    // delete_introduction={this.props.delete_introduction}
                    // togglefileup={this.props.togglefileup}
                    // estimate={this.estimate}
                    // add_mail_room={null}
                    // togglesearch={null}
                    />



                </div>

            </div >


        );
    }


}



const mapStateToProps = (state: any) => {
    return {

        stock_rooms: state.StockRooms.stock_rooms,
        // responseid: state.customersReducer.responseid,
        // response_rooms: state.roomsReducer.response_rooms,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_stock_rooms(state: any) {
            dispatch(StockRooms.get_stock_rooms(state))
        },
        change_stock_rooms_open(state: any) {
            dispatch(StockRooms.change_stock_rooms_open(state))
        },
        deleteall_stock_rooms(state: any) {
            dispatch(StockRooms.deleteall_stock_rooms(state))
        },
        // insert_introductions(state: any) {
        //     dispatch(StockRooms.insert_introductions(state))
        // },
        // delete_introduction(state: any) {
        //     dispatch(StockRooms.delete_introduction(state))
        // },
        // update_open_introductions(state: any) {
        //     dispatch(StockRooms.update_open_introductions(state))
        // },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);