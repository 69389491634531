import React from 'react';

import styled from 'styled-components';
import { connect } from 'react-redux';

import * as Line from './Saga/Line';
var moment = require('moment-timezone');
const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 10px;
    width: 250px;
    border-radius: 12px;
    background: White;
    margin-bottom: 2px;
    text-align: left;
`;

const Description = styled.div`
    font-size:10px;
`;
const List = styled.div`
    padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;width:250px;margin:5px;margin-left:-10px !important;
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0;
    font-size:10px;
    color:#FFF;
`;

// const MsgArea = styled.p`
//     margin:0px;
//     word-wrap: break-word;
// `;

class LineTextComponent extends React.Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],

            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),

        };
    }

    render() {
        return (
            <Balloon className="col-xs-12">
                <Chatting>
                    <Time>
                        <div>{this.props.users && this.props.msg.user_id > 0 && this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id)).length > 0 ? this.props.users.filter(a=>a.id == parseInt(this.props.msg.user_id))[0].nickname : ""}</div>
                        <div>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</div>
                    </Time>
                    <Says>
                        {this.props.msg.message_text}
                        <Description>下記よりファイルをダウンロードしてください</Description>
                        <List className="row">
                            <div className="col-lg-12" style={{ fontWeight: "bold" }} onClick={() => this.props.download_line_file(this.props.msg)}>ダウンロード</div>
                        </List>

                    </Says>
                </Chatting>
            </Balloon>

        )
    }
}

//コンテナ
const mapStateToProps = state => {
    return {
        users: state.masterReducer.users,
        // mail_new: state.mailReducer.mail_new,
        // lines: state.line.lines,
        // line_bottom: state.line.line_bottom,
        // line_user_details: state.line.line_user_details,
        // lines: state.line.lines,
        // line_open_flg: state.line.line_open_flg,
        // //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        download_line_file(state) {
            dispatch(Line.download_line_file(state))
        },
        //  get_lines(state) {
        //     dispatch(Line.get_lines(state))
        //  },
        // get_lines_success(state) {
        //     dispatch(Line.get_lines_success(state))
        // },
        // change_line_bottom(state) {
        //     dispatch(Line.change_line_bottom(state))
        // },
        // get_line_close(state) {
        //     dispatch(Line.get_line_close(state))
        // },
        // get_line_open(state) {
        //     dispatch(Line.get_line_open(state))
        // },
        // get_firestore_listen(state) {
        //     dispatch(Line.get_firestore_listen(state))
        // },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineTextComponent);