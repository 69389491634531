import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as MapMypageTemplate from './Saga/MapMypageTemplate';

import { Table, Column, Cell } from 'fixed-data-table-2';
import { Modal,ModalFooter,ModalBody} from 'reactstrap';

import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MaterialUiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import './Css/List.css';


import moment from 'moment';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';



const taiou = [
    { tantou: "吉田", hiduke: "2021-01-01", houhou: "企業訪問", naiyou: "〇〇さんと同行。契約書類持参。お礼訪問。" },
    { tantou: "吉田", hiduke: "2021-01-01", houhou: "企業訪問", naiyou: "定期訪問" },
    { tantou: "吉田", hiduke: "2021-01-01", houhou: "TEL", naiyou: "解約の事について確認。駐車場解約されたため、〇〇の現場の工事が終わったのかヒアリング。" },
]
class List extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            change: false,
            columnWidths: {
                date: 100,
                user_id: 90,
                rent_m_corporate_way_id: 100,
                remarks: 500,
                name:310,
              },
            modal_point_add:false,
        };

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this.handlePointAddDialog = this.handlePointAddDialog.bind(this);
        this.handlePointAdd = this.handlePointAdd.bind(this);
        this._onRowClick = this._onRowClick.bind(this);
        this.handleChangeSection = this.handleChangeSection.bind(this);
        

        this.props.get_rent_introduction_point_template_list()
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
          columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
          },
        }));
    }


    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    handlePointAddDialog(id) {

        this.setState({
            id: id,
            modal_point_add: true,
            // change: !this.state.change
        });

    }
    handlePointAdd() {

        this.props.add_rent_introduction_point_templates(this.state.id)

        this.setState({
            id: null,
            modal_point_add: false,
            change: !this.state.change
        });

    }
    _onRowClick(event, index){
        this.props.get_rent_introduction_point_templates(this.props.rent_introduction_point_template_list[index].id)

        this.setState({
            change: !this.state.change
        });
    }
    handleChangeSection(e){

        this.props.change_select_section_id(e.target.value)
        this.props.get_rent_introduction_point_template_list(e.target.value)
        this.props.change_rent_introduction_point_templates([])

    
        this.setState({ change: !this.state.change });
    
      }

    render() {

        return (
            <div className='template_list'>

        <FormControl variant="outlined" fullWidth>
            <InputLabel id="sectionId-label">店舗</InputLabel>
            <MaterialUiSelect
                labelId="sectionId-label"
                id="section_id"
                name="section_id"
                value={this.props.select_section_id}
                onChange={this.handleChangeSection}
                // disabled={this.props.formData.id}
                label="section_id"
                margin='dense'
            >
                <MenuItem key={-1} value={0}>全店</MenuItem>
                {this.props.section_select.filter((a) => a.value > 0 && a.value <=6 || a.value == 10 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47 || a.value == 55).map(function (item, i) {
                return (
                    <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                );

                })}

            </MaterialUiSelect>
        </FormControl>

                <Table 
                    rowHeight={34} 
                    rowsCount={this.props.rent_introduction_point_template_list.length} 
                    height={window.innerHeight-240} 
                    headerHeight={30} 
                    width={250}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    onRowClick={this._onRowClick}

                >
                    
                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} onClick={(e) => { this.handleOpen(this.props.rent_corporate_contacts_list[rowIndex].id); }} aria-label="edit" size="small"><AddCircleIcon /></IconButton></Cell>)} width={40} /> */}
                    <Column header={<Cell>挿入</Cell>} cell={({ rowIndex, ...props }) => (<Cell className={this.props.rent_introduction_point_templates.id == this.props.rent_introduction_point_template_list[rowIndex].id ? "select" : ""} {...props}><IconButton style={{marginTop:-5}} onClick={(e) => { this.handlePointAddDialog(this.props.rent_introduction_point_template_list[rowIndex].id) }} aria-label="edit" size="small"><AddCircleIcon color="primary" /></IconButton></Cell>)} width={45} />

                    
                    <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>テンプレート名</Cell>} cell={({ rowIndex, ...props }) => (<Cell className={this.props.rent_introduction_point_templates.id == this.props.rent_introduction_point_template_list[rowIndex].id ? "select" : ""} style={{textAlign:"left"}} {...props}>{this.props.rent_introduction_point_template_list[rowIndex].name}</Cell>)} />
                    
                    <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell className={this.props.rent_introduction_point_templates.id == this.props.rent_introduction_point_template_list[rowIndex].id ? "select" : ""} {...props}>
{
 this.props.rent_introduction_point_template_list[rowIndex].section_id === 1 ? <span className="badge badge-success">番</span>
 : this.props.rent_introduction_point_template_list[rowIndex].section_id === 2 ? <span className="badge badge-warning">レ</span>
   : this.props.rent_introduction_point_template_list[rowIndex].section_id === 3 ? <span className="badge badge-danger">駅</span>
     : this.props.rent_introduction_point_template_list[rowIndex].section_id === 4 ? <span className="badge badge-dark">東</span>
       : this.props.rent_introduction_point_template_list[rowIndex].section_id === 6 ? <span className="badge badge-light">公</span>
         : this.props.rent_introduction_point_template_list[rowIndex].section_id === 17 ? <span className="badge badge-info">サ</span>
           : this.props.rent_introduction_point_template_list[rowIndex].section_id === 47 ? <span className="badge badge-secondary">通</span>
           : this.props.rent_introduction_point_template_list[rowIndex].section_id === 10 ? <span className="badge badge-primary">シ</span>
           :""
}
                        </Cell>)} width={45} />

                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} onClick={(e) => { this.handleOpen(this.props.rent_corporate_contacts_list[rowIndex].id); }} aria-label="edit" size="small"><EditIcon /></IconButton></Cell>)} width={40} />
                    <Column width={this.state.columnWidths.user_id} isResizable={true} columnKey="user_id" header={<Cell>担当</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_corporate_contacts_list[rowIndex].user_id ? this.props.users_select.filter((a) => a.value === this.props.rent_corporate_contacts_list[rowIndex].user_id).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_corporate_contacts_list[rowIndex].user_id)[0].label : "" : ""}</Cell>)} />
                    <Column width={this.state.columnWidths.date} isResizable={true} columnKey="date" header={<Cell>日付</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_corporate_contacts_list[rowIndex].date).format("YYYY-MM-DD")}</Cell>)} />
                    <Column width={this.state.columnWidths.rent_m_corporate_way_id} isResizable={true} columnKey="rent_m_corporate_way_id" header={<Cell>対応方法</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                        {(() => {
                            if (this.props.rent_corporate_contacts_list[rowIndex].rent_m_corporate_way_id === 0) {
                                return "";
                            } else if (this.props.rent_corporate_contacts_list[rowIndex].rent_m_corporate_way_id === 1) {
                                return "企業訪問";
                            } else if (this.props.rent_corporate_contacts_list[rowIndex].rent_m_corporate_way_id === 2) {
                                return "TEL";
                            } else if (this.props.rent_corporate_contacts_list[rowIndex].rent_m_corporate_way_id === 3) {
                                return "メール";
                            }
                        })()}

                    </Cell>)} /> */}
                    {/* <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>タイトル</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_introduction_point_template_list[rowIndex].name}</Cell>)} /> */}

                </Table>

                <Button variant="contained" color="primary" onClick={() => this.props.update_rent_introduction_point_templates(0)} style={{ marginTop: 5 }}>テンプレート追加</Button>

                

                
                <Modal isOpen={this.state.modal_point_add} fade={false} zIndex={3002} toggle={(e) => { this.setState({ "modal_point_add": false }) }} className="modal-dialog-centered">
                <ModalBody>
                    <div>マイページにテンプレートを挿入します。</div>
                    <div>マイページのポイント等を修正中の場合には、保存してからテンプレートの挿入を行ってください。</div>
                    <div style={{fontSize:11}}>※保存されていない状態でテンプレート挿入を行うと、修正中のポイントが消去されます!</div>
                </ModalBody>
                <ModalFooter>
                
                    <Button variant="contained" color="secondary" onClick={() => {this.handlePointAdd();}}>テンプレート挿入</Button>
                    <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal_point_add": false }) }}>閉じる</Button>
                </ModalFooter>
                </Modal>


            </div>
        );
    }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {

        rent_introduction_point_template_list: state.mapmypagetemplate.rent_introduction_point_template_list,
        rent_introduction_point_templates: state.mapmypagetemplate.rent_introduction_point_templates,
        user_details: state.masterReducer.user_details,
        section_select: state.masterReducer.section_select,
        select_section_id: state.mapmypagetemplate.select_section_id,
 
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_rent_introduction_point_template_list(state) {
            dispatch(MapMypageTemplate.get_rent_introduction_point_template_list(state))
        },
        change_select_section_id(state) {
            dispatch(MapMypageTemplate.change_select_section_id(state))
        },
        add_rent_introduction_point_templates(state) {
            dispatch(MapMypageTemplate.add_rent_introduction_point_templates(state))
        },
        get_rent_introduction_point_templates(state) {
            dispatch(MapMypageTemplate.get_rent_introduction_point_templates(state))
        },
        update_rent_introduction_point_templates(state) {
            dispatch(MapMypageTemplate.update_rent_introduction_point_templates(state))
        },
        change_rent_introduction_point_templates(state) {
            dispatch(MapMypageTemplate.change_rent_introduction_point_templates(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(List);




