
const authentication = require('../../../react-azure-adb2c2').default;






export const list = (values) => {

    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/list/` + values.line_user_id + '/' + values.line_account_section_id + '/' + values.take;

    // let url = `http://localhost:5000/v1/lines/list/` + values.line_user_id + '/' + values.line_account_section_id + '/' + values.take;

    // alert(url)
    // let url = process.env.REACT_APP_API_LINE_URL + `/api/TimeLine/` + values.lineuserid + '/' + values.take;
    // let url = `https://localhost:44385/api/TimeLine/`+values.lineuserid+'/'+values.take;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};




export const list_by_user = (values) => {
    // alert(values.id)
    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/list/` + values.line_user_id + '/' + values.line_account_section_id + '/' + values.take;

    // let url = `http://localhost:5000/v1/lines/list/` + values.line_user_id + '/' + values.line_account_section_id + '/' + values.take;

    // alert()
    // let url = process.env.REACT_APP_API_LINE_URL + `/api/TimeLine/` + values.lineuserid + '/' + values.take;
    // let url = `https://localhost:44385/api/TimeLine/`+values.lineuserid+'/'+values.take;
    // alert(url)
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const userlist = (values) => {

    // let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/list/` + values.line_user_id + '/' + values.line_account_section_id + '/' + values.take;
    let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/userline/` + values;
    // let url = `http://localhost:5000/v1/lines/userline/` + values;

    // alert()
    // let url = process.env.REACT_APP_API_LINE_URL + `/api/TimeLine/` + values.lineuserid + '/' + values.take;
    // let url = `https://localhost:44385/api/TimeLine/`+values.lineuserid+'/'+values.take;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
