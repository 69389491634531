import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as IntroductionListEdit from './../Saga/MapMypage';
// import RoomDocComponent from '../Rooms/RoomDocComponent';
// import * as Responses from '../../Store/Responses';
// import '../../Css/GridRooms.css';
// import LoadingComponent from '../../Layout/LoadingComponent';
// import moment from 'moment';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Grid from '@mui/material/Grid';
// import IntroductionsGridComponent from './IntroductionsGridComponent';
// import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
// import arrayMove from 'array-move';
// import List from '@mui/material/List';
// import ListItem from '@mui/material/ListItem';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import ListItemText from '@mui/material/ListItemText';
// import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
// import DragHandleIcon from '@mui/icons-material/DragHandle';
import Button from '@mui/material/Button';
import SelectList from './SelectList';
import MypageList from './MypageList';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
// import FormControl from '@mui/material/FormControl'
// import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import RoomDocComponent from '../../Rooms/RoomDocComponent';
import * as MapMypage from './../Saga/MapMypage';


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rent_introduction_headers: 0,
      selectopen: false,
      tatemonoId: 0,
      roomId: 0,
      refresh: false,
      items: [
        { id: '1', text: 'Item 1' },
        { id: '2', text: 'Item 2' },
        { id: '3', text: 'Item 3' },
        { id: '4', text: 'Item 4' }
      ]


    }
    this.handleSelect = this.handleSelect.bind(this);
    this.onChange = this.onChange.bind(this);

    this.onChangedispList = this.onChangedispList.bind(this);
    this.onChangedispApplied = this.onChangedispApplied.bind(this);



    this.handleOpen = this.handleOpen.bind(this);

    this.handleCloseSheet = this.handleCloseSheet.bind(this);
    
    this.props.change_rent_introduction_headers([])
    this.props.change_rent_introduction_point_headers([])
    this.props.get_rent_introduction_headers_list(this.props.responsedetail.id)
    // this.selectTatemono = this.selectTatemono.bind(this);
    // this.onMypage = this.onMypage.bind(this);
    // this.selectsheet = this.selectsheet.bind(this);

    // this.togglesheet = this.togglesheet.bind(this);

    // this.props.get_introductions({rent_response_id:props.rent_response_id});
  }

  handleSelect(id) {

    this.props.get_rent_introduction_headers(id)

    if (id === 0) {
      this.props.change_rent_introduction_headers({ disp_list: 0, disp_applied: 0 })
    }

    this.props.change_select_rooms([])
    this.props.change_open_rooms([])

    this.props.change_rent_introduction_headers_id(id)
    let select_rooms = id > 0 ? this.props.rent_introduction_headers_list.filter(a => a.id === id)[0].rent_introduction_details : []
    this.props.change_select_rooms(select_rooms)

    const open_rooms = this.props.map_mypage_introductions.filter(function (value) {
      if (select_rooms.filter(a => parseInt(a.tatemono_id) === parseInt(value.tatemono_id) && parseInt(a.room_id) === parseInt(value.room_id))[0]) {
      } else {
        return value;
      }
    }, this);

    this.props.change_open_rooms(open_rooms)

    this.setState({ rent_introduction_headers: id })
    this.props.change_select_list_open(true)
  }
  handleOpen() {
    this.setState({ selectopen: !this.state.selectopen })
  }

  onChange(e) {
    let rent_introduction_headers = this.props.rent_introduction_headers
    rent_introduction_headers[e.target.name] = e.target.value
    this.props.change_rent_introduction_headers(rent_introduction_headers)
    this.setState({ refresh: !this.state.refresh })
  }
  onChangedispList(e) {
    let rent_introduction_headers = this.props.rent_introduction_headers
    rent_introduction_headers.disp_list = rent_introduction_headers.disp_list === 1 ? 0 : 1
    this.props.change_rent_introduction_headers(rent_introduction_headers)
    this.setState({ refresh: !this.state.refresh })
  }
  onChangedispApplied(e) {
    let rent_introduction_headers = this.props.rent_introduction_headers
    rent_introduction_headers.disp_applied = rent_introduction_headers.disp_applied === 1 ? 0 : 1
    this.props.change_rent_introduction_headers(rent_introduction_headers)
    this.setState({ refresh: !this.state.refresh })
  }

  handleCloseSheet () {
    let map_mypage_room_detail_disp = this.props.map_mypage_room_detail_disp
    map_mypage_room_detail_disp = false
    this.props.change_map_mypage_room_detail_disp(map_mypage_room_detail_disp)

    let map_mypage_room_detail_disp_id = this.props.map_mypage_room_detail_disp_id
    map_mypage_room_detail_disp_id.tatemono_id = 0
    map_mypage_room_detail_disp_id.room_id = 0
    this.props.change_map_mypage_room_detail_disp_id(map_mypage_room_detail_disp_id)
  };

  render() {

    return (
      <div style={{ textAlign: "right" }}>


        <Button size="small"
          color="secondary"
          variant="contained"
          type="button"
          style={{ margin: "5px" }}
          onClick={() => this.handleSelect(0)}
        >
          ページ追加
        </Button>

        <MypageList handleSelect={this.handleSelect} />


        <Modal isOpen={this.props.select_list_open} toggle={() => this.props.change_select_list_open(false)} size="xl" fade={false}>
          <ModalHeader toggle={() => this.props.change_select_list_open(false)}>物件選択</ModalHeader>

          <ModalBody  >
            <Grid container spacing={0}>
              <Grid item xs={2}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={this.onChange}
                  name = "name"
                  value={this.props.rent_introduction_headers.name} label="ページ名"
                  variant="outlined" />
                <FormControlLabel
                  control={

                    <Switch
                      name="disp_list"
                      checked={this.props.rent_introduction_headers.disp_list === 0 ? true : false}
                      onChange={this.onChangedispList}
                      value={1}
                      color="primary"
                    />
                  }
                  label="リスト表示"
                />
                <FormControlLabel
                  control={

                    <Switch
                      name="disp_applied"
                      checked={this.props.rent_introduction_headers.disp_applied === 0 ? true : false}
                      onChange={this.onChangedispApplied}
                      value={1}
                      color="primary"
                    />
                  }
                  label="成約済グレー表示"
                />

              </Grid>

              <Grid item xs={10}>
                <SelectList />
              </Grid>
              <Grid item xs={12} style={{marginTop:20}}>
                <TextField
                  InputLabelProps={{
                    shrink: true,
                  }}
                  multiline
                  maxRows={10}
                  minRows={5}
                  onChange={this.onChange}
                  fullWidth
                  name="comment"
                  value={this.props.rent_introduction_headers.comment} label="担当者からのコメント"
                  variant="outlined" />
              </Grid>
            </Grid>
          </ModalBody>
          <ModalFooter>


            <Button size="small"
              color="primary"
              variant="contained"
              type="button"
              onClick={this.props.update_rent_introduction_headers}

            >
              保存
            </Button>


            <Button size="small"
              color="primary"
              variant="outlined"
              type="button"
              onClick={() => this.props.change_select_list_open(false)}

            >
              閉じる
            </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.props.map_mypage_room_detail_disp} toggle={()=>this.handleCloseSheet()} size="xxl" fade={false}>
          <ModalHeader>
            <b style={{ fontSize: "20px" }}>{this.props.room_details.tatemono_name} {this.props.room_details.room_name}</b>
            <IconButton
              aria-label="close"
              style={{position:'absolute', right:1, top:1}}
              onClick={()=>this.handleCloseSheet()}
              size="large">
              <CloseIcon />
            </IconButton>
          </ModalHeader>

          {/* < RoomDocComponent tatemonoId={"2849846"} roomId={"5"} /> */}
          < RoomDocComponent tatemonoId={this.props.map_mypage_room_detail_disp_id.tatemono_id} roomId={this.props.map_mypage_room_detail_disp_id.room_id} />

      </Modal>

      </div>
    );
  }


}



const mapStateToProps = (state) => {
  return {
    rooms: state.roomsReducer.rooms,
    introductions: state.introductionlist.introductions,
    rent_introduction_headers: state.mapmypage.rent_introduction_headers,
    rent_introduction_headers_list: state.mapmypage.rent_introduction_headers_list,
    rent_introduction_headers_id: state.mapmypage.rent_introduction_headers_id,
    // responseid: state.customersReducer.responseid,
    responsedetail: state.responsesReducer.responsedetail,
    contacts_loading: state.customerContactsReducer.contacts_loading,
    select_rooms: state.mapmypage.select_rooms,
    select_list_open: state.mapmypage.select_list_open,
    map_mypage_introductions: state.mapmypage.map_mypage_introductions,
    map_mypage_room_detail_disp: state.mapmypage.map_mypage_room_detail_disp,
    map_mypage_room_detail_disp_id: state.mapmypage.map_mypage_room_detail_disp_id,
    room_details: state.roomsReducer.room_details,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_rent_introduction_headers(state) {
      dispatch(IntroductionListEdit.get_rent_introduction_headers(state))
    },
    update_rent_introduction_headers(state) {
      dispatch(IntroductionListEdit.update_rent_introduction_headers(state))
    },
    get_rent_introduction_headers_list(state) {
      dispatch(IntroductionListEdit.get_rent_introduction_headers_list(state))
    },
    change_select_rooms(state) {
      dispatch(IntroductionListEdit.change_select_rooms(state))
    },
    change_open_rooms(state) {
      dispatch(IntroductionListEdit.change_open_rooms(state))
    },
    change_rent_introduction_headers_id(state) {
      dispatch(IntroductionListEdit.change_rent_introduction_headers_id(state))
    },
    change_rent_introduction_point_headers(state) {
      dispatch(IntroductionListEdit.change_rent_introduction_point_headers(state))
    },
    change_rent_introduction_headers(state) {
      dispatch(IntroductionListEdit.change_rent_introduction_headers(state))
    },
    change_select_list_open(state) {
      dispatch(IntroductionListEdit.change_select_list_open(state))
    },
    change_map_mypage_room_detail_disp(state) {
      dispatch(MapMypage.change_map_mypage_room_detail_disp(state))
    },
    change_map_mypage_room_detail_disp_id(state) {
      dispatch(MapMypage.change_map_mypage_room_detail_disp_id(state))
    },
    // delete_introduction(state:any) {
    //     dispatch(IntroductionList.delete_introduction(state))
    // },
    // update_introductions(state:any) {
    //     dispatch(IntroductionList.update_introductions(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);