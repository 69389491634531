import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import * as MailsApi from '../Api/MailsApi';
import * as MailDetailFilesApi from '../Api/MailDetailFilesApi';
import * as MailSendApi from '../Api/MailSendApi';

import * as CustomerContacts from '../../CustomerContacts/Saga/CustomerContacts';
import * as MailTemplatesApi from '../Api/MailTemplatesApi';

import * as MailMAddressesApi from '../Api/MailMAddressesApi';

import * as RentIntroductionHeadersListApi from '../Api/RentIntroductionHeadersListApi';

import * as MailTemplateSelectListApi from '../Api/MailTemplateSelectListApi';
import * as MailTemplateDetailApi from '../Api/MailTemplateDetailApi';
import * as rent_responses_api from '../Api/rent_responses_api';

import * as ContractFollow from '../../ContractFollow/Saga/ContractFollow';
import * as Responses from '../../ResponseDetail/Saga/Responses';
import * as ResponseList from '../../ResponseList/Saga/ResponseList';

import { Editor } from '@ckeditor/ckeditor5-core'; // CKEditor の型



declare global {
    interface Window {
        editorInstance2?: Editor; // editorInstance2 を定義
    }
}
// import console = require('console');
// import * as Common from './Common';

export interface MailState {
    mails: any;
    datebinary: number;
    min_mail_id: number;
    mail_loading: number;
    mail_details: any;
    mail_listening: number;
    mail_detail_files: any;
    mail_file: any;
    get_mail_details_loading: number;
    get_mail_detail_files_loading: number;
    mail_new: number;
    mail_minimum: number;
    mail_addresses: any;
    send_mail_details: any;
    send_mail_editor_state: any;
    send_mail_cc_disp: number;
    send_mail_bcc_disp: number;
    send_mail_address_disp: number;
    send_files: any;
    send_edit_contents: number;
    send_to_addresses: any;
    mail_templates: any;
    response_mails: any;
    editor_height: number;
    editor_width: number;
    response_mail_loading: number;
    send_mail_loading: boolean;
    send_mail_alert: number;
    mail_rent_introduction_header_list: any;
    mail_template_select_list: any;

    mail_tab_change_loading: boolean;
}

const initialState: MailState = {

    mails: [],
    mail_details: [],
    min_mail_id: 0,
    datebinary: 0,
    mail_loading: 0,
    mail_listening: 0,
    mail_detail_files: [],
    mail_templates: [],
    mail_file: "",
    get_mail_details_loading: 0,
    get_mail_detail_files_loading: 1,
    mail_new: 0,
    mail_minimum: 0,
    mail_addresses: [],
    send_mail_details: [],
    send_mail_editor_state: [],
    send_mail_cc_disp: 0,
    send_mail_bcc_disp: 0,
    send_mail_address_disp: 0,
    send_files: [],
    send_edit_contents: 1,
    send_to_addresses: [],
    response_mails: [],
    editor_height: 600,
    editor_width: 700,
    response_mail_loading: 0,
    send_mail_loading: false,
    send_mail_alert: 0,
    mail_rent_introduction_header_list: [],
    mail_template_select_list: [],
    mail_tab_change_loading: false,
}



///action
const actionCreator = actionCreatorFactory();

// export const get_mails = actionCreator('GET_MAILS');
export const get_mails_success = actionCreator<any>("GET_MAILS_SUCCESS");
export const get_mails_failure = actionCreator<any>("GET_MAILS_FAILURE");

export const add_mails = actionCreator('ADD_MAILS');
export const add_mails_success = actionCreator<any>("ADD_MAILS_SUCCESS");
export const add_mails_failure = actionCreator<any>("ADD_MAILS_FAILURE");

export const get_mail_details = actionCreator<number>('GET_MAIL_DETAILS');
export const get_mail_details_success = actionCreator<any>("GET_MAIL_DETAILS_SUCCESS");
export const get_mail_details_failure = actionCreator<any>("GET_MAIL_DETAILS_FAILURE");

export const change_get_mail_details_loading = actionCreator<number>('CHANGE_GET_MAIL_DETAILS_LOADIG');
export const change_get_mail_detail_files_loading = actionCreator<number>('CHANGE_GET_MAIL_DETAIL_FILES_LOADIG');



export const change_min_mail_id = actionCreator<number>('CHANGE_MIN_MAIL_ID');

export const change_mail_loading = actionCreator<number>('CHANGE_MAIL_LOADIG');

export const change_mail_new = actionCreator<any>('CHANGE_MAIL_NEW');
export const change_mail_new_success = actionCreator<number>("CHANGE_MAIL_NEW_SUCCESS");
export const change_mail_new_failure = actionCreator<any>("CHANGE_MAIL_NEW_FAILURE");



export const change_mail_new_template = actionCreator<any>('CHANGE_MAIL_NEW_TEMPLATE');

export const change_mail_new_publicfiles = actionCreator<any>('CHANGE_MAIL_NEW_PUBLICFLES');



export const close_mail = actionCreator("CLOSE_MAIL");



export const change_mail_minimum = actionCreator<any>('CHANGE_MAIL_MINIMUM');



export const change_mail_draft_edit = actionCreator<any>('CHANGE_MAIL_DRAFT_EDIT');

export const change_mail_listening = actionCreator<number>('CHANGE_MAIL_LISTENING');


export const get_mail_detail_files = actionCreator<number>('GET_MAIL_DETAIL_FAILES');
export const get_mail_detail_files_success = actionCreator<any>("GET_MAIL_DETAIL_FAILES_SUCCESS");
export const get_mail_detail_files_failure = actionCreator<any>("GET_MAIL_DETAIL_FAILES_FAILURE");

export const get_mail_file = actionCreator<any>('GET_MAIL_FAILE');
export const get_mail_file_success = actionCreator<any>("GET_MAIL_FAILE_SUCCESS");
export const get_mail_file_failure = actionCreator<any>("GET_MAIL_FAILE_FAILURE");

export const delete_mail_file = actionCreator<any>('DELETE_MAIL_FAILE');


export const add_mail_file = actionCreator<any>('ADD_MAIL_FILE');
export const add_mail_file_success = actionCreator<any>("ADD_MAIL_FILE_SUCCESS");
export const add_mail_file_failure = actionCreator<any>("ADD_MAIL_FILE_FAILURE");


export const add_mail_image = actionCreator<any>('ADD_MAIL_IMAGE');
export const change_mail_image_cid = actionCreator<any>("CHANGE_MAIL_IMAGE_CID");
export const add_mail_image_failure = actionCreator<any>("ADD_MAIL_IMAGE_FAILURE");


export const get_mail_addresses = actionCreator<string>("GET_MAIL_ADDRESSES");
export const get_mail_addresses_success = actionCreator<number>("GET_MAIL_ADDRESSES_SUCCESS");
export const get_mail_addresses_failure = actionCreator<any>("GET_MAIL_ADDRESSES_FAILURE");

export const send_mail = actionCreator<any>('SEND_MAIL');
export const send_mail_success = actionCreator<any>("SEND_MAIL_SUCCESS");
export const send_mail_failure = actionCreator<any>("SEND_MAIL_FAILURE");
export const change_send_mail_loading = actionCreator<any>('CHANGE_SEND_MAIL_LOADING');


export const change_send_mail_alert = actionCreator<any>('CHANGE_SEND_MAIL_ALERT');


export const change_send_mail_details = actionCreator<any>('CHANGE_SEND_MAIL_DETAILS');

export const change_send_mail_editor_state = actionCreator<any>('CHANGE_SEND_MAIL_EDITOR_STATE');
export const update_send_mail_editor_state = actionCreator<any>('UPDATE_SEND_MAIL_EDITOR_STATE');
export const update_send_mail_editor_success = actionCreator<any>("UPDATE_SEND_MAIL_EDITOR_STATE_SUCCESS");
export const update_send_mail_editor_failure = actionCreator<any>("UPDATE_SEND_MAIL_EDITOR_STATE_FAILURE");


export const change_send_mail_cc_disp = actionCreator<any>('CHANGE_SEND_MAIL_CC_DISP');
export const change_send_mail_bcc_disp = actionCreator<any>('CHANGE_SEND_MAIL_BCC_DISP');
export const change_send_mail_address_disp = actionCreator<any>('CHANGE_SEND_ADDRESS_DISP');

export const change_send_files = actionCreator<any>('CHANGE_SEND_FILES');


export const change_send_edit_contents = actionCreator<number>('CHANGE_SEND_EDIT_CONTENTS');

export const change_send_to_addresses = actionCreator<any>('CHANGE_SEND_TO_ADDRESSES');

export const change_editor_height = actionCreator<any>('CHANGE_EDITOR_HEIGHT');
export const change_editor_width = actionCreator<any>('CHANGE_EDITOR_WIDTH');




export const get_mail_templates = actionCreator('GET_MAIL_TEMPLATES');
export const get_mail_templates_success = actionCreator<any>("GET_MAIL_TEMPLATES_SUCCESS");
export const get_mail_templates_failure = actionCreator<any>("GET_MAIL_TEMPLATES_FAILURE");


export const update_mail_templates = actionCreator('UPDATE_MAIL_TEMPLATES');
export const update_mail_templates_success = actionCreator<any>("UPDATE_MAIL_TEMPLATES_SUCCESS");
export const update_mail_templates_failure = actionCreator<any>("UPDATE_MAIL_TEMPLATES_FAILURE");


export const add_mail_templates = actionCreator('ADD_MAIL_TEMPLATES');
export const add_mail_templates_success = actionCreator<any>("ADD_MAIL_TEMPLATES_SUCCESS");
export const add_mail_templates_failure = actionCreator<any>("ADD_MAIL_TEMPLATES_FAILURE");

export const delete_mail_templates = actionCreator('DELETE_MAIL_TEMPLATES');


export const add_mail_templates_category = actionCreator<any>('ADD_MAIL_TEMPLATES_CATEGORY');
// export const add_mail_templates_category_success = actionCreator<any>("ADD_MAIL_TEMPLATES_CATEGORY_SUCCESS");
// export const add_mail_templates_category_failure = actionCreator<any>("ADD_MAIL_TEMPLATES_CATEGORY_FAILURE");

export const update_mail_templates_category = actionCreator<any>('UPDATE_MAIL_TEMPLATES_CATEGORY');
// export const update_mail_templates_category_success = actionCreator<any>("UPDATE_MAIL_TEMPLATES_CATEGORY_SUCCESS");
// export const update_mail_templates_category_failure = actionCreator<any>("UPDATE_MAIL_TEMPLATES_CATEGORY_FAILURE");

export const delete_mail_templates_category = actionCreator<any>('DELETE_MAIL_TEMPLATES_CATEGORY');
export const add_mail_room = actionCreator<any>('ADD_MAIL_ROOM');

export const delete_draft = actionCreator<any>('DELETE_DRAFT');


export const get_response_mails = actionCreator<any>('GET_RESPONSE_MAILS');
export const get_response_mails_success = actionCreator<any>('GET_RESPONSE_MAILS_SUCCESS');

export const change_response_mail_loading = actionCreator<any>('CHANGE_RESPONSE_MAIL_LOADING');

export const add_mypage_link = actionCreator<any>('ADD_MYPAGE_LINK');

export const get_mail_rent_introduction_header_list = actionCreator<any>('GET_MAIL_RENT_INTRODUCTION_HEADER_LIST');
export const change_mail_rent_introduction_header_list = actionCreator<any>('CHANGE_MAIL_RENT_INTRODUCTION_HEADER_LIST');

export const get_mail_template_select_list = actionCreator<any>('GET_MAIL_TEMPLATE_SELECT_LIST');
export const change_mail_template_select_list = actionCreator<any>('CHANGE_MAIL_TEMPLATE_SELECT_LIST');

export const add_mail_templates_detail = actionCreator<any>('ADD_MAIL_TEMPLATES_DETAIL');

export const delete_media_mail = actionCreator<any>('DELETE_MEDIA_MAIL');

export const update_mail_looked = actionCreator<any>('UPDATE_MAIL_LOOKED');

export const change_mail_tab_change_loading = actionCreator<any>('CHANGE_MAIL_TAB_CHANGE_LOADING');

export const mailReducer = reducerWithInitialState(initialState)
    // .case(get_mails_success, (state, payload) => ({ ...state, mails:state.mails.concat(payload)}))
    // .case(get_mails_success, (state, payload) => ({ ...state, mails:payload.DateBinary>state.datebinary?state.mails.concat(payload):state.mails.length>0?state.mails.concat(payload):state.mails.concat(payload)}))
    // .case(get_mails_success, (state, payload) => ({ ...state, mails:state.mails.concat(payload).sort(sort_by(state.mails.concat(payload)))}))
    .case(get_mails_success, (state, payload) => ({
        ...state, mails: state.mails.concat(payload).sort((a: any, b: any) => {
            if (a.Id > b.Id) return -1;
            if (a.Id < b.Id) return 1;
            return 0;
        })
    }))


    .case(get_mail_details_success, (state, payload) => ({ ...state, mail_details: payload }))
    .case(get_mail_detail_files_success, (state, payload) => ({ ...state, mail_detail_files: payload }))
    .case(get_mail_file_success, (state, payload) => ({ ...state, mail_file: payload }))


    .case(change_mail_loading, (state, payload) => ({ ...state, mail_loading: payload }))

    .case(change_get_mail_details_loading, (state, payload) => ({ ...state, get_mail_details_loading: payload }))
    .case(change_get_mail_detail_files_loading, (state, payload) => ({ ...state, get_mail_detail_files_loading: payload }))

    .case(change_mail_new_success, (state, payload) => ({ ...state, mail_new: payload }))
    .case(change_mail_minimum, (state, payload) => ({ ...state, mail_minimum: payload }))

    .case(get_mail_addresses_success, (state, payload) => ({ ...state, mail_addresses: payload }))

    .case(change_send_mail_details, (state, payload) => ({ ...state, send_mail_details: payload }))

    .case(change_send_mail_editor_state, (state, payload) => ({ ...state, send_mail_editor_state: payload }))

    .case(change_send_files, (state, payload) => ({ ...state, send_files: payload }))

    .case(change_send_mail_alert, (state, payload) => ({ ...state, send_mail_alert: payload }))


    .case(change_send_to_addresses, (state, payload) => ({ ...state, send_to_addresses: payload }))

    .case(get_mail_templates_success, (state, payload) => ({ ...state, mail_templates: payload }))
    .case(add_mail_templates_success, (state, payload) => ({ ...state, mail_templates: payload }))


    .case(change_send_mail_cc_disp, (state, payload) => ({ ...state, send_mail_cc_disp: state.send_mail_cc_disp === 0 ? 1 : 0 }))
    .case(change_send_mail_bcc_disp, (state, payload) => ({ ...state, send_mail_bcc_disp: state.send_mail_bcc_disp === 0 ? 1 : 0 }))
    .case(change_send_mail_address_disp, (state, payload) => ({ ...state, send_mail_address_disp: state.send_mail_address_disp === 0 ? 1 : 0 }))

    .case(change_send_edit_contents, (state, payload) => ({ ...state, send_edit_contents: payload }))
    .case(change_send_mail_loading, (state, payload) => ({ ...state, send_mail_loading: payload }))


    .case(get_mail_file_success, (state, payload) => ({ ...state, mail_file: payload }))

    .case(get_response_mails_success, (state, payload) => ({ ...state, response_mails: payload }))
    .case(change_response_mail_loading, (state, payload) => ({ ...state, response_mail_loading: payload }))



    .case(change_editor_height, (state, payload) => ({ ...state, editor_height: payload }))
    .case(change_editor_width, (state, payload) => ({ ...state, editor_width: payload }))



    // .case(add_mails_success, (state, payload) => ({ ...state, mails: state.mails.concat(payload) }));
    .case(change_min_mail_id, (state, payload) => ({ ...state, min_mail_id: (state.min_mail_id === 0 || state.min_mail_id > payload) ? payload : state.min_mail_id }))
    .case(change_mail_listening, (state, payload) => ({ ...state, mail_listening: payload }))

    .case(change_mail_rent_introduction_header_list, (state, payload) => ({ ...state, mail_rent_introduction_header_list: payload }))
    .case(change_mail_template_select_list, (state, payload) => ({ ...state, mail_template_select_list: payload }))
    .case(change_mail_tab_change_loading, (state, payload) => ({ ...state, mail_tab_change_loading: payload }))





// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const mailSaga = [
    // takeEvery('GET_MAILS', handle_get_mails),
    takeEvery('ADD_MAILS', handle_add_mails),
    takeEvery('GET_MAIL_DETAILS', handle_get_mail_details),

    takeEvery('GET_MAIL_DETAIL_FAILES', handle_get_mail_detail_files),
    takeEvery('GET_MAIL_FAILE', handle_get_mail_file),

    takeEvery('SEND_MAIL', handle_send_mail),

    takeEvery('ADD_MAIL_FILE', handle_add_mail_file),
    takeEvery('ADD_MAIL_IMAGE', handle_add_mail_image),


    takeEvery('GET_MAIL_ADDRESSES', handle_get_mail_addresses),

    takeEvery('CHANGE_MAIL_NEW', handle_change_mail_new),



    takeEvery('CHANGE_MAIL_DRAFT_EDIT', handle_change_mail_draft_edit),


    takeEvery('DELETE_MAIL_FAILE', handle_delete_mail_file),

    takeEvery('DELETE_DRAFT', handle_delete_draft),

    takeEvery('CLOSE_MAIL', handle_close_mail),

    takeEvery('GET_MAIL_RENT_INTRODUCTION_HEADER_LIST', handle_get_mail_rent_introduction_header_list),
    takeEvery('GET_MAIL_TEMPLATE_SELECT_LIST', handle_get_mail_template_select_list),

    takeEvery('ADD_MAIL_TEMPLATES_DETAIL', handle_add_mail_template_detail),


    takeEvery('CHANGE_MAIL_NEW_TEMPLATE', handle_change_mail_new_template),


    takeEvery('CHANGE_MAIL_NEW_PUBLICFLES', handle_change_mail_new_publicfiles),




    // takeEvery('GET_MAIL_TEMPLATES', handle_get_mail_templates),

    // takeEvery('UPDATE_MAIL_TEMPLATES', handle_update_mail_templates),
    // takeEvery('DELETE_MAIL_TEMPLATES', handle_delete_mail_templates),

    // takeEvery('ADD_MAIL_TEMPLATES_CATEGORY', handle_add_mail_templates_category),
    // takeEvery('DELETE_MAIL_TEMPLATES_CATEGORY', handle_delete_mail_templates_category),

    // takeEvery('ADD_MAIL_TEMPLATES', handle_add_mail_templates),

    // takeEvery('UPDATE_MAIL_TEMPLATES_CATEGORY', handle_update_mail_templates_category),


    takeEvery('UPDATE_SEND_MAIL_EDITOR_STATE', handle_update_send_mail_editor_state),



    takeEvery('GET_RESPONSE_MAILS', handle_get_response_mails),

    takeEvery('ADD_MYPAGE_LINK', handle_add_mypage_link),


    takeEvery('ADD_MAIL_ROOM', handle_add_mail_room),

    takeEvery('DELETE_MEDIA_MAIL', handle_delete_media_mail),

    takeEvery('UPDATE_MAIL_LOOKED', handle_update_mail_looked),

];


function* handle_close_mail() {
    yield put(change_mail_new_success(0))
    yield put(change_send_mail_details([]))
    yield put(change_mail_rent_introduction_header_list({}))
}




// function createEventChannel() {

//     var db = firebase.firestore();
//     const listener: any = eventChannel(
//         emit => {
//             const unsubscribe = db.collection("tomails").doc('sun@global-center.co.jp').collection('mails').orderBy("Id", 'desc').limit(10).onSnapshot(function (snapshot) {

//                 snapshot.docChanges().forEach(function (change) {

//                     if (change.type === 'added') {

//                         return emit(change.doc.data())

//                     }

//                 });
//             });

//             return () => unsubscribe;
//         }

//     );

//     return listener;
// };



// function* handle_get_mails(action: any): any {
//     const state = yield select();
//     if (state.mailReducer.mail_listening === 0) {
//         yield put(change_mail_listening(1))

//         // const channel = yield call(createEventChannel)
//         // while (true) {
//         //     const getmails = yield take(channel);
//         //     console.log(getmails)

//         //     yield put(change_min_mail_id(getmails.Id));

//         //     yield put(get_mails_success(getmails));
//         //     yield put(change_mail_loading(0));
//         // }
//     }

// }

function* handle_get_response_mails(action: any) {

    yield put(change_response_mail_loading(1));
    const { payload, error } = yield call(MailsApi.maillist, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)

        // 成功時アクション呼び出し
        yield put(get_response_mails_success(payload))
        // yield put(change_get_mail_details_loading(0));
        yield put(change_response_mail_loading(0));


    } else {


    }
}


// function getEventChannel(state: any) {

//     var db = firebase.firestore();
//     const listener: any = eventChannel(
//         emit => {

//             const citiesRef = db.collection("tomails").doc('sun@global-center.co.jp').collection('mails').where('Id', '<', state.mailReducer.min_mail_id).orderBy("Id", 'desc').limit(20);

//             var unsubscribe = citiesRef.get()

//                 .then(snapshot => {
//                     snapshot.forEach(doc => {

//                         //  console.log(doc.data().Id)
//                         return emit(doc.data())
//                     });
//                 })
//                 .catch(err => {
//                     console.log('Error getting documents', err);
//                 });



//             return () => unsubscribe;
//         }

//     );

//     return listener;
// };


function* handle_add_mails(action: any): any {

    const state = yield select();

    yield put(change_mail_loading(1));
    // const channels = getEventChannel(state);
    // console.log(channels)
    // while (true) {

    //     const getmails = yield take(channels);

    //     // console.log(getmails)

    //     yield put(change_min_mail_id(getmails.Id));
    //     yield put(get_mails_success(getmails));
    //     yield put(change_mail_loading(0));

    // }
}


function* handle_get_mail_details(action: any): any {

    const state = yield select();
    // yield put(change_customerloding0(0))

    if (state.mailReducer.get_mail_details_loading === 0) {
        yield put(change_get_mail_details_loading(1));
        yield put(get_mail_details_success({}))
        const { payload, error } = yield call(MailsApi.detail, action.payload);

        // サーバーからのレスポンスデータによる分岐処理
        if (payload && !error) {
            // 成功時アクション呼び出し


            yield put(get_mail_details_success(payload))
            yield put(change_get_mail_details_loading(0));



        } else {

            // yield put(Common.change_limitmodal(true))

        }
    }

}



function* handle_get_mail_detail_files(action: any) {


    yield put(change_get_mail_detail_files_loading(1))
    yield put(get_mail_detail_files_success({}))
    // alert(action.payload)
    const { payload, error } = yield call(MailDetailFilesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // console.log(payload)
        // alert("dd")
        yield put(get_mail_detail_files_success(payload))

        yield put(change_get_mail_detail_files_loading(0))


    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}

function* handle_get_mail_file(action: any) {

    const { payload, error } = yield call(MailDetailFilesApi.get_file, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // ダウンロード
        // console.log(payload)
        const a = document.createElement('a');
        // ダウンロードされるファイル名
        a.download = action.payload.name;
        a.href = URL.createObjectURL(payload);
        // ダウンロード開始
        a.click();


        // let anchor = document.createElement("a");

        // anchor.href = window.URL.createObjectURL(payload);
        // alert(window.URL.createObjectURL(payload))
        // window.URL.createObjectURL(payload).click();




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }
}

function* handle_send_mail(action: any) {

    yield put(change_send_mail_loading(true))
    const { payload, error } = yield call(MailSendApi.send, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        // ダウンロード
        // const a = document.createElement('a');
        // // ダウンロードされるファイル名
        // a.download = action.payload.name;
        // a.href = URL.createObjectURL(payload);
        // // ダウンロード開始
        // a.click();
        // console.log(payload)
        // console.log(payload)
        if (payload == 2) {
            yield put(change_send_mail_alert(1))

            yield delay(500)
            yield put(get_response_mails(action.payload.rentResponseId))
            // yield put(change_mail_new_success(0))
            yield put(close_mail())
            yield put(change_send_mail_alert(0))
            yield put(change_send_mail_loading(false))

            yield put(CustomerContacts.get_rent_customer_contacts(action.payload.rentResponseId))


        } else {

            yield put(change_send_mail_alert(2))

            yield delay(1500)
            yield put(change_send_mail_alert(0))
            yield put(change_send_mail_loading(false))
        }
        // alert("send")
        // let anchor = document.createElement("a");

        // anchor.href = window.URL.createObjectURL(payload);
        // alert(window.URL.createObjectURL(payload))
        // window.URL.createObjectURL(payload).click();




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }
}


function* handle_add_mail_file(action: any) {

    yield put(change_get_mail_detail_files_loading(1))
    // console.log( action.payload)
    yield put(get_mail_detail_files_success([]))
    const { payload, error } = yield call(MailSendApi.add_file, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        //  yield put(change_send_files(payload));

        yield put(get_mail_detail_files_success(payload))

        yield put(change_get_mail_detail_files_loading(0))
        // let anchor = document.createElement("a");

        // anchor.href = window.URL.createObjectURL(payload);
        // alert(window.URL.createObjectURL(payload))
        // window.URL.createObjectURL(payload).click();




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }
}


function* handle_add_mail_image(action: any) {

    const { payload, error } = yield call(MailSendApi.add_image, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        yield put(change_mail_image_cid(payload));

        return payload;


        // let anchor = document.createElement("a");

        // anchor.href = window.URL.createObjectURL(payload);
        // alert(window.URL.createObjectURL(payload))
        // window.URL.createObjectURL(payload).click();




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }
}



function* handle_get_mail_addresses(action: any) {

    const { payload, error } = yield call(MailMAddressesApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し


        const mailaddress = payload.map((item: any, i: number) => ({
            name: item.address,
        }))
        yield put(get_mail_addresses_success(mailaddress))




    } else {
        alert(error)
        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}



function* handle_change_mail_new(action: any) {

    // const state = yield select();

    yield put(get_mail_detail_files_success([]))
    yield put(change_send_mail_details([]))


    const { payload, error } = yield call(MailsApi.newmail, action.payload);



    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        const sendmaildetail = payload;

        sendmaildetail.sendType = sendmaildetail.sendType ? sendmaildetail.sendType :  0

        const searchStrings = ["@softbank.ne.jp", "@vodafone.ne.jp", "@ezweb.ne.jp"];

        // 配列の中から文字列が含まれているかどうかをチェック
        const isStringIncluded = searchStrings.some(searchString => sendmaildetail.toMail.includes(searchString));

        if (isStringIncluded) {
            // alert("少なくとも1つの文字列が含まれています");
            sendmaildetail.sendType = 1
        } else {
            // alert("どの文字列も含まれていません");
        }

        sendmaildetail.tab = sendmaildetail.sendType ? parseInt(sendmaildetail.sendType) == 0 || parseInt(sendmaildetail.sendType) == 2 ? 0 : 1 : 0


        yield put(change_send_mail_details(sendmaildetail))
        yield put(get_response_mails(action.payload.rentResponseId))
        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))

        yield put(get_mail_detail_files(sendmaildetail.id))
        yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}





function* handle_change_mail_new_template(action: any) {

    // const state = yield select();

    yield put(get_mail_detail_files_success([]))
    yield put(change_send_mail_details([]))



    const { payload, error } = yield call(MailsApi.newmailtemplate, action.payload);



    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // 成功時アクション呼び出し
        let sendmaildetail = {
            attachmentInfo: payload.attachment_info,
            attachments: payload.attachments,
            bcc: payload.bcc,
            body: payload.body,
            bodyUtf8: payload.bodyUtf8,
            cc: payload.cc,
            char: payload.char,
            charsets: payload.charsets,
            contentIds: payload.content_ids,
            date: payload.date,
            dateBinary: payload.date_binary,
            dkim: payload.dkim,
            draftjs: payload.draftjs,
            envelope: payload.envelope,
            fromMail: payload.from_mail,
            fromName: payload.from_name,
            headers: payload.headers,
            html: payload.html,
            htmlUtf8: payload.html_utf8,
            id: payload.id,
            isDeleted: payload.is_deleted,
            isSended: payload.is_sended,
            mailAccountId: payload.mail_account_id,
            openedAt: payload.opened_at,
            rentCustomerId: payload.rent_customer_id,
            rentResponseId: payload.rent_response_id,
            requestContactWay: payload.request_contact_way,
            requestContents: payload.request_contents,
            requestCustomerAddress: payload.request_customer_address,
            requestCustomerFax: payload.request_customer_fax,
            requestCustomerMail: payload.request_customer_mail,
            requestCustomerName: payload.request_customer_name,
            requestCustomerNameKana: payload.request_customer_name_kana,
            requestCustomerPost: payload.request_customer_post,
            requestCustomerTel: payload.request_customer_tel,
            requestRentMMediaId: payload.request_rent_m_media_id,
            requestRoomArea: payload.request_room_area,
            requestRoomChinryou: payload.request_room_chinryou,
            requestRoomChoiki: payload.request_room_choiki,
            requestRoomId: payload.request_room_id,
            requestRoomKyouekihi: payload.request_room_kyouekihi,
            requestRoomMadoriNo: payload.request_room_madori_no,
            requestRoomName: payload.request_room_name,
            requestRoomReikin: payload.request_room_reikin,
            requestRoomSetsubi: payload.request_room_setsubi,
            requestRoomShikikin: payload.request_room_shikikin,
            requestRoomShunkouMonth: payload.request_room_shunkou_month,
            requestRoomShunkouYear: payload.request_room_shunkou_year,
            requestTatemonoId: payload.request_tatemono_id,
            requestTatemonoName: payload.request_tatemono_name,
            returnedMailId: payload.returned_mail_id,
            sendIntendAt: payload.send_intend_at,
            senderIp: payload.sender_ip,
            spamReport: payload.spam_report,
            spamScore: payload.spam_score,
            spf: payload.spf,
            subject: payload.subject,
            toMail: payload.to_mail,
            toMailall: payload.to_mailall,
            toName: payload.to_name,
        }




        yield put(change_send_mail_details(sendmaildetail))
        yield put(get_response_mails(action.payload.rent_response_id))
        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))

        yield put(get_mail_detail_files(sendmaildetail.id))
        yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}



function* handle_change_mail_new_publicfiles(action: any) {

    // const state = yield select();

    yield put(get_mail_detail_files_success([]))
    yield put(change_send_mail_details([]))



    const { payload, error } = yield call(MailsApi.newmailpublicfiles, action.payload);



    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // 成功時アクション呼び出し
        let sendmaildetail = {
            sendType: 0,
            tab: 0,
            attachmentInfo: payload.attachment_info,
            attachments: payload.attachments,
            bcc: payload.bcc,
            body: payload.body,
            bodyUtf8: payload.bodyUtf8,
            cc: payload.cc,
            char: payload.char,
            charsets: payload.charsets,
            contentIds: payload.content_ids,
            date: payload.date,
            dateBinary: payload.date_binary,
            dkim: payload.dkim,
            draftjs: payload.draftjs,
            envelope: payload.envelope,
            fromMail: payload.from_mail,
            fromName: payload.from_name,
            headers: payload.headers,
            html: payload.html,
            htmlUtf8: payload.html_utf8,
            id: payload.id,
            isDeleted: payload.is_deleted,
            isSended: payload.is_sended,
            mailAccountId: payload.mail_account_id,
            openedAt: payload.opened_at,
            rentCustomerId: payload.rent_customer_id,
            rentResponseId: payload.rent_response_id,
            requestContactWay: payload.request_contact_way,
            requestContents: payload.request_contents,
            requestCustomerAddress: payload.request_customer_address,
            requestCustomerFax: payload.request_customer_fax,
            requestCustomerMail: payload.request_customer_mail,
            requestCustomerName: payload.request_customer_name,
            requestCustomerNameKana: payload.request_customer_name_kana,
            requestCustomerPost: payload.request_customer_post,
            requestCustomerTel: payload.request_customer_tel,
            requestRentMMediaId: payload.request_rent_m_media_id,
            requestRoomArea: payload.request_room_area,
            requestRoomChinryou: payload.request_room_chinryou,
            requestRoomChoiki: payload.request_room_choiki,
            requestRoomId: payload.request_room_id,
            requestRoomKyouekihi: payload.request_room_kyouekihi,
            requestRoomMadoriNo: payload.request_room_madori_no,
            requestRoomName: payload.request_room_name,
            requestRoomReikin: payload.request_room_reikin,
            requestRoomSetsubi: payload.request_room_setsubi,
            requestRoomShikikin: payload.request_room_shikikin,
            requestRoomShunkouMonth: payload.request_room_shunkou_month,
            requestRoomShunkouYear: payload.request_room_shunkou_year,
            requestTatemonoId: payload.request_tatemono_id,
            requestTatemonoName: payload.request_tatemono_name,
            returnedMailId: payload.returned_mail_id,
            sendIntendAt: payload.send_intend_at,
            senderIp: payload.sender_ip,
            spamReport: payload.spam_report,
            spamScore: payload.spam_score,
            spf: payload.spf,
            subject: payload.subject,
            toMail: payload.to_mail,
            toMailall: payload.to_mailall,
            toName: payload.to_name,
        }




        yield put(change_send_mail_details(sendmaildetail))
        yield put(get_response_mails(action.payload.rent_response_id))
        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))

        yield put(get_mail_detail_files(sendmaildetail.id))
        yield put(change_mail_new_success(1))




    } else {

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}


function* handle_change_mail_draft_edit(action: any) {

    // const state = yield select();
    // console.log(action)
    yield put(get_mail_detail_files_success([]))
    yield put(change_send_mail_details([]))

    // const { payload, error }= yield call(MailAllReceivesApi.newmail, action.payload);

    // // サーバーからのレスポンスデータによる分岐処理
    // if (payload && !error) {

    let detail = action.payload

    detail.sendType = detail.sendType ? detail.sendType :  0

    detail.tab = detail.sendType ? parseInt(detail.sendType) == 0 || parseInt(detail.sendType) == 2 ? 0 : 1 : 0

    // 成功時アクション呼び出し
    yield put(change_send_mail_details(action.payload))

    yield put(get_mail_detail_files(action.payload.id))
    // alert(action.payload.id)
    // const mailaddress = payload.map((item:any, i:number) => ({
    //     name: item.address,
    //   }))
    yield put(change_mail_new_success(1))



    // } else {

    //         // yield put(customerDetail0Failure(payload))
    //         // yield put(change_customerloding0(1))
    // }

}

function* handle_update_send_mail_editor_state(action: any) {


    const { payload, error } = yield call(MailsApi.update, action.payload);
    // console.log(action.payload.toMailall+"dddssssd")
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        //    console.log(payload.toMailall+"dddd")

        // 成功時アクション呼び出し
        // yield put(change_send_mail_details(payload))

        // const mailaddress = payload.map((item:any, i:number) => ({
        //     name: item.address,
        //   }))

        let send_mail_details = {...payload}
        if(action.payload.bodyChange){//メール形式修正時
            
            send_mail_details.tab = payload.sendType ? parseInt(payload.sendType) == 0 || parseInt(payload.sendType) == 2 ? 0 : 1 : 0
        }else{
            send_mail_details.tab = action.payload.tab
        }
        
        yield put(change_send_mail_details(send_mail_details))


        yield put(get_response_mails(action.payload.rentResponseId))

        yield put(change_mail_tab_change_loading(false))


    } else {
        alert(error)

        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}


function* handle_delete_draft(action: any) {
    yield put(change_send_mail_loading(true))

    const { payload, error } = yield call(MailsApi.update, action.payload);
    // console.log(action.payload.toMailall+"dddssssd")
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(change_send_mail_alert(3))

        yield delay(500)
        yield put(get_response_mails(action.payload.rentResponseId))
        // yield put(change_mail_new_success(0))
        yield put(close_mail())
        yield put(change_send_mail_alert(0))
        yield put(change_send_mail_loading(false))
    } else {

        yield put(change_send_mail_alert(4))

        yield delay(1500)
        yield put(change_send_mail_alert(0))
        yield put(change_send_mail_loading(false))
    }

}


function* handle_delete_mail_file(action: any) {


    yield put(change_get_mail_detail_files_loading(1))

    const { payload, error } = yield call(MailsApi.delete_file, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        yield put(get_mail_detail_files_success(payload))

        yield put(change_get_mail_detail_files_loading(0))
    } else {
        alert(error)

    }

}

function* handle_add_mypage_link(action: any):any {
    // alert(action.payload.id)

    const state = yield select();
    let send_mail_details = {...state.mailReducer.send_mail_details}


    const { payload, error } = yield call(MailTemplatesApi.responsedetail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // // alert(payload.bodyAll)
        // // let editor =action.payload.window.CKEDITOR.instances.editor1;
        // let editor = action.payload.window.CKEDITOR.instances[Object.keys(action.payload.window.CKEDITOR.instances)[0]];
        // // const state = yield select();

        // // payload.bodyAll= payload.bodyAll.replace(/%お客様名%/g, state.customersReducer.customerdetail.name1 );    
        // // state.mailReducer.send_mail_details.subject=payload.subject;
        // // alert(state.mailReducer.send_mail_details.subject)
        // // yield put(change_send_mail_details(state.mailReducer.send_mail_details))

        // editor.insertHtml(payload.bodyAll);
        // // state.form.sendmailForm.subject=payload.subject;


        const editorInstance = window.editorInstance2;

        // editorInstance と model、document の null チェック
        if (editorInstance && editorInstance.model && editorInstance.model.document) {
            editorInstance.model.change(writer => {

                // console.log('挿入データ:',payload.body_all)
                // HTMLを含む要素を挿入する
                const viewFragment = editorInstance.data.processor.toView('<div>' + payload.bodyAll + '</div>');
                const modelFragment = editorInstance.data.toModel(viewFragment);
                editorInstance.model.insertContent(modelFragment, editorInstance.model.document.selection);

            });

            // エディタの全内容を取得
            const editorContent = editorInstance.getData();
            console.log('エディタの全内容:', editorContent);

            send_mail_details.htmlUtf8 = editorContent

            yield put(change_send_mail_details(send_mail_details))

        } else {
            console.error('Editor instance or model or document is not available');
        }

    } else {
        alert(error)
        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}


function* handle_get_mail_rent_introduction_header_list(action: any) {
    // alert(action.payload.id)
    yield put(change_mail_rent_introduction_header_list({}))
    const { payload, error } = yield call(RentIntroductionHeadersListApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)
        yield put(change_mail_rent_introduction_header_list(payload))

        // alert(payload.bodyAll)
        // let editor =action.payload.window.CKEDITOR.instances.editor1;
        // const state = yield select();

        // payload.bodyAll= payload.bodyAll.replace(/%お客様名%/g, state.customersReducer.customerdetail.name1 );    
        // state.mailReducer.send_mail_details.subject=payload.subject;
        // alert(state.mailReducer.send_mail_details.subject)
        // yield put(change_send_mail_details(state.mailReducer.send_mail_details))

        // state.form.sendmailForm.subject=payload.subject;


    } else {
        alert(error)
        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}


function* handle_get_mail_template_select_list(action: any) {
    // alert(action.payload.id)
    const { payload, error } = yield call(MailTemplateSelectListApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // console.log(payload)
        yield put(change_mail_template_select_list(payload))

        // alert(payload.bodyAll)
        // let editor =action.payload.window.CKEDITOR.instances.editor1;
        // const state = yield select();

        // payload.bodyAll= payload.bodyAll.replace(/%お客様名%/g, state.customersReducer.customerdetail.name1 );    
        // state.mailReducer.send_mail_details.subject=payload.subject;
        // alert(state.mailReducer.send_mail_details.subject)
        // yield put(change_send_mail_details(state.mailReducer.send_mail_details))

        // state.form.sendmailForm.subject=payload.subject;


    } else {
        alert(error)
        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}



function* handle_add_mail_template_detail(action: any): any {
    // alert(action.payload.id)
    const { payload, error } = yield call(MailTemplateDetailApi.responsedetail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // alert(payload.bodyAll)
        // let editor =action.payload.window.CKEDITOR.instances.editor1;
        const state = yield select();
        let editor = action.payload.window.CKEDITOR.instances[Object.keys(action.payload.window.CKEDITOR.instances)[0]];
        if (action.payload.isDisp === 2 || action.payload.isDisp === 3 || action.payload.isDisp === 4) {
            // editor.setData(payload.html_utf8);
            editor.setData(payload.bodyAll);

            state.mailReducer.send_mail_details.subject = payload.subject;
        } else {
            // editor.insertHtml(payload.html_utf8);
            editor.insertHtml(payload.bodyAll);
        }
        // payload.bodyAll= payload.bodyAll.replace(/%お客様名%/g, state.customersReducer.customerdetail.name1 );    

        // alert(state.mailReducer.send_mail_details.subject)
        yield put(change_send_mail_details(state.mailReducer.send_mail_details))


        // state.form.sendmailForm.subject=payload.subject;


    } else {
        alert(error)
        // yield put(customerDetail0Failure(payload))
        // yield put(change_customerloding0(1))
    }

}
// function* handle_get_mail_templates(action: any) {



//     const { payload, error }= yield call(MailTemplatesApi.list, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//     // 成功時アクション呼び出し
//     yield put(get_mail_templates_success(payload))

//     // const mailaddress = payload.map((item:any, i:number) => ({
//     //     name: item.address,
//     //   }))
//         // yield put(change_mail_new_success(1))




//     } else {

//             // yield put(customerDetail0Failure(payload))
//             // yield put(change_customerloding0(1))
//     }

//     }


// function* handle_update_mail_templates(action: any) {



//     const { payload, error }= yield call(MailTemplatesApi.update, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//     // 成功時アクション呼び出し
//     // yield put(get_mail_templates_success(payload))

//     yield put(get_mail_templates())

//     // const mailaddress = payload.map((item:any, i:number) => ({
//     //     name: item.address,
//     //   }))
//         // yield put(change_mail_new_success(1))




//     } else {

//             // yield put(customerDetail0Failure(payload))
//             // yield put(change_customerloding0(1))
//     }

//     }

// function* handle_delete_mail_templates(action: any) {



//     const { payload, error }= yield call(MailTemplatesApi.deletetemplate, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//     // 成功時アクション呼び出し
//     // yield put(get_mail_templates_success(payload))

//     yield put(get_mail_templates())

//     // const mailaddress = payload.map((item:any, i:number) => ({
//     //     name: item.address,
//     //   }))
//         // yield put(change_mail_new_success(1))




//     } else {
//         alert(error)
//             // yield put(customerDetail0Failure(payload))
//             // yield put(change_customerloding0(1))
//     }

//     }



// function* handle_add_mail_templates(action: any) {



//     const { payload, error }= yield call(MailTemplatesApi.insert, action.payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     if (payload && !error) {
//     // 成功時アクション呼び出し
//     // yield put(add_mail_templates_success(payload))
//     yield put(get_mail_templates())
//     // const mailaddress = payload.map((item:any, i:number) => ({
//     //     name: item.address,
//     //   }))
//         // yield put(change_mail_new_success(1))




//     } else {

//             // yield put(customerDetail0Failure(payload))
//             // yield put(change_customerloding0(1))
//     }

//     }

//         function* handle_add_mail_templates_category(action: any) {

// console.log(action.payload)

//             const { payload, error }= yield call(MailTemplatesApi.insertcategory, action.payload);

//             // サーバーからのレスポンスデータによる分岐処理
//             if (payload && !error) {
//             // 成功時アクション呼び出し
//             // yield put(get_mail_templates_success(payload))
//             yield put(get_mail_templates())

//             // const mailaddress = payload.map((item:any, i:number) => ({
//             //     name: item.address,
//             //   }))
//                 // yield put(change_mail_new_success(1))




//             } else {

//                     // yield put(customerDetail0Failure(payload))
//                     // yield put(change_customerloding0(1))
//             }

//             }

// function* handle_update_mail_templates_category(action: any) {

//     console.log(action.payload)

//                 const { payload, error }= yield call(MailTemplatesApi.updatecategory, action.payload);

//                 // サーバーからのレスポンスデータによる分岐処理
//                 if (payload && !error) {
//                 // 成功時アクション呼び出し
//                 // yield put(get_mail_templates_success(payload))
//                 yield put(get_mail_templates())

//                 // const mailaddress = payload.map((item:any, i:number) => ({
//                 //     name: item.address,
//                 //   }))
//                     // yield put(change_mail_new_success(1))




//                 } else {

//                         // yield put(customerDetail0Failure(payload))
//                         // yield put(change_customerloding0(1))
//                 }

//                 }

// function* handle_delete_mail_templates_category(action: any) {

//     console.log(action.payload)

//                 const { payload, error }= yield call(MailTemplatesApi.deletecategory, action.payload);

//                 // サーバーからのレスポンスデータによる分岐処理
//                 if (payload && !error) {
//                 // 成功時アクション呼び出し
//                 // yield put(get_mail_templates_success(payload))
//                 yield put(get_mail_templates())

//                 // const mailaddress = payload.map((item:any, i:number) => ({
//                 //     name: item.address,
//                 //   }))
//                     // yield put(change_mail_new_success(1))




//                 } else {

//                         // yield put(customerDetail0Failure(payload))
//                         // yield put(change_customerloding0(1))
//                 }

//                 }


function* handle_add_mail_room(action: any): any {
    //    alert("dd")
    console.log(action.payload)
    const state = yield select();
    // alert(action.payload)

    let rent_response_id = state.responsesReducer.responseid
    let send_mail_details = {...state.mailReducer.send_mail_details}
    // const { payload, error }= yield call(SearchRoomsApi.detail, action.payload);
    // const room =  payload;
    // // サーバーからのレスポンスデータによる分岐処理
    // if (payload && !error) {
    //  alert("saga")
    if (rent_response_id > 0) {
        const { payload, error } = yield call(MailTemplatesApi.rooms, { rent_response_id: rent_response_id, tatemono_id: action.payload.tatemonoId, room_id: action.payload.roomId });

        if (payload && !error) {
            // // alert("success")

            // // let editor =action.payload.window.CKEDITOR.instances.editor1;
            // let editor = action.payload.window.CKEDITOR.instances[Object.keys(action.payload.window.CKEDITOR.instances)[0]];
            // // payload.bodyAll= payload.bodyAll.replace("%物件名%", room.tatemonoName+room.roomName );    

            // // console.log(payload)
            // // alert()
            // editor.insertHtml(payload.body_all);

            const editorInstance = window.editorInstance2;

            // editorInstance と model、document の null チェック
            if (editorInstance && editorInstance.model && editorInstance.model.document) {
                editorInstance.model.change(writer => {
                    // HTMLを含む要素を挿入する '<p><strong>HTMLで挿入されたテキスト</strong></p>'

                    // console.log('挿入データ:',payload.body_all)
                    // HTMLを含む要素を挿入する
                    const viewFragment = editorInstance.data.processor.toView('<div>' + payload.body_all + '</div>');
                    const modelFragment = editorInstance.data.toModel(viewFragment);
                    editorInstance.model.insertContent(modelFragment, editorInstance.model.document.selection);

                });

                // エディタの全内容を取得
                const editorContent = editorInstance.getData();
                // console.log('エディタの全内容:', editorContent);

                send_mail_details.htmlUtf8 = editorContent

                yield put(change_send_mail_details(send_mail_details))

            } else {
                console.error('Editor instance or model or document is not available');
            }

        } else {
            alert(error)
        }
    }
    // 成功時アクション呼び出し
    // yield put(get_mail_templates_success(payload))
    // yield put(get_mail_templates())


    // } else {

    //         // yield put(customerDetail0Failure(payload))
    //         // yield put(change_customerloding0(1))
    // }

}

function* handle_delete_media_mail(action: any): any {
    // yield put(change_line_files_loading(true));
    const state = yield select();
    // alert(action.payload)

    let response_mails = state.mailReducer.response_mails
    const { payload, error } = yield call(MailsApi.delete_media_mail, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {


        let result = response_mails.filter((value: any) => {
            return (!(value.id == action.payload));
        });
        yield put(get_response_mails_success(result))
        // line_user_details: state.line.line_user_details,
        // yield put(get_line_user_details_success([]))

    } else {
        alert(error)

    }
}


function* handle_update_mail_looked(action: any): any {
    // yield put(change_line_files_loading(true));
    const state = yield select();

    // yield put(change_line_send_file_loading(true));

    const { payload, error } = yield call(rent_responses_api.change, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {

        //詳細画面
        let responsedetail = {...state.responsesReducer.responsedetail}
        
        responsedetail.status = 6
        responsedetail.intendedRentMResponseStatusId = 6
        responsedetail.intended_rent_m_response_status_id = 6
        responsedetail.receive_mail_at = null
        yield put(Responses.change_responsedetail(responsedetail))


        //反響リスト
        let response_list = state.responselist.response_list
        let response_search_list = state.responselist.response_search_list

        let result = response_list.map(function (value: any) {
        if(value.id == action.payload.id){
            value.status = 6;
            value["receive_mail_at"] = null

        }
        return value;
        })

        yield put(ResponseList.change_response_list(result));

        result = response_search_list.map(function (value: any) {
        if(value.id == action.payload.id){
            value.status = 6;
            value["receive_mail_at"] = null
        }
        return value;
        })

        yield put(ResponseList.change_response_search_list(result));


        //契約リスト
        let contract_follow_list = state.contractfollow.contract_follow_list

        result = contract_follow_list.map(function (value: any) {
            if(value.rent_response_id == action.payload.id){
              value.status = 6;
              value["receive_mail_at"] = null
            }
            return value;
        })
        yield put(ContractFollow.change_contract_follow_list(contract_follow_list))
        yield put(ContractFollow.search_contract_follow_list(contract_follow_list))
        



    } else {
        alert(error)

    }
}