import React, { Component } from 'react';

import { connect } from 'react-redux';


import ResponseListSearch from "./Search";

import './Css/Search.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { compose } from 'redux'
import Grid from './Grid';

import { getRentMReasons } from '../../Store/Master';
import { get_contract_follow_list, get_rent_m_contract_follows, get_rent_m_agents } from './Saga/ContractFollow';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { change_response_add_disp, change_response_add_detail, change_customer_add_detail } from '../ResponseDetail/Saga/Responses';
import MailTemplateComponent from '../MailTemplate/Index';
import EventListener from 'react-event-listener';
import '../../Css/Form.css';
import withStyles from '@mui/styles/withStyles';
const styles = (theme) => ({

    backdrop: {
        // zIndex: theme.zIndex.drawer + 1,
        zIndex: 20000,
        color: '#fff',
    },

})

class ResponseList extends Component {
    // class ResponseList extends Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            height: window.innerHeight,
            toggleaddresponse: false,
            togglemailtemplate: false,
            showRight: 0,
            userDetail: {},
            condition: "1"
        };


        this.props.get_rent_m_contract_follows()
        this.props.get_rent_m_agents()
        this.handletoggleaddresponse = this.handletoggleaddresponse.bind(this);
        this.handletogglemailtemplate = this.handletogglemailtemplate.bind(this);
        this.responsedetailsearch = this.responsedetailsearch.bind(this);

    }
    componentWillMount() {

        //this.props.dispatchRequest()
    }

    handleResize = () => {
        console.info(
            `window height:width=${window.innerHeight}:${window.innerWidth}`,
        );
        this.setState({ height: window.innerHeight });
    };
    handletoggleaddresponse(state) {

        this.setState({ userDetail: state })
        this.setState(prevState => ({
            toggleaddresponse: !prevState.toggleaddresponse
        }));

    }

    handletogglemailtemplate() {
        this.setState(prevState => ({
            togglemailtemplate: !prevState.togglemailtemplate
        }));

    }
    //   toggleaddresponse(){
    //     this.setState(prevState => ({
    //         toggleaddresponse: !prevState.toggleaddresponse
    //     }));
    // }
    responsedetailsearch(value) {
        // alert(JSON.stringify(value))
        // alert(value.condition)
        this.setState({ condition: value.condition })

        this.props.get_contract_follow_list(value)

    }
    render() {

        return (
            <div >
                <EventListener target="window" onResize={this.handleResize} />
                {this.state.togglemailtemplate === true ? <MailTemplateComponent /> :
                    <div className="box responseMenu" style={{ position: "relative" }}>

                        <div style={{ position: "absolute", zIndex: 5, left: 0, right: 0 }}>
                            <div className="row" >
                                <div className=" col-lg-12" id="contractfollow">
                                    <Card >
                                        <CardContent >
                                            {/* <div style={{ backgroundColor: "#FFF" }}> */}
                                            {this.props.masterReducer.user_details.id > 0 ? <ResponseListSearch
                                                user_details={this.props.masterReducer.user_details}
                                                onSubmit={this.responsedetailsearch}
                                                handletoggleaddresponse={this.handletoggleaddresponse}
                                                handletogglemailtemplate={this.handletogglemailtemplate}
                                            // toggleaddresponse={this.toggleaddresponse}
                                            /> : ""}

                                            {/* </div> */}
                                        </CardContent >
                                    </Card >
                                </div>
                            </div>
                            <div className="row">
                                <div className="item-outer col-lg-12">
                                    <Card >
                                        <CardContent >

                                            {this.props.contractfollow.rent_m_contract_follows.length > 0 && this.props.masterReducer.user_details.amSectionId > 0 ? <Grid /> : ""}
                                        </CardContent >
                                    </Card >
                                </div>
                            </div>
                        </div>

                        {/* <div style={{ position: "absolute", zIndex: 100, width: this.state.showRight + 40, right: 0 }}>
                            <div style={{ float: "left" }}>
                                <div style={{ backgroundColor: "#a9a9a9", width: 40, height: 40, textAlign: "left", paddingLeft: 8, paddingTop: 6 }}>
                                    <div className="fa fa-search" style={{ fontSize: 26, color: "White" }} onClick={() => this.setState({ "showRight": this.state.showRight === 0 ? 300 : 0 })}></div>
                                </div>
                            </div>
                            <div className="search_menu" style={{ float: "left", width: 300, border: "1px solid #cdcdcd", height: this.state.height - 40, overflowY: "scroll", display: this.state.showRight === 0 ? 'none' : '' }}>
                                <Search />
                                <button className="btn btn-primary float-right" type="button" >検索</button>

                            </div>

                        </div> */}
                    </div >




                }


                {/* <Modal isOpen={this.props.responsesReducer.response_add_disp} toggle={() => this.props.change_response_add_disp(0)}
                    size="xxl" fade={false}>


                    <ModalHeader toggle={() => this.props.change_response_add_disp(0)}>新規登録</ModalHeader>
                    <ModalBody >
                        <ResponseAddComponent
                            responseedit={null}
                            responseeditchange={null}
                        />


                    </ModalBody>
                </Modal> */}


                <Backdrop className={this.props.classes.backdrop} open={this.props.contractfollow.contract_follow_backdrop}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div >
        );
    }
};

export default compose(withStyles(styles), connect(
    ({ masterReducer, responsesReducer, contractfollow }) => ({ masterReducer, responsesReducer, contractfollow }),
    // state => ({
    //   mapsearch: state.mapsearchReducer,

    // }),
    {
        get_contract_follow_list, get_rent_m_agents,
        getRentMReasons,
        change_response_add_disp, change_response_add_detail, change_customer_add_detail
        , get_rent_m_contract_follows
    }




))(ResponseList)
//export  default CustomerList;
// const mapStateToProps = (state:any) => {
//     return {

//     }
// }
// function mapDispatchToProps(dispatch: any) {
//     return {
//         responseList(search:string) {
//             dispatch(Responses.responseList(search))
//         },
//         dispatchRequest() { dispatch(Master.getRentMReasons()) },

//     };
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ResponseList);




