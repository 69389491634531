import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as AutoSend from './Saga/AutoSend';
// import * as Mail from '../Mail/Saga/Mail';
// import * as Responses from '../../Store/Responses';
import '../../Css/GridRooms.css';
import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment';
import { ModalHeader, Button, ModalBody, ModalFooter, Modal } from 'reactstrap';
// import ReactDataGrid from "react-data-grid"
const ReactDataGrid = require('react-data-grid');



// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {

    rentResponseId: number,
    get_auto_sends: any,
    auto_sends: any,
    toggle: any,
    auto_sends_loading: boolean,
    selectsheet: any,
    mailadd: any,
    add_mail_room: any,
    togglesearch: any,
    introductions: any,
    introductions_loading: number,
    delete_auto_send: any,
}


interface State {
    modalautodelete: boolean,

    tatemonoId: string,
    roomId: string
    // state types
}
// const MailLinkFormatter = (payload:any) => {

//     return <a role="button"  className="btn btn-primary" target="_blank" onClick={this.mailadd} >追加</a>;

// };
const DateFormatter = (payload: any) => {
    if (payload.value) {
        return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
    } else {
        return "";
    }
};

const gaikanFormatter = (payload: any) => {
    const url = "https://img.global-center.co.jp/v2/tatemono/" + payload.row.tatemonoId + "/1/1/520/780/0";
    return (<img src={url} />)
};

const MadoriFormatter = (payload: any) => {
    const url = "https://img.global-center.co.jp/v2/rooms/" + payload.row.tatemonoId + "/" + payload.row.roomId + "/8/1/520/780/0";
    return <div><div><img style={{ width: 50, height: "auto", maxHeight: 50 }} src={url} /></div><div>{payload.row.madoriName}</div></div>
};

const NameFormatter = (payload: any) => {
    return <div><div>{payload.row.tatemonoName}</div><div>{payload.row.shikuchoson}{payload.row.choiki}{payload.row.otherAddress}</div><div>{payload.row.shunkouYear}年{payload.row.shunkouMonth}月</div></div>
};

const RoomFormatter = (payload: any) => {
    return <div><div>{payload.row.roomName}</div><div>{payload.row.kuushitsuJoukyouName}</div><div>{payload.row.kanriKubunName}</div></div>
};

const ChinRyouFormatter = (payload: any) => {
    return (
        <div>
            <div>賃料 {payload.row.chinRyou.toLocaleString()}円</div>
            <div>共益 {payload.row.kyouekiHi != null ? payload.row.kyouekiHi.toLocaleString() : "-"}円</div>
            <div>水道 {payload.row.suidouRyou}</div>
            <div>車① {payload.row.pRyou}</div>
        </div>
    )
};
const KeiyakuFormatter = (payload: any) => {
    return (
        <div>
            <div>敷金 {payload.row.shikiKinMon === null ? "" : payload.row.shikiKinMon + "ヶ月"}</div>
            <div>礼金 {payload.row.reikinMon === null ? "" : payload.row.reikinMon + "ヶ月"}</div>
        </div>
    )
};



const columns = [

    { key: 'tatemonoId', name: '外観', width: 100, resizable: true, formatter: gaikanFormatter },
    { key: 'madoriName', name: '間取', width: 60, resizable: true, formatter: MadoriFormatter },
    { key: 'tatemonoName', name: '物件名', width: 180, resizable: true, formatter: NameFormatter },
    { key: 'roomName', name: '部屋名', width: 70, resizable: true, formatter: RoomFormatter },
    { key: 'chinRyou', name: '賃料', width: 120, resizable: true, formatter: ChinRyouFormatter },
    { key: 'chinRyouK', name: '契約時', width: 100, resizable: true, formatter: KeiyakuFormatter },

    { key: 'delete', name: '削除', width: 60, resizable: true },
    // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
];

class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            modalautodelete: false,

            tatemonoId: "",
            roomId: "",
        }
        this.onRowClick = this.onRowClick.bind(this);
        // this.mailadd = this.mailadd.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.toggleautodelete = this.toggleautodelete.bind(this);
        this.delete_auto_send = this.delete_auto_send.bind(this);
        this.props.get_auto_sends(this.props.rentResponseId)
    }
    componentWillMount() {


        // this.props.customerList()

    }
    getSelectedCell(event: any) {
        alert()
    }

    onRowClick = (rowIdx: any, row: any) => {

        if (row)
            this.props.toggle(row);




    }

    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.auto_sends_loading === true ? <LoadingComponent /> : <div style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }} > <h3>{message}</h3></div>

        );
    };

    mailadd = (tatemonoId: any, roomId: any) => {

        this.props.add_mail_room({ window: window, id: 131, tatemonoId: tatemonoId, roomId: roomId })
        this.props.togglesearch();
    }
    toggleautodelete = () => {

        this.setState(prevState => ({
            modalautodelete: !prevState.modalautodelete,

        }));

    }
    getCellActions(column: any, row: any) {
        // console.log(row.way)

        // const cellActions = {

        //   coming: comingActions
        // };
        console.log(row)

        if (column.key === "delete") {

            return [{
                icon: <i className="fas fa-times" />,

                callback: () => {
                    this.toggleautodelete()
                    this.setState({
                        tatemonoId: row.tatemonoId,
                        roomId: row.roomId
                    })
                    // this.delete_auto_send()
                    // this.togglemail(row.mailAllReceiveId)
                    // this.toggleMatching(row.id)

                }
            }
            ]



        } else {
            return "";
        }

    }



    delete_auto_send() {
        let insertdata = {
            rentResponseId: this.props.rentResponseId,
            tatemonoId: this.state.tatemonoId,
            status: 4,

            roomId: this.state.roomId,

        };


        this.props.delete_auto_send(insertdata)
        this.toggleautodelete()
    }






    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div >

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    enableCellSelect={false}
                    columns={columns}
                    rowGetter={(i: number) => this.props.auto_sends[i]}
                    rowHeight={90}
                    headerRowHeight={35}
                    rowsCount={this.props.auto_sends.length}
                    getCellActions={this.getCellActions}
                    // headerRenderer={true}
                    dragable={true}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    minHeight={500}
                    emptyRowsView={this.EmptyRowsView}
                    onRowClick={this.props.selectsheet}
                //onCellSelected={this.getSelectedCell}
                />


                <Modal isOpen={this.state.modalautodelete} toggle={this.toggleautodelete} size="sm" fade={false}>

                    <ModalHeader toggle={this.toggleautodelete}>自動送信から除外</ModalHeader>
                    <ModalBody>
                        自動送信の対象物件から除外します。
               </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.delete_auto_send}>除外する</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggleautodelete}>キャンセル</button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        auto_sends: state.autosends.auto_sends,
        auto_sends_loading: state.autosends.auto_sends_loading,
        // responseid: state.customersReducer.responseid,
        // responsedetail: state.customersReducer.responsedetail,
        // introductions_loading:state.responsesReducer.introductions_loading
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_auto_sends(state: any) {
            dispatch(AutoSend.get_auto_sends(state))
        },

        delete_auto_send(state: any) {
            dispatch(AutoSend.delete_auto_send(state))
        },
        // add_mail_room(state:any) {
        //     dispatch(Mail.add_mail_room(state))
        // },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);