import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as CustomerContacts from './Saga/CustomerContacts';
import AutoSendGridComponent from './AutoSendGridComponent';
import MailTemplateComponent from '../MailTemplate/MailTemplateComponent';
import EmailIcon from '@mui/icons-material/Email';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';


import { Modal, Row, Col, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import Button from '@mui/material/Button';

class ContactsComponet extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mode: '2',
            modalselect: false,
            modalscheduler: false,
            modalreserve: false,
            modalend: false,
            modalAutoSendSelect: false,

            add_auto_send_complete:false,
        };

        this.toggleAutoSendSelect = this.toggleAutoSendSelect.bind(this);
        this.add_auto_send = this.add_auto_send.bind(this);
        this.add_auto_send_all = this.add_auto_send_all.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.handleFormSubmit = this.handleFormSubmit.bind(this);
        // this.toggledelete = this.toggledelete.bind(this);
        // this.togglereserve = this.togglereserve.bind(this);
        // this.delete = this.delete.bind(this);
        // this.toggleend = this.toggleend.bind(this);
        // this.handleEndFormSubmit = this.handleEndFormSubmit.bind(this);
        // this.toggleendreset = this.toggleendreset.bind(this);


        this.props.get_auto_send_reserve_list(this.props.responsedetail.id)

    }
    componentWillMount() {



    }
    handleClick(event) {
        if (this.state.mode === 1) {
            this.setState({

                mode: '2',
            });
        } else {
            this.setState({

                mode: '1',
            });
        }
    }
    // handleFormSubmit(event) {

    //     // console.log(event)
    //     // alert()
    //     // event.userId= event.userId.value;

    //     if(event.id>0){
    //         if(event.way==="3"){
    //             event.rentMResponseStatusId=8;
    //         }else if(event.way==="2"){
    //             event.rentMResponseStatusId=2;
    //         }else if(event.way==="1"){
    //             event.rentMResponseStatusId=9;
    //         }
    //         console.log(event)

    //         this.props.update_rent_customer_contacts(event)

    //     }else{
    //         if(event.way==="3"){
    //             event.rentMResponseStatusId=8;
    //         }else if(event.way==="2"){
    //             event.rentMResponseStatusId=2;
    //         }else if(event.way==="1"){
    //             event.rentMResponseStatusId=9;
    //         }
    //     this.props.add_rent_customer_contacts(event)
    // }
    //     this.toggleselect(event)

    //         }

    handleEndFormSubmit(event) {


        this.props.change_rent_responses_end(event);
        this.setState({ modalend: false });
    }


    toggleendreset() {
        this.props.change_rent_responses_end({ rentResponseId: this.props.rent_response_id, endResponseDate: null, endResponseReason: 0, endResponseRemarks: null, endResponseUserId: 0 });
        this.setState({ modalend: false });
    }

    toggleselect(state) {

        state.rentResponseId = state.rentResponseId === 0 ? this.props.responsedetail.id : state.rentResponseId;
        state.sectionId = state.sectionId === 0 ? this.props.responsedetail.sectionId : state.sectionId;
        state.userId = state.userId === 0 ? this.props.user_details.id : state.userId;
        console.log(state)

        this.setState(prevState => ({
            modalselect: !prevState.modalselect,
            contactstate: state
        }));


        // {
        //     id: 0,
        //     userId: this.props.userId,
        //     rentResponseId: this.props.responsedetail.id,
        //     sectionId: this.props.responsedetail.sectionId,
        //     way:3,
        //     returning:0,
        //     going:0,
        //     remarks:"",
        //   date:new Date(),
        //   intend:0,
        //   intend_time:0,
        //   intend_time2:0
        // });/










    }
    togglescheduler = () => {

        this.setState(prevState => ({
            modalscheduler: !prevState.modalscheduler,

        }));
    }

    togglereserve = () => {

        this.setState(prevState => ({
            modalreserve: !prevState.modalreserve,

        }));
    }


    toggledelete() {


        this.setState(prevState => ({
            modaldelete: !prevState.modaldelete,

        }));
    }

    toggleend() {

        this.setState(prevState => ({
            modalend: !prevState.modalend,

        }));

    }
    toggleAutoSendSelect() {

        this.setState(prevState => ({
            modalAutoSendSelect: !prevState.modalAutoSendSelect,

        }));

    }


    delete() {
        this.props.delete_rent_customer_contacts(this.state.contactstate.id)

        this.setState({
            modaldelete: false,
            modalselect: false,
        });
    }

    handleTemplateClose(){
        this.props.add_auto_send_modal(false)
        this.setState({
            refresh: !this.state.refresh,
        });
    }

    add_auto_send(){
        this.setState({
            add_auto_send_complete: true,
        });
        
        this.props.add_auto_send({mail_template_id: this.props.mail_template_details.id, rent_response_id: this.props.responsedetail.id})

        setTimeout(() => {
            this.setState({
                add_auto_send_complete: false,
            });
        }, 1000);
    }

    add_auto_send_all(){
        // this.setState({
        //     add_auto_send_complete: true,
        // });
        
        this.props.insert_auto_sends_templates_all({mail_template_category_id: this.props.mail_template_form.auto_send_mail_template_category_id, rent_response_id: this.props.responsedetail.id, section_id: this.props.mail_template_form.section_id})
        // this.props.add_auto_send({mail_template_id: this.props.mail_template_details.id, rent_response_id: this.props.responsedetail.id})

    }

    render() {
        console.log(this.props.customer_contacts)

        return (
            <div>

                <Row>
                    <Col lg="12">

                    </Col>
                    <Col className="item-outer">
                        {<AutoSendGridComponent rentResponseId={this.props.responsedetail.id} toggle={this.props.toggle} />}
                    </Col>
                </Row>



{/* 
                <Modal isOpen={this.props.auto_send_modal} toggle={() => this.props.add_auto_send_modal(false)} size="sm" fade={false}>

                    <ModalHeader toggle={() => this.props.add_auto_send_modal(false)}>自動追客追加</ModalHeader>
                    <ModalBody>
                        {<AutoSendSelectGridComponent rentResponseId={this.props.responsedetail.id} />}
                    </ModalBody>
                    <ModalFooter>


                        <Button color="secondary" onClick={() => this.props.add_auto_send_modal(false)}>閉じる</Button>
                    </ModalFooter>
                </Modal> */}


                <Modal isOpen={this.props.auto_send_modal} toggle={() => this.props.add_auto_send_modal(false)} size="xxl" fade={false}>
                    <ModalHeader toggle={() => this.props.add_auto_send_modal(false)}>
                    メールテンプレート
                    </ModalHeader>
                    <MailTemplateComponent handleTemplateClose={this.handleTemplateClose} auto_send={1} />
                    <ModalFooter>
                    <div style={{position:"absolute", left:"10px", bottom:"10px"}}>
                    </div>
                        <Button startIcon={<DynamicFeedIcon />} variant="contained" color="primary" disabled={this.props.mail_template_form.auto_send_mail_template_category_id > 0 ? false : true} onClick={this.add_auto_send_all} style={{float:"right",marginRight:20}} >自動送信一括追加</Button>

                        {/* {this.state.add_auto_send_complete ? <Alert severity="success" color="warning" style={{position:"absolute", zIndex:2000}}>
                            追加しました
                        </Alert>:""} */}
                        
                        <Button startIcon={<EmailIcon />} variant="contained" color="primary" disabled={this.props.mail_template_details.id > 0 ? false : true} onClick={this.add_auto_send} style={{marginRight:5}} >自動送信追加</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.add_auto_send_complete}>
                <ModalBody>
                    追加しました
                </ModalBody>
                </Modal>


                <Modal isOpen={this.props.auto_mail_template_details_disp} toggle={()=>this.props.change_auto_mail_template_details_disp(false)} size="lg" fade={false}>

                    <ModalHeader toggle={()=>this.props.change_auto_mail_template_details_disp(false)}>{this.props.auto_mail_template_details.template_name}</ModalHeader>
                    <ModalBody>
                        <div style={{ padding: 15, overflow: "auto", height: 650 }} dangerouslySetInnerHTML={{ __html: this.props.auto_mail_template_details.body_all }} />

                    </ModalBody>

                </Modal>



            </div>
        );
    }
}

// function renderForecastsTable(handleClick, handleFormSubmit, state) {

//     if (state.mode===1) {
//         return (
//             <button className="btn btn-success float-right" onClick={handleClick}>編集</button>
//         );
//     } else {
//         //return (
//         //    <div>
//         //        <button className="btn btn-default float-right" onClick={handleClick}>閉じる</button>

//         //    </div>
//         //);
//     }
// }

// export default connect(
// )(ContactsComponet);

const mapStateToProps = state => {
    return {
        // users: state.masterReducer.users,
        // users_select: state.masterReducer.users_select,
        // section_id: state.masterReducer.section_id,
        // user_details: state.masterReducer.user_details,
        auto_send_modal: state.customerContactsReducer.auto_send_modal,
        responsedetail: state.responsesReducer.responsedetail,
        mail_template_details: state.mailtemplate.mail_template_details,
        mail_template_form: state.mailtemplate.mail_template_form,
        auto_mail_template_details: state.customerContactsReducer.auto_mail_template_details,
        auto_mail_template_details_disp: state.customerContactsReducer.auto_mail_template_details_disp
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_auto_send_reserve_list(state) {
            dispatch(CustomerContacts.get_auto_send_reserve_list(state))
        },

        add_auto_send_modal(state) {
            dispatch(CustomerContacts.add_auto_send_modal(state))
        },
        add_auto_send(state) {
            dispatch(CustomerContacts.add_auto_send(state))
          },
        change_auto_mail_template_details_disp(state) {
            dispatch(CustomerContacts.change_auto_mail_template_details_disp(state))
        },
        insert_auto_sends_templates_all(state) {
            dispatch(CustomerContacts.insert_auto_sends_templates_all(state))
        },

        // add_rent_customer_contacts(state) {
        //    dispatch(CustomerContacts.add_rent_customer_contacts(state))
        // },
        // update_rent_customer_contacts(state) {
        //     dispatch(CustomerContacts.update_rent_customer_contacts(state))
        //  },
        //  delete_rent_customer_contacts(state) {
        //     dispatch(CustomerContacts.delete_rent_customer_contacts(state))
        //  },
        // submit(state) {

        //     dispatch(submit(state))
        //  },
        //  change_rent_responses_end(state){
        //     dispatch(CustomerContacts.change_rent_responses_end(state))
        //  }

    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ContactsComponet);