import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { call, put, takeEvery, select } from 'redux-saga/effects';

import * as RentCustomersApi from '../Api/RentCustomersApi';

import * as rent_m_agents_api from '../Api/rent_m_agents_api';
import * as rent_m_corporates_api from '../Api/rent_m_corporates_api';
import * as rent_corporate_offices_api from '../Api/rent_corporate_offices_api';
import * as rent_responses_api from '../Api/rent_responses_api';
import * as attentions_api from '../Api/attentions_api';
import * as tm_zip_address_api from '../Api/tm_zip_address_api';
import * as RentResponsesApi from '../../ResponseDetail/Api/RentResponsesApi';


export interface CustomersState {
    customer_details: any;
    customer_details_new: any;
    customer_details_old: any;
    customer_detail_logs: any;
    customerdetail_search: any;
    customerdetail: any;

    customerloding_search: number;
    customerloding: number;
    customersearchdisp: number;

    customeredit: number;

    customerlist: any;
    customer_list_loading: number;
    rent_customer_editor_forms: any;
    rent_m_agents: any,
    coprporate_add_forms: any,
    rent_m_corporates: any,
    rent_m_corporates_loading: boolean,
    rent_m_corporate_editor: boolean,

    rent_m_corporate_search_modal: boolean,
    rent_m_corporate_search: any,
    rent_m_corporate_search_list: any,
    rent_m_corporate_office_search_list: any,

    customer_copy_modal: number,

    rent_responses_duplicate_modal: boolean,
    rent_responses_duplicate_list: any,
    rent_responses_duplicate_loading: boolean,
    attentions_matching_list: any,
    attentions_matching_list_loading: boolean,
}


const initialState: CustomersState = {
    customer_details: [],
    customer_details_new: [],
    customer_details_old: [],
    customer_detail_logs: [],
    customerdetail_search: [],
    customerdetail: [],

    customerloding_search: 2,//0がローディング1がロード済み
    customerloding: 0,//0がローディング1がロード済み
    customersearchdisp: 0,//customersearch結果を表示

    customeredit: 0,//0が表示が１編集

    customerlist: [],
    customer_list_loading: 0,//1　リストロード中
    rent_customer_editor_forms: {
        rent_response_id: 0,
        contract_form: 0,
        rent_m_corporate_id: 0,
        rent_corporate_office_id: 0,
        rent_corporate_office_name: "",
        memo: "",
        name1: "",
        kana1: "",
        name2: "",
        sex: 1,
        birthday: null,
        tel1: "",
        tel1_remarks: "",
        tel2: "",
        tel2_remarks: "",
        fax: "",
        fax_remarks: "",
        mail1: "",
        mail1_remarks: "",
        mail2: "",
        mail2_remarks: "",
        post: "",
        add1: "",
        add2: "",
        office_rent_m_corporate_id: 0,
        office_rent_corporate_office_id: 0,
        office_rent_corporate_office_name: "",
        remarks: "",
        rent_m_agent_id: 0,
        agent_etc: "",
    },
    coprporate_add_forms: {
        name: '',
        kana: '',
    },
    rent_m_agents: [],
    rent_m_corporate_editor: false,
    rent_m_corporates: [],
    rent_m_corporates_loading: false,

    rent_m_corporate_search_modal: false,
    rent_m_corporate_search: {name:""},
    rent_m_corporate_search_list: [],
    rent_m_corporate_office_search_list: [],

    customer_copy_modal:0,

    rent_responses_duplicate_modal: false,
    rent_responses_duplicate_list: [],
    rent_responses_duplicate_loading: false,
    attentions_matching_list: [],
    attentions_matching_list_loading: false,
}


///action 
const actionCreator = actionCreatorFactory();

export const get_customer_details = actionCreator<any>("GET_CUSTOMER_DETAILS");
export const change_customer_details = actionCreator<any>("CHANGE_CUSTOMER_DETAILS");

export const change_customer_details_new = actionCreator<any>("CHANGE_CUSTOMER_DETAILS_NEW");
export const change_customer_details_old = actionCreator<any>("CHANGE_CUSTOMER_DETAILS_OLD");
export const change_customer_detail_logs = actionCreator<any>("CHANGE_CUSTOMER_DETAIL_LOGS");


export const change_customeredit = actionCreator<number>('CUSTOMERE_EDIT');
// export const change_customeredit1 = actionCreator<number>('CUSTOMERE_EDIT1');
// export const change_customeredit2 = actionCreator<number>('CUSTOMERE_EDIT2');
export const change_customerloding_search = actionCreator<number>('CHANGE_CUSTOMERE_LODING_SEARCH');
export const change_customersearchdisp = actionCreator<number>('CHANGE_CUSTOMERE_SEARCH_DISP');



export const get_rent_m_corporates = actionCreator<any>('GET_RENT_M_CORPORATES');
export const change_rent_m_corporates = actionCreator<any>('CHANGE_RENT_M_CORPORATES');


export const change_customerloding = actionCreator<number>('CUSTOMERE_LODING');
// export const change_customerloding1 = actionCreator<number>('CUSTOMERE_LODING1');
// export const change_customerloding2 = actionCreator<number>('CUSTOMERE_LODING2');
export const customerNewDispSet = actionCreator<number>('CUSTOMERE_NEW_DISP');
export const customerDetail = actionCreator<any>('CUSTOMER_DETAIL');
export const customerDetail_search_Success = actionCreator<any>('CUSTOMER_DETAIL_SEARCH_SUCCESS');
export const customerDetail_search_Failure = actionCreator<any>('CUSTOMER_DETAIL_SEARCH_FAILURE');

export const customerDetailSuccess = actionCreator<any>('CUSTOMER_DETAIL_SUCCESS');
export const customerDetailFailure = actionCreator<any>('CUSTOMER_DETAIL_FAILURE');
// export const customerDetail1Success = actionCreator<any>('CUSTOMER_DETAIL1_SUCCESS');
// export const customerDetail1Failure = actionCreator<any>('CUSTOMER_DETAIL1_FAILURE');
// export const customerDetail2Success = actionCreator<any>('CUSTOMER_DETAIL2_SUCCESS');
// export const customerDetail2Failure = actionCreator<any>('CUSTOMER_DETAIL2_FAILURE');
export const customerList = actionCreator<any[]>("CUSTOMER_LIST");
export const customerListSuccess = actionCreator("CUSTOMER_LIST_SUCCESS");
export const customerListFailure = actionCreator("CUSTOMER_LIST_FAILURE");
export const change_customer_list_loading = actionCreator<number>("CHANGE_CUSTOMER_LIST_LOADING");
export const change_coprporate_add_forms = actionCreator<any>('CHANGE_CORPORATE_ADD_FORMS');


export const add_rent_m_corporates = actionCreator<any>('ADD_RENT_M_CORPORATES');
export const add_rent_m_corporates_other = actionCreator<any>('ADD_RENT_M_CORPORATES_OTHER');



export const change_rent_m_corporates_loading = actionCreator<any>('CHANGE_RENT_M_CORPORATES_LOADING');



export const update_customerdetail = actionCreator<any>("CUSTOMER_UPDATE");
export const update_customerdetail_success = actionCreator<any>("CUSTOMER_UPDATE_SUCCESS");
export const update_customerdetail_failure = actionCreator("CUSTOMER_UPDATE_FAILURE");
export const customerReset = actionCreator<any>('CUSTOMER_RESET');
export const check_rent_customer_editor_disp = actionCreator<any>('CHECK_RENT_CUSTOMER_EDITOR_DISP');

export const get_rent_m_agents = actionCreator<any>('GET_RENT_M_AGENTS');
export const change_rent_m_agents = actionCreator<any>('CHANGE_RENT_M_AGENTS');
export const change_rent_m_corporate_editor = actionCreator<any>('CHANGE_RENT_M_CORPORATE_EDITOR');

export const select_customer = actionCreator<any>('SELECT_CUSTOMER');
export const get_rent_customer_zip_address = actionCreator<any>('GET_RENT_CUSTOMER_ZIP_ADDRESS');

export const change_rent_customer_editor_forms = actionCreator<any>("CHANGE_RENT_CUSTOMER_EDITOR_FORMS");

export const change_customerdetail = actionCreator<any>("CHANGE_CUSTOMER_DETAIL");

export const new_customerdetail = actionCreator<any>("NEW_CUSTOMERDETAIL");
export const copy_customerdetail = actionCreator<any>("COPY_CUSTOMERDETAIL");

export const change_rent_m_corporate_search_modal = actionCreator<any>("CHANGE_RENT_M_CORPORATE_SEARCH_MODAL");
export const change_rent_m_corporate_search = actionCreator<any>("CHANGE_RENT_M_CORPORATE_SEARCH");
export const change_rent_m_corporate_search_list = actionCreator<any>("CHANGE_RENT_M_CORPORATE_SEARCH_LIST");
export const get_rent_m_corporate_search_list = actionCreator<any>("GET_RENT_M_CORPORATE_SEARCH_LIST");
export const change_rent_m_corporate_office_search_list = actionCreator<any>("CHANGE_RENT_M_CORPORATE_OFFICE_SEARCH_LIST");
export const get_rent_m_corporate_office_search_list = actionCreator<any>("GET_RENT_M_CORPORATE_OFFICE_SEARCH_LIST");

export const change_customer_copy_modal = actionCreator<any>("CHANGE_CUSTOMER_COPY_MODAL");

export const change_rent_responses_duplicate_modal = actionCreator<any>("CHANGE_RENT_RESPONSES_DUPLICATE_MODAL");
export const get_rent_responses_duplicate_check = actionCreator<any>("GET_RENT_RESPONSES_DUPLICATE_CHECK");
export const change_rent_responses_duplicate_list = actionCreator<any>("CHANGE_RENT_RESPONSES_DUPLICATE_LIST");

export const get_attentions_matching_list = actionCreator<any>("GET_ATTENTIONS_MATCHING_LIST");
export const change_attentions_matching_list = actionCreator<any>("CHANGE_ATTENTIONS_MATCHING_LIST");

export const change_rent_responses_duplicate_loading = actionCreator<any>("CHANGE_RENT_RESPONSES_DUPLICATE_LOADING");
export const change_attentions_matching_list_loading = actionCreator<any>("CHANGE_ATTENTIONS_MATCHING_LIST_LOADING");



//reducers
export const customersReducer = reducerWithInitialState(initialState)
    .case(customerDetail_search_Success, (state, payload) => ({ ...state, customerdetail_search: payload }))

    .case(customerDetailSuccess, (state, payload) => ({ ...state, customerdetail: payload }))


    .case(change_customerdetail, (state, payload) => ({ ...state, customerdetail: payload }))
    // .case(customerDetail1Success, (state, payload) => ({ ...state, customerdetail1: payload}))
    // .case(customerDetail2Success, (state, payload) => ({ ...state, customerdetail2: payload }))

    .case(change_customerloding_search, (state, payload) => ({ ...state, customerloding_search: payload }))
    .case(change_customerloding, (state, payload) => ({ ...state, customerloding: payload }))

    .case(change_rent_m_corporates, (state, payload) => ({ ...state, rent_m_corporates: payload }))
    .case(change_rent_m_corporates_loading, (state, payload) => ({ ...state, rent_m_corporates_loading: payload }))
    // .case(change_customerloding1, (state, payload) => ({ ...state, customerloding1: payload }))
    // .case(change_customerloding2, (state, payload) => ({ ...state, customerloding2: payload }))
    // .case(customerList, (state, payload) => ({ ...state, customerlist: payload }))
    .case(customerListSuccess, (state, payload) => ({ ...state, customerlist: payload }))
    .case(customerListFailure, (state, payload) => ({ ...state, customerlist: [] }))
    .case(change_customeredit, (state, payload) => ({ ...state, customeredit: payload }))
    .case(change_rent_customer_editor_forms, (state, payload) => ({ ...state, rent_customer_editor_forms: payload }))
    // .case(change_customeredit1, (state, payload) => ({ ...state, customeredit1: payload }))
    // .case(change_customeredit2, (state, payload) => ({ ...state, customeredit2: payload }))
    .case(customerNewDispSet, (state, payload) => ({ ...state, customerNewDisp: payload }))
    .case(change_customersearchdisp, (state, payload) => ({ ...state, customersearchdisp: payload }))
    .case(change_rent_m_agents, (state, payload) => ({ ...state, rent_m_agents: payload }))
    .case(change_rent_m_corporate_editor, (state, payload) => ({ ...state, rent_m_corporate_editor: payload }))
    .case(change_customer_list_loading, (state, payload) => ({ ...state, customer_list_loading: payload }))

    .case(change_customer_details, (state, payload) => ({ ...state, customer_details: payload }))

    .case(change_customer_details_new, (state, payload) => ({ ...state, customer_details_new: payload }))
    .case(change_customer_details_old, (state, payload) => ({ ...state, customer_details_old: payload }))
    .case(change_customer_detail_logs, (state, payload) => ({ ...state, customer_detail_logs: payload }))

    .case(change_rent_m_corporate_search_modal, (state, payload) => ({ ...state, rent_m_corporate_search_modal: payload }))
    .case(change_rent_m_corporate_search, (state, payload) => ({ ...state, rent_m_corporate_search: payload }))
    .case(change_rent_m_corporate_search_list, (state, payload) => ({ ...state, rent_m_corporate_search_list: payload }))
    .case(change_rent_m_corporate_office_search_list, (state, payload) => ({ ...state, rent_m_corporate_office_search_list: payload }))

    .case(change_customer_copy_modal, (state, payload) => ({ ...state, customer_copy_modal: payload }))

    .case(change_rent_responses_duplicate_modal, (state, payload) => ({ ...state, rent_responses_duplicate_modal: payload }))
    .case(change_rent_responses_duplicate_list, (state, payload) => ({ ...state, rent_responses_duplicate_list: payload }))
    .case(change_attentions_matching_list, (state, payload) => ({ ...state, attentions_matching_list: payload }))
    .case(change_rent_responses_duplicate_loading, (state, payload) => ({ ...state, rent_responses_duplicate_loading: payload }))
    .case(change_attentions_matching_list_loading, (state, payload) => ({ ...state, attentions_matching_list_loading: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const customersSaga = [takeEvery('CUSTOMER_UPDATE', handleRentCustomersUpdateRequest),
takeEvery('CUSTOMER_RESET', handlecustomerResetRequest),
takeEvery('CUSTOMER_LIST', handleRentCustomersListRequest),
takeEvery('CUSTOMER_DETAIL', handleRentCustomersDetailRequest),
takeEvery('CHECK_RENT_CUSTOMER_EDITOR_DISP', handle_check_rent_customer_editor_disp),
takeEvery('GET_RENT_M_AGENTS', handle_get_rent_m_agents),
takeEvery('GET_RENT_M_CORPORATES', handle_get_rent_m_corporates),
takeEvery('GET_RENT_M_CORPORATE_SEARCH_LIST', handle_get_rent_m_corporate_search_list),
takeEvery('GET_RENT_M_CORPORATE_OFFICE_SEARCH_LIST', handle_get_rent_m_corporate_office_search_list),
takeEvery('ADD_RENT_M_CORPORATES', handle_add_rent_m_corporates),
takeEvery('ADD_RENT_M_CORPORATES_OTHER', handle_add_rent_m_corporates_other),

takeEvery('SELECT_CUSTOMER', handle_select_customer),
takeEvery('GET_RENT_CUSTOMER_ZIP_ADDRESS', handle_get_rent_customer_zip_address),
takeEvery('NEW_CUSTOMERDETAIL', handle_new_customerdetail),
takeEvery('COPY_CUSTOMERDETAIL', handle_copy_customerdetail),

takeEvery('GET_CUSTOMER_DETAILS', handle_get_customer_details),
takeEvery('GET_RENT_RESPONSES_DUPLICATE_CHECK', handle_get_rent_responses_duplicate_check),
takeEvery('GET_ATTENTIONS_MATCHING_LIST', handle_get_attentions_matching_list),

];


function* handle_get_customer_details(values: any): any {

    // alert("get")
    const state = yield select();

    yield put(change_customer_details({}));
    yield put(change_customer_details_new({}));
    yield put(change_customer_details_old({}));
    yield put(change_customer_detail_logs([]));
    yield put(check_rent_customer_editor_disp({}));


    // yield put(change_customeredit(0));
    // let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms
    //let { payload } = action;
    // alert(values.payload.rent_response_id)
    if (values.payload.rent_response_id) {
        const { payload, error } = yield call(RentCustomersApi.get_details_by_rent_response_id, values.payload.rent_response_id);
        if (payload && !error) {

            yield put(change_customer_details(payload.current));
            yield put(change_customer_details_new(payload.logs.filter((a: any) => a.workflow_count === payload.current.workflow_count)[0] ? payload.logs.filter((a: any) => a.workflow_count === payload.current.workflow_count)[0] : payload.current));
            yield put(change_customer_details_old(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] ? payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] : payload.logs.filter((a: any) => a.workflow_count === 0)[0]));
            yield put(change_customer_detail_logs(payload.logs));
            yield put(check_rent_customer_editor_disp(payload.current));

        } else {


        }

    } else if (values.payload.rent_contract_id) {

        const { payload, error } = yield call(RentCustomersApi.get_details_by_rent_contract_id, values.payload.rent_contract_id);
        if (payload && !error) {

            yield put(change_customer_details(payload.current));
            yield put(change_customer_detail_logs(payload.logs));
            yield put(change_customer_details_new(payload.logs.filter((a: any) => a.workflow_count === payload.current.workflow_count)[0]));
            yield put(change_customer_details_old(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] ? payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] : payload.logs.filter((a: any) => a.workflow_count === 0)[0]));

            yield put(check_rent_customer_editor_disp(payload.current));

        } else {


        }

    } else {





    }


}




function* handleRentCustomersUpdateRequest(values: any): any {


    const state = yield select();

    let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms
    //let { payload } = action;
    const { payload, error } = yield call(RentCustomersApi.update, rent_customer_editor_forms);


    if (payload && !error) {





        if (rent_customer_editor_forms.customerno === -1) {
            yield put(customerDetail_search_Success(payload.current));
        }
        // }else if(values.payload.customerno===0){
        // yield put(customerDetailSuccess(payload));


        yield put(change_customer_details(payload.current));
        yield put(change_customer_details_new(payload.logs.filter((a: any) => a.workflow_count === payload.current.workflow_count).length > 0 ? payload.logs.filter((a: any) => a.workflow_count === payload.current.workflow_count)[0] : payload.current));
        yield put(change_customer_details_old(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] ? payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] : payload.logs.filter((a: any) => a.workflow_count === 0)[0]));
        yield put(change_customer_detail_logs(payload.logs));
        yield put(check_rent_customer_editor_disp(payload.current));
        yield put(change_customeredit(0));

        // }else if(values.payload.customerno===1){
        //     yield put(customerDetail1Success(values.payload));
        //     yield put(change_customeredit1(0));
        // }else if(values.payload.customerno===2){
        //     yield put(customerDetail2Success(values.payload));
        //     yield put(change_customeredit2(0));
        // }

    } else {
        yield put(update_customerdetail_failure());

    }
}

// サーバーからのレスポンスデータによる分岐処理
//if (ログイン成功か失敗かの条件) {
// 成功時アクション呼び出し





function* handle_get_rent_m_corporates(action: any): any {
    yield put(change_rent_m_corporates_loading(true));

    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(rent_m_corporates_api.list, { name: action.payload });


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)


        yield put(change_rent_m_corporates(payload));

        yield put(change_rent_m_corporates_loading(false));



    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}


function* handle_get_rent_m_corporate_search_list(action: any): any {
    yield put(change_rent_m_corporates_loading(true));

    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(rent_m_corporates_api.search_list, state.customersReducer.rent_m_corporate_search);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)


        // let rent_m_corporate_search = state.customersReducer.rent_m_corporate_search
        // rent_m_corporate_search.rent_m_corporate_id = 0
        // rent_m_corporate_search.rent_m_corporate_kana = ""
        // rent_m_corporate_search.rent_m_corporate_name = ""
        // rent_m_corporate_search.rent_corporate_office_id = 0
        // rent_m_corporate_search.rent_corporate_office_kana = ""
        // rent_m_corporate_search.rent_corporate_office_name = ""
        // yield put(change_rent_m_corporate_search(rent_m_corporate_search));

        yield put(change_rent_m_corporate_office_search_list([]));

        yield put(change_rent_m_corporate_search_list(payload));

        yield put(change_rent_m_corporates_loading(false));



    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}


function* handle_get_rent_m_corporate_office_search_list(action: any): any {
    yield put(change_rent_m_corporates_loading(true));

    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(rent_corporate_offices_api.search_list, state.customersReducer.rent_m_corporate_search);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)


        yield put(change_rent_m_corporate_office_search_list(payload));

        yield put(change_rent_m_corporates_loading(false));



    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}






// function* handle_add_rent_m_corporates(action: any): any {

    // const state = yield select();　　// store.getState()と同じ
    // // 成功時アクション呼び出し

    // let coprporate_add_forms = state.customersReducer.coprporate_add_forms
    // // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    // const { payload, error } = yield call(rent_m_corporates_api.insert, coprporate_add_forms);


    // // サーバーからのレスポンスデータによる分岐処理
    // //if (ログイン成功か失敗かの条件) {
    // // 成功時アクション呼び出し

    // if (payload && !error) {
    //     console.log(payload)
    //     var rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms
    //     if (state.customersReducer.rent_m_corporate_editor === 1) {

    //         rent_customer_editor_forms.rent_m_corporate_id = payload.id
    //         rent_customer_editor_forms.name1 = payload.name
    //         rent_customer_editor_forms.kana1 = payload.kana
    //         rent_customer_editor_forms.office_rent_m_corporate_id = payload.id
    //         rent_customer_editor_forms.office_name = payload.name
    //     } else if (state.customersReducer.rent_m_corporate_editor === 2) {

    //         rent_customer_editor_forms.office_rent_m_corporate_id = payload.id
    //         rent_customer_editor_forms.office_name = payload.name
    //     }
    //     yield put(change_rent_customer_editor_forms(rent_customer_editor_forms));



    //     coprporate_add_forms.name = ''
    //     coprporate_add_forms.kana = ''
    //     yield put(change_coprporate_add_forms(coprporate_add_forms));
    //     yield put(change_rent_m_corporate_editor(0));





    // } else {
    //     // 失敗時アクション呼び出し
    //     // yield put(loginFailure(payload));
    // }


// }

function* handle_add_rent_m_corporates(action: any): any {

    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let coprporate_add_forms = state.customersReducer.coprporate_add_forms
    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(rent_m_corporates_api.insert, coprporate_add_forms);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        let rent_m_corporate_search = state.customersReducer.rent_m_corporate_search
        rent_m_corporate_search.rent_m_corporate_id = 0
        rent_m_corporate_search.rent_m_corporate_kana = ""
        rent_m_corporate_search.rent_m_corporate_name = ""
        rent_m_corporate_search.rent_corporate_office_id = 0
        rent_m_corporate_search.rent_corporate_office_kana = ""
        rent_m_corporate_search.rent_corporate_office_name = ""
        yield put(change_rent_m_corporate_search(rent_m_corporate_search));

        yield put(get_rent_m_corporate_search_list([]));
        
        yield put(change_rent_m_corporate_editor(0));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}

function* handle_add_rent_m_corporates_other(action: any): any {

    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let coprporate_add_forms = state.customersReducer.coprporate_add_forms


    var rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms
    if (state.customersReducer.rent_m_corporate_editor === 1) {// 法人名

        rent_customer_editor_forms.rent_m_corporate_id = 13688// その他
        rent_customer_editor_forms.name1 = coprporate_add_forms.name
        rent_customer_editor_forms.kana1 = coprporate_add_forms.kana
        rent_customer_editor_forms.office_rent_m_corporate_id = 13688// その他
        rent_customer_editor_forms.office_name = coprporate_add_forms.name

    } else if (state.customersReducer.rent_m_corporate_editor === 2) {// 勤務先

        rent_customer_editor_forms.office_rent_m_corporate_id = 13688// その他
        rent_customer_editor_forms.office_name = coprporate_add_forms.name
    }
    yield put(change_rent_customer_editor_forms(rent_customer_editor_forms));



    coprporate_add_forms.name = ''
    coprporate_add_forms.kana = ''
    yield put(change_coprporate_add_forms(coprporate_add_forms));
    yield put(change_rent_m_corporate_editor(0));


}



function* handleRentCustomersListRequest(action: any) {

    yield put(change_customer_list_loading(1));
    // const reset: any = [];
    // yield put(customerListSuccess(reset));
    const { payload, error } = yield call(RentCustomersApi.list, action);

    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
    console.log(payload)

    if (payload && !error) {
        yield put(customerListSuccess(payload));
        yield put(change_customer_list_loading(0));
    } else {

        yield put(customerListFailure());
    }
    //} else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
    //}
}

function* handleRentCustomersDetailRequest(action: any) {
    // alert("start")
    if (action.payload.no === -1) {
        yield put(change_customerloding_search(0))
    } else {
        yield put(change_customerloding(0))
    }
    // }else if (action.payload.no === 0) {

    // } else if (action.payload.no === 1) {
    //     yield put(change_customerloding1(0))
    // } else if (action.payload.no === 2) {
    //     yield put(change_customerloding2(0))
    // }


    const { payload, error } = yield call(RentCustomersApi.detail, action.payload.customerid);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // alert("end")
        // 成功時アクション呼び出し
        if (action.payload.no === -1) {

            if (payload.birthday !== null) {
                var now = new Date(payload.birthday);
                payload.birthdayyear = now.getFullYear(); //年
                payload.birthdaymonth = now.getMonth() + 1;    //月
                payload.birthdayday = now.getDate();


            }


            yield put(customerDetail_search_Success(payload))
            yield put(change_customerloding_search(1))

            yield put(change_customersearchdisp(1))
        } else {
            if (payload.birthday !== null) {
                var now = new Date(payload.birthday);
                payload.birthdayyear = now.getFullYear(); //年
                payload.birthdaymonth = now.getMonth() + 1;    //月
                payload.birthdayday = now.getDate();

            }

            yield put(customerDetailSuccess(payload))
            yield put(change_customerloding(1))

        }

        //     else if (action.payload.no === 1) {
        //     yield put(customerDetail1Success(payload))
        //     yield put(change_customerloding1(1))
        //     } else if (action.payload.no === 2) {
        //     yield put(customerDetail2Success(payload))
        //     yield put(change_customerloding2(1))
        // }

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
        // if (action.payload.no === 0) {
        yield put(customerDetailFailure(payload))
        yield put(change_customerloding(1))
        // } else if (action.payload.no === 1) {
        //     yield put(customerDetail1Failure(payload))
        //     yield put(change_customerloding1(1))
        // } else if (action.payload.no === 2) {
        //     yield put(customerDetail2Failure(payload))
        //     yield put(change_customerloding2(1))
        // }
    }
    // }
}

function* handlecustomerResetRequest(values: any) {

    const customerdetail = {
        id: -1,
        name1: "",
        kana1: "",
        name2: "",
        kana2: "",
        type: 0,
        sex: 1,
        no: values.no
    }

    yield put(customerDetail(customerdetail))
    // yield put(change_customeredit(0))


}





function* handle_check_rent_customer_editor_disp(action: any): any {
    yield put(change_rent_customer_editor_forms({}));
    const state = yield select();

    const responsedetail = state.responsesReducer.responsedetail
    const customer_details = state.customersReducer.customer_details
    let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms

    // rent_customer_editor_forms.rent_contract_id = customerdetail.id
    rent_customer_editor_forms.rent_response_id = responsedetail.id
    rent_customer_editor_forms.rent_customer_id = customer_details.id

    rent_customer_editor_forms.contract_form = customer_details.type

    rent_customer_editor_forms.name1 = customer_details.name1
    rent_customer_editor_forms.kana1 = customer_details.kana1
    rent_customer_editor_forms.name2 = customer_details.name2
    rent_customer_editor_forms.kana2 = customer_details.kana2


    rent_customer_editor_forms.rent_m_country_id = customer_details.rent_m_country_id
    rent_customer_editor_forms.rent_m_country_remarks = customer_details.rent_m_country_remarks


    rent_customer_editor_forms.sex = customer_details.sex
    rent_customer_editor_forms.memo = customer_details.memo
    rent_customer_editor_forms.tel1 = customer_details.tel1
    rent_customer_editor_forms.tel1_remarks = customer_details.tel1_remarks
    rent_customer_editor_forms.tel2 = customer_details.tel2
    rent_customer_editor_forms.tel2_remarks = customer_details.tel2_remarks
    rent_customer_editor_forms.fax = customer_details.fax

    rent_customer_editor_forms.fax_remarks = customer_details.fax_remarks

    rent_customer_editor_forms.mail1 = customer_details.mail1
    rent_customer_editor_forms.mail1_remarks = customer_details.mail1_remarks
    rent_customer_editor_forms.mail2 = customer_details.mail2
    rent_customer_editor_forms.mail2_remarks = customer_details.mail2_remarks
    rent_customer_editor_forms.rent_m_corporate_id = customer_details.rent_m_corporate_id
    rent_customer_editor_forms.rent_m_corporate_name = customer_details.rent_m_corporate_name
    rent_customer_editor_forms.rent_corporate_office_id = customer_details.rent_corporate_office_id
    rent_customer_editor_forms.rent_corporate_office_name = customer_details.rent_corporate_office_name
    rent_customer_editor_forms.office_rent_m_corporate_id = customer_details.office_rent_m_corporate_id
    rent_customer_editor_forms.office_rent_corporate_office_id = customer_details.office_rent_corporate_office_id
    rent_customer_editor_forms.office_rent_corporate_office_name = customer_details.office_rent_corporate_office_name
    rent_customer_editor_forms.office_name = customer_details.office_name
    rent_customer_editor_forms.post = customer_details.post
    rent_customer_editor_forms.add1 = customer_details.add1
    rent_customer_editor_forms.add2 = customer_details.add2
    rent_customer_editor_forms.birthday = customer_details.birthday ? customer_details.birthday : null
    rent_customer_editor_forms.remarks = customer_details.remarks

    rent_customer_editor_forms.rent_m_agent_id = customer_details.rent_m_agent_id
    rent_customer_editor_forms.agent_etc = customer_details.agent_etc
    rent_customer_editor_forms.executive = customer_details.executive
    rent_customer_editor_forms.attentions_check = customer_details.attentions_check




    yield put(change_rent_customer_editor_forms(rent_customer_editor_forms));


}



function* handle_new_customerdetail(action: any): any {

    const state = yield select();


    let customerdetail = state.customersReducer.customerdetail
    let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms

    // rent_customer_editor_forms.rent_contract_id = customerdetail.id

    customerdetail.id = rent_customer_editor_forms.rent_customer_id

    customerdetail.type = rent_customer_editor_forms.contract_form

    customerdetail.name1 = rent_customer_editor_forms.name1
    customerdetail.kana1 = rent_customer_editor_forms.kana1
    customerdetail.name2 = rent_customer_editor_forms.name2
    customerdetail.kana2 = rent_customer_editor_forms.kana2
    customerdetail.sex = rent_customer_editor_forms.sex
    customerdetail.memo = rent_customer_editor_forms.memo
    customerdetail.tel1 = rent_customer_editor_forms.tel1
    customerdetail.tel1_remarks = rent_customer_editor_forms.tel1_remarks
    customerdetail.tel2 = rent_customer_editor_forms.tel2
    customerdetail.tel2_remarks = rent_customer_editor_forms.tel2_remarks
    customerdetail.fax = rent_customer_editor_forms.fax
    customerdetail.fax_remarks = rent_customer_editor_forms.fax_remarks
    customerdetail.mail1 = rent_customer_editor_forms.mail1
    customerdetail.mail1_remarks = rent_customer_editor_forms.mail1_remarks
    customerdetail.mail2 = rent_customer_editor_forms.mail2
    customerdetail.mail2_remarks = rent_customer_editor_forms.mail2_remarks
    customerdetail.rent_m_corporate_id = rent_customer_editor_forms.rent_m_corporate_id
    customerdetail.rent_corporate_office_id = rent_customer_editor_forms.rent_corporate_office_id
    customerdetail.rent_corporate_office_name = rent_customer_editor_forms.rent_corporate_office_name

    customerdetail.office_rent_m_corporate_id = rent_customer_editor_forms.office_rent_m_corporate_id
    customerdetail.office_rent_corporate_office_id = rent_customer_editor_forms.office_rent_corporate_office_id
    customerdetail.office_rent_corporate_office_name = rent_customer_editor_forms.office_rent_corporate_office_name
    customerdetail.office_name = rent_customer_editor_forms.office_name
    customerdetail.post = rent_customer_editor_forms.post
    customerdetail.add1 = rent_customer_editor_forms.add1
    customerdetail.add2 = rent_customer_editor_forms.add2
    customerdetail.birthday = rent_customer_editor_forms.birthday ? rent_customer_editor_forms.birthday : null
    customerdetail.remarks = rent_customer_editor_forms.remarks

    customerdetail.rent_m_agent_id = rent_customer_editor_forms.rent_m_agent_id
    customerdetail.agent_etc = rent_customer_editor_forms.agent_etc
    customerdetail.executive = rent_customer_editor_forms.executive

    customerdetail.rent_m_country_id = rent_customer_editor_forms.rent_m_country_id
    customerdetail.rent_m_country_remarks = rent_customer_editor_forms.rent_m_country_remarks

    customerdetail.attentions_check = rent_customer_editor_forms.attentions_check


    yield put(change_customer_details(customerdetail));
    yield put(change_customer_details_new(customerdetail));
    yield put(change_customer_details_old(customerdetail));
    yield put(check_rent_customer_editor_disp(customerdetail));
    yield put(change_customeredit(0));

}

function* handle_copy_customerdetail(action: any): any {

    const state = yield select();


    let customerdetail = JSON.parse(JSON.stringify(state.customersReducer.customerdetail))
    // let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms
    // let rent_customer_editor_forms = state.customersReducer.customer_details

    // rent_customer_editor_forms.rent_contract_id = customerdetail.id
    customerdetail.hukusei = 1
    customerdetail.copy_type = action.payload.type//1:マイページもコピーする場合
    customerdetail.id = 0
    customerdetail.contract_form = customerdetail.type

    // // section_id?


    let responsedetail = JSON.parse(JSON.stringify(state.responsesReducer.responsedetail))
    responsedetail.copy_rent_response_id = responsedetail.id
    responsedetail.id = 0
    responsedetail.rent_hearing_sheet_id = 0
    // contact_response_count = 0
    // gl_response_id
    responsedetail.first_contact_section_id = state.masterReducer.user_details.amSectionId // 初回対応店舗
    responsedetail.user_id = state.masterReducer.user_details.id    //反響担当
    responsedetail.primary_user_id = state.masterReducer.user_details.id//追客担当 使ってない
    responsedetail.coming_user_id = 0//来店担当
    responsedetail.going_user_id = 0//案内担当
    responsedetail.web_user_id = (state.masterReducer.user_details.amSectionId == 10 || state.masterReducer.user_details.amSectionId == 26 || state.masterReducer.user_details.amSectionId == 37 || state.masterReducer.user_details.amSectionId == 55) ? state.masterReducer.user_details.id : null//Web担当 使ってない
    responsedetail.status = 0
    responsedetail.section_id = action.payload.section_id
    

    console.log("customerdetail",customerdetail)
    console.log("rent_responses_editor",responsedetail)


    let rent_responses_editor = state.responsesReducer.rent_responses_editor
    // let response_add_select_mail_id_list = state.responsesReducer.response_add_select_mail_id_list
    // let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms

    // let line_user_id = state.responsesReducer.line_user_id

    const { payload, error } = yield call(RentResponsesApi.add, {
      rent_customer_editor_forms: customerdetail,
      rent_responses_editor: responsedetail,
      response_add_select_mail_id_list: [],
      line_user_id: []
    });
    if (payload && !error) {

      console.log(payload)
      if(payload == 1){
      // すでに登録済みの時
        alert("この反響はすでに登録済みです！")
      }else{
        // alert("複製しました。")
        yield put(change_customer_copy_modal(2)); // 0非表示 1店舗選択 2完了
      }

      
    } else {
      alert(error)
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }










}


function* handle_select_customer(action: any): any {

    const state = yield select();
    yield put(change_rent_customer_editor_forms({}));


    let rent_customer_editor_forms = {
        // rent_customer_id: action.payload.id,

        contract_form: action.payload.type,

        name1: action.payload.name1,
        kana1: action.payload.kana1,
        name2: action.payload.name2,
        kana2: action.payload.kana2,


        sex: action.payload.sex,
        memo: action.payload.memo,
        tel1: action.payload.tel1,
        tel1_remarks: action.payload.tel1_remarks,
        tel2: action.payload.tel2,
        tel2_remarks: action.payload.tel2_remarks,
        fax: action.payload.fax,

        fax_remarks: action.payload.fax_remarks,

        mail1: action.payload.mail1,
        mail1_remarks: action.payload.mail1_remarks,
        mail2: action.payload.mail2,
        mail2_remarks: action.payload.mail2_remarks,
        rent_m_corporate_id: action.payload.rent_m_corporate_id,
        office_rent_m_corporate_id: action.payload.office_rent_m_corporate_id,
        office_name: action.payload.office_name,
        post: action.payload.post,
        add1: action.payload.add1,
        add2: action.payload.add2,
        birthday: action.payload.birthday ? action.payload.birthday : null,
        remarks: action.payload.remarks,

        rent_m_agent_id: action.payload.rent_m_agent_id,
        agent_etc: action.payload.agent_etc,
        executive: action.payload.executive,

    }
    // alert(action.payload.office_name)
    // alert(action.payload.office_rent_m_corporate_id)
    yield put(change_rent_customer_editor_forms(rent_customer_editor_forms));




}


function* handle_get_rent_responses_duplicate_check(action: any): any {

    yield put(change_rent_responses_duplicate_loading(true));

    const state = yield select();

    // let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms

    const { payload, error } = yield call(rent_responses_api.list, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {

        yield put(change_rent_responses_duplicate_list(payload));

        // if(payload.length > 0){
        //     yield put(change_rent_responses_duplicate_modal(true));
        // }else{
        //     yield put(new_customerdetail([]));
        // }



    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }

    yield put(change_rent_responses_duplicate_loading(false));


}

function* handle_get_attentions_matching_list(action: any): any {

    yield put(change_attentions_matching_list_loading(true));

    const state = yield select();

    // let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms

    const { payload, error } = yield call(attentions_api.list, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {

        yield put(change_attentions_matching_list(payload));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }
    yield put(change_attentions_matching_list_loading(false));


}

function* handle_get_rent_m_agents(action: any) {

    // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
    const { payload, error } = yield call(rent_m_agents_api.list, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        // console.log(payload)
        // alert(JSON.stringify(payload))

        yield put(change_rent_m_agents(payload));

    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}




function* handle_get_rent_customer_zip_address(action: any): any {



    const state = yield select();　　// store.getState()と同じ
    // 成功時アクション呼び出し

    let rent_customer_editor_forms = state.customersReducer.rent_customer_editor_forms
    const { payload, error } = yield call(tm_zip_address_api.address, rent_customer_editor_forms.post);


    yield put(change_rent_customer_editor_forms({}));
    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    if (payload && !error) {
        console.log(payload)


        if (payload.todoufuken_name) {
            rent_customer_editor_forms.add1 = payload.todoufuken_name + payload.shikuchouson_name + payload.chouiki_name;
        }

        yield put(change_rent_customer_editor_forms(rent_customer_editor_forms));




    } else {
        // 失敗時アクション呼び出し
        // yield put(loginFailure(payload));
    }


}