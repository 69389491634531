import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { Card, Button, CardContent } from '@mui/material';

import * as CalendarSaga from './Saga/Calendar';

// import './Css/Grid.css'
import { TextField } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';


class SupportHistoryGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            columnWidths: {
                tatemono_id: 80,
                tatemono_name: 250,
                owner_name: 250,
                kanri_corp_name: 250,
              },
        };

        this.handleChangeNotSearchText = this.handleChangeNotSearchText.bind(this);
        this.handleChangeNotSearchTextEnter = this.handleChangeNotSearchTextEnter.bind(this);
        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this.RowClick = this.RowClick.bind(this);

    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
          columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
          },
        }));
    }


    _rowClassNameGetter(index){
        
        if(this.props.tm_owner_search_building_list && this.props.tm_owner_search_building_list[index]) {
            return "normal"
        }else{
          return ""
        }
    }

    handleChangeNotSearchText(state) {

        let tm_owner_search_form = this.props.tm_owner_search_form

        tm_owner_search_form[state.target.name] = state.target.value

        this.props.change_tm_owner_search_form(tm_owner_search_form);

        this.setState({ refresh: !this.state.refresh })
    }

    handleChangeNotSearchTextEnter(state) {

        this.props.get_tm_owner_search_building_list()
        this.setState({ refresh: !this.state.refresh })
    }

    RowClick(event,index){
        // alert(index)
        let eigyou_detail = this.props.eigyou_detail
        eigyou_detail.owner_id = this.props.tm_owner_search_building_list[index].owner_id
        this.props.change_eigyou_detail(eigyou_detail)

        if(eigyou_detail.owner_id > 0){
            this.props.get_eigyou_owner_room(eigyou_detail.owner_id)
        }
        
        this.props.handleOwnerSearchClose()

        this.setState({ refresh: !this.state.refresh })
    }
  
    // Render your table
    render() {

        return(
            <Card>
                <CardContent>
                {/* <CardHeader title="セミナー参加履歴" /> */}

                <TextField
                    style={{width:150, marginRight:10}}
                    onChange={this.handleChangeNotSearchText}
                    value={this.props.tm_owner_search_form.building_id}
                    margin="dense"
                    label="建物ID"
                    name="building_id"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            // エンターキー押下時の処理
                            this.handleChangeNotSearchTextEnter(e)
                        }
                    }}
                />

                <TextField
                    style={{width:200}}
                    onChange={this.handleChangeNotSearchText}
                    value={this.props.tm_owner_search_form.building_name}
                    margin="dense"
                    label="建物名"
                    name="building_name"
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            // エンターキー押下時の処理
                            this.handleChangeNotSearchTextEnter(e)
                        }
                    }}
                />

                <Button size="small" variant="contained" color="secondary" style={{ color: "white", backgroundColor: "#5cb85c", marginLeft: 10, marginTop:10 }} onClick={()=>this.props.get_tm_owner_search_building_list()} startIcon={<SearchIcon />}>検索</Button>

                <Table
                    className="gridOwnerDetailNormalList"
                    rowHeight={34}
                    rowsCount={this.props.tm_owner_search_building_list.length} 
                    width={650}
                    // height={150}
                    height={300}
                    headerHeight={34}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    rowClassNameGetter={this._rowClassNameGetter}
                    onRowClick={this.RowClick}
                >
                    <Column width={this.state.columnWidths.tatemono_id} isResizable={true} columnKey="tatemono_id" header={<Cell>ID</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                        {this.props.tm_owner_search_building_list[rowIndex].tatemono_id}
                    </Cell>)} />
                    <Column width={this.state.columnWidths.tatemono_name} isResizable={true} columnKey="tatemono_name" header={<Cell>物件名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                        {this.props.tm_owner_search_building_list[rowIndex].tatemono_name}
                    </Cell>)} />
                    <Column width={this.state.columnWidths.owner_name} isResizable={true} columnKey="owner_name" header={<Cell>オーナー名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                        {this.props.tm_owner_search_building_list[rowIndex].owner_name}
                    </Cell>)} />
                    <Column width={this.state.columnWidths.kanri_corp_name} isResizable={true} columnKey="kanri_corp_name" header={<Cell>管理会社</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                        {this.props.tm_owner_search_building_list[rowIndex].kanri_corp_name}
                    </Cell>)} />

                </Table>
                </CardContent>
            </Card>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        tm_owner_search_building_list: state.calendar.tm_owner_search_building_list,
        tm_owner_search_form: state.calendar.tm_owner_search_form,
        eigyou_detail: state.calendar.eigyou_detail,
        users: state.masterReducer.users,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_tm_owner_search_building_list(state) {
            dispatch(CalendarSaga.get_tm_owner_search_building_list(state))
        },
        change_tm_owner_search_form(state) {
            dispatch(CalendarSaga.change_tm_owner_search_form(state))
        },
        change_eigyou_detail(state) {
            dispatch(CalendarSaga.change_eigyou_detail(state))
        },
        get_eigyou_owner_room(state) {
            dispatch(CalendarSaga.get_eigyou_owner_room(state))
        },
    };
} 

// export default AddressGrid;
export default connect(mapStateToProps, mapDispatchToProps)(SupportHistoryGrid);
