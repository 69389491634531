import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as Responses from './Saga/ResponseList';
import '../../Css/Grid.css';
import LoadingComponent from '../Layout/LoadingComponent';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Button from '@mui/material/Button';
import MailDetail from "../Mail/MailDetailComponent";
import moment from 'moment';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


//OK
const DetailFormatter = ({ value, ...props }: any) => {
  const media = props.row.request_rent_m_media_id === 1 ? <span className="badge badge-primary">アパマン</span>
    : props.row.request_rent_m_media_id === 14 ? <span className="badge badge-success">スーモ</span>
      : props.row.request_rent_m_media_id === 16 ? <span className="badge badge-warning">ホームズ</span>
        : props.row.request_rent_m_media_id === 21 ? <span className="badge badge-danger">アットホーム</span>
          : props.row.request_rent_m_media_id === 29 ? <span className="badge badge-light">すまいズ</span>
            : props.row.request_rent_m_media_id === 2 ? <span className="badge badge-info">CLASO.</span>
              : ""

  const shop = props.row.mail_account_id === 1 ? <span className="badge badge-success">番</span>
    : props.row.mail_account_id === 2 ? <span className="badge badge-warning">レ</span>
      : props.row.mail_account_id === 3 ? <span className="badge badge-danger">駅</span>
        : props.row.mail_account_id === 4 ? <span className="badge badge-dark">東</span>
          : props.row.mail_account_id === 5 ? <span className="badge badge-light">公</span>
            : props.row.mail_account_id === 6 ? <span className="badge badge-info">サ</span>
              : props.row.mail_account_id === 11 ? <span className="badge badge-secondary">通</span>
                : ""


  return <span>{shop}{media}</span>
};

//OK
const DateFormatter = ({ value, ...props }: any) => {
  const time = value ? moment(new Date(value).setTime(new Date(value).getTime() - 1000 * 60 * 60 * 9)) : ""
  return time ? time.format("YYYY年MM月DD日 H:mm") : ""
  // return value === true ? <i className="fas fa-check" style={{ color: "green" }}></i> : ""

};

const columns2 = [

  { key: 'request_rent_m_media_id', name: '内容', width: 100, resizable: true, formatter: DetailFormatter },
  { key: 'date', name: '反響日', width: 160, resizable: true, formatter: DateFormatter },
  { key: 'request_customer_name', name: 'お客様名', width: 120, resizable: true },
  { key: 'request_customer_tel', name: '連絡先', width: 100, resizable: true },
  { key: 'request_tatemono_name', name: '反響内容', width: 100, resizable: true },
  { key: 'request_customer_mail', name: 'メールアドレス', width: 150, resizable: true },
  { key: 'detail', name: '詳細', resizable: true, width: 100 },
  { key: 'id', name: 'id', resizable: true },
]

const columns = [
  { key: 'selected', name: '', width: 40 },
  { key: 'request_rent_m_media_id', name: '内容', width: 100 },
  { key: 'date', name: '反響日', width: 160 },
  { key: 'request_customer_name', name: 'お客様名', width: 120 },
  { key: 'request_customer_tel', name: '連絡先', width: 100 },
  { key: 'request_tatemono_name', name: '反響内容', width: 100 },
  { key: 'request_customer_mail', name: 'メールアドレス', width: 150 },
  { key: 'detail', name: '詳細', width: 100 },
  { key: 'id', name: 'id', width: 150 },
]



interface Props {
  row: any,
  mail_id: number,
  response_matching_list_loading: number,
  response_matching_select_list: any
  setrow: number,
  get_response_matching_select_list_success: any,

}

interface State {
  modalmail: boolean,
  modalselect: boolean,
  mailid: number,
  modalMatching: boolean,
  mailMatchingId: number,
  columns: any,
  refresh: boolean,
}

class checkBoxFormatter extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    super(props);

  }
  render() {

    console.log(this.props.row.id)
    return (this.props.row.flg === 2 ?
      <div>
        <input type="checkbox" id={"scales" + this.props.row.id} className="react-grid-checkbox" name={"scales" + this.props.row.id} />
        <label htmlFor={"scales" + this.props.row.id} className="react-grid-checkbox-label">
        </label>
      </div> : ""
    )
  }
}


const Base = ({ column, props, rowIndex, setrow }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex][column.key] ? props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex][column.key] : ""}
  {/* {props.response_matching_list} */}
</Cell>;

const Media = ({ column, props, rowIndex, setrow }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    {
        (() => {

          const media = props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].request_rent_m_media_id === 1 ? <span className="badge badge-primary">アパマン</span>
          : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].request_rent_m_media_id === 14 ? <span className="badge badge-success">スーモ</span>
            : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].request_rent_m_media_id === 16 ? <span className="badge badge-warning">ホームズ</span>
              : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].request_rent_m_media_id === 21 ? <span className="badge badge-danger">アットホーム</span>
                : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].request_rent_m_media_id === 29 ? <span className="badge badge-light">すまいズ</span>
                  : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].request_rent_m_media_id === 2 ? <span className="badge badge-info">CLASO.</span>
                    : ""
      
        const shop = props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].mail_account_id === 1 ? <span className="badge badge-success">番</span>
          : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].mail_account_id === 2 ? <span className="badge badge-warning">レ</span>
            : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].mail_account_id === 3 ? <span className="badge badge-danger">駅</span>
              : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].mail_account_id === 4 ? <span className="badge badge-dark">東</span>
                : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].mail_account_id === 5 ? <span className="badge badge-light">公</span>
                  : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].mail_account_id === 6 ? <span className="badge badge-info">サ</span>
                    : props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].mail_account_id === 11 ? <span className="badge badge-secondary">通</span>
                      : ""
      
      
        return <span>{shop}{media}</span>
        })()
    }
</Cell>;

const ResponseDate = ({ column, props, rowIndex, setrow }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
  {props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex][column.key] ? moment(props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex][column.key]).utc().format("YYYY年MM月DD日 H:mm") : ""}
</Cell>;

const Detail = ({ column, props, rowIndex, setrow, togglemail }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
  <Button style={{backgroundColor:"#28a745", color:"white"}} variant="contained" size="small" onClick={(e)=>togglemail(props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex].id)}>メール詳細</Button>
</Cell>;

const Check = ({ column, props, rowIndex, setrow, onChangeCheck }: any) => 
<Cell style={{ whiteSpace: "nowrap" }} >
    <FormControlLabel checked={props.response_matching_select_list.filter((a: any) => a.id !== setrow)[rowIndex][column.key] == true ? true: false} onChange={(e)=>onChangeCheck(e, rowIndex)} control={<Checkbox name="checkedC" style={{marginLeft:2, marginTop:-5}} />} label="" />
</Cell>;


class ResponseMatchingSelectGridComponent extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    super(props);
    this.state = {
      modalselect: false,
      mailid: this.props.mail_id,
      modalMatching: false,
      mailMatchingId: 0,
      modalmail: false,
      columns: columns,
      refresh: false,

    };
    this.getCellActions = this.getCellActions.bind(this);
    this.toggleMatching = this.toggleMatching.bind(this);
    this.togglemail = this.togglemail.bind(this);
    this.onRowsSelected = this.onRowsSelected.bind(this);
    this.onRowsDeselected = this.onRowsDeselected.bind(this);
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.onChangeCheck = this.onChangeCheck.bind(this);
    this.onChangeCheckAll = this.onChangeCheckAll.bind(this);
  }




//OK
  togglemail = (id: number) => {

    this.setState(prevState => ({
      modalmail: !prevState.modalmail,
      mailid: id
    }));
  }
  toggleMatching = (id: number) => {

    this.setState(prevState => ({
      modalMatching: !prevState.modalMatching,
      mailMatchingId: id
    }));
  }


  EmptyRowsView = () => {
    const message = "検索結果がありません。";

    return (
      this.props.response_matching_list_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", backgroundColor: "#ddd", height: 160, padding: "50px" }} > <h3>{message}</h3></div>

    );
  };

//OK
  getCellActions(column: any, row: any) {

    if (column.key === "detail") {
      return [{
        icon: <button className="btn btn-sm btn-success" style={{ width: "100px" }} >メール詳細</button>,

        callback: () => {
          this.togglemail(row.id)
        }
      }
      ]

    } else {
      return {};
    }

  }

//OK
  onRowsSelected = (rows: any) => {

    rows.forEach(function (r: any) {
      r.row.selected = true;
    });

    this.props.get_response_matching_select_list_success(this.props.response_matching_select_list);
    this.forceUpdate();

  };
// OK
  onRowsDeselected = (rows: any) => {

    rows.forEach(function (r: any) {

      r.row.selected = false;
    });


    this.props.get_response_matching_select_list_success(this.props.response_matching_select_list);
    this.forceUpdate();
  };



  _onColumnResizeEndCallback(newColumnWidth:any, columnKey:any) {

    let columnsset = this.state.columns.map(function (value:any) {
      // alert(value.name + " " + columnKey)
      if (value.key === columnKey) {
        // alert(value.width)
        value.width = newColumnWidth
        // alert(newColumnWidth)
      }
      return value
    })

    this.setState({ columns: columnsset })

  }

onChangeCheck(e:any, idx:any){

  let response_matching_select_list = this.props.response_matching_select_list.filter((a: any) => a.id !== this.props.setrow).map((value: any, index:any) => {
      if(index == idx){
          value.selected = e.target.checked
          // this.props.selectedrow(value.id, value.mail_all_receive_id)
      }
      return value
  })
  this.props.get_response_matching_select_list_success(response_matching_select_list)
  

  this.setState({
      refresh: !this.state.refresh
  });
}

onChangeCheckAll(e:any){

  let response_matching_select_list = this.props.response_matching_select_list.filter((a: any) => a.id !== this.props.setrow).map((value: any, index:any) => {
      value.selected = e.target.checked
      return value
  })
  this.props.get_response_matching_select_list_success(response_matching_select_list)
  

  this.setState({
      refresh: !this.state.refresh
  });
}


  render() {
    return (
      <div>
        {/* <ReactDataGrid

          scrollToRowIndex={10}
          enableCellSelect={true}
          columns={columns2}
          rowGetter={(i: number) => this.props.response_matching_select_list.filter((a: any) => a.id !== this.props.setrow)[i]}
          rowsCount={this.props.response_matching_select_list.filter((a: any) => a.id !== this.props.setrow).length}
          headerRenderer={true}
          getCellActions={this.getCellActions}
          dragable={true}
          emptyRowsView={this.EmptyRowsView}
          minHeight={200}
          rowSelection={{
            showCheckbox: true,
            enableShiftSelect: true,
            onRowsSelected: this.onRowsSelected,
            onRowsDeselected: this.onRowsDeselected,
            selectBy: {
              isSelectedKey: 'selected'
            }
          }}
        /> */}

            <Table
              rowHeight={40}
              rowsCount={this.props.response_matching_select_list.filter((a: any) => a.id !== this.props.setrow).length}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
              touchScrollEnabled={true}
              width={window.innerWidth- 200}
              height={300}
              headerHeight={40}
              >

              {this.state.columns.map((value:any, i:any) => {
                return <Column
                  columnKey={value.key}
                  header={<Cell 
                    // onSortChange={this._onSortChange}
                    // sortDir={this.state.colSortDirs[value.key_name]}
                    // style={{ whiteSpace: "nowrap" }}
                    >{value.key === "selected" ? <Checkbox onClick={(e)=>this.onChangeCheckAll(e)} style={{padding:0}} /> : value.name}</Cell>}

                  // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                  cell={({ rowIndex, ...props }) => (


                    value.key === "disp_format" ? <Base column={value} props={this.props} rowIndex={rowIndex} setrow={this.props.setrow}></Base>
                    : value.key === "date" ? <ResponseDate column={value} props={this.props} rowIndex={rowIndex} setrow={this.props.setrow}></ResponseDate>
                    : value.key === "request_rent_m_media_id" ? <Media column={value} props={this.props} rowIndex={rowIndex} setrow={this.props.setrow}></Media>
                    : value.key === "selected" ? <Check column={value} props={this.props} rowIndex={rowIndex} setrow={this.props.setrow} onChangeCheck={this.onChangeCheck} ></Check>
                    : value.key === "detail" ? <Detail column={value} props={this.props} rowIndex={rowIndex} setrow={this.props.setrow} togglemail={this.togglemail}></Detail>
                      : <Base c column={value} props={this.props} rowIndex={rowIndex} setrow={this.props.setrow}></Base>

                  )}

                  width={value.width}
                  isResizable={true}
                //   fixed={value.frozen}

                />
              }, this.props)}


            </Table >


        <Modal isOpen={this.state.modalmail} toggle={() => this.togglemail(1)} size="lg" fade={false}>

          <ModalHeader toggle={() => this.togglemail(1)}>メール詳細</ModalHeader>
          <ModalBody>
            <MailDetail toggle={1} mailid={this.state.mailid} />
          </ModalBody>
        </Modal>

      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {
    responselist: state.responsesReducer.responselist,
    responselist_loading: state.responsesReducer.responselist_loading,
    window_height: state.masterReducer.window_height,
    response_matching_select_list: state.responsesReducer.response_matching_select_list,
    response_matching_select_list_loading: state.responsesReducer.response_matching_list_select_loading
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    get_response_matching_select_list_success(state: any) {
      dispatch(Responses.get_response_matching_select_list_success(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseMatchingSelectGridComponent);