import React, { Component } from 'react';
import { connect } from 'react-redux';


import * as Responses from './Saga/Responses';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EditIcon from '@mui/icons-material/Edit';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Alert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import './Css/Table.css';
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
// moment.locale('ja')
moment.locale("ja", {
    weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
    weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
    });


const CustomStepIcon = (props) => {
    // const classes = useStyles();
    const { active, completed } = props;
    return (
        <div>
        {/* {alert(props.completed)} */}
        {/* {console.log("propss",props)} */}
        {/* {props.completed ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20}} /> :
        <div className="circle_number">{props.icon}</div>
        } */}
        {/* <Chip label={props.icon} /> */}
      {/* <CheckCircleIcon style={{color:"#1976d2"}} /> */}
    </div>
    );
  };
const CustomStepIcon2 = (props) => {
    // const classes = useStyles();
    const { active, completed } = props;
    return (
        <div>
        {/* {alert(props.completed)} */}
        {props.completed ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20}} /> :
        <div className="circle_number">{props.icon + 4}</div>
        }
        {/* <Chip label={props.icon} /> */}
      {/* <CheckCircleIcon style={{color:"#1976d2"}} /> */}
    </div>
    );
  };
const CustomStepIcon3 = (props) => {
    // const classes = useStyles();
    const { active, completed } = props;
    return (
        <div>
        {/* {alert(props.completed)} */}
        {props.completed ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20}} /> :
        <div className="circle_number">{props.icon + 8}</div>
        }
        {/* <Chip label={props.icon} /> */}
      {/* <CheckCircleIcon style={{color:"#1976d2"}} /> */}
    </div>
    );
  };

class ResponseComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modal: false,

        }
        this.change_date = this.change_date.bind(this);
        
    }




    componentWillMount() {

    }


    customersubmit(value) {


    }


    handleTabChange = (event, newValue) => {

    };

    // change_date(value, name) {

    //     let rent_response_date_editor = {...this.props.rent_response_date_editor}
    //     if(value){
    //         // if(name == "first_contact_at"){
    //         //     let back = rent_response_date_editor.first_contact_at ? moment(rent_response_date_editor.first_contact_at).format(' HH:mm') : moment().format(' HH:mm')
    //         //     rent_response_date_editor[name] = moment(value).format('YYYY/MM/DD') + " " + back
    //         //     // alert(rent_response_date_editor[name])
    //         // }else{
    //             rent_response_date_editor[name] = moment(value).format('YYYY/MM/DD 00:00')
    //         // }
            
    //     }else{
    //         rent_response_date_editor[name] = null
    //     }
        
    //     this.props.change_rent_response_date_editor(rent_response_date_editor)

    //     this.setState({ 
    //         refresh: !this.state.refresh 
    //     });
    // }

    change_date(e) {

        let rent_response_date_editor = this.props.rent_response_date_editor

        rent_response_date_editor[e.target.name] = e.target.value
        
        this.props.change_rent_response_date_editor(rent_response_date_editor)

        this.setState({ 
            refresh: !this.state.refresh 
        });
    }

    change_time(value, name) {

        let rent_response_date_editor = {...this.props.rent_response_date_editor}
        if(value){
            if(name == "first_contact_at"){
                // alert(value.target.value)
                let back = rent_response_date_editor.first_contact_at ? moment(rent_response_date_editor.first_contact_at).format("YYYY/MM/DD") : moment().format("YYYY/MM/DD")
                rent_response_date_editor[name] = back + " " + value.target.value
                // alert(rent_response_date_editor[name])
            }else{
                // rent_response_date_editor[name] = moment(value).format('YYYY/MM/DD 00:00')
            }
            
        }else{
            rent_response_date_editor[name] = null
        }
        
        this.props.change_rent_response_date_editor(rent_response_date_editor)

        this.setState({ 
            refresh: !this.state.refresh 
        });
    }




    render() {

        return (
            <div style={{marginRight:-5}}>
                <div style={{textAlign:"right"}}>
                    <IconButton size="small" style={{position:'absolute', right:15, top:5}} onClick={(e) => this.setState({ "modal": true })}><EditIcon/></IconButton>
                </div>
            <Dialog onClose={(e) => this.setState({ "modal": false })} aria-labelledby="変更" open={this.state.modal}>
                <DialogTitle>日付変更</DialogTitle>

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
                    <DialogContent style={{padding:10}}>


                            <TextField
                                id="first_contact_at"
                                label="初回リアクション"
                                type="date"
                                name="first_contact_at"
                                size="small"
                                onChange={this.change_date}
                                value={moment(this.props.rent_response_date_editor.first_contact_at).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{marginRight:20}}
                                // variant="standard"
                            />

                            {/* <LocalizationProvider dateAdapter={AdapterDateFns} >
                                <TextField
                                    id="time"
                                    label="時間"
                                    type="time"
                                    // defaultValue="07:30"
                                    InputLabelProps={{
                                    shrink: true,
                                    }}
                                    inputProps={{
                                    step: 300, // 5 min
                                    }}
                                    // onChange={this.change_datetime_start_time}
                                    onChange={(e)=>this.change_time(e, "first_contact_at")}
                                    sx={{ width: 200, marginTop:9 }}
                                    value={this.props.rent_response_date_editor.first_contact_at ? moment(this.props.rent_response_date_editor.first_contact_at).format("HH:mm") : null}
                                    // value={moment(this.props.rent_customer_contacts_forms.start).format("HH:mm")}
                                    // style={{float: "left"}}
                                    variant="outlined"
                                    size='small'
                                    className='m-2'
                                />
                            </LocalizationProvider> */}


                        </DialogContent>
                        <DialogContent style={{padding:10}}>

                            <TextField
                                id="line_way_at"
                                label="LINE誘導"
                                type="date"
                                name="line_way_at"
                                size="small"
                                onChange={this.change_date}
                                value={moment(this.props.rent_response_date_editor.line_way_at).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // variant="standard"
                                style={{marginRight:20}}
                            />

                            <TextField
                                id="apo_get_at"
                                label="アポ取得日"
                                type="date"
                                name="apo_get_at"
                                size="small"
                                onChange={this.change_date}
                                value={moment(this.props.rent_response_date_editor.apo_get_at).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // variant="standard"
                                style={{marginRight:20}}
                            />
                        </DialogContent>
                        <DialogContent style={{padding:10}}>
                                
                            <TextField
                                id="coming_first_intend_at"
                                label="初回来店予定日"
                                type="date"
                                name="coming_first_intend_at"
                                size="small"
                                onChange={this.change_date}
                                value={moment(this.props.rent_response_date_editor.coming_first_intend_at).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // variant="standard"
                                style={{marginRight:20}}
                            />
                         
                            <TextField
                                id="prepare_at"
                                label="準備完了日"
                                type="date"
                                name="prepare_at"
                                size="small"
                                onChange={this.change_date}
                                value={moment(this.props.rent_response_date_editor.prepare_at).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                // variant="standard"
                                style={{marginRight:20}}
                            />
                          
                        
                    </DialogContent>

                    {/* </MuiPickersUtilsProvider> */}
                <DialogActions>
                    <Button variant="contained" style={{ margin: 5 }} color="primary" 
                        onClick={()=>{this.props.update_rent_response_date_edit();this.setState({ "modal": false })}}
                    >保存</Button>
                    <Button variant="outlined" style={{ margin: 5 }} onClick={(e) => this.setState({ "modal": false })}>閉じる</Button>
                </DialogActions>
            </Dialog>
            {/* <Scrollbars style={{float: "left", height:60, width:"100%" }}> */}
                <Stepper activeStep={0} style={{marginTop:3}}>
                    <Step key={0} completed={this.props.responsedetail.date} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                            <div style={{display: "flex"}}>{this.props.responsedetail.date ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>1</div>}反響日</div>
                            {this.props.responsedetail.date ? 
                                <Alert style={{paddingLeft:6, paddingRight:6}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.date).format(moment(this.props.responsedetail.date).format("YYYY") == moment().format("YYYY") ? 
                                        moment(this.props.responsedetail.date).format("M月D日(ddd)") : 
                                        moment(this.props.responsedetail.date).format("YY年M月D日(ddd)"))}
                                </Alert>:""}
                        </StepLabel>
                    </Step>
                    <Step key={1} completed={this.props.responsedetail.first_contact_at} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                        <div style={{display: "flex"}}>{this.props.responsedetail.first_contact_at ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>2</div>}初回ﾘｱｸｼｮﾝ</div>
                            {this.props.responsedetail.first_contact_at ? 
                                <Alert style={{paddingLeft:6, paddingRight:6}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.first_contact_at).format(moment(this.props.responsedetail.first_contact_at).format("YYYY") == moment().format("YYYY") ? 
                                        moment(this.props.responsedetail.first_contact_at).format("M月D日(ddd)") : 
                                        moment(this.props.responsedetail.first_contact_at).format("YY年M月D日(ddd)"))}
                                </Alert> : ""}
                        </StepLabel>
                    </Step>
                    <Step key={2} completed={this.props.responsedetail.line_way_at} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                        <div style={{display: "flex"}}>{this.props.responsedetail.line_way_at ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>3</div>}LINE誘導</div>
                            {this.props.responsedetail.line_way_at ? 
                                <Alert style={{paddingLeft:6, paddingRight:6}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.line_way_at).format(moment(this.props.responsedetail.line_way_at).format("YYYY") == moment().format("YYYY") ? 
                                        moment(this.props.responsedetail.line_way_at).format("M月D日(ddd)") : 
                                        moment(this.props.responsedetail.line_way_at).format("YY年M月D日(ddd)"))}
                                </Alert> : ""}
                        </StepLabel>
                    </Step>
                    <Step key={3} completed={this.props.responsedetail.apo_get_at} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                        <div style={{display: "flex"}}>{this.props.responsedetail.apo_get_at ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>4</div>}アポ取得日</div>
                            {this.props.responsedetail.apo_get_at ? 
                                <Alert style={{paddingLeft:6, paddingRight:6}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.apo_get_at).format(moment(this.props.responsedetail.apo_get_at).format("YYYY") == moment().format("YYYY") ? 
                                        moment(this.props.responsedetail.apo_get_at).format("M月D日(ddd)") : 
                                        moment(this.props.responsedetail.apo_get_at).format("YY年M月D日(ddd)"))}
                                </Alert> : ""}
                        </StepLabel>
                    </Step>

                    <Step key={4} completed={this.props.responsedetail.coming_first_intend_at} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                            <div style={{display: "flex"}}>{this.props.responsedetail.coming_first_intend_at ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>5</div>}来店予定日</div>
                            {this.props.responsedetail.coming_first_intend_at ? 
                                <Alert style={{paddingLeft:6, paddingRight:6}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.coming_first_intend_at).format(moment(this.props.responsedetail.coming_first_intend_at).format("YYYY") == moment().format("YYYY") ? 
                                        moment(this.props.responsedetail.coming_first_intend_at).format("M月D日(ddd)") : 
                                        moment(this.props.responsedetail.coming_first_intend_at).format("YY年M月D日(ddd)"))}
                                </Alert> : ""}
                        </StepLabel>
                    </Step>
                    <Step key={5} completed={this.props.responsedetail.prepare_at} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                            <div style={{display: "flex"}}>{this.props.responsedetail.prepare_at ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>6</div>}準備期間</div>
                            {this.props.responsedetail.prepare_at && this.props.responsedetail.apo_get_at ? 
                                <Alert style={{paddingLeft:6, paddingRight:6, textAlign:"center"}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.prepare_at).diff(moment(this.props.responsedetail.apo_get_at), 'days')}日
                                </Alert>  : ""}
                        </StepLabel>
                    </Step>
                    <Step key={6} completed={this.props.responsedetail.prepare_at} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                            <div style={{display: "flex"}}>{this.props.responsedetail.prepare_at ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>7</div>}準備完了日</div>
                            {this.props.responsedetail.prepare_at ? 
                                <Alert style={{paddingLeft:6, paddingRight:6}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.prepare_at).format(moment(this.props.responsedetail.prepare_at).format("YYYY") == moment().format("YYYY") ? 
                                        moment(this.props.responsedetail.prepare_at).format("M月D日(ddd)") : 
                                        moment(this.props.responsedetail.prepare_at).format("YY年M月D日(ddd)"))}
                                </Alert> : ""}
                        </StepLabel>
                    </Step>
                    <Step key={7} completed={this.props.responsedetail.coming_intend_datetime} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                            <div style={{display: "flex"}}>{this.props.responsedetail.coming_intend_datetime ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>8</div>}来店日</div>
                            {this.props.responsedetail.coming_intend_datetime ? 
                                <Alert style={{paddingLeft:6, paddingRight:6}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.coming_intend_datetime).format(moment(this.props.responsedetail.coming_intend_datetime).format("YYYY") == moment().format("YYYY") ? 
                                        moment(this.props.responsedetail.coming_intend_datetime).format("M月D日(ddd)") : 
                                        moment(this.props.responsedetail.coming_intend_datetime).format("YY年M月D日(ddd)"))}
                                </Alert> : ""}
                        </StepLabel>
                    </Step>

                    <Step key={8} completed={this.props.responsedetail.application_date} variant="caption" color="error">
                        <StepLabel StepIconComponent={CustomStepIcon} style={{whiteSpace: "nowrap"}}>
                            <div style={{display: "flex"}}>{this.props.responsedetail.application_date ? <CheckCircleIcon style={{color:"#1976d2", fontSize:20, marginRight:4}} /> :<div className="circle_number" style={{marginRight:4}}>9</div>}申込日</div>
                            {this.props.responsedetail.application_date ? 
                                <Alert style={{paddingLeft:6, paddingRight:6}} icon={false} className='tuikyaku_status' severity="success">
                                    {moment(this.props.responsedetail.application_date).format(moment(this.props.responsedetail.application_date).format("YYYY") == moment().format("YYYY") ? 
                                        moment(this.props.responsedetail.application_date).format("M月D日(ddd)") : 
                                        moment(this.props.responsedetail.application_date).format("YY年M月D日(ddd)"))}
                                </Alert> : ""}
                        </StepLabel>
                    </Step>

                </Stepper>
            {/* </Scrollbars> */}
            </div >
        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        responsedetail: state.responsesReducer.responsedetail,
        rent_response_date_editor: state.responsesReducer.rent_response_date_editor,
     
    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_rent_response_date_editor(state) {
            dispatch(Responses.change_rent_response_date_editor(state))
        },
        update_rent_response_date_edit(state) {
            dispatch(Responses.update_rent_response_date_edit(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseComponent);
