
const authentication = require('../../../react-azure-adb2c2').default;




export const list = (values: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/TmTatemonoList/`;

    //  const url = `https://localhost:44341/api/TmTatemonoList/`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};












