
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import * as Mail from '../../Mail/Saga/Mail';
import * as CustomerContacts from '../../CustomerContacts/Saga/CustomerContacts';
import * as Files from '../../Files/Saga/Files';
import * as Responses from '../../ResponseDetail/Saga/Responses';

import * as laravel_api from '../Api/laravel_api';



import { call, put, takeEvery, select } from 'redux-saga/effects';

import * as rent_application_docs_api from '../Api/rent_application_docs_api';
import * as rent_contracts_api from '../Api/rent_contracts_api';
import * as rent_contract_workflows_api from '../Api/rent_contract_workflows_api';
import * as tm_zip_address_api from '../Api/tm_zip_address_api';
import * as rent_m_corporates_api from '../Api/rent_m_corporates_api';
import * as tm_tatemono_api from '../Api/tm_tatemono_api';
import * as tm_room_api from '../Api/tm_room_api';
import * as rent_m_reasons_api from '../Api/rent_m_reasons_api';
import * as rent_m_schools_api from '../Api/rent_m_schools_api';
import * as rent_m_agents_api from '../Api/rent_m_agents_api';
import * as rent_m_traders_api from '../Api/rent_m_traders_api';

import * as rent_m_relations_api from '../Api/rent_m_relations_api';
import * as rent_m_job_categorys_api from '../Api/rent_m_job_categorys_api';

import * as tm_kanri_corp_api from '../Api/tm_kanri_corp_api';

import * as t_parking_keiyaku_api from '../Api/t_parking_keiyaku_api';
import * as tm_parkingarea_api from '../Api/tm_parkingarea_api';
import * as MailsApi from '../../Mail/Api/MailsApi';

import * as rent_m_contract_types_api from '../Api/rent_m_contract_types_api';
import * as m_penalty_api from '../Api/m_penalty_api';
import * as t_chinryou_api from '../Api/t_chinryou_api';

import * as rent_contract_families_api from '../Api/rent_contract_families_api';
import * as rent_m_specials_api from '../Api/rent_m_specials_api';

import * as contractsheet_api from '../Api/contractsheet_api';
import * as rent_m_money_templates_api from '../Api/rent_m_money_templates_api';

import * as update_rent_contract_workflows_api from '../Api/update_rent_contract_workflows_api';

import * as zenhoren_api from '../Api/zenhoren_api';


import * as CheckMoney from '../../../Classes/CheckMoney';


var moment = require('moment-timezone');


export interface ResponsesListState {
  contracts_rent_application_doc_uri: any,
  rent_contract_details: any,
  rent_contract_details_new: any,
  rent_contract_details_old: any,
  rent_contract_logs: any,
  rent_contract_id: number,
  rent_contract_logs_new: number,
  rent_contract_logs_old: number,
  rent_contract_boshu_chinryou: any,
  rent_contract_editor_disp: boolean,
  rent_emergency_editor_disp: boolean,
  rent_customer_editor_disp: boolean,
  rent_contract_etc_editor_disp: any,
  rent_contract_special_editor_disp: any,
  rent_customer_editor_forms: any,
  rent_contract_editor_forms: any,
  rent_contract_req_editor_forms: any,
  rent_m_corporates: any,
  rent_m_corporates_loading: boolean,
  rent_m_corporate_editor: boolean,
  tm_tatemono_list: any,
  tm_tatemono_list_loading: boolean,
  rent_m_reasons: any,
  rent_m_schools: any,
  rent_m_agents: any,
  check_rent_customer_info: boolean,
  tm_kanri_corp_list: any,
  tm_room_list: any,
  m_penalties: any,
  rent_m_contract_types: any,
  rent_m_traders: any,
  coprporate_add_forms: any,
  rent_contract_ippan_editor_disp: boolean,
  rent_contract_car_editor_disp: boolean,
  rent_contract_family_editor_disp: boolean,
  t_parking_keiyaku_list: any,
  tm_parkingarea_list: any,
  standard_tm_parkingarea_list: any,
  rent_contract_checks: any,
  contract_check_message: any,
  rent_m_money_templates: any,
  check_contract_sheet: boolean,
  check_contract_button: boolean,
  contract_upload_files_modal: boolean,

  rent_contract_families: any;
  rent_contract_families_disp: any;
  rent_m_specials: any;
  contracts_backdrop: any;
  contracts_doc_extension: string;

  rent_m_relations: any,
  rent_m_job_categorys: any,
  contracts_cancel_confirm: any,
  tm_parkingarea_select_section: any,
  rent_contract_workflows: any,
  check_contract_sheet_file: any,
  check_contract_sheet_text: any,
  check_contract_send_modal: boolean,
  check_contract_send_mail:any,
  check_contract_send_mail_confirm:any,
  check_contract_send:boolean,
  check_contract_send_files:any,
  check_contract_send_mail_template:any,

  file_viewer_dialog_disp: boolean,
  electronic_contract: any,
  cloudsign_document_id: any,
  application_qr: any,
  transfer_qr: any,
  send_complete: any,
  send_complete_text: any,

  zenhoren_file: any,
  zenhoren_result_modal: boolean,
  back_drop_contract_mail: boolean,
  rent_contract_send_status: any,

}

const initialState: ResponsesListState = {
  contracts_rent_application_doc_uri: null,
  rent_contract_details: {},
  rent_contract_details_new: {},
  rent_contract_details_old: {},
  rent_contract_logs: [],
  rent_contract_id: 0,
  rent_contract_logs_new: 0,
  rent_contract_logs_old: 0,
  rent_contract_boshu_chinryou: [],
  rent_contract_editor_disp: false,
  rent_emergency_editor_disp: false,
  rent_customer_editor_disp: false,
  rent_contract_checks: [],
  rent_customer_editor_forms: {
    contract_form: 0,
    rent_m_corporate_id: 0,
    memo: "",
    name1: "",
    kana1: "",
    name2: "",
    sex: 1,
    birthday: null,
    tel1: "",
    tel1_remarks: "",
    tel2: "",
    tel2_remarks: "",
    fax: "",
    fax_remarks: "",
    mail1: "",
    mail1_remarks: "",
    mail2: "",
    mail2_remarks: "",
    post: "",
    add1: "",
    add2: "",
    office_rent_m_corporate_id: 0,
    remarks: "",
    rent_m_agent_id: 0,
    agent_etc: "",
  },
  rent_contract_editor_forms: {

    contract_date: "",
    application_date: "",
    tatemono_id: 0,
    tatemono_name: "",
    room_id: 0,
    room_name: "",
    number_of_car: "",
    family: []
  },
  rent_contract_req_editor_forms: {
    contract_form: 0,
    rent_m_corporate_id: 0,
    name1: "",
    kana1: "",
    name2: "",
    sex: 1,
    birthday: null,
    tel1: "",
    tel1_remarks: "",
    tel2: "",
    tel2_remarks: "",
    fax: "",
    fax_remarks: "",
    mail1: "",
    mail1_remarks: "",
    mail2: "",
    mail2_remarks: "",
    post: "",
    add1: "",
    add2: "",
    office_rent_m_corporate_id: 0,
    remarks: "",
  },
  rent_m_corporates: [],
  rent_m_corporates_loading: false,
  tm_tatemono_list: [],
  tm_tatemono_list_loading: false,
  rent_m_corporate_editor: false,
  rent_m_reasons: [],
  rent_m_schools: [],
  rent_m_agents: [],
  check_rent_customer_info: false,
  tm_kanri_corp_list: [],
  tm_room_list: [],
  m_penalties: [],
  rent_m_contract_types: [],
  rent_m_traders: [],
  rent_contract_etc_editor_disp: false,
  rent_contract_special_editor_disp: false,
  coprporate_add_forms: {
    name: '',
    kana: '',
  },
  rent_contract_ippan_editor_disp: false,
  rent_contract_car_editor_disp: false,
  rent_contract_family_editor_disp: false,
  t_parking_keiyaku_list: [],
  tm_parkingarea_list: [],
  standard_tm_parkingarea_list: [],
  contract_check_message: [],
  rent_m_money_templates: [],

  rent_contract_families: [],
  rent_contract_families_disp: [],
  rent_m_specials: [],
  contracts_backdrop: false,
  contracts_doc_extension: "",
  rent_contract_workflows: {},

  rent_m_relations: [],
  rent_m_job_categorys: [],
  contracts_cancel_confirm: false,
  tm_parkingarea_select_section: {},
  check_contract_sheet: false,
  contract_upload_files_modal:false,
  check_contract_sheet_file: [],
  check_contract_sheet_text: { procedureNum: '', title: '', Mail: '', name: '', company: '', message: '', shougakkou: '' },
  check_contract_send_modal: false,
  check_contract_send_mail: {from:'',to:'',cc:'',bcc:'',subject:'',body:''},
  check_contract_send_mail_confirm: {from:'',to:'',cc:'',bcc:'',subject:'',body:''},
  check_contract_send:false,
  check_contract_send_files: [],
  check_contract_send_mail_template:{},
  check_contract_button: false,

  file_viewer_dialog_disp: false,
  electronic_contract: 0,
  cloudsign_document_id: null,
  application_qr: null,
  transfer_qr: null,
  send_complete: false,

  zenhoren_file: { type: '', file_id: [] },
  zenhoren_result_modal: false,
  send_complete_text: { header: '', body: '' },
  back_drop_contract_mail:false,
  rent_contract_send_status: {
    rent_contract_id: "",
    send_section_id: "",
    status: 0,
    cancel_remarks: "",
  }
}


///action 
const actionCreator = actionCreatorFactory();





export const download_contracts_rent_application_doc = actionCreator<any>('DOWNLOAD_CONTRACTS_RENT_APPLICATION_DOC');
export const change_contracts_rent_application_doc_uri = actionCreator<any>('CHANGE_CONTRACTS_RENT_APPLICATION_DOC_URI');
export const get_sendmail_files = actionCreator<any>('GET_SENDMAIL_FILES');
// export const change_contract_follow_list_loading = actionCreator<boolean>('CHANGE_CONTRACT_FOLLOW_LIST_LOADING');

// export const change_calendar_list_form = actionCreator<any>('CHANGE_CALENDAR_LIST_FORM');

export const get_rent_contract_details = actionCreator<any>('GET_RENT_CONTRACT_DETAILS');
export const change_rent_contract_details = actionCreator<any>("CHANGE_RENT_CONTRACT_DETAILS");
export const change_rent_contract_details_old = actionCreator<any>("CHANGE_RENT_CONTRACT_DETAILS_OLD");
export const change_rent_contract_details_new = actionCreator<any>("CHANGE_RENT_CONTRACT_DETAILS_NEW");

export const change_rent_contract_id = actionCreator<any>("CHANGE_RENT_CONTRACT_ID");

export const change_rent_contract_logs = actionCreator<any>("CHANGE_RENT_CONTRACT_LOGS");
export const change_check_contract_sheet = actionCreator<boolean>('CHANGE_CHECK_CONTRACT_SHEET');
export const change_contract_upload_files_modal = actionCreator<boolean>('CHANGE_CONTRACT_UPLOAD_FILES_MODAL');
export const change_check_contract_send_modal = actionCreator<boolean>('CHANGE_CHECK_CONTRACT_SEND_MODAL');

export const change_rent_contract_logs_ver = actionCreator<any>("CHANGE_RENT_CONTRACT_LOGS_VER");
export const change_rent_contract_logs_new = actionCreator<any>("CHANGE_RENT_CONTRACT_LOGS_NEW");
export const change_rent_contract_logs_old = actionCreator<any>("CHANGE_RENT_CONTRACT_LOGS_OLD");


export const change_rent_contract_boshu_chinryou = actionCreator<any>("CHANGE_RENT_CONTRACT_BOSHU_CHINRYOU");


export const change_rent_contract_editor_disp = actionCreator<any>("CHANGE_RENT_CONTRACT_EDITOR_DISP");
export const check_rent_contract_editor_disp = actionCreator<any>("CHECK_RENT_CONTRACT_EDITOR_DISP");
export const change_rent_emergency_editor_disp = actionCreator<any>("CHANGE_RENT_EMERGENCY_EDITOR_DISP");
export const check_rent_emergency_editor_disp = actionCreator<any>("CHECK_RENT_EMERGENCY_EDITOR_DISP");

export const change_rent_customer_editor_disp = actionCreator<any>("CHANGE_RENT_CUSTOMER_EDITOR_DISP");

export const change_rent_customer_editor_forms = actionCreator<any>("CHANGE_RENT_CUSTOMER_EDITOR_FORMS");


export const get_rent_customer_zip_address = actionCreator<any>('GET_RENT_CUSTOMER_ZIP_ADDRESS');


export const get_rent_m_corporates = actionCreator<any>('GET_RENT_M_CORPORATES');
export const change_rent_m_corporates = actionCreator<any>('CHANGE_RENT_M_CORPORATES');


export const change_rent_m_corporates_loading = actionCreator<any>('CHANGE_RENT_M_CORPORATES_LOADING');

export const set_rent_contract_editor_forms = actionCreator<any>('SET_RENT_CONTRACT_EDITOR_FORMS');
export const change_rent_contract_editor_forms = actionCreator<any>('CHANGE_RENT_CONTRACT_EDITOR_FORMS');

export const change_tm_tatemono_list = actionCreator<any>('CHANGE_TM_TATEMONO_LIST');
export const change_tm_tatemono_list_loading = actionCreator<any>('CHANGE_TM_TATEMONO_LIST_LOADING');
export const get_tm_tatemono_list = actionCreator<any>('GET_TM_TATEMONO_LIST');


export const change_rent_m_corporate_editor = actionCreator<any>('CHANGE_RENT_M_CORPORATE_EDITOR');

export const get_rent_m_reasons = actionCreator<any>('GET_RENT_M_REASONS');
export const change_rent_m_reasons = actionCreator<any>('CHANGE_RENT_M_REASONS');

export const get_rent_m_schools = actionCreator<any>('GET_RENT_M_SCHOOLS');
export const change_rent_m_schools = actionCreator<any>('CHANGE_RENT_M_SCHOOLS');

export const get_rent_m_agents = actionCreator<any>('GET_RENT_M_AGENTS');
export const change_rent_m_agents = actionCreator<any>('CHANGE_RENT_M_AGENTS');


export const change_rent_contract_req_editor_forms = actionCreator<any>('CHANGE_RENT_CONTRACT_REQ_EDITOR_FORMS');

export const check_rent_contract_editor_forms = actionCreator<any>('CHECK_RENT_CONTRACT_REQ_EDITOR_FORMS');


export const change_check_rent_customer_info = actionCreator<any>('CHANGE_CHECK_RENT_CUSTOMER_INFO');


export const get_tm_kanri_corp_list = actionCreator<any>('GET_TM_KANRI_CORP_LIST');
export const change_tm_kanri_corp_list = actionCreator<any>('CHANGE_TM_KANRI_CORP_LIST');


export const get_tm_room_list = actionCreator<any>('GET_TM_ROOM_LIST');
export const change_tm_room_list = actionCreator<any>('CHANGE_TM_ROOM_LIST');


export const get_m_penalties = actionCreator<any>('GET_M_PENALTIES');
export const change_m_penalties = actionCreator<any>('CHANGE_M_PENALTIES');

export const get_rent_m_contract_types = actionCreator<any>('GET_RENT_M_CONTRACT_TYPES');
export const change_rent_m_contract_types = actionCreator<any>('CHANGE_RENT_M_CONTRACT_TYPES');

export const get_rent_m_traders = actionCreator<any>('GET_RENT_M_TRADERS');
export const change_rent_m_traders = actionCreator<any>('CHANGE_RENT_M_TRADERS');

export const change_rent_contract_etc_editor_disp = actionCreator<any>('CHANGE_RENT_CONTRACT_ETC_EDITOR_DISP');
export const change_rent_contract_special_editor_disp = actionCreator<any>('CHANGE_RENT_CONTRACT_SPECIAL_EDITOR_DISP');

// export const update_rent_customers = actionCreator<any>('UPDATE_RENT_CUSTOMERS');

export const update_rent_contracts = actionCreator<any>('UPDATE_RENT_CONTRACTS');

export const update_rent_contract_workflows = actionCreator<any>('UPDATE_RENT_CONTRACT_WORKFLOWS');
export const get_rent_contract_workflows = actionCreator<any>('GET_RENT_CONTRACT_WORKFLOWS');
export const change_rent_contract_workflows = actionCreator<any>('CHANGE_RENT_CONTRACT_WORKFLOWS');

export const change_coprporate_add_forms = actionCreator<any>('CHANGE_CORPORATE_ADD_FORMS');

export const change_rent_contract_ippan_editor_disp = actionCreator<any>('CHANGE_RENT_CONTRACT_IPPAN_EDITOR_DISP');
export const change_rent_contract_car_editor_disp = actionCreator<any>('CHANGE_RENT_CONTRACT_CAR_EDITOR_DISP');
export const change_rent_contract_family_editor_disp = actionCreator<any>('CHANGE_RENT_CONTRACT_FAMILY_EDITOR_DISP');

export const get_t_parking_keiyaku_list = actionCreator<any>('GET_T_PARKING_KEIYAKU_LIST');
export const change_t_parking_keiyaku_list = actionCreator<any>('CHANGE_T_PARKING_KEIYAKU_LIST');
export const change_tm_parkingarea_list = actionCreator<any>('CHANGE_TM_PARKINGAREA_LIST');
export const change_tm_parkingarea_select_section = actionCreator<any>('CHANGE_TM_PARKINGAREA_SELECT_SECTION')

export const change_rent_contract_checks = actionCreator<any>('CHANGE_RENT_CONTRACT_CHECKS');

export const change_contract_check_message = actionCreator<any>('CHANGE_CONTRACT_CHECK_MESSAGE');

export const get_rent_m_money_templates = actionCreator<any>('GET_RENT_M_MONEY_TEMPLATES');
export const change_rent_m_money_templates = actionCreator<any>('CHANGE_RENT_M_MONEY_TEMPLATES');

export const set_contracts_boshu_chinryou = actionCreator<any>('SET_CONTRACTS_BOSHU_CHINRYOU');


export const get_rent_contract_families = actionCreator<any>('GET_RENT_CONTRACT_FAMILIES');
export const update_rent_contract_families = actionCreator<any>('UPDATE_RENT_CONTRACT_FAMILIES');
export const change_rent_contract_families = actionCreator<any>('CHANGE_RENT_CONTRACT_FAMILIES');
export const get_rent_contract_families_disp = actionCreator<any>('GET_RENT_CONTRACT_FAMILIES_DISP');
export const change_rent_contract_families_disp = actionCreator<any>('CHANGE_RENT_CONTRACT_FAMILIES_DISP');

export const get_rent_m_specials = actionCreator<any>('GET_RENT_M_SPECIALS');
export const change_rent_m_specials = actionCreator<any>('CHANGE_RENT_M_SPECIALS');


export const change_contracts_backdrop = actionCreator<any>('CHANGE_CONTRACTS_BACKDROP');

export const update_cars = actionCreator<any>('UPDATE_CARS');


export const contracts_send_parking = actionCreator<any>('CONTRACTS_SEND_PARKING');
export const contracts_send_application_mail = actionCreator<any>('CONTRACTS_SEND_APPLICATION_MAIL');
export const create_send_form = actionCreator<any>('CREATE_SEND_FORM');
export const update_send_form = actionCreator<any>('UPDATE_SEND_FORM');
export const contracts_resend_parking = actionCreator<any>('CONTRACTS_RESEND_PARKING');
export const get_resend_parking = actionCreator<any>('GET_RESEND_PARKING');

export const get_tm_parkingarea_list = actionCreator<any>('GET_TM_PARKINGAREA_LIST');
export const get_standard_tm_parkingarea_list = actionCreator<any>('GET_STANDARD_TM_PARKINGAREA_LIST');

export const get_rent_m_relations = actionCreator<any>('GET_RENT_M_RELATIONS');
export const change_rent_m_relations = actionCreator<any>('CHANGE_RENT_M_RELATIONS');
export const get_rent_m_job_categorys = actionCreator<any>('GET_RENT_M_JOB_CATEGORYS');
export const change_rent_m_job_categorys = actionCreator<any>('CHANGE_RENT_M_JOB_CATEGORYS');


export const change_contracts_doc_extension = actionCreator<any>('CHANGE_CONTRACTS_DOC_EXTENSION');

export const get_tm_parkingarea_detail_list = actionCreator<any>('GET_TM_PARKINGAREA_DETAIL_LIST');


export const download_docs = actionCreator<any>('DOWNLOAD_DOCS');
export const download_bill = actionCreator<any>('DOWNLOAD_BILL');
export const download_contract = actionCreator<any>('DOWNLOAD_CONTRACT');
export const download_disclosure_statement = actionCreator<any>('DOWNLOAD_DISCLOSURE_STATEMENT');
export const download_lifeline_sheet = actionCreator<any>('DOWNLOAD_LIFELINE_SHEET');
export const download_jippi = actionCreator<any>('DOWNLOAD_JIPPI');
export const download_shikibiki = actionCreator<any>('DOWNLOAD_SHIKIBIKI');
export const download_kaketsuke = actionCreator<any>('DOWNLOAD_KAKETSUKE');
export const download_kaden = actionCreator<any>('DOWNLOAD_KADEN');
export const download_kaden_shiyou = actionCreator<any>('DOWNLOAD_KADEN_SHIYOU');
export const download_jousuiki = actionCreator<any>('DOWNLOAD_JOUSUIKI');
export const download_hazard = actionCreator<any>('DOWNLOAD_HAZARD');
export const download_mimamori = actionCreator<any>('DOWNLOAD_MIMAMORI');


export const change_contracts_cancel_confirm = actionCreator<any>('CHANGE_CONTRACTS_CANCEL_CONFIRM');

export const contracts_cancel = actionCreator<any>('CONTRACTS_CANCEL');

export const change_check_contract_sheet_file = actionCreator<any>('CHANGE_CHECK_CONTRACT_SHEET_FILE');
export const change_check_contract_button = actionCreator<any>('CHANGE_CHECK_CONTRACT_BUTTON');
export const resend_contract_sheet = actionCreator<any>('RESEND_CONTRACT_SHEET');
export const change_check_contract_sheet_text = actionCreator<any>('CHANGE_CHECK_CONTRACT_SHEET_TEXT');
export const change_settlement_lock = actionCreator<any>('CHANGE_SETTLEMENT_LOCK');
export const contracts_sheet_check = actionCreator<any>('CONTRACTS_SHEET_CHECK');
export const contracts_sheet_corp = actionCreator<any>('CONTRACTS_SHEET_CORP');

export const change_file_viewer_dialog_disp = actionCreator<any>('CHANGE_FILE_VIEWER_DIALOG_DISP');
export const change_hazardmap = actionCreator<any>('CHANGE_HAZARDMAP');

export const set_rent_contract_checks = actionCreator<any>('SET_RENT_CONTRACT_CHECKS');
export const get_electronic_contract = actionCreator<any>('GET_ELECTRONIC_CONTRACT');
export const electronic_contract_cancel = actionCreator<any>('ELECTRONIC_CONTRACT_CANCEL');
export const check_send_files = actionCreator<any>('CHECK_SEND_FILES');
export const change_check_contract_send_files = actionCreator<any>('CHANGE_CHECK_CONTRACT_SEND_FILES');
export const change_check_contract_send_mail = actionCreator<any>('CHANGE_CHECK_CONTRACT_SEND_MAIL');
export const change_check_contract_send_mail_confirm = actionCreator<any>('CHANGE_CHECK_CONTRACT_SEND_MAIL_CONFIRM');
export const change_check_contract_send = actionCreator<any>('CHANGE_CHECK_CONTRACT_SEND');
export const change_check_contract_send_mail_template = actionCreator<any>('CHANGE_CHECK_CONTRACT_SEND_MAIL_TEMPLATE');
export const check_electronic_contract = actionCreator<any>('CHECK_ELECTRONIC_CONTRACT');
export const remind_electronic_contract = actionCreator<any>('REMIND_ELECTRONIC_CONTRACT');
export const change_electronic_contract = actionCreator<any>('CHANGE_ELECTRONIC_CONTRACT');
export const change_cloudsign_document_id = actionCreator<any>('CHANGE_CLOUDSIGN_DOCUMENT_ID');
export const change_application_qr = actionCreator<any>('CHANGE_APPLICATION_QR');
export const change_transfer_qr = actionCreator<any>('CHANGE_TRANSFER_QR');
export const change_send_complete = actionCreator<any>('CHANGE_SEND_COMPLETE');
export const change_send_complete_text = actionCreator<any>('CHANGE_SEND_COMPLETE_TEXT');

export const update_rent_contracts_zenhoren_apply = actionCreator<any>('UPDATE_RENT_CONTRACTS_ZENHOREN_APPLY');
export const change_zenhoren_file = actionCreator<any>('CHANGE_ZENHOREN_FILE');
export const change_zenhoren_result_modal = actionCreator<boolean>('CHANGE_ZENHOREN_RESULT_MODAL');

export const insert_rent_contract_workflow = actionCreator<any>('INSERT_RENT_CONTRACT_WORKFLOW');
export const change_rent_contract_send_status = actionCreator<any>('CHANGE_RENT_CONTRACT_SEND_STATUS');
export const update_rent_contract_workflow_manager = actionCreator<any>('UPDATE_RENT_CONTRACT_WORKFLOW_MANAGER');
export const update_rent_contract_workflow_pm = actionCreator<any>('UPDATE_RENT_CONTRACT_WORKFLOW_PM');
export const change_back_drop_contract_mail = actionCreator<any>('CHANGE_BACK_DROP_CONTRACT_MAIL');
export const contract_file_upload = actionCreator<any>('CHANGE_CONTRACT_FILE_UPLOAD');
export const contract_AddFiles = actionCreator<any>('CONTRACT_ADDFILES');
export const contract_DelFiles = actionCreator<any>('CONTRACT_DELFILES');


// export const search_contract_follow_list = actionCreator<any>('SEARCH_CONTRACT_FOLLOW_LIST');


// export const change_contract_follow_list_form = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_LIST_FORM');

// export const change_contract_follow_editer = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_EDITER');


export const contractsReducer = reducerWithInitialState(initialState)
  // .case(change_response_id, (state, payload) => ({ ...state, response_id: payload }))
  .case(change_contracts_rent_application_doc_uri, (state, payload) => ({ ...state, contracts_rent_application_doc_uri: payload }))
  .case(change_rent_contract_details, (state, payload) => ({ ...state, rent_contract_details: payload }))
  .case(change_rent_contract_details_old, (state, payload) => ({ ...state, rent_contract_details_old: payload }))
  .case(change_rent_contract_details_new, (state, payload) => ({ ...state, rent_contract_details_new: payload }))
  .case(change_rent_contract_id, (state, payload) => ({ ...state, rent_contract_id: payload }))
  .case(change_rent_contract_logs, (state, payload) => ({ ...state, rent_contract_logs: payload }))
  .case(change_rent_contract_logs_new, (state, payload) => ({ ...state, rent_contract_logs_new: payload }))
  .case(change_rent_contract_logs_old, (state, payload) => ({ ...state, rent_contract_logs_old: payload }))
  .case(change_rent_contract_boshu_chinryou, (state, payload) => ({ ...state, rent_contract_boshu_chinryou: payload }))
  .case(change_check_contract_sheet, (state, payload) => ({ ...state, check_contract_sheet: payload }))
  .case(change_contract_upload_files_modal, (state, payload) => ({ ...state, contract_upload_files_modal: payload }))
  .case(change_check_contract_send_modal, (state, payload) => ({ ...state, check_contract_send_modal: payload }))
  .case(change_check_contract_send_files, (state, payload) => ({ ...state, check_contract_send_files: payload }))
  .case(change_check_contract_send_mail, (state, payload) => ({ ...state, check_contract_send_mail: payload }))
  .case(change_check_contract_send_mail_confirm, (state, payload) => ({ ...state, check_contract_send_mail_confirm: payload }))
  .case(change_check_contract_send, (state, payload) => ({ ...state, check_contract_send: payload }))
  .case(change_check_contract_send_mail_template, (state, payload) => ({ ...state, check_contract_send_mail_template: payload }))

  .case(change_rent_contract_editor_disp, (state, payload) => ({ ...state, rent_contract_editor_disp: payload }))
  .case(change_rent_emergency_editor_disp, (state, payload) => ({ ...state, rent_emergency_editor_disp: payload }))
  .case(change_rent_customer_editor_disp, (state, payload) => ({ ...state, rent_customer_editor_disp: payload }))
  .case(change_rent_customer_editor_forms, (state, payload) => ({ ...state, rent_customer_editor_forms: payload }))
  .case(set_rent_contract_editor_forms, (state, payload) => ({ ...state, rent_contract_editor_forms: payload }))

  .case(change_tm_tatemono_list_loading, (state, payload) => ({ ...state, tm_tatemono_list_loading: payload }))
  .case(change_tm_tatemono_list, (state, payload) => ({ ...state, tm_tatemono_list: payload }))

  .case(change_rent_m_corporates, (state, payload) => ({ ...state, rent_m_corporates: payload }))
  .case(change_rent_m_corporates_loading, (state, payload) => ({ ...state, rent_m_corporates_loading: payload }))
  .case(change_rent_m_corporate_editor, (state, payload) => ({ ...state, rent_m_corporate_editor: payload }))

  .case(change_rent_m_agents, (state, payload) => ({ ...state, rent_m_agents: payload }))
  .case(change_rent_m_reasons, (state, payload) => ({ ...state, rent_m_reasons: payload }))
  .case(change_rent_m_schools, (state, payload) => ({ ...state, rent_m_schools: payload }))
  .case(change_rent_contract_req_editor_forms, (state, payload) => ({ ...state, rent_contract_req_editor_forms: payload }))

  .case(change_tm_kanri_corp_list, (state, payload) => ({ ...state, tm_kanri_corp_list: payload }))

  .case(change_tm_room_list, (state, payload) => ({ ...state, tm_room_list: payload }))

  .case(change_m_penalties, (state, payload) => ({ ...state, m_penalties: payload }))
  .case(change_rent_m_contract_types, (state, payload) => ({ ...state, rent_m_contract_types: payload }))


  .case(change_rent_m_traders, (state, payload) => ({ ...state, rent_m_traders: payload }))

  .case(change_rent_contract_etc_editor_disp, (state, payload) => ({ ...state, rent_contract_etc_editor_disp: payload }))
  .case(change_rent_contract_special_editor_disp, (state, payload) => ({ ...state, rent_contract_special_editor_disp: payload }))

  .case(change_rent_contract_ippan_editor_disp, (state, payload) => ({ ...state, rent_contract_ippan_editor_disp: payload }))
  .case(change_rent_contract_car_editor_disp, (state, payload) => ({ ...state, rent_contract_car_editor_disp: payload }))
  .case(change_rent_contract_family_editor_disp, (state, payload) => ({ ...state, rent_contract_family_editor_disp: payload }))
  .case(change_t_parking_keiyaku_list, (state, payload) => ({ ...state, t_parking_keiyaku_list: payload }))
  .case(change_tm_parkingarea_list, (state, payload) => ({ ...state, tm_parkingarea_list: payload }))
  .case(get_standard_tm_parkingarea_list, (state, payload) => ({ ...state, standard_tm_parkingarea_list: payload }))
  .case(change_tm_parkingarea_select_section, (state, payload) => ({ ...state, tm_parkingarea_select_section: payload }))

  .case(change_rent_contract_checks, (state, payload) => ({ ...state, rent_contract_checks: payload }))


  .case(change_contract_check_message, (state, payload) => ({ ...state, contract_check_message: payload }))

  .case(change_rent_m_money_templates, (state, payload) => ({ ...state, rent_m_money_templates: payload }))

  .case(change_rent_contract_families, (state, payload) => ({ ...state, rent_contract_families: payload }))
  .case(change_rent_contract_families_disp, (state, payload) => ({ ...state, rent_contract_families_disp: payload }))

  .case(change_rent_m_specials, (state, payload) => ({ ...state, rent_m_specials: payload }))


  .case(change_contracts_backdrop, (state, payload) => ({ ...state, contracts_backdrop: payload }))

  .case(change_contracts_doc_extension, (state, payload) => ({ ...state, contracts_doc_extension: payload }))
  .case(change_rent_m_relations, (state, payload) => ({ ...state, rent_m_relations: payload }))
  .case(change_rent_m_job_categorys, (state, payload) => ({ ...state, rent_m_job_categorys: payload }))

  .case(change_contracts_cancel_confirm, (state, payload) => ({ ...state, contracts_cancel_confirm: payload }))
  .case(change_rent_contract_workflows, (state, payload) => ({ ...state, rent_contract_workflows: payload }))
  .case(change_check_contract_sheet_file, (state, payload) => ({ ...state, check_contract_sheet_file: payload }))
  .case(change_check_contract_button, (state, payload) => ({ ...state, check_contract_button: payload }))
  .case(change_check_contract_sheet_text, (state, payload) => ({ ...state, check_contract_sheet_text: payload }))

  .case(change_file_viewer_dialog_disp, (state, payload) => ({ ...state, file_viewer_dialog_disp: payload }))
  .case(change_electronic_contract, (state, payload) => ({ ...state, electronic_contract: payload }))
  .case(change_cloudsign_document_id, (state, payload) => ({ ...state, cloudsign_document_id: payload }))
  .case(change_application_qr, (state, payload) => ({ ...state, application_qr: payload }))
  .case(change_transfer_qr, (state, payload) => ({ ...state, transfer_qr: payload }))
  .case(change_send_complete, (state, payload) => ({ ...state, send_complete: payload }))
  .case(change_send_complete_text, (state, payload) => ({ ...state, send_complete_text: payload }))

  .case(change_zenhoren_file, (state, payload) => ({ ...state, zenhoren_file: payload }))
  .case(change_zenhoren_result_modal, (state, payload) => ({ ...state, zenhoren_result_modal: payload }))

  .case(change_rent_contract_send_status, (state, payload) => ({ ...state, rent_contract_send_status: payload }))
  .case(change_back_drop_contract_mail, (state, payload) => ({ ...state, back_drop_contract_mail: payload }))

// // .case(change_contract_count, (state, payload) => ({ ...state, contract_count: payload }))
// .case(change_contract_follow_list_form, (state, payload) => ({ ...state, contract_follow_list_form: payload }))

// .case(change_rent_m_contract_follows, (state, payload) => ({ ...state, rent_m_contract_follows: payload }))

// .case(change_contract_follow_editer, (state, payload) => ({ ...state, contract_follow_editer: payload }))

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const contractsSaga = [

  takeEvery('DOWNLOAD_CONTRACTS_RENT_APPLICATION_DOC', handle_download_contracts_rent_application_doc),
  takeEvery('GET_SENDMAIL_FILES', handle_get_sendmail_files),
  takeEvery('GET_RENT_CONTRACT_DETAILS', handle_get_rent_contract_details),
  takeEvery('CHANGE_RENT_CONTRACT_LOGS_VER', handle_change_rent_contract_logs_ver),
  takeEvery('GET_RENT_CUSTOMER_ZIP_ADDRESS', handle_get_rent_customer_zip_address),
  takeEvery('GET_RENT_M_CORPORATES', handle_get_rent_m_corporates),
  takeEvery('GET_TM_TATEMONO_LIST', handle_get_tm_tatemono_list),

  takeEvery('GET_RENT_M_REASONS', handle_get_rent_m_reasons),
  takeEvery('GET_RENT_M_SCHOOLS', handle_get_rent_m_schools),
  takeEvery('GET_RENT_M_AGENTS', handle_get_rent_m_agents),


  takeEvery('CHECK_RENT_CONTRACT_EDITOR_DISP', handle_check_rent_contract_editor_disp),
  takeEvery('CHECK_RENT_EMERGENCY_EDITOR_DISP', handle_check_rent_emergency_editor_disp),

  takeEvery('GET_TM_KANRI_CORP_LIST', handle_get_tm_kanri_corp_list),

  takeEvery('GET_TM_ROOM_LIST', handle_get_tm_room_list),

  takeEvery('GET_M_PENALTIES', handle_get_m_penalties),
  takeEvery('GET_RENT_M_CONTRACT_TYPES', handle_get_rent_m_contract_types),

  takeEvery('GET_RENT_M_TRADERS', handle_get_rent_m_traders),

  // takeEvery('UPDATE_RENT_CUSTOMERS', handle_update_rent_customers),

  takeEvery('UPDATE_RENT_CONTRACTS', handle_update_rent_contracts),

  takeEvery('UPDATE_RENT_CONTRACT_WORKFLOWS', handle_update_rent_contract_workflows),
  takeEvery('GET_RENT_CONTRACT_WORKFLOWS', handle_get_rent_contract_workflows),


  takeEvery('GET_T_PARKING_KEIYAKU_LIST', handle_get_t_parking_keiyaku_list),

  takeEvery('CHECK_RENT_CONTRACT_REQ_EDITOR_FORMS', handle_check_rent_contract_editor_forms),


  takeEvery('GET_RENT_M_MONEY_TEMPLATES', handle_get_rent_m_money_templates),

  takeEvery('SET_CONTRACTS_BOSHU_CHINRYOU', handle_set_contracts_boshu_chinryou),

  takeEvery('CHANGE_RENT_CONTRACT_EDITOR_FORMS', handle_change_rent_contract_editor_forms),

  takeEvery('UPDATE_RENT_CONTRACT_FAMILIES', handle_update_rent_contract_families),
  takeEvery('GET_RENT_CONTRACT_FAMILIES', handle_get_rent_contract_families),

  takeEvery('GET_RENT_M_SPECIALS', handle_get_rent_m_specials),


  takeEvery('UPDATE_CARS', handle_update_cars),


  takeEvery('CONTRACTS_SEND_PARKING', handle_contracts_send_parking),
  takeEvery('CONTRACTS_SEND_APPLICATION_MAIL', handle_contracts_send_application_mail),
  takeEvery('CREATE_SEND_FORM', handle_create_send_form),
  takeEvery('UPDATE_SEND_FORM', handle_update_send_form),
  takeEvery('CONTRACTS_RESEND_PARKING', handle_contracts_resend_parking),

  takeEvery('GET_TM_PARKINGAREA_LIST', handle_get_tm_parkingarea_list),
  //   takeEvery('CHANGE_TM_PARKINGAREA_SELECT_SECTION',handle_change_tm_parkingarea_select_section),

  takeEvery('GET_RENT_M_RELATIONS', handle_get_rent_m_relations),
  takeEvery('GET_RENT_M_JOB_CATEGORYS', handle_get_rent_m_job_categorys),

  takeEvery('GET_TM_PARKINGAREA_DETAIL_LIST', handle_get_tm_parkingarea_detail_list),


  takeEvery('DOWNLOAD_DOCS', handle_download_docs),
  takeEvery('DOWNLOAD_BILL', handle_download_bill),
  takeEvery('DOWNLOAD_CONTRACT', handle_download_contract),
  takeEvery('DOWNLOAD_DISCLOSURE_STATEMENT', handle_download_disclosure_statement),
  takeEvery('DOWNLOAD_LIFELINE_SHEET', handle_download_lifeline_sheet),
  takeEvery('DOWNLOAD_JIPPI', handle_download_jippi),
  takeEvery('DOWNLOAD_SHIKIBIKI', handle_download_shikibiki),
  takeEvery('DOWNLOAD_KAKETSUKE', handle_download_kaketsuke),
  takeEvery('DOWNLOAD_KADEN', handle_download_kaden),
  takeEvery('DOWNLOAD_KADEN_SHIYOU', handle_download_kadenShiyou),
  takeEvery('DOWNLOAD_JOUSUIKI', handle_download_jousuiki),
  takeEvery('DOWNLOAD_HAZARD', handle_download_hazard),
  takeEvery('DOWNLOAD_MIMAMORI', handle_download_mimamori),


  takeEvery('CONTRACTS_CANCEL', handle_contracts_cancel),
  takeEvery('RESEND_CONTRACT_SHEET', handle_resend_contract_sheet),
  takeEvery('CONTRACTS_SHEET_CHECK', handle_contracts_sheet_check),
  takeEvery('CONTRACTS_SHEET_CORP', handle_contracts_sheet_corp),
  takeEvery('CHANGE_HAZARDMAP', handle_change_hazardmap),


  takeEvery('SET_RENT_CONTRACT_CHECKS', handle_set_rent_contract_checks),
  takeEvery('GET_ELECTRONIC_CONTRACT', handle_get_electronic_contract),
  takeEvery('ELECTRONIC_CONTRACT_CANCEL', handle_electronic_contract_cancel),
  takeEvery('CHECK_SEND_FILES', handle_get_check_send_files),
  takeEvery('CHECK_ELECTRONIC_CONTRACT', handle_check_electronic_contract),
  takeEvery('REMIND_ELECTRONIC_CONTRACT', handle_remind_electronic_contract),

  takeEvery('UPDATE_RENT_CONTRACTS_ZENHOREN_APPLY', handle_update_rent_contracts_zenhoren_apply),
  takeEvery('INSERT_RENT_CONTRACT_WORKFLOW', handle_insert_rent_contract_workflow),
  takeEvery('UPDATE_RENT_CONTRACT_WORKFLOW_MANAGER', handle_update_rent_contract_workflow_manager),
  takeEvery('UPDATE_RENT_CONTRACT_WORKFLOW_PM', handle_update_rent_contract_workflow_pm),
  takeEvery('CHANGE_SETTLEMENT_LOCK', handle_settlement_lock),
  takeEvery('CHANGE_CONTRACT_FILE_UPLOAD', handle_contract_file_upload),
  takeEvery('CONTRACT_ADDFILES', handle_contract_AddFiles),
  takeEvery('CONTRACT_DELFILES', handle_contract_DelFiles),
  // takeEvery('SEARCH_CONTRACT_FOLLOW_LIST', handle_search_contract_follow_list),

];
// export default responsesSaga;



function* handle_download_contracts_rent_application_doc(action: any): any {
  // yield put(change_line_files_loading(true));
  const state = yield select();

  const { payload, error } = yield call(rent_application_docs_api.download, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // yield put(change_line_file_data(null));
    // 成功時アクション呼び出し
    console.log(payload)
    // yield put(change_response_file_update_disp(false));
    // yield put(change_response_files_loading(false));
    // const a = document.createElement('a');
    // // // ダウンロードされるファイル名
    // // a.download = action.payload.message_text;
    // a.download = "10.pdf";
    // a.href = URL.createObjectURL(payload);
    // // // ダウンロード開始
    // a.click();
    yield put(change_contracts_rent_application_doc_uri(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}


function* handle_create_send_form(action: any): any {
  const state = yield select();
  yield put(change_contracts_backdrop(true));
  const { payload, error } = yield call(rent_contracts_api.createdForm, state.contracts.rent_contract_details.id);
  if (payload && !error) {
    if (state.contracts.rent_contract_details.contract_code) {
      var qr_url = ''
      if (payload.date && payload.pass) {
        qr_url = 'https://rent.global-center.co.jp/mypage/apply/' + state.contracts.rent_contract_details.contract_code
        const application_qr = yield call(laravel_api.get_QR, qr_url);
        if (application_qr.payload && !application_qr.error) {
          yield put(change_application_qr(application_qr.payload));
        }
      }
      var qr_url1 = 'https://rent.global-center.co.jp/mypage/transfer/' + state.contracts.rent_contract_details.contract_code
      const transfer_qr = yield call(laravel_api.get_QR, qr_url1);
      if (transfer_qr.payload && !transfer_qr.error) {
        yield put(change_transfer_qr(transfer_qr.payload));
      }
    }
    var rent_contract_details = state.contracts.rent_contract_details;
    yield put(change_rent_contract_details({}));
    rent_contract_details.application_limit_at = payload.date
    rent_contract_details.application_password = payload.pass
    yield put(change_rent_contract_details(rent_contract_details));
    yield put(change_contracts_backdrop(false));
  }

}
function* handle_update_send_form(action: any): any {
  // const { payload, error } = yield call(laravel_api.get_QR, action.payload);
  const state = yield select();
  yield put(change_contracts_backdrop(true));
  const { payload, error } = yield call(rent_contracts_api.formUpdate, { id: state.contracts.rent_contract_details.id, change: action.payload });
  if (payload && !error) {
    if (state.contracts.rent_contract_details.contract_code) {
      var qr_url = ''
      if (payload.date && payload.pass) {
        qr_url = 'https://rent.global-center.co.jp/mypage/apply/' + state.contracts.rent_contract_details.contract_code
        const application_qr = yield call(laravel_api.get_QR, qr_url);
        if (application_qr.payload && !application_qr.error) {
          yield put(change_application_qr(application_qr.payload));
        }
      }
      var qr_url1 = 'https://rent.global-center.co.jp/mypage/transfer/' + state.contracts.rent_contract_details.contract_code
      const transfer_qr = yield call(laravel_api.get_QR, qr_url1);
      if (transfer_qr.payload && !transfer_qr.error) {
        yield put(change_transfer_qr(transfer_qr.payload));
      }
    }
    var rent_contract_details = state.contracts.rent_contract_details;
    yield put(change_rent_contract_details({}));
    rent_contract_details.application_limit_at = payload.date
    rent_contract_details.application_password = payload.pass
    yield put(change_rent_contract_details(rent_contract_details));

    yield put(change_contracts_backdrop(false));
  }
}
function* handle_contracts_send_application_mail(action: any): any {
  const state = yield select();
  var rent_response_id = state.contracts.rent_contract_details.rent_response_id
  yield put(Mail.change_mail_new_template({ rent_response_id: rent_response_id, mail_template_id: 329 }))
}

function* handle_contracts_send_parking(action: any): any {

  //   // yield put(change_line_files_loading(true));
  const state = yield select();
  console.log(state.contratcs)
  //   yield put(change_contracts_backdrop(true));
  //   let rent_contract_details = state.contracts.rent_contract_details
  //   const { payload, error } = yield call(contractsheet_api.send, action.payload);

  //   yield put(change_rent_contract_details({}));
  //   // rent_contract_details.counting = 2
  //   // yield put(change_rent_contract_details(rent_contract_details));
  //   // サーバーからのレスポンスデータによる分岐処理
  //   if (payload && !error) {
  //     // yield put(change_line_file_data(null));
  //     // 成功時アクション呼び出し
  //     console.log(payload)

  //     rent_contract_details.counting = 2
  //     yield put(change_rent_contract_details(rent_contract_details));
  //     yield put(change_contracts_backdrop(false));
  //     // yield put(change_contracts_rent_application_doc_uri(payload));

  //   } else {
  //   }
  var end = true
  const { payload, error } = yield call(contractsheet_api.CreateContractFile, { id: state.contracts.rent_contract_details.id/*, user_id: state.masterReducer.user_details.id*/ })
  if (payload && !error) {
    console.log(payload)
    var payload2 = payload
    var check_contract_sheet_file = []
    for (let i = 0; i < payload2.length; i++) {

      const { payload, error } = yield call(contractsheet_api.ResendContract, { id: payload2[i].rent_contract_id })
      if (payload && !error) {
        // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
        check_contract_sheet_file[i] = payload2[i]
        check_contract_sheet_file[i].file = payload
        check_contract_sheet_file[i].file_name = payload2[i].rent_contract_id
        yield put(change_check_contract_sheet_file(check_contract_sheet_file))
      } else {
        end = false
        yield put(change_check_contract_button(false));
        alert(error)
      }
    }
    yield put(change_check_contract_sheet(end));
    yield put(change_check_contract_button(false));
  } else {
    alert(error)
  }
}


function* handle_contracts_resend_parking(action: any): any {
  const state = yield select();
  // yield put(Mail.change_mail_new(action.payload.maildetail))
  if (action.payload.payment == 0) {
    //カード再登録ページ再送
    yield put(Mail.change_mail_new_template({ rent_response_id: action.payload.responses_id, mail_template_id: 329 }))
  } else if (action.payload.payment == 1) {
    const { payload, error } = yield call(MailsApi.newmailtemplate, { rent_response_id: action.payload.responses_id, mail_template_id: 331 });
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      console.log(payload)
      // 成功時アクション呼び出し
      let sendmaildetail = {
        attachmentInfo: payload.attachment_info,
        attachments: payload.attachments,
        bcc: payload.bcc,
        body: payload.body,
        bodyUtf8: payload.bodyUtf8,
        cc: payload.cc,
        char: payload.char,
        charsets: payload.charsets,
        contentIds: payload.content_ids,
        date: payload.date,
        dateBinary: payload.date_binary,
        dkim: payload.dkim,
        draftjs: payload.draftjs,
        envelope: payload.envelope,
        fromMail: payload.from_mail,
        fromName: payload.from_name,
        headers: payload.headers,
        html: payload.html,
        htmlUtf8: payload.html_utf8,
        id: payload.id,
        isDeleted: payload.is_deleted,
        isSended: payload.is_sended,
        mailAccountId: payload.mail_account_id,
        openedAt: payload.opened_at,
        rentCustomerId: payload.rent_customer_id,
        rentResponseId: payload.rent_response_id,
        requestContactWay: payload.request_contact_way,
        requestContents: payload.request_contents,
        requestCustomerAddress: payload.request_customer_address,
        requestCustomerFax: payload.request_customer_fax,
        requestCustomerMail: payload.request_customer_mail,
        requestCustomerName: payload.request_customer_name,
        requestCustomerNameKana: payload.request_customer_name_kana,
        requestCustomerPost: payload.request_customer_post,
        requestCustomerTel: payload.request_customer_tel,
        requestRentMMediaId: payload.request_rent_m_media_id,
        requestRoomArea: payload.request_room_area,
        requestRoomChinryou: payload.request_room_chinryou,
        requestRoomChoiki: payload.request_room_choiki,
        requestRoomId: payload.request_room_id,
        requestRoomKyouekihi: payload.request_room_kyouekihi,
        requestRoomMadoriNo: payload.request_room_madori_no,
        requestRoomName: payload.request_room_name,
        requestRoomReikin: payload.request_room_reikin,
        requestRoomSetsubi: payload.request_room_setsubi,
        requestRoomShikikin: payload.request_room_shikikin,
        requestRoomShunkouMonth: payload.request_room_shunkou_month,
        requestRoomShunkouYear: payload.request_room_shunkou_year,
        requestTatemonoId: payload.request_tatemono_id,
        requestTatemonoName: payload.request_tatemono_name,
        returnedMailId: payload.returned_mail_id,
        sendIntendAt: payload.send_intend_at,
        senderIp: payload.sender_ip,
        spamReport: payload.spam_report,
        spamScore: payload.spam_score,
        spf: payload.spf,
        subject: payload.subject,
        toMail: payload.to_mail,
        toMailall: payload.to_mailall,
        toName: payload.to_name,
      }

      yield put(Mail.change_mail_new_success(1))
      const { payload1, error1 } = yield call(tm_parkingarea_api.ResendInvoice, { id: state.contracts.rent_contract_details.id, mailId: payload.id })
      yield put(Mail.change_send_mail_details(sendmaildetail))
      yield put(Mail.get_response_mails(action.payload.rent_response_id))
      console.log("OK？")
      // const mailaddress = payload.map((item:any, i:number) => ({
      //     name: item.address,
      //   }))

      yield put(Mail.get_mail_detail_files(sendmaildetail.id))
      //請求書再送
      yield put(Mail.change_get_mail_detail_files_loading(1))
      yield put(Mail.get_mail_detail_files_success([]))
      if (payload1 && error1) {
        yield put(Mail.get_mail_detail_files_success(payload1))
        yield put(Mail.change_get_mail_detail_files_loading(0))
      }
    } else {
      // yield put(customerDetail0Failure(payload))
      // yield put(change_customerloding0(1))
    }
  } else if (action.payload.payment == 2) {
    //カード再送
    yield put(Mail.change_mail_new_template({ rent_response_id: action.payload.responses_id, mail_template_id: 327 }))
  } else if (action.payload.payment == 3) {
    //口振再送
    yield put(Mail.change_mail_new_template({ rent_response_id: action.payload.responses_id, mail_template_id: 330 }))
  }
  else if (action.payload.payment == 4) {
    //契約書再送
    yield put(change_check_contract_button(true))
    var end = true
    const { payload, error } = yield call(contractsheet_api.CreateContractFile, { id: state.contracts.rent_contract_details.id, user_id: state.masterReducer.user_details.id })
    if (payload && !error) {
      var payload2 = payload
      var check_contract_sheet_file = []
      for (let i = 0; i < payload.length; i++) {

        const { payload, error } = yield call(contractsheet_api.ResendContract, { id: state.contracts.rent_contract_details.id })
        if (payload && !error) {
          // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
          check_contract_sheet_file[i] = payload2[i]
          check_contract_sheet_file[i].file = payload
          check_contract_sheet_file[i].file_name = state.contracts.rent_contract_details.id
          check_contract_sheet_file[i].type = ''
          yield put(change_check_contract_sheet_file(check_contract_sheet_file))
        } else {
          end = false
          yield put(change_check_contract_button(false));
          alert(error)
        }
      }
      yield put(change_check_contract_sheet(end));
      yield put(change_check_contract_button(false));
    } else {
      alert(error)
    }
  }
}


function* handle_resend_contract_sheet(action: any): any {
  const state = yield select();　　// store.getState()と同じ
  yield put(change_contracts_backdrop(true));
  var file = state.contracts.check_contract_sheet_file
  console.log(file)
  var mail = {}
  if(state.contracts.check_contract_send){
      mail = state.contracts.check_contract_send_mail_confirm
      file = file.concat(state.contracts.check_contract_send_files)
      console.log(mail,"メール！")
      console.log(file,"ファイル！")
  }
  //   var files = file.map(function (value: any) {
  //     var x = { rent_contract_id: value.rent_contract_id, rent_contract_document_id: value.rent_contract_document_id ,files}
  //     return x
  //   })
  // 成功時アクション呼び出し
  //契約書送信APIうごかすやつ
  const { payload, error } = yield call(contractsheet_api.ConfirmationSendContracts, { files: file, text: state.contracts.check_contract_sheet_text, user_id: state.masterReducer.user_details.id, mail:mail });
  if (payload && !error) {
    yield put(change_contracts_backdrop(false));
    if(payload != 'error'){
        yield put(change_send_complete_text({ header: '契約書送信完了', body: '電子契約を送信しました。' }));
        yield put(change_cloudsign_document_id(payload));
    }else{
        yield put(change_send_complete_text({ header: '契約書送信失敗', body: 'エラーが発生しました。' }));
    }
    yield put(change_send_complete(true));
    yield put(change_check_contract_sheet(false));
    // yield put(Mail.change_mail_new_template({rent_response_id:action.payload.responses_id,mail_template_id:332}));
  } else {
  }
}



function* handle_get_rent_contract_details(action: any): any {


  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let rent_contract_id = 0
  if (action.payload == 18 || action.payload == 19 || !action.payload) {
    if (state.contracts.rent_contract_id == 18 || state.contracts.rent_contract_id == 19 || !state.contracts.rent_contract_id) {
      rent_contract_id = action.payload
    } else {
      rent_contract_id = state.contracts.rent_contract_id
    }
  } else {
    rent_contract_id = action.payload
  }

  const rent_contract_id_const = rent_contract_id
  console.log(state.contracts.rent_contract_id)
  // alert(state.responsesReducer.responsedetail.id)
  // alert(rent_contract_id)
  const { payload, error } = yield call(rent_contracts_api.details, { rent_contract_id: rent_contract_id, rent_response_id: state.responsesReducer.responsedetail.id });

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    console.log(payload)
    // alert()
    // alert(JSON.stringify(payload))
    yield put(change_rent_contract_logs(payload.logs));
    yield put(change_rent_contract_details(payload.current));
    yield put(get_electronic_contract(payload.current.id));

    // alert(payload.current.counting)
    if (payload.current.counting == 0 || payload.current.counting == 1) {
      yield put(get_tm_room_list(payload.current.tatemono_id));
    } else {
      yield put(get_tm_parkingarea_list(payload.current.tatemono_id));
    }

    yield put(get_rent_contract_families(rent_contract_id_const));

    yield put(change_rent_contract_boshu_chinryou(payload.chinryou));

    yield put(change_rent_contract_checks(payload.rent_contract_checks));

    yield put(get_rent_contract_workflows(payload.current.workflow_id ? payload.current.workflow_id : 0));

    if (payload.current.ver > 0) {
      // alert(payload.current.ver)
      yield put(change_rent_contract_logs_new(payload.current.ver));
      yield put(change_rent_contract_logs_old(payload.current.log_confirm));
      yield put(change_rent_contract_details_new(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.ver)[0]));
      yield put(change_rent_contract_details_old(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] ? payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] : payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.ver)[0]));
    } else {

      yield put(change_rent_contract_details_new(payload.logs[0]));
      yield put(change_rent_contract_details_old(payload.logs[0]));
    }
    // alert(payload.current.rent_response_id)
    yield put(Mail.get_response_mails(payload.current.rent_response_id));
    yield put(CustomerContacts.get_rent_customer_contacts(payload.current.rent_response_id));
    yield put(Files.get_files(payload.current.rent_response_id));


    let rent_contract_id = state.contracts.rent_contract_id
    // yield put(search_contract_follow_list(payload));

    yield put(download_contracts_rent_application_doc(payload.logs[0].main_rent_application_doc_id));
    let responsedetail = state.responsesReducer.responsedetail
    if (!responsedetail.id) {
      // alert(payload.current.rent_response_id)
      // const responsesSearch = props.responsesSearch ? props.responsesSearch : false;
      let response: any = { rent_response_id: 0, responses_search: false };
      response.rent_response_id = payload.current.rent_response_id;

      // alert(payload.current.rent_customer_id)
      yield put(Responses.responsedetailData(response));
      // yield put(Line.get_line_user_details_response(payload.current.rent_response_id));

    }
    console.log(payload, "ぺいろーど")
    if (payload.current.contract_code) {
      var qr_url = ''
      if (payload.current.application_limit_at && payload.current.application_password) {
        qr_url = 'https://rent.global-center.co.jp/mypage/apply/' + payload.current.contract_code
        const application_qr = yield call(laravel_api.get_QR, qr_url);
        if (application_qr.payload && !application_qr.error) {
          yield put(change_application_qr(application_qr.payload));
        }
      }
      var qr_url1 = 'https://rent.global-center.co.jp/mypage/transfer/' + payload.current.contract_code

      const transfer_qr = yield call(laravel_api.get_QR, qr_url1);
      console.log(transfer_qr)
      if (transfer_qr.payload && !transfer_qr.error) {
        yield put(change_transfer_qr(transfer_qr.payload));
      }
    }
    if (!payload.current.id || payload.current.id == 18 || payload.current.id == 19) {

      // yield put(check_rent_contract_editor_disp(true));
      // yield put(change_rent_contract_editor_disp(true));
      yield put(check_rent_contract_editor_disp(({ editor: "rent_contract_editor_disp", disp: true })));
    }

    yield put(change_rent_contract_send_status({ rent_contract_id: "", send_section_id: "", status: 0, cancel_remarks: "" }));
  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}

function* handle_change_rent_contract_logs_ver(action: any): any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し
  let rent_contract_logs = state.contracts.rent_contract_logs
  let rent_contract_logs_new = state.contracts.rent_contract_logs_new
  let rent_contract_logs_old = state.contracts.rent_contract_logs_old

  yield put(change_rent_contract_details_new(rent_contract_logs.filter((a: any) => (a.workflow_count + 1) === rent_contract_logs_new)[0]));
  yield put(change_rent_contract_details_old(rent_contract_logs.filter((a: any) => (a.workflow_count + 1) === rent_contract_logs_old)[0]));

}




function* handle_get_rent_customer_zip_address(action: any): any {



  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let rent_customer_editor_forms = state.contracts.rent_customer_editor_forms
  const { payload, error } = yield call(tm_zip_address_api.address, rent_customer_editor_forms.post);


  yield put(change_rent_customer_editor_forms({}));
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    console.log(payload)


    if (payload.todoufuken_name) {
      rent_customer_editor_forms.add1 = payload.todoufuken_name + payload.shikuchouson_name + payload.chouiki_name;
    }

    yield put(change_rent_customer_editor_forms(rent_customer_editor_forms));





  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}




function* handle_get_rent_m_corporates(action: any): any {
  yield put(change_rent_m_corporates_loading(true));

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_corporates_api.list, { name: action.payload });


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)


    yield put(change_rent_m_corporates(payload));

    yield put(change_rent_m_corporates_loading(false));



  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}



function* handle_get_tm_tatemono_list(action: any): any {
  yield put(change_tm_tatemono_list_loading(true));

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(tm_tatemono_api.searchname, { tatemono_name: action.payload });


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_tm_tatemono_list(payload));

    yield put(change_tm_tatemono_list_loading(false));



  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}



function* handle_get_rent_m_reasons(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_reasons_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_reasons(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}
function* handle_get_rent_m_schools(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_schools_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_schools(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}
function* handle_get_rent_m_agents(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_agents_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_agents(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}
function* handle_get_tm_kanri_corp_list(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(tm_kanri_corp_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_tm_kanri_corp_list(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}
function* handle_get_tm_room_list(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(tm_room_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_tm_room_list(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}


function* handle_get_tm_parkingarea_list(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(tm_parkingarea_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // alert(JSON.stringify(payload))
    let parkingarea = payload.map(function (value: any) {
      let a = {
        room_id: value.pa_id,
        room_name: value.pa_name,
      }

      return a
    })
    yield put(get_standard_tm_parkingarea_list(JSON.parse(JSON.stringify(payload))));
    yield put(change_tm_room_list(parkingarea));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }
}
// function* handle_change_tm_parkingarea_select_section(action: any) {
//     console.log(action.payload)
//     const { payload, error } = yield call(tm_parkingarea_api.select_section, action.payload);
// }

function* handle_get_m_penalties(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(m_penalty_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)


    yield put(change_m_penalties(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}



function* handle_get_t_parking_keiyaku_list(action: any): any {
  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し
  // /contract/rest / parking.json'
  let rent_contract_details = state.contracts.rent_contract_details
  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  if (rent_contract_details.counting === 1) {
    const { payload, error } = yield call(t_parking_keiyaku_api.list, { tatemono_id: rent_contract_details.tatemono_id, rent_contract_id: rent_contract_details.id });
    if (payload && !error) {
      console.log(payload)

      let t_parking_keiyaku_list = payload.map(function (value: any) {
        value.contract_id = rent_contract_details.gl_contract_id
        value.rent_contract_id = rent_contract_details.id
        let shop = 0
        if (rent_contract_details.section_id === 1) {
          shop = 1
        } else if (rent_contract_details.section_id === 2) {
          shop = 2
        } else if (rent_contract_details.section_id === 3) {
          shop = 3
        } else if (rent_contract_details.section_id === 4) {
          shop = 4
        } else if (rent_contract_details.section_id === 6) {
          shop = 5
        } else if (rent_contract_details.section_id === 17) {
          shop = 6
        } else if (rent_contract_details.section_id === 47) {
          shop = 47
        } else if (rent_contract_details.section_id === 10) {
          shop = 17
        }
        value.shop = shop
        value.section_id = rent_contract_details.section_id

        if (rent_contract_details.parkings && rent_contract_details.parkings.filter((a: any) => a.p_id == value.p_id && a.pa_id == value.pa_id).length > 0) {
          value.selectcontract = true
        }

        return value
      })

      yield put(change_t_parking_keiyaku_list(t_parking_keiyaku_list));

    } else {
      // 失敗時アクション呼び出し
      // yield put(loginFailure(payload));
    }
  } else {
    const { payload, error } = yield call(tm_parkingarea_api.list, action.payload);
    if (payload && !error) {
      // console.log(payload)


      yield put(change_tm_parkingarea_list(payload));

    } else {
      // 失敗時アクション呼び出し
      // yield put(loginFailure(payload));
    }

  }

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し




}



function* handle_get_rent_m_contract_types(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_contract_types_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)


    yield put(change_rent_m_contract_types(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}






function* handle_get_rent_m_traders(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_traders_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)


    yield put(change_rent_m_traders(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}


// function* handle_update_rent_customers(action: any) {
//   const state = yield select();
//   let rent_customer_editor_forms = state.contracts.rent_customer_editor_forms
//   const { payload, error } = yield call(rent_customers_api.update, rent_customer_editor_forms);


//   // サーバーからのレスポンスデータによる分岐処理
//   //if (ログイン成功か失敗かの条件) {
//   // 成功時アクション呼び出し

//   if (payload && !error) {

//     // yield put(search_contract_follow_list(payload));

//     // yield put(download_contracts_rent_application_doc(payload.logs[0].main_rent_application_doc_id));



//   } else {
//     // 失敗時アクション呼び出し
//     // yield put(loginFailure(payload));
//   }


// }



function* handle_update_rent_contracts(action: any): any {

  const state = yield select();
  yield put(change_contracts_backdrop(true));

  let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms
  const { payload, error } = yield call(rent_contracts_api.update, rent_contract_editor_forms);
  //一旦コメントアウト(車情報アップデート)
  //   if(state.contracts.tm_parkingarea_select_section){
  //     const { payload1 , error1 } = yield call(tm_parkingarea_api.select_section, state.contracts.tm_parkingarea_select_section);
  //   }

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)

    yield put(change_rent_customer_editor_disp(false));
    yield put(change_rent_contract_editor_disp(false));
    yield put(change_rent_emergency_editor_disp(false));
    yield put(change_rent_contract_special_editor_disp(false));
    yield put(change_rent_contract_etc_editor_disp(false));
    yield put(change_rent_contract_ippan_editor_disp(false));
    // console.log(payload)
    console.log(payload)
    // alert(payload.current.ver)
    yield put(change_rent_contract_logs(payload.logs));
    yield put(change_rent_contract_details(payload.current));



    yield put(change_rent_contract_boshu_chinryou(payload.chinryou));


    if (payload.current.ver > 0) {
      // alert(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.ver)[0].length)
      yield put(change_rent_contract_logs_new(payload.current.ver));
      yield put(change_rent_contract_logs_old(payload.current.log_confirm));
      // rent_contract_logsのrent_contract_workflowsのverより1少ない　0から始まるため
      yield put(change_rent_contract_details_new(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.ver)[0]));
      yield put(change_rent_contract_details_old(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] ? payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] : payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.ver)[0]));
    } else {

      yield put(change_rent_contract_details_new(payload.logs[0]));
      yield put(change_rent_contract_details_old(payload.logs[0]));
    }

    yield put(change_contracts_backdrop(false));
    // yield put(change_rent_m_traders(payload));

  } else {
    yield put(change_contracts_backdrop(false));
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}
function* handle_get_rent_contract_workflows(action: any): any {

  const { payload, error } = yield call(rent_contract_workflows_api.workflows, action.payload);
  if (payload && !error) {
    console.log(payload, "")

    yield put(change_rent_contract_workflows(payload))
  } else {
  }
}
function* handle_update_rent_contract_workflows(action: any): any {

  // alert(action.payload)
  const state = yield select();
  yield put(change_contracts_backdrop(true));


  let rent_contract_logs_new = state.contracts.rent_contract_logs_new
  let rent_contract_logs_old = state.contracts.rent_contract_logs_old
  const { payload, error } = yield call(rent_contract_workflows_api.update, { workflow_id: action.payload, rent_contract_logs_new: rent_contract_logs_new, rent_contract_logs_old: rent_contract_logs_old });


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {

    yield put(change_contracts_backdrop(false));

    let rent_contract_logs = state.contracts.rent_contract_logs

    rent_contract_logs = rent_contract_logs.map(function (value: any, key: number) {
      if (value.log_confirm_user == 0) {
        value.log_confirm_user = state.masterReducer.user_details.id
        value.log_confirm_at = moment().format('YYYY/MM/DD HH:mm:ss')
      }
      return value
    })

    yield put(change_rent_contract_logs(rent_contract_logs));
    yield put(change_rent_contract_logs_old(state.contracts.rent_contract_logs_new));

  } else {
    yield put(change_contracts_backdrop(false));
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}




function* handle_update_cars(action: any): any {
  // alert()
  const state = yield select();
  yield put(change_contracts_backdrop(true));

  let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms

  let t_parking_keiyaku_list = state.contracts.t_parking_keiyaku_list
  let rent_contract_details = state.contracts.rent_contract_details
  // alert(t_parking_keiyaku_list.filter((a: any) => a.selectcontract).length)
  const { payload, error } = yield call(t_parking_keiyaku_api.update, { rent_contract_details: rent_contract_details, t_parking_keiyaku_list: t_parking_keiyaku_list.filter((a: any) => a.selectcontract) });

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)

    yield put(change_rent_customer_editor_disp(false));
    yield put(change_rent_contract_editor_disp(false));
    yield put(change_rent_emergency_editor_disp(false));
    yield put(change_rent_contract_special_editor_disp(false));
    yield put(change_rent_contract_etc_editor_disp(false));
    yield put(change_rent_contract_ippan_editor_disp(false));
    // console.log(payload)
    console.log(payload)

    yield put(change_rent_contract_logs(payload.logs));
    yield put(change_rent_contract_details(payload.current));



    yield put(change_rent_contract_boshu_chinryou(payload.chinryou));


    if (payload.current.ver > 0) {
      yield put(change_rent_contract_logs_new(payload.current.ver));
      yield put(change_rent_contract_logs_old(payload.current.log_confirm));
      yield put(change_rent_contract_details_new(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.ver)[0]));
      yield put(change_rent_contract_details_old(payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] ? payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.log_confirm)[0] : payload.logs.filter((a: any) => (a.workflow_count + 1) === payload.current.ver)[0]));
    } else {
      yield put(change_rent_contract_details_new(payload.logs[0]));
      yield put(change_rent_contract_details_old(payload.logs[0]));
    }

    yield put(get_t_parking_keiyaku_list([]));

    yield put(change_contracts_backdrop(false));
    // yield put(change_rent_m_traders(payload));

  } else {
    yield put(change_contracts_backdrop(false));
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}


function* handle_check_rent_contract_editor_forms(action: any): any {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  // const { payload, error } = yield call(rent_m_contract_types_api.list, action.payload)
  const state = yield select();
  const rent_contract_details = state.contracts.rent_contract_details
  let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms
  let checkset: any = {}
  checkset.rent_contract_monies = rent_contract_editor_forms.rent_contract_monies

  checkset.rent_contract_details = rent_contract_editor_forms
  checkset.master = state.masterReducer
  checkset.rent_contract_boshu_chinryou = state.contracts.rent_contract_boshu_chinryou
  // alert(JSON.stringify(checkset))
  console.log(state.contracts.rent_contract_checks)
  checkset.rent_contract_checks = state.contracts.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === rent_contract_details.rent_m_contract_type_id)

  // alert("チェック")
  let checked: any = CheckMoney.check(checkset)
  // alert(checked)



  // let checkset: any = []
  // checkset.rent_contract_monies = rent_contract_monies
  // checkset.rent_contract_details = rent_contract_details
  // checkset.master = state.masterReducer
  // checkset.rent_contract_boshu_chinryou = state.contracts.rent_contract_boshu_chinryou
  // console.log(state.contracts.rent_contract_checks)
  // checkset.rent_contract_checks = state.contracts.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === rent_contract_details.rent_m_contract_type_id)

  // let checked: any = CheckMoney.check(checkset)
  // alert("dddd")

  // console.log(checked)
  // alert(checked.contract_check_message)
  yield put(change_contract_check_message(checked.contract_check_message));

  // console.log(checked)
  // yield put(change_rent_contract_req_editor_forms(action.payload));
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し




}

function* handle_check_rent_contract_editor_disp(action: any): any {

  const state = yield select();
  const rent_contract_details = state.contracts.rent_contract_details

  let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms

  rent_contract_editor_forms.counting = rent_contract_details.counting
  rent_contract_editor_forms.rent_contract_id = rent_contract_details.id
  rent_contract_editor_forms.rent_response_id = rent_contract_details.rent_response_id
  rent_contract_editor_forms.rent_customer_id = rent_contract_details.rent_customer_id
  rent_contract_editor_forms.type = rent_contract_details.type
  rent_contract_editor_forms.name1 = rent_contract_details.name1
  rent_contract_editor_forms.kana1 = rent_contract_details.kana1
  rent_contract_editor_forms.name2 = rent_contract_details.name2
  rent_contract_editor_forms.kana2 = rent_contract_details.kana2
  rent_contract_editor_forms.sex = rent_contract_details.sex
  rent_contract_editor_forms.memo = rent_contract_details.memo
  rent_contract_editor_forms.tel1 = rent_contract_details.tel1
  rent_contract_editor_forms.tel1_remarks = rent_contract_details.tel1_remarks
  rent_contract_editor_forms.tel2 = rent_contract_details.tel2
  rent_contract_editor_forms.tel2_remarks = rent_contract_details.tel2_remarks
  rent_contract_editor_forms.fax = rent_contract_details.fax
  rent_contract_editor_forms.mail1 = rent_contract_details.mail1
  rent_contract_editor_forms.mail1_remarks = rent_contract_details.mail1_remarks
  rent_contract_editor_forms.mail2 = rent_contract_details.mail2
  rent_contract_editor_forms.mail2_remarks = rent_contract_details.mail2_remarks
  rent_contract_editor_forms.rent_m_corporate_id = rent_contract_details.rent_m_corporate_id
  rent_contract_editor_forms.office_rent_m_corporate_id = rent_contract_details.office_rent_m_corporate_id
  rent_contract_editor_forms.office_name = rent_contract_details.office_name
  rent_contract_editor_forms.post = rent_contract_details.post
  rent_contract_editor_forms.add1 = rent_contract_details.add1
  rent_contract_editor_forms.add2 = rent_contract_details.add2
  rent_contract_editor_forms.birthday = rent_contract_details.birthday
  rent_contract_editor_forms.customer_remarks = rent_contract_details.customer_remarks



  rent_contract_editor_forms.application_date = rent_contract_details.application_date
  rent_contract_editor_forms.contract_date = rent_contract_details.contract_date
  rent_contract_editor_forms.rent_m_contract_type_id = rent_contract_details.rent_m_contract_type_id


  rent_contract_editor_forms.contract_form = rent_contract_details.contract_form
  rent_contract_editor_forms.registration_tatemono = rent_contract_details.registration_tatemono
  rent_contract_editor_forms.tatemono_id = rent_contract_details.tatemono_id
  rent_contract_editor_forms.tatemono_name = rent_contract_details.tatemono_name
  rent_contract_editor_forms.room_id = rent_contract_details.room_id
  rent_contract_editor_forms.room_name = rent_contract_details.room_name
  rent_contract_editor_forms.room_id2 = rent_contract_details.room_id2
  rent_contract_editor_forms.room_name2 = rent_contract_details.room_name2
  rent_contract_editor_forms.room_id3 = rent_contract_details.room_id3
  rent_contract_editor_forms.room_name3 = rent_contract_details.room_name3
  rent_contract_editor_forms.name1 = rent_contract_details.name1
  rent_contract_editor_forms.kana1 = rent_contract_details.kana1
  rent_contract_editor_forms.name2 = rent_contract_details.name2
  rent_contract_editor_forms.kana2 = rent_contract_details.kana2
  rent_contract_editor_forms.division = rent_contract_details.division
  rent_contract_editor_forms.cosigner = rent_contract_details.cosigner
  rent_contract_editor_forms.user_id = rent_contract_details.user_id
  rent_contract_editor_forms.operation_user_id = rent_contract_details.operation_user_id
  rent_contract_editor_forms.section_id = rent_contract_details.section_id
  rent_contract_editor_forms.operation_section_id = rent_contract_details.operation_section_id
  rent_contract_editor_forms.number_of_renters = rent_contract_details.number_of_renters
  rent_contract_editor_forms.number_of_cars = rent_contract_details.number_of_cars
  rent_contract_editor_forms.rent_m_corporates_id = rent_contract_details.rent_m_corporates_id
  rent_contract_editor_forms.management_company = rent_contract_details.management_company
  let rent_contract_monies: any;

  if (rent_contract_details.rent_contract_monies) {
    rent_contract_monies = JSON.stringify(rent_contract_details.rent_contract_monies); // JSON文字列化
    rent_contract_monies = JSON.parse(rent_contract_monies); // JSON文字列化したものを戻す


    rent_contract_monies = rent_contract_monies.map(function (value: any, key: number) {
      value.key = key
      return value
    })
  } else {
    rent_contract_monies = []
  }
  rent_contract_editor_forms.rent_contract_monies = rent_contract_monies;
  rent_contract_editor_forms.pets = rent_contract_details.pets
  rent_contract_editor_forms.pet_type = rent_contract_details.pet_type
  rent_contract_editor_forms.traders = rent_contract_details.traders
  rent_contract_editor_forms.trader_etc = rent_contract_details.trader_etc

  rent_contract_editor_forms.rent_m_guarantee_id = rent_contract_details.rent_m_guarantee_id
  rent_contract_editor_forms.electronics = rent_contract_details.electronics
  rent_contract_editor_forms.special_contract_building = rent_contract_details.special_contract_building
  rent_contract_editor_forms.special_contract_conditions = rent_contract_details.special_contract_conditions
  rent_contract_editor_forms.special_contract_payoff = rent_contract_details.special_contract_payoff
  rent_contract_editor_forms.special_contract_etc = rent_contract_details.special_contract_etc
  rent_contract_editor_forms.contract_document_type = rent_contract_details.contract_document_type
  rent_contract_editor_forms.electrical_presence = rent_contract_details.electrical_presence
  rent_contract_editor_forms.owner_payment_way = rent_contract_details.owner_payment_way

  rent_contract_editor_forms.pay_nextmonth = rent_contract_details.pay_nextmonth

  rent_contract_editor_forms.ad_name = rent_contract_details.ad_name
  rent_contract_editor_forms.ad_amount = rent_contract_details.ad_amount
  rent_contract_editor_forms.ad_pay = rent_contract_details.ad_pay

  rent_contract_editor_forms.application_fee = rent_contract_details.application_fee
  rent_contract_editor_forms.application_fee_payor = rent_contract_details.application_fee_payor
  rent_contract_editor_forms.application_fee_date = rent_contract_details.application_fee_date
  rent_contract_editor_forms.billing_closing_date = rent_contract_details.billing_closing_date
  rent_contract_editor_forms.billing_closing_date_nyuukyosha = rent_contract_details.billing_closing_date_nyuukyosha
  rent_contract_editor_forms.contract_remarks = rent_contract_details.contract_remarks
  rent_contract_editor_forms.estimate_remarks = rent_contract_details.estimate_remarks

  rent_contract_editor_forms.receipt = rent_contract_details.receipt
  rent_contract_editor_forms.contract_send = rent_contract_details.contract_send
  rent_contract_editor_forms.contract_penalty = rent_contract_details.contract_penalty
  rent_contract_editor_forms.penalty_remarks = rent_contract_details.penalty_remarks
  rent_contract_editor_forms.room_penalty = rent_contract_details.room_penalty

  rent_contract_editor_forms.referral_fee = rent_contract_details.referral_fee
  rent_contract_editor_forms.referral_fee_remarks = rent_contract_details.referral_fee_remarks
  rent_contract_editor_forms.referral_fee_etc = rent_contract_details.referral_fee_etc
  rent_contract_editor_forms.referral_fee_etc_remarks = rent_contract_details.referral_fee_etc_remarks
  rent_contract_editor_forms.agent_fee = rent_contract_details.agent_fee
  rent_contract_editor_forms.agent_fee_remarks = rent_contract_details.agent_fee_remarks
  rent_contract_editor_forms.pay_brokerage_fee = rent_contract_details.pay_brokerage_fee

  rent_contract_editor_forms.pay_brokerage_fee_remarks = rent_contract_details.pay_brokerage_fee_remarks
  rent_contract_editor_forms.owner_brokerage_fee = rent_contract_details.owner_brokerage_fee
  rent_contract_editor_forms.owner_brokerage_fee_remarks = rent_contract_details.owner_brokerage_fee_remarks

  rent_contract_editor_forms.kagi_koukan_date = rent_contract_details.kagi_koukan_date
  rent_contract_editor_forms.kagi_shozai = rent_contract_details.kagi_shozai
  rent_contract_editor_forms.kagi_gyousha = rent_contract_details.kagi_gyousha
  rent_contract_editor_forms.hoshuu_date = rent_contract_details.hoshuu_date
  rent_contract_editor_forms.kouji_gyousha = rent_contract_details.kouji_gyousha
  rent_contract_editor_forms.shoudoku_date = rent_contract_details.shoudoku_date
  rent_contract_editor_forms.koukin_date = rent_contract_details.koukin_date

  rent_contract_editor_forms.edit_options = false
  rent_contract_editor_forms.hiwari_free = rent_contract_details.hiwari_free
  rent_contract_editor_forms.is_important_contract = rent_contract_details.is_important_contract


  // let family: any = JSON.stringify(rent_contract_details.family); // JSON文字列化
  // family = JSON.parse(family); // JSON文字列化したものを戻す
  // rent_contract_editor_forms.family = family


  let checkset: any = []
  checkset.rent_contract_monies = rent_contract_monies
  checkset.rent_contract_details = rent_contract_details
  checkset.master = state.masterReducer
  checkset.rent_contract_boshu_chinryou = state.contracts.rent_contract_boshu_chinryou
  console.log(state.contracts.rent_contract_checks)
  checkset.rent_contract_checks = state.contracts.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === rent_contract_details.rent_m_contract_type_id)

  let checked: any = CheckMoney.check(checkset)



  // alert("dis")
  console.log(checked)

  yield put(change_contract_check_message(checked.contract_check_message));



  yield put(change_rent_contract_editor_forms(rent_contract_editor_forms));

  if (action.payload.disp === true) {


    if (action.payload.editor === "rent_contract_editor_disp") {
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
      yield put(change_rent_contract_editor_disp(true));
    } else if (action.payload.editor === "rent_contract_special_editor_disp") {
      yield put(change_rent_contract_special_editor_disp(true));
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_contract_ippan_editor_disp") {
      yield put(change_rent_contract_ippan_editor_disp(true));
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_customer_editor_disp") {
      yield put(change_rent_customer_editor_disp(true));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_contract_etc_editor_disp") {
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(true));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_contract_family_editor_disp") {
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(true));


    }





  } else {
    yield put(change_rent_customer_editor_disp(false));
    yield put(change_rent_contract_editor_disp(false));
    yield put(change_rent_contract_special_editor_disp(false));
    yield put(change_rent_contract_etc_editor_disp(false));
    yield put(change_rent_contract_ippan_editor_disp(false));
    yield put(change_rent_contract_family_editor_disp(false));
  }

}

function* handle_check_rent_emergency_editor_disp(action: any): any {

  const state = yield select();
  const rent_contract_details = state.contracts.rent_contract_details

  let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms

  rent_contract_editor_forms.counting = rent_contract_details.counting
  rent_contract_editor_forms.rent_contract_id = rent_contract_details.id
  rent_contract_editor_forms.rent_response_id = rent_contract_details.rent_response_id
  rent_contract_editor_forms.rent_customer_id = rent_contract_details.rent_customer_id
  rent_contract_editor_forms.type = rent_contract_details.type
  rent_contract_editor_forms.name1 = rent_contract_details.name1
  rent_contract_editor_forms.kana1 = rent_contract_details.kana1
  rent_contract_editor_forms.name2 = rent_contract_details.name2
  rent_contract_editor_forms.kana2 = rent_contract_details.kana2
  rent_contract_editor_forms.sex = rent_contract_details.sex
  rent_contract_editor_forms.memo = rent_contract_details.memo
  rent_contract_editor_forms.tel1 = rent_contract_details.tel1
  rent_contract_editor_forms.tel1_remarks = rent_contract_details.tel1_remarks
  rent_contract_editor_forms.tel2 = rent_contract_details.tel2
  rent_contract_editor_forms.tel2_remarks = rent_contract_details.tel2_remarks
  rent_contract_editor_forms.fax = rent_contract_details.fax
  rent_contract_editor_forms.mail1 = rent_contract_details.mail1
  rent_contract_editor_forms.mail1_remarks = rent_contract_details.mail1_remarks
  rent_contract_editor_forms.mail2 = rent_contract_details.mail2
  rent_contract_editor_forms.mail2_remarks = rent_contract_details.mail2_remarks
  rent_contract_editor_forms.rent_m_corporate_id = rent_contract_details.rent_m_corporate_id
  rent_contract_editor_forms.office_rent_m_corporate_id = rent_contract_details.office_rent_m_corporate_id
  rent_contract_editor_forms.office_name = rent_contract_details.office_name
  rent_contract_editor_forms.post = rent_contract_details.post
  rent_contract_editor_forms.add1 = rent_contract_details.add1
  rent_contract_editor_forms.add2 = rent_contract_details.add2
  rent_contract_editor_forms.birthday = rent_contract_details.birthday
  rent_contract_editor_forms.customer_remarks = rent_contract_details.customer_remarks



  rent_contract_editor_forms.application_date = rent_contract_details.application_date
  rent_contract_editor_forms.contract_date = rent_contract_details.contract_date
  rent_contract_editor_forms.rent_m_contract_type_id = rent_contract_details.rent_m_contract_type_id
  rent_contract_editor_forms.room_penalty = rent_contract_details.room_penalty

  rent_contract_editor_forms.contract_form = rent_contract_details.contract_form
  rent_contract_editor_forms.registration_tatemono = rent_contract_details.registration_tatemono
  rent_contract_editor_forms.tatemono_id = rent_contract_details.tatemono_id
  rent_contract_editor_forms.tatemono_name = rent_contract_details.tatemono_name
  rent_contract_editor_forms.room_id = rent_contract_details.room_id
  rent_contract_editor_forms.room_name = rent_contract_details.room_name
  rent_contract_editor_forms.room_id2 = rent_contract_details.room_id2
  rent_contract_editor_forms.room_name2 = rent_contract_details.room_name2
  rent_contract_editor_forms.room_id3 = rent_contract_details.room_id3
  rent_contract_editor_forms.room_name3 = rent_contract_details.room_name3
  rent_contract_editor_forms.name1 = rent_contract_details.name1
  rent_contract_editor_forms.kana1 = rent_contract_details.kana1
  rent_contract_editor_forms.name2 = rent_contract_details.name2
  rent_contract_editor_forms.kana2 = rent_contract_details.kana2
  rent_contract_editor_forms.division = rent_contract_details.division
  rent_contract_editor_forms.cosigner = rent_contract_details.cosigner
  rent_contract_editor_forms.user_id = rent_contract_details.user_id
  rent_contract_editor_forms.operation_user_id = rent_contract_details.operation_user_id
  rent_contract_editor_forms.section_id = rent_contract_details.section_id
  rent_contract_editor_forms.operation_section_id = rent_contract_details.operation_section_id
  rent_contract_editor_forms.number_of_renters = rent_contract_details.number_of_renters
  rent_contract_editor_forms.number_of_cars = rent_contract_details.number_of_cars
  rent_contract_editor_forms.rent_m_corporates_id = rent_contract_details.rent_m_corporates_id
  rent_contract_editor_forms.management_company = rent_contract_details.management_company

  let rent_contract_monies: any = JSON.stringify(rent_contract_details.rent_contract_monies); // JSON文字列化
  rent_contract_monies = JSON.parse(rent_contract_monies); // JSON文字列化したものを戻す


  rent_contract_monies = rent_contract_monies.map(function (value: any, key: number) {
    value.key = key
    return value
  })
  rent_contract_editor_forms.rent_contract_monies = rent_contract_monies;
  rent_contract_editor_forms.pets = rent_contract_details.pets
  rent_contract_editor_forms.pet_type = rent_contract_details.pet_type
  rent_contract_editor_forms.traders = rent_contract_details.traders
  rent_contract_editor_forms.trader_etc = rent_contract_details.trader_etc

  rent_contract_editor_forms.rent_m_guarantee_id = rent_contract_details.rent_m_guarantee_id
  rent_contract_editor_forms.electronics = rent_contract_details.electronics
  rent_contract_editor_forms.special_contract_building = rent_contract_details.special_contract_building
  rent_contract_editor_forms.special_contract_conditions = rent_contract_details.special_contract_conditions
  rent_contract_editor_forms.special_contract_payoff = rent_contract_details.special_contract_payoff
  rent_contract_editor_forms.special_contract_etc = rent_contract_details.special_contract_etc
  rent_contract_editor_forms.contract_document_type = rent_contract_details.contract_document_type
  rent_contract_editor_forms.electrical_presence = rent_contract_details.electrical_presence
  rent_contract_editor_forms.owner_payment_way = rent_contract_details.owner_payment_way

  rent_contract_editor_forms.pay_nextmonth = rent_contract_details.pay_nextmonth

  rent_contract_editor_forms.ad_name = rent_contract_details.ad_name
  rent_contract_editor_forms.ad_amount = rent_contract_details.ad_amount
  rent_contract_editor_forms.ad_pay = rent_contract_details.ad_pay

  rent_contract_editor_forms.application_fee = rent_contract_details.application_fee
  rent_contract_editor_forms.application_fee_payor = rent_contract_details.application_fee_payor
  rent_contract_editor_forms.application_fee_date = rent_contract_details.application_fee_date
  rent_contract_editor_forms.billing_closing_date = rent_contract_details.billing_closing_date
  rent_contract_editor_forms.billing_closing_date_nyuukyosha = rent_contract_details.billing_closing_date_nyuukyosha

  rent_contract_editor_forms.receipt = rent_contract_details.receipt
  rent_contract_editor_forms.contract_send = rent_contract_details.contract_send
  rent_contract_editor_forms.contract_penalty = rent_contract_details.contract_penalty
  rent_contract_editor_forms.penalty_remarks = rent_contract_details.penalty_remarks

  rent_contract_editor_forms.referral_fee = rent_contract_details.referral_fee
  rent_contract_editor_forms.referral_fee_remarks = rent_contract_details.referral_fee_remarks
  rent_contract_editor_forms.referral_fee_etc = rent_contract_details.referral_fee_etc
  rent_contract_editor_forms.referral_fee_etc_remarks = rent_contract_details.referral_fee_etc_remarks
  rent_contract_editor_forms.agent_fee = rent_contract_details.agent_fee
  rent_contract_editor_forms.agent_fee_remarks = rent_contract_details.agent_fee_remarks
  rent_contract_editor_forms.pay_brokerage_fee = rent_contract_details.pay_brokerage_fee

  rent_contract_editor_forms.pay_brokerage_fee_remarks = rent_contract_details.pay_brokerage_fee_remarks
  rent_contract_editor_forms.owner_brokerage_fee = rent_contract_details.owner_brokerage_fee
  rent_contract_editor_forms.owner_brokerage_fee_remarks = rent_contract_details.owner_brokerage_fee_remarks

  rent_contract_editor_forms.kagi_koukan_date = rent_contract_details.kagi_koukan_date
  rent_contract_editor_forms.kagi_shozai = rent_contract_details.kagi_shozai
  rent_contract_editor_forms.kagi_gyousha = rent_contract_details.kagi_gyousha
  rent_contract_editor_forms.hoshuu_date = rent_contract_details.hoshuu_date
  rent_contract_editor_forms.kouji_gyousha = rent_contract_details.kouji_gyousha
  rent_contract_editor_forms.shoudoku_date = rent_contract_details.shoudoku_date
  rent_contract_editor_forms.koukin_date = rent_contract_details.koukin_date

  rent_contract_editor_forms.edit_options = false
  rent_contract_editor_forms.hiwari_free = rent_contract_details.hiwari_free
  rent_contract_editor_forms.is_important_contract = rent_contract_details.is_important_contract



  // let family: any = JSON.stringify(rent_contract_details.family); // JSON文字列化
  // family = JSON.parse(family); // JSON文字列化したものを戻す
  // rent_contract_editor_forms.family = family


  let checkset: any = []
  checkset.rent_contract_monies = rent_contract_monies
  checkset.rent_contract_details = rent_contract_details
  checkset.master = state.masterReducer
  checkset.rent_contract_boshu_chinryou = state.contracts.rent_contract_boshu_chinryou
  console.log(state.contracts.rent_contract_checks)
  checkset.rent_contract_checks = state.contracts.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === rent_contract_details.rent_m_contract_type_id)

  let checked: any = CheckMoney.check(checkset)


  console.log(checked)

  yield put(change_contract_check_message(checked.contract_check_message));



  yield put(change_rent_contract_editor_forms(rent_contract_editor_forms));

  if (action.payload.disp === true) {


    if (action.payload.editor === "rent_emergency_editor_disp") {
      yield put(change_rent_emergency_editor_disp(true));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_contract_special_editor_disp") {
      yield put(change_rent_contract_special_editor_disp(true));
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_emergency_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_contract_ippan_editor_disp") {
      yield put(change_rent_contract_ippan_editor_disp(true));
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_emergency_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_customer_editor_disp") {
      yield put(change_rent_customer_editor_disp(true));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_emergency_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_contract_etc_editor_disp") {
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_emergency_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(true));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(false));
    } else if (action.payload.editor === "rent_contract_family_editor_disp") {
      yield put(change_rent_customer_editor_disp(false));
      yield put(change_rent_contract_ippan_editor_disp(false));
      yield put(change_rent_contract_editor_disp(false));
      yield put(change_rent_emergency_editor_disp(false));
      yield put(change_rent_contract_etc_editor_disp(false));
      yield put(change_rent_contract_special_editor_disp(false));
      yield put(change_rent_contract_family_editor_disp(true));


    }


  } else {
    yield put(change_rent_customer_editor_disp(false));
    yield put(change_rent_contract_editor_disp(false));
    yield put(change_rent_emergency_editor_disp(false));
    yield put(change_rent_contract_special_editor_disp(false));
    yield put(change_rent_contract_etc_editor_disp(false));
    yield put(change_rent_contract_ippan_editor_disp(false));
    yield put(change_rent_contract_family_editor_disp(false));
  }

}


function* handle_get_rent_m_money_templates(action: any): any {
  // yield put(change_line_files_loading(true));
  const state = yield select();



  const { payload, error } = yield call(rent_m_money_templates_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // alert(payload.length)
    console.log(payload)

    yield put(change_rent_m_money_templates(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}






function* handle_set_contracts_boshu_chinryou(action: any): any {
  // yield put(change_line_files_loading(true));
  const state = yield select();
  let rent_contract_details = state.contracts.rent_contract_details
  let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms

  // rent_contract_editor_forms.rent_contract_monies = []

  const { payload, error } = yield call(t_chinryou_api.list, {
    tatemono_id: rent_contract_editor_forms.tatemono_id, room_id: rent_contract_editor_forms.room_id
  });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    console.log(payload)







    let rent_contract_monies: any = []
    rent_contract_monies = payload.map(function (value: any, key: number) {
      value.works = 0
      value.rent_contract_id = rent_contract_details.id

      if (rent_contract_details.division != 4 && rent_contract_details.division != 5) {
        if (value.rent_m_account_id == 11 || value.rent_m_account_id == 14 || value.rent_m_account_id == 15 || value.rent_m_account_id == 22 || value.rent_m_account_id == 25) {
          value.works = 1
        }

      } else {
        if (value.rent_m_account_id == 11 || value.rent_m_account_id == 14 || value.rent_m_account_id == 15 || value.rent_m_account_id == 22 || value.rent_m_account_id == 25) {
          value.works = 2
        }
      }


      return value
    })
    rent_contract_monies.push({
      rent_contract_id: 0,
      rent_m_account_id: 12,
      name: "仲介手数料",
      display_name: "仲介手数料",
      billing: 0,
      amount: 0,
      amount_month: 0,
      each_month: 0,
      each_year: 0,
      account_group: 2,
      tax: 0,
      tax_amount: 0,
      rent_m_etc_id: 0,
      is_daily_rate: 0,
      is_free_rent: 0,
      works: 0,
      sales_amount: 0,
      remarks: null,
      created_user: 0,
      created_at: null,
      updated_at: null,
      updated_user: 0,
    })

    let checkset: any = []
    checkset.rent_contract_monies = rent_contract_monies
    checkset.rent_contract_details = rent_contract_details
    checkset.master = state.masterReducer
    checkset.rent_contract_boshu_chinryou = state.contracts.rent_contract_boshu_chinryou
    console.log(state.contracts.rent_contract_checks)
    checkset.rent_contract_checks = state.contracts.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === rent_contract_details.rent_m_contract_type_id)


    let set: any = CheckMoney.set_rent_contract_checks(checkset)




    rent_contract_editor_forms.contract_penalty = set.rent_contract_details.contract_penalty
    rent_contract_editor_forms.rent_contract_monies = set.rent_contract_monies;

    // yield put(change_rent_contract_editor_forms([]));



    yield put(change_rent_contract_editor_forms(rent_contract_editor_forms));


    let checked: any = CheckMoney.check(checkset)
    yield put(change_contract_check_message(checked.contract_check_message));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}




function* handle_set_rent_contract_checks(action: any): any {
  // yield put(change_line_files_loading(true));
  const state = yield select();
  let rent_contract_details = state.contracts.rent_contract_details
  let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms







  // let rent_contract_monies: any = []
  // rent_contract_monies = rent_contract_editor_forms.rent_contract_monies.map(function (value: any, key: number) {
  //   value.works = 0
  //   value.rent_contract_id = rent_contract_details.id

  //   return value
  // })

  let checkset: any = []
  checkset.rent_contract_monies = rent_contract_editor_forms.rent_contract_monies
  checkset.rent_contract_details = rent_contract_editor_forms
  checkset.master = state.masterReducer
  checkset.rent_contract_boshu_chinryou = state.contracts.rent_contract_boshu_chinryou
  console.log(state.contracts.rent_contract_checks)
  checkset.rent_contract_checks = state.contracts.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === rent_contract_details.rent_m_contract_type_id)


  let set: any = CheckMoney.set_rent_contract_checks(checkset)



  // alert(set.rent_contract_monies.filter((a: any) => a.rent_m_account_id === 56)[0].amount)
  rent_contract_editor_forms.contract_penalty = set.rent_contract_details.contract_penalty
  rent_contract_editor_forms.rent_contract_monies = set.rent_contract_monies;


  yield put(change_rent_contract_editor_forms(rent_contract_editor_forms));

  let checked: any = CheckMoney.check(checkset)
  yield put(change_contract_check_message(checked.contract_check_message));


}


function* handle_change_rent_contract_editor_forms(action: any): any {
  const state = yield select();
  let rent_contract_details = state.contracts.rent_contract_details
  let rent_contract_editor_forms = action.payload

  // yield put(change_contracts_backdrop(true));

  if (!rent_contract_editor_forms.hiwari_free) {
    action.payload.rent_contract_monies = CheckMoney.set_hiwari(action.payload, action.payload.rent_contract_monies, state.masterReducer)
  }


  let checkset: any = []
  checkset.rent_contract_monies = action.payload.rent_contract_monies
  checkset.rent_contract_details = rent_contract_details
  checkset.master = state.masterReducer
  checkset.rent_contract_boshu_chinryou = state.contracts.rent_contract_boshu_chinryou

  checkset.rent_contract_checks = state.contracts.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === action.payload.rent_m_contract_type_id)

  let checked: any = CheckMoney.check(checkset)

  yield put(change_contract_check_message(checked.contract_check_message));

  yield put(set_rent_contract_editor_forms([]));
  yield put(set_rent_contract_editor_forms(action.payload));
  // alert("dddd")
  yield put(check_rent_contract_editor_forms([]));


}


// 家族 
function* handle_get_rent_contract_families(action: any): any {
  const state = yield select();

  const { payload, error } = yield call(rent_contract_families_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // console.log(payload)

    // let family: any = JSON.stringify(rent_contract_details.family); // JSON文字列化
    // family = JSON.parse(family); // JSON文字列化したものを戻す
    // rent_contract_editor_forms.family = family
    yield put(change_rent_contract_families(JSON.parse(JSON.stringify(payload))));
    yield put(change_rent_contract_families_disp(payload));

  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// 家族 更新
function* handle_update_rent_contract_families(action: any): any {

  // alert(action.payload)

  const state = yield select();

  let rent_contract_families = state.contracts.rent_contract_families

  const { payload, error } = yield call(rent_contract_families_api.update, { "family": rent_contract_families, "rent_contract_id": action.payload });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    // 成功時アクション呼び出し
    // 一覧更新

    rent_contract_families = payload

    yield put(change_rent_contract_families(JSON.parse(JSON.stringify(rent_contract_families))));
    // yield put(change_rent_contract_families(rent_contract_families));
    yield put(change_rent_contract_families_disp(rent_contract_families))


  } else {

  }
}

// 家族 更新
function* handle_get_rent_m_specials(action: any): any {
  const state = yield select();

  const { payload, error } = yield call(rent_m_specials_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    yield put(change_rent_m_specials(payload));

  } else {
    // alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_get_rent_m_relations(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_relations_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_relations(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}

function* handle_get_rent_m_job_categorys(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_job_categorys_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_job_categorys(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}

function* handle_get_tm_parkingarea_detail_list(action: any): any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し
  // /contract/rest / parking.json'
  let rent_contract_editor_forms = state.contracts.rent_contract_editor_forms
  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms

  // alert(rent_contract_details.tatemono_id)
  if (rent_contract_editor_forms.counting === 1) {

  } else {
    // alert(rent_contract_editor_forms.tatemono_id)
    const { payload, error } = yield call(tm_parkingarea_api.list, rent_contract_editor_forms.tatemono_id);
    if (payload && !error) {
      // console.log(payload)

      let list = payload
      payload.map(function (value: any, key: any) {
        if (rent_contract_editor_forms.room_id && rent_contract_editor_forms.room_id == value.pa_id) {
          list[key].selectcontract = true
        } else if (rent_contract_editor_forms.room_id2 && rent_contract_editor_forms.room_id2 == value.pa_id) {
          list[key].selectcontract = true
        } else if (rent_contract_editor_forms.room_id3 && rent_contract_editor_forms.room_id3 == value.pa_id) {
          list[key].selectcontract = true
        }
      })

      yield put(change_tm_parkingarea_list(list));

    } else {
      // 失敗時アクション呼び出し
      // yield put(loginFailure(payload));
    }

  }

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し




}





function* handle_download_docs(action: any): any {
  // yield put(change_line_files_loading(true));
  const state = yield select();

  // alert(action.payload.extension)

  const { payload, error } = yield call(laravel_api.support365, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    const a = document.createElement('a');
    // ダウンロードされるファイル名
    a.download = "サポート365";
    // a.download = "10.pdf";
    a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    a.click();
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

// 請求書
function* handle_download_bill(action: any): any {
  // yield put(change_line_files_loading(true));
  const state = yield select();

  // alert(action.payload.extension)
  if (state.contracts.rent_contract_details.counting === 1) {
    const { payload, error } = yield call(laravel_api.seikyuu, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = "請求書";
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  } else if (state.contracts.rent_contract_details.counting === -1 || state.contracts.rent_contract_details.counting === 2 || state.contracts.rent_contract_details.counting === 6) {
    const { payload, error } = yield call(rent_contracts_api.download_bill, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = "請求書";
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  }
}

// 契約書
function* handle_download_contract(action: any): any {
  // yield put(change_line_files_loading(true));
  const state = yield select();

  // alert(action.payload.extension)
  if (state.contracts.rent_contract_details.counting === 1) {
    const { payload, error } = yield call(laravel_api.contract_file, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = "契約書";
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  } else if (state.contracts.rent_contract_details.counting === -1 || state.contracts.rent_contract_details.counting === 2 || state.contracts.rent_contract_details.counting === 6) {
    // const { payload, error } = yield call(rent_contracts_api.download_contract, action.payload);
    const { payload, error } = yield call(laravel_api.chushajo, action.payload);


    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
      const a = document.createElement('a');
      // ダウンロードされるファイル名
      a.download = "契約書";
      // a.download = "10.pdf";
      a.href = URL.createObjectURL(payload);
      // ダウンロード開始
      a.click();
    } else {
      //    // 失敗時アクション呼び出し
      //    yield put(loginFailure(payload));
    }
  }

}
// 重要事項説明書
function* handle_download_disclosure_statement(action: any): any {
  // yield put(change_line_files_loading(true));
  const state = yield select();
  const { payload, error } = yield call(laravel_api.disclosure_statement_file, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    const a = document.createElement('a');
    // ダウンロードされるファイル名
    a.download = "重要事項説明書";
    // a.download = "10.pdf";
    a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    a.click();
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_download_lifeline_sheet(action: any): any {
  const state = yield select();
  if (state.contracts.rent_contract_details.id) {
    const { payload, error } = yield call(laravel_api.LifeLine, state.contracts.rent_contract_details.id)
    if (payload && !error) {
      console.log(payload)
      // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
      const a = document.createElement('a');
      a.download = "LifeLine.pdf";
      a.href = URL.createObjectURL(payload);
      a.click();
      // check_contract_sheet_file[i] = {}
      // check_contract_sheet_file[i].file =payload
    } else {
      alert(error)
    }
  }
}

function* handle_download_jippi(action: any): any {
  const state = yield select();
  if (state.contracts.rent_contract_details.id) {
    const { payload, error } = yield call(laravel_api.Jippi, state.contracts.rent_contract_details.id)
    if (payload && !error) {
      console.log(payload)
      // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
      const a = document.createElement('a');
      a.download = "実費精算.pdf";
      a.href = URL.createObjectURL(payload);
      a.click();
      // check_contract_sheet_file[i] = {}
      // check_contract_sheet_file[i].file =payload
    } else {
      alert(error)
    }
  }
}
function* handle_download_shikibiki(action: any): any {
  const state = yield select();
  if (state.contracts.rent_contract_details.id) {
    const { payload, error } = yield call(laravel_api.Shikibiki, state.contracts.rent_contract_details.id)
    if (payload && !error) {
      console.log(payload)
      // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
      const a = document.createElement('a');
      a.download = "敷引精算.pdf";
      a.href = URL.createObjectURL(payload);
      a.click();
      // check_contract_sheet_file[i] = {}
      // check_contract_sheet_file[i].file =payload
    } else {
      alert(error)
    }
  }
}
function* handle_download_kaketsuke(action: any): any {
  const state = yield select();
  if (state.contracts.rent_contract_details.id) {
    const { payload, error } = yield call(laravel_api.kaketsuke, state.contracts.rent_contract_details.id)
    if (payload && !error) {
      console.log(payload)
      // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
      const a = document.createElement('a');
      a.download = "かけつけ申込書.pdf";
      a.href = URL.createObjectURL(payload);
      a.click();
      // check_contract_sheet_file[i] = {}
      // check_contract_sheet_file[i].file =payload
    } else {
      alert(error)
    }
  }
}
function* handle_download_kaden(action: any): any {
  const state = yield select();
  if (state.contracts.rent_contract_details.id) {
    const { payload, error } = yield call(laravel_api.kaden, state.contracts.rent_contract_details.id)
    if (payload && !error) {
      console.log(payload)
      // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
      const a = document.createElement('a');
      a.download = "レンタル家電.pdf";
      a.href = URL.createObjectURL(payload);
      a.click();
      // check_contract_sheet_file[i] = {}
      // check_contract_sheet_file[i].file =payload
    } else {
      alert(error)
    }
  }
}
function* handle_download_kadenShiyou(action: any): any {
  const state = yield select();
  if (state.contracts.rent_contract_details.id) {
    const { payload, error } = yield call(laravel_api.kadenShiyou, state.contracts.rent_contract_details.id)
    if (payload && !error) {
      console.log(payload)
      // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
      const a = document.createElement('a');
      a.download = "家電使用賃借.pdf";
      a.href = URL.createObjectURL(payload);
      a.click();
      // check_contract_sheet_file[i] = {}
      // check_contract_sheet_file[i].file =payload
    } else {
      alert(error)
    }
  }
}
function* handle_download_jousuiki(action: any): any {
  const state = yield select();
  if (state.contracts.rent_contract_details.id) {
    const { payload, error } = yield call(laravel_api.jousui, state.contracts.rent_contract_details.id)
    if (payload && !error) {
      console.log(payload)
      // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
      const a = document.createElement('a');
      a.download = "浄水器.pdf";
      a.href = URL.createObjectURL(payload);
      a.click();
      // check_contract_sheet_file[i] = {}
      // check_contract_sheet_file[i].file =payload
    } else {
      alert(error)
    }
  }
}
function* handle_download_hazard(action:any ):any {
const state = yield select();
if (state.contracts.rent_contract_details.id) {
  const { payload, error } = yield call(laravel_api.hazardmap, { id: state.contracts.rent_contract_details.id })
  if (payload && !error) {
    console.log(payload)
    // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
    const a = document.createElement('a');
    a.download = "ハザードマップ.pdf";
    a.href = URL.createObjectURL(payload);
    a.click();
    // check_contract_sheet_file[i] = {}
    // check_contract_sheet_file[i].file =payload
  } else {
    alert(error)
  }
}
}
function* handle_download_mimamori(action:any ):any {
    const state = yield select();
    if (state.contracts.rent_contract_details.id) {
      const { payload, error } = yield call(laravel_api.mimamori, state.contracts.rent_contract_details.id)
    if (payload && !error) {
    console.log(payload)
    // check_contract_sheet_file = (i === 0) ? [] :state.contracts.rent_contract_details.check_contract_sheet_file
    const a = document.createElement('a');
    a.download = "みまもり.pdf";
    a.href = URL.createObjectURL(payload);
    a.click();
    // check_contract_sheet_file[i] = {}
    // check_contract_sheet_file[i].file =payload
  } else {
    alert(error)
  }
}
}
function* handle_get_electronic_contract(action: any): any {
  const { payload, error } = yield call(contractsheet_api.check_contract, action.payload)
  if (payload && !error) {
    yield put(change_electronic_contract(payload.electronic_contract));
    yield put(change_cloudsign_document_id(payload.cloudsign_document_id));
  }
}
function* handle_electronic_contract_cancel(action: any): any {
  const state = yield select();
  const { payload, error } = yield call(contractsheet_api.cancel_electronic_contract, state.contracts.rent_contract_details.id)
  if (payload && !error) {
    alert("送信した電子契約書類を取り消ししました。");
    console.log(payload.cloudsign_document_id)
    yield put(change_cloudsign_document_id(payload.cloudsign_document_id));
  }
}
function* handle_check_electronic_contract(action: any): any {
  const state = yield select();

  yield put(change_contracts_backdrop(true));
  const { payload, error } = yield call(contractsheet_api.check_electronic_contract, state.contracts.rent_contract_details.id)
  let check_contract_sheet_file: any = []
  if (payload && !error) {
    if (payload.length > 0) {

      yield put(change_check_contract_sheet_file([]))
      for (let i = 0; i < payload.length; i++) {
        console.log(payload[i].id)
        const data = yield call(contractsheet_api.download, payload[i].id)

        if (data.payload && !data.error) {
          console.log(payload[i].id)
          check_contract_sheet_file[i] = {}
          check_contract_sheet_file[i].file = data.payload
          check_contract_sheet_file[i].file_name = payload[i].name
          console.log((i + 1), payload.length)
          if ((i + 1) == payload.length) {
            yield put(change_check_contract_sheet_file(check_contract_sheet_file));
            yield put(change_contracts_backdrop(false));
            yield put(change_check_contract_sheet(true));
          }
        }
      }
    }
  }
}
function* handle_get_check_send_files(action: any): any {
    const state = yield select();
    yield put(change_check_contract_send_files([]))
    yield put(change_check_contract_send_mail({from:'',to:'',cc:'',bcc:'',subject:'',body:''}))
    yield put(change_check_contract_send_mail_confirm({from:'',to:'',cc:'',bcc:'',subject:'',body:''}))
    yield put(change_check_contract_send_mail_template({}))
    let file: any = []
    if(action.payload){
        
        yield put(change_back_drop_contract_mail(true));  
        for (let i = 0; i < 3; i++) {
            let data;
            if(i == 0){
                data = yield call(laravel_api.seikyuu, state.contracts.rent_contract_details.id)
                if (data.payload && !data.error) {
                    file[i] = {}
                    file[i].file = data.payload
                    file[i].file_name = 'mail_請求書'
                }
            }else if(i == 1){
                data = yield call(laravel_api.LifeLine, state.contracts.rent_contract_details.id)
                if (data.payload && !data.error) {
                    file[i] = {}
                    file[i].file = data.payload
                    file[i].file_name = 'mail_ライフライン'
                }
            }else if(i == 2){
                data = yield call(laravel_api.NetPikara, state.contracts.rent_contract_details.tatemono_id)
                if (data.payload && !data.error) {
                    if(data.payload.type.indexOf('pdf') != -1){
                        file[i] = {}
                        file[i].file = data.payload
                        file[i].file_name = 'mail_まるごとピカラ'
                    }
            
                        yield put(change_check_contract_send_files(file));
                        const { payload, error } = yield call(MailsApi.contractmailtemplate, { rent_response_id: state.contracts.rent_contract_details.rent_response_id, mail_template_id: 286 });
                        if (payload && !error) {
                            console.log(payload,'データかくにん!!')
                            yield put(change_check_contract_send_mail_template(payload))
                            yield put(change_check_contract_send_mail_confirm({
                                from:payload.tenpo_mail,
                                to:payload.customer_mail,
                                bcc:payload.tenpo_mail,
                                subject:payload.subject,
                                body:payload.body_all + payload.shomei}))
                                yield put(change_back_drop_contract_mail(false));
                        }else{                    
                            yield put(change_back_drop_contract_mail(false));
                        }
                }else{
                }
            }
        }
    }
}
function* handle_remind_electronic_contract(action: any): any {
  const state = yield select();
  const { payload, error } = yield call(contractsheet_api.remind_electronic_contract, state.contracts.rent_contract_details.id)
  if (payload && !error) {
    if (payload == 'success') {
      yield put(change_send_complete_text({ header: 'リマインド完了', body: '電子契約をリマインドしました。' }));
      yield put(change_send_complete(true));
    } else {
      yield put(change_send_complete_text({ header: 'リマインド失敗', body: '電子契約をリマインドに失敗しました。' }));
      yield put(change_send_complete(true));
    }
  } else {
    alert(error)
  }
}
function* handle_contracts_sheet_check(action: any): any {
  const state = yield select();
  yield put(change_contracts_backdrop(true));
  console.log("エラー。")
  let check_contract_sheet_file: any = []
  
        {/* 駐車場対応準備中コメントアウト中↓ */}
  var cnt = 0
  var file_check = 0
//   if(state.contracts.rent_contract_details.counting == -1 || state.contracts.rent_contract_details.counting == 2 || state.contracts.rent_contract_details.counting == 6){
//     const parking = yield call(laravel_api.chushajo, state.contracts.rent_contract_details.id)
//     if (parking.payload && !parking.error) {
//         var file_name_p = state.contracts.rent_contract_details.id + '_parking'
//         check_contract_sheet_file[cnt] = {}
//         check_contract_sheet_file[cnt].file = parking.payload
//         check_contract_sheet_file[cnt].file_name = file_name_p
//         check_contract_sheet_file[cnt].type = 'parking'
//         cnt += 1
//         file_check += 1
//     } else { console.log(parking.error) }
//   }else{
//     file_check += 1
//   }
//   if(state.contracts.rent_contract_details.counting == 0 || state.contracts.rent_contract_details.counting == 1){
    const disclosure_statement = yield call(laravel_api.disclosure_statement_file, state.contracts.rent_contract_details.id)
  if (disclosure_statement.payload && !disclosure_statement.error) {
    var file_name = state.contracts.rent_contract_details.id + '_1'
    check_contract_sheet_file[cnt] = {}
    check_contract_sheet_file[cnt].file = disclosure_statement.payload
    check_contract_sheet_file[cnt].file_name = file_name
    check_contract_sheet_file[cnt].type = 1
    cnt += 1
    file_check += 1
  } else { console.log(disclosure_statement.error) }
//   }else{
//     file_check += 1
//   }
//   if(state.contracts.rent_contract_details.counting == 0 || state.contracts.rent_contract_details.counting == 1){
  const contract_sheet = yield call(laravel_api.contract_file, state.contracts.rent_contract_details.id)
  file_check = file_check + (state.contracts.rent_contract_details.rent_m_corporate_id == 4814 ? 1 : 0)
  if (contract_sheet.payload && !contract_sheet.error && state.contracts.rent_contract_details.rent_m_corporate_id != 4814) { //4814(株式会社リロケーション・ジャパンの時、ファイルアップロードを使うのでlaravel契約書使わない)
    var file_name1 = state.contracts.rent_contract_details.id + '_2'
    check_contract_sheet_file[cnt] = {}
    check_contract_sheet_file[cnt].file = contract_sheet.payload
    check_contract_sheet_file[cnt].file_name = file_name1
    check_contract_sheet_file[cnt].type = 2
    cnt += 1
    file_check += 1
  } else { console.log(contract_sheet.error) }
//   }else{
//     file_check += 1
//   }
//   if(state.contracts.rent_contract_details.counting == 0 || state.contracts.rent_contract_details.counting == 1){
  const getkouku = yield call(laravel_api.getkouku, state.contracts.rent_contract_details.id)
  var check_contract_sheet_text = state.contracts.check_contract_sheet_text
  if (getkouku.payload && !getkouku.error) {
    check_contract_sheet_text.shougakkou = getkouku.payload
    yield put(change_check_contract_sheet_text(check_contract_sheet_text));
  } else { console.log(getkouku.error) }
  const hazardmap = yield call(laravel_api.hazardmap, { id: state.contracts.rent_contract_details.id })
  if (hazardmap.payload && !hazardmap.error) {
    var file_name2 = state.contracts.rent_contract_details.id + '_3'
    check_contract_sheet_file[cnt] = {}
    check_contract_sheet_file[cnt].file = hazardmap.payload
    check_contract_sheet_file[cnt].file_name = file_name2
    check_contract_sheet_file[cnt].type = 3
    cnt += 1
    file_check += 1
  } else { console.log(hazardmap.error) }
//   }else{
//     file_check += 1
//   }
  var jippi = 1;
  var shikibiki_gaku_check = 0;
  var shikibiki_zei_check = 0;
  var shikibiki_month_check = 0;
  var rent_contract_monies = state.contracts.rent_contract_details.rent_contract_monies
  rent_contract_monies.filter((a: any): any => a.account_group === 4).map(function (money4: any): any {
    //※込の場合※
    if (money4.rent_m_account_id == 17 && (money4.rent_m_etc_id == 2 || money4.rent_m_etc_id == 3)) {//無し　こみ　
      jippi = 0
    }

    //※敷引額の場合※
    if (money4.rent_m_account_id == 21) {//敷引額
      shikibiki_gaku_check = money4.amount == 0 ? 0 : 1;
      shikibiki_zei_check = money4.tax_amount == 0 ? 0 : 1;
      shikibiki_month_check = money4.amount_month == 0 ? 0 : 1;

      jippi = 0;
    }
  })



  //実費精算
  if (jippi == 1) {//実費
    const Actual_expense_settlement = yield call(laravel_api.Jippi, state.contracts.rent_contract_details.id)
    if (Actual_expense_settlement.payload && !Actual_expense_settlement.error) {
      var file_name3 = state.contracts.rent_contract_details.id + '_4'
      check_contract_sheet_file[cnt] = {}
      check_contract_sheet_file[cnt].file = Actual_expense_settlement.payload
      check_contract_sheet_file[cnt].file_name = file_name3
      check_contract_sheet_file[cnt].type = 4
      cnt += 1
      file_check += 1
    } else { console.log(Actual_expense_settlement.error) }
  } else {
    file_check += 1
  }

  if (shikibiki_gaku_check == 0 && shikibiki_month_check == 0) {//敷引なし
    file_check += 1
  } else {
    //敷引精算
    const Shikibiki = yield call(laravel_api.Shikibiki, state.contracts.rent_contract_details.id)
    if (Shikibiki.payload && !Shikibiki.error) {
      var file_name4 = state.contracts.rent_contract_details.id + '_5'
      check_contract_sheet_file[cnt] = {}
      check_contract_sheet_file[cnt].file = Shikibiki.payload
      check_contract_sheet_file[cnt].file_name = file_name4
      check_contract_sheet_file[cnt].type = 5
      cnt += 1
      file_check += 1
    } else { console.log(Shikibiki.error) }
  }


  //サポート365
  var rent_m_guarantee_id = state.contracts.rent_contract_details.rent_m_guarantee_id

  if (rent_m_guarantee_id == 1) {
    const support365 = yield call(laravel_api.support365, state.contracts.rent_contract_details.id)
    if (support365.payload && !support365.error) {
      var file_name5 = state.contracts.rent_contract_details.id + '_6'
      check_contract_sheet_file[cnt] = {}
      check_contract_sheet_file[cnt].file = support365.payload
      check_contract_sheet_file[cnt].file_name = file_name5
      check_contract_sheet_file[cnt].type = 6
      cnt += 1
      file_check += 1
    } else { console.log(support365.error) }
  } else {
    file_check += 1
  }
  //かけつけ(安心入居サポート)
  var kaketsuke = state.contracts.rent_contract_details.rent_contract_monies.find((x: any) => x.rent_m_account_id == 22)
  if (kaketsuke) {
    const kaketsuke = yield call(laravel_api.kaketsuke, state.contracts.rent_contract_details.id)
    if (kaketsuke.payload && !kaketsuke.error) {
      var file_name6 = state.contracts.rent_contract_details.id + '_7'
      check_contract_sheet_file[cnt] = {}
      check_contract_sheet_file[cnt].file = kaketsuke.payload
      check_contract_sheet_file[cnt].file_name = file_name6
      check_contract_sheet_file[cnt].type = 7
      cnt += 1
      file_check += 1
    } else { console.log(kaketsuke.error) }
  } else {
    file_check += 1
  }

  //家電
  var kaden_state = state.contracts.rent_contract_details.electronics
  if (kaden_state) {
    var rent = state.contracts.rent_contract_details.rent_contract_monies.filter((x: any) => x.rent_m_account_id == 24)
    if (rent.length > 0 && rent[0].amount && rent[0].amount > 0) {
      var file_name7 = state.contracts.rent_contract_details.id + '_8'
      check_contract_sheet_file[cnt] = {}
      check_contract_sheet_file[cnt].file_name = file_name7
      const kaden = yield call(laravel_api.kaden, state.contracts.rent_contract_details.id)
      if (kaden.payload && !kaden.error) {
        check_contract_sheet_file[cnt].file = kaden.payload
        check_contract_sheet_file[cnt].type = 8
        cnt += 1
        file_check += 1
      } else { console.log(kaden.error) }
    } else {
      const kadenShiyou = yield call(laravel_api.kadenShiyou, state.contracts.rent_contract_details.id)
      if (kadenShiyou.payload && !kadenShiyou.error) {
        var file_name7_1 = state.contracts.rent_contract_details.id + '_8-1'
        check_contract_sheet_file[cnt] = {}
        check_contract_sheet_file[cnt].file_name = file_name7_1
        check_contract_sheet_file[cnt].file = kadenShiyou.payload
        check_contract_sheet_file[cnt].type = '8-1'
        cnt += 1
        file_check += 1
      } else { console.log(kadenShiyou.error) }
    }
  } else {
    file_check += 1
  }


  //浄水器
  var jousui_state = state.contracts.rent_contract_details.rent_contract_monies.filter((x: any) => x.rent_m_account_id === 7 || x.rent_m_account_id === 46).filter((v: any) => v)
  if (jousui_state.length) {
    const jousui = yield call(laravel_api.jousui, state.contracts.rent_contract_details.id)
    if (jousui.payload && !jousui.error) {
      var file_name8 = state.contracts.rent_contract_details.id + '_9'
      check_contract_sheet_file[cnt] = {}
      check_contract_sheet_file[cnt].file = jousui.payload
      check_contract_sheet_file[cnt].file_name = file_name8
      check_contract_sheet_file[cnt].type = 9
      cnt += 1
      file_check += 1
    } else { console.log(jousui.error) }
  } else {
    file_check += 1
  }
  // みまもり24
  var mimamori_state = state.contracts.rent_contract_details.rent_m_contract_type_id == 61 || state.contracts.rent_contract_details.rent_m_contract_type_id == 62 || state.contracts.rent_contract_details.rent_m_contract_type_id == 63 ? true : false
  console.log(mimamori_state)
  if (mimamori_state) {
    const mimamori = yield call(laravel_api.mimamori, state.contracts.rent_contract_details.id)
    if (mimamori.payload && !mimamori.error) {
      var file_name9 = state.contracts.rent_contract_details.id + '_10'
      check_contract_sheet_file[cnt] = {}
      check_contract_sheet_file[cnt].file = mimamori.payload
      check_contract_sheet_file[cnt].file_name = file_name9
      check_contract_sheet_file[cnt].type = 10
      cnt += 1
      file_check += 1
    } else { console.log(mimamori.error) }
  } else {
    file_check += 1
  }

  console.log(file_check)
  if (file_check == 10) {
    if(state.contracts.rent_contract_details.rent_m_corporate_id == 4814){
        yield put(change_check_contract_sheet_file(check_contract_sheet_file))
        yield put(change_check_contract_button(false));
        yield put(change_check_contract_sheet(true));
        yield put(change_contracts_backdrop(false));
        yield put(change_contract_upload_files_modal(true));

    }else{
        yield put(change_check_contract_sheet_file(check_contract_sheet_file))
        yield put(change_check_contract_button(false));
        yield put(change_check_contract_sheet(true));
        yield put(change_contracts_backdrop(false));
    }
  }
}

function* handle_contracts_sheet_corp(action: any): any {
    const state = yield select();
    yield put(change_contracts_backdrop(true));
    let check_contract_sheet_file: any = []
    check_contract_sheet_file[0] = {}
    check_contract_sheet_file[0].file = undefined
    check_contract_sheet_file[0].file_name = ''
    check_contract_sheet_file[0].type = -1
    yield put(change_check_contract_sheet_file(check_contract_sheet_file))
    yield put(change_check_contract_button(false));
    yield put(change_check_contract_sheet(true));
    yield put(change_contracts_backdrop(false));
    yield put(change_contract_upload_files_modal(true));
}

function* handle_change_hazardmap(action: any): any {
  const state = yield select();
  var check_contract_sheet_file = state.contracts.check_contract_sheet_file;
  const hazardmap = yield call(laravel_api.hazardmap, { id: state.contracts.rent_contract_details.id })
  if (hazardmap.payload && !hazardmap.error) {
    yield put(change_check_contract_sheet_file([]))
    var file_name2 = state.contracts.rent_contract_details.id + '_3'
    check_contract_sheet_file[2] = {}
    check_contract_sheet_file[2].file = hazardmap.payload
    check_contract_sheet_file[2].file_name = file_name2
    check_contract_sheet_file[2].type = 3
    yield put(change_check_contract_sheet_file(check_contract_sheet_file))
  } else { console.log(hazardmap.error) }
}

// キャンセル
function* handle_contracts_cancel(action: any): any {

  // alert(action.payload)




  const { payload, error } = yield call(rent_contracts_api.contract_cancel, action.payload);

  // // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {


    const state = yield select();


    let rent_contract_details = state.contracts.rent_contract_details
    rent_contract_details.is_cancel = 1
    rent_contract_details.cancel_date = moment().format("YYYY/MM/DD")

    yield put(change_rent_contract_details(JSON.parse(JSON.stringify(rent_contract_details))))

    //   rent_contract_details.cancel_date = moment().format("YYYY/MM/DD")

    //   yield put(change_rent_contract_details(rent_contract_details))act_details.is_cancel = 1
    //   rent_contr
    //   // 成功時アクション呼び出し
    //   // 一覧更新

    //   // rent_contract_families = payload

    //   // yield put(change_rent_contract_families(JSON.parse(JSON.stringify(rent_contract_families))));
    //   // // yield put(change_rent_contract_families(rent_contract_families));
    //   // yield put(change_rent_contract_families_disp(rent_contract_families))


  } else {
    alert("error")
  }
}

function* handle_insert_rent_contract_workflow(action: any): any {

  yield put(change_contracts_backdrop(true));

  const state = yield select();
  let rent_contract_details = state.contracts.rent_contract_details

  let rent_contract_send_status = state.contracts.rent_contract_send_status
  rent_contract_send_status.rent_contract_id = rent_contract_details.id
  rent_contract_send_status.send_section_id = rent_contract_send_status.send_section_id == "" ? 5 : rent_contract_send_status.send_section_id
  // console.log(rent_contract_send_status)
  // alert(rent_contract_details.id)
  // return
  const { payload, error } = yield call(update_rent_contract_workflows_api.insert, rent_contract_send_status);

  // // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    console.log(payload)
    yield put(change_rent_contract_workflows(payload))

    rent_contract_details.ver = payload.ver
    yield put(change_rent_contract_details(JSON.parse(JSON.stringify(rent_contract_details))))

    yield put(change_rent_contract_logs_new(payload.ver))

    yield put(change_contracts_backdrop(false));

  } else {
    alert("error")
  }
}

function* handle_update_rent_contract_workflow_manager(action: any): any {

  yield put(change_contracts_backdrop(true));

  const state = yield select();
  let rent_contract_details = state.contracts.rent_contract_details

  // console.log(rent_contract_send_status)
  // alert(rent_contract_details.id)
  // return
  const { payload, error } = yield call(rent_contract_workflows_api.manager_update, { workflow_id: state.contracts.rent_contract_workflows.id, status: action.payload });

  // // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    console.log(payload)
    let rent_contract_workflows = state.contracts.rent_contract_workflows
    rent_contract_workflows.status = payload.status
    rent_contract_workflows.log_confirm = payload.log_confirm
    rent_contract_workflows.confirm_manager_at = payload.confirm_manager_at
    rent_contract_workflows.confirm_manager_user_id = payload.confirm_manager_user_id

    yield put(change_rent_contract_workflows(JSON.parse(JSON.stringify(rent_contract_workflows))))

    yield put(change_rent_contract_logs_old(payload.log_confirm))

    // rent_contract_details.ver = 
    // yield put(change_rent_contract_details(JSON.parse(JSON.stringify(rent_contract_details))))

    // yield put(change_rent_contract_workflows(rent_contract_workflows))
    yield put(change_contracts_backdrop(false));

  } else {
    alert("error")
  }
}

function* handle_update_rent_contract_workflow_pm(action: any): any {

  yield put(change_contracts_backdrop(true));

  const state = yield select();

  // console.log(rent_contract_send_status)
  // alert(rent_contract_details.id)
  // return
  const { payload, error } = yield call(rent_contract_workflows_api.pm_update, { workflow_id: state.contracts.rent_contract_workflows.id, status: action.payload });

  // // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    console.log(payload)
    let rent_contract_workflows = state.contracts.rent_contract_workflows
    rent_contract_workflows.status = payload.status
    rent_contract_workflows.confirm_pm_at = payload.confirm_pm_at
    rent_contract_workflows.confirm_pm_user_id = payload.confirm_pm_user_id
    rent_contract_workflows.pm_remarks = payload.pm_remarks

    yield put(change_rent_contract_workflows(JSON.parse(JSON.stringify(rent_contract_workflows))))
    // yield put(change_rent_contract_workflows(rent_contract_workflows))
    yield put(change_contracts_backdrop(false));

  } else {
    alert("error")
  }
}


function* handle_update_rent_contracts_zenhoren_apply(action: any): any {

  yield put(change_contracts_backdrop(true));

  const state = yield select();
  let rent_contract_details = state.contracts.rent_contract_details
  let zenhoren_file = state.contracts.zenhoren_file
  // alert(rent_contract_details.id)
  // return
  const { payload, error } = yield call(zenhoren_api.apply, { rent_contract_id: rent_contract_details.id, zenhoren_file: zenhoren_file });

  // // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {


    console.log(payload)
    console.log(JSON.stringify(payload))
    let result = payload
    if (result.length > 0) { // エラーの時
      rent_contract_details.zenhoren_result = JSON.stringify(payload)
      yield put(change_zenhoren_result_modal(true))// エラー詳細画面の表示
    } else { // 成功したとき
      rent_contract_details.zenhoren_status = action.payload
      rent_contract_details.zenhoren_apply_at = moment().format("YYYY/MM/DD HH:mm:ss")
      rent_contract_details.zenhoren_result = ""
    }

    yield put(change_rent_contract_details(JSON.parse(JSON.stringify(rent_contract_details))))
    yield put(change_zenhoren_file({ type: '', file_id: [] })) //送信ファイル初期化

    yield put(change_contracts_backdrop(false));

  } else {
    alert("error")
  }

}

function* handle_settlement_lock(action: any): any {
  const state = yield select();
  console.log(action.payload)
  const { payload, error } = yield call(contractsheet_api.settlement_lock, { id: state.contracts.rent_contract_details.parking_application_id, state: action.payload ? 1 : 0 });
  if (payload && !error) {
    var rent_contract_details = state.contracts.rent_contract_details
    rent_contract_details.settlement_lock = payload
    yield put(change_rent_contract_details(rent_contract_details));
  }
}
function* handle_get_sendmail_files(action: any):any {
    const state = yield select();
    const { payload, error } = yield call(MailsApi.contractmailtemplate, { rent_response_id: state.contracts.rent_contract_details.rent_response_id, mail_template_id: 286 });
    if (payload && !error) {
        console.log(payload,'データかくにん!!')
        yield put(change_check_contract_send_mail_template(payload))
        yield put(change_check_contract_send_mail_confirm({
            from:payload.tenpo_mail,
            to:payload.customer_mail,
            bcc:payload.tenpo_mail,
            subject:payload.subject,
            body:payload.body_all + payload.shomei}))
    }
    
    
}
function *handle_contract_file_upload(action:any):any {
    const state = yield select();
    console.log('ファイル変更')
    console.log('条件')
    console.log(action)

    let check_contract_sheet_file = action.payload.check_contract_sheet_file;
    console.log(check_contract_sheet_file)
    let rent_contract_id = state.contracts.rent_contract_details.id
    if(check_contract_sheet_file.length > 0){
        check_contract_sheet_file[action.payload.sheet].file = action.payload.blob
        check_contract_sheet_file[action.payload.sheet].file_name = rent_contract_id + '_' + action.payload.name + '_' + check_contract_sheet_file[action.payload.sheet].type
        console.log(check_contract_sheet_file,"ファイル状況")
        yield put(change_check_contract_sheet_file(check_contract_sheet_file))
        return 'ok'
    }
}

function *handle_contract_AddFiles(action:any):any {
    const state = yield select();
    console.log(action.payload,"追加ボタン")
    let check_contract_sheet_file = state.contracts.check_contract_sheet_file;
    
    let add_check_contract_sheet_file = {
        file:undefined,
        file_name:'',
        type:-1
    }
    check_contract_sheet_file.push(add_check_contract_sheet_file)
    console.log(check_contract_sheet_file,"追加後")
    yield put(change_check_contract_sheet_file(check_contract_sheet_file))
    return 'ok'
}

function *handle_contract_DelFiles(action:any):any {
    const state = yield select();
    console.log(action.payload,"ボタン")
    let check_contract_sheet_file = state.contracts.check_contract_sheet_file;
    console.log(check_contract_sheet_file)
    if(check_contract_sheet_file.length > 1){
        check_contract_sheet_file.splice(action.payload, 1);
        yield put(change_check_contract_sheet_file(check_contract_sheet_file))
        return 'ok'
    }else{
        check_contract_sheet_file = []
        check_contract_sheet_file[0] = {}
        check_contract_sheet_file[0].file = undefined
        check_contract_sheet_file[0].file_name = ''
        check_contract_sheet_file[0].type = -1
        yield put(change_check_contract_sheet_file(check_contract_sheet_file))
        return 'ok'
    }
}