import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as TmSetsubiApi from '../Api/TmSetsubiApi';
import * as ResponseMapSearchRoomsApi from '../Api/ResponseMapSearchRoomsApi';
import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import * as t_room_sheets_api from '../Api/t_room_sheets_api';
import * as TmParkingApi from '../Api/TmParkingApi';


import * as IntroductionList from '../../IntroductionList/Saga/IntroductionList';
import * as StockRooms from '../../StockRooms/Saga/StockRooms';

import { startSubmit, stopSubmit, reset, submit } from 'redux-form';
export interface MapSearchState {
    setsubi_master: any;
    rooms: any;
    map_room_details: any;
    map_room_details_all: any;
    response_rooms: any;
    showRight: boolean;
    showLeft: boolean;
    showFlag: boolean;
    draggable: boolean;
    building_details: any;
    introductions: any;
    introductions_loading: number;
    fromMapOpen: number,//1紹介リスト　2メールフォーム

    map_parking_list: any;
    map_room_details_loading: boolean;
}
const initialState: MapSearchState = {

    setsubi_master: [],
    rooms: [],
    map_room_details: [],
    response_rooms: [],
    showRight: false,
    showLeft: true,
    showFlag: true,
    draggable: true,
    building_details: [],
    introductions: [],
    introductions_loading: 0,
    fromMapOpen: 1,//1紹介リスト　2メールフォーム
    map_room_details_all: [],

    map_parking_list: [],
    map_room_details_loading: false,
}



///action 
const actionCreator = actionCreatorFactory();

export const get_setsubi_master = actionCreator('GET_SETSUBI_MASTER');
export const get_setsubi_master_success = actionCreator("GET_SETSUBI_MASTER_SUCCESS");
export const get_setsubi_master_failure = actionCreator("GET_SETSUBI_MASTER_FAILURE");



export const get_rooms = actionCreator('GET_ROOMS');
export const get_rooms_success = actionCreator("GET_ROOMS_SUCCESS");
export const get_rooms_failure = actionCreator("GET_ROOMS_FAILURE");


export const get_response_map_rooms = actionCreator<any>('GET_RESPONSE_MAP_ROOMS');
export const get_response_map_rooms_success = actionCreator<any>("GET_RESPONSE_MAP_ROOMS_SUCCESS");


export const get_map_room_details = actionCreator<any>('GET_MAP_ROOM_DETAILS');
export const get_map_room_details_success = actionCreator<any>("GET_MAP_ROOM_DETAILS_SUCCESS");
export const change_map_room_details_all = actionCreator<any>("CHANGE_MAP_ROOM_DETAILS_ALL");
export const get_map_room_details_failure = actionCreator("GET_MAP_ROOM_DETAILS_FAILURE");





export const change_showRight = actionCreator<any>('change_showRight');
export const change_showLeft = actionCreator<any>('change_showLeft');
export const change_showFlag = actionCreator<any>('change_showFlag');
export const change_draggable = actionCreator<any>('change_draggable');

export const insert_map_to_introductions = actionCreator<any>('INSERT_MAP_TO_INTRODUCTIONS');
// export const insert_map_introductions_success = actionCreator<any>('INSERT_MAP_INTRODUCTIONS_SUCCESS');


export const delete_map_introduction = actionCreator<any>('DELETE_MAP_INTRODUCTION');

export const change_introductions_loading = actionCreator<number>('INTRODUCTIONS_LOADING');



export const get_introductions = actionCreator<any>('GET_INTRODUCTIONS');
export const get_introductions_success = actionCreator<any>('GET_INTRODUCTIONS_SUCCESS');

export const change_fromMapOpen = actionCreator<any>('CHANGE_FROMMAPOPEN');

export const delete_map_stockrooms = actionCreator<any>('DELETE_MAP_STOCKROOMS');

export const insert_map_stockrooms = actionCreator<any>('INSERT_MAP_STOCKROOMS');

export const get_map_parking_list = actionCreator<any>('GET_MAP_PARKING_LIST');
export const change_map_parking_list = actionCreator<any>('CHANGE_MAP_PARKING_LIST');

export const change_map_room_details_loading = actionCreator<boolean>('CHANGE_MAP_ROOM_DETAILS_LOADING');


export const mapsearchReducer = reducerWithInitialState(initialState)
    .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))
    .case(get_map_room_details_success, (state, payload) => ({ ...state, map_room_details: payload }))
    .case(change_map_room_details_all, (state, payload) => ({ ...state, map_room_details_all: payload }))
    .case(get_response_map_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

    .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
    .case(change_showLeft, (state, payload) => ({ ...state, showLeft: payload }))
    .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
    .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

    .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }))

    .case(get_introductions_success, (state, payload) => ({ ...state, introductions: payload }))

    .case(change_introductions_loading, (state, payload) => ({ ...state, introductions_loading: payload }))


    .case(get_introductions_success, (state, payload) => ({ ...state, introductions: payload }))
    .case(change_fromMapOpen, (state, payload) => ({ ...state, fromMapOpen: payload }))

    .case(change_map_parking_list, (state, payload) => ({ ...state, map_parking_list: payload }))
    .case(change_map_room_details_loading, (state, payload) => ({ ...state, map_room_details_loading: payload }))

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const mapsearchSaga = [takeEvery('GET_SETSUBI_MASTER', handleRequestSetsubiMaster),
takeEvery('GET_ROOMS', handleGetRoomsRequest),
takeEvery('GET_MAP_ROOM_DETAILS', handle_map_room_details),
takeEvery('GET_RESPONSE_MAP_ROOMS', handle_get_response_map_rooms),
takeEvery('DELETE_MAP_INTRODUCTION', handle_delete_map_introduction),
takeEvery('INSERT_MAP_TO_INTRODUCTIONS', handle_insert_map_to_introductions),


takeEvery('DELETE_MAP_STOCKROOMS', handle_delete_map_stockrooms),
takeEvery('INSERT_MAP_STOCKROOMS', handle_insert_map_stockrooms),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),

takeEvery('GET_MAP_PARKING_LIST', handle_get_map_parking_list),
];
// export default masterSaga;



function* handleRequestSetsubiMaster(action: any) {

    const { payload, error } = yield call(TmSetsubiApi.get_setsubi, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        // const setsubis = payload.map((item:any, i:number) => ({
        //     value: item.setsubiNo,
        //     label: item.setsubiName,
        //   }))

        // 成功時アクション呼び出し


        //yield put(get_setsubi_master_success(setsubis));
        yield put(get_setsubi_master_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handleGetRoomsRequest(action: any) {

    const { payload, error } = yield call(ResponseMapSearchRoomsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(get_rooms_success(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_map_room_details(action: any) : any{
    const state = yield select();

    console.log(action.payload)
    // yield put(get_room_details_success([]));
    //   alert(action.payload)
    yield put(get_map_room_details_success([]));

    yield put(change_map_room_details_loading(false));


    // const value = { tatemono_id: action.payload, boshu_only: state.roomlist.room_list_search_form.boshu_only};

    let room_list_search_form = state.roomlist.room_list_search_form
    let value = action.payload
    value.boshu_only = state.roomlist.room_list_search_form.boshu_only
    room_list_search_form.rent_response_id = value.rent_response_id
    room_list_search_form.tatemono_id = value.tatemono_id
    const { payload, error } = yield call(ResponseMapSearchRoomsApi.detail, room_list_search_form);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        //   alert("ded")
        if(value.boshu_only){
            yield put(get_map_room_details_success(payload.filter((a:any) => a.kuushitsu_joukyou_no == 1)));
        }else{
            yield put(get_map_room_details_success(payload));
        }
        
        yield put(change_map_room_details_all(payload));
        yield put(change_showRight(true));

        yield put(change_map_room_details_loading(true));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_get_response_map_rooms(action: any) : any {
    const state = yield select();

    // alert(JSON.stringify(action.payload))
    // yield put(get_response_map_rooms_success([]));
    yield put(get_response_map_rooms_success([]));

    
    let room_list_search_form = state.roomlist.room_list_search_form
    room_list_search_form.rent_response_id = state.responsesReducer.responsedetail.id ? state.responsesReducer.responsedetail.id : state.customerContactsReducer.rent_customer_contacts_forms.rent_response_id

    const { payload, error } = yield call(ResponseMapSearchRoomsApi.response_list, room_list_search_form);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        //   alert()
        yield put(get_response_map_rooms_success(payload));

    } else {
        alert(error)
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_delete_map_introduction(action: any) {
    console.log(action.payload)

    const { payload, error } = yield call(RentIntroductionsApi.deleteintro, action.payload);

    if (payload && !error) {

        // alert(action.payload.rent_response_id)  
        yield put(IntroductionList.get_introductions({ rent_response_id: action.payload.rent_response_id }));
        yield put(change_introductions_loading(0));
        yield put(submit('mapsearchform'));
        yield put(get_map_room_details({ tatemono_id: action.payload.tatemono_id, rent_response_id: action.payload.rent_response_id }));

        //   yield put(get_response_map_rooms(payload));


        // } else {
        //   alert(error)
        //   //    // 失敗時アクション呼び出し
        //   //    yield put(loginFailure(payload));
        //   }
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_insert_map_to_introductions(action: any) {
    console.log(action.payload)

    yield put(change_introductions_loading(1));
    const { payload, error } = yield call(RentIntroductionsApi.insert, action.payload);

    if (payload && !error) {

        yield put(IntroductionList.get_introductions({ rent_response_id: action.payload.rentResponseId }));
        yield put(change_introductions_loading(0));
        yield put(submit('mapsearchform'));
        yield put(get_map_room_details({ tatemono_id: action.payload.tatemonoId, rent_response_id: action.payload.rentResponseId }));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}








function* handle_delete_map_stockrooms(action: any) {

    yield put(StockRooms.change_stock_rooms_loading(true));
    const { payload, error } = yield call(t_room_sheets_api.stockdelete, action.payload.id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(StockRooms.change_stock_rooms(payload));
        yield put(StockRooms.change_stock_rooms_loading(false));
        yield put(get_map_room_details({ tatemono_id: action.payload.tatemono_id, rent_response_id: action.payload.rent_response_id }));


    } else {

        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}


function* handle_insert_map_stockrooms(action: any) {
    console.log(action.payload)

    yield put(change_introductions_loading(1));
    const { payload, error } = yield call(t_room_sheets_api.insert, { tatemono_id: action.payload.tatemono_id, room_id: action.payload.room_id });

    if (payload && !error) {
        yield put(StockRooms.change_stock_rooms(payload));
        yield put(StockRooms.change_stock_rooms_loading(false));
        yield put(StockRooms.change_stock_rooms_open(true));

        yield put(get_map_room_details({ tatemono_id: action.payload.tatemono_id, rent_response_id: action.payload.rent_response_id }));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

// 敷地内駐車場
function* handle_get_map_parking_list(action: any): any {
    const state = yield select();

    let map_room_details_all = state.mapsearch.map_room_details_all
    const { payload, error } = yield call(TmParkingApi.list, map_room_details_all[0].tatemono_id);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)

        yield put(change_map_parking_list(payload));

    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}