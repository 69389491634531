import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';

import MaskedInput from 'react-text-mask';
import './Css/Grid.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import ja from "date-fns/locale/ja";
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import * as ContractFollow from './Saga/ContractFollow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ChatIcon from '@mui/icons-material/Chat';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import MenuItem from '@mui/material/MenuItem';
import MaterialSelect from '@mui/material/Select';
import EventNoteIcon from '@mui/icons-material/EventNote';
import FollowCalendar from '../Calendar/FollowCalendar';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import { Modal, Row, Col, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ResponseComponent from "../ResponseDetail/ResponseComponent";
import ListItemIcon from "@mui/material/ListItemIcon";
import * as Responses from '../ResponseDetail/Saga/Responses';
import * as Contracts from '../Contracts/Saga/Contracts';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import TodayIcon from '@mui/icons-material/Today';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import Alert from '@mui/material/Alert';

import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CheckIcon from '@mui/icons-material/Check';
import Editor from './Editor';
import WebIcon from '@mui/icons-material/Web';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';


var moment = require('moment-timezone');
let columns = []
// Table data as a list of array.
function timeMask(value) {
  const chars = value.split('');
  const hours = [
    /[0-2]/,
    chars[0] == '2' ? /[0-3]/ : /[0-9]/
  ];

  const minutes = [/[0-5]/, /[0-9]/];

  return hours.concat(':').concat(minutes);
}

var top = 0
var left = 0

var useStyles = makeStyles({
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
  },
  dialog: {
    position: 'absolute',
    left: left,
    top: top
  }
});

function SimpleDialog(props) {
  useStyles = makeStyles({
    avatar: {
      // backgroundColor: blue[100],
      // color: blue[600],
    },
    dialog: {
      position: 'absolute',
      left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
      top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),

      width: 520,
      height: 580
    }
  });

  const classes = useStyles();
  const { onClose, selectedValue, open, contract_follow_editer } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog
      }}
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      {/* <DialogTitle id="simple-dialog-title">Set backup account</DialogTitle> */}

      <Editor onClose={handleClose} />

    </Dialog>
  );
}















// Custom cell implementation with special prop
const MyCustomCell = ({ mySpecialProp }) =>
  <Cell>
    {mySpecialProp === "column2" ? "I'm column 2" : "I'm not column 2"}
  </Cell>;


const TatemonoName = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                              : ""
  }} >
    {/* <HomeWorkIcon color="primary" onClick={() => props.togglesheet(props.contract_follow_search_list[rowIndex].tatemono_id, props.contract_follow_search_list[rowIndex].room_id)} /> */}
    {props.contract_follow_search_list[rowIndex][column.key_name]}</Cell>;
const RoomName = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                              : ""
  }} >{props.contract_follow_search_list[rowIndex][column.key_name]}</Cell>;


const KanriKubun = ({ column, props, rowIndex, open_customer_window }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                              : ""
  }} >
    <span>{props.contract_follow_search_list[rowIndex].division === 1 ? "サブ"
      : props.contract_follow_search_list[rowIndex].division === 2 ? "管理"
        : props.contract_follow_search_list[rowIndex].division === 3 ? "一般"
          : props.contract_follow_search_list[rowIndex].division === 4 ? "業物"
            : props.contract_follow_search_list[rowIndex].division === 5 ? "業物折半"
              : props.contract_follow_search_list[rowIndex].division === 6 ? "専任"
                : ""}</span>
    <span>{props.contract_follow_search_list[rowIndex].division !== 1 && props.contract_follow_search_list[rowIndex].division !== 2 ? props.contract_follow_search_list[rowIndex].kanri_corp_name : ""}</span>

    <IconButton
      onClick={() => open_customer_window(props.contract_follow_search_list[rowIndex])}
      size="large"><WebIcon color="primary" /></IconButton>
  </Cell>;


const Date = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                              : ""
  }} >

    {props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}


  </Cell>;

const ContractDate = ({ column, props, rowIndex, contractdetail }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                              : ""
  }} >
    <IconButton
      onClick={() => contractdetail(props.contract_follow_search_list[rowIndex].rent_response_id, props.contract_follow_search_list[rowIndex].rent_contract_id)}
      size="large"><MenuBookIcon color="primary" /></IconButton>
    {props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}


  </Cell>;
const DateCheck = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap", background: props.contract_follow_search_list[rowIndex][column.key_name] ? "#f5f5f5" : "pink", width: "100%", height: "100%" }} >{props.contract_follow_search_list[rowIndex][column.key_name] ? moment(props.contract_follow_search_list[rowIndex][column.key_name]).tz("Asia/Tokyo").format('YYYY/MM/DD') : ""}</Cell>;


const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.contract_follow_search_list[rowIndex][column.key]}
  </Cell>;



const EndCheck = ({ column, props, rowIndex, set_contract_follow_end_bulk }) =>
  <Cell style={{ whiteSpace: "nowrap", margin: 10 }} >
    <FormControlLabel
      checked={(props.contract_follow_end_bulk.indexOf(props.contract_follow_search_list[rowIndex].rent_contract_id) !== -1)}
      // checked={props.contract_follow_search_list[rowIndex].end_check}
      onChange={() => set_contract_follow_end_bulk(props.contract_follow_search_list[rowIndex].rent_contract_id)}
      control={<Checkbox name="checkedC" />} />
  </Cell>;


function Follow({ column, props, rowIndex, contract_follow_editer, contract_follow_search_list }) {



  return <div>{
    ((contract_follow_search_list[rowIndex].counting === 2 || contract_follow_search_list[rowIndex].counting === -1) && !column.parking) ? <Cell style={{ whiteSpace: "nowrap", width: "100%", height: "50px", background: "#696969" }} > </Cell > :
      contract_follow_search_list[rowIndex].contract_form === 1 ? <Cell style={{ whiteSpace: "nowrap", width: "100%" }} >
        <div onClick={(e) => contract_follow_editer(column.key_name, rowIndex, contract_follow_search_list[rowIndex], e, false)} style={{
          borderBottom: "1px dotted  #a9a9a9", borderTop: "1px solid #a9a9a9",
          width: "100%",
          lineHeight: contract_follow_search_list[rowIndex]["remarks_" + column.key_name] ? 0.9 : 1.5,
          height: "25px",
          backgroundColor: !contract_follow_editer.nyuukyosha && column.key_name && contract_follow_editer.key_name === column.key_name && rowIndex === contract_follow_editer.row_index ? "#ff7f50"
            : contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 ? "#7fffd4"
              : contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 2 ? "#CCC"
                : ""
        }}> <div>
            {contract_follow_search_list[rowIndex]["date_" + column.key_name] ? moment(contract_follow_search_list[rowIndex]["date_" + column.key_name]).tz("Asia/Tokyo").format('M月D日') : "　"}
            {(contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 || contract_follow_search_list[rowIndex]["way_" + column.key_name] === 2) ? <Tooltip title={moment(contract_follow_search_list[rowIndex]["datetime_start_" + column.key_name]).format("H:mm") + "-" + moment(contract_follow_search_list[rowIndex]["datetime_end_" + column.key_name]).format("H:mm")}>{contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 ? <DirectionsWalkIcon color="primary" style={{ fontSize: "16px" }} /> : <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} />}</Tooltip> : ""}
          </div>



          {contract_follow_search_list[rowIndex]["remarks_" + column.key_name] ? <Tooltip title={contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}><div style={{ fontSize: "10px", background: contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 || contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 0 ? "#ffd700" : "" }}>{contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}</div></Tooltip> : ""}

        </div>
        <div onClick={(e) => contract_follow_editer(column.key_name, rowIndex, contract_follow_search_list[rowIndex], e, true)} style={{
          width: "100%",
          height: "25px",
          lineHeight: contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? 0.9 : 1.5,
          backgroundColor: contract_follow_editer.nyuukyosha && column.key_name && contract_follow_editer.key_name === column.key_name && rowIndex === contract_follow_editer.row_index ? "#ff7f50"
            : contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 ? "#7fffd4"
              : contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 2 ? "#CCC"
                : contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 4 ? "#696969"
                  : ""
        }}>
          <div>
            {props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name] ? moment(props.contract_follow_search_list[rowIndex]["date_n_" + column.key_name]).tz("Asia/Tokyo").format('M月D日') : "　"}
            {/* {props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}><ChatIcon color="primary" /></Tooltip> : ""}</div> */}
            {(props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 2) ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_n_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_n_" + column.key_name]).format("H:mm")}>{props.contract_follow_search_list[rowIndex]["way_n_" + column.key_name] === 1 ? <DirectionsWalkIcon color="primary" style={{ fontSize: "16px" }} /> : <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} />}</Tooltip> : ""}
          </div>
          {props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}><div style={{ fontSize: "10px", background: props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["progress_n_" + column.key_name] === 0 ? "#ffd700" : "" }}>{props.contract_follow_search_list[rowIndex]["remarks_n_" + column.key_name]}</div></Tooltip> : ""}
        </div>
      </Cell >
        : <Cell onClick={(e) => contract_follow_editer(column.key_name, rowIndex, props.contract_follow_search_list[rowIndex], e, false)} style={{
          whiteSpace: "nowrap", width: "100%",
          background: column.key_name && props.contract_follow_editer.key_name === column.key_name && rowIndex === props.contract_follow_editer.row_index ? "#ff7f50"
            : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') ? "#87ceeb"
              : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 && moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD') ? "pink"
                : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 ? "#7fffd4"
                  : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 2 ? "#CCC"
                    : props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 4 ? "#696969"
                      : ""
        }} >
          <div style={{ width: "100%", height: "50px", borderTop: "1px solid  #a9a9a9" }}>
            <div> {props.contract_follow_search_list[rowIndex]["date_" + column.key_name] ? moment(props.contract_follow_search_list[rowIndex]["date_" + column.key_name]).tz("Asia/Tokyo").format('M月D日') : "　"}
              {(props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 2) ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_" + column.key_name]).format("H:mm")}>{props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 1 ? <DirectionsWalkIcon color="primary" style={{ fontSize: "16px" }} /> : <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} />}</Tooltip> : ""}
              {/* : props.contract_follow_search_list[rowIndex]["way_" + column.key_name] === 2 ? <Tooltip title={moment(props.contract_follow_search_list[rowIndex]["datetime_start_" + column.key_name]).format("H:mm") + "-" + moment(props.contract_follow_search_list[rowIndex]["datetime_end_" + column.key_name]).format("H:mm")}> <DevicesOtherIcon color="secondary" style={{ fontSize: "14px" }} /></Tooltip> : ""} */}
            </div>
            {props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name] ? <Tooltip title={props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}><div style={{ background: props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 1 || props.contract_follow_search_list[rowIndex]["progress_" + column.key_name] === 0 ? "#ffd700" : "" }}>{props.contract_follow_search_list[rowIndex]["remarks_" + column.key_name]}</div></Tooltip> : ""}</div>

        </Cell >
  }</div >
};

const Staff = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                              : ""
  }}>
    {props.contract_follow_search_list[rowIndex].operation_user_id > 0 && props.contract_follow_search_list[rowIndex].user_id !== props.contract_follow_search_list[rowIndex].operation_user_id ? <div><div>{props.contract_follow_search_list[rowIndex].user_nickname}</div><div>{props.contract_follow_search_list[rowIndex].operation_user_nickname}</div></div> : <div>{props.contract_follow_search_list[rowIndex].user_nickname}</div>}
  </Cell>;

const Name = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                              : ""
  }}  ><div style={{ borderBottom: "1px dotted  #a9a9a9", width: "100%", height: "25px", textAlign: "left" }} >{props.contract_follow_search_list[rowIndex].line_img_url ? <img className="faceiconcontract" src={props.contract_follow_search_list[rowIndex].line_img_url} alt="lineimg" /> : ""}{props.contract_follow_search_list[rowIndex].name1}</div>
    <div style={{
      textAlign: "left", width: "100%", height: "25px", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                              : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                                : ""
    }}>{props.contract_follow_search_list[rowIndex].name2}</div></Cell >;

const Section = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap", width: "100%", height: "100%", background: (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                            : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"
                              : ""
  }} >{props.contract_follow_search_list[rowIndex].section_name}</Cell >;

const Status = ({ column, props, rowIndex }) =>
  <Cell style={{
    whiteSpace: "nowrap",
    height: "100%",
    width: "100%",
    background:
      (props.contract_follow_search_list[rowIndex].counting === -1 || props.contract_follow_search_list[rowIndex].counting === 0) ? "pink"
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? ""
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? ""
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? ""
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? ""
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? ""
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? ""
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? ""
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? ""
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? ""
                          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? ""
                            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? "#d7ffb3"
                              : props.contract_follow_search_list[rowIndex].counting === 2 ? "#dcdcdc"

                                : ""
  }} >
    <div>{props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 1 ? <span className="badge badge-danger">メール受信</span>
      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 2 ? <span className="badge badge-success">メール送信</span>
        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 3 ? <span className="badge badge-info">自動送信</span>
          : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 4 ? <span className="badge badge-secondary">既読</span>
            : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 5 ? <span className="badge badge-secondary">反響受信</span>
              : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 6 ? <span className="badge badge-secondary">対応</span>
                : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 7 ? <span className="badge badge-danger">マッチング物件なし</span>
                  : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 8 ? <span className="badge badge-danger"></span>
                    : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 9 ? <span className="badge badge-success">LINE送信</span>
                      : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 10 ? <span className="badge badge-secondary">最終対応</span>
                        : props.contract_follow_search_list[rowIndex].intended_rent_m_response_status_id === 12 ? <span className="badge badge-danger">LINE受信</span>

                          : ""}
      {(props.contract_follow_search_list[rowIndex].counting === 0 || props.contract_follow_search_list[rowIndex].counting === -1) ? <span className="badge  badge-info">申込審査中</span> : ""}

    </div>
    <div style={{ textAlign: "left" }}>{props.contract_follow_search_list[rowIndex].last_response_date ? moment(props.contract_follow_search_list[rowIndex].last_response_date).utc().format("YYYY/MM/DD HH:mm") : ""}</div >



  </Cell >;


var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}




function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={timeMask}
      // placeholderChar={'\u2000'}
      showMask
    />
  );
}
class CustomerList extends Component {
  constructor(props) {
    super(props);



    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      focus_start: false,
      focus_end: false,
      // open: false,
      selectedValue: false,
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.contract_follow_editer = this.contract_follow_editer.bind(this);
    this.close_contract_follow_editer = this.close_contract_follow_editer.bind(this);
    this.togglereserve = this.togglereserve.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.contractdetail = this.contractdetail.bind(this);
    // this.handle_change_user_id = this.handle_change_user_id.bind(this);
    this.change_schedule = this.change_schedule.bind(this);

    this.open_customer_window = this.open_customer_window.bind(this);

    this.handleClickOpen = this.handleClickOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.set_contract_follow_end_bulk = this.set_contract_follow_end_bulk.bind(this);


  }
  componentDidMount() {

  }
  open_customer_window(row) {
    window.open(`/customer/` + row.rent_response_id, '_blank', 'noopener')
  }

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.props.rent_m_contract_follows_disp.map(function (value) {
      if (value.key_name === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }



  contract_follow_editer(key_name, rowIndex, row, e, nyuukyosha) {
    if (this.props.contract_follow_easy_mode) {

      alert("簡単モード")
    } else {
      console.log("エディタ最初")

      top = e.target.getBoundingClientRect().top
      left = e.target.getBoundingClientRect().left
      this.handleClickOpen()
      // alert(e.target.getBoundingClientRect().x)
      if (e.target.getBoundingClientRect().x - 580 < 0 && e.target.getBoundingClientRect().x + 500 > window.innerWidth) {
        this.setState({ cardx: 0 })
      } else if (e.target.getBoundingClientRect().x - 580 >= 0) {
        this.setState({ cardx: e.target.getBoundingClientRect().x - 580 })

      }
      else if (e.target.getBoundingClientRect().x + 580 >= 0) {
        this.setState({ cardx: e.target.getBoundingClientRect().x })

      }
      let contract_follow_editer = this.props.contract_follow_editer


      contract_follow_editer.rent_contract_id = row.rent_contract_id
      contract_follow_editer.rent_m_contract_follow_id = key_name

      contract_follow_editer.open = true
      contract_follow_editer.contract_form = row.contract_form
      contract_follow_editer.title = this.props.rent_m_contract_follows_disp.filter(a => a.key_name === key_name)[0].name
      // contract_follow_editer.contract_form = row.contract_form
      contract_follow_editer.tatemono_name = row.tatemono_name
      contract_follow_editer.room_name = row.room_name
      contract_follow_editer.section_id = this.props.contract_follow_search_list[rowIndex]["section_id"] ? this.props.contract_follow_search_list[rowIndex]["section_id"] : this.props.user_details.am_section_id
      contract_follow_editer.key_name = key_name
      contract_follow_editer.row_index = rowIndex
      contract_follow_editer.is_time = this.props.rent_m_contract_follows_disp.filter(a => a.key_name === key_name)[0].is_time
      if (nyuukyosha) {

        contract_follow_editer.name = row.name2
        contract_follow_editer.nyuukyosha = true
        contract_follow_editer.user_id = this.props.contract_follow_search_list[rowIndex]["user_id_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["user_id_n_" + key_name] : this.props.user_details.id
        contract_follow_editer.date = this.props.contract_follow_search_list[rowIndex]["date_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["date_n_" + key_name] : null
        contract_follow_editer.datetime_start = this.props.contract_follow_search_list[rowIndex]["datetime_start_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["datetime_start_n_" + key_name] : null
        contract_follow_editer.datetime_end = this.props.contract_follow_search_list[rowIndex]["datetime_end_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["datetime_end_n_" + key_name] : null
        contract_follow_editer.remarks = this.props.contract_follow_search_list[rowIndex]["remarks_n_" + key_name]
        contract_follow_editer.way = this.props.contract_follow_search_list[rowIndex]["way_n_" + key_name]
        contract_follow_editer.progress = this.props.contract_follow_search_list[rowIndex]["progress_n_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["progress_n_" + key_name] : 0



      } else {
        contract_follow_editer.nyuukyosha = false
        contract_follow_editer.name = row.name1
        contract_follow_editer.user_id = this.props.contract_follow_search_list[rowIndex]["user_id_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["user_id_" + key_name] : this.props.user_details.id
        contract_follow_editer.date = this.props.contract_follow_search_list[rowIndex]["date_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["date_" + key_name] : null
        contract_follow_editer.datetime_start = this.props.contract_follow_search_list[rowIndex]["datetime_start_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["datetime_start_" + key_name] : null
        contract_follow_editer.datetime_end = this.props.contract_follow_search_list[rowIndex]["datetime_end_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["datetime_end_" + key_name] : null
        contract_follow_editer.remarks = this.props.contract_follow_search_list[rowIndex]["remarks_" + key_name]
        contract_follow_editer.way = this.props.contract_follow_search_list[rowIndex]["way_" + key_name]
        contract_follow_editer.progress = this.props.contract_follow_search_list[rowIndex]["progress_" + key_name] ? this.props.contract_follow_search_list[rowIndex]["progress_" + key_name] : 0

      }


      console.log("エディタ描画")

      this.props.change_contract_follow_editer(contract_follow_editer)
    }
    this.setState({ reflesh: !this.props.reflesh })
  }

  close_contract_follow_editer(row) {
    console.log("ddd")
    let contract_follow_editer = this.props.contract_follow_editer

    contract_follow_editer.open = false
    contract_follow_editer.key_name = 0
    contract_follow_editer.row_index = 0
    contract_follow_editer.contract_form = 0
    contract_follow_editer.name = ''
    contract_follow_editer.is_time = false

    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
  }

  change_schedule(date) {
    let contract_follow_editer = this.props.contract_follow_editer

    // alert(date.start)
    contract_follow_editer.date = date.start
    contract_follow_editer.datetime_start = date.start
    contract_follow_editer.datetime_end = date.end
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
  }
  togglereserve() {
    this.setState({ modalreserve: !this.state.modalreserve })
  }
  handleOnChange(e) {

    let contract_follow_editer = this.props.contract_follow_editer
    contract_follow_editer[e.target.name] = e.target.value
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
  }



  _onSortChange(columnKey, sortDir) {

    // alert(columnKey)
    // alert(_defaultSortIndexes.indexOf(columnKey))
    let contract_follow_search_list = this.props.contract_follow_search_list;
    contract_follow_search_list.sort((indexA, indexB) => {
      // alert(indexA)
      // alert(indexA[columnKey])
      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.props.change_contract_follow_search_list(contract_follow_search_list)
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }




  contractdetail(response_id, rent_contract_id) {
    this.props.change_modalResponseDetail(true)
    this.props.change_responseid(response_id)
    this.props.change_rent_contract_id(rent_contract_id)

  }

  handleClickOpen = () => {
    this.props.change_contract_follow_editer_open(true)
    // this.setState({ open: true })
  };

  handleClose = (value) => {
    // this.setState({ open: false })
    this.props.change_contract_follow_editer_open(false)
  };

  set_contract_follow_end_bulk = (value) => {
    // this.setState({ open: false })
    let contract_follow_end_bulk = this.props.contract_follow_end_bulk
    if (contract_follow_end_bulk.indexOf(value) !== -1) {
      contract_follow_end_bulk.splice(contract_follow_end_bulk.indexOf(value), 1)
    } else {
      contract_follow_end_bulk.push(value)

    }

    const contract_follow_search_list = this.props.contract_follow_search_list
    this.props.change_contract_follow_search_list([])
    this.props.search_contract_follow_list()
    this.props.change_contract_follow_end_bulk([])
    this.props.change_contract_follow_end_bulk(contract_follow_end_bulk)
    this.setState({ reflash: !this.state.reflash })

  };


  render() {
    // if (this.myRef.current) {
    //   console.log(this.myRef.current.getBoundingClientRect())
    //   alert()
    // }

    let contract_follow_search_list = this.props.contract_follow_search_list.map(function (value) {
      if (this.props.contract_follow_end_bulk.indexOf(value.rent_contract_id) !== -1)
        return value
    }, this).filter(a => a != undefined)
    return (
      <div id="contract_follow_list" >

        <Card style={{ width: "100%", height: "100%" }}>
          {/* {
            this.props.contract_follow_editer_loading ? <div style={{
              position: "absolute", zIndex: 3001, opacity: 0.7, width: "100%", height: "100%",
              background: "#FFF"
            }}></div> : ""
          } */}
          <CardHeader

            action={
              <IconButton
                aria-label="settings"
                onClick={() => this.props.onClose(false)}
                size="large">
                <CloseIcon />
              </IconButton>
            }
            title="本当に契約フォローを完了してよろしいですか？"
          // subheader={<div style={{ color: "#000", fontWeight: "bold", fontSize: 20 }}><CalendarTodayIcon /> {this.props.contract_follow_editer.title}</div>}
          />
          <CardContent >

            <Table
              rowHeight={50}
              rowsCount={contract_follow_search_list.length}
              onColumnResizeEndCallback={this._onColumnResizeEndCallback}
              isColumnResizing={false}
              touchScrollEnabled={true}
              width={550}
              height={400}

              headerHeight={40}>
              {this.props.rent_m_contract_follows_disp.map(function (value, i) {
                return <Column
                  columnKey={value.key_name}
                  header={<SortHeaderCell
                    onSortChange={this._onSortChange}
                    sortDir={this.state.colSortDirs[value.key_name]}
                    style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                  // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                  cell={({ rowIndex, ...props }) => (

                    // value.key_name === "tatemono_name" ? <TatemonoName column={value} props={this.props} rowIndex={rowIndex}></TatemonoName>
                    //   : value.key_name === "room_name" ? <RoomName column={value} props={this.props} rowIndex={rowIndex}></RoomName>
                    //     : value.key_name === "kanri_kubun" ? <KanriKubun column={value} props={this.props} rowIndex={rowIndex} open_customer_window={this.open_customer_window}></KanriKubun>

                    //       : value.key_name === "application_date" ? <Date column={value} props={this.props} rowIndex={rowIndex}></Date>
                    //         : value.key_name === "contract_date" ? <ContractDate column={value} props={this.props} rowIndex={rowIndex} contractdetail={this.contractdetail}></ContractDate>
                    //           : value.key_name === "name1" ? <Name column={value} props={this.props} rowIndex={rowIndex}></Name>

                    //             : value.key_name === "section_name" ? <Section column={value} props={this.props} rowIndex={rowIndex}></Section>

                    //               : value.key_name === "user_nickname" ? <Staff column={value} props={this.props} rowIndex={rowIndex}></Staff>
                    //                 : value.key_name === "operation_user_nickname" ? <Base column={value} props={this.props} rowIndex={rowIndex}></Base>

                    //                   : value.key_name === 2 ? <Follow column={value} props={this.props} rowIndex={rowIndex} contract_follow_editer={this.contract_follow_editer}></Follow>
                    //                     : value.key_name === "status" ? <Status column={value} props={this.props} rowIndex={rowIndex}></Status>

                    value.key_name === "end_check" ? <Cell style={{ whiteSpace: "nowrap", margin: 10 }} >
                      <FormControlLabel
                        checked={(this.props.contract_follow_end_bulk.indexOf(contract_follow_search_list[rowIndex].rent_contract_id) !== -1)}
                        // checked={props.contract_follow_search_list[rowIndex].end_check}
                        onChange={() => this.set_contract_follow_end_bulk(contract_follow_search_list[rowIndex].rent_contract_id)}
                        control={<Checkbox name="checkedC" />} />
                    </Cell>

                      : <Cell >

                        {contract_follow_search_list[rowIndex][value.key_name]}

                      </Cell>

                  )}

                  width={this.props.rent_m_contract_follows_disp.filter(a => a.key_name === value.key_name)[0].width}
                  isResizable={true}
                  fixed={value.frozen}

                />
              }, this)}





            </Table >

          </CardContent >


          <CardContent >
            {/* {this.state.first_date ? (this.state.delete_set ? <Alert severity="error" style={{ margin: 5 }}>削除してよろしいですか？<Button variant="contained" style={{ float: "right", margin: 5 }} onClick={() => this.setState({ delete_set: false })}>
              キャンセル
            </Button><Button variant="contained" color="secondary" style={{ float: "right", margin: 5 }} onClick={this.props.delete_rent_contract_follow_details}>
                削除する
              </Button></Alert> : <Button variant="contained" color="secondary" style={{ float: "right", margin: 5 }} onClick={() => this.setState({ delete_set: true })}>
              削除
            </Button>) : ""} */}
            {this.props.contract_follow_search_list.length ?
              <Button variant="contained" color="primary" style={{ float: "right", margin: 5 }} onClick={this.props.set_contract_follow_end_bulk}>
                保存
              </Button> : <Alert severity="error" style={{ margin: 5 }}>完了する契約を選択してください。</Alert>}
          </CardContent >
        </Card >


      </div >
    );
  }
};


// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

//export  default CustomerList;
const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    contract_follow_search_list: state.contractfollow.contract_follow_search_list,
    rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    contract_follow_editer: state.contractfollow.contract_follow_editer,
    modalResponseDetail: state.responsesReducer.modalResponseDetail,
    responseid: state.responsesReducer.responseid,
    contract_follow_editer_loading: state.contractfollow.contract_follow_editer_loading,
    contract_follow_editer_open: state.contractfollow.contract_follow_editer_open,
    contract_follow_end_bulk: state.contractfollow.contract_follow_end_bulk,
    contract_follow_easy_mode: state.contractfollow.contract_follow_easy_mode,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_contract_follow_editer(state) {
      dispatch(ContractFollow.change_contract_follow_editer(state))
    },
    change_contract_follow_list(state) {
      dispatch(ContractFollow.change_contract_follow_list(state))
    },
    change_contract_follow_search_list(state) {
      dispatch(ContractFollow.change_contract_follow_search_list(state))
    },
    get_contract_follow_list(state) {
      dispatch(ContractFollow.get_contract_follow_list(state))
    },
    change_responseid(state) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

    change_rent_contract_id(state) {
      dispatch(Contracts.change_rent_contract_id(state))
    },

    update_rent_contract_follow_details(state) {
      dispatch(ContractFollow.update_rent_contract_follow_details(state))
    },
    change_contract_follow_editer_open(state) {
      dispatch(ContractFollow.change_contract_follow_editer_open(state))
    },

    change_contract_follow_end_bulk(state) {
      dispatch(ContractFollow.change_contract_follow_end_bulk(state))
    },
    search_contract_follow_list(state) {
      dispatch(ContractFollow.search_contract_follow_list(state))
    },
    set_contract_follow_end_bulk(state) {
      dispatch(ContractFollow.set_contract_follow_end_bulk(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);