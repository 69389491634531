import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';

// import './Css/Grid.css'
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import * as Contracts from './Saga/Contracts';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import * as Responses from '../ResponseDetail/Saga/Responses';
import CancelIcon from '@mui/icons-material/Cancel';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import * as Contracts from './Saga/Contracts';
import Select from '@mui/material/Select';
import MobileFriendly from '@mui/icons-material/MobileFriendly';
import MaskedInput from 'react-text-mask';
var moment = require('moment-timezone');


const phoneNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];


const mobileNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
function TelMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" ? mobileNumberMask : phoneNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_families_disp[rowIndex][column.key]}
  </Cell>;

const FirstPass = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex][column.key] : props.rent_contract_families_disp[rowIndex][column.key]}
  </Cell>;

const RoomSupportMUserId = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? 
      props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex][column.key] ? <MobileFriendly style={{color:"#4abdba"}} /> : ""
    : props.rent_contract_families_disp[rowIndex][column.key] ? <MobileFriendly style={{color:"#4abdba"}} /> : ""}
  </Cell>;

const Sex = ({ column, props, rowIndex, handleChange }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <Select
      labelId="demo-simple-select-outlined-label"
      id="demo-simple-select-outlined"
      style={{ width: 30 }}
      name="sex"
      size="small"
      variant='standard'
      value={props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex][column.key] ? props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex][column.key] : 1}
      // value={props.rent_contract_families[rowIndex][column.key]}
      // defaultValue={this.props.rent_contract_editor_forms.room_id ? { value: this.props.rent_contract_editor_forms.room_id, label: this.props.rent_contract_editor_forms.room_name } : {}}
      // value={this.props.rent_contract_editor_forms.room_id}
      onChange={(e) => handleChange(e, rowIndex)}
    // label="部屋名"
    >
      <MenuItem value={1}>
        男
      </MenuItem>
      <MenuItem value={2}>
        女
      </MenuItem>
    </Select> : props.rent_contract_families_disp[rowIndex][column.key] === 1 ? "男" : "女"}
  </Cell>;

const Birthday = ({ column, props, rowIndex, handleChange }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="date"
      // label="生年月日"
      type="date"
      name="birthday"
      size="small"
      variant='standard'
      style={{ width: 130 }}
      onChange={(e) => handleChange(e, rowIndex)}
      value={props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex][column.key] ? moment(props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex][column.key]).format('YYYY-MM-DD') : ""}
      // className={classes.textField}
      InputLabelProps={{
        shrink: true,
      }}
    /> :
      props.rent_contract_families_disp[rowIndex][column.key] ? <div style={{ lineHeight: 1 }}> <div>{moment(props.rent_contract_families_disp[rowIndex][column.key]).format("YYYY年M月D日")}</div>  <div>{"(" + age(props.rent_contract_families_disp[rowIndex][column.key]) + "歳)"}</div> </div> : ""}
  </Cell >


function age(birthDate) {
  birthDate = new Date(birthDate);
  // 文字列に分解
  const y2 = birthDate.getFullYear().toString().padStart(4, '0');
  const m2 = (birthDate.getMonth() + 1).toString().padStart(2, '0');
  const d2 = birthDate.getDate().toString().padStart(2, '0');

  // 今日の日付
  const today = new Date();
  const y1 = today.getFullYear().toString().padStart(4, '0');
  const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
  const d1 = today.getDate().toString().padStart(2, '0');

  // 引き算
  const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
  return age;

}

const Name = ({ column, props, rowIndex, family, handleChange }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="name"
      size="small"
      variant='standard'
      onChange={(e) => handleChange(e, rowIndex)}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      value={props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex].name}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.rent_contract_families_disp[rowIndex].name}

  </Cell>;


const Workplace = ({ column, props, rowIndex, family, handleChange }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="workplace"
      onChange={(e) => handleChange(e, rowIndex)}
      size="small"
      variant='standard'
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      value={props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex].workplace}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.rent_contract_families_disp[rowIndex].workplace}

  </Cell>;

const Tel = ({ column, props, rowIndex, family, handleChange }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ?
      <TextField
        // variant="outlined"
        name="tel"
        // label="電話番号1"
        type="tel"
        size="small"
        variant='standard'
        value={props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex].tel}
        onChange={(e) => handleChange(e, rowIndex)}
        id="formatted-numberformat-input"
        style={{ float: "left", width: "100%", paddingRight: "5px" }}

        InputProps={{
          inputComponent: TelMaskCustom,
        }}
      // InputLabelProps={{
      //   shrink: true,
      // }}
      />


      //   <TextField
      //   id="outlined-basic"
      //   //   className={classes.textField}
      //   label=""
      //   margin="normal"
      //   name="tel"
      //   // onChange={this.handleChange}
      //   inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      //   // value={monies[rowIndex][column.key]}
      //   style={{ width: "80px", marginTop: 0 }}

      // />
      : props.rent_contract_families_disp[rowIndex].tel}

  </Cell>;

const Nationality = ({ column, props, rowIndex, family, handleChange }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="nationality"
      onChange={(e) => handleChange(e, rowIndex)}
      size="small"
      variant='standard'
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      value={props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex].nationality}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.rent_contract_families_disp[rowIndex].nationality}

  </Cell>;

const Remarks = ({ column, props, rowIndex, family, handleChange }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ? <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="remarks"
      size="small"
      variant='standard'
      onChange={(e) => handleChange(e, rowIndex)}
      inputProps={{ min: 0, style: { textAlign: 'left' } }} // the change is here
      value={props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex].remarks}
      style={{ width: "80px", marginTop: 0 }}

    /> : props.rent_contract_families_disp[rowIndex].remarks}

  </Cell>;

const Relations = ({ column, props, rowIndex, family, handleChange }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp ?
      <Select
        name="relations"
        value={props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex].relations}
        style={{ padding: 0 }}
        size="small"
        variant='standard'
        onChange={(e) => handleChange(e, rowIndex)}
        label="">
        <MenuItem value={"本人"}>本人</MenuItem>
        <MenuItem value={"婚約者"}>婚約者</MenuItem>
        <MenuItem value={"父"}>父</MenuItem>
        <MenuItem value={"母"}>母</MenuItem>
        <MenuItem value={"兄"}>兄</MenuItem>
        <MenuItem value={"姉"}>姉</MenuItem>
        <MenuItem value={"弟"}>弟</MenuItem>
        <MenuItem value={"妹"}>妹</MenuItem>
        <MenuItem value={"祖父"}>祖父</MenuItem>
        <MenuItem value={"祖母"}>祖母</MenuItem>
        <MenuItem value={"妻"}>妻</MenuItem>
        <MenuItem value={"夫"}>夫</MenuItem>
        <MenuItem value={"子"}>子</MenuItem>
        <MenuItem value={"社員"}>社員</MenuItem>
      </Select> : props.rent_contract_families_disp[rowIndex].relations}

  </Cell>;





const Delete = ({ column, props, rowIndex, monies, handleDel }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_contract_family_editor_disp && !props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[rowIndex].room_support_m_user_id ? <Tooltip title="削除" >
      <IconButton size="small" aria-label="delete" name="is_deleted" onClick={(e) => handleDel(rowIndex)}>
        <DeleteForeverIcon color="secondary" />{}
      </IconButton>
    </Tooltip> : ""}
  </Cell>;




var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns = [
  { key: 'room_support_m_user_id', name: '', width: 40 },
  { key: 'name', name: '入居者名', width: 100 },
  { key: 'relations', name: '関係', width: 70 },
  {
    key: 'sex', name: '性別', width: 40,

  },
  { key: 'birthday', name: '生年月日', width: 140 },
  { key: 'workplace', name: '勤務先・学校', width: 90 },
  { key: 'tel', name: 'ＴＥＬ', width: 110 },
  { key: 'nationality', name: '国籍', width: 90 },
  { key: 'remarks', name: '備考', width: 90 },
  { key: 'first_pass', name: 'アプリID', width: 90 },
  { key: 'delete', name: '削除', width: 40 },

]







class CustomerList extends Component {
  constructor(props) {
    super(props);


    // this.props.rent_m_contract_follows_disp.map(async function (value) {
    //   if (value.disp) {
    //     columns.push({ key: value.key_name, name: value.name, company: value.company, width: 80 })
    //   }
    // })
    console.log(columns)

    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},

      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);



    this.add_new = this.add_new.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDel = this.handleDel.bind(this);
    this.add_keiyakusya = this.add_keiyakusya.bind(this);

    // this.props.check_rent_contract_editor_disp({ editor: "rent_contract_family_editor_disp", disp: false })
    // rent_contract_editor_forms.rent_contract_id ? this.props.get_rent_contract_families(rent_contract_editor_forms.rent_contract_id):""

  }





  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }



  add_new() {
    let rent_contract_families = this.props.rent_contract_families
    // let family = rent_contract_editor_forms.family.map(function (value) {
    //   return value
    // })

    rent_contract_families.push({
      id: "",
      rent_contract_id: this.props.rent_contract_details.id,
      name: "",
      relations: "",
      sex: 1,
      birthday: null,
      workplace: '',
      tel: '',
      nationality: '',
      remarks: '',
      is_deleted: 0,
      is_changed: 0,
      first_pass : '',
      room_support_m_user_id : '',

    })
    // rent_contract_editor_forms.family = family
    this.props.change_rent_contract_families(rent_contract_families)

    this.setState({ reflash: !this.state.reflash })
  }

  add_keiyakusya() {
    let rent_contract_families = this.props.rent_contract_families
    // let family = rent_contract_editor_forms.family.map(function (value) {
    //   return value
    // })
    let m_country = [{ id: 0, value: "日本" }, { id: 1, value: "中国" }, { id: 2, value: "ベトナム" }, { id: 3, value: "ネパール" }, { id: 4, value: "韓国" }, { id: 5, value: "台湾" }, { id: 6, value: "その他" }]
    rent_contract_families.push({
      id: "",
      rent_contract_id: this.props.rent_contract_details.id,
      name: this.props.rent_contract_details.name1,
      relations: "本人",
      sex: this.props.rent_contract_details.sex,
      birthday: this.props.rent_contract_details.birthday,
      workplace: this.props.rent_contract_details.office_name,
      tel: this.props.rent_contract_details.tel1,
      nationality: this.props.rent_contract_details.rent_m_country_id ? m_country.filter(e => e.id === this.props.rent_contract_details.rent_m_country_id)[0].value : "",
      remarks: '',
      is_deleted: 0,
      is_changed: 0,
      first_pass : '',
      room_support_m_user_id : '',
    })
    // rent_contract_editor_forms.family = family
    this.props.change_rent_contract_families(rent_contract_families)

    this.setState({ reflash: !this.state.reflash })
  }

  handleChange(e, index) {

    let rent_contract_families = this.props.rent_contract_families


    rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[index][e.target.name] = e.target.value

    rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[index].is_changed = 1

    this.props.change_rent_contract_families(rent_contract_families)
    this.setState({ reflash: !this.state.reflash })

  }

  handleDel(index) {
    // console.log(this.props.claso_blog_places)
    let rent_contract_families = this.props.rent_contract_families

    rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted)[index].is_deleted = 1

    this.props.change_rent_contract_families(rent_contract_families)
    this.setState({ reflash: !this.state.reflash })

  }

  handleUpdate() {

    this.props.update_rent_contract_families(this.props.rent_contract_details.id)

    this.props.change_rent_contract_family_editor_disp(false)

    this.setState({ reflash: !this.state.reflash })
  }




  render() {
    let hiwari = []

    return (
      <div id="contract_car" >


        {this.props.rent_contract_family_editor_disp ? <Tooltip title="閉じる" style={{ float: "right" }}>
          <IconButton
            aria-label="delete"
            onClick={() => this.props.change_rent_contract_family_editor_disp(false)}
            size="large">
            <CancelIcon />
          </IconButton>
        </Tooltip> : ""}

        {this.props.rent_contract_family_editor_disp ? <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary"
          // onClick={this.props.change_rent_contract_family_editor_disp}
          onClick={() => this.handleUpdate()}
        >
          保存
        </Button> :
          <Button style={{ float: "right", margin: 5 }} size="small" variant="outlined" color="primary"

            onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_family_editor_disp", disp: true })}
          >
            編集
          </Button>}
        {this.props.rent_contract_family_editor_disp ?
          <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={this.add_keiyakusya}>
            契約者を追加
          </Button> : ""}
        {this.props.rent_contract_family_editor_disp ? <Tooltip title="追加" style={{ float: "right" }}>
          <IconButton aria-label="add" onClick={this.add_new} size="large">
            <AddBoxIcon color="primary" />
          </IconButton>
        </Tooltip> : ""}






        {/* {this.props.rent_contract_editor_forms.family?this.props.rent_contract_editor_forms.family.length:""} */}
        <div style={{ marginTop: 10, marginBottom: 10 }}>
          {this.props.rent_contract_families ? <Table
            rowHeight={40}
            rowsCount={this.props.rent_contract_family_editor_disp ? this.props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted).length : this.props.rent_contract_families_disp.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            // width={window.innerWidth / 2 - 100}
            width={window.innerWidth >= 992 ? (window.innerWidth / 2) - 70 : window.innerWidth - 75}
            height={this.props.rent_contract_family_editor_disp ? this.props.rent_contract_families.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted).length * 40 + 60 : this.props.rent_contract_families_disp.length * 40 + 60}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            headerHeight={40}>
            {this.state.columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "sex" ? <Sex column={value} props={this.props} rowIndex={rowIndex} family={this.state.family} handleChange={this.handleChange}></Sex>
                    : value.key === "birthday" ? <Birthday column={value} props={this.props} rowIndex={rowIndex} family={this.state.family} handleChange={this.handleChange}></Birthday>
                      : value.key === "relations" ? <Relations column={value} props={this.props} rowIndex={rowIndex} handleChange={this.handleChange}></Relations>

                        : value.key === "name" ? <Name column={value} props={this.props} rowIndex={rowIndex} handleChange={this.handleChange} ></Name>
                          : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} handleDel={this.handleDel}></Delete>

                            : value.key === "workplace" ? <Workplace column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} handleChange={this.handleChange}></Workplace>
                              : value.key === "tel" ? <Tel column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} handleChange={this.handleChange}></Tel>
                                : value.key === "nationality" ? <Nationality column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} handleChange={this.handleChange}></Nationality>
                                  : value.key === "remarks" ? <Remarks column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} handleChange={this.handleChange}></Remarks>
                                  : value.key === "first_pass" ? <FirstPass column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} handleChange={this.handleChange} />
                                  : value.key === "room_support_m_user_id" ? <RoomSupportMUserId column={value} props={this.props} rowIndex={rowIndex} monies={hiwari} handleChange={this.handleChange} />


                                    // :
                                    : <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table > : ""} </div>





      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_family_editor_disp: state.contracts.rent_contract_family_editor_disp,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    // rent_contract_editor_forms_family_disp: state.contracts.rent_contract_editor_forms.family.filter(a => parseInt(a.is_deleted) == 0 || !a.is_deleted),

    rent_contract_families: state.contracts.rent_contract_families,
    rent_contract_families_disp: state.contracts.rent_contract_families_disp,
    // rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_id: state.contracts.rent_contract_id,

  }
}
function mapDispatchToProps(dispatch) {
  return {


    change_responseid(state) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

    change_rent_contract_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_editor_forms(state))
    },
    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    update_rent_contract_families(state) {
      dispatch(Contracts.update_rent_contract_families(state))
    },

    get_rent_contract_families(state) {
      dispatch(Contracts.get_rent_contract_families(state))
    },
    change_rent_contract_families(state) {
      dispatch(Contracts.change_rent_contract_families(state))
    },
    change_rent_contract_family_editor_disp(state) {
      dispatch(Contracts.change_rent_contract_family_editor_disp(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);