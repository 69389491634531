import React from 'react';
import '../../Css/Ul.css';
import MailTemplateAddCategryComponent from './MailTemplateAddCategryComponent'
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import { connect } from 'react-redux';
import * as MailTemplate from './Saga/MailTemplate';
import withStyles from '@mui/styles/withStyles';
const data =
    [
        {
            name: '新規対応',
            id: 0,
            children: [
                { name: 'child1' },
                { name: 'child2' }
            ]
        },
        {
            name: 'loading parent',
            id: 1,
            children: [{ name: 'child1' },
            { name: 'child2' }]
        },

    ];


const data2 = {
    id: 'root',
    name: 'Parent',
    children: [
        {
            id: '1',
            name: 'Child - 1',
        },
        {
            id: '3',
            name: 'Child - 3',
            children: [
                {
                    id: '4',
                    name: 'Child - 4',
                },
            ],
        },
    ],
};

const styles = ((theme) => ({
    root: {
        height: 110,
        flexGrow: 1,
        maxWidth: 400,
    },
}))
class MailTemplateListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            editCategoryId: 0
        };
        this.onToggle = this.onToggle.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.close = this.close.bind(this);
        this.handleSubmitUpdate = this.handleSubmitUpdate.bind(this);
        this.handleSubmitDelete = this.handleSubmitDelete.bind(this);
        this.renderTree = this.renderTree.bind(this);

        //     this.props.mail_templates.map((template, key) =>
        //         // let value = {}
        //         // template.id
        // })



    }

    onToggle(node, toggled) {
        console.log(node.name)
        const { cursor } = this.state;
        if (cursor) { cursor.active = false }
        node.active = true;
        if (node.children) { node.toggled = toggled; }
        this.setState({ cursor: node });

    }
    handleSubmit(e) {
        e.sectionId = 26;
        this.props.add_mail_templates_category(e)
        console.log(e)

    }
    handleSubmitUpdate(e) {
        e.id = this.state.editCategoryId
        e.sectionId = 26;
        this.props.update_mail_templates_category(e)
        this.setState({ editCategoryId: 0 });
    }

    handleSubmitDelete(e) {

        this.props.delete_mail_templates_category(this.state.editCategoryId)
        // this.setState({ editCategoryId: 0 });
    }

    close() {
        this.setState({ editCategoryId: 0 });
    }

    renderTree = (nodes) => (
        <TreeItem key={nodes.id} nodeId={nodes.id} label={nodes.name}>
            {Array.isArray(nodes.children) ? nodes.children.map((node) => this.renderTree(node)) : null}
        </TreeItem>
    );
    render() {

        console.log(this.props.mail_templates)
        return (
            <div>
                <div style={{ padding: 5 }}>      </div>
                <MailTemplateAddCategryComponent
                    // handleSubmit={this.handleSubmit}
                    onSubmit={this.handleSubmit}
                />
                {/* {this.props.mail_templates.map((item, key) => <div key={key}>
                    <ListGroupItem color="primary" >
                        {this.state.editCategoryId !== item.id ? <span>{item.categoryName}
                            <Button color="success" size="sm" onClick={() => this.setState({ editCategoryId: item.id })} className="float-right">編集</Button><Button onClick={() => this.props.settemplate(item.id, 0)} color="primary" size="sm" className="float-right">追加</Button>
                        </span> : <MailTemplateUpdateCategryComponent onSubmit={this.handleSubmitUpdate} handleSubmitDelete={this.handleSubmitDelete} categoryName={item.categoryName} close={this.close} />}
                    </ListGroupItem>

                    {item.template.map((template, key) =>
                        <ListGroupItem
                            key={key}>
                            {template.templateName}
                            <Button className="float-right" onClick={() => this.props.settemplate(item.id, template.id)} color="success" size="sm">編集</Button></ListGroupItem>)}

                </div>)} */}
aaa
                <TreeView
                    className={this.props.classes.root}
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={['root']}
                    defaultExpandIcon={<ChevronRightIcon />}
                >
                    {this.renderTree(data2)}
                </TreeView>
            </div>
        )

    }
}



const mapStateToProps = (state, ownProps) => {
    return {
        // responsedetail: state.responsesReducer.responsedetail,

    }
}
function mapDispatchToProps(dispatch) {
    return {

        add_mail_templates_category(state) {
            dispatch(MailTemplate.add_mail_templates_category(state))
        },
        update_mail_templates_category(state) {
            dispatch(MailTemplate.update_mail_templates_category(state))
        },
        delete_mail_templates_category(state) {
            dispatch(MailTemplate.delete_mail_templates_category(state))
        },


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MailTemplateListComponent));

