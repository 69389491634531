
const authentication = require('../../react-azure-adb2c2').default;
export const RentMReasonsList = (values: any) => {
    const url = process.env.REACT_APP_API_BASE_URL + `/api/rentMReasons/`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => (res.json()))
        .catch(error => ({ error }));
}
