
const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;
// const url = `http://localhost:5000/v1/tm/parkingarea/list/` + values;



    const url = process.env.REACT_APP_API_CRM_URL + `/v1/tm/parkingarea/list/` + values;
    // const url = `http://localhost:5000/v1/tm/parkingarea/list/` + values;
    
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const select_section = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/v1/tm/parkingarea/selectSection/`;
    const url = `http://localhost:5000/v1/tm/parkingarea/selectSection/`;
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const ResendInvoice = (values: any) => {
    // const url = `http://localhost:5000/api/v1/contractsheet/ResendInvoice/`+ values.id + '/A4/' + values.mailId;
    const url = process.env.REACT_APP_API_CRM_URL + "/api/v1/contractsheet/ResendInvoice/"+ values.id + '/A4/' + values.mailId;
    const token = authentication.getAccessToken();
    // //const search = values ? values : {};
    // // alert(JSON.stringify(values))
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({error }));

}