import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import CorporatesPlaceDialog from "./CorporatesPlaceDialog";

import { Modal } from 'reactstrap';
import { Table, Column, Cell } from 'fixed-data-table-2';

import 'fixed-data-table-2/dist/fixed-data-table.css';

import CheckIcon from '@mui/icons-material/Check';
import PlaceIcon from '@mui/icons-material/Place';

import Button from '@mui/material/Button';
import MaskedInput from 'react-text-mask';
import ControlPointIcon from '@mui/icons-material/ControlPoint';

import Geocode from 'react-geocode';
import styled from 'styled-components'





const phoneNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];


const mobileNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

const postNumberMask = [

  /[0-9]/,
  /\d/,
  /\d/,

  "-",

  /\d/,
  /\d/,
  /\d/,
  /\d/,

];


function TelMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
  
      <MaskedInput
        {...other}
        // ref={inputRef}
        mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" || !props.value ? mobileNumberMask : phoneNumberMask}
        placeholderChar={'\u2000'}
      // showMask
      />
    );
  }

function PostMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={postNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: true,
    fullscreenControl: false,
    // styles: [
    //   {
    //     featureType: 'water',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#e9e9e9',
    //       },
    //       {
    //         lightness: 17,
    //       },
    //     ],
    //   },

  }
}

const Pin = styled.div``
class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false,

            zoom: 14,
            draggable: true,
            lat: 34.36179852,
            lng: 134.048788977,
            geo_lat : "",
            geo_lng : "",
            center: null,

            columnWidths: {
                affiliate: 100,
                name: 90,
                add: 200,
                rent_m_agent_id: 120,
                contact1: 100,
                contact2: 100,
                contact3: 100,
                agent_remarks: 150,
                affiliate_remarks: 150,
            },
        };

        this.handleOpen = this.handleOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        this.props.get_rent_m_agents_list()

        this.onChildMouseMove = this.onChildMouseMove.bind(this);
        this.onChildMouseUp = this.onChildMouseUp.bind(this);
        this.handleZoomChanged = this.handleZoomChanged.bind(this);
        this.getGeocode = this.getGeocode.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this.RowClick = this.RowClick.bind(this);
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
          columnWidths: {
            ...columnWidths,
            [columnKey]: newColumnWidth,
          },
        }));
    }


    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    onChange(state) {
        // console.log(this.props.claso_blog_places)
        let rent_corporate_offices = this.props.rent_corporate_offices

        if (state.target.name == "affiliate" || state.target.name == "request_tatemono_types" || state.target.name == "jyukenyotei") {

            if (rent_corporate_offices[state.target.name] && rent_corporate_offices[state.target.name].split(",").includes(state.target.value)) {

                //削除
                rent_corporate_offices[state.target.name] = rent_corporate_offices[state.target.name].split(",").filter(item => parseInt(item) != parseInt(state.target.value)).join(',')
            } else {

                let ary = rent_corporate_offices[state.target.name] ? rent_corporate_offices[state.target.name].split(",") : []

                ary.push(parseInt(state.target.value))

                ary = ary.filter(Boolean)

                rent_corporate_offices[state.target.name] = ary.join(',')
            }
        }else if (state.target.name === "is_main") {
            rent_corporate_offices[state.target.name] = state.target.checked ? 1 : 0
        }else{
            rent_corporate_offices[state.target.name] = state.target.value
        }

        this.props.change_rent_corporate_offices(rent_corporate_offices)
        this.setState({
            change: !this.state.change
        });

    }

    handleClose(){
        this.setState({ "modal": false, "center":null })
    }

    handleOpen(id) {
        if (id) {
            this.props.set_rent_corporate_offices(id)
        } else {
            // 新規
            let rent_corporate_offices = { id: '', rent_m_corporate_id: this.props.rent_m_corporates.id, rent_m_corporate_office_id: 0, post_card: 0, in_office: 0, remarks: '', name1:"", name2:'', department:'', position:'', tel:'', fax:'', mail:'', remarks:'', is_deleted: 0, created_at: '', created_user_id: '' }
            this.props.change_rent_corporate_offices(rent_corporate_offices)
        }

        this.setState({ "modal": true })
    }

    handleUpdate(id, delflg) {

        let rent_corporate_offices = this.props.rent_corporate_offices
        rent_corporate_offices["is_deleted"] = delflg
        this.props.change_rent_corporate_offices(rent_corporate_offices)

        if (id) {
            this.props.update_rent_corporate_offices()
        } else {
            this.props.insert_rent_corporate_offices()
        }

        this.setState({
            modal: false,
            modal_del: false,
            change: !this.state.change
        });

    }

    _onBoundsChange = (center, zoom, bounds, marginBounds) => {
        this.setState({ zoom: zoom })
      }
    
      handleZoomChanged(){


    }
      onChildMouseUp() {
        this.setState({ draggable: true })
      }
      onChildMouseMove(childKey, childProps, mouse) {

          console.log(mouse)
        // function is just a stub to test callbacks
        this.setState({
          draggable: false,
          lat: mouse.lat,
          lng: mouse.lng,
    
        });

        // if(this.state.zoom < 14){
            this.setState({
                center: {lat:mouse.lat, lng:mouse.lng},
            });
        // }


        let rent_corporate_offices =  this.props.rent_corporate_offices

        rent_corporate_offices["lat"] = mouse.lat
        rent_corporate_offices["lng"] = mouse.lng

        this.props.change_rent_corporate_offices(rent_corporate_offices)
        this.setState({
            change: !this.state.change
        });


        // this.props.mapchange(mouse.lat, mouse.lng)
    
      }

    getGeocode() {
        // alert(this.props.tenant_details.address1 + this.props.tenant_details.address2 + this.props.tenant_details.address3 + this.props.tenant_details.address4 + this.props.tenant_details.address5)
        Geocode.setApiKey('AIzaSyDcEVxG_-xtQ1uCku--0iw84NPOffxow28');
        Geocode.setLanguage("ja");
        Geocode.fromAddress((this.props.rent_corporate_offices.add1 ? this.props.rent_corporate_offices.add1 : "") + (this.props.rent_corporate_offices.add2 ? this.props.rent_corporate_offices.add2 :""))
          .then((response) => {
    
            const { lat, lng } = response.results[0].geometry.location;
    
            let rent_corporate_offices =  this.props.rent_corporate_offices
            rent_corporate_offices.lat = lat
            rent_corporate_offices.lng = lng

            this.props.change_rent_corporate_offices(rent_corporate_offices)
            this.setState({
                center: {lat:lat, lng:lng},
                change: !this.state.change
            });


          })
          .catch((a) => {
            console.log(a)
            this.setState({
              lat: undefined,
              lng: undefined,
            });
          });
    
    }

    RowClick(event,index){

        this.props.set_rent_corporate_offices(this.props.rent_corporate_offices_list[index].id)

        this.setState({ "modal": true })

    }

    _rowClassNameGetter(index){
        
        if(this.props.rent_corporate_offices_list && this.props.rent_corporate_offices_list[index]) {
        //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            return "normal"
        }else{
          return ""
        }
    }

    render() {

        return (
            <div>
                <div style={{ float: "left" }} ><PlaceIcon style={{ color: "#5cb85c" }}></PlaceIcon> 拠点情報</div>
                <Button style={{ float: "right" }} size="small" variant="contained" color="primary" startIcon={<ControlPointIcon />} onClick={(e) => { this.handleOpen("") }}>新規</Button>
                <div style={{clear:"both"}}></div>
                <Table 
                    className="gridCorporateNormalList"
                    rowHeight={34} 
                    rowsCount={this.props.rent_corporate_offices_list.length} 
                    height={200} 
                    headerHeight={30} 
                    width={(window.innerWidth / 2) - 75}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    onRowClick={this.RowClick}
                    rowClassNameGetter={this._rowClassNameGetter}
                >
                    {/* <Column width={40} header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} onClick={(e) => { this.handleOpen(this.props.rent_corporate_offices_list[rowIndex].id); }} aria-label="edit" size="small"><EditIcon /></IconButton></Cell>)} /> */}
                    <Column width={60} header={<Cell>メイン</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].is_main == 1 ? <CheckIcon />:""}</Cell>)} />
                    <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>拠点名</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].name}</Cell>)} />
                    <Column width={this.state.columnWidths.affiliate} isResizable={true} columnKey="affiliate" header={<Cell>提携内容</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>
                        {

                            this.props.rent_corporate_offices_list[rowIndex].affiliate ? this.props.rent_corporate_offices_list[rowIndex].affiliate.split(',').map(function (item, key) {
                                return (
                                    <span key={key}>
                                        {item === "1" ? "間接提携、" : ""}
                                        {item === "2" ? "法人業務提携、" : ""}
                                        {item === "3" ? "福利厚生提携、" : ""}
                                        {item === "4" ? "他県案件送客、" : ""}
                                        {item === "5" ? "専属提携、" : ""}
                                        {item === "6" ? "専任提携、" : ""}
                                    </span>
                                )
                            }) : ""
                        }
                        </Cell>)} />
                    <Column width={this.state.columnWidths.add} isResizable={true} columnKey="add" header={<Cell>住所</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].post}{this.props.rent_corporate_offices_list[rowIndex].add1}{this.props.rent_corporate_offices_list[rowIndex].add2}</Cell>)} />
                    <Column width={this.state.columnWidths.rent_m_agent_id} isResizable={true} columnKey="rent_m_agent_id" header={<Cell>代行会社</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].rent_m_agent_name}</Cell>)} />
                    <Column width={this.state.columnWidths.contact1} isResizable={true} columnKey="contact1" header={<Cell>依頼先1</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].contact1}</Cell>)} />
                    <Column width={this.state.columnWidths.contact2} isResizable={true} columnKey="contact2" header={<Cell>依頼先2</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].contact2}</Cell>)} />
                    <Column width={this.state.columnWidths.contact3} isResizable={true} columnKey="contact3" header={<Cell>依頼先3</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].contact3}</Cell>)} />
                    <Column width={this.state.columnWidths.agent_remarks} isResizable={true} columnKey="agent_remarks" header={<Cell>代行会社備考</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].agent_remarks}</Cell>)} />
                    <Column width={this.state.columnWidths.affiliate_remarks} isResizable={true} columnKey="affiliate_remarks" header={<Cell>提携備考</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_corporate_offices_list[rowIndex].affiliate_remarks}</Cell>)} />
                
                </Table>


                <Modal isOpen={this.state.modal} fade={false}  size="xl" toggle={(e) => { this.setState({ "modal": false, "center":null }) }}>
                    <CorporatesPlaceDialog handleClose={this.handleClose}/>

                </Modal>

                
            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_corporate_offices_list: state.corporation.rent_corporate_offices_list,
        rent_corporate_offices: state.corporation.rent_corporate_offices,
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_agents_list: state.corporation.rent_m_agents_list,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_corporate_offices(state) {
            dispatch(Corporation.change_rent_corporate_offices(state))
        },
        set_rent_corporate_offices(state) {
            dispatch(Corporation.set_rent_corporate_offices(state))
        },
        insert_rent_corporate_offices(state) {
            dispatch(Corporation.insert_rent_corporate_offices(state))
        },
        update_rent_corporate_offices(state) {
            dispatch(Corporation.update_rent_corporate_offices(state))
        },
        get_rent_m_agents_list(state) {
            dispatch(Corporation.get_rent_m_agents_list(state))
        },
        get_rent_m_corporates_zip_address(state) {
            dispatch(Corporation.get_rent_m_corporates_zip_address(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);




