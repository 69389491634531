
const authentication = require('../../../react-azure-adb2c2').default;


export const excel = (value: any) => {
    // alert(value)
    // const url = `http://localhost/laravelapi/public/api/vacancy/` + value;
    // const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/preparation/` + value;
    const url = process.env.REACT_APP_API_LARAVEL_API_URL + `/api/JunbiSheet/` + value;

    const token = authentication.getAccessToken();
    console.log(url)
    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Accept': 'application/pdf', },

        
    }).then(res => res.blob())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}
