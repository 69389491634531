import React, { Component } from 'react';
import { Calendar, Views } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import moment from 'moment'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'

import events from './sampleevents'

import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'

const DragAndDropCalendar = withDragAndDrop(Calendar)
const globallocalizer = localizer(globalize);

class RentCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: events,
      // events:this.props.customer_contacts_calendar
    }

    this.moveEvent = this.moveEvent.bind(this)
    this.newEvent = this.newEvent.bind(this)
    this.viewEvent = this.viewEvent.bind(this)

  }

  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
    console.log('moveEvent')
    const { events } = this.state

    const idx = events.indexOf(event)
    let allDay = event.allDay

    console.log({ start, end, allDay })

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const updatedEvent = { ...event, start, end, allDay }

    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)

    this.setState({
      events: nextEvents,
    })
    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  resizeEvent = ({ event, start, end }) => {
    console.log('resizeEvent')
    const { events } = this.state

    const nextEvents = events.map(existingEvent => {
      var ret = existingEvent;
      if (existingEvent.id === event.id) {
        //日付けを変える変更はさせない
        if (existingEvent.start.getFullYear() !== start.getFullYear()
          || existingEvent.start.getMonth() !== start.getMonth()
          || existingEvent.start.getDate() !== start.getDate()
        ) {
          start = existingEvent.start;
        }
        if (existingEvent.end.getFullYear() !== end.getFullYear()
          || existingEvent.end.getMonth() !== end.getMonth()
          || existingEvent.end.getDate() !== end.getDate()
        ) {
          end = existingEvent.end;
        }
        ret = { ...existingEvent, start, end };
      } else {
        ret = existingEvent;
      }
      return ret;
    })

    this.setState({
      events: nextEvents,
    })


    //alert(`${event.title} was resized to ${start}-${end}`)
  }

  newEvent = ({ start, end }) => {
    this.props.toggle({
      id: 0,
      userId: 0,
      rentResponseId: 0,
      sectionId: 0,
      way: "1",
      returning: 0,
      going: 0,
      remarks: "",
      date: start,
      intend: 0,
      intend_time: 0,
      intend_time2: 0
    });
    // console.log('newEvent')
    // let title = window.prompt('新しい予定')
    // if(!title){
    //   return;
    // }


    // let idList = this.state.events.map(a => a.id)
    // let newId = 1
    // if(idList.length>0){
    //   newId = Math.max(...idList) + 1
    // }
    // console.log('newId')
    // console.log(idList)
    // console.log(newId)

    // let event = {
    //   id: newId,
    //   title: title,
    //   start: start,
    //   end: end,

    // }
    // this.setState({
    //   events: this.state.events.concat([event]),
    // })
  }

  dblclick = (event) => {

    this.props.toggle(event.contents);
    console.log(event)
    // let cur_id = event.id;
    // var evList = []
    // this.state.events.map(function( value ) {
    //   if(value.id != cur_id){
    //     evList.push(value)
    //   }
    // });

    // console.log( evList );
    // this.setState({
    //   events: evList
    // })
  }

  onMouseDown(view) {
    console.log('onMouseDown')
    console.log(view)
    // alert(view)
  }

  onTouchStart(view) {
    console.log('onTouchStart')
    console.log(view)
    // alert(view)
  }

  viewEvent(view) {
    console.log(view)
    if (view === "agenda") {
      this.props.togglescheduler();
    }
    // alert(view)
  }

  render() {

    return (
      <DragAndDropCalendar
        selectable
        localizer={globallocalizer}
        min={new Date('2000/1/1 06:00:00')}
        max={new Date('2000/1/1 23:00:00')}
        culture={'ja-JP'}
        events={this.props.customer_contacts_calendar}
        onEventDrop={this.moveEvent}
        resizable
        onView={this.viewEvent}
        onEventResize={this.resizeEvent}
        onMouseDown={this.onMouseDown}
        onTouchStart={this.onTouchStart}
        onSelectSlot={this.newEvent}
        // onDragStart={console.log('onDragStart')}
        // onDragOver={console.log('onDragOver')}
        // onMouseDown={console.log('onMouseDown')}
        // onTouchStart={console.log('onTouchStart')}
        onDoubleClickEvent={this.dblclick}

        eventPropGetter={
          (event, start, end, isSelected) => {
            let newStyle = {
              backgroundColor: "lightgrey",
              color: 'black',
              borderRadius: "0px",
              border: "none"
            };

            if (event.intend === 1) {
              newStyle.backgroundColor = "lightgreen"
            }

            return {
              className: "",
              style: newStyle
            };
          }
        }


        defaultView={Views.MONTH}
        views={{ agenda: true, week: true, month: true, day: true }}
        // toolbar={false}
        // showMultiDayTimes={true}
        formats={{
          dayFormat: (date) => {
            return (moment.utc(date).format('M月DD日(dddd)'));
          },
          agendaDateFormat: (date) => {
            return (moment.utc(date).format('M月DD日(dddd)'));
          },
          dayHeaderFormat: (date) => {
            return (moment.utc(date).format('YYYY年M月DD日(dddd)'));
          },
          agendaHeaderFormat: ({ start, end }) => {
            return (moment.utc(start).format('YYYY年M月DD日') + ' ～ ' + moment.utc(end).format('YYYY年M月DD日'));
          },
          dayRangeHeaderFormat: ({ start, end }) => {
            return (moment.utc(start).format('YYYY年M月DD日') + ' ～ ' + moment.utc(end).format('YYYY年M月DD日'));
          },
        }}
        messages={{
          date: '日付',
          time: '時間',
          event: '内容',
          allDay: '終日',
          week: '週',
          work_week: 'Work Week',
          day: '日',
          month: '月',
          previous: '←前へ',
          next: '次へ→',
          yesterday: '昨日',
          tomorrow: '明日',
          today: '今日',
          agenda: '一覧',
          noEventsInRange: '期間内に登録されているイベントがありません',
        }}
      />
    );
  }
};

export default RentCalendar;