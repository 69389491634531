import React, { Component, useState } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';

import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';

import TextField from '@mui/material/TextField';
import "react-input-range/lib/css/index.css";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    useReactTable,
  } from '@tanstack/react-table'
import Button from '@mui/material/Button';
import {AccessTime,Close} from '@mui/icons-material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NumberFormat from 'react-number-format';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Grid from '@mui/material/Grid';
import { useVirtual } from 'react-virtual'

const moment = require('moment-timezone');
const authentication = require('../../react-azure-adb2c2').default;

function Table(props) {
    // const rerender = React.useReducer(() => ({}), {})[1]
    const [sorting, setSorting] = useState([])
    const [data, setData] = React.useState([])
    React.useEffect(() => {
        if(props.open && props.id){
            async function fetchData() {
                // const url = `http://localhost:5000/v1/rent/sales/` + props.id
                const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL +`/v1/rent/sales/` + props.id
                const token = authentication.getAccessToken();
                const f = await fetch(url, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
                .then((response) => response.json())
                .then(f_data => {
                    setData(f_data);
                })
            }
            fetchData();
        }
    },[props])
    const columns = [
        {
            accessorKey: 'date',
            header: '売上日',
            size: 100
        },
        {
            accessorKey: 'user',
            header: '更新者',
            size: 50
        },
        {
            accessorKey: 'name',
            header: '項目名',
            size: 100
        },
        {
            accessorKey: 'count',
            header: '件数',
            size: 50
        },
        {
            accessorKey: 'amount',
            header: '金額',
            size: 100
        },
        {
            accessorKey: 'remarks',
            header: '備考',
            size: 120
        },
    ]
    const table = useReactTable({
        data,
        columns,
        state: {
          sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        // debugTable: true,
      })
      const { rows } = table.getRowModel()
      const tableContainerRef = React.useRef(null)
      const rowVirtualizer = useVirtual({
        parentRef: tableContainerRef,
        size: rows.length,
        overscan: 10,
      })
      const { virtualItems: virtualRows, totalSize } = rowVirtualizer
      const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
      const paddingBottom =
        virtualRows.length > 0
          ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
          : 0
          
    if(data.length == 0){
        return (<div>Loading...</div>);
    }else{
    return (
    <div className="p-2">
      <div className="h-2" />
      { data.length > 0 ?
      <div ref={tableContainerRef} style={{height: '500px', overflow: 'auto'}}>
        <table>
          <thead>
            {table.getHeaderGroups().map(headerGroup => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  return (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                    >
                      {header.isPlaceholder ? null : (
                        <div
                          {...{
                            className: header.column.getCanSort()
                              ? 'cursor-pointer select-none'
                              : '',
                            onClick: header.column.getToggleSortingHandler(),
                          }}
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          {{
                            asc: ' 🔼',
                            desc: ' 🔽',
                          }[header.column.getIsSorted()] ?? null}
                        </div>
                      )}
                    </th>
                  )
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {paddingTop > 0 && (
              <tr>
                <td style={{ height: `${paddingTop}px` }} />
              </tr>
            )}
            {virtualRows.map(virtualRow => {
              const row = rows[virtualRow.index]
              return (
                <tr key={row.id}>
                  {row.getVisibleCells().map(cell => {
                    return (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
            {paddingBottom > 0 && (
              <tr>
                <td style={{ height: `${paddingBottom}px` }} />
              </tr>
            )}
          </tbody>
        </table>
      </div> : <div>Loading...</div>
      }
      {/* <div>{table.getRowModel().rows.length} Rows</div>
      <div>
        <button onClick={() => rerender()}>Force Rerender</button>
      </div> */}
    </div>
    );
    }
  }

function MinusNumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  const [valueset, setValueset] = useState(0);
  console.log(props)
  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      style={{ textAlign: 'right' }}

      onValueChange={values => {

        // console.log(values.value ? String(parseInt(values.value) * -1) : 0)
        setValueset(values.value)
        onChange({
          target: {
            value: values.value,
          },
        });
      }}

      thousandSeparator={true}
      prefix={valueset > 0 ? '-' : ''}
    />
  );
}

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  const [valueset, setValueset] = useState(0);
  console.log(props)
  return (
    <NumberFormat
      {...other}
      ref={inputRef}
      style={{ textAlign: 'right' }}

      onValueChange={values => {

        // console.log(values.value ? String(parseInt(values.value) * -1) : 0)
        setValueset(values.value)
        onChange({
          target: {
            value: values.value,
          },
        });
      }}

      thousandSeparator={true}

    />
  );
}

class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      reflash: false,
      open:false
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }
  handleClose(state){
    this.setState({open:false})
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }
  handleChange = (event, name) => {
    console.log(event.target)


    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    if (event.target.name === "electrical_presence") {
      rent_contract_editor_forms[event.target.name] = name;
    } else if (event.target.name === "wifi") {
      rent_contract_editor_forms[event.target.name] = name ? 1 : 2;
    } else if (name === "ad_amount") {
      rent_contract_editor_forms[name] = parseInt(event.target.value) < 0 ? parseInt(event.target.value) * -1 : parseInt(event.target.value);

    } else if (name === "owner_brokerage_fee") {
      rent_contract_editor_forms[name] = parseInt(event.target.value) < 0 ? parseInt(event.target.value) * -1 : parseInt(event.target.value);
    } else if (name === "agent_fee") {
      rent_contract_editor_forms[name] = parseInt(event.target.value) > 0 ? parseInt(event.target.value) * -1 : parseInt(event.target.value);
    } else if (name === "pay_brokerage_fee") {
      rent_contract_editor_forms[name] = parseInt(event.target.value) > 0 ? parseInt(event.target.value) * -1 : parseInt(event.target.value);
    } else if (name === "referral_fee") {
      rent_contract_editor_forms[name] = parseInt(event.target.value) > 0 ? parseInt(event.target.value) * -1 : parseInt(event.target.value);
    } else if (name === "referral_fee_etc") {
      rent_contract_editor_forms[name] = parseInt(event.target.value) > 0 ? parseInt(event.target.value) * -1 : parseInt(event.target.value);

    } else {
      rent_contract_editor_forms[event.target.name] = event.target.value;
    }


    // if (event.target.name === "condition") {
    //   formData.contractionDateStart = new Date();
    //   formData.contractionDateEnd = null;
    // }
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }
  render() {
    let contract_campaign = ""
    if (this.props.rent_contract_details_old.contract_campaign !== this.props.rent_contract_details_new.contract_campaign) {
      contract_campaign = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.contract_campaign === 1 ? "キャンペーン対象" : ""}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.contract_campaign === 1 ? "キャンペーン対象" : ""}</div></span>

    } else {
      contract_campaign = this.props.rent_contract_details_new.contract_campaign === 1 ? "キャンペーン対象" : ""

    }
    let wifi = ""
    if (this.props.rent_contract_details_old.wifi !== this.props.rent_contract_details_new.wifi) {
      wifi = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.contract_campaign === 1 ? "WIFI必要" : this.props.rent_contract_details_old.contract_campaign === 2 ? 'WIFI不要' : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.contract_campaign === 1 ? "WIFI必要" : this.props.rent_contract_details_new.contract_campaign === 2 ? 'WIFI不要' : "-"}</div></span>

    } else {
      wifi = this.props.rent_contract_details_new.contract_campaign === 1 ? "WIFI必要" : this.props.rent_contract_details_new.contract_campaign === 2 ? 'WIFI不要' : "-"

    }





    let alldenka = 0
    if (("," + this.props.rent_contract_details_new.room_setsubi + ",").indexOf(',43,') !== -1) {
      alldenka = 1
    }

    let electrical_presence = ""
    if (alldenka === 1) {


      if (this.props.rent_contract_details_old.electrical_presence !== this.props.rent_contract_details_new.electrical_presence) {
        electrical_presence = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.electrical_presence === 1 ? "電気立合い必要" : "電気立合い不要"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.electrical_presence === 1 ? "電気立合い必要" : "電気立合い不要"}</div></span>

      } else {
        electrical_presence = this.props.rent_contract_details_new.electrical_presence === 1 ? "電気立合い必要" : "電気立合い不要"

      }
    }



    let ad_pay_new = this.props.rent_contract_details_new.ad_pay === 1 ? "相殺" : this.props.rent_contract_details_new.ad_pay === 2 ? "別途請求" : "-"

    let ad_pay_old = this.props.rent_contract_details_old.ad_pay === 1 ? "相殺" : this.props.rent_contract_details_old.ad_pay === 2 ? "別途請求" : "-"
    let ad_amount
    if (this.props.rent_contract_details_new.ad_amount + ad_pay_new !== this.props.rent_contract_details_old.ad_amount + ad_pay_old) {
      ad_amount = <span><div style={{ color: "red", fontWeight: "bold" }}>{(this.props.rent_contract_details_old.ad_amount ? this.props.rent_contract_details_old.ad_amount.toLocaleString() + "円(税込)" : "") + ad_pay_old} </div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{(this.props.rent_contract_details_new.ad_amount ? this.props.rent_contract_details_new.ad_amount.toLocaleString() + "円(税込)" : "") + ad_pay_new}</div></span>
    } else {
      ad_amount = (this.props.rent_contract_details_new.ad_amount ? this.props.rent_contract_details_new.ad_amount.toLocaleString() + "円(税込)" : "") + ad_pay_new
    }


    let ad_name
    if (this.props.rent_contract_details_new.ad_name !== this.props.rent_contract_details_old.ad_name) {
      ad_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.ad_name}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.ad_name}</div></span>
    } else {
      ad_name = this.props.rent_contract_details_new.ad_name
    }

    let trader_name
    if (this.props.rent_contract_details_new.trader_name !== this.props.rent_contract_details_old.trader_name) {
      trader_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.trader_name ? "(" + this.props.rent_contract_details_old.trader_name + ")" : ""}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.trader_name ? "(" + this.props.rent_contract_details_new.trader_name + ")" : ""}</div></span>
    } else {
      trader_name = this.props.rent_contract_details_new.trader_name ? "(" + this.props.rent_contract_details_new.trader_name + ")" : ""
    }
    return (


      <Grid container alignItems="center">

        <Grid
          // justify="space-between" // Add it here :)
          // item
          xs={6}
        >

          <TextField
            style={{ width: "130px" }}
            id="applicationFee"
            label="広告料"
            name="ad_amount"
            value={this.props.rent_contract_editor_forms.ad_amount}
            onChange={(e) => this.handleChange(e, "ad_amount")}
            // type="number"
            // className={classes.textField}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">円</InputAdornment>,
            }}

            // value={this.props.estimateData.applicationFee}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            variant='standard'
          />
        </Grid>
        <Grid xs={6}>
          <FormControl variant="outlined" style={{ marginTop: 5 }}>
            <InputLabel id="demo-simple-select-outlined-label">広告料請求方法</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              style={{ width: 130, margin: 10 }}
              name="ad_pay"
              value={this.props.rent_contract_editor_forms.ad_pay}
              // value={this.props.rent_contract_editor_forms.division}
              onChange={this.handleChange}
              label="広告料請求方法"
              size="small"
            >
              <MenuItem value={0}>
                -
              </MenuItem>
              <MenuItem value={1}>相殺</MenuItem>
              <MenuItem value={2}>別途請求</MenuItem>

            </Select>
          </FormControl>
        </Grid >
        <Grid
          // justify="space-between" // Add it here :)
          // item
          xs={12}
        >

          <TextField
            name="ad_name"
            label="広告料宛名"
            onChange={this.handleChange}
            value={this.props.rent_contract_editor_forms.ad_name}
            // onChange={(e) => change_monies(e, monies[rowIndex], 'amount')}
            id="formatted-numberformat-input"
            // type="number"
            style={{ width: "100%", marginTop: 0 }}
            size="small"
            variant='standard'
          />
        </Grid>

        <Grid xs={6}>

          <TextField
            style={{ width: "130px" }}
            id="agent_fee"
            label="代行会社手数料"
            // type="number"
            // className={classes.textField}
            InputProps={{
              inputComponent: MinusNumberFormatCustom,
              endAdornment: <InputAdornment position="end">円</InputAdornment>,
            }}
            name="agent_fee"
            value={this.props.rent_contract_editor_forms.agent_fee}
            onChange={(e) => this.handleChange(e, "agent_fee")}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            variant='standard'
          />
        </Grid>
        <Grid xs={6}>
          <TextField
            variant="filled"
            label="代行会社手数料備考"

            name="agent_fee_remarks"
            value={this.props.rent_contract_editor_forms.agent_fee_remarks}
            onChange={this.handleChange}
            id="formatted-numberformat-input"
            // type="number"
            style={{ width: "100%", marginTop: 5 }}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />


        </Grid>
        <Grid xs={6}>

          <TextField
            style={{ width: "130px" }}
            id="owner_brokerage_fee"
            label="仲介料オーナー負担"
            // type="number"
            // className={classes.textField}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: <InputAdornment position="end">円</InputAdornment>,
            }}
            name="owner_brokerage_fee"
            value={this.props.rent_contract_editor_forms.owner_brokerage_fee}
            onChange={(e) => this.handleChange(e, "owner_brokerage_fee")}
            // value={this.props.estimateData.applicationFee}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            variant='standard'
          // onChange={this.handleChange}
          />

        </Grid>
        <Grid xs={6}>

          <TextField
            variant="filled"
            label="仲介料オーナー負担備考"

            name="owner_brokerage_fee_remarks"
            value={this.props.rent_contract_editor_forms.owner_brokerage_fee_remarks}
            onChange={this.handleChange}
            id="formatted-numberformat-input"
            // type="number"
            style={{ width: "100%", marginTop: 5 }}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />
        </Grid>
        <Grid xs={6}>

          <TextField
            style={{ width: "130px" }}
            id="pay_brokerage_fee"
            label="支払仲介手数料"
            name="pay_brokerage_fee"
            value={this.props.rent_contract_editor_forms.pay_brokerage_fee}

            onChange={(e) => this.handleChange(e, "pay_brokerage_fee")}
            // type="number"
            // className={classes.textField}
            InputProps={{
              inputComponent: MinusNumberFormatCustom,
              endAdornment: <InputAdornment position="end">円</InputAdornment>,
            }}

            // value={this.props.estimateData.applicationFee}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            variant='standard'
          // onChange={this.handleChange}
          />
        </Grid>
        <Grid xs={6}>
          <TextField

            variant="filled"
            label="支払仲介手数料備考"
            name="pay_brokerage_fee_remarks"
            value={this.props.rent_contract_editor_forms.pay_brokerage_fee_remarks}
            onChange={this.handleChange}
            // value={monies[rowIndex][column.key]}
            // onChange={(e) => change_monies(e, monies[rowIndex], 'amount')}
            id="formatted-numberformat-input"
            // type="number"
            style={{ width: "100%", marginTop: 5 }}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />


        </Grid>

        <Grid xs={6}>
          <TextField
            style={{ width: "130px" }}
            id="referral_fee"
            label="紹介料"
            // type="number"
            // className={classes.textField}
            InputProps={{
              inputComponent: MinusNumberFormatCustom,
              endAdornment: <InputAdornment position="end">円</InputAdornment>,
            }}
            name="referral_fee"
            value={this.props.rent_contract_editor_forms.referral_fee}
            onChange={(e) => this.handleChange(e, "referral_fee")}

            InputLabelProps={{
              shrink: true,
            }}
            size="small"
            variant='standard'
          // onChange={this.handleChange}
          />
        </Grid>

        <Grid xs={6}>
          <TextField
            variant="filled"
            label="紹介料備考"

            name="referral_fee_remarks"
            value={this.props.rent_contract_editor_forms.referral_fee_remarks}
            onChange={this.handleChange}
            // value={monies[rowIndex][column.key]}
            // onChange={(e) => change_monies(e, monies[rowIndex], 'amount')}
            id="formatted-numberformat-input"
            // type="number"
            style={{ width: "100%", marginTop: 5 }}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />

        </Grid>

        <Grid xs={6}>

          <TextField
            style={{ width: "130px" }}
            id="referral_fee_etc"
            label="その他紹介料"
            // type="number"
            // className={classes.textField}
            InputProps={{
              inputComponent: MinusNumberFormatCustom,
              endAdornment: <InputAdornment position="end">円</InputAdornment>,
            }}
            name="referral_fee_etc"
            value={this.props.rent_contract_editor_forms.referral_fee_etc}
            onChange={(e) => this.handleChange(e, "referral_fee_etc")}

            InputLabelProps={{
              shrink: true,
            }}
            variant='standard'
            size="small"
          />
        </Grid>

        <Grid xs={6}>
          <TextField
            variant="filled"
            label="その他紹介料備考"
            name="referral_fee_etc_remarks"
            value={this.props.rent_contract_editor_forms.referral_fee_etc_remarks}
            onChange={this.handleChange}

            // onChange={(e) => change_monies(e, monies[rowIndex], 'amount')}
            id="formatted-numberformat-input"
            // type="number"
            style={{ width: "100%", marginTop: 5 }}
            InputLabelProps={{
              shrink: true,
            }}
            size="small"
          />


        </Grid>
        <Grid xs={12}>
          {/* <FormControlLabel


            control={
              <Checkbox
                style={{ float: "left" }}
                name="wifi"
                checked={this.props.rent_contract_editor_forms.wifi === 1 ? true : false}
                onChange={this.handleChange}
                // checked={this.props.estimateData.applicationFeePayor === 1 ? true : false}
                // onChange={this.handleCheckBoxChange}
                value="1"
                color="primary"
              />
            }
            label="WIFI必要"
          /> */}

        </Grid>
        <Grid xs={12}
        >

          {alldenka === 1 ? <FormControlLabel


            control={
              <Checkbox
                style={{ float: "left" }}
                name="electrical_presence"
                checked={this.props.rent_contract_editor_forms.electrical_presence ? true : false}
                onChange={this.handleChange}
                // checked={this.props.estimateData.applicationFeePayor === 1 ? true : false}
                // onChange={this.handleCheckBoxChange}
                value="1"
                color="primary"
              />
            }
            label="電気立合い必要"
          /> : ""}
        </Grid >
        <Grid xs={12} sm={8}
        >
          <TextField
            id="outlined-multiline-static"
            label="契約備考"
            name="contract_remarks"
            value={this.props.rent_contract_editor_forms.contract_remarks}
            multiline
            rows={4}
            onChange={this.handleChange}
            style={{ width: '100%' }}
            variant="outlined"
          />
        </Grid >
        <Grid xs={12} sm={4} style={{ width: '100%',textAlign:'center' }} >
          <Button variant="outlined" style={{color:"#000",borderColor:"#808080"}} onClick={()=>this.setState({open:true})}><AccessTime fontSize="small"/>売上履歴</Button>
        </Grid >
        <Modal
        open={this.state.open}
        onClose={()=>this.handleClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ position: 'absolute',top: '50%', left: '50%', transform: 'translate(-50%, -50%)',width: 800,bgcolor: 'background.paper',boxShadow: 24,p: 4,}}>
            <div style={{position:'absolute',right:'32px'}} onClick={()=>this.handleClose()}><Close /></div>
          <h5>売上履歴</h5>
          <div style={{marginTop:10}}><Table open={this.state.open} id={this.props.rent_contract_details_new.id}/></div>
        </Box>
      </Modal>
      </Grid >



    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    change_rent_contract_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_editor_forms(state))
    },
    update_rent_contracts(state) {
      dispatch(Contracts.update_rent_contracts(state))
    },

    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
