import React, { Component } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import { Scrollbars } from 'react-custom-scrollbars';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
//import CustomerInfoEdit from './CustomerInfoEditComponent';
//import CustomerInfoDisp from './CustomerInfoDispComponent';
import Fab from '@mui/material/Fab';
import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import MuiButton from '@mui/material/Button';
import './Css/Line.css';
// import LineRightTextComponent from './LineRightTextComponent';
// import LineRightImgComponent from './LineRightImgComponent';
// import LineRightType1Component from './LineRightType1Component';
// import LineRightType2Component from './LineRightType2Component';
// import LineRightType3Component from './LineRightType3Component';
// import LineRightType4Component from './LineRightType4Component';
// import LineRightType5Component from './LineRightType5Component';
// import LineLeftTextComponent from './LineLeftTextComponent';
// import LineLeftImgComponent from './LineLeftImgComponent';
// import LineTimeComponent from './LineTimeComponent';
import LineFormComponent from './LineFormComponent';
import throttle from 'lodash.throttle';
import * as Line from './Saga/Line';
import LineDispComponent from './LineDispComponent';
import Dropzone from 'react-dropzone';
import Grid from '../StockRooms/Grid';
import {Card, CardHeader, CardTitle, CardContent } from '@mui/material';
import * as Firebase from '../../Store/Firebase';
import * as IntroductionListEdit from '../MapMypage/Saga/MapMypage';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import makeStyles from '@mui/styles/makeStyles';
import { Radio, RadioGroup } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';

// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }

// const useStyles = makeStyles(theme => ({
//     absolute: {
//       backgroundColor: theme.palette.common.red,
//       // position: 'absolute',
//     //   bottom: theme.spacing(2),
//     //   right: theme.spacing(3),
//     },
//   }));

  // const classes = useStyles();

class Lines extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            // top: this.props.lines[0].id,
            modal_send: 0,

            base64: null,
            mimeType: null,

            tooltipOpen: 0,
            mypage_id: 0,
        };
        console.log(this.props.lines)
        // this.props.lines.map(){
        //     this.Print()
        // }
        //
        this.send = this.send.bind(this);
        this.send_mypage_url = this.send_mypage_url.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.lineopen = this.lineopen.bind(this);
        this.toggle_send = this.toggle_send.bind(this);
        this.togglesheet = this.togglesheet.bind(this);
        this.scrollToBottom = this.scrollToBottom.bind(this);
        this.mypageOpen = this.mypageOpen.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.props.get_fire_line_detail()
        // this.line_looked = this.line_looked.bind(this);



        // var result = props.lines.map(function( value ) {

        //         // alert()
        //     return this.Print(value)

        //     //配列の各要素を2倍にする
        //     // return value * 2;

        // });
    }






    // }


    async componentDidMount() {
        console.log(this.props)

        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_line_bottom(false);
        }
    }
    componentDidUpdate() {
        // alert(this.props.line_bottom)
        // alert(this.props.line_bottom)
        if (this.node != null && this.props.line_bottom === true) {
            // alert(this.node.scrollHeight)
            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_line_bottom(false);
        }
    }


    send(value) {
        // alert(this.props.line_user_details.id)
        this.props.send_message({ replytoken: "globalcenter", message_type: "text", message_text: value.message, line_user_id: this.props.line_user_details.line_user_id, line_account_section_id: this.props.line_user_details.line_account_section_id, rent_response_id: this.props.responsedetail.id })

    }

    send_mypage_url() {

        let text = "https://rooms.global-center.co.jp/rent/" + this.props.responsedetail.response_code + "/" + this.state.mypage_id + "/"
        this.props.send_mypage({ replytoken: "globalcenter", message_type: "text", message_text: text, line_user_id: this.props.line_user_details.line_user_id, line_account_section_id: this.props.line_user_details.line_account_section_id, rent_response_id: this.props.responsedetail.id })

    }


    lineopen() {

        this.props.get_line_open({ line_user_id: this.props.line_user_details.line_user_id, line_account_section_id: this.props.line_user_details.line_account_section_id, take: 20 })
    }


    toggle_send(nom) {

        this.setState({
            // modal_send: nom,
            base64: null,
            name: null,
            mimeType: null,
        })
        this.props.change_line_file_data(null)
        this.props.change_line_send_modal(nom)

        if(nom == 3){
            this.props.get_rent_introduction_headers_list(this.props.responsedetail.id)
        }

        this.setState({ refresh: !this.state.refresh })
        
    }



    scrollToBottom = () => {

        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;


            this.props.change_line_bottom(false);
        }
    }

    togglesheet = () => {

        this.setState(prevState => ({
            modalsheet: !prevState.modalsheet,

        }));
    }

    onDrop = (files) => {
        console.log(files)
        const file = files[0]
        const reader = new FileReader()
        reader.readAsDataURL(file)
        console.log(file.type)
        reader.onload = () => {
            this.setState({
                base64: reader.result,
                name: file.name,
                mimeType: file.type
            })
            this.props.change_line_file_data({
                file: files,
                name: file.name,
                mimeType: file.type
            })
        }

        // let sendfile = [];
        // sendfile.file = files;



    };
    // line_looked() {

    //     this.props.update_line_looked()
    //     // this.togglemailtaiou()
    // }

    handleScroll = throttle((event) => {
        console.log(this.node.scrollTop)

        // console.log(this.refs[this.state.top])

        if (this.node.scrollTop === 0) {
            // alert(this.state.take)

            this.props.get_lines({ line_user_id: this.props.line_user_details.line_user_id, line_account_section_id: this.props.line_user_details.line_account_section_id, take: this.state.take + 5 })
            this.setState({ take: this.state.take + 5 })
            this.node.scrollTop = 50;


            // let token = authentication.getAccessToken();
            // alert(this.props.line_user_details.id)
            // fetch(process.env.REACT_APP_API_LINE_EX_URL + `/v1/lines/list/` + this.props.line_user_details.id + '/' + this.props.line_user_details.line_account_section_id + '/' + (this.state.take + 5), { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
            //     .then(response => response.json())
            //     .then(data => {

            //         console.log("かえってきたよ")
            //         console.log(data)
            //         this.setState({ lines: data })

            //         this.setState({ take: this.state.take + 5 })
            //         this.node.scrollTop = 50;
            //         // console.log(data); // `data.json()` の呼び出しで解釈された JSON データ
            //     });
            //     let ref = firebaseDb.child('line/all').orderByChild('id').startAt(this.state.old_id + 1).limitToFirst(1);

            //     ref.off()
            //     ref.on('child_added',
            //         (snapshot) => {

            //             this.Print(snapshot);

            //             this.node.scrollTop = 30;

            //         })
        }
        // this.setState({
        //     scrollTop: this.node.scrollTop
        //  })

    }, 100)

    mypageOpen = (id) => {

        window.open("https://rooms.global-center.co.jp/rent/" + this.props.responsedetail.response_code + "/" + id + "/")
    }

    url = (e) => {
        console.log(e)
        e.target.select()
        navigator.clipboard.writeText(e.target.value);
    // alert(e.target.id)
        this.setState({ tooltipOpen: e.target.id });
        this.tooltipdelete()
      }
    
    tooltipdelete = () => {
    
        setTimeout(() => {
            this.setState({ tooltipOpen: 0 });
        }, 1000)
    
      }
    onChange(e) {
        this.setState({
            mypage_id: e.target.value
        });
    }
        
    render() {

        
        return (
            <div className="item-line" id="item-line" onScroll={this.handleScroll} ref={(node) => this.node = node} style={{ height: this.props.line_open_flg ? "calc(100% - 60px)" : "500px" }}>

                <div>
                    {this.props.line_send_modal === 1 ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%", marginLeft: -15, marginTop: -30 }}>

                        <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "450px", padding: "20px", background: "#FFF" }}>
                            LINE物件情報送信
                            <Grid />

                            <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.props.send_line_stock_rooms} disabled={this.props.line_send_list_loading}>送信する</Button>
                            <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.toggle_send(0)}>キャンセル</Button>

                        </div>

                    </div> : ""
                    }

                    {this.props.line_send_modal === 2 ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%", marginLeft: -15, marginTop: -30 }}>
                        <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "400px", padding: "20px", background: "#FFF" }}>
                            <p>ファイル送信</p>
                            {this.state.base64 && (this.state.mimeType === "image/jpeg" || this.state.mimeType === "image/png") ? <div><img src={this.state.base64} style={{ maxWidth: 250, maxHeight: 250 }} /></div>
                                : this.state.base64 ? <div><Card style={{ padding: 5 }}><b>{this.state.name}</b> </Card></div> : <Dropzone

                                    onDrop={this.onDrop}>
                                    {({ getRootProps, getInputProps }) => (

                                        <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                                            <input {...getInputProps()} />

                                            <div style={{ height: 100 }}>
                                                <p>{this.state.name ? this.state.name : "ファイル添付　クリックまたはドラッグ&ドロップ"}</p>
                                                <ul style={{ padding: 0, marginTop: 3 }}  >


                                                </ul>

                                            </div>

                                        </div>

                                    )}
                                </Dropzone>}
                            {this.state.base64 ? <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.props.upload_line_file} disabled={this.props.line_send_file_loading}>送信する</Button> : ""}

                            <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.toggle_send(0)}>キャンセル</Button>

                        </div>
                    </div> : ""}

                    {this.props.line_send_modal === 3 ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%", marginLeft: -15, marginTop: -30 }}>

                        <div className="line_mypage" style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "450px", padding: "20px", background: "#FFF" }}>
                            マイページ送信

                        <Scrollbars style={{height:350, padding:3 }}>
                        <RadioGroup row aria-label="position" name="color" onChange={this.onChange} value={parseInt(this.state.mypage_id)} >
                        {this.props.rent_introduction_headers_list ? this.props.rent_introduction_headers_list.map((value, index) => {
                            return (
                                <Card style={{marginBottom:10}}>
                                    
                                    <CardContent style={{textAlign: "left"}}>
                                        <Typography gutterBottom variant="h7" component="div">
                                            <FormControlLabel control={<Radio value={parseInt(value.id)} />} label="" />
                                            {value.name}
                                            <IconButton color="primary" onClick={() => this.mypageOpen(value.id)} size="large">
                                                <OpenInNewIcon />
                                            </IconButton>
                                        </Typography>
                                        <TextField

                                            id={value.id}
                                            // label="番号"
                                            type="text"
                                            onClick={this.url}
                                            value={"https://rooms.global-center.co.jp/rent/" + this.props.responsedetail.response_code + "/" + value.id + "/"}
                                            style={{ width: "100%" }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />
                                        {/* {parseInt(this.state.tooltipOpen)} */}
                                        <Tooltip title="コピーしました！" open={parseInt(this.state.tooltipOpen) === value.id ? true : false}>
                                            <div></div>
                                        </Tooltip>
                                    </CardContent>
                                    
                                </Card>
                            );

        
                        }):""}
                        </RadioGroup>
                        </Scrollbars>

                            <Button disabled={!(parseInt(this.state.mypage_id) > 0)} color="danger" style={{ margin: "20px", marginTop: "10px" }} onClick={()=>{this.send_mypage_url();this.toggle_send(0)}} >送信する</Button>
                            <Button color="secondary" style={{ margin: "20px", marginTop: "10px" }} onClick={() => this.toggle_send(0)}>キャンセル</Button>

                        </div>

                    </div> : ""
                    }

                    {this.props.line_looked_modal ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "calc(100% - 17px)", height: "calc(100% - 10px)", marginLeft: -15, marginTop: -30 }}>

                        <div style={{ margin: "auto", marginTop: 50, borderRadius: "10px", width: "400px", height: "300px", padding: "20px", background: "#FFF" }}>
                            LINEを既読にしますか？

                            <div>
                                <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.props.update_line_looked} >既読にする</Button>
                                <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.props.change_line_looked_modal(false)}>キャンセル</Button>
                            </div>
                        </div>

                    </div> : ""
                    }

                    <LineDispComponent />
                    <div style={{ float: "left", clear: "both" }}
                        ref={(el) => { this.messagesEnd = el; }}>
                    </div>

                    {this.props.line_open_flg ? <div className="linebottom">
                        <LineFormComponent onSubmit={this.send} get_line_close={this.props.get_line_close} toggle_send={this.toggle_send} />

                    </div> : <div><div style={{ position: "absolute", bottom: "10px", right: "10px" }}>{this.props.responsedetail.intended_rent_m_response_status_id === 12 ? <Fab variant="extended" size="small" style={{ marginRight: "10px" }} onClick={() => this.props.change_line_looked_modal(true)}>
                        <VisibilityIcon color="primary" style={{ fontSize: "20px" }} />
                    </Fab> : ""} <Fab variant="extended" size="small" style={{ marginRight: "10px" }} onClick={this.lineopen}>
                            <i class="fab fa-line" style={{ fontSize: "20px", color: "green" }}></i>

                        </Fab></div>
                        <div style={{ position: "absolute", bottom: "10px", left: "10px" }}>
                            <Tooltip title="LINE解除"><Fab variant="extended" size="small" style={{ marginRight: "10px" }} onClick={this.togglesheet}>
                                <CancelIcon color="primary" style={{ fontSize: "20px" }} /></Fab></Tooltip>
                        </div>
                    </div>}

                    <Modal isOpen={this.state.modalsheet} toggle={this.togglesheet} fade={false}>

                        <ModalBody>
                            <div style={{ fontWeight: "bold" }}>LINEの結び付けを解除しますが、よろしいですか？</div>
                            <div style={{ marginTop: 10 }}>※解除すると、反響登録されていないLINEアカウントとして追客リストに表示されます。</div>
                        </ModalBody>
                        <ModalFooter>
                            <MuiButton variant="contained" color="secondary" onClick={() => this.props.delete_line_link()}>はい</MuiButton>
                            <MuiButton variant="contained" style={{ marginLeft: 3 }} onClick={this.togglesheet}>いいえ</MuiButton>
                        </ModalFooter>

                    </Modal>


                </div>
            </div >
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {

        responsedetail: state.responsesReducer.responsedetail,
        lines: state.line.lines,
        line_bottom: state.line.line_bottom,
        line_user_details: state.line.line_user_details,
        line_open_flg: state.line.line_open_flg,
        line_send_modal: state.line.line_send_modal,
        line_send_file_loading: state.line.line_send_file_loading,
        line_send_list_loading: state.line.line_send_list_loading,
        line_looked_modal: state.line.line_looked_modal,
        rent_introduction_headers_list: state.mapmypage.rent_introduction_headers_list,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        send_message(state) {
            dispatch(Line.send_message(state))
        },
        send_mypage(state) {
            dispatch(Line.send_mypage(state))
        },
        get_lines(state) {
            dispatch(Line.get_lines(state))
        },
        get_lines_success(state) {
            dispatch(Line.get_lines_success(state))
        },
        change_line_bottom(state) {
            dispatch(Line.change_line_bottom(state))
        },
        get_line_close(state) {
            dispatch(Line.get_line_close(state))
        },
        get_line_open(state) {
            dispatch(Line.get_line_open(state))
        },
        send_line_stock_rooms(state) {
            dispatch(Line.send_line_stock_rooms(state))
        },
        change_line_file_data(state) {
            dispatch(Line.change_line_file_data(state))
        },
        upload_line_file(state) {
            dispatch(Line.upload_line_file(state))
        },
        change_line_send_modal(state) {
            dispatch(Line.change_line_send_modal(state))
        },
        change_line_looked_modal(state) {
            dispatch(Line.change_line_looked_modal(state))
        },
        update_line_looked(state) {
            dispatch(Line.update_line_looked(state))
        },
        get_fire_line_detail(state) {
            dispatch(Firebase.get_fire_line_detail(state))
        },
        delete_line_link(state) {
            dispatch(Line.delete_line_link(state))
        },
        get_rent_introduction_headers_list(state) {
            dispatch(IntroductionListEdit.get_rent_introduction_headers_list(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Lines);
