import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';

import * as CustomerContacts from './Saga/CustomerContacts';
// import * as Responses from '../../Store/Responses';
import '../../Css/Grid.css';
import LoadingComponent from '../Layout/LoadingComponent';
import EmailIcon from '@mui/icons-material/Email';
import moment from 'moment';
// import ReactDataGrid from "react-data-grid"
const ReactDataGrid = require('react-data-grid');

// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {
  auto_send_reserve_list: any,
  customer_contacts: any,
  rent_response_id: number,
  get_rent_customer_contacts: any,
  toggle: any,
  contacts_loading: number,
  delete_rent_customer_contacts: any,
  get_auto_mail_template_details: any,
}


interface State {

  // state types
}

const DateFormatter = (payload: any) => {
  if (payload.value) {
    return moment(payload.value).utc().format("YYYY/MM/DD HH:mm");
    //   return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
  } else {
    return "";
  }
};
// "TEL".
const WayFormatter = (payload: any) => {
  if (payload.value == 1) {
    return "電話";
  } else if (payload.value == 2) {
    return "メール";
  } else if (payload.value == 3) {
    return "来店";
  } else {
    return "";
  }
};

const telActions = [
  {
    text: "電話",
    icon: <span style={{ color: "green" }} className="fas fa-phone" />,
    // callback: () => {
    //   alert("Deleting");
    // }
  },

];
const comingActions = [
  {
    text: "来店",
    icon: <span style={{ color: "orange" }} className="fas fa-shoe-prints" />,
    // callback: () => {
    //   alert("Deleting");
    // }
  },

];
const mailActions = [
  {
    text: "メール",
    icon: <span className="fas fa-envelope" />,

    // callback: () => {
    //   alert("Deleting");
    // }
  },

];
function getCellActions(column: any, row: any) {
  console.log(row.way)

  const cellActions = {
    tel: telActions,
    mail: mailActions,
    coming: comingActions
  };
  if (column.key == "way") {
    if (row.way == 1) {
      return cellActions["tel"];
    } else if (row.way == 2) {
      return cellActions["mail"];
    } else if (row.way == 3) {
      return cellActions["coming"];
    }
  } else {
    return "";
  }
}



const columns = [

  {
    key: 'date', name: '対応日', width: 150, resizable: true, formatter: DateFormatter
  },
  { key: 'way', name: '対応方法', width: 90, resizable: true, formatter: WayFormatter },
  { key: 'user_name', name: '担当', width: 70, resizable: true },
  { key: 'remarks', name: '備考', width: 200, resizable: true },
  { key: 'edit', name: '編集', width: 80, resizable: true },
  { key: 'view', name: '', width: 50, resizable: true },
  { key: 'delete', name: '削除', width: 70, resizable: true },
  // { key: 'id', name: 'ID', resizable: true },
  // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
];



const RowRenderer = ({ renderBaseRow, ...props }: any) => {
  console.log(props.row.intend)

  const backgroundColor = (props.row.intend === 1 || props.row.intend === true) ? "row-highlight-green" : "";
  return <span className={backgroundColor}>{renderBaseRow(props)}</span>;
};



// function getCellActions(column, row) {
//     //const cellActions = {
//     //    firstName: firstNameActions
//     //};
//     console.log(column)
//     //return row.id % 2 === 0 ? cellActions[column.key] : null;
// }

class ContactsGridComponent extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);


    this.getCellActions = this.getCellActions.bind(this);


  }
  componentWillMount() {

    // this.props.get_rent_customer_contacts(this.props.rent_response_id)
    // this.props.customerList()

  }
  getSelectedCell(event: any) {
    alert()
  }

  // onRowClick = (rowIdx:any, row:any) => { 

  //     // if (this.props.customerno === 0) {
  //     //     this.props.change_customerloding0(0)
  //     //     let customerdetail: { [key: string]: number; } = {};
  //     //     customerdetail.customerid=row.id;
  //     //     customerdetail.no=0;
  //     //     this.props.customerdetailData(customerdetail)
  //     //     //this.props.responseUpdate(this.props.responsedetail);
  //     // }else if (this.props.customerno === 1) {
  //     //     this.props.change_customerloding1(0)
  //     //     this.props.responsedetail.rentCustomerId = row.id;
  //     //     this.props.responseUpdate(this.props.responsedetail);

  //     // } else if (this.props.customerno === 2) {
  //     //     this.props.change_customerloding2(0)
  //     //     this.props.responsedetail.rentCustomerId2 = row.id;
  //     //     this.props.responseUpdate(this.props.responsedetail);

  //     // }
  //     if(row)
  //     this.props.toggle(row);




  // }

  EmptyRowsView = () => {
    const message = "検索結果がありません。";

    return (
      this.props.contacts_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", height: 250, padding: "100px" }} > <h3>{message}</h3></div>

    );
  };

  //   onRowClick = (rowIdx:any, row:any) => { 
  //  console.log(row)
  //  alert()
  //     if(row)
  //     this.props.toggle(row);

  // }

  getCellActions(column: any, row: any) {
    // console.log(row.way)

    // const cellActions = {

    //   coming: comingActions
    // };
    console.log(row.intend)
    if (column.key === "delete" && (row.intend === 1 || row.intend === true)) {

      return [
        {
          icon: <i className="fas fa-times"></i>,
          callback: () => {

            this.props.delete_rent_customer_contacts(row.id)
            // this.toggledelete()
            // this.setState({rent_introduction_id:row.id})

          }
        }

      ]


    } else if (column.key === "edit") {
      return [
        {
          icon: <button type="button" className="btn btn-sm btn-success" style={{ width: "70px" }}>編集</button>,
          callback: () => {

            this.props.toggle(row);

          }
        }

      ]
    }
    else if (column.key === "view") {
      return [
        {
          icon: <EmailIcon />,
          callback: () => {

            this.props.get_auto_mail_template_details(row.mail_template_id);

          }
        }

      ]
    }


  }
  render() {

    return (
      <div>

        <ReactDataGrid
          // ref={node => this.grid = node}
          enableCellSelect={false}
          columns={columns}
          rowGetter={(i: number) => this.props.customer_contacts.filter((a: any) => a.mail_template_id > 0)[i]}
          rowRenderer={RowRenderer}
          rowsCount={this.props.customer_contacts.filter((a: any) => a.mail_template_id > 0).length}
          headerRenderer={true}
          dragable={true}
          getCellActions={this.getCellActions}

          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          emptyRowsView={this.EmptyRowsView}
          // onRowClick={this.onRowClick}
          //onCellSelected={this.getSelectedCell}
          minHeight={300} />
      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {
    customer_contacts: state.customerContactsReducer.customer_contacts,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    contacts_loading: state.customerContactsReducer.contacts_loading,
    responsedetail: state.responsesReducer.responsedetail
    // auto_send_reserve_list:state.customerContactsReducer.auto_send_reserve_list
  }
}
function mapDispatchToProps(dispatch: any) {
  return {

    delete_rent_customer_contacts(state: any) {
      dispatch(CustomerContacts.delete_rent_customer_contacts(state))
    },
    get_auto_mail_template_details(state: any) {
      dispatch(CustomerContacts.get_auto_mail_template_details(state))
    },
    // change_customerloding0(state:number) {
    //     dispatch(Customers.change_customerloding0(state))
    // },
    // change_customerloding1(state:number) {
    //     dispatch(Customers.change_customerloding1(state))
    // },
    // change_customerloding2(state:number) {
    //     dispatch(Customers.change_customerloding2(state))
    // },
    // customerdetailData(state:any) {
    //     dispatch(Customers.customerDetail(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContactsGridComponent);