import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Contracts from './Saga/Contracts';
// import SelectM from '@mui/material/Select';
import "react-datepicker/dist/react-datepicker.css";
import './Css/Contracts.css';
//import ResponseAddComponent from './ResponseAddComponent';
// import SelectCompo from "./Select";
// import UnSelectCompo from "./UnSelect";
// import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import InputLabel from '@mui/material/InputLabel';
// import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Select from '@mui/material/Select';

import MaskedInput from 'react-text-mask';
var moment = require('moment-timezone');
const phoneNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
const postNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,

  "-",

  /\d/,
  /\d/,
  /\d/,
  /\d/,
];
const mobileNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];


function PostMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={postNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

function TelMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" ? mobileNumberMask : phoneNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

// const selectStyles = {
//   menu: base => ({
//     ...base,
//     zIndex: 0
//   })
// };


// const RenderSelectInput = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="店舗を選択"
//   />
// )

// const RenderSelectUsers = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="追客担当を選択"
//   />
// )

let timer

class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,
      reflash: false,
      ownshop_operation: true,
      ownshop_contract: true,
      focus_pet: false,

      parking_area_select_disp: false,
    };


    // this.props.change_contract_follow_list_form(formData);
    this.toggle = this.toggle.bind(this);
    this.togglecalender = this.togglecalender.bind(this);

    // this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.change_operation_user_id = this.change_operation_user_id.bind(this);
    this.change_contract_user_id = this.change_contract_user_id.bind(this);


    this.handleChange = this.handleChange.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.sort = this.sort.bind(this);
    this.toggle_select_comp = this.toggle_select_comp.bind(this);
    //  this.handlesectionIdChange = this.handlesectionIdChange.bind(this);
    // this.props.get_intend_list();
    // this.props.initialize({sectionId:this.props.masterReducer.user_details.amSectionId>0?this.props.masterReducer.user_details.amSectionId:0});//フォームに初期値を反映させる
    this.save = this.save.bind(this);
    this.saved = this.saved.bind(this);


    this.change_ownshop_contract = this.change_ownshop_contract.bind(this);

    this.change_contract_date = this.change_contract_date.bind(this);
    this.change_application_date = this.change_application_date.bind(this);
    this.handle_search_tatemono = this.handle_search_tatemono.bind(this);
    this.change_tatemono = this.change_tatemono.bind(this);
    this.focus_pet = this.focus_pet.bind(this);
    this.blur_pet = this.blur_pet.bind(this);
    this.setpet = this.setpet.bind(this);
    this.change_parking_area = this.change_parking_area.bind(this);
    this.parking_area_select_close = this.parking_area_select_close.bind(this);
    this.change_relation = this.change_relation.bind(this);


  }


  change_tatemono(e, value) {
    // console.log(value)
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms


    if (value) {
      rent_contract_editor_forms.tatemono_id = value.value
      rent_contract_editor_forms.tatemono_name = value.label
      rent_contract_editor_forms.tatemono_kana = this.props.tm_tatemono_list.filter(a => a.tatemono_id === value.value)[0] ? this.props.tm_tatemono_list.filter(a => a.tatemono_id === value.value)[0].tatemono_kana : ""

    } else {
      rent_contract_editor_forms.tatemono_id = 0
      rent_contract_editor_forms.tatemono_name = ''
      rent_contract_editor_forms.tatemono_kana = ''
    }
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
  }
  change_ownshop_contract() {

    let contract_follow_list_form = this.props.contract_follow_list_form
    if (!this.state.ownshop_contract && this.props.users.filter(a => a.amSectionId === this.props.contract_follow_list_form.section_id && a.user_id === contract_follow_list_form.contract_user_id)[0]) {

    } else if (!this.state.ownshop_contract) {

      contract_follow_list_form.contract_user_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }


    this.setState({ ownshop_contract: !this.state.ownshop_contract })
  }

  handleChange = (event) => {
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    if (event.target.name === "registration_tatemono") {
      rent_contract_editor_forms.registration_tatemono = !rent_contract_editor_forms.registration_tatemono;
      rent_contract_editor_forms.tatemono_id = 0
      rent_contract_editor_forms.tatemono_name = ''
      rent_contract_editor_forms.room_id = 0
      rent_contract_editor_forms.room_name = ''
      this.props.change_tm_room_list([])
    } else {
      rent_contract_editor_forms[event.target.name] = event.target.value;
    }

    if (event.target.name === "room_id") {
      rent_contract_editor_forms.room_name = this.props.tm_room_list.filter(a => a.room_id === event.target.value)[0] ? this.props.tm_room_list.filter(a => a.room_id === event.target.value)[0].room_name : ""
    }

    // this.props.change_rent_contract_editor_forms({});
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }


  handleSelectChange = (event, value) => {
    // console.log(event.target)

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    if (value && value.name) {
      rent_contract_editor_forms[value.name] = value.value;
      if (value.name === "user_id") {
        rent_contract_editor_forms.section_id = value.section_id;
      }
      if (value.name === "section_id") {
        rent_contract_editor_forms.user_id = 0;
      }

      if (value.name === "operation_user_id") {
        rent_contract_editor_forms.operation_section_id = value.section_id;
      }

      if (value.name === "operation_section_id") {
        rent_contract_editor_forms.operation_user_id = 0;
      }

      if (value.name === "tatemono_id") {

        if(rent_contract_editor_forms.counting == -1 || rent_contract_editor_forms.counting == 2){
          rent_contract_editor_forms.room_id = null;
          rent_contract_editor_forms.room_id2 = null;
          rent_contract_editor_forms.room_id3 = null;
          rent_contract_editor_forms.room_name = null;
          rent_contract_editor_forms.room_name2 = null;
          rent_contract_editor_forms.room_name3 = null;

          rent_contract_editor_forms.tatemono_id = value.value
        }else{
          rent_contract_editor_forms.room_id = -1;
          rent_contract_editor_forms.room_name = '';
        }
        
        this.props.get_tm_room_list(value.value)

      }

      // this.props.change_rent_contract_editor_forms({});
      this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);

      this.setState({ reflash: !this.state.reflash });
    }
  }





  setpet = (value) => {

    // alert(event.target.name)
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    rent_contract_editor_forms.pet_type = value

    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);

    this.setState({ reflash: !this.state.reflash });
  }



  handle_search_tatemono(e) {

    if (e.target.value) {
      let name = e.target.value
      clearTimeout(timer);
      timer = setTimeout(() => {

        this.props.get_tm_tatemono_list(name)
      }, 400);
    }
    // }
    // this.setState({ reflash: !this.state.reflash });

  }


  focus_pet(e) {

    this.setState({ focus_pet: true })
  }
  blur_pet(e) {

    setTimeout(() => {
      this.setState({ focus_pet: false })
    }, 200)

  }

  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")

    // this.props.get_contract_follow_list({ section_id: this.props.user_details.amSectionId })
  }
  toggle() {
    //this.setState(prevState => ({
    //    modal: !prevState.modal
    //}));
    this.props.customerNewDispSet(0)
    // let matching = {}


    // this.props.change_matching(matching)
    this.props.history.push('/CustomerNew');

  }


  togglecalender() {
    this.setState(prevState => ({
      modalcalender: !prevState.modalcalender,
      contractor_birthday:this.props.rent_contract_details_new.contractor_birthday,
      cosigner1_birthday:this.props.rent_contract_details_new.cosigner1_birthday
      
    }));
  }
  saveSelectValue = (e, id) => {
    let data = {}
    data.id = id;
    data.value = e.target.value

    this.props.saveSelectValue(data)
  }

  // handleSectionIdChange(sectionId) {
  //   alert()
  //   let formData =   this.state.formData
  //   formData.sectionId=sectionId
  //     this.setState(formData);

  //   }
  change_operation_user_id(e, newValue) {


    if (newValue) {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.operation_user_id = newValue ? newValue.value : 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    } else {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.operation_user_id = 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }
    this.setState({ reflesh: !this.props.reflesh })


  };
  change_contract_user_id(e, newValue) {
    if (newValue) {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.contract_user_id = newValue ? newValue.value : 0
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    } else {
      let contract_follow_list_form = this.props.contract_follow_list_form
      contract_follow_list_form.contract_user_id = 0;
      this.props.change_contract_follow_list_form(contract_follow_list_form);
      this.props.search_contract_follow_list()
    }
  };
  change_contract_date = date => {
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.contract_date = date
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    this.setState({ reflesh: !this.props.reflesh })

  };

  change_application_date = date => {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    rent_contract_editor_forms.application_date = date
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);
    this.setState({ reflesh: !this.props.reflesh })

  };

  sort(sort) {
    let formData = this.props.responselist.formData
    formData.sort = sort.target.value
    this.props.change_formData(formData);
    this.props.search_response_list()

  }

  toggle_select_comp() {
    this.setState({ modal_select_comp: !this.state.modal_select_comp })
  }


  save(e) {
    this.props.update_rent_m_contract_follow_settings()
    this.setState({ anchorEl: e.currentTarget });


  }
  saved(e) {

    this.setState({ anchorEl: null });
    this.props.change_rent_m_contract_follow_settings_save(false)
  }

  change_relation(id){
    var relations = ''
    switch(id){
        case 1:
            relations = '本人'
            break;
        case 2:
            relations = '父'
            break;
        case 3:
            relations = '母'
            break;
        case 4:
            relations = '子'
            break;
        case 5:
            relations = '妻'
            break;
        case 6:
            relations = '夫'
            break;
        case 7:
            relations = '祖父'
            break;
        case 8:
            relations = '祖母'
            break;
        case 9:
            relations = '義父'
            break;
        case 10:
            relations = '義母'
            break;
        case 11:
            relations = '兄'
            break;
        case 12:
            relations = '姉'
            break;
        case 13:
            relations = '弟'
            break;
        case 14:
            relations = '妹'
            break;
        case 15:
            relations = '入居者'
            break;
        case 16:
            relations = '取締役'
            break;
        case 17:
            relations = '本人'
            break;
        case 18:
            relations = '社員'
            break;
        case 19:
            relations = '取引先'
            break;
        case 20:
            relations = '叔父'
            break;
        case 21:
            relations = '叔母'
            break;
        case 22:
            relations = '婚約者'
            break;
        default:
            break;
    }
    return relations
  }
  change_parking_area() {

    let rent_contract_editor_forms = this.props.rent_contract_editor_forms

    rent_contract_editor_forms["room_id"] = null
    rent_contract_editor_forms["room_name"] = null
    rent_contract_editor_forms["room_id2"] = null
    rent_contract_editor_forms["room_name2"] = null
    rent_contract_editor_forms["room_id3"] = null
    rent_contract_editor_forms["room_name3"] = null

    this.props.tm_parkingarea_list.filter(a => a.selectcontract === true).map(function(value, key){
      if(key === 0){
        rent_contract_editor_forms["room_id"] = value.pa_id
        rent_contract_editor_forms["room_name"] = value.pa_name
      }else{
        rent_contract_editor_forms["room_id" + (key + 1)] = value.pa_id
        rent_contract_editor_forms["room_name" + (key + 1)] = value.pa_name
      }
    })

    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms);

    this.setState({ reflesh: !this.props.reflesh})

    this.parking_area_select_close()

}

parking_area_select_close(){

  this.props.change_tm_parkingarea_list([])
  this.setState({ parking_area_select_disp: false})

}
  render() {




    return (
      <div id="contractfollow">
      <Card style={{ padding: 20}}>
        <CardContent>
          <Grid container>

              <Grid xs={4} md={4} style={{paddingBottom: 10}}>
                <TextField
                  id="car_name1"
                  //   className={classes.textField}
                  label="車名"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  name="car_name1"
                  value={this.props.rent_contract_editor_forms.car_name1}
                  defaultValue={this.props.rent_contract_details_new.car_name1}
                  style={{ float: "left", width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              </Grid>
              <Grid xs={4} md={4} style={{paddingLeft: 10,paddingBottom: 10}}>
                <TextField
                  id="car_color1"
                  //   className={classes.textField}
                  label="車　色"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  name="car_color1"
                  value={this.props.rent_contract_editor_forms.car_color1}
                  defaultValue={this.props.rent_contract_details_new.car_color1}
                  style={{ float: "left", width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              </Grid>
              <Grid xs={4} md={4} style={{paddingLeft: 10,paddingBottom: 10}}>
                <TextField
                  id="car_no1"
                  //   className={classes.textField}
                  label="車　ナンバー"
                  margin="normal"
                  variant="outlined"
                  onChange={this.handleChange}
                  name="car_no1"
                  value={this.props.rent_contract_editor_forms.car_no1}
                  defaultValue={this.props.rent_contract_details_new.car_no1}
                  style={{ float: "left", width: "100%" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size="small"
                />
              </Grid>
                {/* </Row> */}
                {/* <Row style={{ paddingTop: 8, paddingLeft: 20, paddingRight: 20, paddingBottom: 15 }}> */}
              <Grid xs={12} md={6} style={{ paddingRight: 10 }}>
                <Select
                    id="demo-simple-select-outlined"
                    style={{ width: 400 }}
                    name="apply_payment_type"
                    label="初期費用"
                    value={this.props.rent_contract_editor_forms.apply_payment_type?this.props.rent_contract_editor_forms.apply_payment_type:this.props.rent_contract_details_new.apply_payment_type}
                    defaultValue={this.props.rent_contract_details_new.apply_payment_type}
                    // value={this.props.rent_contract_editor_forms.room_id}
                    onChange={this.handleChange}
                    // label="部屋名"
                    size="small"
                    >
                    <MenuItem value={1}>
                        口座振込
                    </MenuItem>
                    
                    {this.props.rent_contract_editor_forms.contract_form === 0 ?
                    <MenuItem value={2}>
                        カード支払
                    </MenuItem>:''}
                </Select>
              </Grid>
              <Grid xs={12} md={6} style={{ paddingLeft: 10 }}>
                <Select
                    id="demo-simple-select-outlined"
                    style={{ width: 400 }}
                    name="month_payment_type"
                    label="月額使用料"
                    value={this.props.rent_contract_editor_forms.month_payment_type}
                    defaultValue={this.props.rent_contract_details_new.month_payment_type}
                    // value={this.props.rent_contract_editor_forms.room_id}
                    onChange={this.handleChange}
                    // label="部屋名"
                  size="small"
                    >
                    {this.props.rent_contract_editor_forms.contract_form === 1 ?
                    <MenuItem value={1}>
                        口座振込
                    </MenuItem>:this.props.rent_contract_editor_forms.contract_form === 0 ?
                    <MenuItem value={2}>
                        カード支払
                    </MenuItem>:''}
                    <MenuItem value={3}>
                        口座振替
                    </MenuItem>
                </Select>
              </Grid>
          </Grid>
        </CardContent>
      </Card >
      <Card style={{marginTop: 10, padding: 20}}>
        <CardContent>
          <Grid container>
            <Grid xs={12} md={6} style={{paddingRight: 10}}>
              <TextField
                id="contractor_name1"
                //   className={classes.textField}
                label="契約者名"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_name1"
                value={this.props.rent_contract_editor_forms.contractor_name1}
                defaultValue={this.props.rent_contract_details_new.contractor_name1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} md={6} style={{paddingLeft: 10}}>
              <TextField
                id="contractor_kana1"
                //   className={classes.textField}
                label="契約者名カナ"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_kana1"
                value={this.props.rent_contract_editor_forms.contractor_kana1}
                defaultValue={this.props.rent_contract_details_new.contractor_kana1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid
              style={{paddingTop:10}}
              justifyContent="space-between" // Add it here :)
              item
              xs={12} md={6}
            >
              <FormControlLabel value={1} name="contractor_sex" onChange={this.handleChange} checked={parseInt(this.props.rent_contract_editor_forms.contractor_sex?this.props.rent_contract_editor_forms.contractor_sex:this.props.rent_contract_details_new.contractor_sex) ? true : false} control={<Radio />} label="男性" />
              <FormControlLabel value={0} name="contractor_sex" onChange={this.handleChange} checked={!parseInt(this.props.rent_contract_editor_forms.contractor_sex?this.props.rent_contract_editor_forms.contractor_sex:this.props.rent_contract_details_new.contractor_sex) ? true : false} control={<Radio />} label="女性" />
            </Grid>
            <Grid xs={12} md={6} style={{paddingTop:10,paddingLeft: 10}}>
            <TextField
                id="date"
                label="生年月日"
                type="date"
                name="contractor_birthday"
                value={this.props.rent_contract_editor_forms.contractor_birthday}
                defaultValue={String(moment(this.props.rent_contract_details_new.contractor_birthday).format('YYYY-MM-DD'))}
                onChange={this.handleChange}
                sx={{ width: 220 }}
                InputLabelProps={{
                shrink: true,
                }}
                size="small"
            />
            </Grid>
            <Grid xs={12}>
              <TextField
                variant="outlined"
                name="contractor_post"
                label="郵便番号"
                type="tel"
                value={this.props.rent_contract_editor_forms.contractor_post}
                defaultValue={this.props.rent_contract_details_new.contractor_post}
                onChange={this.handleChange}
                id="formatted-numberformat-input"
                style={{ float: "left", width: 200, paddingRight: "5px" }}
                // error={this.props.rent_contract_req_editor_forms.post ? false : true}
                InputProps={{
                  inputComponent: PostMaskCustom,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
              <Tooltip title="郵便番号から住所を検索" aria-label="add" onClick={this.props.get_rent_customer_zip_address}>
                <IconButton aria-label="delete" size="large">
                  <SearchIcon />
                </IconButton>
              </Tooltip>
            </Grid>
            <Grid xs={12} style={{ paddingTop: 7 }}>
              <TextField
                id="outlined-basic"
                //   className={classes.textField}
                label="住所1"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                // error={this.props.rent_contract_req_editor_forms.add1 ? false : true}
                name="contractor_add1"
                value={this.props.rent_contract_editor_forms.contractor_add1}
                defaultValue={this.props.rent_contract_details_new.contractor_add1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} style={{ paddingTop: 7 }}>
              <TextField
                id="outlined-basic"
                //   className={classes.textField}
                label="住所2"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_add2"
                value={this.props.rent_contract_editor_forms.contractor_add2}
                defaultValue={this.props.rent_contract_details_new.contractor_add2}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} style={{ paddingTop: 15 }}>
              <TextField
                id="contractor_tel1"
                //   className={classes.textField}
                label="携帯電話"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_tel1"
                value={this.props.rent_contract_editor_forms.contractor_tel1}
                defaultValue={this.props.rent_contract_details_new.contractor_tel1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} style={{ paddingTop: 15 }}>
              <TextField
                id="contractor_mail1"
                //   className={classes.textField}
                label="メールアドレス"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_mail1"
                value={this.props.rent_contract_editor_forms.contractor_mail1}
                defaultValue={this.props.rent_contract_details_new.contractor_mail1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} style={{paddingTop: 15}}>
              <TextField
                id="contractor_office_name"
                //   className={classes.textField}
                label="勤務先名"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_office_name"
                value={this.props.rent_contract_editor_forms.contractor_office_name}
                defaultValue={this.props.rent_contract_details_new.contractor_office_name}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                    shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={6} style={{ paddingTop: 15 }}>
              <TextField
                variant="outlined"
                name="contractor_office_post"
                label="勤務先郵便番号"
                type="tel"
                value={this.props.rent_contract_editor_forms.contractor_office_post}
                defaultValue={this.props.rent_contract_details_new.contractor_office_post}
                onChange={this.handleChange}
                id="formatted-numberformat-input"
                style={{ float: "left", width: 200, paddingRight: "5px" }}
                // error={this.props.rent_contract_req_editor_forms.post ? false : true}
                InputProps={{
                  inputComponent: PostMaskCustom,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
              {/* <Tooltip title="郵便番号から住所を検索" aria-label="add" onClick={this.props.get_rent_customer_zip_address}>
                <IconButton aria-label="delete">
                  <SearchIcon />
                </IconButton>
              </Tooltip> */}
            </Grid>
            <Grid xs={12} style={{ paddingTop: 7 }}>
              <TextField
                id="outlined-basic"
                //   className={classes.textField}
                label="勤務先住所"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                // error={this.props.rent_contract_req_editor_forms.add1 ? false : true}
                name="contractor_office_add"
                value={this.props.rent_contract_editor_forms.contractor_office_add}
                defaultValue={this.props.rent_contract_details_new.contractor_office_add}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            
            <Grid xs={12} style={{ paddingTop: 15 }}>
              <TextField
                id="contractor_office_tel"
                //   className={classes.textField}
                label="勤務先電話電話"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_office_tel"
                value={this.props.rent_contract_editor_forms.contractor_office_tel}
                defaultValue={this.props.rent_contract_details_new.contractor_office_tel}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                    shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} md={6} style={{paddingTop: 15, paddingRight: 10}}>
              <Select
                label="業種"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                style={{ width: 400 }}
                name="contractor_rent_m_job_category_id"
                value={this.props.rent_contract_editor_forms.contractor_rent_m_job_category_id}
                defaultValue={this.props.rent_contract_details_new.contractor_rent_m_job_category_id}
                onChange={this.handleChange}
                size="small"
                >
                <MenuItem value={1}>
                    農業
                </MenuItem>
                <MenuItem value={2}>
                    林業
                </MenuItem>
                <MenuItem value={3}>
                    漁業
                </MenuItem>
                <MenuItem value={4}>
                    鉱業
                </MenuItem>
                <MenuItem value={5}>
                    建設業
                </MenuItem>
                <MenuItem value={6}>
                    製造業
                </MenuItem>
                <MenuItem value={7}>
                    電気・ガス・熱供給・水道業
                </MenuItem>
                <MenuItem value={8}>
                    情報通信業
                </MenuItem>
                <MenuItem value={9}>
                    運輸業
                </MenuItem>
                <MenuItem value={10}>
                    卸売業・小売業
                </MenuItem>
                <MenuItem value={11}>
                    金融業・保険業
                </MenuItem>
                <MenuItem value={12}>
                    不動産業
                </MenuItem>
                <MenuItem value={13}>
                    学術研究
                </MenuItem>
                <MenuItem value={14}>
                    宿泊業，飲食サービス業
                </MenuItem>
                <MenuItem value={15}>
                    生活関連サービス業，娯楽業
                </MenuItem>
                <MenuItem value={16}>
                    教育，学習支援業
                </MenuItem>
                <MenuItem value={17}>
                    医療，福祉
                </MenuItem>
                <MenuItem value={18}>
                    その他
                </MenuItem>
              </Select>
            </Grid>
            <Grid xs={12} style={{ paddingTop: 15 }}>
              <TextField
                id="contractor_office_job"
                //   className={classes.textField}
                label="勤務先職種"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_office_job"
                value={this.props.rent_contract_editor_forms.contractor_office_job}
                defaultValue={this.props.rent_contract_details_new.contractor_office_job}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} md={6} style={{paddingTop: 15, paddingRight: 10}}>
              <Select
                label="住居"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                style={{ width: 400 }}
                name="contractor_myhome"
                value={this.props.rent_contract_editor_forms.contractor_myhome}
                defaultValue={this.props.rent_contract_details_new.contractor_myhome}
                onChange={this.handleChange}
                size="small"
                >
                <MenuItem value={0}>
                    {"　"}
                </MenuItem>
                <MenuItem value={1}>
                    持家
                </MenuItem>
                <MenuItem value={2}>
                    借家
                </MenuItem>
                <MenuItem value={3}>
                    親と同居
                </MenuItem>
           </Select>
            </Grid>
            <Grid xs={12} md={6} style={{paddingTop: 15, paddingLeft: 10}}>
              <TextField
                id="reason_remarks"
                //   className={classes.textField}
                label="引越理由"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="reason_remarks"
                value={this.props.rent_contract_editor_forms.reason_remarks}
                defaultValue={this.props.rent_contract_details_new.reason_remarks}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} md={6} style={{paddingTop: 15, paddingRight: 10}}>
              <TextField
                id="contractor_workyears"
                //   className={classes.textField}
                label="勤続年数"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_workyears"
                value={this.props.rent_contract_editor_forms.contractor_workyears}
                defaultValue={this.props.rent_contract_details_new.contractor_workyears}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} md={6} style={{paddingTop: 15, paddingLeft: 10}}>
              <TextField
                id="contractor_income"
                //   className={classes.textField}
                label="年収"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="contractor_income"
                value={this.props.rent_contract_editor_forms.contractor_income}
                default={this.props.rent_contract_details_new.contractor_income}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            {/* <Grid xs={4} style={{ marginTop: 20 }}>
              <FormControl variant="outlined" >
                <InputLabel id="demo-simple-select-outlined-label">プルダウンメニュー</InputLabel>
                <SelectM
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  style={{ width: 200 }}
                  name="rent_m_contract_type_id"
                  value={this.props.rent_contract_editor_forms.rent_m_contract_type_id}
                  onChange={this.handleChange}
                  label=""
                >

                  {this.props.rent_m_contract_types.map(function (value) {
                    return <MenuItem value={value.id}>{value.name}</MenuItem>
                  })}
                </SelectM>
              </FormControl>
            </Grid> */}
          </Grid>
        </CardContent>
      </Card >
      <Card style={{ marginTop: 10, padding: 20 }}>
        <CardContent>
          <Grid container>
              <Grid xs={12} md={6} style={{paddingRight: 10}}>
              <TextField
                id="cosigner1_name1"
                //   className={classes.textField}
                label="緊急連絡先"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="cosigner1_name1"
                value={this.props.rent_contract_editor_forms.cosigner1_name1}
                defaultValue={this.props.rent_contract_details_new.cosigner1_name1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid xs={12} md={6} style={{paddingLeft: 10}}>
              <TextField
                id="cosigner1_kana1"
                //   className={classes.textField}
                label="契約者名カナ"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="cosigner1_kana1"
                value={this.props.rent_contract_editor_forms.cosigner1_kana1}
                defaultValue={this.props.rent_contract_details_new.cosigner1_kana1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
              />
            </Grid>
            <Grid
              style={{paddingTop:10}}
              justifyContent="space-between" // Add it here :)
              item
              xs={12} md={6}
            >
              <FormControlLabel value={1} name="cosigner1_sex" onChange={this.handleChange} checked={parseInt(this.props.rent_contract_editor_forms.cosigner1_sex?this.props.rent_contract_editor_forms.cosigner1_sex:this.props.rent_contract_details_new.cosigner1_sex) ? true : false} control={<Radio />} label="男性" />
              <FormControlLabel value={0} name="cosigner1_sex" onChange={this.handleChange} checked={!parseInt(this.props.rent_contract_editor_forms.cosigner1_sex?this.props.rent_contract_editor_forms.cosigner1_sex:this.props.rent_contract_details_new.cosigner1_sex) ? true : false} control={<Radio />} label="女性" />
            </Grid>
            <Grid
              style={{paddingTop:10,paddingLeft:10,}}
            // justify="space-between" // Add it here :)
            // item
            xs={12} md={6}
            >
            <TextField
                id="date"
                label="生年月日"
                type="date"
                name="cosigner1_birthday"
                value={this.props.rent_contract_editor_forms.cosigner1_birthday}
                defaultValue={String(moment(this.props.rent_contract_details_new.cosigner1_birthday).format('YYYY-MM-DD'))}
                onChange={this.handleChange}
                sx={{ width: 220 }}
                InputLabelProps={{
                shrink: true,
                }}
                size="small"
            />
          </Grid>
          <Grid xs={12}>
            <TextField
              variant="outlined"
              name="cosigner1_post"
              label="郵便番号"
              type="tel"
              value={this.props.rent_contract_editor_forms.cosigner1_post}
              defaultValue={this.props.rent_contract_details_new.cosigner1_post}
              onChange={this.handleChange}
              id="formatted-numberformat-input"
              style={{ float: "left", width: 200, paddingRight: "5px" }}
              // error={this.props.rent_contract_req_editor_forms.post ? false : true}
              InputProps={{
                inputComponent: PostMaskCustom,
              }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
            <Tooltip title="郵便番号から住所を検索" aria-label="add" onClick={this.props.get_rent_customer_zip_address}>
              <IconButton aria-label="delete" size="large">
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid xs={12} style={{ paddingTop: 7 }}>
            <TextField
              id="outlined-basic"
              //   className={classes.textField}
              label="住所1"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              // error={this.props.rent_contract_req_editor_forms.add1 ? false : true}
              name="cosigner1_add1"
              value={this.props.rent_contract_editor_forms.cosigner1_add1}
              defaultValue={this.props.rent_contract_details_new.cosigner1_add1}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
          <Grid xs={12} style={{ paddingTop: 7 }}>
            <TextField
              id="outlined-basic"
              //   className={classes.textField}
              label="住所2"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              name="cosigner1_add2"
              value={this.props.rent_contract_editor_forms.cosigner1_add2}
              defaultValue={this.props.rent_contract_details_new.cosigner1_add2}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
          <Grid xs={12} style={{ paddingTop: 15 }}>
            <TextField
              id="cosigner1_tel1"
              //   className={classes.textField}
              label="TEL/携帯電話"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              name="cosigner1_tel1"
              value={this.props.rent_contract_editor_forms.cosigner1_tel1}
              defaultValue={this.props.rent_contract_details_new.cosigner1_tel1}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
          <Grid xs={12} md={6} style={{paddingTop: 15, paddingRight: 10}}>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ width: 400 }}
                name="cosigner1_rent_m_relation_id"
                value={this.props.rent_contract_editor_forms.cosigner1_rent_m_relation_id}
                defaultValue={this.props.rent_contract_details_new.cosigner1_rent_m_relation_id}
                onChange={this.handleChange}
                size="small"
                label="続柄"
                >
                <MenuItem value={1}>
                {this.change_relation(1)}
                </MenuItem>
                <MenuItem value={2}>
                {this.change_relation(2)}
                </MenuItem>
                <MenuItem value={3}>
                {this.change_relation(3)}
                </MenuItem>
                <MenuItem value={4}>
                {this.change_relation(4)}
                </MenuItem>
                <MenuItem value={5}>
                {this.change_relation(5)}
                </MenuItem>
                <MenuItem value={6}>
                {this.change_relation(6)}
                </MenuItem>
                <MenuItem value={7}>
                {this.change_relation(7)}
                </MenuItem>
                <MenuItem value={8}>
                {this.change_relation(8)}
                </MenuItem>
                <MenuItem value={9}>
                {this.change_relation(9)}
                </MenuItem>
                <MenuItem value={10}>
                {this.change_relation(10)}
                </MenuItem>
                <MenuItem value={11}>
                {this.change_relation(11)}
                </MenuItem>
                <MenuItem value={12}>
                {this.change_relation(12)}
                </MenuItem>
                <MenuItem value={13}>
                {this.change_relation(13)}
                </MenuItem>
                <MenuItem value={14}>
                {this.change_relation(14)}
                </MenuItem>
                <MenuItem value={15}>
                {this.change_relation(15)}
                </MenuItem>
                <MenuItem value={16}>
                {this.change_relation(16)}
                </MenuItem>
                <MenuItem value={17}>
                {this.change_relation(17)}
                </MenuItem>
                <MenuItem value={18}>
                {this.change_relation(18)}
                </MenuItem>
                <MenuItem value={19}>
                {this.change_relation(19)}
                </MenuItem>
                <MenuItem value={20}>
                {this.change_relation(20)}
                </MenuItem>
                <MenuItem value={21}>
                {this.change_relation(21)}
                </MenuItem>
                <MenuItem value={22}>
                {this.change_relation(22)}
                </MenuItem>
           </Select>
          </Grid>
          <Grid xs={12} md={6} style={{paddingTop: 15, paddingLeft: 10}}>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                style={{ width: 400 }}
                name="cosigner1_myhome"
                value={this.props.rent_contract_editor_forms.cosigner1_myhome}
                defaultValue={this.props.rent_contract_details_new.cosigner1_myhome}
                onChange={this.handleChange}
                label="住居"
                size="small"
                >
                <MenuItem value={0}>
                    {"　"}
                </MenuItem>
                <MenuItem value={1}>
                    持家
                </MenuItem>
                <MenuItem value={2}>
                    借家
                </MenuItem>
                <MenuItem value={3}>
                    親と同居
                </MenuItem>
           </Select>
          </Grid>
          <Grid xs={12} md={6} style={{paddingTop: 15, paddingRight: 10}}>
            <TextField
              id="cosigner1_workyears"
              //   className={classes.textField}
              label="勤続年数"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              name="cosigner1_workyears"
              value={this.props.rent_contract_editor_forms.cosigner1_workyears}
              defaultValue={this.props.rent_contract_details_new.cosigner1_workyears}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
          <Grid xs={12} md={6} style={{paddingTop: 15, paddingLeft: 10}}>
            <TextField
              id="cosigner1_income"
              //   className={classes.textField}
              label="年収"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              name="cosigner1_income"
              value={this.props.rent_contract_editor_forms.cosigner1_income}
              defaultValue={this.props.rent_contract_details_new.cosigner1_income}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
          <Grid xs={12} style={{paddingTop: 15}}>
            <TextField
              id="cosigner1_office_name"
              //   className={classes.textField}
              label="勤務先名"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              name="cosigner1_office_name"
              value={this.props.rent_contract_editor_forms.cosigner1_office_name}
              defaultValue={this.props.rent_contract_details_new.cosigner1_office_name}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
          <Grid xs={12} style={{paddingTop: 15}}>
            <TextField
              id="cosigner1_office_add"
              //   className={classes.textField}
              label="勤務先住所"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              name="cosigner1_office_add"
              defaultValue={this.props.rent_contract_details_new.cosigner1_office_add}
              value={this.props.rent_contract_editor_forms.cosigner1_office_add}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
          <Grid xs={12} style={{paddingTop: 15}}>
            <TextField
              id="cosigner1_office_tel"
              //   className={classes.textField}
              label="勤務先TEL"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              name="cosigner1_office_tel"
              defaultValue={this.props.rent_contract_details_new.cosigner1_office_tel}
              value={this.props.rent_contract_editor_forms.cosigner1_office_tel}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
          <Grid xs={12} style={{paddingTop: 15}}>
            <TextField
              id="cosigner1_office_job"
              //   className={classes.textField}
              label="業種"
              margin="normal"
              variant="outlined"
              onChange={this.handleChange}
              name="cosigner1_office_job"
              defaultValue={this.props.rent_contract_details_new.cosigner1_office_job}
              value={this.props.rent_contract_editor_forms.cosigner1_office_job}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
            />
          </Grid>
        </Grid>
      </CardContent>
    </Card >

      </div >
    );
  }
}





const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    user_details: state.masterReducer.user_details,
    // csv_loading: state.Adjustment.csv_loading,
    // search_list: state.Adjustment.search_list,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
    // seisan_follow_search: state.Adjustment.seisan_follow_search,
    // tantou: state.Adjustment.tantou,
    // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
    // rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    rent_contract_details: state.contracts.rent_contract_details,
    
    rent_contract_details_new:state.contracts.rent_contract_details_new,
    tm_tatemono_list: state.contracts.tm_tatemono_list,
    tm_tatemono_list_loading: state.contracts.tm_tatemono_list_loading,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    tm_kanri_corp_list: state.contracts.tm_kanri_corp_list,
    tm_room_list: state.contracts.tm_room_list,
    rent_m_contract_types: state.contracts.rent_m_contract_types,
    rent_m_traders: state.contracts.rent_m_traders,
    tm_parkingarea_list: state.contracts.tm_parkingarea_list,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    get_tm_tatemono_list(state) {
      dispatch(Contracts.get_tm_tatemono_list(state))
    },
    change_rent_contract_editor_forms(state) {
      dispatch(Contracts.change_rent_contract_editor_forms(state))
    },
    get_tm_room_list(state) {
      dispatch(Contracts.get_tm_room_list(state))
    },
    change_tm_room_list(state) {
      dispatch(Contracts.change_tm_room_list(state))
    },
    change_tm_parkingarea_list(state) {
      dispatch(Contracts.change_tm_parkingarea_list(state))
    },
    // change_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.change_rent_m_contract_follow_settings(state))
    // },
    // change_contract_follow_list_form(state) {
    //   dispatch(ContractFollow.change_contract_follow_list_form(state))
    // },
    // get_contract_follow_list(state) {
    //   dispatch(ContractFollow.get_contract_follow_list(state))
    // },
    // search_contract_follow_list(state) {
    //   dispatch(ContractFollow.search_contract_follow_list(state))
    // },
    // update_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.update_rent_m_contract_follow_settings(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);
