import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import '../../Css/Table.css';
import DateFnsUtils from '@date-io/date-fns';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MaterialUiSelect from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';

import { format } from 'date-fns'

var moment = require('moment-timezone');

class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}




const test = [
    { value: "選択肢１", label: "選択肢１" },
    { value: "選択肢２", label: "選択肢２" },
    { value: "選択肢３", label: "選択肢３" },
]

interface Props {
    searchdate_change: any,
    search_change: any,
    search_state: any,
    section_select: any,
    section_id: any,
    users_select: any
}

interface State {
}
class ResponseFollowSheets extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);
    }

    search() {

    }

    render() {
        return (
            <div>
                <TextField
                    id="search_date1"
                    label="売上日"
                    type="date"
                    name="search_date1"
                    size="small"
                    variant='outlined'
                    // onChange={this.props.onChangeuketukeDate1}
                    onChange={(event: any) => this.props.searchdate_change(event.target.value, "search_date1")}
                    value={moment(this.props.search_state.search_date1).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/* <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        label="売上日"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.props.search_state.search_date1}
                        onChange={(event: any) => this.props.searchdate_change(event, "search_date1")}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider> */}

                <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                <TextField
                    id="search_date2"
                    label="売上日"
                    type="date"
                    name="search_date2"
                    size="small"
                    variant='outlined'
                    // onChange={this.props.onChangeuketukeDate1}
                    onChange={(event: any) => this.props.searchdate_change(event.target.value, "search_date2")}
                    value={moment(this.props.search_state.search_date1).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/* <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        label="売上日"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.props.search_state.search_date2}
                        onChange={(event: any) => this.props.searchdate_change(event, "search_date2")}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider> */}

                <FormControl variant="outlined" style={{ width: 300, margin: 10 }}>
                    <InputLabel id="sectionId-label">店舗</InputLabel>
                    <MaterialUiSelect
                        labelId="sectionId-label"
                        id="search_section"
                        name="section_id"
                        defaultValue={this.props.section_id}
                        onChange={this.props.search_change}
                        label="section_id"
                    >
                        {this.props.section_select.map(function (item: any, i: any) {
                            return (
                                <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                            );
                        })}
                    </MaterialUiSelect>
                </FormControl>

                <FormControl variant="outlined" style={{ width: 200, margin: 10 }}>
                    <Autocomplete
                        onChange={this.props.search_change}
                        id="search_user"
                        options={this.props.users_select}
                        getOptionLabel={(option: any) => option.label ? option.label : ''}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField
                            {...params}
                            variant="outlined"
                            name="primary_user_id"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            label="担当" />}

                    />
                </FormControl>

                <FormControl variant="outlined" style={{ width: 200, margin: 10 }}>
                    <Autocomplete
                        onChange={this.props.search_change}
                        id="search_uriage"
                        options={test}
                        getOptionLabel={(option: any) => option.label ? option.label : ''}
                        style={{ width: "100%" }}
                        renderInput={(params) => <TextField
                            {...params}
                            variant="outlined"
                            name="primary_user_id"
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            label="売上" />}

                    />
                </FormControl>

                <TextField
                    id="search_creatdate1"
                    label="登録日"
                    type="date"
                    name="search_creatdate1"
                    size="small"
                    variant='outlined'
                    // onChange={this.props.onChangeuketukeDate1}
                    onChange={(event: any) => this.props.searchdate_change(event.target.value, "search_creatdate1")}
                    value={moment(this.props.search_state.search_date1).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/* <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        label="登録日"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.props.search_state.search_date1}
                        onChange={(event: any) => this.props.searchdate_change(event, "search_creatdate1")}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider> */}

                <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                <TextField
                    id="search_creatdate2"
                    label="登録日"
                    type="date"
                    name="search_creatdate2"
                    size="small"
                    variant='outlined'
                    // onChange={this.props.onChangeuketukeDate1}
                    onChange={(event: any) => this.props.searchdate_change(event.target.value, "search_creatdate2")}
                    value={moment(this.props.search_state.search_date1).format("YYYY-MM-DD")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
                {/* <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                    <KeyboardDatePicker
                        variant="inline"
                        margin="normal"
                        id="date-picker-dialog"
                        label="登録日"
                        format="yyyy/MM/dd"
                        style={{ width: 130 }}
                        value={this.props.search_state.search_date2}
                        onChange={(event: any) => this.props.searchdate_change(event, "search_creatdate2")}
                        autoOk={true}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                </MuiPickersUtilsProvider> */}


                <Button
                    // onClick={this.save}
                    style={{ marginLeft: 30, marginTop: 25 }}
                    color="primary" variant="outlined">
                    検索</Button>

            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        users_select: state.masterReducer.users_select,
        section_select: state.masterReducer.section_select,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResponseFollowSheets);




