import { ComposedChart, XAxis, YAxis, Tooltip, CartesianGrid, Bar } from 'recharts';
import { ThemeContext } from './Index';

//グラフの各領域の色を定義
const COLORS = [
    '#e8edf5',
    '#d2dcec',
    '#bccae3',
    '#a6b9d9',
    '#90a7d0',
    '#7a96c7',
    '#6484bd',
    '#4e72b4',
    '#3861ab',
    '#2250a2',
  ];
function App() {

    return (
        <ThemeContext.Consumer>
            {(value) => {
                const data = value.chart_composed;
                const data_column = value.chart_column;
                const data_unit = value.chart_unit;

                console.log(data)
                console.log("chart")
                return data.length ? <ComposedChart　　//グラフ全体のサイズや位置、データを指定。場合によってmarginで上下左右の位置を指定する必要あり。
                    width={window.innerWidth/3-80}   //グラフ全体の幅を指定
                    height={data.length*32}  //グラフ全体の高さを指定
                    layout="vertical" //グラフのX軸とY軸を入れ替え
                    data={data}   //Array型のデータを指定
                    margin={{ top: 0, right: 60, bottom: 0, left: 0 }}  //marginを指定
                >
                    <XAxis  //X軸に関する設定
                    type="number" //データタイプをnumberに変更。デフォルトではcategoryになっている
                    // domain={['dataMin', 'dataMax + 10']} //軸の表示領域を指定
                    unit={data_unit}
                    />
                    <YAxis //Y軸に関する設定
                    type="category" //データタイプをcategoryに変更
                    dataKey="column1"  //Array型のデータの、Y軸に表示したい値のキーを指定
                    />


                    <Tooltip />
                    <CartesianGrid  //グラフのグリッドを指定
                        stroke="#f5f5f5"  //グリッド線の色を指定
                    />


                    {

                        data_column.map(function (value, key) {
                            if(key > 0){
                                return <Bar
                                dataKey={"column"+ (key+1)}
                                barSize={20}
                                stroke="rgba(34, 80, 162, 0.2)"
                                fillOpacity={1}
                                fill={COLORS[COLORS.length - data_column.length + key + 1]}
                                stackId="a"
                                unit={data_unit}
                                name={value.name}
                                >
                                </Bar>
                            
                            }
                        })

                    }

{/* 
                    <Bar
                    dataKey={"count"}
                    barSize={20}
                    stroke="rgba(34, 80, 162, 0.2)"
                    fillOpacity={1}
                    fill="#2250A2"
                    stackId="a"
                    >
                    </Bar> */}
{/* 
                    <Bar
                    dataKey={"count"}
                    barSize={20}
                    stroke="rgba(34, 80, 162, 0.2)"
                    fillOpacity={1}
                    fill="#2250A2"
                    stackId="a"
                    // onClick={this.onBarCleck()}
                    // onClick={this.onBarClick.bind(this)}
                    >
                        {this.props.tm_owner_age_list.map((value, index) => (
                        <Cell key={value.name} fill={COLORS[index % COLORS.length]} />
                    ))}
                    </Bar> */}

                    {/* <Bar
                        dataKey="sum"
                        barSize={20}
                        stroke="rgba(34, 80, 162, 0.2)"
                        fillOpacity={1}
                        fill="#2250A2"
                    /> */}
                </ComposedChart> : ""

            }}
        </ThemeContext.Consumer>
    );

}

export default App;