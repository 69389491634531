
const authentication = require('../../../react-azure-adb2c2').default;




export const list = (values: any) => {
    let tatemonoId = values.tatemonoId
    if (values.isTatemono == 0 && tatemonoId.indexOf('SysP') === -1) {
        tatemonoId = "SysP" + values.tatemonoId
    }

    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokuTaiyous/` + tatemonoId;

    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};











export const detail = (values: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokuDetails/` + values.tatemonoId + '/' + values.shutokuNo;
    // let url = `https://localhost:44341/api/TShutokuTaiyouDetails/`+ values.tatemonoId+'/'+ values.shutokuNo;
    //  alert(url)
    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const update = (values: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokuTaiyous/`;
    // let url = `https://localhost:44341/api/TShutokuTaiyous/`;
    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;

    // console.log(values)
    // alert(url)
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const deleteshutoku = (values: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokuTaiyous/` + values.tatemonoId + '/' + values.shutokuNo;
    // let url =`https://localhost:44341/api/TShutokuTaiyous/`+values.tatemonoId+'/'+values.shutokuNo;
    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;

    // alert(url)
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};