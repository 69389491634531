import React, { Component } from 'react';

import { connect } from 'react-redux';


import "react-datepicker/dist/react-datepicker.css";

import * as Customers from './../Customers/Saga/Customers';
import * as Responses from './Saga/Responses';
import CustomerDuplicateList from '../Customers/CustomerDuplicateList';
import CustomerAttentionList from '../Customers/CustomerAttentionList';
import ResponseInfoEdit from './ResponseInfoEditComponent.js';
// import ResponseInfoEditSubmitButton from './ResponseInfoEditSubmitButton';
import { Row, Modal } from 'reactstrap';

import 'bootstrap/dist/css/bootstrap.css';
import '../../Css/Table.css';
import '../../Css/Form.css';
import '../../Css/Customers.css';
import './Css/Form.css';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Customer from '../Customers/Index';

import ResponseInfoDisp from './ResponseInfoDispComponent';
import '../../Css/Loading.css';

interface Props {
    customerNewDispSet: any,
    customeredit: any,
    customerNewDisp: number,
    // responseInsert: any,
    responsedetail: any,
    change_responsedetail: any,
    change_customeredit: any,
    // insertdata: any[],
    customerloding: number
    change_customerloding: any
    customerdetail_search: any,
    customerloding_search: number,
    user_details: any,
    customerdetail: any
    customersearchdisp: any,
    // matching: any,
    response_add_loding: boolean,
    customer_add_detail: any,
    response_add_detail: any,
    response_add_select_mail_id_list: any,
    line_user_id: any,

    responseedit: any,
    responseeditchange: any,
    rent_customer_editor_forms: any,
    add_response: any,
    change_customerdetail: any,
    change_customer_details: any,
    change_customer_details_new: any,
    change_customer_details_old: any,





}

interface State {
    customerdetail: any,

    responsedetail: any,
    // matching:any
    // state types
}

const spinner = {
    width: '40px',
    height: '40px',
    border: ' 4px #ddd solid',
    borderTop: '4px #2e93e6 solid',
    borderRadius: '50%',
    animation: 'sp-anime 0.8s infinite linear',
    marginTop: '20%',


};


class ResponseAddComponent extends Component<Props, State>{


    constructor(props: Readonly<Props>) {
        super(props);
        console.log(props.user_details)

        let detail = {
            name: "",

            kana: "",
            email1: "",
            tel1: "",
            rentMMediumId: "",
            buildingName: "",
            sectionId: 0
        };
        // let shop =[];
        // let user =[];
        // if (typeof props.matching.response_matching_detail !== "undefined") {

        //     detail = props.matching.response_matching_detail;


        //     //        shop = this.props.shops.filter(function (shop:any) {
        //     //            return shop.mail === props.matching.response_matching_detail.toMail;
        //     //        });
        //     //        console.log(shop)
        //     // alert()
        //     //        const shopps =shop[0].tenpoNo;
        //     //        user = this.props.users.filter(function (user:any) {
        //     //         return user.mPositionId===4 && user.amSectionId === shopps;
        //     //     });

        //     //   console.log(user)
        //     // alert()
        // } else {
        detail.name = this.props.user_details.name
        // }

        // alert(shop.tenpoNo)
        // alert(this.props.user_details.amSectionId)
        //    alert()
        this.state = {
            // modal: false,
            // modalselect: false,
            customerdetail: {
                id: 0,
                name1: this.props.customer_add_detail.name1,
                kana1: this.props.customer_add_detail.kana1,
                type: "0",
                sex: "1",
                mail1: this.props.customer_add_detail.mail1,
                tel1: this.props.customer_add_detail.tel1,
            }, responsedetail: {
                id: 0,
                // userId: this.props.user_details.amSectionId===26?user[0].id:this.props.user_details.id,
                userId: this.props.user_details.id,
                primaryUserId: this.props.user_details.id,
                sectionId: this.props.response_add_detail.shop ? (this.props.response_add_detail.shop[0] ? this.props.response_add_detail.shop[0].tenpoNo : this.props.user_details.amSectionId) : this.props.user_details.amSectionId,
                firstContactSectionId: this.props.user_details.amSectionId,
                rentCustomerId: 0,
                rentMWayId: this.props.response_add_detail.rentMWayId ? this.props.response_add_detail.rentMWayId : 2,
                date: new Date(),
                rank: this.props.response_add_detail.rank ? this.props.response_add_detail.rank : 0,
                rentMMediumId: this.props.response_add_detail.rentMMediumId,
                responsedBuildings: this.props.response_add_detail.buildingName,
                rentMReasonId: 0,
                requestMoveDate: null,
                studentIndependent: 0,
                rentMSchoolId: 0,
                highschool: "",
                gakunen: "",
                reserveroom: "",
                aloneFamily: 0,
                requestFromCost: this.props.response_add_detail.fromRequestRoomChinryou === null ? 0 : this.props.response_add_detail.fromRequestRoomChinryou,
                requestToCost: this.props.response_add_detail.toRequestRoomChinryou === null ? 0 : this.props.response_add_detail.toRequestRoomChinryou,
                requestFromPlan: this.props.response_add_detail.fromrequestRoomMadoriNo === null ? 0 : this.props.response_add_detail.fromrequestRoomMadoriNo,
                requestToPlan: this.props.response_add_detail.torequestRoomMadoriNo === null ? 0 : this.props.response_add_detail.torequestRoomMadoriNo,
                requestYear: 0,
                requestAreaset: this.props.response_add_detail.areas,
                responseRequests: "",

            },
        }
        this.props.change_customerdetail({
            id: 0,
            name1: this.props.customer_add_detail.name1,
            kana1: this.props.customer_add_detail.kana1,
            type: 0,
            sex: 1,
            mail1: this.props.customer_add_detail.mail1,
            tel1: this.props.customer_add_detail.tel1,
            birthday: null,
        })






        this.props.change_customer_details({
            id: 0,
            name1: this.props.customer_add_detail.name1,
            kana1: this.props.customer_add_detail.kana1,
            type: 0,
            sex: 1,
            mail1: this.props.customer_add_detail.mail1,
            tel1: this.props.customer_add_detail.tel1,
            birthday: null,
        });
        // yield put(change_customer_detail_logs(customer_details));
        this.props.change_customer_details_new({
            id: 0,
            name1: this.props.customer_add_detail.name1,
            kana1: this.props.customer_add_detail.kana1,
            type: 0,
            sex: 1,
            mail1: this.props.customer_add_detail.mail1,
            tel1: this.props.customer_add_detail.tel1,
            birthday: null,
        });
        this.props.change_customer_details_old({
            id: 0,
            name1: this.props.customer_add_detail.name1,
            kana1: this.props.customer_add_detail.kana1,
            type: 0,
            sex: 1,
            mail1: this.props.customer_add_detail.mail1,
            tel1: this.props.customer_add_detail.tel1,
            birthday: null,
        });



        // console.log(props.state.matching)
        // console.log(props.location.state.matching.response_matching_select_detail[0].requestCustomerName)
        //    alert()
        props.customerNewDispSet(0)
        this.customerNewDispSet = this.customerNewDispSet.bind(this);
        // this.customerNewDispSet2 = this.customerNewDispSet2.bind(this);
        this.toggle = this.toggle.bind(this);
        // this.responseadd = this.responseadd.bind(this);
        props.change_customeredit(1)
        props.change_customerloding(1)


        this.submit = this.submit.bind(this);
        this.handleCustomerChange = this.handleCustomerChange.bind(this);

        this.customersubmit = this.customersubmit.bind(this);
        this.handleSelectCustomer = this.handleSelectCustomer.bind(this);

        this.handleCustomerreset = this.handleCustomerreset.bind(this);
        // props.responsedetail.date=new Date();


    }







    customerNewDispSet() {
        this.props.customerNewDispSet(1)//新規登録の表示

    }
    // customerNewDispSet2() {
    //     this.props.customerNewDispSet(0)

    // }

    toggle() {
        //this.props.customerNewDispSet(2)
    }

    customersubmit(value: any) {//顧客情報をステートに持っているのでサブミットしてもフォームを閉じるだけ

        if (value.birthdayyear !== undefined && value.birthdaymonth !== undefined && value.birthdayday !== undefined) {
            value.birthday = value.birthdayyear + "/" + value.birthdaymonth + "/" + value.birthdayday
        }
        this.setState({ customerdetail: value })
        this.props.change_customeredit(0)

    }

    handleCustomerChange(event: any) {
        event.persist();
        const change = this.state.customerdetail;
        change[event.target.name] = event.target.value
        this.setState({ customerdetail: change })
        //  this.setState({ customerdetail: state })
        //     this.state.customerdetail[event.target.name] =event.target.value;
        console.log(this.state.customerdetail)

    }
    handleSelectCustomer(state: any) {
        this.setState({ customerdetail: state })


        this.props.change_customerloding(1)
        this.props.change_customeredit(0)
    }

    handleCustomerreset() {

        this.setState({
            customerdetail: {
                id: 0,
                type: "0",
                sex: "1"
            }
        })
        this.props.change_customeredit(1)
    }
    submit(value: any) {

        // this.state.responsedetail.id = 0
        // this.state.responsedetail.rentCustomerId = this.props.rent_customer_editor_forms.id
        // let insertdata: { responsedetail: any; rent_customer_editor_forms: any; line_user_id: string; line_account_section_id: number; response_add_select_mail_id_list: any }[] = [{
        //     responsedetail: value,
        //     rent_customer_editor_forms: this.props.rent_customer_editor_forms,
        //     line_user_id: this.props.line_user_id.line_user_id,
        //     line_account_section_id: this.props.line_user_id.line_account_section_id,
        //     response_add_select_mail_id_list: this.props.response_add_select_mail_id_list,
        // }];


        this.props.change_responsedetail(value)

        // this.props.responseInsert(insertdata)
        this.props.add_response()

    }



    render() {

        console.log(this.state.customerdetail)
        return (
            <Row >
                <div className="item-outer col-lg-6" >
                    <Card >
                        <CardContent>

                            < Customer rent_contract_id={0} rent_response_id={0} new={0} old={0} />
                            {/* {this.props.customerloding === 1 ? this.props.customeredit === 0 ? <CustomerInfoDisp
                                change_customerloding0={null}
                                // customerdetailData={null}
                                change_customerloding1={null}
                                change_customerloding2={null}
                                responsedetail={null}
                                select={0}
                                responseUpdate={null}
                                edit={1}
                                customerno={0}
                                handleSelectCustomer={null}
                                customerdetail={this.props.customerdetail}
                                customereditchange={this.props.change_customeredit}
                                handleCustomerreset={this.handleCustomerreset} /> : <CustomerInfoEdit notclose={1} customerdetail={this.state.customerdetail} customereditchange={this.props.change_customeredit} customerno={0} handleCustomerChange={this.handleCustomerChange} onSubmit={this.customersubmit} /> : <Loading />} */}
                        </CardContent>
                    </Card>
                </div>

                                
                {
                this.props.customeredit !== 0 ? <div className="customer_duplicate" style={{ display: (this.props.customerNewDisp === 0) ? '' : 'none' }}>
                    <div className="item">
                        <div style={{textAlign:"left"}}>
                            <span style={{fontSize:17}}>反響</span>
                            <CustomerDuplicateList />
                        </div>
                        <div style={{textAlign:"left", marginTop:15}}>
                            <span style={{fontSize:17}}>信用情報</span>
                            <CustomerAttentionList grid_width={((window.innerWidth-60)/2)-20}/>
                        </div>
                    </div>
                </div> : ""
                }

                {
                // this.props.customeredit !== 0 ? <div id="customer_search" style={{ display: (this.props.customerNewDisp === 0) ? '' : 'none' }}>
                //     <div className="item">
                //         顧客サーチ
                //         {/* <CustomerListSearchComponent /> */}
                //         <CustomerGrid />
                //         <br />

                //         {this.props.customersearchdisp === 1 ? this.props.customerloding_search === 1 ? <CustomerInfoSearch
                //             change_customerloding0={null}
                //             // customerdetailData={null}
                //             change_customerloding1={null}
                //             change_customerloding2={null}
                //             responsedetail={null}
                //             customereditchange={null}
                //             handleCustomerreset={null}
                //             select={0}
                //             responseUpdate={null}
                //             edit={0}
                //             customerno={-1}
                //             customerdetail={this.props.customerdetail_search}
                //             handleSelectCustomer={this.handleSelectCustomer} /> : this.props.customerloding_search === 2 ? "" : <Loading /> : ""}
                //         {this.props.customersearchdisp === 1 ? this.props.customerloding_search === 1 ? <CustomerLogGridComponent /> : "" : ""}
                //     </div>
                // </div> : ""
                }




                <div className="item-outer col-lg-6" style={{ display: (this.props.customerNewDisp === 0) ? '' : 'none' }}>

                    <Card >
                        <CardContent>

                            {this.props.customeredit === 0 ? <div><ResponseInfoEdit notclose={1} userId={this.props.user_details.id} responsedetail={this.state.responsedetail} responseedit={this.props.responseedit} responseeditchange={this.props.responseeditchange} onSubmit={this.submit} />
                                {/* <ResponseInfoEditSubmitButton /> */}
                                {/* <button
        type="button"
        className="btn btn-primary float-right" 
        onClick={this.submit}>登録</button> */}

                            </div> : <ResponseInfoDisp notedit={1} responsedetail={this.state.responsedetail} responseeditchange={this.props.responseeditchange} />}
                        </CardContent>
                    </Card>

                </div>
                <Modal isOpen={this.props.response_add_loding} style={spinner} />

            </Row>


        );
    }
}









const mapStateToProps = (state: any) => {
    return {
        customerNewDisp: state.customersReducer.customerNewDisp,
        customerloding: state.customersReducer.customerloding,
        customerloding_search: state.customersReducer.customerloding_search,
        customersearchdisp: state.customersReducer.customersearchdisp,

        responseloding: state.customersReducer.responseloding,
        newResponseCustomer: state.customersReducer.newResponseCustomer,
        customeredit: state.customersReducer.customeredit,
        newResponseId: state.customersReducer.newResponseId,

        customerdetail_search: state.customersReducer.customerdetail_search,
        customerdetail: state.customersReducer.customerdetail,
        newresponseflg: state.customersReducer.newresponseflg,

        user_details: state.masterReducer.user_details,

        responsedetail: state.responsesReducer.responsedetail,
        response_add_detail: state.responsesReducer.response_add_detail,
        customer_add_detail: state.responsesReducer.customer_add_detail,
        response_add_loding: state.responsesReducer.response_add_loding,
        // matching: state.responsesReducer.matching,
        response_add_select_mail_id_list: state.responsesReducer.response_add_select_mail_id_list,
        line_user_id: state.responsesReducer.line_user_id,
        rent_customer_editor_forms: state.customersReducer.rent_customer_editor_forms,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_customer_details(state: number) {
            dispatch(Customers.change_customer_details(state))
        },
        change_customer_details_new(state: number) {
            dispatch(Customers.change_customer_details_new(state))
        },
        change_customer_details_old(state: number) {
            dispatch(Customers.change_customer_details_old(state))
        },
        customerNewDispSet(state: number) {
            dispatch(Customers.customerNewDispSet(state))
        },
        change_customerdetail(state: number) {
            dispatch(Customers.change_customerdetail(state))
        },

        change_customeredit(state: number) {
            dispatch(Customers.change_customeredit(state))
        },
        // responseInsert(state: any) {
        //     dispatch(Responses.responseInsert(state))
        // },
        change_customerloding(state: number) {
            dispatch(Customers.change_customerloding(state))
        },
        add_response(state: number) {
            dispatch(Responses.add_response(state))
        },
        change_responsedetail(state: any) {
            dispatch(Responses.change_responsedetail(state))
        },


    };
}


export default connect(mapStateToProps, mapDispatchToProps)(ResponseAddComponent);



