
const authentication = require('react-azure-adb2c2').default;
export const list = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/response/docs/list/` + values

    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


// export const insert = (values: any) => {
//     const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductionFiles/`;
//     // const url = `https://localhost:44341/api/RentIntroductionFiles/`;
//     const token = authentication.getAccessToken();
//     //const search = values ? values : {};
//     // alert(url)
//     // alert(JSON.stringify(values))
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// }

export const update = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/response/docs/update/` + values.fileid
    // const url = `http://localhost:5000/api/v1/rent/response/docs/update/` + values.fileid
    // const url = `https://localhost:44341/api/RentIntroductions/`+values.id;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const deletefile = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/response/docs/delete/` + values
    // const url = `http://localhost:5000/api/v1/rent/response/docs/delete/` + values
    // const url = `https://localhost:44341/api/RentIntroductions/`+values.id;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
// export const deleteintro = (values: any) => {
//     const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductions/` + values;
//     // const url = `https://localhost:44341/api/RentIntroductions/`+values.id;

//     const token = authentication.getAccessToken();
//     //const search = values ? values : {};

//     // alert(JSON.stringify(values))
//     return fetch(url, {
//         method: 'delete',
//         // body: JSON.stringify(values),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// }



export const download = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/response/docs/download/` + values
    // const url = `http://localhost:5000/api/v1/rent/response/docs/download/` + values


    // let url = `https://localhost:44341/api/RentContractAccounting`;

    const token = authentication.getAccessToken();




    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(response => response.blob())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));






};



export const upload = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/response/docs/uploadfile/`
    // const url = `http://localhost:5000/api/v1/rent/response/docs/uploadfile/`


    // let url = `https://localhost:44341/api/RentContractAccounting`;

    const token = authentication.getAccessToken();


    // alert(url)
    var formData = new FormData();
    formData.append("files", values.file[0]);
    formData.append("rent_response_id", values.response_id);
    formData.append("remarks", values.remarks);
    console.log(formData)
    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'post',
        body: formData,

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));






};