
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';

import { call, put, takeEvery, select, delay, all } from 'redux-saga/effects';
import * as RentResponsesApi from '../Api/RentResponsesApi';
import * as RentResponsesChangeStatus from '../Api/RentResponsesChangeStatusApi';
import * as MailOutRespnoseApi from '../Api/MailOutResponseApi';
import * as LineBindApi from '../Api/LineBindApi';
import * as rent_contract_follows_api from '../Api/rent_contract_follows_api';
import * as rent_contract_follow_details_api from '../Api/rent_contract_follow_details_api';
import * as rent_m_agents_api from '../Api/rent_m_agents_api';
import * as Calendar from '../../Calendar/Saga/Calendar';
import * as Task from '../../Task/Saga/Task';

import * as rent_m_contract_follows_api from '../Api/rent_m_contract_follows_api';
import * as rent_m_contract_follow_settings_api from '../Api/rent_m_contract_follow_settings_api';
import * as rent_contracts_api from '../Api/rent_contracts_api';
import * as users_api from '../Api/users_api';
import * as web_video_call_reservations_api from '../Api/web_video_call_reservations_api';

// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import { startSubmit, stopSubmit, reset, submit } from 'redux-form';
var moment = require('moment-timezone');
// import * as Customers from './Customers';

//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}


export interface ResponsesListState {
  contract_follow_list: any;
  rent_m_contract_follows: any;
  contract_list_loading: boolean;


  contract_count: number;

  contract_follow_backdrop: boolean;


  contract_follow_list_form: {
    email: string,
    password: string,
    tatemono_name: string,
    application_date: any,
    contraction_date_start: any,
    contraction_date_end: any,
    condition: any,
    operation_user_id: number,
    contract_user_id: number,
    name: string,
    section_id: number,
    search_room: boolean,
    search_parking: boolean,
    global_parking: boolean,
    cloudsign: boolean,
    rent_m_agent_id: number,
    kanri_corp_id: number,


  },
  contract_follow_editer: {
    key: number,
    rent_contract_id: number,
    rent_m_contract_follow_id: number,
    title: string,
    contract_form: number,
    row_index: number,
    open: boolean,
    tatemono_name: string,
    room_name: string,
    name1: string,
    name2: string,
    date: any,
    datetime_start: any,
    datetime_end_hour: any,
    datetime_end_minute: any,
    datetime_end: any,
    date_n: any,
    datetime_start_n: any,
    datetime_end_n: any,
    datetime_end_hour_n: any,
    datetime_end_minute_n: any,
    section_id: number,
    section_id_n: number,
    user_id: number,
    user_id_n: number,
    progress: number,
    progress_n: number,
    remarks: string,
    remarks_n: string,
    is_time: boolean,
    way: number,
    way_n: number,
  },
  rent_m_contract_follows_disp: any,
  rent_m_contract_follows_none: any,
  rent_m_contract_follow_settings: any,
  rent_m_contract_follow_settings_save: boolean,
  rent_m_contract_follow_settings_save_popover: boolean,
  contract_follow_search_list: any,
  contract_follow_editer_loading: boolean,
  contract_follow_editer_open: boolean,

  contract_follow_end: boolean,
  contract_follow_end_bulk: any,
  contract_follow_edit_modal: boolean,

  contract_follow_easy_mode: boolean,
  contract_follow_easy_mode_progress: number,
  contract_follow_end_bulk_open: boolean,
  // change_modalMatching:any

  users_list: any;

  rent_m_agents: any,


  web_video_call_reservation_detail: any,
  web_video_call_reservation_detail_modal: boolean,

}

const initialState: ResponsesListState = {

  contract_follow_list: [],

  contract_count: 0,
  contract_list_loading: false,
  rent_m_contract_follows: [],

  contract_follow_backdrop: false,

  contract_follow_list_form: {
    email: '',
    password: '',
    tatemono_name: '',
    application_date: null,
    contraction_date_start: null,
    contraction_date_end: null,
    condition: "4",
    operation_user_id: 0,
    contract_user_id: 0,
    name: '',
    section_id: 0,
    search_room: true,
    search_parking: true,
    rent_m_agent_id: 0,
    kanri_corp_id: 0,
    global_parking:true,
    cloudsign: false,
  },
  contract_follow_editer: {
    key: 0,
    rent_contract_id: 0,
    rent_m_contract_follow_id: 0,

    title: '',
    row_index: 0,
    open: false,
    contract_form: 0,
    tatemono_name: '',
    room_name: '',
    name1: '',
    name2: '',
    is_time: false,
    date: null,
    datetime_start: null,
    datetime_end: null,
    datetime_end_hour: null,
    datetime_end_minute: null,
    date_n: null,
    datetime_start_n: null,
    datetime_end_n: null,
    datetime_end_hour_n: null,
    datetime_end_minute_n: null,
    section_id: 0,
    section_id_n: 0,
    user_id: 0,
    user_id_n: 0,
    progress: 0,//0未　１予定　２完了 3不使用
    progress_n: 0,
    remarks: '',
    remarks_n: '',


    way: 0,
    way_n: 0,

  },
  rent_m_contract_follows_disp: [],
  rent_m_contract_follows_none: [],
  rent_m_contract_follow_settings: {},
  rent_m_contract_follow_settings_save: false,
  rent_m_contract_follow_settings_save_popover: false,
  contract_follow_search_list: [],
  contract_follow_editer_loading: false,
  contract_follow_editer_open: false,
  contract_follow_end: false,
  contract_follow_end_bulk: [],
  contract_follow_edit_modal: false,
  contract_follow_easy_mode: false,
  contract_follow_easy_mode_progress: 2,
  contract_follow_end_bulk_open: false,

  users_list: [],

  rent_m_agents: [],

  web_video_call_reservation_detail:[],
  web_video_call_reservation_detail_modal: false,
}



///action 
const actionCreator = actionCreatorFactory();

// export const responseeditchange = actionCreator< number >('RESPONSE_EDIT');
// export const change_response_id = actionCreator<number>('CHANGE_RESPONSE_ID');
// export const responseloding = actionCreator<number>('RESPONSE_LODING');




// export const responseUpdate = actionCreator<any>('RESPONSE_UPDATE');
// export const responseUpdateSuccess = actionCreator("RESPONSE_UPDATE_SUCCESS");
// export const responseUpdateFailure = actionCreator("RESPONSE_UPDATE_FAILURE");








export const get_contract_follow_list = actionCreator<any>('GET_CONTRACT_FOLLOW_LIST');
export const change_contract_follow_list = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_LIST');
export const change_contract_follow_list_loading = actionCreator<boolean>('CHANGE_CONTRACT_FOLLOW_LIST_LOADING');



export const get_rent_m_contract_follows = actionCreator<any>('GET_RENT_M_CONTRACT_FOLLOWS');
export const change_rent_m_contract_follows = actionCreator<any>("CHANGE_RENT_M_CONTRACT_FOLLOWS");


export const search_contract_follow_list = actionCreator<any>('SEARCH_CONTRACT_FOLLOW_LIST');


export const change_contract_follow_list_form = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_LIST_FORM');

export const change_contract_follow_editer = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_EDITER');

export const change_rent_m_contract_follows_disp = actionCreator<any>('CHANGE_M_CONTRACT_FOLLOWS_DISP');
export const change_rent_m_contract_follows_none = actionCreator<any>('CHANGE_M_CONTRACT_FOLLOWS_NONE');


export const change_rent_m_contract_follow_settings = actionCreator<any>('CHANGE_RENT_M_CONTRACT_FOLLOW_SETTINGS');


export const change_rent_m_contract_follow_settings_save = actionCreator<boolean>('CHANGE_RENT_M_CONTRACT_FOLLOW_SETTINGS_SAVE');
export const change_rent_m_contract_follow_settings_save_popover = actionCreator<boolean>('CHANGE_RENT_M_CONTRACT_FOLLOW_SETTINGS_SAVE_POPOVER');

export const update_rent_m_contract_follow_settings = actionCreator<boolean>('UPDATE_RENT_M_CONTRACT_FOLLOW_SETTINGS');

export const change_contract_follow_search_list = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_SEARCH_LIST');

export const update_rent_contract_follow_details = actionCreator<any>('UPDATE_RENT_CONTRACT_FOLLOW_DETAILS');

export const change_contract_follow_editer_loading = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_EDITER_LOADING');


export const change_contract_follow_editer_open = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_EDITER_OPEN');

export const change_contract_follow_backdrop = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_BACKDROP');


export const delete_rent_contract_follow_details = actionCreator<any>('DELETE_RENT_CONTRACT_FOLLOW_DETAILS');

export const set_end_check = actionCreator<any>('SET_END_CHECK');


export const change_contract_follow_end = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_END');



export const change_contract_follow_end_bulk = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_END_BULK');

export const change_contract_follow_edit_modal = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_EDIT_MODAL');

export const change_contract_follow_easy_mode = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_EASY_MODE');
export const update_contract_follow_easy_mode = actionCreator<any>('UPDATE_CONTRACT_FOLLOW_EASY_MODE');

export const change_contract_follow_easy_mode_progress = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_EASY_MODE_PROGRESS');


export const set_contract_follow_end_bulk = actionCreator<any>('SET_CONTRACT_FOLLOW_END_BULK');

export const change_contract_follow_end_bulk_open = actionCreator<any>('CHANGE_CONTRACT_FOLLOW_END_BULK_OPEN');

export const get_users_list = actionCreator<any>("GET_USERS_LIST");
export const change_users_list = actionCreator<any>("CHANGE_USERS_LIST");

export const get_rent_m_agents = actionCreator<any>('GET_RENT_M_AGENTS');
export const change_rent_m_agents = actionCreator<any>('CHANGE_RENT_M_AGENTS');

export const get_web_video_call_reservation_detail = actionCreator<any>('GET_WEB_VIDEO_CALL_RESERVATION_DETAIL');
export const insert_web_video_call_reservation_detail = actionCreator<any>('INSERT_WEB_VIDEO_CALL_RESERVATION_DETAIL');
export const change_web_video_call_reservation_detail = actionCreator<any>('CHANGE_WEB_VIDEO_CALL_RESERVATION_DETAIL');
export const change_web_video_call_reservation_detail_modal = actionCreator<boolean>('CHANGE_WEB_VIDEO_CALL_RESERVATION_DETAIL_MODAL');


export const contractfollowReducer = reducerWithInitialState(initialState)
  // .case(change_response_id, (state, payload) => ({ ...state, response_id: payload }))
  .case(change_contract_follow_list, (state, payload) => ({ ...state, contract_follow_list: payload }))

  .case(change_contract_follow_list_loading, (state, payload) => ({ ...state, contract_follow_list_loading: payload }))

  // .case(change_contract_count, (state, payload) => ({ ...state, contract_count: payload }))
  .case(change_contract_follow_list_form, (state, payload) => ({ ...state, contract_follow_list_form: payload }))

  .case(change_rent_m_contract_follows, (state, payload) => ({ ...state, rent_m_contract_follows: payload }))
  .case(change_rent_m_contract_follows_disp, (state, payload) => ({ ...state, rent_m_contract_follows_disp: payload }))
  .case(change_rent_m_contract_follows_none, (state, payload) => ({ ...state, rent_m_contract_follows_none: payload }))

  .case(change_contract_follow_editer, (state, payload) => ({ ...state, contract_follow_editer: payload }))

  .case(change_contract_follow_search_list, (state, payload) => ({ ...state, contract_follow_search_list: payload }))


  .case(change_rent_m_contract_follow_settings, (state, payload) => ({ ...state, rent_m_contract_follow_settings: payload }))

  .case(change_rent_m_contract_follow_settings_save, (state, payload) => ({ ...state, rent_m_contract_follow_settings_save: payload }))
  .case(change_rent_m_contract_follow_settings_save_popover, (state, payload) => ({ ...state, rent_m_contract_follow_settings_save_popover: payload }))


  .case(change_contract_follow_editer_loading, (state, payload) => ({ ...state, contract_follow_editer_loading: payload }))

  .case(change_contract_follow_editer_open, (state, payload) => ({ ...state, contract_follow_editer_open: payload }))


  .case(change_contract_follow_backdrop, (state, payload) => ({ ...state, contract_follow_backdrop: payload }))

  .case(change_contract_follow_end, (state, payload) => ({ ...state, contract_follow_end: payload }))

  .case(change_contract_follow_end_bulk, (state, payload) => ({ ...state, contract_follow_end_bulk: payload }))


  .case(change_contract_follow_edit_modal, (state, payload) => ({ ...state, contract_follow_edit_modal: payload }))

  .case(change_contract_follow_easy_mode, (state, payload) => ({ ...state, contract_follow_easy_mode: payload }))
  .case(change_contract_follow_easy_mode_progress, (state, payload) => ({ ...state, contract_follow_easy_mode_progress: payload }))
  .case(change_contract_follow_end_bulk_open, (state, payload) => ({ ...state, contract_follow_end_bulk_open: payload }))

  .case(change_users_list, (state, payload) => ({ ...state, users_list: payload }))

  .case(change_rent_m_agents, (state, payload) => ({ ...state, rent_m_agents: payload }))

  .case(change_web_video_call_reservation_detail, (state, payload) => ({ ...state, web_video_call_reservation_detail: payload }))
  .case(change_web_video_call_reservation_detail_modal, (state, payload) => ({ ...state, web_video_call_reservation_detail_modal: payload }))


// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const contractfollowSaga = [

  takeEvery('GET_CONTRACT_FOLLOW_LIST', handle_get_contract_follow_list),
  takeEvery('GET_RENT_M_CONTRACT_FOLLOWS', handle_get_rent_m_contract_follows),

  takeEvery('SEARCH_CONTRACT_FOLLOW_LIST', handle_search_contract_follow_list),
  takeEvery('UPDATE_RENT_M_CONTRACT_FOLLOW_SETTINGS', handle_update_rent_m_contract_follow_settings),

  takeEvery('UPDATE_RENT_CONTRACT_FOLLOW_DETAILS', handle_update_rent_contract_follow_details),

  takeEvery('DELETE_RENT_CONTRACT_FOLLOW_DETAILS', handle_delete_rent_contract_follow_details),


  takeEvery('SET_END_CHECK', handle_set_end_check),


  takeEvery('SET_CONTRACT_FOLLOW_END_BULK', handle_set_contract_follow_end_bulk),
  takeEvery('UPDATE_CONTRACT_FOLLOW_EASY_MODE', handle_update_contract_follow_easy_mode),

  takeEvery('GET_USERS_LIST', handle_get_users_list),

  takeEvery('GET_RENT_M_AGENTS', handle_get_rent_m_agents),

  takeEvery('INSERT_WEB_VIDEO_CALL_RESERVATION_DETAIL', handle_insert_web_video_call_reservation_detail),
  takeEvery('GET_WEB_VIDEO_CALL_RESERVATION_DETAIL', handle_get_web_video_call_reservation_detail),
];
// export default responsesSaga;




function* handle_get_contract_follow_list(action: any): any {
  yield put(change_contract_follow_list_loading(true));
  console.log("げっとりすと")
  yield put(change_contract_follow_backdrop(true));
  const reset: any = [];
  yield put(change_contract_follow_list(reset));
  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let contract_follow_list_form = state.contractfollow.contract_follow_list_form

  //  if(action.payload){
  //   // if (action.payload[0].sectionId) {
  //     action.payload.sectionId=action.payload.sectionId.value

  // alert("list")
  // }
  // const { payload, error } = yield call(RentResponsesApi.getlist, action.payload);
  const { payload, error } = yield call(rent_contract_follow_details_api.list, contract_follow_list_form);

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {

    let contract_follow_list: any
    let con1 = payload.contract_follow_list.filter((a: any) => a.receive_mail_at)//メール受信
    let con2 = payload.contract_follow_list.filter((a: any) => !a.receive_mail_at && a.receive_line_at)//LINE受信
    let con4 = payload.contract_follow_list.filter((a: any) => !a.receive_mail_at && !a.receive_line_at && (a.counting === 0 || a.counting === -1))//審査中
    let con3 = payload.contract_follow_list.filter((a: any) => !a.receive_mail_at && !a.receive_line_at && a.counting !== -1 && a.counting !== 0)

    con1.sort(function (a: any, b: any) {
      if (a.last_response_date < b.last_response_date) return 1;
      if (a.last_response_date > b.last_response_date) return -1;
      return 0;
    });
    con2.sort(function (a: any, b: any) {
      if (a.last_response_date < b.last_response_date) return 1;
      if (a.last_response_date > b.last_response_date) return -1;
      return 0;
    });
    con3.sort(function (a: any, b: any) {
      if (a.contract_date > b.contract_date) return 1;
      if (a.contract_date < b.contract_date) return -1;
      return 0;
    });

    contract_follow_list = []
    if (con1[0]) {
      contract_follow_list = contract_follow_list.concat(con1)
    }
    if (con2[0]) {
      contract_follow_list = contract_follow_list.concat(con2)
    }

    if (con4[0]) {
      contract_follow_list = contract_follow_list.concat(con4)
    }

    if (con3[0]) {
      contract_follow_list = contract_follow_list.concat(con3)
    }


    console.log("dwsssssssewewe")


    let follows: any = []
    contract_follow_list.map(async (value: any) => {
      let array: any
      array = {

        rent_response_id: value.rent_response_id,
        rent_contract_id: value.rent_contract_id,
        intended_rent_m_response_status_id: value.intended_rent_m_response_status_id,
        receive_mail_at: value.receive_mail_at,
        receive_line_at: value.receive_line_at,
        contract_form: value.contract_form,
        counting: value.counting,
        cloudsign_created_at: value.cloudsign_created_at,
        cloudsign_completed_at: value.cloudsign_completed_at,
        section_id: value.section_id,
        section_name: value.section_name,
        user_id: value.user_id,
        user_name: value.user_name,
        user_nickname: value.user_nickname,
        operation_user_id: value.operation_user_id,
        operation_user_name: value.operation_user_name,
        operation_user_nickname: value.operation_user_nickname,
        name1: value.name1,
        kana1: value.kana1,
        name2: value.name2,
        kana2: value.kana2,
        office_name: value.office_name,
        tel1: value.tel1,
        tel2: value.tel2,
        fax: value.fax,
        mail1: value.mail1,
        mail2: value.mail2,
        sex: value.sex,
        post: value.post,
        add1: value.add1,
        add2: value.add2,
        birthday: value.birthday,
        workyears: value.workyears,
        myhome: value.myhome,
        income: value.income,
        application_date: value.application_date,
        contract_date: value.contract_date,
        tatemono_name: value.tatemono_name,
        tatemono_kana: value.tatemono_kana,
        tatemono_id: value.tatemono_id,

        room_name: value.room_name,
        room_id: value.room_id,
        room_name2: value.room_name2,
        room_id2: value.room_id2,
        room_name3: value.room_name3,
        room_id3: value.room_id3,
        division: value.division,
        kanri_corp_name: value.kanri_corp_name,
        management_company: value.management_company,
        kanri_kubun_name: value.kanri_kubun_name,
        kanri_kubun: value.kanri_corp_no,
        line_img_url: value.line_img_url,
        line_user_id: value.line_user_id,
        line_account_section_id: value.line_account_section_id,
        last_response_date: value.last_response_date,

        agent_etc: value.agent_etc,
        agent_name: value.agent_name,
        rent_m_agent_id: value.rent_m_agent_id,
        contract_money: value.contract_money,
        contract_money_tenant: value.contract_money_tenant,
        get_water_user_id: value.get_water_user_id,
        get_shower_user_id: value.get_shower_user_id,
        room_support_m_user_id: value.room_support_m_user_id,
        need_parking_certificate: value.need_parking_certificate,
        parking_application_id: value.parking_application_id,
        contract_follow_comp_date: value.contract_follow_comp_date,
        is_important_contract: value.is_important_contract,
        web_video_call_reservation_id: value.web_video_call_reservation_id,
        web_video_call_reservation_uuid: value.web_video_call_reservation_uuid,

      }



      for (let i = 1; i < 100; i++) {

        let fs = payload.contract_follow_details.filter((a: any) => a.rent_contract_id === value.rent_contract_id && a.rent_m_contract_follow_id === i)[0] ? payload.contract_follow_details.filter((a: any) => a.rent_contract_id === value.rent_contract_id && a.rent_m_contract_follow_id === i)[0] : null
        array["date_" + i] = fs ? fs.date : null

        array[i] = fs ? fs.date : null

        array["datetime_start_" + i] = fs && fs.datetime_start_hour != null && fs.datetime_start_minute != null ? moment({ years: moment(fs.date).year(), months: moment(fs.date).month(), days: moment(fs.date).date(), hours: fs.datetime_start_hour, minutes: fs.datetime_start_minute, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        array["datetime_end_" + i] = fs && fs.datetime_end_hour != null && fs.datetime_end_minute != null ? moment({ years: moment(fs.date).year(), months: moment(fs.date).month(), days: moment(fs.date).date(), hours: fs.datetime_end_hour, minutes: fs.datetime_end_minute, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        array["section_id_" + i] = fs ? fs.follow_section_id : 0
        array["user_id_" + i] = fs ? fs.follow_user_id : 0
        array["way_" + i] = fs ? fs.way : 0
        array["progress_" + i] = fs ? fs.progress : 0
        array["remarks_" + i] = fs ? fs.remarks : ''
        array["date_n_" + i] = fs ? fs.date_n : null
        array["datetime_start_n_" + i] = fs && fs.datetime_start_hour_n != null && fs.datetime_start_minute_n != null ? moment({ years: moment(fs.date_n).year(), months: moment(fs.date_n).month(), days: moment(fs.date_n).date(), hours: fs.datetime_start_hour_n, minutes: fs.datetime_start_minute_n, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        array["datetime_end_n_" + i] = fs && fs.datetime_end_hour_n != null && fs.datetime_end_minute_n != null ? moment({ years: moment(fs.date_n).year(), months: moment(fs.date_n).month(), days: moment(fs.date_n).date(), hours: fs.datetime_end_hour_n, minutes: fs.datetime_end_minute_n, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        array["section_id_n_" + i] = fs ? fs.follow_section_id_n : 0
        array["user_id_n_" + i] = fs ? fs.follow_user_id_n : 0
        array["way_n_" + i] = fs ? fs.way_n : 0
        array["progress_n_" + i] = fs ? fs.progress_n : 0
        array["remarks_n_" + i] = fs ? fs.remarks_n : ''

      }

      // console.log(array)
      follows.push(array)

    })




    console.log(payload)
    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し

    yield put(change_contract_follow_list(follows));
    yield put(search_contract_follow_list(follows));
    yield put(change_contract_follow_backdrop(false));


  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}

function* handle_search_contract_follow_list(action: any): any {

  const state = yield select();　　// store.getState()と同じ
  // 成功時アクション呼び出し

  let contract_follow_search_list = state.contractfollow.contract_follow_list
  let contract_follow_list_form = state.contractfollow.contract_follow_list_form


  if (contract_follow_list_form.is_important_contract) {
    contract_follow_search_list = contract_follow_search_list.filter((a: any) => a.is_important_contract)
  }

  if (contract_follow_list_form.cloudsign) {
    contract_follow_search_list = contract_follow_search_list.filter((a: any) => a.cloudsign_created_at || a.cloudsign_completed_at)
  }
  if (contract_follow_list_form.tatemono_name) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => han2zen(kanaToHira(hankana2Zenkana(a.tatemono_name))).indexOf(han2zen(kanaToHira(hankana2Zenkana(contract_follow_list_form.tatemono_name)))) !== -1 || han2zen(kanaToHira(hankana2Zenkana(a.tatemono_kana))).indexOf(han2zen(kanaToHira(hankana2Zenkana(contract_follow_list_form.tatemono_name)))) !== -1)

  }

  if (contract_follow_list_form.name) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => han2zen(kanaToHira(hankana2Zenkana(a.name1))).indexOf(han2zen(kanaToHira(hankana2Zenkana(contract_follow_list_form.name)))) !== -1 || han2zen(kanaToHira(hankana2Zenkana(a.kana1))).indexOf(han2zen(kanaToHira(hankana2Zenkana(contract_follow_list_form.name)))) !== -1 || han2zen(kanaToHira(hankana2Zenkana(a.name2))).indexOf(han2zen(kanaToHira(hankana2Zenkana(contract_follow_list_form.name)))) !== -1 || han2zen(kanaToHira(hankana2Zenkana(a.kana2))).indexOf(han2zen(kanaToHira(hankana2Zenkana(contract_follow_list_form.name)))) !== -1)

  }


  if (contract_follow_list_form.operation_user_id) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => a.operation_user_id === contract_follow_list_form.operation_user_id)
  }
  if (contract_follow_list_form.operation_user_id) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => a.operation_user_id === contract_follow_list_form.operation_user_id)
  }

  if (contract_follow_list_form.contract_user_id) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => a.user_id === contract_follow_list_form.contract_user_id)
  }
  if (contract_follow_list_form.contract_user_id) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => a.user_id === contract_follow_list_form.contract_user_id)
  }

  if (contract_follow_list_form.contraction_date_start) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => moment(a.contract_date).format("YYYY/MM/DD") >= moment(contract_follow_list_form.contraction_date_start).format("YYYY/MM/DD"))
  }

  if (contract_follow_list_form.contraction_date_end) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => moment(a.contract_date).format("YYYY/MM/DD") <= moment(contract_follow_list_form.contraction_date_end).format("YYYY/MM/DD"))
  }

  if (!contract_follow_list_form.search_room) {

    contract_follow_search_list = contract_follow_search_list.filter((a: any) => a.counting !== 1 && a.counting !== 0)
  }


  if (!contract_follow_list_form.search_parking) {
    if(contract_follow_list_form.global_parking){
        contract_follow_search_list = contract_follow_search_list.filter((a: any) => !((!a.parking_application_id || a.parking_application_id === 0) && (a.counting === 2 || a.counting === -1)) )
    }else{
        contract_follow_search_list = contract_follow_search_list.filter((a: any) => a.counting !== 2 || a.counting !== -1)
    }
    
  }

  if (!contract_follow_list_form.global_parking) {
    
    contract_follow_search_list = contract_follow_search_list.filter((a: any) => !a.parking_application_id || a.parking_application_id === 0)
  }

  if (contract_follow_list_form.rent_m_agent_id > 0) {
    contract_follow_search_list = contract_follow_search_list.filter((a: any) => parseInt(a.rent_m_agent_id) == parseInt(contract_follow_list_form.rent_m_agent_id))
  }

  if (contract_follow_list_form.kanri_corp_id > 0) {
    contract_follow_search_list = contract_follow_search_list.filter((a: any) => parseInt(a.management_company) == parseInt(contract_follow_list_form.kanri_corp_id))
  }


  contract_follow_search_list = contract_follow_search_list.map(function (value: any) {
    value.end_check = false
    return value
  })


  function kanaToHira(str: string) {
    return String(str).replace(/[\u30a1-\u30f6]/g, function (match) {
      var chr = match.charCodeAt(0) - 0x60;
      return String.fromCharCode(chr);
    });
  }

  function han2zen(str: string) {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }

  function hankana2Zenkana(str: string) {
    var kanaMap: any = {
      'ｶﾞ': 'ガ', 'ｷﾞ': 'ギ', 'ｸﾞ': 'グ', 'ｹﾞ': 'ゲ', 'ｺﾞ': 'ゴ',
      'ｻﾞ': 'ザ', 'ｼﾞ': 'ジ', 'ｽﾞ': 'ズ', 'ｾﾞ': 'ゼ', 'ｿﾞ': 'ゾ',
      'ﾀﾞ': 'ダ', 'ﾁﾞ': 'ヂ', 'ﾂﾞ': 'ヅ', 'ﾃﾞ': 'デ', 'ﾄﾞ': 'ド',
      'ﾊﾞ': 'バ', 'ﾋﾞ': 'ビ', 'ﾌﾞ': 'ブ', 'ﾍﾞ': 'ベ', 'ﾎﾞ': 'ボ',
      'ﾊﾟ': 'パ', 'ﾋﾟ': 'ピ', 'ﾌﾟ': 'プ', 'ﾍﾟ': 'ペ', 'ﾎﾟ': 'ポ',
      'ｳﾞ': 'ヴ', 'ﾜﾞ': 'ヷ', 'ｦﾞ': 'ヺ',
      'ｱ': 'ア', 'ｲ': 'イ', 'ｳ': 'ウ', 'ｴ': 'エ', 'ｵ': 'オ',
      'ｶ': 'カ', 'ｷ': 'キ', 'ｸ': 'ク', 'ｹ': 'ケ', 'ｺ': 'コ',
      'ｻ': 'サ', 'ｼ': 'シ', 'ｽ': 'ス', 'ｾ': 'セ', 'ｿ': 'ソ',
      'ﾀ': 'タ', 'ﾁ': 'チ', 'ﾂ': 'ツ', 'ﾃ': 'テ', 'ﾄ': 'ト',
      'ﾅ': 'ナ', 'ﾆ': 'ニ', 'ﾇ': 'ヌ', 'ﾈ': 'ネ', 'ﾉ': 'ノ',
      'ﾊ': 'ハ', 'ﾋ': 'ヒ', 'ﾌ': 'フ', 'ﾍ': 'ヘ', 'ﾎ': 'ホ',
      'ﾏ': 'マ', 'ﾐ': 'ミ', 'ﾑ': 'ム', 'ﾒ': 'メ', 'ﾓ': 'モ',
      'ﾔ': 'ヤ', 'ﾕ': 'ユ', 'ﾖ': 'ヨ',
      'ﾗ': 'ラ', 'ﾘ': 'リ', 'ﾙ': 'ル', 'ﾚ': 'レ', 'ﾛ': 'ロ',
      'ﾜ': 'ワ', 'ｦ': 'ヲ', 'ﾝ': 'ン',
      'ｧ': 'ァ', 'ｨ': 'ィ', 'ｩ': 'ゥ', 'ｪ': 'ェ', 'ｫ': 'ォ',
      'ｯ': 'ッ', 'ｬ': 'ャ', 'ｭ': 'ュ', 'ｮ': 'ョ',
      '｡': '。', '､': '、', 'ｰ': 'ー', '｢': '「', '｣': '」', '･': '・'
    };

    var reg = new RegExp('(' + Object.keys(kanaMap).join('|') + ')', 'g');
    if (str) {
      return str
        .replace(reg, function (match) {
          return kanaMap[match];
        })
        .replace(/ﾞ/g, '゛')
        .replace(/ﾟ/g, '゜');
    } else {
      return str
    }
  };


  yield put(change_contract_follow_search_list(contract_follow_search_list));

  // yield put(responseListSuccess(payload));
  // yield put(change_contract_follow_list_loading(false));
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し



  // let contract_grid_list: any = [];
  // let name1 = ""
  // let email1 = ""




  // if (contract_follow_list_form.sort === 1) {

  //   contract_follow_list_set.sort(function (a: any, b: any) {
  //     if (new Date(a.last_response_date) < new Date(b.last_response_date)) {
  //       return 1;
  //     } else {
  //       return -1;
  //     }
  //   })
  // } else if (contract_follow_list_form.sort === 2) {

  //   contract_follow_list_set.sort(function (a: any, b: any) {
  //     if (new Date(a.date) < new Date(b.date)) {
  //       return 1;
  //     } else {
  //       return -1;
  //     }
  //   })
  // } else if (contract_follow_list_form.sort === 3) {

  //   contract_follow_list_set.sort(function (a: any, b: any) {

  //     let an = 0

  //     if (a.rank === 0) {
  //       an = 10
  //     } else {
  //       an = a.rank
  //     }

  //     let bn = 0

  //     if (b.rank === 0) {
  //       bn = 10
  //     } else {
  //       bn = b.rank
  //     }

  //     if (an > bn) {
  //       return 1;
  //     } else {
  //       return -1;
  //     }
  //   })
  // }


  // contract_follow_list_set = contract_follow_list_set.filter((a: any) => a.intended_rent_m_response_status_id !== 3)//自動追客じゃない






  // let mailresponse = contract_follow_list_set.filter((a: any) => a.request === 1)//反響
  // let line = contract_follow_list_set.filter((a: any) => a.request === 2)//line

  // let res1 = contract_follow_list_set.filter((a: any) => a.request === 0 && a.status === 1)//メール受信
  // let res2 = contract_follow_list_set.filter((a: any) => a.request === 0 && a.status === 12)
  // let res3 = contract_follow_list_set.filter((a: any) => a.request === 0 && a.status !== 1 && a.status !== 12)




  // if (mailresponse[0]) {
  //   contract_follow_list = contract_follow_list_set.concat(mailresponse)
  // }

  // if (line[0]) {
  //   contract_follow_list = contract_follow_list.concat(line)
  // }
  // if (res1[0]) {
  //   contract_follow_list = contract_follow_list.concat(res1)
  // }
  // if (res2[0]) {
  //   contract_follow_list = contract_follow_list.concat(res2)
  // }
  // if (res3[0]) {
  //   contract_follow_list = contract_follow_list.concat(res3)
  // }










  // contract_follow_list.map(function (value: any) {
  //   // console.log(value.name1)
  //   // console.log(name1)
  //   // console.log(value.request)


  //   if (value.request === 1 && (value.name1 === name1 || (value.email1 === email1 && value.email1 != null && value.email1 !== ""))) {
  //     // console.log(value.request)

  //   } else {


  //     let arry1 = {
  //       id: value.id,
  //       flg: 1,
  //       request: value.request,
  //       status: value.status,
  //       date: value.date,
  //       shops: value.shops,
  //       intended_rent_m_response_status_id: value.intended_rent_m_response_status_id,
  //       section_id: value.section_id,
  //       col1: value.coming_response_count,
  //       col2: value.name1,
  //       col3: value.tel1,
  //       col4: value.attentions,
  //       staff: value.res_name_set,
  //       col5: value.contract_building_name,
  //       col6: value.section_id,
  //       rank: value.rank,
  //       col8: value.contact_response_count,
  //       col9: value.date,
  //       col10: value.line_img_url,
  //       media: value.media,
  //     }

  //     contract_grid_list.push(arry1)
  //     let arry2 = {
  //       id: value.id,
  //       flg: 2,
  //       request: value.request,
  //       status: value.status,
  //       date: value.date,
  //       shops: value.shops,
  //       section_id: value.section_id,
  //       last_response_date: value.last_response_date,
  //       col2: value.building_name,
  //       col3: value.main_media_name,
  //       col4: value.path_name,
  //       col5: value.remarks,
  //       media: value.media,
  //       rank: value.rank,
  //       intended_rent_m_response_status_id: value.intended_rent_m_response_status_id,
  //     }


  //     contract_grid_list.push(arry2)
  //     let arry3 = {
  //       id: value.id,
  //       flg: 3,
  //       request: value.request,
  //       status: value.status,
  //       date: value.date,
  //       shops: value.shops,
  //       section_id: value.section_id,
  //       intended_at: value.intended_at,
  //       col2: value.email1,
  //       col3: value.preferred_date,
  //       col5: value.intended_rent_m_response_status_id,
  //       media: value.media,
  //       rank: value.rank,
  //       intended_rent_m_response_status_id: value.intended_rent_m_response_status_id,
  //     }



  //     contract_grid_list.push(arry3);
  //   }

  //   name1 = value.name1
  //   email1 = value.email1
  // });

  // responselist=payload




}





function* handle_get_rent_m_contract_follows(action: any) {

  const { payload, error } = yield call(rent_m_contract_follows_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    console.log(payload)

    let frozen = true

    let rent_m_contract_follows = payload.rent_m_contract_follows.map(function (value: any) {


      value.frozen = frozen
      if (payload.rent_m_contract_follow_settings.frozen_key_name === value.key_name) {
        frozen = false
      }

      return value
    })


    yield put(change_rent_m_contract_follows(rent_m_contract_follows));
    yield put(change_rent_m_contract_follows_disp(rent_m_contract_follows.filter((a: any) => a.disp === true)));
    yield put(change_rent_m_contract_follows_none(rent_m_contract_follows.filter((a: any) => a.disp === false)));
    yield put(change_rent_m_contract_follow_settings(payload.rent_m_contract_follow_settings))
  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}






function* handle_set_end_check(action: any): any {

  const state = yield select();
  let rent_m_contract_follows_disp = state.contractfollow.rent_m_contract_follows_disp
  // alert(rent_m_contract_follows_disp.length)
  let contract_follow_end = state.contractfollow.contract_follow_end

  if (contract_follow_end) {
    rent_m_contract_follows_disp = rent_m_contract_follows_disp.filter((a: any) => a.key_name !== "end_check")

    yield put(change_contract_follow_end_bulk([]));
  } else {
    let end_check = [{
      end_check: false,
      company: false,
      disp: true,
      flg: false,
      follow_order: 1,
      frozen: true,
      id: 0,
      is_time: false,
      key_name: "end_check",
      name: "完了",
      parking: false,
      required: false,
      resizable: true,
      type: 0,
      width: 50,
    }]

    rent_m_contract_follows_disp = end_check.concat(rent_m_contract_follows_disp)
  }
  // alert(rent_m_contract_follows_disp.length)
  yield put(change_rent_m_contract_follows_disp(rent_m_contract_follows_disp));
  yield put(change_contract_follow_end(!contract_follow_end));
}




function* handle_update_rent_m_contract_follow_settings(action: any): any {
  const state = yield select();
  let rent_m_contract_follow_settings = state.contractfollow.rent_m_contract_follow_settings
  let rent_m_contract_follows_disp = state.contractfollow.rent_m_contract_follows_disp

  yield put(change_rent_m_contract_follow_settings_save(true))
  const { payload, error } = yield call(rent_m_contract_follow_settings_api.update_settings, { rent_m_contract_follow_settings: rent_m_contract_follow_settings, rent_m_contract_follows_disp: rent_m_contract_follows_disp });
  //     const { payload, error } = yield call(KyousaiApi.delete_kyousais, kyousaisset);

  if (payload && !error) {
    yield put(change_rent_m_contract_follow_settings_save(false))
    yield put(change_rent_m_contract_follow_settings_save_popover(true))

    yield delay(500)
    yield put(change_rent_m_contract_follow_settings_save_popover(false))
  }
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し


  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}








function* handle_update_rent_contract_follow_details(action: any): any {
  const state = yield select();
  // let rent_m_contract_follow_settings = state.contractfollow.rent_m_contract_follow_settings
  // let rent_m_contract_follows_disp = state.contractfollow.rent_m_contract_follows_disp

  yield put(change_contract_follow_editer_loading(true))
  let contract_follow_editer = state.contractfollow.contract_follow_editer

  // alert(contract_follow_editer.date)
  // alert(moment(contract_follow_editer.date).isValid())
  contract_follow_editer.date = moment(contract_follow_editer.date).isValid() ? moment(contract_follow_editer.date).format('YYYY-MM-DD') : null
  // contract_follow_editer.datetime_start = contract_follow_editer.datetime_start === 'Invalid date' ? null : moment(contract_follow_editer.datetime_start).format('YYYY-MM-DD HH:mm:ss')

  // alert(moment(contract_follow_editer.datetime_start).isValid())
  contract_follow_editer.datetime_start_hour = moment(contract_follow_editer.datetime_start).isValid() ? moment(contract_follow_editer.datetime_start).hour() : null;
  contract_follow_editer.datetime_start_minute = moment(contract_follow_editer.datetime_start).isValid() ? moment(contract_follow_editer.datetime_start).minute() : null;
  // alert(contract_follow_editer.datetime_start_hour)
  // contract_follow_editer.datetime_start = contract_follow_editer.datetime_start === 'Invalid date' ? null : moment(contract_follow_editer.datetime_start).format('YYYY-MM-DD HH:mm:ss')
  contract_follow_editer.datetime_end_hour = moment(contract_follow_editer.datetime_end).isValid() ? moment(contract_follow_editer.datetime_end).hour() : null;
  contract_follow_editer.datetime_end_minute = moment(contract_follow_editer.datetime_end).isValid() ? moment(contract_follow_editer.datetime_end).minute() : null;

  // contract_follow_editer.datetime_end = contract_follow_editer.datetime_end === 'Invalid date' ? null : moment(contract_follow_editer.datetime_end).format('YYYY-MM-DD HH:mm:ss')
  // contract_follow_editer.date_n = contract_follow_editer.date_n === 'Invalid date' ? null : contract_follow_editer.date_n
  // contract_follow_editer.datetime_start_n = contract_follow_editer.datetime_start_n === 'Invalid date' ? null : contract_follow_editer.datetime_start_n
  // contract_follow_editer.datetime_end_n = contract_follow_editer.datetime_end_n === 'Invalid date' ? null : contract_follow_editer.datetime_end_n


  const { payload, error } = yield call(rent_contract_follow_details_api.update, contract_follow_editer);
  //     const { payload, error } = yield call(KyousaiApi.delete_kyousais, kyousaisset);

  if (payload && !error) {
    // yield put(change_rent_m_contract_follow_settings_save(false))
    // yield put(change_rent_m_contract_follow_settings_save_popover(true))
    let contract_follow_list = state.contractfollow.contract_follow_list
    let contract_follow_editer = state.contractfollow.contract_follow_editer
    let rent_m_contract_follow_id = state.contractfollow.contract_follow_editer.rent_m_contract_follow_id


    contract_follow_list = yield all(contract_follow_list.map(function (value: any) {

      if (value.rent_contract_id === contract_follow_editer.rent_contract_id) {


        // array["datetime_start_" + i] = payload.datetime_start_hour != null && payload.datetime_start_minute != null ? moment({ years: moment(payload.date).year(), months: moment(payload.date).month(), days: moment(payload.date).date(), hours: payload.datetime_start_hour, minutes: payload.datetime_start_minute, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        // array["datetime_end_" + i] = fs && fs.datetime_end_hour != null && fs.datetime_end_minute != null ? moment({ years: moment(fs.date).year(), months: moment(fs.date).month(), days: moment(fs.date).date(), hours: fs.datetime_end_hour, minutes: fs.datetime_end_minute, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null

        value["date_" + rent_m_contract_follow_id] = payload.date
        value["datetime_start_" + rent_m_contract_follow_id] = payload.datetime_start_hour != null && payload.datetime_start_minute != null ? moment({ years: moment(payload.date).year(), months: moment(payload.date).month(), days: moment(payload.date).date(), hours: payload.datetime_start_hour, minutes: payload.datetime_start_minute, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        value["datetime_end_" + rent_m_contract_follow_id] = payload.datetime_end_hour != null && payload.datetime_end_minute != null ? moment({ years: moment(payload.date).year(), months: moment(payload.date).month(), days: moment(payload.date).date(), hours: payload.datetime_end_hour, minutes: payload.datetime_end_minute, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        value["section_id_" + rent_m_contract_follow_id] = payload.section_id
        value["user_id_" + rent_m_contract_follow_id] = payload.user_id
        value["progress_" + rent_m_contract_follow_id] = payload.progress
        value["remarks_" + rent_m_contract_follow_id] = payload.remarks
        value["way_" + rent_m_contract_follow_id] = payload.way
        value["updated_at_" + rent_m_contract_follow_id] = payload.updated_at
        value["date_n_" + rent_m_contract_follow_id] = payload.date_n
        value["datetime_start_n_" + rent_m_contract_follow_id] = payload.datetime_start_hour_n != null && payload.datetime_start_minute_n != null ? moment({ years: moment(payload.date_n).year(), months: moment(payload.date_n).month(), days: moment(payload.date_n).date(), hours: payload.datetime_start_hour_n, minutes: payload.datetime_start_minute_n, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        value["datetime_end_n_" + rent_m_contract_follow_id] = payload.datetime_end_hour_n != null && payload.datetime_end_minute_n != null ? moment({ years: moment(payload.date_n).year(), months: moment(payload.date_n).month(), days: moment(payload.date_n).date(), hours: payload.datetime_end_hour_n, minutes: payload.datetime_end_minute_n, seconds: 0 }).format('YYYY-MM-DD HH:mm:ss') : null
        value["section_id_n_" + rent_m_contract_follow_id] = payload.section_id_n
        value["user_id_n_" + rent_m_contract_follow_id] = payload.user_id_n
        value["progress_n_" + rent_m_contract_follow_id] = payload.progress_n
        value["remarks_n_" + rent_m_contract_follow_id] = payload.remarks_n
        value["way_n_" + rent_m_contract_follow_id] = payload.way_n
        value["updated_at_n_" + rent_m_contract_follow_id] = payload.updated_at_n
      }
      return value

    }))
    // console.log(contract_follow_list)
    // yield put(change_contract_follow_search_list([]));

    yield put(change_contract_follow_list(contract_follow_list));
    // yield put(search_contract_follow_list(contract_follow_list));
    contract_follow_editer.open = false
    contract_follow_editer.key_name = 0
    contract_follow_editer.row_index = 0
    contract_follow_editer.contract_form = 0
    contract_follow_editer.name = ''
    contract_follow_editer.is_time = false
    yield put(change_contract_follow_editer(contract_follow_editer));

    yield put(change_contract_follow_editer_loading(false))


    yield put(change_contract_follow_editer_open(false))




    let calendar_list = state.calendar.calendar_list
    // alert(moment(payload.datetime_start).utc().format())
    // alert(contract_follow_editer.datetime_start)
    calendar_list = calendar_list.map(function (value: any) {

      if (value.type === 2 && value.rent_m_contract_follow_id === payload.rent_m_contract_follow_id && value.rent_contract_id === payload.rent_contract_id) {

        // alert(payload.rent_m_contract_follow_name)
        if (value.nyuukyosha) {
          value.start_edit = new Date(moment(payload.datetime_start_n).utc().format('YYYY/MM/DD HH:mm'))
          value.end_edit = new Date(moment(payload.datetime_end_n).utc().format('YYYY/MM/DD HH:mm'))

          // value.is_time = payload.is_time
          value.date = payload.date_n
          value.start = payload.datetime_start_n
          value.end = payload.datetime_end_n
          value.remarks = payload.remarks_n
          value.way = payload.way_n

          value.follow_section_id = payload.section_id_n
          value.user_id = payload.user_id_n
          value.progress = payload.progress_n

          // value.start_edit = value.start
          // value.end_edit = value.end




        } else {

          value.start_edit = new Date(moment(payload.datetime_start).utc().format('YYYY/MM/DD HH:mm'))
          value.end_edit = new Date(moment(payload.datetime_end).utc().format('YYYY/MM/DD HH:mm'))

          value.date = payload.date
          value.start = payload.datetime_start
          value.end = payload.datetime_end
          value.remarks = payload.remarks
          value.way = payload.way

          value.follow_section_id = payload.section_id
          value.user_id = payload.user_id
          value.progress = payload.progress

          // value.start_edit = value.start
          // value.end_edit = value.end


        }


        // console.log(payload.datetime_start_n)
        // console.log(value.start)
        // console.log(new Date(moment(value.start).utc().format('YYYY-MM-DD HH:mm:ss')))
        // console.log("aaaaaaaaaaaaaaaaaaaa")
        if (moment(value.start).utc().format('YYYY/MM/DD HH:mm') !== moment(value.start).utc().startOf('day').format('YYYY/MM/DD HH:mm')) {

          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))

          if (moment(value.start) < moment(new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm')))) {
            value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
          } else {
            value.end = new Date(moment(value.start).add(30, 'm').format('YYYY/MM/DD HH:mm'))
          }

        } else {
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD'))
          value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD'))
        }

        if (moment(value.start).tz("Asia/Tokyo").hour() < 9 || moment(value.start).tz("Asia/Tokyo").hour() > 18) {
          value.start = new Date(moment(value.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
          value.end = new Date(moment(value.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
        }else if(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes') <= 30){
          value.end = new Date(moment(value.end).tz("Asia/Tokyo").add(30-(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
        }
      }


      return value
    })

    yield put(Calendar.change_calendar_list(calendar_list));
    yield put(change_contract_follow_edit_modal(false))


    let calendar_list_edit = state.calendar.calendar_list_edit

    calendar_list_edit = calendar_list_edit.map(function (value: any) {

      if (value.type === 2 && value.rent_m_contract_follow_id === payload.rent_m_contract_follow_id && value.rent_contract_id === payload.rent_contract_id) {

        if (value.nyuukyosha) {
          value.start_edit = new Date(moment(payload.datetime_start_n).utc().format('YYYY/MM/DD HH:mm'))
          value.end_edit = new Date(moment(payload.datetime_end_n).utc().format('YYYY/MM/DD HH:mm'))

          // value.is_time = payload.is_time
          value.date = payload.date_n
          value.start = payload.datetime_start_n
          value.end = payload.datetime_end_n
          value.remarks = payload.remarks_n
          value.way = payload.way_n

          value.follow_section_id = payload.section_id_n
          value.user_id = payload.user_id_n
          value.progress = payload.progress_n



        } else {

          value.start_edit = new Date(moment(payload.datetime_start).utc().format('YYYY/MM/DD HH:mm'))
          value.end_edit = new Date(moment(payload.datetime_end).utc().format('YYYY/MM/DD HH:mm'))

          value.date = payload.date
          value.start = payload.datetime_start
          value.end = payload.datetime_end
          value.remarks = payload.remarks
          value.way = payload.way

          value.follow_section_id = payload.section_id
          value.user_id = payload.user_id
          value.progress = payload.progress

        }


        if (moment(value.start).utc().format('YYYY/MM/DD HH:mm') !== moment(value.start).utc().startOf('day').format('YYYY/MM/DD HH:mm')) {

          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD HH:mm'))

          if (moment(value.start) < moment(new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm')))) {
            value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD HH:mm'))
          } else {
            value.end = new Date(moment(value.start).add(30, 'm').format('YYYY/MM/DD HH:mm'))
          }

        } else {
          value.start = new Date(moment(value.start).utc().format('YYYY/MM/DD'))
          value.end = new Date(moment(value.end).utc().format('YYYY/MM/DD'))
        }

        if (moment(value.start).tz("Asia/Tokyo").hour() < 9 || moment(value.start).tz("Asia/Tokyo").hour() > 18) {
          value.start = new Date(moment(value.start).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
          value.end = new Date(moment(value.end).tz("Asia/Tokyo").startOf("day").format('YYYY/MM/DD HH:mm'))
        }else if(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes') <= 30){
          value.end = new Date(moment(value.end).tz("Asia/Tokyo").add(30-(moment(value.end).tz("Asia/Tokyo").diff(moment(value.start).tz("Asia/Tokyo"), 'minutes')), 'minutes').format('YYYY/MM/DD HH:mm'))
        }
      }


      return value
    })

    yield put(Calendar.change_calendar_list_edit(calendar_list_edit));
    yield put(Calendar.change_calendar_list_edit_dialog_disp(false));//カレンダー付のダイアログ









    let task_rent_responses_list = state.task.task_rent_responses_list

    task_rent_responses_list = task_rent_responses_list.map(function (value: any) {

      if (value.type === 2 && value.rent_m_contract_follow_id === payload.rent_m_contract_follow_id && value.rent_contract_id === payload.rent_contract_id) {

        if (value.nyuukyosha) {

          // value.is_time = payload.is_time
          value.date = payload.date_n
          value.start = payload.datetime_start_n
          value.end = payload.datetime_end_n
          value.remarks = payload.remarks_n
          value.way = payload.way_n

          value.follow_section_id = payload.section_id_n
          value.user_id = payload.user_id_n
          value.progress = payload.progress_n



        } else {

          value.date = payload.date
          value.start = payload.datetime_start
          value.end = payload.datetime_end
          value.remarks = payload.remarks
          value.way = payload.way

          value.follow_section_id = payload.section_id
          value.user_id = payload.user_id
          value.progress = payload.progress

        }

      }


      return value
    })

    yield put(Task.change_task_rent_responses_list(task_rent_responses_list))
    yield put(Task.search_task_rent_responses_list(task_rent_responses_list))
    yield put(Task.change_rent_tasks_modal(false))// ダッシュボードのダイアログ




    // yield delay(500)
    // yield put(change_rent_m_contract_follow_settings_save_popover(false))
  }
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し


  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}






function* handle_delete_rent_contract_follow_details(action: any): any {
  const state = yield select();

  yield put(change_contract_follow_editer_loading(true))
  let contract_follow_editer = state.contractfollow.contract_follow_editer
  // contract_follow_editer.date = contract_follow_editer.date === 'Invalid date' ? null : contract_follow_editer.date
  // contract_follow_editer.datetime_start = contract_follow_editer.datetime_start === 'Invalid date' ? null : contract_follow_editer.datetime_start
  // contract_follow_editer.datetime_end = contract_follow_editer.datetime_end === 'Invalid date' ? null : contract_follow_editer.datetime_end

  const { payload, error } = yield call(rent_contract_follow_details_api.follow_delete, contract_follow_editer);

  if (payload && !error) {
    // yield put(change_rent_m_contract_follow_settings_save(false))
    // yield put(change_rent_m_contract_follow_settings_save_popover(true))
    let contract_follow_list = state.contractfollow.contract_follow_list
    let contract_follow_editer = state.contractfollow.contract_follow_editer
    let rent_m_contract_follow_id = state.contractfollow.contract_follow_editer.rent_m_contract_follow_id


    contract_follow_list = yield all(contract_follow_list.map(function (value: any) {

      if (value.rent_contract_id === contract_follow_editer.rent_contract_id) {

        value["date_" + rent_m_contract_follow_id] = payload.date
        value["datetime_start_" + rent_m_contract_follow_id] = payload.datetime_start
        value["datetime_end_" + rent_m_contract_follow_id] = payload.datetime_end
        value["section_id_" + rent_m_contract_follow_id] = payload.section_id
        value["user_id_" + rent_m_contract_follow_id] = payload.user_id
        value["progress_" + rent_m_contract_follow_id] = payload.progress
        value["remarks_" + rent_m_contract_follow_id] = payload.remarks
        value["way_" + rent_m_contract_follow_id] = payload.way
        value["updated_at_" + rent_m_contract_follow_id] = payload.updated_at
        value["date_n_" + rent_m_contract_follow_id] = payload.date_n
        value["datetime_start_n_" + rent_m_contract_follow_id] = payload.datetime_start_n
        value["datetime_end_n_" + rent_m_contract_follow_id] = payload.datetime_end_n
        value["section_id_n_" + rent_m_contract_follow_id] = payload.section_id_n
        value["user_id_n_" + rent_m_contract_follow_id] = payload.user_id_n
        value["progress_n_" + rent_m_contract_follow_id] = payload.progress_n
        value["remarks_n_" + rent_m_contract_follow_id] = payload.remarks_n
        value["way_n_" + rent_m_contract_follow_id] = payload.way_n
        value["updated_at_n_" + rent_m_contract_follow_id] = payload.updated_at_n
      }
      return value

    }))


    yield put(change_contract_follow_list(contract_follow_list));
    // yield put(search_contract_follow_list(contract_follow_list));
    contract_follow_editer.open = false
    contract_follow_editer.key_name = 0
    contract_follow_editer.row_index = 0
    contract_follow_editer.contract_form = 0
    contract_follow_editer.name = ''
    contract_follow_editer.is_time = false
    yield put(change_contract_follow_editer(contract_follow_editer));

    yield put(change_contract_follow_editer_loading(false))


    yield put(change_contract_follow_editer_open(false))







    let calendar_list = state.calendar.calendar_list

    calendar_list = calendar_list.filter((value: any) => {
      return !(value.type === 2 && value.rent_contract_id === contract_follow_editer.rent_contract_id && value.rent_m_contract_follow_id === contract_follow_editer.rent_m_contract_follow_id);
    });


    yield put(Calendar.change_calendar_list(calendar_list));
    yield put(change_contract_follow_edit_modal(false))



    let calendar_list_edit = state.calendar.calendar_list_edit

    calendar_list_edit = calendar_list_edit.filter((value: any) => {
      return !(value.type === 2 && value.rent_contract_id === contract_follow_editer.rent_contract_id && value.rent_m_contract_follow_id === contract_follow_editer.rent_m_contract_follow_id);
    });


    yield put(Calendar.change_calendar_list_edit(calendar_list_edit));
    yield put(Calendar.change_calendar_list_edit_dialog_disp(false));//カレンダー付のダイアログ





  }

}





function* handle_set_contract_follow_end_bulk(action: any): any {
  const state = yield select();

  yield put(change_contract_follow_backdrop(true))
  // yield put(change_contract_follow_editer_loading(true))
  let contract_follow_end_bulk = state.contractfollow.contract_follow_end_bulk

  // contract_follow_editer.date = contract_follow_editer.date === 'Invalid date' ? null : contract_follow_editer.date
  // contract_follow_editer.datetime_start = contract_follow_editer.datetime_start === 'Invalid date' ? null : contract_follow_editer.datetime_start
  // contract_follow_editer.datetime_end = contract_follow_editer.datetime_end === 'Invalid date' ? null : contract_follow_editer.datetime_end

  const { payload, error } = yield call(rent_contracts_api.followend, contract_follow_end_bulk);

  if (payload && !error) {
    let contract_follow_list = state.contractfollow.contract_follow_list

    contract_follow_list = contract_follow_list.map(function (value: any) {
      if (contract_follow_end_bulk.indexOf(value.rent_contract_id) === -1)
        return value
    }).filter((a: any) => a != undefined)

    yield put(change_contract_follow_list(contract_follow_list));

    yield put(change_contract_follow_search_list([]))
    yield put(search_contract_follow_list([]))
    yield put(change_contract_follow_end_bulk([]))
    yield put(set_end_check([]))
    yield put(change_contract_follow_backdrop(false))
    yield put(change_contract_follow_end_bulk_open(false))
  }
}







function* handle_update_contract_follow_easy_mode(action: any): any {
  const state = yield select();
  // let rent_m_contract_follow_settings = state.contractfollow.rent_m_contract_follow_settings
  // let rent_m_contract_follows_disp = state.contractfollow.rent_m_contract_follows_disp

  // yield put(change_rent_m_contract_follow_settings_save(true))
  const { payload, error } = yield call(rent_contract_follow_details_api.easy_update, { nyuukyosha: action.payload.nyuukyosha, rent_contract_id: action.payload.rent_contract_id, rent_m_contract_follow_id: action.payload.rent_m_contract_follow_id, progress: state.contractfollow.contract_follow_easy_mode_progress });
  //     const { payload, error } = yield call(KyousaiApi.delete_kyousais, kyousaisset);

  if (payload && !error) {



    let contract_follow_list = state.contractfollow.contract_follow_list
    // let contract_follow_editer = state.contractfollow.contract_follow_editer
    // let rent_m_contract_follow_id = state.contractfollow.contract_follow_editer.rent_m_contract_follow_id


    contract_follow_list = yield all(contract_follow_list.map(function (value: any) {

      if (value.rent_contract_id === action.payload.rent_contract_id) {

        value["date_" + action.payload.rent_m_contract_follow_id] = payload.date
        value["datetime_start_" + action.payload.rent_m_contract_follow_id] = payload.datetime_start
        value["datetime_end_" + action.payload.rent_m_contract_follow_id] = payload.datetime_end
        value["section_id_" + action.payload.rent_m_contract_follow_id] = payload.section_id
        value["user_id_" + action.payload.rent_m_contract_follow_id] = payload.user_id
        value["progress_" + action.payload.rent_m_contract_follow_id] = payload.progress
        value["remarks_" + action.payload.rent_m_contract_follow_id] = payload.remarks
        value["way_" + action.payload.rent_m_contract_follow_id] = payload.way
        value["updated_at_" + action.payload.rent_m_contract_follow_id] = payload.updated_at
        value["date_n_" + action.payload.rent_m_contract_follow_id] = payload.date_n
        value["datetime_start_n_" + action.payload.rent_m_contract_follow_id] = payload.datetime_start_n
        value["datetime_end_n_" + action.payload.rent_m_contract_follow_id] = payload.datetime_end_n
        value["section_id_n_" + action.payload.rent_m_contract_follow_id] = payload.section_id_n
        value["user_id_n_" + action.payload.rent_m_contract_follow_id] = payload.user_id_n
        value["progress_n_" + action.payload.rent_m_contract_follow_id] = payload.progress_n
        value["remarks_n_" + action.payload.rent_m_contract_follow_id] = payload.remarks_n
        value["way_n_" + action.payload.rent_m_contract_follow_id] = payload.way_n
        value["updated_at_n_" + action.payload.rent_m_contract_follow_id] = payload.updated_at_n
      }
      return value

    }))
    // console.log(contract_follow_list)
    // yield put(change_contract_follow_search_list([]));

    yield put(change_contract_follow_list(contract_follow_list));
    yield put(search_contract_follow_list(contract_follow_list));


    // yield put(change_rent_m_contract_follow_settings_save(false))
    // yield put(change_rent_m_contract_follow_settings_save_popover(true))

    // yield delay(500)
    // yield put(change_rent_m_contract_follow_settings_save_popover(false))
  }
  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し


  //} else {
  //    // 失敗時アクション呼び出し
  //    yield put(loginFailure(payload));
  //}
}

function* handle_insert_web_video_call_reservation_detail(action: any):any {

  const state = yield select();

  let web_video_call_reservation_detail = state.contractfollow.web_video_call_reservation_detail

  let api:any
  if(state.contractfollow.contract_follow_editer.web_video_call_reservation_uuid){
    api = web_video_call_reservations_api.update
    web_video_call_reservation_detail.id = state.contractfollow.contract_follow_editer.web_video_call_reservation_id
    web_video_call_reservation_detail.uuid = state.contractfollow.contract_follow_editer.web_video_call_reservation_uuid

    web_video_call_reservation_detail.reservation_at = web_video_call_reservation_detail.reservation_at ? moment(web_video_call_reservation_detail.reservation_at).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss') : null
    web_video_call_reservation_detail.limited_at = web_video_call_reservation_detail.limited_at ? moment(web_video_call_reservation_detail.limited_at).tz('Asia/Tokyo').format('YYYY-MM-DD HH:mm:ss') : null

  } else { 
    web_video_call_reservation_detail.reservation_at = web_video_call_reservation_detail.reservation_at ? moment(web_video_call_reservation_detail.reservation_at).utc().format('YYYY-MM-DD HH:mm:ss') : null
    web_video_call_reservation_detail.limited_at = web_video_call_reservation_detail.limited_at ? moment(web_video_call_reservation_detail.limited_at).utc().format('YYYY-MM-DD HH:mm:ss') : null
    api = web_video_call_reservations_api.insert
  }


  const { payload, error } = yield call(api, web_video_call_reservation_detail);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    // yield put(change_web_video_call_reservation_detail(payload))
    yield put(change_web_video_call_reservation_detail_modal(false))

    if(!state.contractfollow.contract_follow_editer.web_video_call_reservation_uuid){

      yield put(get_web_video_call_reservation_detail(payload))

      // 一覧の情報更新
      let contract_follow_list = state.contractfollow.contract_follow_list.map(function (value: any) {
        if (value.rent_contract_id === web_video_call_reservation_detail.rent_contract_id) {
          value.web_video_call_reservation_uuid = payload
        }
        return value
      })
      yield put(change_contract_follow_list(contract_follow_list))

    }

  } else {

  }
}

function* handle_get_web_video_call_reservation_detail(action: any): any {

  const state = yield select();

  const { payload, error } = yield call(web_video_call_reservations_api.detail, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
  
    let newData = {...payload}
    newData.reservation_at = newData.reservation_at ? moment(newData.reservation_at).utc().format("YYYY/MM/DD HH:mm") :""
    newData.limited_at = newData.limited_at ? moment(newData.limited_at).utc().format("YYYY/MM/DD HH:mm") :""
    console.log("newData",newData)
    yield put(change_web_video_call_reservation_detail(newData))

    //detailの
    let contract_follow_editer = {...state.contractfollow.contract_follow_editer}
    contract_follow_editer.web_video_call_reservation_id = payload.id
    contract_follow_editer.web_video_call_reservation_uuid = payload.uuid
    yield put(change_contract_follow_editer(contract_follow_editer))

    //リスト

  } else {

  }
}



function* handle_get_users_list(action: any) {

  const { payload, error } = yield call(users_api.list, action.payload);
  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    yield put(change_users_list(payload))
  } else {

  }
}



function* handle_get_rent_m_agents(action: any) {

  // let rent_customer_editor_froms = state.contracts.rent_customer_editor_froms
  const { payload, error } = yield call(rent_m_agents_api.list, action.payload);


  // サーバーからのレスポンスデータによる分岐処理
  //if (ログイン成功か失敗かの条件) {
  // 成功時アクション呼び出し

  if (payload && !error) {
    // console.log(payload)
    // alert(JSON.stringify(payload))

    yield put(change_rent_m_agents(payload));

  } else {
    // 失敗時アクション呼び出し
    // yield put(loginFailure(payload));
  }


}
