import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';

import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import "react-input-range/lib/css/index.css";
import Button from '@mui/material/Button';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const moment = require('moment-timezone');
class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  render() {

    let kagi_koukan_date = ""
    if (this.props.rent_contract_details_old.kagi_koukan_date !== this.props.rent_contract_details_new.kagi_koukan_date) {
      kagi_koukan_date = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.kagi_koukan_date ? moment(this.props.rent_contract_details_old.kagi_koukan_date).format("YYYY年M月D日") : ""}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.kagi_koukan_date ? moment(this.props.rent_contract_details_new.kagi_koukan_date).format("YYYY年M月D日") : ""}</div></span>

    } else {
      kagi_koukan_date = this.props.rent_contract_details_new.kagi_koukan_date ? moment(this.props.rent_contract_details_new.kagi_koukan_date).format("YYYY年M月D日") : ""

    }



    let hoshuu_date = ""
    if (this.props.rent_contract_details_old.hoshuu_date !== this.props.rent_contract_details_new.hoshuu_date) {
      hoshuu_date = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.hoshuu_date ? moment(this.props.rent_contract_details_old.hoshuu_date).format("YYYY年M月D日") : ""}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.hoshuu_date ? moment(this.props.rent_contract_details_new.hoshuu_date).format("YYYY年M月D日") : ""}</div></span>

    } else {
      hoshuu_date = this.props.rent_contract_details_new.hoshuu_date ? moment(this.props.rent_contract_details_new.hoshuu_date).format("YYYY年M月D日") : ""

    }

    let shoudoku_date = ""
    if (this.props.rent_contract_details_old.shoudoku_date !== this.props.rent_contract_details_new.shoudoku_date) {
      shoudoku_date = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.shoudoku_date ? moment(this.props.rent_contract_details_old.shoudoku_date).format("YYYY年M月D日") : ""}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.shoudoku_date ? moment(this.props.rent_contract_details_new.shoudoku_date).format("YYYY年M月D日") : ""}</div></span>
    } else {
      shoudoku_date = this.props.rent_contract_details_new.shoudoku_date ? moment(this.props.rent_contract_details_new.shoudoku_date).format("YYYY年M月D日") : ""
    }

    let koukin_date = ""
    if (this.props.rent_contract_details_old.koukin_date !== this.props.rent_contract_details_new.koukin_date) {
      koukin_date = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.koukin_date ? moment(this.props.rent_contract_details_old.koukin_date).format("YYYY年M月D日") : ""}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.koukin_date ? moment(this.props.rent_contract_details_new.koukin_date).format("YYYY年M月D日") : ""}</div></span>
    } else {
      koukin_date = this.props.rent_contract_details_new.koukin_date ? moment(this.props.rent_contract_details_new.koukin_date).format("YYYY年M月D日") : ""
    }

    let kagi_shozai = ""
    let kagi_shozai_arr = []
    kagi_shozai_arr[0] = "-"
    kagi_shozai_arr[1] = "番町店"
    kagi_shozai_arr[2] = "レインボーロード店"
    kagi_shozai_arr[3] = "高松駅前店"
    kagi_shozai_arr[4] = "高松東店"
    kagi_shozai_arr[5] = "中央公園店"
    kagi_shozai_arr[6] = "サンフラワー店"
    kagi_shozai_arr[7] = "中央通り店"
    kagi_shozai_arr[8] = "現地"
    if (this.props.rent_contract_details_old.kagi_shozai !== this.props.rent_contract_details_new.kagi_shozai) {
      kagi_shozai = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.kagi_shozai ? kagi_shozai_arr[this.props.rent_contract_details_old.kagi_shozai] : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.kagi_shozai ? kagi_shozai_arr[this.props.rent_contract_details_new.kagi_shozai] : "-"}</div></span>
    } else {
      kagi_shozai = this.props.rent_contract_details_new.kagi_shozai ? kagi_shozai_arr[this.props.rent_contract_details_new.kagi_shozai] : "-"
    }

    let kagi_gyousha = ""
    let kagi_gyousha_arr = []
    kagi_gyousha_arr[0] = "-"
    kagi_gyousha_arr[1] = "オーナー"
    kagi_gyousha_arr[2] = "他社"
    kagi_gyousha_arr[3] = "当社"

    if (this.props.rent_contract_details_old.kagi_gyousha !== this.props.rent_contract_details_new.kagi_gyousha) {
      kagi_gyousha = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.kagi_gyousha ? kagi_gyousha_arr[this.props.rent_contract_details_old.kagi_gyousha] : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.kagi_shozai ? kagi_gyousha_arr[this.props.rent_contract_details_new.kagi_gyousha] : "-"}</div></span>
    } else {
      kagi_gyousha = this.props.rent_contract_details_new.kagi_gyousha ? kagi_gyousha_arr[this.props.rent_contract_details_new.kagi_gyousha] : "-"
    }

    let kouji_gyousha = ""
    let kouji_gyousha_arr = []
    kouji_gyousha_arr[0] = "-"
    kouji_gyousha_arr[1] = "オーナー"
    kouji_gyousha_arr[2] = "当社"


    if (this.props.rent_contract_details_old.kouji_gyousha !== this.props.rent_contract_details_new.kouji_gyousha) {
      kouji_gyousha = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.kouji_gyousha ? kouji_gyousha_arr[this.props.rent_contract_details_old.kouji_gyousha] : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.kouji_gyousha ? kouji_gyousha_arr[this.props.rent_contract_details_new.kouji_gyousha] : "-"}</div></span>
    } else {
      kouji_gyousha = this.props.rent_contract_details_new.kouji_gyousha ? kouji_gyousha_arr[this.props.rent_contract_details_new.kouji_gyousha] : "-"
    }
    // rent_contract_editor_forms.kouji_gyousha = rent_contract_details.kouji_gyousha




    return (

      <div>

        <table>
          <tr>
            <th colSpan={4}>その他一般
             <Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_ippan_editor_disp", disp: true })}>
                編集
      </Button>
            </th>

          </tr>
          <tr>
            <th style={{ width: "20%" }}>補修完了日</th>
            <td style={{ width: "30%" }}>{hoshuu_date}</td>
            <th style={{ width: "20%" }}>補修先</th>
            <td style={{ width: "30%" }}>{kouji_gyousha}</td>
          </tr>



          <tr>
            <th>カギ交換</th>
            <td>{kagi_gyousha}</td>
            <th>カギの所在</th>
            <td>{kagi_shozai}</td>
          </tr>
          <tr>
            <th>カギ交換日</th>
            <td colSpan={3}>{kagi_koukan_date}</td>

          </tr>
          <tr>
            <th>消毒日</th>
            <td>{shoudoku_date}</td>
            <th>抗菌日</th>
            <td>{koukin_date}</td>
          </tr>






        </table>
      </div >

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
