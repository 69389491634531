import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
// import * as Customers from '../../Store/Customers';
import * as Entrusted from '../Entrusted/Saga/Entrusted';
import '../../Css/Grid.css';
// import ReactDataGrid from "react-data-grid"
import EntrustedGridComponent from './EntrustedGridComponent';
import EntrustedTaiyouGridComponent from './EntrustedTaiyouGridComponent';
import TatemonoInfo from './TatemonoInfo';
import TatemonoGridComponent from './TatemonoGridComponent';
import TatemonoListSearchComponent from './TatemonoListSearchComponent';
import EntrustedOutputComponent from './EntrustedOutputComponent';
import { submit } from 'redux-form';

import '../../Css/Form.css';

import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {
    get_t_shutoku_taiyous: any,
    t_shutoku_taiyous: any,
    t_shutokus: any,
    get_t_shutokus: any,
    get_tatemono_list: any,
    get_t_shutoku_taiyou_details: any,
    get_tm_shutoku_youins: any,
    get_entrusted_rooms: any,
    submit: any,
    entrusted_rooms: any,
    update_t_shutokus: any,
    get_t_shutoku_taiyou_details_success: any,
    get_entrusted_rooms_success: any,
    delete_t_shutokus: any,
    change_entrusted_modal: any,
    entrusted_modal: boolean,
    get_excel: any,
}


interface State {
    toggle: boolean,
    modallist: boolean,
    tatemonoId: string,
    modalname: string,
    shutokuNo: number
    outputmodal: boolean,
    isTatemono: number,
    // state types
}





class App extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.props.get_tatemono_list({ kanriCorpNo: 1, isTatemono: 1 });
        this.props.get_tm_shutoku_youins();
        this.state = {
            toggle: false,
            modallist: false,
            tatemonoId: "",
            isTatemono: 1,
            modalname: "",
            shutokuNo: 0,
            outputmodal: false
        }

        this.toggle = this.toggle.bind(this);
        this.togglelist = this.togglelist.bind(this);
        this.tatemonoSet = this.tatemonoSet.bind(this);
        this.search = this.search.bind(this);
        this.output = this.output.bind(this);


        this.detailset = this.detailset.bind(this);
        this.submit = this.submit.bind(this);
        this.delete_t_shutokus = this.delete_t_shutokus.bind(this);
        this.toggleoutput = this.toggleoutput.bind(this);


    }

    toggle() {

        this.props.change_entrusted_modal(this.props.entrusted_modal === true ? false : true)

    }
    detailset(value: any) {
        console.log(value)
        // alert(this.state.tatemonoId)
        if (value !== 0) {
            this.props.get_entrusted_rooms(value)
            this.props.get_t_shutoku_taiyou_details(value)

            // const tatemonoId = value.isTatemono==true?value.tatemonoId:"SysP"+value.tatemonoId
            this.setState({
                tatemonoId: value.tatemonoId,
                shutokuNo: value.shutokuNo,
                isTatemono: value.isTatemono,
            });

        } else {
            this.props.get_t_shutoku_taiyou_details_success({ tatemonoId: this.state.tatemonoId, shutokuNo: 0, isTatemono: this.state.isTatemono })
            let roomlist = []
            for (var set = 0; set < 100; set++) {
                roomlist.push({
                    shutokuNo: 0,

                    selected: 0,
                    roomName: "",
                    isPrint: false,
                    id: set
                })
            }
            this.props.get_entrusted_rooms({ tatemonoId: this.state.tatemonoId, shutokuNo: 0, isTatemono: this.state.isTatemono })
            // this.props.get_entrusted_rooms_success(roomlist);
        }

        this.toggle()
    }

    togglelist() {

        this.setState((prevState: any) => ({
            modallist: !prevState.modallist,

        }));
    }

    tatemonoSet(value: any) {

        this.props.get_t_shutokus(value);
        this.props.get_t_shutoku_taiyous(value);
        let tatemonoId = value.tatemonoId;
        if (value.tatemonoId.indexOf('SysP') === -1) {
            tatemonoId = value.isTatemono !== 0 ? value.tatemonoId : "SysP" + value.tatemonoId
        }
        this.setState({
            tatemonoId: tatemonoId,
            isTatemono: value.isTatemono,
            modalname: value.tatemonoName
        });
        this.togglelist();
    }

    search(value: any) {
        this.props.get_tatemono_list(value);
    }
    submit(value: any) {

        let t_shutokus: { t_shutokus: any; entrusted_rooms: any }[] = [{
            t_shutokus: value,
            entrusted_rooms: this.props.entrusted_rooms.filter((a: any) => a.selected === 1),

        }];
        console.log(t_shutokus)
        // alert(value.tatemonoId)
        this.props.update_t_shutokus(t_shutokus)
    }



    delete_t_shutokus() {
        this.props.delete_t_shutokus({ "tatemonoId": this.state.tatemonoId, shutokuNo: this.state.shutokuNo })
    }

    output(value: any) {
        this.props.get_excel(value)
    }
    toggleoutput() {
        this.setState((prevState: any) => ({
            outputmodal: !prevState.outputmodal,
        }));
    }
    render() {

        return (
            <div>
                <Row>
                    <Col lg="12" className="item-outer" style={{ background: "#FFFFFF" }}>

                        <TatemonoListSearchComponent onSubmit={this.search} toggleoutput={this.toggleoutput} />


                    </Col>
                    <Col lg="12" className="item-outer">
                        <div className="item">
                            <TatemonoGridComponent toggle={this.tatemonoSet}
                            />
                        </div>
                    </Col>
                </Row>

                <Modal isOpen={this.state.modallist} toggle={this.togglelist} size="xxl" fade={false}>
                    <ModalHeader toggle={this.togglelist}>{this.state.modalname}</ModalHeader>
                    <ModalBody >
                        <Row>


                            <Col lg="12" className="item-outer">
                                <div className="item">
                                    <EntrustedGridComponent detailset={this.detailset} t_shutokus={this.props.t_shutokus} />
                                </div>
                            </Col>
                            <Col lg="12" className="item-outer">
                                <div className="item">
                                    <button className="btn btn-sm btn-danger float-left" type="button" onClick={() => this.detailset(0)}>追加</button>

                                    <EntrustedTaiyouGridComponent
                                        detailset={this.detailset}
                                        t_shutoku_taiyous={this.props.t_shutoku_taiyous} />
                                </div>
                            </Col>
                        </Row>
                    </ModalBody >

                    <ModalFooter>
                    </ModalFooter>
                </Modal>



                <Modal isOpen={this.props.entrusted_modal} toggle={this.toggle} size="xxl" fade={false}>
                    <ModalHeader toggle={this.toggle}>{this.state.modalname}</ModalHeader>


                    <TatemonoInfo onSubmit={this.submit} />
                    <ModalFooter>

                        <button className="btn btn-primary float-right" type="button" onClick={() => this.props.submit('estrustedform')}>保存</button>
                        <button className="btn btn-danger float-right" type="button" onClick={this.delete_t_shutokus}>削除</button>
                        <Button color="secondary" onClick={this.toggle}>キャンセル</Button>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.outputmodal} toggle={this.toggleoutput} size="sl" fade={false}>
                    <ModalHeader toggle={this.toggleoutput}>物件取得表印刷</ModalHeader>
                    <ModalBody>
                        <EntrustedOutputComponent onSubmit={this.output} />
                    </ModalBody>
                    <ModalFooter>
                        <button className="btn btn-success float-right" type="button" onClick={() => this.props.submit('entrustedoutputform')}>印刷</button>
                    </ModalFooter>
                </Modal>


            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        t_shutoku_taiyous: state.entrusted.t_shutoku_taiyous,
        t_shutokus: state.entrusted.t_shutokus,
        tatemono_list: state.entrusted.tatemono_list,
        entrusted_rooms: state.entrusted.entrusted_rooms,
        entrusted_modal: state.entrusted.entrusted_modal,

        // responseid: state.customersReducer.responseid,
        // responsedetail: state.customersReducer.responsedetail,
        // customer_list_loading:state.customersReducer.customer_list_loading
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_t_shutoku_taiyous(state: any) {
            dispatch(Entrusted.get_t_shutoku_taiyous(state))
        },
        get_t_shutokus(state: any) {
            dispatch(Entrusted.get_t_shutokus(state))
        },
        get_tatemono_list(state: any) {
            dispatch(Entrusted.get_tatemono_list(state))
        },
        get_t_shutoku_taiyou_details(state: any) {
            dispatch(Entrusted.get_t_shutoku_taiyou_details(state))
        },
        get_tm_shutoku_youins(state: any) {
            dispatch(Entrusted.get_tm_shutoku_youins(state))
        },
        get_entrusted_rooms(state: any) {
            dispatch(Entrusted.get_entrusted_rooms(state))
        },
        submit(state: any) {
            dispatch(submit(state))
        },
        update_t_shutokus(state: any) {
            dispatch(Entrusted.update_t_shutokus(state))
        },
        get_t_shutoku_taiyou_details_success(state: any) {
            dispatch(Entrusted.get_t_shutoku_taiyou_details_success(state))
        },
        get_entrusted_rooms_success(state: any) {
            dispatch(Entrusted.get_entrusted_rooms_success(state))
        },
        delete_t_shutokus(state: any) {
            dispatch(Entrusted.delete_t_shutokus(state))
        },
        change_entrusted_modal(state: any) {
            dispatch(Entrusted.change_entrusted_modal(state))
        },
        get_excel(state: any) {
            dispatch(Entrusted.get_excel(state))
        },
        // change_customerloding(state:number) {
        //     dispatch(Customers.change_customerloding(state))
        // },
        // change_customerloding1(state:number) {
        //     dispatch(Customers.change_customerloding1(state))
        // },
        // change_customerloding2(state:number) {
        //     dispatch(Customers.change_customerloding2(state))
        // },
        // customerdetailData(state:any) {
        //     dispatch(Customers.customerDetail(state))
        // },  
        // get_customer_logs(state:any) {
        //         dispatch(Entrusted.get_customer_logs(state))
        //     },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);