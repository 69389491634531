import React, { Component } from 'react';

import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import ShowRoomList from './ShowRoomList';
import ParkingDetail from './ParkingDetail';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Home from '@mui/icons-material/Home';
import LocalParking from '@mui/icons-material/LocalParking';
import { DialogContent, Dialog, DialogActions, DialogTitle } from '@mui/material';


function mise(list){

    let misebeya_list = ""

    list.forEach(function(value,index,array){

    // 管理とサブのみ
    if(value.kanri_kubun_no == 1 || value.kanri_kubun_no == 4){
    }else{
      return
    }
  
    // 入居可能状況が募集停止、家主使用、未補修、入居中以外
    if(value.kuushitsu_joukyou_no == 6 || value.kuushitsu_joukyou_no == 100 || value.kuushitsu_joukyou_no == 101 || value.kuushitsu_joukyou_no == 4){
      return
    }
  
    // 鍵番設置日が今日より未来なら見れない。
    if(value.kagiban_setti_date){
      // if(moment(value.io_keiyaku_date) > moment(value.kagiban_setti_date)){
        if(moment(value.kagiban_setti_date).format("YYYYMMDD") >= moment().format("YYYYMMDD")){
          return
        }
    // } else {
      
    // } 
    }
  
  
    // if(value.room_name == "B101"){
    //     alert(value.room_name)
    //   alert(value.io_keiyaku_date)
    //   alert(value.kaiyaku_date)
    // }
    
  
    let keiyakumae = false
    // 契約日がない場合OK
    // 
    if(value.io_keiyaku_date){
      if(moment(value.io_keiyaku_date).format("YYYYMMDD") < moment(value.kaiyaku_date).format("YYYYMMDD")) {
        keiyakumae = true
      }else{
        // 次の契約が決まってて、契約日は今日より未来
        if (moment(value.io_keiyaku_date).format("YYYYMMDD") > moment().format("YYYYMMDD")) {
          keiyakumae = true
        }
      }
    }else{
      keiyakumae = true
    }
    if (!keiyakumae) return
    
    // 鍵番撤去日が入ってて
    if(value.kagiban_tekkyo_date){
      if(value.kaiyaku_date) {
        if(moment(value.kaiyaku_date).format("YYYYMMDD") < moment(value.kagiban_tekkyo_date).format("YYYYMMDD")){
        // 撤去日が解約日より未来で、撤去日が今日より過去
          if(moment(value.kagiban_tekkyo_date).format("YYYYMMDD") <= moment().format("YYYYMMDD")){
            return
          }
        }
      }else{
        // 撤去日が今日より過去
        if(moment(value.kagiban_tekkyo_date).format("YYYYMMDD") <= moment().format("YYYYMMDD")){
          return
        }
      }
    }
    
    
    
    let nyuukyo_kanou
  
    if(value.nyuukyo_kanou_year == "" || value.nyuukyo_kanou_year  == "-" || value.nyuukyo_kanou_month == "" || value.nyuukyo_kanou_month == "-" || value.nyuukyo_kanou_day == "" || value.nyuukyo_kanou_day == "-"){
      nyuukyo_kanou = ""
    }else{
      nyuukyo_kanou = value.nyuukyo_kanou_year+value.nyuukyo_kanou_month+value.nyuukyo_kanou_day.replace("上旬","08").replace("中旬","18").replace("下旬","28")
    }
  
    // alert(value.room_name)
    // alert(value.kaiyaku_date)
    // alert(nyuukyo_kanou)
    
    // 解約日がない
    if(!value.kaiyaku_date){
      // 入居可能日が過ぎている。
      if(nyuukyo_kanou){
        if(moment(nyuukyo_kanou).format("YYYYMMDD") < moment().format("YYYYMMDD")){
            misebeya_list = misebeya_list == "" ? value.room_name : misebeya_list + "," + value.room_name
        }
      }
    }else{
      // 完工日を過ぎている。
      if(value.completion_at && moment(value.completion_at).format("YYYYMMDD") < moment().format("YYYYMMDD")){
        misebeya_list = misebeya_list == "" ? value.room_name : misebeya_list + "," + value.room_name
      }else if(moment(value.kaiyaku_date).format("YYYYMMDD") < moment().format("YYYYMMDD")){
        // 解約日を過ぎている。
        misebeya_list = misebeya_list == "" ? value.room_name : misebeya_list + "," + value.room_name
      }else if(moment(value.kagiban_setti_date).format("YYYYMMDD") < moment().format("YYYYMMDD")){
        // 鍵番設置日を過ぎている。
        misebeya_list = misebeya_list == "" ? value.room_name : misebeya_list + "," + value.room_name
      }
    }
    
    

});

    misebeya_list = misebeya_list.split(",")
    let misebeya_list2 = ""

    if(misebeya_list.length > 0) {
        misebeya_list2 = misebeya_list[0]
        if(misebeya_list.length > 1) {
            misebeya_list2 = misebeya_list2 + "," + misebeya_list[1]
            if(misebeya_list.length > 2) {
                misebeya_list2 = misebeya_list2 + "," + misebeya_list[2]
                if(misebeya_list.length > 3) {
                    misebeya_list2 = misebeya_list2 + ",他" + (misebeya_list.length - 3) + "部屋"
                }
            }
        }
    }

    return misebeya_list2 == "" ? "見せ部屋なし" : "見 " + misebeya_list2
  }

class App extends Component {

    constructor(props) {

        super(props);
        this.state = {
            misebeya_modal: false,
            change: false,
            // columnWidths: {
            //     date: 100,
            //     user_id: 90,
            //     section_id: 90,
            //     rent_m_corporate_way_id: 100,
            //     remarks: 500,
            // },
        };

    }

    render() {
        let misebeya = mise(this.props.mapsearch.map_room_details_all)
        return (
            <div style={{ background: "white" }}>
              {this.props.mapsearch.map_room_details_loading ? <div>
                {this.props.mapsearch.map_room_details[0] ? <div className="tatemono_detail" style={{ paddingleft: 15, border: "1px solid #c4c4c4", height: 180, overflowY: "auto" }}>
                    <ul className="float_mlist" style={{ padding: 8, marginBottom: 30 }}>
                        <li style={{ float: "left", fontSize: 10, fontWeight: "bold", background: "#CC6666", padding: "2px 5px", color: "#fff", marginTop: 0, display: "inline" }}>{this.props.mapsearch.map_room_details[0].kouzou_name}</li>
                        <li style={{ float: "left", fontWeight: "bold", fontSize: 14, paddingTop: 3, paddingBottom: 3, display: "inline", marginLeft: 5 }}>{this.props.mapsearch.map_room_details[0].tatemono_name}</li>
                    </ul>
                    <div style={{ clear: "both" }}></div>

                    <div style={{ float: "left", width: 100 }}>
                        <img src={"https://img.global-center.co.jp/v2/tatemono/" + this.props.mapsearch.map_room_details[0].tatemono_id + "/1/1/520/780/0"} style={{ width: 88, border: "1px solid #c4c4c4" }} />
                    </div>

                    <div style={{ marginLeft: 5, float: "left", fontSize: 12}}>
                        <p style={{ margin: 3 }}>{this.props.mapsearch.map_room_details[0].shikuchoson + this.props.mapsearch.map_room_details[0].choiki + this.props.mapsearch.map_room_details[0].other_address}</p>
                        <p style={{ margin: 3 }}>{this.props.mapsearch.map_room_details[0].koutsuu_1} 徒歩{this.props.mapsearch.map_room_details[0].koutsuu_1_min}分</p>
                        <p style={{ margin: 3 }}>竣工年月：{this.props.mapsearch.map_room_details[0].shunkou_year}年{this.props.mapsearch.map_room_details[0].shunkou_month}月</p>
                        <p style={{ margin: 3 }}>管理会社：{this.props.mapsearch.map_room_details[0].kanri_corp_name}</p>
                        {this.props.mapsearch.map_room_details[0] && this.props.mapsearch.map_room_details[0].kanri_corp_no == 1 ? <p style={{ margin: 3 }}>担当：{this.props.mapsearch.map_room_details[0].shoyuusha_tantou_name}</p>:""}
                    </div>
                    <div style={{ clear: "both" }}></div>
                    
                    {this.props.mapsearch.map_room_details[0] && this.props.mapsearch.map_room_details[0].kanri_corp_no == 1 ? 
                    <button className={misebeya == "見せ部屋なし" ? "btn btn-sm btn-secondary" : "btn btn-sm btn-warning"} onClick={(e) => { this.setState({ "misebeya_modal": true }) }} style={{ fontSize: 10, padding: "0px 3px 0px 3px", marginLeft:10, }}><Home style={{fontSize:13, marginTop:-2}} />{misebeya}</button>:""}
                    {this.props.mapsearch.map_room_details[0] && this.props.mapsearch.map_room_details[0].p_cnt > 0 ? 
                    <button className={"btn btn-sm btn-info"} onClick={(e) => { this.setState({ "p_modal": true }) }} style={{ fontSize: 10, padding: "0px 3px 0px 3px", marginLeft:10, }}><LocalParking style={{fontSize:13, marginTop:-2}} />敷地内駐車場</button>:""}
                </div> : "募集承認されていないため表示できません。"}
                </div>:""}



                <Dialog open={this.state.misebeya_modal} onClose={(e) => { this.setState({ "misebeya_modal": false }) }} maxWidth={false} fullWidth={true}>
                    <DialogTitle>
                        見せ部屋　　{this.props.mapsearch.map_room_details[0] ? this.props.mapsearch.map_room_details[0].tatemono_name : ""}
                        <IconButton
                            aria-label="close"
                            style={{position:'absolute', right:1, top:1}}
                            onClick={(e) => { this.setState({ "misebeya_modal": false }) }}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <ShowRoomList />
                        <div>
                            <span style={{backgroundColor:"#fff396", padding:"5px 10px"}}>見せ部屋</span>
                            {/* <span>補修状況が不明のため要確認</span> */}
                        </div>
                        <div></div>
                        <div style={{color:"gray"}}>※解約日が空で、入居可能日以降　または　解約日（解約日以前に完工している場合は完工日）を過ぎている、かつ鍵番設置日以降で契約日未満　の部屋を見せ部屋とする。</div>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "misebeya_modal": false }) }}>閉じる</Button>
                    </DialogActions>

                </Dialog>

                <Dialog open={this.state.p_modal} onClose={(e) => { this.setState({ "p_modal": false }) }} maxWidth={false} fullWidth={true}>
                    <DialogTitle>
                        {this.props.mapsearch.map_room_details[0] ? this.props.mapsearch.map_room_details[0].tatemono_name : ""}　駐車場
                        <IconButton
                            aria-label="close"
                            style={{position:'absolute', right:1, top:1}}
                            onClick={(e) => { this.setState({ "p_modal": false }) }}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent>
                        <ParkingDetail />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "p_modal": false }) }}>閉じる</Button>
                    </DialogActions>

                </Dialog>
            </div>
        );
    }
};



export default connect(
    ({ mapsearch }) => ({ mapsearch }),
    {
        // MapSearch
    }
)(App)