import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import Button from '@mui/material/Button';
import "react-input-range/lib/css/index.css";

import Alert from '@mui/material/Alert';

var moment = require('moment-timezone');
class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      confirm: false,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.change_new = this.change_new.bind(this);
    this.change_old = this.change_old.bind(this);
  }
  change_new(e) {

    this.props.change_rent_contract_logs_new(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  change_old(e) {
    this.props.change_rent_contract_logs_old(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }

  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }



  render() {
    let ver = []
    for (var i = 1; i <= this.props.rent_contract_details.ver; i++) {
      ver.push(i)
    }
    return (

      <div>

        {
          this.props.rent_contract_details.is_cancel ? <Alert severity="error">{moment(this.props.rent_contract_details.cancel_date).format("YYYY年MM月DD日")}に契約がキャンセルされました。
          </Alert> :
            <div>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_contract(this.props.rent_contract_details.id)}>
                契約書
              </Button>
                {this.props.rent_contract_details.counting === 1 ?
                    <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_disclosure_statement(this.props.rent_contract_details.id)}>
                    重要事項説明書
                    </Button>:''
                }
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_bill(this.props.rent_contract_details.id)}>
                請求書
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_docs(this.props.rent_contract_details.id)}>
                365契約書
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_lifeline_sheet(this.props.rent_contract_details.id)}>
                ライフライン
              </Button>

              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_jippi(this.props.rent_contract_details.id)}>
                実費精算
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_shikibiki(this.props.rent_contract_details.id)}>
                敷引精算
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_kaketsuke(this.props.rent_contract_details.id)}>
                かけつけ
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_kaden(this.props.rent_contract_details.id)}>
                レンタル家電
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_kaden_shiyou(this.props.rent_contract_details.id)}>
                家電使用賃借
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_jousuiki(this.props.rent_contract_details.id)}>
                浄水器
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_hazard(this.props.rent_contract_details.id)}>
                ハザードマップ
              </Button>
              <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.props.download_mimamori(this.props.rent_contract_details.id)}>
                みまもり
              </Button>




              {this.props.contracts_cancel_confirm ? <Alert severity="error">本当にキャンセルしますか？
                <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="secondary" onClick={() => this.props.contracts_cancel(this.props.rent_contract_details.id)}>
                  契約キャンセル
                </Button><Button style={{ float: "right", margin: 5 }} size="small" variant="contained" onClick={() => this.props.change_contracts_cancel_confirm(false)}>
                  閉じる
                </Button></Alert> : <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="secondary" onClick={() => this.props.change_contracts_cancel_confirm(true)}>
                契約キャンセル
              </Button>
              }
            </div>}
        {/* {this.props.rent_contract_details.counting === -1 ? Number(this.props.rent_contract_details.room_id) === -1 ? <Alert severity="error">GlobalParkingから新規申込がありました。駐車場区画を選択して審査を確定してください。</Alert> : this.state.confirm ? <Alert severity="error">承認すると電子契約とカード決済登録メールが申込者に送信されます。
          <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="secondary" onClick={() => this.props.contracts_send_parking(this.props.rent_contract_details.id)}>
            契約書ダウンロード
          </Button></Alert> : <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={() => this.setState({ confirm: true })}>
          契約審査を承認する
        </Button> : ""
        }
        {ver.length > 0 ? <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">確認済</InputLabel>
          <Select
            name="old"
            id="old"
            value={this.props.rent_contract_logs_old}
            margin="dense"
            onChange={this.change_old}
            style={{ fontSize: 13, width: 150 }}
          >
            {ver.map(function (ver_value) {
              return <MenuItem value={ver_value}>{ver_value}</MenuItem>
            })

            }

          </Select> </FormControl> : ""}

        {ver.length > 0 ? <FormControl variant="outlined">
          <InputLabel id="demo-simple-select-outlined-label">VER</InputLabel>
          <Select
            name="new"
            id="new"
            value={this.props.rent_contract_logs_new}
            margin="dense"
            onChange={this.change_new}
            // onChange={this.onChangeText}
            style={{ fontSize: 13, width: 150 }}
          >
            {ver.map(function (ver_value) {
              return <MenuItem value={ver_value}>{ver_value}</MenuItem>
            })

            }

          </Select>


        </FormControl> : ""} */}
      </div >

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_logs_new: state.contracts.rent_contract_logs_new,
    rent_contract_logs_old: state.contracts.rent_contract_logs_old,
    contracts_cancel_confirm: state.contracts.contracts_cancel_confirm,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_rent_contract_logs_new(state) {
      dispatch(Contracts.change_rent_contract_logs_new(state))
    },
    change_rent_contract_logs_old(state) {
      dispatch(Contracts.change_rent_contract_logs_old(state))
    },
    change_rent_contract_logs_ver(state) {
      dispatch(Contracts.change_rent_contract_logs_ver(state))
    },
    contracts_send_parking(state) {
      dispatch(Contracts.contracts_send_parking(state))
    },
    download_docs(state) {
      dispatch(Contracts.download_docs(state))
    },
    download_lifeline_sheet(state) {
        dispatch(Contracts.download_lifeline_sheet(state))
    },
    download_jippi(state) {
        dispatch(Contracts.download_jippi(state))
    },
    download_shikibiki(state) {
        dispatch(Contracts.download_shikibiki(state))
    },
    download_kaketsuke(state) {
        dispatch(Contracts.download_kaketsuke(state))
    },
    download_kaden(state) {
        dispatch(Contracts.download_kaden(state))
    },
    download_kaden_shiyou(state) {
        dispatch(Contracts.download_kaden_shiyou(state))
    },
    download_jousuiki(state) {
        dispatch(Contracts.download_jousuiki(state))
    },
    download_hazard(state) {
        dispatch(Contracts.download_hazard(state))
    },
    download_bill(state) {
      dispatch(Contracts.download_bill(state))
    },
    download_contract(state) {
      dispatch(Contracts.download_contract(state))
    },
    download_disclosure_statement(state) {
        dispatch(Contracts.download_disclosure_statement(state))
      },
    download_mimamori(state) {
        dispatch(Contracts.download_mimamori(state))
    },
    change_contracts_cancel_confirm(state) {
      dispatch(Contracts.change_contracts_cancel_confirm(state))
    },
    contracts_cancel(state) {
      dispatch(Contracts.contracts_cancel(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
