
const authentication = require('../../../react-azure-adb2c2').default;
export const delete_card = (values: any) => {

// alert(values.rent_hearing_sheet_id)
    // let url = process.env.REACT_APP_API_LINE_EX_URL + `/v1/line/users/delete/` + values.rent_hearing_sheet_id;
    // let url = `http://localhost:5000/v1/rent/hearing/sheets/delete/` + values.rent_hearing_sheet_id;
    let url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/hearing/sheets/delete/` + values.rent_hearing_sheet_id;

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'put',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

