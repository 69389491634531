const authentication = require('../../../react-azure-adb2c2').default;


// export const detail = (values: any) => {
//     const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/headers/detail/` + values;
//     // const url = `http://localhost:5000/v1/rent/introduction/headers/detail/` + values;

//     const token = authentication.getAccessToken();
//     //const search = values ? values : {};


//     return fetch(url, {
//         headers: { 'Authorization': 'Bearer ' + token }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// }


// export const list = (values: any) => {

//     // const url = `http://localhost:5000/v1/rent/introduction/headers/list/` + values;

//     const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/headers/list/` + values;

//     const token = authentication.getAccessToken();
//     //const search = values ? values : {};
//     // alert(url)

//     return fetch(url, {
//         headers: { 'Authorization': 'Bearer ' + token }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// }

// export const insert = (values: any) => {
//     // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductionHeaders/`;
//     const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/headers/insert`;
//     // const url = `https://localhost:44341/api/RentIntroductions/`;
//     const token = authentication.getAccessToken();
//     //const search = values ? values : {};

//     // alert(JSON.stringify(values))
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(values),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// }

export const updatecomment = (values: any) => {
    console.log(values)
    // alert(values.id)
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/details/updatecomment/` + values.id
    // const url = `http://localhost:5000/v1/rent/introduction/details/updatecomment/` + values.id
    // alert(JSON.stringify(values))
    const token = authentication.getAccessToken();
    console.log(values)
    // alert(url)
    //const search = values ? values : {};
    //  alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

// export const deleteheaders = (values: any) => {
//     // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductionHeaders/` + values;
//     const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/headers/delete/` + values;
//     // const url = `https://localhost:44341/api/RentIntroductionHeaders/`+values;
//     const token = authentication.getAccessToken();
//     //const search = values ? values : {};

//     // alert(JSON.stringify(values))
//     return fetch(url, {
//         method: 'delete',
//         // body: JSON.stringify(values),

//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

// }