

const authentication = require('../../../react-azure-adb2c2').default;




export const list = (values) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/RentContracts?moneyall=1`;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};





export const maillist = (values) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/mail/api/mails/` + values;
    // let url = `https://localhost:44341/mail/api/mails/`+values;

    // alert(url)

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};








export const detail = (Id) => {
    const url = process.env.REACT_APP_API_BASE_URL + `/api/Mails/` + Id;
    // authentication.run();
    const token = authentication.getAccessToken();
    // alert(token)
    // console.log(token)
    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}



export const newmail = (payload) => {


    // const url = process.env.REACT_APP_API_BASE_URL + `/api/Mails/`;
    // // const url = `https://localhost:44341//api/Mails/`;



    // const url = `http://localhost:5000/v1/mails/new`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/mails/new`;


    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(payload))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(payload),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const newmailtemplate = (payload) => {

    // const url = process.env.REACT_APP_API_BASE_URL + `/ v1 / mails /`;
    // const url = `http://localhost:5000/v1/mails/templatemail/` + payload.rent_response_id + '/' + payload.mail_template_id;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/mails/templatemail/` + payload.rent_response_id + '/' + payload.mail_template_id;
    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(payload))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(payload),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const contractmailtemplate = (payload) => {

    // const url = process.env.REACT_APP_API_BASE_URL + `/ v1 / mails /`;
    // const url = `http://localhost:5000/v1/mails/contracttemplatemail/` + payload.rent_response_id + '/' + payload.mail_template_id;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/mails/contracttemplatemail/` + payload.rent_response_id + '/' + payload.mail_template_id;
    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(payload))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(payload),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const newmailpublicfiles = (payload) => {

    // const url = process.env.REACT_APP_API_BASE_URL + `/ v1 / mails /`;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/mails/publicfiles/` + payload.rent_response_id + '/' + payload.rent_limited_public_page_id
    // const url = `http://localhost:5000/v1/mails/publicfiles/` + payload.rent_response_id + '/' + payload.rent_limited_public_page_id;
    // alert(url)
    const token = authentication.getAccessToken();
    // alert(JSON.stringify(payload))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(payload),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const update = (values) => {

    if (values.id > 0) {
        const url = process.env.REACT_APP_API_BASE_URL + `/api/Mails/` + values.id;
        // let url = `https://localhost:44341/api/Mails/`+values.id;
        const token = authentication.getAccessToken();
        // console.log(JSON.stringify(payload))
        return fetch(url, {
            method: 'put',
            body: JSON.stringify(values),

            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
            .then(payload => ({ payload }))
            .catch(error => ({ error }));
    }
};



export const delete_file = (values) => {

    if (values > 0) {
        const url = process.env.REACT_APP_API_BASE_URL + `/api/MailFiles/` + values;
        // let url = `https://localhost:44341/api/MailFiles/`+values;
        const token = authentication.getAccessToken();
        // console.log(JSON.stringify(payload))
        return fetch(url, {
            method: 'delete',
            // body: JSON.stringify(values),

            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
            .then(payload => ({ payload }))
            .catch(error => ({ error }));
    }
};

export const delete_media_mail = (values) => {

    if (values > 0) {
        let url = process.env.REACT_APP_API_CRM_URL + `/v1/mails/delmediamail/` + values;
        // let url = `http://localhost:5000/v1/mails/delmediamail/` + values;

        const token = authentication.getAccessToken();
        // console.log(JSON.stringify(payload))
        return fetch(url, {
            method: 'put',
            // body: JSON.stringify(values),

            headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

        }).then(res => res.json())
            .then(payload => ({ payload }))
            .catch(error => ({ error }));
    }
};
