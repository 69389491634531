
const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {
    const url = process.env.REACT_APP_API_ROOMS_URL + `/api/SearchRoomsList/` + values;
    // const url = `https://localhost:44341/api/SearchRoomsList/`+values;
    // const url = `https://localhost:44341/api/TmTatemonoList/`;
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify({tatemonoName:values}),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));





    //const search = values ? values : {};
    // alert(JSON.stringify([{TatemonoName:values,KanriCorpNo:1}]))
    //     return  fetch(url, {
    //         method: 'post',
    //         body: JSON.stringify({kanriCorpNo:1,isTatemono:1}),
    //             headers: { 'Authorization': 'Bearer ' + token }
    //         })
    //     .then(res => res.json())
    //     .then(payload => ({ payload }))
    //     .catch(error => ({ error }));

}

