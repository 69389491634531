import React, { Component } from 'react';
import { connect } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import * as Contracts from './Saga/Contracts';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';


const moment = require('moment-timezone');
const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      flexBasis: '70%',
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
    fab: {
      margin: theme.spacing(2),
    },
    absolute: {
      backgroundColor: theme.palette.common.red,
      // position: 'absolute',
      bottom: theme.spacing(2),
      right: theme.spacing(3),
    },
  }));
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    width:'90%',
    bgcolor: 'background.paper',
    border: '1px solid #CCC',
    borderRadius: '5px',
    boxShadow: 24,
};
const style1 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 500,
    width:'90%',
    bgcolor: 'background.paper',
    border: '1px solid #CCC',
    borderRadius: '5px',
    boxShadow: 24,
};

class Application extends Component {

  constructor(props) {
    super(props);
    this.state = {
        Deadline:false,
        CheckDeadline:false,
        date:props.rent_contract_details.application_limit_at ? props.rent_contract_details.application_limit_at : '',
        tooltipOpen:'',
        application_qr:null,
        transfer_qr:null
    };
    this.sendmail = this.sendmail.bind(this);
    this.createform = this.createform.bind(this);
    this.updateform = this.updateform.bind(this);
    this.url = this.url.bind(this);
    this.setTooltipOpen = this.setTooltipOpen.bind(this);
    this.tooltipdelete = this.tooltipdelete.bind(this);
    }
  sendmail(state) {
    this.props.contracts_send_application_mail(state);
  }
  url(e){
    console.log(e)
    e.target.select()
    navigator.clipboard.writeText(e.target.value);

    this.setTooltipOpen(e.target.name);
    this.tooltipdelete()
  }
  setTooltipOpen(e){
    this.setState({tooltipOpen:e})
  }
  tooltipdelete = () => {
    setTimeout(() => {
      this.setTooltipOpen('');
    }, 1000)

  }
  createform(){
    this.props.create_send_form("https://rent.global-center.co.jp/mypage/apply/"+this.props.rent_contract_details.contract_code);
  }
  updateform(values){
    //values:1 → 日付更新　values:2 → パスワード更新
    
    var c = {
        change:values,
        date:values == 1 ? this.state.date : null
    }
    this.props.update_send_form(c)
  }


  render() {
    return (
      <div>
        <p style={{marginBottom:'5px'}}>申込フォーム</p>
        {this.props.rent_contract_details.application_limit_at && this.props.rent_contract_details.application_password?
        <div style={{border:'1px solid #CCC',marginBottom:'10px',padding:'5px'}}>
            <Grid container>
                <Grid item xs={8}>
                    <div style={{display:'flex',marginBottom:'5px'}}>
                        <div style={{lineHeight:'26px',minWidth:'100px'}}>申込フォーム</div>
                        <TextField hiddenLabel fullWidth variant="standard" size="small" name="sendform" InputProps={{readOnly: true}} defaultValue={"https://rent.global-center.co.jp/mypage/apply/"+this.props.rent_contract_details.contract_code} onClick={(e) => this.url(e)}/>
                        <Tooltip title="コピーしました！" open={this.state.tooltipOpen === "sendform" ? true : false}>
                            <div></div>
                        </Tooltip>
                    </div>
                    <div style={{display:'flex',marginBottom:'5px'}}>
                        <div style={{lineHeight:'26px',minWidth:'100px'}}>パスワード</div>
                        <TextField hiddenLabel variant="standard" size="small" name="sendpass" InputProps={{readOnly: true}} defaultValue={this.props.rent_contract_details.application_password} onClick={(e) => this.url(e)}/>
                        <Tooltip title="コピーしました！" open={this.state.tooltipOpen === "sendpass" ? true : false}>
                            <div></div>
                        </Tooltip>
                        
                    </div>
                    <div style={{width:'100%'}}>
                        <p style={{marginBottom:'0'}}>有効期限:{moment(this.props.rent_contract_details.application_limit_at).format('YYYY/MM/DD')}まで</p>
                        {this.props.application_qr ? <img src={window.URL.createObjectURL(this.props.application_qr)} alt="img" style={{margin:'10px'}}/>:""}
                        <p style={{marginBottom:'0'}}>申込フォームQR</p>
                    </div>
                </Grid>
                
                <Grid item xs={4}>
                    <div style={{width:'100%',textAlign:'center'}}> 
                        <Button variant="contained" style={{margin:'5px',backgroundColor:'#5cb85c',width:'142px'}} onClick={() => this.setState({Deadline : true})}>{
                        moment(this.props.rent_contract_details.application_limit_at).isAfter(moment()) ? "公開設定(期限切れ)" : "公開設定(公開中)"}</Button>
                        <Button variant="contained" style={{margin:'5px',width:'142px'}} onClick={() => this.sendmail(345)}>メールで送信する</Button>
                    </div>
                </Grid>
            </Grid>
        </div>:
        <div style={{width:'100%',margin:"0 auto 15px auto"}}><Button variant="contained" style={{margin:'5px',backgroundColor:'#5cb85c',width:'142px'}} onClick={() => this.createform()}>申込フォーム作成</Button></div>
        }
        
        {/* <p style={{marginBottom:'5px'}}>口座振替</p>
        <div style={{border:'1px solid #CCC',padding:'5px'}}>
            <Grid container>
                <Grid item xs={8}>
                    <div>
                        <div style={{display:'flex',marginBottom:'5px'}}>
                            <div style={{lineHeight:'26px',minWidth:'100px'}}>口座振替</div>
                            <TextField hiddenLabel fullWidth variant="standard" size="small" name="AccountTransfer" InputProps={{readOnly: true}} defaultValue={"https://rent.global-center.co.jp/mypage/transfer/"+this.props.rent_contract_details.contract_code} onClick={(e) => this.url(e)}/>
                            <Tooltip title="コピーしました！" open={this.state.tooltipOpen === "AccountTransfer" ? true : false}>
                                <div></div>
                            </Tooltip>
                        </div>
                        <div style={{display:'flex',marginBottom:'5px'}}>
                            <div style={{lineHeight:'26px',minWidth:'100px'}}>パスワード</div>
                            <TextField hiddenLabel variant="standard" size="small"name="AccountTransferPass" InputProps={{readOnly: true}} defaultValue={this.props.rent_contract_details.application_password} onClick={(e) => this.url(e)}/>
                            <Tooltip title="コピーしました！" open={this.state.tooltipOpen === "AccountTransferPass" ? true : false}>
                                <div></div>
                            </Tooltip>
                        </div>
                        <div style={{width:'100%'}}>
                            <p style={{marginBottom:'0'}}>口座振替QR</p>                        
                                {this.props.transfer_qr ? <img src={window.URL.createObjectURL(this.props.transfer_qr)} style={{margin:'10px'}} alt="img"/>:""}
                        </div>
                    </div>
                </Grid>
                
                <Grid item xs={4}>
                    <div style={{width:'100%',textAlign:'center'}}>
                        <Button variant="contained" style={{margin:'5px', backgroundColor:'#5cb85c',width:'142px'}}>口座振替案内</Button>
                        <Button variant="contained" style={{margin:'5px',width:'142px'}} onClick={() => this.sendmail(346)}>メールで送信する</Button>
                    </div>
                </Grid>
            </Grid>
        </div> */}
        <div>
            <Modal 
                open={this.state.Deadline}
                onClose={()=>this.setState({ Deadline : false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                
                <Box sx={style}>
                    <div style={{padding:'10px'}}>
                        <div style={{borderBottom:'1px solid #CCC',display:'flex',justifyContent: 'space-between'}}>
                            <h2 style={{padding:'10px',fontSize:'18px'}}>申込フォーム設定</h2><div style={{lineHeight:'35px',paddingRight:'10px'}}><CloseIcon style={{cursor:'pointer'}}onClick={()=>this.setState({ Deadline : false })}/></div>
                        </div>
                        
                        <div style={{padding:'20px'}}>
                            <div style={{display:'flex',marginBottom:'5px'}}>
                                <div style={{lineHeight:'35px',minWidth:'100px',marginRight:'10px'}}>申込フォーム期限</div>

                                <TextField
                                  id="date"
                                  // label="表示期間"
                                  type="date"
                                  name="date"
                                  size="small"
                                  variant='outlined'
                                  // onChange={this.handleStartDate}
                                  onChange={((e)=> this.setState({date:e.target.value}) )}
                                  value={moment(this.state.date).format("YYYY-MM-DD")}
                                  InputLabelProps={{
                                      shrink: true,
                                  }}
                                />
                                {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja} style={{marginTop: 'auto'}}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="none"
                                    id="date-picker-inline1"
                                    autoOk={true}
                                    name="contract_date"
                                    value={this.state.date}
                                    onChange={((newDate)=> this.setState({date:newDate}) )}
                                    KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={"日付の形式が違います"}
                                />
                                </MuiPickersUtilsProvider> */}

                                <Button variant="contained" size="small" style={{margin:'5px',backgroundColor:'#5cb85c',marginLeft:'10px'}} onClick={()=>this.updateform(1)}>更新</Button>
                            </div>
                            <div>                  
                                <Alert icon={false} severity="success">
                                    パスワード：{this.props.rent_contract_details.application_password}　<Button variant="contained" size="small" style={{margin:'5px',backgroundColor:'#23AC38'}} onClick={()=>this.setState({ CheckDeadline : true })}>パスワードを変更する</Button>
                                </Alert>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>


            <Modal 
                open={this.state.CheckDeadline}
                onClose={()=>this.setState({ CheckDeadline : false })}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style1}>
                    <div style={{padding:'10px'}}>
                        <div style={{padding:'20px'}}>
                            <div style={{display:'flex',marginBottom:'5px'}}>
                                <div style={{lineHeight:'26px',minWidth:'100px'}}>
                                    パスワードをリセットします。<br/>
                                    本当によろしいですか？
                                </div>
                            </div>
                            <div style={{display:'flex',borderTop:'1px solid #CCC',justifyContent: 'space-between',marginTop:'10px'}}>
                                <Button variant="contained" size="small" style={{margin:'5px',backgroundColor:'#FFF',color:"#000",border:"#CCC"}} onClick={() => this.setState({ CheckDeadline :false})}>リセットしない</Button>
                                <Button variant="contained" size="small" style={{margin:'5px'}} color="error" onClick={()=>this.updateform(2)}>リセットする</Button>
                            </div>
                        </div>
                    </div>
                </Box>
            </Modal>
        </div>
      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_m_relations: state.contracts.rent_m_relations,
    rent_m_job_categorys: state.contracts.rent_m_job_categorys,
    application_qr: state.contracts.application_qr,
    transfer_qr: state.contracts.transfer_qr,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    contracts_send_application_mail(state) {
      dispatch(Contracts.contracts_send_application_mail(state))
    },
    create_send_form(state) {
      dispatch(Contracts.create_send_form(state))
    },
    update_send_form(state) {
      dispatch(Contracts.update_send_form(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Application);
