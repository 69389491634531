import React from 'react';

//import { Container } from 'reactstrap';

import * as Responses from '../ResponseDetail/Saga/Responses';
import '../../Css/icomoon/style.css';
import '../../Css/drawer.min.css';
import '../../Css/Site.css';
import '../../Css/fontawesome/css/all.min.css';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import Button from '@mui/material/Button';
interface IProps {

  RentResponseId: number,

  change_responseid: any,
  change_modalResponseDetail: any,
}

interface State {

  // state types
}
// width: ${(props: Props) => props.editor_width}px;
// height: ${(props: Props) => props.editor_height}px;
//  const MailRap  = styled.div`
// height:500px;
// width: ${(props: IProps) => props.editor_width}px;
// position:fixed;
// bottom: 0;
// right: 30px;
// z-index:1000;
// background:#FFFFFF;
// filter: drop-shadow(10px 10px 10px rgba(0,0,0,0.6));
// `;

let notify;


class LayoutComponent extends React.Component<IProps, State>  {

  constructor(props: Readonly<IProps>) {
    super(props)
    this.state = {


    }
    this.openResponse = this.openResponse.bind(this);
  }
  openResponse() {
    window.open("https://crm.homestation.jp/customer/" + this.props.RentResponseId, '_blank', 'noopener')
    // alert(this.props.RentResponseId)
    // this.props.change_responseid(this.props.RentResponseId)
    // this.props.change_modalResponseDetail(true)
  }

  render() {
    return (

      <Button variant="outlined" color="primary" size="small" onClick={this.openResponse}>詳細</Button>

    )
  }
};
//コンテナ
const mapStateToProps = (state: any) => {
  return {


  }
}
function mapDispatchToProps(dispatch: any) {
  return {

    change_responseid(state: number) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state: boolean) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LayoutComponent);
