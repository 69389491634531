import React, { Component } from 'react';

import {ComposedChart,XAxis,YAxis,Tooltip,CartesianGrid,Bar} from 'recharts';

 
const data = [
  { name: '単身　社会人用', "返信率": 60 },
  { name: 'ファミリー用', "返信率": 28 },
  { name: '単身　男性用', "返信率": 27 },
  { name: '単身　女性用', "返信率": 10 },
  { name: '香大生新入学用', "返信率": 5 },
]

class TemplateAnalyticsComponent extends Component {
  render() {
    return (
      <div>
      テンプレート返信率
     <ComposedChart　　//グラフ全体のサイズや位置、データを指定。場合によってmarginで上下左右の位置を指定する必要あり。
    width={500}  //グラフ全体の幅を指定
    height={280}  //グラフ全体の高さを指定
    layout="vertical" //グラフのX軸とY軸を入れ替え
    data={data}   //Array型のデータを指定
    margin={{ top: 20, right: 60, bottom: 0, left: 50 }}  //marginを指定
  >
    <XAxis  //X軸に関する設定
      type="number" //データタイプをnumberに変更。デフォルトではcategoryになっている
      // domain={['dataMin 0', 'dataMax + 10']} //軸の表示領域を指定
      domain={[0, 100]} //軸の表示領域を指定
    />
    <YAxis //Y軸に関する設定
      type="category" //データタイプをcategoryに変更
      dataKey="name"  //Array型のデータの、Y軸に表示したい値のキーを指定
    />
    <Tooltip /> ////hoverさせた時に具体的な値を表示させるように指定
    <CartesianGrid  //グラフのグリッドを指定
      stroke="#f5f5f5"  //グリッド線の色を指定
     /> 
    <Bar
      dataKey="返信率"
      barSize={20}
      stroke="rgba(34, 80, 162, 0.2)"
      fillOpacity={1}
      fill="#2250A2"
    />
  </ComposedChart>
  </div>
    );
  }
}

export default TemplateAnalyticsComponent;