import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import InputLabel from '@mui/material/InputLabel';
import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import { Row, Col } from 'reactstrap';
import Button from '@mui/material/Button';
import InputRange from 'react-input-range';
import "react-input-range/lib/css/index.css";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl'
import LoadingButton from '@mui/lab/LoadingButton';

import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import ContractSendEdit from './ContractSendEdit';
import moment from 'moment';

class Attentions extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal_ver: false,
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
    };
    // this.change_new = this.change_new.bind(this);
    // this.change_old = this.change_old.bind(this);
    // this.sendCheck = this.sendCheck.bind(this);
    // this.sendCheck_Room = this.sendCheck_Room.bind(this);
    // this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
  }


  render() {

    return (

      <div>



      </div>

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    check_contract_sheet_text: state.contracts.check_contract_sheet_text,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_logs_new: state.contracts.rent_contract_logs_new,
    rent_contract_logs_old: state.contracts.rent_contract_logs_old,
    user_details: state.masterReducer.user_details,
    rent_contract_logs: state.contracts.rent_contract_logs,
    users_select: state.masterReducer.users_select,
    check_contract_button: state.contracts.check_contract_button,
    electronic_contract: state.contracts.electronic_contract,
    cloudsign_document_id: state.contracts.cloudsign_document_id,
    rent_contract_workflows: state.contracts.rent_contract_workflows,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_check_contract_sheet_text(state) {
      dispatch(Contracts.change_check_contract_sheet_text(state))
    },
    change_rent_contract_logs_new(state) {
      dispatch(Contracts.change_rent_contract_logs_new(state))
    },
    change_rent_contract_logs_old(state) {
      dispatch(Contracts.change_rent_contract_logs_old(state))
    },
    change_rent_contract_logs_ver(state) {
      dispatch(Contracts.change_rent_contract_logs_ver(state))
    },
    contracts_send_parking(state) {
      dispatch(Contracts.contracts_send_parking(state))
    },
    update_rent_contract_workflows(state) {
      dispatch(Contracts.update_rent_contract_workflows(state))
    },
    change_check_contract_sheet(state) {
      dispatch(Contracts.change_check_contract_sheet(state))
    },
    contracts_sheet_check(state) {
      dispatch(Contracts.contracts_sheet_check(state))
    },
    contracts_sheet_corp(state) {
      dispatch(Contracts.contracts_sheet_corp(state))
    },
    electronic_contract_cancel(state) {
      dispatch(Contracts.electronic_contract_cancel(state))
    },
    check_electronic_contract(state) {
      dispatch(Contracts.check_electronic_contract(state))
    },
    remind_electronic_contract(state) {
      dispatch(Contracts.remind_electronic_contract(state))
    },
    change_send_complete_text(state) {
        dispatch(Contracts.change_send_complete_text(state))
    },
    change_send_complete(state) {
        dispatch(Contracts.change_send_complete(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Attentions);
