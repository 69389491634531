import React, { Component } from 'react';
import { connect } from 'react-redux';

// import ComposedChart from '@bit/recharts.recharts.composed-chart';
import {
    Legend,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Bar,
    ComposedChart
  } from 'recharts';

import withStyles from '@mui/styles/withStyles';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';





const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))

const dataGraph = [
    {month: '1月', '法人': 2, '個人': 2},
    {month: '2月', '法人': 1, '個人': 3},
    {month: '3月', '法人': 2, '個人': 4},
    {month: '4月', '法人': 3, '個人': 5},
    {month: '5月', '法人': 4, '個人': 6},
    {month: '6月', '法人': 5, '個人': 7},
    {month: '7月', '法人': 6, '個人': 8},
    {month: '8月', '法人': 7, '個人': 9},
    {month: '9月', '法人': 8, '個人': 10},
    {month: '10月', '法人': 9, '個人': 11},
    {month: '11月', '法人': 10, '個人': 12},
    {month: '12月', '法人': 11, '個人': 13},
];

class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div>
                <div>契約月データ（過去5年間）</div>
<ComposedChart //グラフ全体のサイズや位置、データを指定。場合によってmarginで上下左右の位置を指定する必要あり。
    width={(window.innerWidth / 2) - 30}  //グラフ全体の幅を指定
    height={280}  //グラフ全体の高さを指定
    data={this.props.rent_corporate_contract_by_month} //ここにArray型のデータを指定
    margin={{ top: 20, right: 60, bottom: 0, left: -30 }}  //marginを指定
>
  <XAxis
    dataKey="month"  //Array型のデータの、X軸に表示したい値のキーを指定
    unit="月"
  />
  < YAxis label={{ value: "件数", angle: -90, position: "insideLeft" }} />
  <Tooltip /> //hoverした時に各パラメーターの詳細を見れるように設定
  <Legend />  // 凡例を表示(図の【売上】【総売上】)
  <CartesianGrid //グラフのグリッドを指定
    stroke="#f5f5f5" //グリッド線の色を指定
  />
  {/* <Area //面積を表すグラフ
    type="monotone"  //グラフが曲線を描くように指定。default値は折れ線グラフ
    dataKey="法人" //Array型のデータの、Y軸に表示したい値のキーを指定
    stroke="#00aced" ////グラフの線の色を指定
    fillOpacity={1}  ////グラフの中身の薄さを指定
    fill="rgba(0, 172, 237, 0.2)"  //グラフの色を指定
  /> */}
  <Bar //棒グラフ
    name="法人"
    dataKey="houjin"　//Array型のデータの、Y軸に表示したい値のキーを指定
    stackId="a"
    barSize={20}  //棒の太さを指定
    stroke="rgba(34, 80, 162, 0.2)" ////レーダーの線の色を指定 
    fillOpacity={1}  //レーダーの中身の色の薄さを指定
    fill="salmon" ////レーダーの中身の色を指定
  />
  <Bar //棒グラフ
    name="個人"
    dataKey="kojin"　//Array型のデータの、Y軸に表示したい値のキーを指定
    stackId="a"
    barSize={20}  //棒の太さを指定
    stroke="rgba(34, 80, 162, 0.2)" ////レーダーの線の色を指定 
    fillOpacity={1}  //レーダーの中身の色の薄さを指定
    fill="skyblue" ////レーダーの中身の色を指定
  />
</ComposedChart>
            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        m_corporations_list: state.corporation.m_corporations_list,
        rent_corporate_contract_by_month: state.corporation.rent_corporate_contract_by_month,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




