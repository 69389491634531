import React, { Component } from 'react';
import * as Responses from './Saga/ResponseList';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import styled from 'styled-components';


const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const List = styled.ul`
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
        
    padding-left:0;
    margin-bottom:0px;

    width:400px;
`;

const ListItem = styled.li`
        display: inline-block;
        width: 50%;
        height:300px;
        margin-left: 5px;
        white-space:normal;
        vertical-align: top;
        margin-bottom: 0px;
        
        background-color:white;
        border-radius: 12px;
        width:200px;
`;

const Title = styled.div`
        padding:10px;
    text-align:left;
`;

const Detail = styled.div`
        padding:10px;padding-top:0px;font-size:10px;height:55px;
    text-align:left;
`;

const Menu1 = styled.div`
        padding-top:5px;padding-bottom:5px;border-top:1px solid #dcdcdc;font-weight:bold;text-align:center;
`;

const Menu2 = styled.div`
        padding-top:5px;padding-bottom:5px;font-weight:bold;text-align:center;
`;


const Time = styled.div`
    width:100%;
    position: relative; 
    margin: 5 5 0 5px;
    bottom: 0;
    font-size:10px;
    color:#FFF;
    text-align:right;
`;

const DeleteButton = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    top: 0;

`;

const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 0;
    max-width: 450px;
    height:270px;
    border-radius: 12px;
    margin-bottom: 2px;
    text-align: left;
`;


class LineTextComponent extends Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],

            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            // lists: "1944_3_サンフィールドⅠ_103_47000_国分寺町国分_3DK,100006_3_フロレスタシエロ_104_49000_林町_1K,100041_5_サンライズ百石_106_31000_屋島西町_1K,100016_0_バビヤージュⅡ_Ａ-1_78000_寺井町_4LDK,100020_1_ハイムクレイドル_102_40000_国分寺町新名_3DK,100003_7_第三米井ビル_401_43000_御坊町_2K".split(','),
            lists: "0_1_おすすめ物件1_号室_賃料_町名_間取,0_1_おすすめ物件2_号室_賃料_町名_間取,0_1_おすすめ物件3_号室_賃料_町名_間取,0_1_おすすめ物件4_号室_賃料_町名_間取,0_1_おすすめ物件5_号室_賃料_町名_間取".split(','),
            // lists: this.props.msg.message_text.split(','),
        
        };
    }

    componentWillMount() {

        // const token = authentication.getAccessToken();
        // const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/template/docs/download/` + this.props.msg.file_id
        // // const url = `http://localhost:5000/v1/rent/line/template/docs/download/` + this.props.msg.file_id

        // var bloburl
        // fetch(url, {
        //     method: 'GET',
        //     headers: { 'Authorization': 'Bearer ' + token }
        // }).then(response => response.blob()).then(images => {

        //     // Then create a local URL for that image and print it 
        //     bloburl = URL.createObjectURL(images)
        //     // alert(bloburl)
        //     console.log(bloburl)
        //     this.setState({
        //         bloburl: bloburl
        //     })

        // });

    }

    onDeleteMessage(no){

        this.props.delete_rent_line_template_detail(no)

        this.setState({
            refresh: !this.state.refresh
          });
    }

    render() {
        return (
            <div>
                <Balloon className="col-xs-12">
                <Chatting>
                    {this.props.line_template_edit_open ? <DeleteButton>
                        <IconButton onClick={()=>this.onDeleteMessage(this.props.msg.no)} size="large"><CloseIcon /></IconButton>
                    </DeleteButton>:""}

                    <Says>
                    <List>
                    {this.props.msg.message_type === 'stocklist' ? 
                        this.props.msg.message_text.split(',').map((data, index) => {
                            return (
                                <ListItem key={index}>
                                    <div><img className="l_img" border="0" src={"https://www.homestation.jp/manage/common/img/building/" + data.split('_')[0] + "/1/1/1"} width="200" alt="" /></div>
                                    <Title>{data.split('_')[2]}-{data.split('_')[3]}</Title>
                                    <Detail>{data.split('_')[5]}/{data.split('_')[4]}円/{data.split('_')[6]}</Detail>
                                    <Menu1 className="col-xs-12">詳細ページ</Menu1>
                                    {/* <Menu2 className="col-xs-12">見学予約</Menu2> */}
                                </ListItem>
                            )
                        })

                    :
                        this.state.lists.map((data, index) => {
                            return (
                                <ListItem key={index}>
                                    <div><img className="l_img" border="0" src={"https://www.homestation.jp/manage/common/img/building/" + data.split('_')[0] + "/1/1/1"} width="200" alt="" /></div>
                                    <Title>{data.split('_')[2]}-{data.split('_')[3]}</Title>
                                    <Detail>{data.split('_')[5]}/{data.split('_')[4]}円/{data.split('_')[6]}</Detail>
                                    <Menu1 className="col-xs-12">詳細ページ</Menu1>
                                    {/* <Menu2 className="col-xs-12">見学予約</Menu2> */}
                                </ListItem>
                            )
                        })
                    }

                    </List>
                    </Says>
                </Chatting>
                {/* <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time> */}
                </Balloon>
            </div>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {
        rent_line_templates_list: state.responselist.rent_line_templates_list,
        rent_line_template_detail: state.responselist.rent_line_template_detail,
        rent_line_template: state.responselist.rent_line_template,
        line_template_edit_open: state.responselist.line_template_edit_open,

    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_rent_line_template_detail(state) {
            dispatch(Responses.change_rent_line_template_detail(state))
        },
        delete_rent_line_template_detail(state) {
            dispatch(Responses.delete_rent_line_template_detail(state))
        },
        change_rent_line_template(state) {
            dispatch(Responses.change_rent_line_template(state))
        },
        get_rent_line_template_detail(state) {
            dispatch(Responses.get_rent_line_template_detail(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineTextComponent);
