import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import * as Files from '../Files/Saga/Files';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Modal, Row, Col, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import * as Contracts from './Saga/Contracts';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';


var moment = require('moment-timezone');

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.contract_files[rowIndex][column.key]}
  </Cell>;

const PName = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <IconButton style={{marginTop:-5}} onClick={(e) => { props.download_contract_files(props.contract_files[rowIndex]) }} aria-label="edit" size="small">
      <GetAppIcon />
    </IconButton>
  </Cell>;

const Name = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.contract_files[rowIndex].type == 1 && props.contract_files[rowIndex].status === 1 ? "賃貸借契約書" : props.contract_files[rowIndex][column.key]}
  </Cell>;

const Icon = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.contract_files[rowIndex].type == 1 ? <DescriptionIcon fontSize="small" /> : <AttachFileIcon fontSize="small" />}
  </Cell>;

const CreatedAt = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {moment(props.contract_files[rowIndex][column.key]).format('YYYY-MM-DD HH:mm:ss')}
  </Cell>;

const Edit = ({ column, props, rowIndex, file_remarks_edit_open }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.contract_files[rowIndex].type == 0 ? 
    <IconButton style={{marginTop:-5}} onClick={(e)=>{file_remarks_edit_open({ fileid: props.contract_files[rowIndex].id, name: props.contract_files[rowIndex].name, remarks: props.contract_files[rowIndex].remarks ? props.contract_files[rowIndex].remarks : "" }) }} aria-label="edit" size="small">
      <EditIcon />
    </IconButton>:""}
  </Cell>;

const Delete = ({ column, props, rowIndex, fncdelete, is_delete }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.contract_files[rowIndex].type == 0 ? 
    <IconButton style={{marginTop:-5}} onClick={(e)=>fncdelete(is_delete, props.contract_files[rowIndex].id) } aria-label="edit" size="small">
      <DeleteIcon />
    </IconButton>:""}
  </Cell>;

const FileSelect = ({ column, props, rowIndex, handleChangeSelect }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <Checkbox style={{padding:0}} checked={props.zenhoren_file.file_id && props.zenhoren_file.file_id.includes(props.contract_files[rowIndex].id)} onChange={(e)=>handleChangeSelect(e, rowIndex)} name="is_select" />
  </Cell>;

const files = ['pdf', 'png', 'jpg', 'jpeg', 'bmp', 'gif'];

const Disp = ({ column, props, rowIndex, openWindow }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {files.includes(props.contract_files[rowIndex].extension.trim()) && props.contract_files[rowIndex].status !== 1 ? 
    <span>
      {/* <Button variant="contained" style={{}} size="small" onClick={() => props.download_disp_contract_files(props.contract_files[rowIndex])} color="primary">表示</Button> */}
      <IconButton size="small" onClick={() => openWindow(props.contract_files[rowIndex].id)} color="primary">
        <OpenInNewIcon />
      </IconButton>
    </span>:""}
    {/* <Button variant="contained" style={{}} size="small" onClick={() => props.download_disp_contract_files(props.contract_files[rowIndex])} color="primary">表示</Button> */}
  </Cell >;

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns = [
  { key: 'select', name: '選択', width: 60 },
  { key: 'p_name', name: 'DL', width: 50 },
  { key: 'icon', name: '', width: 50 },
  { key: 'name', name: 'ファイル名', width: 200 },
  { key: 'disp', name: '表示', width: 100 },
  // { key: 'edit', name: '編集', width: 60 },
  // { key: 'delete', name: '削除', width: 60 },
  { key: 'created_at', name: 'アップロード日時', width: 150 },
  
]







class CustomerList extends Component {
  constructor(props) {
    super(props);

    console.log(columns)

    this.state = {
      columns: columns,
      refresh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},


    };
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    // this.delete = this.delete.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);

  }


  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }


  handleChange(e){

    let zenhoren_file = this.props.zenhoren_file

    zenhoren_file[e.target.name] = e.target.value

    this.props.change_zenhoren_file(JSON.parse(JSON.stringify(zenhoren_file)))

    this.setState({
      refresh: !this.state.refresh,
    });
  }

  handleChangeSelect(e, index){

    let zenhoren_file = this.props.zenhoren_file

    if(e.target.checked){
      zenhoren_file.file_id.push(this.props.contract_files[index].id)
    }else{
      let result = zenhoren_file.file_id.filter(item=> item != this.props.contract_files[index].id);
      zenhoren_file.file_id = result
    }

    this.props.change_zenhoren_file(JSON.parse(JSON.stringify(zenhoren_file)))

    this.setState({
      refresh: !this.state.refresh,
    });
  }




  openWindow(id){
    window.open(`/FileDisp/` + '4/' + id, '_blank', 'noopener,width=500,toolbar=yes,menubar=yes,scrollbars=yes')
  }


  render() {

    return (
      <div>
        <div style={{fontSize:"16px", fontWeight:"bold"}}>本人確認書類を選択してください。</div>
        <FormControl variant="outlined" style={{marginTop:"5px", marginBottom:"5px"}}>
          <InputLabel id="demo-simple-select-outlined-label" margin="dense">書類の種類</InputLabel>
          <Select
            style={{ width: 300 }}
            name="type"
            error={this.props.zenhoren_file.type != "" ? false : true}
            value={this.props.zenhoren_file.type}
            onChange={this.handleChange}
            label="書類の種類"
            margin="dense"
          >
            <MenuItem value={""}>-</MenuItem>
            <MenuItem value={"運転免許証"}>運転免許証</MenuItem>
            <MenuItem value={"健康保険証"}>健康保険証</MenuItem>
            <MenuItem value={"パスポート"}>パスポート</MenuItem>
            <MenuItem value={"住民基本台帳カード"}>住民基本台帳カード（顔写真付）</MenuItem>
            <MenuItem value={"個人番号カード"}>個人番号カード（表面のみ）</MenuItem>
            <MenuItem value={"生活保護受給証明に関する書類"}>生活保護受給証明に関する書類</MenuItem>
            <MenuItem value={"学生証"}>学生証（学生の方）</MenuItem>
            <MenuItem value={"在留カード"}>在留カード（表裏）</MenuItem>
          </Select>
        </FormControl>
       
        {
          this.props.contract_files ? <div style={{ marginBottom: 10 }}><Table
            rowHeight={40}
            rowsCount={this.props.contract_files.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            width={(window.innerWidth / 2) - 75}
            height={250}
            headerHeight={40}>
            {this.state.columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "disp" ? <Disp column={value} props={this.props} rowIndex={rowIndex} openWindow={this.openWindow}></Disp>
                    //   : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Seikyuu>

                    //     : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Amount>
                    //       : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Delete>
                    : value.key === "icon" ? <Icon column={value} props={this.props} rowIndex={rowIndex} ></Icon>
                    : value.key === "name" ? <Name column={value} props={this.props} rowIndex={rowIndex} ></Name>
                    : value.key === "p_name" ? <PName column={value} props={this.props} rowIndex={rowIndex} ></PName>
                    : value.key === "created_at" ? <CreatedAt column={value} props={this.props} rowIndex={rowIndex} ></CreatedAt>
                    : value.key === "select" ? <FileSelect column={value} props={this.props} rowIndex={rowIndex} handleChangeSelect={this.handleChangeSelect} ></FileSelect>
                    : value.key === "edit" ? <Edit column={value} props={this.props} rowIndex={rowIndex} file_remarks_edit_open={this.props.file_remarks_edit_open} ></Edit>
                    // : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} fncdelete={this.delete} is_delete={this.state.is_delete} ></Delete>
                    :<Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table > </div> : ""
        }
         <div style={{marginBottom:15}}>
        ●個人のお申込みの場合、申込時必要書類として、以下のいずれか提出をお願いします。<br />
        　運転免許証・パスポート・健康保険証・住基カード（顔写真付）・個人番号カード（表面のみ）・学生証（学生の方）<br />
        　外国籍の方は、在留カード（表裏）<br />
        　※未成年者の場合は、「親権者（法定代理人）同意書」が併せて必要になります。<br />
        　※生活保護者の方は生活保護受給証明に関する書類（受給額がわかるもの）が必要になる場合がございます。<br />
        　※個人住居用・住居学生用の申込については、個人情報同意書が必要になります。<br />
        　※必要書類で学生証を選んだ場合は賃貸借保証委託契約書の回収時、必要書類（当社規定の本人確認書類、住民票（抄）本、戸籍（抄）本のいずれか）を提出ください。<br />
        　　確認受理後、承諾となります。なお、必要書類を提出いただけない場合、仮承認を無効となります。<br />
        </div>

      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    // contract_files: state.files.contract_files,
    zenhoren_file: state.contracts.zenhoren_file,
  }
}
function mapDispatchToProps(dispatch) {
  return {



    download_disp_contract_files(state) {
      dispatch(Files.download_disp_contract_files(state))
    },

    download_contract_files(state) {
      dispatch(Files.download_contract_files(state))
    },
    change_contract_file_update_disp(state) {
      dispatch(Files.change_contract_file_update_disp(state))
    },
    change_zenhoren_file(state) {
      dispatch(Contracts.change_zenhoren_file(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);