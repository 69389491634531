import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as TmSetsubiApi from '../Api/TmSetsubiApi';
// import * as ResponseMapSearchRoomsApi from '../Api/ResponseMapSearchRoomsApi';
// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
// import * as rent_introductions_api from '../Api/rent_introductions_api';

// import * as IntroductionList from '../../IntroductionList/Saga/IntroductionList';
import { startSubmit, stopSubmit, reset, submit } from 'redux-form';

// import * as RentIntroductionHeadersApi from '../Api/RentIntroductionHeadersApi';
import * as rent_introduction_headers_api from '../Api/rent_introduction_headers_api';

// import * as RentIntroductionHeadersListApi from '../Api/RentIntroductionHeadersListApi';
// import * as RentIntroductionPointHeadersApi from '../Api/RentIntroductionPointHeadersApi';

import * as rent_introduction_point_headers_api from '../Api/rent_introduction_point_headers_api';
import * as rent_introduction_point_templates_api from '../Api/rent_introduction_point_templates_api';
import * as rent_introduction_details_api from '../Api/rent_introduction_details_api';

import * as MapMypageTemplate from '../../MapMypageTemplate/Saga/MapMypageTemplate';
import * as Mail from '../../Mail/Saga/Mail';
export interface MapSearchState {
  setsubi_master: any;
  // rooms: any;
  // map_room_details: any;
  // response_rooms: any;
  showRight: boolean;
  showLeft: boolean;
  showFlag: boolean;
  draggable: boolean;
  building_details: any;
  map_mypage_introductions: any;
  introductions_loading: number;
  fromMapOpen: number,//1紹介リスト　2メールフォーム
  map_settings_flg: number,
  map_polygon_settings: any;
  map_point_settings: any;
  map_settings_edit: number;
  map_disp: boolean,


  select_rooms: any,
  open_rooms: any,
  rent_introduction_headers: any,
  rent_introduction_headers_list: any,
  rent_introduction_headers_id: number,
  select_list_open: boolean,
  rent_introduction_point_headers: any,
  remarks_id: number,

  rent_introduction_point_template_disp: boolean,

  map_mypage_room_detail_disp : boolean,
  map_mypage_room_detail_disp_id : any,

  
}
const initialState: MapSearchState = {

  setsubi_master: [],
  // rooms: [],
  // map_room_details: [],
  // response_rooms: [],
  showRight: false,
  showLeft: true,
  showFlag: true,
  draggable: true,
  building_details: [],
  map_mypage_introductions: [],
  introductions_loading: 0,
  fromMapOpen: 1,//1紹介リスト　2メールフォーム
  map_polygon_settings: [],
  map_point_settings: [],
  map_settings_flg: 1,
  map_settings_edit: 1,
  map_disp: false,


  select_rooms: [],
  open_rooms: [],
  rent_introduction_headers: {
    rent_introduction_details: []
  },
  rent_introduction_headers_list: [],
  rent_introduction_headers_id: 0,
  select_list_open: false,
  rent_introduction_point_headers: [],
  remarks_id: 0,

  rent_introduction_point_template_disp: false,

  map_mypage_room_detail_disp : false,
  map_mypage_room_detail_disp_id: {tatemono_id: 0, room_id: 0},

}



///action 
const actionCreator = actionCreatorFactory();

export const get_setsubi_master = actionCreator('GET_SETSUBI_MASTER');
export const get_setsubi_master_success = actionCreator("GET_SETSUBI_MASTER_SUCCESS");
export const get_setsubi_master_failure = actionCreator("GET_SETSUBI_MASTER_FAILURE");



// export const get_rooms = actionCreator('GET_ROOMS');
// export const get_rooms_success = actionCreator("GET_ROOMS_SUCCESS");
// export const get_rooms_failure = actionCreator("GET_ROOMS_FAILURE");


// export const get_response_map_rooms = actionCreator<any>('GET_RESPONSE_MAP_ROOMS');
// export const get_response_map_rooms_success = actionCreator<any>("GET_RESPONSE_MAP_ROOMS_SUCCESS");


// export const get_map_room_details = actionCreator<any>('GET_MAP_ROOM_DETAILS');
// export const get_map_room_details_success = actionCreator<any>("GET_MAP_ROOM_DETAILS_SUCCESS");
// export const get_map_room_details_failure = actionCreator("GET_MAP_ROOM_DETAILS_FAILURE");

export const change_showRight = actionCreator<any>('change_showRight');
export const change_showLeft = actionCreator<any>('change_showLeft');
export const change_showFlag = actionCreator<any>('change_showFlag');
export const change_draggable = actionCreator<any>('change_draggable');

// export const insert_map_introductions = actionCreator<any>('INSERT_MAP_INTRODUCTIONS');
// export const insert_map_introductions_success = actionCreator<any>('INSERT_MAP_INTRODUCTIONS_SUCCESS');


export const delete_map_introduction = actionCreator<any>('DELETE_MAP_INTRODUCTION');

export const change_introductions_loading = actionCreator<number>('INTRODUCTIONS_LOADING');




// export const get_introductions = actionCreator<any>('GET_INTRODUCTIONS');
// export const get_introductions_success = actionCreator<any>('GET_INTRODUCTIONS_SUCCESS');

export const change_fromMapOpen = actionCreator<any>('CHANGE_FROMMAPOPEN');

export const change_map_polygon_settings = actionCreator<any>('CHANGE_MAP_POLYGON_SETTINGS');
export const change_map_point_settings = actionCreator<any>('CHANGE_MAP_POINT_SETTINGS');


export const change_map_settings_flg = actionCreator<any>('CHANGE_MAP_SETTINGS_FLG');
export const change_map_settings_edit = actionCreator<any>('CHANGE_MAP_SETTINGS_EDIT');

export const change_map_disp = actionCreator<any>('CHANGE_MAP_DISP');

export const change_map_points = actionCreator<any>('CHANGE_MAP_POINTS');




export const update_rent_introduction_point_details = actionCreator<any>('UPDATE_RENT_INTRODUCTION_POINT_DETAILS');



export const change_open_rooms = actionCreator<any>('CHANGE_OPEN_ROOMS');
export const change_select_rooms = actionCreator<any>('CHANGE_SELECT_ROOMS');
// export const update_select_rooms = actionCreator<any>('UPDATE_SELECT_ROOMS');

export const get_rent_introduction_headers = actionCreator<any>('GET_RENT_INTRODUCTION_HEADERS');
export const change_rent_introduction_headers = actionCreator<any>('CHANGE_RENT_INTRODUCTION_HEADERS');
export const update_rent_introduction_headers = actionCreator<any>('UPDATE_RENT_INTRODUCTION_HEADERS');
export const delete_rent_introduction_headers = actionCreator<any>('DELETE_RENT_INTRODUCTION_HEADERS');

export const get_rent_introduction_headers_list = actionCreator<any>('GET_RENT_INTRODUCTION_HEADERS_LIST');
export const change_rent_introduction_headers_list = actionCreator<any>('CHANGE_RENT_INTRODUCTION_HEADERS_LIST');

export const change_rent_introduction_headers_id = actionCreator<any>('CHANGE_RENT_INTRODUCTION_HEADERS_ID');


export const change_select_list_open = actionCreator<any>('CHANGE_SELECT_LIST_OPEN');


export const insert_rent_introduction_point_headers = actionCreator<any>('INSERT_RENT_INTRODUCTION_POINT_HEADERS');
// export const update_rent_introduction_point_headers = actionCreator<any>('UPDATE_RENT_INTRODUCTION_POINT_HEADERS');
export const delete_rent_introduction_point_headers = actionCreator<any>('DELETE_RENT_INTRODUCTION_POINT_HEADERS');



// export const get_rent_introduction_point_headers = actionCreator<any>('GET_RENT_INTRODUCTION_POINT_HEADERS');

export const change_rent_introduction_point_headers = actionCreator<any>('CHANGE_RENT_INTRODUCTION_POINT_HEADERS');


export const change_remarks_id = actionCreator<number>('CHANGE_REMARKS_ID');

export const update_comment = actionCreator<any>('UPDATE_COMMENT');

export const change_map_mypage_introductions = actionCreator<any>('CHANGFE_MAP_MYPAGE_INTRODUCTIONS');

export const insert_rent_introduction_point_template = actionCreator<any>('INSERT_RENT_INTRODUCTION_POINT_TEMPLATE');

export const change_rent_introduction_point_template_disp = actionCreator<any>('CHANGE_RENT_INTRODUCTION_POINT_TEMPLATE_DISP');

export const change_map_mypage_room_detail_disp = actionCreator<any>('CHANGE_MAP_MYPAGE_ROOM_DETAIL_DISP');
export const change_map_mypage_room_detail_disp_id = actionCreator<any>('CHANGE_MAP_MYPAGE_ROOM_DETAIL_DISP_ID');


export const mapmypageReducer = reducerWithInitialState(initialState)
  .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))
  // .case(get_map_room_details_success, (state, payload) => ({ ...state, map_room_details: payload }))

  // .case(get_response_map_rooms_success, (state, payload) => ({ ...state, response_rooms: payload }))

  .case(change_showRight, (state, payload) => ({ ...state, showRight: payload }))
  .case(change_showLeft, (state, payload) => ({ ...state, showLeft: payload }))
  .case(change_showFlag, (state, payload) => ({ ...state, showFlag: payload }))
  .case(change_draggable, (state, payload) => ({ ...state, draggable: payload }))

  // .case(get_rooms_success, (state, payload) => ({ ...state, rooms: payload }))

  // .case(get_introductions_success, (state, payload) => ({ ...state, introductions: payload }))

  .case(change_introductions_loading, (state, payload) => ({ ...state, introductions_loading: payload }))


  // .case(get_introductions_success, (state, payload) => ({ ...state, introductions: payload }))
  .case(change_fromMapOpen, (state, payload) => ({ ...state, fromMapOpen: payload }))

  .case(change_map_polygon_settings, (state, payload) => ({ ...state, map_polygon_settings: payload }))
  .case(change_map_point_settings, (state, payload) => ({ ...state, map_point_settings: payload }))



  .case(change_map_settings_flg, (state, payload) => ({ ...state, map_settings_flg: payload }))

  .case(change_map_settings_edit, (state, payload) => ({ ...state, map_settings_edit: payload }))

  .case(change_map_disp, (state, payload) => ({ ...state, map_disp: payload }))




  .case(change_select_rooms, (state, payload) => ({ ...state, select_rooms: payload }))
  .case(change_open_rooms, (state, payload) => ({ ...state, open_rooms: payload }))



  .case(change_rent_introduction_headers, (state, payload) => ({ ...state, rent_introduction_headers: payload }))

  .case(change_rent_introduction_headers_list, (state, payload) => ({ ...state, rent_introduction_headers_list: payload }))

  .case(change_rent_introduction_headers_id, (state, payload) => ({ ...state, rent_introduction_headers_id: payload }))


  .case(change_select_list_open, (state, payload) => ({ ...state, select_list_open: payload }))

  .case(change_rent_introduction_point_headers, (state, payload) => ({ ...state, rent_introduction_point_headers: payload }))

  .case(change_remarks_id, (state, payload) => ({ ...state, remarks_id: payload }))

  .case(change_map_mypage_introductions, (state, payload) => ({ ...state, map_mypage_introductions: payload }))

  .case(change_rent_introduction_point_template_disp, (state, payload) => ({ ...state, rent_introduction_point_template_disp: payload }))

  .case(change_map_mypage_room_detail_disp, (state, payload) => ({ ...state, map_mypage_room_detail_disp: payload }))
  .case(change_map_mypage_room_detail_disp_id, (state, payload) => ({ ...state, map_mypage_room_detail_disp_id: payload }))

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const mapmypageSaga = [
  // takeEvery('GET_SETSUBI_MASTER', handleRequestSetsubiMaster),
  // takeEvery('GET_ROOMS', handleGetRoomsRequest),
  // takeEvery('GET_MAP_ROOM_DETAILS', handle_map_room_details),
  // takeEvery('GET_RESPONSE_MAP_ROOMS', handle_get_response_map_rooms),
  // takeEvery('DELETE_MAP_INTRODUCTION', handle_delete_map_introduction),
  // takeEvery('INSERT_MAP_INTRODUCTIONS', handle_insert_map_introductions),



  takeEvery('GET_RENT_INTRODUCTION_HEADERS', handle_get_rent_introduction_headers),


  takeEvery('UPDATE_RENT_INTRODUCTION_HEADERS', handle_update_rent_introduction_headers),
  takeEvery('UPDATE_RENT_INTRODUCTION_POINT_DETAILS', handle_update_rent_introduction_point_details),

  takeEvery('GET_RENT_INTRODUCTION_HEADERS_LIST', handle_get_rent_introduction_headers_list),

  takeEvery('INSERT_RENT_INTRODUCTION_POINT_HEADERS', handle_insert_rent_introduction_point_headers),

  // takeEvery('GET_RENT_INTRODUCTION_POINT_HEADERS', handle_get_rent_introduction_point_headers),
  // takeEvery('UPDATE_RENT_INTRODUCTION_POINT_HEADERS', handle_update_rent_introduction_point_headers),

  takeEvery('DELETE_RENT_INTRODUCTION_HEADERS', handle_delete_rent_introduction_headers),
  takeEvery('DELETE_RENT_INTRODUCTION_POINT_HEADERS', handle_delete_rent_introduction_point_headers),
  takeEvery('UPDATE_COMMENT', handle_update_comment),
  // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),

  takeEvery('INSERT_RENT_INTRODUCTION_POINT_TEMPLATE', handle_insert_rent_introduction_point_template),
];
// export default masterSaga;



// function* handleRequestSetsubiMaster(action: any) {

//   const { payload, error } = yield call(TmSetsubiApi.get_setsubi, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)

//     // const setsubis = payload.map((item:any, i:number) => ({
//     //     value: item.setsubiNo,
//     //     label: item.setsubiName,
//     //   }))

//     // 成功時アクション呼び出し


//     //yield put(get_setsubi_master_success(setsubis));
//     yield put(get_setsubi_master_success(payload));

//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }


// function* handleGetRoomsRequest(action: any) {

//   const { payload, error } = yield call(ResponseMapSearchRoomsApi.list, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)

//     yield put(get_rooms_success(payload));

//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }


// function* handle_map_room_details(action: any) {
//   console.log(action.payload)
//   // yield put(get_room_details_success([]));
//   //   alert(action.payload)
//   const value = { tatemonoId: action.payload };
//   const { payload, error } = yield call(ResponseMapSearchRoomsApi.detail, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)
//     //   alert("ded")
//     yield put(get_map_room_details_success(payload));
//     yield put(change_showRight(true));

//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }

// function* handle_get_response_map_rooms(action: any) {
//   // alert(JSON.stringify(action.payload))
//   // yield put(get_response_map_rooms_success([]));
//   const { payload, error } = yield call(ResponseMapSearchRoomsApi.response_list, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)
//     //   alert()
//     yield put(get_response_map_rooms_success(payload));

//   } else {
//     alert(error)
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }


// function* handle_delete_map_introduction(action: any) {
//   console.log(action.payload)

//   const { payload, error } = yield call(rent_introductions_api.deleteintro, action.payload);

//   if (payload && !error) {

//     // alert(action.payload.rent_response_id)  
//     yield put(IntroductionList.get_introductions({ rent_response_id: action.payload.rent_response_id }));
//     yield put(change_introductions_loading(0));
//     yield put(submit('mapsearchform'));
//     // yield put(get_map_room_details({ tatemono_id: action.payload.tatemono_id, rent_response_id: action.payload.rent_response_id }));

//     //   yield put(get_response_map_rooms(payload));


//     // } else {
//     //   alert(error)
//     //   //    // 失敗時アクション呼び出し
//     //   //    yield put(loginFailure(payload));
//     //   }
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }


// function* handle_insert_map_introductions(action: any) {
//   console.log(action.payload)

//   yield put(change_introductions_loading(1));
//   const { payload, error } = yield call(rent_introductions_api.insert, action.payload);

//   if (payload && !error) {

//     yield put(IntroductionList.get_introductions({ rent_response_id: action.payload.rentResponseId }));
//     yield put(change_introductions_loading(0));
//     yield put(submit('mapsearchform'));
//     // yield put(get_map_room_details({tatemono_id:action.payload.tatemonoId,rent_response_id:action.payload.rentResponseId}));
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }




function* handle_get_rent_introduction_headers_list(action: any): any {

  // yield put(change_rent_introduction_headers_list([]));

  const state = yield select();

  let rent_introduction_headers = state.mapmypage.rent_introduction_headers
  const { payload, error } = yield call(rent_introduction_headers_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_rent_introduction_headers_list(payload));

    yield put(change_rent_introduction_headers(payload.filter((a: any) => a.id === rent_introduction_headers.id)[0] ? payload.filter((a: any) => a.id === rent_introduction_headers.id)[0] : []));

    let introductions = state.introductionlist.introductions


    introductions = introductions.map(function (value: any) {
      let existlist: any = []
      payload.map(function (valueheaders: any) {
        valueheaders.rent_introduction_details.map(function (valuedetails: any) {
          if (valuedetails.tatemono_id == value.tatemono_id && valuedetails.room_id == value.room_id) {

            existlist.push(valueheaders.name)


          }

        })
      })
      value.existlist = existlist.join(',')
      return value

    })

    yield put(change_map_mypage_introductions(introductions));


  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_get_rent_introduction_headers(action: any) {

  yield put(change_rent_introduction_headers({}));
  const { payload, error } = yield call(rent_introduction_headers_api.detail, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_rent_introduction_headers(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_update_rent_introduction_headers(action: any): any {
  const state = yield select();

  let rent_introduction_headers = state.mapmypage.rent_introduction_headers
  console.log(rent_introduction_headers)
  // alert("header")
  let responsedetail = state.responsesReducer.responsedetail
  let rent_introduction_details = rent_introduction_headers.rent_introduction_details

  if (action.payload !== 1) {
    let select_rooms = state.mapmypage.select_rooms


    rent_introduction_details = select_rooms.map((item: any, i: number) => ({
      tatemono_id: item.tatemono_id,
      tatemono_name: item.tatemono_name,
      room_id: item.room_id,
      room_name: item.room_name,
      ido: item.ido,
      keido: item.keido,
      numbering: item.numbering ? item.numbering : 0,
      id: item.id,
      chinryou_amount: item.chinryou_amount,
      kyouekihi_amount: item.kyouekihi_amount,
      suidou_flg: item.suidou_flg,

      parking_amount: item.parking_amount,
      parking_flg: item.parking_flg,

      shikikin_amount: item.shikikin_amount,
      shikikin_flg: item.shikikin_flg,
      reikin_amount: item.reikin_amount,
      reikin_flg: item.reikin_flg,
      file_id: item.file_id,
      mime_type: item.mime_type,
      file_name: item.file_name,
      is_bookmark: item.is_bookmark,
      remarks: item.remarks,
    }))
    console.log(rent_introduction_details)

  }
  console.log(rent_introduction_details)
  // console.log(rentIntroductionDetailsViewModel)
  // alert()
  // alert(JSON.stringify(rent_introduction_headers))
  // 重複を検出したものを重複しないでリスト
  //   var setnumber = rentIntroductionDetailsViewModel.filter(function (x:any, i:any, self:any) {
  //     return self.indexOf(x.tatemonoId) === i.tatemonoId && i.tatemonoId !== self.lastIndexOf(x.tatemonoId);
  // });
  var setnumber = rent_introduction_details.filter(function (x: any, i: any, self: any) {
    if (x.tatemono_id === -1) {
      return self
    } else {
      return (self.findIndex(function (v2: any) {
        return (parseInt(x.tatemono_id) === parseInt(v2.tatemono_id))
      }) === i);
    }
  });
  console.log(setnumber)
  // alert()
  // var cleanList = dupList.filter(function(v1,i1,a1){ 
  //   return (a1.findIndex(function(v2){ 
  //     return (v1.key===v2.key) 
  //   }) === i1);
  // });


  let max = Math.max.apply(null, setnumber.map(function (o: any) { return o.numbering; }))
  setnumber = setnumber.map(function (item: any, i: number) {
    if (item.numbering === 0) {
      max = max + 1
      item.numbering = max
      return item
    } else {
      return item
    }

  })



  console.log(rent_introduction_details)

  rent_introduction_details = rent_introduction_details.map(function (item: any, i: number) {

    // rentIntroductionDetailsViewModel.filter((a:any)=>a.tatemonoId)

    item.numbering = setnumber.filter((a: any) => parseInt(a.tatemono_id) === parseInt(item.tatemono_id))[0].numbering
    return item


  })

  // console.log(rentIntroductionDetailsViewModel)
  // alert()
  // alert(responsedetail.id)
  // alert(JSON.stringify(rent_introduction_headers))
  // rent_introduction_headers[0].id = 0
  rent_introduction_headers.rent_introduction_details = rent_introduction_details
  rent_introduction_headers.rent_response_id = responsedetail.id

  // yield put(change_rent_introduction_headers(rent_introduction_headers));
  // console.log(rent_introduction_headers)
  // alert(JSON.stringify(rent_introduction_headers))
  const { payload, error } = yield call(rent_introduction_headers_api.update, rent_introduction_headers);
  console.log(setnumber)

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    //  yield put(get_response_rooms_success(payload));
    //  alert(responsedetail.id)
    yield put(get_rent_introduction_headers_list(responsedetail.id));

    yield put(change_select_list_open(false));

    yield put(Mail.get_mail_rent_introduction_header_list(responsedetail.id));


    yield put(change_map_disp(!state.mapmypage.map_disp));

    yield put(change_rent_introduction_point_headers([]));


  } else {
    alert("error")
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_update_rent_introduction_point_details(action: any): any {
  const state = yield select();



  let rent_introduction_point_headers = state.mapmypage.rent_introduction_point_headers

  // yield put(change_rent_introduction_headers(rent_introduction_headers));
  // console.log(rent_introduction_headers)
  // alert(JSON.stringify(rent_introduction_headers))
  const { payload, error } = yield call(rent_introduction_headers_api.update_point, rent_introduction_point_headers);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    //  yield put(get_response_rooms_success(payload));
    //  alert(responsedetail.id)
    // yield put(get_rent_introduction_headers_list(responsedetail.id));

    // yield put(change_select_list_open(false));

    // yield put(Mail.get_mail_rent_introduction_header_list(responsedetail.id));


    // yield put(change_map_disp(!state.mapmypage.map_disp));

    // yield put(change_rent_introduction_point_headers([]));


  } else {
    alert("error")
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}



// function* handle_get_rent_introduction_point_headers(action: any) {

//   const { payload, error } = yield call(rent_introduction_point_headers_api.list, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)

//     yield put(change_rent_introduction_point_headers(payload));

//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }

// }

function* handle_insert_rent_introduction_point_headers(action: any): any {

  const state = yield select();

  let rent_introduction_headers = state.mapmypage.rent_introduction_headers
  let responsedetail = state.responsesReducer.responsedetail
  let contents = ""
  if (action.payload === 1) {
    contents = "ポイント" + (rent_introduction_headers.rent_introduction_point_headers.filter((a: any) => a.type === 1).length + 1)
  } else if (action.payload === 2) {
    contents = "範囲" + (rent_introduction_headers.rent_introduction_point_headers.filter((a: any) => a.type === 2).length + 1)
  } else if (action.payload === 3) {
    contents = "コメント" + (rent_introduction_headers.rent_introduction_point_headers.filter((a: any) => a.type === 3).length + 1)
  } else if (action.payload === 4) {
    contents = "線" + (rent_introduction_headers.rent_introduction_point_headers.filter((a: any) => a.type === 4).length + 1)
  } else if (action.payload === 5) {
    contents = "円" + (rent_introduction_headers.rent_introduction_point_headers.filter((a: any) => a.type === 5).length + 1)
  }
  // yield put(change_rent_introduction_headers({}));




  const { payload, error } = yield call(rent_introduction_point_headers_api.insert, { type: action.payload, rent_introduction_header_id: rent_introduction_headers.id, rent_response_id: responsedetail.id, contents: contents });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    payload.rent_introduction_point_details = payload.rent_introduction_point_details ? payload.rent_introduction_point_details.map(function (value: any) {

      value = value ? value : []
      //配列の各要素を2倍にする
      return value;

    }) : [];
    rent_introduction_headers.rent_introduction_point_headers = rent_introduction_headers.rent_introduction_point_headers.concat(payload)
    // rent_introduction_headers.rent
    // rent_introduction_headers.rent






    yield put(change_rent_introduction_headers(rent_introduction_headers));
    yield put(change_rent_introduction_point_headers(payload));
    yield put(change_map_disp(!state.mapmypage.map_disp));


    // yield put(get_rent_introduction_headers_list(responsedetail.id))


  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}


// function* handle_update_rent_introduction_point_headers(action: any) {

//   const state = yield select();

//   let rent_introduction_point_headers = state.mapmypage.rent_introduction_point_headers
//   // let responsedetail =  state.responsesReducer.responsedetail
//   // let contents=""




//   const { payload, error } = yield call(rent_introduction_point_headers_api.update, rent_introduction_point_headers);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)
//     yield put(change_map_disp(!state.mapmypage.map_disp));
//     //  rent_introduction_headers.rentIntroductionPointHeadersViewModel =  rent_introduction_headers.rentIntroductionPointHeadersViewModel.concat(payload)
//     // rent_introduction_headers.rent
//     // rent_introduction_headers.rent

//     // yield put(change_rent_introduction_headers(rent_introduction_headers));

//     // yield put(get_rent_introduction_headers_list(responsedetail.id))


//   } else {
//     alert(error)
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }

// }





function* handle_delete_rent_introduction_headers(action: any): any {

  const state = yield select();

  let rent_introduction_headers_list = state.mapmypage.rent_introduction_headers_list
  // let responsedetail =  state.responsesReducer.responsedetail
  // let contents=""



  // yield put(change_rent_introduction_headers_list([]));
  const { payload, error } = yield call(rent_introduction_headers_api.deleteheaders, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    // yield put(get_rent_introduction_headers_list(rent_introduction_headers.rentResponseId));
    yield put(change_rent_introduction_headers_list(rent_introduction_headers_list.filter((a: any) => a.id !== action.payload)));
    yield put(change_rent_introduction_headers([]));
    yield put(change_rent_introduction_point_headers([]));
    yield put(change_map_disp(!state.mapmypage.map_disp));
  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}



function* handle_delete_rent_introduction_point_headers(action: any): any {

  const state = yield select();

  let rent_introduction_headers = state.mapmypage.rent_introduction_headers
  yield put(change_rent_introduction_headers([]));

  const { payload, error } = yield call(rent_introduction_point_headers_api.deletepoint, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    rent_introduction_headers.rent_introduction_point_headers = rent_introduction_headers.rent_introduction_point_headers.filter((a: any) => a.id !== action.payload)
    yield put(change_rent_introduction_headers(rent_introduction_headers));
    yield put(change_rent_introduction_point_headers([]));
    yield put(change_map_disp(!state.mapmypage.map_disp));
  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}

function* handle_insert_rent_introduction_point_template(action: any): any {

  const state = yield select();

  let rent_introduction_headers = state.mapmypage.rent_introduction_headers

  const { payload, error } = yield call(rent_introduction_point_templates_api.insert, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)
    // rent_introduction_headers.rent_introduction_point_headers = rent_introduction_headers.rent_introduction_point_headers.filter((a: any) => a.id !== action.payload)
    // yield put(change_rent_introduction_headers(rent_introduction_headers));
    // yield put(change_rent_introduction_point_headers([]));
    // yield put(change_map_disp(!state.mapmypage.map_disp));

    yield put(change_rent_introduction_point_template_disp(true));
    yield put(MapMypageTemplate.get_rent_introduction_point_templates(payload));


  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}




function* handle_update_comment(action: any): any {

  const state = yield select();

  let rent_introduction_headers = state.mapmypage.rent_introduction_headers
  let rent_introduction_headers_list = state.mapmypage.rent_introduction_headers_list

  const { payload, error } = yield call(rent_introduction_details_api.updatecomment, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    yield put(change_remarks_id(0));



    // let rent_introduction_headers_lists = rent_introduction_headers_list.map(async function (value: any) {
    //   value.rent_introduction_details = await Promise.all(value.rent_introduction_details.map(async function (valuedetails: any) {
    //     if (action.payload.id === valuedetails.id) {
    //       valuedetails.remark = action.payload.remarks
    //     }
    //     return valuedetails
    //   }))
    //   return value
    // })

    yield put(change_rent_introduction_headers_list(payload));

    // rent_introduction_headers.rent_introduction_details = rent_introduction_headers.rent_introduction_details.map(async function (value: any) {
    //   if (action.payload.id === value.id) {
    //     value.remark = action.payload.remarks
    //   }
    //   return value
    // })
    yield put(change_rent_introduction_headers(payload.filter((a: any) => a.id === rent_introduction_headers.id)[0] ? payload.filter((a: any) => a.id === rent_introduction_headers.id)[0] : []));

    // console.log(payload)
    // rent_introduction_headers.rent_introduction_point_headers = rent_introduction_headers.rent_introduction_point_headers.filter((a: any) => a.id !== action.payload)
    // yield put(change_rent_introduction_headers(rent_introduction_headers));



    // yield put(get_rent_introduction_headers_list(rent_introduction_headers.rent_response_id));
    // yield put(change_rent_introduction_point_headers([]));
    // yield put(change_map_disp(!state.mapmypage.map_disp));
  } else {
    alert(error)
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }

}