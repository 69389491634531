import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Calendar, Views } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'

import moment from 'moment'
import localizer from 'react-big-calendar/lib/localizers/globalize'
import globalize from 'globalize'
import events from './sampleevents'
import * as Calendarsaga from './Saga/Calendar';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './Css/Calendar.css';
import Tooltip from '@mui/material/Tooltip';


const DragAndDropCalendar = withDragAndDrop(Calendar)
const globallocalizer = localizer(globalize);


function eventStyleGetter(event, start, end, isSelected) {
  console.log(event);

  var backgroundColor = event.id === 0 ? '#fffacd' : event.intend ? '#98fb98' : '#BDEBF9';
  var style = {
    backgroundColor: backgroundColor,
    borderRadius: '2px',
    borderColor: '#FFF',
    // opacity: 0.8,
    color: '#4d4d4d',
    border: '0px',
    display: 'block'
  };
  return {
    style: style
  };
}



class RBCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      events: events,
      baseDate: new Date(),
      cardx: window.innerWidth - 600,
      open_event_editor: false,

    }


    let calendar_list_form = this.props.calendar_list_form

    calendar_list_form.section_id = this.props.section_id ? this.props.section_id : this.props.user_details.amSectionId
    this.props.change_calendar_list_form(calendar_list_form)

    this.moveEvent = this.moveEvent.bind(this)
    this.newEvent = this.newEvent.bind(this)

    this.viewEvent = this.viewEvent.bind(this)


    this.resizeEvent = this.resizeEvent.bind(this)
    this.Event = this.Event.bind(this)
    this.onNavigate = this.onNavigate.bind(this)
  }
  componentDidMount() {
    this.onNavigate(new Date(), "week");
  }
  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
    console.log('moveEvent')
    // const { events } = this.state

    const idx = this.props.calendar_list_disp.indexOf(event)
    let allDay = event.allDay

    console.log({ start, end, allDay })

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const updatedEvent = { ...event, start, end, allDay }

    const nextEvents = [...events]
    nextEvents.splice(idx, 1, updatedEvent)
    // alert(start)
    this.props.change_calendar_list_disp(nextEvents)
    // this.setState({
    //   events: nextEvents,
    // })
    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  resizeEvent = ({ event, start, end }) => {
    // alert(start)
    console.log('resizeEvent')
    // const { events } = this.state

    const nextEvents = this.props.calendar_list_disp.map(existingEvent => {
      var ret = existingEvent;
      if (existingEvent.id == event.id) {
        //日付けを変える変更はさせない
        if (existingEvent.start.getFullYear() != start.getFullYear()
          || existingEvent.start.getMonth() != start.getMonth()
          || existingEvent.start.getDate() != start.getDate()
        ) {
          start = existingEvent.start;
        }
        if (existingEvent.end.getFullYear() != end.getFullYear()
          || existingEvent.end.getMonth() != end.getMonth()
          || existingEvent.end.getDate() != end.getDate()
        ) {
          end = existingEvent.end;
        }
        ret = { ...existingEvent, start, end };
      } else {
        ret = existingEvent;
      }
      return ret;
    })
    // alert("changes")
    this.props.change_calendar_list(nextEvents)
    // this.setState({

    //   events: nextEvents,
    // })

    //alert(`${event.title} was resized to ${start}-${end}`)
  }

  newEvent = ({ start, end }) => {
    console.log('newEvent')
    let title = ""
    // let title = window.prompt('新しい予定')
    // if (!title) {
    //   return;
    // }
    let idList = this.props.calendar_list_disp.map(a => a.id)
    let newId = 1
    if (idList.length > 0) {
      newId = Math.max(...idList) + 1
    }
    console.log('newId')
    console.log(idList)
    console.log(newId)

    let event = {
      id: newId,
      title: title,
      start: start,
      end: end,
    }
    // alert("changeevent")
    // this.props.change_calendar_list(this.props.calendar_list.concat([event]))

    this.props.change_calendar_list_disp(this.props.calendar_list.concat([event]))
    this.props.change_schedule(event)
    // this.setState({
    //   events: this.state.events.concat([event]),
    // })
  }

  dblclick = (event) => {
    // alert(event.target.getBoundingClientRect().x)
    console.log(event)
    // let cur_id = event.id;
    // var evList = []
    // this.props.calendar_list.map(function (value) {
    //   if (value.id != cur_id) {
    //     evList.push(value)
    //   }
    // });

    // console.log(evList);
    // this.props.change_calendar_list(evList)
    // this.setState({
    //   events: evList
    // })
  }

  onMouseDown(view) {
    console.log('onMouseDown')
    console.log(view)
    // alert(view)
  }

  onTouchStart(view) {
    console.log('onTouchStart')
    console.log(view)
    // alert(view)
  }

  viewEvent(view) {

    this.onNavigate(this.state.baseDate, view)
    console.log(view)
    // alert(view)
  }
  onNavigate = (date, view) => {
    let start, end;

    this.setState({ baseDate: date })

    if (view === 'month') {
      start = moment(date).startOf('month').startOf('week').startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).endOf('month').endOf('week').endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    if (view === 'agenda') {
      start = moment(date).startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).add(1, 'months').endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    if (view === 'week') {
      start = moment(date).add(-6, 'days').startOf('day').format('YYYY/MM/DD HH:mm:ss')
      console.log(start)
      end = moment(date).add(6, 'days').endOf('day').format('YYYY/MM/DD HH:mm:ss')

    }
    if (view === 'day') {
      start = moment(date).startOf('day').format('YYYY/MM/DD HH:mm:ss')

      end = moment(date).endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }


    console.log(start, end);

    let calendar_list_form = this.props.calendar_list_form
    calendar_list_form.start = start
    calendar_list_form.end = end
    // calendar_list_form.section_id = 1
    this.props.change_calendar_list_form(calendar_list_form)
    this.props.get_calendar_list()
    return console.log({ start, end });
  }
  click(e) {

    console.log(e)
    // alert(e.target.getBoundingClientRect().x)
    if (e.target.getBoundingClientRect().x - 600 < 0 && e.target.getBoundingClientRect().x + 600 > window.innerWidth) {
      this.setState({ cardx: 0 })
    } else if (e.target.getBoundingClientRect().x - 600 >= 0) {
      this.setState({ cardx: e.target.getBoundingClientRect().x - 600 })

    }
    else if (e.target.getBoundingClientRect().x + 580 >= 0) {
      this.setState({ cardx: e.target.getBoundingClientRect().x })

    }
    this.setState({ open_event_editor: !this.state.open_event_editor })
  }


  // holidayの数値によってその日のシフトが決まります↓
  // 0出勤 1公休 2有給 3AM半休 4特休 5遅刻 6早退 7出張 8取り消し(=出勤) 9欠勤 11その他 12PM半休 13外出 14旅行
  // NULLの場合も出勤
  // console.log(f)
  Event = ({ event }) => {
    if (event.id === 0) {
      return (
        <Tooltip title={event.users.filter(a => a.holiday === 0 || a.holiday === 3 || a.holiday === 5 || a.holiday === 6 || a.holiday === 7 || a.holiday === 8 || a.holiday === 12 || a.holiday === 13).map(function (value) {
          return <div>{value.user_name_all}{value.holiday === 3 ? "(AM半休)" : value.holiday === 5 ? "(遅刻)" : value.holiday === 6 ? "(早退)" : value.holiday === 7 ? "(出張)" : value.holiday === 12 ? "(PM半休)" : value.holiday === 12 ? "(外出)" : ""} </div>
        })} aria-label="add" placement="bottom-start">
          <div style={{ width: "100%" }}>
            {event.title}

          </ div ></Tooltip>);
    } else {
      return (


        <div
          style={{ width: "100%" }}
          onClick={this.click.bind(this)}>

          {event.title}
        </ div>);
    }
  };
  render() {
    return (
      <span>
        {this.props.calender_loading ? <div style={{
          position: "absolute", opacity: 0.8, zIndex: 10, background: "#FFF", width: window.innerWidth - 50, height: window.innerHeight - 200
        }}></div> : ""}

        {/* {this.state.open_event_editor ? <Card style={{ position: "absolute", zIndex: 3000, width: 500, height: 500, marginLeft: this.state.cardx, marginTop: "-70px" }}>
          <CardContent >
          </CardContent >
        </Card > : ""} */}

        <DragAndDropCalendar
          selectable
          localizer={globallocalizer}
          // min={new Date('2000/1/1 06:00:00')}
          // max={new Date('2000/1/1 23:00:00')}
          culture={'ja-JP'}
          events={this.props.calendar_list_disp}
          // onEventDrop={this.moveEvent}
          // resizable
          onView={this.viewEvent}
          // onEventResize={this.resizeEvent}
          // onMouseDown={this.onMouseDown}
          // onTouchStart={this.onTouchStart}
          // onSelectSlot={this.newEvent}
          // onDragStart={console.log('onDragStart')}
          // onDragOver={console.log('onDragOver')}
          // onNavigate={this.onNavigate}
          // onDoubleClickEvent={this.dblclick}
          eventPropGetter={eventStyleGetter}
          components={{
            event: this.Event,

          }}
          defaultView={Views.WEEK}
          views={{ agenda: true, week: true, month: true, day: true }}
          // toolbar={false}
          // showMultiDayTimes={true}
          formats={{
            dayFormat: (date) => {
              return (moment(date).format('M月DD日(dddd)'));
            },
            agendaDateFormat: (date) => {
              return (moment(date).format('M月DD日(dddd)'));
            },
            dayHeaderFormat: (date) => {
              return (moment(date).format('YYYY年M月DD日(dddd)'));
            },
            agendaHeaderFormat: ({ start, end }) => {
              return (moment(start).format('YYYY年M月DD日') + ' ～ ' + moment(end).format('YYYY年M月DD日'));
            },
            dayRangeHeaderFormat: ({ start, end }) => {
              return (moment(start).format('YYYY年M月DD日') + ' ～ ' + moment(end).format('YYYY年M月DD日'));
            },
          }}
          messages={{
            date: '日付',
            time: '時間',
            event: '内容',
            allDay: '終日',
            week: '週',
            work_week: 'Work Week',
            day: '日',
            month: '月',
            previous: '←前へ',
            next: '次へ→',
            yesterday: '昨日',
            tomorrow: '明日',
            today: '今日',
            agenda: '一覧',
            noEventsInRange: '期間内に登録されているイベントがありません',
          }}
        />
      </span>
    );
  }
};


const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,

    calendar_list: state.calendar.calendar_list,
    calendar_list_disp: state.calendar.calendar_list_disp,
    calendar_list_form: state.calendar.calendar_list_form,
    calender_loading: state.calendar.calender_loading,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    change_calendar_list_form(state) {
      dispatch(Calendarsaga.change_calendar_list_form(state))
    },
    change_calendar_list(state) {
      dispatch(Calendarsaga.change_calendar_list(state))
    },
    change_calendar_list_disp(state) {
      dispatch(Calendarsaga.change_calendar_list_disp(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RBCalendar);