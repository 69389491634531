import React, { Component } from 'react';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
//import CustomerInfoEdit from './CustomerInfoEditComponent';
//import CustomerInfoDisp from './CustomerInfoDispComponent';
// import { Modal, Row, Col, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import './Css/Line.css';



// import Card from '@mui/material/Card';
// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }




class Lines extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            // top: this.props.lines[0].id,
            modal_send: 0,

            base64: null,
            mimeType: null
        };
        console.log(this.props.lines)
        // this.props.lines.map(){
        //     this.Print()
        // }
        //
        this.linepage = this.linepage.bind(this);
        // this.handleScroll = this.handleScroll.bind(this);
        // this.lineopen = this.lineopen.bind(this);
        // this.toggle_send = this.toggle_send.bind(this);

        // this.scrollToBottom = this.scrollToBottom.bind(this);

        // this.onDrop = this.onDrop.bind(this);


        // var result = props.lines.map(function( value ) {

        //         // alert()
        //     return this.Print(value)

        //     //配列の各要素を2倍にする
        //     // return value * 2;

        // });
    }






    // }


    async componentDidMount() {
        console.log(this.props)

        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_line_bottom(false);
        }
    }
    componentDidUpdate() {
        // alert(this.props.line_bottom)
        // alert(this.props.line_bottom)
        if (this.node != null && this.props.line_bottom === true) {

            this.node.scrollTop = this.node.scrollHeight;
            this.props.change_line_bottom(false);
        }
    }

    linepage() {

        window.open("https://rooms.global-center.co.jp/line/" + this.props.responsedetail.response_code + "/0")
    }


    render() {


        return (

            <div>





                <Button variant="outlined"
                    color="primary"
                    onClick={this.linepage}
                >
                    <i className="fab fa-line" style={{ fontSize: "20px", color: "green" }}></i>　LINE登録ページ
                </Button>

            </div>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {

        responsedetail: state.responsesReducer.responsedetail,
        lines: state.line.lines,
        line_bottom: state.line.line_bottom,
        line_user_details: state.line.line_user_details,
        line_open_flg: state.line.line_open_flg,
        line_send_modal: state.line.line_send_modal,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        // send_message(state) {
        //     dispatch(Line.send_message(state))
        // },
        // get_lines(state) {
        //     dispatch(Line.get_lines(state))
        // },
        // get_lines_success(state) {
        //     dispatch(Line.get_lines_success(state))
        // },
        // change_line_bottom(state) {
        //     dispatch(Line.change_line_bottom(state))
        // },
        // get_line_close(state) {
        //     dispatch(Line.get_line_close(state))
        // },
        // get_line_open(state) {
        //     dispatch(Line.get_line_open(state))
        // },
        // send_line_stock_rooms(state) {
        //     dispatch(Line.send_line_stock_rooms(state))
        // },
        // change_line_file_data(state) {
        //     dispatch(Line.change_line_file_data(state))
        // },
        // upload_line_file(state) {
        //     dispatch(Line.upload_line_file(state))
        // },
        // change_line_send_modal(state) {
        //     dispatch(Line.change_line_send_modal(state))
        // },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Lines);
