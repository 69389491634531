import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as Contracts from './Saga/Contracts';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
	ClassicEditor,
	AccessibilityHelp,
	AutoLink,
	Autosave,
	Bold,
	Code,
	CodeBlock,
	Essentials,
	FontBackgroundColor,
	FontColor,
	// FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HtmlComment,
	HtmlEmbed,
	Italic,
	Link,
	Paragraph,
	RemoveFormat,
	SelectAll,
	ShowBlocks,
	SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	Underline,
	Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/ja.js';

import 'ckeditor5/ckeditor5.css';

// import './ckeditor.css';


const editorConfig = {
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'sourceEditing',
      'showBlocks',
      'selectAll',
      '|',
      'heading',
      '|',
      'fontSize',
      // 'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'subscript',
      'superscript',
      'code',
      'removeFormat',
      '|',
      'specialCharacters',
      'link',
      'insertTable',
      'highlight',
      'codeBlock',
      'htmlEmbed',
      '|',
      'accessibilityHelp'
    ],
    shouldNotGroupWhenFull: false
  },
  plugins: [
    AccessibilityHelp,
    AutoLink,
    Autosave,
    Bold,
    Code,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    // FontFamily,
    FontSize,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HtmlComment,
    HtmlEmbed,
    Italic,
    Link,
    Paragraph,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Underline,
    Undo
  ],
  fontFamily: {
    supportAllValues: true
  },
  fontSize: {
    options: [10, 12, 14, 'default', 18, 20, 22],
    supportAllValues: true
  },
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5'
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6'
      }
    ]
  },
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true
      }
    ]
  },
  language: 'ja',
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
    decorators: {
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'file'
        }
      }
    }
  },
  // placeholder: 'Type or paste your content here!',
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
  },
  translations: [translations]
};

  
const tenpo = [
    {mail:'info@global-center.co.jp'},
    {mail:'rainbow@global-center.co.jp'},
    {mail:'takamatsu@global-center.co.jp'},
    {mail:'east@global-center.co.jp'},
    {mail:'park@global-center.co.jp'},
    {mail:'sun@global-center.co.jp'},
    {mail:'cd@global-center.co.jp'},
    {mail:'system@global-center.co.jp'},
]
class ContractSendMailCkEditor extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: "",
      modal: false,
      EditCC:false,
      EditBCC:false,
      from:'',
      to:'',
      cc:'',
      bcc:'',
      subject:'',
      body:''
    };
    // this.toggle = this.toggle.bind(this);
    this.paramset = this.paramset.bind(this);
    this.handleChange = this.handleChange.bind(this);

  }

  handleChange(e){
    console.log(e)
    var mail = this.state
    mail[e.target.name] = e.target.value
    this.setState(mail)
    this.props.change_check_contract_send_mail(mail)
  }
  handleChangeBody(body){
    var mail = this.state
    mail.body = body
    this.setState(mail)
    
    this.props.change_check_contract_send_mail(mail)
  }
  componentDidMount (prevProps){
    if(!this.state.from && this.props.check_contract_send_mail_confirm.from){
        var d = this.props.check_contract_send_mail_confirm
        var mail = {
            from:d.from,
            to:d.to,
            subject:d.subject,
            body:d.body,
            cc:d.cc ? d.cc : '', 
            bcc:d.bcc ? d.bcc : '',
            EditCC:d.cc ? true : false, 
            EditBCC:d.bcc ? true : false
        }
        console.log(mail)
        this.setState(mail)
        this.props.change_check_contract_send_mail(mail)
        
    }
  }
  componentDidUpdate(prevProps){
    if(this.props.check_contract_send_mail_confirm.from && this.state.from == ''){
        console.log("下")
        var mail = this.props.check_contract_send_mail_confirm
        this.setState(mail)
        this.props.change_check_contract_send_mail(mail)
    } else if((this.props.check_contract_send_mail_template != prevProps.check_contract_send_mail_template) && this.state.from == '' && this.props.check_contract_send_mail_confirm.from == '' ){
        console.log("上")
        var d = this.props.check_contract_send_mail_template
        var mail = {
            from:d.tenpo_mail,
            to:d.customer_mail,
            bcc:d.tenpo_mail,
            subject:d.subject,
            body:d.body_all + d.shomei
        }
        console.log(mail)
        this.setState(mail)
        this.props.change_check_contract_send_mail(mail)
        this.props.change_check_contract_send_mail_confirm(mail)
    }else if(!this.state.from && this.props.check_contract_send_mail_confirm.from){
        var d = this.props.check_contract_send_mail_confirm
        var mail = {
            from:d.from,
            to:d.to,
            subject:d.subject,
            body:d.body
        }
        console.log(mail)
        this.setState(mail)
        this.props.change_check_contract_send_mail(mail)
        
    }
  }
  paramset = (text) => {
    // let editor = window.CKEDITOR.instances.editor2;
    let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[1]];

    editor.insertHtml(text);
    // editor.insertText(text);
    this.toggle()
  }
  html = (html) => {
    // let editor = window.CKEDITOR.instances.editor2
    let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];
    ;
    editor.insertHtml(html);
  }
  focus = () => {

    this.editor.focus();
    this.editor.insertText("eddd");
  };


  render() {


    return (
      <div>
        
        <Grid container spacing={1}>
            <Grid item xs={3} md={3}>
                <TextField
                    id="standard-basic"
                    //   className={classes.textField}
                    label="差出人"
                    select
                    size="small"
                    margin="normal"
                    variant="standard"
                    defaultValue={this.props.check_contract_send_mail.from}
                    value={this.state.from}
                    onChange={this.handleChange}
                    name="from"
                    style={{ float: "left", width: "100%", marginTop:'0px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                >
                    {tenpo.map((option) => (
                        <MenuItem key={option.mail} value={option.mail}>
                        {option.mail}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item xs={3} md={3}>
                <TextField
                    id="standard-basic"
                    //   className={classes.textField}
                    label="宛先"
                    size="small"
                    margin="normal"
                    variant="standard"
                    defaultValue={this.props.check_contract_send_mail.to}
                    value={this.state.to}
                    onChange={this.handleChange}
                    name="to"
                    style={{ float: "left", width: "100%", marginTop:'0px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
                {
                    this.state.EditCC ?
            <Grid item xs={3} md={3}>
                <TextField
                    id="standard-basic"
                    //   className={classes.textField}
                    label="CC"
                    size="small"
                    margin="normal"
                    variant="standard"
                    defaultValue={this.props.check_contract_send_mail.cc}
                    value={this.state.cc}
                    onChange={this.handleChange}
                    name="cc"
                    style={{ float: "left", width: "100%", marginTop:'0px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            :""
            }
            {
                    this.state.EditBCC ?
            <Grid item xs={3} md={3}>
                    
                    <TextField
                    id="standard-basic"
                    //   className={classes.textField}
                    label="BCC"
                    size="small"
                    margin="normal"
                    variant="standard"
                    defaultValue={this.props.check_contract_send_mail.bcc}
                    value={this.state.bcc}
                    onChange={this.handleChange}
                    name="bcc"
                    style={{ float: "left", width: "100%", marginTop:'0px' }}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
                :""
                }
            {!this.state.EditCC || !this.state.EditBCC ?
                
                <Grid item xs={!this.state.EditCC && !this.state.EditBCC ? 6 : this.state.EditCC || this.state.EditBCC ? 3 : 0} md={!this.state.EditCC && !this.state.EditBCC ? 6 : this.state.EditCC || this.state.EditBCC ? 3 : 0}>
                    {this.state.EditCC ? "" : <Button onClick={() => {console.log(this.props);this.setState({EditCC:true})}} style={{color:"#333"}} size="small">CC</Button>}
                    {this.state.EditBCC ? "" : <Button onClick={() => {console.log(this.props);this.setState({EditBCC:true})}} style={{color:"#333"}} size="small">BCC</Button>}
                </Grid>
                :""
            }
        </Grid>
        
        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <TextField
                    id="standard-basic"
                    //   className={classes.textField}
                    label="件名"
                    size="small"
                    margin="normal"
                    variant="standard"
                    onChange={this.handleChange}
                    style={{ float: "left", width: "100%", marginTop:'0px' }}
                    defaultValue={this.props.check_contract_send_mail.subject}
                    name="subject"
                    value={this.state.subject}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>
        <CKEditor 
          editor={ClassicEditor} 
          config={editorConfig}
            data={this.state.body}
            
            onChange={(event, editor) => {
  
              const data = editor.getData();
              console.log({ event, editor, data });
              this.handleChangeBody(data)
            }}
        />
      </div>
    );
  }
}






const mapStateToProps = state => {
    return {
      check_contract_send_mail_template: state.contracts.check_contract_send_mail_template,
      check_contract_send_mail: state.contracts.check_contract_send_mail,
      check_contract_send_mail_confirm: state.contracts.check_contract_send_mail_confirm,
    }
  }
function mapDispatchToProps(dispatch) {
  return {
    change_check_contract_send_mail(state) {
      dispatch(Contracts.change_check_contract_send_mail(state))
    },
    change_check_contract_send_mail_confirm(state) {
      dispatch(Contracts.change_check_contract_send_mail_confirm(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSendMailCkEditor);