const authentication = require('../../../react-azure-adb2c2').default;


export const detaillist = (values: any) => {
    // const url = `http://localhost:5000/v1/t/owner/room/detaillist/` + values;
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/t/owner/room/detaillist/` + values;

    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(values))
    // alert(JSON.stringify(values))
    return fetch(url, {
      method: 'get',
      // body: JSON.stringify(values),
      headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(res => res.json())
      .then(payload => ({ payload }))
      .catch(error => ({ error }));
  
  };