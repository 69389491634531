import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';

import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import MaskedInput from 'react-text-mask';
import styled from 'styled-components'
import Geocode from 'react-geocode';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';
// interface Props {
//     // classes: any,
//     // m_corporations_list:any,
//     // get_m_corporations_list:any,
//     // customerNewDispSet: any,
//     // customerNewDisp:number,
//     // customerno:number,
//     // customerList:any,
//     // toggle:any,
//     // get_rent_contract_accounting: any,
//     // banks: any,
//     // get_banks: any
// }



// interface State {

//     // state types
// }

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))
const phoneNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
const mobileNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
const postNumberMask = [

    /[0-9]/,
    /\d/,
    /\d/,
  
    "-",
  
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  
  ];

function PostMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
  
      <MaskedInput
        {...other}
        // ref={inputRef}
        mask={postNumberMask}
        placeholderChar={'\u2000'}
      // showMask
      />
    );
  }

function TelMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" || !props.value ? mobileNumberMask : phoneNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: true,
    fullscreenControl: false,
    // styles: [
    //   {
    //     featureType: 'water',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#e9e9e9',
    //       },
    //       {
    //         lightness: 17,
    //       },
    //     ],
    //   },

  }
}
const Pin = styled.div``
class CustomerList extends Component {
    constructor(props) {
 
        super(props);
        this.state = {
            // modal: false,
            modal_del: false,
            modalselect: false,

            zoom: 14,
            draggable: true,
            lat: 34.36179852,
            lng: 134.048788977,
            geo_lat : "",
            geo_lng : "",
            center: null,

        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()
        this.onChange = this.onChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

        this.onChildMouseMove = this.onChildMouseMove.bind(this);
        this.onChildMouseUp = this.onChildMouseUp.bind(this);
        this.handleZoomChanged = this.handleZoomChanged.bind(this);
        this.getGeocode = this.getGeocode.bind(this);
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    onChange(state){
        // console.log(this.props.claso_blog_places)
        let rent_m_corporates =  this.props.rent_m_corporates

        rent_m_corporates[state.target.name] = state.target.value
        
        this.props.change_rent_m_corporates(rent_m_corporates)
        this.setState({
            change: !this.state.change
        });
        
    }

    handleUpdate(id, delflg){

        let rent_m_corporates =  this.props.rent_m_corporates
        rent_m_corporates["is_display"] = delflg
        this.props.change_rent_m_corporates(rent_m_corporates)
   

        if(id){
            this.props.update_rent_m_corporates()
            
        }else{
            this.props.insert_rent_m_corporates()

        }

        this.props.change_rent_m_corporates_detail_open(false)

        if(this.props.parentComponent == 0){//法人画面から
            if(delflg){
                this.props.change_rent_m_corporates_top_open(false)
            }else{
                if(id){
                    this.props.change_rent_m_corporates_top_open(true)
                }
                
            }
        }else if(this.props.parentComponent == 1){//法人画面じゃない画面

        }

        this.setState({
            modal_del: false,
            change: !this.state.change
        });
        
    }








    componentWillReceiveProps(props) {
        //   alert(this.props.claso_blog_places.lat)
        this.setState({
            // lat: this.props.claso_blog_places.lat,
            // lng: this.props.claso_blog_places.lng
          });
        // this.setState({pins:props.tatemono_list})
      }
    
      _onBoundsChange = (center, zoom, bounds, marginBounds) => {
        this.setState({ zoom: zoom })
      }
    
      handleZoomChanged(){


    }
      onChildMouseUp() {
        this.setState({ draggable: true })
      }
      onChildMouseMove(childKey, childProps, mouse) {

          console.log(mouse)
        // function is just a stub to test callbacks
        this.setState({
          draggable: false,
          lat: mouse.lat,
          lng: mouse.lng,
    
        });

        // if(this.state.zoom < 14){
            this.setState({
                center: {lat:mouse.lat, lng:mouse.lng},
            });
        // }


        let rent_m_corporates =  this.props.rent_m_corporates

        rent_m_corporates["lat"] = mouse.lat
        rent_m_corporates["lng"] = mouse.lng

        this.props.change_rent_m_corporates(rent_m_corporates)
        this.setState({
            change: !this.state.change
        });


        // this.props.mapchange(mouse.lat, mouse.lng)
    
      }


    getGeocode() {
        // alert(this.props.tenant_details.address1 + this.props.tenant_details.address2 + this.props.tenant_details.address3 + this.props.tenant_details.address4 + this.props.tenant_details.address5)
        Geocode.setApiKey('AIzaSyDcEVxG_-xtQ1uCku--0iw84NPOffxow28');
        Geocode.setLanguage("ja");
        Geocode.fromAddress((this.props.rent_m_corporates.add1 ? this.props.rent_m_corporates.add1 : "") + (this.props.rent_m_corporates.add2 ? this.props.rent_m_corporates.add2 :""))
          .then((response) => {
    
            const { lat, lng } = response.results[0].geometry.location;
    
            let rent_m_corporates =  this.props.rent_m_corporates
            rent_m_corporates.lat = lat
            rent_m_corporates.lng = lng

            this.props.change_rent_m_corporates(rent_m_corporates)
            this.setState({
                center: {lat:lat, lng:lng},
                change: !this.state.change
            });


          })
          .catch((a) => {
            console.log(a)
            this.setState({
              lat: undefined,
              lng: undefined,
            });
          });
    
    }

    render() {
        return (
            <div>

                {/* <Modal isOpen={this.state.modal} fade={false} size="xl" > */}
                    <ModalHeader toggle={() => { this.props.change_rent_m_corporates_detail_open(false) }}>
                        <span>基本情報</span>
                        {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
                    </ModalHeader>
                        

                    <ModalBody>
                        <Card>
                            <CardContent>

                        <Grid container spacing={1}>
                            <Grid justifyContent="space-between" item xs={12}>
                                <TextField id="outlined-basic" label="法人名" name="name" onChange={this.onChange} value={this.props.rent_m_corporates.name} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>
                                <TextField id="outlined-basic" label="カナ" name="kana" onChange={this.onChange} value={this.props.rent_m_corporates.kana} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={8}>
                                <TextField id="outlined-basic" label="代表者" name="president" value={this.props.rent_m_corporates.president} onChange={this.onChange} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={4}>
                                <TextField id="outlined-basic" label="代表者役職" name="position" value={this.props.rent_m_corporates.position} onChange={this.onChange} variant="outlined" fullWidth />
                            </Grid>
                            
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField id="outlined-basic" label="資本金" name="capital" onChange={this.onChange} value={this.props.rent_m_corporates.capital} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField id="outlined-basic" label="設立年月日" name="establish" onChange={this.onChange} value={this.props.rent_m_corporates.establish} variant="outlined" fullWidth />
                                {/* <TextField
                                    id="date"
                                    label="設立年月日"
                                    type="date"
                                    name="birthday"
                                    // onChange={this.handleChange}
                                    // defaultValue={moment(this.props.rent_customer_editor_forms.birthday).format("YYYY-MM-DD")}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    /> */}
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField id="outlined-basic" label="創業年月日" name="start_of_business" onChange={this.onChange} value={this.props.rent_m_corporates.start_of_business} variant="outlined" fullWidth />
                                {/* <TextField
                                    id="date"
                                    label="創業年月日"
                                    type="date"
                                    name="birthday"
                                    // onChange={this.handleChange}
                                    // defaultValue={moment(this.props.rent_customer_editor_forms.birthday).format("YYYY-MM-DD")}
                                    // className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    /> */}
                            </Grid>
                            {/* <Grid justify="space-between" item xs={12}>
                                <TextField
                                    variant="outlined"
                                    name="post"
                                    label="本社〒"
                                    type="tel"
                                    value={this.props.rent_m_corporates.post}
                                    onChange={this.onChange}
                                    id="formatted-numberformat-input"
                                    style={{ float: "left", width: 200, paddingRight: "5px" }}

                                    InputProps={{
                                        inputComponent: PostMaskCustom,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    />   <Tooltip title="郵便番号から住所を検索" aria-label="add" onClick={this.props.get_rent_m_corporates_zip_address}>
                                    <IconButton aria-label="delete">
                                        <SearchIcon />
                                    </IconButton>
                                    </Tooltip>
                            </Grid>
                            <Grid justify="space-between" item xs={12}>
                                <TextField id="outlined-basic" label="本社所在地1" name="add1" onChange={this.onChange} value={this.props.rent_m_corporates.add1} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justify="space-between" item xs={12}>
                                <TextField id="outlined-basic" label="本社所在地2" name="add2" onChange={this.onChange} value={this.props.rent_m_corporates.add2} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justify="space-between" item xs={12}>
                                <TextField
                                    variant="outlined"
                                    name="tel1"
                                    label="本社TEL"
                                    type="tel"
                                    value={this.props.rent_m_corporates.tel1}
                                    onChange={this.onChange}
                                    id="formatted-numberformat-input"
                                    fullWidth
                                    InputProps={{
                                        inputComponent: TelMaskCustom,
                                    }}
                                    InputLabelProps={{
                                        shrink: true,
                                      }}
                
                                />
                            </Grid> */}
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField id="outlined-basic" label="事業所数" name="office" onChange={this.onChange} value={this.props.rent_m_corporates.office} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={6}>
                                <TextField id="outlined-basic" label="従業員数" name="employee" onChange={this.onChange} value={this.props.rent_m_corporates.employee} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>
                                <TextField id="outlined-basic" label="HP" name="url" onChange={this.onChange} value={this.props.rent_m_corporates.url} variant="outlined" fullWidth />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>

                                <TextField
                                    fullWidth
                                    id="outlined-multiline-static"
                                    name="vision"
                                    label="事業内容"
                                    multiline
                                    rows={4}
                                    value={this.props.rent_m_corporates.vision}
                                    onChange={this.onChange}
                                    variant="outlined"
                                />
                            </Grid>

                        </Grid>
                        



                            </CardContent>

                        </Card>




                    </ModalBody>
                        

                        {/* <Button onClick={this.handleClose}>閉じる</Button> */}
                        <ModalFooter>

                        <Button variant="contained" color="primary" onClick={()=>this.handleUpdate(this.props.rent_m_corporates.id, 0)} style={{backgroundColor:"#5cb85c", marginRight:5}}>保存</Button>　
                        {/* {this.props.rent_m_corporates.id ? <Button variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button>:""} */}

                        </ModalFooter>
                    {/* </Modal> */}






                    <Modal isOpen={this.state.modal_del} fade={false} className="modal-dialog-centered">
                        <ModalBody>
                            <div>本当に削除してよろしいですか？</div>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="contained" color="secondary" onClick={()=>this.handleUpdate(this.props.rent_m_corporates.id, 1)}>削除</Button>
                            <Button variant="contained" style={{marginLeft:3}} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
                        </ModalFooter>
                        
                    </Modal>

            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_corporates_top_open:state.corporation.rent_m_corporates_top_open,
        rent_m_corporates_detail_open:state.corporation.rent_m_corporates_detail_open,
        // m_corporations_list: state.corporation.m_corporations_list,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_m_corporates(state) {
            dispatch(Corporation.change_rent_m_corporates(state))
        },
        get_rent_m_corporates_zip_address(state) {
            dispatch(Corporation.get_rent_m_corporates_zip_address(state))
        },
        insert_rent_m_corporates(state) {
            dispatch(Corporation.insert_rent_m_corporates(state))
        },
        update_rent_m_corporates(state) {
            dispatch(Corporation.update_rent_m_corporates(state))
        },
        change_rent_m_corporates_top_open(state) {
            dispatch(Corporation.change_rent_m_corporates_top_open(state))
        },
        change_rent_m_corporates_detail_open(state) {
            dispatch(Corporation.change_rent_m_corporates_detail_open(state))
        },
        // get_m_corporations_list(state: any) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




