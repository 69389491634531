import React, { Component } from 'react';
import { ComposedChart, XAxis, YAxis, Tooltip, CartesianGrid, Bar } from 'recharts';
import { connect } from 'react-redux';


// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {

    rent_mypage_logs_graph: any,
}


interface State {

    // state types
}



class MypageLogGraphComponent extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        // this.onRowClick = this.onRowClick.bind(this);


    }
    componentWillMount() {

        // this.props.get_rent_customer_contacts(this.props.rent_response_id)
        // this.props.customerList()

    }




    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div>

                <ComposedChart　　//グラフ全体のサイズや位置、データを指定。場合によってmarginで上下左右の位置を指定する必要あり。
                    width={(window.innerWidth-160)/2}  //グラフ全体の幅を指定
                    height={300}  //グラフ全体の高さを指定
                    // layout="vertical" //グラフのX軸とY軸を入れ替え
                    data={this.props.rent_mypage_logs_graph}   //Array型のデータを指定
                    margin={{ top: 20, right: 10, bottom: 0, left: -20 }}  //marginを指定
                >
                    <XAxis  //X軸に関する設定
                        type="category" //データタイプをcategoryに変更
                        dataKey="time"  //Array型のデータの、Y軸に表示したい値のキーを指定
                        tickFormatter={(unixTime) => unixTime ? unixTime + "時" : ""}
                    />
                    <YAxis //Y軸に関する設定
                        type="number" //データタイプをnumberに変更。デフォルトではcategoryになっている
                        // domain={['dataMin 0', 'dataMax + 10']} //軸の表示領域を指定
                        domain={[0, 'dataMax + 10']} //軸の表示領域を指定
                        unit="件" // Y軸の単位
                    />
                    <Tooltip 
                        labelFormatter={(unixTime) => unixTime ? unixTime + "時" : ""}
                    />
                    <CartesianGrid  //グラフのグリッドを指定
                        stroke="#f5f5f5"  //グリッド線の色を指定
                    />
                    <Bar
                        dataKey="sum"
                        barSize={20}
                        stroke="rgba(34, 80, 162, 0.2)"
                        fillOpacity={1}
                        fill="#6da7e8"
                        unit="件" //単位
                        name="件数"
                        stackId="a"
                    />

                    {/* <Bar
                        dataKey="sum2"
                        barSize={20}
                        stroke="rgba(34, 80, 162, 0.2)"
                        fillOpacity={1}
                        fill="#ffa962"
                        unit="件" //単位
                        name="件数"
                        stackId="a"
                    /> */}
                </ComposedChart>
            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        rent_mypage_logs_graph:state.mypagelog.rent_mypage_logs_graph
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        // get_rent_customer_contacts(state:any) {
        //     dispatch(Contacts.get_rent_customer_contacts(state))
        // },


    };
}

export default connect(mapStateToProps, mapDispatchToProps)(MypageLogGraphComponent);