import React, { Component } from 'react';

import { connect } from 'react-redux';

import ReactDataGrid from 'react-data-grid';

import * as Entrusted from '../Entrusted/Saga/Entrusted';
import "react-datepicker/dist/react-datepicker.css";

import '../../Css/Table.css';
import './Css/Entrusted.css';
let autokana1;



// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};

    if (!values.name1) {
        errors.name1 = "名前を入力してください。";
    }
    if (!values.kana1) {
        errors.kana1 = "カナを入力してください。";
    }

    if (values.mail1) {
        if (!values.mail1.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.mail1 = "メールアドレスの形式が正しくありません。";
        }
    }
    if (values.mail2) {
        if (!values.mail2.match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/)) {
            errors.mail2 = "メールアドレスの形式が正しくありません。";
        }
    }

    if (values.tel1) {
        if (!values.tel1.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel1.match(/^\d{7,13}$/)) {
            errors.tel1 = "電話番号の形式が正しくありません。";
        }
    }

    if (values.tel2) {
        if (!values.tel2.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.tel2.match(/^\d{7,13}$/)) {
            errors.tel2 = "電話番号の形式が正しくありません。";
        }
    }

    if (values.fax) {
        if (!values.fax.match(/^0\d{1,4}-\d{1,4}-\d{3,4}$/) && !values.fax.match(/^\d{7,13}$/)) {
            errors.fax = "FAXの形式が正しくありません。";
        }
    }

    if (values.post) {
        //if (!values.post.match(/^\d{3}-\d{4}$/)) {
        //    errors.post = "郵便番号の形式が正しくありません。";
        //}
    }
    return errors;
};

// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, style, meta: { touched, error } }) => (
//     <div >
//         <input {...input} type={type} id={id} placeholder={placeholder} style={{ width: "80" }} className={touched && error ? 'alert-danger ' + className : className} />
//         {touched && error && <span className="text-danger">{error}</span>}
//     </div>
// );
const noFormatter = ({ value, ...props }) => {
    return ""
}

const columns = [{ key: 'selected', name: '選択', width: 70, formatter: noFormatter },
{ key: 'roomName', name: '部屋名', width: 100, editable: true }, {
    key: 'isPrint', name: '印刷', width: 70, formatter: noFormatter
}];
const rows = [{ id: 1, title: 'Title 1' }];
// const rowGetter = rowNumber => rows[rowNumber];

class RoomEditComponents extends Component {

    constructor(props) {
        super(props);

        this.state = {
            customerinfo: this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.getCellActions = this.getCellActions.bind(this);

    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }


    onChangeName(property, event) {

        if (property === "tatemonoKana") {
            this.props.change(property, autokana1.getFurigana())
        } else if (property === "kana2") {

        }


    }
    getRowCount() {
        let count = this.props.entrusted_rooms.length;
        if (this.state.refresh && count > 0) {
            count--; // hack for update data-grid
            this.setState({
                refresh: false
            });
        }

        return count;
    }

    refresh() {
        this.setState({
            refresh: true
        });
    }

    getCellActions(column, row) {

        if (column.key === "selected") {
            return [{
                icon: < div className="float-right" style={{ textAlign: "center", paddingTop: "5px", paddingRight: "-5px", width: "100px", height: "100px" }}>  {row.selected === 1 ? <i class="fas fa-check"></i> : ""}</div>,

                callback: () => {

                    let entrusted_rooms = this.props.entrusted_rooms
                    // for (var set = 0; set < entrusted_rooms.length; set++) {
                    //     if (row.id === entrusted_rooms[set].id) {
                    //         entrusted_rooms[set].selected = 0;
                    //     }
                    // }
                    let change_entrusted_rooms = entrusted_rooms.map((q) => {
                        if (row.id === q.id) return { ...q, selected: row.selected === 1 ? 0 : 1 };
                        return q;
                    })
                    // let change_entrusted_rooms = entrusted_rooms.map(function (value) {
                    //     if (row.id === value.id) {
                    //         value.selected = row.selected === 1 ? 0 : 1
                    //     }

                    //     return value

                    // })
                    this.props.get_entrusted_rooms_success(change_entrusted_rooms)
                    // this.refresh()

                }
            }
            ]
            // } else if (column.key === "selected" && row.selected === 0) {
            //     return [{
            //         icon: "",

            //         callback: () => {
            //             let entrusted_rooms = this.props.entrusted_rooms
            //             for (var set = 0; set < entrusted_rooms.length; set++) {
            //                 if (row.id === entrusted_rooms[set].id) {
            //                     entrusted_rooms[set].selected = 1;
            //                 }
            //             }

            //             this.props.get_entrusted_rooms_success(entrusted_rooms)
            //             // this.refresh()

            //         }
            //     }
            //     ]
        } else if (column.key === "isPrint") {
            return [{
                icon: < div className="float-right" style={{ textAlign: "center", paddingTop: "5px", paddingRight: "-5px", width: "100px", height: "100px" }}> {row.isPrint ? <i class="fas fa-print"></i> : ""}</div >,
                callback: () => {

                    let entrusted_rooms = this.props.entrusted_rooms
                    // for (var set = 0; set < entrusted_rooms.length; set++) {
                    //     if (row.id === entrusted_rooms[set].id) {
                    //         entrusted_rooms[set].isPrint = false;
                    //     }
                    // }
                    let change_entrusted_rooms = entrusted_rooms.map((q) => {
                        if (row.id === q.id) return { ...q, isPrint: row.isPrint ? false : true };
                        return q;
                    })
                    // let change_entrusted_rooms = entrusted_rooms.map(function (value) {
                    //     if (row.id === value.id) {
                    //         value.isPrint = row.isPrint ? false : true
                    //     }
                    //     return value

                    // })
                    this.props.get_entrusted_rooms_success(change_entrusted_rooms)
                    // this.refresh()
                }
            }
            ]

            // } else if (column.key === "isPrint" && row.isPrint === false) {
            //     return [{
            //         icon: "",

            //         callback: () => {
            //             let entrusted_rooms = this.props.entrusted_rooms
            //             // for (var set = 0; set < entrusted_rooms.length; set++) {
            //             //     if (row.id === entrusted_rooms[set].id) {
            //             //         entrusted_rooms[set].isPrint = true;
            //             //     }
            //             // }
            //             let change_entrusted_rooms = entrusted_rooms.map(function (value) {
            //                 if (row.id === value.id) {
            //                     return value.isPrint = true
            //                 } else {
            //                     return value
            //                 }
            //             })
            //             this.props.get_entrusted_rooms_success(change_entrusted_rooms)
            //             // this.refresh()


            //     }
            // }
            //         ]

        }
    }

    onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
        // console.log(updated)
        let rows = this.props.entrusted_rooms;

        for (let i = fromRow; i <= toRow; i++) {

            rows[i] = { ...this.props.entrusted_rooms[i], ...updated };
        }
        this.props.get_entrusted_rooms_success(rows);
        this.refresh()
        // this.forceUpdate();
        //   return this.props.rowss ;k

    };
    render() {


        //moment.locale('ja')
        return (
            <div className="roomedit">
                <ReactDataGrid
                    columns={columns}
                    rowGetter={i => this.props.entrusted_rooms[i]}
                    rowsCount={this.getRowCount()}
                    minHeight={200}
                    style={{ width: 200 }}
                    onGridRowsUpdated={this.onGridRowsUpdated}
                    enableCellSelect={true}
                    getCellActions={this.getCellActions}
                />
            </div>

        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        entrusted_rooms: state.entrusted.entrusted_rooms,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_entrusted_rooms_success(state) {
            dispatch(Entrusted.get_entrusted_rooms_success(state))
        },
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

// RoomEditComponents = reduxForm({
//     form: 'roomform', // a unique identifier for this form
//     validate//バリデーション
//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(RoomEditComponents)

export default connect(mapStateToProps, mapDispatchToProps)(RoomEditComponents);