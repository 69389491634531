import React, { Component } from 'react';
import { connect } from 'react-redux';
import Calendar from "./Calendar";
import * as Calendarsaga from './Saga/Calendar';
import Search from "./Search";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Backdrop from '@mui/material/Backdrop';
// import CircularProgress from '@mui/material/CircularProgress';
// import { compose } from 'redux'
// import { withStyles } from '@mui/material/styles';
// const styles = (theme) => ({

//   backdrop: {
//     // zIndex: theme.zIndex.drawer + 1,
//     zIndex: 20000,
//     color: '#fff',
//   },

// })
class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }
    this.props.get_calendar_tm_owners()
    this.props.get_calendar_search_store_list()
    this.props.get_calendar_user_details()
    this.props.get_users()
    this.props.get_tm_tatemono_list_master()

  }

  
  componentWillMount() {

    //this.props.dispatchRequest()
}


  render() {
    return (

      // <Card style={{display:'flex', justifyContent:"space-between"}} >



      <Card style={{ display: 'flex' }} >
        <CardContent style={{padding:0}} >
          <Search />
        </CardContent>
        {/* <CardContent style={{ margin: 10, height: window.innerHeight - 200 }}> */}
        <CardContent style={{ padding: 1, width: window.innerWidth - 170 }} >
          {this.props.user_details.amSectionId ? <Calendar /> : ""}
        </CardContent >

      </Card >

      // <div style={{display:'flex'}} >
      //   <div style={{backgroundColor:"white"}} >
      //     <Search />
      //   </div>
      //   <div style={{padding:1, backgroundColor:"white", width:"100%"}} >
      //     {this.props.user_details.amSectionId ? <Calendar /> : ""}
      //   </div >

      // </div >


    );
  }
};




const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    shops: state.masterReducer.shops,
    // users_select: state.masterReducer.users_select,

    // calendar_list: state.calendar.calendar_list,
    // calendar_list_form: state.calendar.calendar_list_form,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_search_store_list(state) {
      dispatch(Calendarsaga.get_calendar_search_store_list(state))
    },
    get_calendar_user_details(state) {
      dispatch(Calendarsaga.get_calendar_user_details(state))
    },
    get_users(state) {
      dispatch(Calendarsaga.get_users(state))
    },
    get_calendar_tm_owners(state) {
      dispatch(Calendarsaga.get_calendar_tm_owners(state))
    },
    get_tm_tatemono_list_master(state) {
      dispatch(Calendarsaga.get_tm_tatemono_list_master(state))
    },
    // change_calendar_list_form(state) {
    //   dispatch(Calendarsaga.change_calendar_list_form(state))
    // },
    // change_calendar_list(state) {
    //   dispatch(Calendarsaga.change_calendar_list(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Index);