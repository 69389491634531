import React, { Component } from 'react';

import { connect } from 'react-redux';

// import {customerNewDispSet,change_customeredit} from '../Customers/Saga/Customers';
// import {change_matching,get_intend_list,responseList} from './Saga/ResponseList';
// import {change_response_add_disp} from '../ResponseDetail/Saga/Responses';

import "react-datepicker/dist/react-datepicker.css";
import { Field, reduxForm, submit } from 'redux-form';
import '../../Css/Table.css';
//import ResponseAddComponent from './ResponseAddComponent';
import { withRouter } from 'react-router';
import DatePicker from "react-datepicker";


const ReduxFormDateRange = (props) => {


    console.log(props)
    return (

        <DatePicker

            selected={props.input.value !== "" ? new Date(props.input.value) : ""}
            //selected={moment(props.input.value, "YYYY-MM-DD").format("YYYY-MM-DD") || null}
            onChange={props.input.onChange}
            //showTimeSelect

            dateFormat="yyyy/MM/dd"
            className="form-control"
        />
    )
}


class entrustedoutput extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            modalselect: false,
            modalcalender: false,

        };

        // this.toggle = this.toggle.bind(this);
        //this.toggleselect = this.toggleselect.bind(this);
        // this.props.get_intend_list();
        // this.props.initialize({kanriCorpNo:1});//フォームに初期値を反映させる

    }

    componentDidMount() {

        //  this.props.responseList({sectionId:this.props.masterReducer.user_details.amSectionId})
    }







    render() {

        const { handleSubmit } = this.props;


        return (
            <div>
                <form onSubmit={handleSubmit}  >
                    {/* <table>

                  <tbody>

                      <tr >

                              <td colSpan="2" > */}
                    <div className="form-inline">
                        開始日<Field
                            name="start"
                            id="start"
                            component={ReduxFormDateRange}
                            type="text"
                            placeholder="開始日"
                            className="form-control"
                        />
～

                                  終了日<Field
                            name="end"
                            id="end"
                            component={ReduxFormDateRange}
                            type="text"
                            placeholder="終了日"
                            className="form-control"
                        />


                        {/* <button
                                      type="submit"
                                      className="btn btn-primary "
                                      >印刷</button>
                                          */}
                    </div>
                    {/* </td>
                      </tr>
                     
                  </tbody>
              </table> */}



                </form>




            </div>
        );
    }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


entrustedoutput = connect(
    ({ masterReducer, responselist }) => ({ masterReducer, responselist }),
    // state => ({
    //   mapsearch: state.mapsearchReducer,

    // }),
    {
        // customerNewDispSet,
        // change_customeredit,
        // change_matching,
        // get_intend_list,
        // responseList,
        // change_response_add_disp,
    }

)(entrustedoutput)



entrustedoutput = reduxForm({
    form: 'entrustedoutputform', // a unique identifier for this form
    // onSubmit: submit
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(entrustedoutput)

export default withRouter(entrustedoutput)