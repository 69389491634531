// import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
// import thunk from 'redux-thunk';
// import { connectRouter } from 'connected-react-router'
// import logger from 'redux-logger';
// // import rootSaga from './sagaes';
// import { createBrowserHistory } from 'history'
// import { routerMiddleware } from 'connected-react-router'
// import createSagaMiddleware from 'redux-saga'
var moment = require('moment-timezone');




export function check(checkset: any) {
    console.log(checkset)
    // alert("check")
    // alert("aaa")

    const rent_contract_details = checkset.rent_contract_details
    let rent_contract_monies = checkset.rent_contract_monies.filter((a: any) => a.rent_m_account_id !== -1)
    // let rent_contract_monies_1 = checkset.rent_contract_monies.filter((a: any) => a.account_group === 1)
    // let estimateMonies2 = checkset.estimateMonies2
    // let estimateMonies3 = checkset.estimateMonies3
    // let estimateMonies4 = checkset.estimateMonies4
    // let estimateMonies5 = checkset.estimateMonies5
    const master = checkset.master
    const rent_contract_boshu_chinryou = checkset.rent_contract_boshu_chinryou
    const rent_contract_checks = checkset.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === rent_contract_details.rent_m_contract_type_id)


    let contract_check_message = ""

    const onemonth_amount = rent_contract_monies.filter((a: any) => a.account_group === 1 && a.rent_m_account_id === 1)[0] ? rent_contract_monies.filter((a: any) => a.account_group === 1 && a.rent_m_account_id === 1)[0].amount : 0





    console.log(rent_contract_monies)


    rent_contract_monies.map(function (value: any) {
        value.amount = value.amount_month > 0 && value.rent_m_account_id != 12 ? onemonth_amount * value.amount_month : value.amount
        value.tax_amount = (value.tax === 1 || value.tax === true) ? Math.round(value.amount * 0.1) : 0
        value.rent_m_etc_id = value.rent_m_etc_id ? value.rent_m_etc_id : -1
        value.check_amount = 0
        value.check_remarks = ""
        value.billing = rent_contract_details.type ? value.billing : 0
        return value
    })

    console.log("小計")

    // rent_contract_monies = rent_contract_monies.concat({
    //     rent_contract_id: 0,
    //     rent_m_account_id: -1,
    //     name: "小計",
    //     display_name: 0,
    //     billing: 0,
    //     amount: rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + (x.is_free_rent === 0 ? parseInt(x.amount) : 0), 0),
    //     amount_month: 0,
    //     each_month: 0,
    //     each_year: 0,
    //     account_group: 5,
    //     tax: 0,
    //     tax_amount: rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + (x.is_free_rent !== 1 ? parseInt(x.tax_amount) : 0), 0),
    //     rent_m_etc_id: 0,
    //     is_daily_rate: 0,
    //     is_free_rent: 0,
    //     works: 0,
    //     sales_amount: 0,
    //     remarks: null,
    //     created_user: 0,
    //     created_at: 0,
    //     updated_at: 0,
    //     updated_user: 0,
    // })


    console.log(rent_contract_monies)
    // const tyukaitotal = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + ((x.rent_m_account_id === 1 || x.rent_m_account_id === 4 || x.rent_m_account_id === 5 || x.rent_m_account_id === 6) ? parseInt(x.amount) : 0), 0)

    const tyukai_total = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + ((x.rent_m_account_id === 1) ? (x.amount ? parseInt(x.amount) : 0) : 0), 0)

    const tyukai_parking_total = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + ((x.rent_m_account_id === 4 || x.rent_m_account_id === 5 || x.rent_m_account_id === 6) ? (x.amount ? parseInt(x.amount) : 0) : 0), 0)


    rent_contract_monies.filter((a: any) => a.account_group !== 5).map(function (value: any) {
        console.log(value)
        // console.log(boshu.moniesViewModel.filter((a: any) => a.rent_m_account_id === value.rent_m_account_id)[0])

        if (value.rent_m_account_id === 12 && (value.amount + value.tax_amount) !== Math.round(tyukai_total * 1.1)) {
            value.check_amount = 1  //仲介手数料と相違
            value.check_remarks = "仲介手数料が" + tyukai_total + "(税" + Math.round(tyukai_total * 0.1) + ")円と相違しています"  //仲介手数料と相違
            contract_check_message += "仲介手数料が" + tyukai_total + "(税" + Math.round(tyukai_total * 0.1) + ")円と相違しています<br/>"

            return value
        } else if (value.rent_m_account_id === 58 && (value.amount + value.tax_amount) !== Math.round(tyukai_parking_total * 1.1)) {
            value.check_amount = 1  //仲介手数料と相違
            value.check_remarks = "駐車場仲介手数料が" + tyukai_parking_total + "(税" + Math.round(tyukai_parking_total * 0.1) + ")円と相違しています"  //仲介手数料と相違
            contract_check_message += "駐車場仲介手数料が" + tyukai_parking_total + "(税" + Math.round(tyukai_parking_total * 0.1) + ")円と相違しています<br/>"

            return value

        } else if (rent_contract_boshu_chinryou.filter((a: any) => a.chinryou_koumoku_no === value.rent_m_account_id)[0]) {
            let boshu_chinryou = rent_contract_boshu_chinryou.filter((a: any) => a.chinryou_koumoku_no === value.rent_m_account_id)[0].seikyuu_gaku + (rent_contract_boshu_chinryou.filter((a: any) => a.chinryou_koumoku_no === value.rent_m_account_id)[0].is_kazei ? Math.round(rent_contract_boshu_chinryou.filter((a: any) => a.chinryou_koumoku_no === value.rent_m_account_id)[0].seikyuu_gaku * 0.1) : 0)
            if ((value.amount + value.tax_amount) !== boshu_chinryou && value.rent_m_account_id !== 11) {
                value.check_amount = 2  //募集賃料と相違
                value.check_remarks = "募集賃料" + boshu_chinryou + "円と相違しています<br/>"  //募集賃料と相違

                return value
            }
        } else {
            return value
        }
    })


    if (tyukai_parking_total > 0 && rent_contract_monies.filter((a: any) => a.rent_m_account_id === 58).length === 0) {

        contract_check_message += "駐車場仲介手数料を設定してください<br/>"
    }


    // console.log(state.estimates.rentMContractTypeId)
    //     alert()

    // alert(rent_contract_checks[0].rate)

    let contract_penalty = rent_contract_details.counting == -1 || rent_contract_details.counting == 2 ? 1 : 4
    rent_contract_checks.map(function (value: any) {
        // if(value.category===1){
        //     if(estimateMonies1.filter((a:any)=>a.rentMAccountId===value.accountId)[0]){

        //         contract_check_message += state.masterReducer.tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.accountId)[0].chinryouKoumokuName
        //         alert(state.masterReducer.tm_chinryou_koumokus.filter((a:any)=>a.chinryouKoumokuNo===value.accountId)[0].chinryouKoumokuName)


        //     }else{

        //     }
        //     }

        let estimateMonies = []
        if (value.category === 6) {//違約金


            contract_penalty = value.account_id

        } else if (value.category === 7) {//フリーレント
            if ((rent_contract_monies.filter((a: any) => a.account_group === 1 && (a.rent_m_account_id === 1 || a.rent_m_account_id === 2 || a.rent_m_account_id === 4 || a.rent_m_account_id === 5 || a.rent_m_account_id === 6) && a.amount !== 0 && a.is_free_rent === 0).length +
                rent_contract_monies.filter((a: any) => a.account_group === 5 && (a.rent_m_account_id === 1 || a.rent_m_account_id === 2 || a.rent_m_account_id === 4 || a.rent_m_account_id === 5 || a.rent_m_account_id === 6) && a.amount !== 0 && a.is_free_rent === 0).length)
                > 0) {
                contract_check_message += "初回賃料がフリーレントになっていません<br/>"

                // estimateMonies.filter((a:any)=>a.rentMAccountId===value.accountId)[0].checkAmount=1  
                // estimateMonies.filter((a:any)=>a.rentMAccountId===value.accountId)[0].checkRemarks="初回賃料がフリーレントになっていません。" 
            }



        } else {


            // let monthtotal = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + (x.rent_m_account_id !== -1 ? parseInt(x.amount + x.tax_amount) : 0), 0)

            // if (value.account_id === 56) {
            let monthtotal = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + (x.rent_m_account_id !== -1 && x.rent_m_account_id !== 56 ? parseInt(x.amount + x.tax_amount) : 0), 0)
            // }





            if (rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0]) {

                if (value.base === 2) {
                    contract_check_message += master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を削除してください<br/>"
                } else if (value.base === 5) {
                    if (rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0].rent_m_etc_id !== 3) {
                        contract_check_message += master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を無しにしてください<br/>"
                        rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0].check_amount = 1
                        rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0].check_remarks = master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を無しにしてください"
                    }
                } else if (value.base === 4) {///込み

                    if (rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0].rent_m_etc_id !== 2) {
                        contract_check_message += master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を込みにしてください<br/>"
                    }
                } else if (value.base === 1) {


                    let amount = rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0].amount
                    if (value.rate > 0) {
                        amount = Math.round(monthtotal * value.rate / 100)
                    }

                    if (value.max_amount > 0) {
                        amount = amount > value.max_amount ? value.max_amount : amount
                    }
                    if (value.min_amount > 0) {
                        amount = amount < value.min_amount ? value.min_amount : amount
                    }

                    if (value.fix_amount > 0) {
                        amount = value.fix_amount
                    }


                    if (amount !== rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0].amount) {
                        contract_check_message += master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "が違います" + amount + "円<br/>"
                        rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0].checkAmount = 1
                        rent_contract_monies.filter((a: any) => a.account_group === value.category && a.rent_m_account_id === value.account_id)[0].checkRemarks = master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "が違います" + amount + "円"
                    }


                }
            } else {

                if (value.base === 1) {




                    contract_check_message += master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName + "を設定してください<br/>"
                }


            }
        }
    })


    // rent_contract_monies = rent_contract_monies.concat({
    //     rentContractId: 0,
    //     rentMAccountId: -1,
    //     name: "小計",
    //     displayName: 0,
    //     billing: 0,
    //     amount: rent_contract_monies.filter((a: any) => a.account_group === 2).reduce((p: any, x: any) => p + (x.is_free_rent === 0 ? parseInt(x.amount) : 0), 0),
    //     amountMonth: 0,
    //     eachMonth: 0,
    //     eachYear: 0,
    //     accountGroup: 5,
    //     tax: 0,
    //     taxAmount: rent_contract_monies.filter((a: any) => a.account_group === 2).reduce((p: any, x: any) => p + (x.is_free_rent === 0 ? parseInt(x.tax_amount) : 0), 0),
    //     rentMEtcId: 0,
    //     isDailyRate: 0,
    //     isFreeRent: 0,
    //     works: 0,
    //     salesAmount: 0,
    //     remarks: null,
    //     createdUser: 0,
    //     createdAt: 0,
    //     updatedAt: 0,
    //     updatedUser: 0,
    // })




    // rent_contract_monies = rent_contract_monies.concat({
    //     rentContractId: 0,
    //     rentMAccountId: -1,
    //     name: "小計",
    //     displayName: 0,
    //     billing: 0,
    //     amount: rent_contract_monies.filter((a: any) => a.account_group === 5).reduce((p: any, x: any) => p + (x.is_free_rent === 0 ? parseInt(x.amount) : 0), 0),
    //     amountMonth: 0,
    //     eachMonth: 0,
    //     eachYear: 0,
    //     accountGroup: 5,
    //     tax: 0,
    //     taxAmount: rent_contract_monies.filter((a: any) => a.account_group === 5).reduce((p: any, x: any) => p + (x.is_free_rent === 0 ? parseInt(x.tax_amount) : 0), 0),
    //     rentMEtcId: 0,
    //     isDailyRate: 0,
    //     isFreeRent: 0,
    //     works: 0,
    //     salesAmount: 0,
    //     remarks: null,
    //     createdUser: 0,
    //     createdAt: 0,
    //     updatedAt: 0,
    //     updatedUser: 0,
    // })

    // alert(JSON.stringify(rent_contract_details))
    // return []

    if ((rent_contract_details.net_corp_no === 1 || rent_contract_details.net_corp_no === 2 || rent_contract_details.net_corp_no === 368) && rent_contract_details.tatemono_id !== "2114") {//初回ネット手数料

        if (rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 63)[0]) {
            if (rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 63)[0].amount + rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 63)[0].tax_amount !== 5500)
                contract_check_message += "初回ネット手数料の金額が違います<br/>"
        } else {
            contract_check_message += "初回ネット手数料を上げてください<br/>"
        }
    }


    if ((',' + rent_contract_details.room_setsubi + ',').indexOf(',155,') !== -1) {

        if (rent_contract_monies.filter((a: any) => a.account_group === 1 && a.rent_m_account_id === 7)[0]) {
            contract_check_message += "浄水器設置不可の物件です<br/>"
        }
    }

    if (rent_contract_details.kaketsuke) {
        if (rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 22)[0]) {
            contract_check_message += "かけつけ加入の物件です<br/>"
        }
    }
    // if(state.estimates.boshu.penalty){
    let room_penalty = 0
    let penalty = contract_penalty

    // alert(rent_contract_details.contract_penalty)
    if (contract_penalty !== 1) {//違約金で建物情報のあるものの違約金は募集条件を優先
        if (rent_contract_details.counting == 1 || rent_contract_details.counting == 0) {
            room_penalty = !rent_contract_details.room_penalty ? 4 : rent_contract_details.room_penalty;
            penalty = master.m_penalties.filter((a: any) => a.id === room_penalty)[0].penaltyOrder > master.m_penalties.filter((a: any) => a.id === contract_penalty)[0].penaltyOrder ? room_penalty : contract_penalty; //一年未満違約金より厳しいもの優先

        } else {
            penalty = !rent_contract_details.room_penalty ? (rent_contract_details.contract_penalty ? 1 : rent_contract_details.contract_penalty) : rent_contract_details.room_penalty;
        }


    }
    // alert(contractPenalty)
    // alert(penalty)
    if (rent_contract_details.contract_penalty !== penalty) {
        contract_check_message += "違約金を" + master.m_penalties.filter((a: any) => a.id === penalty)[0].penaltyName + "に設定してください<br/>";
    }
    // }


    let checked: any = []
    checked.rent_contract_monies = rent_contract_monies
    checked.contract_check_message = contract_check_message
    console.log(checked)
    // alert(1)
    // alert(checked.length)
    return checked



    // yield put(hiwariset())

    // yield put(change_contract_check_message(contract_check_message))


    // yield put(change_estimate_month_flg(true))

    // yield put(change_estimate_onetime_flg(true))
    // yield put(change_estimate_after_flg(true))

    // yield put(change_estimate_sum_flg(true))
}




//契約形態の内容を反映
export function set_rent_contract_checks(checkset: any) {

    let rent_contract_details = checkset.rent_contract_details
    let rent_contract_monies = checkset.rent_contract_monies.filter((a: any) => a.rent_m_account_id !== -1)
    const master = checkset.master
    const rent_contract_boshu_chinryou = checkset.rent_contract_boshu_chinryou
    // alert(rent_contract_details.rent_m_contract_type_id)
    const rent_contract_checks = checkset.rent_contract_checks.filter((a: any) => a.rent_m_contract_type_id === rent_contract_details.rent_m_contract_type_id)


    let rent_contract_monies_set: any = []
    if (rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 1)[0]) {
        rent_contract_monies_set.push({
            rent_contract_id: 0,
            rent_m_account_id: 1,
            name: "賃料",
            display_name: "賃料",
            billing: 0,
            amount: 0,
            amount_month: 0,
            each_month: 0,
            each_year: 0,
            account_group: 2,
            tax: 0,
            tax_amount: 0,
            rent_m_etc_id: -1,
            is_daily_rate: 0,
            is_free_rent: 0,
            works: 0,
            sales_amount: 0,
            remarks: null,
            created_user: 0,
            created_at: null,
            updated_at: null,
            updated_user: 0,
        })
    }

    let contract_penalty = (rent_contract_details.counting == -1 || rent_contract_details.counting == 2) ? 1 : 4
    let is_free_rent_set = false

    rent_contract_checks.map(function (value: any) {




        if (value.category === 6) {//違約金


            contract_penalty = value.account_id
        }

        if (value.category === 7) {//違約金


            is_free_rent_set = true
        }

        // let monthtotal = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + (x.rent_m_account_id !== -1 ? parseInt(x.amount + x.tax_amount) : 0), 0)
        // // alert(monthtotal)
        // if (value.account_id === 56) {
        let monthtotal = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + (x.rent_m_account_id !== -1 && x.rent_m_account_id !== 56 ? parseInt(x.amount + x.tax_amount) : 0), 0)
        // alert(monthtotal)
        // }


        if (rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0]) {

            if (value.base === 2) {
                rent_contract_monies = rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id !== value.account_id)

            } else if (value.base === 5) {//無し


                // rent_contract_monies_set.push({
                //     rent_contract_id: value.rent_contract_id,
                //     rent_m_account_id: value.rent_m_account_id,
                //     name: value.name,
                //     display_name: value.display_name,
                //     billing: 0,
                //     amount: 0,
                //     amount_month: 0,
                //     each_month: 0,
                //     each_year: 0,
                //     account_group: value.account_group,
                //     tax: value.tax,
                //     tax_amount: 0,
                //     rent_m_etc_id: 3,
                //     is_daily_rate: value.is_daily_rate,
                //     is_free_rent: value.is_free_rent,
                //     works: value.works,
                //     sales_amount: value.sales_amount,
                //     remarks: value.remarks,
                //     created_user: 0,
                //     created_at: null,
                //     updated_at: null,
                //     updated_user: 0,
                // })


                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].rent_m_etc_id = 3
                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount = 0
                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].tax_amount = 0
                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount_month = 0
                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].each_year = 0
            } else if (value.base === 4) {//込み
                // rent_contract_monies_set.push({
                //     rent_contract_id: value.rent_contract_id,
                //     rent_m_account_id: value.rent_m_account_id,
                //     name: value.name,
                //     display_name: value.display_name,
                //     billing: 0,
                //     amount: 0,
                //     amount_month: 0,
                //     each_month: 0,
                //     each_year: 0,
                //     account_group: value.account_group,
                //     tax: value.tax,
                //     tax_amount: 0,
                //     rent_m_etc_id: 2,
                //     is_daily_rate: value.is_daily_rate,
                //     is_free_rent: value.is_free_rent,
                //     works: value.works,
                //     sales_amount: value.sales_amount,
                //     remarks: value.remarks,
                //     created_user: 0,
                //     created_at: null,
                //     updated_at: null,
                //     updated_user: 0,
                // })


                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].rent_m_etc_id = 2
                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount = 0
                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].tax_amount = 0
                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount_month = 0
                rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].each_year = 0
            } else if (value.base === 1) {
                let amount = 0
                if (value.rate > 0) {
                    amount = Math.round(monthtotal * value.rate / 100)

                }
                if (value.max_amount > 0) {
                    amount = (amount > 0 ? amount : rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount) > value.max_amount ? value.max_amount : amount ? amount : rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount
                }

                if (value.min_amount > 0) {
                    amount = (amount > 0 ? amount : rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount) < value.min_amount ? value.min_amount : amount ? amount : rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount
                }

                if (value.fix_amount > 0) {

                    amount = value.fix_amount
                }

                if (rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0]) {

                    rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount = amount

                } else {

                    rent_contract_monies.push({
                        rent_contract_id: value.rent_contract_id,
                        rent_m_account_id: value.rent_m_account_id,
                        name: value.name,
                        display_name: value.display_name,
                        billing: value.billing,
                        amount: amount,
                        amount_month: value.amount_month,
                        each_month: value.each_month,
                        each_year: value.each_year,
                        account_group: value.account_group,
                        tax: value.tax,
                        tax_amount: value.tax_amount,
                        rent_m_etc_id: value.rent_m_etc_id,
                        is_daily_rate: value.is_daily_rate,
                        is_free_rent: value.is_free_rent,
                        works: value.works,
                        sales_amount: value.sales_amount,
                        remarks: value.remarks,
                        created_user: 0,
                        created_at: null,
                        updated_at: null,
                        updated_user: 0,
                    })
                }
            }




        } else {
            if (value.base === 1) {


                let amount = 0
                if (value.rate > 0) {
                    amount = Math.round(monthtotal * value.rate / 100)

                }
                if (value.max_amount > 0) {
                    amount = amount > value.max_amount ? value.max_amount : amount
                }
                if (value.min_amount > 0) {
                    amount = amount < value.min_amount ? value.min_amount : amount
                }

                if (value.fix_amount > 0) {

                    amount = value.fix_amount
                }


                if (rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0]) {

                    rent_contract_monies.filter((a: any) => a.account_group !== 5).filter((a: any) => a.rent_m_account_id === value.account_id)[0].amount = amount

                } else {
                    rent_contract_monies.push({
                        rent_contract_id: 0,
                        rent_m_account_id: value.account_id,
                        name: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName,
                        display_name: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].chinryouKoumokuName,
                        billing: 0,
                        amount: amount,
                        amount_month: 0,
                        each_month: 0,
                        each_year: value.each_year,
                        account_group: value.category,
                        tax: value.tax,
                        tax_amount: 0,
                        rent_m_etc_id: -1,
                        is_daily_rate: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.account_id)[0].isHiwari ? 1 : 0,
                        is_free_rent: 0,
                        works: 0,
                        sales_amount: 0,
                        remarks: null,
                        created_user: 0,
                        created_at: null,
                        updated_at: null,
                        updated_user: 0,
                    })
                }

            } else {


            }
        }










    })

    // alert(rent_contract_monies.filter((a: any) => a.rent_m_account_id === 56)[0].amount)

    // alert(contract_penalty)

    // alert(contract_penalty)


    rent_contract_monies = rent_contract_monies.map(function (value: any) {
        if (value.account_group < 5 && rent_contract_checks.filter((a: any) => a.account_id === value.rent_m_account_id)[0]) {
            if (rent_contract_checks.filter((a: any) => a.account_id === value.rent_m_account_id)[0].base !== 2) {

                return value
            }



        } else {


            return value
        }

    })


    if ((rent_contract_details.net_corp_no === 1 || rent_contract_details.net_corp_no === 2 || rent_contract_details.net_corp_no === 368) && rent_contract_details.tatemono_id !== "2114") {//初回ネット手数料

        if (rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 63)[0]) {
            if (rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 63)[0].amount !== 5000) {
                rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 63)[0].amount = 5000
                rent_contract_monies.filter((a: any) => a.account_group === 2 && a.rent_m_account_id === 63)[0].tax_amount = 500

            }
        } else {
            rent_contract_monies.push({
                rent_contract_id: 0,
                rent_m_account_id: 63,
                name: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === 63)[0].chinryouKoumokuName,
                display_name: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === 63)[0].chinryouKoumokuName,
                billing: 0,
                amount: 5000,
                amount_month: 0,
                each_month: 0,
                each_year: 0,
                account_group: 2,
                tax: 1,
                tax_amount: 500,
                rent_m_etc_id: -1,
                is_daily_rate: 0,
                is_free_rent: 0,
                works: 0,
                sales_amount: 0,
                remarks: null,
                created_user: 0,
                created_at: null,
                updated_at: null,
                updated_user: 0,
            })


        }
    }



    const tyukai_total = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + ((x.rent_m_account_id === 1) ? (x.amount ? parseInt(x.amount) : 0) : 0), 0)

    const tyukai_parking_total = rent_contract_monies.filter((a: any) => a.account_group === 1).reduce((p: any, x: any) => p + ((x.rent_m_account_id === 4 || x.rent_m_account_id === 5 || x.rent_m_account_id === 6) ? (x.amount ? parseInt(x.amount) : 0) : 0), 0)



    if (!rent_contract_monies.filter((a: any) => a.rent_m_account_id === 58).length) {
        rent_contract_monies.push({
            rent_contract_id: 0,
            rent_m_account_id: 58,
            name: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === 58)[0].chinryouKoumokuName,
            display_name: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === 58)[0].chinryouKoumokuName,
            billing: 0,
            amount: tyukai_parking_total,
            amount_month: 0,
            each_month: 0,
            each_year: 0,
            account_group: 2,
            tax: 1,
            tax_amount: Math.round(tyukai_parking_total * 0.1),
            rent_m_etc_id: -1,
            is_daily_rate: 0,
            is_free_rent: 0,
            works: 0,
            sales_amount: 0,
            remarks: null,
            created_user: 0,
            created_at: null,
            updated_at: null,
            updated_user: 0,
        })

    }





    let max = rent_contract_monies.reduce((prev: any, current: any) => {
        return (prev.key ? prev.key : 0) > (current.key ? current.key : 0) ? (prev.key ? prev.key : 0) : (current.key ? current.key : 0);
    }, 0);


    rent_contract_monies = rent_contract_monies.map(function (value: any) {
        max = max + 1
        if (!value.key) {
            value.key = max
        }
        return value
    })


    rent_contract_monies = set_hiwari(rent_contract_details, rent_contract_monies, master)






    if ((',' + rent_contract_details.room_setsubi + ',').indexOf(',155,') !== -1) {//浄水器取付不可
        rent_contract_monies = rent_contract_monies.filter((a: any) => a.rent_m_account_id !== 7)
        // rent_contract_monies = rent_contract_monies.map(function (value: any) {

        //     if (value.rent_m_account_id !== 7) {
        //         return value
        //     }

        // })
    }





    if (rent_contract_details.kaketsuke) {
        rent_contract_monies = rent_contract_monies.filter((a: any) => a.rent_m_account_id !== 22)
        // rent_contract_monies = rent_contract_monies.map(function (value: any) {//かけつけ加入物件

        //     if (value.rent_m_account_id !== 22) {
        //         return value
        //     }

        // })

    }

    console.log(rent_contract_monies)



    // let room_penalty = 0
    // let penalty = contract_penalty
    // // alert(rent_contract_details.contract_penalty)
    // if (contract_penalty !== 1) {//違約金で建物情報のあるものの違約金は募集条件を優先
    //     room_penalty = !rent_contract_details.room_penalty ? 4 : rent_contract_details.room_penalty;


    //     penalty = master.m_penalties.filter((a: any) => a.id === room_penalty)[0].penaltyOrder > master.m_penalties.filter((a: any) => a.id === contract_penalty)[0].penaltyOrder ? room_penalty : contract_penalty; //一年未満違約金より厳しいもの優先
    // }

    let room_penalty = 0
    let penalty = room_penalty

    // if (rent_contract_details.room_penalty) {
    if (contract_penalty !== 1) {//違約金で建物情報のあるものの違約金は募集条件を優先
        if (rent_contract_details.counting == 1 || rent_contract_details.counting == 0) {
            room_penalty = !rent_contract_details.room_penalty ? 4 : rent_contract_details.room_penalty;
            penalty = master.m_penalties.filter((a: any) => a.id === room_penalty)[0] && master.m_penalties.filter((a: any) => a.id === room_penalty)[0].penaltyOrder > master.m_penalties.filter((a: any) => a.id === contract_penalty)[0].penaltyOrder ? room_penalty : contract_penalty; //一年未満違約金より厳しいもの優先

        } else if (rent_contract_details.counting == 2 || rent_contract_details.counting == -1) {

            penalty = !rent_contract_details.room_penalty ? (rent_contract_details.contract_penalty ? 1 : rent_contract_details.contract_penalty) : rent_contract_details.room_penalty;
        }

        contract_penalty = penalty
    }
    // }

    rent_contract_details.contract_penalty = contract_penalty


    if (is_free_rent_set !== false) {//フリーレント
        rent_contract_monies = rent_contract_monies.filter(function (value: any) {
            if (value.rent_m_account_id === 1 || value.rent_m_account_id === 2 || value.rent_m_account_id === 4 || value.rent_m_account_id === 5 || value.rent_m_account_id === 6) {
                value.is_free_rent = 1
            }
            return value
        })

    }




    rent_contract_monies = rent_contract_monies.filter(function (value: any) {

        if (value.rent_m_account_id === 12) {
            value.amount = tyukai_total
            value.tax = 1
            value.tax_amount = Math.round(tyukai_total * 0.1)
        }

        if (value.rent_m_account_id === 58) {
            value.amount = tyukai_parking_total
            value.tax = 1
            value.tax_amount = Math.round(tyukai_parking_total * 0.1)
        }
        return value


    })

    // alert(rent_contract_monies.filter((a: any) => a.rent_m_account_id === 56)[0].amount)

    let changed: any = []
    changed.rent_contract_monies = rent_contract_monies
    changed.rent_contract_details = rent_contract_details
    console.log(changed)

    return changed

}







export function set_hiwari(rent_contract_details: any, rent_contract_monies: any, master: any) {
    //日割り

    let max = rent_contract_monies.filter((a: any) => a.account_group !== 5).reduce((prev: any, current: any) => {
        return prev.key > current.key ? prev.key : current.key;
    }, 0);


    let hiwariset: any = []
    if (moment(rent_contract_details.contract_date).format('D') != 1) {
        rent_contract_monies.filter((a: any) => a.account_group === 1).map(function (value: any) {




            if (value.rent_m_account_id != 7 && value.rent_m_account_id != 46 && value.rent_m_account_id) {
                // if (value.key) {
                //     max = value.key
                // } else {
                max = max + 1
                // }
                hiwariset.push({
                    key: max,
                    rent_contract_id: 0,
                    rent_m_account_id: value.rent_m_account_id,
                    name: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rent_m_account_id)[0] ? master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rent_m_account_id)[0].chinryouKoumokuName : "",
                    display_name: master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rent_m_account_id)[0] ? master.tm_chinryou_koumokus.filter((a: any) => a.chinryouKoumokuNo === value.rent_m_account_id)[0].chinryouKoumokuName : "",
                    billing: rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id).length ? rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0].billing : 0,

                    amount: (rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id).length ? rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0].is_daily_rate : value.is_daily_rate) ? Math.round(value.amount * (moment(rent_contract_details.contract_date).endOf("month").date() - moment(rent_contract_details.contract_date).date() + 1) / moment(rent_contract_details.contract_date).endOf('month').date()) : value.amount,
                    amount_month: value.amount_month,
                    each_month: value.each_month,
                    each_year: value.each_year,
                    account_group: 5,
                    tax: value.tax,
                    tax_amount: value.tax_amount,
                    rent_m_etc_id: value.rent_m_etc_id ? value.rent_m_etc_id : - 1,
                    is_daily_rate: value.rent_m_account_id != 8 ? rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id).length ? rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0].is_daily_rate : (value.rent_m_account_id ? master.tm_chinryou_koumoku.filter((a: any) => a.rent_m_account_id === value.rent_m_account_id)[0].is_daily_rate : 0) : 0,
                    is_free_rent: rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id).length ? rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0].is_free_rent : 0,
                    works: rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id).length ? rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0].works : 0,
                    sales_amount: value.sales_amount,
                    remarks: rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id).length ? rent_contract_monies.filter((a: any) => a.account_group === 5 && a.rent_m_account_id === value.rent_m_account_id)[0].remarks : "",
                    created_user: 0,
                    created_at: null,
                    updated_at: null,
                    updated_user: 0,
                })


            }


        })
    }

    rent_contract_monies = rent_contract_monies.filter((a: any) => a.account_group !== 5).concat(hiwariset)

    return rent_contract_monies
}