import React, { Component,forwardRef } from 'react';
import { connect } from 'react-redux';
import * as Calendarsaga from './Saga/Calendar';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import DateTimeComponent from "./DateTimeComponent";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import MaskedInput from 'react-text-mask';
// import './Css/Grid.css'
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import * as ContractFollow from '../ContractFollow/Saga/ContractFollow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MaterialSelect from '@mui/material/Select';
import { Modal, Row, Col, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { Radio, RadioGroup } from '@mui/material';
import * as Responses from '../ResponseDetail/Saga/Responses';
import * as Contracts from '../Contracts/Saga/Contracts';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

import Event from '@mui/icons-material/Event';
import Chip from '@mui/material/Chip';


var moment = require('moment-timezone');
let columns = []

function timeMask(value) {
  const chars = value.split('');
  const hours = [
    /[0-2]/,
    chars[0] === '2' ? /[0-3]/ : /[0-9]/,
  ];
  const minutes = [/[0-5]/, /[0-9]/];

  return hours.concat(':').concat(minutes);
}


const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref}
      mask={timeMask}
      showMask
    />
  );
});
class Editor extends Component {
  constructor(props) {
    super(props);



    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,

      cardx: window.innerWidth - 600,
      colSortDirs: {},
      focus_start: false,
      focus_end: false,
      validation: false,
      validation_text: '',
      // ownshop: this.props.users.filter(a => a.amSectionId === this.props.contract_follow_editer.section_id && a.id === this.props.contract_follow_editer.user_id)[0] ? true : false,
      ownshop: this.props.users.filter(a => a.amSectionId === this.props.user_details.amSectionId && a.id === this.props.user_details.id)[0] ? true : false,

      del : false,
      
      start_at : moment(this.props.rent_task_detail.start_at).format('HH:mm'),
      end_at : moment(this.props.rent_task_detail.end_at).format('HH:mm'),
      start_at_err: false,
      end_at_err: false,
      jibusyo_only: true,
    };
    // this.props.get_contract_follow_list()
    // this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    // this.contract_follow_editer = this.contract_follow_editer.bind(this);

    this.togglereserve = this.togglereserve.bind(this);


    this.handleOnChange = this.handleOnChange.bind(this);
    // this.contractdetail = this.contractdetail.bind(this);
    this.handle_change_user_id = this.handle_change_user_id.bind(this);
    this.handle_change_section_id = this.handle_change_section_id.bind(this);

    this.change_schedule = this.change_schedule.bind(this);

    this.today = this.today.bind(this);

    this.focus_start = this.focus_start.bind(this);
    this.blur_start = this.blur_start.bind(this);
    this.focus_end = this.focus_end.bind(this);
    this.blur_end = this.blur_end.bind(this);

    this.change_date = this.change_date.bind(this);

    this.change_datetime = this.change_datetime.bind(this);
    this.change_datetime_start_from_list = this.change_datetime_start_from_list.bind(this);
    this.change_datetime_end_from_list = this.change_datetime_end_from_list.bind(this);

    this.change_ownshop = this.change_ownshop.bind(this);
    this.check_validation = this.check_validation.bind(this);
    this.onClickSave = this.onClickSave.bind(this);
    this.change_datetime_start_time = this.change_datetime_start_time.bind(this);
    this.change_datetime_end_time = this.change_datetime_end_time.bind(this);
    this.onSelectChip = this.onSelectChip.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.change_datetime_select = this.change_datetime_select.bind(this);
    this.handleKanriOnly = this.handleKanriOnly.bind(this);
    this.onChangeTogetherId = this.onChangeTogetherId.bind(this);
  }


  componentDidMount() {
    this.check_validation()
  }
  focus_start(e) {

    this.check_validation()
    this.setState({ focus_start: true })
  }
  blur_start(e) {

    setTimeout(() => {
      this.setState({ focus_start: false })
    }, 200)

  }
  focus_end(e) {
    console.log(e.target)

    this.setState({ focus_end: true })
  }
  blur_end(e) {
    setTimeout(() => {
      this.setState({ focus_end: false })
    }, 200)

  }

  check_validation() {
// alert()
    let rent_task_detail = this.props.rent_task_detail
    let validation = true
    let validation_text = ''
    validation_text = rent_task_detail.title ? validation_text + "" : validation_text + "タイトルを入力してください。"
    validation_text = rent_task_detail.section_id ? validation_text + "" : validation_text + "部署を選択してください。"
    validation_text = rent_task_detail.user_id ? validation_text + "" : validation_text + "担当を選択してください。"
    validation_text = rent_task_detail.start_at ? validation_text + "" : validation_text + "開始時間を選択してください。"
    validation_text = rent_task_detail.end_at ? validation_text + "" : validation_text + "終了時間を選択してください。"
    validation_text = (rent_task_detail.start_at && rent_task_detail.end_at && moment(rent_task_detail.start_at) >= moment(rent_task_detail.end_at)) ? validation_text + "開始時間より終了時間があとになるように入力してください。": validation_text + ""
    let v = 0
    if (!rent_task_detail.user_id) {
      v = 1
    }
    if (!rent_task_detail.title) {
      v = 1
    }
    if (!rent_task_detail.start_at) {
      v = 1
    }
    if (!rent_task_detail.end_at) {
      v = 1
    }
    if (moment(rent_task_detail.start_at) >= moment(rent_task_detail.end_at)) {
      v = 1
    }

    // if (this.state.start_at_err || this.state.end_at_err) {
    //   v = 1
    // }

    if (v) {
      validation = false
    }
   

    this.setState({ validation: validation, validation_text: validation_text })

  }


  change_ownshop() {
    if (!this.state.ownshop) {
      if (this.props.users.filter(a => a.amSectionId === this.props.contract_follow_editer.section_id && a.id === this.props.contract_follow_editer.user_id)[0]) {

      } else {
        let contract_follow_editer = this.props.contract_follow_editer
        contract_follow_editer.user_id = 0
        this.props.change_contract_follow_editer(contract_follow_editer)
      }
    }
    this.setState({ ownshop: !this.state.ownshop })
    this.check_validation()
  }


  handle_change_section_id(e, newValue) {

    if (newValue) {
      let rent_task_detail = this.props.rent_task_detail
      rent_task_detail.section_id = newValue ? newValue.tenpo_no : 0
      // rent_task_detail.section_id = newValue.amSectionId
      // alert(newValue.section_id)
      this.props.change_rent_task_detail(rent_task_detail)
    }
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }

  handle_change_user_id(e, newValue) {

    if (newValue) {
      let rent_task_detail = this.props.rent_task_detail
      rent_task_detail.user_id = newValue ? newValue.id : 0
      // rent_task_detail.section_id = newValue.amSectionId
      // alert(newValue.section_id)
      this.props.change_rent_task_detail(rent_task_detail)
    }
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }

  change_date(e) {

    let rent_task_detail = this.props.rent_task_detail

    rent_task_detail.start_at = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD ") + moment(rent_task_detail.start_at).format("HH:mm")
    rent_task_detail.end_at = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD ") + moment(rent_task_detail.end_at).format("HH:mm")

    this.props.change_rent_task_detail(rent_task_detail)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }

  change_datetime_select(name, value) {
    // alert(name)
    // alert(moment(value).format('HH:mm'))
    this.setState({ 
      reflesh: !this.props.reflesh,
      [name]: moment(value).format('HH:mm'),
      [name == "start_at" ? "start_at_err" : "end_at_err"]: false,
    })
    this.check_validation()
  }
  change_datetime(e) {
    // alert(e.target.value)
    console.log(e.target.value)

    let rent_task_detail = this.props.rent_task_detail
    // if (e.target.name == "start_at") {
    //     // rent_task_detail.start_at = moment(moment(rent_task_detail.start_at).format("YYYY/MM/DD ") + e.target.value).format("YYYY/MM/DD HH:mm")
    //     rent_task_detail.start_at = moment(moment(rent_task_detail.start_at).format("YYYY/MM/DD ") + e.target.value.split('_').join(0)).format('YYYY/MM/DD HH:mm')

    //   // rent_task_detail.date = moment(rent_task_detail.datetime_start).format("YYYY/MM/DD")
      
    // } else {
    //   // alert(e.target.value.split('_').join(0))
    //   // rent_task_detail.date = moment().format("YYYY/MM/DD")
    //   // rent_task_detail.datetime_start = moment(moment().format("YYYY/MM/DD ") + e.target.value.split('_').join(0))
    //   // rent_task_detail.end_at = moment(moment(rent_task_detail.end_at).format("YYYY/MM/DD ") +  + e.target.value).format("YYYY/MM/DD HH:mm")
    //   rent_task_detail.end_at = moment(moment(rent_task_detail.end_at).format("YYYY/MM/DD ") + e.target.value.split('_').join(0)).format('YYYY/MM/DD HH:mm')
    // }

    // alert(rent_task_detail.end_at)
    // contract_follow_editer.datetime_end = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    // this.props.change_rent_task_detail(rent_task_detail)


    const regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
    // 時間チェックして
    if(regex.test(e.target.value)){ //OK
          this.setState({ 
            [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: false,
          })

      rent_task_detail[e.target.name] = moment(moment(rent_task_detail.start_at).format("YYYY/MM/DD ") + e.target.value).format('YYYY/MM/DD HH:mm')
      this.props.change_rent_task_detail(rent_task_detail)

    }else{// エラー

      this.setState({ 
        [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: true,
      })

    }

    this.setState({ 
      reflesh: !this.props.reflesh,
      [e.target.name]: e.target.value,
    })

    this.check_validation()
  }


  change_datetime_start_from_list(e) {

    let rent_task_detail = this.props.rent_task_detail

    rent_task_detail.start_at = moment(rent_task_detail.start_at).format("YYYY/MM/DD " + e.label)

    this.props.change_rent_task_detail(rent_task_detail)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }
  change_datetime_end_from_list(e) {

    let rent_task_detail = this.props.rent_task_detail

    rent_task_detail.end_at = moment(rent_task_detail.end_at).format("YYYY/MM/DD " + e.label)
// alert(rent_task_detail.end_at)
    this.props.change_rent_task_detail(rent_task_detail)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  change_schedule(date) {
    let contract_follow_editer = this.props.contract_follow_editer

    // alert(date.start)
    contract_follow_editer.date = date.start
    contract_follow_editer.datetime_start = date.start
    contract_follow_editer.datetime_end = date.end

    if (date.start !== null) {
      if (moment(date.start).format("YYYY/MM/DD HH:mm") > moment().format("YYYY/MM/DD HH:mm")) {
        contract_follow_editer.progress = 1
      } else {
        contract_follow_editer.progress = 2
      }
    }

    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  togglereserve() {
    this.setState({ modalreserve: !this.state.modalreserve })
  }

  handleOnChange(e) {

    let rent_task_detail = this.props.rent_task_detail
    

    if(e.target.name == "start_at"){
      rent_task_detail.start_at = moment(moment(e.target.value).format("YYYY/MM/DD") + " " + moment(rent_task_detail.start_at).format("HH:mm")).format("YYYY/MM/DD HH:mm")
      rent_task_detail.end_at = moment(moment(rent_task_detail.start_at).format("YYYY/MM/DD") + " " + moment(rent_task_detail.end_at).format("HH:mm")).format("YYYY/MM/DD HH:mm")

    }else{
      rent_task_detail[e.target.name] = e.target.value
    }



    // if (e.target.name === "progress" && e.target.value === 2 && !contract_follow_editer.date) {
    //   contract_follow_editer.date = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    //   contract_follow_editer.datetime_start = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    //   contract_follow_editer.datetime_end = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")


    // }
    // if (e.target.name === "progress_n" && e.target.value === 2 && !contract_follow_editer.date_n) {
    //   contract_follow_editer.date_n = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    //   contract_follow_editer.datetime_start_n = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    //   contract_follow_editer.datetime_end_n = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")

    // }


    this.props.change_rent_task_detail(rent_task_detail)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  today(nom) {
    let contract_follow_editer = this.props.contract_follow_editer
    contract_follow_editer.date = moment()
    contract_follow_editer.datetime_start = moment()
    contract_follow_editer.datetime_end = moment()
    if (contract_follow_editer.progress !== 1 && contract_follow_editer.progress !== 2) {
      contract_follow_editer.progress = 2
    }
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }

  onClickSave(id, del){
    // alert(id)

    // let rent_task_detail = this.props.rent_task_detail

    // if(del == 3){// 完了
    //   rent_task_detail.completed_at = moment().format("YYYY/MM/DD hh:mm:ss")
    // }else if(del == 4){// 未完了
    //   rent_task_detail.completed_at = null
    // }else{
    //   rent_task_detail.is_deleted = del
    // }

    // this.props.change_rent_task_detail(rent_task_detail)

    if(id) {
      this.props.update_rent_task_detail(del);
    }else{
      this.props.insert_rent_task_detail(del);
    }
    // this.props.onClose()
  }

  change_datetime_start_time(e) {

    let rent_task_detail = this.props.rent_task_detail

    rent_task_detail.start_at = moment(moment(this.props.rent_task_detail.start_at).format("YYYY/MM/DD") + " " + e.target.value).format("YYYY/MM/DD HH:mm")

    if (rent_task_detail.end_at == null || !moment(rent_task_detail.end_at).isValid() || moment(rent_task_detail.end_at).isSame(moment(rent_task_detail.start_at)) || moment(rent_task_detail.end_at).isBefore(moment(rent_task_detail.start_at))) {

      rent_task_detail.end_at = e.target.value ? moment(rent_task_detail.start_at).add(30, 'm').format("YYYY/MM/DD HH:mm") : null
    }

    this.props.change_rent_task_detail(rent_task_detail)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  change_datetime_end_time(e) {

    let rent_task_detail = this.props.rent_task_detail

    rent_task_detail.end_at = moment(moment(this.props.rent_task_detail.start_at).format("YYYY/MM/DD") + " " + e.target.value).format("YYYY/MM/DD HH:mm")

if(moment(rent_task_detail.end_at).format("HH") == "01" || moment(rent_task_detail.end_at).format("HH") == "02" || moment(rent_task_detail.end_at).format("HH") == "00"){

}else{

  if (rent_task_detail.start_at == null || !moment(rent_task_detail.start_at).isValid() || moment(rent_task_detail.start_at).isSame(moment(rent_task_detail.end_at)) || moment(rent_task_detail.start_at).isAfter(moment(rent_task_detail.end_at))) {
    rent_task_detail.start_at = rent_task_detail.end_at ? moment(rent_task_detail.end_at).add(-30, 'm').format("YYYY/MM/DD HH:mm") : null
  }
}



    this.props.change_rent_task_detail(rent_task_detail)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()

  }

  onSelectChip(color, type, title) {
    let rent_task_detail = this.props.rent_task_detail
    rent_task_detail.title = JSON.parse(JSON.stringify(title))
    if(rent_task_detail.event_type == type){
      rent_task_detail.event_type =  0
    }else{
      rent_task_detail.color = color
      rent_task_detail.event_type =  JSON.parse(JSON.stringify(type))
    }
    

    this.props.change_rent_task_detail(rent_task_detail)

    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }

  handleChange(e) {
    let rent_task_detail = this.props.rent_task_detail
    rent_task_detail[e.target.name] = e.target.value

    this.props.change_rent_task_detail(rent_task_detail)

    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }

  newEigyou(){
    // 新規
    let owner_eigyou_detail = { 

      // type: 6,
      type: 1,
      yoyaku_date:moment(this.props.rent_task_detail.start_at).format("YYYY/MM/DD"),

      id: "", 
      eigyou_no:"",
      start_at: moment(this.props.rent_task_detail.start_at).format("HH:mm"),
      end_at: moment(this.props.rent_task_detail.end_at).format("HH:mm"),
      no_count:moment(this.props.rent_task_detail.start_at) >= moment().add(1,"day").startOf('day') ? 1 : 0,
      owner_id: "",
      tantou_user_id: this.props.rent_task_detail.user_id,
      together_user_id: [],
      raiten_kbn_no:"",
      outai_naiyou_no:"",
      outai_naiyou_id:[],
      suggestions:"",
      outai_shubetsu_no:1,
      naiyou:"①物件名：\n②訪問内容（訪問目的）：\n③ヒアリング内容：\n④オーナー様の要求内容・クレーム：\n⑤褒められたこと：\n⑥オーナー様の反応状況（結果）：\n⑦競合他社の情報：\n⑧次回の課題：\n⑨次回の面談予定：\n⑩打合せ記録：",
      komatte:"",
      promise:"",
      confirmers:"",
      responder:"",
    }
    this.props.change_eigyou_detail(owner_eigyou_detail)
    // this.props.get_eigyou_owner_room(this.props.owner_detail.owner_id)

    this.props.change_task_edit_modal(false)

    this.props.change_calendar_event_type(6)
    this.props.change_eigyo_edit_modal(true)
    this.props.get_eigyou_rent_m_corporate_all_list()

    this.props.change_t_owner_plan_counts_list([])
    this.props.get_tm_owner_plan_list()
    this.props.change_eigyou_owner_room([])

  }

  handleKanriOnly() {
    // alert(state.target.value)
    this.setState({ 
      jibusyo_only: !this.state.jibusyo_only
    })
  }

  onChangeTogetherId(e, value) {
    // console.log(value)
    let rent_task_detail = this.props.rent_task_detail
    if (value) {
      rent_task_detail.together_user_id = value

    } else {
      rent_task_detail.together_user_id = []
    }

    this.props.change_rent_task_detail(rent_task_detail);
    this.setState({ change: !this.state.change });

}

  // contractdetail(response_id, rent_contract_id) {
  //   this.props.change_modalResponseDetail(true)
  //   this.props.change_responseid(response_id)
  //   this.props.change_rent_contract_id(rent_contract_id)

  // }

  render() {
    // if (this.myRef.current) {
    //   console.log(this.myRef.current.getBoundingClientRect())
    //   alert()
    // }
    return (
      <div id="contract_follow_list_editor" >





        <Card style={{ width: "100%", height: "100%" }}>
          {/* {
            this.props.contract_follow_editer_loading ? <div style={{
              position: "absolute", zIndex: 3001, opacity: 0.7, width: "100%", height: "100%",
              background: "#FFF"
            }}></div> : ""
          } */}
          <CardHeader subheader={<div style={{ color: "#000", fontWeight: "bold", fontSize: 20 }}><Event /> {"予定"}</div>}
            
            action={
              <>
              {this.props.rent_task_detail.id ? "" :<Button style={{marginRight:10}} size="small" variant="contained" color="primary" onClick={()=>this.newEigyou()}>営業対応入力</Button>}
              <IconButton aria-label="settings" onClick={this.props.onClose} size="large">
                <CloseIcon />
              </IconButton>
              </>
            }
            // title={this.props.contract_follow_editer.tatemono_name + ' ' + this.props.contract_follow_editer.room_name}
            // subheader={<div style={{ color: "#000", fontWeight: "bold", fontSize: 20 }}><CalendarTodayIcon /> {this.props.contract_follow_editer.title}</div>}
          />
          <CardContent >
            <Card >
              <CardContent >

              <Grid container spacing={1}>
                        <Grid item xs={12}>
                <TextField
                  id="outlined-multiline-flexible"
                  label="タイトル"
                  name="title"
                  onChange={this.handleOnChange}
                  value={this.props.rent_task_detail.title}
                  // style={{ width: "300px", marginTop: "10px" }}
                  variant="outlined"
                  fullWidth
                  size="small"
                />
                </Grid>

                <Grid item xs={12}>
                  <FormControl>
                    {this.props.calendar_search_store_list.length > 0 ? <Autocomplete
                      id="combo-box-demo"
                      size="small"
                      options={this.props.calendar_search_store_list.length === 0 && this.props.rent_task_detail.section_id ? [this.props.calendar_search_store_list.filter(a => String(a.tenpo_no) === String(this.props.rent_task_detail.section_id))[0]]
                        : this.props.calendar_search_store_list}
        
                      getOptionLabel={(option) => option.tenpo_name ? option.tenpo_name : ''}
                      value={this.props.rent_task_detail.section_id ? this.props.calendar_search_store_list.filter(a => String(a.tenpo_no) === String(this.props.rent_task_detail.section_id))[0] : {}}
                      style={{ width: 200 }}
                      onChange={this.handle_change_section_id}

                      renderInput={(params) => <TextField variant="outlined"
                        {...params} label="部署を選択" />}

                    /> : ""}


                  </FormControl>
                <FormControl>
                  {this.props.users.length > 0 && this.props.user_details ? <Autocomplete
                    id="combo-box-demo"
                    size="small"
                    options={this.props.users.length === 0 && this.props.rent_task_detail.user_id ? [this.props.users.filter(a => String(a.id) === String(this.props.rent_task_detail.user_id))[0]]
                      : this.props.users}
      
                    getOptionLabel={(option) => option.userNameAll ? option.userNameAll : ''}
                    // name="ownerId"
                    value={this.props.rent_task_detail.user_id ? this.props.users.filter(a => String(a.id) === String(this.props.rent_task_detail.user_id))[0] : {}}
      

                    
                    // value={this.props.rent_task_detail.user_id}
                    // defaultValue={this.props.contract_follow_editer.user_id}
                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                    style={{ width: 150 }}
                    onChange={this.handle_change_user_id}

                    renderInput={(params) => <TextField variant="outlined"
                      {...params} label="担当を選択" />}

                  /> : ""}


                </FormControl>

                </Grid>

                <Grid item xs={12} style={{ display: "flex"}}>
                  <Grid justifyContent="space-between" item xs={12}>
                      {this.props.calendar_users && this.props.rent_task_detail.together_user_id ? <>
                      {console.log("rent_task_detail", this.props.rent_task_detail)}
                          <Autocomplete
                              multiple
                              options={this.state.jibusyo_only ? this.props.calendar_users.filter(a=> ([5,7,34,45].includes(this.props.user_details.amSectionId) ?  [5,7,34,45].includes(a.am_section_id) : a.am_section_id == this.props.user_details.amSectionId)): this.props.calendar_users}
                              getOptionLabel={(option) => option.user_name_all}
                              // defaultValue={[top100Films[1]]}
                              filterSelectedOptions
                              onChange={this.onChangeTogetherId}
                              value={this.props.rent_task_detail.together_user_id}
                              renderInput={(params) => (
                              <TextField
                                  {...params}
                                  label="他メンバー"
                                  variant="standard"
                                  // placeholder="同行者"
                                  // error={(this.props.rent_task_detail.outai_shubetsu_no == 2 || this.props.rent_task_detail.outai_shubetsu_no == 3) && this.props.rent_task_detail.together_user_id.length == 0}
                              />
                              )}
                          />
                          <FormControlLabel style={{width:"100%", marginBottom:0, marginLeft:0}} control={<Checkbox color="primary" checked={this.state.jibusyo_only} onChange={this.handleKanriOnly} name="jibusyo_only" />} label="自店舗のみ表示" />
                      </>
                      :""}
                  </Grid>
                </Grid>
                
                <Grid item xs={12} style={{ display: "flex"}}>

                  <TextField
                      id="start_at"
                      label="反響日"
                      type="date"
                      name="start_at"
                      size="small"
                      onChange={this.handleOnChange}
                      value={moment(this.props.rent_task_detail.start_at).format("YYYY-MM-DD")}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      variant="standard"
                  />
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
                  {/* <KeyboardDatePicker

                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd(EE)"
                    margin="normal"
                    id="date-picker-inline2"
                    label="日付"
                    style={{ marginLeft: "15px", marginTop: "0", width: 150, marginRight:5 }}
                    autoOk={true}
                    // disabled={true}
                    // error={true}
                    // helperText="申込日を選択してください"
                    showTodayButton={true}
                    name="contractionDateEnd"
                    value={this.props.rent_task_detail.start_at ? moment(this.props.rent_task_detail.start_at) : null}
                    InputLabelProps={{ shrink: !!this.props.rent_task_detail.start_at }}
                    onChange={this.change_date}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}

                    invalidDateMessage={"日付を入力してください。"}
                  />
                   */}


                  {/* <TimePicker
                    label="Time"
                    value={this.props.rent_task_detail.start_at}
                    // onChange={handleChange}
                    renderInput={(params) => <TextField {...params} />}
                  /> */}


                  {/* <TextField
                    id="time"
                    label="開始時間"
                    type="time"
                    // defaultValue="07:30"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    onChange={this.change_datetime_start_time}
                    sx={{ width: 150 }}
                    value={moment(this.props.rent_task_detail.start_at).format("HH:mm")}
                    // style={{float: "left"}}
                  /> */}



                  {/* <TimePicker
                    label="開始時間"

                    name="start_at"
                    value={this.props.rent_task_detail.start_at}
                    onChange={value => this.change_datetime_start_time(value)}
                    renderInput={(params) => <TextField style={{ width: 100, float: "left" }} {...params} />}
                  /> */}
                  {/* <div>　–　</div> */}

                  {/* <TextField
                    id="time"
                    label="終了時間"
                    type="time"
                    // defaultValue="07:30"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    onChange={this.change_datetime_end_time}
                    sx={{ width: 150 }}
                    value={moment(this.props.rent_task_detail.end_at).format("HH:mm")}
                    // style={{float: "left"}}
                  /> */}



                  {/* <TimePicker
                    label="終了時間"

                    name="end_at"
                    value={this.props.rent_task_detail.end_at}
                    onChange={value => this.change_datetime_end_time(value)}
                    renderInput={(params) => <TextField style={{ width: 100, float: "left" }} {...params} />}
                  /> */}


                  <div  style={{position: "relative", marginLeft:20}}>
                      <TextField
                        label="開始時間"
                        style={{ width: 65, float: "left" }}
                        onChange={this.change_datetime}
                        name = "start_at"
                        // onChange={handleChange}
                        // id="formatted-text-mask-input"
                        onFocus={this.focus_start}
                        onBlur={this.blur_start}
                        InputProps={{ inputComponent: TextMaskCustom }}
                        // value={moment(this.props.rent_task_detail.start_at).format('HH:mm')}
                        value={this.state.start_at}
                        error={this.state.start_at_err}
                        variant="standard"
                        size="small"
                      />
                      {this.state.focus_start ? <DateTimeComponent start={true} start_time={null} change_datetime_select={this.change_datetime_select} change_detail={this.props.change_rent_task_detail} detail={this.props.rent_task_detail} name={"start_at"} /> : ""}
                  </div>
                  <div>　–　</div>
                  <div  style={{position: "relative"}}>
                      <TextField
                        label="終了時間"
                        style={{ width: 65, float: "left" }}
                        onChange={this.change_datetime}
                        name = "end_at"
                        // onChange={handleChange}
                        // id="formatted-text-mask-input"
                        onFocus={this.focus_end}
                        onBlur={this.blur_end}
                        InputProps={{ inputComponent: TextMaskCustom }}
                        value={this.state.end_at}
                        error={this.state.end_at_err}
                        variant="standard"
                        size="small"
                      />
                      {this.state.focus_end ? <DateTimeComponent start={false} start_time={this.props.rent_task_detail.start_at} change_datetime_select={this.change_datetime_select} change_detail={this.props.change_rent_task_detail} detail={this.props.rent_task_detail} name={"end_at"} /> : ""}
                  </div>


                 {/* <span>

                    <span>
                      <TextField
                        label="開始時間"
                        style={{ width: 70, float: "left" }}
                        onChange={this.change_datetime}
                        name = "start_at"
                        // onChange={handleChange}
                        // id="formatted-text-mask-input"
                        onFocus={this.focus_start}
                        onBlur={this.blur_start}
                        InputProps={{ inputComponent: TextMaskCustom }}
                        value={moment(this.props.rent_task_detail.start_at).format('HH:mm')}

                      />
                      {this.state.focus_start ? <Paper style={{
                        width: 70, position: "absolute", marginLeft: 155, marginTop: 50, zIndex: 2,
                        height: 100,
                        overflow: "auto"
                      }}>
                        <MenuList >
                          {(moment(this.props.rent_task_detail.end_at).format('HHmm') > 0 ? time.filter(a => a.value < moment(this.props.rent_task_detail.end_at).format('HHmm')) : time).map(function (value) {
                            return <MenuItem onClick={() => this.change_datetime_start_from_list(value)}>
                              <Typography variant="inherit">{value.label}</Typography>
                            </MenuItem>
                          }, this)}


                        </MenuList>
                      </Paper > : ""}
                    </span>
                    <div style={{ float: "left" }}> – </div>
                    <span>
                      <TextField
                        label="終了時間"
                        style={{ width: 70, float: "left" }}
                        name="end_at"
                        onChange={this.change_datetime}
                        // id="formatted-text-mask-input"
                        onFocus={this.focus_end}
                        onBlur={this.blur_end}
                        InputProps={{ inputComponent: TextMaskCustom }}
                        value={moment(this.props.rent_task_detail.end_at).format('HH:mm')}
                      />
                      {this.state.focus_end ? <Paper style={{
                        width: 70, position: "absolute", marginLeft: 230, marginTop: 50, zIndex: 2,
                        height: 100,
                        overflow: "auto"
                      }}>
                        <MenuList >
                          {time.filter(a => a.value > moment(this.props.rent_task_detail.start_at).format('HHmm')).map(function (value) {
                            return <MenuItem onClick={() => this.change_datetime_end_from_list(value)}>
                              <Typography variant="inherit">{value.label}</Typography>
                            </MenuItem>
                          }, this)}


                        </MenuList>
                      </Paper > : ""}
                    </span>



                  </span> */}
                  {/* <Tooltip title="本日">
                    <IconButton aria-label="today" onClick={() => this.today(1)}>
                      <ArrowLeftIcon />
                    </IconButton>

                  </Tooltip> */}

                {/* </MuiPickersUtilsProvider> */}


                {/* <FormControlLabel control={<Checkbox name="checkedC" />} label="予定" /> */}
                </Grid>
                <Grid item xs={12}>

                <TextField
                  id="outlined-multiline-flexible"
                  label="備考"
                  multiline
                  minRows={3}
                  maxRows={10}
                  name="remarks"
                  onChange={this.handleOnChange}
                  value={this.props.rent_task_detail.remarks}
                  // style={{ width: "300px", marginTop: "10px" }}
                  variant="outlined"
                  fullWidth
                />
                </Grid>
                <Grid item xs={9}>
                  <RadioGroup row aria-label="position" name="color" onChange={this.handleOnChange} value={this.props.rent_task_detail.color} >
                    <FormControlLabel control={<Radio value={"#BDEBF9"} style={{color:"#BDEBF9"}} />} label="" />
                    <FormControlLabel control={<Radio value={"#DAA3E8"} style={{color:"#DAA3E8"}} />} label="" />
                    <FormControlLabel control={<Radio value={"#D9ED8E"} style={{color:"#D9ED8E"}} />} label="" />
                    <FormControlLabel control={<Radio value={"#F9A7A7"} style={{color:"#F9A7A7"}} />} label="" />
                    <FormControlLabel control={<Radio value={"#A9B9F7"} style={{color:"#A9B9F7"}} />} label="" />
                    <FormControlLabel control={<Radio value={"#F2EF7C"} style={{color:"#F2EF7C"}} />} label="" />
                    {/* <FormControlLabel control={<Radio value={"#ffff7f"} style={{color:"#ffff7f"}} />} label="" /> */}
                    <FormControlLabel control={<Radio value={"#FFDFAB"} style={{color:"#FFDFAB"}} />} label="" />
                    <FormControlLabel control={<Radio value={"#E2E2E2"} style={{color:"#E2E2E2"}} />} label="" />
                    
                    
                  </RadioGroup>
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" >
                    <InputLabel id="demo-simple-select-outlined-label" shrink>種別</InputLabel>
                    <MaterialSelect
                      style={{ width: 120 }}
                      name="event_type"
                      value={this.props.rent_task_detail.event_type}
                      onChange={this.handleChange}
                      label="種別"
                      size="small"
                      margin='dense'
                    >
                      <MenuItem value={0}>-</MenuItem>
                      <MenuItem value={1}>訪問面談</MenuItem>
                      <MenuItem value={2}>来社面談</MenuItem>
                      <MenuItem value={3}>ミーティング</MenuItem>
                      <MenuItem value={4}>提案準備</MenuItem>
                      <MenuItem value={5}>事務処理</MenuItem>
                      <MenuItem value={6}>休憩</MenuItem>
                      <MenuItem value={7}>現場調査</MenuItem>
                      <MenuItem value={8}>写真撮り</MenuItem>
                      <MenuItem value={9}>ヘルプ</MenuItem>
                      <MenuItem value={10}>物件確認</MenuItem>
                      <MenuItem value={11}>確認案内</MenuItem>
                      <MenuItem value={12}>来店枠</MenuItem>
                    </MaterialSelect>
                  </FormControl>
                </Grid>
                {this.props.user_details.amSectionId == 24 || 
                this.props.user_details.amSectionId == 17 || this.props.user_details.amSectionId == 1 ||  
                this.props.user_details.amSectionId == 2 || this.props.user_details.amSectionId == 4 ||  
                this.props.user_details.amSectionId == 3 || this.props.user_details.amSectionId == 6 || 
                this.props.user_details.amSectionId == 47 || this.props.user_details.amSectionId == 10
                ? <Grid item xs={12} style={{marginTop:-5}}>
                  {this.props.rent_task_detail.event_type == 6 ? <Chip label="昼休憩" style={{border:"1px solid #BDEBF9", backgroundColor:"#BDEBF9"}} onClick={() => this.onSelectChip("#BDEBF9", 6, "休憩")} /> : <Chip label="昼休憩" style={{border:"1px solid #BDEBF9"}} variant="outlined"onClick={() => this.onSelectChip("#BDEBF9", 6, "休憩")} />}
                  {this.props.rent_task_detail.event_type == 8 ? <Chip label="写真撮り" style={{border:"1px solid #DAA3E8", backgroundColor:"#DAA3E8"}} onClick={() => this.onSelectChip("#DAA3E8", 8, "写真撮り")} /> : <Chip label="写真撮り" style={{border:"1px solid #DAA3E8"}} variant="outlined" onClick={() => this.onSelectChip("#DAA3E8", 8, "写真撮り")} />}
                  {this.props.rent_task_detail.event_type == 9 ? <Chip label="ヘルプ" style={{border:"1px solid #D9ED8E", backgroundColor:"#D9ED8E"}} onClick={() => this.onSelectChip("#D9ED8E", 9, "ヘルプ")} /> : <Chip label="ヘルプ" style={{border:"1px solid #D9ED8E"}} variant="outlined" onClick={() => this.onSelectChip("#D9ED8E", 9, "ヘルプ")} />}
                  {this.props.rent_task_detail.event_type == 10 ? <Chip label="物件確認" style={{border:"1px solid #FFDFAB", backgroundColor:"#FFDFAB"}} onClick={() => this.onSelectChip("#FFDFAB", 10, "物件確認")} /> : <Chip label="物件確認" style={{border:"1px solid #FFDFAB"}} variant="outlined" onClick={() => this.onSelectChip("#FFDFAB", 10, "物件確認")} />}
                  {this.props.rent_task_detail.event_type == 11 ? <Chip label="確認案内" style={{border:"1px solid #A9B9F7", backgroundColor:"#A9B9F7"}} onClick={() => this.onSelectChip("#A9B9F7", 11, "確認案内")} /> : <Chip label="確認案内" style={{border:"1px solid #A9B9F7"}} variant="outlined" onClick={() => this.onSelectChip("#A9B9F7", 11, "確認案内")} />}
                  {this.props.rent_task_detail.event_type == 12 ? <Chip label="来店枠" style={{border:"1px solid #d9ed8e", backgroundColor:"#d9ed8e"}} onClick={() => this.onSelectChip("#d9ed8e", 12, "来店枠")} /> : <Chip label="来店枠" style={{border:"1px solid #d9ed8e"}} variant="outlined" onClick={() => this.onSelectChip("#d9ed8e", 12, "来店枠")} />}
                  
                  {/* {this.props.rent_task_detail.event_type == 6 ? <Chip label="昼休憩" style={{border:"1px solid #BDEBF9", backgroundColor:"#BDEBF9"}} onClick={() => this.onSelectChip("#BDEBF9", 6, "休憩")} /> : <Chip label="昼休憩" style={{border:"1px solid #BDEBF9"}} variant="outlined"onClick={() => this.onSelectChip("#BDEBF9", 6, "休憩")} />}
                  {this.props.rent_task_detail.event_type == 8 ? <Chip label="写真撮り" style={{border:"1px solid #F9A7A7", backgroundColor:"#F9A7A7"}} onClick={() => this.onSelectChip("#F9A7A7", 8, "写真撮り")} /> : <Chip label="写真撮り" style={{border:"1px solid #F9A7A7"}} variant="outlined" onClick={() => this.onSelectChip("#F9A7A7", 8, "写真撮り")} />}
                  {this.props.rent_task_detail.event_type == 9 ? <Chip label="ヘルプ" style={{border:"1px solid #DAA3E8", backgroundColor:"#DAA3E8"}} onClick={() => this.onSelectChip("#DAA3E8", 9, "ヘルプ")} /> : <Chip label="ヘルプ" style={{border:"1px solid #DAA3E8"}} variant="outlined" onClick={() => this.onSelectChip("#DAA3E8", 9, "ヘルプ")} />}
                  {this.props.rent_task_detail.event_type == 10 ? <Chip label="物件確認" style={{border:"1px solid #FFDFAB", backgroundColor:"#FFDFAB"}} onClick={() => this.onSelectChip("#FFDFAB", 10, "物件確認")} /> : <Chip label="物件確認" style={{border:"1px solid #FFDFAB"}} variant="outlined" onClick={() => this.onSelectChip("#FFDFAB", 10, "物件確認")} />}
                  {this.props.rent_task_detail.event_type == 11 ? <Chip label="確認案内" style={{border:"1px solid #A9B9F7", backgroundColor:"#A9B9F7"}} onClick={() => this.onSelectChip("#A9B9F7", 11, "確認案内")} /> : <Chip label="確認案内" style={{border:"1px solid #A9B9F7"}} variant="outlined" onClick={() => this.onSelectChip("#A9B9F7", 11, "確認案内")} />} */}
                </Grid>
                :<Grid item xs={12} style={{marginTop:-5}}>
                {this.props.rent_task_detail.event_type == 1 ? <Chip label="訪問面談"  style={{border:"1px solid #F9A7A7", backgroundColor:"#F9A7A7"}} onClick={() => this.onSelectChip("#F9A7A7", 1, "訪問面談")} /> : <Chip label="訪問面談" style={{border:"1px solid #F9A7A7"}} variant="outlined" onClick={() => this.onSelectChip("#F9A7A7", 1, "訪問面談")} />}
                {this.props.rent_task_detail.event_type == 2 ? <Chip label="来社面談" style={{border:"1px solid #F9A7A7", backgroundColor:"#F9A7A7"}} onClick={() => this.onSelectChip("#F9A7A7", 2, "来社面談")} /> : <Chip label="来社面談" style={{border:"1px solid #F9A7A7"}} variant="outlined" onClick={() => this.onSelectChip("#F9A7A7", 2, "来社面談")} />}
                {this.props.rent_task_detail.event_type == 3 ? <Chip label="ミーティング" style={{border:"1px solid #D9ED8E", backgroundColor:"#D9ED8E"}} onClick={() => this.onSelectChip("#D9ED8E", 3, "ミーティング")} /> : <Chip label="ミーティング" style={{border:"1px solid #D9ED8E"}} variant="outlined" onClick={() => this.onSelectChip("#D9ED8E", 3, "ミーティング")} />}
                {this.props.rent_task_detail.event_type == 4 ? <Chip label="提案準備" style={{border:"1px solid #F2EF7C", backgroundColor:"#F2EF7C"}} onClick={() => this.onSelectChip("#F2EF7C", 4, "提案準備")} /> : <Chip label="提案準備" style={{border:"1px solid #F2EF7C"}} variant="outlined" onClick={() => this.onSelectChip("#F2EF7C", 4, "提案準備")} />}
                {this.props.rent_task_detail.event_type == 5 ? <Chip label="事務処理" style={{border:"1px solid #A9B9F7", backgroundColor:"#A9B9F7"}} onClick={() => this.onSelectChip("#A9B9F7", 5, "事務処理")} /> : <Chip label="事務処理" style={{border:"1px solid #A9B9F7"}} variant="outlined" onClick={() => this.onSelectChip("#A9B9F7", 5, "事務処理")} />}
                {this.props.rent_task_detail.event_type == 6 ? <Chip label="休憩" style={{border:"1px solid #BDEBF9", backgroundColor:"#BDEBF9"}} onClick={() => this.onSelectChip("#BDEBF9", 6, "休憩")} /> : <Chip label="休憩" style={{border:"1px solid #BDEBF9"}} variant="outlined" onClick={() => this.onSelectChip("#BDEBF9", 6, "休憩")} />}
                {this.props.rent_task_detail.event_type == 7 ? <Chip label="現場調査" style={{border:"1px solid #FFDFAB", backgroundColor:"#FFDFAB"}} onClick={() => this.onSelectChip("#FFDFAB", 7, "現場調査")} /> : <Chip label="現場調査" style={{border:"1px solid #FFDFAB"}} variant="outlined" onClick={() => this.onSelectChip("#FFDFAB", 7, "現場調査")} />}
              </Grid>
                }
                
                </Grid>
              </CardContent >
            </Card >

            {this.state.validation ?

              this.state.start_at_err || this.state.end_at_err ? 
              <Alert severity="error" style={{ margin: 5 }}>開始時間より終了時間があとになるように入力してください。</Alert>
              :
              <div style={{height:28, marginBottom:30}}>
                
                {this.state.del == true ? <Alert severity="error" style={{ margin: 5}} action={<span><Button variant="contained" style={{ float: "right", margin: 5 }} onClick={(e) => { this.setState({ "del": false }) }}>閉じる</Button><Button variant="contained" color="secondary" style={{ float: "right", margin: 5 }} onClick={()=>this.onClickSave(this.props.rent_task_detail.id, 1)}>削除</Button></span>}><span>削除してよろしいですか？</span></Alert> : this.props.rent_task_detail.id ? <Button variant="contained" color="secondary" style={{ float: "left", margin: 5 }}  onClick={(e) => { this.setState({ "del": true }) }}>削除</Button> : ""}

                {this.state.del == true ? "" : <Button variant="contained" color="primary" style={{ float: "right", margin: 5 }} onClick={()=>this.onClickSave(this.props.rent_task_detail.id, 0)}>保存</Button>}

                {this.state.del == true ? "" : this.props.rent_task_detail.completed_at ? <Button variant="contained" color="success" startIcon={<RemoveCircleIcon />} style={{ float: "right", margin: 5, backgroundColor:"#ed6c03", color:"white" }} onClick={()=>this.onClickSave(this.props.rent_task_detail.id, 4)}>未完了にする</Button> : <Button variant="contained" color="success" startIcon={<CheckCircleIcon />} style={{ float: "right", margin: 5, backgroundColor:"#5cb85c", color:"white" }} onClick={()=>this.onClickSave(this.props.rent_task_detail.id, 3)}>完了済にする</Button>}
              </div>
                
            : 
            <Alert severity="error" style={{ margin: 5 }}>{this.state.validation_text}</Alert>}
            {/* {this.props.rent_task_detail.completed_at} */}
          </CardContent >
        </Card >


        {/* this.state.del == true ? <Alert severity="error" style={{ margin: 5 }}><span>本当に削除しますか？</span><Button variant="contained" color="secondary" style={{ float: "right", margin: 5 }} onClick={()=>this.onClickSave(this.props.rent_task_detail.id, 1)}>削除</Button></Alert> :  */}




        {/* <Modal isOpen={this.props.modalResponseDetail} toggle={() => this.props.change_modalResponseDetail(false)}
          size="xxl" fade={false}

        >

          <ResponseComponent responseTabs="r4" change_modalResponseDetail={(a) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responseid} response_modal={true} />

        </Modal> */}






        <Modal isOpen={this.state.modalreserve} toggle={this.togglereserve} size="xxl" fade={false}>

          <ModalHeader toggle={this.togglereserve}>予定</ModalHeader>
          <ModalBody>
            <div style={{ height: window.innerHeight - 200, background: 'white' }}>
              {/* <FollowCalendar change_schedule={this.change_schedule} section_id={this.props.contract_follow_editer.section_id} /> */}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button variant="contained" onClick={this.togglereserve} color="primary" style={{ float: "right", margin: 5 }}>
              閉じる
            </Button>

          </ModalFooter>
        </Modal>




      </div >
    );
  }
};

const time = [{ value: 900, label: '09:00' }
  , { value: 915, label: '09:15' }
  , { value: 930, label: '09:30' }
  , { value: 945, label: '09:45' }
  , { value: 1000, label: '10:00' }
  , { value: 1015, label: '10:15' }
  , { value: 1030, label: '10:30' }
  , { value: 1045, label: '10:45' }
  , { value: 1000, label: '10:00' }
  , { value: 1015, label: '10:15' }
  , { value: 1030, label: '10:30' }
  , { value: 1045, label: '10:45' }
  , { value: 1100, label: '11:00' }
  , { value: 1115, label: '11:15' }
  , { value: 1130, label: '11:30' }
  , { value: 1145, label: '11:45' }
  , { value: 1200, label: '12:00' }
  , { value: 1215, label: '12:15' }
  , { value: 1230, label: '12:30' }
  , { value: 1245, label: '12:45' }
  , { value: 1300, label: '13:00' }
  , { value: 1315, label: '13:15' }
  , { value: 1330, label: '13:30' }
  , { value: 1345, label: '13:45' }
  , { value: 1400, label: '14:00' }
  , { value: 1415, label: '14:15' }
  , { value: 1430, label: '14:30' }
  , { value: 1445, label: '14:45' }
  , { value: 1500, label: '15:00' }
  , { value: 1515, label: '15:15' }
  , { value: 1530, label: '15:30' }
  , { value: 1545, label: '15:45' }
  , { value: 1600, label: '16:00' }
  , { value: 1615, label: '16:15' }
  , { value: 1630, label: '16:30' }
  , { value: 1645, label: '16:45' }
  , { value: 1700, label: '17:00' }
  , { value: 1715, label: '17:15' }
  , { value: 1730, label: '17:30' }
  , { value: 1745, label: '17:45' }
  , { value: 1800, label: '18:00' }
  , { value: 1815, label: '18:15' }
  , { value: 1830, label: '18:30' }
  , { value: 1845, label: '18:45' }
  , { value: 1900, label: '19:00' }]

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

//export  default CustomerList;
const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    calendar_users: state.calendar.users,
    shops: state.masterReducer.shops,
    contract_follow_search_list: state.contractfollow.contract_follow_search_list,
    rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    // contract_follow_editer: state.contractfollow.contract_follow_editer,
    modalResponseDetail: state.responsesReducer.modalResponseDetail,
    responseid: state.responsesReducer.responseid,
    contract_follow_editer_loading: state.contractfollow.contract_follow_editer_loading,

    rent_task_list: state.calendar.rent_task_list,
    rent_task_detail: state.calendar.rent_task_detail,
    calendar_search_store_list: state.calendar.calendar_search_store_list,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_contract_follow_editer(state) {
      dispatch(ContractFollow.change_contract_follow_editer(state))
    },
    change_contract_follow_list(state) {
      dispatch(ContractFollow.change_contract_follow_list(state))
    },

    get_contract_follow_list(state) {
      dispatch(ContractFollow.get_contract_follow_list(state))
    },
    change_responseid(state) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

    change_rent_contract_id(state) {
      dispatch(Contracts.change_rent_contract_id(state))
    },

    update_rent_contract_follow_details(state) {
      dispatch(ContractFollow.update_rent_contract_follow_details(state))
    },
    search_contract_follow_list(state) {
      dispatch(ContractFollow.search_contract_follow_list(state))
    },



    
    insert_rent_task_detail(state) {
      dispatch(Calendarsaga.insert_rent_task_detail(state))
    },
    update_rent_task_detail(state) {
      dispatch(Calendarsaga.update_rent_task_detail(state))
    },
    change_rent_task_detail(state) {
      dispatch(Calendarsaga.change_rent_task_detail(state))
    },

    change_task_edit_modal(state) {
      dispatch(Calendarsaga.change_task_edit_modal(state))
    },
    change_eigyo_edit_modal(state) {
      dispatch(Calendarsaga.change_eigyo_edit_modal(state))
    },
    change_eigyou_detail(state) {
      dispatch(Calendarsaga.change_eigyou_detail(state))
    },
    change_calendar_event_type(state) {
      dispatch(Calendarsaga.change_calendar_event_type(state))
    },
    get_eigyou_rent_m_corporate_all_list(state) {
      dispatch(Calendarsaga.get_eigyou_rent_m_corporate_all_list(state))
    },
    change_t_owner_plan_counts_list(state) {
      dispatch(Calendarsaga.change_t_owner_plan_counts_list(state))
    },
    get_tm_owner_plan_list(state) {
      dispatch(Calendarsaga.get_tm_owner_plan_list(state))
    },
    change_eigyou_owner_room(state) {
      dispatch(Calendarsaga.change_eigyou_owner_room(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);