
const authentication = require('react-azure-adb2c2').default;
export const list = (values: any) => {
    // const url = process.env.REACT_APP_API_BASE_URL + `/api/estimates/` + values;
    // const url = `https://localhost:5000/api/rent/contract/monies` + values;
    const url = process.env.REACT_APP_API_CRM_URL + `/api/rent/contract/monies` + values;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

