import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as Responses from './Saga/ResponseList';
import '../../Css/Grid.css';
import Button from '@mui/material/Button';
// import "Components/RoomList/node_modules/Components/RoomList_/node_modules/react-datepicker/dist/react-datepicker.css";
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css'
import ResponseLineDispComponent from './ResponseLineDispComponent';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import Alert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import * as ResponsesDetail from '../ResponseDetail/Saga/Responses';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import MaterialUiSelect from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

// import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

// import {
//   MuiPickersUtilsProvider,
//   KeyboardTimePicker,
//   KeyboardDatePicker,

// } from '@material-ui/pickers';


var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}

class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}



class ResponseLineSendComponent extends Component{
  constructor(props) {
    super(props);

    var data = new Date()
    data.setMonth(data.getMonth() - 1)
    this.state = {
      // height: window.innerHeight,
      refresh: false,
      text:"",
      modal_del:false,
      fileid:0,
      name:"",
      columnWidths:{
        title:180,
        schedule_at:140,
        no_send:160,
        user:150,
        count:50,
        count_intend:60,
        condition:80,
      },
      colSortDirs: {},
      select_section_id:0, 
    };

    // this.togglemail = this.togglemail.bind(this);
    this.refresh = this.refresh.bind(this);

    this.handleChange = this.handleChange.bind(this);

    this.onAdd = this.onAdd.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.responsedetail = this.responsedetail.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
    this._rowClassNameGetter2 = this._rowClassNameGetter2.bind(this);
    this._onSortChange = this._onSortChange.bind(this);
    this.handleChangeSection = this.handleChangeSection.bind(this);

    this.props.get_rent_line_schedule_list(this.state.select_section_id)

    // this.props.change_rent_line_template({id: '', title:'', section_id: this.props.user_details.amSectionId, is_deleted: 0 })
    // this.props.change_rent_line_template_detail([])
    // alert(this.props.mail_id)
  }

  onAdd = () => {
    if(this.state.text != ""){
      let rent_line_template_detail = this.props.rent_line_template_detail
      let maxNo = rent_line_template_detail.length > 0 ? Math.max.apply(null,rent_line_template_detail.map(function(o){return o.no;})) : -1
      // alert(maxNo)
      let detail = {no:maxNo+1,
        message_type:"text",
        mime_type:null,
        mime:null,
        file_id:null,
        message_text:this.state.text,}
      
      this.props.change_rent_line_template_detail(this.props.rent_line_template_detail.concat(detail))
      
      this.setState({ text: "" });
    }

  }

  onChange = (e) => {

    this.setState({ text: e.target.value });

  }


  refresh() {
    this.setState({
      refresh: !this.state.refresh
    });
  }
  handleChange = (event) => {

    let line_send_search = this.props.line_send_search

    if (event.target.name === "condition") {

      if (event.target.checked) {
          let ary = line_send_search[event.target.name] ? line_send_search[event.target.name].split(",") : []
          ary.push(parseInt(event.target.value))
          ary = ary.filter(Boolean)
          line_send_search[event.target.name] = ary.join(',')
      } else {
        line_send_search[event.target.name] = line_send_search[event.target.name].split(",").filter(item => parseInt(item) != parseInt(event.target.value)).join(',')

      }
      this.props.get_rent_line_schedule_customer_contact_list_search()
    } else {
      line_send_search[event.target.name] = event.target.value;
    }


    this.props.change_line_send_search(line_send_search)

    this.refresh()
  }


  handleOpen(id) {

    this.props.get_rent_line_template_detail(id)

    let rent_line_template = this.props.rent_line_templates_list.filter((a) => a.id == id)[0]
    this.props.change_rent_line_template(rent_line_template);

    this.refresh()

    // this.props.change_rent_m_corporates_top_open(true)
}

handleDelete(){

  let rent_line_schedule_detail = this.props.rent_line_schedule_detail

  this.props.delete_rent_line_schedule(rent_line_schedule_detail.id)

  this.setState({ modal_del : false });

  this.refresh()
  
}

handleEdit(id){

  let rent_line_schedule_detail = this.props.rent_line_schedule_list.filter(a=>a.id == id)[0]
  this.props.change_rent_line_schedule_detail(rent_line_schedule_detail)

  this.props.get_rent_line_template_detail(rent_line_schedule_detail.rent_line_template_id)

  this.props.get_rent_line_schedule_customer_contact_list(id)

  this.refresh()

}

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {
    this.setState(({ columnWidths }) => ({
      columnWidths: {
        ...columnWidths,
        [columnKey]: newColumnWidth,
      },
    }));
  }

  responsedetail(response_id) {
    this.props.change_modalResponseDetail(true)
    this.props.change_responseid(response_id)


  }

  _rowClassNameGetter(index){
    if(this.props.rent_line_schedule_list && this.props.rent_line_schedule_list[index]) {
      // alert(this.props.rent_contracts_list[index].bk_no > 0 && (this.props.rent_contracts_list[index].ver != this.props.rent_contracts_list[index].log_confirm) ? "edit" : this.props.rent_contracts_list[index].cancel_date && this.props.rent_contracts_list[index].bk_no > 0 ? "cancel_zumi" : this.props.rent_contracts_list[index].cancel_date ? "cancel_mi" : this.props.rent_contracts_list[index].cloudsign_completed_at && (this.props.rent_contracts_list[index].payment_type == 2 || this.props.rent_contracts_list[index].apply_payment_type == 1) ? "completed" : "")
      // alert(this.props.rent_line_schedule_list[index].id == this.props.rent_line_schedule_detail.id ? "line_schedule_selected" : "line_schedule_selected")
      return this.props.rent_line_schedule_list[index].id == this.props.rent_line_schedule_detail.id ? "line_schedule_selected" : ""
    }else{
      return ""
    }
  }

  _rowClassNameGetter2(index){
    if(this.props.rent_line_schedule_customer_contact_list && this.props.rent_line_schedule_customer_contact_list[index]) {
      // alert(this.props.rent_contracts_list[index].bk_no > 0 && (this.props.rent_contracts_list[index].ver != this.props.rent_contracts_list[index].log_confirm) ? "edit" : this.props.rent_contracts_list[index].cancel_date && this.props.rent_contracts_list[index].bk_no > 0 ? "cancel_zumi" : this.props.rent_contracts_list[index].cancel_date ? "cancel_mi" : this.props.rent_contracts_list[index].cloudsign_completed_at && (this.props.rent_contracts_list[index].payment_type == 2 || this.props.rent_contracts_list[index].apply_payment_type == 1) ? "completed" : "")
      // alert(this.props.rent_line_schedule_list[index].id == this.props.rent_line_schedule_detail.id ? "line_schedule_selected" : "line_schedule_selected")
      return this.props.rent_line_schedule_customer_contact_list[index].intend == 0 ? "line_schedule_zumi" : ""
    }else{
      return ""
    }
  }

  _onSortChange(columnKey, sortDir) {

    let rent_line_schedule_customer_contact_list = this.props.rent_line_schedule_customer_contact_list;
    rent_line_schedule_customer_contact_list.sort((indexA, indexB) => {
      // alert(indexA)

      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.props.change_rent_line_schedule_customer_contact_list(rent_line_schedule_customer_contact_list)
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  handleChangeSection(e){

    this.props.get_rent_line_schedule_list(e.target.value)
  
    this.setState({ select_section_id: e.target.value });
  
  }

  render() {
    //   alert(this.props.response_matching_list_loading)
    return (
      <Grid container>

        {/* <TextField
          id="outlined-basic"
          //   className={classes.textField}
          label="お客様名"
          margin="normal"

          onChange={this.handleChange}
          name="name"
          value={this.state.formData.name}
          style={{ float: "left", width: "150px", marginTop: 0, paddingRight: "5px" }}

        /> */}

          <Grid xs={5}>
        

          <FormControl variant="outlined" style={{width:200}}>
              <InputLabel id="sectionId-label">店舗</InputLabel>
              <MaterialUiSelect
                  labelId="sectionId-label"
                  id="section_id"
                  name="section_id"
                  value={this.state.select_section_id}
                  onChange={this.handleChangeSection}
                  // disabled={this.props.formData.id}
                  label="section_id"
                  margin='dense'
              >
                  <MenuItem key={-1} value={0}>全店</MenuItem>
                  {this.props.section_select.filter((a) => a.value > 0 && a.value <=6 || a.value == 10 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47 || a.value == 55).map(function (item, i) {
                  return (
                      <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                  );

                  })}

              </MaterialUiSelect>
          </FormControl>


          <Table 
            rowHeight={45} 
            rowsCount={this.props.rent_line_schedule_list.length} 
            headerHeight={40} 
            width={(window.innerWidth-100) / 12 * 5} 
            height={700} 
            onColumnResizeEndCallback={this._onColumnResizeEndCallback} 
            isColumnResizing={false} 
            className="gridSendLineList"
            rowClassNameGetter={this._rowClassNameGetter}
          >
            <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} aria-label="edit" size="small" onClick={() => { this.handleEdit(this.props.rent_line_schedule_list[rowIndex].id) }}><EditIcon style={{color:'green'}} /></IconButton></Cell>)} width={40} />
            {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} aria-label="edit" size="small" onClick={() => { this.props.get_rent_line_schedule_customer_contact_list(this.props.rent_line_schedule_list[rowIndex].id) }}><DeleteIcon /></IconButton></Cell>)} width={40} /> */}
            
            <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell>
            {(() => {
                    if (this.props.rent_line_schedule_list[rowIndex].section_id === 1) {
                        return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-success">番</span></span></div >
                    } else if (this.props.rent_line_schedule_list[rowIndex].section_id === 2) {
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-warning">レ</span></span></div >
                    } else if (this.props.rent_line_schedule_list[rowIndex].section_id === 3) {
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-danger">駅</span></span></div >
                    } else if (this.props.rent_line_schedule_list[rowIndex].section_id === 4) {
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-dark">東</span></span></div >
                    } else if (this.props.rent_line_schedule_list[rowIndex].section_id === 6) {
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-light">公</span></span></div >
                    } else if (this.props.rent_line_schedule_list[rowIndex].section_id === 17) {
                        return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-info">サ</span></span></div >
                    } else if (this.props.rent_line_schedule_list[rowIndex].section_id === 47) {
                        return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-secondary">通</span></span></div >
                    }else if(this.props.rent_line_schedule_list[rowIndex].section_id > 0){
                      return <div style={{  }}><span style={{ fontSize: "16px" }}><span className="badge badge-primary">
                        {this.props.rent_line_schedule_list[rowIndex].section_id > 0 && this.props.shops.length > 0 ? this.props.shops.filter((a) => a.tenpoNo == this.props.rent_line_schedule_list[rowIndex].section_id)[0].tenpoName.charAt(0) : ""}
                      </span></span></div >
                    }
          })()}
            
            </Cell>)} width={40} />
            
            <Column width={this.state.columnWidths.title} isResizable={true} columnKey="title" header={<Cell>タイトル</Cell>} cell={({ rowIndex, ...props }) => (<Cell>{this.props.rent_line_schedule_list[rowIndex].title}</Cell>)} />
            <Column width={this.state.columnWidths.count} isResizable={true} columnKey="count" header={<Cell>件数</Cell>} cell={({ rowIndex, ...props }) => (<Cell>{this.props.rent_line_schedule_list[rowIndex].count}</Cell>)} />
            <Column width={this.state.columnWidths.count_intend} isResizable={true} columnKey="count_intend" header={<Cell>送信済</Cell>} cell={({ rowIndex, ...props }) => (<Cell>{this.props.rent_line_schedule_list[rowIndex].count_intend}</Cell>)} />
            <Column width={this.state.columnWidths.schedule_at} isResizable={true} columnKey="schedule_at" header={<Cell>送信日時</Cell>} cell={({ rowIndex, ...props }) => (<Cell>{moment(this.props.rent_line_schedule_list[rowIndex].schedule_at).utc().format("YYYY-MM-DD HH:mm")}</Cell>)} />
            <Column width={this.state.columnWidths.user} isResizable={true} columnKey="user" header={<Cell>送信者</Cell>} cell={({ rowIndex, ...props }) => (<Cell>{this.props.rent_line_schedule_list[rowIndex].user_id > 0 ? this.props.users_select.filter((a) => a.value == this.props.rent_line_schedule_list[rowIndex].user_id)[0].label : ""}</Cell>)} />
            {/* <Column width={this.state.columnWidths.user} isResizable={true} columnKey="user" header={<Cell>送信者</Cell>} cell={({ rowIndex, ...props }) => (<Cell>{this.props.rent_line_schedule_list[rowIndex].user_id}</Cell>)} /> */}
          </Table>

        

          </Grid>
          <Grid xs={7} >
          {this.props.rent_line_schedule_detail.id ? <div>
            <FormControlLabel control={<Checkbox style={{paddingTop:0,paddingBottom:0,}} checked={this.props.line_send_search.condition ? this.props.line_send_search.condition.split(',').includes("1") : false} value={1} onChange={this.handleChange} name="condition" />} label="来店済" />
            <FormControlLabel control={<Checkbox style={{paddingTop:0,paddingBottom:0,}} checked={this.props.line_send_search.condition ? this.props.line_send_search.condition.split(',').includes("2") : false} value={2} onChange={this.handleChange} name="condition" />} label="追客終了" />
            <FormControlLabel control={<Checkbox style={{paddingTop:0,paddingBottom:0,}} checked={this.props.line_send_search.condition ? this.props.line_send_search.condition.split(',').includes("3") : false} value={3} onChange={this.handleChange} name="condition" />} label="成約済" />
            
            <Chip label={"送信数：" + this.props.rent_line_schedule_customer_contact_list_search.length + "件"} style={{ fontSize: 14, float:"right", marginTop:-5 }} variant="outlined" />
            {/* <Chip label={"開封数：" + this.props.rent_line_schedule_customer_contact_list_search.filter(a=>a.line_template_is_read == 1).length + "件"} style={{ fontSize: 14, float:"right", marginTop:-5 }} variant="outlined" color="" /> */}
            
          </div>:""}
          {this.props.rent_line_schedule_detail.id ? <div style={{float:"left"}}>
            
            
            <Table 
            rowHeight={35} 
            rowsCount={this.props.rent_line_schedule_customer_contact_list_search.length} 
            headerHeight={40} 
            width={((window.innerWidth - 100 ) / 12 * 7) - 310} 
            height={660} 
            onColumnResizeEndCallback={this._onColumnResizeEndCallback} 
            isColumnResizing={false} 

            className="gridSendLineList2"
            rowClassNameGetter={this._rowClassNameGetter2}
          >
            <Column width={this.state.columnWidths.title} isResizable={true} columnKey="title" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.title} style={{ whiteSpace: "nowrap" }}>お客様名</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell style={{padding:0}}>
              {/* {this.props.rent_line_schedule_customer_contact_list_search[rowIndex].id} */}
              {
                this.props.rent_line_schedule_customer_contact_list_search[rowIndex].line_img_url ? this.props.rent_line_schedule_customer_contact_list_search[rowIndex].line_img_url.split(',').map(function (item, key){
                  return(
                    this.props.rent_line_schedule_customer_contact_list_search[rowIndex].line_img_url.split(',')[key] != "" ? 
                    <span style={{position: "relative"}}><img style={{marginTop:-5}} className="faceicon" src={'https://img.global-center.co.jp/v1/line/img/'+this.props.rent_line_schedule_customer_contact_list_search[rowIndex].line_user_id.split(',')[key]+'/200/200'} alt="lineimg" />{this.props.rent_line_schedule_customer_contact_list_search[rowIndex].unfollowed_at.split(',')[key] ? <i style={{ color: "red", position: "absolute", right: 0, top: -5 }} class="fas fa-ban fa-lg"></i> : ""}</span>
                    : 
                    this.props.rent_line_schedule_customer_contact_list_search[rowIndex].unfollowed_at && this.props.rent_line_schedule_customer_contact_list_search[rowIndex].unfollowed_at.split(',')[key] ? <i style={{ color: "red" }} class="fas fa-ban fa-lg"></i> : ""
                  )
                },this) : ""
              }
              {this.props.rent_line_schedule_customer_contact_list_search[rowIndex].name1}
            </Cell>)} />
            <Column width={this.state.columnWidths.condition} isResizable={true} columnKey="condition" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.condition} style={{ whiteSpace: "nowrap" }}>区分</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell>{this.props.rent_line_schedule_customer_contact_list_search[rowIndex].rent_contract_id > 0 ? "成約済" : this.props.rent_line_schedule_customer_contact_list_search[rowIndex].end_response_date ? "追客終了" : this.props.rent_line_schedule_customer_contact_list_search[rowIndex].coming_response_count > 0 ? "来店済" : ""}</Cell>)} />
            <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{marginTop:-5}} aria-label="edit" size="small" onClick={() => this.responsedetail(this.props.rent_line_schedule_customer_contact_list_search[rowIndex].rent_response_id)}><OpenInNewIcon color="primary" /></IconButton></Cell>)} width={40} />
            <Column width={this.state.columnWidths.schedule_at} isResizable={true} columnKey="schedule_at" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.schedule_at} style={{ whiteSpace: "nowrap" }}>送信日時</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell>{moment(this.props.rent_line_schedule_customer_contact_list_search[rowIndex].date).utc().format("YYYY-MM-DD HH:mm")}</Cell>)} />
            <Column width={this.state.columnWidths.no_send} isResizable={true} columnKey="no_send" header={<SortHeaderCell onSortChange={this._onSortChange} sortDir={this.state.colSortDirs.schedule_at} style={{ whiteSpace: "nowrap" }}>備考</SortHeaderCell>} cell={({ rowIndex, ...props }) => (<Cell>{this.props.rent_line_schedule_customer_contact_list_search[rowIndex].line_blocked_at ? "ブロック済のため未送信" : this.props.rent_line_schedule_customer_contact_list_search[rowIndex].end_response_no_send_at ? "追客終了のため未送信" : ""}</Cell>)} />
            <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} aria-label="edit" size="small" onClick={() => { this.props.delete_responselist_rent_customer_contacts(this.props.rent_line_schedule_customer_contact_list_search[rowIndex].id);this.refresh() }}><DeleteIcon /></IconButton></Cell>)} width={40} />
            
          </Table></div>:""}

          
          
            {this.props.rent_line_schedule_detail.id ? <div style={{width:300, height:660, border:"1px solid gray", marginLeft:-20, float:"right"}} className="item-line" id="item-line">
                  <ResponseLineDispComponent rent_line_template_detail={this.props.rent_line_template_detail} />
                </div>:""}


                {this.state.modal_del == true ? 
            <Alert 
              severity="error" 
              style={{ marginTop: 10, width:"100%"}} 
              action={
                <span>
                  <Button variant="contained" style={{ float: "right", marginRight: 5 }} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
                  <Button variant="contained" color="secondary" style={{ float: "right", marginRight: 5 }} onClick={() => this.handleDelete()}>削除</Button>
                  </span>
              }><span>削除してよろしいですか？</span>
            </Alert> : 
              this.props.rent_line_schedule_detail.id ? <div style={{clear:"both"}}><Button style={{ marginRight:5, marginTop:10 }} size="small" variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button></div>:""}
          </Grid>

      </Grid>
    );
  }


}



const mapStateToProps = (state) => {
  return {

    rent_line_schedule_list: state.responselist.rent_line_schedule_list,
    rent_line_schedule_customer_contact_list: state.responselist.rent_line_schedule_customer_contact_list,
    rent_line_schedule_customer_contact_list_search: state.responselist.rent_line_schedule_customer_contact_list_search,
    rent_line_schedule_detail: state.responselist.rent_line_schedule_detail,
    rent_line_template_detail: state.responselist.rent_line_template_detail,
    rent_line_schedule_template_detail: state.responselist.rent_line_schedule_template_detail,
    users_select: state.masterReducer.users_select,
    line_send_search: state.responselist.line_send_search,



    response_search_list: state.responselist.response_search_list,

    line_schedule_detail: state.responselist.line_schedule_detail,
    
    rent_line_template: state.responselist.rent_line_template,
    

    file_update_disp: state.responselist.file_update_disp,

    section_select: state.masterReducer.section_select,
    shops: state.masterReducer.shops,

    


  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_rent_line_schedule_list(state) {
      dispatch(Responses.get_rent_line_schedule_list(state))
    },
    get_rent_line_schedule_customer_contact_list(state) {
      dispatch(Responses.get_rent_line_schedule_customer_contact_list(state))
    },
    delete_responselist_rent_customer_contacts(state) {
      dispatch(Responses.delete_responselist_rent_customer_contacts(state))
    },
    change_rent_line_schedule_detail(state) {
      dispatch(Responses.change_rent_line_schedule_detail(state))
    },
    delete_rent_line_schedule(state) {
      dispatch(Responses.delete_rent_line_schedule(state))
    },
    change_responseid(state) {
      dispatch(ResponsesDetail.change_responseid(state))
    },
    change_modalResponseDetail(state) {
        dispatch(ResponsesDetail.change_modalResponseDetail(state))
    },
    change_rent_line_schedule_customer_contact_list(state) {
        dispatch(Responses.change_rent_line_schedule_customer_contact_list(state))
    },
    change_line_send_search(state) {
        dispatch(Responses.change_line_send_search(state))
    },
    get_rent_line_schedule_customer_contact_list_search(state) {
      dispatch(Responses.get_rent_line_schedule_customer_contact_list_search(state))
    },




    change_line_schedule_detail(state) {
      dispatch(Responses.change_line_schedule_detail(state))
    },
    get_rent_line_template_detail(state) {
      dispatch(Responses.get_rent_line_template_detail(state))
    },
    change_rent_line_template_detail(state) {
      dispatch(Responses.change_rent_line_template_detail(state))
    },
    change_rent_line_template(state) {
      dispatch(Responses.change_rent_line_template(state))
    },
    insert_rent_line_template(state) {
      dispatch(Responses.insert_rent_line_template(state))
    },
    update_rent_line_template(state) {
      dispatch(Responses.update_rent_line_template(state))
    },
    change_file_update_disp(state) {
      dispatch(Responses.change_file_update_disp(state))
    },



  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseLineSendComponent);