
const authentication = require('../../../react-azure-adb2c2').default;


export const followend = (values: any) => {


    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contracts/followend`;
    // let url = `http://localhost:5000/api/v1/rent/contracts/followend`;

    const token = authentication.getAccessToken();
    // console.log(JSON.stringify(values))
    // alert(JSON.stringify(values))


    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },
    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}



