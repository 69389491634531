
const authentication = require('../../../react-azure-adb2c2').default;




export const excel = (values: any) => {
    let start = "";
    let end = "";
    if (values.start) {

        const date1 = new Date(values.start);
        const year = date1.getFullYear();
        // const month = start.getMonth() + 1;
        const month = ("0" + (date1.getMonth() + 1)).slice(-2)
        // const day = start.getDate();
        const day = ("0" + date1.getDate()).slice(-2)
        start = year + "" + month + "" + day
    }
    if (values.end) {

        const date2 = new Date(values.end);
        const year2 = date2.getFullYear();
        // const month2 = end.getMonth() + 1;
        const month2 = ("0" + (date2.getMonth() + 1)).slice(-2)
        // const day2 = end.getDate();
        const day2 = ("0" + date2.getDate()).slice(-2)
        end = year2 + "" + month2 + "" + day2
    }

    let url = process.env.REACT_APP_API_FILE_URL + `/api/EntrustedList?start=` + start + '&end=' + end;
    // alert(url)
    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(response => response.blob())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));

};











export const detail = (values: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokuDetails/` + values.tatemonoId + '/' + values.shutokuNo;
    // let url = `https://localhost:44341/api/TShutokuTaiyouDetails/`+ values.tatemonoId+'/'+ values.shutokuNo;
    //  alert(url)
    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};



export const update = (values: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokuTaiyous/`;
    // let url =`https://localhost:44341/api/TShutokuTaiyous/`;
    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;

    // console.log(values)
    // alert()
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const deleteshutoku = (values: any) => {


    let url = process.env.REACT_APP_API_BASE_URL + `/api/TShutokuTaiyous/` + values.tatemonoId + '/' + values.shutokuNo;
    // let url =`https://localhost:44341/api/TShutokuTaiyous/`+values.tatemonoId+'/'+values.shutokuNo;
    //  const url = `https://localhost:44341/api/RentCustomerContacts/`+ values;

    // alert(url)
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};