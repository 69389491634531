import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';


import { call, put, takeEvery, take, select } from 'redux-saga/effects';

import { eventChannel } from 'redux-saga'
import * as user_details_api from './Api/user_details_api';


import * as firebase_token_api from './Api/firebase_token_api';


import * as Line from '../Components/Line/Saga/Line';





import { initializeApp } from 'firebase/app';

import { getAuth, onAuthStateChanged, signInWithCustomToken } from 'firebase/auth';


import firebaseConfig from '../firebase-config';

import { getFirestore, collection, query, where,  onSnapshot, orderBy } from "firebase/firestore";


const firebaseApp = initializeApp(firebaseConfig);


const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);



export interface MasterState {

    fire_mail: any;
    fire_line: any;
    fire_line_detail: any;
    fire_mypage: any;
}
const initialState: MasterState = {


    fire_mail: [],
    fire_line: [],
    fire_line_detail: [],
    fire_mypage: [],
}



///action 
const actionCreator = actionCreatorFactory();

export const fire_user_details = actionCreator('FIRE_USER_DETAILS');

export const change_fire_mail = actionCreator('CHANGE_FIRE_MAIL');
export const change_fire_line = actionCreator('CHANGE_FIRE_LINE');
export const change_fire_line_detail = actionCreator('CHANGE_FIRE_LINE_DETAIL');

export const get_fire_line = actionCreator('GET_FIRE_LINE');

export const get_fire_line_detail = actionCreator('GET_FIRE_LINE_DETAIL');
export const get_fire_mypage = actionCreator('GET_FIRE_MYPAGE');
export const change_fire_mypage = actionCreator('CHANGE_FIRE_MYPAGE');


export const firebasestoreReducer = reducerWithInitialState(initialState)
    .case(change_fire_mail, (state, payload) => ({ ...state, fire_mail: payload }))
    .case(change_fire_line, (state, payload) => ({ ...state, fire_line: payload }))
    .case(change_fire_line_detail, (state, payload) => ({ ...state, fire_line_detail: payload }))
    .case(change_fire_mypage, (state, payload) => ({ ...state, fire_mypage: payload }))

// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const firebasestoreSaga = [
    takeEvery('FIRE_USER_DETAILS', handle_fire_user_details),
    takeEvery('GET_FIRE_LINE', handle_get_fire_line),
    takeEvery('GET_FIRE_LINE_DETAIL', handle_get_fire_line_detail),
    takeEvery('GET_FIRE_MYPAGE', handle_get_fire_mypage),


];
// export default masterSaga;


// let fire_payload_set: any
let query11: any
function* handle_fire_user_details(action: any): any {
    // let useras: any = yield call(authset)
    // alert(JSON.stringify(auth))

    const { payload, error } = yield call(user_details_api.get_user_details);
    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        console.log(payload)
        // alert("user")

        const { fire_payload, fire_error } = yield call(firebase_token_api.get_firebase_token, action.payload);


        if (fire_payload && !fire_error) {
        //   alert(fire_payload)

        
// 認証状態の変更を監視
onAuthStateChanged(auth, (user) => {
    if (user) {
    //   console.log("User is authenticated", user.uid);
    //   alert("User is authenticated")
    //   // 認証済みのユーザーの場合
    //   const q = query(collection(db, "your-collection"), orderBy("createdAt"));
  
    //   // スナップショットリスナーを設定
    //   onSnapshot(q, (snapshot) => {
    //     snapshot.forEach((doc) => {
    //       console.log(doc.id, " => ", doc.data());
    //     });
    //   }, (error) => {
    //     console.error("Error in snapshot listener: ", error.message);
    //   });
    } else {
      
       
        signInWithCustomToken(auth, fire_payload.token)
        .then((userCredential) => {
            // alert("りぞ")

            // resolve(userCredential)
            //                 // function (e) {
            // alert(user ? user.uid : "ss")

            // const citiesRef = collection(db, "response");
            //                 console.log('firebasesuccess');
        })

    }
  });
           
                      
                  
            // let useras: any = yield call(authset)
     

            yield put(get_fire_line(payload));
            yield put(get_fire_mypage(payload));

            let mail_account_id = 0;
            if (payload.am_section_id === 1) {
                mail_account_id = 1;
            }
            else if (payload.am_section_id === 2) {
                mail_account_id = 2;
            }
            else if (payload.am_section_id === 3) {
                mail_account_id = 3;
            }
            else if (payload.am_section_id === 4) {
                mail_account_id = 4;
            }
            else if (payload.am_section_id === 6) {
                mail_account_id = 5;
            }
            else if (payload.am_section_id === 17) {
                mail_account_id = 6;
            }
            else if (payload.am_section_id === 47) {
                mail_account_id = 11;
            }


            // alert("eese")

            const citiesRef = collection(db, "response");

            // alert("eeswqwqwse")
            if (payload.am_section_id === 10 || payload.am_section_id === 26) {


                // Create a query against the collection.
                query11 = query(citiesRef, where("Timestamp", ">", Math.floor(new Date().getTime() / 1000)));


         

            } else {
                // query11 = db.collection("response").where("MailAccountId", "==", mail_account_id).where("Timestamp", ">", Math.floor(new Date().getTime() / 1000)).orderBy("Timestamp", "desc");
                query11 = query(citiesRef, where("MailAccountId", "==", mail_account_id), where("Timestamp", ">", Math.floor(new Date().getTime() / 1000)), orderBy("Timestamp", "desc"));


            }

            // let usera: any = yield call(authset)

            // fire_payload_set = fire_payload
  


            if (unsubscribe_mail) unsubscribe_mail()
            const channels = yield call(createEventChannel)
            console.log(channels)
            while (true) {

                const getmails = yield take(channels);
                console.log(getmails)
                yield put(change_fire_mail(getmails));

            }





        }
    }
}






let queryline: any
function* handle_get_fire_line(action: any): any {

    const lineRef = collection(db, "line");

    if (action.payload.am_section_id === 10 || action.payload.am_section_id === 26) {

        queryline = query(lineRef, where("coming_response_count", "==", 0), where("timestamp", ">", Math.floor(new Date().getTime() / 1000)), orderBy("timestamp", "desc"));

    } else {
        queryline = query(lineRef, where("section_id", "==", action.payload.am_section_id), where("timestamp", ">", Math.floor(new Date().getTime() / 1000)), orderBy("timestamp", "desc"));
    }
    // alert("line")

    if (unsubscribe_line_all) unsubscribe_line_all()
    const linechannels = yield call(lineEventChannel)
    while (true) {

        const getline = yield take(linechannels);
        console.log(getline)
        yield put(change_fire_line(getline));

    }




}



let querylinedetail: any
function* handle_get_fire_line_detail(action: any): any {
    
    const state = yield select();

    let line_user_details = state.line.line_user_details

    // alert(line_user_details.line_user_id)
    let lines = state.line.lines
    const linedetailRef = collection(db, "line");

    let timestamp: string = "0"
    lines.map(function (value: any) {
        timestamp = value.timestamp > timestamp ? value.timestamp : timestamp
        return value
    })


    querylinedetail = query(linedetailRef, where("line_user_id", "==", line_user_details.line_user_id), where("line_account_section_id", "==", line_user_details.line_account_section_id), where("timestamp", ">", parseInt(timestamp)), orderBy("timestamp", "desc"));



    if (unsubscribe) unsubscribe() //前回のリッスンを終わらせ多重受信を防ぐ

    const linedetailchannels = yield call(lineDetailEventChannel)
    while (true) {

        const getlinedetail = yield take(linedetailchannels);
        console.log(getlinedetail)
        lines = lines.concat(getlinedetail)

        yield put(Line.get_lines_success(lines))
        yield put(Line.change_line_bottom(true))


    }


}




let querymypage: any
function* handle_get_fire_mypage(action: any): any {


    const mypageRef = collection(db, "mypage");

    if (action.payload.am_section_id === 10 || action.payload.am_section_id === 26) {
        console.log("マイページ取得開始")
console.log(Math.floor(new Date().getTime() / 1000))
        querymypage = query(mypageRef, where("coming_response_count", "==", 0), where("timestamp", ">", Math.floor(new Date().getTime() / 1000)), orderBy("timestamp", "desc"));

    } else {
        querymypage = query(mypageRef, where("section_id", "==", action.payload.am_section_id), where("timestamp", ">", Math.floor(new Date().getTime() / 1000)), orderBy("timestamp", "desc"));
    }

    if (unsubscribe_mypage) unsubscribe_mypage()
    const mypagechannels = yield call(mypageEventChannel)
    while (true) {

        const getmypage = yield take(mypagechannels);
        console.log(getmypage)
        yield put(change_fire_mypage(getmypage));

    }





}


// function authset() {
//     // alert("おうｔｈ")
//     return new Promise(resolve => {

//         onAuthStateChanged(auth, user => {
    
//             resolve(user)


//         })
      
//     })
// }

//                     .then((userCredential) => {
//                         // alert("りぞ")

//                         resolve(userCredential)
//                         //                 // function (e) {
//                         // alert(user ? user.uid : "ss")

//                         // const citiesRef = collection(db, "response");
//                         //                 console.log('firebasesuccess');
//                     })


// function tokenset() {
//     return new Promise(resolve => {
//         setPersistence(auth, browserSessionPersistence)
//             .then(() => {
//                 //         // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
//                 //         // firebase.auth().signInWithCustomToken(firebasetoken).then(
//                 signInWithCustomToken(auth, fire_payload_set)
//                     .then((userCredential) => {
//                         // alert("りぞ")

//                         resolve(userCredential)
//                         //                 // function (e) {
//                         // alert(user ? user.uid : "ss")

//                         // const citiesRef = collection(db, "response");
//                         //                 console.log('firebasesuccess');
//                     })
//             })
//     })
// }


let unsubscribe_mail: any
async function createEventChannel() {
    // alert("ちゃねる")


    // alert("先？")
    // alert(user ? user.uid : "user")
    const listener: any = eventChannel(
        emit => {


            unsubscribe_mail = onSnapshot(query11, { includeMetadataChanges: true }, (snapshot: any) => {
                snapshot.docChanges().forEach((change: any) => {
                    // alert(change.type)
                    if (change.type === 'added') {
                        if (change.doc.data().MailAccountId !== 8 && change.doc.data().MailAccountId !== 9) {//テナントマンスリー省く

                            console.log(change.doc.data())
                            // alert(change.doc.data().MailAccountId)
                            return emit(change.doc.data())
                            // }
                        }
                    }
                })
            })


            return () => unsubscribe_mail;


        })
    console.log(listener)
    // alert("げｔ")
    return listener;

    //     });
}



let unsubscribe_line_all: any
async function lineEventChannel() {
    // alert("ちゃねる")


    // alert("先？")
    // alert(user ? user.uid : "user")
    const listener: any = eventChannel(
        emit => {


            unsubscribe_line_all = onSnapshot(queryline, { includeMetadataChanges: true }, (snapshot: any) => {
                snapshot.docChanges().forEach((change: any) => {
                    // alert(change.type)
                    if (change.type === 'added') {

                        console.log(change.doc.data())
                        // alert(change.doc.data().MailAccountId)
                        return emit(change.doc.data())
                        // }

                    }
                })
            })


            return () => unsubscribe_line_all;


        })
    console.log(listener)
    // alert("げｔ")
    return listener;

    //     });
}



let unsubscribe: any
async function lineDetailEventChannel() {
    // alert("ちゃねる")


    // alert("先？")
    // alert(user ? user.uid : "user")
    const listener: any = eventChannel(
        emit => {


            unsubscribe = onSnapshot(querylinedetail, { includeMetadataChanges: true }, (snapshot: any) => {
                snapshot.docChanges().forEach((change: any) => {
                    // alert(change.type)
                    if (change.type === 'added') {

                        console.log(change.doc.data())
                        // alert(change.doc.data().MailAccountId)
                        return emit(change.doc.data())
                        // }

                    }
                })
            })


            return () => unsubscribe;


        })
    console.log(listener)
    // alert("げｔ")
    return listener;

    //     });
}


let unsubscribe_mypage: any
async function mypageEventChannel() {
    // alert("ちゃねる")


    // alert("先？")
    // alert(user ? user.uid : "user")
    const listener: any = eventChannel(
        emit => {


            unsubscribe_mypage = onSnapshot(querymypage, { includeMetadataChanges: true }, (snapshot: any) => {
                snapshot.docChanges().forEach((change: any) => {
                    // alert(change.type)
                    if (change.type === 'added') {

                        console.log(change.doc.data())
                        // alert(change.doc.data().MailAccountId)
                        return emit(change.doc.data())
                        // }

                    }
                })
            })


            return () => unsubscribe_mypage;


        })
    console.log(listener)
    // alert("げｔ")
    return listener;

    //     });
}

