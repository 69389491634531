const authentication = require('../../../react-azure-adb2c2').default;




export const list = (values: any) => {
    console.log(values)
    // alert(values.boshu_only)


    const url = process.env.REACT_APP_API_ROOMS_EX_URL + `/api/v1/search/rooms`;

    // const url = `http://localhost:5000/api/v1/search/rooms`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const alllist = (values: any) => {
    const url = process.env.REACT_APP_API_ROOMS_EX_URL + `/api/v1/search/rooms/all`;
    // const url = `http://localhost:5000/api/v1/search/rooms/all`;

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


export const response_list = (values: any) => {


    const url = process.env.REACT_APP_API_ROOMS_URL + `/response/ResponseSearchRoomsApi`;

    // const url = `https://localhost:44341/response/ResponseSearchRoomsApi`;

    // alert(JSON.stringify(values))
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};








// export const detail = (values:any) => {

//             const url = process.env.REACT_APP_API_BASE_URL+`/api/SearchRooms/detail?tatemono_id=` + values.tatemonoId+'&room_id='+values.roomId;

//             const token = authentication.getAccessToken();

//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }


export const update = (values: any) => {

    const token = authentication.getAccessToken();
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContacts/` + values.id;
    // const url = `https://localhost:44341/api/RentCustomerContacts/` + values.id;
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));


}

export function insert(values: any) {

    const token = authentication.getAccessToken();
    const url = process.env.REACT_APP_API_BASE_URL + `/api/RentCustomerContacts/`;
    // const url = `https://localhost:44341/api/RentCustomerContacts/`;


    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}