import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components'
import GoogleMapReact, { MapOptions, Maps } from 'google-map-react'
import withStyles from '@mui/styles/withStyles';
import RoomIcon from '@mui/icons-material/Room';
import Tooltip from '@mui/material/Tooltip';



const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))

const createMapOptions = (maps) => {
    return {
      mapTypeControlOptions: {
        position: maps.ControlPosition.TOP_RIGHT,
      },
      mapTypeControl: false,
      zoomControl: false,
      scaleControl: false,
      streetViewControl: true,
      fullscreenControl: false,
      // styles: [
      //   {
      //     featureType: 'water',
      //     elementType: 'geometry',
      //     stylers: [
      //       {
      //         color: '#e9e9e9',
      //       },
      //       {
      //         lightness: 17,
      //       },
      //     ],
      //   },
  
    }
  }


const Pin = styled.div``
class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false,
            lat: 34.36179852,
            lng: 134.048788977,
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()

        this.onChildMouseMove = this.onChildMouseMove.bind(this);
        this.onChildMouseUp = this.onChildMouseUp.bind(this);
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        // console.log(state)

        // this.props.get_banks(state)
    }


    _onBoundsChange = (center, zoom  , bounds, marginBounds ) => {
        // console.log(bounds)
        // this.props.onCenterChange(center);
        // this.props.onZoomChange(zoom);
      }

    onChildMouseUp(){
        this.setState({draggable: true})
      }
      onChildMouseMove(childKey, childProps, mouse) {
        // function is just a stub to test callbacks
        this.setState({
          draggable: false,
          lat: mouse.lat,
          lng: mouse.lng
        });
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div>
                <div style={{ float: "left" }} ><RoomIcon style={{ color: "#5cb85c" }}></RoomIcon> マップ</div>
                {/* <Button style={{ float: "right",  }} size="small" variant="contained" color="primary" onClick={(e) => { this.setState({ "modal": true }) }}>位置修正</Button> */}
                <div style={{clear:"both"}}></div>


    <div style={{ height: 455, marginTop:3, width:(window.innerWidth / 2) - 75} }> 
          <GoogleMapReact 

            bootstrapURLKeys={{
              key: 'AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo'
            }}


            defaultCenter={{
                lat: this.props.rent_m_corporates.lat ? parseFloat(this.props.rent_m_corporates.lat) : 34.36179852,
                lng: this.props.rent_m_corporates.lng ? parseFloat(this.props.rent_m_corporates.lng) : 134.048788977
            }}

            // center={{
            //     lat: 34.36179852,
            //     lng: 134.048788977
            // }}

            defaultZoom={13}
            
            options={createMapOptions}
            draggable={true}
            onBoundsChange={this._onBoundsChange}
            
            onChildMouseUp={this.onChildMouseUp}
            onChildMouseMove={this.onChildMouseMove}
            // onZoomChanged={this.handleZoomChanged.bind(this)}

            //  draggable={false}
            //  onChildClick={this._onChildClick}
          // onChildMouseDown={this.onChildMouseDown.bind(this)}
          // onChildMouseUp={this.onChildMouseUp.bind(this)}
          // onChildMouseMove={this.onCircleInteraction.bind(this)}
          >
            {/* <Marker position={{lat: 34.36179852, lng: 134.048788977}} /> */}
            



            {this.props.rent_corporate_offices_list.filter((a) => a.lat && a.lng).map(function(value, key){
                    return <Pin

                    key={"office" + value.id}
    
                    // style={{ position: 'absolute', width: 40, height: 40, left: -20, top: -20, cursor: 'pointer', borderRadius: 40 }}
                    // onDrag={(map) => map.a}
                    //  draggable={true}
                    // lat={this.props.tenant_detail.articleLat > 0 ? this.props.tenant_detail.articleLat : 34.36179852}
                    // lng={this.props.tenant_detail.articleLong > 0 ? this.props.tenant_detail.articleLong : 134.048788977}
                    lat={parseFloat(value.lat)}
                    lng={parseFloat(value.lng)}
                    // lat={ this.props.claso_blog_places.lat}
                    // lng={ this.props.claso_blog_places.lng}
                  //  onDragend={this.onMarkerDragEnd}
                  >
                      <Tooltip title={value.name}><div style={{ borderRadius:15, backgroundColor:"blue",width:"20px", height:"20px", marginLeft:-10, marginTop:-10}}></div></Tooltip>
                    {/* <img src={ie} style={{ "width": 40 }} /> */}
                    {/* <div style={{position: 'absolute',left: -30,whiteSpace: "nowrap",padding:2,paddingLeft:10,paddingRight:10,borderRadius: 10,backgroundColor:"#333333",color:"#FFF",fontWeight:"bold"}}></div>  */}
    
                  </Pin>
                },this)}

                {this.props.rent_responses_contract_list.filter((a) => a.Ido && a.Keido).map(function(value, key){
                    return <Pin

                    key={"bukken" + value.id}
    
                    // style={{ position: 'absolute', width: 40, height: 40, left: -20, top: -20, cursor: 'pointer', borderRadius: 40 }}
                    // onDrag={(map) => map.a}
                    //  draggable={true}
                    // lat={this.props.tenant_detail.articleLat > 0 ? this.props.tenant_detail.articleLat : 34.36179852}
                    // lng={this.props.tenant_detail.articleLong > 0 ? this.props.tenant_detail.articleLong : 134.048788977}
                    lat={value.Ido}
                    lng={value.Keido}
                    // lat={ this.props.claso_blog_places.lat}
                    // lng={ this.props.claso_blog_places.lng}
                  //  onDragend={this.onMarkerDragEnd}
                  >
                      <Tooltip title={value.Tatemono_Name}><div style={{ borderRadius:15, backgroundColor: (value.Madori_No >= 1 && value.Madori_No <=8) || value.Madori_No ==51 ? "green" : value.Madori_No >= 9 && value.Madori_No <=12 ? "yellow" : value.Madori_No >= 13 ? "red" : "" , width:"20px", height:"20px", marginLeft:-10, marginTop:-10}}></div></Tooltip>
                    {/* <img src={ie} style={{ "width": 40 }} /> */}
                    {/* <div style={{position: 'absolute',left: -30,whiteSpace: "nowrap",padding:2,paddingLeft:10,paddingRight:10,borderRadius: 10,backgroundColor:"#333333",color:"#FFF",fontWeight:"bold"}}></div>  */}
    
                  </Pin>
                },this)}




            
            {/* <Pin lat={this.props.rent_m_corporates.lat} lng={this.props.rent_m_corporates.lng}>ここ</Pin> */}
            {/* <Pin lat={43.0582954} lng={141.3466919}>ここ</Pin> */}
            {
                



            }
          </GoogleMapReact>
        </div>
        <div style={{backgroundColor:"green", float:"left", fontSize:11, marginRight:5, padding:3, borderRadius:3}}>1R～2K</div>
        <div style={{backgroundColor:"yellow", float:"left", fontSize:11, marginRight:5, padding:3, borderRadius:3}}>2DK～2SLDK</div>
        <div style={{backgroundColor:"red", float:"left", fontSize:11, marginRight:5, padding:3, borderRadius:3}}>3K～</div>
    </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_responses_contract_list: state.corporation.rent_responses_contract_list,
        rent_corporate_offices_list: state.corporation.rent_corporate_offices_list,
        // rent_corporate_offices_list: state.corporation.rent_corporate_offices_list,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




