import React, { Component } from 'react';

import { connect } from 'react-redux';
import TodayList from "./TodayList";
import Select from '@mui/material/Select';
import '../../Css/Table.css';
import Autocomplete from '@mui/material/Autocomplete';
import * as Calendarsaga from './Saga/Calendar';

import Grid from '@mui/material/Grid';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import StoreIcon from '@mui/icons-material/Store';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Event from '@mui/icons-material/Event';
import ListItemText from '@mui/material/ListItemText';
import { FormControl, InputLabel, MenuItem, Checkbox, FormControlLabel } from '@mui/material';

// const selectStyles = {
//   menu: base => ({
//     ...base,
//     zIndex: 0
//   })
// };


// const RenderSelectInput = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="店舗を選択"
//   />
// )

// const RenderSelectUsers = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="追客担当を選択"
//   />
// )


class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,

    };

    let formData = {
      email: '',
      password: '',
      tatemonoName: '',
      applicationDate: null,
      contractionDateStart: null,
      contractionDateEnd: null,
      condition: "1",
      primaryUserId: 0,
      name: null,
      sectionId: this.props.user_details.amSectionId > 0 ? this.props.user_details.amSectionId : 0,
    }



    this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.handlePrimaryUserIdChange = this.handlePrimaryUserIdChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChangeType = this.onChangeType.bind(this);

  }


  handleChangeSectionId(e, newValue) {
    if (newValue) {
      let calendar_list_form = this.props.calendar_list_form
      calendar_list_form.section_id = newValue.tenpo_no
      this.props.change_calendar_list_form(calendar_list_form);
    } else {
      let calendar_list_form = this.props.calendar_list_form
      calendar_list_form.section_id = 0

      this.props.change_calendar_list_form(calendar_list_form);
    }
    this.props.get_calendar_list();

  }

  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")


  }

  handleChange = (event) => {
    let calendar_list_form = this.props.calendar_list_form

    if(event.target.name == "section_id_os"){
      calendar_list_form[event.target.name] = event.target.checked
        if(event.target.checked){
          calendar_list_form["section_id"] = [45, 34, 501, 502, 503, 7]
        }else{
          calendar_list_form["section_id"] = []
        }
        
    }else{
      calendar_list_form[event.target.name] = event.target.value
    }

    this.props.change_calendar_list_form(calendar_list_form);

    this.props.get_calendar_list()

    this.setState({
      refresh: !this.state.refresh    //再描画
    });


  }

  handlePrimaryUserIdChange(e, newValue) {

    // alert(newValue.id)
    if (newValue) {
      let calendar_list_form = this.props.calendar_list_form
      calendar_list_form.user_id = newValue.id
      this.props.change_calendar_list_form(calendar_list_form);
    } else {
      let calendar_list_form = this.props.calendar_list_form
      calendar_list_form.user_id = 0

      this.props.change_calendar_list_form(calendar_list_form);
    }
    this.props.get_calendar_list();


    // if (primaryUserId) {
    //   let formData = this.props.responselist.formData
    //   formData.primaryUserId = primaryUserId.value
    //   this.setState(formData);
    // } else {
    //   let formData = this.props.responselist.formData
    //   formData.primaryUserId = 0
    //   // this.props.change_formData(formData);
    // }
  };
  handleContractionDateStartChange = date => {
    let formData = this.props.responselist.formData
    formData.contractionDateStart = date
    // this.props.change_formData(formData);

  };

  handleContractionDateEndChange = date => {
    let formData = this.props.responselist.formData
    formData.contractionDateEnd = date
    // this.props.change_formData(formData);

  };

  onChangeType(type) {
    this.props.change_calendar_type(type)

    // let calendar_list_form = this.props.calendar_list_form

    // if(type == 1){// 全店
    //   calendar_list_form.section_id = 0
    // }else if(type == 2){// 担当別
    //   calendar_list_form.section_id = this.props.user_details.amSectionId
    // }

    // calendar_list_form.start = moment().format('YYYY-MM-DD HH:mm:ss')
    // calendar_list_form.end = moment().add(1, 'd').add(-1, 's').format('YYYY-MM-DD HH:mm:ss')
    // this.props.change_calendar_list_form(calendar_list_form);

    this.props.get_calendar_list()

    this.setState({
      change: !this.state.change
    });

  }



  render() {



    return (
      <div>


        <Grid container style={{ width: 170 }}>
          <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 0 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<StoreIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(0)} >今週</Button></Grid>
          <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 1 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<EventNoteIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(1)} >全店</Button></Grid>
          <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 2 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<PermContactCalendarIcon />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(2)} >担当別</Button></Grid>
          <Grid xs={6} style={{ padding: 5 }}><Button variant={this.props.calendar_type == 3 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<Event />} style={{ paddingLeft: 0, paddingRight: 0 }} onClick={() => this.onChangeType(3)} >日別</Button></Grid>

          <Grid xs={12} style={{ padding: 5 }}>







            {this.props.calendar_search_store_list && this.props.calendar_search_store_list.length > 0 ? <FormControl size="small" style={{ width: "100%", marginTop:20 }}>
              <InputLabel>部署を選択</InputLabel>
              <Select
                id="section_id"
                name="section_id"
                onChange={this.handleChange}
                value={this.props.calendar_list_form.section_id}
                label="部署を選択"
                multiple
                renderValue={() => this.props.calendar_list_form.section_id.length > 0 ? this.props.calendar_list_form.section_id.map(function (item) {
                  return parseInt(item) > 0 && this.props.calendar_search_store_list.filter(a => a.tenpo_no === parseInt(item)).length > 0 ? this.props.calendar_search_store_list.filter(a => a.tenpo_no === parseInt(item))[0].tenpo_name : ""
                }, this).join(',') : ""}
                variant='standard'
                
              >
                {this.props.calendar_search_store_list && this.props.calendar_search_store_list.length > 0 ? this.props.calendar_search_store_list.map(function (item, i) {
                  return (
                    <MenuItem value={item.tenpo_no}><Checkbox checked={this.props.calendar_list_form.section_id && this.props.calendar_list_form.section_id.indexOf(item.tenpo_no) > -1} /><ListItemText primary={item.tenpo_name} /></MenuItem>
                  );

                }, this) : ""}
              </Select>
            </FormControl> : ""}

            {this.props.calendar_search_store_list && this.props.calendar_search_store_list.length > 0 ? <FormControlLabel
                        control={
                        <Checkbox
                            name="section_id_os"
                            checked={this.props.calendar_list_form.section_id_os}
                            onChange={this.handleChange}
                            color="primary"
                            style={{marginLeft: 10}}
                        />
                        }
                        label="管理部のみ"
            />:""}

            {/* {this.props.calendar_search_store_list.map(function (item, i) {
                      return (
                          <div>{item.tenpo_no}</div>
                      );

                  }, this)} */}




            {/* {this.props.calendar_search_store_list.length > 0 && this.props.user_details ? <Autocomplete
              id="combo-box-demo"
              options={this.props.calendar_search_store_list}
              getOptionLabel={(option) => option.tenpo_name}
              defaultValue={this.props.calendar_search_store_list.filter(a => a.tenpo_no === this.props.calendar_list_form.section_id)[0]}
              value={this.props.calendar_search_store_list.filter(a => a.tenpo_no === this.props.calendar_list_form.section_id)[0]}
              style={{ width: "100%" }}
              onChange={this.handleChangeSectionId}
              renderInput={(params) => <TextField
                {...params} label="部署を選択" />}

            /> : ""} */}
          </Grid>

          <Grid xs={12} style={{ padding: 5 }}>
            {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
              id="combo-box-demo"

              options={this.props.users.length === 0 && this.props.calendar_list_form.user_id ? [this.props.users.filter(a => String(a.id) === String(this.props.calendar_list_form.user_id))[0]]
                : this.props.users && this.props.calendar_list_form.section_id ?
                  this.props.users.filter(a => this.props.calendar_list_form.section_id.includes(a.am_section_id) 

                    // (this.props.calendar_list_form.section_id.includes(2) ? a.id == 1124 : "") || //小野
                    // (this.props.calendar_list_form.section_id.includes(17) ? a.id == 1020 : "") ||//和田
                    // (this.props.calendar_list_form.section_id.includes(4) ? a.id == 1109 : "") || //清藤
                    || (this.props.calendar_list_form.section_id.includes(3) ? a.id == 1015 : "")  //吉田将 駅前
                    // || (this.props.calendar_list_form.section_id.includes(6) ? a.id == 1015 : "")  //吉田将 中央公園
                    // (this.props.calendar_list_form.section_id.includes(3) ? a.id == 1191 : "") || //株屋根
                    // (this.props.calendar_list_form.section_id.includes(3) ? a.id == 1121 : "") || //山内
                    // (this.props.calendar_list_form.section_id.includes(2) ? a.id == 1119 : "") || //中村
                    // (this.props.calendar_list_form.section_id.includes(2) ? a.id == 1419 : "") || //大森
                    // (this.props.calendar_list_form.section_id.includes(1) ? a.id == 1005 : "") ||//若さ
                    // (this.props.calendar_list_form.section_id.includes(1) ? a.id == 1014 : "") ||//丸岡
                    // (this.props.calendar_list_form.section_id.includes(1) ? a.id == 1232 : "") ||//村田

                    || (this.props.calendar_list_form.section_id.includes(501) ? a.section_group == 1 : "")
                    || (this.props.calendar_list_form.section_id.includes(502) ? a.section_group == 2 : "")
                    || (this.props.calendar_list_form.section_id.includes(503) ? a.section_group == 3 : ""))
                  :
                  this.props.users}

              getOptionLabel={(option) => option.user_name_all ? option.user_name_all : ''}
              // name="ownerId"
              value={this.props.calendar_list_form.user_id ? this.props.users.filter(a => String(a.id) === String(this.props.calendar_list_form.user_id))[0] : {}}



              // options={this.props.calendar_list_form.section_id ? this.props.users.filter(a=>a.) : this.props.users}
              // getOptionLabel={(option) => option.label}
              // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
              // value={this.props.users_select.filter(a => a.value === this.props.calendar_list_form.user_id)[0]}
              style={{ width: "100%" }}
              onChange={this.handlePrimaryUserIdChange}
              size="small"
              renderInput={(params) => <TextField variant='standard'
                {...params} label="担当を選択" />}

            /> : ""}
          </Grid>

          {this.props.calendar_viewType == "day" ? <Grid xs={12} style={{ padding: 5, paddingBottom: 0 }}><TodayList calendar_list_form={this.props.calendar_list_form} /></Grid> : ""}

        </Grid>
      </div >
    );
  }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users: state.calendar.users,
    users_select: state.masterReducer.users_select,
    section_select: state.masterReducer.section_select,
    shops: state.masterReducer.shops,

    calendar_list: state.calendar.calendar_list,
    calendar_list_form: state.calendar.calendar_list_form,
    calendar_type: state.calendar.calendar_type,
    calendar_toggle: state.calendar.calendar_toggle,
    calendar_viewType: state.calendar.calendar_viewType,

    calendar_search_store_list: state.calendar.calendar_search_store_list,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    change_calendar_list_form(state) {
      dispatch(Calendarsaga.change_calendar_list_form(state))
    },
    change_calendar_type(state) {
      dispatch(Calendarsaga.change_calendar_type(state))
    },
    change_calendar_toggle(state) {
      dispatch(Calendarsaga.change_calendar_toggle(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);

// ResponseListSearch = reduxForm({
//   form: 'formset', // a unique identifier for this form
//   onSubmit: submit
//   //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseListSearch)
