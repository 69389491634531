import React, { Component } from 'react';

import { connect } from 'react-redux';

import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import * as Contracts from './Saga/Contracts';
import Button from '@mui/material/Button';
class ContractSheetModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  

  render() {
    return (
        <div>
        <Modal fade={false} isOpen={this.props.send_complete} toggle={() => {this.props.change_send_complete(false);this.props.change_send_complete_text({header:'',body:''});}} size="lg" style={{minHeight:'90%',transform:'translate(0, 0)',display:'flex',alignItems: 'center',justifyContent: 'center'}} >
            <ModalHeader toggle={() => { this.setState({sendModal: false})}}>{this.props.send_complete_text.header}
            </ModalHeader>
            <ModalBody>
                <p style={{fontSize:'16px'}}>{this.props.send_complete_text.body}</p>
                <p style={{fontSize:'16px'}}></p>
            </ModalBody>
            <ModalFooter>
                <div style={{width:'100%'}}>
                    <Button variant="contained" onClick={() => {this.props.change_send_complete(false);this.props.change_send_complete_text({header:'',body:''})}}  style={{ float: "right", margin: 5, backgroundColor:'#fafafa',color:"#000" }}>
                        閉じる
                    </Button>
                </div>
            </ModalFooter>
        </Modal>
        </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {
    send_complete: state.contracts.send_complete,
    send_complete_text: state.contracts.send_complete_text,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_send_complete(state) {
        dispatch(Contracts.change_send_complete(state))
    },
    change_send_complete_text(state) {
        dispatch(Contracts.change_send_complete_text(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ContractSheetModal);
