import React, { Component } from 'react';
// import firebase from "firebase";
// import firebase from 'firebase/compat/app';
// import 'firebase/app'
// import 'firebase/firestore'
// import 'firebase/auth'
import { connect } from 'react-redux';
//import { Link } from 'react-router-dom';
//import CustomerInfoEdit from './CustomerInfoEditComponent';
//import CustomerInfoDisp from './CustomerInfoDispComponent';

import './Css/Line.css';
import LineRightTextComponent from './LineRightTextComponent';
import LineRightImgComponent from './LineRightImgComponent';
import LineRightType1Component from './LineRightType1Component';
import LineRightType2Component from './LineRightType2Component';
import LineRightType3Component from './LineRightType3Component';
import LineRightType4Component from './LineRightType4Component';
import LineRightType5Component from './LineRightType5Component';
import LineRightMypageComponent from './LineRightMypageComponent';
import LineRightTemplateComponent from './LineRightTemplateComponent';
import LineLeftTextComponent from './LineLeftTextComponent';
import LineLeftImgComponent from './LineLeftImgComponent';
import LineLeftStickerComponent from './LineLeftStickerComponent';
import LineLeftFileComponent from './LineLeftFileComponent';
import LineRightFile from './LineRightFile';

import * as Line from './Saga/Line';


import * as Firebase from '../../Store/Firebase';

let unsub
var lines
// function separate(num) {
//     // 文字列にする
//     num = String(num);

//     var len = num.length;

//     // 再帰的に呼び出すよ
//     if (len > 3) {
//         // 前半を引数に再帰呼び出し + 後半3桁
//         return separate(num.substring(0, len - 3)) + ',' + num.substring(len - 3);
//     } else {
//         return num;
//     }
// }



var timestamp = ""
class Lines extends Component {

    constructor(props) {
        super(props);
        this.state = {
            mode: '1',
            messages: [],
            messages2: {},
            scrollTop: 0,
            old_id: 0,
            back_date: "",
            take: 20,
            reflesh: false
            // top: this.props.lines[0].id
        };
        console.log(this.props.lines)
        lines = this.props.lines




        // this.props.lines.map(){
        //     this.Print()
        // }
        //
        // this.send = this.send.bind(this);
        // this.handleScroll = this.handleScroll.bind(this);
        // this.lineopen = this.lineopen.bind(this);




        // var result = props.lines.map(function( value ) {

        //         // alert()
        //     return this.Print(value)

        //     //配列の各要素を2倍にする
        //     // return value * 2;

        // });
    }


    format(value) {

        // コンポーネントの表示用のやつ
        if (value.replytoken === 'globalcenter') {
            if (value.message_type === 'text') {
                return <LineRightTextComponent msg={value} />
            } else if (value.message_type === 'image') {
                return <LineRightImgComponent msg={value} />
            } else if (value.message_type === '1') {//最初の
                return <LineRightType1Component msg={value} />
            } else if (value.message_type === '2') {//店舗
                return <LineRightType2Component msg={value} />
            } else if (value.message_type === '3') {//間取り
                return <LineRightType3Component msg={value} />
            } else if (value.message_type === '4') {//賃料
                return <LineRightType4Component msg={value} />
            } else if (parseInt(value.message_type) === 5 || value.message_type == "roomlist" || value.message_type == 'stocklist') {//物件
                return <LineRightType5Component msg={value} />
            } else if (value.message_type === "file") {//ファイル
                return <LineRightFile msg={value} />
            } else if (value.message_type == "mypage") {//マイページ案内
                return <LineRightMypageComponent msg={value} />
            } else if (value.message_type == "template") {//自動応答
                return <LineRightTemplateComponent msg={value} />
            } else {
                //message = snapshot.val().type;
            }
        } else {
            if (value.message_type === 'text') {
                return <LineLeftTextComponent msg={value} />
            } else if (value.message_type === 'image') {
                return <LineLeftImgComponent msg={value} />
            } else if (value.message_type === 'sticker') {
                return <LineLeftStickerComponent msg={value} />
            } else if (value.message_type === 'file' || value.message_type === 'video' || value.message_type === 'audio') {
                return <LineLeftFileComponent msg={value} />
            } else {
                //message = snapshot.val().type;
            }
        }





    }


    async componentDidMount() {

        this.props.change_line_bottom(true);

        // this.props.get_firestore_listen()
        // alert(linelistening)
        // if (linelistening) {
        //     alert("exist")
        //     linelistening()

        // }
        // if (unsub) {
        //     // alert("exist")
        //     unsub()
        // }


        // this.props.lines.map(function (value) {
        //     timestamp = value.timestamp > timestamp ? value.timestamp : timestamp
        // })
        // let times = Math.floor(new Date().getTime() / 1000)
        // alert(timestamp)
        // alert(times)
        // var db_response = firebase.firestore();
        // const query11 =db.collection("line").doc("7").collection(this.props.line_user_details.id).where("Timestamp",">",Math.floor( new Date().getTime() / 1000 ));]
        // let time = Math.floor(new Date().getTime() / 1000)
        // alert(this.props.line_user_details.id)
        // unsub = db_response.collection("line").where("line_user_id", "==", this.props.line_user_details.id).where("line_account_section_id", "==", this.props.line_user_details.line_account_section_id).where("timestamp", ">", parseInt(timestamp)).orderBy("timestamp", "desc")
        //     .onSnapshot(snapshot => {
        //         snapshot.docChanges().forEach(function (change) {
        // alert(timestamp)

        // this.setState({ reflesh: !this.state.reflesh })
        //     }, this)
        // });

    }
    componentDidUpdate(prevProps) {
        // alert("line")
        // if (prevProps.fire_line_detail !== this.props.fire_line_detail) {
        //     lines = this.props.lines

        //     // alert("line 更新")
        //     // console.log(change.doc.data())
        //     // this.props.get_lines_success(lines.concat(this.props.fire_line_detail))
        //     // this.props.change_line_bottom(true);
        //     // this.setState({ reflesh: !this.state.reflesh })
        // }
    }
    componentWillUnmount() {
        // alert("unmount")


    }
    render() {


        return (


            <div className="line-bc" id="line-bc">

                {this.props.contents.map(function (value) {
                    //   return value.eventType
                    //   alert()

                    return this.format(value)

                }, this)}
                {/* {timestamp} */}
            </div>





        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {

        // mail_new: state.mailReducer.mail_new,
        // lines: state.line.lines,
        line_bottom: state.line.line_bottom,
        line_user_details: state.line.line_user_details,
        lines: state.line.lines,
        line_open_flg: state.line.line_open_flg,
        fire_line_detail: state.firebasestoreReducer.fire_line_detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // change_mail_new_success(state) {
        //    dispatch(Mail.change_mail_new_success(state))
        // },
        send_message(state) {
            dispatch(Line.send_message(state))
        },
        //  get_lines(state) {
        //     dispatch(Line.get_lines(state))
        //  },
        get_lines_success(state) {
            dispatch(Line.get_lines_success(state))
        },
        change_line_bottom(state) {
            dispatch(Line.change_line_bottom(state))
        },
        get_line_close(state) {
            dispatch(Line.get_line_close(state))
        },
        // get_line_open(state) {
        //     dispatch(Line.get_line_opens(state))
        // },
        get_fire_line_detail(state) {
            dispatch(Firebase.get_fire_line_detail(state))
        },

        // get_firestore_listen(state) {
        //     dispatch(Line.get_firestore_listen(state))
        // },

    };
}


export default connect(mapStateToProps, mapDispatchToProps)(Lines);
