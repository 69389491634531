import React, { Component, useRef } from 'react';
import { connect } from 'react-redux';
import ReactDOM from "react-dom";
import ReactDataGrid from 'react-data-grid';
import { Editors, Formatters, EditorBase } from "react-data-grid-addons";
// import * as Tenants from './Saga/Tenants';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Forms from './Css/Form.module.css';
import * as Estimates from './Saga/Estimates';
import Button from '@mui/material/Button';
// import '../../Css/Table.css';
// import '../../Css/Grid.css';
import Grid from './Css/Grid.module.css'
// import './Css/Tenant.css';
// import { EditorBase } from 'common/editors';
let autokana1;
const { DropDownEditor } = Editors;

// const { DropDownFormatter } = Formatters;
// const month1Types = [
//     { id: 0 ,value: "" },
//     { id: 2,value:"込み"},

// ];
// const month2Types = [
//     { id: 0 ,value: "定額" },
//     { id: 1 ,value: "実費" },
//     { id: 2,value:"込み"},

// ];
const payTypes = [
  { id: 0, value: "" },
  { id: 2, value: "込み" },
  { id: 3, value: "無し" }
];
const payUpdateTypes = [
  { id: 0, value: "" },
  { id: 3, value: "無し" },
];
const payTaikyoTypes = [
  { id: 1, value: "実費" },
  { id: 2, value: "込み" },
];

const monthTypes = [
  { id: 0, value: "" }
  , { id: 1, value: "1ヵ月" }
  , { id: 2, value: "2ヵ月" }
  , { id: 3, value: "3ヵ月" }
  , { id: 4, value: "4ヵ月" }
  , { id: 5, value: "5ヵ月" }
  , { id: 6, value: "6ヵ月" }
  , { id: 7, value: "7ヵ月" }
  , { id: 8, value: "8ヵ月" }
  , { id: 9, value: "9ヵ月" }
  , { id: 10, value: "10ヵ月" }
  , { id: 11, value: "11ヵ月" }
  , { id: 12, value: "12ヵ月" }
];
const yearTypes = [
  { id: 0, value: "" }
  , { id: 1, value: "1年間" }
  , { id: 2, value: "2年間" }

];
const year = [];
year[""] = 0;
year["1年間"] = 1;
year["2年間"] = 2;


const month = [];
month[""] = 0;
month["1ヵ月"] = 1;
month["2ヵ月"] = 2;
month["3ヵ月"] = 3;
month["4ヵ月"] = 4;
month["5ヵ月"] = 5;
month["6ヵ月"] = 6;
month["7ヵ月"] = 7;
month["8ヵ月"] = 8;
month["9ヵ月"] = 9;
month["10ヵ月"] = 10;
month["11ヵ月"] = 11;
month["12ヵ月"] = 12;


const monthno = [];
monthno[0] = "";
monthno[1] = "1ヵ月";
monthno[2] = "2ヵ月";
monthno[3] = "3ヵ月";
monthno[4] = "4ヵ月";
monthno[5] = "5ヵ月";
monthno[6] = "6ヵ月";
monthno[7] = "7ヵ月";
monthno[8] = "8ヵ月";
monthno[9] = "9ヵ月";
monthno[10] = "10ヵ月";
monthno[11] = "11ヵ月";
monthno[12] = "12ヵ月";
class DropDownEditor2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rentMEtcId: props.value };

  }
  getInputNode() {

    return ReactDOM.findDOMNode(this);

  }
  getValue() {

    return { rentMEtcId: this.state.rentMEtcId };
  }
  onClick() {
    this.getInputNode().focus();
  }

  onDoubleClick() {
    this.getInputNode().focus();
  }
  onChange(value) {
    this.setState({ rentMEtcId: value.currentTarget.value });

  }
  render() {
    return (
      <select defaultValue={this.props.value} onBlur={this.props.onBlur} onChange={this.onChange.bind(this)} >
        {this.renderOptions()}
      </select>);
  }

  renderOptions() {
    let options = [];
    console.log(this.props)

    let types = this.props.options.etcTypes
    if (this.props.rowData.rentMAccountId === 16) {
      types = this.props.options.payUpdateTypes
    } else if (this.props.rowData.rentMAccountId === 59) {
      types = this.props.options.payUpdateTypes
    } else if (this.props.rowData.rentMAccountId === 17) {
      types = this.props.options.payTaikyoTypes
    }
    types.forEach(function (payload) {
      // if (typeof(name) === 'string') {
      options.push(<option key={payload.id} value={payload.value}>{payload.value}</option>);
      // } else {
      //   options.push(<option key={name.id} value={name.value} title={name.title}  >{name.text || name.value}</option>);
      // }
    }, this);

    return options;
  }
}
class MoneyEditComponent extends Component {




  constructor(props) {
    super(props);




    let columns1 = []
    let columns2 = []
    let columnsCorp1 = []
    let columnsCorp2 = []
    const cloumnkoumoku = {
      key: 'name', name: '項目名', width: 100
      , formatter: function (props) {
        return (props.row.rentMAccountId === 1 || props.row.rentMAccountId === 2 || props.row.rentMAccountId === 3 || props.row.rentMAccountId === 4) ? <div style={{ background: '#CCC', textAlign: 'Left', paddingLeft: "5px" }}>{props.value}</div> : <div style={{ textAlign: 'Left', paddingLeft: "5px" }}>{props.value}　</div>
      }
    }
    columns1.push(cloumnkoumoku)
    columns2.push(cloumnkoumoku)
    columnsCorp1.push(cloumnkoumoku)
    columnsCorp2.push(cloumnkoumoku)

    const columntypeDisp = {
      key: 'rentMEtcId', name: '請求', width: 80,

      editable: function (rowData) {
        return (rowData.id === 0) ? false : (rowData.rentMAccountId !== 17 && rowData.rentMAccountId !== 16 && rowData.rentMAccountId !== 59 ? false : true);
      },

      formatter: function (props) {

        //  console.log(props)
        //  alert(props.row.rentMEtcId)
        //     alert(this.props.payTypes.filter(a=>a.id===props.row.rentMEtcId)[0].value)
        return (props.row.rentMAccountId !== 17 && props.row.rentMAccountId !== 16 && props.row.rentMAccountId !== 59) ? <div style={{ background: '#CCC' }}>　</div> : (props.row.rentMAccountId === 1 ? <div style={{ background: '#CCC' }}>　</div> : <div style={{ background: 'pink' }}>　{this.props.payTypes.filter(a => a.id === props.row.rentMEtcId)[0].value}</div>);
      }.bind(this),
      editor: <DropDownEditor2 options={this.props} />
    }
    //   columns.push(columntypeDisp)
    columns1.push(columntypeDisp)
    columns2.push(columntypeDisp)
    columnsCorp1.push(columntypeDisp)
    columnsCorp2.push(columntypeDisp)






    const columnamountMonth = {
      key: 'amountMonth', name: '月数', width: 60,
      editor: <DropDownEditor options={this.props.monthTypes} />,
      formatter: function (props) {

        return (!props.row.rentMEtcId > 0 && props.row.rentMAccountId !== 17) ? (props.value === 0 ? "" : this.props.monthTypes.filter(a => a.id === props.value)[0].value) : <div style={{ background: '#CCC' }}>{(props.value === 0 ? "" : props.value)}　</div>;
      }.bind(this),
      editable: function (rowData) {
        return (rowData.rentMEtcId > 0 || rowData.rentMAccountId === 17) ? false : true;

      }
    }
    // columns.push(columnamountMonth)


    columns2.push(columnamountMonth)
    columnsCorp2.push(columnamountMonth)

    const columnamount = {
      key: 'amount', name: '金額', width: 100,
      formatter: function (props) {
        // console.log(props.row)
        return (!props.row.rentMEtcId > 0 && props.row.rentMAccountId !== 17) ? (props.value === 0 ? "" : <div style={{ textAlign: 'right', padding: '2px' }}>{props.value.toLocaleString()}</div>) : <div style={{ background: '#CCC' }}>{props.value === 0 ? "" : props.value}　</div>;
      },

      editable: function (rowData) {
        return (rowData.rentMEtcId > 0 || rowData.rentMAccountId === 17) ? false : true;

      }

    }

    columns1.push(columnamount)
    columns2.push(columnamount)
    columnsCorp1.push(columnamount)
    columnsCorp2.push(columnamount)


    const columntaxAmount = {
      key: 'taxAmount', name: '税', width: 50,
      formatter: function (props) {
        // console.log(props.row)

        return (!props.row.rentMEtcId > 0 && props.row.rentMAccountId !== 17) ? "" : <div style={{ background: '#CCC' }}>　</div>
      },

    }


    columns1.push(columntaxAmount)
    columns2.push(columntaxAmount)
    columnsCorp1.push(columntaxAmount)
    columnsCorp2.push(columntaxAmount)

    const columnamountYear = {
      key: 'eachYear', name: '年数', width: 80,
      formatter: function (props) {
        return (!props.row.rentMEtcId > 0) ? (props.value === 0 ? "" : this.props.yearTypes.filter(a => a.id === props.value)[0].value) : <div style={{ background: '#CCC' }}>{(props.value === 0 ? "" : props.value)}　</div>;
      }.bind(this),
      editor: <DropDownEditor options={this.props.yearTypes} />,
      editable: function (rowData) {
        return (rowData.rentMEtcId >= 0) ? false : true;

      }
    }
    columns1.push(columnamountYear)
    columnsCorp1.push(columnamountYear)


    const columntype = {
      key: 'billing', name: '入居者', width: 60,
      formatter: function (props) {
        // console.log(props.row)
        return (!props.row.rentMEtcId > 0 && props.row.rentMAccountId !== 17) ? "" : <div style={{ background: '#CCC' }}>　</div>
      },
    }

    //   columns.push(columntype)

    columnsCorp1.push(columntype)
    columnsCorp2.push(columntype)

    //   const columnworks= { key: 'works', name: '取扱', width: 60 ,
    //         formatter: function (props) {
    //             return (props.row.type===0) ?(props.value===1?Math.round(props.row.seikyuuGaku*1.1):""):<div style={{background: '#CCC'}}>{(props.value===0?"":props.value)}　</div>;
    //         },

    //     }
    //     // columns.push(columnworks)

    //     columns1.push(columnworks)
    //   columns2.push(columnworks)

    const columnremarks = { key: 'remarks', name: '備考', width: 100, editable: true }

    // columns.push(columnremarks)
    columns1.push(columnremarks)
    columns2.push(columnremarks)
    columnsCorp1.push(columnremarks)
    columnsCorp2.push(columnremarks)



    const columndelete = {
      key: 'delete', name: '削除', width: 40,
      formatter: function (props) {
        // console.log(props.row)
        return (props.row.rentMAccountId !== 16 && props.row.rentMAccountId !== 17) ? "" : <div style={{ background: '#CCC' }}>　</div>
      }
    }
    // columns.push(columndelete)
    columns1.push(columndelete)
    columns2.push(columndelete)
    columnsCorp1.push(columndelete)
    columnsCorp2.push(columndelete)
















    this.state = {
      customerinfo: this.props.forecasts,
      startDate: new Date(),
      refresh: true,
      columns1: columns1,
      columns2: columns2,
      columnsCorp1: columnsCorp1,
      columnsCorp2: columnsCorp2,

    };
    this.handleChange = this.handleChange.bind(this);

    // this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
    // this.onRowsTukiUpdated = this.onRowsTukiUpdated.bind(this);
    // this.onRowsKeiyakuUpdated = this.onRowsKeiyakuUpdated.bind(this);
    this.getLeaveRowCount3 = this.getLeaveRowCount3.bind(this);
    this.getLeaveRowCount4 = this.getLeaveRowCount4.bind(this);
    this.add3 = this.add3.bind(this);
    this.add4 = this.add4.bind(this);
    this.refresh = this.refresh.bind(this);

    this.getCellActions3 = this.getCellActions3.bind(this);
    this.getCellActions4 = this.getCellActions4.bind(this);


  }


  add3 = (value) => {

    this.props.add_estimateMonies3(value)
    this.refresh()

  }
  add4 = (value) => {

    this.props.add_estimateMonies4(value)
    this.refresh()

  }

  handleChange = (event) => {
    // alert(event.target.name)
    // console.log(event)

    let estimateData = this.props.estimateData

    estimateData[event.target.name] = event.target.value
    // }
    this.props.change_estimateData(estimateData)
    this.setState({ change: true })
  }
  handleFormSubmit(event) {
    event.preventDefault();
    // console.log(event)
    // console.log(this.props.forecasts);
  }

  onChange(e) {
    // console.log(e.target.value);
    this.setState({ name1: e.target.value });
  }

  componentDidMount() {
    if (this.props.estimate_after_flg === true) {

      this.props.change_estimate_after_flg(false)
      this.refresh()
    }
    // This method is called when the component is first added to the document
    // this.ensureDataFetched();
    // if(this.state.selected){
    window.addEventListener('scroll', event => this.watchCurrentPosition(), true)
    // }
    //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
  }
  watchCurrentPosition() {
    // console.log(document.activeElement.className)

    if (document.activeElement.className !== "" && document.activeElement.className.indexOf("editor-main")) {
      // document.activeElement.blur()
    }
  }



  componentDidUpdate(prevProps) {
    // alert(prevProps.estimateMonies1[0].amount)
    // alert(this.state.amount )



    // console.log(prevProps)
    // alert(prevProps.estimateMonies1[0].amount)
    // alert(this.props.estimateMonies1[0].amount )
    if (this.props.estimate_after_flg === true) {

      this.props.change_estimate_after_flg(false)
      this.refresh()
    }

    //   // This method is called when the route parameters change
    //   this.ensureDataFetched();
  }
  // This method is called when the route parameters change
  // this.ensureDataFetched();
  // let count = this.props.estimateMonies4.length;
  // if(this.state.refresh && count > 0) {
  //   count--; // hack for update data-grid
  //   this.setState({
  //     refresh: false
  //   });
  // }
  // }


  onRowsKoushinUpdated = ({ fromRow, toRow, updated }) => {
    // console.log(updated)

    let estimateMonies3 = this.props.estimateMonies3;
    for (let i = fromRow; i <= toRow; i++) {

      if ('amount' in updated) {
        estimateMonies3[i].amount = parseInt(updated.amount.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        }))



      }

      if (updated.eachYear && isNaN(updated.eachYear) === true) {
        estimateMonies3[i].eachYear = this.props.yearTypes.filter(a => a.value === updated.eachYear)[0].id
      } else if (isNaN(updated.eachYear) === false) {
        estimateMonies3[i].eachYear = 0
      }

      if ('remarks' in updated) {
        estimateMonies3[i].remarks = updated.remarks
      }




      if (updated.rentMEtcId === "込み") {
        estimateMonies3[i].rentMEtcId = 2
        estimateMonies3[i].amount = 0
        estimateMonies3[i].eachYear = 0
        estimateMonies3[i].tax = 0

      } else if (updated.rentMEtcId === "無し") {
        estimateMonies3[i].rentMEtcId = 3
        estimateMonies3[i].amount = 0
        estimateMonies3[i].eachYear = 0
        estimateMonies3[i].isKazei = 0

      } else if (updated.rentMEtcId === "") {
        estimateMonies3[i].rentMEtcId = 0
        // rows[i].seikyuuGaku=0
        // rows[i].seikyuuGakuTsukiSuu=0
        // rows[i].isKazei=0
        // rows[i].seikyuuNenSuu=0
      }
      if (updated.seikyuuNenSuuDisp) {
        estimateMonies3[i].eachYear = year[updated.seikyuuNenSuuDisp]
      }

      if (updated.typeDisp) {

        estimateMonies3[i].eachYear = 0
        estimateMonies3[i].seikyuuNenSuuDisp = ""

      }
      // rows[i] = { ...this.props.money_update[i], ...updated };
    }
    this.props.change_estimateMonies3(estimateMonies3);
    // this.forceUpdate();
    this.refresh();
    // this.forceUpdate();
    //   return this.props.rowss ;k

  };

  onRowsTaikyoUpdated = ({ fromRow, toRow, updated }) => {
    // console.log(updated)


    let estimateMonies4 = this.props.estimateMonies4;

    //  console.log(updated)

    for (let i = fromRow; i <= toRow; i++) {
      if ('amount' in updated) {
        estimateMonies4[i].amount = parseInt(updated.amount.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        }))

        if (estimateMonies4[i].amount % this.props.estimateMonies1.filter(a => a.rentMAccountId === 1)[0].amount === 0) {
          estimateMonies4[i].amountMonth = estimateMonies4[i].amount / this.props.estimateMonies1.filter(a => a.rentMAccountId === 1)[0].amount
        } else {
          estimateMonies4[i].amountMonth = 0
        }


      }

      if (updated.amountMonth && isNaN(updated.amountMonth) === true) {

        estimateMonies4[i].amountMonth = this.props.monthTypes.filter(a => a.value === updated.amountMonth)[0].id
        estimateMonies4[i].amount = this.props.estimateMonies1.filter(a => a.rentMAccountId === 1)[0].amount * estimateMonies4[i].amountMonth
        estimateMonies4[i].taxAmount = estimateMonies4.tax === 1 ? Math.round(estimateMonies4[i].amount * 0.1) : 0
      } else if (isNaN(updated.amountMonth) === false) {
        estimateMonies4[i].amountMonth = 0
        estimateMonies4[i].amount = 0
        estimateMonies4[i].taxAmount = 0
      }

      if ('remarks' in updated) {
        estimateMonies4[i].remarks = updated.remarks
      }
      if (updated.rentMEtcId === "込み") {
        estimateMonies4[i].rentMEtcId = 2
        estimateMonies4[i].amount = 0
        estimateMonies4[i].amountMonth = 0
        estimateMonies4[i].tax = 0
        estimateMonies4[i].eachYear = 0
      } else if (updated.rentMEtcId === "無し") {
        estimateMonies4[i].rentMEtcId = 3
        estimateMonies4[i].amount = 0
        estimateMonies4[i].tax = 0
        estimateMonies4[i].taxAmount = 0
        estimateMonies4[i].amountMonth = 0

        estimateMonies4[i].eachYear = 0
      } else if (updated.rentMEtcId === "") {
        estimateMonies4[i].rentMEtcId = 0
        estimateMonies4[i].amount = 0
        estimateMonies4[i].amountMonth = 0
        estimateMonies4[i].tax = 0
        estimateMonies4[i].eachYear = 0
      }



      if (updated.seikyuuGakuTsukiSuuDisp) {

        estimateMonies4[i].amount = month[updated.seikyuuGakuTsukiSuuDisp] * this.props.estimateMonies1[0].amount
        estimateMonies4[i].amountMonth = month[updated.seikyuuGakuTsukiSuuDisp]
      } else if (updated.seikyuuGakuTsukiSuuDisp === "") {
        estimateMonies4[i].amount = 0
        estimateMonies4[i].amountMonth = 0
      }

      if (updated.seikyuuGaku) {
        if (updated.seikyuuGaku % this.props.money_month[0].seikyuuGaku === 0) {
          estimateMonies4[i].amountMonth = updated.seikyuuGaku / this.props.estimateMonies1[0].amount
          estimateMonies4[i].seikyuuGakuTsukiSuuDisp = monthno[updated.seikyuuGaku / this.props.estimateMonies1[0].amount]

        } else {
          estimateMonies4[i].amountMonth = 0
          estimateMonies4[i].seikyuuGakuTsukiSuuDisp = ""
        }

        estimateMonies4[i].seikyuuGaku = month[updated.seikyuuGakuTsukiSuuDisp] * this.props.estimateMonies1[0].amount

      }



      // estimateMonies4[i] = { ...estimateMonies4[i], ...updated };
    }

    // this.props.change_estimateMonies4({});
    this.props.change_estimateMonies4(estimateMonies4);
    this.refresh();
    // let  estimateMonies1=  this.props.estimateMonies1
    // estimateMonies1[0].amount=10
    // this.props.change_estimateMonies1(estimateMonies1);

    // this.props.change_estimateMonies1_flg(true);
    // this.setState({refresh:!this.state.refresh})
    // this.forceUpdate();
    //   return this.props.rowss ;k

  };




  getCellActions3(column, row) {

    if (column.key === "taxAmount" && (row.rentMEtcId === 0 || row.rentMEtcId === -1)) {
      return [{
        icon: <div className="float-right" style={{ textAlign: "right", paddingRight: "2px", width: "50px", color: "#000000" }}>{this.props.estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount === 0 ? "" : this.props.estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount.toLocaleString()}</div>,
        callback: () => {

          let estimateMonies3 = this.props.estimateMonies3

          if (estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount > 0) {
            estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount = 0
            estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].tax = false
          } else {
            estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount = Math.round(row.amount * 0.1)
            estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].tax = true
          }

          this.props.change_estimateMonies3(estimateMonies3)

          this.setState({ refresh: !this.state.refresh })
        }
      }]

    } else if (column.key === "billing" && (row.rentMEtcId === 0 || row.rentMEtcId === -1)) {


      return [{
        icon: <div style={{ width: "60px" }}>{row.billing === 1 ? <i class="fas fa-check"></i> : ""}</div>,

        callback: () => {
          let estimateMonies3 = this.props.estimateMonies3

          estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].billing = estimateMonies3.filter(a => a.rentMAccountId === row.rentMAccountId)[0].billing === 0 ? 1 : 0
          this.props.change_estimateMonies3(estimateMonies3)
          this.setState({ refresh: !this.state.refresh })
        }
      }
      ]

    } else if (column.key === "delete" && (row.rentMAccountId !== 16)) {
      return [{
        icon: <i class="fas fa-trash-alt" style={{ marginRight: "15px" }}></i>,

        callback: () => {
          if (row.rentMAccountId !== 16) {
            let estimateMonies3 = this.props.estimateMonies3

            estimateMonies3 = estimateMonies3.filter(a => a.rentMAccountId !== row.rentMAccountId)
            this.props.change_estimateMonies3(estimateMonies3)

            this.setState({ refresh: !this.state.refresh })
          }
        }
      }
      ]
    }
  }

  getCellActions4(column, row) {

    if (column.key === "taxAmount" && (row.rentMEtcId === 0 || row.rentMEtcId === -1) && row.rentMAccountId !== 17) {
      return [{
        icon: <div className="float-right" style={{ textAlign: "right", paddingRight: "2px", width: "50px", color: "#000000" }}>{this.props.estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount === 0 ? "" : this.props.estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount.toLocaleString()}</div>,
        callback: () => {

          let estimateMonies4 = this.props.estimateMonies4

          if (estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount > 0) {
            estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount = 0
            estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].tax = false
          } else {
            estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].taxAmount = Math.round(row.amount * 0.1)
            estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].tax = true
          }

          this.props.change_estimateMonies4(estimateMonies4)

          this.setState({ refresh: !this.state.refresh })
        }
      }]

    } else if (column.key === "billing" && (row.rentMEtcId === 0 || row.rentMEtcId === -1) && row.rentMAccountId !== 17) {


      return [{
        icon: <div style={{ width: "60px" }}>{row.billing === 1 ? <i class="fas fa-check"></i> : ""}</div>,

        callback: () => {
          let estimateMonies4 = this.props.estimateMonies4

          estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].billing = estimateMonies4.filter(a => a.rentMAccountId === row.rentMAccountId)[0].billing === 0 ? 1 : 0
          this.props.change_estimateMonies4(estimateMonies4)
          this.setState({ refresh: !this.state.refresh })
        }
      }
      ]

    } else if (column.key === "delete" && (row.rentMAccountId !== 17)) {
      return [{
        icon: <i class="fas fa-trash-alt" style={{ marginRight: "15px" }}></i>,

        callback: () => {
          if (row.rentMAccountId !== 17) {
            let estimateMonies4 = this.props.estimateMonies4

            estimateMonies4 = estimateMonies4.filter(a => a.rentMAccountId !== row.rentMAccountId)
            this.props.change_estimateMonies4(estimateMonies4)

            this.setState({ refresh: !this.state.refresh })
          }
        }
      }
      ]
    }




  }

  getLeaveRowCount3() {
    let count = this.props.estimateMonies3.length;
    if (this.state.refresh && count > 0) {
      count--; // hack for update data-grid
      this.setState({
        refresh: false
      });
    }

    return count;
  }

  getLeaveRowCount4() {
    let count = this.props.estimateMonies4.length;
    if (this.state.refresh && count > 0) {
      count--; // hack for update data-grid
      this.setState({
        refresh: false
      });
    }

    return count;
  }

  refresh() {
    this.setState({ refresh: !this.state.refresh })
    // this.setState({
    //   refresh: true
    // });
  }

  selected() {
    alert()
  }

  render() {

    // const { handleSubmit } = this.props;

    return (
      <div className="item" id="parentDivOfGrid">

        {/* <button className="btn btn-sm btn-light float-right" style={{ display: (this.props.notclose === 1) ? 'none' : '' }} onClick={this.props.moneyeditchange}>閉じる</button> */}



        <div style={{ marginBottom: 10 }}>
          <span className="float-left">更新時</span>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            type="submit"
            style={{ margin: 2, minWidth: 0 }}
            onClick={() => this.add3(59)}
          >継続保証料
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            type="button"
            style={{ margin: 2, minWidth: 0 }}
            onClick={() => this.add3(16)}
          >更新料
          </Button>
          <ReactDataGrid
            columns={String(this.props.estimateData.type) === "1" ? this.state.columnsCorp1 : this.state.columns1}
            // rowGetter={i => this.props.money_update[i]}
            // rowsCount={this.props.money_update.length}
            // minHeight={77}


            rowGetter={i => this.props.estimateMonies3[i]}
            rowsCount={this.getLeaveRowCount3()}
            minHeight={this.props.estimateMonies3.length * 36 + 40}



            style={{ width: 200 }}
            onGridRowsUpdated={this.onRowsKoushinUpdated}
            enableCellSelect={true}
            getCellActions={this.getCellActions3}

          />
        </div>

        <div>
          <span className="float-left">退去時</span>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            type="submit"
            style={{ margin: 2, minWidth: 0 }}
            onClick={() => this.add4(19)}
          >退去時美装代
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            type="button"
            style={{ margin: 2, minWidth: 0 }}
            onClick={() => this.add4(15)}
          >実費
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            type="button"
            style={{ margin: 2, minWidth: 0 }}
            onClick={() => this.add4(17)}
          >敷引1
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            type="button"
            style={{ margin: 2, minWidth: 0 }}
            onClick={() => this.add4(18)}
          >敷引2
          </Button>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            type="button"
            style={{ margin: 2, minWidth: 0 }}
            onClick={() => this.add4(16)}
          >込み
          </Button>
          <ReactDataGrid
            columns={String(this.props.estimateData.type) === "1" ? this.state.columnsCorp2 : this.state.columns2}
            rowGetter={i => this.props.estimateMonies4[i]}
            rowsCount={this.getLeaveRowCount4()}
            minHeight={this.props.estimateMonies4.length * 36 + 40}

            // rowGetter={i => this.props.money_leave[i]}
            // rowsCount={this.getLeaveRowCount()}
            // minHeight={148}
            style={{ width: 200 }}
            onGridRowsUpdated={this.onRowsTaikyoUpdated}
            enableCellSelect={true}
            getCellActions={this.getCellActions4}

          />
        </div>




        {/* 
                    <Grid
justify="space-between" // Add it here :)
container 
spacing={24}
> */}
        <FormControl className={Forms.formPenaltyControl} size="small" style={{ margin: 10, float: "left" }}>
          <InputLabel id="demo-simple-select-label">違約金</InputLabel>
          <Select
            labelId="demo-simple-select-outlined-label"
            id="demo-simple-select-helper"
            value={this.props.estimateData.contractPenalty}
            onChange={this.handleChange}
            name="contractPenalty"
            variant='standard'
          >
            <MenuItem value={0}>
              <em >-</em>
            </MenuItem>
            <MenuItem value={1}>無</MenuItem>
            <MenuItem value={2}>6ヶ月未満1ヶ月</MenuItem>
            <MenuItem value={3}>10ヶ月未満1ヶ月</MenuItem>
            <MenuItem value={4}>1年未満1ヶ月</MenuItem>
            <MenuItem value={5}>2年未満1ヶ月</MenuItem>
            <MenuItem value={6}>1年未満2ヶ月,2年未満1ヶ月</MenuItem>
            <MenuItem value={7}>1年未満2ヶ月</MenuItem>
            <MenuItem value={8}>2年未満2ヶ月</MenuItem>
            <MenuItem value={9}>6ヵ月未満2ヵ月,1年未満1ヵ月</MenuItem>
          </Select>

        </FormControl>


      </div>

    );
  }
}



const mapStateToProps = state => {
  return {
    estimateData: state.estimates.estimateData,
    payTypes: state.estimates.payTypes,
    payUpdateTypes: state.estimates.payUpdateTypes,
    payTaikyoTypes: state.estimates.payTaikyoTypes,
    monthTypes: state.estimates.monthTypes,
    yearTypes: state.estimates.yearTypes,
    estimateMonies1: state.estimates.estimateMonies1,
    estimateMonies3: state.estimates.estimateMonies3,
    estimateMonies4: state.estimates.estimateMonies4,
    estimate_after_flg: state.estimates.estimate_after_flg,
    etcTypes: state.estimates.etcTypes,
    kyouekiTypes: state.estimates.kyouekiTypes,
    suidouTypes: state.estimates.suidouTypes,
    parkingTypes: state.estimates.parkingTypes,
    hokenTypes: state.estimates.hokenTypes,


  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_estimateData(state) {
      dispatch(Estimates.change_estimateData(state))
    },
    add_estimateMonies3(state) {
      dispatch(Estimates.add_estimateMonies3(state))
    },
    add_estimateMonies4(state) {
      dispatch(Estimates.add_estimateMonies4(state))
    },
    change_estimateMonies3(state) {
      dispatch(Estimates.change_estimateMonies3(state))
    },
    change_estimateMonies4(state) {
      dispatch(Estimates.change_estimateMonies4(state))
    },
    change_estimateMonies1(state) {
      dispatch(Estimates.change_estimateMonies1(state))
    },

    change_estimate_after_flg(state) {
      dispatch(Estimates.change_estimate_after_flg(state))
    },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MoneyEditComponent);