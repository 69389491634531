import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
// import { makeStyles } from '@mui/material/styles';
import * as IntroductionListEdit from './../Saga/MapMypage';
// import RoomDocComponent from '../Rooms/RoomDocComponent';
// import * as Responses from '../../Store/Responses';
// import '../../Css/GridRooms.css';
// import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
// import IntroductionsGridComponent from './IntroductionsGridComponent';
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import ChatIcon from '@mui/icons-material/Chat';
import StarIcon from '@mui/icons-material/Star';
import CloseIcon from '@mui/icons-material/Close';
import Comment from './Comment';
import IconButton from '@mui/material/IconButton';
import RoomDocComponent from '../../Rooms/RoomDocComponent';
import * as MapMypage from './../Saga/MapMypage';
// import Button from '@mui/material/Button';

// const DragHandle = SortableHandle(() => (
//     <ListItemIcon>
//       <DragHandleIcon />
//     </ListItemIcon>
//   ));

// const SortableItem = SortableElement(({ value }) => (
//   <ListItem ContainerComponent="div">
//     <img src={"https://www.global-center.co.jp/claso/common/img/building/"+value.tatemonoId+"/1/1"} width="50px"></img>
//     <ListItemText primary={value.tatemonoName} />
//     <ListItemSecondaryAction>
//       <DragHandle />
//     </ListItemSecondaryAction>
//   </ListItem>
// ));

// const SortableListContainer = SortableContainer(({change_number, items }) => (
//   <List component="div">
//     {console.log(items)}
//     {items.map((value, index) => (
//      <ListItem ContainerComponent="div">
//       {/* <SortableItem key={value.tatemonoId} index={index} value={value} /> */}
//       <span  style={{width:"180px"}}>{value.tatemonoName} {value.roomName} </span>
//       <TextField
//       id={value.tatemonoId}
//       label="番号"
//       type="number"
//       onChange={change_number}
//       value={value.numbering}
//       style={{width:"50px",float:"right"}}
//       InputLabelProps={{
//         shrink: true,
//       }}
//     />
//      {/* <Button size="small" 
//      color="primary"
//      variant="outlined"
//      type="button"

//      >
//         採番
//       </Button> */}
//       </ListItem>
//     ))}
//   </List>
// ));





class App extends Component {
  constructor(props) {
    super(props);
    this.state = {

      modalsheet: false,
      tatemono_id: 0,
      room_id: 0,

      items: [
        { id: '1', text: 'Item 1' },
        { id: '2', text: 'Item 2' },
        { id: '3', text: 'Item 3' },
        { id: '4', text: 'Item 4' }
      ],
      refresh: false,



    }
    this.change_number = this.change_number.bind(this);
    this.handleOpenSheet = this.handleOpenSheet.bind(this);


    // this.onMypage = this.onMypage.bind(this);
    // this.selectsheet = this.selectsheet.bind(this);

    // this.togglesheet = this.togglesheet.bind(this);

    // this.props.get_introductions({rent_response_id:props.rent_response_id});
  }


  change_number(e) {
    let rent_introduction_headers = this.props.rent_introduction_headers
    console.log(e.target.value)
    rent_introduction_headers.rent_introduction_details = rent_introduction_headers.rent_introduction_details.map(function (item, i) {
      if (parseInt(item.tatemono_id) === parseInt(e.target.id)) {
        item.numbering = e.target.value
        return item
      } else {
        return item
      }
    })
    this.props.change_rent_introduction_headers(rent_introduction_headers)
    this.setState({ refresh: !this.state.refresh })
  }


  onSortEnd = ({ oldIndex, newIndex }) => {
    this.setState(items => arrayMove(this.props.introductions, oldIndex, newIndex));
  }

  handleOpenSheet = (tid, rid) => {
    let map_mypage_room_detail_disp = this.props.map_mypage_room_detail_disp
    map_mypage_room_detail_disp = true
    this.props.change_map_mypage_room_detail_disp(map_mypage_room_detail_disp)

    let map_mypage_room_detail_disp_id = this.props.map_mypage_room_detail_disp_id
    map_mypage_room_detail_disp_id.tatemono_id = tid
    map_mypage_room_detail_disp_id.room_id = rid
    this.props.change_map_mypage_room_detail_disp_id(map_mypage_room_detail_disp_id)
    // setTatemonoId(tid);
    // setRoomId(rid);
  };


  render() {

    return (
      <div>
        {/* <SortableListContainer
            items={this.props.rent_introduction_headers.rentIntroductionDetailsViewModel?this.props.rent_introduction_headers.rentIntroductionDetailsViewModel:[]}
            onSortEnd={this.onSortEnd}
            useDragHandle={true}
            lockAxis="y"
            change_number={this.change_number}
          /> */}

        <List component="div">

          {/* {this.props.rent_introduction_headers.rent_introduction_details ? this.props.rent_introduction_headers.rent_introduction_details.sort(function (a, b) {
            if (a.numbering < b.numbering) return -1;
            if (a.numbering > b.numbering) return 1;
            return 0;
          }).map((value, index) => ( */}
          {
            this.props.rent_introduction_headers.rent_introduction_details ? this.props.rent_introduction_headers.rent_introduction_details.map((value, index) => (<div>
              <ListItem ContainerComponent="div">

                {/* <SortableItem key={value.tatemonoId} index={index} value={value} /> */}
                {value.is_bookmark ? <StarIcon style={{ color: "#ff4500" }} /> : "　　"}<span style={{ width: "180px" }}>{value.tatemono_name} {value.room_name} <button className="btn btn-sm btn-primary" style={{ width: "25px", fontSize: 10, padding: 0 }} onClick={()=>this.handleOpenSheet(value.tatemono_id, value.room_id)}>詳細</button>
                </span>
                
                <TextField
                  id={value.tatemono_id}
                  label="番号"
                  type="number"
                  onChange={this.change_number}
                  value={value.numbering}
                  style={{ width: "50px", float: "right" }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                />

                {this.props.remarks_id === value.id ? <Fab size="small" color="secondary" onClick={() => this.props.change_remarks_id(0)}>
                  <CloseIcon />
                </Fab> : <Fab size="small" color="primary" onClick={() => this.props.change_remarks_id(value.id)}>
                    <ChatIcon />
                  </Fab>}
              </ListItem>
              <ListItem ContainerComponent="div">
                <Comment rent_introduction_details={value} />


              </ListItem>   </div>
            )) : ""}
        </List>

      </div >
    );
  }


}



const mapStateToProps = (state) => {
  return {
    rooms: state.roomsReducer.rooms,
    introductions: state.introductionlist.introductions,
    rent_introduction_headers_list: state.mapmypage.rent_introduction_headers_list,
    rent_introduction_headers: state.mapmypage.rent_introduction_headers,
    // responseid: state.customersReducer.responseid,
    // response_rooms: state.roomsReducer.response_rooms,
    contacts_loading: state.customerContactsReducer.contacts_loading,
    remarks_id: state.mapmypage.remarks_id,

    room_details: state.roomsReducer.room_details,

    map_mypage_room_detail_disp: state.mapmypage.map_mypage_room_detail_disp,
    map_mypage_room_detail_disp_id: state.mapmypage.map_mypage_room_detail_disp_id,


  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_rent_introduction_headers(state) {
      dispatch(IntroductionListEdit.change_rent_introduction_headers(state))
    },
    change_remarks_id(state) {
      dispatch(IntroductionListEdit.change_remarks_id(state))
    },
    change_map_mypage_room_detail_disp(state) {
      dispatch(MapMypage.change_map_mypage_room_detail_disp(state))
    },
    change_map_mypage_room_detail_disp_id(state) {
      dispatch(MapMypage.change_map_mypage_room_detail_disp_id(state))
    },
    // insert_introductions(state) {
    //     dispatch(IntroductionList.insert_introductions(state))
    // },
    // delete_introduction(state:any) {
    //     dispatch(IntroductionList.delete_introduction(state))
    // },
    // update_introductions(state:any) {
    //     dispatch(IntroductionList.update_introductions(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);