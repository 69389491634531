import React, { Component, useRef } from 'react';

import TextField from '@mui/material/TextField';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
// import RoomSearchListComponent from '../RoomList/Index';
import * as Files from './Saga/Files';
import Loading from '../Layout/LoadingComponent';
import './Css/FileUp.css'
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';

// タイマー用
let timer;


class FileUpComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      deletealert: false


    };

    this.props.change_contract_file_remarks(this.props.remarks)


    this.onDrop = this.onDrop.bind(this);

    this.change_deletealert = this.change_deletealert.bind(this);




  }


  onDrop = (files) => {
    console.log(files)
    const file = files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        name: file.name,
      })
    }

    let sendfile = [];
    sendfile.file = files;
    // sendfile.id = this.props.introduction_file_id;
    this.props.change_contract_file_data(files)


    // this.props.add_introduction_file(sendfile)
  };


  change_deletealert() {
    this.setState({ deletealert: true })
  }

  render() {


    return (

      <div >



        {this.props.fileid > 0 ? <div style={{ textAlign: "center" }}>
          {this.props.name}
          {this.props.file_tab !== "f4" ? 
          <TextField
            id="outlined-multiline-static"
            label="備考"
            defaultValue={this.props.remarks}
            multiline
            rows={4}
            onChange={(a) => this.props.change_contract_file_remarks(a.target.value)}
            style={{ width: "90%", margin: 10 }}
            // defaultValue="Default Value"
            variant="outlined"
          />:""}

          <Button variant="contained" style={{ float: "right" }} onClick={() => this.props.edit_limited_public_doc_remarks(this.props.fileid)} color="primary">
            保存
          </Button>


          {this.state.deletealert ? <Alert style={{ textAlign: "left" }} severity="error">本当に削除してもよろしいですか？ <Button variant="contained" style={{ float: "left" }} onClick={() => this.props.delete_rent_limited_public_docs(this.props.fileid)} color="secondary">
            削除
      </Button></Alert> : <Button variant="contained" style={{ float: "left" }} onClick={this.change_deletealert} color="secondary">
              削除
      </Button>}
        </div > :
          <div style={{ textAlign: "center" }}>

            <div style={{ height: "100%", margin: 5 }}>
              {this.props.get_mail_detail_files_loading === 1 ? <Loading /> : <Dropzone

                onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (

                  <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                    <input {...getInputProps()} />

                    <div style={{ height: 100 }}>
                      <p>{this.state.name ? this.state.name : "ファイル添付　クリックまたはドラッグ&ドロップ"}</p>
                      <ul style={{ padding: 0, marginTop: 3 }}  >

                      </ul>

                    </div>

                  </div>

                )}
              </Dropzone>}

            </div>

            {this.props.file_tab !== "f4" ? <TextField
              id="outlined-multiline-static"
              label="備考"
              multiline
              rows={4}
              onChange={(a) => this.props.change_contract_file_remarks(a.target.value)}
              style={{ width: "90%", margin: 10 }}
              // defaultValue="Default Value"
              variant="outlined"
            />:""}
            <Button variant="contained" onClick={this.props.upload_limited_public_doc} color="primary">
              保存
      </Button>
          </div >
        }
      </div >

    );

  }
}




//コンテナ
const mapStateToProps = state => {
  return {

    response_file_remarks: state.responsefiles.response_file_remarks,

  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_contract_file_data(state) {
      dispatch(Files.change_contract_file_data(state))
    },
    change_contract_file_remarks(state) {
      dispatch(Files.change_contract_file_remarks(state))
    },
    upload_limited_public_doc(state) {
      dispatch(Files.upload_limited_public_doc(state))
    },
    edit_limited_public_doc_remarks(state) {
      dispatch(Files.edit_limited_public_doc_remarks(state))
    },
    delete_rent_limited_public_docs(state) {
      dispatch(Files.delete_rent_limited_public_docs(state))
    },

  };
}


export default connect(mapStateToProps, mapDispatchToProps)(FileUpComponent);
