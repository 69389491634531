import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Responses from './Saga/ResponseList';
import '../../Css/Grid.css';

import LoadingComponent from '../Layout/LoadingComponent';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';

import Select from 'react-select';
import TextField from '@mui/material/TextField';

const ReactDataGrid = require('react-data-grid');
var moment = require('moment-timezone');

// const DetailLinkFormatter = ({ value, ...props }: any) => {

//   if (props.row.flg === 3) {
//     if (props.row.request === 1) {
//       return "";
//     } else {
//       return <a role="button" rel="noreferrer noopener" className="btn btn-primary" target="_blank" href={`/customer/` + props.row.id} >詳細</a>;
//     }
//     // return "";
//   } else {
//     return value;
//   }
//   //return <ProgressBar now={value} label={`${value}%`} />;
// };
// const ColorFormatter = (value: any) => {
//   return <div style={{ textAlign: "left" }}><span style={{ background: "#f5f5f5", padding: "2px", fontWeight: "bold" }}>担当者</span> {value}</div >;

// };

const DetailFormatter = ({ value, ...props }: any) => {

  if (props.row.id > 0) {
    const media = props.row.rent_m_medium_id === 1 ? <span className="badge badge-primary">アパマン</span>
      : props.row.rent_m_medium_id === 14 ? <span className="badge badge-success">スーモ</span>
        : props.row.rent_m_medium_id === 16 ? <span className="badge badge-warning">ホームズ</span>
          : props.row.rent_m_medium_id === 21 ? <span className="badge badge-danger">アットホーム</span>
            : props.row.rent_m_medium_id === 29 ? <span className="badge badge-light">すまいズ</span>
              : props.row.rent_m_medium_id === 2 ? <span className="badge badge-info">CLASO.</span>
                : ""

    const shop = props.row.section_id === 1 ? <span className="badge badge-success">番</span>
      : props.row.section_id === 2 ? <span className="badge badge-warning">レ</span>
        : props.row.section_id === 3 ? <span className="badge badge-danger">駅</span>
          : props.row.section_id === 4 ? <span className="badge badge-dark">東</span>
            : props.row.section_id === 6 ? <span className="badge badge-light">公</span>
              : props.row.section_id === 17 ? <span className="badge badge-info">サ</span>
                : props.row.section_id === 47 ? <span className="badge badge-secondary">通</span>
                  : ""

    return <span>{shop} <span className="badge badge-warning">登録済み</span> {media}</span>
  } else {

    const media = props.row.rent_m_medium_id === 1 ? <span className="badge badge-primary">アパマン</span>
      : props.row.rent_m_medium_id === 14 ? <span className="badge badge-success">スーモ</span>
        : props.row.rent_m_medium_id === 16 ? <span className="badge badge-warning">ホームズ</span>
          : props.row.rent_m_medium_id === 21 ? <span className="badge badge-danger">アットホーム</span>
            : props.row.rent_m_medium_id === 29 ? <span className="badge badge-light">すまいズ</span>
              : props.row.rent_m_medium_id === 2 ? <span className="badge badge-info">CLASO.</span>
                : ""






    const shop = props.row.toMail === "info@global-center.co.jp" ? <span className="badge badge-success">番</span>
      : props.row.toMail === "rainbow@global-center.co.jp" ? <span className="badge badge-warning">レ</span>
        : props.row.toMail === "takamatsu@global-center.co.jp" ? <span className="badge badge-danger">駅</span>
          : props.row.toMail === "east@global-center.co.jp" ? <span className="badge badge-dark">東</span>
            : props.row.toMail === "park@global-center.co.jp" ? <span className="badge badge-light">公</span>
              : props.row.toMail === "sun@global-center.co.jp" ? <span className="badge badge-info">サ</span>
                : props.row.toMail === "cd@global-center.co.jp" ? <span className="badge badge-secondary">通</span>
                  : ""


    return <span>{shop} {media}</span>
  }
};






interface Props {
  row: any,
  mail_id: number,
  response_matching_list_loading: number,
  response_matching_list: any
  get_response_matching_list: any,
  selectedrow: any,
  select_response_matching: any,
  delete_mail: any,
  section_select: any,
  users_select: any,
  user_details: any,
  response_line_matching_list: any,
  get_response_line_matching_list: any,
  line_user_id: any,
  line_bind: any
}

interface State {
  modalmail: boolean,
  modalselect: boolean,
  mailid: number,
  modalMatching: boolean,
  mailMatchingId: number,
  refresh: boolean,
  modalLineSelect: boolean,
  formData: any,
  rent_response_id: number
  // customerdetail:any,

  // responsedetail:any,
  // state types
}

class checkBoxFormatter extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    super(props);

  }
  render() {

    console.log(this.props.row.id)
    return (this.props.row.flg === 2 ?
      <div>
        <input type="checkbox" id={"scales" + this.props.row.id} className="react-grid-checkbox" name={"scales" + this.props.row.id} />
        <label htmlFor={"scales" + this.props.row.id} className="react-grid-checkbox-label">
        </label>
      </div> : ""
    )
  }
}


//   const deleteFormatter = ({ value, ...props }:any) => {

//       return (props.row.mailAllReceiveId>0) ? "" :<div style={{background: '#CCC'}}>　</div>;


//   };

const columns = [
  // { key: 'selected', name: '', width: 40, resizable: true, formatter: CheckFormatter },
  { key: 'media', name: '内容', resizable: true, width: 130, formatter: DetailFormatter },
  { key: 'date', name: '反響日', width: 150, resizable: true },
  // { key: 'mainmediaName', name: 'メディア',  width: 100, resizable: true},
  //{ key: 'col2', name: 'タイトル', width: 300, resizable: true, headerRenderer: <CustomHeader /> },
  { key: 'name', name: 'お客様名', width: 120, resizable: true },
  { key: 'tel1', name: '連絡先', width: 100, resizable: true },
  { key: 'mail1', name: 'メール', width: 150, resizable: true },
  { key: 'buildingName', name: '反響内容', width: 100, resizable: true },
  { key: 'detail', name: '詳細', width: 100, resizable: true },
  { key: 'line', name: '選択', width: 100, resizable: true },
  // { key: 'col5', name: '備考', resizable: true, formatter: DetailLinkFormatter},
];






class ResponseMatchingGridComponent extends Component<Props, State>{
  constructor(props: Readonly<Props>) {
    super(props);

    var data = new Date()
    data.setMonth(data.getMonth() - 1)
    this.state = {
      // height: window.innerHeight,
      modalselect: false,
      mailid: this.props.mail_id,
      modalMatching: false,
      mailMatchingId: 0,
      modalmail: false,
      refresh: false,
      modalLineSelect: false,
      rent_response_id: 0,
      formData: {
        name: '',
        section_id: this.props.user_details.amSectionId,
        user_id: 0,
        response_date_start: data.getFullYear() + '/' + (data.getMonth() + 1) + '/' + data.getDate(),
        response_date_end: null,
      },

    };
    // this.onScroll = this.onScroll.bind(this);
    this.getCellActions = this.getCellActions.bind(this);
    this.toggleMatching = this.toggleMatching.bind(this);
    // this.togglemail = this.togglemail.bind(this);
    this.onRowClick = this.onRowClick.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getRowCount = this.getRowCount.bind(this);
    this.togglemodalLineSelect = this.togglemodalLineSelect.bind(this);


    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.handleUserIdChange = this.handleUserIdChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.line_bind = this.line_bind.bind(this);



    this.handleApplicationDateStartChange = this.handleApplicationDateStartChange.bind(this);
    this.handleApplicationDateEndChange = this.handleApplicationDateEndChange.bind(this);


    // alert(this.props.mail_id)
  }


  onRowClick = (rowIdx: any, row: any) => {

    if (row) {
      this.props.response_matching_list.map((a: any) => a.selected = false)
      row.selected = true;
      this.props.selectedrow(row.id, row.mailAllReceiveId)
      this.refresh()
    }
    //    console.log()
    //    this.props.selectedrow(row.mailAllReceiveId)
    //    this.props.selectedrow(row.id)
    // this.forceUpdate()

  }

  line_bind = () => {
    this.props.line_bind({ rent_response_id: this.state.rent_response_id, line_user_id: this.props.line_user_id.line_user_id, line_account_section_id: this.props.line_user_id.line_account_section_id })


  }
  toggleMatching = (id: number) => {

    this.setState(prevState => ({
      modalMatching: !prevState.modalMatching,
      mailMatchingId: id
    }));
  }

  togglemodalLineSelect = () => {

    this.setState(prevState => ({
      modalLineSelect: !prevState.modalLineSelect,
    }));
  }


  handleSubmit = () => {

    this.props.get_response_line_matching_list(this.state.formData)
    // this.togglemodalMailDelete()
  }
  // handleResize = () => {
  //     console.info(
  //       `window height:width=${window.innerHeight}:${window.innerWidth}`,
  //     );

  //     // this.setState({height: window.innerHeight});

  //   };
  //   onScroll(state:any){

  //     console.log(state.rowVisibleEndIdx)
  //     window.scrollTo(0, 0)
  //     }


  EmptyRowsView = () => {
    const message = "検索結果がありません。";

    return (
      this.props.response_matching_list_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", backgroundColor: "#ddd", height: 200, padding: "50px" }} > <h3>{message}</h3></div>

    );
  };


  getCellActions(column: any, row: any) {


    // const cellActions = {

    //   coming: comingActions
    // };
    if (column.key === "line") {
      return [{
        icon: <button className="btn btn-sm btn-success" style={{ width: "60px" }} >選択</button>,

        callback: () => {
          this.setState({ rent_response_id: row.id })
          this.togglemodalLineSelect()
          // this.toggleMatching(row.id)

        }
      }
      ]


    } else if (column.key === "detail") {
      return [{
        icon: <button className="btn btn-sm btn-primary" style={{ width: "80px" }} >反響詳細</button>,

        callback: () => {
          window.open(`/customer/` + row.id, '_blank', 'noopener')
          // this.togglemail(row.mailAllReceiveId)
          // this.toggleMatching(row.id)

        }
      }
      ]
    } else {
      return {}
    }

  }




  getRowCount() {

    let count = this.props.response_line_matching_list.length;

    if (this.state.refresh && count > 0) {
      count--; // hack for update data-grid
      this.setState({
        refresh: false
      });
    }

    return count;
  }

  refresh() {
    this.setState({
      refresh: true
    });
  }
  handleChange = (event: any) => {
    console.log(event)

    const { formData }: any = this.state;
    formData[event.target.name] = event.target.value;

    this.setState({ formData });
  }


  handleChangeSectionId(sectionId: any) {
    // console.log(sectionId)

    if (sectionId) {
      let formData = this.state.formData
      formData.section_id = sectionId.value
      this.setState(formData);
    } else {
      let formData = this.state.formData
      formData.section_id = 0
      this.setState(formData);
    }
  }
  handleUserIdChange(userId: any) {

    if (userId) {
      let formData = this.state.formData
      formData.user_id = userId.value
      this.setState(formData);
    } else {
      let formData = this.state.formData
      formData.user_id = 0
      this.setState(formData);
    }
  };
  handleApplicationDateStartChange = (date: any) => {
    let formData = this.state.formData
    formData.response_date_start = date
    this.setState(formData);

  };
  handleApplicationDateEndChange = (date: any) => {
    let formData = this.state.formData
    formData.response_date_end = date
    this.setState(formData);

  };
  render() {
    //   alert(this.props.response_matching_list_loading)
    return (
      <div>

        <TextField
          id="outlined-basic"
          //   className={classes.textField}
          label="お客様名"
          margin="normal"

          onChange={this.handleChange}
          name="name"
          value={this.state.formData.name}
          style={{ float: "left", width: "200px", marginTop: 0, paddingRight: "5px" }}
          size="small"

        />


        <TextField
            id="response_date_start"
            label="反響日"
            type="date"
            name="response_date_start"
            size="small"
            onChange={this.handleChange}
            value={moment(this.state.formData.response_date_start).format("YYYY-MM-DD")}
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            style={{ float: "left", marginTop: "0" }}
        />
        <div style={{ float: "left", paddingTop: "15px" }}>～</div>
        <TextField
            id="response_date_end"
            label="反響日"
            type="date"
            name="response_date_end"
            size="small"
            onChange={this.handleChange}
            value={moment(this.state.formData.response_date_end).format("YYYY-MM-DD")}
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            style={{ float: "left", marginLeft: "20px", marginTop: "0" }}
        />

    
        <div className="searchform">
          <Select
            name="section_id"
            options={this.props.section_select}
            value={this.state.formData.section_id}
            onChange={(a: any) => this.handleChangeSectionId(a)}
            // component={RenderSelectInput}
            //  onChange={(a)=>this.setState({formData[sectionId]:a)}
            //  onBlur={(a) => input.onBlur(a?a.value:0)}

            placeholder="店舗を選択"
          />
        </div>
        <div className="searchform">
          <Select
            name="user_id"
            options={this.props.users_select.concat({ value: 0, label: "-" })}
            value={this.state.formData.user_id}
            onChange={(a: any) => this.handleUserIdChange(a)}

            placeholder="担当を選択"
          />
        </div>
        <button className="btn btn btn-success" style={{ margin: "5px" }} onClick={this.handleSubmit}>検索</button>
        {/* <EventListener target="window" onResize={this.handleResize} /> */}
        <ReactDataGrid

          scrollToRowIndex={20}
          enableCellSelect={true}
          columns={columns}
          rowGetter={(i: number) => this.props.response_line_matching_list[i]}
          rowsCount={this.getRowCount()}
          headerRenderer={true}
          getCellActions={this.getCellActions}
          onRowClick={this.onRowClick}

          //headerRowHeight={64}
          dragable={true}
          minHeight={400}
          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          // togglemail={this.togglemail}
          // scrollToRowIndex={100}
          emptyRowsView={this.EmptyRowsView}
        // rowRenderer={RowRenderer}
        // onScroll = {this.onScroll}
        // minHeight={this.state.height-170}


        />


        {/* <Modal isOpen={this.state.modalmail} toggle={()=>this.togglemail(1)} size="lg" fade={false}>

               <ModalHeader toggle={()=>this.togglemail(1)}>メール詳細</ModalHeader>
               <ModalBody>
               <MailDetail toggle={1} mailid={this.state.mailid} />
               </ModalBody>
               </Modal> */}





        <Modal isOpen={this.state.modalLineSelect} toggle={this.togglemodalLineSelect} size="sm" fade={false}>

          <ModalHeader toggle={this.togglemodalLineSelect}>LINEアカウント結びつけ</ModalHeader>
          <ModalBody>
            この反響をLINEアカウントと結びつけて<br />
              よろしいですか？
               </ModalBody>
          <ModalFooter>
            <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.line_bind}>結びつける</button>
            <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.togglemodalLineSelect}>キャンセル</button>
          </ModalFooter>
        </Modal>






      </div>
    );
  }


}



const mapStateToProps = (state: any) => {
  return {
    responselist: state.responsesReducer.responselist,
    responselist_loading: state.responsesReducer.responselist_loading,
    window_height: state.masterReducer.window_height,
    response_matching_list: state.responsesReducer.response_matching_list,
    response_matching_list_loading: state.responsesReducer.response_matching_list_loading,
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    user_details: state.masterReducer.user_details,
    response_line_matching_list: state.responselist.response_line_matching_list,
    line_user_id: state.responselist.line_user_id,
  }
}
function mapDispatchToProps(dispatch: any) {
  return {
    get_response_matching_list(state: any) {
      dispatch(Responses.get_response_matching_list(state))
    },
    select_response_matching(state: any) {
      dispatch(Responses.get_response_matching_list(state))
    },
    get_response_line_matching_list(state: any) {
      dispatch(Responses.get_response_line_matching_list(state))
    },
    line_bind(state: any) {
      dispatch(Responses.line_bind(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseMatchingGridComponent);