import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import '../../Css/Table.css';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MaterialUiSelect from '@mui/material/Select';
import Autocomplete from '@mui/material/Autocomplete';
import * as ResponseFollowSheet from '../ResponseFollowSheet/Saga/ResponseFollowSheet';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';


var moment = require('moment-timezone');

interface Props {
    section_select: any,
    users_select: any,
    get_response_follow_sheet_list: any,
    section_id: number,
    response_follow_sheet_list: any
}

interface State {
    selectedDate1: any,
    selectedDate2: any,
    name: any,
    section_id: any,
    user_id: any,
    mitaiou: boolean,
    end: boolean
}
const date1 = new Date();
const year = date1.getFullYear();
const month = date1.getMonth() + 1;
var sdate = moment(year + "-" + month + "-01").format('YYYY-MM-DD');


class ResponseFollowSheets extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            selectedDate1: sdate,
            selectedDate2: moment().utc().format('YYYY-MM-DD'),
            name: "",
            section_id: this.props.section_id,
            user_id: 0,
            mitaiou: false,
            end: false
        }
        this.search = this.search.bind(this);
        this.onChangedate1 = this.onChangedate1.bind(this);
        this.onChangedate2 = this.onChangedate2.bind(this);
        this.onChangename = this.onChangename.bind(this);
        this.onChangesection = this.onChangesection.bind(this);
        this.onChangeuser_id = this.onChangeuser_id.bind(this);
        this.mitaiou = this.mitaiou.bind(this);
        this.end = this.end.bind(this);

        if (this.props.section_id) {
            this.search()
        }
    }


    onChangedate1 = (e: any) => {
        this.setState({
            selectedDate1: e.target.value,
        });
    };
    onChangedate2 = (e: any) => {
        this.setState({
            selectedDate2: e.target.value,
        });
    };
    onChangename = (e: any) => {
        this.setState({
            name: e.target.value
        });
    };
    onChangesection = (e: any) => {
        console.log(e.target.value);
        this.setState({
            section_id: e.target.value
        });
    };
    onChangeuser_id = (event: any, value: any) => {
        if (value) {
            this.setState({
                user_id: value.value
            });
        } else {
            this.setState({
                user_id: 0
            });
        }
    };

    mitaiou = (e: any) => {
        this.setState({
            mitaiou: e.target.checked
        });
    };
    end = (e: any) => {
        this.setState({
            end: e.target.checked
        });
    };

    search() {
        this.props.get_response_follow_sheet_list(this.state);
    }

    render() {
        return (
            <div>
                <table>
                    <tbody>
                        <tr >
                            <td>
                                <div className="form-inline">



                                    <div>
                                        {/* 日付選aaaaaaaaaaa択 */}
                                        <TextField
                                            id="selectedDate1"
                                            label="反響日"
                                            type="date"
                                            name="selectedDate1"
                                            size="small"
                                            variant='outlined'
                                            onChange={this.onChangedate1}
                                            value={moment(this.state.selectedDate1).format("YYYY-MM-DD")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="反響日"
                                                format="yyyy/MM/dd"
                                                style={{ width: 130 }}
                                                value={this.state.selectedDate1}
                                                onChange={this.onChangedate1}
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider> */}

                                        <i className="fas fa-long-arrow-alt-right" style={{ verticalAlign: "bottom", margin: 10, fontSize: 20 }}></i>

                                        {/* 日付選択 */}
                                        <TextField
                                            id="selectedDate2"
                                            label="反響日"
                                            type="date"
                                            name="selectedDate2"
                                            size="small"
                                            variant='outlined'
                                            onChange={this.onChangedate2}
                                            value={moment(this.state.selectedDate2).format("YYYY-MM-DD")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={jaLocale}>
                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                margin="normal"
                                                id="date-picker-dialog"
                                                label="反響日"
                                                format="yyyy/MM/dd"
                                                style={{ width: 130 }}
                                                value={this.state.selectedDate2}
                                                onChange={this.onChangedate2}
                                                autoOk={true}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                            />
                                        </MuiPickersUtilsProvider> */}
                                    </div>

                                    <TextField fullWidth
                                        id="name"
                                        label="お客様名"
                                        variant="outlined"
                                        onChange={this.onChangename}
                                        name="name"
                                        style={{ float: "left", width: "300px", marginTop: 0, paddingRight: "5px" }}
                                    />

                                    <div className="searchform">
                                        <FormControl variant="outlined" fullWidth>
                                            <InputLabel id="sectionId-label">店舗</InputLabel>
                                            <MaterialUiSelect
                                                labelId="sectionId-label"
                                                id="section_id"
                                                name="section_id"
                                                defaultValue={this.props.section_id}
                                                // onChange={this.handleChange}
                                                // value={this.props.formData.section_id}
                                                onChange={this.onChangesection}
                                                // // disabled={this.props.formData.id}
                                                label="section_id"
                                            >
                                                {this.props.section_select.map(function (item: any, i: any) {
                                                    return (
                                                        <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                                                    );
                                                })}
                                            </MaterialUiSelect>
                                        </FormControl>
                                    </div>

                                    <div className="searchform">
                                        <Autocomplete
                                            onChange={this.onChangeuser_id}
                                            id="combo-box-demo"
                                            options={this.props.users_select}
                                            getOptionLabel={(option: any) => option.label ? option.label : ''}
                                            style={{ width: "100%" }}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                name="primary_user_id"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                label="反響担当" />}

                                        />
                                    </div>

                                    <FormControlLabel
                                        onChange={this.mitaiou}
                                        control={<Checkbox
                                            color="primary"
                                        />}

                                        label="未対応のみ"
                                    />

                                    <FormControlLabel
                                        onChange={this.end}
                                        control={<Checkbox
                                            color="primary"
                                        />}

                                        label="追客終了を表示"
                                    />

                                    <div style={{ marginLeft: 10 }}>
                                        <button
                                            type="button"
                                            onClick={this.search}
                                            className="btn btn-primary ml-3 ml-auto"
                                        >検索</button>

                                        <Chip label={this.props.response_follow_sheet_list.length + "件"} style={{ margin: 10, fontSize: 14 }} variant="outlined" />


                                    </div>

                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        users_select: state.masterReducer.users_select,
        users_details: state.masterReducer.users_details,
        section_select: state.masterReducer.section_select,
        response_follow_sheet_list: state.ResponseFollowSheet.response_follow_sheet_list,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_response_follow_sheet_list(state: any) {
            dispatch(ResponseFollowSheet.get_response_follow_sheet_list(state))
        },
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResponseFollowSheets);




