import React, { Component } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components'

  import GoogleMapReact, { MapOptions, Maps } from 'google-map-react'
import '../../Css/Table.css';
import ie from '../../Img/Map/icon_ie01.png'
// 参考サイト
// https://stackblitz.com/edit/react-umnzy4
// https://tomchentw.github.io/react-google-maps/



const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: true,
    fullscreenControl: false,
 
   
  }
}




class MapDispComponent extends Component {

    constructor(props) {
      super(props);
      this.state = {
        showRight:0,
        showFlag : true,
       



        // center: [this.props.lat,this.props.lng],
        zoom: 9,
        draggable: true,
        lat: this.props.lat>0?this.props.lat:34.36179852,
        lng: this.props.lng>0?this.props.lng:134.048788977




      }
 
   
      this.onChildMouseMove = this.onChildMouseMove.bind(this);
      this.onChildMouseUp = this.onChildMouseUp.bind(this);

      
    }

    

  componentWillReceiveProps(props){
    // this.setState({pins:props.tatemono_list})
  }

    _onBoundsChange = (center, zoom  , bounds, marginBounds ) => {
      console.log(bounds)
      // this.props.onCenterChange(center);
      // this.props.onZoomChange(zoom);
    }
  
    
    handleChange(state){
      console.log(state)
      // alert(state.target.value)
    }


    onChildMouseUp(){
      this.setState({draggable: true})
    }
    onChildMouseMove(childKey, childProps, mouse) {
      // function is just a stub to test callbacks
      this.setState({
        draggable: false,
        lat: mouse.lat,
        lng: mouse.lng
      });

// this.props.change(mouse.lat,mouse.lng)
      
    }
    render() {

        //moment.locale('ja')
      return (
        <div>
         <div style={{height:400}}>
<GoogleMapReact
  
   bootstrapURLKeys={{
     key: 'AIzaSyClDVE129NsjckVjDJpMUpqBUuqMTg5YUo'
   }}
   defaultCenter={{
    lat:this.props.room_details.ido>0?this.props.room_details.ido:34.36179852,
    lng:this.props.room_details.keido>0?this.props.room_details.keido:134.048788977
   }}
   defaultZoom={this.props.room_details.zoomLevel>0?this.props.room_details.zoomLevel:16}
  //  draggable={false}
   options={createMapOptions}
   draggable={this.state.draggable}
   onBoundsChange={this._onBoundsChange}
  //  onChildClick={this._onChildClick}
  // onChildMouseUp={this.onChildMouseUp}
  // onChildMouseMove={this.onChildMouseMove}
   // onChildMouseDown={this.onChildMouseDown.bind(this)}
   // onChildMouseUp={this.onChildMouseUp.bind(this)}
   // onChildMouseMove={this.onCircleInteraction.bind(this)}
 >


 {

     <Pin

     key={0}
     
style={{position: 'absolute',width: 40,height: 40,left: -20,top: -20,cursor: 'pointer',borderRadius: 40}}
     // onDrag={(map) => map.a}
    //  draggable={true}
       lat={this.props.room_details.ido}
       lng={this.props.room_details.keido}
      //  onDragend={this.onMarkerDragEnd}
     >
       <img src={ie} style={{"width":40}} />
       {/* <div style={{position: 'absolute',left: -30,whiteSpace: "nowrap",padding:2,paddingLeft:10,paddingRight:10,borderRadius: 10,backgroundColor:"#333333",color:"#FFF",fontWeight:"bold"}}>{pin.tatemonoName}</div>  */}
      
     </Pin>

 }
 </GoogleMapReact>

 {/* {this.state.lng}
 {this.state.lat} */}
            </div>
            </div>
    );
  }
}

const Pin = styled.div``


//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
      room_details: state.roomsReducer.room_details,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}



export default connect(mapStateToProps, mapDispatchToProps)(MapDispComponent);