
export const set = (state: any) => {
    let estimateMonies: any = []
    // estimateData=state.estimates.estimateData
    // estimateData.applicationDate=new Date()
    // estimateData.contractDate=null
    // estimateData.userId=state.masterReducer.user_details.id
    // estimateData.sectionId=state.masterReducer.user_details.amSectionId
    // estimateData.operationUserId=state.masterReducer.user_details.id
    // estimateData.operationSectionId=state.masterReducer.user_details.amSectionId
    // estimateData.rentMContractTypeId=1
    // estimateData.trader="2"
    // estimateData.division=0
    // estimateData.billingClosingDate=null
    // estimateData.contractPenalty=4
    // estimateData.penaltyRemarks=4
    // estimateData.contractRemarks=null
    estimateMonies.estimateMonies1 = [{
        rentContractId: 0,
        rentMAccountId: 1,
        name: "賃料",
        displayName: "賃料",
        billing: 0,
        amount: 0,
        amountMonth: 0,
        eachMonth: 0,
        eachYear: 0,
        accountGroup: 1,
        tax: 0,
        taxAmount: 0,
        rentMEtcId: 0,
        isDailyRate: 0,
        isFreeRent: 0,
        works: 0,
        salesAmount: 0,
        remarks: null,
        createdUser: 0,
        createdAt: null,
        updatedAt: null,
        updatedUser: 0,
    },
    {
        rentContractId: 0,
        rentMAccountId: 2,
        name: "共益費",
        displayName: 0,
        billing: 0,
        amount: 0,
        amountMonth: 0,
        eachMonth: 0,
        eachYear: 0,
        accountGroup: 1,
        tax: 0,
        taxAmount: 0,
        rentMEtcId: 0,
        isDailyRate: 0,
        isFreeRent: 0,
        works: 0,
        salesAmount: 0,
        remarks: null,
        createdUser: 0,
        createdAt: null,
        updatedAt: null,
        updatedUser: 0,
    },
    {
        rentContractId: 0,
        rentMAccountId: 3,
        name: "水道料",
        displayName: 0,
        billing: 0,
        amount: 0,
        amountMonth: 0,
        eachMonth: 0,
        eachYear: 0,
        accountGroup: 1,
        tax: 0,
        taxAmount: 0,
        rentMEtcId: 1,
        isDailyRate: 0,
        isFreeRent: 0,
        works: 0,
        salesAmount: 0,
        remarks: null,
        createdUser: 0,
        createdAt: null,
        updatedAt: null,
        updatedUser: 0,
    }, {
        rentContractId: 0,
        rentMAccountId: 4,
        name: "駐車料",
        displayName: 0,
        billing: 0,
        amount: 0,
        amountMonth: 0,
        eachMonth: 0,
        eachYear: 0,
        accountGroup: 1,
        tax: 0,
        taxAmount: 0,
        rentMEtcId: 0,
        isDailyRate: 0,
        isFreeRent: 0,
        works: 0,
        salesAmount: 0,
        remarks: null,
        createdUser: 0,
        createdAt: null,
        updatedAt: null,
        updatedUser: 0,
    }]


    estimateMonies.estimateMonies5 = [
        {
            rentContractId: 0,
            rentMAccountId: 1,
            name: "賃料",
            displayName: "賃料",
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 5,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        },
        {
            rentContractId: 0,
            rentMAccountId: 2,
            name: "共益費",
            displayName: 0,
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 5,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        },
        {
            rentContractId: 0,
            rentMAccountId: 3,
            name: "水道料",
            displayName: 0,
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 5,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 1,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        }, {
            rentContractId: 0,
            rentMAccountId: 4,
            name: "駐車料",
            displayName: 0,
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 5,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        }]



    estimateMonies.estimateMonies2 = [

        {
            rentContractId: 0,
            rentMAccountId: 9,
            name: "敷金",
            displayName: 0,
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 2,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        }, {
            rentContractId: 0,
            rentMAccountId: 10,
            name: "礼金",
            displayName: 0,
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 2,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        }
        , {
            rentContractId: 0,
            rentMAccountId: 11,
            name: "住宅総合保険料",
            displayName: 0,
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 2,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        }, {
            rentContractId: 0,
            rentMAccountId: 12,
            name: "仲介手数料",
            displayName: 0,
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 2,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        }, {
            rentContractId: 0,
            rentMAccountId: 14,
            name: "カギ交換料",
            displayName: 0,
            billing: 0,
            amount: 0,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 0,
            accountGroup: 2,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        }]




    estimateMonies.estimateMonies3 = [
        {
            rentContractId: 0,
            rentMAccountId: 16,
            name: "契約更新料",
            displayName: 0,
            billing: 0,
            amount: 15000,
            amountMonth: 0,
            eachMonth: 0,
            eachYear: 2,
            accountGroup: 3,
            tax: 0,
            taxAmount: 0,
            rentMEtcId: 0,
            isDailyRate: 0,
            isFreeRent: 0,
            works: 0,
            salesAmount: 0,
            remarks: null,
            createdUser: 0,
            createdAt: null,
            updatedAt: null,
            updatedUser: 0,
        }]

    estimateMonies.estimateMonies4 = [{
        rentContractId: 0,
        rentMAccountId: 17,
        name: "退去時清算",
        displayName: 0,
        billing: 0,
        amount: 0,
        amountMonth: 0,
        eachMonth: 0,
        eachYear: 0,
        accountGroup: 4,
        tax: 0,
        taxAmount: 0,
        rentMEtcId: 1,
        isDailyRate: 0,
        isFreeRent: 0,
        works: 0,
        salesAmount: 0,
        remarks: null,
        createdUser: 0,
        createdAt: null,
        updatedAt: null,
        updatedUser: 0,
    }
    ]
    return estimateMonies

    // return  fetch(url, {
    //         headers: { 'Authorization': 'Bearer ' + token }
    //     })
    // .then(res => res.json())
    // .then(payload => ({ payload }))
    // .catch(error => ({ error }));

}

