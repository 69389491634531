import React, { Component } from 'react';
import { connect } from 'react-redux';
import Index from "./Index";
import AllStoreIndex from "./AllStoreIndex";
import StoreIndex from "./StoreIndex";
import StaffIndex from "./StaffIndex";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { compose } from 'redux'
import withStyles from '@mui/styles/withStyles';
const styles = (theme) => ({

  backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: 20000,
    color: '#fff',
  },

})



class IndexCalendar extends Component {
  constructor(props) {
    super(props)
    this.state = {

    }


  }


  render() {
    return (
      <div>
        {this.props.calendar_type == 0 ? <Index /> : this.props.calendar_type == 1 ? <AllStoreIndex /> : this.props.calendar_type == 2 ? <StaffIndex /> : this.props.calendar_type == 3 ? <StoreIndex /> : ""}
        <Backdrop className={this.props.classes.backdrop} open={this.props.calendar_backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
  }
};




const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    calendar_type: state.calendar.calendar_type,
    calendar_backdrop: state.calendar.calendar_backdrop
    // users_select: state.masterReducer.users_select,

    // calendar_list: state.calendar.calendar_list,
    // calendar_list_form: state.calendar.calendar_list_form,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    // get_calendar_list(state) {
    //   dispatch(Calendarsaga.get_calendar_list(state))
    // },
    // change_calendar_list_form(state) {
    //   dispatch(Calendarsaga.change_calendar_list_form(state))
    // },
    // change_calendar_list(state) {
    //   dispatch(Calendarsaga.change_calendar_list(state))
    // },
  };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(IndexCalendar);