const authentication = require('../../../react-azure-adb2c2').default;
// export function update( values: any) {

// console.log(values)


//     const token = authentication.getAccessToken();
//     // const url = process.env.REACT_APP_API_BASE_URL+`/api/RentResponses/` + values.id
//     const url = `https://localhost:44341/api/RentResponses/`+ values.id
//     return fetch(url, {
//         method: 'put',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }


// export function insert(values:any) {



// let contents = {rentCustomers:values.customerdetail,
//     rentResponses:values.responsedetail,
//     matchinglist:values.response_matching_select_list==null?[]:values.response_matching_select_list}
// // alert(JSON.stringify(contents))
//     const token = authentication.getAccessToken();
//     const url = `https://localhost:44341/api/RentResponses/`
//     // const url = process.env.REACT_APP_API_BASE_URL+`/api/RentResponses/`
//     return fetch(url, {
//         method: 'post',
//         body: JSON.stringify(contents),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));
// }


// export function get_by_customerid_list(values: number) {

//     const token = authentication.getAccessToken();
//     // const url = `https://localhost:44341/api/c/CustomerLogs/`+values;
//     // const url = `https://localhost:44341/api/c/CustomerLogs/34`;

//     const url = process.env.REACT_APP_API_BASE_URL+`/api/c/CustomerLogs/`+values;
//     // const search = values ? values : {};
//     return fetch(url, {
//         method: 'get',
//         // body: JSON.stringify(search),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
// .then(res => res.json())
// .then(payload => ({ payload }))
//         .catch(error => ({ error }));
// }





export function change(values: any) {

    const token = authentication.getAccessToken();
    // const url = `https://localhost:44341/api/RentResponsesChageStatus/`+values.id;

    // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentResponsesChageStatus/` + values.id;
    // const url = `http://localhost:5000/api/v1/rent/responses/kidoku`
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/kidoku`


    // alert(url)
    // const url = process.env.REACT_APP_API_BASE_URL+`/response/api/MatchingSelectList/`+values.responseId;
    // const search = values ? values : {};
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
}


