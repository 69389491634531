import React, { FC } from "react";
import { connect } from 'react-redux';
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import * as ContractFollow from './Saga/ContractFollow';

const SortableItem = (props: any) => {

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: props.item.id });

  const style = {
    border: "1px solid #ddd",
    padding: "0px",
    marginBottom: "0.5rem",
    backgroundColor: "#fafafa",
    cursor: "move",
    listStyle: "none",
    transform: CSS.Transform.toString(transform),
    transition
  };

  const change_frozen = (key_name: string) => {

    let rent_m_contract_follow_settings = props.rent_m_contract_follow_settings

    let frozen = true

    let follows = props.rent_m_contract_follows_disp.map(function (value: any) {


        value.frozen = frozen
        if (key_name === value.key_name) {
            frozen = false
        }

        return value
    })

    props.change_rent_m_contract_follows_disp(follows)
    rent_m_contract_follow_settings.frozen_key_name = key_name
    props.change_rent_m_contract_follow_settings({})
    props.change_rent_m_contract_follow_settings(rent_m_contract_follow_settings)
    // props.change_kanri_m_follow_settings_pool()
  };

  const displaynone = (id: any) => {
    let rent_m_contract_follows_disp = props.rent_m_contract_follows_disp
    let rent_m_contract_follows_none = props.rent_m_contract_follows_none
    rent_m_contract_follows_none.push(rent_m_contract_follows_disp.filter((a: any) => a.id === id)[0])
    rent_m_contract_follows_disp = rent_m_contract_follows_disp.filter((a: any) => a.id !== id)

    props.change_rent_m_contract_follows_disp(rent_m_contract_follows_disp)
    props.change_rent_m_contract_follows_none([])

    props.change_rent_m_contract_follows_none(rent_m_contract_follows_none)

  };

  return (
    <ListItem style={style}>
      <ListItem>
        <FormControlLabel
            value="top"
            control={<Radio color="primary" />}
            label="固定"
            labelPlacement="top"
            checked={props.rent_m_contract_follow_settings.frozen_key_name === props.item.key_name}
            onChange={() => change_frozen(props.item.key_name)}
        />

        <ListItemText primary={props.item.name} />

        <ListItemSecondaryAction ref={setNodeRef} style={{ marginRight: 30 }} {...attributes} {...listeners}>
            <ListItemIcon className="drag-handle" >
                <DragHandleIcon />
            </ListItemIcon>

        </ListItemSecondaryAction>
        <IconButton aria-label="delete" 
        onClick={() => displaynone(props.item.id)} 
        size="large">
            <DeleteIcon fontSize="small" color="secondary" />
        </IconButton>
      </ListItem>
      {/* {props.item.name} */}
    </ListItem>
  );
};

const mapStateToProps = (state: any) => {
  return {
      users_select: state.masterReducer.users_select,
      // responseid: state.customersReducer.responseid,
      // responsedetail: state.customersReducer.responsedetail,
      user_details: state.masterReducer.user_details,
      // csv_loading: state.Adjustment.csv_loading,
      // search_list: state.Adjustment.search_list,
      // seisan_follow_list: state.Adjustment.seisan_follow_list,
      // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
      // seisan_follow_search: state.Adjustment.seisan_follow_search,
      // tantou: state.Adjustment.tantou,
      // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
      rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
      rent_m_contract_follows_none: state.contractfollow.rent_m_contract_follows_none,
      rent_m_contract_follow_settings: state.contractfollow.rent_m_contract_follow_settings,

  }
}
function mapDispatchToProps(dispatch: any) {
  return {

      change_rent_m_contract_follows_disp(state: any) {
          dispatch(ContractFollow.change_rent_m_contract_follows_disp(state))
      },
      change_rent_m_contract_follows_none(state: any) {
          dispatch(ContractFollow.change_rent_m_contract_follows_none(state))
      },
      change_rent_m_contract_follow_settings(state: any) {
          dispatch(ContractFollow.change_rent_m_contract_follow_settings(state))
      },


      // set_seisan_follow_search_list(state: any) {
      //     dispatch(Adjustment.set_seisan_follow_search_list(state))
      // },

      // update_kyousai_comp(state: any) {
      //   dispatch(Adjustment.update_kyousai_comp(state))
      // },
      // change_search_list(state: any) {
      //   dispatch(Adjustment.change_search_list(state))
      // },
      // get_kyousais(state: any) {
      //   dispatch(Adjustment.get_kyousais(state))
      // },
      // matching(state: any) {
      //   dispatch(Adjustment.matching(state))
      // },
      // delete_kyousais(state: any) {
      //   dispatch(Adjustment.delete_kyousais(state))
      // },
      // all_select(state: any) {
      //   dispatch(Adjustment.all_select(state))
      // },
      // get_kyousais_csv(state: any) {
      //   dispatch(Adjustment.get_kyousais_csv(state))
      // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SortableItem);