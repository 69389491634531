import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
// import * as Customers from '../../Store/Customers';
import * as Responses from '../ResponseDetail/Saga/Responses';
import '../../Css/Grid.css';
// import ReactDataGrid from "react-data-grid"
const ReactDataGrid = require('react-data-grid');
// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {
    t_shutoku_taiyous: any,
    // toggle: any,
    detailset: any,
    // dispatchRequest:any,
    // customerList:any,
    // customerno:number,
    // change_customerloding:any,
    // customerdetailData:any,
    // customerlist:[],
    // // change_customerloding1:any,
    // responsedetail:any,
    // responseUpdate:any,
    // // change_customerloding2:any,
    // toggle:any,
    // customer_list_loading:number
}


interface State {

    // state types
}



const columns = [

    {
        key: 'keiyakuDate', name: '契約日', width: 100, resizable: true
    },
    { key: 'jutakuTantouUserName', name: '受託担当', resizable: true },
    { key: 'kanriKubunName', name: '管理区分', resizable: true },
    { key: 'koSuu', name: '戸数', resizable: true },
    { key: 'tenpoName', name: '担当店舗', resizable: true },
    { key: 'kanriRitsu', name: '管理料率', resizable: true },
    { key: 'kariageKin', name: '借上金額', resizable: true },
    { key: 'kanriKaishiDate', name: '管理契約開始日', resizable: true },
    { key: 'kanriShuuryouDate', name: '管理契約終了日', resizable: true },
    { key: 'bikou', name: '備考', resizable: true },
    // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
];



class EntrustedGridComponent extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.onRowClick = this.onRowClick.bind(this);


    }
    componentWillMount() {

        // this.props.customerList()

    }
    getSelectedCell(event: any) {
        // alert()
    }

    onRowClick = (rowIdx: any, row: any) => {

        if (row)
            this.props.detailset(row)

        // if (this.props.customerno === -1) {
        //     let customerdetail: { [key: string]: number; } = {};
        //     customerdetail.customerid=row.id;
        //     customerdetail.no=-1;
        //     this.props.customerdetailData(customerdetail)

        //     this.props.get_customer_logs(row.id)
        // }else  {
        //     // this.props.change_customerloding(0)
        //     let customerdetail: { [key: string]: number; } = {};
        //     customerdetail.customerid=row.id;
        //     customerdetail.no=0;
        //     this.props.customerdetailData(customerdetail)
        //     //this.props.responseUpdate(this.props.responsedetail);
        // }
        // else if (this.props.customerno === 1) {
        //     this.props.change_customerloding1(0)
        //     this.props.responsedetail.rentCustomerId = row.id;
        //     this.props.responseUpdate(this.props.responsedetail);

        // } else if (this.props.customerno === 2) {
        //     this.props.change_customerloding2(0)
        //     this.props.responsedetail.rentCustomerId2 = row.id;
        //     this.props.responseUpdate(this.props.responsedetail);

        // }
        // this.props.toggle();




    }

    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        // return (
        //     // this.props.customer_list_loading===1?<LoadingComponent/> : <div style={{ textAlign: "center", backgroundColor: "#ddd",height:200, padding: "50px" }} > <h3>{message}</h3></div>

        // );
    };

    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div>

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    enableCellSelect={false}
                    columns={columns}
                    rowGetter={(i: number) => this.props.t_shutoku_taiyous[i]}

                    rowsCount={this.props.t_shutoku_taiyous.length}
                    headerRenderer={true}
                    dragable={true}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    // emptyRowsView={this.EmptyRowsView}
                    onRowClick={this.onRowClick}
                    //onCellSelected={this.getSelectedCell}
                    minHeight={300} />
            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        customerlist: state.customersReducer.customerlist,
        responseid: state.customersReducer.responseid,
        responsedetail: state.customersReducer.responsedetail,
        customer_list_loading: state.customersReducer.customer_list_loading
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        // customerList(state:any) {
        //     dispatch(Customers.customerList(state))
        // },
        responseUpdate(state: any) {
            dispatch(Responses.responseUpdate(state))
        },
        // change_customerloding(state:number) {
        //     dispatch(Customers.change_customerloding(state))
        // },
        // change_customerloding1(state:number) {
        //     dispatch(Customers.change_customerloding1(state))
        // },
        // change_customerloding2(state:number) {
        //     dispatch(Customers.change_customerloding2(state))
        // },
        // customerdetailData(state:any) {
        //     dispatch(Customers.customerDetail(state))
        // },  
        get_customer_logs(state: any) {
            dispatch(Responses.get_customer_logs(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(EntrustedGridComponent);