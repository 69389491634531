import React, { Component }  from 'react';
import { connect } from 'react-redux';
import '../../Css/RentFooterComponent.css';

class RentFooterComponent extends Component {
   

    render() {
       
        return (

            <footer id="footer-menublock">
                <ul class="footer-menu_list pa-left-0">
                    <li>
                        <a class="current" href="">
                            <i class="fa fa-home"></i>
                            お部屋一覧
                        </a>
                    </li>
                    <li>
                        <a class="" href="">
                            <i class="fas fa-map-marker-alt"></i>
                            地図検索
                        </a>
                    </li>
                    <li>
                        <a class="" href="" id="chat-link">
                            <i class="far fa-comment-dots"></i>
                            メッセージ
                        </a>
                    </li>
                    <li>
                        <a class="" href="" id="chat-link">
                            <i class="far fa-calendar-alt"></i>
                            来店予約
                        </a>
                    </li>
                    <li>
                        <a class="" href="" id="chat-link">
                            <i class="fa fa-cogs"></i>
                            条件変更
                        </a>
                    </li>                    
                </ul>
            </footer>

        );
    }
}



//containers
const mapStateToProps = (state, ownProps) => {
    return {
        //customerid: ownProps.match.params.id,
        //customerdetail1: state.customersReducer.customerdetail1,
        //customerdetail2: state.customersReducer.customerdetail2,
        //responsedetail: state.customersReducer.responsedetail,
        //responseedit: state.customersReducer.responseedit,
        //customeredit1: state.customersReducer.customeredit1,
        //customeredit2: state.customersReducer.customeredit2,



    }
}
function mapDispatchToProps(dispatch) {
    return {
//        customeredit1change(state) {
//            dispatch(customeredit1change(state))
//        },
//customeredit2change(state) {
//        dispatch(customeredit2change(state))
//    },
//        responseeditchange(state) {
//            dispatch(responseeditchange(state))
//        },
//        customerdetailData1(state) {
//            dispatch(customerdetailData1(state))
//        }, responsedetailData(state) {
//            dispatch(responsedetailData(state))
//        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RentFooterComponent);
