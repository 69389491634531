const authentication = require('../../../react-azure-adb2c2').default;
export const list = (values: any) => {
    // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductionPointHeaders/` + values;


    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/headers/list/` + values
    // const url = `http://localhost:5000/v1/rent/introduction/point/headers/` + values


    // const url = `https://localhost:44341/api/RentIntroductionPointHeaders/`+values;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}



//  export const detail = (values: any) => {
//         const url = process.env.REACT_APP_API_BASE_URL+`/api/RentIntroductionPointHeaders/`+values;
//         // const url = `https://localhost:44341/api/RentIntroductionHeaders/`+values;
//         const token = authentication.getAccessToken();
//         //const search = values ? values : {};


//         return  fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             })
//         .then(res => res.json())
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));

//     }

// export const detail = (values: any) => {
//     const url = process.env.REACT_APP_API_BASE_URL+`/api/RentIntroductionPointHeaders/`+values;
//     // const url = `https://localhost:44341/api/RentIntroductionHeaders/`+values;
//     const token = authentication.getAccessToken();
//     //const search = values ? values : {};


//     return  fetch(url, {
//             headers: { 'Authorization': 'Bearer ' + token }
//         })
//     .then(res => res.json())
//     .then(payload => ({ payload }))
//     .catch(error => ({ error }));

// }

export const insert = (values: any) => {

    // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductionPointHeaders/`;

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/headers/insert`
    // const url = `http://localhost:5000/v1/rent/introduction/point/headers/insert`

    const token = authentication.getAccessToken();

    // alert(JSON.stringify(values))
    //const search = values ? values : {};
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}



export const update = (values: any) => {

    // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductionPointHeaders/` + values.id;

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/headers/` + values.id;
    // const url = `http://localhost:5000/v1/rent/introduction/point/headers/` + values.id;


    // const url = `https://localhost:44341/api/RentIntroductionPointHeaders/`+values.id;
    const token = authentication.getAccessToken();


    // alert(JSON.stringify(values))
    //const search = values ? values : {};
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const deletepoint = (values: any) => {
    // const url = process.env.REACT_APP_API_BASE_URL + `/api/RentIntroductionPointHeaders/` + values;

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/headers/delete/` + values;
    // const url = `http://localhost:5000/v1/rent/introduction/point/headers/delete/` + values;
    // alert(url)
    const token = authentication.getAccessToken();
    //const search = values ? values : {};

    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}