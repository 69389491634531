import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import './Css/Grid.css'

import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';
import CardContent from '@mui/material/CardContent';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
// import Modal from '@mui/material/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialUiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import MaskedInput from 'react-text-mask';
import DateTimeComponentTime from "../Calendar/DateTimeComponentTime";
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))

const taiou = [
    { tantou: "吉田", hiduke: "2021-01-01", houhou: "企業訪問", naiyou: "〇〇さんと同行。契約書類持参。お礼訪問。" },
    { tantou: "吉田", hiduke: "2021-01-01", houhou: "企業訪問", naiyou: "定期訪問" },
    { tantou: "吉田", hiduke: "2021-01-01", houhou: "TEL", naiyou: "解約の事について確認。駐車場解約されたため、〇〇の現場の工事が終わったのかヒアリング。" },
]

function timeMask(value) {
    const chars = value.split('');
    const hours = [
      /[0-2]/,
      chars[0] === '2' ? /[0-3]/ : /[0-9]/,
    ];
    const minutes = [/[0-5]/, /[0-9]/];
  
    return hours.concat(':').concat(minutes);
  }
  
  
  const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref}
        mask={timeMask}
        showMask
      />
    );
  });

class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            change: false,
            columnWidths: {
                date: 100,
                user_id: 90,
                section_id: 90,
                rent_m_corporate_way_id: 100,
                remarks: 500,
            },
        };
        this.handleUserIdChangeSet = this.handleUserIdChangeSet.bind(this);
        this.handleSectionIdChangeSet = this.handleSectionIdChangeSet.bind(this);
        this.handleKanriCorpIdChangeSet = this.handleKanriCorpIdChangeSet.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.handleOpen = this.handleOpen.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this.RowClick = this.RowClick.bind(this);
        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        this.focus_start = this.focus_start.bind(this);
        this.blur_start = this.blur_start.bind(this);
        this.focus_end = this.focus_end.bind(this);
        this.blur_end = this.blur_end.bind(this);
        this.check_validation = this.check_validation.bind(this);
        this.change_datetime_select = this.change_datetime_select.bind(this);
        this.change_datetime = this.change_datetime.bind(this);

    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            },
        }));
    }


    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }
    handleKanriCorpIdChangeSet(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.kanri_corp_no = value.value

        } else {
            rent_corporate_contacts.kanri_corp_no = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handleUserIdChangeSet(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.user_id = value.value

        } else {
            rent_corporate_contacts.user_id = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handleSectionIdChangeSet(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.section_id = value.value

        } else {
            rent_corporate_contacts.section_id = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handleOpen(id) {
        if (id) {
            this.props.set_rent_corporate_contacts(id)
        } else {
            // 新規
            let rent_corporate_contacts = { id: '', rent_m_corporate_id: this.props.rent_m_corporates.id, user_id: this.props.user_details.id, section_id: this.props.user_details.amSectionId, date: moment(), rent_m_corporate_way_id: '', remarks: '', is_deleted: 0, created_at: '', created_user_id: '' }
            this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        }

        this.setState({ "modal": true })
    }
    onChangeDate(value) {

        let rent_corporate_contacts = this.props.rent_corporate_contacts
        rent_corporate_contacts.date = value
        this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        // this.props.change_responsedetail(responsedetail);
        this.setState({ reflash: !this.state.reflash });
    }
    onChange(state) {
        // console.log(this.props.claso_blog_places)
        let rent_corporate_contacts = this.props.rent_corporate_contacts

        if(state.target.name == "intend"){
            rent_corporate_contacts[state.target.name] = state.target.checked
        }else{
            rent_corporate_contacts[state.target.name] = state.target.value
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        this.setState({
            change: !this.state.change
        });

    }

    handleUpdate(id, delflg) {

        let rent_corporate_contacts = this.props.rent_corporate_contacts
        rent_corporate_contacts["is_deleted"] = delflg
        this.props.change_rent_corporate_contacts(rent_corporate_contacts)

        if (id) {
            this.props.update_rent_corporate_contacts()
        } else {
            this.props.insert_rent_corporate_contacts()
        }

        this.setState({
            modal: false,
            modal_del: false,
            change: !this.state.change
        });

    }

    RowClick(event,index){

        this.props.set_rent_corporate_contacts(this.props.rent_corporate_contacts_list[index].id)

        this.setState({ "modal": true })

    }

    _rowClassNameGetter(index){
        
        if(this.props.rent_corporate_contacts_list && this.props.rent_corporate_contacts_list[index]) {
        //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            return "normal"
        }else{
          return ""
        }
    }

    focus_start(e) {

        this.check_validation()
        this.setState({ focus_start: true })
    }
    blur_start(e) {

        setTimeout(() => {
            this.setState({ focus_start: false })
        }, 200)

    }
    focus_end(e) {
        console.log(e.target)

        this.setState({ focus_end: true })
    }
    blur_end(e) {
        setTimeout(() => {
            this.setState({ focus_end: false })
        }, 200)

    }

    change_datetime_select(name, value) {
        // alert(name)
        // alert(moment(value).format('HH:mm'))
        this.setState({ 
          refresh: !this.props.refresh,
          [name]: moment(value).format('HH:mm'),
          [name == "start_at" ? "start_at_err" : "end_at_err"]: false,
        })
        this.check_validation()
    }

    change_datetime(e) {
        // alert(e.target.value)
        console.log(e.target.value)
    
        let rent_corporate_contacts = this.props.rent_corporate_contacts

        const regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
        // 時間チェックして
        if(regex.test(e.target.value)){ //OK
              this.setState({ 
                [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: false,
              })
    
              rent_corporate_contacts[e.target.name] = e.target.value
          this.props.change_rent_corporate_contacts(rent_corporate_contacts)
    
        }else{// エラー
        // alert(e.target.name)
        rent_corporate_contacts[e.target.name] = e.target.value
            console.log(e.target.value)
            this.props.change_rent_corporate_contacts(rent_corporate_contacts)

            if(e.target.value == "__:__"){
                this.setState({ 
                    [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: false,
                })
            }else{
                this.setState({ 
                    [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: true,
                })
            }

        }
    
        this.setState({ 
          refresh: !this.props.refresh,
          [e.target.name]: e.target.value,
        })
    
        this.check_validation()
    }

    check_validation() {
        // alert()
            let rent_corporate_contacts = this.props.rent_corporate_contacts
            let validation = true
            let validation_text = ''
            
            // validation_text = rent_corporate_contacts.date ? validation_text + "" : validation_text + "対応日を選択してください。" 
    
            // validation_text = rent_corporate_contacts.owner_id > 0 ? validation_text + "" : validation_text + "オーナーまたは法人を選択してください。"
    
            // validation_text = rent_corporate_contacts.raiten_kbn_no > 0 ? validation_text + "" : validation_text + "対応区分を選択してください。"
    
            // validation_text = rent_corporate_contacts.outai_naiyou_no > 0 ? validation_text + "" : validation_text + "対応内容を選択してください。"
    
            
            // if(rent_corporate_contacts.start_at && rent_corporate_contacts.end_at && rent_corporate_contacts.start_at.replace('__:__', "") || rent_corporate_contacts.end_at.replace('__:__', "")){
                
            //     if(rent_corporate_contacts.start_at == '__:__' || rent_corporate_contacts.end_at == '__:__'){
            //         validation_text += "時間を正しく入力してください。"
            //     }else if(rent_corporate_contacts.date && moment(moment(rent_corporate_contacts.date).format("YYYY/MM/DD") + " " + rent_corporate_contacts.start_at) >= moment(moment(rent_corporate_contacts.date).format("YYYY/MM/DD") + " " + rent_corporate_contacts.end_at)) {
            //          validation_text += "開始時間より終了時間があとになるように入力してください。"
            //     }
            // }
    
    
        
            if (validation_text != "") {
                validation = false
            }
            
        
            this.setState({ validation: validation, validation_text: validation_text })
        
        }


    render() {

        return (
            <div>

                <div style={{ float: "left" }}><PermContactCalendarIcon style={{ color: "#5cb85c" }}></PermContactCalendarIcon> 営業対応履歴</div>
                <Button style={{ float: "right" }} size="small" variant="contained" color="primary" startIcon={<ControlPointIcon />} onClick={(e) => { this.handleOpen("") }}>新規</Button>
                <div style={{ clear: "both" }}></div>
                <Table
                    className="gridCorporateNormalList"
                    rowHeight={34}
                    rowsCount={this.props.rent_corporate_contacts_list.length}
                    height={200}
                    headerHeight={30}
                    width={(window.innerWidth / 2) - 75}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    onRowClick={this.RowClick}
                    rowClassNameGetter={this._rowClassNameGetter}
                >

                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton style={{ marginTop: -5 }} onClick={(e) => { this.handleOpen(this.props.rent_corporate_contacts_list[rowIndex].id); }} aria-label="edit" size="small"><EditIcon /></IconButton></Cell>)} width={40} /> */}
                    <Column width={this.state.columnWidths.section_id} isResizable={true} columnKey="section_id" header={<Cell>部署</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.section_select && this.props.rent_corporate_contacts_list[rowIndex].section_id ? this.props.section_select.filter((a) => a.value === this.props.rent_corporate_contacts_list[rowIndex].section_id).length > 0 ? this.props.section_select.filter((a) => a.value === this.props.rent_corporate_contacts_list[rowIndex].section_id)[0].label : "" : ""}</Cell>)} />
                    <Column width={this.state.columnWidths.user_id} isResizable={true} columnKey="user_id" header={<Cell>担当</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.users_select && this.props.rent_corporate_contacts_list[rowIndex].user_id ? this.props.users_select.filter((a) => a.value === this.props.rent_corporate_contacts_list[rowIndex].user_id).length > 0 ? this.props.users_select.filter((a) => a.value === this.props.rent_corporate_contacts_list[rowIndex].user_id)[0].label : "" : ""}</Cell>)} />
                    <Column width={this.state.columnWidths.date} isResizable={true} columnKey="date" header={<Cell>日付</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_corporate_contacts_list[rowIndex].date).format("YYYY-MM-DD")}</Cell>)} />
                    <Column width={this.state.columnWidths.rent_m_corporate_way_id} isResizable={true} columnKey="rent_m_corporate_way_id" header={<Cell>対応方法</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                        {(() => {
                            if (this.props.rent_corporate_contacts_list[rowIndex].rent_m_corporate_way_id === 0) {
                                return "";
                            } else if (this.props.rent_corporate_contacts_list[rowIndex].rent_m_corporate_way_id === 1) {
                                return "企業訪問";
                            } else if (this.props.rent_corporate_contacts_list[rowIndex].rent_m_corporate_way_id === 2) {
                                return "TEL";
                            } else if (this.props.rent_corporate_contacts_list[rowIndex].rent_m_corporate_way_id === 3) {
                                return "メール";
                            }
                        })()}

                    </Cell>)} />
                    <Column width={this.state.columnWidths.remarks} isResizable={true} columnKey="remarks" header={<Cell>対応内容</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_list[rowIndex].remarks}</Cell>)} />

                </Table>






                <Modal isOpen={this.state.modal} size="lg" fade={false} toggle={(e) => { this.setState({ "modal": false }) }} >
                    <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                        <span>営業対応履歴</span>
                        {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
                    </ModalHeader>


                    <ModalBody>


                        <Card>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid justifyContent="space-between" item xs={6} style={{ display: "flex"}}>
                                        <div  style={{position: "relative"}}>
                                            <TextField
                                                id="date"
                                                label="対応日"
                                                type="date"
                                                name="date"
                                                size="small"
                                                onChange={this.onChange}
                                                value={moment(this.props.rent_corporate_contacts.date).format("YYYY-MM-DD")}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant="standard"
                                            />
                                        </div>
                                        <div  style={{position: "relative"}}>
                                            <TextField
                                                label="開始"
                                                style={{ width: 65 }}
                                                onChange={this.change_datetime}
                                                name = "start_at"
                                                // onChange={handleChange}
                                                // id="formatted-text-mask-input"
                                                onFocus={this.focus_start}
                                                onBlur={this.blur_start}
                                                InputProps={{ inputComponent: TextMaskCustom }}
                                                value={this.props.rent_corporate_contacts.start_at}
                                                // value={this.props.eigyou_detail.start_at}
                                                error={this.state.start_at_err}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant='standard'
                                            />
                                            {this.state.focus_start ? <DateTimeComponentTime start={true} start_time={null} change_datetime_select={this.change_datetime_select} detail={this.props.rent_corporate_contacts} change_detail={this.props.change_rent_corporate_contacts} yoyaku_date={moment(this.props.rent_corporate_contacts.date).format("YYYY/MM/DD")} name={"start_at"} /> : ""}
                                        </div>
                                        <div>–</div>
                                        <div  style={{position: "relative"}}>
                                            <TextField
                                                label="終了"
                                                style={{ width: 65 }}
                                                onChange={this.change_datetime}
                                                name = "end_at"
                                                // onChange={handleChange}
                                                // id="formatted-text-mask-input"
                                                onFocus={this.focus_end}
                                                onBlur={this.blur_end}
                                                InputProps={{ inputComponent: TextMaskCustom }}
                                                // value={this.props.eigyou_detail.end_at}
                                                value={this.props.rent_corporate_contacts.end_at}
                                                error={this.state.end_at_err}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant='standard'
                                            />
                                            {this.state.focus_end ? <DateTimeComponentTime start={false} start_time={this.props.rent_corporate_contacts.start_at} change_datetime_select={this.change_datetime_select} detail={this.props.rent_corporate_contacts} change_detail={this.props.change_rent_corporate_contacts} yoyaku_date={moment(this.props.rent_corporate_contacts.date).format("YYYY/MM/DD")} name={"end_at"} /> : ""}
                                        </div>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControl>
                                            {/* <FormLabel></FormLabel> */}
                                            <FormGroup row>
                                                <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_contacts.intend} value={this.props.rent_corporate_contacts.intend} onChange={this.onChange} name="intend" />} label="予定" />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>

                                    <Grid justifyContent="space-between" item xs={6}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.props.section_select.length === 0 && this.props.rent_corporate_contacts.section_id ? [this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.section_id)[0]]
                                                : this.props.section_select}
                                            getOptionLabel={(option) => option.label ? option.label : ''}

                                            style={{ width: "100%" }}
                                            name="userId"
                                            loading={this.props.users_loading}

                                            value={this.props.rent_corporate_contacts.section_id ? this.props.section_select.filter(a => a.value === this.props.rent_corporate_contacts.section_id)[0] : {}}

                                            onChange={this.handleSectionIdChangeSet}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                name="sectionId"
                                                // error={!this.props.rent_responses_editor.user_id}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>

                                                            {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                // onChange={this.handleChange}
                                                label="部署" />}

                                        />

                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            // options={this.props.users_select.map((value) => ({
                                            //     value: value.value,
                                            //     label: value.label
                                            //     }))}
                                            // getOptionLabel={(option) => option.label}
                                            options={this.props.users_select.length === 0 && this.props.rent_corporate_contacts.user_id ? [this.props.users_select.filter(a => a.value === this.props.rent_responses_editor.user_id)[0]]
                                                : this.props.users_select}
                                            getOptionLabel={(option) => option.label ? option.label : ''}

                                            style={{ width: "100%" }}
                                            name="userId"
                                            loading={this.props.users_loading}

                                            value={this.props.rent_corporate_contacts.user_id ? this.props.users_select.filter(a => a.value === this.props.rent_corporate_contacts.user_id)[0] : {}}

                                            onChange={this.handleUserIdChangeSet}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                name="userId"
                                                // error={!this.props.rent_responses_editor.user_id}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>

                                                            {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                // onChange={this.handleChange}
                                                label="営業担当" />}

                                        />

                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={4}>
                                        <FormControl variant="outlined" fullWidth className={{}}>
                                            <InputLabel>対応方法</InputLabel>
                                            <MaterialUiSelect
                                                name="rent_m_corporate_way_id"
                                                value={this.props.rent_corporate_contacts.rent_m_corporate_way_id}
                                                onChange={this.onChange}
                                                label="rank"
                                            >
                                                <MenuItem value={0}>-</MenuItem>
                                                <MenuItem value={1}>企業訪問</MenuItem>
                                                <MenuItem value={2}>TEL</MenuItem>
                                                <MenuItem value={3}>メール</MenuItem>

                                            </MaterialUiSelect>
                                        </FormControl>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={8}>
                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="staff"
                                            label="企業担当者様名"
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.staff}
                                        />
                                    </Grid>
                                    {/* <Grid justify="space-between" item xs={4}>
                                    <FormControl variant="outlined" fullWidth className={{}}>
                                            <InputLabel>主要取引先</InputLabel>
                                            <MaterialUiSelect
                                                name="main_corp"
                                                value={this.props.rent_corporate_contacts.main_corp}
                                                onChange={this.onChange}
                                                label="rank"
                                            >
                                                <MenuItem value={0}>-</MenuItem>
                                                <MenuItem value={1}>穴吹ハウジング</MenuItem>
                                                <MenuItem value={2}>その他</MenuItem>
                                                <MenuItem value={3}>穴吹ハウジング or その他</MenuItem>

                                            </MaterialUiSelect>
                                        </FormControl>

                                    </Grid>
                                    <Grid justify="space-between" item xs={8}>
                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="other_main_corp"
                                            label="その他主要取引先"
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.other_main_corp}
                                            disabled = {!(this.props.rent_corporate_contacts.main_corp >= 2)}
                                        />
                                    </Grid> */}
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <div style={{marginBottom:7}}><span style={{color:"red"}}>※具体的に入力</span></div>
                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="remarks"
                                            label="対応内容"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.remarks}
                                        />
                                    </Grid>
                                    {/* <Grid justify="space-between" item xs={6}>

                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="rival"
                                            label="ライバル情報（他社との状況）"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.rival}
                                        />
                                    </Grid> */}
                                    <Grid justifyContent="space-between" item xs={6}>

                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="need"
                                            label="社宅ニーズ"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.need}
                                        />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>

                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="trouble"
                                            label="お困りごと"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.trouble}
                                        />
                                    </Grid>
                                    {/* <Grid justify="space-between" item xs={6}>

                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="request"
                                            label="弊社に望むこと"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.request}
                                        />
                                    </Grid> */}

                                </Grid>



                            </CardContent>

                        </Card>




                    </ModalBody>


                    {/* <Button onClick={this.handleClose}>閉じる</Button> */}
                    <ModalFooter>

                        <Button variant="contained" color="primary" onClick={() => this.handleUpdate(this.props.rent_corporate_contacts.id, 0)} style={{ backgroundColor: "#5cb85c", marginRight: 5 }}>保存</Button>
                        {this.props.rent_corporate_contacts.id ? <Button variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button> : ""}

                    </ModalFooter>
                </Modal>

                <Modal isOpen={this.state.modal_del} fade={false} className="modal-dialog-centered">
                    <ModalBody>
                        <div>本当に削除してよろしいですか？</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="secondary" onClick={() => this.handleUpdate(this.props.rent_corporate_contacts.id, 1)}>削除</Button>
                        <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
                    </ModalFooter>

                </Modal>


            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
        users_select: state.masterReducer.users_select,
        section_select: state.masterReducer.section_select,
        kanricorps: state.masterReducer.kanricorps,
        rent_corporate_contacts: state.corporation.rent_corporate_contacts,
        rent_corporate_contacts_list: state.corporation.rent_corporate_contacts_list,
        user_details: state.masterReducer.user_details,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        get_rent_corporate_contacts_list(state) {
            dispatch(Corporation.get_rent_corporate_contacts_list(state))
        },
        change_rent_corporate_contacts(state) {
            dispatch(Corporation.change_rent_corporate_contacts(state))
        },
        set_rent_corporate_contacts(state) {
            dispatch(Corporation.set_rent_corporate_contacts(state))
        },
        insert_rent_corporate_contacts(state) {
            dispatch(Corporation.insert_rent_corporate_contacts(state))
        },
        update_rent_corporate_contacts(state) {
            dispatch(Corporation.update_rent_corporate_contacts(state))
        },
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




