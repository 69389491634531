import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select, delay } from 'redux-saga/effects';
import * as ResponseFollowSheetApi from '../Api/ResponseFollowSheetApi';
import moment from 'moment';

export interface State {
    response_follow_sheet_list: any,
    response_follow_sheet_open: boolean
}
const initialState: State = {
    response_follow_sheet_list: [],
    response_follow_sheet_open: false
}



///action 
const actionCreator = actionCreatorFactory();

export const get_response_follow_sheet_list = actionCreator('GET_RESPONSE_FOLLOW_SHEET_LIST');
export const get_response_follow_sheet_list_success = actionCreator('GET_RESPONSE_FOLLOW_SHEET_LIST_SUCCESS');
export const change_response_follow_sheet_open = actionCreator<boolean>('CHANGE_RESPONSE_FOLLOW_SHEET_OPEN');





export const ResponseFollowReducer = reducerWithInitialState(initialState)
    .case(get_response_follow_sheet_list_success, (state, payload) => ({ ...state, response_follow_sheet_list: payload }))
    .case(change_response_follow_sheet_open, (state, payload) => ({ ...state, response_follow_sheet_open: payload }))




// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const ResponseFollowSaga = [
    takeEvery('GET_RESPONSE_FOLLOW_SHEET_LIST', handle_get_response_follow_sheet_list),

];
// export default masterSaga;



function* handle_get_response_follow_sheet_list(action: any) {

    const { payload, error } = yield call(ResponseFollowSheetApi.request_getfile, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        yield put(get_response_follow_sheet_list_success(payload));
    } else {
        // alert(error)
    }
}

