import React, { Component } from 'react';
import { connect } from 'react-redux';

// import CKEditor from '@ckeditor/ckeditor5-react';
// import CkEditor, { getEditorNamespace } from 'ckeditor4-react';
// import CKEDITOR from 'getEditorNamespace-react';
import * as MailTemplate from './Saga/MailTemplate';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ParamGrid from './ParamGrid';

// import CKEDITOR from 'https://cdn.ckeditor.com/4.12.1/standard-all/ckeditor.js'
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import ClassicEditor from '@ckeditor/ckeditor5-build-decoupled-document';
// import FontColor from '@ckeditor/ckeditor5-font/src/fontcolor';

// import Font from '@ckeditor/ckeditor5-font/src/font';
class CkEditorComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      data: "",
      modal: false
      // editorState: EditorState.createEmpty(),
      // editorState: editorState,
    };

    this.toggle = this.toggle.bind(this);
    this.paramset = this.paramset.bind(this);

  }
  componentDidMount() {

    // var editor = CKEDITOR.instances.editor1;
    // CKEDITOR.replace(this.elementName);
  }
  toggle() {
    this.setState({ modal: !this.state.modal })
  }
  onChange = (editorState) => {
    // this.setState({
    //   editorState,
    // });
    // console.log(this)

    // var editor = window.CKEDITOR.instances.editor1;

    // // Check the active editing mode.
    // if (editor.mode == 'wysiwyg') {
    //   // Insert as plain text.
    //   // https://ckeditor.com/docs/ckeditor4/latest/api/CKEDITOR_editor.html#method-insertText
    //   editor.insertText("てすと");
    // } 
  }


  paramset = (text) => {
    // let editor = window.CKEDITOR.instances.editor2;
    let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];

    editor.insertHtml(text);
    // editor.insertText(text);
    this.toggle()
  }
  html = (html) => {
    // let editor = window.CKEDITOR.instances.editor2
    let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];
    ;
    editor.insertHtml(html);
  }
  focus = () => {

    this.editor.focus();
    this.editor.insertText("eddd");
  };


  //   upload() {alert()
  //     // // Update the loader's progress.
  //     // server.onUploadProgress( data => {
  //     //     loader.uploadTotal = data.total;
  //     //     loader.uploaded = data.uploaded;
  //     // } );

  //     // // Return a promise that will be resolved when the file is uploaded.
  //     // return loader.file
  //     //     .then( file => server.upload( file ) );
  // }

  // Aborts the upload process.
  // abort() {
  //     // Reject the promise returned from the upload() method.
  // //     server.abortUpload();
  // }
  render() {


    return (
      <div>
        {/* <CkEditor

          // editor={ ClassicEditor }

          // type="classic"
          data={this.props.mail_template_details.body_all}
          // onInit={ editor => {
          //     // You can store the "editor" and use when it is needed.
          //     console.log( 'Editor is ready to use!', editor );

          // } }

          onChange={(event, editor) => {

            const data = event.editor.getData();
            let mail_template_details = this.props.mail_template_details
            mail_template_details.body_all = data
            this.props.change_mail_template_details(mail_template_details)
            // this.onChange()

            // this.props.body=event.editor.getData();
            this.setState({
              data,
            });


            console.log({ event, editor, data });
          }}
          onBlur={editor => {
            console.log('Blur.', editor);
          }}
          onFocus={editor => {
            console.log('Focus.', editor);

          }}
          onSelectionChange={editor => {
            console.log()

          }}


          //   FileRepository={ editor => {
          //     console.log( 'Focus.', editor );
          // } }
          config={{
            uploadUrl: 'localhost',
            height: this.props.height == null ? 500 : this.props.height,
            language: 'ja',
            extraPlugins: 'image2,uploadimage,colorbutton,colordialog',
            allowedContent: true,
            enterMode: 2,//エンターを押したときにpタグじゃなbrを挿入
            // Plugins:[FontColor],
            // extraPlugins: fontcolor,
            //                 colorButton_colors: 'CF5D4E,454545,FFF,CCC,DDD,CCEAEE,66AB16',
            // colorButton_enableAutomatic: false

            toolbar: [['Bold'], ['Italic'], ['image'], ['Link'], ['Format', 'Font', 'FontSize'], ['FontSize'], ['TextColor', 'BGColor'], ['Table'], ['Source'], ['Undo'], ['Redo']]

          }}
        /> */}

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ margin: '5px' }}
          onClick={this.toggle}>自動項目</button>


        {/* <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%担当名%")}>担当名</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.html("<img alt='店舗写真' src='https://rent.global-center.co.jp/home/shopimg' style='max-width:300px;max-height:300px'  />")}>店舗写真</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.html("<img alt='店舗写真' src='https://rent.global-center.co.jp/home/staffimg' style='max-width:300px;max-height:300px'  />")}>スタッフ写真</button>

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.html("<img alt='店舗マップ' src='https://rent.global-center.co.jp/home/shopmapimg/' style='max-width:300px;max-height:300px'  />")}>店舗マップ</button>


        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%店舗名%")}>店舗名</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%お客様名%")}>お客様名</button>

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%店舗メールアドレス%")}>店舗メールアドレス</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%スタッフ紹介URL%")}>スタッフ紹介URL</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%営業時間%")}>営業時間</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%定休日%")}>定休日</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%店舗TEL%")}>店舗TEL</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%店舗FAX%")}>店舗FAX</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%店舗住所%")}>店舗住所</button>

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%物件名%")}>物件名</button>

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%物件所在地%")}>物件所在地</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%竣工年月%")}>竣工年月</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%沿線駅%")}>沿線駅</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%種別・構造%")}>種別・構造</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%間取り%")}>間取り</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%占有面積%")}>占有面積</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%賃料%")}>賃料</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%共益費%")}>共益費</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%敷金%")}>敷金</button>
        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%礼金%")}>礼金</button>

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%媒体名挨拶%")}>【媒体名挨拶】この度は、≪媒体名≫よりお問い合わせいただきまして、まことにありがとうございます。</button>

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.name("%他店まとめて送信説明文%")}>【他店まとめて送信説明文】○○店へ重複してお問い合わせいただいておりましたので、当店にて 一括してご返信させていただきます。</button>




        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.html("<img alt='外観' src='https://homestationapi.azurewebsites.net/api/img/etc/gaikan.png' style='max-width:540px;max-height:400px' />")}>外観</button>

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ marginLeft: '5px' }}
          onClick={() => this.html("<img alt='間取り' src='https://homestationapi.azurewebsites.net/api/img/etc/madori.png' style='max-width:300px;max-height:300px'  />")}>間取</button>
        */}
        <Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>

          <ModalHeader toggle={this.toggle}>項目選択</ModalHeader>
          <ModalBody>
            <ParamGrid paramset={a => this.paramset(a)} />
          </ModalBody>
          <ModalFooter>

            {/* <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggledelete}>キャンセル</button> */}
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}





const mapStateToProps = (state) => {
  return {
    mail_template_details: state.mailtemplate.mail_template_details,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_mail_template_details(state) {
      dispatch(MailTemplate.change_mail_template_details(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CkEditorComponent);