import React, { Component } from 'react';

import { connect } from 'react-redux';


import "react-datepicker/dist/react-datepicker.css";

import { Field, reduxForm } from 'redux-form';

import '../../Css/Table.css';

let Bosyuflg;



// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};



    return errors;
};


export class RenderField extends React.Component {
    render() {
        const { className, id, name, type, style, input, disabled, onBlur, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};



class LifeLineEditComponent extends Component {
   
    constructor(props) {
        super(props);
      
        this.state = {

            
        };

        Bosyuflg = 1;

   
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.props.initialize(this.props.roomdetail);//フォームに初期値を反映させる
    }


    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }
    
  componentDidMount() {
    // This method is called when the component is first added to the document
      this.ensureDataFetched();
  }

  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      //console.log(startDateIndex)

      //this.props.requestWeatherForecasts(0);

    }


    render() {

        //moment.locale('ja')
      return (
          <div>
                  <button className="btn btn-sm btn-light float-right" style={{ display: (this.props.notclose === 1) ? 'none' : '' }} onClick={this.props.tatemonoeditchange}>閉じる</button>
            <table>
                <colgroup width='20%' />
                <colgroup width='30%' />
                <colgroup width='20%' />
                <colgroup width='30%' />
                <tbody>
                    <tr >
                        <th>水道</th>
                        <td>
                            <Field
                                name="suidouNo"
                                id="suidouNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="0">-</option>
                            </Field>
                        </td>
                        <th>水道業者</th>
                        <td>
                            <Field
                                name="suidouGyoshaNo"
                                id="suidouGyoshaNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="0">-</option>
                            </Field>
                        </td>
                    </tr>

                    <tr >
                        <th>ガス</th>
                        <td>
                            <Field
                                name="gasNo"
                                id="gasNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="0">-</option>
                            </Field>
                        </td>
                        <th>ガス業者</th>
                        <td>
                            <Field
                                name="gasGyoshaNo"
                                id="gasGyoshaNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="0">-</option>
                            </Field>
                        </td>
                    </tr>
                    <tr >
                        <th>給湯</th>
                        <td>
                            <Field
                                name="kyuutouNo"
                                id="kyuutouNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="0">-</option>
                            </Field>
                        </td>
                        <th>電気業者</th>
                        <td>
                            <Field
                                name="denkiGyoshaNo"
                                id="denkiGyoshaNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="0">-</option>
                            </Field>
                        </td>
                    </tr>
                    <tr >
                        <th>排水</th>
                        <td colSpan="3">
                            <Field
                                name="haisuiNo"
                                id="haisuiNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="0">-</option>
                            </Field>
                        </td>
                    </tr>
                    <tr >
                        <th>ゴミ回収業者</th>
                        <td colSpan="3">
                            <Field
                                name="gomiGyoshaNo"
                                id="gomiGyoshaNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="0">-</option>
                            </Field>
                        </td>
                    </tr>
                    <tr >
                        <th>ゴミステーション場所</th>
                        <td colSpan="3">
                            <Field
                                name="gomiStationBashoNo"
                                id="gomiStationBashoNo"
                                placeholder=""
                                className="form-control"
                                component="select"
                            >
                                <option value="-1">-</option>
                                <option value="1">敷地内</option>
                                <option value="2">敷地外</option>
                            </Field>
                        </td>
                    </tr>
                </tbody>
              </table>

              <button className="btn btn-primary float-right" onClick={this.handleFormSubmit}>保存</button>
              </div>
    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

LifeLineEditComponent = reduxForm({
    form: 'tatemonoeditform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(LifeLineEditComponent)

export default connect(mapStateToProps, mapDispatchToProps)(LifeLineEditComponent);