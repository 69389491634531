import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import * as Files from './Saga/Files';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';
// import * as Contracts from './Saga/Contracts';
import Select from '@mui/material/Select';

var moment = require('moment-timezone');

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.application_files[rowIndex][column.key]}
  </Cell>;

const UpdatedAt = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {moment(props.application_files[rowIndex][column.key]).format('YYYY-MM-DD HH:mm:ss')}
  </Cell>;

const PName = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <IconButton style={{marginTop:-5}} onClick={(e) => { props.download_application_files(props.application_files[rowIndex]) }} aria-label="edit" size="small">
      <GetAppIcon />
    </IconButton>
  </Cell>;

const Edit = ({ column, props, rowIndex, file_remarks_edit_open }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <IconButton style={{marginTop:-5}} onClick={(e)=>{file_remarks_edit_open({ fileid: props.application_files[rowIndex].id, name: props.application_files[rowIndex].name, remarks: props.application_files[rowIndex].remarks ? props.application_files[rowIndex].remarks : "" }) }} aria-label="edit" size="small">
      <EditIcon />
    </IconButton>
  </Cell>;


const Amount = ({ column, props, rowIndex, monies }) =>
  monies[rowIndex].name === "合計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >{monies[rowIndex][column.key]}</Cell> : monies[rowIndex].rent_m_etc_id ? <Cell style={{ whiteSpace: "nowrap", background: "#CCC", width: "100%", height: "100%" }} /> : <Cell style={{ whiteSpace: "nowrap" }} >
    <TextField
      id="outlined-basic"
      //   className={classes.textField}
      label=""
      margin="normal"
      name="tatemono_name"
      // onChange={this.handleChange}
      inputProps={{ min: 0, style: { textAlign: 'right' } }} // the change is here
      value={monies[rowIndex][column.key]}
      style={{ width: "70px", marginTop: 0 }}

    />

  </Cell>;
const all_seikyuu = [
  { value: -1, name: "請求" },
  { value: 1, name: "定額" },
  { value: 2, name: "実費" },
  { value: 3, name: "込み" }
]
const koushin_seikyuu = [
  { value: -1, name: "請求" },
  { value: 3, name: "無し" }
]
const seisan_seikyuu = [
  { value: 1, name: "実費" },
  { value: 2, name: "込み" }
]
const Seikyuu = ({ column, props, rowIndex, monies }) =>
  monies[rowIndex].name === "合計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} /> : <Cell style={{ whiteSpace: "nowrap" }} >
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={monies[rowIndex].rent_m_etc_id ? monies[rowIndex].rent_m_etc_id : -1}
    // defaultValue={0}
    // onChange={handleChange}
    >
      {monies[rowIndex].account_group === 3 ? koushin_seikyuu.map(function (value) {
        return <MenuItem value={value.value}>{value.name}</MenuItem>
      }) : monies[rowIndex].rent_m_account_id === 17 ? seisan_seikyuu.map(function (value) {
        return <MenuItem value={value.value}>{value.name}</MenuItem>
      }) : all_seikyuu.map(function (value) {
        return <MenuItem value={value.value}>{value.name}</MenuItem>
      })}


    </Select>
  </Cell>;


const Delete = ({ column, props, rowIndex, monies }) =>
  monies[rowIndex].name === "合計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} /> : monies[rowIndex].rent_m_account_id === 1 || monies[rowIndex].rent_m_account_id === 2 || monies[rowIndex].rent_m_account_id === 3 || monies[rowIndex].rent_m_account_id === 4 || monies[rowIndex].rent_m_account_id === 9 || monies[rowIndex].rent_m_account_id === 10 ? <Cell style={{ whiteSpace: "nowrap", background: "#CCC", width: "100%", height: "100%" }} /> : <Cell style={{ whiteSpace: "nowrap" }} >
    <Tooltip title="削除" >
      <IconButton size="small" aria-label="delete" onClick={() => this.props.change_rent_contract_money_edit_disp(false)}>
        <DeleteForeverIcon color="secondary" />
      </IconButton>
    </Tooltip>
  </Cell>;

const Koumoku = ({ column, props, rowIndex, monies }) =>

  monies[rowIndex].name === "合計" ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >合計</Cell> :
    (monies[rowIndex].account_group === 3 || monies[rowIndex].account_group === 4) ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >{monies[rowIndex].name}</Cell>
      : (monies[rowIndex].rent_m_account_id === 1 || monies[rowIndex].rent_m_account_id === 2 || monies[rowIndex].rent_m_account_id === 3 || monies[rowIndex].rent_m_account_id === 4 || monies[rowIndex].rent_m_account_id === 9 || monies[rowIndex].rent_m_account_id === 10 || monies[rowIndex].rent_m_account_id === 11 || monies[rowIndex].rent_m_account_id === 12 || monies[rowIndex].rent_m_account_id === 14) ? <Cell style={{ whiteSpace: "nowrap", background: "#f0f8ff", width: "100%", height: "100%" }} >{monies[rowIndex].name}</Cell>
        : <Cell style={{ whiteSpace: "nowrap" }} >
          {props.tm_chinryou_koumoku.length > 0 ? <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={monies[rowIndex].rent_m_account_id}
          // defaultValue={0}
          // onChange={handleChange}
          >
            {props.tm_chinryou_koumoku.map(function (value) {
              return <MenuItem value={value.Chinryou_Koumoku_No}>{value.Chinryou_Koumoku_Name}</MenuItem>
            })}


          </Select> : ""}
        </Cell>

const files = ['pdf', 'png', 'jpg', 'jpeg', 'bmp', 'gif'];

const Disp = ({ column, props, rowIndex, openWindow }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {files.includes(props.application_files[rowIndex].mime.trim()) ? 
    <span>

    <Button variant="contained" style={{}} size="small" onClick={() => props.download_disp_application_files(props.application_files[rowIndex])} color="primary">
      表示
    </Button>
    <IconButton size="small" onClick={() => openWindow(props.application_files[rowIndex].id, props.application_files[rowIndex].blob)} color="primary">
      <OpenInNewIcon />
    </IconButton>
    </span> : ""}
  </Cell >;


var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns = [
  { key: 'p_name', name: 'DL', width: 50 },
  {
    key: 'name', name: 'ファイル名', width: 200,

  },
  { key: 'remarks', name: '備考', width: 150 },
  // { key: 'win', name: '', width: 80 },
  { key: 'disp', name: '表示', width: 100 },
  { key: 'edit', name: '編集', width: 60 },
  { key: 'updated_at', name: 'アップロード日時', width: 150 },



]






class CustomerList extends Component {
  constructor(props) {
    super(props);


    // this.props.rent_m_contract_follows_disp.map(async function (value) {
    //   if (value.disp) {
    //     columns.push({ key: value.key_name, name: value.name, company: value.company, width: 80 })
    //   }
    // })
    console.log(columns)

    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this.openWindow = this.openWindow.bind(this);


    // this.contractdetail = this.contractdetail.bind(this);

  }

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }
  

  openWindow(id, blob){

    window.open(`/FileDisp/` + '1/' + id, '_blank', 'noopener,width=500,toolbar=yes,menubar=yes,scrollbars=yes')

    // window.open(URL.createObjectURL(blob), '_blank', 'noopener,width=500,toolbar=yes,menubar=yes,scrollbars=yes')
  }
  

  componentWillMount(){
    if (this.props.application_files) {

      this.props.application_files.forEach((e) => {

        this.props.get_blob_application_files(e.id)

      })
    }
  }

  render() {

    return (
      <div id="contract_car" >





        {
          this.props.application_files ? <div style={{ marginBottom: 10 }}><Table
            rowHeight={40}
            rowsCount={this.props.application_files.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            // width="100%"
            // height={this.props.application_files.length * 40 + 42}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            width={(window.innerWidth / 2) - 75}
            height={350}
            headerHeight={40}>
            {this.state.columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "disp" ? <Disp column={value} props={this.props} rowIndex={rowIndex} openWindow={this.openWindow}></Disp>
                    //   : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Seikyuu>

                    //     : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Amount>
                    //       : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Delete>
                    : value.key === "p_name" ? <PName column={value} props={this.props} rowIndex={rowIndex} ></PName>
                    : value.key === "updated_at" ? <UpdatedAt column={value} props={this.props} rowIndex={rowIndex} ></UpdatedAt>
                    : value.key === "edit" ? <Edit column={value} props={this.props} rowIndex={rowIndex} file_remarks_edit_open={this.props.file_remarks_edit_open} ></Edit>
                    : <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table > </div> : ""
        }




      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    application_files: state.files.application_files,
    blob_application_files: state.files.blob_application_files,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    download_disp_application_files(state) {
      dispatch(Files.download_disp_application_files(state))
    },
    download_application_files(state) {
      dispatch(Files.download_application_files(state))
    },
    change_contract_file_update_disp(state) {
      dispatch(Files.change_contract_file_update_disp(state))
    },
    get_blob_application_files(state) {
      dispatch(Files.get_blob_application_files(state))
    },
    // change_responseid(state) {
    //   dispatch(Files.change_responseid(state))
    // },
    // change_modalResponseDetail(state) {
    //   dispatch(Files.change_modalResponseDetail(state))
    // },

    // change_rent_contract_id(state) {
    //   dispatch(Files.change_rent_contract_id(state))
    // },
    // change_rent_contract_money_edit_disp(state) {
    //   dispatch(Files.change_rent_contract_money_edit_disp(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);