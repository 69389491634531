import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';


import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import EditIcon from '@mui/icons-material/Edit';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';




const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))


class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };

        this.onChange = this.onChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
    }


    onChange(state){
        // console.log(this.props.claso_blog_places)
        let rent_m_corporates =  this.props.rent_m_corporates

        rent_m_corporates[state.target.name] = state.target.value
        
        this.props.change_rent_m_corporates(rent_m_corporates)
        this.setState({
            change: !this.state.change
        });
        
    }

    handleUpdate(id){


        this.props.update_rent_m_corporates()

        this.setState({
            modal: false,
            change: !this.state.change
        });

        
    }
    render() {

        return (
            <div>
                        <Button style={{ float: "right", background:"#5cb85c", color:"white" }} size="small" variant="contained" color="primary" startIcon={<EditIcon />} onClick={(e) => { this.setState({ "modal": true }) }}>編集</Button>
                        <table>
                            <colgroup style={{ width: "40%" }} />
                            <colgroup style={{ width: "60%" }} />
                            {/* <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} /> */}
                            <tbody>
                                <tr >
                                    <th>社宅ご担当者様情報</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.remarks_person}</td>
                                </tr>
                                <tr >
                                    <th>他社情報・注意規定・弊社に望むこと</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.remarks_attention}</td>
                                </tr>
                                <tr >
                                    <th>過去リクエスト内容</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.remarks_request}</td>
                                </tr>
                                <tr >
                                    <th>土地・建物等　情報</th>
                                    <td colSpan={1}>{this.props.rent_m_corporates.remarks_information}</td>
                                </tr>
                            </tbody>
                        </table>


                <Modal isOpen={this.state.modal} fade={false} size="lg">
                    <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                        <span>その他情報</span>
                        {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
                    </ModalHeader>
                        

                    <ModalBody>
                        <Card>
                            <CardContent>

                        <Grid container spacing={1}>
    
                            <Grid justifyContent="space-between" item xs={12}>
                                <TextField
                                    fullWidth
                                    name="remarks_person"
                                    label="社宅ご担当者様情報"
                                    multiline
                                    rows={4}
                                    variant="outlined"
                                    value={this.props.rent_m_corporates.remarks_person}
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>
                                <TextField
                                    fullWidth
                                    name="remarks_attention"
                                    label="他社情報・注意規定・弊社に望むこと"
                                    multiline
                                    rows={4}
                                    value={this.props.rent_m_corporates.remarks_attention}
                                    variant="outlined"
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>
                                <TextField
                                    fullWidth
                                    name="remarks_request"
                                    label="過去リクエスト内容"
                                    multiline
                                    rows={4}
                                    value={this.props.rent_m_corporates.remarks_request}
                                    variant="outlined"
                                    onChange={this.onChange}
                                />
                            </Grid>
                            <Grid justifyContent="space-between" item xs={12}>
                                <TextField
                                    fullWidth
                                    name="remarks_information"
                                    label="土地・建物等情報"
                                    multiline
                                    rows={4}
                                    value={this.props.rent_m_corporates.remarks_information}
                                    variant="outlined"
                                    onChange={this.onChange}
                                />
                            </Grid>

                        </Grid>



                            </CardContent>

                        </Card>




                    </ModalBody>
                        

                        {/* <Button onClick={this.handleClose}>閉じる</Button> */}
                        <ModalFooter>

                        <Button variant="contained" color="primary" onClick={()=>this.handleUpdate(this.props.rent_m_corporates.id)} style={{backgroundColor:"#5cb85c", marginRight:5}}>保存</Button>　

                        </ModalFooter>
                    </Modal>

                </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_m_corporates(state) {
            dispatch(Corporation.change_rent_m_corporates(state))
        },
        update_rent_m_corporates(state) {
            dispatch(Corporation.update_rent_m_corporates(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




