
const authentication = require('../../../react-azure-adb2c2').default;

export const insert = (values: any) => {

    const url = process.env.REACT_APP_API_SETTINGS_URL + `/api/v1/calling/getUuid`;
    
    // let url = `http://localhost:5000/api/v1/calling/getUuid`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const update = (values: any) => {

    const url = process.env.REACT_APP_API_SETTINGS_URL + `/api/v1/calling/updateCall`;
    
    // let url = `http://localhost:5000/api/v1/calling/updateCall`;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


export const detail = (value: any) => {

    const url = process.env.REACT_APP_API_SETTINGS_URL + `/api/v1/calling/getCall/` + value;
    // let url = `http://localhost:5000/api/v1/calling/getCall` + value;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


