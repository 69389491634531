import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';


import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import { TextField } from '@mui/material';
import Button from '@mui/material/Button';
import MaskedInput from 'react-text-mask';
import Tooltip from '@mui/material/Tooltip';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components'
import GoogleMapReact, { MapOptions, Maps } from 'google-map-react'
import Geocode from 'react-geocode';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import CorporatesDetailDialog from "./CorporatesDetailDialog";
import CorporatesPartnerShipDialog from "./CorporatesPartnerShipDialog";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';
// interface Props {
//     // classes: any,
//     // m_corporations_list:any,
//     // get_m_corporations_list:any,
//     // customerNewDispSet: any,
//     // customerNewDisp:number,
//     // customerno:number,
//     // customerList:any,
//     // toggle:any,
//     // get_rent_contract_accounting: any,
//     // banks: any,
//     // get_banks: any
// }



// interface State {

//     // state types
// }

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))
const phoneNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
const mobileNumberMask = [
    /[0-9]/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
const postNumberMask = [

    /[0-9]/,
    /\d/,
    /\d/,
  
    "-",
  
    /\d/,
    /\d/,
    /\d/,
    /\d/,
  
  ];

function PostMaskCustom(props) {
    const { inputRef, ...other } = props;
  
    return (
  
      <MaskedInput
        {...other}
        // ref={inputRef}
        mask={postNumberMask}
        placeholderChar={'\u2000'}
      // showMask
      />
    );
  }

function TelMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" || !props.value ? mobileNumberMask : phoneNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

const createMapOptions = (maps) => {
  return {
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT,
    },
    mapTypeControl: false,
    zoomControl: false,
    scaleControl: false,
    streetViewControl: true,
    fullscreenControl: false,
    // styles: [
    //   {
    //     featureType: 'water',
    //     elementType: 'geometry',
    //     stylers: [
    //       {
    //         color: '#e9e9e9',
    //       },
    //       {
    //         lightness: 17,
    //       },
    //     ],
    //   },

  }
}
const Pin = styled.div``
class CustomerList extends Component {
    constructor(props) {
 
        super(props);
        this.state = {
            modal: false,
            modal_del: false,
            modalselect: false,
            modal_partnership: false,

            zoom: 14,
            draggable: true,
            lat: 34.36179852,
            lng: 134.048788977,
            geo_lat : "",
            geo_lng : "",
            center: null,

        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()
        this.onChange = this.onChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);

        this.onChildMouseMove = this.onChildMouseMove.bind(this);
        this.onChildMouseUp = this.onChildMouseUp.bind(this);
        this.handleZoomChanged = this.handleZoomChanged.bind(this);
        this.getGeocode = this.getGeocode.bind(this);
        this.handleUpdatePartnership = this.handleUpdatePartnership.bind(this);
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    onChange(state){
        // console.log(this.props.claso_blog_places)
        let rent_m_corporates =  this.props.rent_m_corporates

        rent_m_corporates[state.target.name] = state.target.value
        
        this.props.change_rent_m_corporates(rent_m_corporates)
        this.setState({
            change: !this.state.change
        });
        
    }

    handleUpdate(id, delflg){
//削除しかこない
        let rent_m_corporates =  this.props.rent_m_corporates
        rent_m_corporates["is_display"] = delflg
        this.props.change_rent_m_corporates(rent_m_corporates)
   

        this.props.update_rent_m_corporates()

        this.props.change_rent_m_corporates_detail_open(false)
        this.props.change_rent_m_corporates_top_open(false)


        this.setState({
            modal_del: false,
            change: !this.state.change
        });
        
    }








    componentWillReceiveProps(props) {
        //   alert(this.props.claso_blog_places.lat)
        this.setState({
            // lat: this.props.claso_blog_places.lat,
            // lng: this.props.claso_blog_places.lng
          });
        // this.setState({pins:props.tatemono_list})
      }
    
      _onBoundsChange = (center, zoom, bounds, marginBounds) => {
        this.setState({ zoom: zoom })
      }
    
      handleZoomChanged(){


    }
      onChildMouseUp() {
        this.setState({ draggable: true })
      }
      onChildMouseMove(childKey, childProps, mouse) {

          console.log(mouse)
        // function is just a stub to test callbacks
        this.setState({
          draggable: false,
          lat: mouse.lat,
          lng: mouse.lng,
    
        });

        // if(this.state.zoom < 14){
            this.setState({
                center: {lat:mouse.lat, lng:mouse.lng},
            });
        // }


        let rent_m_corporates =  this.props.rent_m_corporates

        rent_m_corporates["lat"] = mouse.lat
        rent_m_corporates["lng"] = mouse.lng

        this.props.change_rent_m_corporates(rent_m_corporates)
        this.setState({
            change: !this.state.change
        });


        // this.props.mapchange(mouse.lat, mouse.lng)
    
      }


    getGeocode() {
        // alert(this.props.tenant_details.address1 + this.props.tenant_details.address2 + this.props.tenant_details.address3 + this.props.tenant_details.address4 + this.props.tenant_details.address5)
        Geocode.setApiKey('AIzaSyDcEVxG_-xtQ1uCku--0iw84NPOffxow28');
        Geocode.setLanguage("ja");
        Geocode.fromAddress((this.props.rent_m_corporates.add1 ? this.props.rent_m_corporates.add1 : "") + (this.props.rent_m_corporates.add2 ? this.props.rent_m_corporates.add2 :""))
          .then((response) => {
    
            const { lat, lng } = response.results[0].geometry.location;
    
            let rent_m_corporates =  this.props.rent_m_corporates
            rent_m_corporates.lat = lat
            rent_m_corporates.lng = lng

            this.props.change_rent_m_corporates(rent_m_corporates)
            this.setState({
                center: {lat:lat, lng:lng},
                change: !this.state.change
            });


          })
          .catch((a) => {
            console.log(a)
            this.setState({
              lat: undefined,
              lng: undefined,
            });
          });
    
    }

    handleClose(){
        this.setState({
            modal_partnership: false,
            change: !this.state.change
        });
    }

    handleUpdatePartnership(){

        this.props.update_rent_m_corporates_partnership()

        this.handleClose()
        
    }

    render() {
        return (
            <div>
                {/* <Button style={{ float: "right" }} variant="contained" color="primary" onClick={{}}>編集</Button> */}
                <Button style={{ float: "right", marginRight:5, background:"#007bff", color:"white" }} size="small" variant="contained" color="primary" startIcon={<LinkIcon />} onClick={(e) => { this.setState({ "modal_partnership": true }) }}>提携登録</Button>
                <Button style={{ float: "right", marginRight:5, background:"#5cb85c", color:"white" }} size="small" variant="contained" color="primary" startIcon={<EditIcon />} onClick={() => { this.props.change_rent_m_corporates_detail_open(true) }}>基本情報編集</Button>
                {/* {this.props.rent_m_corporates.id && this.props.rent_responses_response_list && this.props.rent_responses_response_list.length == 0  ? <Button style={{ float: "right", marginRight:5 }} size="small" variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button>:""} */}
                {this.props.corporate_loading && this.props.rent_responses_list.length == 0  ? <Button style={{ float: "right", marginRight:5 }} size="small" variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button>:""}
                
                {this.props.rent_m_corporates.updated_at ? <div style={{float:"right", marginRight:10}}>最終更新：{moment(this.props.rent_m_corporates.updated_at).format("YYYY-MM-DD HH:mm:ss")} {this.props.rent_m_corporates.updated_user_id > 0 && this.props.users.length > 0 ? this.props.users.filter(a=>a.id == this.props.rent_m_corporates.updated_user_id)[0].userNameAll : ""}</div> :""}
                
                <Modal isOpen={this.state.modal_del} fade={false} className="modal-dialog-centered">
                    <ModalBody>
                        <div>本当に削除してよろしいですか？</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="secondary" onClick={()=>this.handleUpdate(this.props.rent_m_corporates.id, 1)}>削除</Button>
                        <Button variant="contained" style={{marginLeft:3}} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
                    </ModalFooter>
                        
                </Modal>

                <Grid container spacing={1}>
                    <Grid justifyContent="space-between" item xs={7}>
                        <table>
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <tbody>
                                <tr >
                                    <th>法人名<br/>{this.props.rent_m_corporates.id}</th>
                                    <td colSpan={3}>{this.props.rent_m_corporates.name}</td>
                                </tr>
                                <tr >
                                    <th>カナ</th>
                                    <td colSpan={3}>{this.props.rent_m_corporates.kana}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>
                    <Grid justifyContent="space-between" item xs={5}>
                    <table>
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <tbody>
                                <tr >
                                    <th>本年</th>
                                    <td></td>
                                    <th>ランク</th>
                                    <td></td>
                                </tr>
                                <tr >
                                <th>昨年</th>
                                    <td></td>
                                    <th>ランク</th>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>

                    </Grid>
                    <Grid justifyContent="space-between" item xs={12}>
                    <table>
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <tbody>
                                <tr >
                                    <th>主要取引先</th>
                                    <td>{this.props.rent_m_corporates.main_corp == 1 ? "穴吹ハウジング" : this.props.rent_m_corporates.main_corp == 2 ? "その他" : this.props.rent_m_corporates.main_corp == 3 ? "穴吹ハウジング or その他" : ""}</td>
                                    <th>その他取引先</th>
                                    <td>{this.props.rent_m_corporates.other_main_corp}</td>
                                </tr>
                                
                            </tbody>
                        </table>
                    </Grid>
                    {this.props.rent_m_corporates.referral_fee || 
                    this.props.rent_m_corporates.manage_referral_fee || 
                    this.props.rent_m_corporates.agent_charge || 
                    this.props.rent_m_corporates.manage_agent_charge || 
                    this.props.rent_m_corporates.be_discounted || 
                    this.props.rent_m_corporates.partnership_remarks ? <Grid justifyContent="space-between" item xs={12}>
                        <table>
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <colgroup style={{ width: "20%" }} />
                            <colgroup style={{ width: "30%" }} />
                            <tbody>
                                <tr >
                                    <th>紹介料（税別）</th>
                                    <td>
                                        <div>
                                            {this.props.rent_m_corporates.manage_referral_fee ? "管理：" + this.props.rent_m_corporates.manage_referral_fee :""}
                                            {this.props.rent_m_corporates.manage_referral_fee ? this.props.rent_m_corporates.manage_referral_fee_unit > 0 ? "円":"％" : ""}
                                            {this.props.rent_m_corporates.manage_referral_fee ? this.props.rent_m_corporates.manage_referral_fee_subject ? "（":"" : ""}
                                            {this.props.rent_m_corporates.manage_referral_fee ? this.props.rent_m_corporates.manage_referral_fee_subject ? this.props.rent_m_corporates.manage_referral_fee_subject.split(',').map(function (item) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.rent_m_corporates.manage_referral_fee ? this.props.rent_m_corporates.manage_referral_fee_subject ? "）":"" : ""}
                                        </div>
                                        <div>
                                            {this.props.rent_m_corporates.referral_fee ? "他：" + this.props.rent_m_corporates.referral_fee :""}
                                            {this.props.rent_m_corporates.referral_fee ? this.props.rent_m_corporates.referral_fee_unit > 0 ? "円":"％" : ""}
                                            {this.props.rent_m_corporates.referral_fee ? this.props.rent_m_corporates.referral_fee_subject ? "（":"" : ""}
                                            {this.props.rent_m_corporates.referral_fee ? this.props.rent_m_corporates.referral_fee_subject ? this.props.rent_m_corporates.referral_fee_subject.split(',').map(function (item) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.rent_m_corporates.referral_fee ? this.props.rent_m_corporates.referral_fee_subject ? "）":"" : ""}
                                        </div>
                                    </td>
                                    <th>仲介手数料の割引</th>
                                    <td>
                                        <div>
                                            {this.props.rent_m_corporates.manage_agent_charge ? "管理：" + this.props.rent_m_corporates.manage_agent_charge : ""}
                                            {this.props.rent_m_corporates.manage_agent_charge ? this.props.rent_m_corporates.manage_agent_charge_unit > 0 ? "円":"％" : ""}
                                            {this.props.rent_m_corporates.manage_agent_charge ? this.props.rent_m_corporates.manage_agent_charge_subject ? "（":"" : ""}
                                            {this.props.rent_m_corporates.manage_agent_charge ? this.props.rent_m_corporates.manage_agent_charge_subject ? this.props.rent_m_corporates.manage_agent_charge_subject.split(',').map(function (item) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.rent_m_corporates.manage_agent_charge ? this.props.rent_m_corporates.manage_agent_charge_subject ? "）":"":""}
                                        </div>
                                        <div>
                                            {this.props.rent_m_corporates.agent_charge ? "他：" + this.props.rent_m_corporates.agent_charge : ""}
                                            {this.props.rent_m_corporates.agent_charge ? this.props.rent_m_corporates.agent_charge_unit > 0 ? "円":"％" : ""}
                                            {this.props.rent_m_corporates.agent_charge ? this.props.rent_m_corporates.agent_charge_subject ? "（":"" : ""}
                                            {this.props.rent_m_corporates.agent_charge ? this.props.rent_m_corporates.agent_charge_subject ? this.props.rent_m_corporates.agent_charge_subject.split(',').map(function (item) {

                                                return item == "1" ? "建物" : item == "2" ? "駐車場" : ""

                                            }, this).join(',') : "" : ""}
                                            {this.props.rent_m_corporates.agent_charge ? this.props.rent_m_corporates.agent_charge_subject ? "）":"" : ""}
                                        </div>
                                    </td>
                                </tr>
                                <tr >
                                    <th>割引対象</th>
                                    <td colSpan={3}>{this.props.rent_m_corporates.be_discounted}</td>
                                </tr>
                                <tr >
                                    <th>注意事項・支払い方法・その他</th>
                                    <td colSpan={3}>
                                        {/* {this.props.rent_m_corporates.partnership_remarks} */}
                                        <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.rent_m_corporates.partnership_remarks && this.props.rent_m_corporates.partnership_remarks.length ? this.props.rent_m_corporates.partnership_remarks.replace(/\r?\n/g, '<br/>') : '' }}></span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Grid>:""}
                </Grid>

                <Modal isOpen={this.props.rent_m_corporates_detail_open} toggle={() => { this.props.change_rent_m_corporates_detail_open(false) }} fade={false} >
                    <CorporatesDetailDialog parentComponent={0}></CorporatesDetailDialog>
                    
                </Modal>

                <Modal isOpen={this.state.modal_partnership} size={"lg"} toggle={(e) => { this.handleClose() }} fade={false} >

                    <ModalHeader>
                        <span>提携登録</span>
                        <IconButton
                            aria-label="close"
                            style={{position:'absolute', right:1, top:1}}
                            onClick={(e) => { this.handleClose() }}
                            size="large">
                            <CloseIcon />
                        </IconButton>
                    </ModalHeader>
                    <ModalBody>
                        <CorporatesPartnerShipDialog></CorporatesPartnerShipDialog>
                    </ModalBody>
                    <ModalFooter>

                        <Button variant="contained" color="primary" onClick={() => {this.handleUpdatePartnership()}} style={{backgroundColor:"#5cb85c", marginRight:5}}>保存</Button>　
                        {/* {this.props.rent_m_corporates.id ? <Button variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button>:""} */}

                    </ModalFooter>
                    
                </Modal>

                    
                    

            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_corporates_top_open:state.corporation.rent_m_corporates_top_open,
        rent_m_corporates_detail_open:state.corporation.rent_m_corporates_detail_open,
        rent_responses_response_list: state.corporation.rent_responses_response_list,
        rent_responses_list: state.corporation.rent_responses_list,
        users: state.masterReducer.users,

        corporate_loading: state.corporation.corporate_loading,
        // m_corporations_list: state.corporation.m_corporations_list,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_m_corporates(state) {
            dispatch(Corporation.change_rent_m_corporates(state))
        },
        get_rent_m_corporates_zip_address(state) {
            dispatch(Corporation.get_rent_m_corporates_zip_address(state))
        },
        update_rent_m_corporates(state) {
            dispatch(Corporation.update_rent_m_corporates(state))
        },
        change_rent_m_corporates_top_open(state) {
            dispatch(Corporation.change_rent_m_corporates_top_open(state))
        },
        change_rent_m_corporates_detail_open(state) {
            dispatch(Corporation.change_rent_m_corporates_detail_open(state))
        },
        update_rent_m_corporates_partnership(state) {
            dispatch(Corporation.update_rent_m_corporates_partnership(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




