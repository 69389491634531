import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Contracts from '../Contracts/Saga/Contracts';
import * as Customers from './Saga/Customers';
import CustomerCorporateSearch from './CustomerCorporateSearch';
import CustomerDuplicateList from './CustomerDuplicateList';
import MaterialUiSelect from '@mui/material/Select';
import "react-datepicker/dist/react-datepicker.css";
import '../Contracts/Css/Contracts.css';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import MaskedInput from 'react-text-mask';
import moment from 'moment'
import { useKana } from 'react-use-kana';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

let timer



const Name1 = ({ rent_customer_editor_forms, change_rent_customer_editor_forms, customerList, handleChange }) => {
  const { kana, setKanaSource } = useKana();
  function change_name(e) {
    setKanaSource(e.target.value)


    if (e.target.name === "name1") {

      clearTimeout(timer);
      if (e.target.value !== "") {
        let name = e.target.value
        timer = setTimeout(function () {

          customerList({ name1: name })

        }, 400);
      }


      rent_customer_editor_forms.name1 = e.target.value;
      rent_customer_editor_forms.kana1 = kana;
    } else {
      rent_customer_editor_forms.kana1 = e.target.value;
    }
    change_rent_customer_editor_forms(rent_customer_editor_forms);

  }

  return (
    <Grid container spacing={3} style={{ margin: 2 }}>
      <Grid xs={12} style={{ padding: "5px" }}>

        <TextField
          id="name1"
          //   className={classes.textField}
          label="お客様名"
          size="small"
          variant="outlined"
          onChange={handleChange}
          // onChange={e => setKanaSource(e.target.value)}
          name="name1"
          value={rent_customer_editor_forms.name1}
          error={!rent_customer_editor_forms.name1}
          style={{ float: "left", width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid xs={12} style={{ padding: "5px" }}>
        <TextField
          id="kana1"
          variant="outlined"
          //   className={classes.textField}
          label="お客様名カナ"
          size="small"
          name="kana1"
          onChange={handleChange}
          error={!rent_customer_editor_forms.kana1}
          value={rent_customer_editor_forms.kana1}
          style={{ float: "left", width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>


    </Grid>

  );
};


const Name2 = ({ rent_customer_editor_forms, change_rent_customer_editor_forms, handleChange }) => {
  const { kana, setKanaSource } = useKana();
  function change_name(e) {
    setKanaSource(e.target.value)


    if (e.target.name === "name2") {
      rent_customer_editor_forms.name2 = e.target.value;
      rent_customer_editor_forms.kana2 = kana;
    } else {
      rent_customer_editor_forms.kana2 = e.target.value;
    }
    change_rent_customer_editor_forms(rent_customer_editor_forms);

  }

  return (
    <Grid container spacing={3} style={{ margin: 1, marginTop: 7 }}>
      <Grid xs={12} style={{ padding: "0px 5px" }}>

        <TextField
          id="name2"
          //   className={classes.textField}
          label="入居者名"
          size="small"
          variant="outlined"
          onChange={handleChange}
          // onChange={e => setKanaSource(e.target.value)}
          name="name2"
          value={rent_customer_editor_forms.name2}
          style={{ float: "left", width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid xs={12} style={{ padding: "10px 5px" }}>
        <TextField
          id="kana2"
          variant="outlined"
          //   className={classes.textField}
          label="入居者名カナ"
          size="small"
          name="kana2"
          onChange={handleChange}

          value={rent_customer_editor_forms.kana2}
          style={{ float: "left", width: "100%", backgroundColor:"#e8e8e8" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>


    </Grid>

  );
};




const CorpAdd = ({ coprporate_add_forms, change_coprporate_add_forms }) => {
  const { kana, setKanaSource } = useKana();
  function change_name(e) {
    setKanaSource(e.target.value)







    if (e.target.name === "name") {
      coprporate_add_forms.name = e.target.value;
      coprporate_add_forms.kana = kana;




    } else {
      coprporate_add_forms.kana = e.target.value;
    }
    change_coprporate_add_forms(coprporate_add_forms);

  }

  return (
    <Grid container spacing={3}>
      <Grid xs={12} style={{ padding: "0px 5px" }}>
        <TextField
          id="outlined-basic"
          //   className={classes.textField}
          label="企業名"
          size="small"
          variant="outlined"
          onChange={change_name}
          name="name"

          value={coprporate_add_forms.name}
          style={{ float: "left", width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
      <Grid xs={12} style={{ padding: "5px" }}>
        <TextField
          id="outlined-basic"
          //   className={classes.textField}
          label="企業名カナ"
          size="small"
          variant="filled"
          onChange={change_name}
          name="kana"
          value={coprporate_add_forms.kana}
          style={{ float: "left", width: "100%" }}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Grid>
    </Grid>


  );
};


const phoneNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];


const mobileNumberMask = [
  /[0-9]/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

const postNumberMask = [

  /[0-9]/,
  /\d/,
  /\d/,

  "-",

  /\d/,
  /\d/,
  /\d/,
  /\d/,

];

function hankaku2Zenkaku(str) {
  return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function (s) {
    return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
  });
}



function TelMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" || !props.value ? mobileNumberMask : phoneNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}


function PostMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={postNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}
// const selectStyles = {
//   menu: base => ({
//     ...base,
//     zIndex: 0
//   })
// };


// const RenderSelectInput = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="店舗を選択"
//   />
// )

// const RenderSelectUsers = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="追客担当を選択"
//   />
// )

var name1_history = []
var name2_history = []

class ResponseListSearch extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,
      reflash: false,
      ownshop_operation: true,
      ownshop_contract: true,

      modal_corporate:0, 

    };





    this.handleChange = this.handleChange.bind(this);


    this.handleCorpChange = this.handleCorpChange.bind(this);

    this.handleCorpChangeSet = this.handleCorpChangeSet.bind(this);
    this.handleWorkChangeSet = this.handleWorkChangeSet.bind(this);
    this.handle_agent_change = this.handle_agent_change.bind(this);
    this.check_update_rent_customers = this.check_update_rent_customers.bind(this);
    this.handleCorpChangeSearch = this.handleCorpChangeSearch.bind(this);

    if(this.props.rent_customer_editor_forms.name1 || 
      this.props.rent_customer_editor_forms.kana1 || 
      this.props.rent_customer_editor_forms.tel1 || 
      this.props.rent_customer_editor_forms.tel2 || 
      this.props.rent_customer_editor_forms.mail1 || 
      this.props.rent_customer_editor_forms.mail2){
        
      this.props.get_rent_responses_duplicate_check(this.props.rent_customer_editor_forms)
      this.props.get_attentions_matching_list(this.props.rent_customer_editor_forms)
    }else{
      this.props.change_rent_responses_duplicate_list([])
      this.props.change_attentions_matching_list([])
    }

  }

  handleCorpChange(e) {

    // console.log(event)

    // let contract_follow_list_form = this.props.contract_follow_list_form
    // contract_follow_list_form[event.target.name] = event.target.value;
    if (e.target.value) {
      let name = e.target.value
      clearTimeout(timer);
      timer = setTimeout(() => {

        this.props.get_rent_m_corporates(name)
      }, 400);
    }
    // }
    // this.setState({ reflash: !this.state.reflash });

  }

  handleCorpChangeSet(e, value) {
    console.log(value)
    let rent_customer_editor_forms = this.props.rent_customer_editor_forms


    if (value) {
      rent_customer_editor_forms.rent_m_corporate_id = value.value
      rent_customer_editor_forms.name1 = value.label
      rent_customer_editor_forms.kana1 = this.props.rent_m_corporates.filter(a => a.id === parseInt(value.value))[0] ? this.props.rent_m_corporates.filter(a => a.id === value.value)[0].kana : ""
      rent_customer_editor_forms.office_rent_m_corporate_id = rent_customer_editor_forms.office_rent_m_corporate_id ? rent_customer_editor_forms.office_rent_m_corporate_id : value.value
      rent_customer_editor_forms.office_name = rent_customer_editor_forms.office_name ? rent_customer_editor_forms.office_name : value.label
    } else {
      rent_customer_editor_forms.rent_m_corporate_id = 0
      rent_customer_editor_forms.name1 = ''
      rent_customer_editor_forms.kana1 = ''
      // rent_contract_req_editor_froms.office_rent_m_corporate_id = 0
      // rent_contract_req_editor_froms.office_name = ''
    }
    this.props.change_rent_customer_editor_forms(rent_customer_editor_forms);
    this.setState({ reflash: !this.state.reflash });
  }


  handleWorkChangeSet(e, value) {
    console.log(value)
    let rent_customer_editor_forms = this.props.rent_customer_editor_forms


    if (value) {

      rent_customer_editor_forms.office_rent_m_corporate_id = value.value
      rent_customer_editor_forms.office_name = value.label
    } else {

      rent_customer_editor_forms.office_rent_m_corporate_id = 0
      rent_customer_editor_forms.office_name = ''
    }
    this.props.change_rent_customer_editor_forms(rent_customer_editor_forms);
    this.setState({ reflash: !this.state.reflash });
  }

  handle_agent_change(e, value) {
    console.log(value)
    let rent_customer_editor_forms = this.props.rent_customer_editor_forms


    if (value) {

      rent_customer_editor_forms.rent_m_agent_id = value.value
      if (value.value === 5) {
        rent_customer_editor_forms.agent_etc = ''
      }
    } else {

      rent_customer_editor_forms.rent_m_agent_id = 0
      rent_customer_editor_forms.agent_etc = ''
    }
    this.props.change_rent_customer_editor_forms(rent_customer_editor_forms);
    // alert(rent_customer_editor_forms.rent_m_agent_id)
    this.setState({ reflash: !this.state.reflash });
  }




  componentDidMount() {

    // autokana = AutoKana.bind('#name1', '#kana1');

    // autokana2 = AutoKana.bind('#name2', '#kana2', { katakana: true });


  }
  handleChange = (event) => {

    console.log(event.target.name)
    let rent_customer_editor_forms = this.props.rent_customer_editor_forms
    if (event.target.name === 'mail1' && event.target.name === 'mail2') {
      rent_customer_editor_forms[event.target.name] = hankaku2Zenkaku(event.target.value).replace(/[^0-9a-z@-_.]/g, '');
    } else if (event.target.name === "contract_form") {
      rent_customer_editor_forms[event.target.name] = parseInt(event.target.value);
      rent_customer_editor_forms.name1 = ''
      rent_customer_editor_forms.kana1 = ''
      rent_customer_editor_forms.name2 = ''
      rent_customer_editor_forms.kana2 = ''
      rent_customer_editor_forms.rent_m_corporate_id = 0
      rent_customer_editor_forms.rent_m_corporate_name = ""
      rent_customer_editor_forms.rent_corporate_office_id = 0
      rent_customer_editor_forms.rent_m_agent_id = 0
      rent_customer_editor_forms.agent_etc = ''
      rent_customer_editor_forms.executive = ''
    } else if (event.target.name === "sex") {
      rent_customer_editor_forms[event.target.name] = parseInt(event.target.value);
    } else if (event.target.name === "contract_form") {
      rent_customer_editor_forms[event.target.name] = parseInt(event.target.value);

    } else {
      rent_customer_editor_forms[event.target.name] = event.target.value;
    }



    // if (event.target.name === 'name1') {

    //   name1_history.push(event.target.value)
    //   console.log(name1_history)
    //   // console.log(useKana(event.target.value))
    //   // rent_customer_editor_froms.kana1 = autokana.getFurigana();
    // }

    // if (event.target.name === 'name2') {
    //   name2_history.push(event.target.value)

    //   // console.log(name1_history)
    //   // console.log(historykana(name1_history))
    //   // rent_customer_editor_froms.kana2 = historyKana(name2_history);
    // }
    // if (event.target.name === 'name2') {
    //   rent_customer_editor_froms.kana2 = autokana2.getFurigana();
    // }
    // if (event.target.name === "condition") {
    //   formData.contractionDateStart = new Date();
    //   formData.contractionDateEnd = null;
    // }
    this.props.change_rent_customer_editor_forms(rent_customer_editor_forms);
    // if (event.target.name === "condition") {


    // }
    if (event.target.name === 'name1' || event.target.name === 'kana1' || event.target.name === 'tel1' || event.target.name === 'tel2' || event.target.name === 'mail1' || event.target.name === 'mail2') {
      
      if(!rent_customer_editor_forms.name1 && !rent_customer_editor_forms.kana1 && !rent_customer_editor_forms.tel1 && !rent_customer_editor_forms.tel2 && !rent_customer_editor_forms.mail1 && !rent_customer_editor_forms.mail2){
        // alert()
        this.props.change_rent_responses_duplicate_list([])
        this.props.change_attentions_matching_list([])
      }else{
        if(this.props.customeredit !== 0  && this.props.customerNewDisp === 0){
          if (this.timer) { 
            clearTimeout(this.timer);
          }
      
          this.timer = setTimeout(() => {
            if(!rent_customer_editor_forms.name1 && !rent_customer_editor_forms.kana1 && !rent_customer_editor_forms.tel1 && !rent_customer_editor_forms.tel2 && !rent_customer_editor_forms.mail1 && !rent_customer_editor_forms.mail2){
              this.props.change_rent_responses_duplicate_list([])
              this.props.change_attentions_matching_list([])

            } else {
              this.props.get_rent_responses_duplicate_check(rent_customer_editor_forms)
              this.props.get_attentions_matching_list(rent_customer_editor_forms)
            }
            
          }, 1000);
          
        }
      }
    }
    
    this.setState({ reflash: !this.state.reflash });
  }

  handleCorpChangeSearch() {

    let rent_customer_editor_forms = this.props.rent_customer_editor_forms


    if(this.state.modal_corporate == 1){//1契約者  2勤務先

      if(rent_customer_editor_forms.rent_m_corporate_id != this.props.rent_m_corporate_search.rent_m_corporate_id){
        rent_customer_editor_forms.name1 = this.props.rent_m_corporate_search.rent_m_corporate_name + (this.props.rent_m_corporate_search.rent_corporate_office_name ? " " + this.props.rent_m_corporate_search.rent_corporate_office_name : "")
        rent_customer_editor_forms.kana1 = this.props.rent_m_corporate_search.rent_m_corporate_kana + (this.props.rent_m_corporate_search.rent_corporate_office_kana ? " " + this.props.rent_m_corporate_search.rent_corporate_office_kana : "")
  
      }
      rent_customer_editor_forms.rent_m_corporate_id = this.props.rent_m_corporate_search.rent_m_corporate_id
      rent_customer_editor_forms.rent_m_corporate_name = this.props.rent_m_corporate_search.rent_m_corporate_name
      // rent_customer_editor_forms.name1 = this.props.rent_m_corporate_search.rent_m_corporate_name
      // rent_customer_editor_forms.kana1 = this.props.rent_m_corporate_search.rent_m_corporate_kana
  
      rent_customer_editor_forms.rent_corporate_office_id = this.props.rent_m_corporate_search.rent_corporate_office_id
      rent_customer_editor_forms.rent_corporate_office_name = this.props.rent_m_corporate_search.rent_corporate_office_name
    }


    rent_customer_editor_forms.office_rent_m_corporate_id = this.props.rent_m_corporate_search.rent_m_corporate_id
    rent_customer_editor_forms.office_name = this.props.rent_m_corporate_search.rent_m_corporate_name

    rent_customer_editor_forms.office_rent_corporate_office_id = this.props.rent_m_corporate_search.rent_corporate_office_id
    rent_customer_editor_forms.office_rent_corporate_office_name = this.props.rent_m_corporate_search.rent_corporate_office_name


    this.props.change_rent_customer_editor_forms(rent_customer_editor_forms);

    this.props.change_rent_m_corporate_search_modal(false)

    this.setState({ reflash: !this.state.reflash });
  }



  check_update_rent_customers() {
    this.props.update_rent_customers()
  }




  age(birthDate) {
    birthDate = new Date(birthDate);
    // 文字列に分解
    const y2 = birthDate.getFullYear().toString().padStart(4, '0');
    const m2 = (birthDate.getMonth() + 1).toString().padStart(2, '0');
    const d2 = birthDate.getDate().toString().padStart(2, '0');

    // 今日の日付
    const today = new Date();
    const y1 = today.getFullYear().toString().padStart(4, '0');
    const m1 = (today.getMonth() + 1).toString().padStart(2, '0');
    const d1 = today.getDate().toString().padStart(2, '0');

    // 引き算
    const age = Math.floor((Number(y1 + m1 + d1) - Number(y2 + m2 + d2)) / 10000);
    return age;

  }

  check_duplicate(){
    // this.props.new_customerdetail()


  }



  render() {


    return (
      <div id="contractfollow" style={{ position: "relative" }}>

        {/* {
          this.props.rent_m_corporate_editor > 0 ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "120%", height: "120%", marginLeft: -16, marginTop: -4 }}>

            <Card style={{ margin: "auto", marginTop: 50, width: "400px", padding: "20px" }}>

              <CardHeader

                action={
                  <IconButton aria-label="settings"

                    onClick={() => this.props.change_rent_m_corporate_editor(0)}>
                    <CancelIcon />
                  </IconButton>
                }
                title="企業登録"
                subheader="必ず既存の企業名を検索して二重登録にならないようにしてください。"
              />
              <CardContent>

                <CorpAdd coprporate_add_forms={this.props.coprporate_add_forms} change_coprporate_add_forms={this.props.change_coprporate_add_forms} />

              </CardContent>
              <CardActions >
                <div style={{ padding: 20, width: "100%", textAlign: "center" }}>
                  <Button variant="contained" color="primary" style={{ margin: 5 }} onClick={this.props.add_rent_m_corporates} >登録する</Button>
                  <Button variant="contained" color="secondary" style={{ margin: 5 }} onClick={() => this.props.change_rent_m_corporate_editor(0)}>キャンセル</Button>
                </div>
              </CardActions>


            </Card>

          </div> : ""
        } */}

        {/* {
          this.props.rent_m_corporate_editor > 0 ? <div style={{ position: "absolute", zIndex: 3000, background: "rgba(45,45,45, 0.5)", width: "120%", height: "120%", marginLeft: -16, marginTop: -4 }}>

            <Card style={{ margin: "auto", marginTop: 50, width: "400px", padding: "20px" }}>

              <CardHeader

                action={
                  <IconButton aria-label="settings"

                    onClick={() => this.props.change_rent_m_corporate_editor(0)}>
                    <CancelIcon />
                  </IconButton>
                }
                title="企業登録"
                subheader="必ず既存の企業名を検索して二重登録にならないようにしてください。"
              />
              <CardContent>

                <CorpAdd coprporate_add_forms={this.props.coprporate_add_forms} change_coprporate_add_forms={this.props.change_coprporate_add_forms} />

              </CardContent>
              <CardActions >
                <div style={{ padding: 20, width: "100%", textAlign: "center" }}>
                  <Button variant="contained" color="primary" style={{ margin: 5 }} onClick={this.props.add_rent_m_corporates_other} >登録する</Button>
                  <Button variant="contained" color="secondary" style={{ margin: 5 }} onClick={() => this.props.change_rent_m_corporate_editor(0)}>キャンセル</Button>
                </div>
              </CardActions>


            </Card>

          </div> : ""
        } */}


        <Dialog onClose={()=>this.props.change_rent_m_corporate_search_modal(false)} aria-labelledby="simple-dialog-title" maxWidth="xl" open={this.props.rent_m_corporate_search_modal}>
            <DialogContent style={{height:600, paddingTop:40, paddingLeft:40}}>
                {/* <CustomerCorporateSearch handleOwnerSearchClose={this.handleOwnerSearchClose} /> */}
                <CustomerCorporateSearch modal_corporate={this.state.modal_corporate} />
            </DialogContent>
            <DialogActions>
                    <div row>
                        <div className="col-12" style={{textAlign: 'right', marginBottom: 10}}>
                            <Button variant="contained" color="primary" style={{marginRight: 10}} onClick={() => this.handleCorpChangeSearch()} startIcon={<CheckCircleOutlineIcon/>}>選択</Button>

                            <Button variant="contained" style={{ marginLeft: 3, backgroundColor:"#e0e0e0" }} onClick={()=>this.props.change_rent_m_corporate_search_modal(false)} startIcon={<HighlightOffIcon/>}>閉じる</Button>
                        </div>
                    </div>
            </DialogActions>
        </Dialog>
        {/* <Modal isOpen={this.props.rent_m_corporate_search_modal} toggle={()=>this.props.change_rent_m_corporate_search_modal(false)} size="xl" fade={false}>
            <ModalHeader toggle={()=>this.props.change_rent_m_corporate_search_modal(false)}>企業検索</ModalHeader>
            <CustomerCorporateSearch  />

        </Modal> */}

        <Grid container>

          <Grid
            justifyContent="space-between" // Add it here :)
            xs={6}
            style={{ padding: "0px 10px" }}
          >

            <FormControlLabel value="0" name="contract_form" onChange={this.handleChange} checked={this.props.rent_customer_editor_forms.contract_form === 0 ? true : false} control={<Radio />} label="個人" />
            <FormControlLabel value="1" name="contract_form" onChange={this.handleChange} checked={this.props.rent_customer_editor_forms.contract_form === 1 ? true : false} control={<Radio />} label="法人" />

          </Grid>

          <Grid
            // justify="space-between" // Add it here :)
            xs={5}
            style={{ padding: "0px 5px" }}
          >
            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="メモ"
              name="memo"
              onChange={this.handleChange}
              size="small"
              value={this.props.rent_customer_editor_forms.memo}
              style={{ float: "left", width: "100%", backgroundColor:"#e8e8e8" }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>
          <Grid
            // justify="space-between" // Add it here :)
            // item
            xs={1}
            style={{ padding: "0px 5px" }}
          >

            <Tooltip title="閉じる" style={{ float: "right" }}>
              <IconButton
                aria-label="delete"
                onClick={() => this.props.change_customeredit(0)}
                style={{ display: this.props.rent_customer_editor_forms.rent_customer_id ? '' : 'none' }}
                size="large">
                <CancelIcon />
              </IconButton>
            </Tooltip>
          </Grid>



          {this.props.rent_customer_editor_forms.contract_form === 1 ? <>
          
            
            
            {this.props.rent_customer_editor_forms.rent_m_corporate_id ? <Grid xs={10} style={{ padding: "10px 5px" }}>

              <div style={{fontSize:16}}>{this.props.rent_customer_editor_forms.rent_m_corporate_name ? "法人名：" + this.props.rent_customer_editor_forms.rent_m_corporate_name : ""}</div>
              <div style={{fontSize:16}}>{this.props.rent_customer_editor_forms.rent_corporate_office_id > 0 && this.props.rent_customer_editor_forms.rent_corporate_office_name ? "支店名：" + this.props.rent_customer_editor_forms.rent_corporate_office_name : ""}</div>
              
            </Grid>:""}

            <Grid xs={this.props.rent_customer_editor_forms.rent_m_corporate_id ? 2 : 12} style={{ padding: "10px 5px" }}>
              {/* <Tooltip title="法人検索" aria-label="add">
                <IconButton aria-label="delete" onClick={() => {this.props.change_rent_m_corporate_search_modal(true);this.setState({ modal_corporate: 1})}}>
                  <SearchIcon />
                </IconButton>
              </Tooltip> */}
              <Button color="primary" size="small" style={{ color: "white", backgroundColor: "#28a745", float: "left" }} onClick={() => {this.props.change_rent_m_corporate_search_modal(true);this.setState({ modal_corporate: 1})}}>法人選択</Button>
            
            </Grid>
            </> : ""}

          {this.props.rent_customer_editor_forms.contract_form === 1 ? <Grid xs={12} style={{ padding: "0px 5px" }}>
            <div></div>
          </Grid> : ""}









{/* 
          {this.props.rent_customer_editor_forms.contract_form === 1 ? <Grid xs={11} style={{ padding: "0px 5px" }}>
            <Autocomplete
              id="combo-box-demo"
              // options={this.props.users_select}
              options={this.props.rent_m_corporates.length === 0 && this.props.rent_customer_editor_forms.rent_m_corporate_id ? [{ value: this.props.rent_customer_editor_forms.rent_m_corporate_id, label: this.props.rent_customer_editor_forms.name1 }]
                : this.props.rent_m_corporates.map((value) => ({
                  value: value.id,
                  label: value.name ? value.name : ""
                }))}
              getOptionLabel={(option) => option.label ? option.label : ''}
              // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
              style={{ width: "100%",padding:0 }}
              name="rent_m_corporates_id"
              loading={this.props.rent_m_corporates_loading}
              value={this.props.rent_customer_editor_forms.rent_m_corporate_id ? { value: this.props.rent_customer_editor_forms.rent_m_corporate_id, label: this.props.rent_customer_editor_forms.name1 } : {}}
              // open={false}
              // value={this.props.contract_follow_list_form.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.contract_user_id)[0] : null}
              onChange={this.handleCorpChangeSet}
              renderInput={(params) => <TextField
                {...params}
                variant="outlined"
                margin='dense'
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>

                      {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                error={!this.props.rent_customer_editor_forms.name1}
                onChange={this.handleCorpChange}
                label={"法人名:" + (this.props.rent_customer_editor_forms.kana1 ? this.props.rent_customer_editor_forms.kana1 : "")} />}

            />
          </Grid> : ""}
          {this.props.rent_customer_editor_forms.contract_form === 1 ? <Grid xs={1} style={{ padding: "0px 5px" }}>
            <Tooltip title="法人追加" aria-label="add">
              <IconButton aria-label="delete" onClick={() => this.props.change_rent_m_corporate_editor(1)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid> : ""} */}



          {
            this.props.rent_customer_editor_forms.contract_form === 1 && this.props.rent_customer_editor_forms.rent_m_corporate_id ? <Grid xs={12} style={{ padding: "0px 5px" }}>

              <TextField
                label="契約書用契約者名"
                size="small"
                variant="outlined"
                onChange={this.handleChange}
                name="name1"
                value={this.props.rent_customer_editor_forms.name1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <TextField
                label="契約書用契約者名カナ"
                size="small"
                variant="outlined"
                onChange={this.handleChange}
                name="kana1"
                value={this.props.rent_customer_editor_forms.kana1}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField

                //   className={classes.textField}
                label="代表者名"
                size="small"
                variant="outlined"
                onChange={this.handleChange}
                name="executive"
                value={this.props.rent_customer_editor_forms.executive}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid> : ""}



          {/* {
            this.props.rent_customer_editor_forms.contract_form === 1 ? <Grid xs={8} style={{ padding: 5 }}>
              {this.props.rent_m_agents ? <Autocomplete
                id="combo-box-demo"
                options={this.props.rent_m_agents.map((value) => ({
                  value: value.id,
                  label: value.name
                }))}
                getOptionLabel={(option) => option.label}
                style={{ width: "100%" }}
                name="rent_m_agent_id"
                value={this.props.rent_customer_editor_forms.rent_m_agent_id ? this.props.rent_m_agents.filter(a => a.id === this.props.rent_customer_editor_forms.rent_m_agent_id).map((value) => ({
                  value: value.id,
                  label: value.name
                }))[0] : {}}
                onChange={this.handle_agent_change}
                renderInput={(params) => <TextField
                  variant="filled"
                  {...params} label="代行会社" />}

              /> : ""}
            </Grid> : ""} */}

          {/* {
            this.props.rent_customer_editor_forms.contract_form === 1 ? <Grid xs={4} style={{ padding: 5 }}>
              {this.props.rent_customer_editor_forms.rent_m_agent_id === 5 ? <TextField
                id="agent_etc"
                label="代行会社その他"
                margin="normal"
                variant="outlined"
                onChange={this.handleChange}
                name="agent_etc"
                value={this.props.rent_customer_editor_forms.agent_etc}
                style={{ float: "left", width: "100%" }}
                InputLabelProps={{
                  shrink: true,
                }}
              /> : ""}
            </Grid> : ""} */}







          {this.props.rent_customer_editor_forms.contract_form !== 1 ? <Name1 rent_customer_editor_forms={this.props.rent_customer_editor_forms} change_rent_customer_editor_forms={this.props.change_rent_customer_editor_forms} customerList={this.props.customerList} handleChange={this.handleChange} /> : ""}

          {this.props.rent_customer_editor_forms.contract_form === 1 ? <Name2 rent_customer_editor_forms={this.props.rent_customer_editor_forms} change_rent_customer_editor_forms={this.props.change_rent_customer_editor_forms} handleChange={this.handleChange} /> : ""}


          <Grid xs={4} style={{ padding: "0px 10px" }}>
            <FormControlLabel value="1" name="sex" onChange={this.handleChange} checked={this.props.rent_customer_editor_forms.sex === 1 ? true : false} control={<Radio />} label="男性" />
            <FormControlLabel value="2" name="sex" onChange={this.handleChange} checked={this.props.rent_customer_editor_forms.sex === 2 ? true : false} control={<Radio />} label="女性" />

          </Grid>


          <Grid xs={4} style={{ padding: "10px" }}>
            <TextField
              id="date"
              label="生年月日"
              type="date"
              name="birthday"
              size="small"
              variant='outlined'
              onChange={this.handleChange}
              value={moment(this.props.rent_customer_editor_forms.birthday).format("YYYY-MM-DD")}
              // className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
            />
            {this.props.rent_customer_editor_forms.birthday ? <span style={{verticalAlign:"bottom"}}> ({this.age(this.props.rent_customer_editor_forms.birthday)}歳)</span> : ""}
          </Grid>

          <Grid xs={4} style={{ padding: "7px 10px 0px 10px" }}>

            <FormControl variant="outlined" size="small">
              <InputLabel id="country_label">国籍</InputLabel>
              <MaterialUiSelect
                labelId="country-label"
                id="rent_m_country_id"
                name="rent_m_country_id"
                value={this.props.rent_customer_editor_forms.rent_m_country_id ? Number(this.props.rent_customer_editor_forms.rent_m_country_id) : 0}
                onChange={this.handleChange}
                label="rent_m_country_id" style={{ width: 150 }}
              >
                <MenuItem value={0}>日本</MenuItem>
                <MenuItem value={1}>中国</MenuItem>
                <MenuItem value={2}>ベトナム</MenuItem>
                <MenuItem value={3}>ネパール</MenuItem>
                <MenuItem value={4}>韓国</MenuItem>
                <MenuItem value={5}>台湾</MenuItem>
                <MenuItem value={6}>その他</MenuItem>
              </MaterialUiSelect>
            </FormControl>
            {this.props.rent_customer_editor_forms.rent_m_country_id == 6 ? <TextField style={{ marginLeft: 5, width: 350 }} id="rent_m_country_remarks" label="その他" variant="outlined" onChange={this.handleChange} name="rent_m_country_remarks" value={this.props.rent_customer_editor_forms.rent_m_country_remarks} /> : ""}

          </Grid>



          
          {this.props.rent_customer_editor_forms.office_name ? <Grid xs={10} style={{ padding: "10px 5px" }}>

            <div style={{fontSize:16}}>{this.props.rent_customer_editor_forms.office_name ? "法人名：" + this.props.rent_customer_editor_forms.office_name : ""}</div>
            <div style={{fontSize:16}}>{this.props.rent_customer_editor_forms.office_rent_corporate_office_id > 0 && this.props.rent_customer_editor_forms.office_rent_corporate_office_name ? "支店名：" + this.props.rent_customer_editor_forms.office_rent_corporate_office_name : ""}</div>
            
          </Grid>:""}

          <Grid xs={this.props.rent_customer_editor_forms.office_name ? 2 : 12} style={{ padding: "10px 5px" }}>
            {/* <Tooltip title="法人検索" aria-label="add">
              <IconButton aria-label="delete" onClick={() => {this.props.change_rent_m_corporate_search_modal(true);this.setState({ modal_corporate: 2})}}>
                <SearchIcon />
              </IconButton>
            </Tooltip> */}
            <Button color="primary" size="small" style={{ color: "white", backgroundColor: "#28a745", float: "left" }} onClick={() => {this.props.change_rent_m_corporate_search_modal(true);this.setState({ modal_corporate: 2})}}>勤務先選択</Button>
          </Grid>
        

        {/* {this.props.rent_customer_editor_forms.contract_form === 1 ? <Grid xs={12} style={{ padding: "0px 5px" }}>
          <div></div>
        </Grid> : ""}
          <Grid xs={11} style={{ padding: "0px 5px" }}>
            <Autocomplete
              id="combo-box-demo"
              // options={this.props.users_select}
              // options={this.props.rent_m_corporates.map((value) => ({
              //   value: value.id,
              //   label: value.name ? value.name : ""
              // }))}
              options={this.props.rent_m_corporates.length === 0 && this.props.rent_customer_editor_forms.office_rent_m_corporate_id ? [{ value: this.props.rent_customer_editor_forms.office_rent_m_corporate_id, label: this.props.rent_customer_editor_forms.office_name }]
                : this.props.rent_m_corporates.map((value) => ({
                  value: value.id,
                  label: value.name ? value.name : ""
                }))}
              getOptionLabel={(option) => option.label ? option.label : ''}
              // defaultValue={this.props.masterReducer.users_select.filter(a => a.value === this.props.masterReducer.user_details.id)[0]}
              // value={this.props.rent_customer_editor_froms.name1}
              style={{ width: "100%", padding:0, marginTop:4 }}
              name="office_rent_m_corporates_id"
              loading={this.props.rent_m_corporates_loading}

              value={this.props.rent_customer_editor_forms.office_rent_m_corporate_id ? { value: this.props.rent_customer_editor_forms.office_rent_m_corporate_id, label: this.props.rent_customer_editor_forms.office_name } : {}}

              // open={false}
              // value={this.props.contract_follow_list_form.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.contract_follow_list_form.contract_user_id)[0] : null}
              onChange={this.handleWorkChangeSet}
              renderInput={(params) => <TextField
                {...params}
                variant="outlined"
                margin="dense"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>

                      {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                onChange={this.handleCorpChange}
                label="勤務先" />}

            />
          </Grid>
          <Grid xs={1} style={{ padding: "0px 5px" }}>
            <Tooltip title="法人追加" aria-label="add">
              <IconButton aria-label="delete" onClick={() => this.props.change_rent_m_corporate_editor(2)}>
                <AddCircleOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid> */}




          <Grid xs={12} style={{ padding: "5px" }}>
            <TextField
              variant="outlined"
              name="post"
              label="郵便番号"
              type="tel"
              value={this.props.rent_customer_editor_forms.post}
              onChange={this.handleChange}
              id="formatted-numberformat-input"
              style={{ float: "left", width: 200, paddingRight: "5px" }}
              size="small"
              InputProps={{
                inputComponent: PostMaskCustom,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />   <Tooltip title="郵便番号から住所を検索" aria-label="add" onClick={this.props.get_rent_customer_zip_address}>
              <IconButton aria-label="delete" size="large">
                <SearchIcon />
              </IconButton>
            </Tooltip>
          </Grid>


          <Grid xs={12} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              //   className={classes.textField}
              label="住所1"
              size="small"
              variant="outlined"
              onChange={this.handleChange}
              name="add1"
              value={this.props.rent_customer_editor_forms.add1}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid xs={12} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              //   className={classes.textField}
              label="住所2"
              size="small"
              variant="outlined"
              onChange={this.handleChange}
              name="add2"
              value={this.props.rent_customer_editor_forms.add2}
              style={{ float: "left", width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid xs={4} style={{ padding: "5px" }}>

            <TextField
              variant="outlined"
              name="tel1"
              label="電話番号1"
              type="tel"
              value={this.props.rent_customer_editor_forms.tel1}
              onChange={this.handleChange}
              id="formatted-numberformat-input"
              style={{ float: "left", width: "100%", paddingRight: "5px" }}
              size="small"
              InputProps={{
                inputComponent: TelMaskCustom,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

            {/* <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="電話番号1"
              margin="normal"
              name="tel1"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_froms.tel1}
              style={{ float: "left", width: "100%", paddingRight: "5px" }}
              InputLabelProps={{
                shrink: true,
              }}
            /> */}

          </Grid>

          <Grid xs={8} style={{ padding: "5px" }}>


            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="電話番号1備考"
              size="small"
              name="tel1_remarks"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_forms.tel1_remarks}
              style={{ float: "left", width: "100%", backgroundColor:"#e8e8e8" }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>




          <Grid xs={4} style={{ padding: "5px" }}>

            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="電話番号2"
              size="small"
              name="tel2"
              type="tel"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_forms.tel2}
              style={{ float: "left", width: "100%", paddingRight: "5px" }}
              InputProps={{
                inputComponent: TelMaskCustom,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>

          <Grid xs={8} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="電話番号2備考"
              size="small"
              name="tel2_remarks"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_forms.tel2_remarks}
              style={{ float: "left", width: "100%", backgroundColor:"#e8e8e8" }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>

          <Grid xs={4} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="FAX"
              size="small"
              name="fax"
              type="tel"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_forms.fax}
              style={{ float: "left", width: "100%" }}
              InputProps={{
                inputComponent: TelMaskCustom,
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>

          <Grid xs={8} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="FAX備考"
              size="small"
              name="fax_remarks"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_forms.fax_remarks}
              style={{ float: "left", width: "100%", backgroundColor:"#e8e8e8" }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>

          <Grid xs={6} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="メール1"
              size="small"
              name="mail1"
              onChange={this.handleChange}
              type="email"
              value={this.props.rent_customer_editor_forms.mail1}
              style={{ float: "left", width: "100%" }}
              // InputProps={{
              //   inputComponent: EmailMaskCustom,
              // }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>

          <Grid xs={6} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="メール1備考"
              size="small"
              name="mail1_remarks"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_forms.mail1_remarks}
              style={{ float: "left", width: "100%", backgroundColor:"#e8e8e8" }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>

          <Grid xs={6} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="メール2"
              size="small"
              name="mail2"
              onChange={this.handleChange}
              type="email"
              value={this.props.rent_customer_editor_forms.mail2}
              style={{ float: "left", width: "100%" }}
              // InputProps={{
              //   inputComponent: EmailMaskCustom,
              // }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>

          <Grid xs={6} style={{ padding: "5px" }}>
            <TextField
              id="outlined-basic"
              variant="outlined"
              //   className={classes.textField}
              label="メール2備考"
              size="small"
              name="mail2_remarks"
              onChange={this.handleChange}

              value={this.props.rent_customer_editor_forms.mail2_remarks}
              style={{ float: "left", width: "100%", backgroundColor:"#e8e8e8" }}
              InputLabelProps={{
                shrink: true,
              }}
            />

          </Grid>



          <Grid xs={12} style={{ padding: "5px" }}>
            <TextField
              id="outlined-multiline-static"
              label="お客様備考"
              size="small"
              multiline
              rows={4}
              name="remarks"
              onChange={this.handleChange}
              value={this.props.rent_customer_editor_forms.remarks}
              variant="outlined"
              style={{ width: "100%" }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>

          <Grid xs={12}>
            {
              this.props.rent_customer_editor_forms.contract_form !== 1 && !this.props.rent_customer_editor_forms.name1 ? <Alert severity="error">お客様名を入力してください。</Alert> :
                this.props.rent_customer_editor_forms.contract_form !== 1 && !this.props.rent_customer_editor_forms.kana1 ? <Alert severity="error">お客様名カナを入力してください。</Alert> :
                  this.props.rent_customer_editor_forms.contract_form === 1 && !this.props.rent_customer_editor_forms.rent_m_corporate_id ? <Alert severity="error">法人名を選択してください。</Alert> :


                    this.props.rent_customer_editor_forms.rent_customer_id ?
                      <Button style={{ float: "right" }} variant="contained" color="primary" onClick={this.props.update_customerdetail}>登録</Button> :
                      <Button style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.props.new_customerdetail()}>反響登録へ</Button>
                      // <Button style={{ float: "right" }} variant="contained" color="primary" onClick={this.props.get_rent_responses_duplicate_check}>反響登録へ</Button>


              // (this.props.rent_customer_editor_forms.contract_form !== 1 ? this.props.rent_customer_editor_forms.name1 : true) && 
              // (this.props.rent_customer_editor_forms.contract_form !== 1 ? this.props.rent_customer_editor_forms.kana1 : true) ?

              //   this.props.rent_customer_editor_forms.rent_customer_id ?
              //     <Button style={{ float: "right" }} variant="contained" color="primary" onClick={this.props.update_customerdetail}>登録</Button> : 
              //     <Button style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.props.new_customerdetail()}>反響登録へ</Button> : 

              // (!(this.props.rent_customer_editor_forms.contract_form !== 1 ? this.props.rent_customer_editor_forms.name1 : true) ? <Alert severity="error">お客様名を入力してください。</Alert> : 
              // <Alert severity="error">お客様名カナを入力してください。</Alert>)
            }

          </Grid>

        </Grid>

        <Dialog onClose={()=>this.props.change_rent_responses_duplicate_modal(false)} aria-labelledby="simple-dialog-title" maxWidth="xl" open={this.props.rent_responses_duplicate_modal}>
            <DialogContent style={{height:600}}>
              <CustomerDuplicateList />
                {/* <CustomerCorporateSearch modal_corporate={this.state.modal_corporate} /> */}
            </DialogContent>
            <DialogActions>
              <Alert severity="error" action={
                <>
                  <Button variant="contained" color="primary" onClick={()=>{this.props.change_rent_responses_duplicate_modal(false);this.props.new_customerdetail()}} startIcon={<CheckCircleOutlineIcon/>}>登録を続ける</Button>
                  <Button style={{marginLeft:10}} variant="contained" onClick={()=>this.props.change_rent_responses_duplicate_modal(false)} startIcon={<HighlightOffIcon/>}>キャンセル</Button>
                </>
                }>マッチした反響データがありますが、登録を続けてよろしいですか？</Alert>
            </DialogActions>
        </Dialog>
      </div >
    );
  }
}





const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    user_details: state.masterReducer.user_details,
    rent_m_agents: state.customersReducer.rent_m_agents,
    // csv_loading: state.Adjustment.csv_loading,
    // search_list: state.Adjustment.search_list,
    // seisan_follow_list: state.Adjustment.seisan_follow_list,
    // seisan_follow_search_list: state.Adjustment.seisan_follow_search_list,
    // seisan_follow_search: state.Adjustment.seisan_follow_search,
    // tantou: state.Adjustment.tantou,
    // rent_m_contract_follows: state.ContractFollow.rent_m_contract_follows,
    // rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_customer_editor_disp: state.contracts.rent_customer_editor_disp,
    rent_customer_editor_forms: state.customersReducer.rent_customer_editor_forms,
    rent_m_corporates: state.customersReducer.rent_m_corporates,
    rent_m_corporates_loading: state.customersReducer.rent_m_corporates_loading,
    rent_m_corporate_editor: state.customersReducer.rent_m_corporate_editor,
    coprporate_add_forms: state.customersReducer.coprporate_add_forms,
    customerlist: state.customersReducer.customerlist,
    rent_m_corporate_search_modal: state.customersReducer.rent_m_corporate_search_modal,
    rent_m_corporate_search: state.customersReducer.rent_m_corporate_search,
    rent_responses_duplicate_modal: state.customersReducer.rent_responses_duplicate_modal,
    customeredit: state.customersReducer.customeredit,
    customerNewDisp: state.customersReducer.customerNewDisp,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_rent_customer_editor_disp(state) {
      dispatch(Contracts.change_rent_customer_editor_disp(state))
    },
    // change_rent_customer_editor_forms(state) {
    //   dispatch(Contracts.change_rent_customer_editor_forms(state))
    // },
    get_rent_customer_zip_address(state) {
      dispatch(Customers.get_rent_customer_zip_address(state))
    },
    get_rent_m_corporates(state) {
      dispatch(Customers.get_rent_m_corporates(state))
    },
    change_rent_m_corporate_editor(state) {
      dispatch(Customers.change_rent_m_corporate_editor(state))
    },
    // update_rent_contracts(state) {
    //   dispatch(Contracts.update_rent_contracts(state))
    // },
    change_coprporate_add_forms(state) {
      dispatch(Customers.change_coprporate_add_forms(state))
    },
    change_rent_customer_editor_forms(state) {
      dispatch(Customers.change_rent_customer_editor_forms(state))
    },

    change_customeredit(state) {
      dispatch(Customers.change_customeredit(state))
    },
    check_rent_customer_editor_disp(state) {
      dispatch(Customers.check_rent_customer_editor_disp(state))
    },
    update_customerdetail(state) {
      dispatch(Customers.update_customerdetail(state))
    },
    add_rent_m_corporates(state) {
      dispatch(Customers.add_rent_m_corporates(state))
    },
    add_rent_m_corporates_other(state) {
      dispatch(Customers.add_rent_m_corporates_other(state))
    },
    customerList(state) {
      dispatch(Customers.customerList(state))
    },

    new_customerdetail(state) {
      dispatch(Customers.new_customerdetail(state))
    },
    change_rent_m_corporate_search_modal(state) {
      dispatch(Customers.change_rent_m_corporate_search_modal(state))
    },
    change_rent_responses_duplicate_modal(state) {
      dispatch(Customers.change_rent_responses_duplicate_modal(state))
    },
    get_rent_responses_duplicate_check(state) {
      dispatch(Customers.get_rent_responses_duplicate_check(state))
    },
    change_rent_responses_duplicate_list(state) {
      dispatch(Customers.change_rent_responses_duplicate_list(state))
    },
    get_attentions_matching_list(state) {
      dispatch(Customers.get_attentions_matching_list(state))
    },
    change_attentions_matching_list(state) {
      dispatch(Customers.change_attentions_matching_list(state))
    },
    // search_contract_follow_list(state) {
    //   dispatch(ContractFollow.search_contract_follow_list(state))
    // },
    // update_rent_m_contract_follow_settings(state) {
    //   dispatch(ContractFollow.update_rent_m_contract_follow_settings(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);
