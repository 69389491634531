import React, { Component } from 'react';

import { connect } from 'react-redux';

import MadoriEdit from './MadoriEditComponent';
import MadoriDisp from './MadoriEditComponent';

import * as Rooms from './Saga/Rooms';




class TatemonoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            madoriedit : 1,
            mode: '1',
            //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
        };
   

    }


    render() {
       
    return (
   
        <div>
            {this.state.madoriedit === 0 ? <MadoriDisp madorieditchange={this.props.madorieditchange} /> : <MadoriEdit closed={1} madorieditchange={this.props.madorieditchange} /> }
      　</div>
 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        tatemonoedit: state.roomsReducer.tatemonoedit,


    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        get_room_details(state) {
            dispatch(Rooms.get_room_details(state))
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TatemonoComponent);
