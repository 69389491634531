import React, { Component } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { compose } from 'redux'
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';

var moment = require('moment-timezone');
// const useStyles = makeStyles((theme) => ({
//   paper: {
//     padding: '6px 16px',
//   },
//   secondaryTail: {
//     backgroundColor: theme.palette.secondary.main,
//   },
// }));


const styles = (theme) => ({

  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },

})


class CustomizedTimeline extends Component {
  // function CustomizedTimeline() {

  // classes = useStyles();
  render() {

    return (
      <Timeline align="alternate" >

        {
          this.props.customer_contacts.map(function (value, key) {

            return <TimelineItem>
              <TimelineOppositeContent>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: 15, float: key % 2 === 0 ? "right" : "left" }}>
                  {value.user_name}{moment(value.date).format("MM月DD日 HH:mm")}
                </Typography>
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot color="primary" style={{ backgroundColor: value.intend == 1 ? "green" : "" }}>
                  {value.way == 3 ? <DirectionsWalkIcon /> : value.way == 2 ? <MailOutlineIcon /> : <PhoneIcon />}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Paper elevation={3} className={this.props.classes.paper}>
                  <Typography variant="h6" component="h1">
                    {value.way == 3 ? "来店" : value.way == 2 ? "メール" : "電話"}
                  </Typography>
                  <Typography>{value.remarks}</Typography>
                </Paper>
              </TimelineContent>

            </TimelineItem>
          }, this)
        }


        {/* < TimelineItem >
          <TimelineOppositeContent>
            <Typography variant="body2" color="textSecondary">
              10:00 am
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <LaptopMacIcon />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={this.props.classes.paper}>
              <Typography variant="h6" component="h1">
                Code
              </Typography>
              <Typography>Because it&apos;s awesome!</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary" variant="outlined">
              <HotelIcon />
            </TimelineDot>
            <TimelineConnector className={this.props.classes.secondaryTail} />
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={this.props.classes.paper}>
              <Typography variant="h6" component="h1">
                Sleep
              </Typography>
              <Typography>Because you need rest</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="secondary">
              <RepeatIcon />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Paper elevation={3} className={this.props.classes.paper}>
              <Typography variant="h6" component="h1">
                Repeat
              </Typography>
              <Typography>Because this is the life you love!</Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem> */}
      </Timeline >
    );
  }
}


const mapStateToProps = (state) => {
  return {
    customer_contacts: state.customerContactsReducer.customer_contacts,
    // responseid: state.customersReducer.responseid,
    // responsedetail: state.customersReducer.responsedetail,
    // responsedetail: state.responsesReducer.responsedetail,
    // contacts_loading: state.customerContactsReducer.contacts_loading
  }
}
function mapDispatchToProps(dispatch) {
  return {
    // get_rent_customer_contacts(state) {
    //   dispatch(Contacts.get_rent_customer_contacts(state))
    // },

  };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(CustomizedTimeline);