import { connect } from 'react-redux';
import Composed from './Composed';
import Table from './Table';
import Pie from './Pie';
import React, { createContext, useState, useEffect, useCallback } from "react"
import { CSVLink } from "react-csv";
import Button from '@mui/material/Button';
import moment from 'moment';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Row, Col } from 'reactstrap';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import ListIcon from '@mui/icons-material/List';
import { Scrollbars } from 'react-custom-scrollbars';

const authentication = require('react-azure-adb2c2').default;

export const ThemeContext = createContext();

// const token = authentication.getAccessToken();

// csvカラム★
const headers = [
    { label: "名前", key: "name" },
    { label: "Tel", key: "tel" },
    { label: "Tel付Mail", key: "mail_is_tel" },
    { label: "Mail", key: "mail" },
    { label: "LINE", key: "line" },
    { label: "SNS", key: "sns" },
    { label: "斡旋", key: "assen" },
];

function App(props) {

    const [chart_name, setChartName] = useState("反響数"); //タイトル★

    const [data, setData] = useState([]);
    const [alldata, setAllData] = useState([]);

    const [chart_table, setChartTable] = useState([]); //リスト用データ
    const [chart_composed, setChartComposed] = useState([]); //棒グラフ用データ
    const [chart_pie, setChartPie] = useState([]); //円グラフ用データ
    const [chart_line, setChartLine] = useState([]); //折れ線グラフ用データ

    const [chart_column, setChartColumn] = useState([]); //カラム名

    const [chart_disp, setChartDisp] = useState({ table: 1, composed: 1, pie: 1, line: 1 }); // 表示するグラフ★

    const [chart_unit, setChartUnit] = useState([]); //単位

    const [chart_type, setChartType] = useState(0); // 0:リスト 1:棒 2:円 3:折れ線　初期表示★

    const [tenpolist, setTenpoList] = useState([]);

    const [start_date, setStart_date] = useState(moment().startOf("month").format("YYYY-MM-DD"));
    const [end_date, setEnd_date] = useState(moment().endOf("month").format("YYYY-MM-DD"));
    const [tenpo, setTenpo] = useState(props.user_details.amSectionId);

    const token = authentication.getAccessToken();

    // const getTenpoData = async () => {
    //     // alert()
    //     const f = await fetch(process.env.REACT_APP_API_CRM_URL + `/v1/tm/tenpo`, { method: 'get', headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })

    //     const d = await f.json()

    //     setTenpoList(d)

    // }

    // useEffect(() => {
    //     getTenpoData()
    // }, [])

    const getData = useCallback(
        async (start_date, end_date, tenpo) => {
            const f = await fetch(process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/response_1`, { method: 'post', body: JSON.stringify({ section_id: tenpo, date1: start_date, date2: end_date }), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
            // const f = await fetch(`http://localhost:5000/api/v1/rent/responses/response_1`, { method: 'post', body: JSON.stringify({ section_id: tenpo, date1: start_date, date2: end_date }), headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' } })
            const d = await f.json()

            setData(d)
            setAllData(d)

            let result_table = []
            let result_composed = []
            let result_pie = []
            let result_line = []

            // データ生成 column1, column2....
            d.forEach(function (value) {
                // リスト★
                result_table.push({
                    column1: value.name,
                    column2: value.tel,
                    column3: value.mail_is_tel,
                    column4: value.mail,
                    column5: value.assen,
                    column6: value.line,
                    column7: value.sns,
                    column8: value.tel + value.mail_is_tel + value.mail + value.assen + value.line + value.sns
                })

                // 棒★
                result_composed.push({
                    column1: value.name,
                    column2: value.tel,
                    column3: value.mail_is_tel,
                    column4: value.mail,
                    column5: value.assen,
                    column6: value.line,
                    column7: value.sns,
                })

                // 円★
                result_pie.push({
                    name: value.name,
                    count: value.tel + value.mail_is_tel + value.mail + value.assen + value.line + value.sns
                })


            });

            // カラム★
            let result_chart_column = [
                { name: "名前", size: 50 },
                { name: "Tel", size: 50 },
                { name: "Tel付Mail", size: 90 },
                { name: "Mail", size: 50 },
                { name: "斡旋", size: 50 },
                { name: "LINE", size: 50 },
                { name: "SNS", size: 50 },
                { name: "合計", size: 50 },
            ]


            setChartTable(result_table)
            setChartComposed(result_composed)
            setChartPie(result_pie)
            setChartColumn(result_chart_column)
            setChartUnit("件")

        },
        // [ data, chart_data ]
        []
    );

    useEffect(
        () => {
            getData(start_date, end_date, tenpo);
        },
        [start_date, end_date, tenpo]
    );

    useEffect(() => {
        if (props.user_details.amSectionId) {
            if (props.user_details.amSectionId == 1 || props.user_details.amSectionId == 2 || props.user_details.amSectionId == 3 || props.user_details.amSectionId == 4 || props.user_details.amSectionId == 6 || props.user_details.amSectionId == 17 || props.user_details.amSectionId == 47) {
                setTenpo(props.user_details.amSectionId)
            } else {
                setTenpo(1)
            }

        }

    }, [props])

    // useEffect(
    //     () => {

    //     },
    //     [ chart_type ]
    // );



    return (
        <ThemeContext.Provider value={{
            chart_column: chart_column,
            chart_unit: chart_unit,

            chart_table: chart_table,
            chart_composed: chart_composed,
            chart_pie: chart_pie
        }}>
            <div>

                <p style={{ paddingLeft: 10, paddingTop: 10, fontWeight: "bold", fontSize: "16px", marginBottom: 0 }}>  {chart_name}</p>



                {data.length ?
                    <Button style={{ backgroundColor: "#5cb85c", float: "right", top: -20, marginRight: 5 }} size="small" variant="contained" color="primary">
                        <CSVLink style={{ textDecoration: "none", color: "white" }} headers={headers} filename={chart_name + ".csv"} data={data}>CSV</CSVLink>
                    </Button> : ""}

                {chart_disp.table == 1 ? chart_type != 0 ? <Button style={{ float: "right", marginRight: 5, top: -20 }} color="secondary" size="small" variant="contained" onClick={() => setChartType(0)}><ListIcon style={{ fontSize: 15 }} />　リスト</Button> : <Button style={{ float: "right", marginRight: 5, top: -20 }} color="secondary" size="small" variant="contained" onClick={() => setChartType(0)} disabled><ListIcon style={{ fontSize: 15 }} />　リスト</Button> : ""}
                {chart_disp.composed == 1 ? chart_type != 1 ? <Button style={{ float: "right", marginRight: 5, top: -20 }} color="secondary" size="small" variant="contained" onClick={() => setChartType(1)}><BarChartIcon style={{ fontSize: 15 }} />　棒</Button> : <Button style={{ float: "right", marginRight: 5, top: -20 }} color="secondary" size="small" variant="contained" onClick={() => setChartType(1)} disabled><BarChartIcon style={{ fontSize: 15 }} />　棒</Button> : ""}
                {chart_disp.pie == 1 ? chart_type != 2 ? <Button style={{ float: "right", marginRight: 5, top: -20 }} color="secondary" size="small" variant="contained" onClick={() => setChartType(2)}><PieChartIcon style={{ fontSize: 15 }} />　円</Button> : <Button style={{ float: "right", marginRight: 5, top: -20 }} color="secondary" size="small" variant="contained" onClick={() => setChartType(2)} disabled><PieChartIcon style={{ fontSize: 15 }} />　円</Button> : ""}
                <hr />

                {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
                    <Scrollbars style={{ height: 200 }}>
                        <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 10 }}>
                            <Row style={{ margin: 0 }}>
                                <Col lg="4" style={{ padding: 3 }}>
                                    <TextField
                                        id="start_date"
                                        label="開始日"
                                        type="date"
                                        name="start_date"
                                        size="small"
                                        // onChange={this.onChange}
                                        onChange={(e) => {
                                            this.setStart_date(e.target.value);
                                        }}
                                        value={moment(start_date).format("YYYY-MM-DD")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                        fullWidth
                                    />
                                </Col>
                                <Col lg="4" style={{ padding: 3 }}>
                                <TextField
                                        id="end_date"
                                        label="終了日"
                                        type="date"
                                        name="end_date"
                                        size="small"
                                        // onChange={this.onChange}
                                        onChange={(e) => {
                                            this.setEnd_date(e.target.value);
                                        }}
                                        value={moment(end_date).format("YYYY-MM-DD")}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        variant="standard"
                                        fullWidth
                                    />
                                </Col>
                                <Col lg="4">
                                    {props.section_select.length ? <Autocomplete
                                        fullWidth
                                        // options={tenpolist.filter(a=> a.tenpo_no != -1)}
                                        options={props.section_select.filter(a => a.value == 1 || a.value == 2 || a.value == 3 || a.value == 4 || a.value == 6 || a.value == 17 || a.value == 47)}
                                        getOptionLabel={(option) => option.label}
                                        // defaultValue={tenpo.filter(a => a.value === this.props.user_details.amSectionId)[0]}
                                        defaultValue={props.section_select.filter(a => a.value === 1)[0]}
                                        value={props.section_select.filter(a => a.value === tenpo)[0]}
                                        // style={{ width: 200 }}
                                        onChange={(e, newValue) => {
                                            setTenpo(newValue ? newValue.value : -1);
                                        }}
                                        renderInput={(params) => <TextField variant="standard"
                                            {...params} label="店舗を選択" />}

                                    /> : ""}
                                </Col>
                                <Col lg="12" style={{ padding: 0 }}>

                                    {chart_type == 0 && chart_table.length ? <Table /> : chart_type == 1 && chart_composed.length ? <Composed /> : chart_type == 2 && chart_pie.length ? <Pie /> : ""}
                                </Col>
                            </Row>
                        </div>
                    </Scrollbars>
                {/* </MuiPickersUtilsProvider> */}



            </div>
        </ThemeContext.Provider>
    );
}
const mapStateToProps = (state) => {
    return {
        user_details: state.masterReducer.user_details,
        section_select: state.masterReducer.section_select,
        users_select: state.masterReducer.users_select,
    }
}

export default connect(mapStateToProps)(App);