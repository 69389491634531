import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';



import { call, put, takeEvery } from 'redux-saga/effects';
// import * as RentContractMoniesApi from '../Api/RentContractMoniesApi';

import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import * as AutoSendsApi from '../Api/AutoSendsApi';

// import * as BanksApi from '../Api/BanksApi';
// import * as UsersApi from './Api/UsersApi';
// import * as RentAgentsApi from './Api/RentAgentsApi';

///types


//export interface HogeState {
//    responselist: string[];
//    responseedit: number;
//}
export interface AutoSendState {
    auto_sends: any;
    auto_sends_loading: boolean;
    auto_send_reserve_list: any;
    auto_send_modal: boolean;
    auto_sends_templates: any;
}
const initialState: AutoSendState = {

    auto_sends: [],
    auto_sends_loading: false,
    auto_send_reserve_list: [],
    auto_send_modal: false,
    auto_sends_templates: [],
    // users: [],
    // rentAgents: [],
    // rentMReasons: [],



}



///action 
const actionCreator = actionCreatorFactory();

export const get_auto_sends = actionCreator<any>('GET_AUTO_SENDS');
export const get_auto_sends_success = actionCreator<any>("GET_AUTO_SENDS_SUCCESS");
export const get_auto_sends_failure = actionCreator("GET_AUTO_SENDS_FAILURE");

export const change_auto_sends_loading = actionCreator<any>("CHANGE_AUTO_SENDS_LOADING");

export const delete_auto_send = actionCreator<any>("DELETE_AUTO_SEND");




// export const getUsers = actionCreator('USERS_REQUEST');
// export const getUsersSuccess = actionCreator("USERS_SUCCESS");
// export const getUsersFailure = actionCreator("USERS_FAILURE");

// export const getRentAgents = actionCreator('RENT_AGENTS_REQUEST');
// export const getRentAgentsSuccess = actionCreator("RENT_AGENTS_SUCCESS");
// export const getRentAgentsFailure = actionCreator("RENT_AGENTS_FAILURE");


// export const getRentMReasons = actionCreator('RENT_M_REASONS_REQUEST');
// export const getRentMReasonsSuccess = actionCreator("RENT_M_REASONS_SUCCESS");
// export const getRentMReasonsFailure = actionCreator("RENT_M_REASONS_FAILURE");


export const autosendsReducer = reducerWithInitialState(initialState)
    .case(get_auto_sends_success, (state, payload) => ({ ...state, auto_sends: payload }))
    .case(change_auto_sends_loading, (state, payload) => ({ ...state, auto_sends_loading: payload }))




//.case(getRentMMedeasFailure, (state, payload) => ({ ...state, rentMMedeas: payload }))
// .case(getUsersSuccess, (state, payload) => ({ ...state, users: payload }))
// .case(getUsersFailure, (state, payload) => ({ ...state, users: payload }))
// .case(getRentAgentsSuccess, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentAgentsFailure, (state, payload) => ({ ...state, rentAgents: payload }))
// .case(getRentMReasonsSuccess, (state, payload) => ({ ...state, rentMReasons: payload }))
// .case(getRentMReasonsFailure, (state, payload) => ({ ...state, rentMReasons: payload }));







// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const autosendsSaga = [takeEvery('GET_AUTO_SENDS', handle_get_auto_sends),
takeEvery('DELETE_AUTO_SEND', handle_delete_auto_send),




    // takeEvery('GET_RENT_CONTRACT_ACCOUNTING', handle_get_rent_contract_accounting),
    // takeEvery('GET_BANKS', handle_get_banks),
    // takeEvery('USERS_REQUEST', handleUsersRequest),
    // takeEvery('RENT_AGENTS_REQUEST', handleRentAgentsRequest),
    // takeEvery('RENT_M_REASONS_REQUEST', handleRentMReasonsRequest),
];
// export default masterSaga;



function* handle_get_auto_sends(action: any) {
    yield put(get_auto_sends_success({}));
    yield put(change_auto_sends_loading(true));
    const { payload, error } = yield call(AutoSendsApi.list, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        console.log(payload)
        yield put(get_auto_sends_success(payload));
        yield put(change_auto_sends_loading(false));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}

function* handle_delete_auto_send(action: any) {
    yield put(get_auto_sends_success({}));
    yield put(change_auto_sends_loading(true));
    const { payload, error } = yield call(RentIntroductionsApi.insert, action.payload);

    // サーバーからのレスポンスデータによる分岐処理
    if (payload && !error) {
        // 成功時アクション呼び出し
        yield put(get_auto_sends(action.payload.rentResponseId));
    } else {
        //    // 失敗時アクション呼び出し
        //    yield put(loginFailure(payload));
    }
}














// function* handle_get_rent_contract_accounting(action:any) {

//     const { payload, error }= yield call(RentContractAccountingApi.list, action.payload);

//        // サーバーからのレスポンスデータによる分岐処理
//        if (payload && !error) {
//            // 成功時アクション呼び出し
//        console.log(payload)
//        const a = document.createElement('a');
// // ダウンロードされるファイル名
// a.download = "会計.csv";
// a.href = URL.createObjectURL(payload);
// // ダウンロード開始
// a.click();
//     //    yield put(get_rent_contract_monies_success(payload));

//        } else {
//        //    // 失敗時アクション呼び出し
//        //    yield put(loginFailure(payload));
//        }
//    }



//    function* handle_get_banks(action:any) {
//   console.log(action.payload)

//     const { payload, error }= yield call(BanksApi.list, action.payload);

//        // サーバーからのレスポンスデータによる分岐処理
//        if (payload && !error) {
//            console.log(payload)

//         yield put(get_banks_success(payload));
//     //    yield put(get_rent_contract_monies_success(payload));

//        } else {

//            alert(error)
//        //    // 失敗時アクション呼び出し
//        //    yield put(loginFailure(payload));
//        }
//    }





// function* handleUsersRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(UsersApi.UsersLIst, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getUsersSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentAgentsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentAgentsApi.RentAgentsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentAgentsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }

// function* handleRentMReasonsRequest(action: any) {

//     let { payload } = action;
//     payload = yield call(RentMReasonsApi.RentMReasonsList, payload);

//     // サーバーからのレスポンスデータによる分岐処理
//     //if (ログイン成功か失敗かの条件) {
//     // 成功時アクション呼び出し
//     console.log(payload)

//     yield put(getRentMReasonsSuccess(payload));
//     //} else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//     //}
// }