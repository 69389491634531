import React, { Component, forwardRef } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';
import CorporatesDetailDialog from "./CorporatesDetailDialog";
import './Css/Grid.css'

import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
// import { Card, TextField, CardHeader, FormControl, InputLabel, Select, MenuItem, Checkbox, FormControlLabel, FormLabel, FormGroup, Button, IconButton, Radio, RadioGroup } from '@mui/material';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import { Table, Column, Cell } from 'fixed-data-table-2';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import { TextField } from '@mui/material';

import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import MaterialUiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
// import CountComing from './Gadgets/CountComing';
// import ResponseCount from './Gadgets/ResponseCount/Index';
import Tooltip from '@mui/material/Tooltip';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
import moment from 'moment';
import { CSVLink } from "react-csv";
import Link from '@mui/material/Link';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import GetAppIcon from '@mui/icons-material/GetApp';
import MaskedInput from 'react-text-mask';
import DateTimeComponentTime from "../Calendar/DateTimeComponentTime";

const styles = {
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },

}

const headers = [
    { label: "対応日", key: "taiou_date" },
    { label: "部署", key: "section_name" },
    { label: "担当", key: "user_name" },
    { label: "法人名", key: "name" },

    { label: "対応方法", key: "rent_m_corporate_way_name" },
    { label: "企業担当者様名", key: "staff" },

    { label: "主要取引先", key: "main_corp_name" },
    { label: "その他主要取引先", key: "other_main_corp" },

    { label: "対応内容", key: "remarks" },
    { label: "ライバル情報（他社との状況）", key: "rival" },
    { label: "社宅ニーズ    ", key: "need" },
    { label: "お困りごと", key: "trouble" },
    { label: "弊社に望むこと", key: "request" },
];

function timeMask(value) {
    const chars = value.split('');
    const hours = [
      /[0-2]/,
      chars[0] === '2' ? /[0-3]/ : /[0-9]/,
    ];
    const minutes = [/[0-5]/, /[0-9]/];
  
    return hours.concat(':').concat(minutes);
  }
  
  
  const TextMaskCustom = forwardRef(function TextMaskCustom(props, ref) {
    const { inputRef, ...other } = props;
  
    return (
      <MaskedInput
        {...other}
        ref={ref}
        mask={timeMask}
        showMask
      />
    );
  });
class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modal_del: false,
            modalselect: false,
            columnWidths: {
                date: 100,
                section_id: 90,
                user_id: 90,
                rent_m_corporate_way_id: 100,
                name: 250,
                staff: 100,
                remarks: 600,
                rival: 600,
                need: 600,
                trouble: 600,
                request: 600,
                main_corp_name: 130,
                other_main_corp: 150,
            },
        };

        this.onChange = this.onChange.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.handleUserIdChange = this.handleUserIdChange.bind(this);
        this.handleSectionIdChange = this.handleSectionIdChange.bind(this);
        this.handleRentMCorporateIdChange = this.handleRentMCorporateIdChange.bind(this);
        this.handleSearch = this.handleSearch.bind(this);
        this.handleSearchUserIdChange = this.handleSearchUserIdChange.bind(this);
        this.handleSearchSectionIdChange = this.handleSearchSectionIdChange.bind(this);
        this.onSearchChangeDateStart = this.onSearchChangeDateStart.bind(this);
        this.onSearchChangeDateEnd = this.onSearchChangeDateEnd.bind(this);
        this.handleDetailOpen = this.handleDetailOpen.bind(this);
        this.handleKanriCorpIdChangeSet = this.handleKanriCorpIdChangeSet.bind(this);
        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this.RowClick = this.RowClick.bind(this);

        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);

        this.props.get_rent_corporate_contacts_all_list()

        this.focus_start = this.focus_start.bind(this);
        this.blur_start = this.blur_start.bind(this);
        this.focus_end = this.focus_end.bind(this);
        this.blur_end = this.blur_end.bind(this);
        this.check_validation = this.check_validation.bind(this);
        this.change_datetime_select = this.change_datetime_select.bind(this);
        this.change_datetime = this.change_datetime.bind(this);

        // this.props.get_rent_m_corporates_list()
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            },
        }));
    }


    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    handleOpen(id) {
        if (id) {
            this.props.set_rent_corporate_contacts_all(id)
        } else {
            // 新規
            let rent_corporate_contacts = { id: '', rent_m_corporate_id: '', user_id: this.props.user_details.id, section_id: this.props.user_details.amSectionId, date: moment(), rent_m_corporate_way_id: '', remarks: '', is_deleted: 0, created_at: '', created_user_id: '' }
            this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        }

        this.setState({ "modal": true })
    }

    onChange(state) {
        // console.log(this.props.claso_blog_places)
        let rent_corporate_contacts = this.props.rent_corporate_contacts

        if(state.target.name == "intend"){
            rent_corporate_contacts[state.target.name] = state.target.checked
        }else{
            rent_corporate_contacts[state.target.name] = state.target.value
        }
        

        this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        this.setState({
            change: !this.state.change
        });

    }

    onChange(state){
        let rent_corporate_contacts =  this.props.rent_corporate_contacts
        rent_corporate_contacts[state.target.name] = state.target.value
        this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        this.setState({ reflash: !this.state.reflash });
    }
    
    onChangeDate(value) {

        let rent_corporate_contacts = this.props.rent_corporate_contacts
        rent_corporate_contacts.date = value
        this.props.change_rent_corporate_contacts(rent_corporate_contacts)
        // this.props.change_responsedetail(responsedetail);
        this.setState({ reflash: !this.state.reflash });
    }

    handleKanriCorpIdChangeSet(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.kanri_corp_no = value.value

        } else {
            rent_corporate_contacts.kanri_corp_no = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }

    handleUserIdChange(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.user_id = value.value

        } else {
            rent_corporate_contacts.user_id = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }

    handleSectionIdChange(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.section_id = value.value

        } else {
            rent_corporate_contacts.section_id = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }

    handleRentMCorporateIdChange(e, value) {
        let rent_corporate_contacts = this.props.rent_corporate_contacts
        if (value) {
            rent_corporate_contacts.rent_m_corporate_id = value.id
        } else {
            rent_corporate_contacts.rent_m_corporate_id = 0
        }

        this.props.change_rent_corporate_contacts(rent_corporate_contacts);
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }

    handleUpdate(id, delflg) {

        let rent_corporate_contacts = this.props.rent_corporate_contacts
        rent_corporate_contacts["is_deleted"] = delflg
        this.props.change_rent_corporate_contacts(rent_corporate_contacts)

        if (id) {
            this.props.update_rent_corporate_contacts_all()
        } else {
            this.props.insert_rent_corporate_contacts_all()
        }

        this.setState({
            modal: false,
            modal_del: false,
            change: !this.state.change
        });

    }

    handleSearch(state) {

        let search_data_contacts = this.props.search_data_contacts
        search_data_contacts.name = state.target.value
        this.props.change_search_data_contacts(search_data_contacts);

        this.props.get_rent_corporate_contacts_all_list_search()
        this.setState({
            search: state.target.value
        });
    }
    handleSearchUserIdChange(e, value) {
        let search_data_contacts = this.props.search_data_contacts
        if (value) {
            search_data_contacts.user_id = value.value

        } else {
            search_data_contacts.user_id = 0
        }

        this.props.change_search_data_contacts(search_data_contacts);
        this.props.get_rent_corporate_contacts_all_list_search()
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    handleSearchSectionIdChange(e, value) {
        let search_data_contacts = this.props.search_data_contacts
        if (value) {
            search_data_contacts.section_id = value.value

        } else {
            search_data_contacts.section_id = 0
        }

        this.props.change_search_data_contacts(search_data_contacts);
        this.props.get_rent_corporate_contacts_all_list_search()
        this.setState({ change: !this.state.change });
        // this.props.change_responsedetail(responsedetail);
        // this.setState({ reflash: !this.state.reflash });
    }
    onSearchChangeDateStart(value) {

        let search_data_contacts = this.props.search_data_contacts
        search_data_contacts.start_date = value
        this.props.change_search_data_contacts(search_data_contacts)
        this.props.get_rent_corporate_contacts_all_list_search()
        // this.props.change_responsedetail(responsedetail);
        this.setState({ reflash: !this.state.reflash });
    }

    onSearchChangeDateEnd(value) {

        let search_data_contacts = this.props.search_data_contacts
        search_data_contacts.end_date = value
        this.props.change_search_data_contacts(search_data_contacts)
        this.props.get_rent_corporate_contacts_all_list_search()
        // this.props.change_responsedetail(responsedetail);
        this.setState({ reflash: !this.state.reflash });
    }

    handleDetailOpen() {
        // 新規
        let rent_m_corporates = { id: '', kubun: 1, name: "", kana: "", post: "", add1: "", add2: "", tel1: "", url: "", capital: "", establish: "", office: "", employee: "", vision: "", lat: "", lng: "", is_display: 0, }
        this.props.change_rent_m_corporates(rent_m_corporates)

        this.props.change_rent_m_corporates_detail_open(true)
    }

    open_corporate(id) {
        window.open(`/Corporate/` + id, '_blank', 'noopener')
      
    }

    RowClick(event,index){

        this.props.set_rent_corporate_contacts_all(this.props.rent_corporate_contacts_all_list_search[index].id)

        this.setState({ "modal": true })
    }

    _rowClassNameGetter(index){
        
        if(this.props.rent_corporate_contacts_all_list_search && this.props.rent_corporate_contacts_all_list_search[index]) {
        //   return this.props.owner_eigyou[index].type == 1 ? "line" : 
        //   this.props.owner_eigyou[index].type == 2 ? "mail" : 
        //   this.props.owner_eigyou[index].line_status == 1 ? "line" : "normal"
            return "normal"
        }else{
          return ""
        }
    }

    focus_start(e) {

        this.check_validation()
        this.setState({ focus_start: true })
    }
    blur_start(e) {

        setTimeout(() => {
            this.setState({ focus_start: false })
        }, 200)

    }
    focus_end(e) {
        console.log(e.target)

        this.setState({ focus_end: true })
    }
    blur_end(e) {
        setTimeout(() => {
            this.setState({ focus_end: false })
        }, 200)

    }

    change_datetime_select(name, value) {
        // alert(name)
        // alert(moment(value).format('HH:mm'))
        this.setState({ 
          refresh: !this.props.refresh,
          [name]: moment(value).format('HH:mm'),
          [name == "start_at" ? "start_at_err" : "end_at_err"]: false,
        })
        this.check_validation()
    }

    change_datetime(e) {
        // alert(e.target.value)
        console.log(e.target.value)
    
        let rent_corporate_contacts = this.props.rent_corporate_contacts

        const regex = /^([01][0-9]|2[0-3]):[0-5][0-9]$/
        // 時間チェックして
        if(regex.test(e.target.value)){ //OK
              this.setState({ 
                [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: false,
              })
    
              rent_corporate_contacts[e.target.name] = e.target.value
          this.props.change_rent_corporate_contacts(rent_corporate_contacts)
    
        }else{// エラー
        // alert(e.target.name)
        rent_corporate_contacts[e.target.name] = e.target.value
            console.log(e.target.value)
            this.props.change_rent_corporate_contacts(rent_corporate_contacts)

            if(e.target.value == "__:__"){
                this.setState({ 
                    [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: false,
                })
            }else{
                this.setState({ 
                    [e.target.name == "start_at" ? "start_at_err" : "end_at_err"]: true,
                })
            }

        }
    
        this.setState({ 
          refresh: !this.props.refresh,
          [e.target.name]: e.target.value,
        })
    
        this.check_validation()
    }

    check_validation() {
        // alert()
            let rent_corporate_contacts = this.props.rent_corporate_contacts
            let validation = true
            let validation_text = ''
            
            // validation_text = rent_corporate_contacts.date ? validation_text + "" : validation_text + "対応日を選択してください。" 
    
            // validation_text = rent_corporate_contacts.owner_id > 0 ? validation_text + "" : validation_text + "オーナーまたは法人を選択してください。"
    
            // validation_text = rent_corporate_contacts.raiten_kbn_no > 0 ? validation_text + "" : validation_text + "対応区分を選択してください。"
    
            // validation_text = rent_corporate_contacts.outai_naiyou_no > 0 ? validation_text + "" : validation_text + "対応内容を選択してください。"
    
            
            // if(rent_corporate_contacts.start_at && rent_corporate_contacts.end_at && rent_corporate_contacts.start_at.replace('__:__', "") || rent_corporate_contacts.end_at.replace('__:__', "")){
                
            //     if(rent_corporate_contacts.start_at == '__:__' || rent_corporate_contacts.end_at == '__:__'){
            //         validation_text += "時間を正しく入力してください。"
            //     }else if(rent_corporate_contacts.date && moment(moment(rent_corporate_contacts.date).format("YYYY/MM/DD") + " " + rent_corporate_contacts.start_at) >= moment(moment(rent_corporate_contacts.date).format("YYYY/MM/DD") + " " + rent_corporate_contacts.end_at)) {
            //          validation_text += "開始時間より終了時間があとになるように入力してください。"
            //     }
            // }
    
    
        
            if (validation_text != "") {
                validation = false
            }
            
        
            this.setState({ validation: validation, validation_text: validation_text })
        
        }


    render() {

        return (
            <Card style={{ padding: "10px", backgroundColor: "#e9f2f8" }}>

                <Card style={{ padding: 10, marginBottom: 10 }}>
                    <Grid container spacing={1}>
                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
                        <Grid justifyContent="space-between" item xs={3}>
                            <TextField
                                id="start_date"
                                label="対応日　開始"
                                type="date"
                                name="start_date"
                                size="small"
                                onChange={this.onChange}
                                value={moment(this.props.search_data_contacts.start_date).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            />

                                {/* <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="dense"
                                    id="date-picker-inline1"
                                    label="対応日　開始"
                                    // showTodayButton={true}
                                    style={{ marginTop: "0" }}
                                    autoOk={true}
                                    InputLabelProps={{ shrink: true }}
                                    name="start_date"
                                    value={this.props.search_data_contacts.start_date}
                                    onChange={this.onSearchChangeDateStart}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={"日付の形式が違います"}
                                /> */}

                        </Grid>
                        <Grid justifyContent="space-between" item xs={3}>
                            <TextField
                                id="end_date"
                                label="対応日　終了"
                                type="date"
                                name="end_date"
                                size="small"
                                onChange={this.onChange}
                                value={moment(this.props.search_data_contacts.end_date).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            />
                                {/* <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    margin="dense"
                                    id="date-picker-inline1"
                                    label="対応日　終了"
                                    // showTodayButton={true}
                                    style={{ marginTop: "0" }}
                                    autoOk={true}
                                    InputLabelProps={{ shrink: true }}
                                    name="end_date"
                                    value={this.props.search_data_contacts.end_date}
                                    onChange={this.onSearchChangeDateEnd}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    invalidDateMessage={"日付の形式が違います"}
                                /> */}
                        </Grid>
                        {/* </MuiPickersUtilsProvider> */}
                        <Grid justifyContent="space-between" item xs={4}>



                        </Grid>
                        <Grid justifyContent="space-between" item xs={2}>
                            <Button style={{ float: "right" }} size="small" variant="contained" color="primary" startIcon={<ControlPointIcon />} onClick={(e) => { this.handleOpen("") }}>新規追加</Button>
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3}>

                            <TextField size="small" style={{ width: "300" }} fullWidth onChange={this.handleSearch} id="search" label="検索　法人名" name="search" variant="outlined" InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3}>
                            <Autocomplete
                                id="combo-box-demo"
                                // options={this.props.users_select.map((value) => ({
                                //     value: value.value,
                                //     label: value.label
                                //     }))}
                                // getOptionLabel={(option) => option.label}
                                options={this.props.section_select.length === 0 && this.props.search_data_contacts.section_id ? [this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.section_id)[0]]
                                    : this.props.section_select}
                                getOptionLabel={(option) => option.label ? option.label : ''}

                                name="userId"
                                loading={this.props.users_loading}
                                size="small"
                                value={this.props.search_data_contacts.section_id ? this.props.section_select.filter(a => a.value === this.props.search_data_contacts.section_id)[0] : {}}

                                onChange={this.handleSearchSectionIdChange}
                                renderInput={(params) => <TextField
                                    {...params}
                                    variant="outlined"
                                    name="sectionId"
                                    // error={!this.props.rent_responses_editor.section_id}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>

                                                {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    // onChange={this.handleChange}
                                    label="対応部署" />}

                            />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3}>
                            <Autocomplete
                                id="combo-box-demo"
                                // options={this.props.users_select.map((value) => ({
                                //     value: value.value,
                                //     label: value.label
                                //     }))}
                                // getOptionLabel={(option) => option.label}
                                options={this.props.users_select.length === 0 && this.props.search_data_contacts.user_id ? [this.props.users_select.filter(a => a.value === this.props.rent_responses_editor.user_id)[0]]
                                    : this.props.users_select}
                                getOptionLabel={(option) => option.label ? option.label : ''}
                                fullWidth
                                name="userId"
                                loading={this.props.users_loading}
                                size="small"
                                value={this.props.search_data_contacts.user_id ? this.props.users_select.filter(a => a.value === this.props.search_data_contacts.user_id)[0] : {}}

                                onChange={this.handleSearchUserIdChange}
                                renderInput={(params) => <TextField
                                    {...params}
                                    variant="outlined"
                                    name="userId"
                                    // error={!this.props.rent_responses_editor.user_id}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>

                                                {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                    // onChange={this.handleChange}
                                    label="営業担当" />}

                            />
                        </Grid>
                        
                        <Grid justifyContent="space-between" item xs={3}>

                            <Button style={{ backgroundColor: "#5cb85c", float: "right" }} startIcon={<GetAppIcon />} size="small" variant="contained" color="primary">
                                <CSVLink style={{ textDecoration: "none", color: "white" }} headers={headers} filename={"法人営業対応リスト.csv"} data={this.props.rent_corporate_contacts_all_list_search}>CSV</CSVLink>
                            </Button>
                        </Grid>
                    </Grid>
                </Card>













                <Table
                    className="gridCorporateNormalList"
                    rowHeight={68}
                    rowsCount={this.props.rent_corporate_contacts_all_list_search.length}
                    height={window.innerHeight - 220}
                    headerHeight={30}
                    width={window.innerWidth - 65}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    onRowClick={this.RowClick}
                    rowClassNameGetter={this._rowClassNameGetter}
                >

                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton onClick={(e) => { this.handleOpen(this.props.rent_corporate_contacts_all_list_search[rowIndex].id); }} aria-label="edit" size="small"><EditIcon /></IconButton></Cell>)} width={40} /> */}
                    <Column width={this.state.columnWidths.date} isResizable={true} columnKey="date" header={<Cell>日付</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{moment(this.props.rent_corporate_contacts_all_list_search[rowIndex].date).format("YYYY-MM-DD")}</Cell>)} />
                    <Column width={this.state.columnWidths.section_id} isResizable={true} columnKey="section_id" header={<Cell>部署</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].section_name}</Cell>)} />
                    <Column width={this.state.columnWidths.user_id} isResizable={true} columnKey="user_id" header={<Cell>担当</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].user_name}</Cell>)} />
                    <Column width={this.state.columnWidths.rent_m_corporate_way_id} isResizable={true} columnKey="rent_m_corporate_way_id" header={<Cell>対応方法</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>
                        {(() => {
                            if (this.props.rent_corporate_contacts_all_list_search[rowIndex].rent_m_corporate_way_id === 0) {
                                return "";
                            } else if (this.props.rent_corporate_contacts_all_list_search[rowIndex].rent_m_corporate_way_id === 1) {
                                return "企業訪問";
                            } else if (this.props.rent_corporate_contacts_all_list_search[rowIndex].rent_m_corporate_way_id === 2) {
                                return "TEL";
                            } else if (this.props.rent_corporate_contacts_all_list_search[rowIndex].rent_m_corporate_way_id === 3) {
                                return "メール";
                            }
                        })()}

                    </Cell>)} />
                    <Column width={this.state.columnWidths.name} isResizable={true} columnKey="name" header={<Cell>法人名</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><Link underline="hover" onClick={()=>this.open_corporate(this.props.rent_corporate_contacts_all_list_search[rowIndex].rent_m_corporate_id)}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].name}</Link></Cell>)} />
                    <Column width={this.state.columnWidths.staff} isResizable={true} columnKey="staff" header={<Cell>担当者</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].staff}</Cell>)} />

                    <Column width={this.state.columnWidths.main_corp_name} isResizable={true} columnKey="main_corp_name" header={<Cell>主要取引先</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].main_corp_name}</Cell>)} />
                    <Column width={this.state.columnWidths.other_main_corp} isResizable={true} columnKey="other_main_corp" header={<Cell>その他取引先</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].other_main_corp}</Cell>)} />
                    
                    <Column width={this.state.columnWidths.remarks} isResizable={true} columnKey="remarks" header={<Cell>対応内容</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].remarks}</Cell>)} />
                    <Column width={this.state.columnWidths.rival} isResizable={true} columnKey="rival" header={<Cell>ライバル情報（他社との状況）</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].rival}</Cell>)} />
                    <Column width={this.state.columnWidths.need} isResizable={true} columnKey="need" header={<Cell>社宅ニーズ</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].need}</Cell>)} />
                    <Column width={this.state.columnWidths.trouble} isResizable={true} columnKey="trouble" header={<Cell>お困りごと</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].trouble}</Cell>)} />
                    <Column width={this.state.columnWidths.request} isResizable={true} columnKey="request" header={<Cell>弊社に望むこと</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{this.props.rent_corporate_contacts_all_list_search[rowIndex].request}</Cell>)} />
                </Table>

                <Modal isOpen={this.state.modal} fade={false} size="lg" toggle={(e) => { this.setState({ "modal": false }) }} >
                    <ModalHeader toggle={(e) => { this.setState({ "modal": false }) }}>
                        <span>営業対応履歴</span>
                        {/* <IconButton onClick={this.handleClose} style={{float:"right", marginTop:0, marginRight:0}}><CloseIcon /></IconButton> */}
                    </ModalHeader>


                    <ModalBody>


                        <Card>
                            <CardContent>
                                <Grid container spacing={1}>
                                    <Grid justifyContent="space-between" item xs={4}>
                                        <TextField
                                            id="date"
                                            label="対応日"
                                            type="date"
                                            name="date"
                                            size="small"
                                            onChange={this.onChange}
                                            value={moment(this.props.rent_corporate_contacts.date).format("YYYY-MM-DD")}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            variant="standard"
                                        />
                                        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>

                                            <KeyboardDatePicker
                                                disableToolbar
                                                variant="inline"
                                                format="yyyy/MM/dd"
                                                margin="normal"
                                                id="date-picker-inline1"
                                                label="対応日"
                                                // showTodayButton={true}
                                                style={{ float: "left", marginTop: "0" }}
                                                autoOk={true}
                                                InputLabelProps={{ shrink: true }}
                                                name="date"
                                                value={this.props.rent_corporate_contacts.date}
                                                onChange={this.onChangeDate}
                                                KeyboardButtonProps={{
                                                    'aria-label': 'change date',
                                                }}
                                                invalidDateMessage={"日付の形式が違います"}
                                            />

                                        </MuiPickersUtilsProvider> */}
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={2} style={{ display: "flex"}}>
                                        <div  style={{position: "relative"}}>
                                            <TextField
                                                label="開始"
                                                style={{ width: 55, float: "left" }}
                                                onChange={this.change_datetime}
                                                name = "start_at"
                                                // onChange={handleChange}
                                                // id="formatted-text-mask-input"
                                                onFocus={this.focus_start}
                                                onBlur={this.blur_start}
                                                InputProps={{ inputComponent: TextMaskCustom }}
                                                value={this.props.rent_corporate_contacts.start_at}
                                                // value={this.props.eigyou_detail.start_at}
                                                error={this.state.start_at_err}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant='standard'
                                            />
                                            {this.state.focus_start ? <DateTimeComponentTime start={true} start_time={null} change_datetime_select={this.change_datetime_select} detail={this.props.rent_corporate_contacts} change_detail={this.props.change_rent_corporate_contacts} yoyaku_date={moment(this.props.rent_corporate_contacts.date).format("YYYY/MM/DD")} name={"start_at"} /> : ""}
                                        </div>
                                        <div>–</div>
                                        <div  style={{position: "relative"}}>
                                            <TextField
                                                label="終了"
                                                style={{ width: 55, float: "left" }}
                                                onChange={this.change_datetime}
                                                name = "end_at"
                                                // onChange={handleChange}
                                                // id="formatted-text-mask-input"
                                                onFocus={this.focus_end}
                                                onBlur={this.blur_end}
                                                InputProps={{ inputComponent: TextMaskCustom }}
                                                // value={this.props.eigyou_detail.end_at}
                                                value={this.props.rent_corporate_contacts.end_at}
                                                error={this.state.end_at_err}
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                variant='standard'
                                            />
                                            {this.state.focus_end ? <DateTimeComponentTime start={false} start_time={this.props.rent_corporate_contacts.start_at} change_datetime_select={this.change_datetime_select} detail={this.props.rent_corporate_contacts} change_detail={this.props.change_rent_corporate_contacts} yoyaku_date={moment(this.props.rent_corporate_contacts.date).format("YYYY/MM/DD")} name={"end_at"} /> : ""}
                                        </div>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <FormControl>
                                            {/* <FormLabel></FormLabel> */}
                                            <FormGroup row>
                                                <FormControlLabel control={<Checkbox checked={this.props.rent_corporate_contacts.intend} value={this.props.rent_corporate_contacts.intend} onChange={this.onChange} name="intend" />} label="予定" />
                                            </FormGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={11}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.props.rent_m_corporates_list.length === 0 && this.props.rent_corporate_contacts.rent_m_corporate_id ? [this.props.rent_m_corporates_list.filter(a => a.value === this.props.rent_responses_editor.rent_m_corporate_id)[0]]
                                                : this.props.rent_m_corporates_list}

                                            getOptionLabel={(option) => option.name ? option.name : ''}

                                            style={{ width: "100%" }}
                                            name="userId"
                                            loading={this.props.users_loading}

                                            value={this.props.rent_corporate_contacts.rent_m_corporate_id ? this.props.rent_m_corporates_list.filter(a => a.id === this.props.rent_corporate_contacts.rent_m_corporate_id)[0] : {}}

                                            onChange={this.handleRentMCorporateIdChange}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                name="userId"
                                                // error={!this.props.rent_responses_editor.user_id}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>

                                                            {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                // onChange={this.handleChange}
                                                label="法人" />}

                                        />

                                    </Grid>
                                    <Grid xs={1} style={{ padding: 5 }}>
                                        <Tooltip title="法人追加" aria-label="add">
                                            <IconButton onClick={() => { this.handleDetailOpen() }} size="large">
                                                <AddCircleOutlineIcon />
                                            </IconButton>
                                        </Tooltip>

                                        <Modal isOpen={this.props.rent_m_corporates_detail_open} toggle={() => { this.props.change_rent_m_corporates_detail_open(false) }} fade={false} size="xl" >
                                            <CorporatesDetailDialog parentComponent={1}></CorporatesDetailDialog>
                                        </Modal>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            options={this.props.section_select.length === 0 && this.props.rent_corporate_contacts.section_id ? [this.props.section_select.filter(a => a.value === this.props.rent_responses_editor.section_id)[0]]
                                                : this.props.section_select}
                                            getOptionLabel={(option) => option.label ? option.label : ''}

                                            style={{ width: "100%" }}
                                            name="sectionId"
                                            loading={this.props.users_loading}

                                            value={this.props.rent_corporate_contacts.section_id ? this.props.section_select.filter(a => a.value === this.props.rent_corporate_contacts.section_id)[0] : {}}

                                            onChange={this.handleSectionIdChange}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                name="sectionId"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>

                                                            {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                // onChange={this.handleChange}
                                                label="部署" />}

                                        />

                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            // options={this.props.users_select.map((value) => ({
                                            //     value: value.value,
                                            //     label: value.label
                                            //     }))}
                                            // getOptionLabel={(option) => option.label}
                                            options={this.props.users_select.length === 0 && this.props.rent_corporate_contacts.user_id ? [this.props.users_select.filter(a => a.value === this.props.rent_responses_editor.user_id)[0]]
                                                : this.props.users_select}
                                            getOptionLabel={(option) => option.label ? option.label : ''}

                                            style={{ width: "100%" }}
                                            name="userId"
                                            loading={this.props.users_loading}

                                            value={this.props.rent_corporate_contacts.user_id ? this.props.users_select.filter(a => a.value === this.props.rent_corporate_contacts.user_id)[0] : {}}

                                            onChange={this.handleUserIdChange}
                                            renderInput={(params) => <TextField
                                                {...params}
                                                variant="outlined"
                                                name="userId"
                                                // error={!this.props.rent_responses_editor.user_id}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    endAdornment: (
                                                        <React.Fragment>

                                                            {this.props.users_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                            {params.InputProps.endAdornment}
                                                        </React.Fragment>
                                                    ),
                                                }}
                                                // onChange={this.handleChange}
                                                label="営業担当" />}

                                        />

                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={4}>
                                        <FormControl variant="outlined" fullWidth className={{}}>
                                            <InputLabel>対応方法</InputLabel>
                                            <MaterialUiSelect
                                                name="rent_m_corporate_way_id"
                                                value={this.props.rent_corporate_contacts.rent_m_corporate_way_id}
                                                onChange={this.onChange}
                                                label="rank"
                                            >
                                                <MenuItem value={0}>-</MenuItem>
                                                <MenuItem value={1}>企業訪問</MenuItem>
                                                <MenuItem value={2}>TEL</MenuItem>
                                                <MenuItem value={3}>メール</MenuItem>

                                            </MaterialUiSelect>
                                        </FormControl>
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={8}>
                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="staff"
                                            label="企業担当者様名"
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.staff}
                                        />
                                    </Grid>
                                    {/* <Grid justify="space-between" item xs={4}>
                                        <FormControl variant="outlined" fullWidth className={{}}>
                                            <InputLabel>主要取引先</InputLabel>
                                            <MaterialUiSelect
                                                name="main_corp"
                                                value={this.props.rent_corporate_contacts.main_corp}
                                                onChange={this.onChange}
                                                label="rank"
                                            >
                                                <MenuItem value={0}>-</MenuItem>
                                                <MenuItem value={1}>穴吹ハウジング</MenuItem>
                                                <MenuItem value={2}>その他</MenuItem>
                                                <MenuItem value={3}>穴吹ハウジング or その他</MenuItem>

                                            </MaterialUiSelect>
                                        </FormControl>

                                    </Grid>
                                    <Grid justify="space-between" item xs={8}>
                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="other_main_corp"
                                            label="その他主要取引先"
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.other_main_corp}
                                            disabled = {!(this.props.rent_corporate_contacts.main_corp >= 2)}
                                        />
                                    </Grid> */}
                                    <Grid justifyContent="space-between" item xs={12}>
                                        <div style={{marginBottom:7}}><span style={{color:"red"}}>※具体的に入力</span></div>
                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="remarks"
                                            label="対応内容"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.remarks}
                                        />
                                    </Grid>
                                    {/* <Grid justify="space-between" item xs={6}>

                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="rival"
                                            label="ライバル情報（他社との状況）"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.rival}
                                        />
                                    </Grid> */}
                                    <Grid justifyContent="space-between" item xs={6}>

                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="need"
                                            label="社宅ニーズ"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.need}
                                        />
                                    </Grid>
                                    <Grid justifyContent="space-between" item xs={6}>

                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="trouble"
                                            label="お困りごと"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.trouble}
                                        />
                                    </Grid>
                                    {/* <Grid justify="space-between" item xs={6}>

                                        <TextField
                                            fullWidth
                                            id="outlined-multiline-static"
                                            name="request"
                                            label="弊社に望むこと"
                                            multiline
                                            rows={4}
                                            defaultValue=""
                                            variant="outlined"
                                            onChange={this.onChange}
                                            value={this.props.rent_corporate_contacts.request}
                                        />
                                    </Grid> */}
                                </Grid>



                            </CardContent>

                        </Card>




                    </ModalBody>


                    {/* <Button onClick={this.handleClose}>閉じる</Button> */}
                    <ModalFooter>

                        <Button variant="contained" color="primary" onClick={() => this.handleUpdate(this.props.rent_corporate_contacts.id, 0)} style={{ backgroundColor: "#5cb85c", marginRight: 5 }}>保存</Button>
                        {this.props.rent_corporate_contacts.id ? <Button variant="contained" color="secondary" onClick={(e) => { this.setState({ "modal_del": true }) }}>削除</Button> : ""}

                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modal_del} fade={false} className="modal-dialog-centered">
                    <ModalBody>
                        <div>本当に削除してよろしいですか？</div>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="contained" color="secondary" onClick={() => this.handleUpdate(this.props.rent_corporate_contacts.id, 1)}>削除</Button>
                        <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "modal_del": false }) }}>閉じる</Button>
                    </ModalFooter>

                </Modal>
            </Card>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_corporate_contacts_all_list: state.corporation.rent_corporate_contacts_all_list,
        rent_corporate_contacts_all_list_search: state.corporation.rent_corporate_contacts_all_list_search,
        users_select: state.masterReducer.users_select,
        section_select: state.masterReducer.section_select,
        kanricorps: state.masterReducer.kanricorps,
        rent_corporate_contacts: state.corporation.rent_corporate_contacts,
        rent_m_corporates_list: state.corporation.rent_m_corporates_list,
        user_details: state.masterReducer.user_details,
        search_data_contacts: state.corporation.search_data_contacts,
        rent_m_corporates_detail_open: state.corporation.rent_m_corporates_detail_open,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_search_data_contacts(state) {
            dispatch(Corporation.change_search_data_contacts(state))
        },
        get_rent_corporate_contacts_all_list(state) {
            dispatch(Corporation.get_rent_corporate_contacts_all_list(state))
        },
        get_rent_corporate_contacts_all_list_search(state) {
            dispatch(Corporation.get_rent_corporate_contacts_all_list_search(state))
        },

        set_rent_corporate_contacts_all(state) {
            dispatch(Corporation.set_rent_corporate_contacts_all(state))
        },
        change_rent_corporate_contacts(state) {
            dispatch(Corporation.change_rent_corporate_contacts(state))
        },
        insert_rent_corporate_contacts_all(state) {
            dispatch(Corporation.insert_rent_corporate_contacts_all(state))
        },
        update_rent_corporate_contacts_all(state) {
            dispatch(Corporation.update_rent_corporate_contacts_all(state))
        },

        get_rent_m_corporates_list(state) {
            dispatch(Corporation.get_rent_m_corporates_list(state))
        },

        change_rent_m_corporates(state) {
            dispatch(Corporation.change_rent_m_corporates(state))
        },
        change_rent_m_corporates_detail_open(state) {
            dispatch(Corporation.change_rent_m_corporates_detail_open(state))
        },
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




