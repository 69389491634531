// import { endianness } from "os";
// import { start } from "repl";

const authentication = require('react-azure-adb2c').default;



// export const update = (values:any) => {

//     const token = authentication.getAccessToken();
//     const url = `https://homestationapi.azurewebsites.net/api/RentContracts/` + values.id

//     return fetch(url, {
//         method: 'put',
//         body: JSON.stringify(values),
//         headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//     })
//         .then(res => res)
//         .then(payload => ({ payload }))
//         .catch(error => ({ error }));


// }






export const sales_sheet_api = (values: any) => {



  const url = process.env.REACT_APP_API_REPORTLIN_URL + `/api/v1/nippou/download`;
  // let url = `http://localhost:5000/api/v1/nippou/download`;


  const token = authentication.getAccessToken();

  // alert(url)
  // alert(JSON.stringify(values))
  return fetch(url, {
    method: 'post',
    body: JSON.stringify({
      start: values.start,
      end: values.end,
      section_id: values.section_id
    }),

    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

  }).then(response => response.blob())
    .then(payload => ({ payload }))
    // .then(res => res.json())
    // .then(payload => ({ payload }))
    .catch(error => ({ error }));






};







