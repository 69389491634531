const authentication = require('../../../react-azure-adb2c2').default;


export const list = (values: any) => {

    let url = process.env.REACT_APP_API_BASE_URL + `/api/RentMSaleAccounts/`;

    //  const url = `https://localhost:44341/api/RentSales/`+ values;


    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};














// export const detail = (Id: number) => {

//             const url = process.env.REACT_APP_API_BASE_URL+`/api/RentCustomerContacts/` + Id;

//             const token = authentication.getAccessToken();

//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }


//     export const update = (values:any) => {
//         values.intend= values.intend==true?1:0;
//         values.going= values.going==true?1:0;
//         const token = authentication.getAccessToken();
//         const url = process.env.REACT_APP_API_BASE_URL+`/api/RentCustomerContacts/` + values.id;
//         // const url = `https://localhost:44341/api/RentCustomerContacts/` + values.id;
//         return fetch(url, {
//             method: 'put',
//             body: JSON.stringify(values),
//             headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//         })
//             .then(res => res.json())
//             .then(payload => ({ payload }))
//             .catch(error => ({ error }));


//     }

//     export function insert(values:any) {

//         const token = authentication.getAccessToken();
//         const url = process.env.REACT_APP_API_BASE_URL+`/api/RentCustomerContacts/`;
//         // const url = `https://localhost:44341/api/RentCustomerContacts/`;
//         values.intend= values.intend==true?1:0;
//         values.going= values.going==true?1:0;
//         return fetch(url, {
//             method: 'post',
//             body: JSON.stringify(values),
//             headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
//         })
//             .then(res => res.json())
//             .then(payload => ({ payload }))
//             .catch(error => ({ error }));
//     }