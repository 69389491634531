
import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';


import { call, put, takeEvery, select } from 'redux-saga/effects';
// import * as RentIntroductionsApi from '../Api/RentIntroductionsApi';
import * as rent_response_docs_api from '../Api/rent_response_docs_api';
// import * as RentIntroductionFilesApi from '../Api/RentIntroductionFilesApi';



export interface RoomsState {
  response_files: any;
  response_files_loading: boolean;
  response_file_data: any;
  response_file_update_disp: boolean;
}
const initialState: RoomsState = {

  response_files: [],
  response_files_loading: false,
  response_file_data: null,
  response_file_update_disp: false,
}



///action 
const actionCreator = actionCreatorFactory();


export const get_response_files = actionCreator('GET_RESPONSE_FILES');
export const change_response_files = actionCreator<any>("CHANGE_RESPONSE_FILES");
export const change_response_files_loading = actionCreator<boolean>('CHANGE_RESPONSE_FILES_LOADING');
export const download_response_file = actionCreator('DOWNLOAD_RESPONSE_FILE');

export const change_response_file_data = actionCreator<any>('CHANGE_RESPONSE_FILE_DATA');
export const change_response_file_remarks = actionCreator<any>('CHANGE_RESPONSE_FILE_REMARKS');

export const upload_response_file = actionCreator('UPLOAD_RESPONSE_FILE');
export const edit_response_file_remarks = actionCreator('EDIT_RESPONSE_FILE_REMARKS');

export const delete_response_file = actionCreator('DELETE_RESPONSE_FILE');

export const change_response_file_update_disp = actionCreator<boolean>('CHANGE_RESPONSE_FILE_UPDATE_DISP');



export const responsefilesReducer = reducerWithInitialState(initialState)
  // .case(get_setsubi_master_success, (state, payload) => ({ ...state, setsubi_master: payload }))
  // .case(get_room_details_success, (state, payload) => ({ ...state, room_details: payload }))
  .case(change_response_files, (state, payload) => ({ ...state, response_files: payload }))

  .case(change_response_files_loading, (state, payload) => ({ ...state, response_files_loading: payload }))

  .case(change_response_file_data, (state, payload) => ({ ...state, response_file_data: payload }))
  .case(change_response_file_remarks, (state, payload) => ({ ...state, response_file_remarks: payload }))

  .case(change_response_file_update_disp, (state, payload) => ({ ...state, response_file_update_disp: payload }))




// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const responsefilesSaga = [
  takeEvery('GET_RESPONSE_FILES', handle_get_response_files),
  takeEvery('DOWNLOAD_RESPONSE_FILE', handle_download_response_file),
  takeEvery('UPLOAD_RESPONSE_FILE', handle_upload_response_file),


  takeEvery('EDIT_RESPONSE_FILE_REMARKS', handle_edit_response_file_remarks),


  takeEvery('DELETE_RESPONSE_FILE', handle_delete_response_file),
];
// export default masterSaga;





function* handle_get_response_files(action: any) {
  yield put(change_response_files_loading(true));

  const { payload, error } = yield call(rent_response_docs_api.list, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    console.log(payload)

    yield put(change_response_files(payload));
    yield put(change_response_files_loading(false));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}



function* handle_download_response_file(action: any) {

  const { payload, error } = yield call(rent_response_docs_api.download, action.payload.id);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    // 成功時アクション呼び出し
    console.log(payload)
    const a = document.createElement('a');
    // ダウンロードされるファイル名
    a.download = action.payload.name;
    // a.download = "10.pdf";
    a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    a.click();
    //    yield put(get_rent_contract_monies_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}

function* handle_upload_response_file(action: any): any {
  yield put(change_response_files_loading(true));
  const state = yield select();
  console.log(state.responsefiles.response_file_data)
  const { payload, error } = yield call(rent_response_docs_api.upload, { response_id: state.responsesReducer.responseid, file: state.responsefiles.response_file_data, remarks: state.responsefiles.response_file_remarks });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {
    if(payload.length == 0){
      alert("アップロードに失敗しました。")
    }else{
      yield put(change_response_files(payload));
    }
    // 成功時アクション呼び出し
    // console.log(payload)
    yield put(change_response_file_update_disp(false));
    yield put(change_response_files_loading(false));
    // const a = document.createElement('a');
    // ダウンロードされるファイル名
    // a.download = action.payload.name;
    // a.download = "10.pdf";
    // a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    // a.click();
    //    yield put(get_rent_contract_monies_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}


function* handle_edit_response_file_remarks(action: any): any {
  const state = yield select();
  // console.log(state.responsefiles.response_file_data)
  const { payload, error } = yield call(rent_response_docs_api.update, { fileid: action.payload, remarks: state.responsefiles.response_file_remarks });

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let response_files = state.responsefiles.response_files.map(function (a: any) {
      if (a.id === action.payload) return { ...a, remarks: state.responsefiles.response_file_remarks };
      return a

    })
    yield put(change_response_files(response_files));

    yield put(change_response_file_update_disp(false));
    // 成功時アクション呼び出し
    console.log(payload)
    // const a = document.createElement('a');
    // ダウンロードされるファイル名
    // a.download = action.payload.name;
    // a.download = "10.pdf";
    // a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    // a.click();
    //    yield put(get_rent_contract_monies_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}



function* handle_delete_response_file(action: any): any {
  const state = yield select();
  // console.log(state.responsefiles.response_file_data)
  const { payload, error } = yield call(rent_response_docs_api.deletefile, action.payload);

  // サーバーからのレスポンスデータによる分岐処理
  if (payload && !error) {

    let response_files = state.responsefiles.response_files.map(function (a: any) {
      if (a && a.id !== action.payload) {
        return a
      }
    })
    yield put(change_response_files(response_files));
    yield put(change_response_file_update_disp(false));

    // 成功時アクション呼び出し
    console.log(payload)
    // const a = document.createElement('a');
    // ダウンロードされるファイル名
    // a.download = action.payload.name;
    // a.download = "10.pdf";
    // a.href = URL.createObjectURL(payload);
    // ダウンロード開始
    // a.click();
    //    yield put(get_rent_contract_monies_success(payload));

  } else {
    //    // 失敗時アクション呼び出し
    //    yield put(loginFailure(payload));
  }
}
//  function* handleGetRoomDetailsRequest(action:any) {

//   const { payload, error }= yield call(SearchRoomsApi.detail, action.payload);

//      // サーバーからのレスポンスデータによる分岐処理
//      if (payload && !error) {
//          console.log(payload)

//          yield put(get_room_details_success(payload));

//      } else {
//      //    // 失敗時アクション呼び出し
//      //    yield put(loginFailure(payload));
//      }
//  }



// function* handle_get_introduction_details(action: any) {

//   yield put(change_introduction_detail_loading(true));
//   const { payload, error } = yield call(RentIntroductionDetailsApi.detail, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)

//     yield put(get_introduction_details_success(payload));
//     yield put(change_introduction_detail_loading(false));
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }

// function* handle_get_response_rooms(action: any) {

//   const { payload, error } = yield call(SearchRoomsApi.response_list, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {
//     console.log(payload)

//     yield put(get_response_rooms_success(payload));

//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }












// function* handle_get_introductions(action: any) {
//   console.log(action.payload)

//   yield put(change_introductions_loading(1));
//   // yield put(get_introductions_success({}));
//   // alert(action.payload.rent_response_id)
//   const { payload, error } = yield call(RentIntroductionsApi.list, action.payload.rent_response_id);

//   if (payload && !error) {
//     // 成功時アクション呼び出し
//     console.log(payload)
//     yield put(get_introductions_success(payload));
//     yield put(change_introductions_loading(0));
//     // yield put(change_customer_logs_loading(0));

//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }

// function* handle_insert_introductions(action: any) {
//   console.log(action.payload)

//   yield put(change_introductions_loading(1));
//   const { payload, error } = yield call(RentIntroductionsApi.insert, action.payload);

//   if (payload && !error) {
//     // 成功時アクション呼び出し
//     //   console.log(payload)
//     //   // alert()
//     // alert(payload.rentResponseId)
//     //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
//     //   if (payload && !error) {  

//     yield put(get_introductions_success(payload));
//     yield put(change_introductions_loading(0));

//     // } else {
//     //   alert(error)
//     //   //    // 失敗時アクション呼び出し
//     //   //    yield put(loginFailure(payload));
//     //   }
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }

// function* handle_update_open_introductions(action: any) {
//   console.log(action.payload)

//   yield put(change_introductions_loading(1));
//   const { payload, error } = yield call(RentIntroductionsApi.update, action.payload);

//   if (payload && !error) {
//     // 成功時アクション呼び出し
//     //   console.log(payload)
//     //   // alert()
//     // alert(payload.rentResponseId)
//     //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
//     //   if (payload && !error) {  

//     // yield put(get_introductions_success(payload));
//     yield put(get_introductions({ rent_response_id: action.payload.rentResponseId }));
//     yield put(change_introductions_loading(0));

//     // } else {
//     //   alert(error)
//     //   //    // 失敗時アクション呼び出し
//     //   //    yield put(loginFailure(payload));
//     //   }
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }


// function* handle_delete_introduction(action: any) {
//   console.log(action.payload)

//   yield put(change_introductions_loading(1));
//   const { payload, error } = yield call(RentIntroductionsApi.deleteintro, action.payload.rentIntroductionId);

//   if (payload && !error) {
//     // 成功時アクション呼び出し
//     //   console.log(payload)
//     //   // alert()
//     // alert(payload.rentResponseId)
//     //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
//     //   if (payload && !error) {  

//     yield put(get_introductions({ rent_response_id: action.payload.rent_response_id }));
//     // yield put(change_introductions_loading(0));

//     // } else {
//     //   alert(error)
//     //   //    // 失敗時アクション呼び出し
//     //   //    yield put(loginFailure(payload));
//     //   }
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }





// function* handle_insert_introduction_files(action: any) {
//   console.log(action.payload)

//   yield put(change_introductions_loading(1));


//   const { payload, error } = yield call(RentIntroductionFilesApi.insert, action.payload);
//   const state = yield select();
//   if (payload && !error) {
//     // 成功時アクション呼び出し
//     //   console.log(payload)
//     //   // alert()
//     // alert(payload.rentResponseId)
//     //   const { payload2, error } = yield call(RentIntroductionsApi.list, payload.rentResponseId);
//     //   if (payload && !error) {  
//     // alert(payload.id)

//     if (state.introductionlist.introduction_pdf != null) {
//       var pdf = state.introductionlist.introduction_pdf
//       pdf.id = payload.id
//       yield put(add_introduction_file(pdf))
//       var img = state.introductionlist.introduction_pdf_img
//       img.id = payload.id
//       yield put(add_introduction_file(img))
//     }

//     yield put(get_introductions({ rent_response_id: action.payload.rentResponseId }));

//     yield put(change_introduction_file_id(payload.id));

//     yield put(change_introduction_pdf(null));
//     yield put(change_introduction_pdf_img(null));
//     // // yield put(change_introductions_loading(0));

//     // } else {
//     //   alert(error)
//     //   //    // 失敗時アクション呼び出し
//     //   //    yield put(loginFailure(payload));
//     //   }
//   } else {
//     //    // 失敗時アクション呼び出し
//     //    yield put(loginFailure(payload));
//   }
// }


// function* handle_add_introduction_file(action: any) {

//   // yield put(change_get_mail_detail_files_loading(1))
//   // console.log( action.payload)
//   // yield put(get_mail_detail_files_success([]))
//   const { payload, error } = yield call(RentIntroductionFileUploadApi.add_file, action.payload);

//   // サーバーからのレスポンスデータによる分岐処理
//   if (payload && !error) {


//     //  yield put(get_mail_detail_files_success(payload))

//     //  yield put(change_get_mail_detail_files_loading(0))




//   } else {


//   }
// }


