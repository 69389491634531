import React from 'react';

import { connect } from 'react-redux';
import styled from 'styled-components';
import Carousel, { Modal, ModalGateway } from 'react-images';
import { get_line_images } from './Saga/Line';
import * as Line from './Saga/Line';
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from '@mui/material/IconButton';
var moment = require('moment-timezone');
// import './Css/Images.css';
const authentication = require('../../react-azure-adb2c2').default;
const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin-top:15px;
`;
const Faceicon = styled.div`
    float: left;
        margin-right: -50px;
        width: 40px;
`;
const FaceiconImg = styled.img`
    width: 100%;
        height: auto;
        border-radius: 50%;
`;
const DisplayName = styled.div`
    margin:0 0 0 50px;
    font-size:9px;
    font-weight:bold;
    color:#FFF;
text-align:left;
`;
const Chatting = styled.div`
    width: 100%;
        text-align: left;
`;
const Say = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 50px;
    max-width: 60%;
    margin-bottom: 2px;
    height:250px;
`;
const SayP = styled.p`
    margin:0；
    height:270px;
`;
const BodyImg = styled.img`
    border-radius: 12px;
height:250px;
`;

const Right = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0px;
    font-size:10px;
    color:#FFF;
    width:100px;
`;

const Download = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 20px;
    font-size:10px;
    color:#FFF;
`;

const Time = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    bottom: 0px;
    font-size:10px;
    color:#FFF;
`;
//export default
class LineImgComponent extends React.Component {
    constructor(props) {
        super(props)
        const dt = new Date(this.props.msg.date)
        this.state = {
            bloburl: "",
            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            images: [],
            currentIndex: 0,
            modalIsOpen: false,
        };
        this.imagebox = this.imagebox.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }


    componentWillMount() {



        const token = authentication.getAccessToken();
        const url = `https://homestatonlineapi.azurewebsites.net/v1/lines/getfile/` + this.props.msg.file_id
        // const url = `http://localhost:5000/v1/lines/getfile/` + this.props.msg.file_id
        // alert(url)

        var bloburl
        fetch(url, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => response.blob()).then(images => {
            // Then create a local URL for that image and print it 
            bloburl = URL.createObjectURL(images)
            console.log(bloburl)
            this.setState({
                bloburl: bloburl
            })

        });

    }


    imagebox(index) {

        let images = [];

        images.push({ src: this.state.bloburl })



        this.setState({ "images": images })
        if (index >= 0) {
            this.setState({ currentIndex: 0 })
            this.setState({ modalIsOpen: true });
        }
    }
    toggleModal = () => {
        this.setState(state => ({ modalIsOpen: !state.modalIsOpen }));
    };

    render() {
        return (
            <div>
                <Balloon className="col-xs-12">
                    <Faceicon><FaceiconImg src={'https://img.global-center.co.jp/v1/line/img/' + this.props.msg.line_user_id + '/200/200'} /></Faceicon>
                    <DisplayName>{this.props.msg.display_name}</DisplayName>
                    <Chatting>

                        <Say>
                            <SayP>

                                <BodyImg src={this.state.bloburl} onClick={() => this.imagebox(0)} />
                            </SayP>
                        </Say>
                        <Right>
                            <Download><IconButton
                                style={{color:"white"}}
                                onClick={() => this.props.download_line_receive_file(this.props.msg)}
                                size="large"><DownloadIcon /></IconButton></Download>
                            <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time>
                        </Right>
                        



                    </Chatting>



                </Balloon>
                <ModalGateway>
                    {this.state.modalIsOpen ? (

                        <Modal onClose={this.toggleModal} >
                            <Carousel views={this.state.images}
                                currentIndex={this.state.currentIndex}

                            />
                        </Modal>

                    ) : null}
                </ModalGateway>
            </div>
        );
    }
}
//<BodyImg src={"https://homestationapi.azurewebsites.net/api/lineimage/" + this.props.msg.body_all} />
//<BodyImg src={this.props.lineimage.map(this.getFullName)} /> 
//containers
const mapStateToProps = (state) => {
    return {
        line_images: state.line.line_images

    }
}
function mapDispatchToProps(dispatch) {
    return {

        get_line_images(state) {
            dispatch(get_line_images(state))
        },
        download_line_receive_file(state) {
            dispatch(Line.download_line_receive_file(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(LineImgComponent);
