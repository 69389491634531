import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as Corporation from './Saga/Corporation';

import { Radio, RadioGroup } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { TextField } from '@mui/material';
import styled from 'styled-components'
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
var moment = require('moment-timezone');

// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';
// interface Props {
//     // classes: any,
//     // m_corporations_list:any,
//     // get_m_corporations_list:any,
//     // customerNewDispSet: any,
//     // customerNewDisp:number,
//     // customerno:number,
//     // customerList:any,
//     // toggle:any,
//     // get_rent_contract_accounting: any,
//     // banks: any,
//     // get_banks: any
// }



// interface State {

//     // state types
// }

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))

const Pin = styled.div``
class CustomerList extends Component {
    constructor(props) {
 
        super(props);
        this.state = {
            // modal: false,
            modal_del: false,
            modalselect: false,

            zoom: 14,
            draggable: true,
            lat: 34.36179852,
            lng: 134.048788977,
            geo_lat : "",
            geo_lng : "",
            center: null,

        };


        this.onChange = this.onChange.bind(this);
        this.onChangePartnershipAt = this.onChangePartnershipAt.bind(this);

    }




    componentWillMount() {

    }


    onChange(state){
        // console.log(this.props.claso_blog_places)
        let rent_m_corporates =  this.props.rent_m_corporates

        if(state.target.name == "referral_fee_subject" || state.target.name == "agent_charge_subject" || state.target.name == "manage_referral_fee_subject" || state.target.name == "manage_agent_charge_subject"){
            if (state.target.checked) {
                let ary = rent_m_corporates[state.target.name] ? rent_m_corporates[state.target.name].split(",") : []
                ary.push(parseInt(state.target.value))
                ary = ary.filter(Boolean)
                rent_m_corporates[state.target.name] = ary.join(',')
            } else {
                rent_m_corporates[state.target.name] = rent_m_corporates[state.target.name].split(",").filter(item => parseInt(item) != parseInt(state.target.value)).join(',')

            }
        // } else if(state.target.name == "referral_fee_unit" || state.target.name == "agent_charge_unit"){
        //     rent_m_corporates[state.target.name] = state.target.checked
        }else{
            rent_m_corporates[state.target.name] = state.target.value
        }
        
        
        this.props.change_rent_m_corporates(rent_m_corporates)
        this.setState({
            change: !this.state.change
        });
        
    }

    onChangePartnershipAt(value) {

        let rent_m_corporates = this.props.rent_m_corporates
        rent_m_corporates.partnership_at = value
        
        this.props.change_rent_m_corporates(rent_m_corporates)


        this.setState({
            refresh: !this.state.refresh    //再描画
        });
    }


    render() {
        return (
            <Card>
                <CardContent>

                    <Grid container spacing={1}>
                        <Grid justifyContent="space-between" item xs={12}>
                            <span style={{ fontSize: 15 }}>紹介料</span>
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3} style={{paddingLeft:40}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 13 }}>管理物件</FormLabel>
                            <FormControlLabel control={<Checkbox checked={this.props.rent_m_corporates.manage_referral_fee_subject ? this.props.rent_m_corporates.manage_referral_fee_subject.split(',').includes("1") : false} value={1} onChange={this.onChange} name="manage_referral_fee_subject" />} label="建物" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_m_corporates.manage_referral_fee_subject ? this.props.rent_m_corporates.manage_referral_fee_subject.split(',').includes("2") : false} value={2} onChange={this.onChange} name="manage_referral_fee_subject" />} label="P" />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3}>
                        {/* <TextField size="small" margin="dense" id="outlined-basic" label="紹介料（税別）" name="manage_referral_fee" onChange={this.onChange} value={this.props.rent_m_corporates.manage_referral_fee} variant="outlined" fullWidth /> */}
                            <TextField type="number" size="small" margin="dense" id="outlined-basic" label="紹介料（税別）" name="manage_referral_fee" onChange={this.onChange} value={this.props.rent_m_corporates.manage_referral_fee} variant="outlined" fullWidth />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={6}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 9, marginTop:6 }}>　</FormLabel>
                            <RadioGroup size="small" row name="manage_referral_fee_unit" onChange={this.onChange} value={parseInt(this.props.rent_m_corporates.manage_referral_fee_unit)} >
                                <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={0} />} label="％" />
                                <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={1} />} label="円" />
                            </RadioGroup>
                        </Grid>

                        <Grid justifyContent="space-between" item xs={3} style={{paddingLeft:40}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 13 }}>その他物件</FormLabel>
                            <FormControlLabel control={<Checkbox checked={this.props.rent_m_corporates.referral_fee_subject ? this.props.rent_m_corporates.referral_fee_subject.split(',').includes("1") : false} value={1} onChange={this.onChange} name="referral_fee_subject" />} label="建物" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_m_corporates.referral_fee_subject ? this.props.rent_m_corporates.referral_fee_subject.split(',').includes("2") : false} value={2} onChange={this.onChange} name="referral_fee_subject" />} label="P" />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3}>
                            <TextField type="number" size="small" margin="dense" id="outlined-basic" label="紹介料（税別）" name="referral_fee" onChange={this.onChange} value={this.props.rent_m_corporates.referral_fee} variant="outlined" fullWidth />
                            {/* <TextField size="small" margin="dense" id="outlined-basic" label="紹介料（税別）" name="referral_fee" onChange={this.onChange} value={this.props.rent_m_corporates.referral_fee} variant="outlined" fullWidth /> */}
                        </Grid>
                        <Grid justifyContent="space-between" item xs={6}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 9, marginTop:6 }}>　</FormLabel>
                            <RadioGroup size="small" row name="referral_fee_unit" onChange={this.onChange} value={parseInt(this.props.rent_m_corporates.referral_fee_unit)} >
                                <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={0} />} label="％" />
                                <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={1} />} label="円" />
                            </RadioGroup>
                        </Grid>
                    </Grid>



                    <Grid container spacing={1}>
                        <Grid justifyContent="space-between" item xs={12}>
                            <span style={{ fontSize: 15 }}>仲介手数料の割引</span>
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3} style={{paddingLeft:40}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 13 }}>管理物件</FormLabel>
                            <FormControlLabel control={<Checkbox checked={this.props.rent_m_corporates.manage_agent_charge_subject ? this.props.rent_m_corporates.manage_agent_charge_subject.split(',').includes("1") : false} value={1} onChange={this.onChange} name="manage_agent_charge_subject" />} label="建物" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_m_corporates.manage_agent_charge_subject ? this.props.rent_m_corporates.manage_agent_charge_subject.split(',').includes("2") : false} value={2} onChange={this.onChange} name="manage_agent_charge_subject" />} label="P" />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3}>
                            <TextField type="number" size="small" margin="dense" id="outlined-basic" label="仲介手数料の割引" name="manage_agent_charge" onChange={this.onChange} value={this.props.rent_m_corporates.manage_agent_charge} variant="outlined" fullWidth />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={6}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 9, marginTop:6 }}>　</FormLabel>
                            <RadioGroup size="small" row name="manage_agent_charge_unit" onChange={this.onChange} value={parseInt(this.props.rent_m_corporates.manage_agent_charge_unit)} >
                                <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={0} />} label="％" />
                                <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={1} />} label="円" />
                            </RadioGroup>
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3} style={{paddingLeft:40}}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 13 }}>その他物件</FormLabel>
                            <FormControlLabel control={<Checkbox checked={this.props.rent_m_corporates.agent_charge_subject ? this.props.rent_m_corporates.agent_charge_subject.split(',').includes("1") : false} value={1} onChange={this.onChange} name="agent_charge_subject" />} label="建物" />
                            <FormControlLabel control={<Checkbox checked={this.props.rent_m_corporates.agent_charge_subject ? this.props.rent_m_corporates.agent_charge_subject.split(',').includes("2") : false} value={2} onChange={this.onChange} name="agent_charge_subject" />} label="P" />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={3}>
                            <TextField type="number" size="small" margin="dense" id="outlined-basic" label="仲介手数料の割引" name="agent_charge" onChange={this.onChange} value={this.props.rent_m_corporates.agent_charge} variant="outlined" fullWidth />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={6}>
                            <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 9, marginTop:6 }}>　</FormLabel>
                            <RadioGroup size="small" row name="agent_charge_unit" onChange={this.onChange} value={parseInt(this.props.rent_m_corporates.agent_charge_unit)} >
                                <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={0} />} label="％" />
                                <FormControlLabel control={<Radio style={{paddingTop:0, paddingBottom:0}} value={1} />} label="円" />
                            </RadioGroup>
                        </Grid>
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid justifyContent="space-between" item xs={12}>
                            <TextField id="outlined-basic" label="割引対象" name="be_discounted" value={this.props.rent_m_corporates.be_discounted} onChange={this.onChange} variant="outlined" fullWidth />
                        </Grid>
                        

                        <Grid justifyContent="space-between" item xs={12}>

                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                name="partnership_remarks"
                                label="注意事項"
                                multiline
                                rows={4}
                                value={this.props.rent_m_corporates.partnership_remarks}
                                onChange={this.onChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid justifyContent="space-between" item xs={12}>

                            <TextField
                                id="partnership_at"
                                label="登録日"
                                type="date"
                                name="partnership_at"
                                size="small"
                                onChange={this.onChange}
                                value={moment(this.props.rent_m_corporates.partnership_at).format("YYYY-MM-DD")}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                variant="standard"
                            />

                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>
                                <KeyboardDatePicker
                                    disableToolbar
                                    variant="inline"
                                    format="yyyy/MM/dd"
                                    label="登録日"
                                    // showTodayButton={true}
                                    style={{ marginTop: "0", width: 160 }}
                                    autoOk={true}
                                    InputLabelProps={{ shrink: true }}
                                    name="yoyaku_date_start"
                                    value={this.props.rent_m_corporates.partnership_at ? this.props.rent_m_corporates.partnership_at : null}
                                    onChange={this.onChangePartnershipAt}
                                    inputVariant="outlined"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                    // invalidDateMessage={"日付の形式が違います"}
                                />

                            
                            </MuiPickersUtilsProvider> */}



                        </Grid>
                        

                    </Grid>
                    



                </CardContent>

            </Card>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        rent_m_corporates: state.corporation.rent_m_corporates,
        rent_m_corporates_top_open:state.corporation.rent_m_corporates_top_open,
        rent_m_corporates_detail_open:state.corporation.rent_m_corporates_detail_open,
        // m_corporations_list: state.corporation.m_corporations_list,
        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_m_corporates(state) {
            dispatch(Corporation.change_rent_m_corporates(state))
        },

        update_rent_m_corporates(state) {
            dispatch(Corporation.update_rent_m_corporates(state))
        },
        change_rent_m_corporates_top_open(state) {
            dispatch(Corporation.change_rent_m_corporates_top_open(state))
        },
        change_rent_m_corporates_detail_open(state) {
            dispatch(Corporation.change_rent_m_corporates_detail_open(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




