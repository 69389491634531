import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';

import ResponseCount from './Gadgets/ResponseCount/Index';
import ResponseType from './Gadgets/ResponseType/Index';
import TelContact from './Gadgets/TelContact/Index';
import Invite from './Gadgets/Invite/Index';
import Coming from './Gadgets/Coming/Index';
import Conduct from './Gadgets/Conduct/Index';
import DisclosureStatement from './Gadgets/DisclosureStatement/Index';
import LineBlock from './Gadgets/LineBlock/Index';

import withStyles from '@mui/styles/withStyles';
import Grid from '@mui/material/Grid';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';
interface Props {
    classes: any,
    user_details: any,
    // customerNewDispSet: any,
    // customerNewDisp:number,
    // customerno:number,
    // customerList:any,
    // toggle:any,
    // get_rent_contract_accounting: any,
    // banks: any,
    // get_banks: any
}



interface State {

    // state types
}

const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))


class CustomerList extends Component<Props, State> {
    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state: any) {
        console.log(state)

        // this.props.get_banks(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div className={this.props.classes.grid} style={{ margin: 5 }}>
                <Grid container spacing={1}>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            {/* <CountComing /> */}
                            <ResponseType />
                        </Card>

                    </Grid>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            {this.props.user_details.amSectionId > 0 ? <TelContact /> : ""}
                        </Card>

                    </Grid>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            {this.props.user_details.amSectionId > 0 ? <Invite /> : ""}
                        </Card>

                    </Grid>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            {this.props.user_details.amSectionId > 0 ? <Coming /> : ""}
                        </Card>

                    </Grid>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            {this.props.user_details.amSectionId > 0 ? <Conduct /> : ""}
                        </Card>

                    </Grid>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            {this.props.user_details.amSectionId > 0 ? <DisclosureStatement /> : ""}
                        </Card>

                    </Grid>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            {this.props.user_details.amSectionId > 0 ? <LineBlock /> : ""}
                        </Card>
                    </Grid>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            <ResponseCount />
                        </Card>

                    </Grid>
                    <Grid item xs={4}>
                        <Card className={this.props.classes.root}>
                            <ResponseCount />
                        </Card>

                    </Grid>
                </Grid>
            </div >
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state: any) => {
    return {

        user_details: state.masterReducer.user_details,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




