import React, { Component } from 'react';

import { connect } from 'react-redux';


import { Field, reduxForm } from 'redux-form';

import './Css/DateComponent.css';
let hiddenFlg;

const WeekChars = [ "(日)", "(月)", "(火)", "(水)", "(木)", "(金)", "(土)" ];


class DateComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            // gasu: this.props.responsedetail.gasu,
            // denki: this.props.responsedetail.denki,
            // ih: this.props.responsedetail.ih,
            // gasuka: this.props.responsedetail.gasuka,
            // denkika: this.props.responsedetail.denkika,
            // ihka: this.props.responsedetail.ihka,
            // eakon: this.props.responsedetail.eakon,
        };
    }

    render() {
    
    　//土曜日or日曜日
      const wd = this.props.date.getDay() == 0 ? "sun" : (this.props.date.getDay() == 6 ? "sat" : "") ;
      return (
        <div>            
            <div className={'dateCell' + ' ' + wd} style={{width:this.props.width,height:this.props.height,fontSize:this.props.fontSize}} >
                <span>{this.props.date.getDate()}</span>
                <span>{WeekChars[this.props.date.getDay()]}</span>
            </div>
        </div>
    );
  }
}



//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        // setsubi_master: state.roomsReducer.setsubi_master,

        // chomei_list: state.search.chomei_list,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {


    };
}


export default connect(mapStateToProps, mapDispatchToProps)(DateComponent);