// import * as React from 'react';
import React, { useState } from "react"
import { connect } from 'react-redux';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import { DialogContent } from '@mui/material';

import GraphCreateComponent from './GraphCreateComponent';






function App(props) {

    const [create_dialog_0pen, setCreateDialogOpen] = useState(0);
    const [graph_id, setGraphId] = useState(0);
    // const [value, setValue] = React.useState(0);

    // const handleChange = (event, newValue) => {
    //     setValue(newValue);
    // };

    const handleOpen = async(id) => {

        setGraphId(id)

        setCreateDialogOpen(true)

    };

    const handleClose = async(id) => {

        setGraphId(0)

        setCreateDialogOpen(false)

    };

    return (
        <Box sx={{ width: '100%' }}>
            <Button onClick={()=>handleOpen(0)}>グラフ作成</Button>

            <Dialog onClose={handleClose} open={create_dialog_0pen} maxWidth={"xl"} fullScreen={true}>
                <DialogTitle>集計する</DialogTitle>
                <DialogContent>
                    <GraphCreateComponent />
                </DialogContent>
                
            </Dialog>

        </Box>
    );
}

const mapStateToProps = (state) => {
    return {
      user_details: state.masterReducer.user_details,
      users_select: state.masterReducer.users_select,
    }
  }

export default connect(mapStateToProps)(App);