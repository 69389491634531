import React, { Component } from 'react';

import { connect } from 'react-redux';

import "react-datepicker/dist/react-datepicker.css";

import '../../Css/Table.css';
import Autocomplete from '@mui/material/Autocomplete';
import * as Calendarsaga from './Saga/Calendar';
import TodayList from "./TodayList";
import Grid from '@mui/material/Grid';


import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import moment from 'moment';
import StoreIcon from '@mui/icons-material/Store';
import EventNoteIcon from '@mui/icons-material/EventNote';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import Today from '@mui/icons-material/Today';
import DoubleArrow from '@mui/icons-material/DoubleArrow';
import NavigateNext from '@mui/icons-material/NavigateNext';
import Event from '@mui/icons-material/Event';

import IconButton from '@mui/material/IconButton';


// const selectStyles = {
//   menu: base => ({
//     ...base,
//     zIndex: 0
//   })
// };


// const RenderSelectInput = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="店舗を選択"
//   />
// )

// const RenderSelectUsers = ({ input, options, name, id }) => (
//   <Select
//     {...input}
//     id={id}
//     name={name}
//     options={options}
//     value={input.value}
//     onChange={(a) => input.onChange(a ? a.value : 0)}
//     onBlur={(a) => input.onBlur(a ? a.value : 0)}

//     placeholder="追客担当を選択"
//   />
// )



class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,

    };

    this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.handlePrimaryUserIdChange = this.handlePrimaryUserIdChange.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeDateButton = this.onChangeDateButton.bind(this);

  }

  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")


  }

  handleChangeSectionId(e, newValue) {
    // alert(newValue.tenpoNo)
    if (newValue) {
      let calendar_store_list_form = this.props.calendar_store_list_form
      calendar_store_list_form.section_id = [newValue.tenpoNo]
      this.props.change_calendar_store_list_form(calendar_store_list_form);
      // alert(calendar_store_list_form.section_id)
    } else {
      let calendar_store_list_form = this.props.calendar_store_list_form
      calendar_store_list_form.section_id = []

      this.props.change_calendar_store_list_form(calendar_store_list_form);
    }

    this.setState({
      refresh: !this.state.refresh    //再描画
  });

    this.props.get_calendar_list();

  }

  handlePrimaryUserIdChange(e, newValue) {
    // alert(newValue.id)
    if (newValue) {
      let calendar_store_list_form = this.props.calendar_store_list_form
      calendar_store_list_form.user_id = newValue.id
      this.props.change_calendar_store_list_form(calendar_store_list_form);
      // alert(calendar_store_list_form.section_id)
    } else {
      let calendar_store_list_form = this.props.calendar_store_list_form
      calendar_store_list_form.user_id = 0

      this.props.change_calendar_store_list_form(calendar_store_list_form);
    }

    this.setState({
      refresh: !this.state.refresh    //再描画
  });

    this.props.get_calendar_list();

  }
  


  onChangeDate(value) {
    if(!value){
      return
    }

    let calendar_store_list_form = this.props.calendar_store_list_form
    calendar_store_list_form.start = moment(value).startOf('day').format('YYYY/MM/DD HH:mm:ss')
    calendar_store_list_form.end = moment(value).endOf('day').format('YYYY/MM/DD HH:mm:ss')
    this.props.change_calendar_store_list_form(calendar_store_list_form);

    if(calendar_store_list_form.start != 'Invalid date' && calendar_store_list_form.end != 'Invalid date'){
      this.props.get_calendar_list();
    }

    this.setState({
        refresh: !this.state.refresh    //再描画
    });
  }

  onChangeDateButton(value) {
    
    let calendar_store_list_form = this.props.calendar_store_list_form

    if(value == 0){
      calendar_store_list_form.start = moment().startOf('day').format('YYYY/MM/DD HH:mm:ss')
      calendar_store_list_form.end = moment().endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }else{
      calendar_store_list_form.start = moment(calendar_store_list_form.start).add(value, "days").startOf('day').format('YYYY/MM/DD HH:mm:ss')
      calendar_store_list_form.end = moment(calendar_store_list_form.end).add(value, "days").endOf('day').format('YYYY/MM/DD HH:mm:ss')
    }
    

    this.props.change_calendar_store_list_form(calendar_store_list_form);

    this.props.get_calendar_list();

    this.setState({
        refresh: !this.state.refresh    //再描画
    });
  }

  onChangeType(type){
    this.props.change_calendar_type(type)

    // this.props.change_calendar_store_list_form({start: '', end: '', section_id: 0, user_id: 0,})
    
    this.props.get_calendar_list()

    this.setState({
      change: !this.state.change
    });

  }

  render() {



    return (
      <Grid container style={{width:170}}>

<Grid xs={6} style={{ padding: 5}}><Button variant={this.props.calendar_type == 0 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<StoreIcon/>} style={{paddingLeft:0, paddingRight:0}} onClick={()=>this.onChangeType(0)} >今週</Button></Grid>
          <Grid xs={6} style={{ padding: 5}}><Button variant={this.props.calendar_type == 1 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<EventNoteIcon/>} style={{paddingLeft:0, paddingRight:0}} onClick={()=>this.onChangeType(1)} >全店</Button></Grid>
          <Grid xs={6} style={{ padding: 5}}><Button variant={this.props.calendar_type == 2 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<PermContactCalendarIcon/>} style={{paddingLeft:0, paddingRight:0}} onClick={()=>this.onChangeType(2)} >担当別</Button></Grid>
          <Grid xs={6} style={{ padding: 5}}><Button variant={this.props.calendar_type == 3 ? "outlined" : "contained"} color="primary" fullWidth startIcon={<Event/>} style={{paddingLeft:0, paddingRight:0}} onClick={()=>this.onChangeType(3)} >日別</Button></Grid>
        <Grid xs={12} style={{ padding: 5 }}>
            {this.props.shops.length > 0 && this.props.user_details ? <Autocomplete
              id="combo-box-demo"
              options={this.props.shops.sort((a, b) => {return a.dispOrder < b.dispOrder ? -1 : 1;}).filter(a => a.tenpoNo > 0 && a.tenpoNo <=6 || a.tenpoNo == 7 || a.tenpoNo == 10 || a.tenpoNo == 16 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 37 || a.tenpoNo == 47 || a.tenpoNo == 34 || a.tenpoNo == 53 || a.tenpoNo == 55 || a.tenpoNo == 21 || a.tenpoNo == 25 || a.tenpoNo == 45 || a.tenpoNo == 24).filter(a=> a.tenpoNo != -1)}
              getOptionLabel={(option) => option.tenpoName}
              defaultValue={this.props.shops.filter(a => a.tenpoNo > 0 && a.tenpoNo <=6 || a.tenpoNo == 7 || a.tenpoNo == 10 || a.tenpoNo == 16 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 37 || a.tenpoNo == 47 || a.tenpoNo == 34 || a.tenpoNo == 53 || a.tenpoNo == 55 || a.tenpoNo == 21 || a.tenpoNo == 25 || a.tenpoNo == 45 || a.tenpoNo == 24).filter(a => a.tenpoNo === this.props.user_details.amSectionId)[0]}
              value={this.props.shops.filter(a => a.tenpoNo > 0 && a.tenpoNo <=6 || a.tenpoNo == 7 || a.tenpoNo == 10 || a.tenpoNo == 16 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 37 || a.tenpoNo == 47 || a.tenpoNo == 34 || a.tenpoNo == 53 || a.tenpoNo == 55 || a.tenpoNo == 21 || a.tenpoNo == 25 || a.tenpoNo == 45 || a.tenpoNo == 24).filter(a => a.tenpoNo === this.props.calendar_store_list_form.section_id)[0]}
              style={{ width: "100%" }}
              onChange={this.handleChangeSectionId}
              size="small"
              renderInput={(params) => <TextField variant='standard'
                {...params} label="部署を選択" />}

            /> : ""}
        </Grid>
        <Grid xs={12} style={{ padding: 5 }}>
            {this.props.users_select.length > 0 && this.props.user_details ? <Autocomplete
              id="combo-box-demo"

              options={this.props.users.length === 0 && this.props.calendar_store_list_form.user_id ? [this.props.users.filter(a => String(a.id) === String(this.props.calendar_store_list_form.user_id))[0]]
                : this.props.users && this.props.calendar_store_list_form.section_id ? this.props.users.filter(a=>a.amSectionId == this.props.calendar_store_list_form.section_id[0]) : this.props.users}

              getOptionLabel={(option) => option.userNameAll ? option.userNameAll : ''}
              // name="ownerId"
              value={this.props.calendar_store_list_form.user_id ? this.props.users.filter(a => String(a.id) === String(this.props.calendar_store_list_form.user_id))[0] : {}}



              // options={this.props.calendar_store_list_form.section_id ? this.props.users.filter(a=>a.) : this.props.users}
              // getOptionLabel={(option) => option.label}
              // defaultValue={this.props.users_select.filter(a => a.value === this.props.user_details.id)[0]}
              // value={this.props.users_select.filter(a => a.value === this.props.calendar_store_list_form.user_id)[0]}
              style={{ width: "100%" }}
              onChange={this.handlePrimaryUserIdChange}
              size="small"
              renderInput={(params) => <TextField variant='standard'
                {...params} label="担当を選択" />}

            /> : ""}
          </Grid>
        <Grid xs={12} style={{ padding: 5 }}>
          <TextField
            fullWidth
            id="start"
            label="開始"
            type="date"
            name="start"
            size="small"
            // style={{marginLeft:20}}
            value={moment(this.props.calendar_store_list_form.start).format("YYYY-MM-DD")}
            InputLabelProps={{
                shrink: true,
            }}
            variant="standard"
            onChange={e => {
                this.onChangeDate(e.target.value)
              }
            }
            onKeyDown={e => {
              if (e.key === 'Enter') {
                  // エンターキー押下時の処理
                  this.onChangeDate(e.target.value)
              }
            }}
          />

        {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>

        <KeyboardDatePicker
            disableToolbar
            variant="inline"
            format="yyyy/MM/dd(EE)"
            margin="dense"
            id="date-picker-inline1"
            label="日付"
            // showTodayButton={true}
            style={{ float: "left", marginTop: "0" }}
            autoOk={true}
            InputLabelProps={{ shrink: true }}
            name="date"
            value={this.props.calendar_store_list_form.start}
            onChange={this.onChangeDate}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                  // エンターキー押下時の処理
                  this.onChangeDate(e.target.value)
              }
            }}
            KeyboardButtonProps={{
                'aria-label': 'change date',
            }}
            invalidDateMessage={"日付の形式が違います"}
        />

        </MuiPickersUtilsProvider> */}

      </Grid>
      <Grid xs={12} style={{ padding: 5 }}>
          <IconButton
            style={{padding:5}}
            onClick={()=>this.onChangeDateButton(-7)}
            size="large"><DoubleArrow fontSize="small" style={{transform:"rotate(180deg)"}}/></IconButton>

          <IconButton
            style={{padding:5}}
            onClick={()=>this.onChangeDateButton(-1)}
            size="large"><NavigateNext fontSize="medium" style={{transform:"rotate(180deg)"}}/></IconButton>
          <IconButton style={{padding:5}} onClick={()=>this.onChangeDateButton(0)} size="large"><Today fontSize="small"/></IconButton>
          <IconButton style={{padding:5}} onClick={()=>this.onChangeDateButton(1)} size="large"><NavigateNext fontSize="medium"/></IconButton>

          <IconButton style={{padding:5}} onClick={()=>this.onChangeDateButton(7)} size="large"><DoubleArrow fontSize="small"/></IconButton>
      </Grid>

      <Grid xs={12} style={{ padding: 5 }}>
        <TodayList calendar_list_form={this.props.calendar_store_list_form} />
      </Grid>

      </Grid >
    );
  }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users: state.masterReducer.users,
    users_select: state.masterReducer.users_select,
    section_select: state.masterReducer.section_select,
    shops: state.masterReducer.shops,

    calendar_list: state.calendar.calendar_list,
    calendar_store_list_form: state.calendar.calendar_store_list_form,
    calendar_type: state.calendar.calendar_type,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    get_calendar_list(state) {
      dispatch(Calendarsaga.get_calendar_list(state))
    },
    change_calendar_store_list_form(state) {
      dispatch(Calendarsaga.change_calendar_store_list_form(state))
    },
    change_calendar_type(state) {
      dispatch(Calendarsaga.change_calendar_type(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);

// ResponseListSearch = reduxForm({
//   form: 'formset', // a unique identifier for this form
//   onSubmit: submit
//   //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseListSearch)
