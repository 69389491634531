const authentication = require('../../../react-azure-adb2c2').default;






export const list = (value: any) => {


    // let url = `https://localhost:44341/api/MailTemplates`;
    // const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/`
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/mail/template/categories/`
    // const url = `http://localhost:5000/api/v1/mail/template/categories`

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(search),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const auto_send_list = (value: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/mail/template/categories/autosend`
    // const url = `http://localhost:5000/api/v1/mail/template/categories/autosend`

    const token = authentication.getAccessToken();
    // alert(url)
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const auto_send_insert = (value: any) => {
    console.log(value)

    // let url = `http://localhost:5000/api/v1/mail/template/categories/autosendinsert`
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/mail/template/categories/autosendinsert`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const auto_send_update = (value: any) => {
    console.log(value)

    // let url = `http://localhost:5000/api/v1/mail/template/categories/autosendupdate`
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/mail/template/categories/autosendupdate`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const auto_send_delete = (value: any) => {
    console.log(value)

    // let url = `http://localhost:5000/api/v1/mail/template/categories/autosenddelete`
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/mail/template/categories/autosenddelete`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const auto_send_update_sort = (value: any) => {
    console.log(value)

    // let url = `http://localhost:5000/api/v1/mail/template/categories/autosendupdatesort`
    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/mail/template/categories/autosendupdatesort`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};







export const update = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates2/`+value.id;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplates/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};


export const insert = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplates/`

    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const deletetemplate = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`+value;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const insertcategory = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/`
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'post',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};
export const updatecategory = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`+value.id;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

export const deletecategory = (value: any) => {
    console.log(value)

    // let url = `https://localhost:44341/api/MailTemplates/`+value;
    const url = process.env.REACT_APP_API_BASE_URL + `/api/MailTemplateCategories/` + value.id
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(value),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

};

// export const get_file = (Id: number) => {
//     const token = authentication.getAccessToken();

//     // let url = "https://localhost:44341/api/c/MailFileDownloadApi/"+Id;
//     let url = "http://homestationapi.azurewebsites.net/api/c/MailFileDownloadApi/"+Id;
//     return   fetch(url, { method: 'GET', headers: new Headers([["Authorization", 'Bearer ' + token]])}).then(response => response.blob())
//     .then(payload => ({ payload }))
//             .catch(error => ({ error }));
//     // .then(blob => {

//     //     window.URL.createObjectURL(blob)
//     //     // alert(window.URL.createObjectURL(blob))
//     //     // let anchor = document.createElement("a");
//     //     // anchor.href = window.URL.createObjectURL(blob);
//     //     // anchor.click();
//     //    })



//     //     return  fetch(url, {
//     //     method: 'get',
//     //     // body: JSON.stringify(search),

//     //     headers: { 'Authorization': 'Bearer ' + token},

//     // }).then(response => response.blob())
//     // .then(blob => ({blob}))
//     //     .catch(error => ({ error }));
// };












// export const detail = (Id: number) => {

//             const url = `https://homestationapi.azurewebsites.net/api/MailAllReceives/` + Id;

//             const token = authentication.getAccessToken();

//             return fetch(url, {
//                 headers: { 'Authorization': 'Bearer ' + token }
//             }).then(res => res.json())
//                 .then(payload => ({ payload }))
//                 .catch(error => ({ error }));


//     }



