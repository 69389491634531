
const authentication = require('../../../react-azure-adb2c2').default;
export const list = () => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;

    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/tm/parkingarea/YoyakuParking`;
    // const url = `http://localhost:5000/v1/tm/parkingarea/YoyakuParking`;
    // const url = process.env.REACT_APP_API_PARKING_URL + `/api/v1/parking/YoyakuParking`
    // const url = `http://localhost:5000/v1/tm/zip/address/` + values;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const reloadlist = (values: any)=> {
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/tm/parkingarea/ReloadYoyakuParking`;
    // const url = `http://localhost:5000/v1/tm/parkingarea/ReloadYoyakuParking`;
    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
} 
export const addresponse = (values: any)=> {
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/tm/parkingarea/addresponse`;
    // const url = `http://localhost:5000/v1/tm/parkingarea/addresponse`;
    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
} 
export const parking_info = (values: any)=> {
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/tm/parkingarea/parkinginfo`;
    // const url = `http://localhost:5000/v1/tm/parkingarea/parkinginfo`;
    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
} 
export const changedata = (values: any)=> {
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/tm/parkingarea/updatedata`;
    // const url = `http://localhost:5000/v1/tm/parkingarea/updatedata`;
    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
} 
export const addyoyaku = (values: any)=> {
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/tm/parkingarea/addyoyaku`;
    // const url = `http://localhost:5000/v1/tm/parkingarea/addyoyaku`;
    const token = authentication.getAccessToken();
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));
} 
