import React, { Component } from 'react';
import * as Responses from './Saga/ResponseList';
import { connect } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import styled from 'styled-components';
const authentication = require('../../react-azure-adb2c2').default;


const Balloon = styled.div`
    position:relative;
    padding:0px;
    margin: 10px 0;
    margin-top:15px;
`;

const Chatting = styled.div`
    width: 100%;
    text-align: right;
`;

const List = styled.ul`
    overflow-x: auto;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
        
    padding-left:0;
    margin-bottom:0px;
`;

const ListItem = styled.li`
        display: inline-block;
        width: 50%;
        height:300px;
        margin-left: 5px;
        white-space:normal;
        vertical-align: top;
        margin-bottom: 0px;
        
        background-color:white;
        border-radius: 12px;
        width:200px;
`;


const Says = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 60px;
    padding: 0;
    max-width: 250px;
    height:250px;
    border-radius: 12px;
    margin-bottom: 2px;
    text-align: left;
`;

const DivItem = styled.div`
        background-color:white;
        border-radius: 12px;
        height:230px;
        margin:0;
`;

const Title = styled.div`
        padding:10px;
    text-align:left;
`;

const Detail = styled.div`
        padding:10px;padding-top:0px;font-size:10px;height:55px;
    text-align:left;
`;

const Menu1 = styled.div`
        padding-top:25px;
        border-top:1px solid #dcdcdc;
        font-weight:bold;
        text-align:center;
`;

const Menu2 = styled.div`
        padding-top:5px;padding-bottom:5px;font-weight:bold;text-align:center;
`;


const Time = styled.div`
    width:100%;
    position: relative; 
    margin: 5 5 0 5px;
    bottom: 0;
    font-size:10px;
    color:#FFF;
    text-align:right;
`;

const DeleteButton = styled.div`
    display: inline-block;
    position: relative; 
    margin: 0 0 0 5px;
    position: absolute;
    top: 0;

`;



class LineTextComponent extends Component {
    constructor(props) {
        super(props);
        const dt = new Date(this.props.msg.date)
        this.state = {
            mode: '1',
            messages: [],

            date_time: (dt.getMonth() + 1) + "/" + dt.getDate() + " " + dt.getHours() + ":" + dt.getMinutes(),
            lists: this.props.msg.message_text.split(','),
        };
    }

    componentWillMount() {

        const token = authentication.getAccessToken();
        const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/line/template/docs/download/` + this.props.msg.file_id
        // const url = `http://localhost:5000/v1/rent/line/template/docs/download/` + this.props.msg.file_id

        var bloburl
        fetch(url, {
            method: 'GET',
            headers: { 'Authorization': 'Bearer ' + token }
        }).then(response => response.blob()).then(images => {

            // Then create a local URL for that image and print it 
            bloburl = URL.createObjectURL(images)
            // alert(bloburl)
            console.log(bloburl)
            this.setState({
                bloburl: bloburl
            })

        });

    }

    onDeleteMessage(no){

        this.props.delete_rent_line_template_detail(no)

        this.setState({
            refresh: !this.state.refresh
          });
    }

    open_window(url) {
        window.open(url, '_blank', 'noopener')
      }

    render() {
        return (
            <div>
            <Balloon className="col-xs-12">
                <Chatting>
                    {this.props.line_template_edit_open ? <DeleteButton>
                        <IconButton onClick={()=>this.onDeleteMessage(this.props.msg.no)} size="large"><CloseIcon /></IconButton>
                    </DeleteButton>:""}
                    <Says>
                        <DivItem>

                            {/* <ListItem> */}
                            <div><img className="l_img" border="0" src={this.state.bloburl}/></div>
                            <Menu1 className="col-xs-12"><Button  variant="outlined" onClick={()=>this.open_window(this.props.msg.url)}>詳細ページ</Button></Menu1>
                            {/* </ListItem> */}
                        </DivItem>
                    </Says>
                </Chatting>
                {/* <Time>{moment.utc(this.props.msg.date).format('MM/DD HH:mm')}</Time> */}
            </Balloon>
            </div>
        );
    }
}


//コンテナ
const mapStateToProps = state => {
    return {
        rent_line_templates_list: state.responselist.rent_line_templates_list,
        rent_line_template_detail: state.responselist.rent_line_template_detail,
        rent_line_template: state.responselist.rent_line_template,
        line_template_edit_open: state.responselist.line_template_edit_open,

    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_rent_line_template_detail(state) {
            dispatch(Responses.change_rent_line_template_detail(state))
        },
        delete_rent_line_template_detail(state) {
            dispatch(Responses.delete_rent_line_template_detail(state))
        },
        change_rent_line_template(state) {
            dispatch(Responses.change_rent_line_template(state))
        },
        get_rent_line_template_detail(state) {
            dispatch(Responses.get_rent_line_template_detail(state))
        },
    };
}


export default connect(mapStateToProps, mapDispatchToProps)(LineTextComponent);
