import React, { Component } from 'react';

import { connect } from 'react-redux';

import MaterialSelect from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import MaterialUiSelect from '@mui/material/Select';
// import "Components/RoomList/node_modules/Components/RoomList_/node_modules/react-datepicker/dist/react-datepicker.css";
import ContactsComponent from '../CustomerContacts/ContactsComponent';
import '../../Css/Table.css';
import { Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import * as ResponseList from './Saga/ResponseList';

import * as ResponseDetail from './../ResponseDetail/Saga/Responses';
import ResponseFollowSheet from '../ResponseFollowSheet/Index';
import * as ResponseFollowSheetSaga from './../ResponseFollowSheet/Saga/ResponseFollowSheet';
import * as Responses from '../ResponseDetail/Saga/Responses';



import TextField from '@mui/material/TextField';
// import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MuiButton from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
var moment = require('moment-timezone');

let timer



function SimpleDialog(props) {

  const { section_id, open, change_response_follow_sheet_open } = props;

  const handleClose = () => {
    change_response_follow_sheet_open(false)
    // onClose(selectedValue)
  };

  const handleListItemClick = (value) => {
    // onClose(value);
  };

  return (
    <Dialog
      maxWidth="xl"
      // classes={{
      //   paper: classes.dialog
      // }}
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">追客状況
        <IconButton
          aria-label="close"
          style={{ float: "right" }}
          onClick={handleClose}
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <ResponseFollowSheet onClose={handleClose} section_id={section_id} />

    </Dialog>
  );
}


class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,

    };

    let formData = this.props.formData
    formData = {
      email: '',
      password: '',
      tatemonoName: '',
      applicationDate: null,
      contractionDateStart: null,
      contractionDateEnd: null,
      condition: "1",
      primaryUserId: 0,
      name: null,
      sectionId: this.props.user_details.amSectionId > 0 ? this.props.user_details.amSectionId : 0,
    }
    this.props.change_formData(formData);
    this.toggle = this.toggle.bind(this);
    this.togglecalender = this.togglecalender.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    this.handlePrimaryUserIdChange = this.handlePrimaryUserIdChange.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.sort = this.sort.bind(this);
    this.intend = this.intend.bind(this);

    this.open_response_add_set = this.open_response_add_set.bind(this);
    this.change_primary_user_id = this.change_primary_user_id.bind(this);
    this.onClear = this.onClear.bind(this);
    //  this.handlesectionIdChange = this.handlesectionIdChange.bind(this);
    this.props.get_intend_list();
    // this.props.initialize({sectionId:this.props.masterReducer.user_details.amSectionId>0?this.props.masterReducer.user_details.amSectionId:0});//フォームに初期値を反映させる

  }

  handleSubmit(e) {
    e.preventDefault();
    this.props.onSubmit(this.props.formData)

  }

  handleChangeSectionId(sectionId) {
    // console.log(sectionId)

    if (sectionId) {
      let formData = this.props.formData
      formData.sectionId = sectionId.value
      this.props.change_formData(formData);
    } else {
      let formData = this.props.formData
      formData.sectionId = 0
      this.props.change_formData(formData);
    }
  }

  intend(e) {
    let formData = this.props.formData
    formData.intend = e.target.value
    this.props.change_formData(formData);
  }

  // handleChange = (event) => {
  //   console.log(event)

  //   let formData = this.props.formData
  //   formData[event.target.name] = event.target.value;
  //   if (event.target.name === "condition") {
  //     formData.contractionDateStart = new Date();
  //     formData.contractionDateEnd = null;
  //   }
  //   this.props.change_formData(formData);
  //   if (event.target.name === "condition") {

  //     this.props.search_response_list2()
  //   }
  //   // this.setState({ formData });
  // }
  handleChange = (event) => {
    console.log(event)
    // alert(event.target.name)
    // alert(event.target.value)
    let formData = this.props.formData
    formData[event.target.name] = event.target.value;
    if (event.target.name === "condition") {
      formData.contractionDateStart = new Date();
      formData.contractionDateEnd = null;
    }


    this.props.change_formData(formData);

    if (event.target.name === "section_id") {
      this.props.responseList()
    } else if (event.target.name === "condition" && Number(event.target.value) === 5) {

      this.props.responseList()

    } else if (event.target.name === "name") {
      clearTimeout(timer);
      timer = setTimeout(function () {

        this.props.search_response_list2()
      }.bind(this), 800);

    } else {
      this.props.search_response_list2()

    }
    this.setState({ reflash: !this.state.reflash });
  }


  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")
    let formData = this.props.formData

    formData.section_id = this.props.user_details.amSectionId

    this.props.change_formData(formData);
    this.props.responseList()
  }
  toggle() {
    //this.setState(prevState => ({
    //    modal: !prevState.modal
    //}));
    this.props.customerNewDispSet(0)
    // let matching = {}


    // this.props.change_matching(matching)
    this.props.history.push('/CustomerNew');

  }


  togglecalender() {
    this.setState(prevState => ({
      modalcalender: !prevState.modalcalender
    }));
  }
  saveSelectValue = (e, id) => {
    let data = {}
    data.id = id;
    data.value = e.target.value

    this.props.saveSelectValue(data)
  }

  // handleSectionIdChange(sectionId) {
  //   alert()
  //   let formData =   this.state.formData
  //   formData.sectionId=sectionId
  //     this.setState(formData);

  //   }
  handlePrimaryUserIdChange(primaryUserId) {
    if (primaryUserId) {
      let formData = this.props.formData
      formData.primaryUserId = primaryUserId.value
      this.setState(formData);
    } else {
      let formData = this.props.formData
      formData.primaryUserId = 0
      this.props.change_formData(formData);
    }
  };
  handleContractionDateStartChange = date => {
    let formData = this.props.formData
    formData.contractionDateStart = date
    this.props.change_formData(formData);

  };

  handleContractionDateEndChange = date => {
    let formData = this.props.formData
    formData.contractionDateEnd = date
    this.props.change_formData(formData);

  };

  sort(sort) {
    let formData = this.props.formData
    formData.sort = Number(sort.target.value)
    this.props.change_formData(formData);
    this.props.search_response_list2()
    this.setState({ reflash: !this.state.reflash });

  }

  open_response_add_set() {
    this.props.change_line_user_id({ line_user_id: 0, line_account_section_id: 0 })
    this.props.open_response_add({})
    this.props.change_response_add_select_mail_id_list([])
  }


  change_primary_user_id = (event, value) => {

    let formData = this.props.formData
    formData.primary_user_id = value ? value.value : 0;

    this.props.change_formData(formData);

    this.props.search_response_list2()
    this.setState({ reflash: !this.state.reflash });
  }

  onClear() {

    let formData = this.props.formData

    if (!(formData.section_id === this.props.user_details.amSectionId)) {
      formData.section_id = this.props.user_details.amSectionId
      this.props.change_formData(formData);
      this.props.responseList()
    }

    formData = {
      sort: 1,
      intend: 0,
      // email: '',
      tatemonoName: '',
      applicationDate: '',
      contractionDateStart: '',
      contractionDateEnd: '',
      condition: "1",
      response_user_id: 0,
      primary_user_id: 0,
      name: '',

      // sectionId: 10,
      section_id: this.props.user_details.amSectionId,
      building_name: '',
      response_date_start: '',
      response_date_end: '',
      response_condition: 0,
      tel1: '',
      moving_time: 0,
      way: 0,
      media: 0,
      agent: 0,
      email: '',

      from_cost: 0,
      to_cost: 0,
      from_madori: 0,
      to_madori: 0,
      area: 0,
      requests: 0,
      contract_form: false,
      student: false,
      end_response: 0,
      reserved: false,

    }


    this.props.change_formData(formData);
    this.props.search_response_list2()
    this.setState({ reflash: !this.state.reflash });
  }
  render() {

    const { handleSubmit } = this.props;


    return (
      <div>

        <table>

          <tbody>

            <tr >

              <td colSpan="2" >
                <div className="form-inline">
                  {/* <Field
                                  name="name"
                                  component="input"
                                  type="text"
                                  placeholder="お客様名"
                                          className="form-control"
                                          size="20"
                                  /> */}


                  <TextField fullWidth
                    id="name"
                    label="お客様名"
                    variant="outlined"
                    onChange={this.handleChange}
                    // onChange={this.handleChange}
                    name="name"
                    value={this.props.formData.name}
                    style={{ float: "left", width: "150px", marginTop: 0, paddingRight: "5px" }}
                  />

                  {/* <TextField
                      id="outlined-basic"
                      //   className={classes.textField}
                      label="お客様名"
                      margin="normal"

                      onChange={this.handleChange}
                      name="name"
                      value={this.props.responselist.formData.name}
                      style={{ float: "left", width: "150px", marginTop: 0, paddingRight: "5px" }}

                    /> */}


                  <RadioGroup aria-label="状況" name="condition" value={this.props.formData.condition} onChange={this.handleChange} row>
                    <FormControlLabel value="1" control={<Radio />} label="反響" />
                    <FormControlLabel value="6" control={<Radio />} label="自動追客中" />
                    <FormControlLabel value="2" control={<Radio />} label="初回来店" />
                    <FormControlLabel value="3" control={<Radio />} label="再来店" />
                    {/* <FormControlLabel value="4" control={<Radio />} label="契約済" /> */}

                    <FormControlLabel value="5" control={<Radio />} label="すべて" />
                  </RadioGroup>


                  <div className="searchform">



                    {/* <Select
                        name="sectionId"
                        options={this.props.masterReducer.section_select}
                        value={this.props.responselist.formData.sectionId}
                        onChange={(a) => this.handleChangeSectionId(a)}
                        // component={RenderSelectInput}
                        //  onChange={(a)=>this.setState({formData[sectionId]:a)}
                        //  onBlur={(a) => input.onBlur(a?a.value:0)}

                        placeholder="店舗を選択"
                      /> */}


                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="sectionId-label">店舗</InputLabel>
                      <MaterialUiSelect
                        labelId="sectionId-label"
                        id="section_id"
                        name="section_id"
                        value={this.props.formData.section_id}
                        onChange={this.handleChange}
                        // disabled={this.props.formData.id}
                        label="section_id"
                      >
                        <MenuItem key={-1} value={0}>全店</MenuItem>
                        {this.props.section_select.filter(a => a.value > 0).map(function (item, i) {
                          return (
                            <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                          );

                        })}
                      </MaterialUiSelect>
                    </FormControl>







                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td >
                <div className="form-inline ">
                  <div className="searchform">
                    {/* <Field 
              value={this.state.formData.primaryUserId}
                   name="primaryUserId"
                   onChange={this.handlePrimaryUserIdChange}
                   options={this.props.masterReducer.users_select}
                        component={RenderSelectUsers} /> */}

                    {/* <Select
                        name="sectionId"
                        options={this.props.masterReducer.users_select}
                        value={this.props.responselist.formData.primaryUserId}
                        onChange={(a) => this.handlePrimaryUserIdChange(a)}
                        // component={RenderSelectInput}
                        //  onChange={(a)=>this.setState({formData[sectionId]:a)}
                        //  onBlur={(a) => input.onBlur(a?a.value:0)}

                        placeholder="追客担当を選択"
                      /> */}
                    <Autocomplete
                      id="combo-box-demo"

                      options={this.props.users_select.length === 0 && this.props.formData.primary_user_id ? [this.props.users_select.filter(a => a.value === this.props.formData.primary_user_id)[0]]
                        : this.props.users_select}
                      getOptionLabel={(option) => option.label ? option.label : ''}

                      style={{ width: "100%" }}
                      name="userId"
                      loading={this.props.users_loading}

                      value={this.props.formData.primary_user_id ? this.props.users_select.filter(a => a.value === this.props.formData.primary_user_id)[0] : {}}

                      onChange={this.change_primary_user_id}
                      renderInput={(params) => <TextField
                        {...params}
                        variant="outlined"
                        name="primary_user_id"

                        InputProps={{
                          ...params.InputProps,
                          endAdornment: (
                            <React.Fragment>

                              {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                              {params.InputProps.endAdornment}
                            </React.Fragment>
                          ),
                        }}
                        // onChange={this.change_primary_user_id}
                        label="追客担当" />}

                    />

                  </div>












                  {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}> */}
                    {/* <Grid
      justify="space-between" // Add it here :)
      item
      xs={6}
    > */}
                    <div style={{ float: "left", width: "500px", display: this.props.formData.condition === "4" ? "" : "none" }}>
                      <TextField
                          id="contractionDateStart"
                          label="反響日"
                          type="date"
                          name="contractionDateStart"
                          size="small"
                          variant='outlined'
                          onChange={this.handleChange}
                          value={this.props.formData.contractionDateStart === null ? moment().format("YYYY-MM-DD") : moment(this.props.formData.contractionDateStart).format("YYYY-MM-DD")}
                          InputLabelProps={{
                              shrink: true,
                          }}
                      />
                      {/* <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline1"
                        label="反響日"
                        style={{ float: "left", marginTop: "0" }}
                        name="contractionDateStart"
                        value={this.props.formData.contractionDateStart === null ? new Date() : this.props.formData.contractionDateStart}
                        onChange={this.handleContractionDateStartChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        invalidDateMessage={"日付の形式が違います"}
                      /> */}
                      {/* </Grid> */}
                      {/* <Grid
      justify="space-between" // Add it here :)
      item
      xs={6}
    > */}
                      <div style={{ float: "left", paddingTop: "15px" }}>～</div>

                        <TextField
                            id="contractionDateEnd"
                            label="反響日"
                            type="date"
                            name="contractionDateEnd"
                            size="small"
                            variant='outlined'
                            onChange={this.handleChange}
                            value={moment(this.props.formData.contractionDateEnd).format("YYYY-MM-DD")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                      {/* <KeyboardDatePicker

                        disableToolbar
                        variant="inline"
                        format="yyyy/MM/dd"
                        margin="normal"
                        id="date-picker-inline2"
                        label="反響日"
                        style={{ marginLeft: "20px", marginTop: "0" }}
                        name="contractionDateEnd"
                        value={this.props.formData.contractionDateEnd}
                        onChange={this.handleContractionDateEndChange}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        invalidDateMessage={"日付の形式が違います"}
                      /> */}
                    </div>
                    {/* </Grid> */}
                  {/* </MuiPickersUtilsProvider> */}
                  {/* <label key="1" style={{padding:5}}>
                                      <Field name="status" component="input" type="radio" checked={this.state.status === "1" || !this.state.status} value="1" onChange={()=>this.setState({"status" : "1"})} />
                                      対応中</label>
                                  <label key="2" style={{padding:5}}>
                                      <Field name="status" component="input" type="radio" checked={this.state.status === "3"} value="3" onChange={()=>this.setState({"status" : "3"})} />
                                      自動追客中</label> */}
                  {/* <label key="3" style={{padding:5}}>
                                      <Field name="status" component="input" type="radio" checked={this.state.status === "3"} value="3" onChange={()=>this.setState({"status" : "3"})} />
                                      再来店</label> */}



                  <FormControl style={{ marginLeft: "10px", width: "100px" }}>
                    <InputLabel id="demo-simple-select-label">並び順</InputLabel>
                    <MaterialSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.props.formData.sort}
                      name="sort"
                      onChange={this.handleChange}
                    >
                      <MenuItem value={1}>最新対応順</MenuItem>
                      <MenuItem value={2}>反響日順</MenuItem>
                      <MenuItem value={3}>ランク順</MenuItem>
                    </MaterialSelect>
                  </FormControl>

                  <FormControl style={{ marginLeft: "10px", width: "100px" }}>
                    <InputLabel id="demo-simple-select-label">予定</InputLabel>
                    <MaterialSelect
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.props.formData.intend}
                      // value={age}
                      name="intend"
                      onChange={this.handleChange}
                    >
                      <MenuItem value={0}>-</MenuItem>
                      <MenuItem value={1}>来店予定</MenuItem>
                      <MenuItem value={2}>追客予定</MenuItem>
                    </MaterialSelect>
                  </FormControl>


                  {this.props.form_count > 0 ?
                    <Chip label={"検索条件:" + this.props.form_count + "件指定中"} style={{ margin: 10, fontSize: 14 }} variant="outlined" color="secondary" /> : ""}

                  <MuiButton

                    style={{ marginLeft: 5 }}
                    onClick={this.onClear}
                    //   this.props.handletoggleaddresponse}
                    variant="contained"
                  >条件クリア</MuiButton>


                  <button
                    type="button"
                    onClick={this.props.responseList}
                    className="btn btn-primary ml-3 ml-auto"
                  >更新</button>



                  <Chip label={this.props.response_count + "件"} style={{ margin: 10, fontSize: 14 }} variant="outlined" />


                  <Button
                    onClick={this.open_response_add_set}
                    //   this.props.handletoggleaddresponse}
                    className="btn btn-danger ml-3 ml-auto"
                  >追加</Button>
                </div>
              </td>
              <td style={{ 'paddingRight': 40 }}>

                <MuiButton

                  style={{ marginLeft: 5 }}
                  onClick={() => this.props.change_response_follow_sheet_open(true)}
                  //   this.props.handletoggleaddresponse}
                  variant="contained"
                >追客状況</MuiButton>
                <button className="btn btn-sm btn-primary float-right ml-1" type="button" onClick={this.togglecalender.bind(this)}>追客カレンダー</button>

              </td>
            </tr>
          </tbody>
        </table>



        <Modal isOpen={this.state.modalcalender} toggle={this.togglecalender} size="lg" fade={false}>

          <ModalHeader toggle={this.togglemail}>カレンダー</ModalHeader>
          <ModalBody>
            <ContactsComponent rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} customer_contacts={this.props.intend_list} customer_contacts_calendar={this.props.intend_list_calendar} />

          </ModalBody>
        </Modal>



        <SimpleDialog section_id={this.props.formData.section_id} open={this.props.response_follow_sheet_open} change_response_follow_sheet_open={this.props.change_response_follow_sheet_open} />



      </div >
    );
  }
}




//<Modal isOpen={this.state.modal} toggle={this.toggle} size="lg" fade={false}>
//    <ResponseInsertComponent
//        toggle={this.toggle} onSubmit={this.props.customerUpdate}
//    />
//</Modal>


// ResponseListSearch = connect(
//   ({ masterReducer, responselist }) => ({ masterReducer, responselist }),
//   // state => ({
//   //   mapsearch: state.mapsearchReducer,

//   // }),
//   {
//     customerNewDispSet,
//     change_customeredit,
//     // change_matching,
//     get_intend_list,
//     responseList,
//     change_response_add_disp,
//     change_formData,
//     search_response_list,
//     open_response_add,
//     change_line_user_id
//   }

// )(ResponseListSearch)



// ResponseListSearch = reduxForm({
//   form: 'formset', // a unique identifier for this form
//   onSubmit: submit
//   //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseListSearch)

// export default withRouter(ResponseListSearch)



const mapStateToProps = state => {
  return {
    rentMMedeas: state.masterReducer.rentMMedeas,
    rentAgents: state.masterReducer.rentAgents,
    users_select: state.masterReducer.users_select,
    users_details: state.masterReducer.users_details,
    section_select: state.masterReducer.section_select,
    formData: state.responselist.formData,
    response_count: state.responselist.response_count,
    form_count: state.responselist.form_count,
    intend_list_calendar: state.responselist.intend_list_calendar,
    response_follow_sheet_open: state.ResponseFollowSheet.response_follow_sheet_open,
    //customerdetail: state.customerReducer.detail,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    //firebasedata: state.firebaseReducer.firebasedata,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_formData(state) {
      dispatch(ResponseList.change_formData(state))
    },

    change_line_user_id(state) {
      dispatch(ResponseDetail.change_line_user_id(state))
    },
    open_response_add(state) {
      dispatch(ResponseDetail.open_response_add(state))
    },
    search_response_list2(state) {
      dispatch(ResponseList.search_response_list2(state))
    },

    responseList(state) {
      dispatch(ResponseList.responseList(state))
    },
    get_intend_list(state) {
      dispatch(ResponseList.get_intend_list(state))
    },
    change_form_count(state) {
      dispatch(ResponseList.change_form_count(state))
    },
    change_response_follow_sheet_open(state) {
      dispatch(ResponseFollowSheetSaga.change_response_follow_sheet_open(state))
    },
    change_response_add_select_mail_id_list(state) {
      dispatch(Responses.change_response_add_select_mail_id_list(state))
    },

    //loadTodos(state) {
    //    dispatch(loadTodos(state))
    //}
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);