import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as MailTemplate from './Saga/MailTemplate';
// import * as Mail from '../Mail/Saga/Mail';
// import * as Responses from '../../Store/Responses';
// import './Css/FileGrid.css';
import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment';
import Button from '@mui/material/Button';
import './Css/Grid.css';
// import ReactDataGrid from "react-data-grid"
const ReactDataGrid = require('react-data-grid');



// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {
    // rooms: any,
    // rent_response_id: number,
    mail_templates: any,
    response_files_loading: boolean,
    get_mail_template_details: any,
    mail_templates_search_list: any,
    // download_response_file: any,
    // file_remarks_edit: any,
    // delete_response_file: any,
    // change_response_file_update_disp: any,
    // get_rooms: any,
    // toggle: any,
    // contacts_loading: number,
    // selectsheet: any,
    // // mailadd: any,
    // add_mail_room: any,
    // togglesearch: any,
    // introductions: any,
    // introductions_loading: number,
    // onMypage: any,
    // delete_introduction: any,
    // togglefileup: any,
    // estimate: any,
}


interface State {
    columns: any,
    // modaldelete: boolean,
    // rent_introduction_id: number,

    // state types
}
// const MailLinkFormatter = (payload:any) => {

//     return <a role="button"  className="btn btn-primary" target="_blank" onClick={this.mailadd} >追加</a>;

// };
const DateFormatter = (payload: any) => {
    if (payload.value) {
        return moment(new Date(payload.value).setTime(new Date(payload.value).getTime() - 1000 * 60 * 60 * 9)).format("YYYY/MM/DD hh:mm:ss");
    } else {
        return "";
    }
};

const sectionFormatter = (payload: any) => {
    if (payload.value) {
        return payload.value
    } else {
        return "共通";
    }
};



const RowRenderer = ({ renderBaseRow, ...props }: any) => {
    //console.log(props)
    //const color = props.idx % 2 ? "green" : "blue";


    let backgroundColor = props.row.kuushitsuJoukyouNo === 4 ? " row-highlight-gray" : "";
    backgroundColor = props.row.status === 4 ? " row-highlight-gray" : backgroundColor;
    //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
    return <span className={backgroundColor}>{renderBaseRow(props)}</span>;



};



class IntroductionsGridComponent extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);


        // this.onRowClick = this.onRowClick.bind(this);
        // this.mailadd = this.mailadd.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        // this.StatusFormatter = this.StatusFormatter.bind(this);
        // this.RoomFormatter = this.RoomFormatter.bind(this);
        this.toggledelete = this.toggledelete.bind(this);
        // this.download = this.download.bind(this);
        // this.delete_introductions = this.delete_introductions.bind(this);

        // this.NameFormatter = this.NameFormatter.bind(this);



        this.state = {
            // modaldelete: false,
            // rent_introduction_id: 0,

            columns: [

                { key: 'edit', name: '編集', width: 70, resizable: true },
                { key: 'template_name', name: 'テンプレート名', width: 150, resizable: true },
                { key: 'category_name', name: 'カテゴリ', width: 100, resizable: true },
                { key: 'section_name', name: '店舗', width: 100, resizable: true, formatter: sectionFormatter },
                { key: 'user_name', name: '担当', width: 100, resizable: true },

                { key: 'updated_at', name: '更新日', width: 200, resizable: true, formatter: DateFormatter },
                { key: 'id', name: 'id', width: 60, resizable: true },
                // { key: 'statusset', name: 'ステータス', width: 90, resizable: true, formatter: this.StatusFormatter },
                // { key: 'tatemonoName', name: '物件名', width: 180, resizable: true, formatter: this.NameFormatter },
                // { key: 'roomName', name: '部屋名', width: 70, resizable: true, formatter: this.RoomFormatter },
                // { key: 'chinRyou', name: '賃料', width: 120, resizable: true, formatter: ChinRyouFormatter },
                // { key: 'chinRyouK', name: '契約時', width: 100, resizable: true, formatter: KeiyakuFormatter },
                // { key: 'delete', name: '削除', width: 60, resizable: true },



                // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
            ]
        }



    }


    // StatusFormatter = (payload: any) => {
    //     let status;
    //     if (payload.row.status === 1) {
    //         status = <div className="badge badge-success" style={{ width: "70px", margin: "2px" }}>初回問合</div>
    //     } else if (payload.row.status === 3) {
    //         status = <div className="badge badge-info" style={{ width: "70px", margin: "2px", fontSize: "12px" }}>自動送信</div>
    //     } else if (payload.row.status === 4) {
    //         status = <div className="badge badge-secondary" style={{ width: "70px", margin: "2px", fontSize: "10px" }}>自動送信除外</div>
    //     }
    //     let publish;
    //     if (payload.row.onMypage === 1) {
    //         publish = <button type="button" className="btn btn-sm btn-danger" style={{ width: "70px", margin: "2px" }} onClick={() => this.props.onMypage(payload.row)}>公開中</button>

    //     } else {
    //         publish = <button type="button" className="btn btn-sm btn-success" style={{ width: "70px", margin: "2px" }} onClick={() => this.props.onMypage(payload.row)}>公開する</button>
    //     }
    //     return <span><div>{status}</div><div>{publish}</div></span>

    // };

    // RoomFormatter = (payload: any) => {
    //     const kanriKubunName = payload.row.kanriKubunNo === 6 ? payload.row.kanriCorpName : payload.row.kanriKubunName
    //     if (payload.row.status === 5) {
    //         return <div><div>{payload.row.roomName}</div><button type="button" className="btn btn-sm btn-primary" style={{ width: "50px", margin: "2px" }} onClick={() => this.props.togglefileup(payload.row.id)}>詳細</button><div>{payload.row.kuushitsuJoukyouName}</div><div>{kanriKubunName}</div></div>

    //     } else {
    //         return <div><div>{payload.row.roomName}</div><button type="button" className="btn btn-sm btn-primary" style={{ width: "50px", margin: "2px" }} onClick={() => this.props.selectsheet(payload.row)}>詳細</button><div>{payload.row.kuushitsuJoukyouName}</div><div>{kanriKubunName}</div></div>
    //     }

    // };

    componentWillMount() {

        // this.props.get_rooms()
        // this.props.customerList()

    }
    getSelectedCell(event: any) {
        alert()
    }
    edit(event: any) {
        alert(event)
    }
    // onRowClick = (rowIdx:any, row:any) => { 

    //     if(row)
    //     this.props.toggle(row);




    // }

    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.response_files_loading === true ? <LoadingComponent /> : <div style={{ textAlign: "center", padding: "100px" }} > <h3>{message}</h3></div>

        );
    };

    // download = (fileid: number) => {


    //     get_response_files
    // }


    toggledelete = () => {

        // this.setState(prevState => ({
        //     modaldelete: !prevState.modaldelete,

        // }));
    }


    getCellActions(column: any, row: any) {

        if (column.key === "edit") {
            return [{
                icon: <Button variant="contained" color="primary" size="small">編集</Button>,
                callback: () => {
                    // this.props.bind_kyousai_contract(row.id)
                    this.props.get_mail_template_details(row.id)

                    // window.open(`https://www.homestation.jp/manage/contract/detail/view?w_id=` + row.w_id, '_blank', 'noopener')
                    // this.setState({ refresh: !this.state.refresh })
                }
            }]


        }
    }

    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div className="filesgrid">

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    enableCellSelect={false}
                    columns={this.state.columns}
                    rowGetter={(i: number) => this.props.mail_templates_search_list[i]}

                    rowsCount={this.props.mail_templates_search_list.length}
                    getCellActions={this.getCellActions}
                    // headerRenderer={true}
                    dragable={true}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    emptyRowsView={this.EmptyRowsView}
                    rowRenderer={RowRenderer}
                    minHeight={600}
                // onRowClick={this.props.selectsheet}
                //onCellSelected={this.getSelectedCell}
                />






                {/* <Modal isOpen={this.state.modaldelete} toggle={this.toggledelete} size="lg" fade={false}>

                    <ModalHeader toggle={this.toggledelete}>リストから削除</ModalHeader>
                    <ModalBody>
                        対象物件を紹介リストから削除します。

                        自動送信・自動送信除外を削除した場合は再度自動送信リストに加わります。
               </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.delete_introductions}>削除する</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggledelete}>キャンセル</button>
                    </ModalFooter>
                </Modal> */}


            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        // rooms: state.roomlist.rooms,
        // response_files: state.responsefiles.response_files,
        // response_file_update_disp: state.responsefiles.response_file_update_disp,
        mail_templates: state.mailtemplate.mail_templates,
        // responseid: state.customersReducer.responseid,
        // responsedetail: state.customersReducer.responsedetail,
        response_files_loading: state.responsefiles.response_files_loading,
        mail_templates_search_list: state.mailtemplate.mail_templates_search_list,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        get_mail_templates(state: any) {
            dispatch(MailTemplate.get_mail_templates(state))
        },
        get_mail_template_details(state: any) {
            dispatch(MailTemplate.get_mail_template_details(state))
        },
        // delete_response_file(state: any) {
        //     dispatch(ResponseFiles.delete_response_file(state))
        // },
        // change_response_file_update_disp(state: any) {
        //     dispatch(ResponseFiles.change_response_file_update_disp(state))
        // },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionsGridComponent);