import React, { Component } from 'react';

import { connect } from 'react-redux';

// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';

import * as Contracts from './Saga/Contracts';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
const moment = require('moment-timezone');
class MoneyDisp extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      value: 10,
      //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
    };
    this.numPages = this.numPages.bind(this);
  }
  numPages(state) {
    this.setState({ numPages: state })
  }
  handleButtonClick(page) {
    this.setState({ page })

  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }

  render() {
    let contract_campaign = ""
    if (this.props.rent_contract_details_old.contract_campaign !== this.props.rent_contract_details_new.contract_campaign) {
      contract_campaign = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.contract_campaign === 1 ? "キャンペーン対象" : ""}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.contract_campaign === 1 ? "キャンペーン対象" : ""}</div></span>

    } else {
      contract_campaign = this.props.rent_contract_details_new.contract_campaign === 1 ? "キャンペーン対象" : ""

    }
    let wifi = ""
    if (this.props.rent_contract_details_old.wifi !== this.props.rent_contract_details_new.wifi) {
      wifi = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.wifi === 1 ? "WIFI必要" : this.props.rent_contract_details_old.wifi === 2 ? 'WIFI不要' : "-"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.wifi === 1 ? "WIFI必要" : this.props.rent_contract_details_new.wifi === 2 ? 'WIFI不要' : "-"}</div></span>

    } else {
      wifi = this.props.rent_contract_details_new.wifi === 1 ? "WIFI必要" : this.props.rent_contract_details_new.wifi === 2 ? 'WIFI不要' : "-"

    }





    let alldenka = 0
    if (("," + this.props.rent_contract_details_new.room_setsubi + ",").indexOf(',43,') !== -1) {
      alldenka = 1
    }

    let electrical_presence = ""
    if (alldenka === 1) {


      if (this.props.rent_contract_details_old.electrical_presence !== this.props.rent_contract_details_new.electrical_presence) {
        electrical_presence = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.electrical_presence === 1 ? "電気立合い必要" : "電気立合い不要"}</div>  <ArrowDropDownIcon />  <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.electrical_presence === 1 ? "電気立合い必要" : "電気立合い不要"}</div></span>

      } else {
        electrical_presence = this.props.rent_contract_details_new.electrical_presence === 1 ? "電気立合い必要" : "電気立合い不要"

      }
    }



    let ad_pay_new = this.props.rent_contract_details_new.ad_pay === 1 ? "相殺" : this.props.rent_contract_details_new.ad_pay === 2 ? "別途請求" : "-"

    let ad_pay_old = this.props.rent_contract_details_old.ad_pay === 1 ? "相殺" : this.props.rent_contract_details_old.ad_pay === 2 ? "別途請求" : "-"
    let ad_amount
    if (this.props.rent_contract_details_new.ad_amount + ad_pay_new !== this.props.rent_contract_details_old.ad_amount + ad_pay_old) {
      ad_amount = <span><div style={{ color: "red", fontWeight: "bold" }}>{(this.props.rent_contract_details_old.ad_amount ? this.props.rent_contract_details_old.ad_amount.toLocaleString() + "円(税込)" : "") + ad_pay_old} </div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{(this.props.rent_contract_details_new.ad_amount ? this.props.rent_contract_details_new.ad_amount.toLocaleString() + "円(税込)" : "") + ad_pay_new}</div></span>
    } else {
      ad_amount = (this.props.rent_contract_details_new.ad_amount ? this.props.rent_contract_details_new.ad_amount.toLocaleString() + "円(税込)" : "") + ad_pay_new
    }


    let ad_name
    if (this.props.rent_contract_details_new.ad_name !== this.props.rent_contract_details_old.ad_name) {
      ad_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.ad_name}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.ad_name}</div></span>
    } else {
      ad_name = this.props.rent_contract_details_new.ad_name
    }

    let trader_name
    if (this.props.rent_contract_details_new.trader_name !== this.props.rent_contract_details_old.trader_name) {
      trader_name = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.trader_name ? "(" + this.props.rent_contract_details_old.trader_name + ")" : ""}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.trader_name ? "(" + this.props.rent_contract_details_new.trader_name + ")" : ""}</div></span>
    } else {
      trader_name = this.props.rent_contract_details_new.trader_name ? "(" + this.props.rent_contract_details_new.trader_name + ")" : ""
    }


    let referral_fee
    if (this.props.rent_contract_details_new.referral_fee !== this.props.rent_contract_details_old.referral_fee || this.props.rent_contract_details_new.referral_fee_remarks !== this.props.rent_contract_details_old.referral_fee_remarks) {
      referral_fee = <span><div style={{ color: "red", fontWeight: "bold" }}>{(this.props.rent_contract_details_old.referral_fee ? this.props.rent_contract_details_old.referral_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_old.referral_fee_remarks ? "(" + this.props.rent_contract_details_old.referral_fee_remarks + ")" : "")}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{(this.props.rent_contract_details_new.referral_fee ? this.props.rent_contract_details_new.referral_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.referral_fee_remarks ? "(" + this.props.rent_contract_details_new.referral_fee_remarks + ")" : "")}</div></span>
    } else {
      referral_fee = (this.props.rent_contract_details_new.referral_fee ? this.props.rent_contract_details_new.referral_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.referral_fee_remarks ? "(" + this.props.rent_contract_details_new.referral_fee_remarks + ")" : "")
    }

    let referral_fee_etc
    if (this.props.rent_contract_details_new.referral_fee_etc !== this.props.rent_contract_details_old.referral_fee_etc || this.props.rent_contract_details_new.referral_fee_etc_remarks !== this.props.rent_contract_details_old.referral_fee_etc_remarks) {
      referral_fee_etc = <span><div style={{ color: "red", fontWeight: "bold" }}>{(this.props.rent_contract_details_old.referral_fee_etc ? this.props.rent_contract_details_old.referral_fee_etc.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_old.referral_fee_etc_remarks ? "(" + this.props.rent_contract_details_old.referral_fee_etc_remarks + ")" : "")}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{(this.props.rent_contract_details_new.referral_fee_etc ? this.props.rent_contract_details_new.referral_fee_etc.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.referral_fee_etc_remarks ? "(" + this.props.rent_contract_details_new.referral_fee_etc_remarks + ")" : "")}</div></span>
    } else {
      referral_fee_etc = (this.props.rent_contract_details_new.referral_fee_etc ? this.props.rent_contract_details_new.referral_fee_etc.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.referral_fee_etc_remarks ? "(" + this.props.rent_contract_details_new.referral_fee_etc_remarks + ")" : "")
    }
    let agent_fee
    if (this.props.rent_contract_details_new.agent_fee !== this.props.rent_contract_details_old.agent_fee || this.props.rent_contract_details_new.agent_fee_remarks !== this.props.rent_contract_details_old.agent_fee_remarks) {
      agent_fee = <span><div style={{ color: "red", fontWeight: "bold" }}>{(this.props.rent_contract_details_old.agent_fee ? this.props.rent_contract_details_old.agent_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_old.agent_fee_remarks ? "(" + this.props.rent_contract_details_old.agent_fee_remarks + ")" : "")}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{(this.props.rent_contract_details_new.agent_fee ? this.props.rent_contract_details_new.agent_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.agent_fee_remarks ? "(" + this.props.rent_contract_details_new.agent_fee_remarks + ")" : "")}</div></span>
    } else {
      agent_fee = (this.props.rent_contract_details_new.agent_fee ? this.props.rent_contract_details_new.agent_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.agent_fee_remarks ? "(" + this.props.rent_contract_details_new.agent_fee_remarks + ")" : "")
    }

    let pay_brokerage_fee
    if (this.props.rent_contract_details_new.pay_brokerage_fee !== this.props.rent_contract_details_old.pay_brokerage_fee || this.props.rent_contract_details_new.pay_brokerage_fee_remarks !== this.props.rent_contract_details_old.pay_brokerage_fee_remarks) {
      pay_brokerage_fee = <span><div style={{ color: "red", fontWeight: "bold" }}>{(this.props.rent_contract_details_old.pay_brokerage_fee ? this.props.rent_contract_details_old.pay_brokerage_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_old.pay_brokerage_fee_remarks ? "(" + this.props.rent_contract_details_old.pay_brokerage_fee_remarks + ")" : "")}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{(this.props.rent_contract_details_new.pay_brokerage_fee ? this.props.rent_contract_details_new.pay_brokerage_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.pay_brokerage_fee_remarks ? "(" + this.props.rent_contract_details_new.pay_brokerage_fee_remarks + ")" : "")}</div></span>
    } else {
      pay_brokerage_fee = (this.props.rent_contract_details_new.pay_brokerage_fee ? this.props.rent_contract_details_new.pay_brokerage_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.pay_brokerage_fee_remarks ? "(" + this.props.rent_contract_details_new.pay_brokerage_fee_remarks + ")" : "")
    }


    let owner_brokerage_fee
    if (this.props.rent_contract_details_new.owner_brokerage_fee !== this.props.rent_contract_details_old.owner_brokerage_fee || this.props.rent_contract_details_new.owner_brokerage_fee_remarks !== this.props.rent_contract_details_old.owner_brokerage_fee_remarks) {
      owner_brokerage_fee = <span><div style={{ color: "red", fontWeight: "bold" }}>{(this.props.rent_contract_details_old.owner_brokerage_fee ? this.props.rent_contract_details_old.owner_brokerage_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_old.owner_brokerage_fee_remarks ? "(" + this.props.rent_contract_details_old.owner_brokerage_fee_remarks + ")" : "")}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{(this.props.rent_contract_details_new.owner_brokerage_fee ? this.props.rent_contract_details_new.owner_brokerage_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.owner_brokerage_fee_remarks ? "(" + this.props.rent_contract_details_new.owner_brokerage_fee_remarks + ")" : "")}</div></span>
    } else {
      owner_brokerage_fee = (this.props.rent_contract_details_new.owner_brokerage_fee ? this.props.rent_contract_details_new.owner_brokerage_fee.toLocaleString() + "円" : "-") + (this.props.rent_contract_details_new.owner_brokerage_fee_remarks ? "(" + this.props.rent_contract_details_new.owner_brokerage_fee_remarks + ")" : "")
    }

    let contract_remarks
    if (this.props.rent_contract_details_new.contract_remarks !== this.props.rent_contract_details_old.contract_remarks) {
      contract_remarks = <span><div style={{ color: "red", fontWeight: "bold" }}>{this.props.rent_contract_details_old.contract_remarks}</div>  <ArrowDropDownIcon /> <div style={{ color: "blue", fontWeight: "bold" }}>{this.props.rent_contract_details_new.contract_remarks}</div></span>
    } else {
      contract_remarks = this.props.rent_contract_details_new.contract_remarks
    }


    return (

      <div>

        <table>
          <tr>
            <th colSpan={4}>その他
              {/* <Button size="small" style={{ float: "right" }} variant="contained" color="primary" onClick={() => this.props.check_rent_contract_editor_disp({ editor: "rent_contract_etc_editor_disp", disp: true })}>
                編集
      </Button> */}
            </th>

          </tr>
          <tr>
            <th>広告料</th>
            <td>{ad_amount}</td>
            <th>広告料宛名</th>
            <td>{ad_name}</td>
          </tr>


          <tr>
            <th>代行会社手数料</th>
            <td colSpan={3}>{agent_fee}</td>

          </tr>
          <tr>
            <th>仲介手数料オーナー負担</th>
            <td>{owner_brokerage_fee}</td>
            <th>支払仲介手数料</th>
            <td>{pay_brokerage_fee}</td>
          </tr>
          <tr>
            <th>紹介料</th>
            <td>{referral_fee}</td>
            <th>その他紹介料</th>
            <td>{referral_fee_etc}</td>
          </tr>


          <tr>



            <th style={{ width: "25%" }}>{alldenka === 1 ? "オール電化物件" : ""}</th>
            <td style={{ width: "25%" }}>
              {alldenka === 1 ? electrical_presence : ""}
            </td>
            <th style={{ width: "25%" }}></th>
            <td style={{ width: "25%" }}>

            </td>

            {/* <th style={{ width: "25%" }}>wifi</th>
            <td style={{ width: "25%" }}>{wifi}</td> */}

          </tr>

          <tr>
            <th>契約備考</th>
            <td colSpan="3">{contract_remarks}</td>
          </tr>



        </table>
      </div >

    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    check_rent_contract_editor_disp(state) {
      dispatch(Contracts.check_rent_contract_editor_disp(state))
    },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MoneyDisp);
