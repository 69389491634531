
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';

import App from './App';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css';

import * as Master from './Store/Master';
import * as Firebase from './Store/Firebase';


import * as StockRooms from './Components/StockRooms/Saga/StockRooms';


import './index.css';
import './web.config';

import { Provider } from 'react-redux';

import configureStore, { history } from './Store/configureStore'





const authentication = require('./react-azure-adb2c2').default;



const store = configureStore(/* provide initial state if any */)




authentication.initialize({
 
    instance: 'https://homestation.b2clogin.com/',

    // your B2C tenant
    tenant: 'homestation.onmicrosoft.com',

    signInPolicy: 'B2C_1_homestation_signin',
    resetPolicy: 'B2C_1_homestation_password_reset',
    applicationId: '279ce483-e15a-4deb-b954-d5024ef73f8e',

    cacheLocation: 'localStorage',
    scopes: ['https://homestation.onmicrosoft.com/api/user_impersonation'],

    redirectUri: process.env.REACT_APP_BASE_URL,
  
});



authentication.run(() => {
 
    store.dispatch(Firebase.fire_user_details())
    store.dispatch(Master.getUsers())
    store.dispatch(Master.get_user_details())
    store.dispatch(Master.getRentMMedeas())
    store.dispatch(Master.getRentAgents())
    store.dispatch(Master.getRentMReasons())
    store.dispatch(Master.get_shops())
    store.dispatch(Master.get_setsubi_master())
    store.dispatch(Master.get_chomeis())
    store.dispatch(Master.get_chuugakkous())
    store.dispatch(Master.get_shougakkous())
    store.dispatch(Master.get_todoufukens())
    store.dispatch(Master.get_shikuchousons())
    store.dispatch(Master.get_kanricorps())
    store.dispatch(Master.get_tm_owners())
    store.dispatch(Master.get_rent_m_contract_types())
    store.dispatch(Master.get_tm_chinryou_koumokus())
    store.dispatch(Master.get_m_penalties())
    store.dispatch(StockRooms.get_stock_rooms())
    store.dispatch(Master.get_m_stations())
    store.dispatch(Master.get_tm_chinryou_koumoku())


    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}> { /* place ConnectedRouter under Provider */}

                <App />

            </ConnectedRouter>
        </Provider>
        , document.getElementById('root'));
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://bit.ly/CRA-PWA
    serviceWorker.unregister();
})