import React, { Component } from 'react';

import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import "react-datepicker/dist/react-datepicker.css";

import * as Estimates from './Saga/Estimates';
import makeStyles from '@mui/styles/makeStyles';

import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import RoomDocComponent from '../Rooms/RoomDocComponent';
import { Modal, ModalHeader } from 'reactstrap';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import 'bootstrap/dist/css/bootstrap.css';
import '../../Css/Table.css';


import './Css/Form.css';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Forms from './Css/Form.module.css'



import Select from '@mui/material/Select';
import ReactSelect from 'react-select';
import TextField from '@mui/material/TextField';
import moment from 'moment';


// タイマー用
let timer;

const options = [
  { value: 'pikachu', label: 'ピカチュウ' },
  { value: 'bulbasaur', label: 'フシギダネ' },
  { value: 'charmander', label: 'ヒトカゲ' },
  { value: 'squirtle', label: 'ゼニガメ' },
]


const useStyles = makeStyles({
  card: {
    minWidth: 275,
    background: "pink",
    textAlign: "left"

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});

const useStylesGreen = makeStyles({
  card: {
    minWidth: 275,
    background: "#87cefa",
    textAlign: "left"

  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
});


const ContractCard = (props) => {
  const { explain } = props;

  const classes = useStylesGreen();
  return (
    <Card className={classes.card}>
      <CardContent>
        {explain ?
          <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: explain.replace(/\r?\n/g, '<br/>') }}></span>
          : ""}
      </CardContent>
    </Card>
  );
}

const ContractCheckCard = (props) => {
  const { explain, set_contract_checks } = props;

  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <CardContent>
        {explain ?
          <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: explain.replace(/\r?\n/g, '<br/>') }}></span>
          : ""}

        <Button
          color="secondary"
          variant="contained"
          size="small"
          type="submit"
          style={{ margin: 2, minWidth: 0 }}
          onClick={set_contract_checks}
        >反映
        </Button>
      </CardContent>
    </Card>
  );
}
// フォームの入力用コンポーネント。
// input と meta は redux-form が渡してくる props。
// type や label は Field に渡した props がそのまま渡ってくる。
// const renderField = ({ className, input, type, label, id, placeholder, onKeyDown,style, meta: { touched, error } }) => (
//     <div >
//         <input {...input} type={type} id={id} onKeyDown={onKeyDown} placeholder={placeholder} style={style} className={touched && error ? 'alert-danger ' + className : className}/>
//         {touched && error && <div className="text-danger">{error}</div>}
//     </div>
// );

// const ReduxFormDateRange = (props) => {

//     return (
//         <DatePicker

//             selected={props.input.value !== "" ? new Date(props.input.value) : props.input.value}
//             //selected={moment(props.input.value, "YYYY-MM-DD").format("YYYY-MM-DD") || null}
//             onChange={props.input.onChange}
//             //showTimeSelect
//             dateFormat="yyyy/MM/dd"
//             className="form-control"
//         />
//     )
// }


class EstimatesInfoEdit extends Component {

  constructor(props) {
    // const classes = useStyles();
    super(props);


    // if (props.customerdetail.name1) {
    //  props.initialize(props.customerdetail);//フォームに初期値を反映させる
    // } else {


    //     var customerdetail = {
    //         id:0,
    //         name1: "",
    //         kana1: "",
    //         name2: "",
    //         kana2: "",
    //         type: "0",
    //         sex: "1",
    //     }
    //     props.initialize(customerdetail);//フォームに初期値を反映させる
    // }
    this.state = {
      //     formData: {
      //         email: '',
      //         password: '',
      //         tatemonoName: '',
      //         applicationDate: null,
      //         contractionDate: null,

      //     },
      submitted: false,
      emp: false,

      tooltipOpen: false,
      modalsheet: false,

    }

    // this.onChangeShowHide = this.onChangeShowHide.bind(this);
    // this.onChangeSex= this.onChangeSex.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleEmp = this.handleEmp.bind(this);
    this.getOptions = this.getOptions.bind(this);
    this.handleRoomsChange = this.handleRoomsChange.bind(this);
    this.handleManagementCompanyChange = this.handleManagementCompanyChange.bind(this);

    this.togglesheet = this.togglesheet.bind(this);
    this.toggle = this.toggle.bind(this);
    //    this.elements = null;
    //this.props.initialize(this.props.forecasts);

  }


  handleChange = (event) => {
    // alert(event.target.name)
    // alert(event.target.value)
    console.log(event)

    // alert(event.target.name)
    let estimateData = this.props.estimateData
    // if(event.target.name==="trader"){ alert(event.target.value)
    //   estimateData[event.target.name] = Number(event.target.value)
    // }else{
    estimateData[event.target.name] = event.target.value


    if (event.target.name == "pets") {
      if (event.target.value == 1) {
        if (estimateData.rentMContractTypeId == 42) {
          estimateData.rentMContractTypeId = 53
        }
      } else {
        if (estimateData.rentMContractTypeId == 53) {
          estimateData.rentMContractTypeId = 42
        }
      }

    }
    // }
    this.props.change_estimateData(estimateData)




    if (event.target.name === "rentMContractTypeId") {
      this.props.set_contract_checks();
      // this.props.get_rent_contract_checks(event.target.value)
    }

    this.setState({ change: true })
    this.props.check_estimates()
  }


  handleTatemonoIdChange = (event) => {

    console.log(event)

    let estimateData = this.props.estimateData
    if (event) {
      // alert(event.value)
      estimateData.tatemonoId = event.value
      estimateData.buildingName = event.label
      estimateData.roomId = null
      this.props.get_estimate_rooms(event.value)

      const tatemono = this.props.tatemonoes.filter(a => a.tatemonoId === event.value)
      estimateData.managementCompany = tatemono[0].kanriCorpNo

    } else {
      estimateData.tatemonoId = null
      estimateData.buildingName = null
    }
    // }
    this.props.change_estimateData(estimateData)

    this.setState({ change: true })
  }


  handleRoomsChange = (event) => {

    console.log(event)

    let estimateData = this.props.estimateData

    estimateData.roomId = event.target.value

    const room = this.props.estimate_rooms.filter(a => a.roomId === event.target.value)
    console.log(room)

    estimateData.roomName = room[0].roomName
    // alert(room[0].kanriKubunNo)
    // alert(room[0].kanriKubunNo)
    if (room[0].kanriKubunNo === 1) {
      estimateData.division = 2
    } else if (room[0].kanriKubunNo === 2) {
      estimateData.division = 3
    } else if (room[0].kanriKubunNo === 3) {
      estimateData.division = 6
    } else if (room[0].kanriKubunNo === 4) {
      estimateData.division = 1
    } else {
      estimateData.division = 4
    }

    console.log(estimateData)
    // alert()

    this.props.change_estimateData(estimateData)
    this.props.get_boshu({ tatemonoId: estimateData.tatemonoId, roomId: estimateData.roomId })
    this.setState({ change: true })
  }


  handleManagementCompanyChange = (event) => {

    console.log(event)

    let estimateData = this.props.estimateData

    estimateData.managementCompany = event.value



    this.props.change_estimateData(estimateData)
    this.setState({ change: true })
  }








  handleSubmit = () => {
    this.setState({ submitted: true }, () => {
      setTimeout(() => this.setState({ submitted: false }), 5000);
    });
  }

  componentWillMount() {

    // console.log(this.props)
    // if (this.props.customerdetail) {
    // this.props.customerdetail.customerno = this.props.customerno;


    // }

  }

  componentDidMount() {
    this.setState({ tooltipOpen: !this.state.tooltipOpen })

  }



  componentDidUpdate() {
    // This method is called when the route parameters change
    //this.ensureDataFetched();

  }



  handleEmp(event) {
    this.setState({ [event.target.name]: event.target.checked });

    // alert(event.target.checked )
    let estimateData = this.props.estimateData

    estimateData.emp = event.target.checked ? 1 : 0
    estimateData.tatemonoId = 0
    estimateData.buildingName = ""
    estimateData.roomName = ""
    estimateData.roomId = 0
    // const room= this.props.estimate_rooms.filter(a=>a.roomId===event.target.value)

    this.props.change_estimateData(estimateData)
    this.setState({ change: true })
  };



  // handleApplicationDateChange = date => {
  //   // this.setState({formData:{applicationDate:date,
  //   //     contractionDate:this.state.formData.contractionDate}});
  //   let estimateData = this.props.estimateData
  //   estimateData.applicationDate = date
  //   this.props.change_estimateData(estimateData);
  //   this.setState({ change: true })
  // };

  handleApplicationDateChange = (e) => {

    let estimateData = this.props.estimateData
    estimateData.applicationDate = e.target.value
    this.props.change_estimateData(estimateData);
    this.setState({ change: true })
  };

  // handleContractionDateChange = date => {

  //   let estimateData = this.props.estimateData

  //   estimateData.contractDate = date;

  //   this.props.change_estimateData(estimateData)
  //   this.setState({ change: true })
  //   this.props.check_estimates()
  // };

  handleContractionDateChange = (e) => {

    let estimateData = this.props.estimateData

    estimateData.contractDate = e.target.value;

    this.props.change_estimateData(estimateData)
    this.setState({ change: true })
    this.props.check_estimates()
  };



  handleCustomerChange(event) {
    event.persist();
    console.log(event.target.value)
    // const change = this.state.customerdetail;
    // change[event.target.name] = event.target.value
    // this.setState({customerdetail: change})
    let change = this.props.customerdetail;
    change[event.target.name] = event.target.value;

    console.log(this.state.customerdetail)

  }
  handleSelectCustomer(state) {
    this.setState({ customerdetail: state })


    this.props.change_customerloding(1)
    this.props.change_customeredit(0)
  }

  handleCustomerreset() {

    this.setState({
      customerdetail: {
        id: 0,
        type: "0",
        sex: "1"
      }
    })
    this.props.change_customeredit(1)
  }


  async getOptions(name) {
    if (name) {

      // alert(name)






      clearTimeout(timer);
      if (name) {
        timer = setTimeout(async function () {
          await this.props.get_tatemonoes(name)
        }.bind(this), 400);
      }
    }

  }

  toggle() {
    // this.setState({tooltipOpen:!this.state.tooltipOpen})
  }


  togglesheet = () => {

    this.setState(prevState => ({
      modalsheet: !prevState.modalsheet,

    }));
  }




  render() {
    let user = this.props.users.filter(a => a.amSectionId === this.props.estimateData.sectionId)
    let operationUser = this.props.users.filter(a => a.amSectionId === this.props.estimateData.operationSectionId)

    // console.log(this.props.estimateData)



    // const { customersubmit,handleSubmit } = this.props;

    // const year = [""];
    // const day = [""];

    // var hiduke = new Date(); 
    // for (var i = 1926; i <= hiduke.getFullYear(); i++) {
    //     year.push(i);
    // }
    // for (var i2= 1; i2 <= 31; i2++) {
    //     day.push(i2);
    // }

    // const { formData, submitted } = this.state;
    // console.log(formData)
    return (
      <ValidatorForm
        ref="form"
        onSubmit={this.handleSubmit}
        onError={errors => console.log(errors)}
      >
        <Grid
          justifyContent="space-between" // Add it here :)
          container

        >

          <div style={{ float: "left", width: "100%" }}>

            <TextField
              id="contractDate"
              label="契約日"
              type="date"
              name="contractDate"
              size="small"
              onChange={this.handleContractionDateChange}
              value={moment(this.props.estimateData.contractDate).format("YYYY-MM-DD")}
              InputLabelProps={{
                  shrink: true,
              }}
              style={{ float: "left", marginRight:20 }}
              variant="standard"
            />

            {this.props.estimateData.contractFlg === 1 ? <TextField
              id="applicationDate"
              label="申込日"
              type="date"
              name="applicationDate"
              size="small"
              onChange={this.handleApplicationDateChange}
              value={moment(this.props.estimateData.applicationDate).format("YYYY-MM-DD")}
              InputLabelProps={{
                  shrink: true,
              }}
              variant="standard"
              style={{ float: "left" }}
            />:""}

            {/* <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ja}>


              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-estimate2"
                label="契約日"
                //   showTodayButton={true}
                style={{ float: "left" }}
                // error={true}
                // helperText="契約日を選択してください"
                //   errorMessages={['契約日を選択してください']}
                name="contractDate"
                // value={formData.contractionDate}
                value={this.props.estimateData.contractDate}
                onChange={this.handleContractionDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                //   minDateMessage="契約日を選択してください"
                // minDate={new Date("2000-01-01")}
                invalidDateMessage={"日付の形式が違います"}
              />

              {this.props.estimateData.contractFlg === 1 ? <KeyboardDatePicker

                disableToolbar
                variant="inline"
                format="yyyy/MM/dd"
                margin="normal"
                id="date-picker-estimate2"
                label="申込日"
                style={{ marginLeft: "20px" }}
                // error={true}
                // helperText="申込日を選択してください"
                //   showTodayButton={true}
                name="applicationDate"
                // value={formData.applicationDate}
                value={this.props.estimateData.applicationDate}
                onChange={this.handleApplicationDateChange}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                invalidDateMessage={"日付の形式が違います"}
              /> : ""}

            </MuiPickersUtilsProvider> */}

            <Button size="small" variant="outlined" style={{ margin: 5, float: "right", display: (this.props.notedit === 1) ? 'none' : '' }} onClick={() => this.props.change_contract_detail_edit_disp(false)}>閉じる</Button>

            <Button size="small"
              style={{ float: "right", margin: 5 }}
              color="primary"
              variant="contained"
              type="button"
              onClick={this.props.auto_boshu_setting}
            >
              物件情報から反映
            </Button>


          </div>


        </Grid>

        <Grid container spacing={3}>
          <Grid item xs={12}>
            {this.props.estimateData.emp === 1 ? <div>
              {/* <Grid
      justify="space-between" // Add it here :)
      item
      xs={6}
     
    > */}
              <TextValidator
                id="outlined-basic"
                //   className={classes.textField}
                label="物件名"
                margin="normal"

                onChange={this.handleChange}
                name="buildingName"
                value={this.props.estimateData.buildingName}
                style={{ float: "left", width: "400px", marginTop: 0 }}
                validators={['required']}
              // errorMessages={['物件名を選択してください']}
              />
              <TextValidator
                id="outlined-basic"
                //   className={classes.textField}
                label="部屋名"
                margin="normal"

                onChange={this.handleChange}
                name="roomName"
                value={this.props.estimateData.roomName}
                style={{ float: "left", width: "100px", marginLeft: "10px", marginTop: 0 }}
                validators={['required']}
                errorMessages={['部屋名を入力してください。']}
              />
              {/* </Grid> */}
            </div> :
              <div>
                <div style={{ float: "left", width: "400px", marginTop: "10px", textAlign: "left" }} >
                  <ReactSelect
                    options={this.props.tatemono_select}
                    onChange={this.handleTatemonoIdChange}
                    onInputChange={this.getOptions}
                    value={this.props.estimateData.tatemonoId}
                    // loadOptions=
                    placeholder="物件名を選択してください" />
                </div>


                <FormControl className={Forms.formControl} style={{ float: "left", marginTop: "10px", marginLeft: "10px" }} >
                  <InputLabel id="demo-simple-select-helper-label">部屋名</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.props.estimateData.roomId}
                    name="roomId"
                    onChange={this.handleRoomsChange}
                  >
                    <MenuItem value="">
                      <em>-</em>
                    </MenuItem>
                    {this.props.estimate_rooms.map((value, key) => <MenuItem key={key} value={value.roomId}>{value.roomName}</MenuItem>)}



                    {/* 
          <MenuItem value={10}>無し</MenuItem>
          <MenuItem value={20}>1人</MenuItem>
          <MenuItem value={30}>2人</MenuItem>
          <MenuItem value={30}>緊急連絡先</MenuItem> */}
                  </Select>



                </FormControl>


              </div>}


            <FormControlLabel
              style={{ float: "left", marginLeft: "10px" }}
              control={
                <Checkbox
                  name="emp"
                  checked={this.props.estimateData.emp === 1 ? true : false}
                  onChange={this.handleEmp}

                  value={true}
                  color="primary"
                />
              }
              label="登録なし"
            />

            {this.props.estimateData.tatemonoId > 0 ? <Button size="small"
              style={{ float: "right" }}
              color="primary"
              variant="contained"
              type="button"
              onClick={this.togglesheet}
            >
              詳細
            </Button> : ""}
          </Grid>




          <Grid item xs={12}>
            <FormControl className={Forms.formControl} style={{ float: "left" }} size="small">
              <InputLabel id="demo-simple-select-helper-label" size="small">管理区分</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.props.estimateData.division}
                variant='standard'
                onChange={this.handleChange}
                name="division"
              >
                <MenuItem value="">
                  <em>-</em>
                </MenuItem>
                <MenuItem value={2}>管理</MenuItem>
                <MenuItem value={1}>サブ</MenuItem>
                <MenuItem value={3}>一般</MenuItem>
                <MenuItem value={6}>専任</MenuItem>
                <MenuItem value={4}>業物</MenuItem>
                <MenuItem value={5}>業物折半</MenuItem>
              </Select>



            </FormControl>
            <div style={{ float: "left", width: "400px", marginTop: "10px", marginLeft: "10px", textAlign: "left" }} >
              <ReactSelect
                options={this.props.kanricorps}
                onChange={this.handleManagementCompanyChange}
                value={this.props.estimateData.managementCompany}
                name="managementCompany"
                placeholder="管理会社" />
            </div>
          </Grid >
          <Grid item xs={12}>
            <Grid
              justifyContent="space-between" // Add it here :)
              container

            >
              <RadioGroup aria-label="形態" name="type" value={String(this.props.estimateData.type)} onChange={this.handleChange} row>
                <FormControlLabel value="0" control={<Radio />} label="個人" />
                <FormControlLabel value="1" control={<Radio />} label="法人" />

              </RadioGroup>
            </Grid>
          </Grid>



          <Grid item xs={12}>
            <TextValidator
              id="outlined-basic"
              //   className={classes.textField}
              label="契約者"
              margin="normal"

              onChange={this.handleChange}
              name="name1"
              value={this.props.estimateData.name1}
              style={{ float: "left", width: "400px", marginTop: 0 }}
            // validators={['required']}
            // errorMessages={['契約者名を入力してください']}
            />
          </Grid >
          {String(this.props.estimateData.type) === "1" ?
            <Grid item xs={12}>
              <TextValidator
                id="outlined-basic"
                //   className={classes.textField}
                label="入居者名"
                margin="normal"

                onChange={this.handleChange}
                name="name2"
                value={this.props.estimateData.name2}
                style={{ float: "left", width: "400px", marginTop: 0 }}
              // validators={['required']}
              // errorMessages={['入居者名を入力してください']}
              />
            </Grid > : ""}


          <Grid item xs={12}>
            <FormControl className={Forms.formControl} size="small" style={{ float: "left" }}>
              <InputLabel id="demo-simple-select-helper-label">店舗</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.props.estimateData.sectionId}
                onChange={this.handleChange}
                name="sectionId"
                variant='standard'
              >
                {/* <MenuItem value="">
            <em>-</em>
          </MenuItem> */}
                {this.props.section_select.map((value, key) => <MenuItem key={key} value={value.value}>{value.label}</MenuItem>)}

              </Select>



            </FormControl>

            <FormControl className={Forms.formControl} size="small" style={{ float: "left", marginLeft: "10px" }}>
              <InputLabel id="demo-simple-select-helper-label">担当</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"

                value={this.props.estimateData.userId}
                onChange={this.handleChange}
                name="userId"
                variant='standard'
              >
                {/* <MenuItem value="">
            <em>-</em>
          </MenuItem> */}
                {user.map((value, key) => <MenuItem key={key} value={value.id}>{value.nickname}</MenuItem>)}
              </Select>
            </FormControl>


            {this.props.estimateData.contractFlg === 1 ? <FormControl className={Forms.formControl} style={{ float: "left", marginLeft: "30px" }}>
              <InputLabel id="demo-simple-select-helper-label">手続担当店舗</InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={this.props.estimateData.operationSectionId}
                onChange={this.handleChange}
                name="operationSectionId"
              >
                {/* <MenuItem value="">
            <em>-</em>
          </MenuItem> */}
                {this.props.section_select.map((value) => <MenuItem value={value.value}>{value.label}</MenuItem>)}

              </Select>



            </FormControl> : ""}
            {this.props.estimateData.contractFlg === 1 ?
              <FormControl className={Forms.formControl} style={{ float: "left", marginLeft: "10px" }}>
                <InputLabel id="demo-simple-select-helper-label">手続担当</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  // value={this.state.age}
                  value={this.props.estimateData.operationUserId}
                  onChange={this.handleChange}
                  name="operationUserId"
                >
                  {/* <MenuItem value="">
            <em>-</em>
          </MenuItem> */}
                  {operationUser.map((value) => <MenuItem value={value.id}>{value.nickname}</MenuItem>)}


                </Select>
              </FormControl> : ""}
          </Grid >


          <Grid item xs={12}  >
            <Grid item xs={5} style={{ float: "left" }}>
              <FormControl id="TooltipExample2" className={Forms.formControl} size="small" style={{ marginLeft: "10px", width: "200px" }}>
                <InputLabel id="demo-simple-select-helper-label">契約形態</InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={this.props.estimateData.rentMContractTypeId}
                  onChange={this.handleChange}
                  name="rentMContractTypeId"
                  variant='standard'
                >
                  {this.props.rent_m_contract_types.map((value, key) => <MenuItem key={key} value={value.value}>{value.label}</MenuItem>)}




                </Select>


              </FormControl>

              <RadioGroup aria-label="ペット" name="pets" value={String(this.props.estimateData.pets)} onChange={this.handleChange} style={{ marginTop: "10px" }} row>

                <FormControlLabel value="0" control={<Radio />} label="ペット無" />
                <FormControlLabel value="1" control={<Radio />} label="ペット有" />

              </RadioGroup>
            </Grid>
            <Grid item xs={4} style={{ float: "left", marginLeft: "10px" }}>
              <ContractCard explain={this.props.rent_m_contract_types_detail.filter(a => a.id === this.props.estimateData.rentMContractTypeId)[0] ? this.props.rent_m_contract_types_detail.filter(a => a.id === this.props.estimateData.rentMContractTypeId)[0].explain : ""} />
            </Grid>
            <Grid item xs={3} style={{ float: "left", marginLeft: "10px" }}>
              {this.props.contract_check_message ? <ContractCheckCard explain={this.props.contract_check_message} set_contract_checks={this.props.set_contract_checks} /> : ""}


              {/* <p>Somewhere in here is a <span style={{textDecoration: "underline", color:"blue"}} href="#" id="TooltipExample2">tooltip</span>.</p> */}
              {/* <Tooltip placement="right" isOpen={this.state.tooltipOpen} target="TooltipExample2"  toggle={this.toggle} dataHtml={true}>
   
      <span style={{textAlign:"left"}} dangerouslySetInnerHTML={{__html: this.props.rent_m_contract_types_detail.filter(a=>a.id===this.props.estimateData.rentMContractTypeId)[0].explain}}></span>

      </Tooltip> */}

            </Grid>
          </Grid>
        </Grid>

        {this.props.estimateData.contractFlg === 1 ?
          <Grid
            justifyContent="space-between" // Add it here :)
            container
            spacing={24}
          >
            <RadioGroup aria-label="客付け" name="trader" value={String(this.props.estimateData.trader)} onChange={this.handleChange} row>
              <FormControlLabel value="1" control={<Radio />} label="自社付" />
              <FormControlLabel value="2" control={<Radio />} label="他者付" />

            </RadioGroup>
          </Grid> : ""}




        <Grid
          justifyContent="space-between" // Add it here :)
          container


        >
          {this.props.estimateData.contractFlg === 1 ?
            <Grid item xs={12}>
              <Grid
                justifyContent="space-between" // Add it here :)
                item
                xs={2}
              >
                <FormControl className={Forms.formControl} style={{ margin: 3 }}>
                  <InputLabel id="demo-simple-select-helper-label">保証人</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.age}
                    onChange={this.handleChange}
                  >
                    <MenuItem value="">
                      <em>-</em>
                    </MenuItem>
                    <MenuItem value={10}>無し</MenuItem>
                    <MenuItem value={20}>1人</MenuItem>
                    <MenuItem value={30}>2人</MenuItem>
                    <MenuItem value={30}>緊急連絡先</MenuItem>
                  </Select>
                  {/* <FormHelperText>Some important helper text</FormHelperText> */}
                </FormControl>

              </Grid>

              <Grid
                justifyContent="space-between" // Add it here :)
                xs={2}
                item
              >
                <FormControl className={Forms.formControl} style={{ margin: 3 }}>
                  <InputLabel id="demo-simple-select-helper-label">入居人数</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.age}
                    onChange={this.handleChange}
                  >
                    <MenuItem value="">
                      <em>-</em>
                    </MenuItem>
                    <MenuItem value={10}>1人</MenuItem>
                    <MenuItem value={20}>2人</MenuItem>
                    <MenuItem value={30}>3人</MenuItem>
                    <MenuItem value={30}>4人</MenuItem>
                    <MenuItem value={30}>5人</MenuItem>
                    <MenuItem value={30}>6人</MenuItem>
                  </Select>
                  {/* <FormHelperText>Some important helper text</FormHelperText> */}
                </FormControl>
              </Grid>
              <Grid
                justifyContent="space-between" // Add it here :)

                xs={2}
                item
              >
                <FormControl className={Forms.formControl} style={{ margin: 3 }}>
                  <InputLabel id="demo-simple-select-helper-label">駐車場台数</InputLabel>
                  <Select
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={this.state.age}
                    onChange={this.handleChange}
                  >
                    <MenuItem value="">
                      <em>-</em>
                    </MenuItem>
                    <MenuItem value={30}>無</MenuItem>
                    <MenuItem value={10}>1台</MenuItem>
                    <MenuItem value={20}>2台</MenuItem>
                    <MenuItem value={30}>3台</MenuItem>

                  </Select>

                  {/* <FormHelperText>Some important helper text</FormHelperText> */}
                </FormControl>
              </Grid>
              <Grid
                justifyContent="space-between" // Add it here :)

                xs={6}
                item
              >

              </Grid>
            </Grid> : ""}
        </Grid>



        <Modal isOpen={this.state.modalsheet} toggle={this.togglesheet} size="xxl" zIndex={3000} fade={false}>
          <ModalHeader toggle={this.togglesheet}>物件情報</ModalHeader>
          < RoomDocComponent tatemonoId={this.props.estimateData.tatemonoId} roomId={this.props.estimateData.roomId} />

        </Modal>
      </ValidatorForm>
    );
  }
}









const mapStateToProps = state => {
  return {
    customerNewDispSet: state.customersReducer.customerNewDispSet,
    customerlist: state.customersReducer.customerlist,
    formvalue: state.form,
    users: state.masterReducer.users,
    section_select: state.masterReducer.section_select,
    rent_m_contract_types: state.masterReducer.rent_m_contract_types,
    rent_m_contract_types_detail: state.masterReducer.rent_m_contract_types_detail,
    kanricorps: state.masterReducer.kanricorps,
    estimateData: state.estimates.estimateData,
    tatemonoes: state.estimates.tatemonoes,
    tatemono_select: state.estimates.tatemono_select,
    estimate_rooms: state.estimates.estimate_rooms,
    contract_check_message: state.estimates.contract_check_message,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    // update_customerdetail() {
    //     dispatch(Customers.update_customerdetail())
    // },
    change_estimateData(state) {
      dispatch(Estimates.change_estimateData(state))
    },
    get_tatemonoes(state) {
      dispatch(Estimates.get_tatemonoes(state))
    },
    get_estimate_rooms(state) {
      dispatch(Estimates.get_estimate_rooms(state))
    },
    get_boshu(state) {
      dispatch(Estimates.get_boshu(state))
    },
    auto_boshu_setting(state) {
      dispatch(Estimates.auto_boshu_setting(state))
    },
    get_rent_contract_checks(state) {
      dispatch(Estimates.get_rent_contract_checks(state))
    },
    set_contract_checks(state) {
      dispatch(Estimates.set_contract_checks(state))
    },
    check_estimates(state) {
      dispatch(Estimates.check_estimates(state))
    },
    change_contract_detail_edit_disp(state) {
      dispatch(Estimates.change_contract_detail_edit_disp(state))
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EstimatesInfoEdit);


// CustomerInfoEdit = connect(
//     mapStateToProps, mapDispatchToProps
// )(CustomerInfoEdit);

// CustomerInfoEdit = reduxForm({
//     form: 'customerform', // a unique identifier for this form
//     // validate//バリデーション

//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(CustomerInfoEdit)





