
const authentication = require('../../../react-azure-adb2c2').default;
export const details = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contracts/logs/` + values.rent_response_id + '/' + values.rent_contract_id;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values.rent_response_id + '/' + values.rent_contract_id;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}



export const update = (values: any) => {

    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;

    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contracts/logs/` + values;
    // const url = `http://localhost:5000/v1/update/rent/contracts/` + (values.rent_contract_id != 18 ? values.rent_contract_id : 0);

    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/update/rent/contracts/` + (values.rent_contract_id != 18 ? values.rent_contract_id : 0);

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(values.billing_closing_date_nyuukyosha)
    // alert("アップデート")
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}





export const contract_cancel = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;

    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contracts/logs/` + values;
    // const url = `http://localhost:5000/v1/rent/contract/cancel/` + values;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/rent/contract/cancel/` + values;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}




export const download_docs = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/contractsheet/365download/` + values + `/A4`
    // const url = `http://localhost:5000/api/v1/rent/contract/docs/download/` + values
    // const url = `http://localhost:5000/api/v1/rent/contract/docs/download/7552`

    // let url = `https://localhost:44341/api/RentContractAccounting`;

    const token = authentication.getAccessToken();




    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(response => response.blob())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));






};

export const download_bill = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/contractsheet/download/` + values
    ///api/v1/contractsheet/download/:rent_contract_id

    const token = authentication.getAccessToken();

    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(response => response.blob())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));
};

export const download_contract = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/contractsheet/contract/download/` + values + `/A3`
    ///api/v1/contractsheet/contract/download/::rent_contract_id

    const token = authentication.getAccessToken();

    // alert(JSON.stringify(accountinglistset))
    return fetch(url, {
        method: 'get',
        // body: JSON.stringify(accountinglistset),

        headers: { 'Authorization': 'Bearer ' + token },

    }).then(response => response.blob())
        .then(payload => ({ payload }))
        // .then(res => res.json())
        // .then(payload => ({ payload }))
        .catch(error => ({ error }));
};
export const formUpdate = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;

    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contracts/logs/` + values;
    // const url = `http://localhost:5000/v1/rent/contracts/formUpdate/` + values.id;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/rent/contract/formUpdate/` + values.id;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values.change),
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const createdForm = (values: any) => {
    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contract/follows/list`;
    // const url = `http://localhost:5000/api/v1/rent/contracts/logs/` + values;
    //
    // const url = `http://localhost:5000/api/v1/rent/contracts/detail/` + values;

    // const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/contracts/logs/` + values;
    // const url = `http://localhost:5000/v1/rent/contracts/createApplication/` + values;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/rent/contract/createApplication/` + values;
    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}