import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import IconButton from '@mui/material/IconButton';
import * as Files from './Saga/Files';
import Button from '@mui/material/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GetAppIcon from '@mui/icons-material/GetApp';
import EditIcon from '@mui/icons-material/Edit';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
var moment = require('moment-timezone');

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_response_contract_docs[rowIndex][column.key]}
  </Cell>;

const UpdatedAt = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {moment(props.rent_response_contract_docs[rowIndex][column.key]).format('YYYY-MM-DD HH:mm:ss')}
  </Cell>;

const PName = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <IconButton style={{marginTop:-5}} onClick={(e) => { props.download_rent_response_contract_docs(props.rent_response_contract_docs[rowIndex]) }} aria-label="edit" size="small">
      <GetAppIcon />
    </IconButton>
  </Cell>;

const Edit = ({ column, props, rowIndex, file_remarks_edit_open }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <IconButton style={{marginTop:-5}} onClick={(e)=>{file_remarks_edit_open({ 
      fileid: props.rent_response_contract_docs[rowIndex].id, 
      name: props.rent_response_contract_docs[rowIndex].name, 
      remarks: props.rent_response_contract_docs[rowIndex].remarks ? props.rent_response_contract_docs[rowIndex].remarks : "",
      contractdoc: props.rent_response_contract_docs[rowIndex].contractdoc}) }} aria-label="edit" size="small">
      <EditIcon />
    </IconButton>
  </Cell>;

const files = ['pdf', 'png', 'jpg', 'jpeg', 'bmp', 'gif'];
const Disp = ({ column, props, rowIndex, openWindow }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {files.includes(props.rent_response_contract_docs[rowIndex].mime.trim()) ? <span>
      <Button variant="contained" style={{}} size="small" onClick={() => props.download_disp_rent_response_contract_docs(props.rent_response_contract_docs[rowIndex])} color="primary">
      表示
    </Button>
    <IconButton size="small" onClick={() => openWindow(props.rent_response_contract_docs[rowIndex].id)} color="primary">
      <OpenInNewIcon />
    </IconButton></span>:""}
  </Cell >;

const ContractDoc = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_response_contract_docs[rowIndex].contractdoc == 0 ? "契約書" : 
    props.rent_response_contract_docs[rowIndex].contractdoc == 1 ? "フォローシート":
    props.rent_response_contract_docs[rowIndex].contractdoc == 2 ? "適格請求書":
    props.rent_response_contract_docs[rowIndex].contractdoc == 3 ? "代行会社からの適格請求書":
    ""}
  </Cell>;



var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns = [
  { key: 'p_name', name: 'DL', width: 50 },
  {
    key: 'name', name: '社内ファイル名', width: 200,  },
  { key: 'contractdoc', name: '種類', width: 100 },
  { key: 'remarks', name: '備考', width: 150 },
  { key: 'disp', name: '表示', width: 100 },
  { key: 'edit', name: '編集', width: 60 },
  { key: 'updated_at', name: 'アップロード日時', width: 150 },



]







class CustomerList extends Component {
  constructor(props) {
    super(props);


    // this.props.rent_m_contract_follows_disp.map(async function (value) {
    //   if (value.disp) {
    //     columns.push({ key: value.key_name, name: value.name, company: value.company, width: 80 })
    //   }
    // })
    console.log(columns)

    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);


    // this.contractdetail = this.contractdetail.bind(this);


  }





  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }

  openWindow(id){
    window.open(`/FileDisp/` + '5/' + id, '_blank', 'noopener,width=500,toolbar=yes,menubar=yes,scrollbars=yes')
  }

  render() {
    let hiwari = []

    return (
      <div id="contract_car" >





        {
          this.props.rent_response_contract_docs ? <div style={{ marginBottom: 10 }}><Table
            rowHeight={40}
            rowsCount={this.props.rent_response_contract_docs.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            // width="100%"
            // height={this.props.rent_response_contract_docs.length * 40 + 42}
            // width={window.innerWidth - 110}
            // height={window.innerHeight - 250}
            width={(window.innerWidth / 2) - 75}
            height={350}
            headerHeight={40}>
            {this.state.columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  // value.key === "name" ? <Koumoku column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Koumoku>
                  //   : value.key === "rent_m_etc_id" ? <Seikyuu column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Seikyuu>

                  //     : value.key === "amount" ? <Amount column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Amount>
                  //       : value.key === "delete" ? <Delete column={value} props={this.props} rowIndex={rowIndex} monies={hiwari}></Delete>

                  // :
                  value.key === "disp" ? <Disp column={value} props={this.props} rowIndex={rowIndex} openWindow={this.openWindow} ></Disp>:
                  value.key === "p_name" ? <PName column={value} props={this.props} rowIndex={rowIndex} ></PName> :
                  value.key === "updated_at" ? <UpdatedAt column={value} props={this.props} rowIndex={rowIndex} ></UpdatedAt> : 
                  value.key === "edit" ? <Edit column={value} props={this.props} rowIndex={rowIndex} file_remarks_edit_open={this.props.file_remarks_edit_open} ></Edit>: 
                  value.key === "contractdoc" ? <ContractDoc column={value} props={this.props} rowIndex={rowIndex} ></ContractDoc> :
                  <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

                )}

                width={value.width}
                isResizable={true}


              />
            }, this)}

          </Table > </div> : ""
        }




      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    rent_response_contract_docs: state.files.rent_response_contract_docs,


  }
}
function mapDispatchToProps(dispatch) {
  return {

    // download_disp_application_files(state) {
    //   dispatch(Files.download_disp_application_files(state))
    // },

    download_rent_response_contract_docs(state) {
      dispatch(Files.download_rent_response_contract_docs(state))
    },
    download_rent_response_contract_docs(state) {
      dispatch(Files.download_rent_response_contract_docs(state))
    },
    download_disp_rent_response_contract_docs(state) {
      dispatch(Files.download_disp_rent_response_contract_docs(state))
    },
    // change_responseid(state) {
    //   dispatch(Files.change_responseid(state))
    // },
    // change_modalResponseDetail(state) {
    //   dispatch(Files.change_modalResponseDetail(state))
    // },

    // change_rent_contract_id(state) {
    //   dispatch(Files.change_rent_contract_id(state))
    // },
    // change_rent_contract_money_edit_disp(state) {
    //   dispatch(Files.change_rent_contract_money_edit_disp(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList);