import React, { Component } from 'react';

import { connect } from 'react-redux';

import TatemonoEdit from './TatemonoEditComponent';
import TatemonoDisp from './TatemonoDispComponent';
import Loading from '../Layout/LoadingComponent';

import * as Rooms from './Saga/Rooms';




class TatemonoComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tatemonoedit : 1,
            mode: '1',
            //whichComponent: props.customeredit == 0 ? <CustomerInfoDisp customerdetail={props.customerdetail}/> : <CustomerInfoEdit />
        };
   

    }


    render() {

    return (
        <div>
            {this.state.tatemonoedit === 0 ? <TatemonoDisp roomdetail={this.props.roomdetail} tatemonoeditchange={this.props.tatemonoeditchange} /> : this.props.roomdetail.tatemonoId ? <TatemonoEdit roomdetail={this.props.roomdetail} closed={1} tatemonoeditchange={this.props.tatemonoeditchange} />:<Loading /> }
      　</div>
 
    );
  }
}


//コンテナ
const mapStateToProps = state => {
    return {
        tatemonoedit: state.roomsReducer.tatemonoedit,


    }
}
function mapDispatchToProps(dispatch) {
    return {
    
        get_room_details(state) {
            dispatch(Rooms.get_room_details(state))
        },
        responseUpdate(state) {
            //dispatch(Responses.responseUpdate(state))
        },
        responseloding(state) {
            //dispatch(Responses.responseloding(state))
        }

        
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TatemonoComponent);
