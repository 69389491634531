import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as MapSearch from './Saga/MapSearch';
import '../../Css/GridRooms.css';

import { Table, Column, Cell } from 'fixed-data-table-2';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import LocalParking from '@mui/icons-material/LocalParking';
import Grid from '@mui/material/Grid';
import { Modal, ModalBody } from 'reactstrap';



function PaName ({ column, e, rowIndex }){
  return <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: e.area[rowIndex].kuushitsu_joukyou_no == 1 ? "#fff396" : ""}} >
    {e.area[rowIndex].pa_name}</Cell>;
}

function KuushitsuJoukyouName ({ column, e, rowIndex }){
  return <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: e.area[rowIndex].kuushitsu_joukyou_no == 1 ? "#fff396" : ""}} >
    {e.area[rowIndex].kuushitsu_joukyou_name}</Cell>;
}

function NyuukyoKanouYear ({ column, e, rowIndex }){
  return <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: e.area[rowIndex].kuushitsu_joukyou_no == 1 ? "#fff396" : ""}} >
    {e.area[rowIndex].nyuukyo_kanou_year == "" || e.area[rowIndex].nyuukyo_kanou_year == "-" 
    || e.area[rowIndex].nyuukyo_kanou_month == "" || e.area[rowIndex].nyuukyo_kanou_month == "-" 
    || e.area[rowIndex].nyuukyo_kanou_day == "" || e.area[rowIndex].nyuukyo_kanou_day == "-" ? "" :
    e.area[rowIndex].nyuukyo_kanou_year + "/" + e.area[rowIndex].nyuukyo_kanou_month + "/" + e.area[rowIndex].nyuukyo_kanou_day}</Cell>;
}

function KanouKbnNo ({ column, e, rowIndex }){
  return <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: e.area[rowIndex].kuushitsu_joukyou_no == 1 ? "#fff396" : ""}} >
    {e.area[rowIndex].kanou_kbn_no == 1 ? "普通自動車" 
    : e.area[rowIndex].kanou_kbn_no == 2 ? "軽自動車" 
    : e.area[rowIndex].kanou_kbn_no == 3 ? "大型自動車" 
    : e.area[rowIndex].kanou_kbn_no == 4 ? "小型車" 
    : e.area[rowIndex].kanou_kbn_no == 5 ? "自動二輪(〜中型)" 
    : e.area[rowIndex].kanou_kbn_no == 6 ? "自動二輪(大型)" 
    : e.area[rowIndex].kanou_kbn_no == 7 ? "自転車" : ""}</Cell>;
}

function SeikyuuGaku ({ column, e, rowIndex }){
  return <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: e.area[rowIndex].kuushitsu_joukyou_no == 1 ? "#fff396" : ""}} >
    {e.area[rowIndex].seikyuu_gaku ? e.area[rowIndex].seikyuu_gaku.toLocaleString() + "円" : ""}
    {e.area[rowIndex].seikyuu_gaku && e.area[rowIndex].is_kazei == 1 ? "(税別)" : ""}</Cell>;
}

function Detail ({ column, e, rowIndex }){
  return <Cell style={{whiteSpace: "nowrap", width: "100%", height: "100%", borderTop: "1px solid  #9c9c9c", background: e.area[rowIndex].kuushitsu_joukyou_no == 1 ? "#fff396" : ""}} >
    {e[column]}</Cell>;
}

const columns =
[
  {key: 'pa_name', name:'区画', width: 70, resizeable:true},
  {key: 'kuushitsu_joukyou_name', name:'空室状況', width: 80, resizeable:true},
  {key: 'nyuukyo_kanou_year', name:'入居可能日', width: 90, resizeable:true},
  {key: 'kanou_kbn_no', name:'駐車可能車両', width: 100, resizeable:true},
  {key: 'seikyuu_gaku', name:'賃料', width: 150, resizeable:true},
]

var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};

function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}






class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      columns: columns,
      reflesh: false,
      colSortDirs: {},

      kukaku_modal: false,
      p_id: "",
    };

    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    this._onSortChange = this._onSortChange.bind(this);

    this.props.get_map_parking_list();

  }
  

  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }

  _onSortChange(columnKey, sortDir) {

    let map_parking_list = this.props.map_parking_list;

    map_parking_list.sort((indexA, indexB) => {
      // alert(indexA)

      var valueA = indexA[columnKey];
      var valueB = indexB[columnKey];
      var sortVal = 0;
      if (valueA > valueB) {
        sortVal = 1;
      }
      if (valueA < valueB) {
        sortVal = -1;
      }

      if (!valueA && !valueB) {
        sortVal = 0
      }
      if (!valueA) {
        sortVal = -1
      }
      if (!valueB) {
        sortVal = 1
      }


      if (sortVal !== 0 && sortDir === SortTypes.DESC) {
        sortVal = sortVal * -1;
      }

      return sortVal;
    });
    this.props.change_map_parking_list(map_parking_list)
    this.setState({
      // sortedDataList: new DataListWrapper(sortIndexes, this._dataList),
      colSortDirs: {
        [columnKey]: sortDir,
      },
    });
  }

  // }

  render() {
    //     // console.log(this.props.customerlists)
    //     // alert(this.props.customerlists+"sss")
    //     // const ss:any=[];
    return (
      <div>
        {
        this.props.map_parking_list ? this.props.map_parking_list.map(function (e) {
          
        return (
          <div style={{marginBottom:20}}>



          <div>
            <LocalParking /><span style={{fontSize:15}}>{e.p_name}</span>
          </div>  
          <Grid container spacing={1}>
            <Grid justifyContent="space-between" item xs={4}>
              <img src={"https://img.global-center.co.jp/v1/parking/" + e.p_id + "/3/1/400/400/0"} alt="図面" onClick={(a) => { this.setState({ "kukaku_modal": true, "p_id" : e.p_id }); }} />
            </Grid>
            <Grid justifyContent="space-between" item xs={4}>
          <Table
            className='parkingList'
            rowHeight={28}
            rowsCount={e.area.length}
            onColumnResizeEndCallback={this._onColumnResizeEndCallback}
            isColumnResizing={false}
            touchScrollEnabled={true}
            width={(window.innerWidth - 110) / 3}
            height={260}
            headerHeight={20}>

            {columns.map(function (value, i) {
              return <Column
                columnKey={value.key}
                header={<SortHeaderCell
                  onSortChange={this._onSortChange}
                  sortDir={this.state.colSortDirs[value.key]}
                  style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

                // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
                cell={({ rowIndex, ...props }) => (

                  value.key === "pa_name" ? <PaName column={value} e={e} rowIndex={rowIndex}></PaName>
                   : value.key === "kuushitsu_joukyou_name" ? <KuushitsuJoukyouName column={value} e={e} rowIndex={rowIndex}></KuushitsuJoukyouName>
                   : value.key === "nyuukyo_kanou_year" ? <NyuukyoKanouYear column={value} e={e} rowIndex={rowIndex}></NyuukyoKanouYear>
                   : value.key === "kanou_kbn_no" ? <KanouKbnNo column={value} e={e} rowIndex={rowIndex}></KanouKbnNo>
                   : value.key === "seikyuu_gaku" ? <SeikyuuGaku column={value} e={e} rowIndex={rowIndex}></SeikyuuGaku>
                    : <Detail column={value} e={e} rowIndex={rowIndex}></Detail>
                )}

                width={columns.filter(a => a.key === value.key)[0].width}
                isResizable={true}
                fixed={value.frozen}

              />
            }, this)}

          </Table >
          </Grid>
          <Grid justifyContent="space-between" item xs={4}>
            <table>
              <tr>
                <td style={{width:60}}>住所</td>
                <td>{e.zip_1}-{e.zip_2} {e.todoufuken}{e.shikuchoson}{e.choiki}{e.other_address}</td>
              </tr>
              <tr>
                <td>区画数</td>
                <td>{e.soukukakusuu}区画</td>
              </tr>
              <tr>
                <td>備考</td>
                <td>{e.bikou ? <div dangerouslySetInnerHTML={ { __html: e.bikou.replace(/\r?\n/g, '<br />') } } /> : ""}</td>
              </tr>
            </table>
          </Grid>
          </Grid>
          </div>
        );
        },this) : ""
      }


        <Modal isOpen={this.state.kukaku_modal} toggle={(e) => { this.setState({ "kukaku_modal": false }) }} size="xl" fade={false} className="modal-dialog-centered">
            {/* <ModalHeader>
                {this.props.mapsearch.map_room_details[0] ? this.props.mapsearch.map_room_details[0].tatemono_name : ""}　駐車場
                <IconButton aria-label="close" style={{position:'absolute', right:1, top:1}} onClick={(e) => { this.setState({ "kukaku_modal": false }) }}>
                    <CloseIcon />
                </IconButton>
            </ModalHeader> */}
            <ModalBody style={{textAlign:"center"}}>
            <img src={"https://img.global-center.co.jp/v1/parking/" + this.state.p_id + "/3/1/700/700/0"} alt="図面" />
            </ModalBody>
            {/* <ModalFooter>
                <Button variant="contained" color="" style={{ marginLeft: 3 }} onClick={(e) => { this.setState({ "kukaku_modal": false }) }}>閉じる</Button>
            </ModalFooter> */}

        </Modal>

      </div>
    );
  }

}


// }

const mapStateToProps = (state) => {
  return {
    map_room_details_all: state.mapsearch.map_room_details_all,
    map_parking_list: state.mapsearch.map_parking_list,


  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_map_room_details_all(state) {
        dispatch(MapSearch.change_map_room_details_all(state))
    },
    get_map_parking_list(state) {
      dispatch(MapSearch.get_map_parking_list(state))
    },
    change_map_parking_list(state) {
      dispatch(MapSearch.change_map_parking_list(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);