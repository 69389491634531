const authentication = require('../../../react-azure-adb2c2').default;
// ●
export const list = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/templates/list/` + values
    // const url = `http://localhost:5000/v1/rent/introduction/point/templates/list/` + values

    const token = authentication.getAccessToken();
    //const search = values ? values : {};


    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

// ●
export const detail = (values: any) => {
    // alert(values)
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/templates/detail/` + values;
    // const url = `http://localhost:5000/v1/rent/introduction/point/templates/detail/` + values;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(url)

    return fetch(url, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

// ●
export const insert = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/templates/insert`
    // const url = `http://localhost:5000/v1/rent/introduction/point/templates/insert`

    const token = authentication.getAccessToken();

    // alert(JSON.stringify(values))
    //const search = values ? values : {};
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify({id:values}),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

// ●
export const update = (values: any) => {
    console.log(values)
    // alert(values.id ? values.id : 0)
    // const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/templates/update/` + (values.id ? values.id : 0)
    // const url = `http://localhost:5000/v1/rent/introduction/point/templates/update/` + (values.id ? values.id : 0)
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/templates/update/` + values.type // 0:新規 1:更新 2:テンプレートコピー
    // const url = `http://localhost:5000/v1/rent/introduction/point/templates/update/` + values.type // 0:新規 1:更新 2:テンプレートコピー

    const token = authentication.getAccessToken();
    console.log(values)

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values.rent_introduction_point_templates),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

// ●
export const deleteheaders = (values: any) => {
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/templates/delete/` + values;
    // const url = `http://localhost:5000/v1/rent/introduction/point/templates/delete/` + values;
    const token = authentication.getAccessToken();

    return fetch(url, {
        method: 'delete',
        // body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}


//●
export const add = (values: any) => {

    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/templates/add`
    // const url = `http://localhost:5000/v1/rent/introduction/point/templates/add`

    const token = authentication.getAccessToken();

    // alert(JSON.stringify(values))
    //const search = values ? values : {};
    // alert(url)
    // alert(JSON.stringify(values))
    return fetch(url, {
        method: 'post',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const update_point = (values: any) => {
    console.log(values)
    const url = process.env.REACT_APP_API_CRM_URL + `/v1/rent/introduction/point/templates/pointupdate/` +  values.id
    // const url = `http://localhost:5000/v1/rent/introduction/point/templates/pointupdate/` +  values.id

    const token = authentication.getAccessToken();
    console.log(values)

    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    })
        .then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}