import React, { useState, useEffect, useRef } from 'react';
import { connect, Field, reduxForm } from 'react-redux';
import makeStyles from '@mui/styles/makeStyles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import * as Mail from './Saga/Mail';
import WebIcon from '@mui/icons-material/Web';
const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
}));

function ListDividers(props) {
  // const classes = useStyles();




  //   const node = useRef();
  const select = (mail_template_id, rent_introduction_header_id, rentResponseId) => {
    // alert(rentResponseId)
    // alert(props.responsedetail.id)
    if (rentResponseId === props.responsedetail.id) {
      if (rent_introduction_header_id || mail_template_id === 179) {

        if (window.editorInstance2) {
          props.add_mypage_link({ window: window, id: mail_template_id, rentResponseId: props.responsedetail.id, rent_introduction_header_id: rent_introduction_header_id })
        }
        
        props.togglemypageclose()
      } else {

      }
    }
  }
  return (
    <List component="nav" style={{backgroundColor:"white"}} aria-label="mailbox folders">
      <ListItem　>
        <WebIcon /> <b >マイページ選択</b>
      </ListItem>
      <Divider />
      {props.mail_rent_introduction_header_list.map((value, key) => (
        <span key={key}>
          <ListItem onClick={() => select(186, value.id, value.rentResponseId)} button>
            <ListItemText primary={value.name} /> <MailOutlineIcon />

          </ListItem>
          <Divider />
        </span>
      ))}


      <ListItem onClick={() => select(179, "", props.responsedetail.id)} button>
        <ListItemText primary="旧マイページ" /> <MailOutlineIcon />
      </ListItem>
    </List>
  );
}


const mapStateToProps = state => {
  return {
    responsedetail: state.responsesReducer.responsedetail,
    mail_rent_introduction_header_list: state.mailReducer.mail_rent_introduction_header_list,
    //     map_settings_edit: state.mapmypage.map_settings_edit,
    //     rent_introduction_headers:state.mapmypage.rent_introduction_headers,
    //     map_disp:state.mapmypage.map_disp,

    //    rent_introduction_point_headers:state.mapmypage.rent_introduction_point_headers,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    //firebasedata: state.firebaseReducer.firebasedata,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    add_mypage_link(state) {
      dispatch(Mail.add_mypage_link(state))
    },
    get_mail_rent_introduction_header_list(state) {
      dispatch(Mail.get_mail_rent_introduction_header_list(state))
    },
  };
}

// App = reduxForm({
//     form: 'mapsearchform', // a unique identifier for this form
//     validate,//バリデーション

//     //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(App)
// App = withStyles(useStyles)(App);  
export default connect(mapStateToProps, mapDispatchToProps)(ListDividers);   