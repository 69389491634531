import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Container, Draggable } from "react-smooth-dnd";
import { connect } from 'react-redux';
import arrayMove from "array-move";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemSecondaryAction from "@mui/material/ListItemSecondaryAction";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import makeStyles from '@mui/styles/makeStyles';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Radio from '@mui/material/Radio';
import Divider from '@mui/material/Divider';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import * as ContractFollow from './Saga/ContractFollow';

// const useStyles = makeStyles((theme) => ({
//     root: {
//         margin: 'auto',
//     },
//     paper: {
//         width: 200,
//         height: 230,
//         overflow: 'auto',
//     },
//     button: {
//         margin: theme.spacing(0.5, 0),
//     },
// }));

// function not(a: any, b: any) {
//     return a.filter((value: any) => b.indexOf(value) === -1);
// }

// function intersection(a: any, b: any) {
//     return a.filter((value: any) => b.indexOf(value) !== -1);
// }


const SortableList = (props: any) => {
    const [items, setItems] = useState([
        { id: "1", text: "Item 1" },
        { id: "2", text: "Item 2" },
        { id: "3", text: "Item 3" },
        { id: "4", text: "Item 4" }
    ]);

    // const onDrop = ({ removedIndex, addedIndex }: any) => {

    //     setItems(items => arrayMove(items, removedIndex - 1, addedIndex - 1));
    // };
    const onDrop = ({ removedIndex, addedIndex }: any) => {
        setItems(items => {
            const newItems = [...items];
            const [movedItem] = newItems.splice(removedIndex, 1);
            newItems.splice(addedIndex, 0, movedItem);
            return newItems;
        });
    };
    // const display = (id: any) => {
    //     let rent_m_contract_follows_disp = props.rent_m_contract_follows_disp
    //     let rent_m_contract_follows_none = props.rent_m_contract_follows_none
    //     rent_m_contract_follows_disp.push(rent_m_contract_follows_none.filter((a: any) => a.id === id)[0])
    //     rent_m_contract_follows_none = rent_m_contract_follows_none.filter((a: any) => a.id !== id)


    //     let rent_m_contract_follow_settings = props.rent_m_contract_follow_settings
    //     let frozen = true
    //     let follows = rent_m_contract_follows_disp.map(function (value: any) {


    //         value.frozen = frozen
    //         if (rent_m_contract_follow_settings.frozen_key_name === value.key_name) {
    //             frozen = false
    //         }

    //         return value
    //     })
    //     props.change_rent_m_contract_follows_disp([])
    //     props.change_rent_m_contract_follows_disp(follows)


    //     props.change_rent_m_contract_follows_none([])

    //     props.change_rent_m_contract_follows_none(rent_m_contract_follows_none)

    // };
    const display = (id: any) => {
        let rent_m_contract_follows_disp = props.rent_m_contract_follows_disp;
        let rent_m_contract_follows_none = props.rent_m_contract_follows_none;
        rent_m_contract_follows_disp.push(rent_m_contract_follows_none.filter((a: any) => a.id === id)[0]);
        rent_m_contract_follows_none = rent_m_contract_follows_none.filter((a: any) => a.id !== id);

        let rent_m_contract_follow_settings = props.rent_m_contract_follow_settings;
        let frozen = true;
        let follows = rent_m_contract_follows_disp.map(function (value: any) {
            value.frozen = frozen;
            if (rent_m_contract_follow_settings.frozen_key_name === value.key_name) {
                frozen = false;
            }
            return value;
        });
        props.change_rent_m_contract_follows_disp(follows);
        props.change_rent_m_contract_follows_none(rent_m_contract_follows_none);
    };
    return (
        <List>
                <Divider />
                {props.rent_m_contract_follows_none.map(({ id, name }: any) => (
                    <>
                        <ListItem>

                            <IconButton aria-label="AddCircleOutlineIcon" onClick={() => display(id)} >
                                <AddCircleOutlineIcon color="primary" />
                            </IconButton>

                            <ListItemText primary={name} />
                        </ListItem>
                        <Divider />
                    </>
                ))}
        </List>
    );
};



const mapStateToProps = (state: any) => {
    return {
        users_select: state.masterReducer.users_select,

        user_details: state.masterReducer.user_details,
   
        rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
        rent_m_contract_follows_none: state.contractfollow.rent_m_contract_follows_none,
        rent_m_contract_follow_settings: state.contractfollow.rent_m_contract_follow_settings,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {
        change_rent_m_contract_follows_disp(state: any) {
            dispatch(ContractFollow.change_rent_m_contract_follows_disp(state))
        },
        change_rent_m_contract_follows_none(state: any) {
            dispatch(ContractFollow.change_rent_m_contract_follows_none(state))
        },
        change_rent_m_contract_follow_settings(state: any) {
            dispatch(ContractFollow.change_rent_m_contract_follow_settings(state))
        },
      
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(SortableList);

