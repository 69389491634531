import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns'
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';


class JaLocalizedUtils extends DateFnsUtils {
    // ヘッダ部分のテキストを取得するメソッド
    getDatePickerHeaderText(date: Date) {
        return format(date, 'M月d日(E)', { locale: this.locale })
    }
}


const test = [
    { value: "選択肢１", label: "選択肢１" },
    { value: "選択肢２", label: "選択肢２" },
    { value: "選択肢３", label: "選択肢３" },
]

interface Props {
    users_select: any,
    user_details: any
}

interface State {
}

class ResponseFollowSheet extends Component<Props, State> {


    constructor(props: Readonly<Props>) {
        super(props);

        this.save = this.save.bind(this);

    }


    save() {
        console.log(this.props.user_details);
        alert("保存していません。")
    }

    render() {
        return (
            <div className="item-outer col-lg-12">

                <div>
                    <TextField
                        id="uketuke_Date1"
                        label="売上日"
                        type="date"
                        name="uketuke_Date1"
                        size="small"
                        variant='outlined'
                        // onChange={this.props.onChangeuketukeDate1}
                        // value={moment(this.props.uketuke_Date1).format("YYYY-MM-DD")}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    {/* <MuiPickersUtilsProvider utils={JaLocalizedUtils} locale={jaLocale}>
                        <KeyboardDatePicker
                            variant="inline"
                            margin="normal"
                            id="date-picker-dialog"
                            label="売上日"
                            format="yyyy/MM/dd"
                            style={{ width: 130 }}
                            // value={this.props.uketuke_Date1}
                            // onChange={this.props.onChangeuketukeDate1}
                            autoOk={true}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider> */}
                </div>

                <div style={{ marginTop: 20 }}>
                    <TextField
                        autoComplete='off'
                        style={{ width: 400 }}
                        id="standard-basic"
                        // onChange={this.props.onChangekokyaku_name}
                        label="お客様名"
                    />
                </div>

                <div style={{ marginTop: 20 }}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={this.props.users_select}
                        getOptionLabel={(option: any) => option.label}
                        style={{ width: 200 }}
                        // onChange={this.props.onChangejudensya}
                        renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="担当" />}
                    />
                </div>


                <div style={{ marginTop: 20 }}>
                    <TextField
                        autoComplete='off'
                        style={{ width: 500 }}
                        id="standard-basic"
                        // onChange={this.props.onChangekokyaku_name}
                        label="売上名"
                    />
                </div>

                <div style={{ marginTop: 20 }}>
                    <Autocomplete
                        id="combo-box-demo"
                        options={test}
                        getOptionLabel={(option: any) => option.label}
                        style={{ width: 200 }}
                        // onChange={this.props.onChange_type}
                        renderInput={(params) => <TextField
                            autoComplete='off' {...params} label="売上" />}
                    />
                </div>


                <div style={{ marginTop: 20 }}>
                    <TextField
                        autoComplete='off'
                        id="standard-number"
                        label="売上額"
                        type="number"
                        // value={this.state.Shokai_M}
                        // onChange={this.onChangeShokai_M}
                        style={{ width: 200 }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </div>


                <div style={{ marginTop: 20 }}>
                    <TextField
                        autoComplete='off'
                        id="outlined-read-only-input"
                        label="備考"
                        fullWidth
                        multiline
                        rows={3}
                        variant="outlined"
                    // onChange={this.onChangeNaiyou}
                    // style={{ marginTop: "10px" }}
                    />
                </div>

                <div style={{ marginTop: 20 }}>
                    <Button
                        onClick={this.save}
                        style={{ float: "right", margin: 5 }}
                        color="primary" variant="outlined">
                        保存</Button>
                </div>

            </div >
        );
    }
};


const mapStateToProps = (state: any) => {
    return {
        users_select: state.masterReducer.users_select,
        user_details: state.masterReducer.user_details,

    }
}
function mapDispatchToProps(dispatch: any) {
    return {
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResponseFollowSheet);




