import actionCreatorFactory from 'typescript-fsa';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import { call, put, takeEvery, select } from 'redux-saga/effects';
import * as rent_customer_contacts_api from '../Api/rent_customer_contacts_api';
import moment from 'moment';


export interface TaskState {
    rent_tasks_disp_form: any;
    rent_tasks_modal: boolean;
    task_rent_responses_list: any;
    task_rent_responses_list_search: any;
    task_backdrop: boolean;
}
const initialState: TaskState = {

    rent_tasks_disp_form: {
        disp_tab: 0, 
        section_id: 0, 
        user_id: 0, 
        start: moment().startOf('day').format('YYYY/MM/DD HH:mm:ss'),
        // start: null,
        end: moment().endOf('day').format('YYYY/MM/DD HH:mm:ss'),
        is_completed: true, 
        is_auto_mail: true, 
        count_type_0: 0,
        count_type_1: 0,
        count_type_2: 0,
        count_type_3: 0,
        count_type_4: 0,
    },

    rent_tasks_modal: false,

    task_rent_responses_list:[],
    task_rent_responses_list_search:[],

    task_backdrop: false,

}



///action 
const actionCreator = actionCreatorFactory();

export const get_task_rent_responses_list = actionCreator("GET_TASK_RENT_RESPONSES_LIST");
export const change_task_rent_responses_list = actionCreator<any>("CHANGE_TASK_RENT_RESPONSES_LIST");
export const search_task_rent_responses_list = actionCreator("SEARCH_TASK_RENT_RESPONSES_LIST");
export const change_task_rent_responses_list_search = actionCreator<any>("CHANGE_TASK_RENT_RESPONSES_LIST_SEARCH");
export const change_rent_tasks_disp_form = actionCreator("CHANGE_RENT_TASKS_DISP_FORM");
export const change_rent_tasks_modal = actionCreator<boolean>("CHANGE_RENT_TASKS_MODAL");
export const change_task_backdrop = actionCreator<boolean>("CHANGE_TASK_BACKDROP");



export const taskReducer = reducerWithInitialState(initialState)
    .case(change_rent_tasks_disp_form, (state, payload) => ({ ...state, rent_tasks_disp_form: payload }))
    .case(change_rent_tasks_modal, (state, payload) => ({ ...state, rent_tasks_modal: payload }))
    .case(change_task_rent_responses_list, (state, payload) => ({ ...state, task_rent_responses_list: payload }))
    .case(change_task_rent_responses_list_search, (state, payload) => ({ ...state, task_rent_responses_list_search: payload }))
    .case(change_task_backdrop, (state, payload) => ({ ...state, task_backdrop: payload }))



// 「LOGIN_REQUEST」アクションが呼ばれるのを待つ呼ばれたhandleRequestを実行
export const taskSaga = [
    
    takeEvery('GET_TASK_RENT_RESPONSES_LIST', handle_get_task_rent_responses_list),
    takeEvery('SEARCH_TASK_RENT_RESPONSES_LIST', handle_search_task_rent_responses_list),

];
// export default masterSaga;



function* handle_get_task_rent_responses_list(action: any): any {

    yield put(change_task_backdrop(true));
    const state = yield select();

    const reset: any = [];
    yield put(change_task_rent_responses_list([]));
    
    let rent_tasks_disp_form = state.task.rent_tasks_disp_form
  
    const { payload, error } = yield call(rent_customer_contacts_api.task, rent_tasks_disp_form);
  
  
    // サーバーからのレスポンスデータによる分岐処理
    //if (ログイン成功か失敗かの条件) {
    // 成功時アクション呼び出し
  
    if (payload && !error) {
  
      // サーバーからのレスポンスデータによる分岐処理
      //if (ログイン成功か失敗かの条件) {
      // 成功時アクション呼び出し
    //   alert()
      console.log(payload)
    
      yield put(change_task_rent_responses_list(payload));
      yield put(search_task_rent_responses_list(payload));
    //   yield put(change_task_rent_responses_list2(payload));
  
  
    yield put(change_task_backdrop(false));
  
     
    } else {

    }
  
  
  }
  
  function* handle_search_task_rent_responses_list(action: any): any {

    const state = yield select();

    yield put(change_task_rent_responses_list_search([]));

    let rent_tasks_disp_form = state.task.rent_tasks_disp_form
    let task_rent_responses_list = state.task.task_rent_responses_list
    
    let data = task_rent_responses_list

    // 担当
    if(rent_tasks_disp_form.user_id){
        data = data.filter((a:any)=> a.contract_user_id == rent_tasks_disp_form.user_id || a.user_id == rent_tasks_disp_form.user_id)
    }else if(rent_tasks_disp_form.section_id == 501 || rent_tasks_disp_form.section_id == 502 || rent_tasks_disp_form.section_id == 503){// 1課2課3課の時
        data = data.filter((a:any)=> (rent_tasks_disp_form.section_id == 501 ? a.section_id == 5 && a.section_group == 1 : rent_tasks_disp_form.section_id == 502 ? a.section_id == 5 && a.section_group == 2 : a.section_id == rent_tasks_disp_form.section_id))
    }

    

    // 完了になってないやつだけ
    if(rent_tasks_disp_form.is_completed){
        data = data.filter((a:any)=> (a.type == 1 && a.intend) || (a.type == 2 && a.progress != 2) || ((a.type == 3 || a.type == 5) && a.completed_at == null))
        // 追客とその他は以前のも
        if(rent_tasks_disp_form.start){
            data = data.filter((a:any)=> 
            (a.type == 1 && a.way == 3 && moment(a.date).utc().format("YYYYMMDD") <= moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 来店 今日だけ
            (a.type == 1 && a.way != 3 && moment(a.date).utc().format("YYYYMMDD") <= moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 追客 今日以前
            (a.type == 2 && moment(a.date).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 契約　今日だけ
            ((a.type == 3 || a.type == 5) && moment(a.start).utc().format("YYYYMMDD") <= moment(rent_tasks_disp_form.start).format("YYYYMMDD"))) // その他　今日以前
        }
    }else{
        // // この日のやつだけ　// 1:来店とか 2:契約フォロー 3:タスク 4:予定件数 5:フリー 　　 日付が変わったらはじくため。
        // // 追客とその他も今日の
        // if(rent_tasks_disp_form.start){
        //     data = data.filter((a:any)=> 
        //     (a.type == 1 && a.way == 3 && moment(a.date).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 来店 今日
        //     (a.type == 1 && a.way != 3 && moment(a.date).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 追客 今日
        //     (a.type == 2 && moment(a.date).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 契約　今日
        //     ((a.type == 3 || a.type == 5) && moment(a.start).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD"))) // その他　今日
        // }

        //今日のまたは未完了の
        data = data.filter((a:any)=> //未完了のと今日のもの
            (a.type == 1 && a.intend) || //未完了
            (a.type == 2 && a.progress != 2) || //未完了
            ((a.type == 3 || a.type == 5) && a.completed_at == null) || //未完了

            // (a.type == 1 && a.way == 3 && moment(a.date).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 来店 今日
            // (a.type == 1 && a.way != 3 && moment(a.date).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 追客 今日
            (a.type == 1 && moment(a.date).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 来店 今日
            (a.type == 2 && moment(a.date).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) || // 契約　今日
            ((a.type == 3 || a.type == 5) && moment(a.start).utc().format("YYYYMMDD") == moment(rent_tasks_disp_form.start).format("YYYYMMDD")) // その他　今日

            )
    }

    // メール自動送信以外
    if(rent_tasks_disp_form.is_auto_mail){
        data = data.filter((a:any)=> !(a.type == 1 && a.way == 2 && a.mail_template_id > 0))
    }

    //　カレンダーと同じAPIを使っているため余分なやつははじく　今は分けた。
    // data = data.filter((a:any) => a.type == 1 || a.type == 2 || a.type == 3 || a.type == 5)

    //件数集計
    rent_tasks_disp_form.count_type_0 = data.length
    rent_tasks_disp_form.count_type_1 = data.filter((a:any)=> (a.type == 1 && a.way == 3)).length// 3来店
    rent_tasks_disp_form.count_type_4 = data.filter((a:any)=> (a.type == 1 && a.way != 3)).length// 追客
    rent_tasks_disp_form.count_type_2 = data.filter((a:any)=> (a.type == 2)).length// 契約フォロー
    rent_tasks_disp_form.count_type_3 = data.filter((a:any)=> (a.type == 3 || a.type == 5)).length// その他
    rent_tasks_disp_form.count_type_5 = data.filter((a:any)=> a.type == 1 && a.way != 3 && (a.id == a.syokai_taiou_rent_customer_id)).length// 初回対応


    // タブ // 0:出勤 1:来店とか 2:契約フォロー 3:タスク 4:予定件数 5:フリー
    if(rent_tasks_disp_form.disp_tab == 1){// 来店
        data = data.filter((a:any)=> (a.type == 1 && a.way == 3))
    }else if(rent_tasks_disp_form.disp_tab == 4){// 追客
        data = data.filter((a:any)=> (a.type == 1 && a.way != 3))
    }else if(rent_tasks_disp_form.disp_tab == 2){// 契約フォロー 
        data = data.filter((a:any)=> (a.type == 2))
    }else if(rent_tasks_disp_form.disp_tab == 3){// その他
        data = data.filter((a:any)=> (a.type == 3 || a.type == 5))
    }else if(rent_tasks_disp_form.disp_tab == 5){// 初回対応
        data = data.filter((a:any)=> a.type == 1 && a.way != 3 && a.id == a.syokai_taiou_rent_customer_id)
    }

    // 時間でソート
    data.sort(function (a: any, b: any) {
      if (moment(a.type == 1 && a.way != 3 ? a.date : a.start) > moment(b.type == 1 && b.way != 3 ? b.date : b.start)) return 1;
      if (moment(a.type == 1 && a.way != 3 ? a.date : a.start) <= moment(b.type == 1 && b.way != 3 ? b.date : b.start) || a.start == "Invalid date" ) return -1;
      return 0;
    });


    yield put(change_rent_tasks_disp_form(JSON.parse(JSON.stringify(rent_tasks_disp_form))))
    yield put(change_task_rent_responses_list_search(data));

  }

