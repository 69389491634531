import React, { Component, useRef, useState } from 'react';
import { connect } from 'react-redux';


import * as MailTemplate from './Saga/MailTemplate';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import ParamGrid from './ParamGrid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

import { CKEditor } from '@ckeditor/ckeditor5-react';

import {
	ClassicEditor,
	AccessibilityHelp,
	AutoLink,
	Autosave,
	Bold,
	Code,
	CodeBlock,
	Essentials,
	FontBackgroundColor,
	FontColor,
	// FontFamily,
	FontSize,
	FullPage,
	GeneralHtmlSupport,
	Heading,
	Highlight,
	HtmlComment,
	HtmlEmbed,
	Italic,
	Link,
	Paragraph,
	RemoveFormat,
	SelectAll,
	ShowBlocks,
	SourceEditing,
	SpecialCharacters,
	SpecialCharactersArrows,
	SpecialCharactersCurrency,
	SpecialCharactersEssentials,
	SpecialCharactersLatin,
	SpecialCharactersMathematical,
	SpecialCharactersText,
	Strikethrough,
	Subscript,
	Superscript,
	Table,
	TableCaption,
	TableCellProperties,
	TableColumnResize,
	TableProperties,
	TableToolbar,
	Underline,
	Undo
} from 'ckeditor5';

import translations from 'ckeditor5/translations/ja.js';

import 'ckeditor5/ckeditor5.css';

// import './ckeditor.css';


const editorConfig = {
  toolbar: {
    items: [
      'undo',
      'redo',
      '|',
      'sourceEditing',
      'showBlocks',
      'selectAll',
      '|',
      'heading',
      '|',
      'fontSize',
      // 'fontFamily',
      'fontColor',
      'fontBackgroundColor',
      '|',
      'bold',
      'italic',
      'underline',
      'strikethrough',
      'subscript',
      'superscript',
      'code',
      'removeFormat',
      '|',
      'specialCharacters',
      'link',
      'insertTable',
      'highlight',
      'codeBlock',
      'htmlEmbed',
      '|',
      'accessibilityHelp'
    ],
    shouldNotGroupWhenFull: false
  },
  plugins: [
    AccessibilityHelp,
    AutoLink,
    Autosave,
    Bold,
    Code,
    CodeBlock,
    Essentials,
    FontBackgroundColor,
    FontColor,
    // FontFamily,
    FontSize,
    FullPage,
    GeneralHtmlSupport,
    Heading,
    Highlight,
    HtmlComment,
    HtmlEmbed,
    Italic,
    Link,
    Paragraph,
    RemoveFormat,
    SelectAll,
    ShowBlocks,
    SourceEditing,
    SpecialCharacters,
    SpecialCharactersArrows,
    SpecialCharactersCurrency,
    SpecialCharactersEssentials,
    SpecialCharactersLatin,
    SpecialCharactersMathematical,
    SpecialCharactersText,
    Strikethrough,
    Subscript,
    Superscript,
    Table,
    TableCaption,
    TableCellProperties,
    TableColumnResize,
    TableProperties,
    TableToolbar,
    Underline,
    Undo
  ],
  fontFamily: {
    supportAllValues: true
  },
  fontSize: {
    options: [10, 12, 14, 'default', 18, 20, 22],
    supportAllValues: true
  },
  heading: {
    options: [
      {
        model: 'paragraph',
        title: 'Paragraph',
        class: 'ck-heading_paragraph'
      },
      {
        model: 'heading1',
        view: 'h1',
        title: 'Heading 1',
        class: 'ck-heading_heading1'
      },
      {
        model: 'heading2',
        view: 'h2',
        title: 'Heading 2',
        class: 'ck-heading_heading2'
      },
      {
        model: 'heading3',
        view: 'h3',
        title: 'Heading 3',
        class: 'ck-heading_heading3'
      },
      {
        model: 'heading4',
        view: 'h4',
        title: 'Heading 4',
        class: 'ck-heading_heading4'
      },
      {
        model: 'heading5',
        view: 'h5',
        title: 'Heading 5',
        class: 'ck-heading_heading5'
      },
      {
        model: 'heading6',
        view: 'h6',
        title: 'Heading 6',
        class: 'ck-heading_heading6'
      }
    ]
  },
  htmlSupport: {
    allow: [
      {
        name: /^.*$/,
        styles: true,
        attributes: true,
        classes: true
      }
    ]
  },
  language: 'ja',
  link: {
    addTargetToExternalLinks: true,
    defaultProtocol: 'https://',
    decorators: {
      toggleDownloadable: {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'file'
        }
      }
    }
  },
  // placeholder: 'Type or paste your content here!',
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells', 'tableProperties', 'tableCellProperties']
  },
  translations: [translations]
};


function CkEditorComponent(props) {

  const [modal, setModal] = useState(false);
  const editorRef = useRef(null);

  const handleEditorReady = (editor) => {
    editorRef.current = editor;
    window.editorInstance = editor; // グローバルに保存
};
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     data: "",
  //     modal: false,
  //     // editorRef: useRef(null)
  //   };

  //   // this.toggle = this.toggle.bind(this);
  //   // this.paramset = this.paramset.bind(this);

  // }

  // toggle() {
  //   this.setState({ modal: !this.state.modal })
  // }



  // paramset = (text) => {
  //   // let editor = window.CKEDITOR.instances.editor2;
  //   let cnt = 0
  //   if(Object.keys(window.CKEDITOR.instances).length == 2){//1つなら左のテンプレートボタンから。2つならメール送るところからテンプレートを開いたとき
  //     cnt = 1
  //   }

  //   let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[cnt]];

  //   editor.insertHtml(text);
  //   // editor.insertText(text);
  //   this.toggle()
  // }

  const paramset = (text) => {
    // let editor = window.CKEDITOR.instances.editor2;
    // let cnt = 0
    // if(Object.keys(window.CKEDITOR.instances).length == 2){//1つなら左のテンプレートボタンから。2つならメール送るところからテンプレートを開いたとき
    //   cnt = 1
    // }

    // let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[cnt]];

    // editor.insertHtml(text);
    // // editor.insertText(text);


    if (window.editorInstance) {
      const editor = window.editorInstance;
      const selection = editor.model.document.selection;

      // 現在のカーソル位置を取得
      const position = selection.getFirstPosition();
    
      console.log("positionnnn2", position)


      // 例: カーソル位置に "Hello, World!" を挿入
      editor.model.change(writer => {
          writer.insertText(text, position);
      });
    }

    setModal(false)
  }


  const html = (html) => {
    // let editor = window.CKEDITOR.instances.editor2
    let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];
    ;
    editor.insertHtml(html);
  }
  // focus = () => {

  //   this.editor.focus();
  //   this.editor.insertText("eddd");
  // };


    return (
      
      <div>

      <div style={{height:props.height == null ? 500 : props.height, overflow: "auto"}}>
        <CKEditor 
          editor={ClassicEditor} 
          config={editorConfig} 

          data={props.mail_template_details.body_all ? props.mail_template_details.body_all : ""}
          // onChange={handleChange}
          onReady={ editor => handleEditorReady(editor) }


          onBlur={(event, editor) => {
            const data = editor.getData();

            let mail_template_details = props.mail_template_details
            mail_template_details.body_all = data
            props.change_mail_template_details(mail_template_details)


          }}

        />
      </div>
        {/* <CkEditor
//テンプレート
          data={this.props.mail_template_details.body_all}

          onChange={(event, editor) => {

            const data = event.editor.getData();
            let mail_template_details = this.props.mail_template_details
            mail_template_details.body_all = data
            this.props.change_mail_template_details(mail_template_details)

            this.setState({
              data,
            });


            console.log({ event, editor, data });
          }}
          onBlur={editor => {
            console.log('Blur.', editor);
          }}
          onFocus={editor => {
            console.log('Focus.', editor);

          }}
          onSelectionChange={editor => {
            console.log()

          }}


          config={{
            uploadUrl: 'localhost',
            height: this.props.height == null ? 500 : this.props.height,
            // height: 200,
            language: 'ja',
            extraPlugins: 'image2,uploadimage,colorbutton,colordialog',
            allowedContent: true,
            enterMode: 2,//エンターを押したときにpタグじゃなbrを挿入

            toolbar: [['Bold'], ['Italic'], ['image'], ['Link'], ['Format', 'Font', 'FontSize'], ['FontSize'], ['TextColor', 'BGColor'], ['Table'], ['Source'], ['Undo'], ['Redo']]

          }}
        /> */}

        <button
          type="button"
          className="btn btn-sm btn-primary float-left"
          style={{ margin: '5px' }}
          onClick={() => {setModal(true)}}>自動項目</button>


          <Dialog open={modal} style={{zIndex:1052}} maxWidth="md" fullWidth={true} onClose={() => {setModal(false)}}>
            <DialogTitle>
            項目選択
              <IconButton
                aria-label="close"
                onClick={() => {setModal(false)}}
                style={{float: 'right'}}
                size="large">
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <div style={{padding:20}}>
              <ParamGrid display={100} is_text={0} paramset={a => paramset(a)} />
            </div>
          </Dialog>


      </div>
    );
  }






const mapStateToProps = (state) => {
  return {
    mail_template_details: state.mailtemplate.mail_template_details,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_mail_template_details(state) {
      dispatch(MailTemplate.change_mail_template_details(state))
    },

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CkEditorComponent);