import React, { Component } from 'react';
import { connect } from 'react-redux';

// import Search from './Search';
// import RoomList from './RoomList';
// import Building from './Building';
// import Map from './Map';
// import styled from 'styled-components'
import './Css/Search.css';
import RoomDocComponent from '../Rooms/RoomDocComponent';
import { Modal, Row, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import VisitEdit from '../CustomerContacts/VisitEdit';
import {
  get_response_map_rooms, change_showRight, change_showLeft,
  change_showFlag, get_response_map_rooms_success, delete_map_introduction, insert_map_to_introductions, insert_map_stockrooms, delete_map_stockrooms
} from './Saga/MapSearch';
import {change_visit_edit, change_visit_editor_forms, set_visit_edit, get_customer_contacts_tm_room_list, update_rent_visits_edit, update_rent_visits} from '../CustomerContacts/Saga/CustomerContacts';
import {insert_introductions} from '../IntroductionList/Saga/IntroductionList';

import { add_mail_room } from '../Mail/Saga/Mail';

import EstimateComponent from '../Estimates/Index';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      bounds: null,
      modalsheet: false,
      modalestimates: false,
    }
    // this.selectTatemono = this.selectTatemono.bind(this);
    // props.get_response_rooms({rentResponseId:props.rent_response_id});
    // this.handleSubmit = this.handleSubmit.bind(this);
    this.selectTatemono = this.selectTatemono.bind(this);

    this.togglesheet = this.togglesheet.bind(this);
    this.toggleestimates = this.toggleestimates.bind(this);

    this.handleChange = this.handleChange.bind(this);

  }

  handleChange = (event) => {

    // alert(event.target.name)
    let visit_editor_forms = this.props.visit_editor_forms

    visit_editor_forms[event.target.name] = event.target.value;

    this.props.change_visit_editor_forms({});
    this.props.change_visit_editor_forms(visit_editor_forms);
    // if (event.target.name === "condition") {


    // }
    this.setState({ reflash: !this.state.reflash });
  }

  selectTatemono(tatemonoId, roomId, tatemonoName, roomName) {
    // let  data={rentResponseId:this.props.responseid,tatemonoId:tatemonoId,roomId:roomId}
    if (this.props.mapsearch.fromMapOpen === 2) {

      if (window.editorInstance2) {
        this.props.add_mail_room({ window: window, id: 131, tatemonoId: tatemonoId, roomId: roomId, rentResponseId: this.props.responsesReducer.responsedetail.id })
      }

      
    } else if (this.props.mapsearch.fromMapOpen === 3 || this.props.mapsearch.fromMapOpen === 4) {
      // 案内物件に追加
      // set_visit_edit
      // <IconButton size="small" aria-label="edit" onClick={() => props.set_visit_edit({ id: props.rent_visits[rowIndex].rent_customer_contact_id, disp: true, rent_visit_id: props.rent_visits[rowIndex].id, tatemono_name: props.rent_visits[rowIndex].tatemono_name, tatemono_id: props.rent_visits[rowIndex].tatemono_id, remarks: props.rent_visits[rowIndex].remarks, date: props.rent_visits[rowIndex].date, user_id: props.rent_visits[rowIndex].user_id, room_id: props.rent_visits[rowIndex].room_id, room_name: props.rent_visits[rowIndex].room_name })}>

      let rent_customer_contacts_forms = this.props.mapsearch.fromMapOpen === 3 ? this.props.customerContactsReducer.rent_customer_contacts_calendar_forms : this.props.customerContactsReducer.rent_customer_contacts_forms

      let visit_editor_forms = this.props.customerContactsReducer.visit_editor_forms

      this.props.get_customer_contacts_tm_room_list(parseInt(tatemonoId))

      visit_editor_forms.date = rent_customer_contacts_forms.date
      visit_editor_forms.id = 0
      visit_editor_forms.remarks = ""
      visit_editor_forms.rent_customer_contact_id = rent_customer_contacts_forms.id
      visit_editor_forms.rent_response_id = rent_customer_contacts_forms.rent_response_id
      visit_editor_forms.rent_visit_id = 0
      visit_editor_forms.room_id = parseInt(roomId)
      visit_editor_forms.room_name = roomName
      visit_editor_forms.tatemono_id = parseInt(tatemonoId)
      visit_editor_forms.tatemono_name = tatemonoName
      visit_editor_forms.user_id = rent_customer_contacts_forms.user_id
      visit_editor_forms.no = 0

      this.props.change_visit_editor_forms(visit_editor_forms)

      this.setState({ modalvisit: true })
    } else {

      let insertdata = {
        rentResponseId: this.props.responsesReducer.responsedetail.id,
        tatemonoId: tatemonoId,
        status: 2,

        roomId: roomId,

      };
      console.log(insertdata)

      this.props.insert_map_to_introductions(insertdata)
    }
  }

  togglesheet = () => {

    this.setState(prevState => ({
      modalsheet: !prevState.modalsheet,

    }));
  }

  toggleestimates = () => {

    this.setState(prevState => ({
      modalestimates: !prevState.modalestimates,

    }));

  }

  visitUpdate(){
    // alert(this.props.fromMapOpen)
    if(this.props.mapsearch.fromMapOpen == 3){
        this.props.update_rent_visits_edit()
    }else{
        this.props.update_rent_visits()
    }

    let visit_editor_forms = this.props.customerContactsReducer.visit_editor_forms

    let insertdata = {
        rentResponseId: this.props.mapsearch.fromMapOpen == 3 ? this.props.customerContactsReducer.rent_customer_contacts_calendar_forms.rent_response_id : this.props.customerContactsReducer.rent_customer_contacts_forms.rent_response_id,
        tatemonoId: visit_editor_forms.tatemono_id,
        status: 2,
        roomId: visit_editor_forms.room_id,

    };


    this.props.insert_introductions(insertdata)

    this.setState({ "modalvisit": false })

}


  render() {


    return (


      <div>

        {this.props.mapsearch.fromMapOpen === 2 ? 
          <button className="btn btn-sm btn-success" onClick={() => this.selectTatemono(this.props.rowData.tatemono_id, this.props.rowData.room_id)} style={{ width: "45px", fontSize: 10, padding: 0 }}>メールに追加</button> : 
          this.props.mapsearch.fromMapOpen === 3 || this.props.mapsearch.fromMapOpen === 4 ? //来店登録 3カレンダー付　4:カレンダーから開いたやつ
          <button className="btn btn-sm btn-success" onClick={() => this.selectTatemono(this.props.rowData.tatemono_id, this.props.rowData.room_id, this.props.rowData.tatemono_name, this.props.rowData.room_name)} style={{ width: "45px", fontSize: 10, padding: 0 }}>案内</button> : 
          (this.props.rowData.selected == null ?
            <button className="btn btn-sm btn-success" onClick={() => this.selectTatemono(this.props.rowData.tatemono_id, this.props.rowData.room_id)} style={{ width: "45px", fontSize: 10, padding: 0 }}>追加</button>
            : 
            <button onClick={() => this.props.delete_map_introduction({ introduction_id: this.props.rowData.selected, tatemono_id: this.props.rowData.tatemono_id, rent_response_id: this.props.responsesReducer.responsedetail.id })} className="btn btn-sm btn-danger" style={{ width: "45px", fontSize: 10, padding: 0 }}>取消</button>)}
        <br />
        <button className="btn btn-sm btn-primary" onClick={this.togglesheet} style={{ width: "45px", fontSize: 9, padding: 0 }}>詳細</button>
        <br />
        <button className="btn btn-sm btn-warning" onClick={this.toggleestimates} style={{ width: "45px", fontSize: 9, padding: 0 }}>見積</button>
        <br />
        {this.props.rowData.selected_stock == null ? <button className="btn btn-sm btn-success" onClick={() => this.props.insert_map_stockrooms({
          tatemono_id: this.props.rowData.tatemono_id, room_id: this.props.rowData.room_id, rent_response_id: this.props.responsesReducer.responsedetail.id
        })} style={{ width: "45px", fontSize: 9, padding: 0 }}>ｽﾄｯｸ</button> : <button className="btn btn-sm btn-danger" onClick={() => this.props.delete_map_stockrooms({
          tatemono_id: this.props.rowData.tatemono_id,
          room_id: this.props.rowData.room_id,
          rent_response_id: this.props.responsesReducer.responsedetail.id,
          id: this.props.rowData.selected_stock,
        })} style={{ width: "45px", fontSize: 9, padding: 0 }}>ｽﾄｯｸ取消</button>}




        <Modal isOpen={this.state.modalsheet} toggle={this.togglesheet} size="xxl" zIndex={3000} fade={false}>
          <ModalHeader toggle={this.togglesheet}><b style={{ fontSize: "20px" }}>{this.props.roomsReducer.room_details.tatemono_name} {this.props.roomsReducer.room_details.room_name}</b></ModalHeader>

          < RoomDocComponent tatemonoId={this.props.rowData.tatemono_id} roomId={this.props.rowData.room_id} />

        </Modal>


        <Modal isOpen={this.state.modalestimates} zIndex={3000} size="xxl" fade={false}>

          <ModalHeader toggle={() => this.toggleestimates(-1)}>見積作成</ModalHeader>
          <ModalBody style={{ background: "#9fb6cc" }}>
            <EstimateComponent rentEstimateId={0} rentResponseId={0} tatemonoId={this.props.rowData.tatemono_id} roomId={this.props.rowData.room_id} />
          </ModalBody>
          <ModalFooter>
            {/* <button className="btn btn btn-danger" style={{margin:"5px"}} onClick={this.delete_auto_send}>除外する</button> */}
            <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={() => this.toggleestimates(-1)}>閉じる</button>
          </ModalFooter>
        </Modal>

        <Dialog open={this.state.modalvisit} onClose={() => this.setState({ "modalvisit": false })} size="md" fade={false}>
        <DialogTitle onClose={() => this.setState({ "modalvisit": false })}>案内物件</DialogTitle>
        <DialogContent>
        <VisitEdit contactstate={this.props.customerContactsReducer.rent_customer_contacts_calendar_forms} />
              {/* <TextField
                id="outlined-multiline-static"
                label="備考"
                value={this.props.customerContactsReducer.visit_editor_forms.remarks}
                name="remarks"
                onChange={this.handleChange}
                multiline
                rows={4}
                style={{ width: '90%', margin: 10 }}
                variant="outlined"
              /> */}
        </DialogContent>
        <DialogActions>
        <Button variant="contained" style={{ margin: 5 }} color="primary" onClick={()=>this.visitUpdate()}>保存</Button>
          {/* <Button variant="contained" style={{ margin: 5 }} color="primary" onClick={()=>{this.props.update_rent_visits_edit();this.setState({ "modalvisit": false })}}>保存</Button> */}
          {/* {visit_editor_forms.rent_visit_id ? <Button style={{ margin: 5 }} variant="contained" color="secondary" onClick={delete_rent_visits}>削除</Button> : ""} */}
          <Button variant="outlined" style={{ margin: 5 }} onClick={() => this.setState({ "modalvisit": false })}>閉じる</Button>
        </DialogActions>
        </Dialog>

      </div>
    )
  }

}

export default connect(
  ({ mapsearch, responsesReducer, mailReducer, roomsReducer, customerContactsReducer, IntroductionList }) => ({ mapsearch, responsesReducer, mailReducer, roomsReducer, customerContactsReducer, IntroductionList }),
  // ({responsesReducer}) => ({responsesReducer}),

  // state => ({
  //   mapsearch: state.mapsearchReducer,

  // }),
  {
    get_response_map_rooms,
    get_response_map_rooms_success,
    change_showRight,
    change_showLeft,
    change_showFlag,
    delete_map_introduction,
    insert_map_to_introductions,
    add_mail_room,

    insert_map_stockrooms, delete_map_stockrooms,
    change_visit_edit, change_visit_editor_forms,
    set_visit_edit,
    get_customer_contacts_tm_room_list,
    update_rent_visits_edit,
    update_rent_visits,
    insert_introductions,
  }

)(App)
// const mapStateToProps = (state) => {
//   return {
//     response_rooms: state.mapsearchReducer.response_rooms,

//   }
// }
// function mapDispatchToProps(dispatch) {
//   return {

//       get_response_rooms(state) {
//           dispatch(Rooms.get_response_rooms(state))
//       },
//   };
// }
// export default connect(mapStateToProps, mapDispatchToProps)(App);


