import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import Detail from './ApprovalDetail';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import { ThemeContext } from './ApprovaIndex';
import IconButton from '@mui/material/IconButton';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import './Css/Grid.css'
const authentication = require('react-azure-adb2c2').default;

// Table data as a list of array.


function App() {

    const [columnWidths, setColumnWidths] = useState({column1:70, column2:90, column3:90, column4:90, column5:90, column6:90});

    const [modal, setModal] = useState(false);

    const [application_id, setApplicationId] = useState([]);

    const token = authentication.getAccessToken();

    const handleOpen = async(e, index, data) => {

        setApplicationId(data[index].application_id)

        setModal(true)

    };

    const _rowClassNameGetter = async(index, data) => {
        // alert(index)
        if(data){
            if(data[index].application_id) {
                // alert(data[index].application_id)
                return "normal"
            }else{
                return ""
            }
        }else{
            return ""
        }

    };

    return (
        <ThemeContext.Consumer>
            {(value) => {
                const data = value.table;
                let data_column = value.column;

                setColumnWidths(data_column)

                return data.length ? (<div><Table 
                    className='gridNormalList'
                    rowHeight={34}
                    rowsCount={data.length}
                    width={window.innerWidth - 120}
                    height={window.innerHeight-200}
                    headerHeight={30}
                    // onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    onColumnResizeEndCallback={(newColumnWidth, columnKey) => {
                        let columnsset = columnWidths.map(function (value, key) {
                            if (key === columnKey) {
                              value.size = newColumnWidth
                            }
                            return value
                          })
                          setColumnWidths(columnsset)

                    }}
                    isColumnResizing={false}
                    onRowClick={(e, index)=>handleOpen(e, index, data)}
                    // rowClassNameGetter={(index) => _rowClassNameGetter(index, data)}

                >
                    {/* <Column header={<Cell></Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}><IconButton onClick={(e) => {handleOpen(data[rowIndex].application_id); }} aria-label="edit" size="small"><EditIcon /></IconButton></Cell>)} width={40} /> */}
                    {
                        data_column.map(function (value, key) {
                            return <Column width={data_column[key].size} className={"normal"} isResizable={true} columnKey={key} header={<Cell>{data_column[key].name}</Cell>} cell={({ rowIndex, ...props }) => (<Cell {...props}>{data[rowIndex][data_column[key].column]}</Cell>)} />
                        })

                    }
                
                </Table>
                
                <Modal isOpen={modal} fade={false}  toggle={(e) => { setModal(false) }} className="modal-dialog-centered" size="xxl">
                    <ModalHeader>
     
                            <TaskAltIcon style={{fontSize:20, marginRight:5}} /><span>承認</span>

                            <IconButton
                                aria-label="close"
                                style={{position:'absolute', right:1, top:1}}
                                onClick={(e) => { setModal(false) }}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                        
                    </ModalHeader>
                    <ModalBody>

                        <Detail application_id={application_id} />
                    </ModalBody>
                    <ModalFooter>
                        {/* <Button variant="contained" color="secondary" onClick={() => this.handleUpdate(this.props.rent_corporate_contacts.id, 1)}>削除</Button> */}
                        <Button variant="contained" style={{ marginLeft: 3 }} onClick={(e) => { setModal(false) }}>閉じる</Button>
                    </ModalFooter>

                </Modal>


                </div>) : "";

            }}
        </ThemeContext.Consumer>
    );

}

export default App;

// // Render your table
// ReactDOM.render(
//     <Table
//         rowHeight={50}
//         rowsCount={rows.length}
//         width={5000}
//         height={5000}
//         headerHeight={50}>
//         <Column
//             header={<Cell>Col 1</Cell>}
//             cell={<Cell>Column 1 static content</Cell>}
//             width={2000}
//         />
//         <Column
//             header={<Cell>Col 2</Cell>}
//             cell={<MyCustomCell mySpecialProp="column2" />}
//             width={1000}
//         />
//         <Column
//             header={<Cell>Col 3</Cell>}
//             cell={({ rowIndex, ...props }) => (
//                 <Cell {...props}>
//                     Data for column 3: {rows[rowIndex]}
//                 </Cell>
//             )}
//             width={2000}
//         />
//     </Table>,
//     document.getElementById('example')
// );