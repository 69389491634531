import React, { Component } from 'react';
import { connect } from 'react-redux';
// import { Table, Column, Cell } from 'fixed-data-table-2';
import {Table, Column, ColumnGroup, Cell} from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
import moment from 'moment';


import withStyles from '@mui/styles/withStyles';
// import { customerList,  customerNewDispSet } from '../../Store/Customers';
// import * as Monies from './Saga/Monies';


const styles = (() => ({
    root: {
        minWidth: 275,

    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },


    grit: {
        flexGrow: 1,
    },


}))

const houjin =[
    {nen:"2018", hankyo:"5", raiten:"5", seiyaku:"4", kanriseiyaku:"3", kingaku:"489000", yobikomiritu:"100", seiyakuritu:"80"},
    {nen:"2019", hankyo:"10", raiten:"8", seiyaku:"7", kanriseiyaku:"5", kingaku:"668000", yobikomiritu:"80", seiyakuritu:"88"},
    {nen:"2020", hankyo:"2", raiten:"2", seiyaku:"1", kanriseiyaku:"0", kingaku:"92000", yobikomiritu:"67", seiyakuritu:"50"},
    {nen:"2021", hankyo:"1", raiten:"0", seiyaku:"0", kanriseiyaku:"0", kingaku:"0", yobikomiritu:"0", seiyakuritu:"-"},
]
const kojin =[
    {nen:"2018", hankyo:"5", raiten:"5", seiyaku:"4", kanriseiyaku:"3", kingaku:"489000", yobikomiritu:"100", seiyakuritu:"80"},
    {nen:"2019", hankyo:"10", raiten:"8", seiyaku:"7", kanriseiyaku:"5", kingaku:"668000", yobikomiritu:"80", seiyakuritu:"88"},
    {nen:"2020", hankyo:"2", raiten:"2", seiyaku:"1", kanriseiyaku:"0", kingaku:"92000", yobikomiritu:"67", seiyakuritu:"50"},
    {nen:"2021", hankyo:"1", raiten:"0", seiyaku:"0", kanriseiyaku:"0", kingaku:"0", yobikomiritu:"0", seiyakuritu:"-"},
]
class CustomerList extends Component {

    constructor(props) {

        super(props);
        this.state = {
            modal: false,
            modalselect: false
        };

        // this.toggle = this.toggle.bind(this);
        // this.toggleselect = this.toggleselect.bind(this);
        // this.customerreset = this.customerreset.bind(this);
        // this.handleonClick = this.handleonClick.bind(this);
        // this.props.get_m_corporations_list()
    }




    componentWillMount() {
        // this.props.customerNewDispSet(0)
        this.submit = this.submit.bind(this);
    }
    //     aa(state:any){
    // console.log(state)
    //     }

    submit(state) {
        console.log(state)

        // this.props.get_banks(state)
    }

    csv() {

        // this.props.get_rent_contract_accounting(this.props.banks);
    }
    render() {

        return (
            <div>
                {/* <Table rowHeight={34} rowsCount={houjin.length} width={550} height={200} headerHeight={30}>
                    <ColumnGroup fixed={true} header={<div>Name</div>}>
                        <Column
                            columnKey="firstName"
                            fixed={true}
                            header={<div>First Name</div>}
                            cell={({rowIndex, ...props}) => ( <Cell {...props}>{houjin[rowIndex].hankyo}</Cell>)}
                            width={150}
                        />
                        <Column
                            columnKey="lastName"
                            fixed={true}
                            header={<div>Last Name</div>}
                            cell={({rowIndex, ...props}) => ( <Cell {...props}>{houjin[rowIndex].hankyo}</Cell>)}
                            width={150}
                        />
                    </ColumnGroup>
                    <ColumnGroup header={<div>About</div>}>
                        <Column
                            columnKey="companyName"
                            header={<div>Company</div>}
                            cell={({rowIndex, ...props}) => ( <Cell {...props}>{houjin[rowIndex].hankyo}</Cell>)}
                            flexGrow={1}
                            width={150}
                        />
                        <Column
                            columnKey="sentence"
                            header={<div>Sentence</div>}
                            cell={({rowIndex, ...props}) => ( <Cell {...props}>{houjin[rowIndex].hankyo}</Cell>)}
                            flexGrow={1}
                            width={150}
                        />
                    </ColumnGroup>
                </Table> */}

                    <Table rowHeight={34} rowsCount={this.props.rent_responses_contract_count.length} height={300} headerHeight={30} groupHeaderHeight={30} width={(window.innerWidth / 2) - 75}>
                        <ColumnGroup fixed={true} header={<Cell></Cell>}>
                            <Column header={<Cell></Cell>} fixed={true} cell={({rowIndex, ...props}) => ( <Cell {...props}>{moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY')}</Cell>)} width={50}/>
                        </ColumnGroup>
                        <ColumnGroup header={<Cell>法人</Cell>}>
                            <Column header={<Cell>反響</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_responses_response_list && this.props.rent_responses_response_list.filter(a => a.type == 1 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length > 0 ? this.props.rent_responses_response_list.filter(a => a.type == 1 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length : ""}</Cell>)} width={50}/>
                            <Column header={<Cell>来店</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_responses_coming_list && this.props.rent_responses_coming_list.filter(a => a.type == 1 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length > 0 ? this.props.rent_responses_coming_list.filter(a => a.type == 1 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length : ""}</Cell>)} width={50}/>
                            <Column header={<Cell>成約</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_responses_contract_count[rowIndex].rent_contract_id_1}</Cell>)} width={50}/>
                            <Column header={<Cell>管理成約</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_responses_contract_count[rowIndex].division_1}</Cell>)} width={80}/>
                            <Column header={<Cell>金額</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_responses_contract_count[rowIndex].black_paid_1 ? this.props.rent_responses_contract_count[rowIndex].black_paid_1.toLocaleString() : ""}</Cell>)} width={80}/>
                            
                            <Column header={<Cell>呼込率</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_responses_coming_list && this.props.rent_responses_response_list && this.props.rent_responses_response_list.filter(a => a.type == 1 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length > 0 ? 
                                    Math.round(this.props.rent_responses_coming_list.filter(a => a.type == 1 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length / this.props.rent_responses_response_list.filter(a => a.type == 1 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length * 100) + "％" : ""}</Cell>)} width={70}/>
                            <Column header={<Cell>成約率</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_responses_contract_count[rowIndex].rent_contract_id_1 && this.props.rent_responses_response_list ? Math.round(this.props.rent_responses_contract_count[rowIndex].rent_contract_id_1 / this.props.rent_responses_response_list.filter(a => a.type == 1 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length * 100) + "％" : ""}</Cell>)} width={70}/>
                        </ColumnGroup>
                        <ColumnGroup header={<Cell>個人</Cell>}>
                            <Column header={<Cell>反響</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_responses_response_list && this.props.rent_responses_response_list.filter(a => a.type == 0 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length > 0 ? this.props.rent_responses_response_list.filter(a => a.type == 0 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length : ""}</Cell>)} width={50}/>
                            <Column header={<Cell>来店</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_responses_coming_list && this.props.rent_responses_coming_list.filter(a => a.type == 0 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length > 0 ? this.props.rent_responses_coming_list.filter(a => a.type == 0 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length : ""}</Cell>)} width={50}/>
                            <Column header={<Cell>成約</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_responses_contract_count[rowIndex].rent_contract_id_0}</Cell>)} width={50}/>
                            <Column header={<Cell>管理成約</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_responses_contract_count[rowIndex].division_0}</Cell>)} width={80}/>
                            <Column header={<Cell>金額</Cell>} cell={({rowIndex, ...props}) => ( <Cell {...props}>{this.props.rent_responses_contract_count[rowIndex].black_paid_0 ? this.props.rent_responses_contract_count[rowIndex].black_paid_0.toLocaleString() : ""}</Cell>)} width={80}/>
                            <Column header={<Cell>呼込率</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_responses_coming_list && this.props.rent_responses_response_list && this.props.rent_responses_response_list.filter(a => a.type == 0 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length > 0 ? 
                                    Math.round(this.props.rent_responses_coming_list.filter(a => a.type == 0 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length / this.props.rent_responses_response_list.filter(a => a.type == 0 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length * 100) + "％" : ""}</Cell>)} width={70}/>
                            <Column header={<Cell>成約率</Cell>} cell={({rowIndex, ...props}) => (<Cell {...props}>{this.props.rent_responses_contract_count[rowIndex].rent_contract_id_0 && this.props.rent_responses_response_list ? Math.round(this.props.rent_responses_contract_count[rowIndex].rent_contract_id_0 / this.props.rent_responses_response_list.filter(a => a.type == 0 && moment(a.date).format('YYYY-MM-DD') >= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-01-01" &&  moment(a.date).format('YYYY-MM-DD') <= moment(this.props.rent_responses_contract_count[rowIndex].year).format('YYYY') + "-12-31").length * 100) + "％" : ""}</Cell>)} width={70}/>
                        </ColumnGroup>



                    </Table>

            </div>
        );
    }
}


//export  default CustomerList;
const mapStateToProps = (state) => {
    return {
        // m_corporations_list: state.corporation.m_corporations_list,
        rent_responses_contract_count: state.corporation.rent_responses_contract_count,
        rent_responses_response_list: state.corporation.rent_responses_response_list,
        rent_responses_coming_list: state.corporation.rent_responses_coming_list,

        // customerNewDisp: state.customersReducer.customerNewDisp,
        // rent_contracts_list: state.rentContractsReducer.rent_contracts_list,
        // banks: state.rentContractMoniesReducer.banks,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        // get_m_corporations_list(state) {
        //     dispatch(Corporation.get_m_corporations_list(state))
        // },
        // customerList(search:any) {
        //     dispatch(customerList(search))
        // },
        // customerNewDispSet(state:number) {
        //     dispatch(customerNewDispSet(state))
        // },
        // get_rent_contract_accounting(state: any) {
        //     dispatch(Monies.get_rent_contract_accounting(state))
        // },
        // get_banks(state: any) {
        //     dispatch(Monies.get_banks(state))
        // },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(CustomerList));




