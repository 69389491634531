import React, { Component } from 'react';

import { connect } from 'react-redux';

//import { actionCreators } from '../../Store/MailData';

import "react-datepicker/dist/react-datepicker.css";
//import { actionCreators } from '../../Store/WeatherForecasts';

import {  reduxForm } from 'redux-form';
// import { Button, Modal } from 'reactstrap';
import '../../Css/Table.css';
// import * as AutoKana from 'vanilla-autokana';
import moment from 'moment';



// const FIELDS = {
//     name1: 'name1',
//     kana1:'kana1',
// };

// redux-form 用バリデーション関数


class LifeLineDispComponent extends Component {
   
    constructor(props) {
        super(props);
      
        this.state = {
            customerinfo:this.props.forecasts,
            startDate: new Date()
        };
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        //this.onChange = this.onChange.bind(this);
    }
    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }




  componentDidUpdate() {
    // This method is called when the route parameters change
    this.ensureDataFetched();
  }

  ensureDataFetched() {
      //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
      //console.log(startDateIndex)

      //this.props.requestWeatherForecasts(0);

    }



    render() {

      return (
          <div>
                  {/* <button className="btn btn-sm btn-success float-right" style={{ display: (this.props.notedit === 1) ? 'none' : '' }} onClick={this.props.tatemonoeditchange}>編集</button> */}
                  <table className="disp_table">
                <colgroup width='20%' />
                <colgroup width='30%' />
                <colgroup width='20%' />
                <colgroup width='30%' />
                <tbody>
                    <tr >
                        <th>水道</th>
                        <td>
                            {this.props.roomdetail.suidou_name}
                        </td>
                        <th>水道業者</th>
                        <td>
                            {this.props.roomdetail.suidou_gyosha_name}
                        </td>
                    </tr>

                    <tr >
                        <th>ガス</th>
                        <td>
                            {this.props.roomdetail.gas_name}
                        </td>
                        <th>ガス業者</th>
                        <td>
                            {this.props.roomdetail.gas_gyosha_name}
                        </td>
                    </tr>
                    <tr >
                        <th>給湯</th>
                        <td>
                            {this.props.roomdetail.kyuutou_name}
                        </td>
                        <th>電気業者</th>
                        <td>
                            {this.props.roomdetail.denki_gyosha_name}
                        </td>
                    </tr>
                    <tr >
                        <th>排水</th>
                        <td colSpan="3">
                            {this.props.roomdetail.haisui_name}
                        </td>
                    </tr>
                    <tr >
                        <th>ゴミ回収業者</th>
                        <td>
                            {this.props.roomdetail.gomi_gyosha_name}
                        </td>
                        <th>ゴミステーション場所</th>
                        <td>
                            {(() => {
                                if (this.props.roomdetail.gomi_station_basho_no === 1) {
                                    return "敷地内";
                                } else if (this.props.roomdetail.gomi_station_basho_no === 2) {
                                    return "敷地外";
                                } else{
                                    return "";
                                }
                            })()}
                        </td>
                    </tr>
                </tbody>
              </table>
            <div style={{textAlign:"left"}}>{this.props.roomdetail.updated_at ? "最終更新: "+moment(this.props.roomdetail.updated_at).utc().format('YYYY/MM/DD HH:mm') : ""}</div>
              </div>
    );
  }
} 




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {

        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        //firebaseSet(state) {
        //    dispatch(firebaseSet(state))
        //},
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

LifeLineDispComponent = reduxForm({
    form: 'roomform', // a unique identifier for this form
    //validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(LifeLineDispComponent)

export default connect(mapStateToProps, mapDispatchToProps)(LifeLineDispComponent);