
const authentication = require('../../../react-azure-adb2c2').default;




export const update = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/contract/workflows/update/` + values.workflow_id;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/rent/contract/workflows/update/` + values.workflow_id;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}
export const workflows = (values: any) => {
    // const url = `http://localhost:5000/v1/rent/contract/workflows/WorkFlowid/` + values;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/rent/contract/workflows/WorkFlowid/` + values;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))

    return fetch(url, {
        method: 'get',
        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const manager_update = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/contract/workflows/confirm/manager/` + values.workflow_id;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/rent/contract/workflows/confirm/manager/` + values.workflow_id;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}

export const pm_update = (values: any) => {

    // const url = `http://localhost:5000/v1/rent/contract/workflows/confirm/pm/` + values.workflow_id;
    const url = process.env.REACT_APP_API_CRM_CONTRACTS_URL + `/v1/rent/contract/workflows/confirm/pm/` + values.workflow_id;

    const token = authentication.getAccessToken();
    //const search = values ? values : {};
    // alert(JSON.stringify(values))
    // alert(url)
    return fetch(url, {
        method: 'put',
        body: JSON.stringify(values),

        headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' },

    }).then(res => res.json())
        .then(payload => ({ payload }))
        .catch(error => ({ error }));

}