import React, { Component } from 'react';

import { connect } from 'react-redux';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
// import Loading from '../Layout/LoadingComponent';
// import { Field, reduxForm, submit } from 'redux-form';
// import '../../Css/Table.css';
// import { Button, ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
//import ResponseAddComponent from './ResponseAddComponent';

import * as MailTemplate from './Saga/MailTemplate';


import TextField from '@mui/material/TextField';

import Autocomplete from '@mui/material/Autocomplete';



class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      modalselect: false,
      modalcalender: false,
      refresh: false
    };

    // this.toggle = this.toggle.bind(this);
    // this.togglecalender = this.togglecalender.bind(this);
    // this.handleSubmit = this.handleSubmit.bind(this);
    // this.handleChangeSectionId = this.handleChangeSectionId.bind(this);
    // this.handlePrimaryUserIdChange = this.handlePrimaryUserIdChange.bind(this);
    let mail_template_search = this.props.mail_template_search
    mail_template_search.mail_template_category_id = 70
    mail_template_search.section_id = this.props.user_details.amSectionId
    this.props.change_mail_template_search(mail_template_search)
    // this.sort = this.sort.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handle_section_id_change = this.handle_section_id_change.bind(this);
    this.handle_user_id_change = this.handle_user_id_change.bind(this);
    this.handle_category_change = this.handle_category_change.bind(this);
    this.addtemplate = this.addtemplate.bind(this);

  }



  handle_section_id_change(e, newValue) {
    // console.log(sectionId)
    let mail_template_search = this.props.mail_template_search
    mail_template_search.section_id = newValue ? newValue.value : 0
    this.props.change_mail_template_search(mail_template_search)
    this.setState({ refresh: !this.state.refresh })
    this.props.get_mail_templates_search_list()
  }

  handle_user_id_change(e, newValue) {

    // console.log(sectionId)
    let mail_template_search = this.props.mail_template_search
    mail_template_search.user_id = newValue ? newValue.value : 0
    this.props.change_mail_template_search(mail_template_search)
    this.setState({ refresh: !this.state.refresh })
    this.props.get_mail_templates_search_list()
  }
  handle_category_change(e, newValue) {

    // console.log(sectionId)
    let mail_template_search = this.props.mail_template_search
    mail_template_search.mail_template_category_id = newValue ? newValue.value : 0
    this.props.change_mail_template_search(mail_template_search)
    this.setState({ refresh: !this.state.refresh })
    this.props.get_mail_templates_search_list()
  }


  handleChange = (event) => {
    console.log(event)

    let formData = this.props.responselist.formData
    formData[event.target.name] = event.target.value;
    if (event.target.name === "condition") {
      formData.contractionDateStart = new Date();
      formData.contractionDateEnd = null;
    }
    this.props.change_formData(formData);
    if (event.target.name === "condition") {

      this.props.search_response_list()
    }
    // this.setState({ formData });
  }
  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")

    // this.props.responseList({ sectionId: this.props.masterReducer.user_details.amSectionId })
  }
  toggle() {
    //this.setState(prevState => ({
    //    modal: !prevState.modal
    //}));
    this.props.customerNewDispSet(0)
    // let matching = {}


    // this.props.change_matching(matching)
    this.props.history.push('/CustomerNew');

  }


  togglecalender() {
    this.setState(prevState => ({
      modalcalender: !prevState.modalcalender
    }));
  }
  saveSelectValue = (e, id) => {
    let data = {}
    data.id = id;
    data.value = e.target.value

    this.props.saveSelectValue(data)
  }

  addtemplate() {
    // if (templateid === 0) {
    // alert(this.props.mail_template_search.mail_template_category_id)
    if (this.props.mail_template_search.mail_template_category_id) {
      this.props.add_mail_templates({
        id: 0, BodyAll: "", subject: "", MailTemplatecategoryId: this.props.mail_template_search.mail_template_category_id, templateName: "New"
      })
    }
    // } else {
    //   // console.log(this.props.mail_templates)

    //   this.setState({ cateid: cateid, templateid: templateid });
    //   // console.log(this.props.mail_templates.filter(a => a.id=== cateid)[0].template.filter(b => b.id=== templateid)[0].bodyAll)
    //   this.setState({ mailtemplate: this.props.mail_templates.filter(a => a.id === cateid)[0].template.filter(b => b.id === templateid)[0] });
    // }
    // this.props.mail_templates.

  }

  // handleSectionIdChange(sectionId) {
  //   alert()
  //   let formData =   this.state.formData
  //   formData.sectionId=sectionId
  //     this.setState(formData);

  //   }
  // handlePrimaryUserIdChange(primaryUserId) {
  //   if (primaryUserId) {
  //     let formData = this.props.responselist.formData
  //     formData.primaryUserId = primaryUserId.value
  //     this.setState(formData);
  //   } else {
  //     let formData = this.props.responselist.formData
  //     formData.primaryUserId = 0
  //     this.props.change_formData(formData);
  //   }
  // };
  // handleContractionDateStartChange = date => {
  //   let formData = this.props.responselist.formData
  //   formData.contractionDateStart = date
  //   this.props.change_formData(formData);

  // };

  // handleContractionDateEndChange = date => {
  //   let formData = this.props.responselist.formData
  //   formData.contractionDateEnd = date
  //   this.props.change_formData(formData);

  // };

  // sort(sort) {
  //   let formData = this.props.responselist.formData
  //   formData.sort = sort.target.value
  //   this.props.change_formData(formData);
  //   this.props.search_response_list()

  // }
  render() {


    return (
      <div>
        <table>

          <tbody>

            <tr >

              <td colSpan="2" >
                <div className="form-inline">
                  {/* <Field
                                  name="name"
                                  component="input"
                                  type="text"
                                  placeholder="お客様名"
                                          className="form-control"
                                          size="20"
                                  /> */}

                  <div className="searchform">
                    <TextField
                      id="outlined-basic"
                      //   className={classes.textField}
                      label="テンプレート名"
                      margin="normal"

                      onChange={this.handleChange}
                      name="name"
                      // value={this.props.responselist.formData.name}
                      style={{ float: "left", width: "150px", marginTop: 0, paddingRight: "5px" }}

                    />
                  </div>
                  <div className="searchform">
                    {this.props.mail_template_categories[0] ? <Autocomplete
                      id="combo-box-demo"
                      options={this.props.mail_template_categories}
                      getOptionLabel={(option) => option.label}
                      value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                      style={{ width: 150 }}

                      onChange={this.handle_category_change}
                      renderInput={(params) => <TextField  {...params} label="カテゴリ" />}
                    /> : ""}
                  </div>
                  <div className="searchform">
                    {this.props.section_select[0] ? <Autocomplete
                      id="combo-box-demo"
                      options={this.props.section_select}
                      getOptionLabel={(option) => option.label}
                      style={{ width: 150 }}
                      value={this.props.section_select.filter(a => a.value === this.props.mail_template_search.section_id)[0]}
                      onChange={this.handle_section_id_change}
                      disabled={true}
                      renderInput={(params) => <TextField  {...params} label="店舗選択" />}
                    /> : ""}
                  </div>
                  <div className="searchform">
                    <Autocomplete
                      id="combo-box-demo"
                      options={this.props.users_select}
                      getOptionLabel={(option) => option.label}
                      style={{ width: 150 }}
                      // value={this.props.users_select.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                      onChange={this.handle_user_id_change}
                      renderInput={(params) => <TextField  {...params} label="担当選択" />}
                    />


                  </div>


                  {/* <div className="searchform">

                    カテゴリ<Select
                      name="mail_template_category_id"
                      options={this.props.mail_template_categories}
                      getOptionLabel={(option) => option.label}
                      value={this.props.mail_template_search.mail_template_category_id}
                      onChange={(a) => this.handle_category_change(a)}

                      placeholder="カテゴリを選択"
                    />

                  </div> */}

                  {/* <div className="searchform">


                    店　舗<Select
                      name="section_id"
                      options={this.props.section_select}
                      value={this.props.mail_template_search.section_id}
                      onChange={(a) => this.handle_section_id_change(a)}
                      // component={RenderSelectInput}
                      //  onChange={(a)=>this.setState({formData[sectionId]:a)}
                      //  onBlur={(a) => input.onBlur(a?a.value:0)}
                      disabled={true}
                      placeholder="店舗を選択"
                    />



                  </div>



                  担　当<div className="searchform">
                    <Select
                      name="user_id"
                      options={this.props.users_select}
                      // value={this.props.responselist.formData.primaryUserId}
                      onChange={(a) => this.handle_user_id_change(a)}

                      placeholder="担当を選択"
                    />


                  </div> */}




                  <Chip label={this.props.mail_templates_search_list.length + "件"} style={{ margin: 10, fontSize: 14 }} variant="outlined" />
                  <Button
                    onClick={this.addtemplate}
                    variant="contained" color="secondary" size="small">追加</Button>
                </div>
              </td>

            </tr>
          </tbody>
        </table>





      </div >
    );
  }
}


const mapStateToProps = state => {
  return {
    mail_template_details: state.mailtemplate.mail_template_details,
    mail_templates: state.mailtemplate.mail_templates,
    // mail_details: state.mailReducer.mail_details,
    mail_template_categories: state.mailtemplate.mail_template_categories,
    section_select: state.masterReducer.section_select,
    users_select: state.masterReducer.users_select,
    mail_template_search: state.mailtemplate.mail_template_search,
    user_details: state.masterReducer.user_details,
    users: state.masterReducer.users,
    mail_templates_search_list: state.mailtemplate.mail_templates_search_list,
    // mailtemplateForm: state.form.mailtemplateForm,
    //firebasedata: state.firebaseReducer.firebasedata,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    change_mail_template_search(state) {
      dispatch(MailTemplate.change_mail_template_search(state))
    },
    get_mail_templates_search_list(state) {
      dispatch(MailTemplate.get_mail_templates_search_list(state))
    },
    // get_mail_templates(state) {
    //   dispatch(MailTemplate.get_mail_templates(state))
    // },
    // get_mail_template_categories(state) {
    //   dispatch(MailTemplate.get_mail_template_categories(state))
    // },
    // update_mail_templates(state) {
    //   dispatch(MailTemplate.update_mail_templates(state))
    // },
    add_mail_templates(state) {
      dispatch(MailTemplate.add_mail_templates(state))
    },
    // delete_mail_templates(state) {
    //   dispatch(MailTemplate.delete_mail_templates(state))
    // },

  };
}


export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);


// export default withRouter(ResponseListSearch)