import React, { Component } from 'react';
// import ReactDataGrid from 'react-data-grid';
import { connect } from 'react-redux';
import * as IntroductionList from './Saga/IntroductionList';
import * as StockRooms from '../StockRooms/Saga/StockRooms';
// import * as Responses from '../../Store/Responses';
import '../../Css/GridRooms.css';
import LoadingComponent from '../Layout/LoadingComponent';
import moment from 'moment';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import StarIcon from '@mui/icons-material/Star';
import Badge from '@mui/material/Badge';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import IconButton from '@mui/material/IconButton';
// import ReactDataGrid from "react-data-grid"
const ReactDataGrid = require('react-data-grid');



// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {
    // rooms: any,
    rent_response_id: number,
    // get_rooms: any,
    // toggle: any,
    // contacts_loading: number,
    selectsheet: any,
    // mailadd: any,
    add_mail_room: any,
    togglesearch: any,
    introductions: any,
    introductions_loading: number,
    onMypage: any,
    delete_introduction: any,
    togglefileup: any,
    estimate: any,
    toiawase: any,
    stock_rooms: any,
    insert_stock_rooms: any,
    delete_stock_rooms: any,
    get_introductions_success: any,
    get_introductions: any,
}


interface State {
    columns: any,
    modaldelete: boolean,
    rent_introduction_id: number,
    refresh: boolean,

    // state types
}
// const MailLinkFormatter = (payload:any) => {

//     return <a role="button"  className="btn btn-primary" target="_blank" onClick={this.mailadd} >追加</a>;

// };
const DateFormatter = (payload: any) => {
    if (payload.value) {
        return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
    } else {
        return "";
    }
};

const gaikanFormatter = (payload: any) => {
    let url = "";
    if (payload.row.status === 5) {
        url = "https://homestationopenrooms.azurewebsites.net/api/img/card/" + payload.row.id + "?" + (new Date()).getTime();

    } else {
        url = "https://img.global-center.co.jp/v2/tatemono/" + payload.row.tatemono_id + "/1/1/520/780/0";
    }
    return (<img src={url} />)
};

const MadoriFormatter = (payload: any) => {
    if (payload.row.status === 5) {
        return ""
    } else {
        const url = "https://img.global-center.co.jp/v2/rooms/" + payload.row.tatemono_id + "/" + payload.row.room_id + "/8/1/520/780/0";
        return <div><div><img style={{ width: 50, height: "auto", maxHeight: 50 }} src={url} /></div><div>{payload.row.madori_name}</div></div>
    }
};






const ChinRyouFormatter = (payload: any) => {
    if (payload.row.status === 5) {
        return (
            <div>
                <div>賃料 {payload.row.chinryou ? payload.row.chinryou_amount.toLocaleString() : "-"}円</div>
                <div>共益 {payload.row.kyouekihi != null ? payload.row.kyouekihi_amount.toLocaleString() : "-"}円</div>
                <div>水道 {payload.row.suidouryou ? payload.row.suidou_amount : "-"}</div>
                <div>車① {payload.row.pryou ? payload.row.parking_amount : "-"}</div>
            </div>
        )
    } else {
        return (
            <div>
                <div>賃料 {payload.row.chinryou ? payload.row.chinryou.toLocaleString() : "-"}円</div>
                <div>共益 {payload.row.kyouekihi != null ? payload.row.kyouekihi.toLocaleString() : "-"}円</div>
                <div>水道 {payload.row.suidouryou ? payload.row.suidouryou : "-"}</div>
                <div>車① {payload.row.pryou ? payload.row.pryou : "-"}</div>
            </div>
        )
    }
};
const KeiyakuFormatter = (payload: any) => {
    return (
        <div>
            <div>敷金 {payload.row.shikikin_mon === null ? "" : payload.row.shikikin_mon + "ヶ月"}</div>
            <div>礼金 {payload.row.reikin_mon === null ? "" : payload.row.reikin_mon + "ヶ月"}</div>
        </div>
    )
};

const RowRenderer = ({ renderBaseRow, ...props }: any) => {
    //console.log(props)
    //const color = props.idx % 2 ? "green" : "blue";


    let backgroundColor = props.row.kuushitsu_joukyou_no === 4 ? " row-highlight-gray" : "";
    backgroundColor = props.row.status === 4 ? " row-highlight-gray" : backgroundColor;
    //divだと描画が震える　.border-bottoms>div>div 　アンダーバーは←のように指定
    return <span className={backgroundColor}>{renderBaseRow(props)}</span>;



};



class IntroductionsGridComponent extends Component<Props, State> {
    constructor(props: Readonly<Props>) {
        super(props);


        // this.onRowClick = this.onRowClick.bind(this);
        this.mailadd = this.mailadd.bind(this);
        this.getCellActions = this.getCellActions.bind(this);
        this.StatusFormatter = this.StatusFormatter.bind(this);
        this.RoomFormatter = this.RoomFormatter.bind(this);
        this.toggledelete = this.toggledelete.bind(this);
        this.delete_introductions = this.delete_introductions.bind(this);

        this.NameFormatter = this.NameFormatter.bind(this);
        this.add_stock_room = this.add_stock_room.bind(this);
        this.delete_stock_room = this.delete_stock_room.bind(this);



        this.state = {
            modaldelete: false,
            rent_introduction_id: 0,
            refresh: false,

            columns: [

                { key: 'tatemono_id', name: '外観', width: 100, resizable: true, formatter: gaikanFormatter },
                { key: 'madori_name', name: '間取', width: 60, resizable: true, formatter: MadoriFormatter },
                { key: 'statusset', name: 'ステータス', width: 90, resizable: true, formatter: this.StatusFormatter },
                { key: 'tatemono_name', name: '物件名', width: 180, resizable: true, formatter: this.NameFormatter },
                { key: 'room_name', name: '部屋名', width: 70, resizable: true, formatter: this.RoomFormatter },
                { key: 'chinryou', name: '賃料', width: 120, resizable: true, formatter: ChinRyouFormatter },
                { key: 'chinryouK', name: '契約時', width: 100, resizable: true, formatter: KeiyakuFormatter },
                { key: 'delete', name: '削除', width: 60, resizable: true },
                { key: 'inquiry_rent_response_id', name: '集約', width: 60, resizable: true, formatter: this.InquiryFormatter },



                // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
            ]
        }



    }

    StatusFormatter = (payload: any) => {
        let status = <div></div>;
        if (payload.row.status === 1) {
            // status = <div className="badge badge-success" style={{ width: "70px", margin: "2px" }}>問合物件</div>
            status = <button type="button" className="btn btn-sm btn-danger" style={{ width: "70px", margin: "2px", marginBottom: "5px" }} onClick={() => this.props.toiawase(payload.row)}>問合物件</button>
        } else {
            status = <button type="button" className="btn btn-sm btn-light" style={{ width: "70px", margin: "2px", marginBottom: "5px" }} onClick={() => this.props.toiawase(payload.row)}>問合指定</button>
        }

        let auto = <div></div>;
        if (payload.row.status === 3) {
            auto = <div className="badge badge-info" style={{ width: "70px", margin: "2px", fontSize: "12px" }}>自動送信</div>
        } else if (payload.row.status === 4) {
            auto = <div className="badge badge-secondary" style={{ width: "70px", margin: "2px", fontSize: "10px" }}>自動送信除外</div>
        }
        let publish;
        if (payload.row.on_mypage === 1) {
            publish = <button type="button" className="btn btn-sm btn-danger" style={{ width: "70px", margin: "2px" }} onClick={() => this.props.onMypage(payload.row)}>公開中</button>

        } else {
            publish = <button type="button" className="btn btn-sm btn-success" style={{ width: "70px", margin: "2px" }} onClick={() => this.props.onMypage(payload.row)}>公開する</button>
        }
        return <span><div>{status}</div><div>{auto}</div><div>{publish}</div></span>

    };

    RoomFormatter = (payload: any) => {
        const kanri_kubun_name = payload.row.kanri_kubun_no === 6 ? payload.row.kanri_corp_name : payload.row.kanri_kubun_name
        if (payload.row.status === 5) {
            return <div><div>{payload.row.room_name}</div><button type="button" className="btn btn-sm btn-primary" style={{ width: "50px", margin: "2px" }} onClick={() => this.props.togglefileup(payload.row.id)}>詳細</button><div>{payload.row.kuushitsu_joukyou_name}</div><div>{kanri_kubun_name}</div></div>

        } else {
            return <div><div>{payload.row.room_name}</div><button type="button" className="btn btn-sm btn-primary" style={{ width: "50px", margin: "2px" }} onClick={() => this.props.selectsheet(payload.row)}>詳細</button><div>{payload.row.kuushitsu_joukyou_name}</div><div>{kanri_kubun_name}</div></div>
        }

    };

    NameFormatter = (payload: any) => {
        if (payload.row.status === 5) {

            return <div><div>{payload.row.is_bookmark ? <StarIcon style={{ color: "#ff4500" }} /> : ""}{payload.row.tatemono_name}</div></div>

        } else {

            return <div><div>{payload.row.is_bookmark ? <StarIcon style={{ color: "#ff4500" }} /> : ""}<Badge color="secondary" anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',

            }}
                badgeContent={payload.row.is_looked}>{payload.row.tatemono_name}</Badge></div>
                <div>{payload.row.shikuchoson}{payload.row.choiki}{payload.row.other_address}</div>
                <div>{payload.row.shunkou_year}年{payload.row.shunkou_month}月</div>
                <button className="btn btn-sm btn-warning" onClick={() => this.props.estimate(payload.row.tatemono_id, payload.row.room_id)} style={{ width: "45px", fontSize: 9, padding: 0 }}>見積</button>
                {/* {this.props.stock_rooms.length} */}
                {/* {payload.row.stocked ? "a":"b"} */}
                {/* {this.props.stock_rooms.filter((a: any) => a.tatemono_id == payload.row.tatemono_id && a.room_id == payload.row.room_id).length > 0 ? 
                    <IconButton style={{padding:5}} onClick={(e:any) => {this.delete_stock_room(payload.row.tatemono_id, payload.row.room_id)}}><RemoveShoppingCartIcon style={{ color: "red" }} /></IconButton> : <IconButton style={{padding:5}} onClick={(e:any) => {this.add_stock_room(payload.row.tatemono_id, payload.row.room_id)}}><AddShoppingCartIcon /></IconButton>} */}
            
            </div >
        }
    };

    InquiryFormatter = (payload: any) => {
        return (
            <div>
                {payload.row.inquiry_rent_response_id == 0 ? "" : <IconButton
                    style={{padding:5}}
                    onClick={(e:any) => {this.openHankyou(payload.row.inquiry_rent_response_id)}}
                    size="large"><OpenInNewIcon /></IconButton>}
            </div>
        );
    };

    openHankyou(id:any){
        window.open(`/customer/` + id, '_blank', 'noopener')
    }

    componentWillMount() {

        // this.props.get_rooms()
        // this.props.customerList()

    }
    getSelectedCell(event: any) {
        alert()
    }

    // onRowClick = (rowIdx:any, row:any) => { 

    //     if(row)
    //     this.props.toggle(row);




    // }

    EmptyRowsView = () => {
        const message = "検索結果がありません。";

        return (
            this.props.introductions_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", padding: "100px" }} > <h3>{message}</h3></div>

        );
    };

    mailadd = (tatemonoId: any, roomId: any) => {

        this.props.add_mail_room({ window: window, id: 131, tatemonoId: tatemonoId, roomId: roomId })
        this.props.togglesearch();
    }


    toggledelete = () => {

        this.setState(prevState => ({
            modaldelete: !prevState.modaldelete,

        }));
    }

    delete_introductions() {
        // alert(this.props.rent_response_id)
        this.props.delete_introduction({ rentIntroductionId: this.state.rent_introduction_id, rent_response_id: this.props.rent_response_id })
        this.toggledelete()
    }
    getCellActions(column: any, row: any) {
        // console.log(row.way)

        // const cellActions = {

        //   coming: comingActions
        // };
        if (column.key === "delete") {

            return [
                {
                    icon: <i className="fas fa-times"></i>,
                    callback: () => {

                        this.toggledelete()
                        this.setState({ rent_introduction_id: row.id })

                    }
                }

            ]
        // } else if (column.key === "tatemono_name") {

        //     return [
        //         {
        //             icon: <i className="fas fa-times"></i>,
        //             callback: () => {

        //                 this.toggledelete()
        //                 this.setState({ rent_introduction_id: row.id })

        //             }
        //         }

        //     ]
        }
        
        // if( column.key==="roomName" ){
        //     let status;
        //     if(row.status===1){
        //         status=<div className="badge badge-success">初回問合</div> 
        //       }else if(row.status===3){
        //         status= <div><div className="clearfix"></div><div className="badge badge-info">自動送信</div> </div>
        //       }
        //       let publish;
        //       if(row.onMypage===1){
        //         publish=<div><div className="clearfix"></div><button type="button" className="btn btn-sm btn-danger" style={{width:"70px",margin:"2px"}}>公開取消</button></div>

        //       }else{
        //         publish= <div><div className="clearfix"></div><button type="button" className="btn btn-sm btn-success" style={{width:"70px",margin:"2px"}}>公開する</button></div>
        //       }
        // return [
        //     {
        //         // icon: <button className="btn btn-sm btn-success"  style={{width:"50px"}}>追加</button>,
        //         icon:<button type="button" className="btn btn-sm btn-success" style={{width:"70px",margin:"2px"}}>詳細</button>,
        //         callback: () => {

        //             this.mailadd(row.tatemonoId,row.roomId)


        //         }
        //       }
        //       ,{
        //     // icon: <button className="btn btn-sm btn-success"  style={{width:"50px"}}>追加</button>,
        //     icon:publish,
        //     callback: () => {

        //         this.mailadd(row.tatemonoId,row.roomId)


        //     }
        //   }
        //     ]

        // }else{
        //     return "";
        // }

    }

    delete_stock_room(tatemono_id:any, room_id:any) {
        this.props.delete_stock_rooms(this.props.stock_rooms.filter((a: any) => a.tatemono_id === tatemono_id && a.room_id === room_id)[0].id)


        // let NewIntroductions = this.props.introductions.map(function (value: any) {
        //     if (value.tatemono_id === tatemono_id && value.room_id === room_id) {
        //         value.stocked = false
        //     }
        //     return value
        // })

        // // const NewIntroductions = [...this.props.introductions];
        // this.props.get_introductions_success([])
        // this.props.get_introductions_success(NewIntroductions)

        // this.props.get_introductions({ rent_response_id: this.props.rent_response_id })
        this.setState({
            refresh: !this.state.refresh    //再描画
          });
    }

    add_stock_room(tatemono_id:any, room_id:any){
        let insertdata = {
            tatemono_id: tatemono_id,

            // search_rooms: this.props.search_rooms,
            room_id: room_id,

        };


        this.props.insert_stock_rooms(insertdata)
        // let NewIntroductions = this.props.introductions.map(function (value: any) {
        //     if (value.tatemono_id === tatemono_id && value.room_id === room_id) {
        //         value.stocked = true
        //     }
        //     return value
        // })

        // // const NewIntroductions = [...this.props.introductions];
        // this.props.get_introductions_success([])
        // this.props.get_introductions_success(NewIntroductions)
        // // this.props.get_introductions({ rent_response_id: this.props.rent_response_id })
        this.setState({
            refresh: !this.state.refresh    //再描画
          });
    }

    render() {
        // console.log(this.props.customerlists)
        // alert(this.props.customerlists+"sss")
        // const ss:any=[];
        return (
            <div >

                <ReactDataGrid
                    // ref={node => this.grid = node}
                    enableCellSelect={false}
                    columns={this.state.columns}
                    rowGetter={(i: number) => this.props.introductions[i]}
                    rowHeight={90}
                    headerRowHeight={35}
                    rowsCount={this.props.introductions.length}
                    getCellActions={this.getCellActions}
                    // headerRenderer={true}
                    dragable={true}
                    // onColumnResize={(idx, width) =>
                    //     console.log(`Column ${idx} has been resized to ${width}`)
                    // }
                    emptyRowsView={this.EmptyRowsView}
                    rowRenderer={RowRenderer}
                    
                    // enableVirtualization={true}
                // onRowClick={this.props.selectsheet}
                //onCellSelected={this.getSelectedCell}
                />






                <Modal isOpen={this.state.modaldelete} toggle={this.toggledelete} size="lg" fade={false}>

                    <ModalHeader toggle={this.toggledelete}>リストから削除</ModalHeader>
                    <ModalBody>
                        対象物件を紹介リストから削除します。

                        自動送信・自動送信除外を削除した場合は再度自動送信リストに加わります。
               </ModalBody>
                    <ModalFooter>
                        <button className="btn btn btn-danger" style={{ margin: "5px" }} onClick={this.delete_introductions}>削除する</button>
                        <button className="btn btn btn-secondary" style={{ margin: "5px" }} onClick={this.toggledelete}>キャンセル</button>
                    </ModalFooter>
                </Modal>


            </div>
        );
    }


}



const mapStateToProps = (state: any) => {
    return {
        // rooms: state.roomlist.rooms,
        introductions: state.introductionlist.introductions,

        // responseid: state.customersReducer.responseid,
        // responsedetail: state.customersReducer.responsedetail,
        introductions_loading: state.roomlist.introductions_loading,
        stock_rooms: state.StockRooms.stock_rooms,
    }
}
function mapDispatchToProps(dispatch: any) {
    return {

        // add_mail_room(state:any) {
        //     dispatch(Mail.add_mail_room(state))
        // },
        insert_stock_rooms(state: any) {
            dispatch(StockRooms.insert_stock_rooms(state))
        },
        delete_stock_rooms(state: any) {
            dispatch(StockRooms.delete_stock_rooms(state))
        },
        get_introductions_success(state: any) {
            dispatch(IntroductionList.get_introductions_success(state))
        },
        get_introductions(state: any) {
            dispatch(IntroductionList.get_introductions(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntroductionsGridComponent);