const authentication = require('../../../react-azure-adb2c2').default;


export const request_getfile = (values: any) => {
  // let url = `http://localhost:5000/api/v1/rent/responses/response_follow_list`;
  const url = process.env.REACT_APP_API_CRM_URL + `/api/v1/rent/responses/response_follow_list`;


  const token = authentication.getAccessToken();


  return fetch(url, {
    method: 'post',
    body: JSON.stringify(values),
    headers: { 'Authorization': 'Bearer ' + token, 'Content-type': 'application/json; charset=UTF-8' }
  }).then(res => res.json())
    .then(payload => ({ payload }))
    .catch(error => ({ error }));
};


