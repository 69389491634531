import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';
// import * as Responses from '../../Store/Responses';
import '../../Css/GridRooms.css';
import './Css/Search.css';
import LoadingComponent from '../Layout/LoadingComponent';
import DetailButton from './DetailButton';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import TextField from '@mui/material/TextField';
import InformationDisp from '../Rooms/InformationDispComponent';


// import * as ReactDataGrid from "react-data-grid";
// import { Toolbar, Data } from "react-data-grid-addons";
interface Props {
  rooms: any,
  rent_response_id: number,
  get_rooms: any,
  toggle: any,
  contacts_loading: number,
  selectsheet: any,
  mapsearch: any,
  selectTatemono: any,
  delete_map_introduction: any,
  mail: boolean

  get_room_details: any,
  get_room_campaign: any,
  roomsReducer: any,
  masterReducer: any,
}


interface State {
  modalvisit:any,
  columns:any,
  tyuuijikou_disp:any,
  // state types
}
















// const DateFormatter = (payload: any) => {
//   if (payload.value) {
//     return moment(payload.value).format("YYYY/MM/DD hh:mm:ss");
//   } else {
//     return "";
//   }
// };

// const gaikanFormatter = (payload: any) => {

//   const url = "https://img.global-center.co.jp/v2/tatemono/" + props.mapsearch.map_room_details[rowIndex].tatemono_id + "/1/1/520/780/0";
//   return (<img style={{ maxWidth: 70 }} src={url} />)
// };



  const Madori = ({ column, props, rowIndex, OpenTyuuijikou }:any) =>{
  const url = "https://img.global-center.co.jp/v2/rooms/" +  props.mapsearch.map_room_details[rowIndex].tatemono_id + "/" + props.mapsearch.map_room_details[rowIndex].room_id + "/8/1/520/780/0";
  return <Cell>
    <div>
      <img style={{ width: "90%", height: "auto" }} src={url} />
    </div>
    <div>
      <button onClick={() => OpenWindowShousai(props.mapsearch.map_room_details[rowIndex].tatemono_id, props.mapsearch.map_room_details[rowIndex].room_id)} className="btn btn-sm btn-primary" style={{ fontSize: 10, padding: 0 }}>部屋詳細</button> 
      <button onClick={() => OpenTyuuijikou(props.mapsearch.map_room_details[rowIndex].tatemono_id, props.mapsearch.map_room_details[rowIndex].room_id)} className="btn btn-sm btn-danger" style={{ fontSize: 10, padding: 0, marginLeft:1 }}>注意事項</button> 
      
    </div>
    <div>
      <button onClick={() => OpenWindowHeyagazou(props.mapsearch.map_room_details[rowIndex].tatemono_id, props.mapsearch.map_room_details[rowIndex].room_id, props)} className="btn btn-sm btn-primary" style={{ fontSize: 10, padding: 0, marginLeft:1 }}>部屋画像</button>
      {props.mapsearch.map_room_details[rowIndex].vr_code ? <button onClick={() => OpenWindowVr(props.mapsearch.map_room_details[rowIndex].tatemono_id, props.mapsearch.map_room_details[rowIndex].room_id)} className="btn btn-sm btn-primary" style={{ fontSize: 10, padding: 0, marginLeft:1 }}>VR</button>:""}
      {/* <button className="btn btn-sm btn-primary" style={{ fontSize: 10, padding: 0, marginLeft:1 }}>動画</button> */}
    </div>
    <div>
      <button onClick={() => OpenWindowDaityo(props.mapsearch.map_room_details[rowIndex].tatemono_id, props.mapsearch.map_room_details[rowIndex].room_id, props)} className="btn btn-sm btn-success" style={{ fontSize: 10, padding: 0 }}>台帳</button> 
      <button onClick={() => OpenWindowNewVer(props.mapsearch.map_room_details[rowIndex].tatemono_id, props.mapsearch.map_room_details[rowIndex].room_id, props)} className="btn btn-sm btn-success" style={{ fontSize: 10, padding: 0, marginLeft:1 }}>新Ver</button> 
      <button onClick={() => OpenWindowSatuei(props.mapsearch.map_room_details[rowIndex].tatemono_id, props.mapsearch.map_room_details[rowIndex].room_id)} className="btn btn-sm btn-success" style={{ fontSize: 10, padding: 0, marginLeft:1 }}>撮影用</button>
    </div>
  </Cell>
};


const OpenWindowShousai = (tid: any, rid: any) => {
  window.open(`https://www.homestation.jp/manage/room/detail/index?t_id=`+tid+`&r_id=`+rid, '_blank', 'noopener')
}
const OpenWindowHeyagazou = (tid: any, rid: any, props: any) => {
  const tenpo = [1, 3, 6, 2, 4, 47, 17].filter(a=>a== props.masterReducer.user_details.amSectionId).length > 0 ? [1, 3, 6, 2, 4, 47, 17].filter(a=>a== props.masterReducer.user_details.amSectionId)[0] : 17
  window.open(`https://www.homestation.jp/manage/room/sheet/images/8?t_id=`+tid+`&r_id=`+rid+`&s_id=`+tenpo, '_blank', 'noopener')
}
const OpenWindowVr = (tid: any, rid: any) => {
  window.open(`https://spacely.co.jp/web01/`+tid+`-`+rid, '_blank', 'noopener')
}
const OpenWindowDaityo = (tid: any, rid: any, props:any) => {
  const tenpo = [1, 3, 6, 2, 4, 47, 17].filter(a=>a== props.masterReducer.user_details.amSectionId).length > 0 ? [1, 3, 6, 2, 4, 47, 17].filter(a=>a== props.masterReducer.user_details.amSectionId)[0] : 17
  window.open(`https://www.homestation.jp/manage/room/sheet/rent?st=1&t_id=`+tid+`&r_id=`+rid+`&s_id=`+tenpo, '_blank', 'noopener')
}
const OpenWindowNewVer = (tid: any, rid: any, props:any) => {
  const tenpo = [1, 3, 6, 2, 4, 47, 17].filter(a=>a== props.masterReducer.user_details.amSectionId).length > 0 ? [1, 3, 6, 2, 4, 47, 17].filter(a=>a== props.masterReducer.user_details.amSectionId)[0] : 17
  window.open(`https://www.homestation.jp/manage/room/sheet/sasaki?st=1&t_id=`+tid+`&r_id=`+rid+`&s_id=`+tenpo, '_blank', 'noopener')
}
const OpenWindowSatuei = (tid: any, rid: any) => {
  window.open(`https://www.homestation.jp/manage/room/sheet/rent/multi?req=1&rooms=`+tid+`-`+rid, '_blank', 'noopener')
}




const Chinryou = ({ column, props, rowIndex, OpenTyuuijikou }:any) =>{
  // const url = "https://www.global-center.co.jp/claso/common/img/room/"+props.mapsearch.map_room_details[rowIndex].tatemonoId+"/"+props.mapsearch.map_room_details[rowIndex].roomId+"/8/1/";
  return <div style={{ fontSize: "12px" }}>
      <div>家賃:{props.mapsearch.map_room_details[rowIndex].chinryou}</div>
      <div>共益費:{props.mapsearch.map_room_details[rowIndex].kyouekihi}</div>
      <div>敷金:{props.mapsearch.map_room_details[rowIndex].shikikin_mon > 0 ? props.mapsearch.map_room_details[rowIndex].shikikin_mon + "ヵ月" : props.mapsearch.map_room_details[rowIndex].shikikin > 0 ? props.mapsearch.map_room_details[rowIndex].shikikin + "円" : "-"}</div>
      <div>礼金:{props.mapsearch.map_room_details[rowIndex].reikin_mon > 0 ? props.mapsearch.map_room_details[rowIndex].reikin_mon + "ヵ月" : props.mapsearch.map_room_details[rowIndex].reikin > 0 ? props.mapsearch.map_room_details[rowIndex].reikin + "円" : "-"}</div>
      <div><button className="btn btn-sm btn-success" style={{ fontSize: 10, padding: 0 }}>{props.mapsearch.map_room_details[rowIndex].electronic_contract == 1 ? "電子契約不可" : "電子契約可"}</button></div>
      {props.mapsearch.map_room_details[rowIndex].content ? <div><button onClick={() => OpenTyuuijikou(props.mapsearch.map_room_details[rowIndex].tatemono_id, props.mapsearch.map_room_details[rowIndex].room_id)} className="btn btn-sm btn-primary" style={{ fontSize: 10, padding: 0 }}>キャンペーン</button></div>:""}
    </div>
};


const Selected = ({ column, props, rowIndex }:any) =>{
  // const url = "https://www.global-center.co.jp/claso/common/img/room/"+props.mapsearch.map_room_details[rowIndex].tatemonoId+"/"+props.mapsearch.map_room_details[rowIndex].roomId+"/8/1/";
  return <DetailButton rowData={props.mapsearch.map_room_details[rowIndex]} />
};


const Room = ({ column, props, rowIndex }:any) =>{

  const today = new Date()
  let taikyo = '';

  if (props.mapsearch.map_room_details[rowIndex].taikyo_yotei_date != null /* && val.Kagiban_Setti_Date == '0000年00月00日'*/) {
    if (new Date(props.mapsearch.map_room_details[rowIndex].taikyo_yotei_date) < today) {
      taikyo = props.mapsearch.map_room_details[rowIndex].taikyo_yotei_date
    } else {
      taikyo = props.mapsearch.map_room_details[rowIndex].taikyo_yotei_date
    }
  } else if (props.mapsearch.map_room_details[rowIndex].kaiyaku_date != null && props.mapsearch.map_room_details[rowIndex].kagiban_setti_date == null) {
    if (new Date(props.mapsearch.map_room_details[rowIndex].kaiyaku_date) < today) {
      taikyo = props.mapsearch.map_room_details[rowIndex].taikyo_yotei_date
    } else {
      taikyo = props.mapsearch.map_room_details[rowIndex].kaiyaku_date
    }
  }

  let kagi = "";
  //                      
  if (props.mapsearch.map_room_details[rowIndex].kagi_uketori_date != null && props.mapsearch.map_room_details[rowIndex].kagiban_setti_date < new Date(props.mapsearch.map_room_details[rowIndex].kagi_uketori_date)) {
    kagi = kagi == "" ? '' : '</br>';
    kagi += '鍵受取日:' + (new Date(props.mapsearch.map_room_details[rowIndex].kagi_uketori_date).getFullYear() + '年' + (new Date(props.mapsearch.map_room_details[rowIndex].kagi_uketori_date).getMonth() + 1) + '月' + new Date(props.mapsearch.map_room_details[rowIndex].kagi_uketori_date).getDate() + '日')
  }
  let kagikaigyou = kagi == "" ? '' : '</br>';
  kagi += props.mapsearch.map_room_details[rowIndex].kagi_shozai_name != "" ? kagikaigyou + props.mapsearch.map_room_details[rowIndex].kagi_shozai_name : "";

  if (props.mapsearch.map_room_details[rowIndex].kagiban_setti_date != null && props.mapsearch.map_room_details[rowIndex].io_keiyaku_date != null && new Date(props.mapsearch.map_room_details[rowIndex].kagiban_setti_date) > new Date(props.mapsearch.map_room_details[rowIndex].io_keiyaku_date)) {
    kagikaigyou = kagi == "" ? '' : '</br>';
    kagi += props.mapsearch.map_room_details[rowIndex].kagiban_setti_date != null ? kagikaigyou + "鍵番設置:" + (new Date(props.mapsearch.map_room_details[rowIndex].kagiban_setti_date).getFullYear() + '年' + (new Date(props.mapsearch.map_room_details[rowIndex].kagiban_setti_date).getMonth() + 1) + '月' + new Date(props.mapsearch.map_room_details[rowIndex].kagibanSettiDate).getDate() + '日') : ""
  }
  if (props.mapsearch.map_room_details[rowIndex].kagiBan != "") {
    kagikaigyou = kagi == "" ? '' : '</br>';
    kagi += kagikaigyou + "鍵番種類:" + props.mapsearch.map_room_details[rowIndex].kagi_ban
  }
  if (props.mapsearch.map_room_details[rowIndex].kagiban_tekkyo_date == null) {
    kagikaigyou = kagi == "" ? '' : '</br>';
    kagi += (props.mapsearch.map_room_details[rowIndex].kagiban_tekkyo_date != null && new Date(props.mapsearch.map_room_details[rowIndex].kagiban_tekkyo_date) > new Date(props.mapsearch.map_room_details[rowIndex].kaiyakuDate)) ? kagikaigyou + "鍵番撤去日" + (new Date(props.mapsearch.map_room_details[rowIndex].kagiban_tekkyo_date).getFullYear() + '年' + (new Date(props.mapsearch.map_room_details[rowIndex].kagiban_tekkyo_date).getMonth() + 1) + '月' + new Date(props.mapsearch.map_room_details[rowIndex].kagiban_tekkyo_date).getDate() + '日') : ""
  }
  kagikaigyou = kagi == "" ? '' : '</br>';
  kagi += (props.mapsearch.map_room_details[rowIndex].kagibikou != '' ? kagikaigyou + '鍵備考:' : '') + props.mapsearch.map_room_details[rowIndex].kagibikou;





  let nyuukyo_kanoubi = '-';
  let nk_day = props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_day;
  if (nk_day.indexOf('上旬') != -1) {
    nk_day = 8
  } else if (nk_day.indexOf('中旬') != -1) {
    nk_day = 18
  } else if (nk_day.indexOf('下旬') != -1) {
    nk_day = 28
  }

  //  props.mapsearch.map_room_details[rowIndex].nyuukyoKanouYear +'/'+(props.mapsearch.map_room_details[rowIndex].nyuukyoKanouMonth)+'/'+nk_day
  let nyuukyo_date = props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_year + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_month + '/' + nk_day
  if (props.mapsearch.map_room_details[rowIndex].kuushitsu_joukyou_no == 1 || props.mapsearch.map_room_details[rowIndex].kuushitsu_joukyou_no == 2 || props.mapsearch.map_room_details[rowIndex].kuushitsu_joukyou_no == 3) {
    if (props.mapsearch.map_room_details[rowIndex].kaiyaku_date != null) {
      let day = new Date();
      let nkd = day.setDate(new Date(nyuukyo_date).getDate() + 3);
      let bd = day.setDate(new Date(props.mapsearch.map_room_details[rowIndex].kaiyaku_date).getDate() + 30);
      // let bd= date('Y/m/d', strtotime('+30 day',  strtotime($value["Kaiyaku_Date"])));

      if (new Date(nyuukyo_date) > new Date()) {
        nyuukyo_kanoubi = props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_year > 0 ? props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_year + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_month + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_day : '即';

      } else {
        nyuukyo_kanoubi = '即';
      }

    } else {
      if (new Date(nyuukyo_date) > new Date()) {
        nyuukyo_kanoubi = props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_year > 0 ? props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_year + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_month + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_day : '即';
      } else {
        nyuukyo_kanoubi = '即';
      }
    }

    if (props.mapsearch.map_room_details[rowIndex].hoshuu_date != null) {
      if (new Date() < new Date(props.mapsearch.map_room_details[rowIndex].hoshuu_date)) {
        nyuukyo_kanoubi = '募集中';//'募集中　完工予定:' + val.checkout.Hoshuu_Date;
      }
    }
    if (props.mapsearch.map_room_details[rowIndex].completion_at != null && props.mapsearch.map_room_details[rowIndex].completion_at != '') {
      if (new Date() < new Date(props.mapsearch.map_room_details[rowIndex].completion_at)) {
        nyuukyo_kanoubi = '募集中';//'募集中　完工:' + val.checkout.completion_at;
      } else {
        nyuukyo_kanoubi = '即';
      }
    }

  }




  const time = new Date()
  const ndate = props.mapsearch.map_room_details[rowIndex].nyuukyoritsu_date

  let boshu_joukyou = ((props.mapsearch.map_room_details[rowIndex].completion_at !== null && props.mapsearch.map_room_details[rowIndex].completion_at !== '') ? '即' : '募集中');
  // if (props.mapsearch.map_room_details[rowIndex].kuushitsuJoukyouNo === 2) {
  //   boshu_joukyou = '押さえ'
  // } else if (props.mapsearch.map_room_details[rowIndex].kuushitsuJoukyouNo === 3) {
  //   boshu_joukyou = '申込み'
  // } else if (props.mapsearch.map_room_details[rowIndex].kuushitsuJoukyouNo === 4) {
  //   boshu_joukyou = '入居中'
  // } else if (props.mapsearch.map_room_details[rowIndex].kuushitsuJoukyouNo === 5) {
  //   boshu_joukyou = '解約予定'
  // } else if (props.mapsearch.map_room_details[rowIndex].kuushitsuJoukyouNo === 6) {
  //   boshu_joukyou = '募集停止'
  // }

  let kankou = (props.mapsearch.map_room_details[rowIndex].completion_at !== null && props.mapsearch.map_room_details[rowIndex].completion_at !== '') ? (new Date(props.mapsearch.map_room_details[rowIndex].completion_at).getFullYear() + '/' + (new Date(props.mapsearch.map_room_details[rowIndex].completion_at).getMonth() + 1) + '/' + new Date(props.mapsearch.map_room_details[rowIndex].completion_at).getDate()) : (props.mapsearch.map_room_details[rowIndex].hoshuu_date != null ? <div>補完予定 :{(new Date(props.mapsearch.map_room_details[rowIndex].hoshuu_date).getFullYear() + '/' + (new Date(props.mapsearch.map_room_details[rowIndex].hoshuu_date).getMonth() + 1) + '/' + new Date(props.mapsearch.map_room_details[rowIndex].hoshuu_date).getDate())}</div> : '');

  if (kankou == '' && props.mapsearch.map_room_details[rowIndex].kaiyaku_date == null) {
    if (new Date(nyuukyo_date) > new Date()) {

      kankou = <div>補完予定 :{props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_year > 0 ? props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_year + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_month + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_day : "即"}</div>
    } else {
      kankou = props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_year + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_month + '/' + props.mapsearch.map_room_details[rowIndex].nyuukyo_kanou_day
      boshu_joukyou = '即';

    }
  }

  if (props.mapsearch.map_room_details[rowIndex].kuushitsu_joukyou_no === 2) {
    boshu_joukyou = '押さえ'
  } else if (props.mapsearch.map_room_details[rowIndex].kuushitsu_joukyou_no === 3) {
    boshu_joukyou = '申込み'
  } else if (props.mapsearch.map_room_details[rowIndex].kuushitsu_joukyou_no === 4) {
    boshu_joukyou = '入居中'
  } else if (props.mapsearch.map_room_details[rowIndex].kuushitsu_joukyou_no === 5) {
    boshu_joukyou = '解約予定'
  } else if (props.mapsearch.map_room_details[rowIndex].kuushitsu_joukyou_no === 6) {
    boshu_joukyou = '募集停止'
  }

  let boshu_set = (props.mapsearch.map_room_details[rowIndex].kanri_kubun_no == "1" || props.mapsearch.map_room_details[rowIndex].kanri_kubun_no == "4") ? <div>
    <div>募集状況:{boshu_joukyou}</div>
    <div>入居可能:{nyuukyo_kanoubi}</div>
    <div>契 約 日:{(props.mapsearch.map_room_details[rowIndex].io_keiyaku_date != null ? (new Date(props.mapsearch.map_room_details[rowIndex].io_keiyaku_date).getFullYear() + '/' + (new Date(props.mapsearch.map_room_details[rowIndex].io_keiyaku_date).getMonth() + 1) + '/' + new Date(props.mapsearch.map_room_details[rowIndex].io_keiyaku_date).getDate()) : '')}</div>
    <div>解 約 日:{((props.mapsearch.map_room_details[rowIndex].kaiyaku_date != null) ? (new Date(props.mapsearch.map_room_details[rowIndex].kaiyaku_date).getFullYear() + '/' + (new Date(props.mapsearch.map_room_details[rowIndex].kaiyaku_date).getMonth() + 1) + '/' + new Date(props.mapsearch.map_room_details[rowIndex].kaiyaku_date).getDate()) : '')}</div>
    <div>退去予定:{((taikyo != "" && taikyo != null) ? new Date(taikyo).getFullYear() + '/' + (new Date(taikyo).getMonth() + 1) + '/' + new Date(taikyo).getDate() : '')}</div>
    <div>工事完了:{kankou}</div>
    {(time < ndate ? <div>管理開始{new Date(props.mapsearch.map_room_details[rowIndex].nyuukyoritsu_date).getFullYear() + '/' + (new Date(props.mapsearch.map_room_details[rowIndex].nyuukyoritsu_date).getMonth() + 1) + '/' + new Date(props.mapsearch.map_room_details[rowIndex].nyuukyoritsu_date).getDate()} </div> : '')}
  </div> : <div>
      <div>募集状況:{boshu_joukyou} </div>
      <div>入居可能:{nyuukyo_kanoubi}</div>
    </div>


  return <div><div style={{ fontWeight: "bold" }}>{props.mapsearch.map_room_details[rowIndex].room_name} ({props.mapsearch.map_room_details[rowIndex].madori_name})</div>
    {props.mapsearch.map_room_details[rowIndex].kanri_kubun_no == "2" ? <div>一般</div> : props.mapsearch.map_room_details[rowIndex].kanri_kubun_no == "3" ? <div>専任</div> :""}
    <div style={{ fontSize: "10px" }}>専有面積:{props.mapsearch.map_room_details[rowIndex].senyuu_menseki}㎡</div>

    <div style={{ fontSize: "10px" }}>{boshu_set}</div></div >
  {/* <div style={{fontSize:"10px"}}>入居可能日{props.mapsearch.map_room_details[rowIndex].nyuukyoKanouDate}</div><div style={{fontSize:"10px"}}>面積{props.mapsearch.map_room_details[rowIndex].senyuuMenseki}㎡</div></div> */ }
};

// const columns = [


//   { key: 'madoriName', name: '間取', width: 90, resizable: true, formatter: MadoriFormatter },
//   // { key: 'tatemonoName', name: '物件名', width: 150, resizable: true },
//   { key: 'roomName', name: '部屋', width: 110, resizable: true, formatter: roomDataFormatter },
//   { key: 'chinRyou', name: '賃料', width: 80, resizable: true, formatter: DitailFormatter },
//   { key: 'selected', name: '追加', width: 60, resizable: true, formatter: DetailButtonFormatter },
//   // { key: 'id', name: 'ID', width: 20,resizable: true },
//   // { key: 'col8', name: '件数', resizable: true, formatter: DetailLinkFormatter }
// ];

const columns = [
  { key: 'madori_name', name: '間取', width: 90 },
  { key: 'room_name', name: '部屋', width: 110 },
  { key: 'chinryou', name: '賃料', width: 80 },
  { key: 'selected', name: '追加', width: 60 },


]


const EmptyRowsView = (props: any) => {
  const message = "検索結果がありません。";

  return (
    props.contacts_loading === 1 ? <LoadingComponent /> : <div style={{ textAlign: "center", backgroundColor: "#ddd", height: 500, padding: "100px" }} > <h3>{message}</h3></div>

  );
};
// const App = (props:any) =>  (
class App extends Component<Props, State> {
  constructor(props: Readonly<Props>) {
    super(props);

    this.state = {
      columns: columns,
      modalvisit: false,
      tyuuijikou_disp: false,
    }
    this.getCellActions = this.getCellActions.bind(this);
    this.togglesheet = this.togglesheet.bind(this);
    this._rowClassNameGetter = this._rowClassNameGetter.bind(this);

  }

  togglesheet = () => {

    this.setState(prevState => ({
      modalvisit: !prevState.modalvisit,

    }));
  }


  getCellActions(column: any, row: any) {
    // console.log(row.way)

    // const cellActions = {

    //   coming: comingActions
    // };
    console.log(row)
    if (column.key === "selected" && this.props.mail === true) {
      return [{
        icon: <button className="btn btn-sm btn-success" style={{ width: "45px", fontSize: 9, padding: 0 }}>メールに追加</button>,

        callback: () => {

          this.props.selectTatemono(row.tatemonoId, row.roomId)


        }
      },
      {
        icon: <button className="btn btn-sm btn-primary" style={{ width: "45px", fontSize: 9, padding: 0 }}>詳細</button>,

        callback: () => {

          this.props.selectTatemono(row.tatemonoId, row.roomId)


        }
      }
      ]
    } else if (column.key === "selected" && row.selected == null) {
      return [{
        icon: <div><button className="btn btn-sm btn-success" style={{ width: "25px", fontSize: 10, padding: 0 }}>追加</button><br /></div>,

        callback: () => {

          this.props.selectTatemono(row.tatemonoId, row.roomId)


        }
      },
      {
        icon: <div><button className="btn btn-sm btn-primary" style={{ width: "25px", fontSize: 10, padding: 0 }}>詳細</button></div>,

        callback: () => {

          this.props.selectTatemono(row.tatemonoId, row.roomId)


        }
      }
      ]

    } else if (column.key === "selected") {
      return [{
        // icon: <button className="btn btn-sm btn-danger"  style={{width:"50px"}}>削除</button>,
        icon: <button className="btn btn-sm btn-danger" style={{ width: "50px" }}>取消</button>,
        callback: () => {

          this.props.delete_map_introduction({ introduction_id: row.selected, tatemono_id: row.tatemonoId, rent_response_id: this.props.rent_response_id })


        }
      }
      ]
    }

  }

  _rowClassNameGetter(index:any){
        
    if(this.props.mapsearch.map_room_details && this.props.mapsearch.map_room_details[index]) {
        if(this.props.mapsearch.map_room_details[index].kuushitsu_joukyou_no == 2 || 
          this.props.mapsearch.map_room_details[index].kuushitsu_joukyou_no == 3 || 
          this.props.mapsearch.map_room_details[index].kuushitsu_joukyou_no == 4 || 
          this.props.mapsearch.map_room_details[index].kuushitsu_joukyou_no == 5 || 
          this.props.mapsearch.map_room_details[index].kuushitsu_joukyou_no == 6 ){


          return "nyukyo"
        }else{
          return "normal"
        }
        
    }else{
      return ""
    }
  }


  OpenTyuuijikou = (tid: any, rid: any) => {

    const room = {
      tatemono_id: tid,
      room_id: rid,
    }
    this.props.get_room_details(room)
    this.props.get_room_campaign(room)

    this.setState(prevState => ({
      tyuuijikou_disp: !prevState.tyuuijikou_disp,

    }));
  }

  render() {
    //     // console.log(this.props.customerlists)
    //     // alert(this.props.customerlists+"sss")
    //     // const ss:any=[];
    return (
      <div>


{
          (
            this.props.mapsearch.map_room_details && 
            this.props.mapsearch.map_room_details.length > 0 &&
            this.props.mapsearch.map_room_details.filter((a:any)=>
              // a.send_aos || 
              // a.kanri_corp_no == 5 || //大東
              // a.kanri_corp_no == 6 || //レオパ
              // a.kanri_corp_no == 2 || //穴吹ハウ
              a.kanri_corp_no != 1 || 
            (a.approval_status == 1 && (a.kanri_kubun_no == 1 || a.kanri_kubun_no == 4))
            ).length > 0
          ) ? 
                <Table
                    className="gridMapSearchRoomList"
                    rowHeight={130}
                    rowsCount={this.props.mapsearch.map_room_details.length} 
                    width={345}
                    // height={150}
                    height={window.innerHeight - 240}
                    headerHeight={35}
                    // onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={false}
                    rowClassNameGetter={this._rowClassNameGetter}
                    // onRowClick={this.RowClick}
                >
              
                  {this.state.columns.map((value:any) => {
                    return <Column
                      columnKey={value.key}
                      header={<Cell>{value.name}</Cell>}

                      cell={({ rowIndex, ...cellProps }) => (

                        value.key === "madori_name" ? <Madori column={value} props={this.props} rowIndex={rowIndex} OpenTyuuijikou={this.OpenTyuuijikou}></Madori>
                          : value.key === "room_name" ? <Room column={value} props={this.props} rowIndex={rowIndex}></Room>
                            : value.key === "chinryou" ? <Chinryou column={value} props={this.props} rowIndex={rowIndex} OpenTyuuijikou={this.OpenTyuuijikou}></Chinryou>

                                                : <Selected column={value} props={this.props} rowIndex={rowIndex}></Selected>
                      )}

                      width={value.width}
                      // isResizable={true}
                      // fixed={value.frozen}

                    />
                  }, this)}

                </Table>
          
          
          : "募集承認されていないため表示できません。"}


{/* 


        {
          (
            this.props.mapsearch.map_room_details && 
            this.props.mapsearch.map_room_details.length > 0 &&
            this.props.mapsearch.map_room_details.filter((a:any)=>
              // a.send_aos || 
              // a.kanri_corp_no == 5 || //大東
              // a.kanri_corp_no == 6 || //レオパ
              // a.kanri_corp_no == 2 || //穴吹ハウ
              a.kanri_corp_no != 1 || 
            (a.approval_status == 1 && (a.kanri_kubun_no == 1 || a.kanri_kubun_no == 4))
            ).length > 0
          ) ? <ReactDataGrid
          // ref={node => this.grid = node}
          enableCellSelect={false}
          columns={columns}
          rowGetter={(i: number) => this.props.mapsearch.map_room_details[0] ? this.props.mapsearch.map_room_details[i] : {}}
          rowHeight={130}
          headerRowHeight={35}
          rowsCount={this.props.mapsearch.map_room_details[0] ? this.props.mapsearch.map_room_details.length : 0}
          headerRenderer={true}
          // getCellActions={this.getCellActions}
          dragable={true}
          // onColumnResize={(idx, width) =>
          //     console.log(`Column ${idx} has been resized to ${width}`)
          // }
          emptyRowsView={EmptyRowsView}
          // onRowClick={props.selectsheet}
          //onCellSelected={this.getSelectedCell}
          minWidth={350}
          minHeight={window.innerHeight - 230} /> : "募集承認されていないため表示できません。"} */}

{/* <TextField
                id="outlined-multiline-static"
                label="備考"
                // value={this.props.customerContactsReducer.visit_editor_forms.remarks}
                name="remarks"
                // onChange={this.handleChange}
                multiline
                rows={4}
                style={{ width: '90%', margin: 10 }}
                variant="outlined"
              />

      <Button onClick={()=>this.togglesheet()}>aaa</Button> */}
      <Modal isOpen={this.state.modalvisit} zIndex={3000} size="md" fade={false}>
        <ModalHeader toggle={() => this.setState({ "modalvisit": false })}>案内物件</ModalHeader>
        <ModalBody>
              <TextField
                id="outlined-multiline-static"
                label="備考"
                // value={this.props.customerContactsReducer.visit_editor_forms.remarks}
                name="remarks"
                // onChange={this.handleChange}
                multiline
                rows={4}
                style={{ width: '90%', margin: 10 }}
                variant="outlined"
              />
        </ModalBody>
        <ModalFooter>
          {/* <Button variant="contained" style={{ margin: 5 }} color="primary" onClick={update_rent_visits}>保存</Button> */}
          {/* {visit_editor_forms.rent_visit_id ? <Button style={{ margin: 5 }} variant="contained" color="secondary" onClick={delete_rent_visits}>削除</Button> : ""} */}
          {/* <Button variant="outlined" style={{ margin: 5 }} onClick={handleClose}>閉じる</Button> */}
        </ModalFooter>
      </Modal>



        <Modal isOpen={this.state.tyuuijikou_disp} toggle={() => this.setState({ "tyuuijikou_disp": false })} zIndex={3000} size="md" fade={false}>
        <ModalHeader toggle={() => this.setState({ "tyuuijikou_disp": false })}>注意事項　{this.props.roomsReducer.room_details.tatemono_name ? this.props.roomsReducer.room_details.tatemono_name : ""}　{this.props.roomsReducer.room_details.room_name ? this.props.roomsReducer.room_details.room_name : ""}</ModalHeader>
        <ModalBody>
          <InformationDisp room_campaign={this.props.roomsReducer.room_campaign}  room_details={this.props.roomsReducer.room_details}/>
        </ModalBody>
        {/* <ModalFooter>

        </ModalFooter> */}
        </Modal>


      </div>
    )
  }

}


// }

export default connect(
  ({ mapsearch, roomsReducer, masterReducer }: any) => ({ mapsearch, roomsReducer, masterReducer }),
  {
    // MapSearch
  }
)(App)